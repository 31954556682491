const JobOpeningsIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_14823_29562" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
        <rect x="0.5" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_14823_29562)">
        <path
          d="M3.16668 13.9987C2.80001 13.9987 2.48612 13.8681 2.22501 13.607C1.9639 13.3459 1.83334 13.032 1.83334 12.6654V5.33203C1.83334 4.96536 1.9639 4.65148 2.22501 4.39036C2.48612 4.12925 2.80001 3.9987 3.16668 3.9987H5.83334V2.66536C5.83334 2.2987 5.9639 1.98481 6.22501 1.7237C6.48612 1.46259 6.80001 1.33203 7.16668 1.33203H9.83334C10.2 1.33203 10.5139 1.46259 10.775 1.7237C11.0361 1.98481 11.1667 2.2987 11.1667 2.66536V3.9987H13.8333C14.2 3.9987 14.5139 4.12925 14.775 4.39036C15.0361 4.65148 15.1667 4.96536 15.1667 5.33203V12.6654C15.1667 13.032 15.0361 13.3459 14.775 13.607C14.5139 13.8681 14.2 13.9987 13.8333 13.9987H3.16668ZM3.16668 12.6654H13.8333V5.33203H3.16668V12.6654ZM7.16668 3.9987H9.83334V2.66536H7.16668V3.9987Z"
          fill="#082051"
          fillOpacity="0.5"
        />
      </g>
    </svg>
  );
};

export default JobOpeningsIcon;
