/**
 * @generated SignedSource<<12fe550f0af9a77e6cec7075d887381f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EducationQueriesKindsQuery$variables = {
  skip: boolean;
};
export type EducationQueriesKindsQuery$data = {
  readonly educationKinds?: {
    readonly edges: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly perma: string;
    }>;
  } | null;
};
export type EducationQueriesKindsQuery = {
  response: EducationQueriesKindsQuery$data;
  variables: EducationQueriesKindsQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'skip',
      },
    ],
    v1 = [
      {
        condition: 'skip',
        kind: 'Condition',
        passingValue: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'EducationKindList',
            kind: 'LinkedField',
            name: 'educationKinds',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'EducationKind',
                kind: 'LinkedField',
                name: 'edges',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'id',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'name',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'perma',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'EducationQueriesKindsQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'EducationQueriesKindsQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'd26e7e1baba1e2049a5dd40035cb8ccd',
      id: null,
      metadata: {},
      name: 'EducationQueriesKindsQuery',
      operationKind: 'query',
      text: 'query EducationQueriesKindsQuery(\n  $skip: Boolean!\n) {\n  educationKinds @skip(if: $skip) {\n    edges {\n      id\n      name\n      perma\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '1bf591f784e6e52010762ba0400564fc';

export default node;
