// packages
import { useIntl } from 'react-intl';
// types
import { EmptyListProps } from 'system/ListComponents/types';
import { useGetProfileIdFromAuthOrParams } from 'hooks/useGetProfileIdFromAuthOrParams';

const EmptyList = ({ onClick, icon, title, navigationTitle }: EmptyListProps) => {
  const intl = useIntl();

  const { isSelfProfile } = useGetProfileIdFromAuthOrParams();

  return (
    <div className="flex flex-col w-56 mx-auto mt-3 sm:mt-20">
      {icon}
      <div className="flex flex-col items-center sm:mt-5 mt-2.5">
        <div className="text-center text-specialGray-1 text-2xl font-semibold sm:mt-5">{intl.formatMessage(title)}</div>
        <span className="text-xs text-specialGray-075 text-center sm:mt-3 mt-1.5 w-3/4">
          {intl.formatMessage({ id: isSelfProfile ? 'profile_addMoreInformation' : 'profile_user_has__no_information' })}
        </span>
        {isSelfProfile && (
          <span onClick={onClick} className="text-blue text-sm font-medium cursor-pointer sm:mt-5 mt-3">
            {intl.formatMessage(navigationTitle)}
          </span>
        )}
      </div>
    </div>
  );
};

export default EmptyList;
