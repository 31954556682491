// packages
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLazyLoadQuery, useMutation } from 'react-relay';
// components
import { Modal } from 'system/Modal';
import NoAppraisalIcon from 'icons/NoAppraisalIcon';
import EmptyList from 'system/ListComponents/EmptyList';
import HeaderList from 'system/ListComponents/HeaderList';
import { ConfirmModalBody } from 'system/ConfirmModalBody';
import AppraisalsForm from '../AppraisalForm/AppraisalsForm';
import AppraisalsFormSkeleton from 'system/skeletons/appraisals/AppraisalsFormSkeleton';
import AppraisalItemWithDocument from '../AppraisalsListItemWrapper/AppraisalItemWithDocument';
import AppraisalItemWithoutDocument from '../AppraisalsListItemWrapper/AppraisalItemWithoutDocument';
// hooks
import { useAuth } from 'authentication';
import useSortedByEndDate from 'hooks/useSortedByEndDate';
import { useGetProfileIdFromAuthOrParams } from 'hooks/useGetProfileIdFromAuthOrParams';
// generated
import {
  AppraisalFragmentsOneAppraisalWithoutDocument$data,
  AppraisalFragmentsOneAppraisalWithoutDocument$key,
} from 'schemas/appraisals/__generated__/AppraisalFragmentsOneAppraisalWithoutDocument.graphql';
import { AppraisalQueriesListQuery } from 'schemas/appraisals/__generated__/AppraisalQueriesListQuery.graphql';
import { AppraisalMutationsListRemoveMutation } from 'schemas/appraisals/__generated__/AppraisalMutationsListRemoveMutation.graphql';
import { AppraisalQueriesListWithoutDocumentQuery } from 'schemas/appraisals/__generated__/AppraisalQueriesListWithoutDocumentQuery.graphql';
import { AppraisalFragmentsOneAppraisal$data, AppraisalFragmentsOneAppraisal$key } from 'schemas/appraisals/__generated__/AppraisalFragmentsOneAppraisal.graphql';
// schemas
import { REMOVE_APPRAISAL } from 'schemas/appraisals/AppraisalMutations';
import { GET_ALL_APPRAISALS_LIST, GET_ALL_APPRAISALS_LIST_WITHOUT_DOCUMENT } from 'schemas/appraisals/AppraisalQueries';
// formHelpers
import { getConnectionId } from 'formHelpers/utils';
import { UpdatersOfAppraisalCommitRemove } from 'formHelpers/updaters/updatersOfAppraisal';
// types
import { AppraisalSortedItemProps } from '../types';

const AppraisalsList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { identity } = useAuth();

  const { isSelfProfile } = useGetProfileIdFromAuthOrParams();

  useEffect(() => {
    window.analytics?.track('wui-open-appraisalsList-tab');
  }, []);

  const [selected, setSelected] = useState<AppraisalFragmentsOneAppraisal$data | AppraisalFragmentsOneAppraisalWithoutDocument$data>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const [removingItemId, setRemovingItemId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const storyID = getConnectionId('AppraisalListFromProfile_appraisals');

  const { appraisals: appraisalsWithoutDocument } = useLazyLoadQuery<AppraisalQueriesListWithoutDocumentQuery>(GET_ALL_APPRAISALS_LIST_WITHOUT_DOCUMENT, {
    ownerId: id!,
    skip: !id,
  });
  const { appraisals } = useLazyLoadQuery<AppraisalQueriesListQuery>(GET_ALL_APPRAISALS_LIST, { ownerId: `${identity?.profileId}`, skip: !identity?.profileId });

  const [commitRemoveListItem] = useMutation<AppraisalMutationsListRemoveMutation>(REMOVE_APPRAISAL);

  const getSortedField = useCallback((item: AppraisalSortedItemProps) => item?.node?.seaService.end ?? null, []);

  const sortedAppraisalsByEndDate = useSortedByEndDate([...(appraisals?.edges || [])], getSortedField);
  const sortedAppraisalsWithoutDocsByEndDate = useSortedByEndDate([...(appraisalsWithoutDocument?.edges || [])], getSortedField);

  const onItemRemoveClick = useCallback((id: string) => {
    setShowConfirmModal(true);
    setRemovingItemId(id);
  }, []);

  const openCreateModal = () => {
    setSelected(undefined);
    setShowModal(true);
  };

  const openUpdateModal = (appraisal: AppraisalFragmentsOneAppraisal$data | AppraisalFragmentsOneAppraisalWithoutDocument$data) => {
    setSelected(appraisal);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    navigate(pathname, {
      replace: true,
      state: null,
    });
  };

  const handleRemoveItemFromList = (appraisalId: string) => () => {
    setLoading(true);

    window.analytics?.track('wui-remove-appraisal', {
      removedAppraisalId: appraisalId,
    });

    commitRemoveListItem({
      variables: { id: appraisalId },
      onCompleted: () => {
        setLoading(false);
        setShowConfirmModal(false);
      },
      updater: UpdatersOfAppraisalCommitRemove(`${storyID}(ownerId:"${identity?.profileId}")`),
    });
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };

  return (
    <>
      {isSelfProfile ? (
        <>
          <Modal
            onClose={closeModal}
            show={showModal}
            title={{ id: selected ? 'appraisal_edit_modalTitle' : 'appraisal_add_modalTitle' }}
            description={{ id: 'appraisal_modal_description' }}
          >
            <Suspense fallback={<AppraisalsFormSkeleton isAppraisal={!!(selected as AppraisalFragmentsOneAppraisal$data)?.document?.id} />}>
              <AppraisalsForm appraisal={selected} onCloseModal={closeModal} profileId={`${identity?.profileId}`} />
            </Suspense>
          </Modal>
          <Modal onClose={closeConfirmModal} show={showConfirmModal} tinyModal={true}>
            <ConfirmModalBody
              title={{ id: 'confirm_remove_item_title' }}
              textOne={{ id: 'confirm_remove_item_text_1' }}
              textTwo={{ id: 'confirm_remove_item_text_2' }}
              onClick={handleRemoveItemFromList(removingItemId)}
              onCancel={closeConfirmModal}
              buttonLabel={{ id: 'delete' }}
              typeButtonConfirm="red-primary"
            />
          </Modal>
        </>
      ) : null}

      <HeaderList
        title={{ id: 'profile_appraisalsTitle' }}
        onClick={openCreateModal}
        buttonLabel={{ id: 'profile_appraisal' }}
        amountLabel={{ id: 'profile_appraisalAmount' }}
        amount={appraisals?.edges?.length || appraisalsWithoutDocument?.edges?.length || 0}
      />
      {sortedAppraisalsByEndDate ? (
        sortedAppraisalsByEndDate.map(
          appraisal =>
            appraisal?.node && (
              <AppraisalItemWithDocument
                key={appraisal?.node?.id}
                appraisalFragment={appraisal?.node as AppraisalFragmentsOneAppraisal$key}
                openUpdateModal={openUpdateModal}
                onRemoveItem={onItemRemoveClick}
                disabled={loading}
              />
            ),
        )
      ) : sortedAppraisalsWithoutDocsByEndDate ? (
        sortedAppraisalsWithoutDocsByEndDate.map(
          appraisal =>
            appraisal?.node && (
              <AppraisalItemWithoutDocument
                key={appraisal?.node?.id}
                appraisalFragment={appraisal?.node as AppraisalFragmentsOneAppraisalWithoutDocument$key}
                openUpdateModal={openUpdateModal}
                onRemoveItem={onItemRemoveClick}
                disabled={loading}
              />
            ),
        )
      ) : (
        <EmptyList navigationTitle={{ id: 'profile_appraisal' }} onClick={openCreateModal} icon={<NoAppraisalIcon />} title={{ id: 'profile_noAppraisal' }} />
      )}
    </>
  );
};

export default AppraisalsList;
