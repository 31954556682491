// packages
import { FC } from 'react';
// components
import ListOfCrewSkeleton from './components/ListOfCrewSkeleton';
// models
import { VACANCY_DESCRIPTION_TYPE_OF_LIST } from 'models/enums';

const RightSideListsOfCrewSkeleton: FC<{ additionalClasses?: string }> = ({ additionalClasses = 'w-2/5' }) => {
  return (
    <div className={`flex flex-col space-y-8 ${additionalClasses}`}>
      <ListOfCrewSkeleton typeOfList={VACANCY_DESCRIPTION_TYPE_OF_LIST.INVITED} />
      <ListOfCrewSkeleton typeOfList={VACANCY_DESCRIPTION_TYPE_OF_LIST.APPLIED} />
    </div>
  );
};

export default RightSideListsOfCrewSkeleton;
