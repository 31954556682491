/**
 * @generated SignedSource<<f2a182c83f27305fba363774f94b7152>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type CertificateInfoData = {
  documentId?: string | null;
  expires?: String | null;
};
export type CertificateMutationsAddMutation$variables = {
  cid: string;
  data: CertificateInfoData;
  pid: string;
};
export type CertificateMutationsAddMutation$data = {
  readonly createCertificateInfo: {
    readonly node: {
      readonly certificate: {
        readonly ' $fragmentSpreads': FragmentRefs<'CertificateFragments_single'>;
      };
      readonly id: string;
    } | null;
  };
};
export type CertificateMutationsAddMutation = {
  response: CertificateMutationsAddMutation$data;
  variables: CertificateMutationsAddMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'cid',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'data',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'pid',
    },
    v3 = [
      {
        kind: 'Variable',
        name: 'cid',
        variableName: 'cid',
      },
      {
        kind: 'Variable',
        name: 'data',
        variableName: 'data',
      },
      {
        kind: 'Variable',
        name: 'pid',
        variableName: 'pid',
      },
    ],
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'CertificateMutationsAddMutation',
      selections: [
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: 'CertificateInfoEdge',
          kind: 'LinkedField',
          name: 'createCertificateInfo',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'CertificateInfo',
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                v4 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'Certificate',
                  kind: 'LinkedField',
                  name: 'certificate',
                  plural: false,
                  selections: [
                    {
                      args: null,
                      kind: 'FragmentSpread',
                      name: 'CertificateFragments_single',
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v2 /*: any*/, v0 /*: any*/, v1 /*: any*/],
      kind: 'Operation',
      name: 'CertificateMutationsAddMutation',
      selections: [
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: 'CertificateInfoEdge',
          kind: 'LinkedField',
          name: 'createCertificateInfo',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'CertificateInfo',
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                v4 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'Certificate',
                  kind: 'LinkedField',
                  name: 'certificate',
                  plural: false,
                  selections: [
                    v4 /*: any*/,
                    v5 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'alts',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'priority',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'neverExpires',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'CertificateKind',
                      kind: 'LinkedField',
                      name: 'kind',
                      plural: false,
                      selections: [
                        v4 /*: any*/,
                        v5 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'perma',
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '58dca723ae49ddba0ffcdb057adfd910',
      id: null,
      metadata: {},
      name: 'CertificateMutationsAddMutation',
      operationKind: 'mutation',
      text: 'mutation CertificateMutationsAddMutation(\n  $pid: ID!\n  $cid: ID!\n  $data: CertificateInfoData!\n) {\n  createCertificateInfo(pid: $pid, cid: $cid, data: $data) {\n    node {\n      id\n      certificate {\n        ...CertificateFragments_single\n        id\n      }\n    }\n  }\n}\n\nfragment CertificateFragments_single on Certificate {\n  id\n  name\n  alts\n  priority\n  neverExpires\n  kind {\n    id\n    name\n    perma\n  }\n}\n',
    },
  };
})();

(node as any).hash = '9cd331dfe33ee7cc01783f039cb50a5c';

export default node;
