// packages
import React, { useCallback, useEffect, useState } from 'react';
import parseISO from 'date-fns/parseISO';
import { Form, FormikProvider, useFormik } from 'formik';
import { useMutation } from 'react-relay';
// components
import { Button } from 'system/Button';
import { DateInputGroup } from 'system/DateInputGroup';
import AlertForError from 'system/Alert/AlertForError';
// models
import { CertificateInfoModalForm } from 'models/modelsOfForms';
// helpers
import { CertificateInfoFormValidateSchema } from 'formHelpers/validationsOfForms';
// schemas
import { UPDATE_CERTIFICATE_ITEM_MUTATION } from 'schemas/certificates/CertificateMutations';
// generated
import { CertificateMutationsUpdateCertificateInfoMutation } from 'schemas/certificates/__generated__/CertificateMutationsUpdateCertificateInfoMutation.graphql';
// types
import { CertificateInfoProps } from './types';

const CertificateInfoForm = ({ defaultSubmitting, defaultError, onCloseModal, certificate }: CertificateInfoProps) => {
  const [error, setError] = useState<Error | null | undefined>(defaultError);
  const [commitUpdateCertificateInfoDate] = useMutation<CertificateMutationsUpdateCertificateInfoMutation>(UPDATE_CERTIFICATE_ITEM_MUTATION);

  const formik = useFormik<CertificateInfoModalForm>({
    onSubmit,
    initialValues: {
      expiryDate: certificate?.expires ? parseISO(`${certificate?.expires}`) : null,
    },
    validationSchema: CertificateInfoFormValidateSchema,
  });

  const handleAddExpiryDateToCertificate = useCallback(
    (expires: string, handleError: (error: Error) => void) => {
      commitUpdateCertificateInfoDate({
        onError: handleError,
        variables: {
          id: certificate?.id as string,
          certificatesInfoData: { documentId: certificate?.document?.id, expires },
        },
      });
    },
    [commitUpdateCertificateInfoDate, certificate?.id, certificate?.document?.id],
  );

  function onSubmit(formData: CertificateInfoModalForm) {
    const onError = (error: Error) => {
      setError(error);
      formik.setSubmitting(false);
    };

    if (formData.expiryDate) {
      handleAddExpiryDateToCertificate(formData.expiryDate.toISOString(), onError);
    }
    if (onCloseModal) {
      onCloseModal();
    }
    window.analytics?.track('wui-added-a-certificate-expiry-date');
  }

  useEffect(() => {
    if (defaultSubmitting && !formik.isSubmitting) formik.setSubmitting(true);
  }, [formik, defaultSubmitting]);

  const handleRemoveExpiredData = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      commitUpdateCertificateInfoDate({
        variables: {
          id: certificate?.id as string,
          certificatesInfoData: { documentId: certificate?.document?.id, expires: null },
        },
        onCompleted: () => {
          if (onCloseModal) {
            onCloseModal();
          }
        },
      });
    },
    [commitUpdateCertificateInfoDate, certificate?.id, certificate?.document?.id, onCloseModal],
  );

  return (
    <FormikProvider value={formik}>
      <Form className="space-y-6">
        {error && <AlertForError heading={{ id: 'certificate_form_failedSubmit' }} error={error} />}
        <DateInputGroup name="expiryDate" label={{ id: 'certificate_expiry_date' }} autoFocus />
        <div className="flex justify-between">
          {certificate?.expires && <Button label={{ id: 'certificate_form_remove_expiry' }} onClick={handleRemoveExpiredData} buttonType="white-primary" type="button" />}
          <Button label={{ id: 'save' }} type="submit" disabled={formik.isSubmitting} buttonType="secondary" />
        </div>
      </Form>
    </FormikProvider>
  );
};

export default CertificateInfoForm;
