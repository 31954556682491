import graphql from 'babel-plugin-relay/macro';

export const CREATE_APPRAISAL = graphql`
  mutation AppraisalMutationsFormCreateMutation($data: AppraisalData!, $connections: [ID!]!) {
    createAppraisal(data: $data) {
      node @appendNode(connections: $connections, edgeTypeName: "AppraisalEdge") {
        ... on Appraisal {
          id
          ...AppraisalFragmentsOneAppraisal
        }
      }
    }
  }
`;

export const UPDATE_APPRAISAL = graphql`
  mutation AppraisalMutationsFormUpdateMutation($id: ID!, $data: AppraisalData!) {
    updateAppraisal(id: $id, data: $data) {
      id
      ...AppraisalFragmentsOneAppraisal
    }
  }
`;

export const REMOVE_APPRAISAL = graphql`
  mutation AppraisalMutationsListRemoveMutation($id: ID!) {
    deleteAppraisal(id: $id) {
      id
    }
  }
`;
