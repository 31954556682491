// packages
import { isEmpty } from 'lodash';
//components
import CountryFlag from 'system/CountryFlag';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
//models
import { FilterDropDownItemProps } from '../../types';

export const FilterDropDownItem = ({ name, onClickEvent, selected = false, isoCode, isHovered, alts }: FilterDropDownItemProps) => {
  const hoveredIcon = isHovered ? <XMarkIcon className="w-5 h-5" /> : <CheckIcon className="w-5 h-5" />;
  return (
    <div onClick={onClickEvent} className="flex w-full items-center text-sm font-normal cursor-pointer p-2.5 hover:bg-specialGray-005">
      <div className="w-10% text-blue">{selected && hoveredIcon}</div>
      <div className="flex ml-4 w-[90%] text-wrap">
        {isoCode && (
          <div className="mr-2">
            <CountryFlag countryId={isoCode} countryName={name} />
          </div>
        )}
        <span className="text-wrap text-darkBlue">
          <span>
            {name}
            {alts?.[0] ? <span>&#44;&nbsp;</span> : null}
          </span>

          {!isEmpty(alts) && (
            <span className="text-specialGray-075" id={alts?.join(', ')}>
              {alts?.join(', ')}
            </span>
          )}
        </span>
      </div>
    </div>
  );
};
