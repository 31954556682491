import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

const ListOfCertificateItemSkeleton: FC<{ index: number; roundedBorderBottom: boolean }> = ({ index, roundedBorderBottom }) => {
  const setBorder = (index: number) => {
    if (index === 0) {
      return 'rounded-t-xl';
    }
    return 'border-t-0';
  };
  return (
    <div className={`flex items-start border border-specialGray-01 w-full space-x-4 p-4 ${setBorder(index)} ${roundedBorderBottom ? 'rounded-b-xl' : 'border-b'}`}>
      <Skeleton width={18} height={21} containerClassName="w-50%" />
      <Skeleton width="100%" height={14} containerClassName="w-2/3" />
    </div>
  );
};

export default ListOfCertificateItemSkeleton;
