/**
 * @generated SignedSource<<f747b6fb6844324f834a75612fccaa83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SearchIndex = 'ORGANIZATION' | 'PROFILE' | 'VACANCY' | '%future added value';
export type SearchQueriesOrganisationsQuery$variables = {
  after?: any | null;
  filters?: string | null;
  first?: number | null;
  index: SearchIndex;
  query?: string | null;
  skip: boolean;
};
export type SearchQueriesOrganisationsQuery$data = {
  readonly search?: {
    readonly edges: ReadonlyArray<{
      readonly cursor: any;
      readonly node: {
        readonly objectID: string;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: any | null;
      readonly hasNextPage: boolean;
    };
    readonly totalCount: number;
  } | null;
};
export type SearchQueriesOrganisationsQuery = {
  response: SearchQueriesOrganisationsQuery$data;
  variables: SearchQueriesOrganisationsQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'after',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'filters',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'first',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'index',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'query',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'skip',
    },
    v6 = {
      kind: 'Variable',
      name: 'filters',
      variableName: 'filters',
    },
    v7 = {
      kind: 'Variable',
      name: 'index',
      variableName: 'index',
    },
    v8 = {
      kind: 'Variable',
      name: 'query',
      variableName: 'query',
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'totalCount',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'objectID',
      storageKey: null,
    },
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: '__typename',
      storageKey: null,
    },
    v12 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cursor',
      storageKey: null,
    },
    v13 = {
      alias: null,
      args: null,
      concreteType: 'PageInfo',
      kind: 'LinkedField',
      name: 'pageInfo',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'hasNextPage',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'endCursor',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v14 = [
      {
        kind: 'Variable',
        name: 'after',
        variableName: 'after',
      },
      v6 /*: any*/,
      {
        kind: 'Variable',
        name: 'first',
        variableName: 'first',
      },
      v7 /*: any*/,
      v8 /*: any*/,
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/, v5 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'SearchQueriesOrganisationsQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: 'search',
              args: [v6 /*: any*/, v7 /*: any*/, v8 /*: any*/],
              concreteType: 'SearchConnection',
              kind: 'LinkedField',
              name: '__SearchOrganisationsQueries_search_connection',
              plural: false,
              selections: [
                v9 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'SearchEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: null,
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [v10 /*: any*/, v11 /*: any*/],
                      storageKey: null,
                    },
                    v12 /*: any*/,
                  ],
                  storageKey: null,
                },
                v13 /*: any*/,
              ],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v0 /*: any*/, v2 /*: any*/, v4 /*: any*/, v3 /*: any*/, v1 /*: any*/, v5 /*: any*/],
      kind: 'Operation',
      name: 'SearchQueriesOrganisationsQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v14 /*: any*/,
              concreteType: 'SearchConnection',
              kind: 'LinkedField',
              name: 'search',
              plural: false,
              selections: [
                v9 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'SearchEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: null,
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [v11 /*: any*/, v10 /*: any*/],
                      storageKey: null,
                    },
                    v12 /*: any*/,
                  ],
                  storageKey: null,
                },
                v13 /*: any*/,
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: v14 /*: any*/,
              filters: ['query', 'index', 'filters'],
              handle: 'connection',
              key: 'SearchOrganisationsQueries_search',
              kind: 'LinkedHandle',
              name: 'search',
            },
          ],
        },
      ],
    },
    params: {
      cacheID: '74bdbab4485237b5ee637f45485a8f18',
      id: null,
      metadata: {
        connection: [
          {
            count: 'first',
            cursor: 'after',
            direction: 'forward',
            path: ['search'],
          },
        ],
      },
      name: 'SearchQueriesOrganisationsQuery',
      operationKind: 'query',
      text: 'query SearchQueriesOrganisationsQuery(\n  $after: Cursor\n  $first: Int\n  $query: String\n  $index: SearchIndex!\n  $filters: String\n  $skip: Boolean!\n) {\n  search(after: $after, first: $first, query: $query, index: $index, filters: $filters) @skip(if: $skip) {\n    totalCount\n    edges {\n      node {\n        __typename\n        objectID\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '505bc093d830b2cd9868c1244b7ebd34';

export default node;
