// packages
import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
// components
import Overview from 'app/Profile/Overview/Overview';
import { BasicInfoForm } from 'app/Profile/BasicInfoForm/BasicInfoForm';
import BasicInfoFormSkeleton from 'system/skeletons/profile/BasicInfoFormSkeleton';
// models
import { Modal } from 'system/Modal';

const EditProfileInfo = () => {
  const navigate = useNavigate();

  return (
    <>
      <Modal additionalClasses="max-w-[720px]" onClose={() => navigate(-1)} show title={{ id: 'self_basic_modalTitle' }} description={{ id: 'self_basic_modalDescription' }}>
        <Suspense fallback={<BasicInfoFormSkeleton />}>
          <BasicInfoForm />
        </Suspense>
      </Modal>
      <Overview />
    </>
  );
};

export default EditProfileInfo;
