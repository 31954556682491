// packages
import { Suspense } from 'react';
// routes
import { RoutePaths, RoutesPathNames } from 'app/routing';
// components
import ServiceList from 'app/Profile/Service/ServiceList';
import EducationList from 'app/Profile/Education/EducationList';
import CrewVisitWrapper from '../components/crewVisit/CrewVisitWrapper';
import AppraisalsList from 'app/Profile/Appraisals/AppraisalsList/AppraisalsList';
import EducationsTabSkeleton from 'system/skeletons/profileTabs/EducationsTabSkeleton';
import AppraisalsTabSkeleton from 'system/skeletons/profileTabs/AppraisalsTabSkeleton';
import ServicesListTabSkeleton from 'system/skeletons/profileTabs/ServicesListTabSkeleton';
import GetOverviewForCrew from '../components/crewVisit/components/GetOverviewForCrew/GetOverviewForCrew';
import CrewShareCertificates from '../components/crewVisit/components/CrewShareCertificates/CrewShareCertificates';

export const CrewVisitProfileRoute = {
  path: `${RoutePaths.ProfileSingleCrewPage}/:${RoutesPathNames.id}/*`,
  Component: CrewVisitWrapper,
  children: [
    {
      index: true,
      Component: GetOverviewForCrew,
    },
    {
      path: RoutesPathNames.ProfileBaseSeaServices,
      element: (
        <Suspense fallback={<ServicesListTabSkeleton />}>
          <ServiceList />
        </Suspense>
      ),
    },
    {
      path: RoutesPathNames.ProfileBaseCertificates,
      Component: CrewShareCertificates,
    },
    {
      path: RoutesPathNames.ProfileBaseEducation,
      element: (
        <Suspense fallback={<EducationsTabSkeleton />}>
          <EducationList />
        </Suspense>
      ),
    },
    {
      path: RoutesPathNames.ProfileBaseAppraisals,
      element: (
        <Suspense fallback={<AppraisalsTabSkeleton />}>
          <AppraisalsList />
        </Suspense>
      ),
    },
  ],
};
