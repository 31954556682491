import { useMemo } from 'react';
import { isEmpty } from 'lodash';

const useSortedByEndDate = <T>(arrOfItems: T[], sortedField: (item: T) => String | null) => {
  return useMemo(() => {
    if (isEmpty(arrOfItems)) {
      return null;
    }
    return arrOfItems.sort((a, b) => {
      const valueA = sortedField(a);
      const valueB = sortedField(b);

      const itemA = valueA ? new Date(valueA.toString()).getTime() : new Date().getTime();
      const itemB = valueB ? new Date(valueB.toString()).getTime() : new Date().getTime();
      return itemB - itemA;
    });
  }, [arrOfItems, sortedField]);
};

export default useSortedByEndDate;
