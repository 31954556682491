import React, { useState } from 'react';
import { useIntl } from 'react-intl';
//component
import { Modal } from 'system/Modal';
import { ConfirmModalBody } from 'system/ConfirmModalBody';
//types
import { ThreeDotsPopoverItemProps } from 'app/ProfileEmployer/components/JobDescriptionPage/types';

const ThreeDotsPopoverItem = ({
  children,
  title,
  onClickFunction,
  modalEventFunction,
  confirmModalTitle = { id: 'job_opening_delete_title' },
  confirmModalButtonLabel = { id: 'delete' },
  confirmModalDescription = { id: 'job_opening_delete_description' },
  additionalClasses,
}: ThreeDotsPopoverItemProps) => {
  const intl = useIntl();
  const [openModel, setOpenModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    if (modalEventFunction) return setOpenModal(true);
    if (onClickFunction) onClickFunction(event);
  };

  return (
    <>
      {modalEventFunction && (
        <Modal onClose={() => setOpenModal(false)} show={openModel} tinyModal>
          <ConfirmModalBody
            title={confirmModalTitle}
            textOne={confirmModalDescription}
            onClick={modalEventFunction}
            onCancel={handleCloseModal}
            buttonLabel={confirmModalButtonLabel}
            typeButtonConfirm="red-primary"
          />
        </Modal>
      )}
      <div onClick={handleOnClick} className={`flex pl-5 pt-4 pb-2.5 text-darkBlue cursor-pointer hover:bg-specialGray-005 border-b last:border-b-0 ${additionalClasses}`}>
        {children}
        <span className="ml-2">{intl.formatMessage(title)}</span>
      </div>
    </>
  );
};

export default ThreeDotsPopoverItem;
