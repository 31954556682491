// packages
import { useCallback } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { RoutesPathNames } from 'app/routing';
//generated
import { ServiceFragments$data } from 'schemas/services/__generated__/ServiceFragments.graphql';
//components
import { Modal } from 'system/Modal';
import { SeeMoreItem } from 'system/SeeMoreItem';

const SeaServiceDetailsModalEV = () => {
  const params = useParams();
  const navigate = useNavigate();

  const seaServiceId = params[RoutesPathNames.seaServiceId];

  const seaServiceData: ServiceFragments$data = useOutletContext();

  const handleCloseModal = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Modal onClose={handleCloseModal} show={seaServiceId === seaServiceData?.id} tinyModal childrenClass="mt-0">
      <SeeMoreItem currentService={seaServiceData} />
    </Modal>
  );
};

export default SeaServiceDetailsModalEV;
