import graphql from 'babel-plugin-relay/macro';

export const SERVICE_QUERY = graphql`
  query ServiceQueriesOneQuery($id: ID!) {
    node(id: $id) {
      ...ServiceFragments
    }
  }
`;

export const GET_ALL_DUTIES_QUERY = graphql`
  query ServiceQueriesFormGetAllDutiesQuery($skip: Boolean!) {
    duties @skip(if: $skip) {
      edges {
        id
        name
        alts
        perma
      }
    }
  }
`;

export const GET_ALL_TAGS_QUERY = graphql`
  query ServiceQueriesFormGetAllTagsQuery($substr: String!, $skip: Boolean!) {
    activities(substr: $substr) @skip(if: $skip) {
      edges {
        id
        label
        perma
        timesUsed
      }
    }
  }
`;

export const GET_ONE_DUTY_QUERY = graphql`
  query ServiceQueriesFormGetOneDutyQuery($id: ID!, $skip: Boolean!) {
    node(id: $id) @skip(if: $skip) {
      ... on Duty {
        id
        name
        alts
        perma
      }
    }
  }
`;

export const GET_VESSEL_BY_NAME_QUERY = graphql`
  query ServiceQueriesSearchByQuery($imo: Int, $name: String, $skip: Boolean!) {
    vessels(imo: $imo, name: $name) @skip(if: $skip) {
      edges {
        fleetmonID
        imo
        name
        grossTonnage
        operatorName
        image
        kind {
          id
          name
          perma
        }
        length
        countryName
        cnISO
      }
    }
  }
`;

export const DETAILS_VESSEL = graphql`
  query ServiceQueriesDetailsVesselQuery($vesselID: Int!, $skip: Boolean!) {
    vessel(vesselID: $vesselID) @skip(if: $skip) {
      deadWeight
      grossTonnage
      imageURL
      length
      manager
      name
      owner
      width
      imoNumber
      kind {
        id
        name
        perma
      }
      cnISO
    }
  }
`;

export const GET_VESSELS_KINDS = graphql`
  query ServiceQueriesVesselKindsQuery($skip: Boolean!) {
    vesselKinds @skip(if: $skip) {
      edges {
        id
        name
        perma
      }
    }
  }
`;

export const LIST_OF_SEA_SERVICE_FROM_PROFILE = graphql`
  query ServiceQueriesListOfServicesFromProfileQuery($ownerId: ID!, $after: Cursor, $first: Int, $skip: Boolean!, $orderBy: SeaServiceOrder) {
    seaServices(ownerId: $ownerId, first: $first, after: $after, orderBy: $orderBy) @skip(if: $skip) @connection(key: "ServiceListFromProfile_seaServices") {
      edges {
        node {
          id
          start
          end
          vessel {
            name
          }
          ...ServiceFragments
        }
      }
    }
  }
`;
