import Skeleton from 'react-loading-skeleton';

const AddCrewToPoolInfoSkeleton = () => {
  return (
    <div className="flex items-center w-1/2 gap-y-4 border-r border-r-1 mr-4">
      <Skeleton className="rounded-md" width={146} height={160} />
      <div className="flex gap-x-2 ml-4 font-medium">
        <Skeleton width={60} height={16} className="leading-6" />
        <Skeleton width={80} height={16} className="leading-6" />
      </div>
    </div>
  );
};

export default AddCrewToPoolInfoSkeleton;
