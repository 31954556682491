// packages
import { MouseEvent, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { RadioGroup } from '@headlessui/react';
import { useLocation } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/solid';
// components
import FooterLinkButtonWithBorder from './components/FooterLinkButtonWithBorder';
// models
import { PaymentSubscriptionTierType } from 'models/enums';
// helpers
import { productAnalyticsEvents } from 'app/ProfileEmployer/HireProduct/productHelpers';
// mocks
import { pricing } from './mockData';
// types
import { PricingType } from '../../types';

const PaymentSubscriptionModalContent = ({ onClose }: { onClose: () => void }) => {
  const { state } = useLocation();
  const [frequency, setFrequency] = useState<PricingType['frequencies'][number]>(state?.frequencyForStorybook ?? pricing.frequencies[0]);
  const intl = useIntl();

  const handleClickByFreeTrailButton = (tierId: PaymentSubscriptionTierType) => (event: MouseEvent<HTMLAnchorElement>) => {
    if (tierId !== PaymentSubscriptionTierType.FREE) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();

    onClose();
  };

  const getMessageDescriptionButtonLabel = useCallback((tierId: string) => {
    switch (tierId) {
      case PaymentSubscriptionTierType.FREE:
        return 'subscription_modal_plan_free_trail_button';
      default:
        return 'subscription_modal_plan_buy_button';
    }
  }, []);

  return (
    <section className="flex flex-col gap-y-14">
      <div className="mx-auto px-6 mt-3.5 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h1 className="text-base font-semibold leading-7 text-specialPurple-2">{intl.formatMessage({ id: 'subscription_modal_small_title' })}</h1>
          <p className="mt-2 text-4xl font-bold tracking-tight text-darkBlue sm:text-5xl">{intl.formatMessage({ id: 'subscription_modal_big_title' })}</p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-specialGray-075">{intl.formatMessage({ id: 'subscription_modal_description' })}</p>
        <div className="mt-10 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
            {pricing.frequencies.map(option => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) => `${checked ? 'bg-specialPurple-2 text-white' : 'text-specialGray-075'} cursor-pointer rounded-full px-2.5 py-1`}
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
          {pricing.tiers.map(tier => (
            <div key={tier.id} className={`${tier.mostPopular ? 'ring-2 ring-specialPurple-2' : 'ring-1 ring-gray-200'} rounded-3xl p-8`}>
              <h2 id={tier.id} className={`${tier.mostPopular ? 'text-specialPurple-2' : 'text-darkBlue'} text-lg font-semibold leading-8`}>
                {tier.name}
              </h2>
              <p className="mt-4 text-sm leading-6 text-specialGray-075">{tier.description}</p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-darkBlue">{tier.price[frequency.value]}</span>
                <span className="text-sm font-semibold leading-6 text-specialGray-075">{tier.id !== PaymentSubscriptionTierType.CUSTOM ? frequency.priceSuffix : null}</span>
              </p>
              <a
                href={tier.href}
                onClick={handleClickByFreeTrailButton(tier.id)}
                aria-describedby={tier.id}
                className={`${
                  tier.mostPopular
                    ? 'bg-specialPurple-2 text-white shadow-sm hover:bg-specialPurple-1'
                    : 'text-specialPurple-2 ring-1 ring-inset ring-specialPurple-03 hover:ring-specialPurple-2'
                }
                  mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-specialPurple`}
              >
                {intl.formatMessage({ id: getMessageDescriptionButtonLabel(tier.id) })}
              </a>
              <ul className="mt-8 space-y-3 text-sm leading-6 text-specialGray-075">
                {tier.features.map(feature => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-specialPurple-2" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col -mx-4 w-[calc(100%_+_32px)] sm:-mx-6 sm:w-[calc(100%_+_48px)] -mb-6">
        <div className="flex flex-col sm:flex-row items-center sm:items-baseline sm:justify-between bg-[#FCFCFC] px-10 sm:px-14 xl:px-[152px] py-6 sm:py-11 gap-y-8">
          <div className="flex flex-col justify-center gap-y-3 text-center sm:text-left">
            <span className="text-darkBlue text-[26px] font-semibold tracking-[.25px]">{intl.formatMessage({ id: 'subscription_more_information' })}</span>
            <span className="text-specialGray-075 text-sm max-w-[340px]">{intl.formatMessage({ id: 'subscription_free_information_description' })}</span>
          </div>
          <div className="flex flex-col gap-y-2.5">
            <FooterLinkButtonWithBorder productAnalyticsEvents={productAnalyticsEvents.sendEmail} href="mailto:maurits@crewlinker.com" title={{ id: 'subscription_email_us' }} />
            <FooterLinkButtonWithBorder
              productAnalyticsEvents={productAnalyticsEvents.bookMeeting}
              href={process.env.REACT_APP_SUBSCRIPTION_MEETING_LINK || '#'}
              isBlank
              title={{ id: 'subscription_book_meeting' }}
            />
          </div>
        </div>
        <div className="w-full flex justify-end items-center bg-specialGray-004 py-4 pr-8">
          <button onClick={onClose} className="border border-specialGray-012 rounded-[10px] px-8 py-2.5 text-specialGray-075 bg-white text-sm font-medium">
            {intl.formatMessage({ id: 'cancel' })}
          </button>
        </div>
      </div>
    </section>
  );
};

export default PaymentSubscriptionModalContent;
