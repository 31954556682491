// packages
import { Suspense, useCallback, MouseEvent } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
// routes
import { RoutePaths, tabs } from 'app/routing';
// components
import CustomNavLink from 'system/CustomNavLink/CustomNavLink';
import TabsWrapperLayout from 'system/Layouts/TabsWrapperLayout';
import RenderingTab from 'system/skeletons/profileTabs/RenderingTab';
import MobileProfileTabs from 'system/ProfileTabs/Mobile/MobileProfileTabs';
// generated
import { ProfileFragments_info$data } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';

export const ProfileTabs = ({ profileInfoData }: { profileInfoData: ProfileFragments_info$data }) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const disabledStyles = useCallback(
    (activeTab: string) => {
      return activeTab !== tabs[0].name && !profileInfoData ? 'hover:text-specialGray-075 hover:border-transparent cursor-not-allowed border-none' : '';
    },
    [profileInfoData],
  );

  const handleMoveByLink = useCallback(
    (tabHref: string) => (event: MouseEvent) => {
      event.preventDefault();
      navigate({ pathname: tabHref, search });
    },
    [navigate, search],
  );

  return (
    <>
      <TabsWrapperLayout>
        <>
          {tabs.map((tab, i) => {
            let isDisabledLinkButton = false;
            //just first tab can be clickable
            if (!profileInfoData) isDisabledLinkButton = i > 0;

            return (
              <CustomNavLink
                to={tab.href === RoutePaths.ProfileBaseOverview && pathname === RoutePaths.ProfileBase ? '.' : tab.href}
                key={tab.name}
                disabled={isDisabledLinkButton}
                className={`${disabledStyles(tab.name)} whitespace-nowrap py-4 px-5 text-sm`}
                onClick={handleMoveByLink(tab.href)}
              >
                {tab.name}
              </CustomNavLink>
            );
          })}
        </>
      </TabsWrapperLayout>
      <MobileProfileTabs profileInfoData={profileInfoData} />
      <Suspense fallback={<RenderingTab />}>
        <Outlet />
      </Suspense>
    </>
  );
};
