// packages
import { useEffect, useState, Suspense, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFragment, useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
// routes
import { RoutesPathNames } from 'app/routing';
// components
import { Modal } from 'system/Modal';
import { BasicCvPresence } from 'system/BasicCvPresence';
import ProfileRating from 'system/ProfileReting/ProfileRating';
import { ProfileAvatar } from 'app/Avatars/ProfileAvatar/ProfileAvatar';
import ProfileBasicInfoControlButtons from './components/ProfileBasicInfoControlButtons';
import ProfileBasicSummaryInfo from 'app/Profile/ProfileBasicInfo/components/ProfileBasicSummaryInfo';
// generated
import { ProfileFragments$key } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { CountryFragments_info$key } from 'schemas/country/__generated__/CountryFragments_info.graphql';
import { ProfileQueriesRootQuery } from 'schemas/profile/__generated__/ProfileQueriesRootQuery.graphql';
import { ProfileQueriesRootRefreshQuery } from 'schemas/profile/__generated__/ProfileQueriesRootRefreshQuery.graphql';
import { ProfileFragments_info$data, ProfileFragments_info$key } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
//schemas
import { PROFILE_QUERY } from 'schemas/profile/ProfileQueries';
import { COUNTRY_FRAGMENT } from 'schemas/country/CountryFragments';
import { PROFILE_FRAGMENT, PROFILE_INFO_FRAGMENT } from 'schemas/profile/ProfileFragments';
//types
import { ProfileBasicInfoProps } from './types';
// hooks
import { useAuth } from 'authentication';
import { useToggleShowWindow } from 'hooks/useToggleShowModal';
// wrappers
import WrappersForSubscribes from 'wrappers/WrappersForSubscribes';

export const ProfileBasicInfo = ({ defaultShowCvModal = false, setSelfData }: ProfileBasicInfoProps) => {
  const { identity } = useAuth();
  const navigate = useNavigate();
  const { toggleShowWindow: toggleOpenCvModal, showWindow: showCvModal } = useToggleShowWindow();

  const selfProfileQueryRef = useLazyLoadQuery<ProfileQueriesRootQuery>(PROFILE_QUERY, { id: `${identity?.profileId}`, skip: !identity?.profileId });
  const [selfData] = useRefetchableFragment<ProfileQueriesRootRefreshQuery, ProfileFragments$key>(PROFILE_FRAGMENT, selfProfileQueryRef.node!);

  useEffect(() => {
    if (setSelfData) {
      setSelfData(selfProfileQueryRef.node as ProfileFragments$key);
    }
  }, [setSelfData, selfProfileQueryRef]);

  const profileInfoData = useFragment<ProfileFragments_info$key>(PROFILE_INFO_FRAGMENT, selfData?.info || null);
  const countryData = useFragment<CountryFragments_info$key>(COUNTRY_FRAGMENT, profileInfoData?.country || null);

  const [showMoreTextModal, setShowMoreTextModal] = useState<boolean>(false);

  const openEditModal = useCallback(() => navigate({ pathname: RoutesPathNames.editProfileInfo }, { state: { isNewHire: true } }), [navigate]);

  return (
    <div className="mx-auto md:flex md:items-center md:justify-between">
      <Modal
        onClose={toggleOpenCvModal(false)}
        tinyModal
        show={showCvModal || defaultShowCvModal}
        title={{ id: 'createCV_title' }}
        description={{ id: 'self_basic_modalDescription' }}
      >
        <Suspense>
          <BasicCvPresence onCloseModal={toggleOpenCvModal(false)} profileId={selfData?.id} />
        </Suspense>
      </Modal>

      <Modal onClose={() => setShowMoreTextModal(false)} show={showMoreTextModal} title={{ id: 'profile_about_me' }} description={{ id: 'profile_bio_modal_description' }}>
        <>{profileInfoData?.bio}</>
      </Modal>

      <div className="block md:flex w-full text-darkBlue">
        <div className="flex w-full sm:flex-row flex-col items-center sm:items-start space-y-3 sm:space-y-0">
          <div className="flex sm:mr-6">
            <ProfileAvatar
              size="h-[83px] w-[83px]"
              avatarUrl={profileInfoData?.avatarUrl}
              nameAbbreviation={profileInfoData ? `${profileInfoData?.firstName[0]}${profileInfoData?.lastName[0]}` : ''}
              profile={selfData}
            />
          </div>

          <div className="flex flex-col md:flex-row w-full items-center sm:items-start h-full sm:space-y-3 md:space-y-0">
            <div className="flex flex-col w-full items-center sm:items-start h-full sm:justify-between">
              <section className="flex sm:flex-row flex-col items-center sm:items-start space-y-2 sm:space-y-0">
                {profileInfoData && (
                  <h1 className="text-3xl sm:text-[32px] text-center sm:text-start font-bold sm:mr-5 lg:leading-[48px]">
                    {profileInfoData?.firstName} {profileInfoData?.lastName}
                  </h1>
                )}
                <div className="hidden sm:flex">
                  <ProfileBasicInfoControlButtons
                    profileInfoData={profileInfoData as ProfileFragments_info$data}
                    handleOpenBasicInfoForm={openEditModal}
                    handleOpenCVModal={toggleOpenCvModal(true)}
                  />
                </div>
              </section>
              <ProfileBasicSummaryInfo countryData={countryData} selfData={selfData} profileInfoData={profileInfoData} />
            </div>

            <div className="flex sm:flex-col md:ml-auto sm:mt-0 mt-3 w-full sm:w-auto space-x-2 sm:space-x-0 justify-center sm:justify-start">
              <div className="sm:hidden flex space-x-2 sm:space-x-0">
                <WrappersForSubscribes>
                  <ProfileBasicInfoControlButtons
                    profileInfoData={profileInfoData as ProfileFragments_info$data}
                    handleOpenBasicInfoForm={openEditModal}
                    handleOpenCVModal={toggleOpenCvModal(true)}
                  />
                </WrappersForSubscribes>
              </div>
              <ProfileRating />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
