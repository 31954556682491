// packages
import { useCallback, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useMutation } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// components
import { Button } from 'system/Button';
import Footer from 'auth/SignUpSteps/components/Footer';
import Header from 'auth/SignUpSteps/components/Header';
import GlassDiscoveryPurple from 'icons/GlassDiscoveryPurple';
import StepWithSeaServiceErrorBoundaryForm from './StepWithSeaServiceErrorBoundaryForm';
// types
import { RegisterWithSeaServiceFrom } from 'auth/SignUpSteps/types';
// routing
import { RoutePaths } from 'app/routing';
// formHelpers
import { RegisterWithSeaServiceFormValues } from 'formHelpers/initialValuesOfForms';
import { StepWithSeaServiceFromValidateSchema } from 'formHelpers/validationsOfForms';
// generated
import { ServiceMutationsFormCreateMutation } from 'schemas/services/__generated__/ServiceMutationsFormCreateMutation.graphql';
// schemas
import { CREATE_SERVICE } from 'schemas/services/ServiceMutations';
// models
import { PLATFORM_GROUP } from 'models/enums';

const StepWithSeaServiceForm = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<Error | undefined>();

  const [commitCreate, createLoading] = useMutation<ServiceMutationsFormCreateMutation>(CREATE_SERVICE);

  const formik = useFormik<RegisterWithSeaServiceFrom>({
    onSubmit,
    initialValues: RegisterWithSeaServiceFormValues,
    validationSchema: StepWithSeaServiceFromValidateSchema(),
  });

  async function onSubmit(formData: RegisterWithSeaServiceFrom) {
    const getCurrentUser = await Auth.currentAuthenticatedUser();

    const data = {
      ownerId: getCurrentUser.attributes?.['custom:profile_id'],
      start: !!formData.start ? formData.start.toISOString() : '',
      end: !!formData.end ? formData.end.toISOString() : null,
      rotations: 1,
      dutyId: formData.dutyId !== 'null' ? formData.dutyId : '',
      vesselImo: formData.vesselImo ? +formData.vesselImo : 0,
      vesselName: formData.vesselName,
      vesselKindId: formData.vesselKindId || null,
      vesselOperatorName: formData.vesselOperatorName || undefined,
      vesselGrossTonnage: formData.vesselGrossTonnage ? +formData.vesselGrossTonnage : 0,
      vesselImage: formData.vesselImage || '',
      vesselCnIso: formData.vesselCnIso || '',
      vesselLength: formData.vesselLength || 0,
    };

    commitCreate({
      onError: setError,
      variables: {
        data,
        connections: [],
      },
      onCompleted: response => {
        window.analytics?.track('wui-signup-step-with-enter-most-recent-work-experience', {
          seaServiceId: response.createSeaService.node?.id,
          type_of_account: PLATFORM_GROUP.CREW,
        });

        navigate(RoutePaths.HomePath);
      },
    });
  }

  const handlePressOnSkipButton = useCallback(() => {
    window.analytics?.track('wui-signup-step-with-enter-most-recent-work-experience-press-on-skip-button');
    navigate(RoutePaths.HomePath);
  }, [navigate]);

  return (
    <section className="flex w-full flex-col sm:min-h-full min-h-auto h-auto pt-5 pb-7">
      <Header title={{ id: 'most_recent_sea_service_title' }} description={{ id: 'most_recent_sea_service_descriptor' }} icon={<GlassDiscoveryPurple />} />
      <FormikProvider value={formik}>
        <Form className="flex flex-col mt-7 sm:mt-8 w-full sm:py-8 px-4 sm:border sm:px-10 rounded-[20px] h-full justify-between sm:justify-start">
          <fieldset className="[&>input]:sm:h-[34px] [&_input]:h-12 [&_input]:text-sm mb-[35px]">
            <StepWithSeaServiceErrorBoundaryForm />
          </fieldset>
          <div className="flex flex-col gap-y-4">
            <Footer error={error} pathnameToPrevStep={RoutePaths.AuthRegisterStepFour} isDisabled={createLoading} labelForRightLabel="done" />
            <Button
              type="button"
              buttonType="white-primary"
              label={{ id: 'skip' }}
              onClick={handlePressOnSkipButton}
              additionalClasses=" h-12 rounded-[10px] font-medium"
              fullSize
            />
          </div>
        </Form>
      </FormikProvider>
    </section>
  );
};

export default StepWithSeaServiceForm;
