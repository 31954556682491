import { ConnectionHandler } from 'relay-runtime';
import { ICertificateInfoEdge } from 'models/ICertificate';

export const getConnectionId = (key: string) => ConnectionHandler.getConnectionID('client:root', key);

export const sortingByAlphabeticallyCertificates = (a: ICertificateInfoEdge, b: ICertificateInfoEdge) => {
  if (a.certificate.name < b.certificate.name) {
    return -1;
  }
  if (a.certificate.name > b.certificate.name) {
    return 1;
  }
  return 0;
};

export const customizeToCapitalLetter = (str: string) => `${str.charAt(0)}${str.slice(1).toLowerCase()}`;

export const prepareResponsibilitiesData = (str?: string | null) => {
  if (str) {
    return str?.split('\n').filter(task => task);
  }
  return [];
};

export const charsCounterWithoutDots = (str?: string) => {
  if (str) {
    return str.split('\n').join('').length;
  }
  return 0;
};

export const yearsToMonths = (number: string) => (number ? (+number * 12).toString() : '');
