// packages
import { Suspense } from 'react';
// routes
import { RoutesPathNames } from 'app/routing';
// components
import PrivateRoutes from 'app/PrivateRoutes/PrivateRoutes';
import ListOfJobOpenings from 'app/ListOfJobOpenings/ListOfJobOpenings';
import JobOpeningSlide from 'app/ListOfJobOpenings/components/JobOpeningItem/components/JobOpeningSlide/JobOpeningSlide';
import JobOpeningSlideOverSkeleton from 'system/skeletons/lists/ListOfCrewSkeleton/components/JobOpeningSlideOverSkeleton/JobOpeningSlideOverSkeleton';

export const SearchJobOpeningsRoute = {
  path: `${RoutesPathNames.JobOpenings}/*`,
  element: (
    <PrivateRoutes>
      <ListOfJobOpenings />
    </PrivateRoutes>
  ),
  children: [
    {
      path: `${RoutesPathNames.show}/:${RoutesPathNames.jobOpeningSlideId}`,
      element: (
        <Suspense fallback={<JobOpeningSlideOverSkeleton />}>
          <JobOpeningSlide />
        </Suspense>
      ),
    },
  ],
};
