// routes
import { RoutePaths } from 'app/routing';
// components
import AssumeComponent from 'system/Admin/AssumeProfile/AssumeComponent';
import StopAssumeComponent from 'system/Admin/AssumeProfile/StopAssumeComponent';

export const AdminRoutes = [
  {
    path: RoutePaths.adminAssumeById,
    Component: AssumeComponent,
  },
  {
    path: RoutePaths.adminStopAssume,
    Component: StopAssumeComponent,
  },
];
