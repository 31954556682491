import graphql from 'babel-plugin-relay/macro';

export const CREATE_APPLICATION = graphql`
  mutation ApplicationMutationsCreateApplicationMutation($data: ApplicationData!) {
    createApplication(data: $data) {
      node {
        id
      }
    }
  }
`;

export const REMOVE_APPLICATION = graphql`
  mutation ApplicationMutationsRemoveApplicationMutation($id: ID!) {
    deleteApplication(id: $id) {
      id
    }
  }
`;

export const UPDATE_APPLICATION_STATUS = graphql`
  mutation ApplicationMutationsUpdateStatusMutation($id: ID!, $status: ApplicationStatus!) {
    updateApplicationStatus(id: $id, status: $status) {
      id
      ...ApplicationFragments
    }
  }
`;
