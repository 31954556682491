const SchoolIcon = () => {
  return (
    <svg className="fill-current" width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_10196_79138" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect className="fill-current" width="24" height="24" />
      </mask>
      <g mask="url(#mask0_10196_79138)">
        <path
          className="fill-current"
          d="M21.9992 16.9999C21.7159 16.9999 21.4786 16.9039 21.2872 16.7119C21.0952 16.5206 20.9992 16.2832 20.9992 15.9999V10.0999L12.9742 14.4749C12.8242 14.5582 12.6699 14.6206 12.5112 14.6619C12.3532 14.7039 12.1826 14.7249 11.9992 14.7249C11.8159 14.7249 11.6452 14.7039 11.4872 14.6619C11.3286 14.6206 11.1742 14.5582 11.0242 14.4749L2.59922 9.8749C2.43255 9.79157 2.30355 9.67057 2.21222 9.5119C2.12022 9.3539 2.07422 9.18324 2.07422 8.9999C2.07422 8.81657 2.12022 8.6459 2.21222 8.4879C2.30355 8.32924 2.43255 8.20824 2.59922 8.1249L11.0492 3.5249C11.1992 3.44157 11.3532 3.3789 11.5112 3.3369C11.6699 3.29557 11.8326 3.2749 11.9992 3.2749C12.1659 3.2749 12.3286 3.29557 12.4872 3.3369C12.6452 3.3789 12.7992 3.44157 12.9492 3.5249L22.4742 8.7249C22.6409 8.80824 22.7702 8.9289 22.8622 9.0869C22.9536 9.24557 22.9992 9.41657 22.9992 9.5999V15.9999C22.9992 16.2832 22.9032 16.5206 22.7112 16.7119C22.5199 16.9039 22.2826 16.9999 21.9992 16.9999ZM11.9992 20.7249C11.8326 20.7249 11.6699 20.7042 11.5112 20.6629C11.3532 20.6209 11.1992 20.5582 11.0492 20.4749L6.04922 17.7749C5.71589 17.5916 5.45755 17.3459 5.27422 17.0379C5.09089 16.7292 4.99922 16.3832 4.99922 15.9999V12.1999L10.6742 15.2749C10.8909 15.3916 11.1076 15.4956 11.3242 15.5869C11.5409 15.6789 11.7659 15.7249 11.9992 15.7249C12.2326 15.7249 12.4619 15.6749 12.6872 15.5749C12.9119 15.4749 13.1326 15.3666 13.3492 15.2499L18.9992 12.1999V15.9999C18.9992 16.3832 18.9076 16.7292 18.7242 17.0379C18.5409 17.3459 18.2826 17.5916 17.9492 17.7749L12.9492 20.4749C12.7992 20.5582 12.6452 20.6209 12.4872 20.6629C12.3286 20.7042 12.1659 20.7249 11.9992 20.7249Z"
        />
      </g>
    </svg>
  );
};

export default SchoolIcon;
