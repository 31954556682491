import { useRecoilValue } from 'recoil';
import { useLazyLoadQuery } from 'react-relay';
// generated
import { ServiceQueriesListOfServicesFromProfileQuery } from 'schemas/services/__generated__/ServiceQueriesListOfServicesFromProfileQuery.graphql';
// schemas
import { LIST_OF_SEA_SERVICE_FROM_PROFILE } from 'schemas/services/ServiceQueries';
// types
import { ORDER_BY, ORDER_DIRECTION, RESTRICTED_PROFILE_DATA_COUNTER } from 'models/enums';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';

const useRestrictedCrewProfileSeaServices = (profileId?: string) => {
  const isSelectedOrganization = useRecoilValue(currentOrganisationSelector).isSelected;

  const getLatestSeaServices = useLazyLoadQuery<ServiceQueriesListOfServicesFromProfileQuery>(LIST_OF_SEA_SERVICE_FROM_PROFILE, {
    ownerId: `${profileId}`,
    // skip this query if this is a hire account
    skip: !profileId || isSelectedOrganization,
    orderBy: { direction: ORDER_DIRECTION.DESC, field: ORDER_BY.START_DATE },
    first: RESTRICTED_PROFILE_DATA_COUNTER.SEA_SERVICES,
  }).seaServices?.edges;
  return {
    getLatestSeaServices,
  };
};
export default useRestrictedCrewProfileSeaServices;
