/**
 * @generated SignedSource<<6b7e6085ab92fd2d455c588e1cd9c40e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type AppraisalData = {
  documentId: string;
  ownerId: string;
  review?: string | null;
  seaServiceId: string;
};
export type AppraisalMutationsFormUpdateMutation$variables = {
  data: AppraisalData;
  id: string;
};
export type AppraisalMutationsFormUpdateMutation$data = {
  readonly updateAppraisal: {
    readonly 'id': string;
    readonly ' $fragmentSpreads': FragmentRefs<'AppraisalFragmentsOneAppraisal'>;
  };
};
export type AppraisalMutationsFormUpdateMutation = {
  response: AppraisalMutationsFormUpdateMutation$data;
  variables: AppraisalMutationsFormUpdateMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'data',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v2 = [
      {
        kind: 'Variable',
        name: 'data',
        variableName: 'data',
      },
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'AppraisalMutationsFormUpdateMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'Appraisal',
          kind: 'LinkedField',
          name: 'updateAppraisal',
          plural: false,
          selections: [
            v3 /*: any*/,
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'AppraisalFragmentsOneAppraisal',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'AppraisalMutationsFormUpdateMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'Appraisal',
          kind: 'LinkedField',
          name: 'updateAppraisal',
          plural: false,
          selections: [
            v3 /*: any*/,
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'review',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'Document',
              kind: 'LinkedField',
              name: 'document',
              plural: false,
              selections: [
                v3 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'urls',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'xlarge_urls',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'Profile',
              kind: 'LinkedField',
              name: 'owner',
              plural: false,
              selections: [v3 /*: any*/],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'SeaService',
              kind: 'LinkedField',
              name: 'seaService',
              plural: false,
              selections: [
                v3 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'start',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'end',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Duty',
                  kind: 'LinkedField',
                  name: 'duty',
                  plural: false,
                  selections: [v3 /*: any*/, v4 /*: any*/],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Vessel',
                  kind: 'LinkedField',
                  name: 'vessel',
                  plural: false,
                  selections: [
                    v4 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'image',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'cnISO',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'countryName',
                      storageKey: null,
                    },
                    v3 /*: any*/,
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '776ecd92074ae1f2b9ec8c95044f0b84',
      id: null,
      metadata: {},
      name: 'AppraisalMutationsFormUpdateMutation',
      operationKind: 'mutation',
      text: 'mutation AppraisalMutationsFormUpdateMutation(\n  $id: ID!\n  $data: AppraisalData!\n) {\n  updateAppraisal(id: $id, data: $data) {\n    id\n    ...AppraisalFragmentsOneAppraisal\n  }\n}\n\nfragment AppraisalFragmentsOneAppraisal on Appraisal {\n  id\n  review\n  document {\n    id\n    urls\n    xlarge_urls\n  }\n  owner {\n    id\n  }\n  seaService {\n    id\n    start\n    end\n    duty {\n      id\n      name\n    }\n    vessel {\n      name\n      image\n      cnISO\n      countryName\n      id\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '4a3c8f9923c32c4454fd7747fd7a37e9';

export default node;
