// packages
import { Fragment, useMemo, useState, MouseEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { Menu, Transition } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';
// routing
import { RoutePaths } from 'app/routing';
// components
import CommercialAvatar from 'app/Avatars/CommercialAvatar/CommercialAvatar';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
// hooks
import { useGetHireProfile } from 'hooks/hireProfileHooks';
import { useAuth } from 'authentication';
// recoil
import { personalAccountSelector } from 'recoil/Profile/personalAccount/selectors/personalAccountSelector';
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';
import { ProfileAvatar } from '../../../Avatars/ProfileAvatar/ProfileAvatar';

const OrganizationInvitesMenu = ({ selfAvatarUrl }: { selfAvatarUrl: string }) => {
  const intl = useIntl();
  const { identity } = useAuth();
  const { profileOrganizations } = useGetHireProfile();
  const { state } = useLocation();

  const [openList, setOpenList] = useState<boolean>(state?.openOrganizationsByDefault ?? false);

  const [getCurrentOrganisation, setCurrentOrganisation] = useRecoilState(currentOrganisationSelector);
  const [getIsPersonalAccount, setIsPersonalAccount] = useRecoilState(personalAccountSelector);

  const filteredOrganizations = useMemo(() => profileOrganizations?.filter(organization => organization.role !== 'invited'), [profileOrganizations]);

  const handleSelectCurrentOrganisation = useCallback(
    (id: string) => () => {
      setCurrentOrganisation({ isSelected: true, organisationId: id, profileId: identity?.profileId?.toString() || '' });
      setIsPersonalAccount({ personalAccountIsSelected: false });
    },
    [identity?.profileId, setCurrentOrganisation, setIsPersonalAccount],
  );

  const handleSelectCrewProfile = useCallback(() => {
    setCurrentOrganisation({ isSelected: false, organisationId: '', profileId: '' });
    setIsPersonalAccount({ personalAccountIsSelected: true });
  }, [setCurrentOrganisation, setIsPersonalAccount]);

  if (!filteredOrganizations?.length) {
    return null;
  }

  return (
    <div className="relative">
      <Menu.Button
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          e.stopPropagation();
          setOpenList(prev => !prev);
        }}
        className="relative flex justify-between w-full text-left px-4 py-2 text-sm text-darkBlue hover:bg-specialGray-005"
      >
        {intl.formatMessage({ id: 'label_organizations' })}
        {openList ? <ChevronUpIcon className="text-darkBlue w-4 h-4 self-center" /> : <ChevronDownIcon className="text-darkBlue w-4 h-4 self-center" />}
      </Menu.Button>
      <Transition
        show={openList}
        as={Fragment}
        enter="transition duration-0"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition duration-0"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items static className="relative flex flex-col justify-between w-full py-2 text-sm text-darkBlue bg-specialGray-002">
          {filteredOrganizations?.map(org => {
            return (
              <Menu.Item key={org.id}>
                <Link
                  to={`${RoutePaths.ProfileOrganizationBase}/${org.organization.id}`}
                  onClick={handleSelectCurrentOrganisation(org.organization.id)}
                  className="flex px-4 py-2 hover:bg-specialGray-005 items-center gap-x-1.5 "
                >
                  <div className={`${getCurrentOrganisation.organisationId === org.organization.id ? 'ring-2 ring-blue rounded-md' : ''}`}>
                    <CommercialAvatar size="w-6 h-6" avatarUrl={org.organization.avatarUrl} />
                  </div>
                  <span className="calc(100%_-_48px) truncate">{org.organization.name}</span>
                </Link>
              </Menu.Item>
            );
          })}
          {getCurrentOrganisation.isSelected ? (
            <Menu.Item>
              <Link to={`${RoutePaths.ProfileBase}`} onClick={handleSelectCrewProfile} className="flex px-4 py-2 hover:bg-specialGray-005 items-center gap-x-1.5">
                <div className={`${getIsPersonalAccount.personalAccountIsSelected ? 'ring-2 ring-blue rounded-md' : ''}`}>
                  <ProfileAvatar size="w-6 h-6" avatarUrl={selfAvatarUrl} />
                </div>
                <span className="calc(100%_-_48px) truncate"> {intl.formatMessage({ id: 'navbar_go_to_crew_profile' })}</span>
              </Link>
            </Menu.Item>
          ) : null}
        </Menu.Items>
      </Transition>
    </div>
  );
};

export default OrganizationInvitesMenu;
