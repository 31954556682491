// packages
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// components
import NoJobOpening from 'icons/NoJobOpening';
// routes
import { RoutePaths } from 'app/routing';
import { useNavbarSearchContext } from 'Context/NavbarSearchContext';

const NoJobs = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { setQueryParams } = useNavbarSearchContext();

  const handleClearSearchParams = () => {
    navigate({ pathname: RoutePaths.JobOpenings, search: '' });
    setQueryParams('');
  };
  return (
    <div className="flex flex-col items-center w-full sm:w-[400px] mx-auto mt-20">
      <NoJobOpening />
      <div className="flex flex-col items-center mt-3.5">
        <div className="text-center text-specialGray-1 text-2xl font-semibold">{intl.formatMessage({ id: 'job_opening_empty_page_title' })}</div>
        <span className="text-xs text-specialGray-075 text-center mt-3 w-3/4">{intl.formatMessage({ id: 'job_opening_empty_page_description' })}</span>
        <button onClick={handleClearSearchParams} className="text-blue text-sm font-medium cursor-pointer sm:mt-5 mt-3">
          {intl.formatMessage({ id: 'job_opening_show_all_job_openings' })}
        </button>
      </div>
    </div>
  );
};

export default NoJobs;
