import { FC } from 'react';
//components
import VacancySkeleton from 'system/skeletons/hireProfile/components/VacancySkeleton/VacancySkeleton';
import RightSideListsOfCrewSkeleton from 'system/skeletons/hireProfile/JobDescriptionTabsSkeleton/components/RightSide/RightSideListsOfCrewSkeleton';

const JobDescriptionEditPreviewSkeleton: FC = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="flex w-full max-w-7xl space-x-12 pl-[54px] pr-[65px]">
        <div className="w-3/5">
          <VacancySkeleton />
        </div>
        <RightSideListsOfCrewSkeleton additionalClasses="w-[39%]" />
      </div>
    </div>
  );
};

export default JobDescriptionEditPreviewSkeleton;
