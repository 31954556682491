// packages
import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useFragment, useLazyLoadQuery } from 'react-relay';
// schemas
import { GET_VACANCY } from 'schemas/vacancy/VacancyQueries';
import { VACANCY_FRAGMENT } from 'schemas/vacancy/VacancyFragments';
// components
import DoneIcon from 'icons/DoneIcon';
import { Button } from 'system/Button';
import InviteIcon from 'icons/InviteIcon';
import ProfileListItem from './ProfileListItem';
// models
import { ProfileCommonListItemProps } from 'models/modelsOfComponents';
// generated
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { VacancyQueriesGetVacancyQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetVacancyQuery.graphql';
// hooks
import { useFoundInvite } from 'hooks/useFoundInvite';
import { useCreateInvite, useDeleteInvite } from 'hooks/useInvite';
import { useGetUserAlreadyApplied } from 'hooks/useGetUserAlreadyApplied';

const ProfileInviteListItem: FC<ProfileCommonListItemProps> = ({ profile, ...props }) => {
  const intl = useIntl();
  const { jobDescriptionEditId } = useParams();

  const vacancyFragment = useLazyLoadQuery<VacancyQueriesGetVacancyQuery>(GET_VACANCY, { id: jobDescriptionEditId!, skip: !jobDescriptionEditId });
  const vacancyData = useFragment<VacancyFragments$key>(VACANCY_FRAGMENT, vacancyFragment.node as VacancyFragments$key);

  const foundInvite = useFoundInvite(vacancyData, profile.objectID);
  const { userAlreadyApplied } = useGetUserAlreadyApplied(false, vacancyData, profile.objectID);
  const handleCreateInvite = useCreateInvite(vacancyFragment.node as VacancyFragments$key, profile.objectID);
  const handleRemoveInvite = useDeleteInvite(vacancyFragment.node as VacancyFragments$key, profile.objectID, foundInvite?.id);

  const renderInviteButton = useCallback(() => {
    return (
      <>
        {userAlreadyApplied ? (
          <button className="flex px-3 py-1 border border-green-500 rounded-md text-green-500 text-sm leading-4 font-medium items-center">
            <DoneIcon />
            <span className="ml-2">{intl.formatMessage({ id: 'hire_label_applied' })}</span>
          </button>
        ) : null}
        {!!foundInvite && !userAlreadyApplied ? (
          <button className="flex px-3 py-1 border border-green-500 rounded-md text-green-500 text-sm leading-4 font-medium items-center" onClick={handleRemoveInvite}>
            <DoneIcon />
            <span className="ml-2">{intl.formatMessage({ id: 'hire_job_invited_label' })}</span>
          </button>
        ) : null}
        {!foundInvite && !userAlreadyApplied ? (
          <Button
            buttonType="white-primary"
            type="button"
            prefixIcon={InviteIcon}
            label={{ id: 'search_list_item_invite_button_label' }}
            additionalClasses="space-x-3"
            onClick={handleCreateInvite}
          />
        ) : null}
      </>
    );
  }, [userAlreadyApplied, intl, foundInvite, handleRemoveInvite, handleCreateInvite]);

  return <ProfileListItem profile={profile} inviteButton={renderInviteButton} {...props} />;
};

export default ProfileInviteListItem;
