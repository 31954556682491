// packages
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
// models
import { SlideOverProps } from 'models/modelsOfComponents';

const SlideOver: React.FC<SlideOverProps> = ({ children, title, show, onClose, size = 'max-w-2xl', zIndex = '' }) => {
  const intl = useIntl();

  const escPressed = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (event.code === 'Escape') {
      onClose();
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className={`fixed inset-0 overflow-hidden ${zIndex}`} onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-50"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-50"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 max-w-full flex" onKeyDown={escPressed}>
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-50 sm:duration-70"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-50 sm:duration-70"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className={`relative w-screen ${size}`}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-50"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-50"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-2 right-2 pt-4 pr-2 flex sm:pr-4">
                    <button
                      type="button"
                      className="rounded-md text-specialGray-075 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 bg-white z-10"
                      onClick={onClose}
                    >
                      <span className="sr-only">Close panel</span>
                      <XMarkIcon className="h-6 w-6 text-specialGray-05 hover:text-specialGray-075" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col pt-6 sm:py-6 bg-white shadow-xl overflow-y-auto">
                  {/* title */}
                  <div className="px-4 sm:px-6">{title && <Dialog.Title className="text-lg font-medium text-gray-900">{intl.formatMessage(title)}</Dialog.Title>}</div>
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">
                    <div className="absolute inset-0 px-4 sm:px-6">{children}</div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SlideOver;
