// packages
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useFragment } from 'react-relay';
// components
import CommercialAvatar from 'app/Avatars/CommercialAvatar/CommercialAvatar';
// routing
import { RoutesPathNames } from 'app/routing';
// generated
import { OrganisationFragmentsForSearchFragment$key } from 'schemas/organisations/__generated__/OrganisationFragmentsForSearchFragment.graphql';
// schemas
import { ORGANISATION_FRAGMENT_FOR_SEARCH } from 'schemas/organisations/OrganisationFragments';
// hooks
import { useGetOrganizationSize } from 'hooks/useGetOrganizationSize';

const JobOpeningItem = ({ organisation }: { organisation: OrganisationFragmentsForSearchFragment$key }) => {
  const intl = useIntl();
  const organisationData = useFragment<OrganisationFragmentsForSearchFragment$key>(ORGANISATION_FRAGMENT_FOR_SEARCH, organisation);
  const { selectedOrganizationSize } = useGetOrganizationSize(organisationData?.size || null);

  const handleClickByLink = () => {
    window.analytics?.track('wui-open-organisation-in-side-drawer', {
      vacancyId: organisationData.id,
    });
  };

  return (
    <Link
      to={`/${RoutesPathNames.orgInfo}/${organisationData.id}`}
      className="border border-specialGray-012 rounded-2xl cursor-pointer w-full min-h-[94px] flex h-full p-4 flex-col sm:flex-row gap-4"
      onClick={handleClickByLink}
    >
      <div className="flex gap-4">
        <CommercialAvatar imgMessage={intl.formatMessage({ id: 'avatar_alt' })} size="w-14 h-14" avatarUrl={organisationData?.avatarUrl} />
        <h2 className="block sm:hidden text-specialGray-1 text-lg font-semibold truncate max-w-full sm:max-w-[50%] xl:max-w-[65%]">{organisationData?.name}</h2>
      </div>
      {/* 72px - this is the size of the avatar + the margin between the avatar and the title */}
      <div className="flex flex-col w-[calc(100%_-_72px)] gap-y-2.5">
        <h2 className="hidden sm:block text-specialGray-1 text-lg font-semibold truncate max-w-full sm:max-w-[50%] xl:max-w-[65%]">{organisationData?.name}</h2>
        <div className="flex w-full flex-wrap">
          {/*Number of vacancies*/}
          <div className="flex items-center space-x-1.5 mr-[18px]">
            <p className="text-xs text-specialGray-075">{intl.formatMessage({ id: 'employer_vacancy_status_open' })}</p>
            <span className="text-sm text-darkBlue">{organisationData?.numberOfVacancies}</span>
          </div>
          {/*Company size*/}
          {!!organisationData?.size && (
            <div className="flex items-center space-x-1.5 mr-[18px]">
              <p className="text-xs text-specialGray-075">{intl.formatMessage({ id: 'employer_org_size_label' })}</p>
              <span className="text-sm text-darkBlue">{selectedOrganizationSize?.name}</span>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default JobOpeningItem;
