import { useIntl } from 'react-intl';
//components
import SuggestionIcon from 'icons/SuggestionIcon';
//types
import { CertificateWithoutDocAllFilledProps } from 'app/Profile/Overview/types';

const CertificateWithoutDocAllFilled = ({ title, description }: CertificateWithoutDocAllFilledProps) => {
  const intl = useIntl();
  return (
    <div className="flex w-full flex-col text-darkBlue items-center pt-12">
      <SuggestionIcon />
      <h2 className="font-semibold text-lg mb-1.5 mt-3">{intl.formatMessage(title)}</h2>
      <span className="text-xs text-specialGray-075 sm:max-w-[350px] text-center">{intl.formatMessage(description)}</span>
    </div>
  );
};

export default CertificateWithoutDocAllFilled;
