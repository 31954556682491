/**
 * @generated SignedSource<<29e5c65bae0c6de084bc9421d23c184f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OrderDirection = 'ASC' | 'DESC' | '%future added value';
export type VacancyOrderField = 'CREATED_AT' | 'LAST_INVITED_AT' | 'STATUS' | '%future added value';
export type VacancyStatus = 'CLOSED' | 'OPEN' | '%future added value';
export type FilterModel = {
  field: string;
  values: ReadonlyArray<string>;
};
export type VacancyOrder = {
  direction: OrderDirection;
  field?: VacancyOrderField | null;
};
export type VacancyQueriesListOfVacanciesForOrgMembershipQuery$variables = {
  after?: any | null;
  filters?: ReadonlyArray<FilterModel | null> | null;
  first?: number | null;
  orderBy?: VacancyOrder | null;
  orgId?: string | null;
  skip: boolean;
};
export type VacancyQueriesListOfVacanciesForOrgMembershipQuery$data = {
  readonly vacancies?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly organizationMember: {
          readonly id: string;
          readonly profile: {
            readonly info: {
              readonly firstName: string;
              readonly id: string;
              readonly lastName: string;
            } | null;
          };
        };
        readonly status: VacancyStatus;
        readonly title: string;
      } | null;
    } | null> | null;
  } | null;
};
export type VacancyQueriesListOfVacanciesForOrgMembershipQuery = {
  response: VacancyQueriesListOfVacanciesForOrgMembershipQuery$data;
  variables: VacancyQueriesListOfVacanciesForOrgMembershipQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'after',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'filters',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'first',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'orderBy',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'orgId',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'skip',
    },
    v6 = {
      kind: 'Variable',
      name: 'filters',
      variableName: 'filters',
    },
    v7 = {
      kind: 'Variable',
      name: 'orderBy',
      variableName: 'orderBy',
    },
    v8 = {
      kind: 'Variable',
      name: 'orgId',
      variableName: 'orgId',
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'title',
      storageKey: null,
    },
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'status',
      storageKey: null,
    },
    v12 = {
      alias: null,
      args: null,
      concreteType: 'ProfileInfo',
      kind: 'LinkedField',
      name: 'info',
      plural: false,
      selections: [
        v9 /*: any*/,
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'lastName',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'firstName',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v13 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: '__typename',
      storageKey: null,
    },
    v14 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cursor',
      storageKey: null,
    },
    v15 = {
      alias: null,
      args: null,
      concreteType: 'PageInfo',
      kind: 'LinkedField',
      name: 'pageInfo',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'endCursor',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'hasNextPage',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v16 = [
      {
        kind: 'Variable',
        name: 'after',
        variableName: 'after',
      },
      v6 /*: any*/,
      {
        kind: 'Variable',
        name: 'first',
        variableName: 'first',
      },
      v7 /*: any*/,
      v8 /*: any*/,
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/, v5 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'VacancyQueriesListOfVacanciesForOrgMembershipQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: 'vacancies',
              args: [v6 /*: any*/, v7 /*: any*/, v8 /*: any*/],
              concreteType: 'VacancyConnection',
              kind: 'LinkedField',
              name: '__VacancyListForOrganizationMembership_vacancies_connection',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'VacancyEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Vacancy',
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [
                        v9 /*: any*/,
                        v10 /*: any*/,
                        v11 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: 'OrganizationMember',
                          kind: 'LinkedField',
                          name: 'organizationMember',
                          plural: false,
                          selections: [
                            v9 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              concreteType: 'Profile',
                              kind: 'LinkedField',
                              name: 'profile',
                              plural: false,
                              selections: [v12 /*: any*/],
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                        v13 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    v14 /*: any*/,
                  ],
                  storageKey: null,
                },
                v15 /*: any*/,
              ],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v4 /*: any*/, v0 /*: any*/, v2 /*: any*/, v5 /*: any*/, v1 /*: any*/, v3 /*: any*/],
      kind: 'Operation',
      name: 'VacancyQueriesListOfVacanciesForOrgMembershipQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v16 /*: any*/,
              concreteType: 'VacancyConnection',
              kind: 'LinkedField',
              name: 'vacancies',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'VacancyEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Vacancy',
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [
                        v9 /*: any*/,
                        v10 /*: any*/,
                        v11 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: 'OrganizationMember',
                          kind: 'LinkedField',
                          name: 'organizationMember',
                          plural: false,
                          selections: [
                            v9 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              concreteType: 'Profile',
                              kind: 'LinkedField',
                              name: 'profile',
                              plural: false,
                              selections: [v12 /*: any*/, v9 /*: any*/],
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                        v13 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    v14 /*: any*/,
                  ],
                  storageKey: null,
                },
                v15 /*: any*/,
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: v16 /*: any*/,
              filters: ['orgId', 'filters', 'orderBy'],
              handle: 'connection',
              key: 'VacancyListForOrganizationMembership_vacancies',
              kind: 'LinkedHandle',
              name: 'vacancies',
            },
          ],
        },
      ],
    },
    params: {
      cacheID: '6d9552943fe9ace43b4f9784ac488317',
      id: null,
      metadata: {
        connection: [
          {
            count: 'first',
            cursor: 'after',
            direction: 'forward',
            path: ['vacancies'],
          },
        ],
      },
      name: 'VacancyQueriesListOfVacanciesForOrgMembershipQuery',
      operationKind: 'query',
      text: 'query VacancyQueriesListOfVacanciesForOrgMembershipQuery(\n  $orgId: ID\n  $after: Cursor\n  $first: Int\n  $skip: Boolean!\n  $filters: [FilterModel]\n  $orderBy: VacancyOrder\n) {\n  vacancies(orgId: $orgId, first: $first, after: $after, filters: $filters, orderBy: $orderBy) @skip(if: $skip) {\n    edges {\n      node {\n        id\n        title\n        status\n        organizationMember {\n          id\n          profile {\n            info {\n              id\n              lastName\n              firstName\n            }\n            id\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '4c47f17b6a5c1c0eabbd1015fe59f34b';

export default node;
