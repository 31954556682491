/**
 * @generated SignedSource<<4ce571873759000133fd95f636f80bc7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrganizationMemberRole = 'admin' | 'invited' | 'member' | 'owner' | '%future added value';
import { FragmentRefs } from 'relay-runtime';
export type ProfileFragmentsOrganizationMemberInvitation$data = {
  readonly 'id': string;
  readonly 'organization': {
    readonly avatarUrl: string | null;
    readonly id: string;
    readonly name: string;
  };
  readonly 'profile': {
    readonly id: string;
    readonly info: {
      readonly avatarUrl: string | null;
      readonly firstName: string;
      readonly id: string;
      readonly lastName: string;
    } | null;
  };
  readonly 'role': OrganizationMemberRole;
  readonly ' $fragmentType': 'ProfileFragmentsOrganizationMemberInvitation';
};
export type ProfileFragmentsOrganizationMemberInvitation$key = {
  readonly ' $data'?: ProfileFragmentsOrganizationMemberInvitation$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsOrganizationMemberInvitation'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'avatarUrl',
      storageKey: null,
    };
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'ProfileFragmentsOrganizationMemberInvitation',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'role',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Organization',
        kind: 'LinkedField',
        name: 'organization',
        plural: false,
        selections: [
          v0 /*: any*/,
          v1 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'name',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Profile',
        kind: 'LinkedField',
        name: 'profile',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'ProfileInfo',
            kind: 'LinkedField',
            name: 'info',
            plural: false,
            selections: [
              v0 /*: any*/,
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'firstName',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'lastName',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
    type: 'OrganizationMember',
    abstractKey: null,
  };
})();

(node as any).hash = 'e738cfec65098bef1ee1f31b9d335f6a';

export default node;
