import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
// packages
import { useSearchParams } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
// enums
import { DASHBOARD_VACANCIES_FILTER_ENUM, ORDER_DIRECTION, VACANCY_ORDER_FIELD, VACANCY_STATUS } from 'models/enums';
// recoil
import { fetchDashboardVacanciesByParamsAtom } from 'recoil/Organisation/Dashboard/atoms/fetchDashboardVacanciesByParamsAtom';

const SortingArrows = ({
  fieldOrderBy,
  tabWithDefaultDownArrow,
}: {
  fieldOrderBy: VACANCY_ORDER_FIELD;
  tabWithDefaultDownArrow: VACANCY_STATUS | DASHBOARD_VACANCIES_FILTER_ENUM;
}) => {
  const [searchParams] = useSearchParams();
  const getFetchDashboardVacanciesByParams = useRecoilValue(fetchDashboardVacanciesByParamsAtom);

  const handleDefaultSetArrowDown = useMemo(() => {
    const isEmptySearch = isEmpty(Object.fromEntries([...searchParams]));
    return tabWithDefaultDownArrow === getFetchDashboardVacanciesByParams.filterByStatus && isEmptySearch;
  }, [getFetchDashboardVacanciesByParams.filterByStatus, searchParams, tabWithDefaultDownArrow]);

  const fieldOrderByLowercase = searchParams.get(fieldOrderBy.toLowerCase());
  return (
    <div className="absolute right-1 -translate-y-1/2 top-1/2 [&>svg]:text-specialGray-05">
      {fieldOrderByLowercase || handleDefaultSetArrowDown ? (
        <>{fieldOrderByLowercase === ORDER_DIRECTION.ASC.toLowerCase() ? <ChevronUpIcon className="w-2.5 h-2.5" /> : <ChevronDownIcon className="w-2.5 h-2.5" />}</>
      ) : (
        <>
          <ChevronUpIcon className="w-2.5 h-2.5" />
          <ChevronDownIcon className="w-2.5 h-2.5" />
        </>
      )}
    </div>
  );
};

export default SortingArrows;
