import graphql from 'babel-plugin-relay/macro';

export const APPLICATION_FRAGMENT = graphql`
  fragment ApplicationFragments on Application @refetchable(queryName: "ApplicationQueriesGetApplicationRefreshQuery") {
    id
    createdAt
    vacancy {
      id
      hires {
        id
        owner {
          id
        }
      }
    }
    owner {
      id
      ...ProfileFragmentsCrewProfile
    }
    status
  }
`;
