// packages
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useRecoilState } from 'recoil';
import { useLazyLoadQuery } from 'react-relay';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
// hooks
import { useSearchJobOpeningFilterQuery } from 'hooks/searchFilter/useSearchJobOpeningFilterQuery';
// routes
import { RoutesPathNames } from 'app/routing';
// components
import SlideOver from 'system/SlideOver';
import NoJobs from './components/NoJobg/NoJobs';
import JobOpeningComponent from './components/JobOpeningComponent/JobOpeningComponent';
import LoadMoreJobOpeningsButton from 'system/LoadMoreJobOpeningsButton/LoadMoreJobOpeningsButton';
// schemas
import { SEARCH_VACANCIES_QUERY } from 'schemas/search/SearchQueries';
import { GET_LIST_OF_VACANCIES_BY_IDS } from 'schemas/vacancy/VacancyQueries';
// enums
import { SEARCH_INDEX, VACANCY_STATUS } from 'models/enums';
// generated
import { SearchQueriesVacanciesQuery } from 'schemas/search/__generated__/SearchQueriesVacanciesQuery.graphql';
import { VacancyFragmentsForSearchFragment$key } from 'schemas/vacancy/__generated__/VacancyFragmentsForSearchFragment.graphql';
import { VacancyQueriesGetListOfVacanciesByIdsQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetListOfVacanciesByIdsQuery.graphql';
// recoil
import { fetchNextOrganisationJobOpeningsSelector } from 'recoil/searchCounter/selectors/searchCountersSelector';

const ListOfVacancies = ({ setAmountVacancies }: { setAmountVacancies: Dispatch<SetStateAction<number>> }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { search, state } = useLocation();

  const { filters } = useSearchJobOpeningFilterQuery();

  const [getOrganisationJobOpeningsCounter, setOrganisationJobOpeningsCounter] = useRecoilState(fetchNextOrganisationJobOpeningsSelector);

  const vacancies = useLazyLoadQuery<SearchQueriesVacanciesQuery>(SEARCH_VACANCIES_QUERY, {
    first: getOrganisationJobOpeningsCounter.count,
    query: '',
    index: SEARCH_INDEX.VACANCY,
    skip: false,
    filters: `status: ${VACANCY_STATUS.OPEN} AND organization: ${params?.orgId}${!!filters ? ` AND ${filters}` : ''}`,
  });

  const listOfVacancies = useLazyLoadQuery<VacancyQueriesGetListOfVacanciesByIdsQuery>(GET_LIST_OF_VACANCIES_BY_IDS, {
    ids: isEmpty(vacancies) ? [] : (vacancies.search?.edges?.map(vacancy => vacancy?.node?.objectID) as Array<string>),
  }).nodes;

  useEffect(() => {
    setAmountVacancies(vacancies.search?.totalCount as number);
  }, [vacancies.search?.totalCount, setAmountVacancies]);

  const handleCloseSlide = () => {
    navigate({ pathname: `/${RoutesPathNames.orgInfo}/${params?.orgId}`, search }, { state });
  };

  const handleIncreaseNumberOfItems = useCallback(() => {
    setOrganisationJobOpeningsCounter({ count: 4 });
  }, [setOrganisationJobOpeningsCounter]);

  if (isEmpty(listOfVacancies)) {
    return <NoJobs />;
  }

  return (
    <>
      <SlideOver show={!!params[RoutesPathNames.jobOpeningSlideId]} onClose={handleCloseSlide}>
        <Outlet />
      </SlideOver>
      <div className="flex flex-col gap-y-6">
        {listOfVacancies?.map(vacancy => (vacancy ? <JobOpeningComponent key={vacancy?.id} vacancy={vacancy as VacancyFragmentsForSearchFragment$key} /> : null))}
        {vacancies.search?.pageInfo.hasNextPage && (
          <LoadMoreJobOpeningsButton totalNumberOfJobOpenings={vacancies.search.edges?.length || 0} handleIncreaseNumberOfItems={handleIncreaseNumberOfItems} />
        )}
      </div>
    </>
  );
};

export default ListOfVacancies;
