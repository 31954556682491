// packages
import { useLazyLoadQuery } from 'react-relay';
import { useSearchParams } from 'react-router-dom';
// models
import { FILTER_TYPE } from 'models/enums';
// schemas
import { GET_HIRING_AREA_LIST } from 'schemas/vacancy/VacancyQueries';
import { GET_ALL_DUTIES_QUERY, GET_VESSELS_KINDS } from 'schemas/services/ServiceQueries';
// generated
import { ServiceQueriesVesselKindsQuery } from 'schemas/services/__generated__/ServiceQueriesVesselKindsQuery.graphql';
import { ServiceQueriesFormGetAllDutiesQuery } from 'schemas/services/__generated__/ServiceQueriesFormGetAllDutiesQuery.graphql';
import { VacancyQueriesGetHiringAreaListQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetHiringAreaListQuery.graphql';

const useJobOpeningsFiltersQuery = (isSkippedRequest = true) => {
  const [searchParams] = useSearchParams();

  const allDuties = useLazyLoadQuery<ServiceQueriesFormGetAllDutiesQuery>(GET_ALL_DUTIES_QUERY, {
    skip: isSkippedRequest ? !isSkippedRequest : !searchParams.get(FILTER_TYPE.DUTIES),
  }).duties?.edges;

  const vesselsType = useLazyLoadQuery<ServiceQueriesVesselKindsQuery>(GET_VESSELS_KINDS, {
    skip: isSkippedRequest ? !isSkippedRequest : !searchParams.get(FILTER_TYPE.VESSEL_TYPES),
  })?.vesselKinds?.edges;

  const listOfHiringArea = useLazyLoadQuery<VacancyQueriesGetHiringAreaListQuery>(GET_HIRING_AREA_LIST, {
    skip: isSkippedRequest ? !isSkippedRequest : !searchParams.get(FILTER_TYPE.HIRING_AREA),
  }).hiringAreas?.edges;

  return {
    allDuties,
    vesselsType,
    listOfHiringArea,
  };
};

export default useJobOpeningsFiltersQuery;
