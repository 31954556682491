// packages
import { useIntl } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
// components
import { Button } from 'system/Button';
import LockGrayIcon from 'icons/LockGrayIcon';
// types
import { PopoverContentProps } from '../types';
// recoil
import { RestrictedFromPublishJobAtom } from 'recoil/RestrictedFromPublishJob/atoms/RestrictedFromPublishJobAtom';
import { paymentSubscriptionModalAtom } from 'recoil/ContactInformation/atoms/paymentSubscriptionModalAtom';

const PopoverContent = ({ onClick, popoverDescription }: PopoverContentProps) => {
  const intl = useIntl();

  const isRestrictedFromPublishClosedJob = useRecoilValue(RestrictedFromPublishJobAtom);
  const setShowPaymentSubscriptionModalAtom = useSetRecoilState(paymentSubscriptionModalAtom);

  const handleClickByPublishButton = () => {
    if (isRestrictedFromPublishClosedJob) {
      window.analytics?.track('wui-job-opening-page-show-upgrade-to-pro-modal');
      setShowPaymentSubscriptionModalAtom({ showPaymentSubscriptionModal: true });
      return;
    }
    onClick();
  };

  return (
    <div className="flex px-4 py-1 items-center cursor-default">
      <LockGrayIcon />
      <div className="flex flex-col ml-3.5 mr-8 text-sm text-darkBlue whitespace-nowrap">
        <span className="font-semibold">{intl.formatMessage({ id: 'hire_job_tabs_popover_content_title' })}</span>
        <span className="max-w-[320px] whitespace-pre-wrap 2xl:max-w-full 2xl:whitespace-nowrap">{intl.formatMessage(popoverDescription)}</span>
      </div>
      <Button
        additionalClasses={isRestrictedFromPublishClosedJob ? 'text-specialPurple' : ''}
        onClick={handleClickByPublishButton}
        type="button"
        label={{ id: 'publish' }}
        buttonType="white-secondary"
      />
    </div>
  );
};

export default PopoverContent;
