import React from 'react';

interface ToggleSVGIcon {
  isActive: boolean;
}

export const OneTermToggle: React.FC<ToggleSVGIcon> = ({ isActive = false }) => {
  return (
    <svg className={`fill-current ${isActive ? 'text-darkBlue' : 'text-specialGray-03'}`} width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6019_52644)">
        <path d="M25.917 32.7846L27.5837 35.6846C25.9837 36.418 24.2337 36.9013 22.417 37.0846V33.718C23.6503 33.568 24.817 33.2513 25.917 32.7846ZM7.53366 22.168H4.16699C4.35033 24.0013 4.83366 25.7346 5.56699 27.3346L8.46699 25.668C8.00033 24.568 7.68366 23.4013 7.53366 22.168ZM25.917 8.21797L27.5837 5.31797C25.9837 4.58464 24.2503 4.1013 22.417 3.91797V7.28464C23.6503 7.43464 24.817 7.7513 25.917 8.21797ZM33.967 18.8346H37.3337C37.1503 17.0013 36.667 15.268 35.9337 13.668L33.0337 15.3346C33.5003 16.4346 33.817 17.6013 33.967 18.8346ZM15.5837 32.7846L13.917 35.6846C15.517 36.418 17.267 36.9013 19.0837 37.0846V33.718C17.8503 33.568 16.6837 33.2513 15.5837 32.7846ZM19.0837 7.28464V3.91797C17.2503 4.1013 15.517 4.58464 13.917 5.31797L15.5837 8.21797C16.6837 7.7513 17.8503 7.43464 19.0837 7.28464ZM31.3503 12.4513L34.2503 10.768C33.2003 9.31797 31.917 8.03464 30.467 6.98464L28.7837 9.88464C29.767 10.6346 30.617 11.4846 31.3503 12.4513ZM8.46699 15.3346L5.56699 13.668C4.83366 15.268 4.35033 17.0013 4.16699 18.8346H7.53366C7.68366 17.6013 8.00033 16.4346 8.46699 15.3346ZM33.967 22.168C33.817 23.4013 33.5003 24.568 33.0337 25.668L35.9337 27.3346C36.667 25.7346 37.1503 23.9846 37.3337 22.168H33.967ZM28.8003 31.1013L30.4837 34.0013C31.9337 32.9513 33.217 31.668 34.267 30.218L31.367 28.5346C30.617 29.518 29.767 30.368 28.8003 31.1013ZM12.7003 9.9013L11.0337 6.98464C9.56699 8.0513 8.30033 9.31797 7.25033 10.7846L10.1503 12.468C10.8837 11.4846 11.7337 10.6346 12.7003 9.9013ZM10.1503 28.5513L7.25033 30.218C8.30033 31.668 9.58366 32.9513 11.0337 34.0013L12.717 31.1013C11.7337 30.368 10.8837 29.518 10.1503 28.5513ZM20.7503 12.168C19.8337 12.168 19.0837 12.918 19.0837 13.8346V19.818C19.0837 20.7013 19.4337 21.5513 20.067 22.168L25.067 27.168C25.717 27.818 26.767 27.818 27.417 27.168C28.067 26.518 28.067 25.468 27.417 24.818L22.417 19.818V13.8346C22.417 12.918 21.667 12.168 20.7503 12.168Z" />
      </g>
      <defs>
        <clipPath id="clip0_6019_52644">
          <rect width="40" height="40" fill="white" transform="translate(0.75 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const TemporaryToggle: React.FC<ToggleSVGIcon> = ({ isActive = false }) => {
  return (
    <svg className={`fill-current ${isActive ? 'text-darkBlue' : 'text-specialGray-03'}`} width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6019_52655)">
        <path d="M20.7335 3.83398C11.5335 3.83398 4.0835 11.3007 4.0835 20.5007C4.0835 29.7007 11.5335 37.1673 20.7335 37.1673C29.9502 37.1673 37.4168 29.7007 37.4168 20.5007C37.4168 11.3007 29.9502 3.83398 20.7335 3.83398ZM20.7502 33.834C13.3835 33.834 7.41683 27.8673 7.41683 20.5007C7.41683 13.134 13.3835 7.16732 20.7502 7.16732C28.1168 7.16732 34.0835 13.134 34.0835 20.5007C34.0835 27.8673 28.1168 33.834 20.7502 33.834ZM20.3835 12.1673H20.2835C19.6168 12.1673 19.0835 12.7007 19.0835 13.3673V21.234C19.0835 21.8173 19.3835 22.3673 19.9002 22.6673L26.8168 26.8173C27.3835 27.1507 28.1168 26.984 28.4502 26.4173C28.8002 25.8507 28.6168 25.1007 28.0335 24.7673L21.5835 20.934V13.3673C21.5835 12.7007 21.0502 12.1673 20.3835 12.1673Z" />
      </g>
      <defs>
        <clipPath id="clip0_6019_52655">
          <rect width="40" height="40" fill="white" transform="translate(0.75 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PermanentToggle: React.FC<ToggleSVGIcon> = ({ isActive = false }) => {
  return (
    <svg className={`fill-current ${isActive ? 'text-darkBlue' : 'text-specialGray-03'}`} width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6019_52665)">
        <path d="M22.8336 5.49997C14.3503 5.26664 7.40028 12.0666 7.40028 20.5H4.41695C3.66695 20.5 3.30028 21.4 3.83361 21.9166L8.48361 26.5833C8.81695 26.9166 9.33361 26.9166 9.66695 26.5833L14.3169 21.9166C14.8503 21.4 14.4669 20.5 13.7336 20.5H10.7336C10.7336 14 16.0336 8.74997 22.5669 8.8333C28.7669 8.91664 33.9836 14.1333 34.0669 20.3333C34.1503 26.85 28.9003 32.1666 22.4003 32.1666C19.7169 32.1666 17.2336 31.25 15.2669 29.7C14.6003 29.1833 13.6669 29.2333 13.0669 29.8333C12.3669 30.55 12.4169 31.7166 13.2003 32.3333C15.7336 34.3166 18.9336 35.5 22.4003 35.5C30.8169 35.5 37.6336 28.55 37.4003 20.0666C37.1836 12.25 30.6503 5.71664 22.8336 5.49997ZM21.9836 13.8333C21.3003 13.8333 20.7336 14.4 20.7336 15.0833V21.2166C20.7336 21.8 21.0503 22.35 21.5503 22.65L26.7503 25.7333C27.3503 26.0833 28.1169 25.8833 28.4669 25.3C28.8169 24.7 28.6169 23.9333 28.0336 23.5833L23.2336 20.7333V15.0666C23.2336 14.4 22.6836 13.8333 21.9836 13.8333Z" />
      </g>
      <defs>
        <clipPath id="clip0_6019_52665">
          <rect width="40" height="40" fill="white" transform="translate(0.75 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
