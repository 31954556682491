import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSkeletonContext } from 'Context/StorybookContext';
import { useBreakpoints } from 'hooks/useBreakpoints';

const AppraisalsListItem: React.FC = () => {
  const { sm } = useBreakpoints();
  const { isSelfProfile } = useSkeletonContext();

  return (
    <div className="flex-col sm:flex-row mt-5 rounded-2xl border bg-white flex py-3 pl-4 pr-5">
      <div className="w-auto md:w-28 text-center">
        <Skeleton width={80} />
        <Skeleton width={80} className="mt-2 mb-3" />
      </div>
      <div className="flex flex-col sm:w-1/3 sm:mr-5 mb-3 sm:mb-0">
        <Skeleton width={130} />
        <Skeleton width={200} height={12} />
        <Skeleton width={170} height={12} />
      </div>
      <div className="flex flex-col w-10% mr-6">
        {isSelfProfile && (
          <>
            <Skeleton width={100} className="mb-2" />
            <Skeleton width={28} height={28} />
          </>
        )}
      </div>
      <div className="flex sm:w-1/3 w-full flex-col sm:flex-row">
        <Skeleton width={sm ? 222 : 112} height={sm ? 147 : 64} />
        <div className="flex flex-col md:ml-5 justify-center mt-4 sm:mt-0">
          <Skeleton width={110} />
          <div className="flex">
            <Skeleton width={24} height={16} className="mr-3" />
            <Skeleton width={160} />
          </div>
        </div>
      </div>
      {isSelfProfile && (
        <div className="flex items-center sm:ml-auto mx-auto mt-4 sm:mt-0">
          <Skeleton width={50} height={34} className="mr-5" borderRadius="0.75rem" />
          <Skeleton width={42} height={34} borderRadius="0.75rem" />
        </div>
      )}
    </div>
  );
};

export default AppraisalsListItem;
