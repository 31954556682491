// packages
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/solid';
// types
import { typeOfRegisterAccount } from '../types';
// components
import BusinessCenterIcon from 'icons/BusinessCenterIcon';
import PickCrewAccountIcon from 'icons/PickCrewAccountIcon';

const ChoiceTypeOfAccountElement = ({ id }: { id: typeOfRegisterAccount }) => {
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();

  const getIsEqualChoiceType = useMemo(() => searchParams.get('default_selection') === id, [id, searchParams]);

  const getClasses = useMemo(() => {
    if (getIsEqualChoiceType) {
      return 'border-[#2A6FF5]/50 bg-[#2A6FF5]/[0.06]';
    }
    return 'border-[#DADEE5] bg-specialGray-002';
  }, [getIsEqualChoiceType]);

  const handleClickByElement = useCallback(() => {
    setSearchParams({ default_selection: id });
  }, [id, setSearchParams]);

  return (
    <div
      data-test-id={id}
      className={`flex flex-col border border-specialGray-012 p-4 rounded-[14px] cursor-pointer items-center px-2.5 pb-6 text-xs sm:text-base w-1/2 h-auto ${getClasses}`}
      onClick={handleClickByElement}
    >
      <div className={`${getIsEqualChoiceType ? 'bg-[#2A6FF5]' : 'bg-specialGray-012'} rounded-full w-[27px] h-[27px] self-end p-1`}>
        {getIsEqualChoiceType ? <CheckIcon className="text-white" /> : null}
      </div>
      <span className={`text-[38px] ${getIsEqualChoiceType ? 'text-blue' : 'text-specialGray-05'}`}>{id === 'crew' ? <PickCrewAccountIcon /> : <BusinessCenterIcon />}</span>
      <span className="text-darkBlue font-medium">{intl.formatMessage({ id: id === 'crew' ? 'pick_crew_profile_type_title' : 'pick_hire_profile_type_title' })}</span>
      <span className="text-specialGray-075">{intl.formatMessage({ id: id === 'crew' ? 'pick_crew_profile_type_description' : 'pick_hire_profile_type_description' })}</span>
    </div>
  );
};

export default ChoiceTypeOfAccountElement;
