import graphql from 'babel-plugin-relay/macro';

export const GET_EDUCATION_KIND = graphql`
  query EducationQueriesKindsQuery($skip: Boolean!) {
    educationKinds @skip(if: $skip) {
      edges {
        id
        name
        perma
      }
    }
  }
`;

export const LIST_OF_EDUCATIONS_FROM_PROFILE = graphql`
  query EducationQueriesListOfEducationsFromProfileQuery($ownerId: ID!, $after: Cursor, $first: Int, $skip: Boolean!, $orderBy: EducationOrder) {
    educations(ownerId: $ownerId, first: $first, after: $after, orderBy: $orderBy) @connection(key: "EducationsListFromProfile_educations") @skip(if: $skip) {
      edges {
        node {
          id
          end
          ...EducationFragments
        }
      }
    }
  }
`;
