// packages
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useFragment } from 'react-relay';
// routing
import { RoutePaths } from 'app/routing';
// components
import LocationIcon from 'icons/LocationIcon';
// generation
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
// schemas
import { VACANCY_FRAGMENT } from 'schemas/vacancy/VacancyFragments';

const JobOpeningItem = ({ vacancy }: { vacancy: VacancyFragments$key }) => {
  const intl = useIntl();
  const vacancyData = useFragment<VacancyFragments$key>(VACANCY_FRAGMENT, vacancy);
  return (
    <Link to={`${RoutePaths.ProfileBase}/job/${vacancyData.id}`} className="flex flex-col py-2.5 px-6 hover:bg-blue-50 space-y-1.5">
      <span className="text-darkBlue sm:text-lg font-medium overflow-hidden truncate">{vacancyData?.title}</span>
      <div className="flex space-x-6">
        {vacancyData?.hiringArea && (
          <div className="flex space-x-1.5 items-center">
            <LocationIcon size="w-3 h-3" />
            <span className="text-specialGray-075 text-sm">{vacancyData?.hiringArea.name}</span>
          </div>
        )}
        <div className="space-x-1.5 text-sm">
          <span className="text-specialGray-075">
            {vacancyData?.currency ? vacancyData?.currency.name : intl.formatMessage({ id: 'usd' })}&nbsp;&#8725;&nbsp;{vacancyData?.salaryPeriod?.toLocaleLowerCase()}
          </span>
          <span className="mr-2">
            {vacancyData?.salaryFrom || ''}
            {vacancyData?.salaryTo ? `\u00A0\u2212\u00A0${vacancyData?.salaryTo}` : null}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default JobOpeningItem;
