// packages
import { Uppy } from '@uppy/core';
import Cropper from 'react-easy-crop';
import React, { useState } from 'react';
import Transloadit from '@uppy/transloadit';
import { Point, Area } from 'react-easy-crop/types';
// components
import { Button } from 'system/Button';
import AnimateSpin from 'icons/AnimateSpin';
import AlertForError from 'system/Alert/AlertForError';
// hooks
import { useAuth } from 'authentication';
// models
import { AvatarUploadCropperProps } from 'models/modelsOfComponents';

// AvatarUploadCropperProps describes the properties for the cropper modal

// AvatarUploadCropper describes a modal that allows for cropping an image before uploading it to
// transloadit for cropping, scaling and storing of the image. It also clears the browser cache so
// the image is refreshed
export const AvatarUploadCropper: React.FC<AvatarUploadCropperProps> = ({
  onUploaded = () => {},
  onUrlReady = () => {},
  onClose,
  file,
  preview,
  defaultError,
  defaultProgress,
}) => {
  const { identity } = useAuth();
  const [progress, setProgress] = useState(defaultProgress);
  // const [progressValue, setProgressValue] = useState<number>(0);
  // uppy is used to upload files and the transloadit plugin takes care of
  // resizing and cropping.
  const [error, setError] = useState<Error | undefined>(defaultError);
  const [progressPersentage, setProgressPersentage] = useState<number>(0);

  // second version of uppy supports fields update only from params so we save
  // states here for cropper transloadit to slice image
  const [CROP_X1, setCROP_X1] = useState<number>(0);
  const [CROP_X2, setCROP_X2] = useState<number>(0);
  const [CROP_Y1, setCROP_Y1] = useState<number>(0);
  const [CROP_Y2, setCROP_Y2] = useState<number>(0);
  const [USER_ID, setUSER_ID] = useState<number>(0);

  const uppy = new Uppy({
    meta: { type: 'avatar' },
    restrictions: { maxNumberOfFiles: 1 },
    autoProceed: true,
  });

  uppy.use(Transloadit, {
    params: {
      auth: { key: process.env.REACT_APP_TRANSLOADIT_KEY || '00000000000000000000000000000000' },
      template_id: process.env.REACT_APP_TRANSLOADIT_TEMPLATE_ID,
      fields: {
        CROP_X1,
        CROP_X2,
        CROP_Y1,
        CROP_Y2,
        USER_ID,
      },
    },
    waitForEncoding: true,
    waitForMetadata: true,
    limit: 1,
  });

  uppy
    .on('upload-progress', async (_, progress) => {
      const progressPersentage = Math.round((100 * progress.bytesUploaded) / progress.bytesTotal);
      if (progressPersentage < 99) {
        setProgressPersentage(progressPersentage);
      } else {
        setProgressPersentage(98);
      }
    })
    .on('upload-success', async () => {
      setProgressPersentage(99);
    })
    .on('complete', async res => {
      // @ts-ignore types fail because we have the transloadit plugin
      const url = res?.transloadit && res?.transloadit?.[0] ? res?.transloadit?.[0]?.results?.thumbnail_xs?.[0]?.ssl_url : null;

      if (url) {
        // There we check if url for development or for production to add "https" to url link, because image should be loaded over https.
        onUrlReady(url.includes('uploads.crewlinker.com') ? url.replace('s3.eu-west-1.amazonaws.com', '') : url);
        const cache = await caches.open('v1');
        await cache.delete(url);
      }
    })
    .on('upload-error', (_, err) => {
      setError(err);
    });

  // the cropper requires us to keep state so that is what we do. Unfortunately typescript definitions are off
  // for the Transloadit plugin
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = (_: any, capx: Area) => {
    setCROP_X1(capx.x);
    setCROP_X2(capx.x + capx.width);
    setCROP_Y1(capx.y);
    setCROP_Y2(capx.y + capx.height);
    identity?.profileId && setUSER_ID(identity.profileId);
  };

  // Upload the actual file, we use await here for consistency but the library doesn't seem to be made for
  // it since but the result from it doesn't contain our actual result and errors don't cause exceptions
  // we can catch.
  async function upload() {
    const fid = uppy.addFile({
      name: 'avatar',
      data: file,
    });

    setProgress(true);

    const res = await uppy.upload();
    uppy.removeFile(fid);
    setProgressPersentage(100);
    setTimeout(() => setProgress(false), 250);

    // onUploaded needs to be called after the async or things might get
    // dismounted and cleaned up before it finishes
    if (res.failed.length === 0) {
      setProgressPersentage(100);
      setTimeout(() => onUploaded(), 250);
    }
  }

  return (
    <>
      {error && <AlertForError heading={{ id: 'avatar_cropper_failed_upload' }} error={error} />}
      <div className="relative h-96 mb-20 mt-1">
        <Cropper
          style={{
            containerStyle: { borderRadius: '1rem' },
            cropAreaStyle: { boxShadow: '0 0 0 9999em rgba(8, 32, 91, .5)', borderRadius: '1rem' },
          }}
          showGrid={false}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          image={preview}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropComplete={onCropComplete}
        />
      </div>
      <div className="flex flex-row justify-end absolute w-full py-4 px-6 left-0 bottom-0 bg-specialGray-002">
        <Button disabled={progress} buttonType={'white-primary'} additionalClasses="mr-5" onClick={onClose} label={{ id: 'cancel' }} />
        {progress && (
          <div className="flex items-center bg-blue-600 rounded-l-md opacity-50">
            <AnimateSpin />
            <div className="pl-1 text-white text-sm leading-4">{progressPersentage}%</div>
          </div>
        )}
        <Button additionalClasses={`${progress ? 'rounded-l-none' : ''}`} disabled={progress} onClick={upload} label={{ id: 'avatar_cropper_confirm_cta' }} />
      </div>
    </>
  );
};
