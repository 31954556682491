// packages
import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Form, FormikProvider, useFormik } from 'formik';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
// components
import DoneIcon from 'icons/DoneIcon';
import { InputGroup } from 'system/InputGroups/InputGroup';
import CustomButton from 'system/Buttons/CustomButton';
import AlertForError from 'system/Alert/AlertForError';
// models
import { IChangePasswordForm } from 'models/modelsOfForms';
// formHelpers
import { ChangePasswordFormInitialValues } from 'formHelpers/initialValuesOfForms';
import { ChangePasswordFormValidateSchema } from 'formHelpers/validationsOfForms';
// types
import { ChangePasswordFormProps } from 'app/AccountSettings/types';

const ChangePasswordForm = ({ defaultError, defaultSuccess = false }: ChangePasswordFormProps) => {
  const [error, setError] = useState<Error | undefined>(defaultError);
  const [successFull, setSuccessFull] = useState<boolean>(defaultSuccess);
  const [selectedInputNames, setSelectedInputNames] = useState<Array<string>>([]);
  const [oldPasswordInputName] = useState<string>('oldPassword');
  const [newPasswordInputName] = useState<string>('newPassword');

  const formik = useFormik<IChangePasswordForm>({
    onSubmit,
    initialValues: ChangePasswordFormInitialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ChangePasswordFormValidateSchema,
  });

  function onSubmit(data: IChangePasswordForm) {
    try {
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, data.oldPassword, data.newPassword);
        })
        .then(data => {
          if (data === 'SUCCESS') {
            window.analytics?.track('wui-crew-changed-password');
            setSuccessFull(true);
          }
        })
        .catch(err => {
          setSuccessFull(false);
          if (err.code === 'NotAuthorizedException') {
            setError({ message: 'Incorrect current password.' } as Error);
            return;
          }
          setError(err as Error);
        });
      setError(undefined);
      setSelectedInputNames([]);
    } catch (err) {
      console.warn(err);
    }

    formik.setSubmitting(false);
    formik.resetForm();
  }

  useEffect(() => {
    let timer: NodeJS.Timer | null = null;

    if (successFull && !defaultSuccess) {
      timer = setTimeout(() => setSuccessFull(false), 2000);
    }

    return () => {
      clearTimeout(timer as NodeJS.Timer);
    };
  }, [successFull, defaultSuccess]);

  const handleToggleEye = (inputName: string) => (event: React.MouseEvent<SVGElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedInputNames(prevState => {
      if (prevState.includes(inputName)) {
        return prevState.filter(item => item !== inputName);
      }
      return [...prevState, inputName];
    });
  };

  const getEyeIcon = (inputName: string) => {
    if (selectedInputNames.includes(inputName)) {
      return <EyeSlashIcon className="w-6 h-6 text-specialGray-05 cursor-pointer" aria-hidden="true" onClick={handleToggleEye(inputName)} />;
    }
    return <EyeIcon className="w-6 h-6 text-specialGray-05 cursor-pointer" aria-hidden="true" onClick={handleToggleEye(inputName)} />;
  };

  const getInputType = (inputName: string) => (selectedInputNames.includes(inputName) ? 'text' : 'password');

  const getButtonBg = successFull ? 'bg-[#00BF78]' : 'bg-blue';

  return (
    <FormikProvider value={formik}>
      <Form className="flex flex-col">
        {error && <AlertForError heading={{ id: 'confirm_failedConfirmOrResend' }} error={error} />}
        <div className={`flex flex-col w-full lg:w-[430px] xl:w-[640px] gap-y-6 ${!!error?.message ? 'mt-4' : ''}`}>
          <InputGroup
            label={{ id: 'change_password_form_old_passwordLabel' }}
            name={oldPasswordInputName}
            type={getInputType(oldPasswordInputName)}
            placeholderText={{ id: 'change_password_form_old_password_placeholder' }}
            inputCustomStyle="rounded-[10px] h-12"
          >
            {getEyeIcon(oldPasswordInputName)}
          </InputGroup>
          <InputGroup
            label={{ id: 'change_password_form_new_passwordLabel' }}
            name={newPasswordInputName}
            type={getInputType(newPasswordInputName)}
            placeholderText={{ id: 'change_password_form_new_password_placeholder' }}
            description={{ id: 'change_password_form_new_password_description' }}
            inputCustomStyle="rounded-[10px] h-12"
          >
            {getEyeIcon(newPasswordInputName)}
          </InputGroup>
        </div>
        <CustomButton
          type="submit"
          label={{ id: successFull ? 'hire_label_applied' : 'change_password_button' }}
          className={`flex w-fit px-3.5 xs:px-8 py-3.5 mt-5 xs:mx-0 mx-auto text-white rounded-[10px] text-sm font-medium space-x-2 ${getButtonBg}`}
        >
          <>{successFull ? <DoneIcon /> : null}</>
        </CustomButton>
      </Form>
    </FormikProvider>
  );
};

export default ChangePasswordForm;
