const ProfileProgressBarIcon2 = () => {
  return (
    <svg width="209" height="104" viewBox="0 0 209 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M202.925 97.7492C202.925 47.1746 158.996 6.17578 104.806 6.17578C50.6168 6.17578 6.6875 47.1746 6.6875 97.7492"
        stroke="#F1F2F4"
        strokeWidth="11.8934"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.6875 97.7499C6.6875 77.5809 13.674 58.9349 25.5115 43.8047" stroke="#F1F2F4" strokeWidth="11.8934" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.6875 97.7499C6.6875 77.5809 13.674 58.9349 25.5115 43.8047"
        stroke="url(#paint0_linear_6750_59196)"
        strokeWidth="11.8934"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6875 97.7499C6.6875 77.5809 13.674 58.9349 25.5115 43.8047"
        stroke="url(#paint1_linear_6750_59196)"
        strokeWidth="11.8934"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient id="paint0_linear_6750_59196" x1="26.688" y1="31.7161" x2="-8.85617" y2="39.9844" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FC9318" />
          <stop offset="0.984375" stopColor="#E75B3C" />
        </linearGradient>
        <linearGradient id="paint1_linear_6750_59196" x1="36.0112" y1="36.5837" x2="-1.31058" y2="158.823" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FC9318" />
          <stop offset="0.958034" stopColor="#E75B3C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProfileProgressBarIcon2;
