import graphql from 'babel-plugin-relay/macro';

export const GET_ALL_APPRAISALS_LIST = graphql`
  query AppraisalQueriesListQuery($ownerId: ID!, $after: Cursor, $first: Int, $skip: Boolean!) {
    appraisals(ownerId: $ownerId, first: $first, after: $after) @skip(if: $skip) @connection(key: "AppraisalListFromProfile_appraisals") {
      edges {
        node {
          id
          seaService {
            id
            start
            end
          }
          ...AppraisalFragmentsOneAppraisal
        }
      }
    }
  }
`;

export const APPRAISAL_QUERY = graphql`
  query AppraisalQueriesOneQuery($id: ID!) {
    node(id: $id) {
      ...AppraisalFragmentsOneAppraisal
    }
  }
`;

export const GET_ALL_APPRAISALS_LIST_WITHOUT_DOCUMENT = graphql`
  query AppraisalQueriesListWithoutDocumentQuery($ownerId: ID!, $after: Cursor, $first: Int, $skip: Boolean!) {
    appraisals(ownerId: $ownerId, first: $first, after: $after) @skip(if: $skip) @connection(key: "AppraisalListFromProfile_appraisals") {
      edges {
        node {
          id
          seaService {
            id
            start
            end
          }
          ...AppraisalFragmentsOneAppraisalWithoutDocument
        }
      }
    }
  }
`;
