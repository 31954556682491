// packages
import { FC, useEffect, useState } from 'react';
import { useMutation } from 'react-relay';
import { isEmpty, isEqual } from 'lodash';
import { Form, FormikProvider, useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
// context
import { useJobOpeningFormContext } from 'Context/JobOpeningFormContext';
// routing
import { RoutePaths, RoutesPathNames } from 'app/routing';
import AlertForError from 'system/Alert/AlertForError';
// components
import { Button } from 'system/Button';
import BottomFormLayout from '../BottomFormLayout';
import { TextAreaWithMark } from 'system/TextAreaWithMark';
import { TextareaInputGroup } from 'system/TextareaInputGroup';
// formHelpers
import { prepareResponsibilitiesData } from 'formHelpers/utils';
import { HireFormInitialValuesStepTree } from 'formHelpers/initialValuesOfForms';
import { JobOpeningStep3FormValidateSchema } from 'formHelpers/validationsOfForms';
// models
import { FormStepProps } from 'models/modelsOfComponents';
import { IJobOpeningStep3Form } from 'models/modelsOfForms';
// generated
import { VacancyMutationsUpdateVacancyMutation } from 'schemas/vacancy/__generated__/VacancyMutationsUpdateVacancyMutation.graphql';
// hooks
import { useGetVacancy } from 'hooks/useGetVacancy';
import { useHandleGoBackFormVacancy } from 'hooks/useHandleGoBackFormVacancy';
// schemas
import { UPDATE_VACANCY } from 'schemas/vacancy/VacancyMutations';
// models
import { typeFormVacancyState } from 'models/routeLocationState';
import { VACANCY_STATUS } from 'models/enums';
//updaters
import { JobOpeningCommitUpdateVacancy } from 'formHelpers/updaters/updatersOfJobOpening';

const FormStepThree: FC<FormStepProps> = ({ defaultError = null, defaultVacancyId = '' }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [error, setError] = useState<Error | null>(defaultError);
  const { setIsChange, nextStep } = useJobOpeningFormContext();
  const { goBack } = useHandleGoBackFormVacancy(`${state?.organization?.id}/${RoutePaths.JobOpeningStep2}`);

  const vacancy = useGetVacancy(defaultVacancyId);

  const [commitUpdateVacancy] = useMutation<VacancyMutationsUpdateVacancyMutation>(UPDATE_VACANCY);

  const formik = useFormik<IJobOpeningStep3Form>({
    onSubmit,
    validateOnBlur: false,
    enableReinitialize: !!vacancy,
    initialValues: HireFormInitialValuesStepTree(vacancy),
    validationSchema: JobOpeningStep3FormValidateSchema,
  });

  useEffect(() => {
    if (vacancy && nextStep?.active) {
      const localVacancy = {
        description: vacancy.description,
        responsibilities: vacancy.responsibilities ? vacancy.responsibilities.join('\n') : '',
      };
      setIsChange(!isEqual(localVacancy, formik.values));
    } else {
      setIsChange(false);
    }
  }, [vacancy, formik.values, setIsChange, nextStep]);

  const handleFormError = (error: Error) => {
    setError(error);
    formik.setSubmitting(false);
  };

  function onSubmit(data: IJobOpeningStep3Form) {
    const { description, responsibilities } = data;
    const variableData = {
      title: vacancy.title,
      salaryFrom: vacancy?.salaryFrom,
      salaryTo: vacancy?.salaryTo,
      industry: vacancy?.industry,
      duration: vacancy?.duration,
      salaryPeriod: vacancy?.salaryPeriod,
      duties: !isEmpty(vacancy?.duties) ? vacancy?.duties?.map(item => item?.id) : [],
      organizationId: vacancy.organization.id,
      status: vacancy.status === VACANCY_STATUS.OPEN ? VACANCY_STATUS.OPEN : VACANCY_STATUS.CLOSED,
      description,
      certificates: vacancy?.certificates?.map(certificate => certificate.id),
      responsibilities: prepareResponsibilitiesData(responsibilities as string),
      vesselKindId: vacancy.vesselKind?.id || null,
      startingDate: vacancy.startingDate,
    };
    commitUpdateVacancy({
      onError: handleFormError,
      variables: {
        id: (state as typeFormVacancyState)?.organization?.vacancyId as string,
        data: variableData,
      },
      onCompleted: () => {
        navigate(`${RoutePaths.ProfileOrganizationBase}/${vacancy.organization.id}/${RoutesPathNames.job}/${RoutesPathNames.edit}/${vacancy.id}`, {
          state: null,
        });
      },
      updater: JobOpeningCommitUpdateVacancy,
    });
  }

  return (
    <FormikProvider value={formik}>
      <Form className="rounded-xl bg-white shadow mx-auto">
        <div className="flex flex-col gap-y-8 px-20 py-8">
          {error && <AlertForError heading={{ id: 'hire_form_failedSubmit' }} error={error} />}
          <section>
            <TextareaInputGroup
              name="description"
              label={{ id: 'hire_form_step_1_job_description' }}
              placeholderText={{ id: 'placeholder_form_type' }}
              description={{ id: 'form_limit_characters' }}
              maxCharCount={1000}
              autoHeight={198}
              breakLinesLimit
            />
          </section>
          <section>
            <TextAreaWithMark
              label={{ id: 'hire_form_step_1_job_responsibilities' }}
              placeholderText={{ id: 'placeholder_form_type' }}
              description={{ id: 'hire_form_step_1_job_description_limit_of_chars' }}
              name="responsibilities"
              maxCharCount={2000}
            />
          </section>
        </div>
        <BottomFormLayout>
          <>
            <Button
              type="button"
              buttonType="white-primary"
              onClick={goBack}
              label={{ id: 'back' }}
              prefixIcon={ChevronLeftIcon}
              additionalClasses="h-10"
              disabled={formik.isSubmitting}
            />
            <Button buttonType="primary" type="submit" label={{ id: 'job_opening_save_and_review' }} disabled={formik.isSubmitting} />
          </>
        </BottomFormLayout>
      </Form>
    </FormikProvider>
  );
};

export default FormStepThree;
