import { FC } from 'react';

const ForwardToInbox: FC = () => {
  return (
    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_7174_64079" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="51" height="50">
        <rect x="0.25" width="50" height="50" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7174_64079)">
        <path
          d="M25.25 27.084L8.58329 16.6673V37.5007H27.3333V41.6673H8.58329C7.43746 41.6673 6.4569 41.2597 5.64163 40.4444C4.82496 39.6277 4.41663 38.6465 4.41663 37.5007V12.5007C4.41663 11.3548 4.82496 10.3743 5.64163 9.55898C6.4569 8.74232 7.43746 8.33398 8.58329 8.33398H41.9166C43.0625 8.33398 44.0437 8.74232 44.8604 9.55898C45.6757 10.3743 46.0833 11.3548 46.0833 12.5007V27.084C45.4236 27.084 44.7465 27.084 44.052 27.084C43.3576 27.084 42.6458 27.084 41.9166 27.084V16.6673L25.25 27.084ZM25.25 22.9173L41.9166 12.5007H8.58329L25.25 22.9173ZM8.58329 16.6673V37.5007V39.584C8.58329 36.1118 8.58329 33.1604 8.58329 30.7298C8.58329 28.2993 8.58329 27.084 8.58329 27.084C8.58329 27.084 8.58329 27.0923 8.58329 27.109C8.58329 27.127 8.58329 27.1708 8.58329 27.2402V16.6673Z"
          fill="#082051"
          fillOpacity="0.3"
        />
        <mask id="mask1_7174_64079" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="28" y="28" width="23" height="22">
          <rect x="28.9679" y="28.1738" width="21.2821" height="21.8262" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask1_7174_64079)">
          <path
            d="M47.719 48.9337L43.2268 44.4331C42.594 44.7947 41.9161 45.096 41.1929 45.3371C40.4698 45.5781 39.7014 45.6986 38.8879 45.6986C36.628 45.6986 34.7069 44.908 33.1244 43.3267C31.5431 41.7442 30.7524 39.823 30.7524 37.5632C30.7524 35.3033 31.5431 33.3822 33.1244 31.7997C34.7069 30.2184 36.628 29.4277 38.8879 29.4277C41.1477 29.4277 43.0689 30.2184 44.6514 31.7997C46.2327 33.3822 47.0233 35.3033 47.0233 37.5632C47.0233 38.3767 46.9028 39.1451 46.6618 39.8682C46.4207 40.5914 46.1194 41.2693 45.7578 41.9021L50.25 46.4027L47.719 48.9337ZM38.8879 42.0829C40.1534 42.0829 41.2231 41.646 42.0969 40.7722C42.9707 39.8984 43.4076 38.8287 43.4076 37.5632C43.4076 36.2977 42.9707 35.228 42.0969 34.3542C41.2231 33.4804 40.1534 33.0435 38.8879 33.0435C37.6224 33.0435 36.5527 33.4804 35.6789 34.3542C34.8051 35.228 34.3682 36.2977 34.3682 37.5632C34.3682 38.8287 34.8051 39.8984 35.6789 40.7722C36.5527 41.646 37.6224 42.0829 38.8879 42.0829Z"
            fill="#082051"
            fillOpacity="0.3"
          />
        </g>
      </g>
    </svg>
  );
};

export default ForwardToInbox;
