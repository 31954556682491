const BusinessCenterIcon = () => {
  return (
    <svg className="fill-current" width="1em" height="1em" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_9954_78705" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
        <rect className="fill-current" x="0.0429688" y="0.5" width="24" height="24" />
      </mask>
      <g mask="url(#mask0_9954_78705)">
        <path
          className="fill-current"
          d="M2.04297 13.5V8.5C2.04297 7.95 2.23897 7.47933 2.63097 7.088C3.0223 6.696 3.49297 6.5 4.04297 6.5H8.04297V4.5C8.04297 3.95 8.23897 3.479 8.63097 3.087C9.0223 2.69567 9.49297 2.5 10.043 2.5H14.043C14.593 2.5 15.064 2.69567 15.456 3.087C15.8473 3.479 16.043 3.95 16.043 4.5V6.5H20.043C20.593 6.5 21.064 6.696 21.456 7.088C21.8473 7.47933 22.043 7.95 22.043 8.5V13.5H15.043V12.5C15.043 12.2167 14.9473 11.979 14.756 11.787C14.564 11.5957 14.3263 11.5 14.043 11.5H10.043C9.75964 11.5 9.5223 11.5957 9.33097 11.787C9.13897 11.979 9.04297 12.2167 9.04297 12.5V13.5H2.04297ZM10.043 6.5H14.043V4.5H10.043V6.5ZM11.043 15.5V13.5H13.043V15.5H11.043ZM4.04297 21.5C3.49297 21.5 3.0223 21.3043 2.63097 20.913C2.23897 20.521 2.04297 20.05 2.04297 19.5V15.5H9.04297V16.5C9.04297 16.7833 9.13897 17.0207 9.33097 17.212C9.5223 17.404 9.75964 17.5 10.043 17.5H14.043C14.3263 17.5 14.564 17.404 14.756 17.212C14.9473 17.0207 15.043 16.7833 15.043 16.5V15.5H22.043V19.5C22.043 20.05 21.8473 20.521 21.456 20.913C21.064 21.3043 20.593 21.5 20.043 21.5H4.04297Z"
        />
      </g>
    </svg>
  );
};

export default BusinessCenterIcon;
