import { MessageDescriptor } from '@formatjs/intl';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

const Header = ({ title, description, icon }: { title: MessageDescriptor; description: MessageDescriptor; icon: ReactElement }) => {
  const intl = useIntl();
  return (
    <div className="flex w-full flex-col items-center">
      {icon}
      <h1 className="mt-3 mb-1 text-darkBlue font-semibold sm:text-lg text-center">{intl.formatMessage(title)}</h1>
      <h2 className="text-specialGray-075 text-xs sm:text-sm text-center">{intl.formatMessage(description)}</h2>
    </div>
  );
};

export default Header;
