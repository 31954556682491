// packages
import React, { useMemo } from 'react';
import { useFragment } from 'react-relay';
import { intervalToDuration } from 'date-fns';
import { useIntl } from 'react-intl';
// components
import ShipPhoto from 'icons/ShipPhoto';
import CountryFlag from 'system/CountryFlag';
import ComponentWithPopover from 'system/ComponentWithPopover';
// models
import { ProfileSlideServiceItemProps } from 'models/modelsOfComponents';
// generated
import { ServiceFragments$key } from 'schemas/services/__generated__/ServiceFragments.graphql';
import { ServiceFragmentsVessel$key } from 'schemas/services/__generated__/ServiceFragmentsVessel.graphql';
import { ServiceFragmentsOneDuty$key } from 'schemas/services/__generated__/ServiceFragmentsOneDuty.graphql';
import { ServiceFragmentsActivities$key } from 'schemas/services/__generated__/ServiceFragmentsActivities.graphql';
// schemas
import { GET_ONE_DUTY, SERVICE_ACTIVITIES, SERVICE_FRAGMENT, VESSEL_FRAGMENT } from 'schemas/services/ServiceFragments';
import SeaServiceJobExperience from 'system/SeaServiceJobExpirience/SeaServiceJobExperience';
import ServiceTime from 'system/ServiceTime/ServiceTime';

const ProfileSlideServiceItem: React.FC<ProfileSlideServiceItemProps> = ({ serviceFragment }) => {
  const intl = useIntl();
  const service = useFragment<ServiceFragments$key>(SERVICE_FRAGMENT, serviceFragment);
  const vesselData = useFragment<ServiceFragmentsVessel$key>(VESSEL_FRAGMENT, service?.vessel || null);
  const serviceDuty = useFragment<ServiceFragmentsOneDuty$key>(GET_ONE_DUTY, service.duty);
  const serviceActivities = useFragment<ServiceFragmentsActivities$key>(SERVICE_ACTIVITIES, service.activities);

  const jobExperience = useMemo(() => {
    if (service.end) {
      return intervalToDuration({ start: new Date(`${service.start}`), end: new Date(`${service.end}`) });
    } else {
      return intervalToDuration({ start: new Date(`${service.start}`), end: new Date() });
    }
  }, [service.end, service.start]);

  const prepareTimeView = (str: string | null) => (str ? str.split('T')[0].split('-').join('.') : null);

  return (
    <div className="border-solid border border-specialGray-012 rounded-xl mb-4 p-4">
      <div className="flex justify-between">
        <div className="flex">
          <CountryFlag countryName={vesselData?.countryName} countryId={vesselData?.cnISO} />
          <span className="ml-4 font-medium text-specialGray-1">{vesselData?.name}</span>
        </div>
        <div className="flex flex-col items-end">
          <span className="ml-2 text-specialGray-075 font-normal text-sm">{vesselData?.kind?.name}</span>
          <span className="ml-2 text-specialGray-075 font-normal text-sm">
            <ServiceTime startDate={prepareTimeView(`${service.start}`)} endDate={prepareTimeView(`${service.end}`)} />
          </span>
        </div>
      </div>
      <div className="flex mt-3">
        {vesselData?.image ? (
          <img className="bg-avatar-bg mr-5 w-24 flex items-center rounded-md" src={vesselData.image} alt={vesselData.name} />
        ) : (
          <section className="bg-avatar-bg mr-5 w-1/5 flex items-center justify-center mx-auto rounded-xl">
            <span className="w-[37px] h-[33px]">
              <ShipPhoto />
            </span>
          </section>
        )}
        <div className="flex flex-col w-full">
          <div className="flex text-specialGray-075 border-b py-1.5 font-medium">
            <span className="mr-auto text-xs font-medium">{intl.formatMessage({ id: 'sea_service_position' }).toLocaleUpperCase()}</span>
            <p className="text-specialGray-1 ml-2 text-sm font-medium"> {serviceDuty.name}</p>
          </div>
          <div className="flex text-specialGray-075 pt-3 font-medium">
            <span className="mr-auto text-xs font-medium">{intl.formatMessage({ id: 'sea_service_duration' }).toLocaleUpperCase()}</span>
            <SeaServiceJobExperience
              days={jobExperience.days as number}
              months={jobExperience.months as number}
              years={jobExperience.years as number}
              classes="text-specialGray-1 text-sm font-medium"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap mt-3.5">
        {serviceActivities?.map(tag => (
          <ComponentWithPopover key={`${service.id}-${tag.id}`} id={tag.id} name={tag.label} />
        ))}
      </div>
    </div>
  );
};

export default ProfileSlideServiceItem;
