// packages
import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useMutation } from 'react-relay';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
// routes
import { RoutePaths } from 'app/routing';
// schemas
import { STOP_ALLOW_ASSUMING_PROFILE_BY_ID } from 'schemas/AdminAssume/AdminAssumeMutations';
// generated
import { AdminAssumeMutationsStopAllowByIdMutation } from 'schemas/AdminAssume/__generated__/AdminAssumeMutationsStopAllowByIdMutation.graphql';
// components
import SpinnerIcon from 'icons/SpinnerIcon';
// recoil
import { personalAccountSelector } from 'recoil/Profile/personalAccount/selectors/personalAccountSelector';
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';

const StopAssumeComponent = () => {
  const navigate = useNavigate();
  const [stopAssumeProfileById] = useMutation<AdminAssumeMutationsStopAllowByIdMutation>(STOP_ALLOW_ASSUMING_PROFILE_BY_ID);

  const setIsPersonalAccount = useSetRecoilState(personalAccountSelector);
  const setCurrentOrganisation = useSetRecoilState(currentOrganisationSelector);

  useEffect(() => {
    stopAssumeProfileById({
      variables: {},
      onCompleted: () => {
        setCurrentOrganisation({ isSelected: false, organisationId: '', profileId: '' });
        setIsPersonalAccount({ personalAccountIsSelected: true });
        navigate(RoutePaths.AuthLogin);
        Auth.signOut().then();
      },
    });
  }, [navigate, setCurrentOrganisation, setIsPersonalAccount, stopAssumeProfileById]);

  return (
    <div className="flex justify-center items-center h-screen">
      <SpinnerIcon />
    </div>
  );
};

export default StopAssumeComponent;
