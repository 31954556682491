// packages
import { FC } from 'react';
import { useIntl } from 'react-intl';
//components
import EmptyFieldItem from '../components/EmptyFieldItem';

const VacancyResponsibilities: FC<{ vacancyResponsibilities: readonly string[] | null }> = ({ vacancyResponsibilities }) => {
  const intl = useIntl();

  return (
    <div className="flex flex-col">
      <h2 id="sea-service-title" className="flex-1 min-w-0 text-lg sm:text-2xl leading-6 font-semibold text-specialGray-1 mb-2">
        {intl.formatMessage({ id: 'hire_description_responsibilities_section' })}
      </h2>
      {!!vacancyResponsibilities?.length ? (
        <ul className="text-specialGray-075 list-disc ml-5">
          {vacancyResponsibilities?.map((responsibility, index) => (
            <li data-test-id={responsibility.split(' ')[0] + index} key={`${responsibility}${index}`}>
              {responsibility}
            </li>
          ))}
        </ul>
      ) : (
        <EmptyFieldItem />
      )}
    </div>
  );
};

export default VacancyResponsibilities;
