import graphql from 'babel-plugin-relay/macro';

graphql`
  fragment ServiceFragmentsVesselKind on VesselKind {
    id
    name
    perma
  }
`;

export const GET_ONE_DUTY = graphql`
  fragment ServiceFragmentsOneDuty on Duty {
    id
    name
    alts
    perma
  }
`;

export const VESSEL_FRAGMENT = graphql`
  fragment ServiceFragmentsVessel on Vessel {
    id
    imo
    name
    grossTonnage
    operatorName
    kind {
      id
      name
      perma
    }
    length
    image
    cnISO
    countryName
  }
`;

export const SERVICE_ACTIVITIES = graphql`
  fragment ServiceFragmentsActivities on Activity @relay(plural: true) {
    id
    label
    perma
    timesUsed
  }
`;

export const SERVICE_FRAGMENT = graphql`
  fragment ServiceFragments on SeaService {
    id
    start
    end
    rotations
    description
    vessel {
      ...ServiceFragmentsVessel
    }
    duty {
      ...ServiceFragmentsOneDuty
    }
    activities {
      ...ServiceFragmentsActivities
    }
  }
`;

export const SERVICES_LIST_FRAGMENT = graphql`
  fragment ServiceFragmentsListPlural_profile on SeaService @relay(plural: true) {
    ...ServiceFragments
    id
    start
    end
  }
`;
