import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
//components
import { PlusIcon } from '@heroicons/react/24/outline';

const EmployerCrewPoolsHeader = ({ totalCrewPools, totalAddedUsers }: { totalCrewPools: number; totalAddedUsers: number }) => {
  const intl = useIntl();
  return (
    <div className="flex w-full justify-between">
      <div className="flex flex-col">
        <h2 data-test-id="crew-pools-header-title" className="text-specialGray-1 font-medium mb-2">
          {intl.formatMessage({ id: 'hire_crew_pools' })}
        </h2>
        <div className="flex gap-x-6">
          <span className="lowercase text-specialGray-075 text-sm">
            <span className="mr-1">{totalCrewPools}</span>
            {intl.formatMessage({ id: 'hire_crew_pools' })}
          </span>

          <span data-test-id="crew-pools-total-added" className="lowercase text-specialGray-075 text-sm">
            {intl.formatMessage({ id: 'hire_crew_added' }, { value: totalAddedUsers })}
          </span>
        </div>
      </div>
      <Link data-test-id="create-new-pool-button" className="flex bg-blue text-white rounded-lg h-10 px-3 text-sm items-center font-medium gap-x-2" to="new-pool">
        <PlusIcon className="h-[18px] w-[18px]" />
        {intl.formatMessage({ id: 'hire_create_new' })}
      </Link>
    </div>
  );
};

export default EmployerCrewPoolsHeader;
