// packages
import React, { forwardRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// routes
import { RoutePaths, tabs } from 'app/routing';
// generated
import { ProfileFragments_info$data } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
// components
import CustomNavLink from 'system/CustomNavLink/CustomNavLink';

const RenderTabsForMobile = forwardRef<HTMLDivElement, { profileInfoData: ProfileFragments_info$data; isScrollableNavbar: boolean }>(
  ({ profileInfoData, isScrollableNavbar }, ref) => {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();

    const disabledStyles = useCallback(
      (activeTab: string) => {
        return activeTab !== tabs[0].name && !profileInfoData ? 'hover:text-specialGray-075 hover:border-transparent cursor-not-allowed border-none' : '';
      },
      [profileInfoData],
    );

    const handleMoveByLink = useCallback(
      (tabHref: string) => (event: React.MouseEvent) => {
        event.preventDefault();
        navigate({ pathname: tabHref, search });
      },
      [navigate, search],
    );

    return (
      <nav
        ref={ref}
        className={`-mb-px flex ${
          isScrollableNavbar ? 'justify-start' : 'justify-center sm:justify-start'
        } sm:mt-5 mt-7 overflow-x-scroll sm:overflow-auto [&::-webkit-scrollbar]:hidden snap-x snap-mandatory relative scroll-smooth border-b border-gray-200`}
        aria-label="Tabs"
      >
        {tabs.map((tab, i) => {
          let isDisabledLinkButton = false;
          //just first tab can be clickable
          if (!profileInfoData) isDisabledLinkButton = i > 0;
          return (
            <CustomNavLink
              to={tab.href === RoutePaths.ProfileBaseOverview && pathname === RoutePaths.ProfileBase ? '.' : tab.href}
              key={tab.name}
              disabled={isDisabledLinkButton}
              className={`${disabledStyles(tab.name)} whitespace-nowrap px-2.5 py-2 sm:py-4 sm:px-5 sm:text-sm text-xs`}
              onClick={handleMoveByLink(tab.href)}
            >
              {tab.name}
            </CustomNavLink>
          );
        })}
      </nav>
    );
  },
);

export default RenderTabsForMobile;
