import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
// component
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';
import PopoverRatingContent from 'system/ProfileReting/components/PopoverRatingContent';
// hooks
import { useGetProfileScore } from 'hooks/useGetProfileScore';
// components
import RatingScoreWithIcon from 'system/ProfileReting/components/RatingScoreWithIcon';

const ProfileRating = () => {
  const intl = useIntl();
  const { profileScoreDetails, profileScoreSummary } = useGetProfileScore();
  const [getSummary, setSummary] = useState<number>(profileScoreSummary);
  const [maximumSummary] = useState<number>(100);

  useEffect(() => {
    if (!!profileScoreSummary) {
      setSummary(prev => {
        if (prev < maximumSummary && profileScoreSummary >= maximumSummary) {
          window.analytics?.track('wui-completed-profile');
        }
        return profileScoreSummary;
      });
    }
  }, [maximumSummary, profileScoreSummary]);

  return (
    <div className="flex font-medium w-full xs:w-2/5 sm:w-[326px] md:w-[300px] lg:w-[326px] h-10 sm:h-[67px] justify-center md:justify-start lg:justify-center items-center border border-specialGray-012 rounded-[10px] sm:rounded-2xl sm:py-4 px-2 sm:flex-row flex-col">
      <span className="hidden sm:block text-xs text-left uppercase sm:mr-[18px]">{intl.formatMessage({ id: 'rating_profile_completion' })}</span>
      <div className="hidden sm:flex items-center">
        <RatingScoreWithIcon summaryRating={getSummary} maxSummary={maximumSummary} />
        <PopoverHelper
          symbolClasses="bg-white text-red border border-specialGray-05 text-specialGray-05 w-[18px] h-[18px]"
          symbol="i"
          classes="relative left-2.5"
          position="pr-3 right-full -top-28"
          positionArrow="top-[121px] -right-0.5 -rotate-45"
        >
          <PopoverRatingContent profileScoreDetails={profileScoreDetails} getSummary={getSummary} maximumSummary={maximumSummary} />
        </PopoverHelper>
      </div>
      <div className="flex sm:hidden w-full justify-center">
        <PopoverHelper
          symbolClasses="bg-white text-red border border-specialGray-05 text-specialGray-05 w-[18px] h-[18px]"
          element={
            <div className="flex items-center">
              <RatingScoreWithIcon summaryRating={getSummary} maxSummary={maximumSummary} />
            </div>
          }
          position="pr-3 right-full top-10 -left-[140px]"
          positionArrow="left-2/3 rotate-225 top-1.5"
          widthContainer="w-[254px]"
        >
          <PopoverRatingContent profileScoreDetails={profileScoreDetails} getSummary={getSummary} maximumSummary={maximumSummary} />
        </PopoverHelper>
      </div>
    </div>
  );
};

export default ProfileRating;
