/**
 * @generated SignedSource<<3cc4544a580a557e9365775fbb0dd10a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type OrderDirection = 'ASC' | 'DESC' | '%future added value';
export type VacancyOrderField = 'CREATED_AT' | 'LAST_INVITED_AT' | 'STATUS' | '%future added value';
export type VacancyStatus = 'CLOSED' | 'OPEN' | '%future added value';
export type FilterModel = {
  field: string;
  values: ReadonlyArray<string>;
};
export type VacancyOrder = {
  direction: OrderDirection;
  field?: VacancyOrderField | null;
};
export type VacancyQueriesListOfVacanciesForDashboardQuery$variables = {
  after?: any | null;
  filters?: ReadonlyArray<FilterModel | null> | null;
  first?: number | null;
  orderBy?: VacancyOrder | null;
  orgId?: string | null;
  skip: boolean;
};
export type VacancyQueriesListOfVacanciesForDashboardQuery$data = {
  readonly vacancies?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly 'id': string;
        readonly 'status': VacancyStatus;
        readonly ' $fragmentSpreads': FragmentRefs<'VacancyFragmentsForDashboardFragment'>;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: any | null;
      readonly hasNextPage: boolean;
    };
    readonly totalCount: number;
  } | null;
};
export type VacancyQueriesListOfVacanciesForDashboardQuery = {
  response: VacancyQueriesListOfVacanciesForDashboardQuery$data;
  variables: VacancyQueriesListOfVacanciesForDashboardQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'after',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'filters',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'first',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'orderBy',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'orgId',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'skip',
    },
    v6 = {
      kind: 'Variable',
      name: 'filters',
      variableName: 'filters',
    },
    v7 = {
      kind: 'Variable',
      name: 'orderBy',
      variableName: 'orderBy',
    },
    v8 = {
      kind: 'Variable',
      name: 'orgId',
      variableName: 'orgId',
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'status',
      storageKey: null,
    },
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: '__typename',
      storageKey: null,
    },
    v12 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cursor',
      storageKey: null,
    },
    v13 = {
      alias: null,
      args: null,
      concreteType: 'PageInfo',
      kind: 'LinkedField',
      name: 'pageInfo',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'hasNextPage',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'endCursor',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v14 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'totalCount',
      storageKey: null,
    },
    v15 = [
      {
        kind: 'Variable',
        name: 'after',
        variableName: 'after',
      },
      v6 /*: any*/,
      {
        kind: 'Variable',
        name: 'first',
        variableName: 'first',
      },
      v7 /*: any*/,
      v8 /*: any*/,
    ],
    v16 = [
      v9 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'name',
        storageKey: null,
      },
    ],
    v17 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'avatarUrl',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/, v5 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'VacancyQueriesListOfVacanciesForDashboardQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: 'vacancies',
              args: [v6 /*: any*/, v7 /*: any*/, v8 /*: any*/],
              concreteType: 'VacancyConnection',
              kind: 'LinkedField',
              name: '__VacancyList_vacancies_connection',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'VacancyEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Vacancy',
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [
                        v9 /*: any*/,
                        v10 /*: any*/,
                        {
                          args: null,
                          kind: 'FragmentSpread',
                          name: 'VacancyFragmentsForDashboardFragment',
                        },
                        v11 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    v12 /*: any*/,
                  ],
                  storageKey: null,
                },
                v13 /*: any*/,
                v14 /*: any*/,
              ],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v4 /*: any*/, v0 /*: any*/, v2 /*: any*/, v5 /*: any*/, v1 /*: any*/, v3 /*: any*/],
      kind: 'Operation',
      name: 'VacancyQueriesListOfVacanciesForDashboardQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v15 /*: any*/,
              concreteType: 'VacancyConnection',
              kind: 'LinkedField',
              name: 'vacancies',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'VacancyEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Vacancy',
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [
                        v9 /*: any*/,
                        v10 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'createdAt',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'title',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'industry',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'salaryFrom',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'salaryTo',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: 'VesselKind',
                          kind: 'LinkedField',
                          name: 'vesselKind',
                          plural: false,
                          selections: v16 /*: any*/,
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Duty',
                          kind: 'LinkedField',
                          name: 'duties',
                          plural: true,
                          selections: v16 /*: any*/,
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Application',
                          kind: 'LinkedField',
                          name: 'applications',
                          plural: true,
                          selections: [
                            v9 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              concreteType: 'Profile',
                              kind: 'LinkedField',
                              name: 'owner',
                              plural: false,
                              selections: [
                                v9 /*: any*/,
                                {
                                  alias: null,
                                  args: null,
                                  concreteType: 'ProfileInfo',
                                  kind: 'LinkedField',
                                  name: 'info',
                                  plural: false,
                                  selections: [
                                    {
                                      alias: null,
                                      args: null,
                                      kind: 'ScalarField',
                                      name: 'firstName',
                                      storageKey: null,
                                    },
                                    {
                                      alias: null,
                                      args: null,
                                      kind: 'ScalarField',
                                      name: 'lastName',
                                      storageKey: null,
                                    },
                                    v17 /*: any*/,
                                    v9 /*: any*/,
                                  ],
                                  storageKey: null,
                                },
                              ],
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Organization',
                          kind: 'LinkedField',
                          name: 'organization',
                          plural: false,
                          selections: [v9 /*: any*/, v17 /*: any*/],
                          storageKey: null,
                        },
                        v11 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    v12 /*: any*/,
                  ],
                  storageKey: null,
                },
                v13 /*: any*/,
                v14 /*: any*/,
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: v15 /*: any*/,
              filters: ['orgId', 'filters', 'orderBy'],
              handle: 'connection',
              key: 'VacancyList_vacancies',
              kind: 'LinkedHandle',
              name: 'vacancies',
            },
          ],
        },
      ],
    },
    params: {
      cacheID: '1f91a53096febab982fdc02bd5996b04',
      id: null,
      metadata: {
        connection: [
          {
            count: 'first',
            cursor: 'after',
            direction: 'forward',
            path: ['vacancies'],
          },
        ],
      },
      name: 'VacancyQueriesListOfVacanciesForDashboardQuery',
      operationKind: 'query',
      text: 'query VacancyQueriesListOfVacanciesForDashboardQuery(\n  $orgId: ID\n  $after: Cursor\n  $first: Int\n  $skip: Boolean!\n  $filters: [FilterModel]\n  $orderBy: VacancyOrder\n) {\n  vacancies(orgId: $orgId, first: $first, after: $after, filters: $filters, orderBy: $orderBy) @skip(if: $skip) {\n    edges {\n      node {\n        id\n        status\n        ...VacancyFragmentsForDashboardFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n  }\n}\n\nfragment VacancyFragmentsForDashboardFragment on Vacancy {\n  id\n  createdAt\n  title\n  status\n  industry\n  salaryFrom\n  salaryTo\n  vesselKind {\n    id\n    name\n  }\n  duties {\n    id\n    name\n  }\n  applications {\n    id\n    owner {\n      id\n      info {\n        firstName\n        lastName\n        avatarUrl\n        id\n      }\n    }\n  }\n  organization {\n    id\n    avatarUrl\n  }\n}\n',
    },
  };
})();

(node as any).hash = '6b607cbbe280fb72ed2c6daebd148d86';

export default node;
