// enums
import { FILTER_TYPE } from 'models/enums';
// components
import FilteringByDate from '../components/FilteringByDate';
import { FilteringByMinSalary } from '../components/FilteringByMinSalary';
import { SearchFilterWithDropDown } from '../components/SearchFilterWithDropDown';
// utils
import { formatterFilterByName } from 'utils/filterHelpers';
// mocks
import { MOCK_DURATION_OF_POSITION_WITH_NAME, MOCK_LIST_OF_INDUSTRIES, MOCK_STARTING_CONDITION } from 'mocks/mockData';
// hooks
import useJobOpeningsFiltersQuery from 'hooks/searchFilter/useJobOpeningsFiltersQuery';

const JobOpeningFilterComponent = () => {
  const { listOfHiringArea, allDuties, vesselsType } = useJobOpeningsFiltersQuery();

  return (
    <section>
      {allDuties && <SearchFilterWithDropDown listOfFilters={allDuties} formatter={formatterFilterByName} filterType={FILTER_TYPE.DUTIES} title={{ id: 'label_form_duty' }} />}
      {vesselsType && (
        <SearchFilterWithDropDown listOfFilters={vesselsType} formatter={formatterFilterByName} filterType={FILTER_TYPE.VESSEL_TYPES} title={{ id: 'hire_vessel_type_label' }} />
      )}
      <SearchFilterWithDropDown
        listOfFilters={MOCK_LIST_OF_INDUSTRIES}
        formatter={formatterFilterByName}
        filterType={FILTER_TYPE.INDUSTRY}
        title={{ id: 'hire_information_industry' }}
      />
      <SearchFilterWithDropDown
        listOfFilters={MOCK_DURATION_OF_POSITION_WITH_NAME}
        formatter={formatterFilterByName}
        filterType={FILTER_TYPE.DURATION}
        title={{ id: 'sea_service_duration' }}
      />
      {listOfHiringArea && (
        <SearchFilterWithDropDown
          listOfFilters={listOfHiringArea}
          formatter={formatterFilterByName}
          filterType={FILTER_TYPE.HIRING_AREA}
          title={{ id: 'search_filter_hiring_area_label' }}
        />
      )}
      <FilteringByMinSalary title={{ id: 'hire_information_min_salary' }} filterName={FILTER_TYPE.NUMBER_OF_MIN_SALARY} />
      <SearchFilterWithDropDown
        listOfFilters={MOCK_STARTING_CONDITION}
        formatter={formatterFilterByName}
        filterType={FILTER_TYPE.STARTING_CONDITIONAL}
        title={{ id: 'hire_form_step_1_job_description_starting_condition_label' }}
      />
      <FilteringByDate />
    </section>
  );
};

export default JobOpeningFilterComponent;
