import graphql from 'babel-plugin-relay/macro';

export const CREATE_CONTACT = graphql`
  mutation ContactMutationsCreateVacancyContactMutation($data: ContactData!) {
    createContact(data: $data) {
      node {
        id
        owner {
          id
        }
      }
    }
  }
`;

export const DELETE_CONTACT = graphql`
  mutation ContactMutationsDeleteVacancyContactMutation($id: ID!) {
    deleteContact(id: $id) {
      id
    }
  }
`;
