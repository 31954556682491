/**
 * @generated SignedSource<<aaaf22196a82b3fbc0905fe05424a83e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AdminAssumeMutationsStopAllowByIdMutation$variables = {};
export type AdminAssumeMutationsStopAllowByIdMutation$data = {
  readonly clearAssumedProfile: boolean;
};
export type AdminAssumeMutationsStopAllowByIdMutation = {
  response: AdminAssumeMutationsStopAllowByIdMutation$data;
  variables: AdminAssumeMutationsStopAllowByIdMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'clearAssumedProfile',
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'AdminAssumeMutationsStopAllowByIdMutation',
      selections: v0 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'AdminAssumeMutationsStopAllowByIdMutation',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: '6a583c1037aa6cd17fdf1ab0c4c96c10',
      id: null,
      metadata: {},
      name: 'AdminAssumeMutationsStopAllowByIdMutation',
      operationKind: 'mutation',
      text: 'mutation AdminAssumeMutationsStopAllowByIdMutation {\n  clearAssumedProfile\n}\n',
    },
  };
})();

(node as any).hash = '1d9352a10f4c90412c411316dd8f599e';

export default node;
