/**
 * @generated SignedSource<<bb515f168044579f5e56db7c8d24d8b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ProfileFragmentsAvatarInfo$data = {
  readonly 'avatar_version': number;
  readonly 'id': string;
  readonly ' $fragmentType': 'ProfileFragmentsAvatarInfo';
};
export type ProfileFragmentsAvatarInfo$key = {
  readonly ' $data'?: ProfileFragmentsAvatarInfo$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsAvatarInfo'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ProfileFragmentsAvatarInfo',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      kind: 'ClientExtension',
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'avatar_version',
          storageKey: null,
        },
      ],
    },
  ],
  type: 'Profile',
  abstractKey: null,
};

(node as any).hash = '3c00a1925ba9e52ea16cdb5345df9258';

export default node;
