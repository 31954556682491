/**
 * @generated SignedSource<<dadf274b603e378282193aa27e9e8811>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProfileQueriesCrewProfileInfoQuery$variables = {
  id: string;
};
export type ProfileQueriesCrewProfileInfoQuery$data = {
  readonly node: {
    readonly certificateInfos?: ReadonlyArray<{
      readonly certificate: {
        readonly id: string;
      };
    }> | null;
    readonly info?: {
      readonly avatarUrl: string | null;
      readonly birthDate: String;
      readonly country: {
        readonly id: string;
        readonly isoCode: string;
        readonly name: string;
      };
      readonly firstName: string;
      readonly lastName: string;
      readonly primaryDuty: {
        readonly name: string;
      } | null;
    } | null;
    readonly seaServices?: ReadonlyArray<{
      readonly id: string;
      readonly vessel: {
        readonly imo: number;
        readonly name: string;
      };
    }> | null;
  } | null;
};
export type ProfileQueriesCrewProfileInfoQuery = {
  response: ProfileQueriesCrewProfileInfoQuery$data;
  variables: ProfileQueriesCrewProfileInfoQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'imo',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      concreteType: 'Certificate',
      kind: 'LinkedField',
      name: 'certificate',
      plural: false,
      selections: [v2 /*: any*/],
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'firstName',
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'lastName',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'avatarUrl',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'birthDate',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      concreteType: 'Country',
      kind: 'LinkedField',
      name: 'country',
      plural: false,
      selections: [
        v2 /*: any*/,
        v3 /*: any*/,
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'isoCode',
          storageKey: null,
        },
      ],
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileQueriesCrewProfileInfoQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              kind: 'InlineFragment',
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'SeaService',
                  kind: 'LinkedField',
                  name: 'seaServices',
                  plural: true,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Vessel',
                      kind: 'LinkedField',
                      name: 'vessel',
                      plural: false,
                      selections: [v3 /*: any*/, v4 /*: any*/],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'CertificateInfo',
                  kind: 'LinkedField',
                  name: 'certificateInfos',
                  plural: true,
                  selections: [v5 /*: any*/],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'ProfileInfo',
                  kind: 'LinkedField',
                  name: 'info',
                  plural: false,
                  selections: [
                    v6 /*: any*/,
                    v7 /*: any*/,
                    v8 /*: any*/,
                    v9 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Duty',
                      kind: 'LinkedField',
                      name: 'primaryDuty',
                      plural: false,
                      selections: [v3 /*: any*/],
                      storageKey: null,
                    },
                    v10 /*: any*/,
                  ],
                  storageKey: null,
                },
              ],
              type: 'Profile',
              abstractKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileQueriesCrewProfileInfoQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: '__typename',
              storageKey: null,
            },
            {
              kind: 'InlineFragment',
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'SeaService',
                  kind: 'LinkedField',
                  name: 'seaServices',
                  plural: true,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Vessel',
                      kind: 'LinkedField',
                      name: 'vessel',
                      plural: false,
                      selections: [v3 /*: any*/, v4 /*: any*/, v2 /*: any*/],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'CertificateInfo',
                  kind: 'LinkedField',
                  name: 'certificateInfos',
                  plural: true,
                  selections: [v5 /*: any*/, v2 /*: any*/],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'ProfileInfo',
                  kind: 'LinkedField',
                  name: 'info',
                  plural: false,
                  selections: [
                    v6 /*: any*/,
                    v7 /*: any*/,
                    v8 /*: any*/,
                    v9 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Duty',
                      kind: 'LinkedField',
                      name: 'primaryDuty',
                      plural: false,
                      selections: [v3 /*: any*/, v2 /*: any*/],
                      storageKey: null,
                    },
                    v10 /*: any*/,
                    v2 /*: any*/,
                  ],
                  storageKey: null,
                },
              ],
              type: 'Profile',
              abstractKey: null,
            },
            v2 /*: any*/,
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: 'e9b1d1144de1bc213d3e4b76e881f534',
      id: null,
      metadata: {},
      name: 'ProfileQueriesCrewProfileInfoQuery',
      operationKind: 'query',
      text: 'query ProfileQueriesCrewProfileInfoQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Profile {\n      seaServices {\n        id\n        vessel {\n          name\n          imo\n          id\n        }\n      }\n      certificateInfos {\n        certificate {\n          id\n        }\n        id\n      }\n      info {\n        firstName\n        lastName\n        avatarUrl\n        birthDate\n        primaryDuty {\n          name\n          id\n        }\n        country {\n          id\n          name\n          isoCode\n        }\n        id\n      }\n    }\n    id\n  }\n}\n',
    },
  };
})();

(node as any).hash = '71f02336360cb22933d6e26f6f6842aa';

export default node;
