//types
import { OverviewCrewInfoEvTitleProps } from 'app/ProfileCrew/type/ProfileCrewTypes';

const OverviewCrewInfoEvTitle = ({ title, description, children }: OverviewCrewInfoEvTitleProps) => {
  return (
    <div className="flex flex-col w-full">
      <h2 className="text-2xl font-semibold text-specialGray-1 mb-2">{title}</h2>
      <div className="flex w-full text-specialGray-075 text-sm">
        <span>{description}</span>
        {children}
      </div>
    </div>
  );
};

export default OverviewCrewInfoEvTitle;
