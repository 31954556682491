// packages
import { Suspense } from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
// components
import SettingsLeftSide from 'app/AccountSettings/components/LeftSide/SettingsLeftSide';
import SettingRightSide from './components/RightSide/SettingRightSide';
import CrewSettingsSkeletonLeftSide from 'system/skeletons/settings/CrewSettingsSkeletonLeftSide';
import CrewSettingsSkeletonRightSide from 'system/skeletons/settings/CrewSettingsSkeletonRightSide';
// generated
import { ProfileFragments$key } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { ProfileQueriesRootQuery, ProfileQueriesRootQuery$data } from 'schemas/profile/__generated__/ProfileQueriesRootQuery.graphql';
// schemas
import { PROFILE_QUERY } from 'schemas/profile/ProfileQueries';
import { PROFILE_FRAGMENT } from 'schemas/profile/ProfileFragments';
// hooks
import { useAuth } from 'authentication';

const AccountSettings = () => {
  const { identity } = useAuth();

  const selfProfileQueryRef: ProfileQueriesRootQuery$data = useLazyLoadQuery<ProfileQueriesRootQuery>(PROFILE_QUERY, {
    id: `${identity?.profileId}`,
    skip: !identity?.profileId,
  });
  const profileData = useFragment<ProfileFragments$key>(PROFILE_FRAGMENT, selfProfileQueryRef.node || null);

  return (
    <div className="flex w-full min-h-[calc(100vh_-_136px)]">
      <Suspense fallback={<CrewSettingsSkeletonLeftSide />}>
        <SettingsLeftSide profileData={profileData} />
      </Suspense>
      <div className="w-full md:w-3/4">
        <Suspense fallback={<CrewSettingsSkeletonRightSide />}>
          <SettingRightSide />
        </Suspense>
      </div>
    </div>
  );
};

export default AccountSettings;
