// packages
import { FormikProvider, Form, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
// components
import { Button } from 'system/Button';
import { InputGroup } from 'system/InputGroups/InputGroup';
// formHelpers
import { AssumeProfileInitialValue } from 'formHelpers/initialValuesOfForms';
import { AssumeProfileFormValidateSchema } from 'formHelpers/validationsOfForms';
// models
import { IAssumeProfileForm } from 'models/modelsOfForms';
// routes
import { RoutePaths } from 'app/routing';

const AssumeProfileForm = () => {
  const navigate = useNavigate();

  const formik = useFormik<IAssumeProfileForm>({
    onSubmit: handleSubmit,
    initialValues: AssumeProfileInitialValue,
    validationSchema: AssumeProfileFormValidateSchema,
  });

  function handleSubmit(data: IAssumeProfileForm) {
    navigate(`${RoutePaths.adminAssume}/${data.profileId}`);
  }

  return (
    <FormikProvider value={formik}>
      <Form className="flex flex-col gap-y-6 w-full">
        <InputGroup
          autoFocus
          label={{ id: 'assume_profile_modal_form_input_label' }}
          name="profileId"
          type="number"
          placeholderText={{ id: 'assume_profile_modal_form_input_placeholder' }}
        />
        <Button type="submit" label={{ id: 'assume_profile_modal_form_button_label' }} fullSize disabled={formik.isSubmitting} />
      </Form>
    </FormikProvider>
  );
};

export default AssumeProfileForm;
