import { BreakpointsType } from 'models/commonTypes';

export enum ModeType {
  overview = 'Overview',
  seaServices = 'Sea Services',
  certificates = 'Certificates',
  education = 'Education',
  appraisals = 'Appraisals',
}

export enum PaymentSubscriptionLabelSizes {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
}
export enum PaymentSubscriptionType {
  TRIAL = '2',
  PREMIUM = '1',
}
export enum PaymentSubscriptionName {
  PRO = 'PRO',
  FREE = 'Free',
}
export enum PaymentSubscriptionTierType {
  FREE = 'tier-free',
  PRO = 'tier-pro',
  CUSTOM = 'tier-custorm',
}
export enum EmployerModeType {
  jobOpenings = 'Job Openings',
  'crewPools' = 'Crew pools',
  'organizationMembers' = 'Team members',
}

export enum JOB_RESPONSES_TITLE_ENUMS {
  invitation = 'Invitation',
  applied = 'Applied',
}

export enum JOB_OPENING_TITLE_ENUMS {
  jobOpening = 'Job opening overview',
  inviteCrew = 'Invite users to the job opening',
  reviewApplications = 'Review applications',
}

export const BREAKPOINTS: BreakpointsType = {
  sm: { min: 0, max: 639 },
  md: { min: 640, max: 1023 },
  lg: { min: 1024, max: 1279 },
  xl: { min: 1280, max: 1920 },
};

export enum VACANCY_STATUS {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum DASHBOARD_VACANCIES_FILTER_ENUM {
  SHOW_ALL = 'SHOW_ALL',
}

export enum INDUSTRY_LIST_ENUMS {
  NAUTICAL_WORLDWIDE = 'NAUTICAL_WORLDWIDE',
  OFFSHORE_WIND = 'OFFSHORE_WIND',
  OFFSHORE_OIL_GAS = 'OFFSHORE_OIL_GAS',
}

export enum DURATION_LIST_ENUMS {
  ONETERM = 'ONETERM',
  TEMPORARY = 'TEMPORARY',
  PERMANENT = 'PERMANENT',
}

export enum SALARY_PERIOD_ENUMS {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum STARTING_CONDITION_ENUMS {
  IMMEDIATELY = 'IMMEDIATELY',
  CONTINUOUSLY = 'CONTINUOUSLY',
  DATE = 'DATE',
}

export enum ORDER_DIRECTION {
  ASC = 'ASC',
  DESC = 'DESC',
}
export enum ORDER_BY {
  START_DATE = 'START_DATE',
}

export enum VACANCY_ORDER_FIELD {
  CREATED_AT = 'CREATED_AT',
  LAST_INVITED_AT = 'LAST_INVITED_AT',
  STATUS = 'STATUS',
}

export enum FILTER_TYPE {
  DUTIES = 'duties',
  PRIMARY_DUTIES = 'primary-duty',
  MOST_RECENT_SERVICE_DUTY = 'most-recent-service-duty',
  CERTIFICATES = 'certificates',
  VESSEL_TYPES = 'vessel-types',
  ACTIVITIES = 'activities',
  EDUCATION = 'education',
  COUNTRIES = 'countries',
  WORKED_FOR = 'worked-for',
  HIRING_AREA = 'hiring-area',
  INDUSTRY = 'industry',
  DURATION = 'duration',
  STARTING_CONDITIONAL = 'starting-conditional',
  STARTING_DATE = 'starting-date',
  SALARY_PERIOD = 'salary-period',
  CREW_VESSEL_IMO = 'imo',
  AVAILABLE_FOR_WORK = 'available',

  NUMBER_OF_SEA_SERVICES_FROM = 'ssf',
  NUMBER_OF_SEA_SERVICES_TO = 'sst',
  NUMBER_OF_EXPERIENCE_FROM = 'exp-from',
  NUMBER_OF_EXPERIENCE_TO = 'exp-to',
  NUMBER_OF_APPRAISALS_FROM = 'appr-from',
  NUMBER_OF_APPRAISALS_TO = 'appr-to',
  NUMBER_OF_MIN_SALARY = 'ms',
}
export enum FILTER_BOOLEAN_ID {
  AVAILABLE_FOR_WORK = 'available-for-work',
  NOT_AVAILABLE_FOR_WORK = 'not-available-for-work',
}

export enum VACANCY_DESCRIPTION_TYPE_OF_LIST {
  APPLIED = 'APPLIED',
  INVITED = 'INVITED',
  MATCHED = 'MATCHED',
}

export enum NOTIFICATION_PREFERENCE {
  jobOpeningNotifications = 'jobOpening',
  tipsToImproveNotifications = 'tipsToImprove',
}
export enum PERSONAL_GENERAL_SETTINGS {
  availableForWork = 'availableForWork',
  hideContactInfo = 'hideContactInfo',
}

export enum ORGANIZATION_SIZE {
  SIZE_1_10 = 'SIZE_1_10',
  SIZE_11_50 = 'SIZE_11_50',
  SIZE_51_200 = 'SIZE_51_200',
  SIZE_201_500 = 'SIZE_201_500',
  SIZE_501_1000 = 'SIZE_501_1000',
  SIZE_MORE_1000 = 'SIZE_MORE_1000',
}

export enum SIGN_UP_STEP_WITH_USER_EXPERIENCE {
  YEARS_0_1 = 'YEARS_0_1',
  YEARS_1_2 = 'YEARS_1_2',
  YEARS_2_5 = 'YEARS_2_5',
  YEARS_5_10 = 'YEARS_5_10',
  YEARS_10_20 = 'YEARS_10_20',
  YEARS_MORE_20 = 'YEARS_MORE_20',
}

export enum VACANCY_APPLICATION_STATUS {
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
}

export enum SEARCH_INDEX {
  PROFILE = 'PROFILE',
  VACANCY = 'VACANCY',
  ORGANIZATION = 'ORGANIZATION',
}

export enum PLATFORM_GROUP {
  HIRE = 'hire',
  CREW = 'crew',
  GUEST = 'guest',
}

export enum TAB_NAMES {
  OVERVIEW = 'OVERVIEW',
  SEA_SERVICE = 'SEA_SERVICE',
  CERTIFICATES = 'CERTIFICATES',
  EDUCATIONS = 'EDUCATIONS',
  APPRAISALS = 'APPRAISALS',
}

export enum DASHBOARD_TABS_ID {
  OPENED_TAB = 'OPENED_TAB',
  SHOW_ALL_TAB = 'SHOW_ALL_TAB',
}

export enum RESTRICTED_PROFILE_DATA_COUNTER {
  CERTIFICATES = 4,
  EDUCATIONS = 4,
  SEA_SERVICES = 3,
}
