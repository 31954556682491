// packages
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom';
// icons
import LogoFull from 'icons/LogoFullIcon';
// routing
import { RoutePaths } from 'app/routing';
// mocks
import { CrewSteps } from './steps';

const CrewSignUpStepsLayout = () => {
  const intl = useIntl();
  const { pathname, state } = useLocation();
  const numberOfSteps = useMemo<number>(() => Object.keys(CrewSteps).length, []);

  const currentStep = useMemo<number>(() => {
    const stepIndex = Object.values(CrewSteps).findIndex(screenName => pathname.includes(screenName));
    return stepIndex >= 0 ? stepIndex + 1 : 1;
  }, [pathname]);

  if (!state?.choseTypeOfAccount) {
    return <Navigate to={RoutePaths.AuthRegister} />;
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'register_form_meta_title' })}</title>
        <meta name="description" content={intl.formatMessage({ id: 'register_form_meta_description' })} />
        {/*hide register pages from crawlers*/}
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="flex flex-col">
        <header className="w-full h-[72px] flex justify-center items-center">
          <Link to={RoutePaths.AuthLogin}>
            <LogoFull />
          </Link>
        </header>
        <progress
          value={currentStep}
          max={numberOfSteps}
          className="w-full h-0.5 [&::-webkit-progress-bar]:bg-[#D9D9D9] [&::-webkit-progress-value]:bg-[#00BF78] [&::-webkit-progress-value]:transition-all [&::-webkit-progress-value]:ease-in-out [&::-webkit-progress-value]:duration-500"
        />
      </div>
      <main className="flex flex-col h-[calc(100vh_-_72px)] w-full">
        <section className="sm:my-auto mx-auto w-full sm:max-w-md h-full sm:h-auto">
          <Outlet />
        </section>
      </main>
    </>
  );
};

export default CrewSignUpStepsLayout;
