import { FC } from 'react';

const EmptyInvitationsIcon: FC = () => {
  return (
    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg" id="empty-invitations">
      <mask id="mask0_7172_63488" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="51" height="50">
        <rect x="0.5" width="50" height="50" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7172_63488)">
        <path
          d="M25.5 27.084L8.83332 16.6673V37.5007H27.5833V41.6673H8.83332C7.68749 41.6673 6.70693 41.2597 5.89166 40.4444C5.07499 39.6277 4.66666 38.6465 4.66666 37.5007V12.5007C4.66666 11.3548 5.07499 10.3743 5.89166 9.55898C6.70693 8.74232 7.68749 8.33398 8.83332 8.33398H42.1667C43.3125 8.33398 44.2937 8.74232 45.1104 9.55898C45.9257 10.3743 46.3333 11.3548 46.3333 12.5007V27.084C45.6736 27.084 44.9965 27.084 44.3021 27.084C43.6076 27.084 42.8958 27.084 42.1667 27.084V16.6673L25.5 27.084ZM25.5 22.9173L42.1667 12.5007H8.83332L25.5 22.9173ZM40.0833 47.9173L37.1667 45.0007L40.4479 41.6673H31.75V37.5007H40.4479L37.1146 34.1673L40.0833 31.2507L48.4167 39.584L40.0833 47.9173ZM8.83332 16.6673V37.5007V39.584C8.83332 36.1118 8.83332 33.1604 8.83332 30.7298C8.83332 28.2993 8.83332 27.084 8.83332 27.084C8.83332 27.084 8.83332 27.0923 8.83332 27.109C8.83332 27.127 8.83332 27.1708 8.83332 27.2402V16.6673Z"
          fill="#082051"
          fillOpacity="0.3"
        />
      </g>
    </svg>
  );
};

export default EmptyInvitationsIcon;
