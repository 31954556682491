//components
import DutyItemCrewsEv from '../components/DutyItemCrewsEV';
import CrewsEmployerViewTimeItem from '../components/CrewsEmployerViewTimeItem';
import AvatarCrewsOverviewItemEmployerView from '../components/AvatarCrewsOverviewItemEmployerView';
//type
import { DescriptionCrewsEvWrapperProps } from 'app/ProfileCrew/type/ProfileCrewTypes';

const CrewsSeaServiceEVWrapperView = ({
  timeFrom,
  timeTo,
  avatarUrl,
  dutyName,
  vesselName,
  children,
  additionalClassName,
  additionalFieldComponent = null,
}: DescriptionCrewsEvWrapperProps) => {
  return (
    <div
      id="CrewsSeaServiceEVWrapperView"
      className={`relative flex w-full min-h-[96px] rounded-[15px] border border-specialGray-012 items-center text-darkBlue bg-white ${additionalClassName}`}
    >
      <div className="flex w-full p-4">
        {/*//time*/}
        <div className="flex flex-col justify-center space-y-1 mr-6 items-center min-w-[60px]">
          <CrewsEmployerViewTimeItem time={timeFrom} />
          <CrewsEmployerViewTimeItem time={timeTo} />
        </div>
        <AvatarCrewsOverviewItemEmployerView avatarUrl={avatarUrl} />

        {/*content container*/}
        <div className="flex w-full flex-col ml-4">
          {/*header*/}
          <div className="flex w-full gap-x-3 items-center">
            <DutyItemCrewsEv title={dutyName} />
            <h4 className="text-lg uppercase font-medium">{vesselName}</h4>
            {additionalFieldComponent}
          </div>

          {/*additional content*/}
          <div className="flex w-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default CrewsSeaServiceEVWrapperView;
