/**
 * @generated SignedSource<<5f70638098d2d61db88837cea412e356>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OrganizationMemberRole = 'admin' | 'invited' | 'member' | 'owner' | '%future added value';
export type VacancyQueriesOrganizationMembersQuery$variables = {
  after?: any | null;
  first?: number | null;
  orgId: string;
  skip: boolean;
};
export type VacancyQueriesOrganizationMembersQuery$data = {
  readonly organizationMembers?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly organization: {
          readonly id: string;
          readonly name: string;
        };
        readonly profile: {
          readonly info: {
            readonly firstName: string;
            readonly lastName: string;
          } | null;
        };
        readonly role: OrganizationMemberRole;
      } | null;
    } | null> | null;
  } | null;
};
export type VacancyQueriesOrganizationMembersQuery = {
  response: VacancyQueriesOrganizationMembersQuery$data;
  variables: VacancyQueriesOrganizationMembersQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'after',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'first',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'orgId',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'skip',
    },
    v4 = {
      kind: 'Variable',
      name: 'orgId',
      variableName: 'orgId',
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'role',
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'firstName',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'lastName',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      concreteType: 'Organization',
      kind: 'LinkedField',
      name: 'organization',
      plural: false,
      selections: [
        v5 /*: any*/,
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: '__typename',
      storageKey: null,
    },
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cursor',
      storageKey: null,
    },
    v12 = {
      alias: null,
      args: null,
      concreteType: 'PageInfo',
      kind: 'LinkedField',
      name: 'pageInfo',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'endCursor',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'hasNextPage',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v13 = [
      {
        kind: 'Variable',
        name: 'after',
        variableName: 'after',
      },
      {
        kind: 'Variable',
        name: 'first',
        variableName: 'first',
      },
      v4 /*: any*/,
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'VacancyQueriesOrganizationMembersQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: 'organizationMembers',
              args: [v4 /*: any*/],
              concreteType: 'OrganizationMemberConnection',
              kind: 'LinkedField',
              name: '__organizationMembersListForForm_organizationMembers_connection',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'OrganizationMemberEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'OrganizationMember',
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [
                        v5 /*: any*/,
                        v6 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Profile',
                          kind: 'LinkedField',
                          name: 'profile',
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              concreteType: 'ProfileInfo',
                              kind: 'LinkedField',
                              name: 'info',
                              plural: false,
                              selections: [v7 /*: any*/, v8 /*: any*/],
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                        v9 /*: any*/,
                        v10 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    v11 /*: any*/,
                  ],
                  storageKey: null,
                },
                v12 /*: any*/,
              ],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v2 /*: any*/, v0 /*: any*/, v1 /*: any*/, v3 /*: any*/],
      kind: 'Operation',
      name: 'VacancyQueriesOrganizationMembersQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v13 /*: any*/,
              concreteType: 'OrganizationMemberConnection',
              kind: 'LinkedField',
              name: 'organizationMembers',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'OrganizationMemberEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'OrganizationMember',
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [
                        v5 /*: any*/,
                        v6 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Profile',
                          kind: 'LinkedField',
                          name: 'profile',
                          plural: false,
                          selections: [
                            {
                              alias: null,
                              args: null,
                              concreteType: 'ProfileInfo',
                              kind: 'LinkedField',
                              name: 'info',
                              plural: false,
                              selections: [v7 /*: any*/, v8 /*: any*/, v5 /*: any*/],
                              storageKey: null,
                            },
                            v5 /*: any*/,
                          ],
                          storageKey: null,
                        },
                        v9 /*: any*/,
                        v10 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    v11 /*: any*/,
                  ],
                  storageKey: null,
                },
                v12 /*: any*/,
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: v13 /*: any*/,
              filters: ['orgId'],
              handle: 'connection',
              key: 'organizationMembersListForForm_organizationMembers',
              kind: 'LinkedHandle',
              name: 'organizationMembers',
            },
          ],
        },
      ],
    },
    params: {
      cacheID: 'b707ab29d1d17ddc141ba37f72318172',
      id: null,
      metadata: {
        connection: [
          {
            count: 'first',
            cursor: 'after',
            direction: 'forward',
            path: ['organizationMembers'],
          },
        ],
      },
      name: 'VacancyQueriesOrganizationMembersQuery',
      operationKind: 'query',
      text: 'query VacancyQueriesOrganizationMembersQuery(\n  $orgId: ID!\n  $after: Cursor\n  $first: Int\n  $skip: Boolean!\n) {\n  organizationMembers(orgId: $orgId, after: $after, first: $first) @skip(if: $skip) {\n    edges {\n      node {\n        id\n        role\n        profile {\n          info {\n            firstName\n            lastName\n            id\n          }\n          id\n        }\n        organization {\n          id\n          name\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '41a6b81adfc7449769937533b0ef365d';

export default node;
