// components
import VacancySkeleton from 'system/skeletons/hireProfile/components/VacancySkeleton/VacancySkeleton';
import RightSideWithOrganizationInfoSkeleton from 'system/skeletons/hireProfile/JobDescriptionTabsSkeleton/components/RightSide/RightSideWithOrganizationInfoSkeleton';

const JobDescriptionCommonPreviewSkeleton = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="flex w-full gap-x-12 gap-y-9 lg:flex-row flex-col">
        <div className="xl:w-3/5 lg:w-1/2 w-full">
          <VacancySkeleton />
        </div>
        <RightSideWithOrganizationInfoSkeleton />
      </div>
    </div>
  );
};

export default JobDescriptionCommonPreviewSkeleton;
