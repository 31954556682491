import { useIntl } from 'react-intl';
import { FieldWrapperProps } from 'app/AccountSettings/types';

const FieldWrapper = ({ children, title, description }: FieldWrapperProps) => {
  const intl = useIntl();

  return (
    <section className="flex w-full lg:flex-row flex-col gap-y-6 gap-x-2">
      <div className="lg:w-1/3 w-full flex flex-col">
        <h5 className="font-medium text-darkBlue">{intl.formatMessage(title)}</h5>
        {description ? <span className="text-specialGray-075 text-xs xs:text-sm lg:max-w-[265px] max-w-full">{intl.formatMessage(description)}</span> : null}
      </div>
      {children}
    </section>
  );
};

export default FieldWrapper;
