// components
import RightSideListOfAppliedCrew from './components/RightSideListOfAppliedCrew';
import RightSideListOfInvitedCrew from './components/RightSideListOfInvitedCrew';
//generated
import { useSwitchVacancyStatus } from 'hooks/useSwitchVacancyStatus';
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';

const RightSideListsOfCrew = ({ vacancyFragment }: { vacancyFragment: VacancyFragments$key }) => {
  const { vacancy, handleSwitchStatus, isCommitSwitchVacancyLoading } = useSwitchVacancyStatus(vacancyFragment as VacancyFragments$key);

  return (
    <div className="w-2/5 flex flex-col space-y-8">
      <RightSideListOfInvitedCrew isCommitSwitchVacancyLoading={isCommitSwitchVacancyLoading} handleSwitchStatus={handleSwitchStatus} vacancy={vacancy} />
      <RightSideListOfAppliedCrew isCommitSwitchVacancyLoading={isCommitSwitchVacancyLoading} handleSwitchStatus={handleSwitchStatus} vacancy={vacancy} />
    </div>
  );
};

export default RightSideListsOfCrew;
