// types
import { PaymentSubscriptionName } from 'models/enums';

const PaymentSubscriptionProLabel = ({ className = '' }: { className?: string }) => {
  return (
    <span
      className={`flex items-center uppercase text-specialPurple rounded-full border border-specialPurple bg-specialPurple-012 px-2 text-[10px] font-semibold h-[16px] ${className}`}
    >
      {PaymentSubscriptionName.PRO}
    </span>
  );
};

export default PaymentSubscriptionProLabel;
