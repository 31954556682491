// packages
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
// models
import { CountryFlagProps } from 'models/modelsOfComponents';
// components
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';

const CountryFlag: React.FC<CountryFlagProps> = ({
  countryId,
  countryName,
  classes = 'mr-1',
  size,
  autoShowPopover,
  customPositionPopoverArrow = '-bottom-[3px] left-1/2 rotate-45',
  customPositionPopover = '-translate-y-full -top-2 -translate-x-1/2 left-3',
}) => {
  const intl = useIntl();

  const getTextForPopover = useMemo<string>(() => countryName || countryId || intl.formatMessage({ id: 'no_info' }), [countryId, countryName, intl]);

  const renderMemoCountryFlag = useMemo(
    () =>
      countryId ? (
        <>
          <img className={`${size ? size : 'w-6 h-4'} inline-block rounded ${classes}`} alt={countryName || ''} src={`https://flagcdn.com/w40/${countryId?.toLowerCase()}.png`} />
        </>
      ) : (
        <>
          <img className="inline-block w-6 h-4 mr-1 filter grayscale rounded" alt={intl.formatMessage({ id: 'no_info' })} src={'https://flagcdn.com/w40/at.png'} />
        </>
      ),
    [classes, countryId, countryName, intl, size],
  );
  return (
    <PopoverHelper
      widthContainer="min-w-[100px] w-fit"
      defaultText={getTextForPopover}
      position={customPositionPopover}
      positionArrow={customPositionPopoverArrow}
      element={renderMemoCountryFlag}
      autoShow={autoShowPopover}
      darkTheme
    />
  );
};

export default CountryFlag;
