//packages
import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
// skeleton
import ListOfEducationsSkeleton from './components/ListOfEducationsSkeleton/ListOfEducationsSkeleton';
import ListOfSeaServicesSkeleton from './components/ListOfSeaServicesSkeleton/ListOfSeaServicesSkeleton';
import ListOfCertificatesSkeleton from './components/ListOfCertificatesSkeleton/ListOfCertificatesSkeleton';

const SearchProfileSlideSkeleton: FC = () => {
  return (
    <div className="flex w-full flex-col">
      <section className="flex space-x-5 mb-4">
        <Skeleton width={192} height={192} borderRadius={16} />
        <div className="flex flex-col justify-end mb-4">
          <div className="flex flex-col space-y-2">
            <Skeleton width={200} height={24} />
            <Skeleton width={150} height={14} />
          </div>
          <div className="flex space-x-2">
            <Skeleton width={204} height={37} borderRadius={6} />
            <Skeleton width={112} height={37} borderRadius={6} />
          </div>
        </div>
      </section>
      <section className="flex flex-col space-y-1.5">
        <Skeleton width={60} height={14} />
        <div className="flex flex-col space-y-1">
          <Skeleton width="100%" height={14} />
          <Skeleton width="100%" height={14} />
          <Skeleton width="100%" height={14} />
          <Skeleton width="50%" height={14} />
        </div>
      </section>
      <section className="flex flex-col space-y-4 w-full mt-4">
        <div className="flex w-full">
          <Skeleton width={30} height={12} containerClassName="w-1/2" />
          <Skeleton width={100} height={12} />
        </div>
        <div className="flex w-full">
          <Skeleton width={100} height={12} containerClassName="w-1/2" />
          <Skeleton width={90} height={12} />
        </div>
        <div className="flex w-full">
          <Skeleton width={110} height={12} containerClassName="w-1/2" />
          <Skeleton width={100} height={12} />
        </div>
        <div className="flex w-full">
          <Skeleton width={40} height={12} containerClassName="w-1/2" />
          <Skeleton width={190} height={12} />
        </div>
        <div className="flex w-full">
          <Skeleton width={120} height={12} containerClassName="w-1/2" />
          <Skeleton width={100} height={12} />
        </div>
      </section>
      <ListOfSeaServicesSkeleton />
      <ListOfCertificatesSkeleton />
      <ListOfEducationsSkeleton />
    </div>
  );
};

export default SearchProfileSlideSkeleton;
