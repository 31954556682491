import React from 'react';
import Skeleton from 'react-loading-skeleton';

const DashboardTableHeaderSkeleton: React.FC = () => {
  return (
    <div className="mt-6">
      <section className="flex flex-row rounded-2xl rounded-b-none px-3 py-3 border border-b-0 md:border-b-1 bg-white md:bg-specialGray-004">
        {/*serialNumber*/}
        <Skeleton width={16} height={16} containerClassName="hidden md:flex items-center pl-3 w-5%" />
        {/*STATUS*/}
        <Skeleton width={45} height={16} containerClassName="hidden md:flex items-center w-5%" />
        {/*POSITION TITLE*/}
        <Skeleton width={90} height={16} containerClassName="hidden md:flex items-center w-1/5 pl-4" />
        {/*POSITIONS (DUTIES)*/}
        <Skeleton width={55} height={16} containerClassName="hidden md:flex items-center w-[13%] pl-4" />
        {/*VESSEL TYPE*/}
        <Skeleton width={75} height={16} containerClassName="hidden md:flex items-center w-[15%] pl-4" />
        {/*SALARY RANGE*/}
        <Skeleton width={75} height={16} containerClassName="hidden md:flex items-center w-[10%]" />
        {/*INDUSTRY*/}
        <Skeleton width={75} height={16} containerClassName="hidden md:flex items-center w-[10%]" />
        {/*APPLIED CREW*/}
        <Skeleton width={75} height={16} containerClassName="hidden md:flex items-center w-[7%]" />
        {/*POSTING DATE*/}
        <Skeleton width={70} height={16} containerClassName="hidden md:flex items-center w-[10%]" />
        {/*setting button*/}
        <div className="hidden md:flex w-5%" />
      </section>
    </div>
  );
};

export default DashboardTableHeaderSkeleton;
