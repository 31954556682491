import { useIntl } from 'react-intl';
import isAfter from 'date-fns/isAfter';
import format from 'date-fns/format';
//components
import DangerIcon from 'icons/DangerIcon';
import CheckCircleIcon from 'icons/CheckCircleIcon';
import ErrorCircleRoundedIcon from 'icons/ErrorCircleRoundedIcon';

const CertificateWithoutDocDateItem = ({ expiryDate }: { expiryDate?: string | number }) => {
  const intl = useIntl();
  const dateExpired = expiryDate && isAfter(new Date(), new Date(expiryDate));
  const date = expiryDate && format(new Date(expiryDate), 'dd MMM yy');

  const isExpiredDate = dateExpired && date;
  const isNotExpiredDate = !dateExpired && date;
  const dateIsNotExist = !expiryDate;

  return (
    <div className="flex text-lg sm:w-24 self-start sm:self-center">
      {isExpiredDate && <DangerIcon />}
      {isNotExpiredDate && (
        <div className="text-[#43E5A9]">
          <CheckCircleIcon />
        </div>
      )}
      {dateIsNotExist && (
        <div className="text-[#F96434]">
          <ErrorCircleRoundedIcon />
        </div>
      )}
      <div className="flex items-center text-sm">
        <span className="flex items-center ml-2.5 sm:whitespace-nowrap">{date || intl.formatMessage({ id: 'search_modal_posting_date_dead_line_empty' })}</span>
      </div>
    </div>
  );
};

export default CertificateWithoutDocDateItem;
