// components
import { Button } from 'system/Button';
import ShieldIcon from 'icons/ShieldIcon';
import DeleteIcon from 'icons/DeleteIcon';
// models
import { EssentialCertificateItemProps } from 'models/modelsOfComponents';
// utils
import { sliceAltsIfLong } from 'utils';

const RequiredCertificateItem = ({ certificate, removeItem }: EssentialCertificateItemProps) => {
  return (
    <div
      data-test-id={certificate?.name}
      className="flex items-center px-4 py-2 border border-specialGray-012 w-full first:rounded-t-xl last:rounded-b-xl [&:not(:first-child)]:border-t-0"
      key={certificate?.id}
    >
      <ShieldIcon kind={certificate?.kind?.name} />
      <span className="text-sm text-specialGray-1 ml-4 ">{certificate?.name}</span>
      {certificate?.alts && <div className="text-specialGray-03 text-xs ml-2">{sliceAltsIfLong(certificate?.alts as Array<string>, 30)}</div>}
      <Button buttonType="icon-button" onClick={removeItem(certificate?.id)} icon={DeleteIcon} iconWrapperSize="w-5 h-5" additionalClasses="ml-auto" />
    </div>
  );
};

export default RequiredCertificateItem;
