import HeaderSkeleton from './components/HeaderSkeleton';
import ServiceListItemSkeleton from './components/ServiceListItemSkeleton';

const ServicesListTabSkeleton = () => {
  return (
    <div className="flex flex-col px-6">
      <HeaderSkeleton widthBigTitle={155} />
      <ServiceListItemSkeleton />
      <ServiceListItemSkeleton />
      <ServiceListItemSkeleton />
    </div>
  );
};

export default ServicesListTabSkeleton;
