// packages
import { useState } from 'react';
// hooks
import useGetIsProSubscription from './useGetIsProSubscription';
import { useGetNumberOfOpenedAndClosedVacancies } from 'hooks/hireProfileHooks';

// set restricted if free subscription and more than one opened job opening
const useIsRestrictedFromPublishJobOpening = () => {
  const [maxNumberOfActiveVacancies] = useState<number>(2);

  const isProSubscription = useGetIsProSubscription();
  const { getNumberOfOpenedVacancies } = useGetNumberOfOpenedAndClosedVacancies();

  return !isProSubscription && getNumberOfOpenedVacancies >= maxNumberOfActiveVacancies;
};

export default useIsRestrictedFromPublishJobOpening;
