// packages
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
// components
import FilterIcon from 'icons/FilterIcon';
import CustomButton from 'system/Buttons/CustomButton';
import FilterComponentSkeleton from 'system/skeletons/filters/FilterComponentSkeleton';
import FilterSliderWrapper from 'system/Layouts/FilterSliderWrapper/FilterSliderWrapper';
import ListOfCrewSkeleton from 'system/skeletons/lists/ListOfCrewSkeleton/ListOfCrewSkeleton';
import FilterSlideComponent from 'system/FilterSlideComponents/ProfileFiltering/FilterSlideComponent';
import ProfileSearchMembers from 'app/ProfileSearch/components/ProfileSearchMembers/ProfileSearchMembers';
import SelectedProfileFilterElements from 'system/FilterSlideComponents/components/SelectedFilterElements/SelectedProfileFilterElements';
// models
import { ProfileSearchItemState } from 'models/routeLocationState';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';

const ProfileSearchPage = ({ defaultOpenFilterModal }: { defaultOpenFilterModal?: boolean }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);

  const [isOpenListOfFilters, setIsOpenListOfFilters] = useState<boolean>(defaultOpenFilterModal || false);

  const openFilter = () => {
    setIsOpenListOfFilters(prev => !prev);
  };

  const handleBeforeUnload = useCallback(() => {
    navigate(pathname, {
      replace: true,
      state: {
        isOpenProfileSlide: (state as ProfileSearchItemState)?.isOpenProfileSlide,
        isOpenCertificateSlide: (state as ProfileSearchItemState)?.isOpenCertificateSlide,
      },
    });
  }, [navigate, pathname, state]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  return (
    <>
      <div className="flex-col w-full">
        <div className="flex justify-between py-2">
          <div className="pl-2">
            <h2 id="sea-service-title" className="flex-1 min-w-0 text-2xl leading-6 font-semibold text-specialGray-1">
              {intl.formatMessage({ id: 'search_list_title' })}
            </h2>
          </div>
          {getCurrentOrganisation.isSelected && (
            <CustomButton
              label={{ id: 'label_filter' }}
              onClick={openFilter}
              classNameStyle="w-28 justify-between text-sm disabled:opacity-50 px-5 py-3.5 border border-specialGray-05 text-sm leading-4 font-normal rounded-xl hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 mr-1 max-h-12"
            >
              <FilterIcon />
            </CustomButton>
          )}
        </div>
        {getCurrentOrganisation.isSelected && (
          <Suspense>
            <SelectedProfileFilterElements />
          </Suspense>
        )}
        <Suspense fallback={<ListOfCrewSkeleton />}>
          <ProfileSearchMembers />
        </Suspense>
      </div>
      {getCurrentOrganisation.isSelected && (
        <FilterSliderWrapper filterButtonEvent={openFilter} isOpenListOfFilters={isOpenListOfFilters}>
          <Suspense fallback={<FilterComponentSkeleton />}>{isOpenListOfFilters && <FilterSlideComponent />}</Suspense>
        </FilterSliderWrapper>
      )}
    </>
  );
};

export default ProfileSearchPage;
