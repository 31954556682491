// packages
import { useIntl } from 'react-intl';
//models
import { NoSearchResultProps } from 'app/Navbar/types';

const NoSearchResult = ({ message, showBorderBottom, children }: NoSearchResultProps) => {
  const intl = useIntl();
  return (
    <div className={`flex flex-col text-specialGray-075 h-[153px] items-center justify-center rounded-md gap-y-2 ${showBorderBottom ? 'border-b' : ''}`}>
      <div>{children}</div>
      <span>{intl.formatMessage(message)}</span>
    </div>
  );
};

export default NoSearchResult;
