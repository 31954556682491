const ProfileGreenIcon = () => {
  return (
    <svg width="48" height="55" viewBox="0 0 48 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_9266_78375)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.69468 20.1284C9.69468 24.6082 13.3605 28.1983 17.935 28.1983C22.5078 28.1983 26.1753 24.6082 26.1753 20.1284C26.1753 15.6486 22.5078 12.0586 17.935 12.0586C13.3605 12.0586 9.69468 15.6486 9.69468 20.1284ZM30.3887 37.3353C30.3887 33.1883 24.6519 32.1508 17.9353 32.1508C11.1822 32.1508 5.48199 33.2241 5.48199 37.3743C5.48199 41.5212 11.2187 42.5588 17.9353 42.5588C24.6884 42.5588 30.3887 41.4855 30.3887 37.3353Z"
          fill="url(#paint0_linear_9266_78375)"
        />
      </g>
      <g filter="url(#filter1_f_9266_78375)">
        <path
          d="M20.6397 34.8086C24.7406 34.8086 28.2432 35.4977 28.2432 38.2518C28.2432 41.008 24.7628 41.7208 20.6397 41.7208C16.5388 41.7208 13.0362 41.0318 13.0362 38.2777C13.0362 35.5214 16.5165 34.8086 20.6397 34.8086Z"
          fill="#75FF34"
          fillOpacity="0.5"
        />
      </g>
      <g filter="url(#filter2_f_9266_78375)">
        <path
          d="M19.9484 24.2127C17.646 24.2127 15.801 22.3676 15.801 20.0653C15.801 17.763 17.646 15.918 19.9484 15.918C22.2498 15.918 24.0957 17.763 24.0957 20.0653C24.0957 22.3676 22.2498 24.2127 19.9484 24.2127Z"
          fill="#75FF34"
          fillOpacity="0.5"
        />
      </g>
      <g filter="url(#filter3_b_9266_78375)">
        <mask id="path-4Outside-1_9266_78375" maskUnits="userSpaceOnUse" x="12.5176" y="8" width="35" height="42" fill="black">
          <rect fill="white" x="12.5176" y="8" width="35" height="42" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.3792 19.5831C19.3792 25.4582 24.1868 30.1663 30.1859 30.1663C36.1829 30.1663 40.9926 25.4582 40.9926 19.5831C40.9926 13.7081 36.1829 9 30.1859 9C24.1868 9 19.3792 13.7081 19.3792 19.5831ZM46.5176 42.1497C46.5176 36.7112 38.9942 35.3505 30.1857 35.3505C21.3294 35.3505 13.8539 36.7581 13.8539 42.2008C13.8539 47.6393 21.3773 49 30.1857 49C39.042 49 46.5176 47.5925 46.5176 42.1497Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.3792 19.5831C19.3792 25.4582 24.1868 30.1663 30.1859 30.1663C36.1829 30.1663 40.9926 25.4582 40.9926 19.5831C40.9926 13.7081 36.1829 9 30.1859 9C24.1868 9 19.3792 13.7081 19.3792 19.5831ZM46.5176 42.1497C46.5176 36.7112 38.9942 35.3505 30.1857 35.3505C21.3294 35.3505 13.8539 36.7581 13.8539 42.2008C13.8539 47.6393 21.3773 49 30.1857 49C39.042 49 46.5176 47.5925 46.5176 42.1497Z"
          fill="#D0FF95"
          fillOpacity="0.4"
        />
        <path
          d="M30.1859 31.1663C23.6543 31.1663 18.3792 26.03 18.3792 19.5831H20.3792C20.3792 24.8863 24.7192 29.1663 30.1859 29.1663V31.1663ZM41.9926 19.5831C41.9926 26.0302 36.7152 31.1663 30.1859 31.1663V29.1663C35.6505 29.1663 39.9926 24.8861 39.9926 19.5831H41.9926ZM30.1859 8C36.7152 8 41.9926 13.1361 41.9926 19.5831H39.9926C39.9926 14.2801 35.6505 10 30.1859 10V8ZM18.3792 19.5831C18.3792 13.1362 23.6543 8 30.1859 8V10C24.7192 10 20.3792 14.28 20.3792 19.5831H18.3792ZM30.1857 34.3505C34.6119 34.3505 38.833 34.6886 41.9785 35.7645C43.557 36.3045 44.9374 37.0541 45.9314 38.1063C46.9479 39.1824 47.5176 40.5321 47.5176 42.1497H45.5176C45.5176 41.048 45.1468 40.1883 44.4775 39.4797C43.7855 38.7472 42.7346 38.1369 41.3312 37.6569C38.5129 36.6928 34.5681 36.3505 30.1857 36.3505V34.3505ZM12.8539 42.2008C12.8539 40.5845 13.4189 39.2324 14.43 38.1512C15.4192 37.0935 16.7946 36.3366 18.371 35.7899C21.5118 34.7004 25.7341 34.3505 30.1857 34.3505V36.3505C25.7811 36.3505 21.8374 36.7044 19.0264 37.6794C17.6269 38.1649 16.5799 38.7804 15.8907 39.5174C15.2234 40.2309 14.8539 41.0958 14.8539 42.2008H12.8539ZM30.1857 50C25.7596 50 21.5385 49.662 18.393 48.586C16.8145 48.0461 15.4341 47.2965 14.4401 46.2443C13.4236 45.1681 12.8539 43.8184 12.8539 42.2008H14.8539C14.8539 43.3025 15.2246 44.1623 15.894 44.8709C16.5859 45.6033 17.6369 46.2136 19.0403 46.6937C21.8586 47.6577 25.8034 48 30.1857 48V50ZM47.5176 42.1497C47.5176 43.766 46.9526 45.1181 45.9415 46.1993C44.9523 47.257 43.5769 48.0139 42.0005 48.5607C38.8596 49.6501 34.6374 50 30.1857 50V48C34.5904 48 38.5341 47.6461 41.3451 46.6711C42.7446 46.1857 43.7916 45.5701 44.4808 44.8332C45.1481 44.1196 45.5176 43.2548 45.5176 42.1497H47.5176Z"
          fill="url(#paint1_linear_9266_78375)"
          mask="url(#path-4Outside-1_9266_78375)"
        />
      </g>
      <defs>
        <filter id="filter0_b_9266_78375" x="-7.51758" y="-0.941406" width="50.9062" height="56.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_9266_78375" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_9266_78375" result="shape" />
        </filter>
        <filter id="filter1_f_9266_78375" x="0.0361328" y="21.8086" width="41.207" height="32.9141" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_9266_78375" />
        </filter>
        <filter id="filter2_f_9266_78375" x="0.800781" y="0.917969" width="38.2949" height="38.293" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_9266_78375" />
        </filter>
        <filter id="filter3_b_9266_78375" x="-2.14648" y="-7" width="64.6641" height="72" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_9266_78375" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_9266_78375" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_9266_78375" x1="-1.12205" y1="7.68434" x2="27.9455" y2="28.7391" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C8EB9C" />
          <stop offset="1" stopColor="#7ACC11" />
        </linearGradient>
        <linearGradient id="paint1_linear_9266_78375" x1="41.3181" y1="13.6601" x2="15.2179" y2="40.0569" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProfileGreenIcon;
