import { PropsWithChildren } from 'react';

const TabsWrapperLayout = ({ children }: PropsWithChildren) => {
  return (
    <div className="hidden sm:flex items-center justify-between space-x-5">
      <div className="w-full border-b border-specialGray-012">
        <nav className="-mb-px flex mt-5" aria-label="Tabs">
          {children}
        </nav>
      </div>
    </div>
  );
};

export default TabsWrapperLayout;
