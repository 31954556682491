// packages
import isChromatic from 'chromatic';
import { useIntl } from 'react-intl';
// models
import { JobExperienceProps } from 'models/modelsOfComponents';

const SeaServiceJobExperience = ({ days, months, years, classes }: JobExperienceProps) => {
  const intl = useIntl();

  return (
    <p className={classes}>
      {isChromatic() ? (
        intl.formatMessage({ id: 'chromatic_sea_service_job_experience' })
      ) : (
        <>
          <span className="mr-1">{!!years && `${years} year${years > 1 ? 's' : ''}`}</span>
          <span className="mr-1">{!!months && `${months} month${months > 1 ? 's' : ''}`}</span>
          {/*hide days if years exist*/}
          {!years ? <span className="mr-1">{!!days && `${days} day${days > 1 ? 's' : ''}`}</span> : null}
          <span className="mr-1 lowercase">{intl.formatMessage({ id: 'profile_sea_services_total' })}</span>
        </>
      )}
    </p>
  );
};

export default SeaServiceJobExperience;
