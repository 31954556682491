// packages
import { useRef, useEffect, useState, useCallback, ChangeEvent } from 'react';
import { uniqueId } from 'lodash';
import { useField } from 'formik';
import { useIntl } from 'react-intl';
// components
import { FieldErrorMessage } from '../FieldErrorMessage';
import InputComponent from './components/InputComponent';
import LabelComponent from 'system/LabelComponent/LabelComponent';
// models
import { PinCodeProps } from './types';
// utils
import { getLastElementFromArray } from 'utils';

export const PinCodev2 = ({
  name,
  description,
  label,
  maxLength,
  skipQuery = false,
  inputType,
  autoFocus,
  additionalClasses = 'sm:mt-5 mt-4 h-12 sm:h-9 ',
  trackingCallback,
}: PinCodeProps) => {
  const intl = useIntl();
  const [{ value: inputValue }, { touched, error, initialTouched, initialError }, { setValue, setTouched }] = useField<string>(name);
  const [showError, setShowError] = useState<string | false | undefined>(touched && error);

  useEffect(() => {
    setShowError((touched || initialTouched) && (error || initialError));
  }, [touched, error, initialTouched, initialError]);

  useEffect(() => {
    return () => {
      setTouched(false);
    };
    // eslint-disable-next-line
  }, []);

  const emptyArray: Array<string> = new Array(maxLength).fill('');
  const revealRefs = useRef<Array<HTMLInputElement>>([]);
  const [pastedValue, setPastedValue] = useState<string>('');

  const [id] = useState<string>(() => uniqueId('ig-'));
  const containerRef = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState<boolean>(false);

  const [cleanLetterSpacing, setCleanLetterSpacing] = useState<number>(0);

  const handleResize = useCallback(() => {
    setCleanLetterSpacing(100 / maxLength);
  }, [maxLength]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    // clean up on destroy
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const addToRefs = useCallback((el: HTMLInputElement) => {
    if (el && !revealRefs.current?.includes(el)) {
      revealRefs.current?.push(el);
    }
  }, []);

  const handleClickByInputs = useCallback((el: HTMLInputElement) => {
    const element: HTMLInputElement = revealRefs.current.find((ref: HTMLInputElement) => ref === el) as HTMLInputElement;

    if (!element) {
      return;
    }
    setFocused(true);
    if (element.value) {
      if (element.nextElementSibling) {
        if (!(element.nextElementSibling as HTMLInputElement).value) {
          (element.nextElementSibling as HTMLInputElement).focus();
        } else if ((element.nextElementSibling as HTMLInputElement).value) {
          handleClickByInputs(element.nextElementSibling as HTMLInputElement);
        }
      } else {
        element.focus();
      }
      if (element.previousElementSibling && element.nextElementSibling && !(element.nextElementSibling as HTMLInputElement).value) {
        handleClickByInputs(element.nextElementSibling as HTMLInputElement);
      }
    } else {
      if (element.previousElementSibling) {
        if (!(element.previousElementSibling as HTMLInputElement).value) {
          handleClickByInputs(element.previousElementSibling as HTMLInputElement);
        }
        if ((element.previousElementSibling as HTMLInputElement).value) {
          element.focus();
        }
      } else {
        element.focus();
      }
    }
  }, []);

  useEffect(() => {
    const element: HTMLDivElement = containerRef.current as HTMLDivElement;
    element.addEventListener('mousedown', event => event.preventDefault());
    element.addEventListener('click', event => handleClickByInputs(event.target as HTMLInputElement));
    return () => {
      element.removeEventListener('mousedown', event => event.preventDefault());
      element.removeEventListener('click', event => handleClickByInputs(event.target as HTMLInputElement));
    };
  }, [handleClickByInputs]);

  const handleKeyDown = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      if (inputValue.length === maxLength) {
        setPastedValue(inputValue);
        return;
      }
      if (inputValue.length > 1) {
        event.target.value = inputValue.slice(0, 1);
      }
      if (inputValue && !event.target.nextElementSibling) {
        event.target.focus();
      }
      if (event.target.nextElementSibling) {
        (event.target.nextElementSibling as HTMLDivElement).focus();
      }
      if (!event.target.nextElementSibling && inputValue) {
        let code = '';
        for (let char of revealRefs.current) {
          code += char.value;
        }
        setValue(code);
        setFocused(false);
        event.target.blur();
      }
    },
    [maxLength, setValue],
  );

  const handleSetValue = useCallback(
    (value: string, lastElementInFocus: boolean) => {
      for (let ref = 0; ref < revealRefs.current.length; ref++) {
        revealRefs.current[ref].value = value[ref];
        if (getLastElementFromArray<HTMLInputElement>(revealRefs.current) === revealRefs.current[ref] && lastElementInFocus) {
          getLastElementFromArray<HTMLInputElement>(revealRefs.current)?.focus();
        }
      }
      setValue(value);
      if (trackingCallback && value.length === maxLength) {
        trackingCallback(value);
      }
    },
    // eslint-disable-next-line
    [maxLength],
  );

  useEffect(() => {
    if (!!pastedValue) {
      handleSetValue(pastedValue, true);
    }
  }, [handleSetValue, pastedValue]);

  const handleFocus = useCallback(() => {
    setShowError(undefined);
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
    setTouched(true);
  }, [setTouched]);

  useEffect(() => {
    if (`${inputValue}`.length === maxLength && !pastedValue) {
      let oldCode = '';
      for (const element of revealRefs.current) {
        oldCode += element.value;
      }
      if (oldCode !== inputValue) {
        handleSetValue(`${inputValue}`, false);
      }
    }
    // eslint-disable-next-line
  }, [inputValue, handleSetValue, maxLength, cleanLetterSpacing, pastedValue]);

  const handleRemoveValueFromInput = useCallback(() => {
    setValue(`${inputValue}`.slice(0, -1));
  }, [inputValue, setValue]);

  useEffect(() => {
    if (`${inputValue}`.length < maxLength && !focused && !skipQuery && touched && initialTouched) {
      setShowError('service_imo_incomplete');
    }
    // eslint-disable-next-line
  }, [inputValue, maxLength, focused, skipQuery, touched, initialTouched]);

  const setFocusForFirstElement = () => {
    revealRefs?.current[0]?.focus();
  };

  useEffect(() => {
    if (autoFocus) {
      window.addEventListener('load', setFocusForFirstElement);
    }
    return () => {
      window.addEventListener('load', setFocusForFirstElement);
    };
  }, [autoFocus]);

  return (
    <div className="w-full h-full flex flex-col justify-between">
      <LabelComponent label={label} />

      <div className={`relative flex rounded-[10px] sm:rounded-xl outline-1 ${additionalClasses}`} ref={containerRef} onBlur={handleBlur} onFocus={handleFocus}>
        {!!cleanLetterSpacing &&
          emptyArray.map((_: string, index: number) => {
            return (
              <InputComponent
                key={`${id}-${index}`}
                ref={addToRefs}
                firstElement={index === 0}
                lastElement={emptyArray.length - 1 === index}
                style={{ width: `${cleanLetterSpacing}%` }}
                type={inputType}
                inFocus={focused}
                id={`${id}-${index}`}
                onChange={handleKeyDown}
                showError={showError}
                handleRemoveValueFromInput={handleRemoveValueFromInput}
                autoFocus={index === 0 && autoFocus}
                testId={`${name}-${index}`}
              />
            );
          })}
      </div>

      {/* Only show the description if there is no error */}
      {description && (
        <p className="mt-2 text-xs sm:text-sm text-gray-500 my-auto sm:my-0" id={id + '-description'}>
          {intl.formatMessage(description)}
        </p>
      )}
      {showError && <FieldErrorMessage error={error || showError} />}
    </div>
  );
};
