import { useFragment } from 'react-relay';
//generated
import { ProfileFragmentsEmployerCrewPools$key } from 'schemas/profile/__generated__/ProfileFragmentsEmployerCrewPools.graphql';
//schema
import { EMPLOYER_CREW_POOLS_FRAGMENT } from 'schemas/profile/ProfileFragments';

const EmployerAddUserToCrewPoolItem = ({ crewPool }: { crewPool: ProfileFragmentsEmployerCrewPools$key }) => {
  const pool = useFragment<ProfileFragmentsEmployerCrewPools$key>(EMPLOYER_CREW_POOLS_FRAGMENT, crewPool);
  return <div className="flex text-sm sm:text-base">{pool.name}</div>;
};

export default EmployerAddUserToCrewPoolItem;
