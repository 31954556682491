import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
//components
import CrewPoolSkeletonItem from './components/CrewPoolSkeletonItem';

const CrewPoolsSkeleton = () => {
  const [poolItem] = useState<string[]>(['poolItem1', 'poolItem2', 'poolItem3']);

  return (
    <div className="flex flex-col w-full pt-8">
      <div className="flex w-full justify-between">
        <div className="flex flex-col">
          <Skeleton width={90} height={16} className="leading-6" />
          <Skeleton width={90} height={14} className="leading-5" />
        </div>
        <Skeleton width={125} height={44} />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 w-full gap-x-8 gap-y-2 mt-[38px]">
        {poolItem.map(item => {
          return <CrewPoolSkeletonItem key={item} />;
        })}
      </div>
    </div>
  );
};

export default CrewPoolsSkeleton;
