// routes
import { RoutePaths, RoutesPathNames } from 'app/routing';
// components
import StepWithRank from 'auth/SignUpSteps/StepWithRank/StepWithRank';
import StepWithCountry from 'auth/SignUpSteps/StepWithCountry/StepWithCountry';
import StepWithPassword from 'auth/SignUpSteps/StepWithPassword/StepWithPassword';
import StepWithEnterName from 'auth/SignUpSteps/StepWithEnterName/StepWithEnterName';
import StepWithExperience from 'auth/SignUpSteps/StepWithExperience/StepWithExperience';
import StepWithConfirmCode from 'auth/SignUpSteps/StepWithConfirmCode/StepWithConfirmCode';
import CrewSignUpStepsLayout from 'system/Layouts/SignUpStepsLayout/CrewSignUpStepsLayout';
import StepWithEmailAndPhone from 'auth/SignUpSteps/StepWithEmailAndPhone/StepWithEmailAndPhone';
import StepWithSeaServiceForm from 'auth/SignUpSteps/StepWithSeaServiceForm/StepWithSeaServiceForm';
// enums
import { PLATFORM_GROUP } from 'models/enums';

export const CrewRegisterRoute = {
  path: `${RoutePaths.AuthRegisterBySteps}/*`,
  Component: CrewSignUpStepsLayout,
  children: [
    {
      path: RoutesPathNames.stepOne,
      element: <StepWithEmailAndPhone registerType={PLATFORM_GROUP.CREW} />,
    },
    {
      path: RoutesPathNames.stepTwo,
      element: <StepWithPassword registerType={PLATFORM_GROUP.CREW} />,
    },
    {
      path: RoutesPathNames.stepThree,
      element: <StepWithConfirmCode registerType={PLATFORM_GROUP.CREW} />,
    },
    {
      path: RoutesPathNames.stepFour,
      element: <StepWithEnterName registerType={PLATFORM_GROUP.CREW} />,
    },
    {
      path: RoutesPathNames.stepFive,
      Component: StepWithCountry,
    },
    {
      path: RoutesPathNames.stepSix,
      Component: StepWithRank,
    },
    {
      path: RoutesPathNames.stepSeven,
      Component: StepWithExperience,
    },
    {
      path: RoutesPathNames.stepEight,
      Component: StepWithSeaServiceForm,
    },
  ],
};
