import { useRecoilValue } from 'recoil';
// selector
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';
// hooks
import { PaymentSubscriptionType } from 'models/enums';
import useGetPaymentSubscription from 'hooks/useGetPaymentSubscription';

const useGetIsProSubscription = () => {
  const getCurrentOrganization = useRecoilValue(currentOrganisationSelector);
  const paymentSubscription = useGetPaymentSubscription(getCurrentOrganization.organisationId)?.node?.product?.perma;
  return paymentSubscription === PaymentSubscriptionType.PREMIUM;
};

export default useGetIsProSubscription;
