import { RecordSourceSelectorProxy } from 'relay-runtime';
// generated
import { DocumentMutationsCreateDocumentMutation$data } from 'schemas/document/__generated__/DocumentMutationsCreateDocumentMutation.graphql';

export const DocumentCommitCreate =
  (onAddAttachedFile: ((documentId: string, handleError: (error: Error) => void) => void) | undefined, onError: (error: Error) => void) =>
  (store: RecordSourceSelectorProxy<DocumentMutationsCreateDocumentMutation$data>) => {
    const payload = store.getRootField('createDocument');
    const nodeRecord = payload?.getLinkedRecord('node');
    const documentId = nodeRecord?.getValue('id');
    if (onAddAttachedFile && documentId) {
      onAddAttachedFile(documentId, onError);
    }
  };
