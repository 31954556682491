import EmptyJobIcon from 'icons/EmptyJobIcon';

const JobAvatar = ({ avatarUrl, alt, size }: { avatarUrl: string | null; alt: string; size: string }) => {
  return (
    <div className="flex">
      <div className={`flex ${size} justify-center items-center bg-avatar-bg rounded-md overflow-hidden`}>
        {avatarUrl ? <img data-test-id="commercial-avatar" className="block w-auto mx-auto rounded-md" alt={alt} src={avatarUrl} key={avatarUrl} /> : <EmptyJobIcon />}
      </div>
    </div>
  );
};

export default JobAvatar;
