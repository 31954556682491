// packages
import { useFragment } from 'react-relay';
// models
import { CountryInfoProps } from 'models/modelsOfComponents';
// schemas
import { COUNTRY_FRAGMENT } from 'schemas/country/CountryFragments';
// components
import CountryFlag from 'system/CountryFlag';
// hooks
import useGetIsProSubscription from 'hooks/subscription/useGetIsProSubscription';

const CountryInfo = ({ countryFragment, classes }: CountryInfoProps) => {
  const isProSubscription = useGetIsProSubscription();
  const country = useFragment(COUNTRY_FRAGMENT, countryFragment);

  return (
    <div className={classes}>
      {isProSubscription ? (
        <>
          <CountryFlag classes="-mt-1" countryId={country.isoCode} countryName={country.name} />
          &nbsp;
        </>
      ) : null}
      {country.isoCode}&#44;&nbsp;{country.name}
    </div>
  );
};

export default CountryInfo;
