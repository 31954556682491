// packages
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';
// hooks
import { useGetHireProfile } from '../hireProfileHooks';
import useGetPaymentSubscription from '../useGetPaymentSubscription';

export function useGetSubscriptionPlanForCurrentOrg() {
  const { profileOrganizations } = useGetHireProfile();
  const getOrganisation = useRecoilValue(currentOrganisationSelector);

  const findCurrentOwnerOrganization = useMemo(
    () => profileOrganizations?.find(organization => organization.organization.id === getOrganisation.organisationId),
    [getOrganisation.organisationId, profileOrganizations],
  );

  return useGetPaymentSubscription(findCurrentOwnerOrganization?.organization.id || null)?.node.product.perma;
}
