/**
 * @generated SignedSource<<32c49c162f82f63524fb011b78b2eb18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ServiceMutationsAddDescriptionToSeaServiceMutation$variables = {
  description: string;
  id: string;
};
export type ServiceMutationsAddDescriptionToSeaServiceMutation$data = {
  readonly setDescriptionForSeaService: {
    readonly description: string | null;
  };
};
export type ServiceMutationsAddDescriptionToSeaServiceMutation = {
  response: ServiceMutationsAddDescriptionToSeaServiceMutation$data;
  variables: ServiceMutationsAddDescriptionToSeaServiceMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'description',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v2 = [
      {
        kind: 'Variable',
        name: 'description',
        variableName: 'description',
      },
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'description',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'ServiceMutationsAddDescriptionToSeaServiceMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'SeaService',
          kind: 'LinkedField',
          name: 'setDescriptionForSeaService',
          plural: false,
          selections: [v3 /*: any*/],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'ServiceMutationsAddDescriptionToSeaServiceMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'SeaService',
          kind: 'LinkedField',
          name: 'setDescriptionForSeaService',
          plural: false,
          selections: [
            v3 /*: any*/,
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'id',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '9cc8b6cfef8eaea1681fb17b831e3627',
      id: null,
      metadata: {},
      name: 'ServiceMutationsAddDescriptionToSeaServiceMutation',
      operationKind: 'mutation',
      text: 'mutation ServiceMutationsAddDescriptionToSeaServiceMutation(\n  $id: ID!\n  $description: String!\n) {\n  setDescriptionForSeaService(id: $id, description: $description) {\n    description\n    id\n  }\n}\n',
    },
  };
})();

(node as any).hash = '3fb064a828395cea096d3bf905e73ded';

export default node;
