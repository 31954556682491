// packages
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/solid';
// types
import { LinkButtonWithIconType } from './types';

const BlueLinkButton = ({ to, handleClick, title, state, withIcon, dataTestId }: LinkButtonWithIconType) => {
  const intl = useIntl();
  return (
    <Link
      data-test-id={dataTestId}
      className="bg-blue text-white text-center text-sm font-medium px-4 xl:px-5 flex h-10 rounded-[10px] items-center gap-x-1.5 lg:gap-x-3 my-auto"
      state={state}
      to={to}
      onClick={handleClick}
    >
      {withIcon ? <PlusIcon className="w-5 h-5 text-white" /> : null}
      {intl.formatMessage(title)}
    </Link>
  );
};

export default BlueLinkButton;
