// packages
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useLazyLoadQuery } from 'react-relay';
// components
import { Age } from 'app/Profile/Age';
import FolderWithCheckMark from 'icons/FolderWithCheckMark';
import CertificatesInfo from './components/CertificatesInfo';
import CountryComponent from './components/CountryComponent';
import ComponentWithPopover from 'system/ComponentWithPopover';
import PrimaryDutyComponent from './components/PrimaryDutyComponent';
import { ProfileAvatar } from 'app/Avatars/ProfileAvatar/ProfileAvatar';
import SeaServiceJobExperience from 'system/SeaServiceJobExpirience/SeaServiceJobExperience';
// models
import { ProfileListItemProps } from 'models/modelsOfComponents';
import { ProfileCertificateInfos, ProfileCertificateInfosCertificate } from 'models/IProfiles';
// generated
import { CountryFragments_info$data } from 'schemas/country/__generated__/CountryFragments_info.graphql';
import { ServiceFragmentsOneDuty$data } from 'schemas/services/__generated__/ServiceFragmentsOneDuty.graphql';
import { CertificateQueriesListQuery } from 'schemas/certificates/__generated__/CertificateQueriesListQuery.graphql';
import { ServiceQueriesFormGetAllTagsQuery } from 'schemas/services/__generated__/ServiceQueriesFormGetAllTagsQuery.graphql';
import { ServiceQueriesFormGetAllDutiesQuery } from 'schemas/services/__generated__/ServiceQueriesFormGetAllDutiesQuery.graphql';
import { CountryQueriesCountriesOfResidenceQuery } from 'schemas/country/__generated__/CountryQueriesCountriesOfResidenceQuery.graphql';
import { CertificateFragmentsCertificateInfo$data } from 'schemas/certificates/__generated__/CertificateFragmentsCertificateInfo.graphql';
// schemas
import { GET_ALL_CERTIFICATES_LIST } from 'schemas/certificates/CertificateQueries';
import { GET_PROFILE_COUNTRIES_OF_RESIDENCE } from 'schemas/country/CountryQueries';
import { GET_ALL_DUTIES_QUERY, GET_ALL_TAGS_QUERY } from 'schemas/services/ServiceQueries';
// models
import { IActivities, IActivity } from 'models/IActivity';
//hooks
import useCrewUrlPath from 'hooks/useCrewURLPath';
import { useConvertToCorrectServiceDate } from 'hooks/useConvertToCorrectServiceDate';
import useGetRestrictedProfileLastName from 'hooks/ProfileDataHooks/useGetRestrictedProfileLastName';
// recoil
import { scrollWithPaginationType } from 'recoil/ScrollWithPagination/types';
import { profileSearchScrollWithPaginationSelector } from 'recoil/ScrollWithPagination/selectors/SearchScrollWithPaginationSelector';

const ProfileListItem = ({ profile, onClick = () => {}, inviteButton }: ProfileListItemProps) => {
  const intl = useIntl();
  const crewPagePath = useCrewUrlPath();
  const lastName = useGetRestrictedProfileLastName(profile.lastName);

  const [profileLinkByRightClick] = useState<string>(`${crewPagePath}/${profile?.objectID}`);

  const allDuties = useLazyLoadQuery<ServiceQueriesFormGetAllDutiesQuery>(GET_ALL_DUTIES_QUERY, { skip: false });
  const allCountries = useLazyLoadQuery<CountryQueriesCountriesOfResidenceQuery>(GET_PROFILE_COUNTRIES_OF_RESIDENCE, { skip: false });
  const allCertificates = useLazyLoadQuery<CertificateQueriesListQuery>(GET_ALL_CERTIFICATES_LIST, { skip: false });
  const allActivities = useLazyLoadQuery<ServiceQueriesFormGetAllTagsQuery>(GET_ALL_TAGS_QUERY, { substr: '', skip: false });

  const { convertDays, convertMonths, convertYears } = useConvertToCorrectServiceDate(profile?.seaServicesDuration || null);

  const [dateValue, setDateValue] = useState<string>('');
  const [profileDuty, setProfileDuty] = useState<ServiceFragmentsOneDuty$data | undefined>(undefined);
  const [certificates, setCertificates] = useState<CertificateFragmentsCertificateInfo$data>([]);
  const [profileCountry, setProfileCountry] = useState<CountryFragments_info$data | undefined>(undefined);

  const [seaServiceDuties, setSeaServiceDuties] = useState<Array<string>>([]);
  const [activities, setActivities] = useState<IActivities>([]);

  const setScrollWithPagination = useSetRecoilState(profileSearchScrollWithPaginationSelector);

  useEffect(() => {
    setSeaServiceDuties(() => {
      let serviceDuties: Set<string> = new Set();
      allDuties.duties?.edges?.forEach(duty => {
        profile.seaServiceDuties?.forEach(seaServiceDuty => {
          if (duty.id === seaServiceDuty) {
            serviceDuties.add(duty.name);
          }
        });
      });
      return [...serviceDuties];
    });

    setProfileDuty(allDuties.duties?.edges?.find(duty => duty.id === profile.primaryDuty) as ServiceFragmentsOneDuty$data);
  }, [allDuties.duties?.edges, profile]);

  useEffect(() => {
    setActivities(() => {
      let arrayOfActivities: Set<IActivity> = new Set<IActivity>();
      allActivities.activities?.edges?.forEach(activity => {
        profile.seaServiceActivities?.forEach(seaServiceActivity => {
          if (activity.id === seaServiceActivity) {
            arrayOfActivities.add(activity);
          }
        });
      });
      return [...arrayOfActivities];
    });
  }, [allActivities.activities?.edges, profile.seaServiceActivities]);

  useEffect(() => {
    setDateValue(new Date(+`${profile?.birthDate}000`).toISOString());
  }, [profile?.birthDate]);

  useEffect(() => {
    setProfileCountry(allCountries.countries?.edges.find(country => country.isoCode === profile.country) as CountryFragments_info$data);
  }, [allCountries.countries?.edges, profile.country]);

  useEffect(() => {
    setCertificates(() => {
      let arrayOfCertificates: Array<ProfileCertificateInfos> = [];
      allCertificates.certificates?.edges?.forEach(certificate => {
        profile?.certificates?.forEach(certificateId => {
          if (certificate.id === certificateId) {
            arrayOfCertificates.push({
              certificate: {
                id: certificate.id,
                name: certificate.name,
                kind: certificate?.kind,
              } as ProfileCertificateInfosCertificate,
              owner: { id: profile.objectID },
            });
          }
        });
      });
      return arrayOfCertificates as unknown as CertificateFragmentsCertificateInfo$data;
    });
  }, [allCertificates.certificates?.edges, profile?.certificates, profile?.objectID]);

  const handleClickByLInk = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onClick();
    setScrollWithPagination((prevState: scrollWithPaginationType) => ({ ...prevState, scrollY: window.scrollY }));
  };

  return (
    <Link
      to={profileLinkByRightClick}
      onClick={handleClickByLInk}
      className="flex flex-col sm:flex-row border-solid border border-specialGray-012 rounded-[15px] mb-6 lg:pb-7 lg:pt-[27px] lg:px-6 sm:p-4 p-3.5 cursor-pointer w-full bg-white gap-y-3.5"
    >
      <div className="flex w-full">
        <ProfileAvatar size="w-[66px] sm:w-14 lg:w-40 h-[66px] sm:h-14 lg:h-40" avatarRadius="rounded-xl" avatarUrl={profile.avatarUrl} />
        <div className="flex flex-col pl-4 lg:pl-6 w-full lg:w-[calc(100%_-_160px)]">
          <div className={`flex relative w-full justify-between ${profile?.poolName ? 'sm:mb-2 lg:mb-4' : ''}`}>
            <div className="flex flex-col sm:flex-row gap-x-4 gap-y-1 sm:items-center lg:items-start h-fit">
              <div className="flex items-center font-semibold text-lg text-darkBlue gap-x-4 lg:gap-x-5">
                {profile ? `${profile.firstName} ${lastName}` : intl.formatMessage({ id: 'profile_is_empty' })}
                {/*for pool items page shows folder with checkmark and name of the current pool*/}
                {profile?.poolName ? (
                  <div className="flex sm:whitespace-nowrap pl-1 w-fit pr-3 h-8 bg-blue-100 items-center rounded-[50px] gap-x-2 text-xs lg:text-sm text-blue font-semibold">
                    <FolderWithCheckMark /> {profile.poolName}
                  </div>
                ) : null}
              </div>

              {profileDuty ? <span className="inline lg:hidden text-xs sm:text-sm text-specialGray-075">{profileDuty.name}</span> : null}
            </div>
            <div className="hidden sm:flex h-[42px]">{inviteButton(profile.objectID, profile.availableForWork)}</div>
          </div>
          <section className="hidden lg:flex w-full">
            <div className="inline-flex w-1/2 flex-col">
              <div className="flex flex-col w-full">
                <div className="flex w-full">
                  <p className="text-xs text-specialGray-075 font-normal mb-2 w-14">{intl.formatMessage({ id: 'profile_age' })}</p>
                  {profile?.birthDate && <Age classes="text-sm text-darkBlue ml-7" birthday={dateValue} />}
                </div>
                {profileCountry && (
                  <CountryComponent
                    classNameContainer="justify-start"
                    classNameTitle="font-normal mb-2 text-xs w-14"
                    classNameDescription="ml-7 text-sm"
                    profileCountry={profileCountry}
                  />
                )}
                {profileDuty && (
                  <PrimaryDutyComponent
                    classNameContainer="justify-start"
                    classNameTitle="font-normal mb-2 text-xs w-14"
                    classNameDescription="ml-7 text-sm"
                    profileDuty={profileDuty}
                  />
                )}
              </div>
              {!isEmpty(activities) && (
                <div className="flex w-full flex-row mt-3">
                  <span className="text-xs text-specialGray-075 mr-3 whitespace-nowrap pt-1">{intl.formatMessage({ id: 'profile_frequently_used_tags' })}</span>
                  <div className="flex flex-wrap">
                    {activities?.map((activity, index) => (
                      <ComponentWithPopover additionalClasses="mb-1 py-1 mr-3" key={`${profile.objectID}-${activity.id}-${index}`} id={activity.id} name={activity.label} />
                    ))}
                  </div>
                </div>
              )}
            </div>

            {!!profile.numSeaServices && (
              <div className="flex flex-col text-sm w-1/2">
                <div className="flex w-full">
                  <p className="text-specialGray-075 text-xs mr-5 2xl:mr-4 mb-2 pt-0.5">{intl.formatMessage({ id: 'profile_sea_services' })}</p>
                  <p className="flex text-darkBlue mr-5">
                    {profile.numSeaServices}
                    {!isEmpty(profile?.seaServicesDuration) && (
                      <div className="flex text-specialGray-075 pl-3">
                        &#40;
                        <SeaServiceJobExperience days={convertDays} months={convertMonths} years={convertYears} classes="flex [&>span]:whitespace-nowrap [&>span]:mr-0 gap-x-1" />
                        &#41;
                      </div>
                    )}
                  </p>
                </div>

                {!isEmpty(seaServiceDuties) && (
                  <div className="flex w-full items-center mb-2">
                    <p className="text-specialGray-075 mr-5 2xl:mr-4 text-xs mb-1 pt-0.5">{intl.formatMessage({ id: 'profile_service_other_duties' })}</p>
                    <span className="text-darkBlue">{seaServiceDuties.join(', ')}</span>
                  </div>
                )}

                {!isEmpty(certificates) ? (
                  <div className="flex w-full">
                    <p className="text-specialGray-075 text-xs mr-5 2xl:mr-5.5 mb-1 pt-0.5">{intl.formatMessage({ id: 'profile_certificates' })}</p>
                    <CertificatesInfo certificates={certificates} />
                  </div>
                ) : null}
              </div>
            )}
          </section>
          {/*The tablet layout*/}
          <div className="hidden sm:flex lg:hidden gap-x-3">
            <div className="flex gap-x-1.5 text-xs">
              <p className="text-specialGray-075 font-normal">{intl.formatMessage({ id: 'profile_age' })}</p>
              {profile?.birthDate ? <Age classes="text-darkBlue" birthday={dateValue} /> : null}
            </div>
            <div className="flex gap-x-1.5 text-xs">
              <p className="text-specialGray-075 font-normal">{intl.formatMessage({ id: 'profile_location' })}</p>
              {profileCountry ? <span className="text-darkBlue">{profileCountry.name}</span> : null}
            </div>
            <div className="flex gap-x-1.5 text-xs">
              <p className="text-specialGray-075 font-normal">{intl.formatMessage({ id: 'profile_seaServiceTitle' })}</p>
              <span className="text-darkBlue">{profile.numSeaServices}</span>
            </div>
            <div className="flex gap-x-1.5 text-xs">
              <p className="text-specialGray-075 font-normal">{intl.formatMessage({ id: 'profile_certificatesTitle' })}</p>
              <span className="text-darkBlue">{profile.certificates?.length || 0}</span>
            </div>
          </div>
        </div>
      </div>
      {/*The mobile layout*/}
      <div className="flex sm:hidden flex-col w-full gap-y-2">
        <div className="flex gap-x-1.5 text-xs w-full justify-between">
          <p className="text-specialGray-075 font-normal">{intl.formatMessage({ id: 'profile_age' })}</p>
          {profile?.birthDate ? <Age classes="text-darkBlue" birthday={dateValue} /> : null}
        </div>
        {profileCountry ? (
          <CountryComponent classNameContainer="justify-between items-center" classNameTitle="text-xs" classNameDescription="text-xs self-center" profileCountry={profileCountry} />
        ) : null}
        <div className="flex gap-x-1.5 text-xs w-full justify-between">
          <p className="text-specialGray-075 font-normal">{intl.formatMessage({ id: 'profile_seaServiceTitle' })}</p>
          <span className="text-darkBlue">{intl.formatMessage({ id: 'profile_seaServiceAmount' }, { amount: profile.numSeaServices })}</span>
        </div>
        <div className="flex gap-x-1.5 text-xs w-full justify-between">
          <p className="text-specialGray-075 font-normal">{intl.formatMessage({ id: 'profile_certificatesTitle' })}</p>
          <span className="text-darkBlue">{intl.formatMessage({ id: 'profile_certificatesAmount' }, { amount: profile.certificates?.length || 0 })}</span>
        </div>
      </div>
      <div className="flex flex-wrap gap-y-2 sm:hidden w-full">{inviteButton()}</div>
    </Link>
  );
};

export default ProfileListItem;
