// packages
import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
// schemas
import { SEARCH_ORGANISATIONS_QUERY } from 'schemas/search/SearchQueries';
import { GET_LIST_OF_ORGANISATIONS_BY_IDS } from 'schemas/organisations/OrganisationQueries';
// enums
import { SEARCH_INDEX } from 'models/enums';
// components
import DropDownHeader from '../DropDownHeader';
import NoSearchResult from '../NoSearchResult';
import DropDownListItem from './components/DropDownListItem';
import OrganisationEmptyIcon from 'icons/OrganisationEmptyIcon';
// generated
import { SearchQueriesOrganisationsQuery } from 'schemas/search/__generated__/SearchQueriesOrganisationsQuery.graphql';
import { OrganisationFragmentsForSearchFragment$key } from 'schemas/organisations/__generated__/OrganisationFragmentsForSearchFragment.graphql';
import { OrganisationQueriesGetListOfOrganisationsByIdsQuery } from 'schemas/organisations/__generated__/OrganisationQueriesGetListOfOrganisationsByIdsQuery.graphql';
// routes
import { RoutesPathNames } from 'app/routing';
// context
import { useNavbarSearchContext } from 'Context/NavbarSearchContext';

const DropDownWithOrganisations = ({ setShowDropDown }: { setShowDropDown: () => void }) => {
  const navigate = useNavigate();
  const { queryParams } = useNavbarSearchContext();

  const getSearchedOrganisations = useLazyLoadQuery<SearchQueriesOrganisationsQuery>(SEARCH_ORGANISATIONS_QUERY, {
    first: 3,
    query: queryParams,
    index: SEARCH_INDEX.ORGANIZATION,
    skip: false,
  }).search;

  const listOfOrganisations = useLazyLoadQuery<OrganisationQueriesGetListOfOrganisationsByIdsQuery>(GET_LIST_OF_ORGANISATIONS_BY_IDS, {
    ids: isEmpty(getSearchedOrganisations?.edges) ? [] : (getSearchedOrganisations?.edges?.map(organisation => organisation?.node?.objectID) as Array<string>),
  });

  const handleNavigationToSearchPage = useCallback(() => {
    window.analytics?.track('wui-show-all-in-search-bar');

    setShowDropDown();
    navigate(RoutesPathNames.employers, {
      state: { queryParams },
    });
  }, [navigate, queryParams, setShowDropDown]);

  return (
    <>
      <DropDownHeader
        label={{ id: 'see_all_employers' }}
        counter={getSearchedOrganisations?.totalCount}
        onClick={handleNavigationToSearchPage}
        isShowSeeAllButton={!!getSearchedOrganisations?.totalCount}
      />
      {!isEmpty(getSearchedOrganisations?.edges) ? (
        <>
          {listOfOrganisations?.nodes?.map(employer => (
            <DropDownListItem key={employer?.id} employer={employer as OrganisationFragmentsForSearchFragment$key} setShowDropDown={setShowDropDown} />
          ))}
        </>
      ) : (
        <NoSearchResult message={{ id: 'search_input_no_employer_results' }}>
          <OrganisationEmptyIcon />
        </NoSearchResult>
      )}
    </>
  );
};

export default DropDownWithOrganisations;
