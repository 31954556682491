import { Suspense } from 'react';
import CrewOverviewTabSkeleton from 'system/skeletons/profileTabs/CrewOverviewTabSkeleton';
import OverviewComponent from 'system/OverviewComponent/OverviewComponent';
import { ProfileFragmentsCrewProfile$data, ProfileFragmentsCrewProfile$key } from 'schemas/profile/__generated__/ProfileFragmentsCrewProfile.graphql';
import Overview from 'app/Profile/Overview/Overview';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { CREW_PROFILE_FRAGMENT } from 'schemas/profile/ProfileFragments';
import { useSearchParams } from 'react-router-dom';
import { ProfileQueriesProfileShareQuery } from 'schemas/profile/__generated__/ProfileQueriesProfileShareQuery.graphql';
import { GET_PROFILE_SHARE } from 'schemas/profile/ProfileQueries';

const GetOverviewForCrew = () => {
  const [searchParams] = useSearchParams();
  const profileShare = useLazyLoadQuery<ProfileQueriesProfileShareQuery>(GET_PROFILE_SHARE, { uuid: searchParams.get('t') || '' });
  const profileCrew = useFragment<ProfileFragmentsCrewProfile$key>(CREW_PROFILE_FRAGMENT, profileShare.profileShare?.owner || null);

  return (
    <>
      {searchParams.get('t') ? (
        <Suspense fallback={<CrewOverviewTabSkeleton />}>
          <OverviewComponent profileData={profileCrew as ProfileFragmentsCrewProfile$data} />
        </Suspense>
      ) : (
        <Suspense fallback={<CrewOverviewTabSkeleton />}>
          <Overview />
        </Suspense>
      )}
    </>
  );
};

export default GetOverviewForCrew;
