import LineSvg from 'icons/LineSvg';
import Skeleton from 'react-loading-skeleton';

const RightSideWithOrganizationInfoSkeleton = () => {
  return (
    <section className="flex flex-col border border-1 border-specialGray-012 rounded-2xl xl:w-2/5 lg:w-1/2 w-full space-y-4 h-fit pb-5">
      <section className="flex flex-col pt-4 px-6">
        <div className="flex space-x-6 items-center">
          <Skeleton width={56} height={56} />
          <Skeleton width={100} height={18} />
        </div>
      </section>
      <LineSvg />
      <section className="flex flex-col px-6">
        <Skeleton width={50} height={12} containerClassName="mb-3" />
        <Skeleton width="100%" height={12} />
        <Skeleton width="100%" height={12} />
        <Skeleton width="100%" height={12} />
        <Skeleton width="100%" height={12} />
      </section>
      <LineSvg />
      <section className="flex flex-col space-y-3 px-6">
        <Skeleton width={150} height={12} />
        <div className="flex flex-col space-y-2">
          <Skeleton width="50%" height={18} />
          <Skeleton width={150} height={12} />
        </div>
        <div className="flex flex-col space-y-2">
          <Skeleton width="50%" height={18} />
          <Skeleton width={150} height={12} />
        </div>
        <div className="flex flex-col space-y-2">
          <Skeleton width="50%" height={18} />
          <Skeleton width={150} height={12} />
        </div>
      </section>
    </section>
  );
};

export default RightSideWithOrganizationInfoSkeleton;
