// packages
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useFragment } from 'react-relay';
// components
import { ProfileAvatar } from 'app/Avatars/ProfileAvatar/ProfileAvatar';
// routing
import { RoutePaths } from 'app/routing';
// models
import { CommonItemListOfCrewProps } from '../types';
import { VACANCY_DESCRIPTION_TYPE_OF_LIST } from 'models/enums';
// generated
import { ProfileFragments_info$key } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
import { ServiceFragmentsOneDuty$key } from 'schemas/services/__generated__/ServiceFragmentsOneDuty.graphql';
import { ProfileFragmentsCrewProfile$key } from 'schemas/profile/__generated__/ProfileFragmentsCrewProfile.graphql';
// schemas
import { CREW_PROFILE_FRAGMENT, PROFILE_INFO_FRAGMENT } from 'schemas/profile/ProfileFragments';
import { GET_ONE_DUTY } from 'schemas/services/ServiceFragments';
// utils
import { getDifferentTime } from 'utils';
// hooks
import useGetRestrictedProfileLastName from 'hooks/ProfileDataHooks/useGetRestrictedProfileLastName';

const RightSideCommonItemListOfCrew = ({ profileFragment, vacancyCreateAt, typeOfList }: CommonItemListOfCrewProps) => {
  const intl = useIntl();
  const profileData = useFragment<ProfileFragmentsCrewProfile$key>(CREW_PROFILE_FRAGMENT, profileFragment);
  const profileInfoData = useFragment<ProfileFragments_info$key>(PROFILE_INFO_FRAGMENT, profileData.info);
  const primaryDuty = useFragment<ServiceFragmentsOneDuty$key>(GET_ONE_DUTY, profileInfoData?.primaryDuty || null);
  const lastName = useGetRestrictedProfileLastName(profileInfoData?.lastName);

  return (
    <Link to={`${RoutePaths.ProfileSingleCrewPageEmployerView}/${profileData.id}`} className="flex justify-between cursor-pointer rounded-md border border-gray-300 p-4 w-full">
      <div className="flex items-center space-x-3">
        <ProfileAvatar avatarUrl={profileInfoData?.avatarUrl} size="w-9 h-9" />
        <div className="flex flex-col justify-between">
          <span className="text-darkBlue font-medium">
            {profileInfoData?.firstName || intl.formatMessage({ id: 'no_name' })}&nbsp;{lastName}
          </span>
          {primaryDuty?.name && <span className="text-specialGray-075 text-sm">{primaryDuty.name}</span>}
        </div>
      </div>
      <div className="flex flex-col text-specialGray-075 text-xs space-y-2 justify-center items-center">
        {typeOfList === VACANCY_DESCRIPTION_TYPE_OF_LIST.INVITED && (
          <>
            <span>{format(new Date(vacancyCreateAt), 'hh:mm')}</span>
            <span>{format(new Date(vacancyCreateAt), 'dd LLL yyyy')}</span>
          </>
        )}
        {typeOfList === VACANCY_DESCRIPTION_TYPE_OF_LIST.APPLIED && <span>{getDifferentTime(vacancyCreateAt)}</span>}
      </div>
    </Link>
  );
};

export default RightSideCommonItemListOfCrew;
