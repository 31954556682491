// packages
import { Suspense } from 'react';
// routes
import { RoutePaths, RoutesPathNames } from 'app/routing';
// components
import SpinnerIcon from 'icons/SpinnerIcon';
import Dashboard from 'app/ProfileEmployer/components/Dashboard/Dashboard';
import OrganisationView from './components/OrganizationView/OrganisationView';
import WelcomeHireCongratulation from 'system/CongratulationModals/WelcomeHireCongratulation/WelcomeHireCongratulation';
import EmployerOrganizationMain from 'app/ProfileEmployer/components/EmployerOrganization/components/EmployerOrganizationMain';
// enums
import { PLATFORM_GROUP } from 'models/enums';
// utils
import { envVariableTransform } from 'utils';
// routes
import { ProfilePoolsRoute } from './routes/pools/ProfilePoolsRoute';

export const OrganisationDashboardRoute = [
  {
    path: `${RoutePaths.ProfileOrganizationBase}/*`,
    element: <OrganisationView accountTypes={[PLATFORM_GROUP.HIRE]} />,
    children: [
      {
        index: true,
        Component: Dashboard,
      },
      {
        path: RoutesPathNames.welcome,
        Component: WelcomeHireCongratulation,
      },
    ],
  },
  {
    path: `${RoutePaths.ProfileOrganizationBase}/:${RoutesPathNames.orgId}/*`,
    element: <OrganisationView accountTypes={[PLATFORM_GROUP.HIRE, PLATFORM_GROUP.CREW]} />,
    children: [
      {
        index: true,
        Component: Dashboard,
      },
      {
        path: RoutesPathNames.welcome,
        Component: WelcomeHireCongratulation,
      },
      ProfilePoolsRoute,
      {
        path: `${RoutePaths.ProfileOrganizationMembers}/*`,
        element: envVariableTransform(process.env.REACT_APP_FEATURE_HIRE_ORGANIZATION_TEAM_MEMBERS) ? (
          <Suspense fallback={<SpinnerIcon additionalClasses="w-20 h-20 mt-20" />}>
            <EmployerOrganizationMain />
          </Suspense>
        ) : null,
      },
    ],
  },
];
