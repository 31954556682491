/**
 * @generated SignedSource<<658efd3c516216940acb86e64721a887>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type InviteFragments$data = {
  readonly 'createdAt': String;
  readonly 'id': string;
  readonly 'owner': {
    readonly 'id': string;
    readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsCrewProfile'>;
  };
  readonly 'vacancy': {
    readonly id: string;
  };
  readonly ' $fragmentType': 'InviteFragments';
};
export type InviteFragments$key = {
  readonly ' $data'?: InviteFragments$data;
  readonly ' $fragmentSpreads': FragmentRefs<'InviteFragments'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'id',
    storageKey: null,
  };
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'InviteFragments',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'createdAt',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Vacancy',
        kind: 'LinkedField',
        name: 'vacancy',
        plural: false,
        selections: [v0 /*: any*/],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Profile',
        kind: 'LinkedField',
        name: 'owner',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'ProfileFragmentsCrewProfile',
          },
        ],
        storageKey: null,
      },
    ],
    type: 'Invite',
    abstractKey: null,
  };
})();

(node as any).hash = '0525385e388888e7709db0138f1d6a67';

export default node;
