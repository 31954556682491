// packages
import { useIntl } from 'react-intl';
import { isEmpty, orderBy, sortBy } from 'lodash';
import { useFragment } from 'react-relay';
// components
import PaperIcon from 'icons/PaperSmall';
import OverviewTitleSection from 'system/OverviewTitleSection';
import HiddenCrewDataPopover from 'system/ProfileSlide/components/HiddenCrewDataPopover';
import OverviewCertificateItem from 'app/Profile/Certificates/CertificateItem/OverviewCertificateItem';
// generated
import { CertificateFragmentsCertificateInfo$data, CertificateFragmentsCertificateInfo$key } from 'schemas/certificates/__generated__/CertificateFragmentsCertificateInfo.graphql';
// models
import { ICertificateInfoEdge } from 'models/ICertificate';
// utils
import { addBorderStyle } from 'utils';
// schemas
import { LIST_OF_CERTIFICATE_INFOS } from 'schemas/certificates/CertificateFragments';
//type
import { HighlightedOverviewSectionProps } from 'app/ProfileCrew/type/ProfileCrewTypes';
import { RESTRICTED_PROFILE_DATA_COUNTER } from 'models/enums';

export const HighlightedCrewCertificates = ({ profileCrewData }: HighlightedOverviewSectionProps) => {
  const intl = useIntl();
  const certificateFromProfile = useFragment<CertificateFragmentsCertificateInfo$key>(LIST_OF_CERTIFICATE_INFOS, profileCrewData?.certificateInfos || null);

  const hasCertificates = !isEmpty(profileCrewData?.certificateInfos);

  const alphabeticallySortCertificates = (certificates: CertificateFragmentsCertificateInfo$data | null) => {
    const sortingCertificates = sortBy(certificates, ['certificate.priority', 'certificate.name']);
    return orderBy(sortingCertificates, ['certificate.priority'], ['desc']);
  };

  const certificatesCounter = profileCrewData?.certificateInfos?.length || 0;
  const andMoreTitleCounter = certificatesCounter - RESTRICTED_PROFILE_DATA_COUNTER.CERTIFICATES;

  return (
    <section className="flex flex-col gap-y-4 mt-6">
      <OverviewTitleSection title={{ id: 'profile_certificatesTitle' }} countLabel={{ id: 'profile_certificatesAmount' }} count={certificatesCounter} />

      {hasCertificates && (
        <section className="border border-specialGray-012 rounded-2xl overflow-hidden">
          <section className="bg-specialGray-004 border-b">
            <span className="text-specialGray-075 uppercase font-medium block text-xs p-2 px-4">{intl.formatMessage({ id: 'certificate_list_all' })}</span>
          </section>

          <div className="flex flex-col md:flex-row flex-wrap justify-between sm:rounded-lg">
            {alphabeticallySortCertificates(certificateFromProfile)?.map((certificateInfo: ICertificateInfoEdge, index: number) =>
              certificateInfo && index < RESTRICTED_PROFILE_DATA_COUNTER.CERTIFICATES ? (
                <section key={certificateInfo.id} className={`${addBorderStyle(index, certificatesCounter || 0)} py-4 pr-4 flex justify-between items-center`}>
                  <OverviewCertificateItem certificateInfo={certificateInfo} />
                </section>
              ) : null,
            )}
          </div>
        </section>
      )}
      {certificatesCounter > RESTRICTED_PROFILE_DATA_COUNTER.CERTIFICATES && (
        <HiddenCrewDataPopover label={intl.formatMessage({ id: 'profile_certificate_with_restriction_amount' }, { amount: andMoreTitleCounter })} />
      )}

      {!hasCertificates && (
        <div className="flex items-center border border-specialGray-012 rounded-xl px-4 py-2">
          <PaperIcon />
          <span className="text-specialGray-1 ml-2 mr-1 text-sm">{intl.formatMessage({ id: 'profile_no_certificates_added' })}</span>
        </div>
      )}
    </section>
  );
};
