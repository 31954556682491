import graphql from 'babel-plugin-relay/macro';

export const UPDATE_PROFILE_DOCUMENT = graphql`
  mutation ProfileMutationsProfileProgressMutation($pid: ID!, $data: ProgressData!) {
    updateProgress(pid: $pid, data: $data) {
      filledOutBasicInfo
      addedFirstSeaService
      wroteABio
      createdFirstEducationItem
      addedFirstCertificate
      cvDownloaded
    }
  }
`;

// Organizations profile mutations
export const CREATE_ORGANIZATION = graphql`
  mutation ProfileMutationsCreateOrganizationMutation($data: OrganizationData!) {
    createOrganization(data: $data) {
      id
      country {
        name
        isoCode
        id
      }
      city
      name
      size
    }
  }
`;

export const UPDATE_ORGANIZATION = graphql`
  mutation ProfileMutationsUpdateOrganizationMutation($id: ID!, $data: OrganizationData!) {
    updateOrganization(id: $id, data: $data) {
      id
      country {
        isoCode
        name
        id
      }
      city
      name
      size
    }
  }
`;

export const CREATE_PROFILE_SHARE = graphql`
  mutation ProfileMutationsShareMutation($data: ProfileShareData!) {
    createProfileShare(data: $data) {
      uuid
    }
  }
`;

export const UPDATE_PROFILE_INFO_HIDDEN = graphql`
  mutation ProfileMutationsUpdateProfileInfoHiddenMutation($ownerId: ID!, $value: Boolean!) {
    updateProfileInfoHidden(ownerId: $ownerId, value: $value) {
      id
      isHidden
    }
  }
`;

export const CREATE_NOTIFICATION_PREFERENCE = graphql`
  mutation ProfileMutationsCreateNotificationPreferenceMutation($data: NotificationPreferenceData!) {
    createNotificationPreference(data: $data) {
      id
      jobOpening
      tipsToImprove
    }
  }
`;
export const UPDATE_NOTIFICATION_PREFERENCE = graphql`
  mutation ProfileMutationsUpdateNotificationPreferenceMutation($id: ID!, $data: NotificationPreferenceData!) {
    updateNotificationPreference(id: $id, data: $data) {
      id
      jobOpening
      tipsToImprove
    }
  }
`;

export const CREATE_GENERAL_PROFILE_SETTINGS = graphql`
  mutation ProfileMutationsCreateGeneralSettingsMutation($data: GeneralSettingData!) {
    createGeneralSetting(data: $data) {
      id
      availableForWork
      hideContactInfo
    }
  }
`;

export const UPDATE_GENERAL_PROFILE_SETTINGS = graphql`
  mutation ProfileMutationsUpdateGeneralSettingsMutation($id: ID!, $data: GeneralSettingData!) {
    updateGeneralSetting(id: $id, data: $data) {
      id
      availableForWork
      hideContactInfo
    }
  }
`;

export const CREATE_NEW_POOL = graphql`
  mutation ProfileMutationsCreateNewPoolMutation($data: CrewPoolData!) {
    createCrewPool(data: $data) {
      node {
        name
        kind
        id
        Organization {
          id
        }
        CreatedAt
        UpdatedAt
      }
    }
  }
`;
export const DELETE_POOL = graphql`
  mutation ProfileMutationsDeletePoolMutation($id: ID!) {
    deleteCrewPool(id: $id) {
      id
    }
  }
`;

export const ADD_PROFILE_TO_HIRE_CREW_POOL = graphql`
  mutation ProfileMutationsAddProfileToCrewPoolMutation($poolId: [ID!]!, $profileIDs: [ID!]!) {
    addCrewPoolProfiles(poolId: $poolId, profileIDs: $profileIDs) {
      edges {
        id
        name
        kind
        Organization {
          id
        }
        CreatedAt
        UpdatedAt
      }
    }
  }
`;

export const DELETE_CREW_FROM_POOL = graphql`
  mutation ProfileMutationsDeleteCrewFromPoolMutation($poolId: ID!, $profileIDs: [ID!]!) {
    removeCrewPoolProfiles(profileIDs: $profileIDs, poolId: $poolId) {
      id
    }
  }
`;
export const ADD_NEW_MEMBER_TO_ORGANIZATION = graphql`
  mutation ProfileMutationsCreateOrganizationMemberMutation($data: OrganizationMemberData!) {
    createOrganizationMember(data: $data) {
      node {
        id
        profile {
          id
          educations {
            id
            name
          }
          seaServices {
            vessel {
              imo
            }
          }
          certificateInfos {
            id
          }
          info {
            primaryDuty {
              name
            }
            country {
              name
              isoCode
            }
            birthDate
            avatarUrl
            firstName
            lastName
          }
        }
        organization {
          id
          avatarUrl
          name
        }
        role
      }
    }
  }
`;

export const DELETE_MEMBER_FROM_ORGANIZATION = graphql`
  mutation ProfileMutationsDeleteOrganizationMemberMutation($id: ID!) {
    deleteOrganizationMember(id: $id) {
      id
    }
  }
`;

export const UPDATE_PROFILE_OID_CLAIM = graphql`
  mutation ProfileMutationsUpsertProfileOIDClaimsMutation($username: String!) {
    upsertProfileOIDClaims(username: $username) {
      id
      phoneNumber
      phoneNumberVerified
    }
  }
`;

export const ACCEPT_ORGANIZATION_MEMBER_INVITATION = graphql`
  mutation ProfileMutationsAcceptOrganizationMemberInviteMutation($orgMemberId: ID!) {
    acceptOrganizationMemberInvitation(orgMemberId: $orgMemberId) {
      id
      role
      organization {
        id
        name
      }
      profile {
        id
        info {
          id
          avatarUrl
          firstName
          lastName
        }
      }
    }
  }
`;
