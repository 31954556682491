import { isEmpty } from 'lodash';
//components
import { withoutSelectedFilters } from 'utils/filterHelpers';
import { FilterDropDownItem } from 'system/FilterSlideComponents/components/FilterItems/FilterDropDownItem';
//models
import { FilterDoubleListDropDownProps, ListOfFilterProps } from '../../types';
//hooks
import { useHoverById } from 'hooks/useHover';

export const FilterDoubleListDropDown = <T extends ListOfFilterProps>({
  filterItems = [],
  selectedFilterItems,
  onClickEvent,
  onClickDeleteEvent,
  nameFormatter,
}: FilterDoubleListDropDownProps<T>) => {
  const { selectedId, handleMouseEnter, handleMouseOut } = useHoverById();

  return (
    <div className="flex flex-col w-full absolute border border-specialGray-012 bg-white top-20 rounded-xl justify-center items-center overflow-y-auto z-10">
      {!isEmpty(selectedFilterItems) && (
        <div className="flex-col overflow-y-auto border-b border-b-specialGray-012 max-h-52 border-b-border-specialGray-012 w-full">
          {selectedFilterItems.map(item => {
            return (
              <div key={item.id} onMouseEnter={handleMouseEnter(item.id)} onMouseLeave={handleMouseOut}>
                <FilterDropDownItem
                  isHovered={selectedId === item.id}
                  isoCode={item?.isoCode}
                  name={nameFormatter(item)}
                  selected
                  onClickEvent={onClickDeleteEvent(item)}
                  alts={item.alts}
                />
              </div>
            );
          })}
        </div>
      )}

      <div className="overflow-y-auto border-b border-b-specialGray-012 max-h-[304px] w-full z-10">
        {withoutSelectedFilters(filterItems, selectedFilterItems).map(item => {
          return (
            <div key={item.id} onMouseEnter={handleMouseEnter(item.id)} onMouseLeave={handleMouseOut}>
              <FilterDropDownItem
                isHovered={selectedId === item.id}
                key={item.id}
                isoCode={item?.isoCode}
                name={nameFormatter(item)}
                onClickEvent={onClickEvent(item)}
                alts={item.alts}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
