import React, { forwardRef, Ref, useCallback, useState, KeyboardEvent } from 'react';
import { InputComponentProps } from '../types';

const InputComponent = forwardRef(
  ({ firstElement, lastElement, inFocus, showError, handleRemoveValueFromInput, testId, ...props }: InputComponentProps, ref: Ref<HTMLInputElement>) => {
    const [defaultStyle] = useState<string>(
      'bg-gray-100 border border-solid text-center outline-none outline-offset-0 font-mono sm:text-sm focus:ring-0 h-auto placeholder:text-specialGray-05',
    );
    const styleFocus: string = inFocus ? `border-blue-500` : 'border-specialGray-012';
    const borderColor: string = showError ? 'border-red-500 focus:border-red-500' : styleFocus;
    const [moveToPrevInput, setMovePrevInput] = useState<boolean>(false);

    const handlePressButton = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        const nextSibling: HTMLInputElement = (event.target as HTMLInputElement).nextElementSibling as HTMLInputElement;
        const prevSibling: HTMLInputElement = (event.target as HTMLInputElement).previousElementSibling as HTMLInputElement;
        if (event.key === 'ArrowLeft') {
          if (prevSibling && prevSibling.value) {
            prevSibling.focus();
            prevSibling.type = 'text';
            prevSibling.selectionStart = prevSibling.value.length;
            prevSibling.type = 'number';
          } else {
            (event.target as HTMLInputElement).focus();
          }
          return;
        }
        if (event.key === 'ArrowRight') {
          if (nextSibling && (event.target as HTMLInputElement).value) {
            nextSibling.focus();
            nextSibling.type = 'text';
            nextSibling.selectionStart = nextSibling.value.length;
            nextSibling.type = 'number';
          } else {
            (event.target as HTMLInputElement).focus();
          }
          return;
        }
        if (event.key === 'Backspace') {
          if (!nextSibling && !moveToPrevInput) {
            (event.target as HTMLInputElement).focus();
          } else if (prevSibling) {
            prevSibling.focus();
            prevSibling.value = '';
          }
        }
      },
      [moveToPrevInput],
    );

    const handleDownButton = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
          event.preventDefault();
          return;
        }
        if (event.key === 'ArrowLeft') {
          if ((event.target as HTMLInputElement).previousElementSibling && ((event.target as HTMLInputElement).previousElementSibling as HTMLInputElement).value) {
            event.preventDefault();
            return;
          }
        }
        if (event.key === 'ArrowRight') {
          if ((event.target as HTMLInputElement).nextElementSibling && ((event.target as HTMLInputElement).nextElementSibling as HTMLInputElement).value) {
            event.preventDefault();
            return;
          }
        }
        if (event.key === 'Backspace') {
          if (handleRemoveValueFromInput) {
            handleRemoveValueFromInput();
          }
          if ((event.target as HTMLInputElement).value) {
            setMovePrevInput(false);
          } else {
            setMovePrevInput(true);
            (event.target as HTMLInputElement).value = '';
          }
        }
      },
      [handleRemoveValueFromInput],
    );

    return (
      <input
        ref={ref}
        placeholder={inFocus ? '' : '×'}
        autoComplete="off"
        className={`${defaultStyle} ${borderColor} first:rounded-tl-[10px] first:rounded-bl-[10px] sm:first:rounded-tl-xl sm:first:rounded-bl-xl last:rounded-tr-[10px] last:rounded-br-[10px] sm:last:rounded-tr-xl sm:last:rounded-br-xl border-r-0 last:border`}
        onKeyUp={handlePressButton}
        onKeyDown={handleDownButton}
        data-test-id={testId}
        {...props}
      />
    );
  },
);

export default InputComponent;
