const ProfileProgressBarIcon4 = () => {
  return (
    <svg width="208" height="104" viewBox="0 0 208 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M201.242 97.1336C201.242 46.8982 157.607 6.17432 103.781 6.17432C49.9546 6.17432 6.31989 46.8982 6.31989 97.1336"
        stroke="#F1F2F4"
        strokeWidth="11.8136"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.31989 97.1343C6.31989 77.1006 13.2595 58.5796 25.0176 43.5509C37.1073 28.0984 54.291 16.338 74.2632 10.4215C93.3923 4.75469 114.169 4.75469 133.298 10.4215"
        stroke="#F1F2F4"
        strokeWidth="11.8136"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.31989 97.1343C6.31989 77.1006 13.2595 58.5796 25.0176 43.5509C37.1073 28.0984 54.291 16.338 74.2632 10.4215C93.3923 4.75469 114.169 4.75469 133.298 10.4215"
        stroke="url(#paint0_linear_6750_59351)"
        strokeWidth="11.8136"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient id="paint0_linear_6750_59351" x1="142.614" y1="24.1424" x2="-24.34" y2="222.172" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BFE42C" />
          <stop offset="1" stopColor="#F9E52C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProfileProgressBarIcon4;
