// packages
import { Suspense, useState } from 'react';
import { useIntl } from 'react-intl';
// components
import ListOfEmployers from './components/ListOfEmployers/ListOfEmployers';
import ListOfEmployersSkeleton from 'system/skeletons/lists/ListOfEmployersSkeleton/ListOfEmployersSkeleton';

const EmployersComponent = () => {
  const intl = useIntl();
  const [amountOrganisations, setAmountOrganisations] = useState<number>(0);

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center py-2">
        <div className="flex flex-col">
          <h2 className="text-lg sm:text-2xl text-darkBlue mb-1 font-medium">{intl.formatMessage({ id: 'crew_find_organisation' })}</h2>
          <span className="text-specialGray-075 text-sm">{intl.formatMessage({ id: 'job_openings_page_amount_found' }, { amount: amountOrganisations })}</span>
        </div>
      </div>
      <Suspense fallback={<ListOfEmployersSkeleton />}>
        <ListOfEmployers setAmountOrganisations={setAmountOrganisations} />
      </Suspense>
    </div>
  );
};

export default EmployersComponent;
