import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
// schemas
import { SEARCH_QUERY } from 'schemas/search/SearchQueries';
// generated
import { SearchQueriesQuery } from 'schemas/search/__generated__/SearchQueriesQuery.graphql';
import { ProfileQueriesGetOrganizationMembersConnectionQuery } from 'schemas/profile/__generated__/ProfileQueriesGetOrganizationMembersConnectionQuery.graphql';
// models
import { SearchProfileNode } from 'models/IProfiles';
import { GET_ALL_HIRE_ORGANIZATION_MEMBERS } from 'schemas/profile/ProfileQueries';
// components
import ProfileEmptyIcon from 'icons/ProfileEmptyIcon';
import NoSearchResult from 'app/Navbar/components/SearchInput/components/NoSearchResult';
import EmployerOrganizationAddMemberListItem from './EmployerOrganizationAddMemberListItem';

const EmployerOrganizationAddMemberList = ({ searchValue }: { searchValue: string }) => {
  const { orgId } = useParams();

  const getSearchedProfiles = useLazyLoadQuery<SearchQueriesQuery>(SEARCH_QUERY, {
    first: 15,
    query: searchValue,
    index: 'PROFILE',
    skip: !searchValue,
  });

  const allTeamMembers = useLazyLoadQuery<ProfileQueriesGetOrganizationMembersConnectionQuery>(GET_ALL_HIRE_ORGANIZATION_MEMBERS, {
    orgId: orgId!,
  }).organizationMembers;

  return (
    <div className="flex w-full flex-col overflow-y-scroll max-h-[350px]">
      {!isEmpty(getSearchedProfiles.search?.edges) ? (
        getSearchedProfiles?.search?.edges?.map(searchedProfile => {
          const isInvitedProfile = allTeamMembers?.edges?.find(member => member?.node?.profile?.id === searchedProfile?.node?.objectID);
          return searchedProfile?.node ? (
            <EmployerOrganizationAddMemberListItem
              key={searchedProfile?.node?.objectID}
              isInvitedProfile={!!isInvitedProfile}
              profileNode={searchedProfile?.node as SearchProfileNode}
            />
          ) : null;
        })
      ) : (
        <NoSearchResult message={{ id: 'search_input_no_profiles_results' }} showBorderBottom>
          <ProfileEmptyIcon defaultClasses="w-12 h-8" />
        </NoSearchResult>
      )}
    </div>
  );
};

export default EmployerOrganizationAddMemberList;
