/**
 * @generated SignedSource<<180d6b10b18e312186b32074e590014e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DocumentData = {
  kindId: string;
  original?: string | null;
  ownerId?: string | null;
  urls: ReadonlyArray<string>;
  xlarge_urls?: ReadonlyArray<string> | null;
};
export type DocumentMutationsCreateDocumentMutation$variables = {
  data: DocumentData;
};
export type DocumentMutationsCreateDocumentMutation$data = {
  readonly createDocument: {
    readonly node: {
      readonly id: string;
      readonly urls: ReadonlyArray<string>;
      readonly xlarge_urls: ReadonlyArray<string> | null;
    } | null;
  };
};
export type DocumentMutationsCreateDocumentMutation = {
  response: DocumentMutationsCreateDocumentMutation$data;
  variables: DocumentMutationsCreateDocumentMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'data',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'data',
            variableName: 'data',
          },
        ],
        concreteType: 'DocumentEdge',
        kind: 'LinkedField',
        name: 'createDocument',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'Document',
            kind: 'LinkedField',
            name: 'node',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'urls',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'xlarge_urls',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'DocumentMutationsCreateDocumentMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'DocumentMutationsCreateDocumentMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '83332d8a601b4f647ed66456a5104524',
      id: null,
      metadata: {},
      name: 'DocumentMutationsCreateDocumentMutation',
      operationKind: 'mutation',
      text: 'mutation DocumentMutationsCreateDocumentMutation(\n  $data: DocumentData!\n) {\n  createDocument(data: $data) {\n    node {\n      id\n      urls\n      xlarge_urls\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '0c281efb743cd92797b54c46681da63d';

export default node;
