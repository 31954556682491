// packages
import { useFragment, useLazyLoadQuery } from 'react-relay';
// hooks
import { useAuth } from '../authentication';
// generated
import { ProfileFragmentsScoreSubscription$key } from 'schemas/profile/__generated__/ProfileFragmentsScoreSubscription.graphql';
import { ProfileQueriesScoreSubscriptionQuery } from 'schemas/profile/__generated__/ProfileQueriesScoreSubscriptionQuery.graphql';
// schemas
import { PROFILE_SCORE_FRAGMENT } from 'schemas/profile/ProfileFragments';
import { PROFILE_SCORE_FOR_SUBSCRIPTION } from 'schemas/profile/ProfileQueries';
// models
import { ProfileScoreDetailsType } from 'models/commonTypes';

export function useGetProfileScore(): { profileScoreSummary: number; profileScoreDetails: Array<ProfileScoreDetailsType> } {
  const { identity } = useAuth();

  const getProfileScoreFragment = useLazyLoadQuery<ProfileQueriesScoreSubscriptionQuery>(PROFILE_SCORE_FOR_SUBSCRIPTION, {
    pid: +identity?.profileId!,
    skip: !identity?.profileId,
  });
  const profileScore = useFragment<ProfileFragmentsScoreSubscription$key>(PROFILE_SCORE_FRAGMENT, getProfileScoreFragment.profileScore || null);

  return {
    profileScoreSummary: profileScore?.summary || 0,
    profileScoreDetails: profileScore?.details ? JSON.parse(profileScore.details) : [],
  };
}
