// packages
import { useParams } from 'react-router-dom';
import { useFragment, useLazyLoadQuery } from 'react-relay';
// schemas
import { CREW_PROFILE_QUERY } from 'schemas/profile/ProfileQueries';
import { CREW_PROFILE_FRAGMENT, PROFILE_GENERAL_SETTINGS_FRAGMENT, PROFILE_INFO_FRAGMENT } from 'schemas/profile/ProfileFragments';
// generated
import { ProfileFragments_info$key } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
import { ProfileFragmentsCrewProfile$key } from 'schemas/profile/__generated__/ProfileFragmentsCrewProfile.graphql';
import { ProfileQueriesCrewProfileQuery } from 'schemas/profile/__generated__/ProfileQueriesCrewProfileQuery.graphql';
import { ProfileFragmentsGeneralSettings$key } from 'schemas/profile/__generated__/ProfileFragmentsGeneralSettings.graphql';
// components
import ProfileSlideInfo from './components/ProfileSlideInfo';
import ProfileSlideHeader from './components/ProfileSlideHeader';
import ProfileSlideServices from './components/ProfileSlideServices';
import ProfileSlideEducations from './components/ProfileSlideEducations';
import ProfileSlideCertificates from './components/ProfileSlideCertificates';
// routing
import { RoutesPathNames } from 'app/routing';

const ProfileSlide = () => {
  const params = useParams();
  const profileSideBarId = params[RoutesPathNames.profileSideBarId];

  const profile = useLazyLoadQuery<ProfileQueriesCrewProfileQuery>(CREW_PROFILE_QUERY, { id: profileSideBarId! });

  const profileFragment = useFragment<ProfileFragmentsCrewProfile$key>(CREW_PROFILE_FRAGMENT, profile.node || null);
  const profileInfo = useFragment<ProfileFragments_info$key>(PROFILE_INFO_FRAGMENT, profileFragment?.info as ProfileFragments_info$key);

  const generalSettings = useFragment<ProfileFragmentsGeneralSettings$key>(PROFILE_GENERAL_SETTINGS_FRAGMENT, profileFragment?.generalSetting || null);

  return (
    <div className="flex w-full flex-col">
      <ProfileSlideHeader generalSettings={generalSettings} profileInfo={profileInfo} profileId={profileSideBarId!} />
      <ProfileSlideInfo profileInfo={profileInfo} />

      <div className="flex flex-col w-full gap-y-8">
        <ProfileSlideServices seaServicesFragments={profileFragment?.seaServices} />
        <ProfileSlideCertificates certificateInfosFragment={profileFragment?.certificateInfos} />
        <ProfileSlideEducations educationFragments={profileFragment?.educations} />
      </div>
    </div>
  );
};

export default ProfileSlide;
