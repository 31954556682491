import isAfter from 'date-fns/isAfter';
import { useIntl } from 'react-intl';
import format from 'date-fns/format';
//components
import DangerIcon from 'icons/DangerIcon';
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';
//types
import { CertificateExpiryDateItemProps } from 'app/Profile/Certificates/types';

const CertificateExpiryDateItem = ({ children, expiryDate, popoverText = 'certificate_expired' }: CertificateExpiryDateItemProps) => {
  const intl = useIntl();
  const dateExpired = isAfter(new Date(), new Date(expiryDate as string));

  return (
    <div className="text-specialGray-03 text-xs flex items-center">
      {dateExpired && (
        <PopoverHelper
          element={
            <div className="relative inline-block">
              <DangerIcon />
            </div>
          }
          position="bottom-8 -left-2 sm:left-[70%] sm:-translate-x-1/2"
          positionArrow="sm:left-1/2 left-4 sm:left-1/2 sm:-translate-x-1/2 rotate-45 -bottom-0.5"
          widthContainer="w-[200px] sm:min-w-fit"
          darkTheme
        >
          {intl.formatMessage({ id: popoverText })}
        </PopoverHelper>
      )}

      <span className="sm:ml-1 text-specialGray-075 sm:whitespace-nowrap">{format(new Date(`${expiryDate}`), 'dd MMM yyyy')}</span>
      {children}
    </div>
  );
};

export default CertificateExpiryDateItem;
