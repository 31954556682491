// packages
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, createRoutesFromChildren, matchRoutes, RouterProvider, useLocation, useNavigationType } from 'react-router-dom';
// routing
import { AuthRoute } from 'routing/auth/authRoute';
import { AdminRoutes } from 'routing/admin/adminRoute';
import { CypressRoute } from 'routing/cypress/CypressRoute';
import { RegisterRoute } from 'routing/Register/registerRoute';
import { MainLayoutRoute } from 'routing/mainLayout/MainLayoutRoute';
import { SimpleRoute } from 'routing/simpleRoute/SimpleRoute/SimpleRoute';
import { JobEditDetailsRoute } from 'routing/JobEditDetails/JobEditDetailsRoute';
// components
import Providers from 'Providers';
// utils
import { backListErrors } from './utils/sentryBlackList';
// styles
import 'index.css';
import 'WaterSpinner.css';
import 'App.css';
import 'react-loading-skeleton/dist/skeleton.css';

Sentry.init({
  dsn: process.env.REACT_APP_PUBLIC_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV !== 'development',
  ignoreErrors: backListErrors,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([AuthRoute, ...RegisterRoute, MainLayoutRoute, JobEditDetailsRoute, ...SimpleRoute, CypressRoute, ...AdminRoutes]);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Providers>
    <RouterProvider router={router} />
  </Providers>,
);
