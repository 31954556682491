// packages
import { useCallback, useState } from 'react';
import { useMutation } from 'react-relay';
import { Form, FormikProvider, useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
// routing
import { RoutePaths } from 'app/routing';
// generated
import { BasicInfoFormMutationsUpdateMutation } from 'schemas/forms/__generated__/BasicInfoFormMutationsUpdateMutation.graphql';
// schemas
import { UPDATE_PROFILE_INFO_MUTATION } from 'schemas/forms/BasicInfoFormMutations';
// components
import Footer from '../components/Footer';
import Header from '../components/Header';
import AlertForError from 'system/Alert/AlertForError';
import CalendarExperience from 'icons/CalendarExperience';
import RadioButtons from 'system/RadioButtons/RadioButtons';
// formHelpers
import { RegisterWithExperience } from 'formHelpers/initialValuesOfForms';
import { RegisterStepWithExperienceValidateSchema } from 'formHelpers/validationsOfForms';
// mocks
import { numberOfYearsOfExperience } from 'mocks/mockData';
// enums
import { PLATFORM_GROUP, SIGN_UP_STEP_WITH_USER_EXPERIENCE } from 'models/enums';
// models
import { SignUpStepsState } from 'models/routeLocationState';

const StepWithExperience = ({ defaultSelected }: { defaultSelected?: typeof numberOfYearsOfExperience[number] }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [error, setError] = useState<Error | undefined>();
  const [selected, setSelected] = useState<typeof numberOfYearsOfExperience[number]>(defaultSelected || numberOfYearsOfExperience[0]);

  const [commitUpdate, updateLoading] = useMutation<BasicInfoFormMutationsUpdateMutation>(UPDATE_PROFILE_INFO_MUTATION);

  const formik = useFormik<{ userExperience: SIGN_UP_STEP_WITH_USER_EXPERIENCE }>({
    onSubmit,
    initialValues: RegisterWithExperience,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: RegisterStepWithExperienceValidateSchema,
  });

  const handleSetSelected = useCallback(
    (value: typeof numberOfYearsOfExperience[number]) => {
      setSelected(value);
      formik.setValues({ userExperience: value.id });
    },
    [formik],
  );

  async function onSubmit(data: { userExperience: SIGN_UP_STEP_WITH_USER_EXPERIENCE }) {
    if ((state as SignUpStepsState).userExperience !== data.userExperience) {
      commitUpdate({
        variables: {
          id: (state as SignUpStepsState).profileInfo.id,
          data: {
            ownerId: (state as SignUpStepsState).profileInfo.owner.id,
            firstName: (state as SignUpStepsState).profileInfo.firstName,
            lastName: (state as SignUpStepsState).profileInfo.lastName,
            birthDate: (state as SignUpStepsState).profileInfo.birthDate,
            countryId: (state as SignUpStepsState).countryId,
            yearsOfExperience: data.userExperience as SIGN_UP_STEP_WITH_USER_EXPERIENCE,
          },
        },
        onCompleted: () => {
          window.analytics?.track('wui-signup-step-with-enter-experience', {
            yearsOfExperience: data.userExperience,
            type_of_account: PLATFORM_GROUP.CREW,
          });
          navigate(RoutePaths.AuthRegisterStepEight, { state: { ...state, userExperience: data.userExperience } });
        },
        onError: (error: Error) => {
          setError(error);
          formik.setSubmitting(false);
        },
      });
    }
  }

  return (
    <section className="flex w-full flex-col h-full sm:h-auto pt-5 pb-7">
      <Header title={{ id: 'step_with_experience_title' }} description={{ id: 'step_with_experience_description' }} icon={<CalendarExperience />} />
      <FormikProvider value={formik}>
        <Form className="flex flex-col mt-7 sm:mt-8 w-full sm:py-8 px-4 sm:border sm:border-specialGray-012 sm:px-10 rounded-[20px] space-y-[18px] h-full justify-between sm:justify-start">
          {error && <AlertForError heading={{ id: 'register_failedAlert' }} error={error} />}
          <RadioButtons value={selected} setValue={handleSetSelected} listOfRadioButtons={numberOfYearsOfExperience} />
          <Footer pathnameToPrevStep={RoutePaths.AuthRegisterStepOne} isDisabled={!formik.values.userExperience || updateLoading} />
        </Form>
      </FormikProvider>
    </section>
  );
};

export default StepWithExperience;
