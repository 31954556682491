// packages
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import { parse, format as formatDate, format } from 'date-fns';
import { useIntl } from 'react-intl';
import { isEmpty, uniqueId } from 'lodash';
// components
import { FieldErrorMessage } from './FieldErrorMessage';
import LabelComponent from 'system/LabelComponent/LabelComponent';
// models
import { DateInputGroupProps } from 'models/modelsOfComponents';

export const DateInputGroup: React.FC<DateInputGroupProps> = ({ name, description, label, autoFocus = false, isResetInput, disabled = false, trackingCallback }) => {
  const intl = useIntl();
  const formikContext = useFormikContext<{ start?: Date; end?: Date }>();

  const [, meta, helpers] = useField<Date | undefined>({
    name,
  });
  // initialize state for controlled React inputs
  const [id] = useState(() => uniqueId('ig-'));

  const showError = !disabled && meta.touched && meta.error;
  const refs = { day: useRef<HTMLInputElement>(null), month: useRef<HTMLInputElement>(null), year: useRef<HTMLInputElement>(null) };
  const [date, setDate] = useState<{ [key: string]: string }>({ day: '', month: '', year: '' });

  useEffect(() => {
    setDate({
      day: meta.initialValue ? formatDate(meta.initialValue, 'dd') : '',
      month: meta.initialValue ? formatDate(meta.initialValue, 'MM') : '',
      year: meta.initialValue ? formatDate(meta.initialValue, 'yyyy') : '',
    });
  }, [meta.initialValue, refs.day, refs.month, refs.year]);

  const getDateFromRefs = useCallback(() => {
    const { day: d, month: m, year: y } = date;
    return { d, m, y };
  }, [date]);

  const handleDayChange = (currentField: 'day' | 'month' | 'year', length: number, nextField: 'month' | 'year' | null) => () => {
    if (!refs[currentField]?.current) {
      return;
    }

    if ((refs[currentField]?.current as HTMLInputElement)?.value.length <= length) {
      setDate(prevState => ({ ...prevState, [currentField]: refs[currentField]?.current?.value || '' }));
    }

    const { d, m, y } = getDateFromRefs();

    if ((d?.length as number) >= 1 && (m?.length as number) >= 1 && (y?.length as number) >= 4) {
      helpers.setValue(parse(`${d}-${m}-${y}`, 'dd-MM-yyyy', new Date()));
      helpers.setTouched(true);
    }

    if ((d?.length as number) === 0 && (m?.length as number) === 0 && (y?.length as number) === 0) {
      formikContext.setFieldValue(name, undefined);
      formikContext.validateField(name);
      helpers.setTouched(true);
    }

    if (!nextField) {
      return;
    }

    if ((refs[currentField]?.current as HTMLInputElement)?.value.length >= length) {
      refs[nextField]?.current?.focus();
    }
  };

  useEffect(() => {
    if (isResetInput || disabled) {
      setDate({
        day: '',
        month: '',
        year: '',
      });
      helpers.setValue(undefined);
      helpers.setTouched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetInput, disabled]);

  useEffect(() => {
    if (refs.day?.current && autoFocus) {
      refs.day?.current.focus();
    }
  }, [autoFocus, refs.day]);

  const inputClasses =
    showError || !isEmpty(meta.initialError)
      ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 text-xs sm:text-sm rounded-md'
      : 'shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full text-xs sm:text-sm border-specialGray-012 placeholder:text-specialGray-05 rounded-md';

  useEffect(() => {
    const { d, m, y } = getDateFromRefs();
    if (d.length <= 2 && m.length <= 2 && y.length <= 4) {
      const isCorrectYear = y.length === 4;
      helpers.setValue(isCorrectYear ? parse(`${d}-${m}-${y}`, 'dd-MM-yyyy', new Date()) : undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDateFromRefs]);

  const handleOnBlurContainer = useCallback(() => {
    const { d, m, y } = getDateFromRefs();
    if (!meta.touched) {
      helpers.setTouched(true);
    }
    if (name === 'start' && y.length === 4) {
      formikContext.setTouched({ ...formikContext.touched, end: true });
    }
    if (trackingCallback && d.length <= 2 && m.length <= 2 && y.length === 4) {
      trackingCallback(`${d}-${m}-${y}`);
    }
  }, [formikContext, getDateFromRefs, helpers, meta.touched, name, trackingCallback]);

  return (
    <div className="relative">
      <LabelComponent id={name + id} label={label} classes={`block text-xs sm:text-sm font-medium ${disabled ? 'text-gray-500' : 'text-specialGray-1'}`} />
      <div className={'mt-2 ' + (showError || !isEmpty(meta.initialError) ? 'relative rounded-md shadow-sm' : '')}>
        <div onBlur={handleOnBlurContainer} className="mt-1 flex justify-between items-baseline w-full space-x-4 text-darkBlue">
          <input
            autoFocus={autoFocus}
            type="number"
            placeholder={intl.formatMessage({ id: 'DD' })}
            id={id + '-dd'}
            disabled={disabled}
            className={`${disabled && 'bg-gray-200'} ${inputClasses}`}
            aria-labelledby={id}
            aria-describedby={description && id + '-description'}
            ref={refs.day}
            onChange={handleDayChange('day', 2, 'month')}
            value={date.day}
            data-test-id={`${name}-dd`}
          />

          <input
            type="number"
            placeholder={intl.formatMessage({ id: 'MM' })}
            id={id + '-mm'}
            disabled={disabled}
            className={`${disabled && 'bg-gray-200'} ${inputClasses}`}
            aria-labelledby={id}
            aria-describedby={description && id + '-description'}
            ref={refs.month}
            onChange={handleDayChange('month', 2, 'year')}
            value={date.month}
            data-test-id={`${name}-mm`}
          />

          <input
            type="number"
            placeholder={intl.formatMessage({ id: 'YYYY' })}
            id={id + '-yy'}
            aria-labelledby={id}
            disabled={disabled}
            className={`${disabled && 'bg-gray-200'} ${inputClasses}`}
            ref={refs.year}
            onChange={handleDayChange('year', 4, null)}
            value={date.year}
            data-test-id={`${name}-yy`}
          />
        </div>
      </div>

      {(showError || !isEmpty(meta.initialError)) && (
        <>
          {formikContext.errors?.end === 'default_end_date_is_max_then_start' ? (
            <div className="absolute -bottom-6 left-0.5">
              <FieldErrorMessage error={meta.error || meta.initialError} dynamicErrorValue={format(formikContext.values.start!, 'dd.MM.yyyy')} />
            </div>
          ) : (
            <div className="absolute -bottom-6 left-0.5">
              <FieldErrorMessage error={meta.error || meta.initialError} dynamicErrorValue={format(new Date(), 'dd.MM.yyyy')} />
            </div>
          )}
        </>
      )}
      {(!showError || !isEmpty(meta.initialError)) && description && (
        <p className="mt-2 text-xs sm:text-sm text-gray-500 my-auto sm:my-0" id={id + '-description'}>
          {intl.formatMessage(description)}
        </p>
      )}
    </div>
  );
};
