/**
 * @generated SignedSource<<8b1d9fdf2ffd5635ead85bb218998c7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OrganizationFleetsize = 'SIZE_0_5' | 'SIZE_25_100' | 'SIZE_26_100' | 'SIZE_2_25' | 'SIZE_6_25' | 'SIZE_MORE_100' | '%future added value';
export type OrganizationSize = 'SIZE_11_50' | 'SIZE_1_10' | 'SIZE_201_500' | 'SIZE_501_1000' | 'SIZE_51_200' | 'SIZE_MORE_1000' | '%future added value';
export type OrganizationData = {
  avatarUrl?: string | null;
  city?: string | null;
  countryId?: string | null;
  description?: string | null;
  fleetsize?: OrganizationFleetsize | null;
  headquarters?: string | null;
  name: string;
  size?: OrganizationSize | null;
  websiteUrl?: string | null;
};
export type ProfileMutationsCreateOrganizationMutation$variables = {
  data: OrganizationData;
};
export type ProfileMutationsCreateOrganizationMutation$data = {
  readonly createOrganization: {
    readonly city: string | null;
    readonly country: {
      readonly id: string;
      readonly isoCode: string;
      readonly name: string;
    } | null;
    readonly id: string;
    readonly name: string;
    readonly size: OrganizationSize | null;
  };
};
export type ProfileMutationsCreateOrganizationMutation = {
  response: ProfileMutationsCreateOrganizationMutation$data;
  variables: ProfileMutationsCreateOrganizationMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'data',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v3 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'data',
            variableName: 'data',
          },
        ],
        concreteType: 'Organization',
        kind: 'LinkedField',
        name: 'createOrganization',
        plural: false,
        selections: [
          v1 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'Country',
            kind: 'LinkedField',
            name: 'country',
            plural: false,
            selections: [
              v2 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'isoCode',
                storageKey: null,
              },
              v1 /*: any*/,
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'city',
            storageKey: null,
          },
          v2 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'size',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileMutationsCreateOrganizationMutation',
      selections: v3 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileMutationsCreateOrganizationMutation',
      selections: v3 /*: any*/,
    },
    params: {
      cacheID: 'ebfb33a809668c444243d5b4bd9c3068',
      id: null,
      metadata: {},
      name: 'ProfileMutationsCreateOrganizationMutation',
      operationKind: 'mutation',
      text: 'mutation ProfileMutationsCreateOrganizationMutation(\n  $data: OrganizationData!\n) {\n  createOrganization(data: $data) {\n    id\n    country {\n      name\n      isoCode\n      id\n    }\n    city\n    name\n    size\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'd87e479e0b339d0012a65f6d61d703cd';

export default node;
