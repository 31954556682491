/**
 * @generated SignedSource<<ca72c16e9bc6e59f0d3a3c8474f66ae6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type OrderDirection = 'ASC' | 'DESC' | '%future added value';
export type SeaServiceOrderField = 'START_DATE' | '%future added value';
export type SeaServiceOrder = {
  direction: OrderDirection;
  field?: SeaServiceOrderField | null;
};
export type ServiceQueriesListOfServicesFromProfileQuery$variables = {
  after?: any | null;
  first?: number | null;
  orderBy?: SeaServiceOrder | null;
  ownerId: string;
  skip: boolean;
};
export type ServiceQueriesListOfServicesFromProfileQuery$data = {
  readonly seaServices?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly 'end': String | null;
        readonly 'id': string;
        readonly 'start': String;
        readonly 'vessel': {
          readonly name: string;
        };
        readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragments'>;
      } | null;
    } | null> | null;
  } | null;
};
export type ServiceQueriesListOfServicesFromProfileQuery = {
  response: ServiceQueriesListOfServicesFromProfileQuery$data;
  variables: ServiceQueriesListOfServicesFromProfileQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'after',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'first',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'orderBy',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'ownerId',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'skip',
    },
    v5 = {
      kind: 'Variable',
      name: 'orderBy',
      variableName: 'orderBy',
    },
    v6 = {
      kind: 'Variable',
      name: 'ownerId',
      variableName: 'ownerId',
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'start',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'end',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: '__typename',
      storageKey: null,
    },
    v12 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cursor',
      storageKey: null,
    },
    v13 = {
      alias: null,
      args: null,
      concreteType: 'PageInfo',
      kind: 'LinkedField',
      name: 'pageInfo',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'endCursor',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'hasNextPage',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v14 = [
      {
        kind: 'Variable',
        name: 'after',
        variableName: 'after',
      },
      {
        kind: 'Variable',
        name: 'first',
        variableName: 'first',
      },
      v5 /*: any*/,
      v6 /*: any*/,
    ],
    v15 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'perma',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'ServiceQueriesListOfServicesFromProfileQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: 'seaServices',
              args: [v5 /*: any*/, v6 /*: any*/],
              concreteType: 'SeaServiceConnection',
              kind: 'LinkedField',
              name: '__ServiceListFromProfile_seaServices_connection',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'SeaServiceEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'SeaService',
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [
                        v7 /*: any*/,
                        v8 /*: any*/,
                        v9 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Vessel',
                          kind: 'LinkedField',
                          name: 'vessel',
                          plural: false,
                          selections: [v10 /*: any*/],
                          storageKey: null,
                        },
                        {
                          args: null,
                          kind: 'FragmentSpread',
                          name: 'ServiceFragments',
                        },
                        v11 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    v12 /*: any*/,
                  ],
                  storageKey: null,
                },
                v13 /*: any*/,
              ],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v3 /*: any*/, v0 /*: any*/, v1 /*: any*/, v4 /*: any*/, v2 /*: any*/],
      kind: 'Operation',
      name: 'ServiceQueriesListOfServicesFromProfileQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v14 /*: any*/,
              concreteType: 'SeaServiceConnection',
              kind: 'LinkedField',
              name: 'seaServices',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'SeaServiceEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'SeaService',
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [
                        v7 /*: any*/,
                        v8 /*: any*/,
                        v9 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Vessel',
                          kind: 'LinkedField',
                          name: 'vessel',
                          plural: false,
                          selections: [
                            v10 /*: any*/,
                            v7 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'imo',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'grossTonnage',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'operatorName',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              concreteType: 'VesselKind',
                              kind: 'LinkedField',
                              name: 'kind',
                              plural: false,
                              selections: [v7 /*: any*/, v10 /*: any*/, v15 /*: any*/],
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'length',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'image',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'cnISO',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'countryName',
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'rotations',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'description',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Duty',
                          kind: 'LinkedField',
                          name: 'duty',
                          plural: false,
                          selections: [
                            v7 /*: any*/,
                            v10 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'alts',
                              storageKey: null,
                            },
                            v15 /*: any*/,
                          ],
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Activity',
                          kind: 'LinkedField',
                          name: 'activities',
                          plural: true,
                          selections: [
                            v7 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'label',
                              storageKey: null,
                            },
                            v15 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'timesUsed',
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                        v11 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    v12 /*: any*/,
                  ],
                  storageKey: null,
                },
                v13 /*: any*/,
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: v14 /*: any*/,
              filters: ['ownerId', 'orderBy'],
              handle: 'connection',
              key: 'ServiceListFromProfile_seaServices',
              kind: 'LinkedHandle',
              name: 'seaServices',
            },
          ],
        },
      ],
    },
    params: {
      cacheID: 'fefa38374c2373dd0296de5c4a0165ed',
      id: null,
      metadata: {
        connection: [
          {
            count: 'first',
            cursor: 'after',
            direction: 'forward',
            path: ['seaServices'],
          },
        ],
      },
      name: 'ServiceQueriesListOfServicesFromProfileQuery',
      operationKind: 'query',
      text: 'query ServiceQueriesListOfServicesFromProfileQuery(\n  $ownerId: ID!\n  $after: Cursor\n  $first: Int\n  $skip: Boolean!\n  $orderBy: SeaServiceOrder\n) {\n  seaServices(ownerId: $ownerId, first: $first, after: $after, orderBy: $orderBy) @skip(if: $skip) {\n    edges {\n      node {\n        id\n        start\n        end\n        vessel {\n          name\n          id\n        }\n        ...ServiceFragments\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ServiceFragments on SeaService {\n  id\n  start\n  end\n  rotations\n  description\n  vessel {\n    ...ServiceFragmentsVessel\n    id\n  }\n  duty {\n    ...ServiceFragmentsOneDuty\n    id\n  }\n  activities {\n    ...ServiceFragmentsActivities\n    id\n  }\n}\n\nfragment ServiceFragmentsActivities on Activity {\n  id\n  label\n  perma\n  timesUsed\n}\n\nfragment ServiceFragmentsOneDuty on Duty {\n  id\n  name\n  alts\n  perma\n}\n\nfragment ServiceFragmentsVessel on Vessel {\n  id\n  imo\n  name\n  grossTonnage\n  operatorName\n  kind {\n    id\n    name\n    perma\n  }\n  length\n  image\n  cnISO\n  countryName\n}\n',
    },
  };
})();

(node as any).hash = '6c8ba041833ca8a3043e2a25c0d922d3';

export default node;
