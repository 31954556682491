const EditIconWithUnderline = ({ className = 'h-5 w-5 text-[#8490A8]' }: { className?: string }) => {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.4161 16.5807H16.7303" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7124 4.12902C11.3266 3.34652 12.3191 3.38735 13.1024 4.00152L14.2607 4.90985C15.0441 5.52402 15.3216 6.47652 14.7074 7.26069L7.79991 16.0732C7.56907 16.3682 7.21657 16.5424 6.84157 16.5465L4.17741 16.5807L3.57407 13.9849C3.48907 13.6207 3.57407 13.2374 3.80491 12.9415L10.7124 4.12902Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.41888 5.78125L13.4139 8.91292" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default EditIconWithUnderline;
