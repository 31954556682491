import { useIntl } from 'react-intl';
import { Menu } from '@headlessui/react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
// models
import { NavLinkProps } from 'app/Navbar/types';

const CustomNavLink = ({ to, label, onClick, state }: NavLinkProps) => {
  const intl = useIntl();
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Menu.Item>
      <Link
        onClick={onClick}
        to={to}
        className={
          match ? 'bg-specialGray-005 block w-full text-left px-4 py-2 text-sm text-darkBlue' : 'hover:bg-specialGray-005 block w-full text-left px-4 py-2 text-sm text-darkBlue'
        }
        state={state}
      >
        {intl.formatMessage(label)}
      </Link>
    </Menu.Item>
  );
};

export default CustomNavLink;
