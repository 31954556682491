import { useIntl } from 'react-intl';
//components
import CountryFlag from 'system/CountryFlag';
import OverviewTextItemWithPopover from '../../components/OverviewTextItemWithPopover';
//type
import { CrewsSeaServiceInfoEvItemProps } from 'app/ProfileCrew/type/ProfileCrewTypes';

const CrewsSeaServiceInfoEvItem = ({ title, iso, description }: CrewsSeaServiceInfoEvItemProps) => {
  const intl = useIntl();
  return (
    <div className="flex text-xs mr-[18px] whitespace-nowrap items-center leading-5">
      <span className="text-specialGray-075 mr-1.5">{intl.formatMessage(title)}</span>
      {iso && <CountryFlag classes="mr-1.5" size="max-w-[24px] h-[16px]" countryId={iso} countryName={description} />}
      <OverviewTextItemWithPopover description={description} />
    </div>
  );
};

export default CrewsSeaServiceInfoEvItem;
