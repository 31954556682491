/**
 * @generated SignedSource<<eb5b72555d21babb96688f616fbf6601>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type EducationFragments$data = {
  readonly 'end': String | null;
  readonly 'id': string;
  readonly 'kind': {
    readonly id: string;
    readonly name: string;
    readonly perma: string;
  } | null;
  readonly 'name': string;
  readonly 'start': String;
  readonly ' $fragmentType': 'EducationFragments';
};
export type EducationFragments$key = {
  readonly ' $data'?: EducationFragments$data;
  readonly ' $fragmentSpreads': FragmentRefs<'EducationFragments'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    };
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'EducationFragments',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'start',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'end',
        storageKey: null,
      },
      v1 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'EducationKind',
        kind: 'LinkedField',
        name: 'kind',
        plural: false,
        selections: [
          v0 /*: any*/,
          v1 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'perma',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
    type: 'Education',
    abstractKey: null,
  };
})();

(node as any).hash = '341c219341e79bb22bde52f3676af51c';

export default node;
