// packages
import { useRef } from 'react';
// routing
import { JOB_OPENING_TABS } from 'app/routing';
// hooks
import { useGetPositions } from 'hooks/useGetPositions';
// components
import LockWhiteIcon from 'icons/LockWhiteIcon';
import ArrowComponent from 'system/Layouts/JobDescriptionTabs/components/ArrowComponent';
import PopoverContent from 'system/Layouts/JobDescriptionTabs/components/PopoverContent';
// types
import { VacancyTabWithPopoverProps } from '../../types';

const VacancyTabWithPopover = ({ autoShow, callback, index, name, popoverDescription }: VacancyTabWithPopoverProps) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const refWrapper = useRef<HTMLDivElement>(null);

  const { handleShowPopover, handleMouseOver, hoverElement } = useGetPositions(refContainer, refWrapper, autoShow);

  return (
    <>
      <div
        className="flex relative justify-center w-fit items-center h-16 cursor-pointer cursor-not-allowed text-darkBlue"
        ref={refWrapper}
        onMouseOver={handleMouseOver}
        onClick={handleShowPopover}
      >
        <div className="flex">
          <LockWhiteIcon />
          <span className="ml-3">{name}</span>
          <div className="absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 left-1/2 -bottom-[175px] 2xl:-bottom-40 inline-block">
            {hoverElement && (
              <div ref={refContainer} className="relative w-min">
                <div className="absolute h-2 w-2 transform origin-top-left border border-l-0 border-t-0 bg-white rotate-45 -bottom-0.5 left-1/2" />
                <div className="p-2 text-center rounded-md border bg-white text-gray">
                  <PopoverContent popoverDescription={popoverDescription} onClick={callback} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {index && JOB_OPENING_TABS.length - 1 > index && <ArrowComponent index={index} />}
    </>
  );
};

export default VacancyTabWithPopover;
