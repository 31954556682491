// packages
import Skeleton from 'react-loading-skeleton';
// components
import LineSvg from 'icons/LineSvg';
import DateInputSkeleton from 'system/skeletons/components/DateInputSkeleton';
import TextInputSkeleton from 'system/skeletons/components/TextInputSkeleton';
import VesselInformationSkeleton from './components/VesselInformationSkeleton';

const StepWithSeaServiceFormSkeleton = () => {
  return (
    <>
      <TextInputSkeleton heightInput={48} borderRadius="6px" widthTitle={100} />
      <div className="mt-3.5 space-y-3.5">
        <DateInputSkeleton labelWidth={50} wrapperClasses="w-full" inputHeight={48} />
        <DateInputSkeleton labelWidth={45} wrapperClasses="w-full" inputHeight={48} />
      </div>
      <LineSvg classes="mt-6 mb-7" />
      <Skeleton width={100} height={20} className="mb-6" />
      <div className="relative">
        <VesselInformationSkeleton />
        <Skeleton width={160} height={16} containerClassName="absolute right-0 top-0 flex items-center z-10" />
      </div>
    </>
  );
};

export default StepWithSeaServiceFormSkeleton;
