import { useMemo } from 'react';
//hooks
import { useGetHireProfileVacancies } from './useGetHireProfileVacancies';

const useInvitedProfilesToVacancies = (): string[] => {
  const getVacancies = useGetHireProfileVacancies();
  return useMemo(() => {
    const IDs: string[] = [];
    if (!getVacancies?.length) {
      return IDs;
    }
    getVacancies.forEach(vacancies => {
      vacancies?.node?.invites?.forEach(vacancyInviteItem => IDs.push(vacancyInviteItem.owner.id));
    });
    return IDs;
  }, [getVacancies]);
};

export default useInvitedProfilesToVacancies;
