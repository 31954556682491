/**
 * @generated SignedSource<<5518403818ddf3807a936fed40929bb2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CountryQueriesCountriesOfResidenceQuery$variables = {
  skip: boolean;
};
export type CountryQueriesCountriesOfResidenceQuery$data = {
  readonly countries?: {
    readonly edges: ReadonlyArray<{
      readonly id: string;
      readonly isoCode: string;
      readonly name: string;
    }>;
  } | null;
};
export type CountryQueriesCountriesOfResidenceQuery = {
  response: CountryQueriesCountriesOfResidenceQuery$data;
  variables: CountryQueriesCountriesOfResidenceQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'skip',
      },
    ],
    v1 = [
      {
        condition: 'skip',
        kind: 'Condition',
        passingValue: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'CountryList',
            kind: 'LinkedField',
            name: 'countries',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'Country',
                kind: 'LinkedField',
                name: 'edges',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'id',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'isoCode',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'name',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'CountryQueriesCountriesOfResidenceQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'CountryQueriesCountriesOfResidenceQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '31f96fdd3a5c8c882b7fd928fdcc8f57',
      id: null,
      metadata: {},
      name: 'CountryQueriesCountriesOfResidenceQuery',
      operationKind: 'query',
      text: 'query CountryQueriesCountriesOfResidenceQuery(\n  $skip: Boolean!\n) {\n  countries @skip(if: $skip) {\n    edges {\n      id\n      isoCode\n      name\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '0baecaee7acaecf0cc49b5ec170ab3d7';

export default node;
