import Skeleton from 'react-loading-skeleton';

const MainOrgInfoSkeleton = () => (
  <>
    <div className="flex gap-x-6">
      <Skeleton width={56} height={56} borderRadius={6} />
      <div className="flex flex-col">
        <Skeleton width={200} height={18} />
        <div className="flex mt-3 gap-x-3 sm:gap-x-6">
          <Skeleton width={110} height={14} />
          <Skeleton width={80} height={14} />
        </div>
      </div>
    </div>
    <div className="flex flex-col gap-y-3">
      <Skeleton width={80} height={18} />
      <div className="w-full flex flex-col">
        <Skeleton width="100%" height={14} />
        <Skeleton width="100%" height={14} />
        <Skeleton width="100%" height={14} />
        <Skeleton width="50%" height={14} />
      </div>
    </div>
  </>
);
export default MainOrgInfoSkeleton;
