import { FC } from 'react';

const InviteCrewIcon: FC = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" id="invite-crew-icon">
      <mask id="mask0_7338_64887" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="15" height="16">
        <rect y="0.5" width="15" height="15" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7338_64887)">
        <path
          d="M0 13V11.25C0 10.8958 0.09125 10.5702 0.27375 10.2731C0.455833 9.97646 0.697917 9.75 1 9.59375C1.64583 9.27083 2.30208 9.02854 2.96875 8.86688C3.63542 8.70563 4.3125 8.625 5 8.625C5.6875 8.625 6.36458 8.70563 7.03125 8.86688C7.69792 9.02854 8.35417 9.27083 9 9.59375C9.30208 9.75 9.54417 9.97646 9.72625 10.2731C9.90875 10.5702 10 10.8958 10 11.25V13H0ZM5 8C4.3125 8 3.72396 7.75521 3.23438 7.26562C2.74479 6.77604 2.5 6.1875 2.5 5.5C2.5 4.8125 2.74479 4.22396 3.23438 3.73438C3.72396 3.24479 4.3125 3 5 3C5.6875 3 6.27604 3.24479 6.76562 3.73438C7.25521 4.22396 7.5 4.8125 7.5 5.5C7.5 6.1875 7.25521 6.77604 6.76562 7.26562C6.27604 7.75521 5.6875 8 5 8ZM7.8125 7.96875C8.11458 7.63542 8.34646 7.25521 8.50813 6.82812C8.66937 6.40104 8.75 5.95833 8.75 5.5C8.75 5.04167 8.66937 4.59896 8.50813 4.17188C8.34646 3.74479 8.11458 3.36458 7.8125 3.03125C8.4375 3.11458 8.95833 3.39062 9.375 3.85938C9.79167 4.32812 10 4.875 10 5.5C10 6.125 9.79167 6.67188 9.375 7.14062C8.95833 7.60938 8.4375 7.88542 7.8125 7.96875ZM11.25 13V11.125C11.25 10.75 11.1667 10.3933 11 10.055C10.8333 9.71625 10.6146 9.41667 10.3438 9.15625C10.875 9.34375 11.3673 9.58583 11.8206 9.8825C12.2735 10.1796 12.5 10.5938 12.5 11.125V13H11.25ZM12.5 8.625V7.375H11.25V6.125H12.5V4.875H13.75V6.125H15V7.375H13.75V8.625H12.5Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default InviteCrewIcon;
