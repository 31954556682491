// packages
import { useRecoilValue } from 'recoil';
import LineSvg from 'icons/LineSvg';
import Skeleton from 'react-loading-skeleton';
// components
import EssentialCertificatesSkeleton from 'system/skeletons/hireProfile/components/VacancySkeleton/components/EssentialCertificatesSkeleton';
import MainInformationSkeleton from 'system/skeletons/hireProfile/components/VacancySkeleton/components/MainInformationSkeleton';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';

const JobOpeningSlideOverSkeleton = () => {
  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);

  return (
    <div className="flex flex-col gap-y-8 pb-10 sm:px-4">
      <div className="flex gap-x-5 mb-2">
        <Skeleton width={80} height={80} borderRadius={6} />
        <div className="flex flex-col justify-between w-full">
          <Skeleton width="100%" height={24} containerClassName="w-1/2" />
          <div className="hidden sm:flex gap-x-5">
            {getCurrentOrganisation.isSelected ? <Skeleton width={90} height={40} borderRadius={6} /> : null}
            <Skeleton width={170} height={40} borderRadius={6} />
          </div>
        </div>
      </div>
      <MainInformationSkeleton />
      <LineSvg classes="w-[calc(100%_+_32px)] relative -left-4" />
      <div className="flex flex-col">
        <Skeleton width={270} height={24} className="mb-2" />
        <Skeleton width="100%" height={14} />
        <Skeleton width="100%" height={14} />
        <Skeleton width="100%" height={14} />
        <Skeleton width="35%" height={14} />
      </div>
      <LineSvg classes="w-[calc(100%_+_32px)] relative -left-4" />
      <div className="flex flex-col">
        <Skeleton width={270} height={24} className="mb-2" />
        <Skeleton width="100%" height={14} />
        <Skeleton width="40%" height={14} />
        <Skeleton width="100%" height={14} />
        <Skeleton width="40%" height={14} />
        <Skeleton width="100%" height={14} />
        <Skeleton width="40%" height={14} />
        <Skeleton width="100%" height={14} />
        <Skeleton width="40%" height={14} />
      </div>
      <LineSvg classes="w-[calc(100%_+_32px)] relative -left-4" />
      <EssentialCertificatesSkeleton />
    </div>
  );
};

export default JobOpeningSlideOverSkeleton;
