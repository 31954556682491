import Skeleton from 'react-loading-skeleton';
import LineSvg from 'icons/LineSvg';

const ListOfJobOpeningsItemSkeleton = () => {
  return (
    <div className="flex flex-col sm:flex-row sm:items-center px-4 py-4 sm:py-5 border border-specialGray-01 rounded-2xl w-full">
      <div className="flex w-full sm:w-4/5 gap-x-4">
        <Skeleton width={56} height={56} borderRadius={6} />
        <div className="flex flex-col justify-between sm:justify-start w-[calc(100%_-_72px)]">
          <div className="flex items-center gap-x-4">
            <Skeleton width={250} height={18} />
            <div className="hidden sm:flex justify-between w-full">
              <div className="flex space-x-2 text-specialGray-05 text-sm">
                <Skeleton width={200} height={28} borderRadius={50} />
                <Skeleton width={45} height={28} borderRadius={50} />
              </div>
            </div>
          </div>
          <div className="flex w-full flex-wrap">
            <div className="flex text-center gap-x-1.5 mr-[18px]">
              <Skeleton width={50} height={12} containerClassName="mt-[1px] hidden sm:inline" />
              <Skeleton width={14} height={14} containerClassName="inline sm:hidden" />
              <Skeleton width={130} height={14} />
            </div>
            <div className="hidden sm:flex items-center space-x-1.5 text-specialGray-05 mr-[18px]">
              <Skeleton width={76} height={12} containerClassName="mt-[1px]" />
              <Skeleton width={35} height={14} />
              &nbsp;&#45;
              <Skeleton width={35} height={14} className="mr-2" />
              <Skeleton width={30} height={14} />
              &nbsp;&#8725;
              <Skeleton width={60} height={14} />
            </div>
            <div className="hidden sm:flex text-center space-x-1.5 mr-[18px]">
              <Skeleton width={80} height={12} containerClassName="mt-[1px]" />
              <Skeleton width={130} height={14} />
            </div>
            <div className="hidden sm:flex text-center space-x-1.5">
              <Skeleton width={51} height={12} containerClassName="mt-[1px]" />
              <Skeleton width={105} height={14} />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden sm:flex w-1/5">
        <div className="flex w-1/2">
          <Skeleton width="100%" height={14} containerClassName="w-full" />
        </div>
        <div className="flex w-1/2 justify-end">
          <Skeleton width={90} height={14} />
        </div>
      </div>
      <LineSvg classes="sm:hidden mt-3 mb-2 w-[calc(100%_+_32px)] relative -left-4" />
      <div className="flex sm:hidden justify-between gap-x-1.5 text-specialGray-05">
        <div className="flex">
          <Skeleton width={30} height={14} />
          &nbsp;&#8725;
          <Skeleton width={60} height={14} />
        </div>
        <div className="flex">
          <Skeleton width={35} height={14} />
          &nbsp;&#45;
          <Skeleton width={35} height={14} className="mr-2" />
        </div>
      </div>
    </div>
  );
};

export default ListOfJobOpeningsItemSkeleton;
