import translations from 'translations';

const ErrorBoundaryMessage = ({ error, errorId }: { error: Error | null; errorId: string }) => {
  return (
    <div className="flex flex-col w-full items-center justify-center py-20 text-darkBlue">
      <div className="w-full flex items-center flex-col mb-4">
        <h1 className="flex items-center lg:text-3xl text-lg font-bold mb-5">{translations.error_boundary_text}</h1>
      </div>
      <div className="w-1/2 mx-auto rounded-md bg-red-100 px-4 py-2">
        <span>Error id: {errorId}</span>
        <details className="whitespace-pre-wrap">
          {error && error.toString()}
          <br />
          {process.env.NODE_ENV === 'production' && error && error.stack}
        </details>
      </div>
    </div>
  );
};

export default ErrorBoundaryMessage;
