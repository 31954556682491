/**
 * @generated SignedSource<<230f34b102d5c57da72737279a6ebb50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ServiceFragmentsVesselKind$data = {
  readonly 'id': string;
  readonly 'name': string;
  readonly 'perma': string;
  readonly ' $fragmentType': 'ServiceFragmentsVesselKind';
};
export type ServiceFragmentsVesselKind$key = {
  readonly ' $data'?: ServiceFragmentsVesselKind$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragmentsVesselKind'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ServiceFragmentsVesselKind',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'perma',
      storageKey: null,
    },
  ],
  type: 'VesselKind',
  abstractKey: null,
};

(node as any).hash = '73672c771d67879367e24b7630c5eb21';

export default node;
