import { FC } from 'react';

const BigAppliedIcon: FC = () => {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7980_69718)">
        <path
          d="M33.8333 6.66797H7.16665C5.33331 6.66797 3.83331 8.16797 3.83331 10.0013V30.0013C3.83331 31.8346 5.33331 33.3346 7.16665 33.3346H33.8333C35.6666 33.3346 37.1666 31.8346 37.1666 30.0013V10.0013C37.1666 8.16797 35.6666 6.66797 33.8333 6.66797ZM33.1666 13.7513L22.2666 20.568C21.1833 21.2513 19.8166 21.2513 18.7333 20.568L7.83331 13.7513C7.41665 13.4846 7.16665 13.0346 7.16665 12.5513C7.16665 11.4346 8.38331 10.768 9.33331 11.3513L20.5 18.3346L31.6666 11.3513C32.6166 10.768 33.8333 11.4346 33.8333 12.5513C33.8333 13.0346 33.5833 13.4846 33.1666 13.7513Z"
          fill="#082051"
          fillOpacity="0.3"
        />
        <path
          d="M20.5 32.3084C20.5 28.3433 23.7144 25.1289 27.6795 25.1289H35.8846C39.8497 25.1289 43.0641 28.3433 43.0641 32.3084C43.0641 36.2735 39.8497 39.4879 35.8846 39.4879H27.6795C23.7144 39.4879 20.5 36.2735 20.5 32.3084Z"
          fill="#FCFCFC"
        />
        <path
          d="M30.1894 36.8303C30.1894 37.5959 29.2707 37.9702 28.7433 37.4258L23.9964 32.6789C23.6561 32.3386 23.6561 31.8112 23.9964 31.4709L28.7433 26.724C29.2707 26.1966 30.1894 26.5709 30.1894 27.3365L30.1894 30.382L35.2936 30.382C36.2294 30.382 36.995 31.1476 36.995 32.0834C36.995 33.0192 36.2294 33.7848 35.2936 33.7848L30.1894 33.7848L30.1894 36.8303Z"
          fill="#082051"
          fillOpacity="0.3"
        />
      </g>
      <defs>
        <clipPath id="clip0_7980_69718">
          <rect width="40" height="40" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BigAppliedIcon;
