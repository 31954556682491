// packages
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Disclosure } from '@headlessui/react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
// models
import { PaymentSubscriptionLabelSizes, PaymentSubscriptionName, PaymentSubscriptionType, PLATFORM_GROUP } from 'models/enums';
// context
import { useNavbarSearchContext } from 'Context/NavbarSearchContext';
// hooks
import { useAuth } from 'authentication';
import { useGetSubscriptionPlanForCurrentOrg } from 'hooks/subscription/useGetSubscriptionPlanForCurrentOrg';
// generated
import { ProfileQueriesRootQuery } from 'schemas/profile/__generated__/ProfileQueriesRootQuery.graphql';
import { ProfileFragments$data, ProfileFragments$key } from 'schemas/profile/__generated__/ProfileFragments.graphql';
// schemas
import { PROFILE_QUERY } from 'schemas/profile/ProfileQueries';
import { PROFILE_FRAGMENT } from 'schemas/profile/ProfileFragments';
// routes
import { RoutePaths } from 'app/routing';
// components
import Logo from 'icons/LogoIcon';
import { Modal } from 'system/Modal';
import { Button } from 'system/Button';
import LogoFull from 'icons/LogoFullIcon';
import { LoginForm } from 'auth/LoginForm';
import { NavbarProfile } from './NavbarProfile';
import BlueLinkButton from 'system/Buttons/BlueLinkButton';
import SearchInput from './components/SearchInput/SearchInput';
import CustomNavLink from 'system/CustomNavLink/CustomNavLink';
import PaymentSubscriptionLabel from 'app/ProfileEmployer/HireProduct/components/PaymentSubscriptionLabel';
// utils
import { envVariableTransform } from 'utils';
import { getItemFromLocalStorageByName } from 'utils/localStorageHelpers';
// types
import { NavbarProps } from './types';
import { scrollWithPaginationType } from 'recoil/ScrollWithPagination/types';
// recoil
import { assumeProfileAtom } from 'recoil/Admin/atoms/assumeProfileAtom';
import { personalAccountSelector } from 'recoil/Profile/personalAccount/selectors/personalAccountSelector';
import { emptyOrganisationAtom } from 'recoil/Organisation/getCurrentOrganization/atoms/emptyOrganisationAtom';
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';
import { jobOpeningsSearchScrollWithPaginationSelector, profileSearchScrollWithPaginationSelector } from 'recoil/ScrollWithPagination/selectors/SearchScrollWithPaginationSelector';

// Navbar renders the top Navbar with an optional profile menu. We use a preloadedQuery here to allow
// the navbar to be rendered even though the query itself might still be fetching. This gives faster
// feedback to the user with creates a better user experience.
export const Navbar = ({ openNavbarProfileByDefault, openMobileMenuByDefault }: NavbarProps) => {
  const intl = useIntl();
  const { identity } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setQueryParams } = useNavbarSearchContext();
  const subscriptionPlanForCurrentOrg = useGetSubscriptionPlanForCurrentOrg();

  const getIsPersonalAccount = useRecoilValue(personalAccountSelector);
  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);
  const getIsPersonalAccountSelected = useRecoilValue(personalAccountSelector);
  const [getIsAssumeProfile, setIsAssumeProfile] = useRecoilState(assumeProfileAtom);
  const getIsEmptyOrganisation = useRecoilValue(emptyOrganisationAtom);

  const setProfilesSearchScrollWithPagination = useSetRecoilState(profileSearchScrollWithPaginationSelector);
  const setJobOpeningsSearchScrollWithPagination = useSetRecoilState(jobOpeningsSearchScrollWithPaginationSelector);

  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const createJobPostingLink: string = `${RoutePaths.ProfileOrganizationBase}/${getCurrentOrganisation.organisationId}/${RoutePaths.JobOpeningStep1}`;

  const selfProfileQueryRef = useLazyLoadQuery<ProfileQueriesRootQuery>(PROFILE_QUERY, { id: `${identity?.profileId}`, skip: !identity?.profileId });
  const selfData = useFragment<ProfileFragments$key>(PROFILE_FRAGMENT, selfProfileQueryRef.node || null);

  const handleByNavLink = (eventName: string) => () => {
    window.analytics?.track(eventName);
    setQueryParams('');
    navigate(RoutePaths.SearchProfileBase);
  };

  const handleResetScrollWithPaginationInLocalStorage = useCallback(() => {
    const getJobOpeningsSearchScrollWithPaginationFromLocalStorage = getItemFromLocalStorageByName('jobOpeningsSearchScrollWithPagination');
    const getProfilesSearchScrollWithPaginationFromLocalStorage = getItemFromLocalStorageByName('profileSearchScrollWithPagination');

    if (getJobOpeningsSearchScrollWithPaginationFromLocalStorage || getProfilesSearchScrollWithPaginationFromLocalStorage) {
      setProfilesSearchScrollWithPagination((prevState: scrollWithPaginationType) => ({ ...prevState, scrollY: 0 }));
      setJobOpeningsSearchScrollWithPagination((prevState: scrollWithPaginationType) => ({ ...prevState, scrollY: 0 }));
    }
  }, [setJobOpeningsSearchScrollWithPagination, setProfilesSearchScrollWithPagination]);

  const handleClickByLogo = useCallback(() => {
    setQueryParams('');
  }, [setQueryParams]);

  const handleClickByRegister = () => {
    navigate(RoutePaths.AuthRegisterStepOne);
  };

  const getLinkForLogo = useMemo<string>(() => {
    if (identity?.platformGroup === PLATFORM_GROUP.HIRE && getIsEmptyOrganisation.isEmpty) {
      return RoutePaths.ProfileOrganizationBase;
    }
    if (getIsPersonalAccount.personalAccountIsSelected) {
      return RoutePaths.ProfileBase;
    }
    if (getCurrentOrganisation.isSelected) {
      return `${RoutePaths.ProfileOrganizationBase}/${getCurrentOrganisation.organisationId}`;
    }
    return '';
  }, [getCurrentOrganisation, getIsEmptyOrganisation, getIsPersonalAccount.personalAccountIsSelected, identity?.platformGroup]);

  const handleStopAssumingProfile = useCallback(() => {
    setIsAssumeProfile(false);
    navigate(RoutePaths.adminStopAssume);
  }, [navigate, setIsAssumeProfile]);

  const isRenderNavButtons = useMemo<boolean>(
    () => pathname !== RoutePaths.ProfileOrganizationBase && (!!selfData?.info || getCurrentOrganisation.isSelected),
    [getCurrentOrganisation.isSelected, pathname, selfData?.info],
  );

  return (
    <>
      <Modal title={{ id: 'login_loginCta' }} onClose={() => setShowLoginModal(false)} show={showLoginModal} tinyModal>
        <LoginForm forgotPasswordLink={RoutePaths.AuthForget} onLoginSuccess={() => setShowLoginModal(false)} />
      </Modal>

      <Disclosure as="nav" className="bg-white border-b border-b-specialGray-012" onClick={handleResetScrollWithPaginationInLocalStorage}>
        {({ open }) => (
          <>
            <div className="w-full pl-4 pr-2 px-6 lg:px-8 xl:px-16 relative flex justify-between h-[72px]">
              {selfData ? (
                <div className="flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center py-2 rounded-md text-specialGray-075 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                    <span className="sr-only">{intl.formatMessage({ id: 'navbar_sr_openMainMenu' })}</span>
                    {open || openMobileMenuByDefault ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                  </Disclosure.Button>
                </div>
              ) : null}

              <div className="flex items-center">
                <Link className="flex flex-shrink-0 items-center" to={getLinkForLogo} onClick={handleClickByLogo} state={{ isNewHire: true }}>
                  <Logo />
                  <LogoFull classes="hidden lg:block" />
                </Link>
                {(envVariableTransform(process.env.REACT_APP_FEATURE_PAYMENT_SUBSCRIPTION) || envVariableTransform(process.env.STORYBOOK_FEATURE_PAYMENT_SUBSCRIPTION)) &&
                  subscriptionPlanForCurrentOrg && (
                    <PaymentSubscriptionLabel
                      size={PaymentSubscriptionLabelSizes.SM}
                      textColor="text-white"
                      bg={`${subscriptionPlanForCurrentOrg === PaymentSubscriptionType.TRIAL ? 'bg-blue' : 'bg-[#00BF78]'}`}
                      additionalClassName="mx-2 sm:mx-3 xl:mx-6 mt-1"
                      subscriptionPlan={subscriptionPlanForCurrentOrg === PaymentSubscriptionType.TRIAL ? PaymentSubscriptionName.FREE : PaymentSubscriptionName.PRO}
                    />
                  )}
              </div>

              <>
                {isRenderNavButtons ? (
                  <div className="sm:flex w-1/3 items-center hidden px-1">
                    <SearchInput />
                  </div>
                ) : null}

                {getIsAssumeProfile ? (
                  <div className="flex items-center">
                    <Button
                      label={{ id: 'assume_profile_stop_assume_button_label' }}
                      additionalClasses="h-12 m-y-auto"
                      buttonType="red-primary"
                      onClick={handleStopAssumingProfile}
                    />
                  </div>
                ) : null}

                <div className="flex gap-x-3 lg:gap-x-6">
                  <div className="hidden sm:flex gap-x-4 lg:gap-x-12">
                    {isRenderNavButtons ? (
                      <>
                        <CustomNavLink
                          to={RoutePaths.SearchProfileBase}
                          className="inline-flex items-center px-1 pt-1"
                          onClick={handleByNavLink(getCurrentOrganisation.isSelected ? 'wui-click-hire-find-crew-button' : 'wui-click-crew-members-button')}
                        >
                          {intl.formatMessage({ id: getCurrentOrganisation.isSelected ? 'navbar_hire_find_crew_link' : 'crew_members_link_label' })}
                        </CustomNavLink>
                        {getCurrentOrganisation.isSelected ? (
                          <BlueLinkButton
                            withIcon
                            to={createJobPostingLink}
                            title={{ id: 'hire_dashboard_header_button_job_opening' }}
                            handleClick={handleByNavLink('wui-click-hire-create-job-button')}
                            state={{
                              organization: {
                                id: getCurrentOrganisation.organisationId,
                              },
                            }}
                          />
                        ) : null}
                        {getIsPersonalAccountSelected.personalAccountIsSelected ? (
                          <BlueLinkButton to={RoutePaths.JobOpenings} title={{ id: 'navbar_crew_find_job' }} handleClick={handleByNavLink('wui-click-crew-find-job-button')} />
                        ) : null}
                      </>
                    ) : null}
                  </div>

                  {selfData ? (
                    <NavbarProfile selfProfileData={selfData as ProfileFragments$data} openNavbarProfileByDefault={openNavbarProfileByDefault} />
                  ) : (
                    <div className="flex gap-x-4 items-center justify-self-end">
                      <button
                        onClick={() => setShowLoginModal(true)}
                        className="px-3.5 border border-blue-600 shadow-sm text-sm font-medium text-white hover:bg-blue-700 hover:border-blue-700 focus:outline-none rounded-[10px] bg-blue h-10"
                      >
                        {intl.formatMessage({ id: 'login_loginCta' })}
                      </button>

                      <button onClick={handleClickByRegister} className="text-blue text-sm font-medium hover:text-blue-400">
                        {intl.formatMessage({ id: 'login_sign_upCta' })}
                      </button>
                    </div>
                  )}
                </div>
              </>
            </div>

            {selfData?.info || getCurrentOrganisation.isSelected ? (
              <Disclosure.Panel className="sm:hidden" static={openMobileMenuByDefault}>
                <div className="flex pt-2 pb-4 px-4 justify-between">
                  <Link
                    to={RoutePaths.SearchProfileBase}
                    className="border-transparent text-specialGray-075 hover:border-gray-300 hover:text-darkBlue inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                    onClick={handleByNavLink('wui-click-hire-find-crew-button')}
                  >
                    {intl.formatMessage({ id: 'crew_members_link_label' })}
                  </Link>
                  {getCurrentOrganisation.isSelected ? (
                    <BlueLinkButton
                      withIcon
                      to={createJobPostingLink}
                      title={{ id: 'hire_dashboard_header_button_job_opening' }}
                      handleClick={handleByNavLink('wui-click-hire-create-job-button')}
                      state={{
                        organization: {
                          id: getCurrentOrganisation.organisationId,
                        },
                      }}
                    />
                  ) : null}
                  {getIsPersonalAccountSelected.personalAccountIsSelected ? (
                    <BlueLinkButton to={RoutePaths.JobOpenings} title={{ id: 'navbar_crew_find_job' }} handleClick={handleByNavLink('wui-click-crew-find-job-button')} />
                  ) : null}
                </div>
                <div className="flex justify-center w-full items-center sm:hidden px-4 pb-2">
                  <SearchInput />
                </div>
              </Disclosure.Panel>
            ) : null}
          </>
        )}
      </Disclosure>
    </>
  );
};
