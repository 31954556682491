import graphql from 'babel-plugin-relay/macro';

export const GET_LIST_OF_INVITED_CREW = graphql`
  query InviteQueriesGetListOfInvitedCrewQuery($vacancyId: ID!, $after: Cursor, $first: Int, $skip: Boolean!) {
    invites(vacancyId: $vacancyId, first: $first, after: $after) @skip(if: $skip) @connection(key: "ListWithInvites_invites") {
      edges {
        node {
          id
          ...InviteFragments
        }
      }
    }
  }
`;
