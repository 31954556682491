import OverviewProfileCrewEVTitleSkeleton from './OverviewProfileCrewEVTitleSkeleton';
import Skeleton from 'react-loading-skeleton';

const CertificatesCrewEvComponentSkeleton = () => {
  const contentWidth = [200, 150, 80, 300];
  return (
    <section className="flex w-full flex-col space-y-6">
      <OverviewProfileCrewEVTitleSkeleton />

      <div className="flex w-full">
        <div className="overflow-hidden border border-specialGray-012 rounded-xl w-full">
          <table className="min-w-full divide-y divide-specialGray-012">
            <thead className="bg-specialGray-004 text-specialGray-075 text-xs uppercase">
              <tr>
                <th scope="col" className="text-left w-full font-normal h-10 pl-5 pr-[46px]">
                  <Skeleton width={64} height={12} />
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-specialGray-012 bg-white text-specialGray-012 text-xs">
              {contentWidth.map((width, i) => {
                return (
                  <tr key={i}>
                    <td className="flex min-h-[64px] items-center w-full justify-start pl-5 pr-[46px]">
                      <Skeleton className="mr-3" width={25} height={14} borderRadius={50} />
                      <Skeleton width={width} height={14} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default CertificatesCrewEvComponentSkeleton;
