/**
 * @generated SignedSource<<fbb1c50eff216cf1427619a683d9941e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ServiceQueriesFormGetAllTagsQuery$variables = {
  skip: boolean;
  substr: string;
};
export type ServiceQueriesFormGetAllTagsQuery$data = {
  readonly activities?: {
    readonly edges: ReadonlyArray<{
      readonly id: string;
      readonly label: string;
      readonly perma: string | null;
      readonly timesUsed: number;
    }>;
  } | null;
};
export type ServiceQueriesFormGetAllTagsQuery = {
  response: ServiceQueriesFormGetAllTagsQuery$data;
  variables: ServiceQueriesFormGetAllTagsQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'skip',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'substr',
    },
    v2 = [
      {
        condition: 'skip',
        kind: 'Condition',
        passingValue: false,
        selections: [
          {
            alias: null,
            args: [
              {
                kind: 'Variable',
                name: 'substr',
                variableName: 'substr',
              },
            ],
            concreteType: 'ActivityList',
            kind: 'LinkedField',
            name: 'activities',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'Activity',
                kind: 'LinkedField',
                name: 'edges',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'id',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'label',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'perma',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'timesUsed',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'ServiceQueriesFormGetAllTagsQuery',
      selections: v2 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'ServiceQueriesFormGetAllTagsQuery',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '7ddca6bea18059154ad6a22fa8207524',
      id: null,
      metadata: {},
      name: 'ServiceQueriesFormGetAllTagsQuery',
      operationKind: 'query',
      text: 'query ServiceQueriesFormGetAllTagsQuery(\n  $substr: String!\n  $skip: Boolean!\n) {\n  activities(substr: $substr) @skip(if: $skip) {\n    edges {\n      id\n      label\n      perma\n      timesUsed\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '808a0d20ef19f4fd20624fb58c3bdb1c';

export default node;
