import React from 'react';
import ShipPhoto from 'icons/ShipPhoto';
import { VesselImageComponentsProps } from 'models/modelsOfComponents';

const VesselImageComponents: React.FC<VesselImageComponentsProps> = ({ src, alt }) => {
  return src ? (
    <div className="w-20 h-12 sm:w-28 sm:h-16 overflow-hidden rounded-lg flex items-center">
      <img className="w-full h-auto" src={src} alt={alt} />
    </div>
  ) : (
    <div className="bg-avatar-bg py-2 px-3 sm:py-3 sm:px-6 flex justify-center items-center rounded-lg w-20 h-12 sm:w-28 sm:h-16">
      <span className="w-[37px] h-[33px]">
        <ShipPhoto />
      </span>
    </div>
  );
};

export default VesselImageComponents;
