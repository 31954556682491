/**
 * @generated SignedSource<<f23d0b84761f847120abeb7f59a3c134>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CrewPoolKind = 'REGULAR' | '%future added value';
export type ProfileMutationsAddProfileToCrewPoolMutation$variables = {
  poolId: ReadonlyArray<string>;
  profileIDs: ReadonlyArray<string>;
};
export type ProfileMutationsAddProfileToCrewPoolMutation$data = {
  readonly addCrewPoolProfiles: {
    readonly edges: ReadonlyArray<{
      readonly CreatedAt: String;
      readonly Organization: {
        readonly id: string;
      };
      readonly UpdatedAt: String;
      readonly id: string;
      readonly kind: CrewPoolKind;
      readonly name: string;
    }>;
  };
};
export type ProfileMutationsAddProfileToCrewPoolMutation = {
  response: ProfileMutationsAddProfileToCrewPoolMutation$data;
  variables: ProfileMutationsAddProfileToCrewPoolMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'poolId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'profileIDs',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'poolId',
            variableName: 'poolId',
          },
          {
            kind: 'Variable',
            name: 'profileIDs',
            variableName: 'profileIDs',
          },
        ],
        concreteType: 'CrewPoolList',
        kind: 'LinkedField',
        name: 'addCrewPoolProfiles',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'CrewPool',
            kind: 'LinkedField',
            name: 'edges',
            plural: true,
            selections: [
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'name',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'kind',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'Organization',
                kind: 'LinkedField',
                name: 'Organization',
                plural: false,
                selections: [v1 /*: any*/],
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'CreatedAt',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'UpdatedAt',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileMutationsAddProfileToCrewPoolMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileMutationsAddProfileToCrewPoolMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: 'c052fa2041030bca4bf345d0abbf449c',
      id: null,
      metadata: {},
      name: 'ProfileMutationsAddProfileToCrewPoolMutation',
      operationKind: 'mutation',
      text: 'mutation ProfileMutationsAddProfileToCrewPoolMutation(\n  $poolId: [ID!]!\n  $profileIDs: [ID!]!\n) {\n  addCrewPoolProfiles(poolId: $poolId, profileIDs: $profileIDs) {\n    edges {\n      id\n      name\n      kind\n      Organization {\n        id\n      }\n      CreatedAt\n      UpdatedAt\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '2bad80d84916e66800a7da86ba4539a5';

export default node;
