import { useIntl } from 'react-intl';
//hooks
import { useGetProfileScore } from 'hooks/useGetProfileScore';
//components
import ProfileProgressBarCommon from './components/ProfileProgressBarCommon';

const ProfileCompletionScoreDetails = () => {
  const intl = useIntl();

  const { profileScoreSummary } = useGetProfileScore();

  return (
    <section className="flex flex-col w-full sm:max-w-[236px] lg:max-w-[400px] h-[236px] lg:h-[137px] py-[22px] px-6 shadow-[0px_8.04px_21.44px_rgba(140,145,180,0.15)] rounded-[15px] text-darkBlue">
      <div className="flex flex-col items-center lg:items-start lg:flex-row">
        <div className="flex flex-col space-y-1">
          <h4 className="uppercase text-xs font-medium">{intl.formatMessage({ id: 'rating_completion_score' })}</h4>

          {profileScoreSummary === 100 ? (
            <div className="flex w-full justify-center items-center pt-2.5 text-specialGray-075">
              <span>{intl.formatMessage({ id: 'rating_excellent_score' })}</span>
            </div>
          ) : (
            <span className="text-specialGray-075 text-sm">{intl.formatMessage({ id: 'rating_extend_profile' })}</span>
          )}
        </div>
        <ProfileProgressBarCommon additionalClasses="lg:ml-auto lg:-top-6 top-5" score={profileScoreSummary} />
      </div>
    </section>
  );
};

export default ProfileCompletionScoreDetails;
