// packages
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useIntl } from 'react-intl';
import { Form, FormikProvider, useFormik } from 'formik';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
// routing
import { RoutePaths } from 'app/routing';
// formHelpers
import Footer from 'auth/SignUpSteps/components/Footer';
import Header from 'auth/SignUpSteps/components/Header';
import { RegisterWithPasswordValues } from 'formHelpers/initialValuesOfForms';
import { RegisterStepWithPasswordValidateSchema } from 'formHelpers/validationsOfForms';
// types
import { RegisterWithPasswordData } from '../types';
// components
import KeyGreenIcon from 'icons/KeyGreenIcon';
import AlertForError from 'system/Alert/AlertForError';
import { InputGroup } from 'system/InputGroups/InputGroup';
import { CheckboxInputGroup } from 'system/CheckboxInputGroup';
// models
import { PLATFORM_GROUP } from 'models/enums';
import { SignUpStepsState } from 'models/routeLocationState';

const StepWithPassword = ({ registerType, defaultError }: { registerType: PLATFORM_GROUP; defaultError?: Error }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { state, search } = useLocation();

  const [error, setError] = useState<Error | undefined>(defaultError);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const formik = useFormik<RegisterWithPasswordData>({
    onSubmit,
    initialValues: RegisterWithPasswordValues((state as SignUpStepsState)?.password),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: RegisterStepWithPasswordValidateSchema,
  });

  async function onSubmit(d: RegisterWithPasswordData) {
    if ((state as SignUpStepsState).password && (state as SignUpStepsState).email) {
      navigate({ pathname: RoutePaths.AuthRegisterStepTree }, { state });
      return;
    }
    let err: Error | undefined;
    formik.setSubmitting(true);
    try {
      await Auth.signUp({
        username: (state as SignUpStepsState).email!,
        password: d.password,
        clientMetadata: { 'custom:platform_group': `${registerType}` },
      });

      window.analytics?.track(registerType === 'hire' ? 'wui-hire-signup-step-with-password' : 'wui-signup-step-with-password', {
        type_of_account: registerType,
      });

      err = undefined;
      navigate(
        { pathname: registerType === 'hire' ? RoutePaths.HireAuthRegisterStepTree : RoutePaths.AuthRegisterStepTree, search },
        { state: { ...state, password: d.password } },
      );
    } catch (e: unknown) {
      console.error(e);
      err = e as Error;
    }
    setError(err);
    formik.setSubmitting(false);
  }

  const handleShowPassword = (value: boolean) => () => {
    setShowPassword(value);
  };

  const getEyeIcon = () => {
    if (showPassword) {
      return <EyeSlashIcon className="w-6 h-6 text-specialGray-05 cursor-pointer" aria-hidden="true" onClick={handleShowPassword(false)} />;
    }
    return <EyeIcon className="w-6 h-6 text-specialGray-05 cursor-pointer" aria-hidden="true" onClick={handleShowPassword(true)} />;
  };

  const getInputType = () => (showPassword ? 'text' : 'password');

  return (
    <section className="flex w-full flex-col h-full sm:h-auto pt-5 pb-7">
      <Header title={{ id: 'password_title' }} description={{ id: 'password_description' }} icon={<KeyGreenIcon />} />
      <FormikProvider value={formik}>
        <Form className="flex flex-col mt-7 sm:mt-8 w-full sm:py-8 px-4 sm:border sm:border-specialGray-012 sm:px-10 rounded-[20px] space-y-[18px] h-full justify-between sm:justify-start">
          <fieldset className="space-y-[18px]">
            {error && <AlertForError heading={{ id: 'register_failedAlert' }} error={error} />}
            {error?.message === intl.formatMessage({ id: 'An account with the given email already exists.' }) && (
              <Link
                className="flex w-full relative rounded-b-md bg-red-50 text-sm underline text-red-700 font-semibold pb-4 pr-3 pl-12 bottom-6"
                state={{ ...state, password: formik.values.password }}
                to={{ pathname: registerType === 'hire' ? RoutePaths.HireAuthRegisterStepTree : RoutePaths.AuthRegisterStepTree }}
              >
                {intl.formatMessage({ id: 'register_info_existing_account_code' })}
              </Link>
            )}

            <InputGroup
              name="password"
              type={getInputType()}
              label={{ id: 'default_auth_passwordLabel' }}
              placeholderText={{ id: 'default_auth_passwordLabel' }}
              inputCustomStyle="h-12 sm:h-[34px]"
              autoFocus
            >
              {getEyeIcon()}
            </InputGroup>

            <CheckboxInputGroup
              name="privacy"
              label={{ id: 'auth_register_checkbox_privacy' }}
              onChange={() => {}}
              value={'false'}
              labelLink={{ id: 'privacy_policy' }}
              hrefLink={process.env.REACT_APP_PRIVACY_POLICY_LINK ?? 'https://crewlinker.com/privacy/'}
            />
          </fieldset>
          <Footer
            error={error}
            pathnameToPrevStep={registerType === 'hire' ? RoutePaths.HireAuthRegisterStepOne : RoutePaths.AuthRegisterStepOne}
            isDisabled={!formik.values.password || formik.isSubmitting}
          />
        </Form>
      </FormikProvider>
    </section>
  );
};

export default StepWithPassword;
