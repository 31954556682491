// packages
import { DefaultValue, GetRecoilValue, selector, SetRecoilState } from 'recoil';
// recoil
import { currentOrganisationAtom } from '../atoms/currentOrganisationAtom';
import { selfProfileIdAtom } from 'recoil/Auth/atoms/selfProfileIdAtom';
// types
import { currentOrganisationType } from '../../types';

export const currentOrganisationSelector = selector({
  key: 'currentOrganisationSelector',
  get: ({ get }: { get: GetRecoilValue }): currentOrganisationType => {
    const getCurrentOrganisation: currentOrganisationType = get(currentOrganisationAtom);
    const getSelfProfileId: string | null = get(selfProfileIdAtom);
    const currentOrganisationFromStorage: currentOrganisationType = JSON.parse(localStorage.getItem('currentOrganisation') as string);

    if (getSelfProfileId === currentOrganisationFromStorage?.profileId) {
      return currentOrganisationFromStorage;
    }

    if (getCurrentOrganisation.profileId !== getSelfProfileId) {
      localStorage.removeItem('currentOrganisation');
    }
    return getCurrentOrganisation;
  },
  set: ({ set }: { set: SetRecoilState }, newValue) => {
    set(currentOrganisationAtom, newValue as DefaultValue);
    if (!process.env.STORYBOOK_IS_STORYBOOK) {
      localStorage.setItem('currentOrganisation', JSON.stringify(newValue));
    }
  },
});
