// packages
import { PropsWithChildren } from 'react';
// models
import { VACANCY_STATUS } from 'models/enums';
import { IVacancyCertificate } from 'models/ICertificate';
// components
import MainInformation from './MainInformation';
import VacancyDescription from './VacancyDescription';
import EssentialCertificates from './EssentialCertificates';
import VacancyResponsibilities from './VacancyResponsibilities';
import JobOpeningStatus from 'system/JobOpeningStatus/JobOpeningStatus';
// types
import { VacancyFragments$data } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';

const VacancyInformationLayout = ({ children, vacancy }: PropsWithChildren<{ vacancy: VacancyFragments$data }>) => {
  return (
    <div className="flex flex-col w-full gap-y-4 sm:gap-y-8">
      <div className="flex items-center">
        {vacancy?.status && <JobOpeningStatus status={vacancy?.status as VACANCY_STATUS} />}
        <h2 className="text-specialGray-1 text-2xl font-semibold ml-3 sm:mr-6">{vacancy?.title}</h2>
        {children}
      </div>
      {!!vacancy && <MainInformation vacancy={vacancy} withDuties />}
      <VacancyDescription vacancyDescription={vacancy.description} />
      <VacancyResponsibilities vacancyResponsibilities={vacancy.responsibilities} />
      <EssentialCertificates certificates={vacancy?.certificates as Array<IVacancyCertificate>} />
    </div>
  );
};

export default VacancyInformationLayout;
