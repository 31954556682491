/**
 * @generated SignedSource<<522ca42999398d748043f9eb6563ed1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ServiceQueriesVesselKindsQuery$variables = {
  skip: boolean;
};
export type ServiceQueriesVesselKindsQuery$data = {
  readonly vesselKinds?: {
    readonly edges: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly perma: string;
    }>;
  } | null;
};
export type ServiceQueriesVesselKindsQuery = {
  response: ServiceQueriesVesselKindsQuery$data;
  variables: ServiceQueriesVesselKindsQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'skip',
      },
    ],
    v1 = [
      {
        condition: 'skip',
        kind: 'Condition',
        passingValue: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'VesselKindList',
            kind: 'LinkedField',
            name: 'vesselKinds',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'VesselKind',
                kind: 'LinkedField',
                name: 'edges',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'id',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'name',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'perma',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ServiceQueriesVesselKindsQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ServiceQueriesVesselKindsQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '66e604699ca619168a6e60f3c2205839',
      id: null,
      metadata: {},
      name: 'ServiceQueriesVesselKindsQuery',
      operationKind: 'query',
      text: 'query ServiceQueriesVesselKindsQuery(\n  $skip: Boolean!\n) {\n  vesselKinds @skip(if: $skip) {\n    edges {\n      id\n      name\n      perma\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '4027aa007c667a93c758513fb3f6e3e2';

export default node;
