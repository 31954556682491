// packages
import { FC, useCallback, useEffect, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
// system
import SelectInputGroup from 'system/SelectInputGroup';
// models
import { ENTITY_WITH_PERMA } from 'models/commonTypes';
import { SelectLocationInputGroupProps } from 'models/modelsOfComponents';
// schemas
import { GET_HIRING_AREA_LIST } from 'schemas/vacancy/VacancyQueries';
// generated
import { VacancyQueriesGetHiringAreaListQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetHiringAreaListQuery.graphql';

const SelectCountryInputGroup: FC<SelectLocationInputGroupProps> = ({ name, initialValue, label, placeholder, setSelectLocation }) => {
  const dataOfCountries = useLazyLoadQuery<VacancyQueriesGetHiringAreaListQuery>(GET_HIRING_AREA_LIST, { skip: false });
  const [countries, setCountries] = useState<ReadonlyArray<ENTITY_WITH_PERMA>>(dataOfCountries?.hiringAreas?.edges || []);
  const [selectedLocation, setSelectedLocation] = useState<ENTITY_WITH_PERMA | null>(null);

  const handleChangeLocation = useCallback(
    (value: string) => {
      if (value) {
        setCountries(() => dataOfCountries?.hiringAreas?.edges.filter(({ name }) => name.toLowerCase().includes(value.toLowerCase())) as ReadonlyArray<ENTITY_WITH_PERMA>);
      } else {
        setCountries(() => dataOfCountries.hiringAreas?.edges as ReadonlyArray<ENTITY_WITH_PERMA>);
      }
    },
    [dataOfCountries.hiringAreas],
  );

  useEffect(() => {
    if (initialValue) {
      setSelectedLocation(initialValue);
    }
  }, [initialValue]);

  const handleSetSelectedCountry = useCallback(
    (value: ENTITY_WITH_PERMA) => {
      setSelectedLocation(value);
      if (setSelectLocation) {
        setSelectLocation(value);
      }
    },
    [setSelectLocation],
  );

  return (
    <SelectInputGroup
      name={name}
      options={countries}
      label={label}
      onChangeSelect={handleChangeLocation}
      placeholder={placeholder}
      defaultSelectedElement={selectedLocation}
      setSelectedItem={handleSetSelectedCountry}
      selectedItem={selectedLocation}
      sortOptionsByAlphabetically
      autoComplete="chrome-off"
    />
  );
};

export default SelectCountryInputGroup;
