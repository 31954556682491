import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AgeProps } from 'models/modelsOfComponents';

export const Age: React.FC<AgeProps> = ({ birthday, classes }) => {
  const intl = useIntl();

  const getAge = useCallback((dateString: string) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }, []);

  return (
    <span className={classes} title={intl.formatMessage({ id: 'born_date' })}>
      {intl.formatMessage({ id: 'years_old' }, { years: getAge(birthday) })}
    </span>
  );
};
