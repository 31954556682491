import { useFragment } from 'react-relay';
import { VACANCY_DESCRIPTION_TYPE_OF_LIST } from 'models/enums';
import { INVITE_FRAGMENT } from 'schemas/Invite/InviteFragments';
import RightSideCommonItemListOfCrew from './RightSideCommonItemListOfCrew';
import { InviteFragments$key } from 'schemas/Invite/__generated__/InviteFragments.graphql';

const RightSideInviteItemListOfCrew = ({ inviteFragment }: { inviteFragment: InviteFragments$key }) => {
  const invite = useFragment<InviteFragments$key>(INVITE_FRAGMENT, inviteFragment);

  return <RightSideCommonItemListOfCrew vacancyCreateAt={`${invite.createdAt}`} profileFragment={invite.owner} typeOfList={VACANCY_DESCRIPTION_TYPE_OF_LIST.INVITED} />;
};

export default RightSideInviteItemListOfCrew;
