/**
 * @generated SignedSource<<790dbe28b7ab5e1f7a81505ff8aaf0a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ApplicationData = {
  vacancyId: string;
};
export type ApplicationMutationsCreateApplicationMutation$variables = {
  data: ApplicationData;
};
export type ApplicationMutationsCreateApplicationMutation$data = {
  readonly createApplication: {
    readonly node: {
      readonly id: string;
    } | null;
  };
};
export type ApplicationMutationsCreateApplicationMutation = {
  response: ApplicationMutationsCreateApplicationMutation$data;
  variables: ApplicationMutationsCreateApplicationMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'data',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'data',
            variableName: 'data',
          },
        ],
        concreteType: 'ApplicationEdge',
        kind: 'LinkedField',
        name: 'createApplication',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'Application',
            kind: 'LinkedField',
            name: 'node',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ApplicationMutationsCreateApplicationMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ApplicationMutationsCreateApplicationMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '0f1daa16c97d59f90a23128ecc73c73c',
      id: null,
      metadata: {},
      name: 'ApplicationMutationsCreateApplicationMutation',
      operationKind: 'mutation',
      text: 'mutation ApplicationMutationsCreateApplicationMutation(\n  $data: ApplicationData!\n) {\n  createApplication(data: $data) {\n    node {\n      id\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '171f9e9ce3fd501c85fc536622686d99';

export default node;
