// packages
import { FC, useCallback, useEffect, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { Form, FormikProvider, useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { useLazyLoadQuery, useMutation } from 'react-relay';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
// components
import { Button } from 'system/Button';
import BottomFormLayout from '../BottomFormLayout';
import AlertForError from 'system/Alert/AlertForError';
import SelectInputGroup, { OptionType } from 'system/SelectInputGroup';
import SelectLocationInputGroup from './components/SelectCountryInputGroup/SelectLocationInputGroup';
import SelectRequiredCertificatesComponent from 'system/SelectRequiredCertificates/SelectRequiredCertificatesComponent';
// models
import { IVesselKind } from 'models/IVesselKind';
import { ENTITY_WITH_PERMA } from 'models/commonTypes';
import { FormStepProps } from 'models/modelsOfComponents';
import { IJobOpeningStep2Form } from 'models/modelsOfForms';
import { typeFormVacancyState } from 'models/routeLocationState';
// routing
import { RoutePaths } from 'app/routing';
// schemas
import { UPDATE_VACANCY } from 'schemas/vacancy/VacancyMutations';
import { GET_VESSELS_KINDS } from 'schemas/services/ServiceQueries';
// generation
import { VacancyFragments$data } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { ServiceFragmentsVesselKind$data } from 'schemas/services/__generated__/ServiceFragmentsVesselKind.graphql';
import { CertificateFragments_single$data } from 'schemas/certificates/__generated__/CertificateFragments_single.graphql';
import { ServiceQueriesVesselKindsQuery } from 'schemas/services/__generated__/ServiceQueriesVesselKindsQuery.graphql';
import { VacancyMutationsUpdateVacancyMutation } from 'schemas/vacancy/__generated__/VacancyMutationsUpdateVacancyMutation.graphql';
// hooks
import { useGetVacancy } from 'hooks/useGetVacancy';
import { useHandleGoBackFormVacancy } from 'hooks/useHandleGoBackFormVacancy';
import { useUpdateCompletedFormVacancy } from 'hooks/UseUpdateCompletedFormVacancy';
// context
import { useJobOpeningFormContext } from 'Context/JobOpeningFormContext';
// formHelpers
import { HireFormInitialValuesStepTwo } from 'formHelpers/initialValuesOfForms';
import { JobOpeningStepTwoValidationSchema } from 'formHelpers/validationsOfForms';
import { JobOpeningCommitUpdateVacancy } from 'formHelpers/updaters/updatersOfJobOpening';

const FormStepTwo: FC<FormStepProps> = ({ defaultError = null, defaultVacancyId = '' }) => {
  const { state } = useLocation();
  const { handleUpdateCompleted } = useUpdateCompletedFormVacancy(RoutePaths.JobOpeningStep3);
  const { isChange, setIsChange, nextStep } = useJobOpeningFormContext();
  const { goBack } = useHandleGoBackFormVacancy(`${state?.organization?.id}/${RoutePaths.JobOpeningStep1}`);
  const [error, setError] = useState<Error | null>(defaultError);

  const [selectedType, setSelectedType] = useState<ServiceFragmentsVesselKind$data | null>(null);

  const vacancy = useGetVacancy(defaultVacancyId);
  const vesselKinds = useLazyLoadQuery<ServiceQueriesVesselKindsQuery>(GET_VESSELS_KINDS, { skip: false });

  const [types, setTypes] = useState<IVesselKind>((vesselKinds.vesselKinds?.edges as IVesselKind) || []);

  const [commitUpdateVacancy] = useMutation<VacancyMutationsUpdateVacancyMutation>(UPDATE_VACANCY);

  const formik = useFormik<IJobOpeningStep2Form>({
    onSubmit,
    validateOnBlur: false,
    enableReinitialize: !!vacancy,
    initialValues: HireFormInitialValuesStepTwo(vacancy),
    validationSchema: JobOpeningStepTwoValidationSchema,
  });
  useEffect(() => {
    if (vacancy && nextStep?.active) {
      const localVacancy = {
        essentialCertificates: vacancy.certificates?.map(item => item?.id),
        vesselKindId: vacancy.vesselKind?.id ?? '',
        hiringAreaId: vacancy.hiringArea?.id ?? '',
      };
      setIsChange(!isEqual(localVacancy, formik.values));
    } else {
      setIsChange(false);
    }
  }, [vacancy, formik.values, setIsChange, nextStep]);

  useEffect(() => {
    if (vacancy?.vesselKind) {
      setSelectedType(vacancy?.vesselKind as ServiceFragmentsVesselKind$data);
    }
  }, [vacancy?.vesselKind]);

  const handleFormError = (error: Error) => {
    setError(error);
    formik.setSubmitting(false);
  };

  function onSubmit(data: IJobOpeningStep2Form) {
    const { essentialCertificates, vesselKindId, hiringAreaId } = data;
    const variableData = {
      title: vacancy?.title,
      salaryFrom: vacancy?.salaryFrom,
      salaryTo: vacancy?.salaryTo,
      industry: vacancy?.industry,
      duration: vacancy?.duration,
      salaryPeriod: vacancy?.salaryPeriod,
      organizationId: vacancy?.organization.id,
      status: vacancy?.status,
      certificates: essentialCertificates,
      duties: !isEmpty(vacancy?.duties) ? vacancy?.duties?.map(item => item?.id) : [],
      responsibilities: !isEmpty(vacancy?.responsibilities) ? [...(vacancy?.responsibilities as ReadonlyArray<string>)] : [],
      vesselKindId: vesselKindId || null,
      hiringAreaId: hiringAreaId || null,
      startingDate: vacancy.startingDate,
    };
    commitUpdateVacancy({
      onError: handleFormError,
      variables: {
        id: (state as typeFormVacancyState)?.organization?.vacancyId as string,
        data: variableData,
      },
      onCompleted: () => {
        handleUpdateCompleted();
        formik.setSubmitting(false);
      },
      updater: JobOpeningCommitUpdateVacancy,
    });
  }

  const handleChangeType = useCallback(
    (value: string) => {
      if (value) {
        setTypes(() => vesselKinds.vesselKinds?.edges.filter(({ name }: OptionType) => name?.toLowerCase().includes(value.toLowerCase())) as IVesselKind);
      } else {
        setTypes(() => vesselKinds.vesselKinds?.edges as IVesselKind);
      }
    },
    [vesselKinds],
  );

  return (
    <FormikProvider value={formik}>
      <Form className="rounded-xl bg-white shadow mx-auto">
        <div className="flex flex-col gap-y-8 px-20 py-8">
          {error && <AlertForError heading={{ id: 'hire_form_failedSubmit' }} error={error} />}
          <SelectRequiredCertificatesComponent
            name="essentialCertificates"
            certificates={(vacancy as VacancyFragments$data)?.certificates as ReadonlyArray<CertificateFragments_single$data>}
          />
          <div className="-mb-2">
            <SelectLocationInputGroup
              name="hiringAreaId"
              label={{ id: 'hire_location_label' }}
              placeholder={{ id: 'hire_location_placeholder' }}
              initialValue={vacancy?.hiringArea as ENTITY_WITH_PERMA}
            />
          </div>

          <SelectInputGroup
            containerMargin="mb-0"
            name="vesselKindId"
            options={[...types].sort((a, b) => a.name.localeCompare(b.name))}
            label={{ id: 'hire_vessel_type_label' }}
            onChangeSelect={handleChangeType}
            placeholder={{ id: 'hire_form_step_2_field_placeholder' }}
            defaultSelectedElement={selectedType}
            setSelectedItem={setSelectedType}
            selectedItem={selectedType}
            autoComplete="off"
          />
        </div>
        <BottomFormLayout>
          <>
            <Button
              type="button"
              buttonType="white-primary"
              onClick={goBack}
              label={{ id: 'back' }}
              prefixIcon={ChevronLeftIcon}
              additionalClasses="h-10"
              disabled={formik.isSubmitting}
            />
            <Button buttonType="primary" type="submit" label={{ id: isChange ? 'save' : 'next_step' }} disabled={formik.isSubmitting} />
          </>
        </BottomFormLayout>
      </Form>
    </FormikProvider>
  );
};

export default FormStepTwo;
