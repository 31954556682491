// packages
import { Suspense } from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useNavigate, useParams } from 'react-router-dom';
import { useFragment, useLazyLoadQuery } from 'react-relay';
// routing
import { RoutePaths, RoutesPathNames } from 'app/routing';
// generated
import { InviteFragments$key } from 'schemas/Invite/__generated__/InviteFragments.graphql';
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { VacancyQueriesGetVacancyQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetVacancyQuery.graphql';
import { InviteQueriesGetListOfInvitedCrewQuery } from 'schemas/Invite/__generated__/InviteQueriesGetListOfInvitedCrewQuery.graphql';
// schemas
import { GET_VACANCY } from 'schemas/vacancy/VacancyQueries';
import { VACANCY_FRAGMENT } from 'schemas/vacancy/VacancyFragments';
import { GET_LIST_OF_INVITED_CREW } from 'schemas/Invite/InviteQueries';
// components
import { Button } from 'system/Button';
import SpinnerIcon from 'icons/SpinnerIcon';
import Breadcrumbs from 'system/Breadcrumbs/Breadcrumbs';
import ItemListOfCrew from '../ItemListOfCrew/ItemListOfCrew';
//models
import { VACANCY_STATUS } from 'models/enums';

const ListOfInvitedCrew = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { jobDescriptionEditId, orgId } = useParams();
  const invitedCrews = useLazyLoadQuery<InviteQueriesGetListOfInvitedCrewQuery>(
    GET_LIST_OF_INVITED_CREW,
    { vacancyId: jobDescriptionEditId!, skip: !jobDescriptionEditId },
    { fetchPolicy: 'store-and-network' },
  );
  const vacancyFragment = useLazyLoadQuery<VacancyQueriesGetVacancyQuery>(GET_VACANCY, { id: jobDescriptionEditId!, skip: !jobDescriptionEditId });
  const vacancyData = useFragment<VacancyFragments$key>(VACANCY_FRAGMENT, vacancyFragment.node as VacancyFragments$key);

  const handleInviteMoreCrew = () => {
    navigate(`${RoutePaths.ProfileOrganizationBase}/${orgId}/${RoutesPathNames.job}/${RoutesPathNames.edit}/${jobDescriptionEditId}/${RoutePaths.JobInviteCrew}`);
  };

  return (
    <div className="flex flex-col">
      <Breadcrumbs />
      <div className="flex justify-between mt-6 mb-1">
        <div className="flex space-x-3 items-center">
          <h2 className="text-2xl text-darkBlue font-medium">{intl.formatMessage({ id: 'hire_job_description_invited_crew_title' })}</h2>
          <span className="text-specialGray-075 text-sm">
            {intl.formatMessage({ id: 'hire_job_description_amount_users' }, { amount: invitedCrews.invites?.edges?.length as number })}
          </span>
        </div>
        <Button
          type="button"
          buttonType="primary"
          label={{ id: 'hire_job_description_right_side_button_label_invite_users' }}
          prefixIcon={PlusIcon}
          disabled={vacancyData?.status !== VACANCY_STATUS.OPEN}
          onClick={handleInviteMoreCrew}
        />
      </div>
      <div className="flex flex-col mt-8 space-y-4">
        {!isEmpty(invitedCrews.invites?.edges) ? (
          invitedCrews.invites?.edges?.map(invite => (
            <Suspense key={invite?.node?.id} fallback={<SpinnerIcon />}>
              <ItemListOfCrew key={invite?.node?.id} inviteFragment={invite?.node as InviteFragments$key} vacancyFragment={vacancyFragment.node as VacancyFragments$key} />
            </Suspense>
          ))
        ) : (
          <div className="mx-auto">{intl.formatMessage({ id: 'review_applications_invited_crews_empty_page' })}</div>
        )}
      </div>
    </div>
  );
};

export default ListOfInvitedCrew;
