// packages
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
// components
import ListOfEmployersItemSkeleton from './components/ListOfEmployersItemSkeleton';

const ListOfEmployersSkeleton = () => {
  const [emptyList] = useState<Array<string>>(new Array(6).fill(uuidv4()).map(() => uuidv4()));
  return (
    <div className="flex flex-col space-y-6">
      {emptyList.map(item => (
        <ListOfEmployersItemSkeleton key={item} />
      ))}
    </div>
  );
};

export default ListOfEmployersSkeleton;
