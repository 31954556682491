import { Modal } from 'system/Modal';
//hooks
import { useToggleShowWindow } from 'hooks/useToggleShowModal';
//components
import CustomButton from 'system/Buttons/CustomButton';
import FileImageComponentEvItem from './FileImageComponentEvItem';
//types
import { FileImageComponentEvProps } from 'app/ProfileCrew/type/ProfileCrewTypes';

const FileImageComponentEv = ({ smallImages, fullSizeImages, title }: FileImageComponentEvProps) => {
  const { showWindow, toggleShowWindow } = useToggleShowWindow();
  const titleUrl = smallImages[0];

  return (
    <div className="flex w-8 h-8 border border-specialGray-012 rounded-md justify-center items-center overflow-hidden">
      <Modal title={title} onClose={toggleShowWindow(false)} show={showWindow}>
        <div className="flex space-x-2">
          {smallImages.map((url, index) => {
            const findLargeUrl = fullSizeImages[index];
            return <FileImageComponentEvItem key={url} smallImgUrl={url} largeImgUrl={findLargeUrl} />;
          })}
        </div>
      </Modal>
      <CustomButton onClick={toggleShowWindow(true)} className="flex justify-center items-center h-full object-cover">
        <img className="h-full w-full" src={titleUrl} alt="img" />
      </CustomButton>
    </div>
  );
};

export default FileImageComponentEv;
