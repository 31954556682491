import Skeleton from 'react-loading-skeleton';
import SeaServiceCrewEvItemSkeleton from './SeaServiceCrewEVItemSkeleton';
import OverviewProfileCrewEVTitleSkeleton from './OverviewProfileCrewEVTitleSkeleton';
import SeaServiceEmployerViewWrapperSkeleton from './SeaServiceEmployerViewWrapperSkeleton';

const SeaServiceCrewEvComponentsSkeleton = () => {
  const contentWidth = [88, 881, 882];
  return (
    <section className="flex w-full flex-col space-y-6">
      <OverviewProfileCrewEVTitleSkeleton>
        <Skeleton className="ml-6" width={98} height={14} />
      </OverviewProfileCrewEVTitleSkeleton>
      {contentWidth.map((item, i) => {
        return (
          <SeaServiceEmployerViewWrapperSkeleton key={i + item}>
            <div className="flex w-full mt-4 flex-wrap items-center">
              <SeaServiceCrewEvItemSkeleton descriptionWidth={80} titleWidth={50}>
                <Skeleton className="mr-1.5" width={24} height={12} borderRadius={8} />
              </SeaServiceCrewEvItemSkeleton>

              <SeaServiceCrewEvItemSkeleton descriptionWidth={50} titleWidth={84} />
              <SeaServiceCrewEvItemSkeleton descriptionWidth={6} titleWidth={51} />
              <SeaServiceCrewEvItemSkeleton descriptionWidth={20} titleWidth={56} />
              <SeaServiceCrewEvItemSkeleton descriptionWidth={26} titleWidth={43} />
              <SeaServiceCrewEvItemSkeleton descriptionWidth={35} titleWidth={15} />
              <SeaServiceCrewEvItemSkeleton descriptionWidth={72} titleWidth={68} />
            </div>
          </SeaServiceEmployerViewWrapperSkeleton>
        );
      })}
    </section>
  );
};

export default SeaServiceCrewEvComponentsSkeleton;
