// routes
import { RoutesPathNames } from 'app/routing';
// components
import PrivateRoutes from 'app/PrivateRoutes/PrivateRoutes';
import EmployersComponent from 'app/EmployersComponent/EmployersComponent';

export const SearchEmployersRoute = {
  path: RoutesPathNames.employers,
  element: (
    <PrivateRoutes>
      <EmployersComponent />
    </PrivateRoutes>
  ),
};
