/**
 * @generated SignedSource<<cb6b50d1bad2168ebcf39a964bb3c336>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type VacancyQueriesGetListOfVacanciesByIdsQuery$variables = {
  ids: ReadonlyArray<string>;
};
export type VacancyQueriesGetListOfVacanciesByIdsQuery$data = {
  readonly nodes: ReadonlyArray<{
    readonly 'id': string;
    readonly ' $fragmentSpreads': FragmentRefs<'VacancyFragmentsForSearchFragment'>;
  } | null>;
};
export type VacancyQueriesGetListOfVacanciesByIdsQuery = {
  response: VacancyQueriesGetListOfVacanciesByIdsQuery$data;
  variables: VacancyQueriesGetListOfVacanciesByIdsQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'ids',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'ids',
        variableName: 'ids',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'perma',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v5 = [v2 /*: any*/, v4 /*: any*/];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'VacancyQueriesGetListOfVacanciesByIdsQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'nodes',
          plural: true,
          selections: [
            v2 /*: any*/,
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'VacancyFragmentsForSearchFragment',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'VacancyQueriesGetListOfVacanciesByIdsQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'nodes',
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: '__typename',
              storageKey: null,
            },
            v2 /*: any*/,
            {
              kind: 'InlineFragment',
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'createdAt',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'title',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'status',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Organization',
                  kind: 'LinkedField',
                  name: 'organization',
                  plural: false,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'avatarUrl',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'salaryPeriod',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'salaryFrom',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'salaryTo',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'description',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'HiringArea',
                  kind: 'LinkedField',
                  name: 'hiringArea',
                  plural: false,
                  selections: [v2 /*: any*/, v3 /*: any*/, v4 /*: any*/],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Currency',
                  kind: 'LinkedField',
                  name: 'currency',
                  plural: false,
                  selections: [v2 /*: any*/, v4 /*: any*/, v3 /*: any*/],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Duty',
                  kind: 'LinkedField',
                  name: 'duties',
                  plural: true,
                  selections: v5 /*: any*/,
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'VesselKind',
                  kind: 'LinkedField',
                  name: 'vesselKind',
                  plural: false,
                  selections: v5 /*: any*/,
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'startingDate',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'duration',
                  storageKey: null,
                },
              ],
              type: 'Vacancy',
              abstractKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: 'f49ec3dd5e016a78dba148ba7c926dd2',
      id: null,
      metadata: {},
      name: 'VacancyQueriesGetListOfVacanciesByIdsQuery',
      operationKind: 'query',
      text: 'query VacancyQueriesGetListOfVacanciesByIdsQuery(\n  $ids: [ID!]!\n) {\n  nodes(ids: $ids) {\n    __typename\n    id\n    ...VacancyFragmentsForSearchFragment\n  }\n}\n\nfragment VacancyFragmentsForSearchFragment on Vacancy {\n  id\n  createdAt\n  title\n  status\n  organization {\n    id\n    avatarUrl\n  }\n  salaryPeriod\n  salaryFrom\n  salaryTo\n  description\n  hiringArea {\n    id\n    perma\n    name\n  }\n  currency {\n    id\n    name\n    perma\n  }\n  duties {\n    id\n    name\n  }\n  vesselKind {\n    id\n    name\n  }\n  startingDate\n  duration\n}\n',
    },
  };
})();

(node as any).hash = 'fe9494e8ac086429c9cf71f7333fd012';

export default node;
