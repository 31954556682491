/**
 * @generated SignedSource<<15f641664382f8ed7d33f13be82ed253>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type EducationData = {
  end?: String | null;
  kindId: string;
  name: string;
  ownerId?: string | null;
  start: String;
};
export type EducationMutationsFormUpdateMutation$variables = {
  data: EducationData;
  sid: string;
};
export type EducationMutationsFormUpdateMutation$data = {
  readonly updateEducation: {
    readonly ' $fragmentSpreads': FragmentRefs<'EducationFragments'>;
  };
};
export type EducationMutationsFormUpdateMutation = {
  response: EducationMutationsFormUpdateMutation$data;
  variables: EducationMutationsFormUpdateMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'data',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'sid',
    },
    v2 = [
      {
        kind: 'Variable',
        name: 'data',
        variableName: 'data',
      },
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'sid',
      },
    ],
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'EducationMutationsFormUpdateMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'Education',
          kind: 'LinkedField',
          name: 'updateEducation',
          plural: false,
          selections: [
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'EducationFragments',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'EducationMutationsFormUpdateMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'Education',
          kind: 'LinkedField',
          name: 'updateEducation',
          plural: false,
          selections: [
            v3 /*: any*/,
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'start',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'end',
              storageKey: null,
            },
            v4 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'EducationKind',
              kind: 'LinkedField',
              name: 'kind',
              plural: false,
              selections: [
                v3 /*: any*/,
                v4 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'perma',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '1d8fcf122e369ac2d7e9b9e4529c311e',
      id: null,
      metadata: {},
      name: 'EducationMutationsFormUpdateMutation',
      operationKind: 'mutation',
      text: 'mutation EducationMutationsFormUpdateMutation(\n  $sid: ID!\n  $data: EducationData!\n) {\n  updateEducation(id: $sid, data: $data) {\n    ...EducationFragments\n    id\n  }\n}\n\nfragment EducationFragments on Education {\n  id\n  start\n  end\n  name\n  kind {\n    id\n    name\n    perma\n  }\n}\n',
    },
  };
})();

(node as any).hash = '9a6b28e24fa401b28178f6a69c5bc050';

export default node;
