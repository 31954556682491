import { FC } from 'react';
import { useIntl } from 'react-intl';
// models
import { LabelComponentProps } from 'models/modelsOfComponents';
// components
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';

const LabelComponent: FC<LabelComponentProps> = ({ id, label, optional, helpText, classes, classesPopover, popoverPosition = '', popoverArrowPosition = '' }) => {
  const intl = useIntl();
  return (
    <label htmlFor={id} className={classes ? classes : 'block text-xs sm:text-sm font-medium text-specialGray-1'}>
      {intl.formatMessage(label)}
      {optional && <span className="ml-1 text-specialGray-075 text-xs font-medium">{intl.formatMessage({ id: 'optional' })}</span>}
      {helpText && (
        <PopoverHelper
          position={popoverPosition}
          positionArrow={popoverArrowPosition}
          defaultText={intl.formatMessage(helpText)}
          symbol="?"
          classes={classesPopover ?? 'ml-1 '}
          darkTheme
          widthContainer="w-60 w-[300px]"
        />
      )}
    </label>
  );
};

export default LabelComponent;
