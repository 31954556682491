/**
 * @generated SignedSource<<80e76ee033592e67c43cc0b95afba522>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NotificationPreferenceData = {
  jobOpening?: boolean | null;
  ownerId: string;
  tipsToImprove?: boolean | null;
};
export type ProfileMutationsUpdateNotificationPreferenceMutation$variables = {
  data: NotificationPreferenceData;
  id: string;
};
export type ProfileMutationsUpdateNotificationPreferenceMutation$data = {
  readonly updateNotificationPreference: {
    readonly id: string;
    readonly jobOpening: boolean;
    readonly tipsToImprove: boolean;
  };
};
export type ProfileMutationsUpdateNotificationPreferenceMutation = {
  response: ProfileMutationsUpdateNotificationPreferenceMutation$data;
  variables: ProfileMutationsUpdateNotificationPreferenceMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'data',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'data',
            variableName: 'data',
          },
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        concreteType: 'NotificationPreference',
        kind: 'LinkedField',
        name: 'updateNotificationPreference',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'jobOpening',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'tipsToImprove',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileMutationsUpdateNotificationPreferenceMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'ProfileMutationsUpdateNotificationPreferenceMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: 'a70da042e24c5f7d1cd37964f879b730',
      id: null,
      metadata: {},
      name: 'ProfileMutationsUpdateNotificationPreferenceMutation',
      operationKind: 'mutation',
      text: 'mutation ProfileMutationsUpdateNotificationPreferenceMutation(\n  $id: ID!\n  $data: NotificationPreferenceData!\n) {\n  updateNotificationPreference(id: $id, data: $data) {\n    id\n    jobOpening\n    tipsToImprove\n  }\n}\n',
    },
  };
})();

(node as any).hash = '99137f6f490198941cb1f1b4a3fcd5a9';

export default node;
