import { FC } from 'react';

const FilterIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_7153_64000" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
        <rect x="0.757812" y="0.0566406" width="24.1416" height="24.1416" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_7153_64000)">
        <path
          d="M10.8167 18.1626V16.1508H14.8403V18.1626H10.8167ZM3.77539 8.10359V6.0918H21.8816V8.10359H3.77539ZM6.79308 13.1331V11.1213H18.8639V13.1331H6.79308Z"
          fill="#05173D"
          fillOpacity="0.6"
        />
      </g>
    </svg>
  );
};

export default FilterIcon;
