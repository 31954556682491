// packages
import { useLocation, useNavigate } from 'react-router-dom';
// routing
import { RoutePaths } from 'app/routing';
// context
import { useJobOpeningFormContext } from 'Context/JobOpeningFormContext';
//models
import { typeFormVacancyState } from 'models/routeLocationState';

export const useUpdateCompletedFormVacancy = (nextPathname: string) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setIsChange } = useJobOpeningFormContext();

  const handleUpdateCompleted = () => {
    navigate(`${RoutePaths.ProfileOrganizationBase}/${(state as typeFormVacancyState).organization?.id}/${nextPathname}`, {
      state,
    });

    setIsChange(false);
  };

  return {
    handleUpdateCompleted,
  };
};
