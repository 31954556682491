/**
 * @generated SignedSource<<4dcfcf8fd4203a84ef08a60f821b46b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InviteData = {
  ownerId: string;
  vacancyId: string;
};
export type InviteMutationsCreateInviteMutation$variables = {
  data: InviteData;
};
export type InviteMutationsCreateInviteMutation$data = {
  readonly createInvite: {
    readonly node: {
      readonly id: string;
      readonly owner: {
        readonly id: string;
      };
    } | null;
  };
};
export type InviteMutationsCreateInviteMutation = {
  response: InviteMutationsCreateInviteMutation$data;
  variables: InviteMutationsCreateInviteMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'data',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'data',
            variableName: 'data',
          },
        ],
        concreteType: 'InviteEdge',
        kind: 'LinkedField',
        name: 'createInvite',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'Invite',
            kind: 'LinkedField',
            name: 'node',
            plural: false,
            selections: [
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                concreteType: 'Profile',
                kind: 'LinkedField',
                name: 'owner',
                plural: false,
                selections: [v1 /*: any*/],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'InviteMutationsCreateInviteMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'InviteMutationsCreateInviteMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: 'e767c63e82fc0313e0bbbd612274353a',
      id: null,
      metadata: {},
      name: 'InviteMutationsCreateInviteMutation',
      operationKind: 'mutation',
      text: 'mutation InviteMutationsCreateInviteMutation(\n  $data: InviteData!\n) {\n  createInvite(data: $data) {\n    node {\n      id\n      owner {\n        id\n      }\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '53968452218e38cc7df4e6b09069c591';

export default node;
