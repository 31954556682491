/**
 * @generated SignedSource<<0ab69001d5614f17480a4a371fa5f7f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VacancyMutationsDeleteVacancyMutation$variables = {
  id: string;
};
export type VacancyMutationsDeleteVacancyMutation$data = {
  readonly deleteVacancy: {
    readonly id: string;
  };
};
export type VacancyMutationsDeleteVacancyMutation = {
  response: VacancyMutationsDeleteVacancyMutation$data;
  variables: VacancyMutationsDeleteVacancyMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        concreteType: 'Vacancy',
        kind: 'LinkedField',
        name: 'deleteVacancy',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'VacancyMutationsDeleteVacancyMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'VacancyMutationsDeleteVacancyMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'd1eb40bd1b00ef14cb29cb0b2da0db7b',
      id: null,
      metadata: {},
      name: 'VacancyMutationsDeleteVacancyMutation',
      operationKind: 'mutation',
      text: 'mutation VacancyMutationsDeleteVacancyMutation(\n  $id: ID!\n) {\n  deleteVacancy(id: $id) {\n    id\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'efab8484e46664fe26b557915d405a5a';

export default node;
