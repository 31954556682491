const JobOpeningIcon = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" id="job-opening-icon">
      <g clipPath="url(#clip0_7323_64720)">
        <path
          d="M9.11875 2.11875C8.88125 1.88125 8.5625 1.75 8.23125 1.75H3.75C3.0625 1.75 2.5 2.3125 2.5 3V13C2.5 13.6875 3.05625 14.25 3.74375 14.25H11.25C11.9375 14.25 12.5 13.6875 12.5 13V6.01875C12.5 5.6875 12.3687 5.36875 12.1313 5.1375L9.11875 2.11875ZM9.375 11.75H5.625C5.28125 11.75 5 11.4688 5 11.125C5 10.7813 5.28125 10.5 5.625 10.5H9.375C9.71875 10.5 10 10.7813 10 11.125C10 11.4688 9.71875 11.75 9.375 11.75ZM9.375 9.25H5.625C5.28125 9.25 5 8.96875 5 8.625C5 8.28125 5.28125 8 5.625 8H9.375C9.71875 8 10 8.28125 10 8.625C10 8.96875 9.71875 9.25 9.375 9.25ZM8.125 5.5V2.6875L11.5625 6.125H8.75C8.40625 6.125 8.125 5.84375 8.125 5.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7323_64720">
          <rect width="15" height="15" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default JobOpeningIcon;
