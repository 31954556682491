// packages
import { useCallback, useMemo, useState } from 'react';
import isChromatic from 'chromatic';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useFragment } from 'react-relay';
import { differenceInHours, format } from 'date-fns';
//mocks
import { MOCK_INDUSTRY_LIST, tableCellWidth } from 'mocks/mockData';
// models
import { VACANCY_STATUS } from 'models/enums';
// generated
import { VacancyFragmentsForDashboardFragment$key } from 'schemas/vacancy/__generated__/VacancyFragmentsForDashboardFragment.graphql';
// schemas
import { VACANCY_FRAGMENT_FOR_DASHBOARD } from 'schemas/vacancy/VacancyFragments';
// components
import { Button } from 'system/Button';
import ControlButtons from './ControlButtons';
import CombinedShapeIcon from 'icons/CombinedShapeIcon';
import TableCellWithPopover from './TableCellWithPopover';
import AppliedCrewPortraits from './AppliedCrewPortraits';
import JobOpeningStatus from 'system/JobOpeningStatus/JobOpeningStatus';
import LittlePopoverWrapper from 'system/LittlePopoverWrapper/LittlePopoverWrapper';
// types
import { DashboardTableItemProps } from '../../../types';
// routing
import { RoutesPathNames } from 'app/routing';

const DashboardDesktopTableItem = ({ lastIndex, item, serialNumber }: DashboardTableItemProps) => {
  const intl = useIntl();
  const { number, status, positionTitle, position, vesselType, salaryRange, industry, applied, postingDate, emptyTitle } = tableCellWidth;

  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);

  const vacancy = useFragment<VacancyFragmentsForDashboardFragment$key>(VACANCY_FRAGMENT_FOR_DASHBOARD, item || null);

  const mappingVacancyDuties = useMemo<string>(() => vacancy?.duties?.map(item => item.name)?.join(', ') || '', [vacancy?.duties]);

  const getBackgroundForItem = useCallback(
    (date: string) => {
      if (differenceInHours(new Date(), new Date(date)) > 24 && vacancy?.status === VACANCY_STATUS.CLOSED) {
        return 'bg-gradient-to-r from-specialGray-005 to-white text-specialGray-075';
      }
      return 'bg-white text-darkBlue';
    },
    [vacancy?.status],
  );

  const handleGetOrderNumber = useCallback(
    (date: string) => {
      if (differenceInHours(new Date(), new Date(date)) <= 24 || (isChromatic() && serialNumber === 1)) {
        return <span className="uppercase text-[#F96434] font-bold">{intl.formatMessage({ id: 'hire_dashboard_table_new_element' })}</span>;
      }
      return <span className="ml-3 text-sm">{serialNumber}</span>;
    },
    [intl, serialNumber],
  );

  return (
    <>
      <Link
        to={`${RoutesPathNames.job}/${RoutesPathNames.edit}/${vacancy?.id}`}
        className={`flex flex-col md:flex-row items-left md:items-center p-3 border border-specialGray-012 border-t-0 cursor-pointer ${getBackgroundForItem(
          vacancy?.createdAt as string,
        )} ${lastIndex && 'rounded-2xl rounded-t-none'}`}
      >
        {/*serialNumber*/}
        <div className={`${number} flex items-center`}>{handleGetOrderNumber(vacancy?.createdAt as string)}</div>

        {/*STATUS*/}
        <div className={`${status} flex mt-1 md:mt-0`}>
          <JobOpeningStatus status={(vacancy?.status as VACANCY_STATUS) || ''} />
        </div>

        {/*POSITION TITLE*/}
        <div data-test-id="position-title" className={`${positionTitle} px-4 mt-1 md:mt-0`}>
          <TableCellWithPopover text={vacancy?.title} />
        </div>

        {/*POSITIONS (DUTIES)*/}
        <div className={`${position} px-4 mt-1 md:mt-0`}>
          <TableCellWithPopover text={mappingVacancyDuties} />
        </div>

        {/*VESSEL TYPE*/}
        <div className={`${vesselType} px-4 mt-1 md:mt-0`}>
          <TableCellWithPopover text={vacancy?.vesselKind?.name || ''} />
        </div>

        {/*SALARY RANGE*/}
        <div className={`${salaryRange} text-sm flex`}>{!!vacancy?.salaryFrom ? `${vacancy?.salaryFrom} - ${vacancy?.salaryTo}` : ''}</div>

        {/*INDUSTRY*/}
        <div className={`${industry} text-sm flex`}>
          {vacancy?.industry && intl.formatMessage({ id: MOCK_INDUSTRY_LIST.find(element => element.id === vacancy?.industry)?.title })}
        </div>

        {/*APPLIED CREW*/}
        <div className={`${applied}`}>
          <AppliedCrewPortraits listOfAppliedCrew={vacancy?.applications} />
        </div>

        {/*POSTING DATE*/}
        <div className={`${postingDate} text-sm flex justify-center`}>{vacancy?.createdAt && format(new Date(`${vacancy?.createdAt}`), 'dd LLL yyyy')}</div>

        {/*setting button*/}
        <div className={`${emptyTitle} flex flex-col justify-center gap-1`}>
          <div className="relative flex justify-center">
            <Button
              buttonType="just-icon-rounded-xl"
              icon={CombinedShapeIcon}
              additionalClasses="w-10 h-10"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setIsOpenPopover(prevState => !prevState);
              }}
            />
            <LittlePopoverWrapper isOpen={isOpenPopover} setIsOpen={setIsOpenPopover}>
              <ControlButtons vacancy={vacancy} callback={() => setIsOpenPopover(false)} />
            </LittlePopoverWrapper>
          </div>
        </div>
      </Link>
    </>
  );
};

export default DashboardDesktopTableItem;
