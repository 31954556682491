import Skeleton from 'react-loading-skeleton';
import { PropsWithChildren } from 'react';

const NavbarSearchDropdownMembersSkeletonItem = ({ children }: PropsWithChildren) => {
  return (
    <div className="w-full px-2.5 flex items-center border-b border-solid border-gray-300 h-[73px]">
      <Skeleton width={56} height={56} className="rounded-md" />
      <div className="flex flex-col ml-3 ">
        <section className="flex space-x-2">
          <Skeleton width={60} height={14} />
          <Skeleton width={50} height={14} />
        </section>
        <section>
          <Skeleton width={45} height={14} />
        </section>
      </div>
      {children}
    </div>
  );
};

export default NavbarSearchDropdownMembersSkeletonItem;
