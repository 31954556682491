// packages
import { useNavigate } from 'react-router-dom';
// routes
import { RoutePaths } from 'app/routing';
//
import { LoginForm } from 'auth/LoginForm';
import { useAuth } from 'authentication';

const HireLoginComponent = () => {
  const navigate = useNavigate();
  const { setEmail, setPassword } = useAuth();

  return (
    <LoginForm
      onLoginEmailConfirmedFault={(email, password) => {
        if (setEmail && setPassword) {
          setEmail(email);
          setPassword(password);
        }
        navigate(RoutePaths.HireAuthRegisterConfirm, { state: { isHire: true } });
      }}
      forgotPasswordLink={RoutePaths.HireAuthForgot}
      onLoginSuccess={d => {
        if (d.platformGroup === 'crew') {
          return navigate(RoutePaths.ProfileBase);
        }
        navigate(RoutePaths.ProfileOrganizationBase);
      }}
    />
  );
};

export default HireLoginComponent;
