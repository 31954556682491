/**
 * @generated SignedSource<<d9ff6410e9f6e7937b103566f2bb2709>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProfileMutationsUpsertProfileOIDClaimsMutation$variables = {
  username: string;
};
export type ProfileMutationsUpsertProfileOIDClaimsMutation$data = {
  readonly upsertProfileOIDClaims: {
    readonly id: string;
    readonly phoneNumber: string | null;
    readonly phoneNumberVerified: boolean;
  };
};
export type ProfileMutationsUpsertProfileOIDClaimsMutation = {
  response: ProfileMutationsUpsertProfileOIDClaimsMutation$data;
  variables: ProfileMutationsUpsertProfileOIDClaimsMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'username',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'username',
            variableName: 'username',
          },
        ],
        concreteType: 'ProfileOIDClaims',
        kind: 'LinkedField',
        name: 'upsertProfileOIDClaims',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'phoneNumber',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'phoneNumberVerified',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileMutationsUpsertProfileOIDClaimsMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileMutationsUpsertProfileOIDClaimsMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '4f32a48091426f12d86f17e61e741442',
      id: null,
      metadata: {},
      name: 'ProfileMutationsUpsertProfileOIDClaimsMutation',
      operationKind: 'mutation',
      text: 'mutation ProfileMutationsUpsertProfileOIDClaimsMutation(\n  $username: String!\n) {\n  upsertProfileOIDClaims(username: $username) {\n    id\n    phoneNumber\n    phoneNumberVerified\n  }\n}\n',
    },
  };
})();

(node as any).hash = '34a025714cafaa5c5d16373d9a95fbef';

export default node;
