// packages
import { useSetRecoilState } from 'recoil';
import { useCallback, useState, MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { differenceInMinutes } from 'date-fns';
import { useFragment, useMutation, useRefetchableFragment } from 'react-relay';
// components
import { Modal } from 'system/Modal';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ConfirmModalBody } from 'system/ConfirmModalBody';
import ListOfCrewItemCommon from 'system/JobOpeningComponents/ListOfCrewItemCommon';
// generated
import { CREW_PROFILE_FRAGMENT, PROFILE_INFO_FRAGMENT } from 'schemas/profile/ProfileFragments';
import { ApplicationFragments$key } from 'schemas/Application/__generated__/ApplicationFragments.graphql';
import { ProfileFragmentsCrewProfile$key } from 'schemas/profile/__generated__/ProfileFragmentsCrewProfile.graphql';
import { ProfileFragments_info$data, ProfileFragments_info$key } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
import { CountryFragments_info$data, CountryFragments_info$key } from 'schemas/country/__generated__/CountryFragments_info.graphql';
import { ServiceFragmentsOneDuty$data, ServiceFragmentsOneDuty$key } from 'schemas/services/__generated__/ServiceFragmentsOneDuty.graphql';
import { ApplicationMutationsUpdateStatusMutation } from 'schemas/Application/__generated__/ApplicationMutationsUpdateStatusMutation.graphql';
// schemas
import { GET_ONE_DUTY } from 'schemas/services/ServiceFragments';
import { COUNTRY_FRAGMENT } from 'schemas/country/CountryFragments';
import { APPLICATION_FRAGMENT } from 'schemas/Application/ApplicationFragments';
import { UPDATE_APPLICATION_STATUS } from 'schemas/Application/ApplicationMutations';
// utils
import { getDifferentTime } from 'utils';
// types
import { AppliedCrewItemProps } from '../types';
// models
import { VACANCY_APPLICATION_STATUS } from 'models/enums';
// formHelpers
import { ApplicationQueriesGetApplicationQuery } from 'schemas/Application/__generated__/ApplicationQueriesGetApplicationQuery.graphql';
// recoil
import { contactInformationAtom } from 'recoil/ContactInformation/atoms/contactInformationAtom';

const AppliedCrewItem = ({ typeOfList, applicationFragment }: AppliedCrewItemProps) => {
  const intl = useIntl();

  const [showConfirmForDeny, setShowConfirmForDeny] = useState<boolean>(false);

  const [application, reFetch] = useRefetchableFragment<ApplicationQueriesGetApplicationQuery, ApplicationFragments$key>(APPLICATION_FRAGMENT, applicationFragment);
  const profileData = useFragment<ProfileFragmentsCrewProfile$key>(CREW_PROFILE_FRAGMENT, application.owner);
  const profileInfoData = useFragment<ProfileFragments_info$key>(PROFILE_INFO_FRAGMENT, profileData.info);
  const primaryDuty = useFragment<ServiceFragmentsOneDuty$key>(GET_ONE_DUTY, profileInfoData?.primaryDuty || null);
  const countryData = useFragment<CountryFragments_info$key>(COUNTRY_FRAGMENT, profileInfoData?.country || null);

  const setContactInfoButton = useSetRecoilState(contactInformationAtom);

  const [commitUpdateStatus] = useMutation<ApplicationMutationsUpdateStatusMutation>(UPDATE_APPLICATION_STATUS);

  const isLessThanOneHour = (createAt: string) => {
    const minutes = differenceInMinutes(new Date(), new Date(createAt));
    return minutes < 60;
  };

  const handleChangeApplicationStatus = () => {
    commitUpdateStatus({
      variables: {
        id: application.id,
        status: VACANCY_APPLICATION_STATUS.DECLINED,
      },
      onCompleted: () => {
        setShowConfirmForDeny(false);
        reFetch({}, { fetchPolicy: 'network-only' });
      },
    });
  };

  const handleOpenConfirmDenyModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setShowConfirmForDeny(true);
  };

  const handleOpenContactModal = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setContactInfoButton({
        showContactInformation: true,
        profileId: profileData.id,
      });
    },
    [profileData.id, setContactInfoButton],
  );

  return (
    <>
      <Modal onClose={() => setShowConfirmForDeny(false)} show={showConfirmForDeny} tinyModal>
        <ConfirmModalBody
          title={{ id: 'confirm_deny_this_crew_title' }}
          textOne={{ id: 'confirm_deny_this_crew_text_1' }}
          onCancel={() => setShowConfirmForDeny(false)}
          onClick={handleChangeApplicationStatus}
          buttonLabel={{ id: 'hire_label_deny' }}
          typeButtonConfirm="primary"
          buttonWithIcon={false}
        />
      </Modal>
      <ListOfCrewItemCommon
        typeOfList={typeOfList}
        profileInfoData={profileInfoData as ProfileFragments_info$data}
        profileData={profileData}
        countryData={countryData as CountryFragments_info$data}
        primaryDuty={primaryDuty as ServiceFragmentsOneDuty$data}
      >
        <div className="flex gap-x-4 items-center">
          <button
            className="w-20 h-9 text-center vertical-center border border-specialGray-05 text-specialGray-075 rounded-lg hover:bg-gray-100 text-sm disabled:bg-gray-100 disabled:cursor-not-allowed"
            onClick={handleOpenContactModal}
          >
            <span>{intl.formatMessage({ id: 'review_applications_contact_label' })}</span>
          </button>

          {application.status === VACANCY_APPLICATION_STATUS.PENDING ? (
            <button
              className="w-20 h-9 text-center vertical-center border border-specialGray-05 text-specialGray-075 rounded-lg hover:bg-gray-100 text-sm"
              onClick={handleOpenConfirmDenyModal}
            >
              <span>{intl.formatMessage({ id: 'hire_label_deny' })}</span>
            </button>
          ) : null}
          {application.status === VACANCY_APPLICATION_STATUS.DECLINED ? (
            <div className="w-20 text-red-500 text-sm flex items-center font-medium">
              <XMarkIcon className="w-4 h-4" />
              <span className="ml-2">{intl.formatMessage({ id: 'hire_label_denied' })}</span>
            </div>
          ) : null}
          <span className={`w-[105px] text-sm ${isLessThanOneHour(`${application.createdAt}`) ? 'text-blue' : 'text-specialGray-075'} font-medium text-right`}>
            {getDifferentTime(`${application.createdAt}`)}
          </span>
        </div>
      </ListOfCrewItemCommon>
    </>
  );
};

export default AppliedCrewItem;
