// packages
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
//routs
import { JOB_OPENING_TABS, RoutePaths, RoutesPathNames } from 'app/routing';
//hooks
import useProfileMembership from 'hooks/useProfileMembership';
import { useSwitchVacancyStatus } from 'hooks/useSwitchVacancyStatus';
import VacancyTabWithPopover from 'system/Layouts/JobDescriptionTabs/components/VacancyTabWithPopover/VacancyTabWithPopover';
//components
import ArrowComponent from 'system/Layouts/JobDescriptionTabs/components/ArrowComponent';
//models
import { VACANCY_STATUS } from 'models/enums';
// schemas
import { GET_VACANCY } from 'schemas/vacancy/VacancyQueries';
// generated
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { VacancyQueriesGetVacancyQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetVacancyQuery.graphql';

const VacancyTabs = () => {
  const { jobDescriptionEditId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { membership } = useProfileMembership();

  const revealRefs = useRef<Array<HTMLDivElement>>([]);
  const [selectedTab, setSelectedTab] = useState<string>('false');
  const vacancyFragment = useLazyLoadQuery<VacancyQueriesGetVacancyQuery>(GET_VACANCY, { id: `${jobDescriptionEditId}`, skip: !jobDescriptionEditId });
  const { vacancy, handleSwitchStatus } = useSwitchVacancyStatus(vacancyFragment.node as VacancyFragments$key);

  useEffect(() => {
    const isOwnerVacancy = vacancy?.organization?.id === membership?.organization?.id && (membership?.role === 'owner' || membership?.role === 'member');
    if (!isOwnerVacancy) {
      navigate(`${RoutePaths.ProfileBase}/${RoutesPathNames.job}/${jobDescriptionEditId}`, { replace: true });
    }
  }, [jobDescriptionEditId, membership?.organization?.id, membership?.role, navigate, vacancy?.organization?.id]);

  useEffect(() => {
    setSelectedTab((JOB_OPENING_TABS.find(tab => pathname.includes(tab.href))?.href as string) || JOB_OPENING_TABS[0].href);
  }, [pathname]);

  const handleClickByTab = (href: string) => () => {
    setSelectedTab(href);
    navigate(href === RoutePaths.JobDescription ? '.' : href);
  };

  const handleResizeArrow = useCallback(() => {
    JOB_OPENING_TABS.forEach((_, index) => {
      const arrow: HTMLElement | null = document.getElementById(`arrow-${index}`);

      const currentTab = revealRefs.current[index];
      const nextSiblingTab = revealRefs.current[index + 1];
      const childInCurrentTab = (currentTab as HTMLDivElement)?.firstChild;
      const childInNextTab = (nextSiblingTab as HTMLDivElement)?.firstChild;

      const offsetLeftCurrentTab = (currentTab as HTMLElement)?.offsetLeft;
      const offsetLeftNextSiblingTab = (nextSiblingTab as HTMLElement)?.offsetLeft;
      const offsetWidthNextSiblingTab = (nextSiblingTab as HTMLElement)?.offsetWidth;
      const offsetWidthChildInNextTab = (childInNextTab as HTMLElement)?.offsetWidth;
      const offsetLeftChildInCurrentTab = (childInCurrentTab as HTMLElement)?.offsetLeft;
      const offsetWidthChildInCurrentTab = (childInCurrentTab as HTMLElement)?.offsetWidth;

      const arrowLeftPositionWithOpenStatus = offsetWidthChildInCurrentTab + offsetLeftChildInCurrentTab;
      const getLeftSpacingBetweenTabAndTabContent = (offsetWidthNextSiblingTab - offsetWidthChildInNextTab) / 2;
      const arrowWidth = offsetLeftNextSiblingTab - arrowLeftPositionWithOpenStatus - offsetLeftCurrentTab + getLeftSpacingBetweenTabAndTabContent - 16;

      (arrow as HTMLDivElement)?.setAttribute('style', `left:${arrowLeftPositionWithOpenStatus}px; width:${arrowWidth}px`);
    });
  }, []);

  useEffect(() => {
    handleResizeArrow();
    window.addEventListener('resize', handleResizeArrow);
    return () => {
      window.removeEventListener('resize', handleResizeArrow);
    };
  }, [handleResizeArrow, vacancy.status]);

  const addToRefs = useCallback((el: HTMLInputElement) => {
    if (el && !revealRefs.current?.includes(el)) {
      revealRefs.current?.push(el);
    }
  }, []);

  return (
    <div className="w-full bg-specialGray-004 flex items-center">
      <div className="flex w-full max-w-7xl h-full justify-between mx-auto">
        {JOB_OPENING_TABS.map((tab, index) => {
          const Icon = tab.activeIcon;
          const isRestrictedTab = vacancy.status === VACANCY_STATUS.CLOSED && index > 0;
          return isRestrictedTab ? (
            <div ref={addToRefs} className="flex w-80 justify-center items-center hover:bg-specialGray-005 relative" key={tab.href}>
              <VacancyTabWithPopover popoverDescription={tab.popoverDescription} name={tab.name} index={index} callback={handleSwitchStatus(VACANCY_STATUS.OPEN)} />
            </div>
          ) : (
            <div
              key={tab.href}
              ref={addToRefs}
              className={`flex justify-center w-80 items-center h-16 relative ${isRestrictedTab ? 'cursor-not-allowed' : 'cursor-pointer'} hover:bg-specialGray-005 text-darkBlue`}
              onClick={handleClickByTab(tab.href)}
            >
              <div className="flex">
                <div className={`rounded-full ${selectedTab === tab.href ? 'bg-blue' : 'bg-specialGray-05'} w-6 h-6 flex justify-center items-center`}>
                  <Icon />
                </div>
                <div className="ml-3 text-darkBlue relative flex items-center">{tab.name}</div>
              </div>

              {JOB_OPENING_TABS.length - 1 > index && <ArrowComponent index={index} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VacancyTabs;
