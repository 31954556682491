export const excludedEmailDomain = [
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'hotmail.com',
  'aol.com',
  'zoho.com',
  'protonmail.com',
  'mail.com',
  'icloud.com',
  'gmx.com',
  'yandex.com',
  'fastmail.com',
  'tutanota.com',
  'hushmail.com',
  'prodigy.net',
  'netzero.net',
  'lycos.com',
  'rediffmail.com',
  'inbox.com',
  'rocketmail.com',
  'bluemail.me',
  'wp.pl',
  'mail.ru',
  'proton.me',
  'onet.pl',
  'my.com',
  'libero.it',
];
