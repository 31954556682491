// packages
import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { PlusIcon } from '@heroicons/react/24/solid';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
// components
import LineSvg from 'icons/LineSvg';
import { Modal } from 'system/Modal';
import { Button } from 'system/Button';
import JobAvatar from 'app/Avatars/JobAvatar/JobAvatar';
import ProfileLowScoreBody from 'app/ProfileEmployer/components/JobDescriptionPage/components/ProfileLowScoreBody';
import MainInformation from 'app/ProfileEmployer/components/JobDescriptionPage/components/VacancyInformation/components/MainInformation';
import VacancyDescription from 'app/ProfileEmployer/components/JobDescriptionPage/components/VacancyInformation/components/VacancyDescription';
import EssentialCertificates from 'app/ProfileEmployer/components/JobDescriptionPage/components/VacancyInformation/components/EssentialCertificates';
import SecondaryInformation from 'app/ProfileEmployer/components/JobDescriptionPage/components/VacancyInformation/components/SecondaryInformation';
// generated
import { VacancyQueriesGetVacancyQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetVacancyQuery.graphql';
import { VacancyFragments$data, VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { VacancyQueriesGetVacancyRefreshQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetVacancyRefreshQuery.graphql';
// schemas
import { ConfirmModalBody } from 'system/ConfirmModalBody';
import { GET_VACANCY } from 'schemas/vacancy/VacancyQueries';
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';
import { VACANCY_FRAGMENT } from 'schemas/vacancy/VacancyFragments';
// routing
import { RoutePaths, RoutesPathNames } from 'app/routing';
// hooks
import { useAuth } from 'authentication';
import { useGetProfileScore } from 'hooks/useGetProfileScore';
import { useToggleShowWindow } from 'hooks/useToggleShowModal';
import { useGetUserAlreadyApplied } from 'hooks/useGetUserAlreadyApplied';
import { useCreateVacancyApplication } from 'hooks/useCreateVacancyApplication';
import { useRemoveVacancyApplication } from 'hooks/useRemoveVacancyApplication';
// models
import { IVacancyCertificate } from 'models/ICertificate';
// recoil
import { personalAccountSelector } from 'recoil/Profile/personalAccount/selectors/personalAccountSelector';
//enums
import { PLATFORM_GROUP } from 'models/enums';

const JobOpeningSlide = () => {
  const intl = useIntl();
  const params = useParams();
  const { identity } = useAuth();
  const { state } = useLocation();
  const { profileScoreSummary } = useGetProfileScore();
  const { showWindow, toggleShowWindow } = useToggleShowWindow();
  const getIsPersonalAccount = useRecoilValue(personalAccountSelector);

  const vacancyFragment = useLazyLoadQuery<VacancyQueriesGetVacancyQuery>(GET_VACANCY, {
    id: params[RoutesPathNames.jobOpeningSlideId] || '',
    skip: !params[RoutesPathNames.jobOpeningSlideId],
  });

  const [vacancy, reFetch] = useRefetchableFragment<VacancyQueriesGetVacancyRefreshQuery, VacancyFragments$key>(VACANCY_FRAGMENT, vacancyFragment.node as VacancyFragments$key);

  const { userAlreadyApplied } = useGetUserAlreadyApplied(false, vacancy);

  const handleCreateApplication = useCreateVacancyApplication(vacancy as VacancyFragments$data, reFetch);
  const handleRemoveApplication = useRemoveVacancyApplication(vacancy, `${identity?.profileId}`, reFetch);

  const [isLowProfileScoreModal, setIsLowProfileScoreModal] = useState<boolean>(state?.storybookShowIsLowProfileScoreModal || false);

  const isLowProfileScore = useMemo<boolean>(
    () =>
      profileScoreSummary < (process.env.REACT_APP_MINIMAL_PROFILE_SCORE || process.env.STORYBOOK_FEATURE_MINIMAL_PROFILE_SCORE || 0) &&
      identity?.platformGroup !== PLATFORM_GROUP.GUEST,
    [identity?.platformGroup, profileScoreSummary],
  );

  const handleOpenOrCloseWindowWithAnalytics = useCallback(
    (openWindow: boolean = false) =>
      () => {
        if (profileScoreSummary < 20) {
          return;
        }

        if (isLowProfileScore) {
          setIsLowProfileScoreModal(true);
          return;
        }

        toggleShowWindow(openWindow)();

        const trackEventName = openWindow ? 'wui-side-drawer-applied-single-job-opening-click' : 'wui-side-drawer-cancel-applied-single-job-opening-click';
        window.analytics?.track(trackEventName, { vacancyId: vacancy?.id });
      },
    [isLowProfileScore, profileScoreSummary, toggleShowWindow, vacancy?.id],
  );

  const handleConfirmationApplying = useCallback(() => {
    window.analytics?.track('wui-side-drawer-confirm-applied-single-job-opening', {
      vacancyId: vacancy?.id,
    });
    handleCreateApplication();
    toggleShowWindow(false)();
  }, [handleCreateApplication, toggleShowWindow, vacancy?.id]);

  const handleRemoveApplicationWithAnalytics = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      window.analytics?.track('wui-side-drawer-remove-applied-single-job-opening-click', {
        vacancyId: vacancy?.id,
      });
      handleRemoveApplication(e);
    },
    [handleRemoveApplication, vacancy?.id],
  );

  const GetApplyButton = useCallback(
    () =>
      !userAlreadyApplied ? (
        <button
          className={`h-[62px] sm:h-10 rounded-lg flex px-8 items-center justify-center gap-x-3.5 sm:text-sm text-lg sm:w-fit w-full ${
            profileScoreSummary < 20 ? 'bg-[#D4D7DD] text-specialGray-03 cursor-not-allowed' : 'text-white hover:bg-blue-700 bg-blue'
          }`}
          onClick={handleOpenOrCloseWindowWithAnalytics(true)}
        >
          <PlusIcon className="sm:h-3.5 h- sm:w-3.5 h-5 w-5" />
          {intl.formatMessage({ id: 'hire_button_label_apply' })}
        </button>
      ) : null,
    [handleOpenOrCloseWindowWithAnalytics, intl, profileScoreSummary, userAlreadyApplied],
  );

  const renderButtons = useCallback(
    (isFullButtonSize: boolean) => (
      <>
        {getIsPersonalAccount.personalAccountIsSelected ? (
          userAlreadyApplied ? (
            <Button
              type="button"
              buttonType="white-primary"
              label={{ id: 'hire_label_applied' }}
              additionalClasses="h-10 rounded-lg"
              onClick={handleRemoveApplicationWithAnalytics}
              fullSize={isFullButtonSize}
            />
          ) : (
            <>
              {profileScoreSummary < 20 ? (
                <PopoverHelper
                  element={
                    <>
                      <GetApplyButton />
                    </>
                  }
                  position="bottom-[70px] sm:-bottom-3 sm:-right-2 sm:translate-x-full"
                  positionArrow="left-[35%] sm:left-0 rotate-45 sm:bottom-[45%] -bottom-0.5 sm:translate-x-0 translate-x-1/2"
                  widthContainer="w-[200px] sm:min-w-fit"
                  darkTheme
                  autoShow={state?.storybookShowPopoverByDefault}
                >
                  {intl.formatMessage({ id: 'job_description_apply_popover' })}
                </PopoverHelper>
              ) : (
                <GetApplyButton />
              )}
            </>
          )
        ) : null}

        <Link
          to={`${RoutePaths.ProfileBase}/job/${vacancy?.id}`}
          className={`text-center sm:text-start border border-specialGray-05 px-5 py-2.5 text-specialGray-075 rounded-lg text-sm ${isFullButtonSize ? 'w-full' : ''}`}
        >
          <span className="hidden sm:inline">{intl.formatMessage({ id: 'slide_button_label_see_all_information' })}</span>
          <span className="sm:hidden">{intl.formatMessage({ id: 'slide_button_label_see_all_info' })}</span>
        </Link>
      </>
    ),
    [
      GetApplyButton,
      getIsPersonalAccount.personalAccountIsSelected,
      handleRemoveApplicationWithAnalytics,
      intl,
      profileScoreSummary,
      state?.storybookShowPopoverByDefault,
      userAlreadyApplied,
      vacancy?.id,
    ],
  );

  return (
    <>
      {isLowProfileScore && (
        <Modal onClose={() => setIsLowProfileScoreModal(false)} show={isLowProfileScoreModal} tinyModal>
          <ProfileLowScoreBody vacancyId={vacancy.id} setIsLowProfileScoreModal={setIsLowProfileScoreModal} handleCreateApplication={handleCreateApplication} />
        </Modal>
      )}

      <Modal onClose={toggleShowWindow(false)} show={showWindow} tinyModal>
        <ConfirmModalBody
          title={{ id: 'hire_confirm_apply_to_vacancy_title' }}
          onClick={handleConfirmationApplying}
          onCancel={handleOpenOrCloseWindowWithAnalytics()}
          buttonLabel={{ id: 'ok' }}
          typeButtonConfirm="primary"
        />
      </Modal>
      <div className="flex flex-col gap-y-8 pb-10 sm:px-4">
        <div className="flex gap-x-5 mb-2">
          <JobAvatar size="w-20 h-20" avatarUrl={vacancy?.organization.avatarUrl} alt={vacancy?.title} />
          <div className="flex flex-col justify-between">
            <Link to={`${RoutePaths.ProfileBase}/job/${vacancy?.id}`} className="text-specialGray-1 text-2xl font-semibold">
              {vacancy?.title}
            </Link>
            <div className="gap-x-5 hidden sm:flex">{renderButtons(false)}</div>
          </div>
        </div>
        {!!vacancy && <MainInformation vacancy={vacancy} />}
        {!!vacancy?.description && (
          <>
            <LineSvg classes="w-[calc(100%_+_64px)] relative -left-12" />
            <VacancyDescription vacancyDescription={vacancy.description} />
          </>
        )}
        {!isEmpty(vacancy?.certificates) && (
          <>
            <LineSvg classes="w-[calc(100%_+_64px)] relative -left-12" />
            <EssentialCertificates certificates={vacancy?.certificates as Array<IVacancyCertificate>} />
          </>
        )}
        <>
          <LineSvg classes="w-[calc(100%_+_64px)] relative -left-12" />
          <SecondaryInformation vacancy={vacancy} />
        </>
      </div>
      <div className="gap-x-3 xs:gap-x-5 flex sm:hidden sticky bottom-0 left-0 py-2 bg-white">{renderButtons(true)}</div>
    </>
  );
};

export default JobOpeningSlide;
