import { differenceBy, isEmpty } from 'lodash';
import { RefObject, useEffect, useState } from 'react';

export function useGetWidthVerticalListOfItems<T>(listContainerRef: RefObject<HTMLElement>, listContainerWidth: number, listOfItems: ReadonlyArray<T> | null) {
  const [arrayOfItemsRef, setArrayOfItemsRef] = useState<HTMLDivElement[]>([]);
  const [arrayWithDiffer, setArrayWithDiffer] = useState<typeof listOfItems>([]);
  const [arrayOfItemsForRendering, setArrayOfItemsForRendering] = useState<typeof listOfItems>(listOfItems);

  const [widthPopover] = useState<number>(40);

  const addToRefs = (el: HTMLInputElement) => {
    if (el && !arrayOfItemsRef?.includes(el)) {
      setArrayOfItemsRef(prevState => [...prevState, el]);
    }
  };

  useEffect(() => {
    if (!isEmpty(arrayOfItemsRef) && !!listContainerWidth) {
      let sum = 0;
      // 8px - this is the margin between the duties
      arrayOfItemsRef.forEach(item => (sum += item?.clientWidth + 8));

      if (sum + widthPopover > listContainerWidth) {
        const newArrayOfDutiesForRendering = arrayOfItemsForRendering?.slice(0, -1) as typeof listOfItems;
        const newArrayOfDutiesRef = arrayOfItemsRef?.slice(0, -1);
        setArrayOfItemsForRendering(newArrayOfDutiesForRendering);
        setArrayOfItemsRef(newArrayOfDutiesRef);
      }
    }
  }, [arrayOfItemsForRendering, arrayOfItemsRef, listContainerWidth, widthPopover]);

  useEffect(() => {
    setArrayWithDiffer(differenceBy(listOfItems, arrayOfItemsForRendering || [], 'id'));
  }, [arrayOfItemsForRendering, listOfItems]);

  return {
    addToRefs,
    arrayWithDiffer,
    arrayOfItemsForRendering,
  };
}
