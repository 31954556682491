const PublishIcon = ({ className = '' }: { className?: string }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_305_29834)">
        <path
          d="M4.16699 4.16732C4.16699 4.62565 4.54199 5.00065 5.00033 5.00065H15.0003C15.4587 5.00065 15.8337 4.62565 15.8337 4.16732C15.8337 3.70898 15.4587 3.33398 15.0003 3.33398H5.00033C4.54199 3.33398 4.16699 3.70898 4.16699 4.16732ZM6.17533 11.6673H7.50033V15.834C7.50033 16.2923 7.87533 16.6673 8.33366 16.6673H11.667C12.1253 16.6673 12.5003 16.2923 12.5003 15.834V11.6673H13.8253C14.567 11.6673 14.942 10.7673 14.417 10.2423L10.592 6.41732C10.267 6.09232 9.74199 6.09232 9.41699 6.41732L5.59199 10.2423C5.06699 10.7673 5.43366 11.6673 6.17533 11.6673Z"
          fill="#6F1CF4"
        />
      </g>
      <defs>
        <clipPath id="clip0_305_29834">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PublishIcon;
