// packages
import { useLocation } from 'react-router-dom';
import { useFragment, useLazyLoadQuery } from 'react-relay';
// schemas
import { GET_VACANCY } from 'schemas/vacancy/VacancyQueries';
import { VACANCY_FRAGMENT } from 'schemas/vacancy/VacancyFragments';
// generated
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { VacancyQueriesGetVacancyQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetVacancyQuery.graphql';
// models
import { typeFormVacancyState } from 'models/routeLocationState';

export function useGetVacancy(defaultVacancyId: string) {
  const { state } = useLocation();

  const vacancyFragment = useLazyLoadQuery<VacancyQueriesGetVacancyQuery>(GET_VACANCY, {
    id: (state as typeFormVacancyState)?.organization?.vacancyId || defaultVacancyId,
    skip: !((state as typeFormVacancyState)?.organization?.vacancyId || defaultVacancyId),
  });

  return useFragment<VacancyFragments$key>(VACANCY_FRAGMENT, vacancyFragment.node as VacancyFragments$key);
}
