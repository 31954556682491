import graphql from 'babel-plugin-relay/macro';

export const REMOVE_SERVICE_LIST_ITEM_MUTATION = graphql`
  mutation ServiceMutationsListRemoveMutation($id: ID!) {
    deleteSeaService(id: $id) {
      id
    }
  }
`;

export const CREATE_SERVICE = graphql`
  mutation ServiceMutationsFormCreateMutation($data: SeaServiceData!, $connections: [ID!]!) {
    createSeaService(data: $data) {
      node @appendNode(connections: $connections, edgeTypeName: "SeaServiceEdge") {
        ... on SeaService {
          id
          ...ServiceFragments
        }
      }
    }
  }
`;

export const UPDATE_SERVICE = graphql`
  mutation ServiceMutationsFormUpdateMutation($id: ID!, $data: SeaServiceData!) {
    updateSeaService(id: $id, data: $data) {
      ...ServiceFragments
    }
  }
`;

export const ADD_TAGS_TO_SEA_SERVICE = graphql`
  mutation ServiceMutationsAddTagsToSeaServiceMutation($sid: ID!, $tags: [String!]!) {
    setActivitiesForSeaService(sid: $sid, activities: $tags) {
      id
      activities {
        id
        label
      }
    }
  }
`;

export const ADD_HIGHLIGHTED_SEA_SERVICE = graphql`
  mutation ServiceMutationsHighlightedItemAddServiceMutation($pid: ID!, $sid: ID!) {
    addHighlightedSeaService(pid: $pid, sid: $sid)
  }
`;

export const REMOVE_HIGHLIGHTED_SEA_SERVICE = graphql`
  mutation ServiceMutationsHighlightedItemRemoveServiceMutation($pid: ID!, $sid: ID!) {
    removeHighlightedSeaService(pid: $pid, sid: $sid) {
      id
    }
  }
`;

export const ADD_DESCRIPTION_TO_SEA_SERVICE = graphql`
  mutation ServiceMutationsAddDescriptionToSeaServiceMutation($id: ID!, $description: String!) {
    setDescriptionForSeaService(id: $id, description: $description) {
      description
    }
  }
`;
