import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import isAfter from 'date-fns/isAfter';
import { useLocation } from 'react-router-dom';
// hooks
import { useGetProfileIdFromAuthOrParams } from 'hooks/useGetProfileIdFromAuthOrParams';
// components
import Popover from 'system/Popover';
import { Button } from 'system/Button';
import EditIcons from 'icons/EditIcons';
import HighlightElementWrapper from 'system/wrappers/HighlightElementWrapper';
import CertificateExpiryDateItem from './components/CertificateExpiryDateItem';
// models
import { typeCertificateState } from 'models/routeLocationState';
//types
import { CertificateExpiryDateProps } from 'app/Profile/Certificates/types';

const CertificateExpiryDate = ({ expiryDate, handleOpenModal, neverExpires }: CertificateExpiryDateProps) => {
  const intl = useIntl();
  const { state } = useLocation();
  const [hoverIcon, setHoverIcon] = useState(false);
  const { isSelfProfile } = useGetProfileIdFromAuthOrParams();

  const handleMouseOver = useCallback(() => {
    setHoverIcon(true);
  }, []);
  const handleMouseOut = useCallback(() => {
    setHoverIcon(false);
  }, []);

  return expiryDate ? (
    <div className="sm:pl-4">
      <HighlightElementWrapper
        addPadding="outline-offset-4"
        enableFlickering={(state as typeCertificateState)?.notExpiredDate && expiryDate && isAfter(new Date(), new Date(expiryDate as string))}
      >
        <CertificateExpiryDateItem expiryDate={expiryDate}>
          {isSelfProfile && <Button buttonType="icon-button" icon={EditIcons} additionalClasses="ml-2 sm:ml-4" onClick={handleOpenModal} />}
        </CertificateExpiryDateItem>
      </HighlightElementWrapper>
    </div>
  ) : isSelfProfile ? (
    <section className="relative inline-block sm:ml-3">
      <HighlightElementWrapper addPadding="outline-offset-4" enableFlickering={!expiryDate && (state as typeCertificateState)?.addExpiryDate}>
        <Button
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          label={{ id: 'cert_button_add_expiry_date' }}
          buttonType="white-primary"
          additionalClasses="h-7"
          onClick={handleOpenModal}
          disabled={neverExpires}
        />
        {hoverIcon && neverExpires && <Popover position="bottom-8 max-w-[220px]">{intl.formatMessage({ id: 'certificate_not_configure' })}</Popover>}
      </HighlightElementWrapper>
    </section>
  ) : (
    <div className="text-specialGray-03 text-xs pl-4 flex items-center">{intl.formatMessage({ id: 'certificate_without_expiry_date' })}</div>
  );
};

export default CertificateExpiryDate;
