// packages
import { Suspense, useState } from 'react';
import { useIntl } from 'react-intl';
import FilterIcon from 'icons/FilterIcon';
import { useLocation } from 'react-router-dom';
//hooks
import { useToggleShowWindow } from 'hooks/useToggleShowModal';
import useSearchUrlAnalytics from 'hooks/anaytics/useSearchUrlAnalytics';
// components
import CustomButton from 'system/Buttons/CustomButton';
import ListOfJobOpeningsComponent from './components/ListOfJobOpeningsComponent';
import FilterSliderWrapper from 'system/Layouts/FilterSliderWrapper/FilterSliderWrapper';
import JobOpeningFilterComponentSkeleton from 'system/skeletons/filters/JobOpeningFilterComponentSkeleton';
import JobOpeningFilterComponent from 'system/FilterSlideComponents/JobOpeningFiltering/JobOpeningFilterComponent';
import ListOfJobOpeningsSkeleton from 'system/skeletons/lists/ListOfJobOpeningsSkeleton/ListOfJobOpeningsSkeleton';
import SelectedJobOpeningsFilterElements from 'system/FilterSlideComponents/components/SelectedFilterElements/SelectedJobOpeningsFilterElements';
// context
import { useNavbarSearchContext } from 'Context/NavbarSearchContext';

const ListOfJobOpenings = ({ defaultOpenedFilterInStorybook }: { defaultOpenedFilterInStorybook?: boolean }) => {
  const intl = useIntl();
  const { toggleShowWindow, showWindow } = useToggleShowWindow();
  const { search } = useLocation();
  const { queryParams } = useNavbarSearchContext();
  useSearchUrlAnalytics('wui-opened-job-opening-list-page', 'wui-job-opening-list-with-filters');

  const [amountVacancies, setAmountVacancies] = useState<number>(0);

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="flex justify-between items-center py-2">
          <div className="flex flex-col">
            <h2 className="text-lg sm:text-2xl text-darkBlue mb-1 font-medium">{intl.formatMessage({ id: 'crew_find_a_job_opening' })}</h2>
            {/*Don't show total counter for job openings*/}
            {(search || queryParams) && (
              <span className="text-specialGray-075 text-sm">{intl.formatMessage({ id: 'job_openings_page_amount_found' }, { amount: amountVacancies })}</span>
            )}
          </div>
          <CustomButton
            label={{ id: 'label_filter' }}
            onClick={toggleShowWindow(true)}
            classNameStyle="w-28 justify-between text-sm disabled:opacity-50 px-5 py-3.5 border border-specialGray-05 text-sm leading-4 font-normal rounded-xl hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 mr-1 max-h-12"
          >
            <FilterIcon />
          </CustomButton>
        </div>
        <Suspense>
          <SelectedJobOpeningsFilterElements />
        </Suspense>
        <Suspense fallback={<ListOfJobOpeningsSkeleton />}>
          <ListOfJobOpeningsComponent setAmountVacancies={setAmountVacancies} />
        </Suspense>
      </div>
      <FilterSliderWrapper filterButtonEvent={toggleShowWindow(false)} isOpenListOfFilters={defaultOpenedFilterInStorybook || showWindow}>
        <Suspense fallback={<JobOpeningFilterComponentSkeleton />}>{(defaultOpenedFilterInStorybook || showWindow) && <JobOpeningFilterComponent />}</Suspense>
      </FilterSliderWrapper>
    </>
  );
};

export default ListOfJobOpenings;
