// packages
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { useFragment } from 'react-relay';
// schemas
import { VACANCY_FRAGMENT_FOR_SEARCH } from 'schemas/vacancy/VacancyFragments';
// generated
import { VacancyFragmentsForSearchFragment$key } from 'schemas/vacancy/__generated__/VacancyFragmentsForSearchFragment.graphql';
// routes
import { RoutePaths } from 'app/routing';
// components
import ShipPhoto from 'icons/ShipPhoto';
import LocationIcon from 'icons/LocationIcon';
import SalaryComponent from './components/SalaryComponent';
import ComponentWithPopover from 'system/ComponentWithPopover';
import ListWithShowMoreItems from 'system/ListWithShowMoreItems/ListWithShowMoreItems';

const JobOpeningComponent = ({ vacancy }: { vacancy: VacancyFragmentsForSearchFragment$key }) => {
  const vacancyData = useFragment<VacancyFragmentsForSearchFragment$key>(VACANCY_FRAGMENT_FOR_SEARCH, vacancy as VacancyFragmentsForSearchFragment$key);

  const handleClickByLink = () => {
    if (vacancyData.status === 'OPEN') {
      window.analytics?.track('wui-open-job-in-organization-info-page', {
        vacancyId: vacancyData.id,
      });
    }
  };

  return (
    <Link
      to={`${RoutePaths.ProfileBase}/job/${vacancyData.id}`}
      className="border border-specialGray-012 rounded-2xl cursor-pointer w-full min-h-[94px] flex h-full p-4 flex-col sm:flex-row justify-between gap-y-2 sm:gap-0"
      onClick={handleClickByLink}
    >
      <div className="flex w-full justify-between">
        <div className="flex flex-col w-[calc(100%_-_100px)] gap-y-2.5 gap-x-4">
          <div className="flex items-center gap-x-4 flex-wrap">
            <h2 className="text-specialGray-1 text-lg font-semibold truncate max-w-full sm:max-w-[50%] xl:max-w-[65%]">{vacancyData?.title}</h2>
            <div className="hidden sm:inline-block">
              <ListWithShowMoreItems list={vacancyData.duties} field="name" />
            </div>
          </div>
          <div className="flex w-full flex-wrap">
            {/*Hiring area*/}
            {vacancyData?.hiringArea && (
              <div className="flex items-center gap-x-1.5 sm:mr-[18px]">
                <LocationIcon size="w-4 h-4" />
                <ComponentWithPopover
                  popoverPosition="bottom-[calc(100%_+_5px)]"
                  id={vacancyData.hiringArea.name}
                  name={vacancyData.hiringArea.name}
                  customStyle="text-sm text-darkBlue max-w-[150px] truncate"
                />
              </div>
            )}
            {/*Salary range*/}
            {!!(vacancyData?.salaryFrom || vacancyData?.salaryTo) ? <SalaryComponent vacancy={vacancyData} styles="sm:flex hidden" /> : null}
            {/*Vessel kind*/}
            {vacancyData?.vesselKind && (
              <div className="flex items-center gap-x-1.5 sm:mr-[18px]">
                <span className="w-4 h-4">
                  <ShipPhoto />
                </span>
                <span className="text-sm text-darkBlue lowercase first-letter:uppercase whitespace-nowrap">{vacancyData.vesselKind.name}</span>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center text-specialGray-075 text-sm whitespace-nowrap self-start text-center">
          {vacancyData?.createdAt && format(new Date(`${vacancyData.createdAt}`), 'dd MMM yyyy')}
        </div>
      </div>
      {!!(vacancyData?.salaryFrom || vacancyData?.salaryTo) ? <SalaryComponent vacancy={vacancyData} styles="flex sm:hidden justify-between" /> : null}
    </Link>
  );
};

export default JobOpeningComponent;
