import React from 'react';

const NoAppraisalIcon: React.FC = () => {
  return (
    <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.8082 6.32812H18.564C13.8455 6.32812 9.77777 10.1529 9.77777 14.8738V39.736C9.77777 44.7227 13.5774 48.3871 18.564 48.3871H36.8699C41.5907 48.3871 45.4178 44.4569 45.4178 39.736V18.419L33.8082 6.32812Z"
        stroke="#8490A8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.206 6.30469V12.9711C33.206 16.2253 35.8391 18.8653 39.0933 18.8722C42.1091 18.8791 45.196 18.8814 45.4046 18.8676"
        stroke="#8490A8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.1781 21.7589L30.1332 25.6919C30.2311 25.8903 30.4202 26.0287 30.6397 26.0608L35.0168 26.6931C35.194 26.7167 35.3536 26.8096 35.4625 26.9514C35.6668 27.2173 35.6355 27.5938 35.3907 27.8226L32.2183 30.8903C32.0571 31.0423 31.9853 31.2651 32.0275 31.4821L32.7873 35.811C32.8405 36.1698 32.5957 36.5058 32.2369 36.564C32.0883 36.5868 31.9364 36.5632 31.8013 36.4965L27.9029 34.4527C27.707 34.3464 27.4723 34.3464 27.2765 34.4527L23.3494 36.5075C23.021 36.6746 22.6192 36.5505 22.4402 36.2289C22.3718 36.0989 22.3482 35.9511 22.3718 35.8068L23.1316 31.4779C23.1696 31.2617 23.0978 31.0397 22.9408 30.8869L19.7515 27.82C19.4915 27.5617 19.4898 27.1413 19.749 26.8813L19.7515 26.8779C19.8587 26.7809 19.9904 26.7159 20.1331 26.6905L24.511 26.0582C24.7296 26.0236 24.9179 25.8869 25.0175 25.6885L26.9709 21.7589C27.0494 21.5993 27.1887 21.4769 27.3575 21.4212C27.5272 21.3646 27.7129 21.3781 27.8725 21.4583C28.0033 21.5233 28.1105 21.6289 28.1781 21.7589Z"
        stroke="#8490A8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NoAppraisalIcon;
