import graphql from 'babel-plugin-relay/macro';

export const GET_TOTAL_COUNT_OF_VACANCIES = graphql`
  query VacancyQueriesGetTotalCountOfVacanciesQuery($orgId: ID!, $skip: Boolean!) {
    organizationVacancyStats(orgId: $orgId) @skip(if: $skip) {
      stats {
        totalCount
        status
      }
    }
  }
`;

export const LIST_OF_VACANCIES = graphql`
  query VacancyQueriesListOfVacanciesQuery($orgId: ID, $after: Cursor, $first: Int, $skip: Boolean!, $filters: [FilterModel], $orderBy: VacancyOrder) {
    vacancies(orgId: $orgId, first: $first, after: $after, filters: $filters, orderBy: $orderBy) @skip(if: $skip) @connection(key: "VacancyList_opened_vacancies") {
      edges {
        node {
          id
          status
          title
          duties {
            name
          }
          createdAt
          invites {
            id
            owner {
              id
            }
          }
          applications {
            id
            owner {
              id
            }
          }
          ...VacancyFragments
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

export const LIST_OF_VACANCIES_FOR_DASHBOARD = graphql`
  query VacancyQueriesListOfVacanciesForDashboardQuery($orgId: ID, $after: Cursor, $first: Int, $skip: Boolean!, $filters: [FilterModel], $orderBy: VacancyOrder) {
    vacancies(orgId: $orgId, first: $first, after: $after, filters: $filters, orderBy: $orderBy) @skip(if: $skip) @connection(key: "VacancyList_vacancies") {
      edges {
        node {
          id
          status
          ...VacancyFragmentsForDashboardFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

export const LIST_OF_VACANCIES_FOR_ORGANIZATION_MEMBERSHIP = graphql`
  query VacancyQueriesListOfVacanciesForOrgMembershipQuery($orgId: ID, $after: Cursor, $first: Int, $skip: Boolean!, $filters: [FilterModel], $orderBy: VacancyOrder) {
    vacancies(orgId: $orgId, first: $first, after: $after, filters: $filters, orderBy: $orderBy)
      @skip(if: $skip)
      @connection(key: "VacancyListForOrganizationMembership_vacancies") {
      edges {
        node {
          id
          title
          status
          organizationMember {
            id
            profile {
              info {
                id
                lastName
                firstName
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_VACANCY = graphql`
  query VacancyQueriesGetVacancyQuery($id: ID!, $skip: Boolean!) {
    node(id: $id) @skip(if: $skip) {
      ...VacancyFragments
    }
  }
`;

export const GET_HIRING_AREA_LIST = graphql`
  query VacancyQueriesGetHiringAreaListQuery($skip: Boolean!) {
    hiringAreas @skip(if: $skip) {
      edges {
        id
        name
        perma
      }
    }
  }
`;

export const GET_LIST_OF_CURRENCIES = graphql`
  query VacancyQueriesGetListOfCurrenciesQuery {
    currencies {
      edges {
        id
        name
        perma
      }
    }
  }
`;

export const GET_LIST_OF_VACANCIES_BY_IDS = graphql`
  query VacancyQueriesGetListOfVacanciesByIdsQuery($ids: [ID!]!) {
    nodes(ids: $ids) {
      id
      ...VacancyFragmentsForSearchFragment
    }
  }
`;

export const GET_LIST_OF_CONTACTS_FROM_VACANCY = graphql`
  query VacancyQueriesGetListOfContactsFromVacancyQuery($id: ID!, $skip: Boolean!) {
    node(id: $id) @skip(if: $skip) {
      ... on Vacancy {
        contacts {
          id
          owner {
            id
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_MEMBERS = graphql`
  query VacancyQueriesOrganizationMembersQuery($orgId: ID!, $after: Cursor, $first: Int, $skip: Boolean!) {
    organizationMembers(orgId: $orgId, after: $after, first: $first) @connection(key: "organizationMembersListForForm_organizationMembers") @skip(if: $skip) {
      edges {
        node {
          id
          role
          profile {
            info {
              firstName
              lastName
            }
          }
          organization {
            id
            name
          }
        }
      }
    }
  }
`;
