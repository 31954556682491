// packages
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Skeleton from 'react-loading-skeleton';
// components
import ListOfReviewAppliedCrewItemSkeleton from './components/ListOfReviewAppliedCrewItemSkeleton';

const ListOfReviewAppliedCrewSkeleton = () => {
  const [emptyList] = useState<Array<string>>(new Array(6).fill('').map(() => uuidv4()));

  return (
    <div className="flex flex-col">
      <div className="flex justify-between mb-8">
        <div className="flex space-x-8 items-center">
          <Skeleton width={250} height={24} />
          <Skeleton width={50} />
        </div>
        <Skeleton width={112} height={56} borderRadius={12} />
      </div>
      <div className="flex flex-col space-y-4">
        {emptyList.map(item => (
          <ListOfReviewAppliedCrewItemSkeleton key={item} />
        ))}
      </div>
    </div>
  );
};

export default ListOfReviewAppliedCrewSkeleton;
