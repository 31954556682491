import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
// hooks
import { useGetOrganizationSize } from 'hooks/useGetOrganizationSize';
// components
import WebIcon from 'icons/WebIcon';
import { EmptyCompanyIcon } from 'icons/EmptyCompanyIcon';
import OrganizationInfoSizeIcon from 'icons/OrganizationInfoSizeIcon';
import CommercialAvatar from 'app/Avatars/CommercialAvatar/CommercialAvatar';
// generated
import { VacancyFragments$data } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
// routing
import { RoutesPathNames } from 'app/routing';

const SecondaryInformation = ({ vacancy }: { vacancy: VacancyFragments$data }) => {
  const intl = useIntl();
  const { selectedOrganizationSize } = useGetOrganizationSize(vacancy.organization.size || null);

  return (
    <section className="flex w-full flex-col text-darkBlue gap-y-[18px]">
      <div className="flex gap-x-4 items-center">
        <CommercialAvatar size="w-[46px] h-[46px]" avatarUrl={vacancy.organization.avatarUrl} />
        <Link className="font-medium text-lg" to={`/${RoutesPathNames.orgInfo}/${vacancy.organization.id}`}>
          {vacancy.organization.name}
        </Link>
      </div>
      <div className="flex gap-x-6 text-sm">
        <div className="flex items-center gap-x-1.5">
          <div className="flex w-[18px]">
            <EmptyCompanyIcon />
          </div>
          {intl.formatMessage({ id: 'service_form_vessel_operator_name' })}
        </div>

        {selectedOrganizationSize?.name ? (
          <div className="flex gap-x-1.5 items-center">
            <OrganizationInfoSizeIcon />
            <span>
              {selectedOrganizationSize.name}&nbsp;
              <span className="lowercase">{intl.formatMessage({ id: 'sea_service_workers' })}</span>
            </span>
          </div>
        ) : null}

        {vacancy.organization.websiteUrl ? (
          <div className="flex gap-x-1.5 items-center text-blue">
            <WebIcon />
            <a href={vacancy.organization.websiteUrl} target="_blank" rel="noreferrer">
              {vacancy.organization.websiteUrl}
            </a>
          </div>
        ) : null}
      </div>

      <div className="flex flex-col text-sm">
        <span className="uppercase mb-3 text-xs">{intl.formatMessage({ id: 'about' })}</span>
        <p>{vacancy.organization.description}</p>
      </div>
    </section>
  );
};

export default SecondaryInformation;
