// packages
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
// components
import getEducationIcon from 'icons/EducationIcons';
import { EducationItemProps } from 'app/Profile/Education/types';
// schemas
import { EDUCATION_FRAGMENT } from 'schemas/educations/EducationFragments';
// generated
import { EducationFragments$key } from 'schemas/educations/__generated__/EducationFragments.graphql';

const ListEducationItem = ({ education, openUpdateModal, disabled, removeFunction }: EducationItemProps) => {
  const intl = useIntl();
  const dataOfEducation = useFragment<EducationFragments$key>(EDUCATION_FRAGMENT, education);

  return (
    <li className="flex flex-col sm:flex-row items-left sm:items-center border border-t-0 bg-white last:rounded-2xl first:rounded-2xl first:rounded-b-none first:border-t last:rounded-t-none text-darkBlue p-4 gap-y-2">
      <div className="flex justify-between text-sm text-specialGray-075">
        <div className="flex gap-x-1 flex-wrap">
          {format(new Date(`${dataOfEducation.start}`), 'dd.MM.yyyy')}
          <span>&#8208;</span>
          {dataOfEducation?.end ? format(new Date(`${dataOfEducation.end}`), 'dd.MM.yyyy') : intl.formatMessage({ id: 'education_item_present_end' })}
        </div>
        <div className="xs:text-left text-right w-min xs:w-auto">{dataOfEducation?.kind?.name}</div>
      </div>
      <div className="flex items-center gap-x-2">
        <div className="w-8 h-8">{dataOfEducation.kind?.name && getEducationIcon(dataOfEducation.kind.perma)}</div>
        <span className="text-specialGray-1 font-medium">{dataOfEducation.name}</span>
      </div>
      <div className="flex ml-auto gap-x-2.5">
        <span className="flex justify-center items-center w-10 h-8 border border-solid border-grayIcon rounded-[10px]">
          <PencilIcon aria-disabled={disabled} className="w-5 h-5 text-grayIcon cursor-pointer" onClick={() => openUpdateModal(dataOfEducation)} />
        </span>
        <span className="flex justify-center items-center w-10 h-8 border border-solid border-grayIcon rounded-[10px]">
          <TrashIcon aria-disabled={disabled} className="w-5 h-5 text-grayIcon cursor-pointer" onClick={() => removeFunction(dataOfEducation.id)} />
        </span>
      </div>
    </li>
  );
};

export default ListEducationItem;
