import graphql from 'babel-plugin-relay/macro';

export const GET_ONE_COUNTRY = graphql`
  query CountryQueriesOneCountryQuery($id: ID!, $skip: Boolean!) {
    node(id: $id) @skip(if: $skip) {
      ... on Country {
        id
        isoCode
        name
      }
    }
  }
`;

export const GET_PROFILE_COUNTRIES_OF_RESIDENCE = graphql`
  query CountryQueriesCountriesOfResidenceQuery($skip: Boolean!) {
    countries @skip(if: $skip) {
      edges {
        id
        isoCode
        name
      }
    }
  }
`;
