import { FC } from 'react';

const CakeIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_8283_75075" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_8283_75075)">
        <path
          d="M5 13V10C5 9.45 5.196 8.979 5.588 8.587C5.97933 8.19567 6.45 8 7 8H11V6.55C10.7 6.35 10.4583 6.10833 10.275 5.825C10.0917 5.54167 10 5.2 10 4.8C10 4.55 10.05 4.304 10.15 4.062C10.25 3.82067 10.4 3.6 10.6 3.4L12 2L13.4 3.4C13.6 3.6 13.75 3.82067 13.85 4.062C13.95 4.304 14 4.55 14 4.8C14 5.2 13.9083 5.54167 13.725 5.825C13.5417 6.10833 13.3 6.35 13 6.55V8H17C17.55 8 18.021 8.19567 18.413 8.587C18.8043 8.979 19 9.45 19 10V13H5ZM4 22C3.71667 22 3.47933 21.904 3.288 21.712C3.096 21.5207 3 21.2833 3 21V17C3 16.45 3.19567 15.9793 3.587 15.588C3.979 15.196 4.45 15 5 15H19C19.55 15 20.021 15.196 20.413 15.588C20.8043 15.9793 21 16.45 21 17V21C21 21.2833 20.904 21.5207 20.712 21.712C20.5207 21.904 20.2833 22 20 22H4Z"
          fill="#082051"
          fillOpacity="0.5"
        />
      </g>
    </svg>
  );
};

export default CakeIcon;
