// packages
import { useSearchParams } from 'react-router-dom';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { ProfileFragments_info$data, ProfileFragments_info$key } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
// generated
import { ProfileQueriesProfileShareQuery } from 'schemas/profile/__generated__/ProfileQueriesProfileShareQuery.graphql';
import { ProfileFragmentsCrewProfile$data, ProfileFragmentsCrewProfile$key } from 'schemas/profile/__generated__/ProfileFragmentsCrewProfile.graphql';
// components
import { ProfileTabs } from '../Profile/ProfileTabs';
import ProfileCrewInfo from '../ProfileCrew/components/ProfileCrewInfo';
// schemas
import { GET_PROFILE_SHARE } from 'schemas/profile/ProfileQueries';
import { CREW_PROFILE_FRAGMENT, PROFILE_INFO_FRAGMENT } from 'schemas/profile/ProfileFragments';

const ProfileShareFull = () => {
  const [searchParams] = useSearchParams();

  const profileShare = useLazyLoadQuery<ProfileQueriesProfileShareQuery>(GET_PROFILE_SHARE, { uuid: searchParams.get('t') || '' });
  const profile = useFragment<ProfileFragmentsCrewProfile$key>(CREW_PROFILE_FRAGMENT, profileShare.profileShare?.owner as ProfileFragmentsCrewProfile$key);
  const profileInfoData = useFragment<ProfileFragments_info$key>(PROFILE_INFO_FRAGMENT, profile?.info || null);

  return (
    <main className="w-full max-w-7xl">
      <ProfileCrewInfo profile={profile as ProfileFragmentsCrewProfile$data} fullShareProfile />
      <ProfileTabs profileInfoData={profileInfoData as ProfileFragments_info$data} />
    </main>
  );
};

export default ProfileShareFull;
