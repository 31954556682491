import { useEffect, useMemo, useState } from 'react';
import { intervalToDuration } from 'date-fns';
import { sumBy } from 'lodash';
import { ProfileSeaService } from 'models/IProfiles';

export const useConvertToCorrectServiceDate = (services: ReadonlyArray<ProfileSeaService> | null) => {
  const [days, setDays] = useState<number>(0);
  const [months, setMonths] = useState<number>(0);
  const [years, setYears] = useState<number>(0);

  const jobExperience = useMemo(() => {
    return services?.map(service => {
      if (service.end) {
        return intervalToDuration({ start: new Date(`${service.start}`), end: new Date(`${service.end}`) });
      } else {
        return intervalToDuration({ start: new Date(`${service.start}`), end: new Date() });
      }
    });
    // The next line disabled because if the services are added in the useEffect dependencies, they call the recalculation of days and months
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDays(prevState => prevState + (sumBy(jobExperience, 'days') % 30));
    setMonths(prevState => prevState + (sumBy(jobExperience, 'months') % 12));
    setYears(sumBy(jobExperience, 'years'));
  }, [jobExperience]);

  useEffect(() => {
    setMonths(prevState => prevState + Math.trunc(sumBy(jobExperience, 'days') / 30));
  }, [jobExperience]);

  useEffect(() => {
    if (sumBy(jobExperience, 'months') > 12) {
      setYears(prevState => prevState + Math.trunc(sumBy(jobExperience, 'months') / 12));
    }
  }, [jobExperience]);

  return { convertDays: days, convertMonths: months, convertYears: years };
};
