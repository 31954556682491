/**
 * @generated SignedSource<<6ff665ba90c0a725a0907e444d020ecd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProfileQueriesProfileNodeQuery$variables = {
  id: string;
  skip: boolean;
};
export type ProfileQueriesProfileNodeQuery$data = {
  readonly node?: {
    readonly certificateInfos?: ReadonlyArray<{
      readonly certificate: {
        readonly id: string;
        readonly kind: {
          readonly id: string;
          readonly name: string;
          readonly perma: string;
        } | null;
        readonly name: string;
      };
      readonly id: string;
    }> | null;
    readonly id?: string;
    readonly info?: {
      readonly firstName: string;
      readonly id: string;
      readonly lastName: string;
    } | null;
  } | null;
};
export type ProfileQueriesProfileNodeQuery = {
  response: ProfileQueriesProfileNodeQuery$data;
  variables: ProfileQueriesProfileNodeQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'skip',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      concreteType: 'ProfileInfo',
      kind: 'LinkedField',
      name: 'info',
      plural: false,
      selections: [
        v2 /*: any*/,
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'firstName',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'lastName',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      concreteType: 'CertificateInfo',
      kind: 'LinkedField',
      name: 'certificateInfos',
      plural: true,
      selections: [
        v2 /*: any*/,
        {
          alias: null,
          args: null,
          concreteType: 'Certificate',
          kind: 'LinkedField',
          name: 'certificate',
          plural: false,
          selections: [
            v2 /*: any*/,
            v4 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'CertificateKind',
              kind: 'LinkedField',
              name: 'kind',
              plural: false,
              selections: [
                v2 /*: any*/,
                v4 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'perma',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileQueriesProfileNodeQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: null,
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                {
                  kind: 'InlineFragment',
                  selections: [v2 /*: any*/, v3 /*: any*/, v5 /*: any*/],
                  type: 'Profile',
                  abstractKey: null,
                },
              ],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileQueriesProfileNodeQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: null,
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: '__typename',
                  storageKey: null,
                },
                v2 /*: any*/,
                {
                  kind: 'InlineFragment',
                  selections: [v3 /*: any*/, v5 /*: any*/],
                  type: 'Profile',
                  abstractKey: null,
                },
              ],
              storageKey: null,
            },
          ],
        },
      ],
    },
    params: {
      cacheID: 'a700e3353530e95557cb7ba1871e24d6',
      id: null,
      metadata: {},
      name: 'ProfileQueriesProfileNodeQuery',
      operationKind: 'query',
      text: 'query ProfileQueriesProfileNodeQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on Profile {\n      id\n      info {\n        id\n        firstName\n        lastName\n      }\n      certificateInfos {\n        id\n        certificate {\n          id\n          name\n          kind {\n            id\n            name\n            perma\n          }\n        }\n      }\n    }\n    id\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'bbda7a782ef15cb760f0987dbcaac4a3';

export default node;
