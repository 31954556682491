const NoJobOpening = () => {
  return (
    <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_7741_70204" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="51" height="50">
        <rect x="0.5" width="50" height="50" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7741_70204)">
        <path
          d="M8.83329 43.7493C7.68746 43.7493 6.7069 43.3417 5.89163 42.5264C5.07496 41.7098 4.66663 40.7285 4.66663 39.5827V16.666C4.66663 15.5202 5.07496 14.5396 5.89163 13.7243C6.7069 12.9077 7.68746 12.4993 8.83329 12.4993H17.1666V8.33268C17.1666 7.18685 17.575 6.2056 18.3916 5.38893C19.2069 4.57365 20.1875 4.16602 21.3333 4.16602H29.6666C30.8125 4.16602 31.7937 4.57365 32.6104 5.38893C33.4257 6.2056 33.8333 7.18685 33.8333 8.33268V12.4993H42.1666C43.3125 12.4993 44.2937 12.9077 45.1104 13.7243C45.9257 14.5396 46.3333 15.5202 46.3333 16.666V25.5723C45.7083 25.1209 45.0486 24.7299 44.3541 24.3993C43.6597 24.0702 42.9305 23.7841 42.1666 23.541V16.666H8.83329V39.5827H23.5729C23.677 40.3118 23.8333 41.0237 24.0416 41.7181C24.25 42.4125 24.5104 43.0896 24.8229 43.7493H8.83329ZM21.3333 12.4993H29.6666V8.33268H21.3333V12.4993Z"
          fill="#082051"
          fillOpacity="0.3"
        />
        <path
          d="M45.79 47.1603L41.2978 42.6597C40.6651 43.0213 39.9871 43.3226 39.264 43.5636C38.5408 43.8047 37.7725 43.9252 36.9589 43.9252C34.6991 43.9252 32.7779 43.1345 31.1954 41.5533C29.6141 39.9708 28.8235 38.0496 28.8235 35.7897C28.8235 33.5299 29.6141 31.6087 31.1954 30.0262C32.7779 28.4449 34.6991 27.6543 36.9589 27.6543C39.2188 27.6543 41.14 28.4449 42.7224 30.0262C44.3037 31.6087 45.0944 33.5299 45.0944 35.7897C45.0944 36.6033 44.9739 37.3716 44.7328 38.0948C44.4918 38.8179 44.1904 39.4959 43.8289 40.1286L48.3211 44.6293L45.79 47.1603ZM36.9589 40.3094C38.2244 40.3094 39.2941 39.8725 40.1679 38.9987C41.0417 38.1249 41.4786 37.0553 41.4786 35.7897C41.4786 34.5242 41.0417 33.4546 40.1679 32.5808C39.2941 31.707 38.2244 31.2701 36.9589 31.2701C35.6934 31.2701 34.6238 31.707 33.75 32.5808C32.8761 33.4546 32.4392 34.5242 32.4392 35.7897C32.4392 37.0553 32.8761 38.1249 33.75 38.9987C34.6238 39.8725 35.6934 40.3094 36.9589 40.3094Z"
          fill="#082051"
          fillOpacity="0.3"
        />
      </g>
    </svg>
  );
};

export default NoJobOpening;
