import { useMemo } from 'react';
import { useLazyLoadQuery } from 'react-relay';
// generated
import { ServiceQueriesFormGetAllDutiesQuery } from 'schemas/services/__generated__/ServiceQueriesFormGetAllDutiesQuery.graphql';
// schemas
import { GET_ALL_DUTIES_QUERY } from 'schemas/services/ServiceQueries';

const useGetPrimaryDuty = (primaryDutyId: string | null) => {
  const allDuties = useLazyLoadQuery<ServiceQueriesFormGetAllDutiesQuery>(GET_ALL_DUTIES_QUERY, { skip: false });

  return useMemo(() => {
    return allDuties.duties?.edges?.find(duty => duty.id === primaryDutyId);
  }, [allDuties.duties?.edges, primaryDutyId]);
};

export default useGetPrimaryDuty;
