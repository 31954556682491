// packages
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
// components
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';
// hooks
import { useGetWidthVerticalListOfItems } from 'hooks/useGetWidthVerticalListOfItems';

const ListWithShowMoreItemsWithPopover = <T extends { id: string }>({ list, field }: { list: ReadonlyArray<T> | null; field: keyof T }) => {
  const listContainerRef: RefObject<HTMLElement> = useRef<HTMLElement>(null);
  const [widthContainer, setWidthContainer] = useState<number>(0);

  const { arrayWithDiffer, arrayOfItemsForRendering, addToRefs } = useGetWidthVerticalListOfItems<T>(listContainerRef, widthContainer, list);

  useEffect(() => {
    // 72px - this is the size of the avatar + the margin between the avatar and the title
    setWidthContainer(listContainerRef.current?.parentElement?.offsetWidth as number);
  }, []);

  const renderPopoverWithRestElement = useCallback((count: number) => <div className="flex min-h-[28px] px-3 rounded-lg bg-specialGray-012 items-center">&#43;{count}</div>, []);
  const renderPopoverListItem = useCallback(
    (itemName: string) => (
      <span className="bg-specialGray-01 text-center font-medium min-w-20 max-w-[147px] overflow-hidden truncate text-sm px-3 rounded-lg text-specialGray-075 py-1 mr-2">
        {itemName}
      </span>
    ),
    [],
  );

  return (
    <section ref={listContainerRef} className="flex text-specialGray-075 text-xs" style={{ width: `${widthContainer}px` }}>
      {!isEmpty(arrayOfItemsForRendering) ? (
        <>
          {arrayOfItemsForRendering?.map(item =>
            item ? (
              <div key={item.id} ref={addToRefs} className="text-sm">
                <PopoverHelper
                  widthContainer="w-72"
                  classes="flex items-center"
                  element={renderPopoverListItem(item[field] as unknown as string)}
                  position="bottom-10 sm:left-1/2 sm:-translate-x-1/2 -left-4"
                  positionArrow="sm:left-1/2 left-6 rotate-45 -bottom-[3px]"
                  darkTheme
                >
                  <>{item[field]}</>
                </PopoverHelper>
              </div>
            ) : null,
          )}
        </>
      ) : null}

      {!isEmpty(arrayWithDiffer) ? (
        <PopoverHelper
          widthContainer="w-fit"
          classes="flex items-center"
          element={renderPopoverWithRestElement(arrayWithDiffer?.length as number)}
          position="bottom-10 sm:left-1/2 sm:-translate-x-1/2 -left-4"
          positionArrow="sm:left-1/2 left-6 rotate-45 -bottom-[3px]"
          darkTheme
        >
          <div className="flex flex-col space-y-2 items-start">
            {arrayWithDiffer?.map(item => (
              <span key={item.id} className="whitespace-nowrap">
                <>{item[field]}</>
              </span>
            ))}
          </div>
        </PopoverHelper>
      ) : null}
    </section>
  );
};

export default ListWithShowMoreItemsWithPopover;
