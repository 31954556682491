import Skeleton from 'react-loading-skeleton';
import OverviewProfileCrewEVTitleSkeleton from './OverviewProfileCrewEVTitleSkeleton';

const EducationCrewEvSkeletonComponent = () => {
  const contentWidth = [100, 150, 120, 170];
  return (
    <section className="flex w-full flex-col space-y-6">
      <OverviewProfileCrewEVTitleSkeleton />
      <div className="flex flex-col md:flex-row justify-between flex-wrap">
        {contentWidth.map((contentWidth, i) => {
          return (
            <div key={i} className="flex w-full h-[86px] pl-[15px] pr-[25px] py-[15px] rounded-lg bg-white border items-center text-sm lg:w-[49%] mb-6">
              <Skeleton className="mr-6" width={56} height={56} borderRadius={10} />

              <div className="flex flex-col pr-2">
                <Skeleton containerClassName="mb-1 h-5" className="mr-1" width={56} height={14} />
                <Skeleton containerClassName="h-5" className="mr-1" width={contentWidth} height={14} />
              </div>

              <div className="flex ml-auto">
                <Skeleton className="mr-2.5" width={80} height={14} />
                <Skeleton width={50} height={14} />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default EducationCrewEvSkeletonComponent;
