import { useIntl } from 'react-intl';
import { useCallback } from 'react';
// hooks
import { useGenerateResume } from 'hooks/useGenerateResume';
//components
import SpinnerIcon from 'icons/SpinnerIcon';
import SimCardDownload from 'icons/SimCardDownload';
import CustomButton from 'system/Buttons/CustomButton';
//types
import { EmployerViewDownloadCvProps } from 'app/ProfileCrew/type/ProfileCrewTypes';

const EmployerViewDownloadCv = ({ profileData }: EmployerViewDownloadCvProps) => {
  const intl = useIntl();
  const { generateResume, loading } = useGenerateResume(profileData.id);

  const handleGenerateResume = useCallback(() => {
    generateResume();
    window.analytics?.track('wui-download-crew-cv');
  }, [generateResume]);

  return (
    <CustomButton
      disabled={loading}
      classNameStyle="h-10 rounded-[10px] border text-sm border-specialGray-05 min-w-[160px] hover:bg-gray-50 bg-white"
      onClick={handleGenerateResume}
    >
      <div className="flex w-full justify-center items-center font-medium [&>svg]:text-xl [&>svg]:text-specialGray-05">
        {loading ? <SpinnerIcon additionalClasses="w-5 h-5" /> : <SimCardDownload />}
        <span className="ml-2 text-specialGray-075">{intl.formatMessage({ id: 'downloadCV_buttonLabel_profile_view' })}</span>
      </div>
    </CustomButton>
  );
};

export default EmployerViewDownloadCv;
