import { Component, ErrorInfo } from 'react';
import * as Sentry from '@sentry/react';
//utils
import { getRequestErrorIdFromMessage } from 'utils';
//models
import { ErrorBoundaryProps, ErrorBoundaryState } from 'models/modelsOfComponents';
//components
import ErrorBoundaryMessage from './components/ErrorBoundaryMessage';
class ErrorBoundaryForModal extends Component<
  ErrorBoundaryProps & {
    storybookError?: Error;
  },
  ErrorBoundaryState
> {
  public state: ErrorBoundaryState = {
    error: null,
    hasError: false,
    errorId: '',
  };

  public static getDerivedStateFromError(err: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: err, errorId: getRequestErrorIdFromMessage(err) };
  }
  componentDidMount() {
    if (this.props.storybookError) {
      this.setState({ hasError: true, errorId: getRequestErrorIdFromMessage(this.props.storybookError), error: this.props.storybookError });
    }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.warn('Uncaught error:', error, errorInfo);
    if (error) {
      Sentry.captureException(error);
    }
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorBoundaryMessage error={this.state.error} errorId={this.state.errorId} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundaryForModal;
