// packages
import React, { useEffect, useMemo, useRef } from 'react';
import { useResolvedPath, useMatch } from 'react-router-dom';
// models
import { CustomNavLinkProps } from 'models/modelsOfComponents';

const CustomNavLink: React.FC<CustomNavLinkProps> = ({ children, to, className, PrefixIcon, count, matchByEndOfPath = true, ...props }) => {
  const resolved = useResolvedPath(to);

  const match = useMatch({ path: resolved.pathname, end: matchByEndOfPath });

  const refButton = useRef<HTMLButtonElement>(null);

  const matchedStyles = useMemo<string>(
    () => (match ? 'border-blue text-blue focus:border-blue' : 'text-specialGray-075 hover:text-darkBlue hover:border-specialGray-012'),
    [match],
  );

  useEffect(() => {
    if (match) {
      refButton.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [match]);

  return (
    <button ref={refButton} className={`${className} ${matchedStyles} hover:text-darkBlue border-b-2 text-sm border-transparent font-medium flex`} {...props}>
      {match && PrefixIcon && (
        <div className="mr-2">
          <PrefixIcon />
        </div>
      )}
      {children}
      {count && <span className="opacity-60 text-current ml-2">{count}</span>}
    </button>
  );
};

export default CustomNavLink;
