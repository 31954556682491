// packages
import { useIntl } from 'react-intl';
// components
import { Age } from 'app/Profile/Age';
import CountryInfo from 'app/ProfileSearch/components/ProfileListItem/components/CountryInfo';
import InfoPrimaryDuty from 'app/ProfileSearch/components/ProfileListItem/components/InfoPrimaryDuty';
// generated
import { ProfileFragments_info$data } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
// hooks
import { useGetYearsOfExperience } from 'hooks/crewProfileHooks/useGetYearsOfExperience';

const ProfileSlideInfo = ({ profileInfo }: { profileInfo: ProfileFragments_info$data }) => {
  const intl = useIntl();
  const { getYearsOfExperience } = useGetYearsOfExperience(profileInfo?.yearsOfExperience);

  return (
    <div className="flex flex-col mt-4">
      {profileInfo?.bio && (
        <div className="flex w-full flex-col mb-6">
          <p className="uppercase text-specialGray-075 font-semibold mb-1 mt-2 text-xs relative top-0.5">{intl.formatMessage({ id: 'profile_about_me' })}</p>
          <span className="font-normal text-sm mt-1 leading-6 text-specialGray-1 whitespace-pre-wrap">{profileInfo.bio}</span>
        </div>
      )}
      <div className="flex w-full">
        <p className="uppercase text-specialGray-075 font-semibold mb-7 w-1/2 text-xs">{intl.formatMessage({ id: 'profile_age' })}</p>
        {profileInfo?.birthDate && <Age birthday={profileInfo?.birthDate as string} classes="text-sm leading-6 text-specialGray-1" />}
      </div>
      {profileInfo?.primaryDuty && (
        <div className="flex w-full">
          <p className="uppercase text-specialGray-075 font-semibold mb-7 w-1/2 text-xs">{intl.formatMessage({ id: 'profile_position' })}</p>
          <InfoPrimaryDuty primaryDutyFragment={profileInfo?.primaryDuty} classes="text-sm leading-6 text-specialGray-1" />
        </div>
      )}
      {profileInfo?.country && (
        <div className="flex w-full">
          <p className="uppercase text-specialGray-075 font-semibold mb-8 w-1/2 text-xs">{intl.formatMessage({ id: 'profile_location' })}</p>
          <CountryInfo countryFragment={profileInfo?.country} classes="text-sm leading-6 text-specialGray-1" />
        </div>
      )}
      {getYearsOfExperience && (
        <div className="flex w-full">
          <p className="uppercase text-specialGray-075 font-semibold mb-8 w-1/2 text-xs">{intl.formatMessage({ id: 'profile_years_of_work_experience' })}</p>
          <span className="font-normal text-sm leading-6 text-specialGray-1 whitespace-pre-wrap">{getYearsOfExperience.name}</span>
        </div>
      )}
    </div>
  );
};

export default ProfileSlideInfo;
