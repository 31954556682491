// packages
import { isEmpty } from 'lodash';
import { useLazyLoadQuery } from 'react-relay';
import { useParams } from 'react-router-dom';
// routing
import { RoutePaths } from 'app/routing';
// models
import { VACANCY_DESCRIPTION_TYPE_OF_LIST, VACANCY_STATUS } from 'models/enums';
// generated
import { InviteFragments$key } from 'schemas/Invite/__generated__/InviteFragments.graphql';
import { InviteQueriesGetListOfInvitedCrewQuery } from 'schemas/Invite/__generated__/InviteQueriesGetListOfInvitedCrewQuery.graphql';
// schemas
import { GET_LIST_OF_INVITED_CREW } from 'schemas/Invite/InviteQueries';
// components
import BigInvitationIcon from 'icons/BigInvitationIcon';
import RightSideEmptyMessage from './RightSideEmptyMessage';
import RightSideCommonListOfCrew from './RightSideCommonListOfCrew';
import RightSideInviteItemListOfCrew from './RightSideInviteItemListOfCrew';
//types
import { RightSideListOfCrewItemProps } from '../types';

const RightSideListOfInvitedCrew = ({ vacancy, handleSwitchStatus, isCommitSwitchVacancyLoading }: RightSideListOfCrewItemProps) => {
  const { jobDescriptionEditId } = useParams();

  const invitedCrews = useLazyLoadQuery<InviteQueriesGetListOfInvitedCrewQuery>(
    GET_LIST_OF_INVITED_CREW,
    { vacancyId: jobDescriptionEditId!, skip: !jobDescriptionEditId },
    { fetchPolicy: 'store-and-network' },
  );

  const emptyMessageDescription = vacancy.status === VACANCY_STATUS.OPEN ? 'hire_job_description_no_invited_user_description' : 'hire_job_description_closed_job_description';

  return (
    <RightSideCommonListOfCrew
      vacancy={vacancy}
      title={{ id: 'hire_job_description_invited_crew_title' }}
      seeAllLink={RoutePaths.JobInvitedCrew}
      typeOfList={VACANCY_DESCRIPTION_TYPE_OF_LIST.INVITED}
      amountOfUsers={invitedCrews?.invites?.edges?.length ?? 0}
    >
      {!isEmpty(invitedCrews?.invites?.edges) ? (
        invitedCrews?.invites?.edges?.map((item, index) =>
          index < 3 ? <RightSideInviteItemListOfCrew key={item?.node?.id} inviteFragment={item?.node as InviteFragments$key} /> : null,
        )
      ) : (
        <RightSideEmptyMessage
          isCommitSwitchVacancyLoading={isCommitSwitchVacancyLoading}
          handleSwitchStatus={handleSwitchStatus}
          vacancy={vacancy}
          title={{ id: 'hire_job_description_no_invited_user_label' }}
          description={{ id: emptyMessageDescription }}
          typeOfList={VACANCY_DESCRIPTION_TYPE_OF_LIST.INVITED}
        >
          <BigInvitationIcon />
        </RightSideEmptyMessage>
      )}
    </RightSideCommonListOfCrew>
  );
};

export default RightSideListOfInvitedCrew;
