import graphql from 'babel-plugin-relay/macro';

export const CREATE_PROFILE_INFO_MUTATION = graphql`
  mutation BasicInfoFormMutationsCreateMutation($data: ProfileInfoData!) {
    createProfileInfo(data: $data) {
      id
      firstName
      lastName
      bio
      birthDate
      avatarUrl
      primaryDuty {
        id
        name
        ...ServiceFragmentsOneDuty
      }
      owner {
        id
        info {
          firstName
        }
      }
      country {
        ...CountryFragments_info
      }
    }
  }
`;

export const UPDATE_PROFILE_INFO_MUTATION = graphql`
  mutation BasicInfoFormMutationsUpdateMutation($id: ID!, $data: ProfileInfoData!) {
    updateProfileInfo(id: $id, data: $data) {
      id
      firstName
      lastName
      bio
      birthDate
      avatarUrl
      primaryDuty {
        id
        name
        ...ServiceFragmentsOneDuty
      }
      owner {
        id
        info {
          firstName
        }
      }
      country {
        ...CountryFragments_info
      }
      yearsOfExperience
    }
  }
`;
