import { SearchProfileNode } from 'models/IProfiles';
import { ProfileAvatar } from 'app/Avatars/ProfileAvatar/ProfileAvatar';
// hooks
import useGetPrimaryDuty from 'hooks/SearchNodeProfileHooks/useGetPrimaryDuty';
import useGetRestrictedProfileLastName from 'hooks/ProfileDataHooks/useGetRestrictedProfileLastName';

const SearchNodeProfileItem = ({ profile }: { profile: SearchProfileNode }) => {
  const primaryDuty = useGetPrimaryDuty(profile?.primaryDuty);
  const lastName = useGetRestrictedProfileLastName(profile?.lastName);
  return (
    <>
      <ProfileAvatar size="w-10 h-10 sm:w-14 sm:h-14" avatarUrl={profile.avatarUrl} />
      <section className="flex flex-col w-full py-1 truncate">
        <span className="text-specialGray-1 text-sm sm:text-base truncate font-medium">{`${profile?.firstName} ${lastName}`}</span>
        <span className="text-specialGray-075 font-normal flex-wrap text-xs sm:text-sm">{primaryDuty?.name}</span>
      </section>
    </>
  );
};

export default SearchNodeProfileItem;
