import { useIntl } from 'react-intl';
// types
import { SlideProfileInfoFieldWithIconProps } from '../types';

const SlideProfileInfoFieldWithIcon = ({ icon, description, children }: SlideProfileInfoFieldWithIconProps) => {
  const intl = useIntl();
  return (
    <div className="flex space-x-7">
      {icon}
      <div className="flex flex-col">
        <span className="text-darkBlue">{children}</span>
        <span className="text-specialGray-075 text-xs">{intl.formatMessage(description)}</span>
      </div>
    </div>
  );
};

export default SlideProfileInfoFieldWithIcon;
