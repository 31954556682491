// packages
import { FC } from 'react';
import { useIntl } from 'react-intl';
// models
import { PrimaryDutyComponentProps } from 'models/modelsOfComponents';

const PrimaryDutyComponent: FC<PrimaryDutyComponentProps> = ({
  profileDuty,
  classNameDescription,
  classNameTitle = 'uppercase font-semibold mb-3.5',
  classNameContainer = 'justify-between',
}) => {
  const intl = useIntl();

  return (
    <div className={`flex w-full ${classNameContainer}`}>
      <p className={`text-specialGray-075 ${classNameTitle}`}>{intl.formatMessage({ id: 'label_form_duty' })}</p>
      <span className={`text-darkBlue ${classNameDescription}`}>{profileDuty?.name}</span>
    </div>
  );
};

export default PrimaryDutyComponent;
