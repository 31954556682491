import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSkeletonContext } from 'Context/StorybookContext';
import { useBreakpoints } from 'hooks/useBreakpoints';

const TableWithCertificatesSkeleton: React.FC = () => {
  const { sm } = useBreakpoints();
  const { isSelfProfile } = useSkeletonContext();

  return (
    <section className="border border-specialGray-01 rounded-2xl mb-6">
      <table className="table-auto w-full mb-2">
        <thead className="hidden md:contents">
          <tr className="bg-specialGray-004 border-b px-4">
            <th className="p-2 px-4 w-2/6 rounded-tl-2xl text-left">
              <Skeleton width={40} />
            </th>
            <th className="p-2 px-4 w-2/6" />
            <th className="py-2 pl-4 w-15 text-left">{isSelfProfile && <Skeleton width={100} />}</th>
            <th className="py-2 w-10% text-left">
              <Skeleton width={70} />
            </th>
            <th className="p-2 px-4 w-min rounded-tr-2xl" />
          </tr>
        </thead>
        <tbody className="sm:rounded-lg">
          <tr className="h-14 w-full border-t border-specialGray-01">
            <td className="m-2 md:m-0 md:pl-4 flex md:block justify-center">
              <div className="overflow-hidden text-left mr-1 flex flex-row md:mt-2 mt-0">
                <Skeleton width={20} height={24} />
                <div className="ml-5 mt-auto">
                  <Skeleton width={sm ? 150 : 250} />
                </div>
              </div>
            </td>
            <td className="block md:table-cell text-specialGray-03 text-xs md:pl-12 md:pr-20 md:text-right text-center">{isSelfProfile && <Skeleton width={70} />}</td>
            <td className="flex md:table-cell justify-center">{isSelfProfile && <Skeleton width={24} height={24} className="sm:ml-4" />}</td>
            <td className="flex md:table-cell justify-center">
              {isSelfProfile ? (
                <Skeleton width={50} height={28} />
              ) : (
                <>
                  <Skeleton width={85} />
                  <Skeleton width={30} />
                </>
              )}
            </td>
            <td className="flex md:table-cell justify-center sm:text-center">{isSelfProfile && <Skeleton width={28} height={28} className="ml-auto" />}</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default TableWithCertificatesSkeleton;
