// packages
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSearchParams } from 'react-router-dom';
//mocks
import { tableCellWidth } from 'mocks/mockData';
import SortingArrows from './components/SortingArrows';
// enums
import { DASHBOARD_VACANCIES_FILTER_ENUM, ORDER_DIRECTION, VACANCY_ORDER_FIELD, VACANCY_STATUS } from 'models/enums';
// recoil
import { fetchDashboardVacanciesByParamsAtom } from 'recoil/Organisation/Dashboard/atoms/fetchDashboardVacanciesByParamsAtom';
import { fetchDashboardVacanciesByOpenParamsSelector } from 'recoil/Organisation/Dashboard/selectors/fetchDashboardVacanciesByOpenParamsSelector';
import { fetchDashboardVacanciesByShowAllParamsSelector } from 'recoil/Organisation/Dashboard/selectors/fetchDashboardVacanciesByShowAllParamsSelector';

const TableHeader = () => {
  const intl = useIntl();
  const { number, status, positionTitle, position, vesselType, salaryRange, industry, applied, postingDate, emptyTitle } = tableCellWidth;

  const [searchParams, setSearchParams] = useSearchParams();

  const setAllOpenedFilters = useSetRecoilState(fetchDashboardVacanciesByOpenParamsSelector);
  const setAllShowAllFilters = useSetRecoilState(fetchDashboardVacanciesByShowAllParamsSelector);

  const getFetchDashboardVacanciesByParams = useRecoilValue(fetchDashboardVacanciesByParamsAtom);
  const handleSortByOrder = useCallback(
    (fieldOrderBy: VACANCY_ORDER_FIELD) => () => {
      // We need to set search URL to lowercase (not uppercase)
      const fieldOrderLowercase = fieldOrderBy.toLowerCase();
      const isAscSearchData = searchParams.get(fieldOrderLowercase) === ORDER_DIRECTION.ASC.toLowerCase();
      const isCreatedAtExist = searchParams.get(VACANCY_ORDER_FIELD.CREATED_AT.toLowerCase());

      for (const [key] of searchParams) {
        searchParams.delete(key);
      }

      if (getFetchDashboardVacanciesByParams.filterByStatus === VACANCY_STATUS.OPEN && fieldOrderBy === VACANCY_ORDER_FIELD.CREATED_AT && !isCreatedAtExist) {
        searchParams.set(fieldOrderLowercase, ORDER_DIRECTION.DESC.toLowerCase());

        setAllOpenedFilters(prev => [
          ...prev,
          {
            orderBy: {
              field: fieldOrderBy,
              direction: ORDER_DIRECTION.DESC,
            },
          },
        ]);
      } else {
        const orderBy = {
          field: fieldOrderBy,
          direction: isAscSearchData ? ORDER_DIRECTION.DESC : ORDER_DIRECTION.ASC,
        };

        if (getFetchDashboardVacanciesByParams.filterByStatus === VACANCY_STATUS.OPEN) {
          setAllOpenedFilters(prev => [
            ...prev,
            {
              orderBy,
            },
          ]);
        } else {
          setAllShowAllFilters(prev => [
            ...prev,
            {
              orderBy,
            },
          ]);
        }
        searchParams.set(fieldOrderLowercase, isAscSearchData ? ORDER_DIRECTION.DESC.toLowerCase() : ORDER_DIRECTION.ASC.toLowerCase());
      }

      setSearchParams(searchParams);
    },
    [getFetchDashboardVacanciesByParams.filterByStatus, searchParams, setAllShowAllFilters, setAllOpenedFilters, setSearchParams],
  );

  return (
    <section className="flex rounded-2xl rounded-b-none px-3 py-3 border border-specialGray-012 border-b-0 md:border-b-1 bg-white md:bg-specialGray-004">
      <div className={`hidden md:flex ${number} pl-3 text-specialGray-075 font-medium text-xs uppercase`}>
        {intl.formatMessage({ id: 'hire_dashboard_table__header_order_number' })}
      </div>
      <div className={`hidden md:flex ${status} text-specialGray-075 font-medium text-xs uppercase`}>{intl.formatMessage({ id: 'hire_dashboard_table_header_status' })}</div>
      <div className={`hidden md:flex ${positionTitle} pl-4 text-specialGray-075 font-medium text-xs uppercase`}>
        {intl.formatMessage({ id: 'hire_information_position_title' })}
      </div>
      <div className={`hidden md:flex ${position} pl-4 text-specialGray-075 font-medium text-xs uppercase`}>{intl.formatMessage({ id: 'hire_information_position' })}</div>

      <div className={`hidden md:flex ${vesselType} pl-4 text-specialGray-075 font-medium text-xs uppercase`}>{intl.formatMessage({ id: 'hire_vessel_type_label' })}</div>
      <div className={`hidden md:flex ${salaryRange} text-specialGray-075 font-medium text-xs uppercase`}>{intl.formatMessage({ id: 'hire_information_salary_range' })}</div>
      <div className={`hidden md:flex ${industry} text-specialGray-075 font-medium text-xs uppercase`}>{intl.formatMessage({ id: 'hire_information_industry' })}</div>
      <div
        className={`hidden md:flex ${applied} justify-center text-specialGray-075 font-medium text-xs uppercase relative cursor-pointer`}
        onClick={handleSortByOrder(VACANCY_ORDER_FIELD.LAST_INVITED_AT)}
      >
        {intl.formatMessage({ id: 'hire_label_applied' })}
        <SortingArrows fieldOrderBy={VACANCY_ORDER_FIELD.LAST_INVITED_AT} tabWithDefaultDownArrow={VACANCY_STATUS.OPEN} />
      </div>
      <div
        className={`hidden md:flex ${postingDate} text-specialGray-075 font-medium text-xs uppercase justify-center relative cursor-pointer`}
        onClick={handleSortByOrder(VACANCY_ORDER_FIELD.CREATED_AT)}
      >
        {intl.formatMessage({ id: 'hire_information_posting_date' })}
        <SortingArrows fieldOrderBy={VACANCY_ORDER_FIELD.CREATED_AT} tabWithDefaultDownArrow={DASHBOARD_VACANCIES_FILTER_ENUM.SHOW_ALL} />
      </div>
      <div className={`hidden md:flex ${emptyTitle}`} />
    </section>
  );
};

export default TableHeader;
