import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { useLazyLoadQuery, useMutation } from 'react-relay';
//schemas
import { ADD_PROFILE_TO_HIRE_CREW_POOL, CREATE_NEW_POOL } from 'schemas/profile/ProfileMutations';
import { GET_CREW_POOLS, GET_SELECTED_POOLS_FOR_PROFILE } from 'schemas/profile/ProfileQueries';
//hooks
import { useGetHireProfile } from 'hooks/hireProfileHooks';
//generated
import { ProfileMutationsCreateNewPoolMutation } from 'schemas/profile/__generated__/ProfileMutationsCreateNewPoolMutation.graphql';
import { ProfileQueriesGetProfileCrewPoolsQuery } from 'schemas/profile/__generated__/ProfileQueriesGetProfileCrewPoolsQuery.graphql';
import { ProfileMutationsAddProfileToCrewPoolMutation } from 'schemas/profile/__generated__/ProfileMutationsAddProfileToCrewPoolMutation.graphql';
import { ProfileQueriesGetSelectedPoolsForProfileQuery } from 'schemas/profile/__generated__/ProfileQueriesGetSelectedPoolsForProfileQuery.graphql';
//updaters
import { addUserToPoolUpdater, crewNewCrewPoolUpdater } from 'formHelpers/updaters/updatersOfHireCrewPools';
//components
import SpinnerIcon from 'icons/SpinnerIcon';
import SearchThin from 'icons/SearchThin';
import { CheckIcon } from '@heroicons/react/24/solid';
import CustomButton from 'system/Buttons/CustomButton';
import AlertForError from 'system/Alert/AlertForError';
import { FolderIcon } from '@heroicons/react/24/outline';
import EmployerAddUserToCrewPoolItem from './EmployerAddUserToCrewPoolItem';
//types
import { EmployerAddUserToCrewPoolMainProps } from '../../../types';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';

const EmployerAddUserToCrewPoolMain = ({ userId, onHandleClose }: EmployerAddUserToCrewPoolMainProps) => {
  const intl = useIntl();
  const { organizationId } = useGetHireProfile();
  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);

  const [createNewPoolValue, setCreateNewPoolValue] = useState<string>('');
  const [selectedPools, setSelectedPools] = useState<string[]>([]);
  const [error, setError] = useState<Error>();

  const [isDisabledSaveButtonByValue, setIsDisabledSaveButtonByValue] = useState<boolean>(false);
  const [isDisabledSaveButtonByLoading, setIsDisabledSaveButtonByLoading] = useState<boolean>(false);
  const [filteredPoolsValue, setFilteredPoolsValue] = useState('');

  const data = useLazyLoadQuery<ProfileQueriesGetProfileCrewPoolsQuery>(GET_CREW_POOLS, { orgId: getCurrentOrganisation.organisationId ?? `${organizationId}` }).crewPools?.edges;

  const crewsSelectedPoolsData = useLazyLoadQuery<ProfileQueriesGetSelectedPoolsForProfileQuery>(GET_SELECTED_POOLS_FOR_PROFILE, {
    profileId: `${userId}`,
    orgId: getCurrentOrganisation.organisationId ?? `${organizationId}`,
    skip: !(organizationId || getCurrentOrganisation.organisationId),
  }).crewPoolsByProfile?.edges;

  const poolsDataWithSearchFilter = useMemo(() => {
    if (filteredPoolsValue) {
      return data?.filter(pool => pool?.node?.name.toLowerCase().includes(filteredPoolsValue.toLowerCase()));
    }

    return data;
  }, [data, filteredPoolsValue]);

  const getExistingPoolIds = useMemo(() => {
    const allExistingIds: string[] = [];
    crewsSelectedPoolsData?.forEach(pool => pool?.node?.id && allExistingIds.push(pool?.node?.id));
    return allExistingIds;
  }, [crewsSelectedPoolsData]);

  const [addUserToPool, loadingAddUserToCrewPool] = useMutation<ProfileMutationsAddProfileToCrewPoolMutation>(ADD_PROFILE_TO_HIRE_CREW_POOL);
  const [createPool, loadingPoolCreation] = useMutation<ProfileMutationsCreateNewPoolMutation>(CREATE_NEW_POOL);

  useEffect(() => {
    setIsDisabledSaveButtonByValue(!selectedPools.length && !createNewPoolValue);
    setIsDisabledSaveButtonByLoading(loadingAddUserToCrewPool || loadingPoolCreation);
  }, [loadingAddUserToCrewPool, loadingPoolCreation, selectedPools.length, createNewPoolValue]);

  const handleAddToPool = (selectedPools: string[], userId: string, onCloseFunction?: () => void) => {
    addUserToPool({
      variables: {
        poolId: selectedPools,
        profileIDs: [`${userId}`],
      },
      onError: setError,
      onCompleted: onCloseFunction,
      updater: addUserToPoolUpdater(userId),
    });
  };

  const handleCreatePool = () => {
    createPool({
      variables: {
        data: {
          name: createNewPoolValue,
          organizationId: getCurrentOrganisation.organisationId ?? `${organizationId}`,
          kind: 'REGULAR',
        },
      },
      updater: crewNewCrewPoolUpdater(getCurrentOrganisation.organisationId ?? `${organizationId}`),
      onError: setError,
      onCompleted: resp => {
        // add user to current created pool
        handleAddToPool([`${resp.createCrewPool.node?.id}`], `${userId}`, onHandleClose);
      },
    });
  };

  const handleAddUserToPool = () => {
    if (!userId || !(organizationId || getCurrentOrganisation.organisationId)) {
      return;
    }

    const isSelectedPoolWithNewPool = !!selectedPools.length && !!createNewPoolValue;
    const isNewPoolWithoutSelectedPool = !selectedPools.length && !!createNewPoolValue;
    const isSelectedPoolWithoutNewPool = !!selectedPools.length && !createNewPoolValue;

    if (isSelectedPoolWithNewPool) {
      handleAddToPool(selectedPools, userId);
      handleCreatePool();
    }
    if (isNewPoolWithoutSelectedPool) {
      handleCreatePool();
    }
    if (isSelectedPoolWithoutNewPool) {
      handleAddToPool(selectedPools, userId, onHandleClose);
    }
  };

  return (
    <div className="flex flex-col w-1/2">
      <div className="flex flex-col w-full gap-y-3">
        {error ? <AlertForError error={error} heading={{ id: 'error_boundary_text' }} /> : null}
        <h3 className="font-medium">{intl.formatMessage({ id: 'hire_create_new_crew_pool' })}</h3>
        <input
          placeholder={intl.formatMessage({ id: 'hire_create_new_crew_pool_placeholder' })}
          value={createNewPoolValue}
          onChange={e => setCreateNewPoolValue(e.target.value)}
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 border-specialGray-012 block w-full sm:text-sm rounded-[10px] h-12 placeholder:text-specialGray-05"
          type="text"
        />
      </div>

      <div className="flex flex-col w-full gap-y-4 mt-10">
        <div className="flex flex-col w-full gap-y-4 text-darkBlue">
          <h3 className="font-medium">{intl.formatMessage({ id: 'add_to_existing_one' })}</h3>
          <div className="relative flex w-full h-12 my-2">
            <SearchThin className="absolute w-6 h-6 top-1/2 transform -translate-x-1/2 -translate-y-1/2 left-6 text-specialGray-05" />
            <input
              value={filteredPoolsValue}
              placeholder={intl.formatMessage({ id: 'search_input_placeholder' })}
              onChange={e => setFilteredPoolsValue(e.target.value)}
              className="shadow-sm placeholder:text-specialGray-05 focus:ring-blue-500 focus:border-blue-500 border-specialGray-012 block w-full sm:text-sm rounded-[10px] mb-4 h-full pl-10"
              type="text"
            />
          </div>
          <div className="flex flex-col w-full gap-y-4 max-h-[200px] overflow-y-scroll pr-4 pb-10">
            {poolsDataWithSearchFilter?.length ? (
              poolsDataWithSearchFilter?.map(item => {
                const itemNodeId = item?.node?.id;
                if (!itemNodeId) {
                  return null;
                }
                const isSelectedPool = selectedPools.includes(itemNodeId);
                const isExistingPoolInProfile = getExistingPoolIds.includes(itemNodeId);
                return (
                  <CustomButton
                    key={item?.node?.id}
                    data-test-id={item.node.name}
                    disabled={isExistingPoolInProfile}
                    onClick={() => {
                      setSelectedPools(isSelectedPool ? selectedPools.filter(poolId => poolId !== itemNodeId) : [...selectedPools, itemNodeId]);
                    }}
                    className={`flex px-2 sm:px-3.5 py-2.5 items-center transition-all border w-full font-medium ${
                      isSelectedPool || isExistingPoolInProfile
                        ? 'bg-[#EDFDF7] border-[#8EE4C4] hover:bg-green-100 disabled:hover:bg-[#EDFDF7]'
                        : 'border-[#D1D6DF] hover:bg-specialGray-005'
                    } rounded-[10px]`}
                  >
                    <EmployerAddUserToCrewPoolItem crewPool={item.node} />

                    {isExistingPoolInProfile ? (
                      <FolderIcon className="ml-auto h-5 text-[#00BF78]" />
                    ) : (
                      <div
                        className={`flex border ${
                          isSelectedPool ? 'border-[#00BF78]' : 'border-[#D1D6DF]'
                        } justify-center items-center w-5 h-5 ml-auto rounded-[3px] transition-all`}
                      >
                        <CheckIcon className={`flex ${isSelectedPool ? 'opacity-100 font-bold' : 'opacity-0'} transition-all stroke-1 stroke-[#00BF78]  h-4 text-[#00BF78]`} />
                      </div>
                    )}
                  </CustomButton>
                );
              })
            ) : (
              <span className="flex w-full bg-specialGray-005 border border-[#D1D6DF] text-specialGray-075 rounded-xl items-center py-2 justify-center">
                {intl.formatMessage({ id: 'hire_crew_pools_is_empty' })}
              </span>
            )}
          </div>
        </div>

        <div className="flex text-sm items-center px-6 gap-x-5 justify-end absolute w-[calc(100%_+_32px)] sm:w-[calc(100%_+_48px)] h-20 bg-[#F5F6F8] overflow-hidden -left-4 sm:-left-6 -bottom-4 sm:-bottom-6 rounded-b-xl">
          <CustomButton className="bg-white border border-specialGray-012 px-8 h-12 rounded-lg font-medium text-specialGray-075 hover:bg-specialGray-002" onClick={onHandleClose}>
            {intl.formatMessage({ id: 'cancel' })}
          </CustomButton>

          <CustomButton
            type="submit"
            data-test-id="save-user-to-crew-pool"
            disabled={isDisabledSaveButtonByValue || isDisabledSaveButtonByLoading}
            className="flex h-12 bg-blue text-white px-8 font-medium items-center justify-center rounded-lg disabled:bg-blue-100"
            onClick={handleAddUserToPool}
          >
            {intl.formatMessage({ id: 'save' })} {(loadingAddUserToCrewPool || loadingPoolCreation) && <SpinnerIcon additionalClasses="h-5 w-5" />}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default EmployerAddUserToCrewPoolMain;
