import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
// routing
import { RoutePaths } from 'app/routing';
// icons
import ReadinessScoreIcon from 'icons/ReadinessScoreIcon';

const LowProfileScoreBarNotification = () => {
  const intl = useIntl();
  return (
    <div className="flex text-blue flex-col sm:flex-row items-center border border-blue rounded-[15px] bg-lightBlue py-[13px] px-6 gap-x-[18px]">
      <div className="flex flex-row gap-x-[18px] items-center">
        <ReadinessScoreIcon className="min-w-[32px]" />
        <div className="flex flex-col text-xs gap-y-1">
          <span className="font-semibold">{intl.formatMessage({ id: 'rating_profile_not_enough_score_to_apply' })}</span>
          <span>{intl.formatMessage({ id: 'rating_profile_minimum_to_apply' })}</span>
        </div>
      </div>
      <div className="flex sm:ml-auto self-center items-center mt-3 sm:mt-0">
        <Link state={{ profileHowCanIDoBetterFlickering: true }} to={RoutePaths.ProfileBase} className="uppercase text-sm font-semibold">
          {intl.formatMessage({ id: 'rating_add_info_to_profile' })}
        </Link>
      </div>
    </div>
  );
};

export default LowProfileScoreBarNotification;
