// packages
import { Suspense } from 'react';
// routes
import { RoutePaths, RoutesPathNames } from 'app/routing';
// utils
import { envVariableTransform } from 'utils';
// components
import SpinnerIcon from 'icons/SpinnerIcon';
import CrewPoolsSkeleton from 'system/skeletons/hireProfile/hireTabs/CrewPoolsSkeleton/CrewPoolsSkeleton';
import EmployerCrewPoolInfo from 'app/ProfileEmployer/components/EmployerCrewPools/components/EmployerInfoOfPool/EmployerCrewPoolInfo';
import EmployerCrewPoolsMain from 'app/ProfileEmployer/components/EmployerCrewPools/components/EmployerCrewPoolsMainTab/EmployerCrewPoolsMain';
import EmployerDeletePool from 'app/ProfileEmployer/components/EmployerCrewPools/components/EmployerCrewPoolsMainTab/components/EmployerDeletePool';
import EmployerCrewPoolCreateForm from 'app/ProfileEmployer/components/EmployerCrewPools/components/EmployerCrewPoolsMainTab/components/EmployerCrewPoolCreateForm';
import EmployerCrewPoolDeleteUserFromPool from 'app/ProfileEmployer/components/EmployerCrewPools/components/EmployerInfoOfPool/components/EmployerCrewPoolDeleteUserFromPool';

export const ProfilePoolsRoute = {
  path: `${RoutePaths.ProfileCrewPools}/*`,

  children: [
    {
      index: true,
      element: envVariableTransform(process.env.REACT_APP_FEATURE_HIRE_CREW_POOLS) ? (
        <Suspense fallback={<CrewPoolsSkeleton />}>
          <EmployerCrewPoolsMain />
        </Suspense>
      ) : null,
    },
    {
      path: `${RoutesPathNames.deletePool}/:${RoutesPathNames.poolId}`,
      Component: EmployerDeletePool,
    },
    {
      path: `${RoutesPathNames.newPool}`,
      Component: EmployerCrewPoolCreateForm,
    },
    {
      path: `${RoutesPathNames.currentPool}/:${RoutesPathNames.poolId}/*`,
      element: (
        <Suspense fallback={<SpinnerIcon additionalClasses="w-20 h-20 mx-auto mt-10" />}>
          <EmployerCrewPoolInfo />
        </Suspense>
      ),
      children: [
        {
          path: `${RoutesPathNames.deleteUser}/:${RoutesPathNames.userId}`,
          Component: EmployerCrewPoolDeleteUserFromPool,
        },
      ],
    },
  ],
};
