// packages
import { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Skeleton from 'react-loading-skeleton';
// components
import ListOfSeaServiceItemSkeleton from './components/ListOfSeaServiceItemSkeleton';

const ListOfSeaServicesSkeleton: FC = () => {
  const [emptyList] = useState<Array<string>>(new Array(3).fill('').map(() => uuidv4()));

  return (
    <>
      <div className="flex mt-4">
        <Skeleton width={100} height={12} containerClassName="w-1/2" />
        <div className="flex space-x-2 w-1/2">
          <Skeleton width={100} height={12} />
          <Skeleton width="100%" height={12} containerClassName="w-full" />
        </div>
      </div>
      <section className="flex flex-col space-y-4 mt-4">
        {emptyList.map(item => (
          <ListOfSeaServiceItemSkeleton key={item} />
        ))}
        <Skeleton width={175} height={34} borderRadius={6} containerClassName="mx-auto" />
      </section>
    </>
  );
};

export default ListOfSeaServicesSkeleton;
