/**
 * @generated SignedSource<<20edcbb9f657583eed1b873bfc887939>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ServiceFragments$data = {
  readonly 'activities': ReadonlyArray<{
    readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragmentsActivities'>;
  }> | null;
  readonly 'description': string | null;
  readonly 'duty': {
    readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragmentsOneDuty'>;
  };
  readonly 'end': String | null;
  readonly 'id': string;
  readonly 'rotations': number;
  readonly 'start': String;
  readonly 'vessel': {
    readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragmentsVessel'>;
  };
  readonly ' $fragmentType': 'ServiceFragments';
};
export type ServiceFragments$key = {
  readonly ' $data'?: ServiceFragments$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragments'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ServiceFragments',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'start',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'end',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'rotations',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'description',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      concreteType: 'Vessel',
      kind: 'LinkedField',
      name: 'vessel',
      plural: false,
      selections: [
        {
          args: null,
          kind: 'FragmentSpread',
          name: 'ServiceFragmentsVessel',
        },
      ],
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      concreteType: 'Duty',
      kind: 'LinkedField',
      name: 'duty',
      plural: false,
      selections: [
        {
          args: null,
          kind: 'FragmentSpread',
          name: 'ServiceFragmentsOneDuty',
        },
      ],
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      concreteType: 'Activity',
      kind: 'LinkedField',
      name: 'activities',
      plural: true,
      selections: [
        {
          args: null,
          kind: 'FragmentSpread',
          name: 'ServiceFragmentsActivities',
        },
      ],
      storageKey: null,
    },
  ],
  type: 'SeaService',
  abstractKey: null,
};

(node as any).hash = '21849d94f621eec5effbf7a13d8ddad5';

export default node;
