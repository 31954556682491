// packages
// helpers
import { customizeToCapitalLetter } from 'formHelpers/utils';
// models
import { VACANCY_STATUS } from 'models/enums';
import React, { useCallback } from 'react';

const JobOpeningStatus: React.FC<{ status: VACANCY_STATUS; additionalClasses?: string }> = ({ status, additionalClasses = 'h-6 text-sm px-1.5 py-0.5' }) => {
  const getBackground = useCallback((vacancyStatus: VACANCY_STATUS) => {
    switch (vacancyStatus) {
      case VACANCY_STATUS.OPEN:
        return 'bg-gradient-to-r from-blue-600 to-blue-400';
      default:
        return 'bg-specialGray-03';
    }
  }, []);

  return (
    <div
      data-test-id={`vacancy-status-${status}`}
      className={`font-semibold flex items-center w-max text-white rounded cursor-default ${getBackground(status)} ${additionalClasses}`}
    >
      {customizeToCapitalLetter(status as VACANCY_STATUS)}
    </div>
  );
};

export default JobOpeningStatus;
