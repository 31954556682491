// packages
import { useIntl } from 'react-intl';
import { PlusIcon } from '@heroicons/react/24/solid';
// routing
import { RoutePaths } from 'app/routing';
// models
import { CommonListOfCrewsProps } from 'models/modelsOfComponents';
import { VACANCY_DESCRIPTION_TYPE_OF_LIST, VACANCY_STATUS } from 'models/enums';
// components
import CustomRouterLinkButton from 'system/Buttons/CustomRouterLinkButton';

const RightSideCommonListOfCrew = ({ seeAllLink, title, children, typeOfList, amountOfUsers, vacancy }: CommonListOfCrewsProps) => {
  const intl = useIntl();
  const isInvitedTypeOfList = typeOfList === VACANCY_DESCRIPTION_TYPE_OF_LIST.INVITED;

  const seeAllButtonTitle = isInvitedTypeOfList ? 'hire_job_description_right_side_button_label' : 'hire_job_description_right_side_button_label_review';

  return (
    <div className="flex flex-col">
      <div className="flex justify-between mb-3 items-center">
        <div className="flex space-x-3 items-center">
          <h5 className="text-lg font-medium text-darkBlue">{intl.formatMessage(title)}</h5>
          <span className="text-specialGray-075 text-sm">{intl.formatMessage({ id: 'hire_job_description_amount_users' }, { amount: amountOfUsers })}</span>
        </div>
        <div className="flex space-x-8">
          {isInvitedTypeOfList && amountOfUsers !== 0 && (
            <CustomRouterLinkButton
              classNameStyle="text-blue"
              isDisabled={vacancy?.status !== VACANCY_STATUS.OPEN}
              label={{ id: 'hire_job_description_right_side_button_label_invite_users' }}
              to={RoutePaths.JobInviteCrew}
            >
              <PlusIcon className="h-[21px] mr-2" />
            </CustomRouterLinkButton>
          )}

          {!!amountOfUsers && <CustomRouterLinkButton classNameStyle="text-blue" label={{ id: seeAllButtonTitle }} to={seeAllLink} />}
        </div>
      </div>
      <div className="flex flex-col space-y-3">{children}</div>
    </div>
  );
};

export default RightSideCommonListOfCrew;
