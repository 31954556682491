// packages
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
// components
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';
// hooks
import { useGetWidthVerticalListOfItems } from 'hooks/useGetWidthVerticalListOfItems';

const ListWithShowMoreItems = <T extends { id: string }>({ list, field }: { list: ReadonlyArray<T> | null; field: keyof T }) => {
  const listContainerRef: RefObject<HTMLElement> = useRef<HTMLElement>(null);
  const [widthContainer, setWidthContainer] = useState<number>(0);

  const { arrayWithDiffer, arrayOfItemsForRendering, addToRefs } = useGetWidthVerticalListOfItems<T>(listContainerRef, widthContainer, list);

  useEffect(() => {
    // 72px - this is the size of the avatar + the margin between the avatar and the title
    setWidthContainer(
      (listContainerRef.current?.parentElement?.parentElement?.offsetWidth as number) -
        (listContainerRef?.current?.parentElement?.previousElementSibling?.clientWidth as number) -
        72,
    );
  }, []);

  const renderPopoverElement = useCallback((count: number) => <div className="flex min-h-[28px] px-3 rounded-full bg-specialGray-012 items-center">&#43;{count}</div>, []);

  return (
    <section ref={listContainerRef} className="flex space-x-2 text-specialGray-075 text-xs" style={{ width: `${widthContainer}px` }}>
      {!isEmpty(arrayOfItemsForRendering) ? (
        <>
          {arrayOfItemsForRendering?.map(duty =>
            duty ? (
              <div key={duty.id} ref={addToRefs} className="flex px-3 bg-specialGray-012 min-h-[28px] items-center w-auto rounded-full whitespace-nowrap">
                <>{duty[field]}</>
              </div>
            ) : null,
          )}
        </>
      ) : null}

      {!isEmpty(arrayWithDiffer) ? (
        <PopoverHelper
          widthContainer="w-fit"
          classes="flex items-center"
          element={renderPopoverElement(arrayWithDiffer?.length as number)}
          position="pl-3 left-full top-1/2 -translate-y-1/2"
          positionArrow="rotate-[135deg] top-1/2 left-1.5"
          darkTheme
        >
          <div className="flex flex-col space-y-2 items-start">
            {arrayWithDiffer?.map(item => (
              <span key={item.id} className="whitespace-nowrap">
                <>{item[field]}</>
              </span>
            ))}
          </div>
        </PopoverHelper>
      ) : null}
    </section>
  );
};

export default ListWithShowMoreItems;
