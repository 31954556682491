// packages
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import parseISO from 'date-fns/parseISO';
import { useLocation } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
import { differenceInCalendarDays } from 'date-fns';
// hooks
import { useGetHireProfile } from 'hooks/hireProfileHooks';
import useGetPaymentSubscription from 'hooks/useGetPaymentSubscription';
// components
import { Modal } from 'system/Modal';
import { ArrowIcon } from 'icons/ArrowIcon';
import PaymentSubscriptionView from './components/PaymentSubscriptionView';
import PaymentSubscriptionModalContent from './components/PaymentSubscriptionModalContent/PaymentSubscriptionModalContent';
// models
import { PaymentSubscriptionName, PaymentSubscriptionType } from 'models/enums';
// helpers
import { onHandleSendProductAnalytics, productAnalyticsEvents } from 'app/ProfileEmployer/HireProduct/productHelpers';
// schemas
import { GET_EMAIL_OF_PROFILE } from 'schemas/profile/ProfileQueries';
// generated
import { ProfileQueriesGetEmailOfProfileQuery } from 'schemas/profile/__generated__/ProfileQueriesGetEmailOfProfileQuery.graphql';
// recoil
import { paymentSubscriptionModalAtom } from 'recoil/ContactInformation/atoms/paymentSubscriptionModalAtom';

const HireSubscriptionProduct = () => {
  const intl = useIntl();
  const { state } = useLocation();

  const { organizationId, profileId } = useGetHireProfile();
  const [openPaymentSubscriptionModal, setShowPaymentSubscriptionModal] = useRecoilState(paymentSubscriptionModalAtom);

  const email =
    useLazyLoadQuery<ProfileQueriesGetEmailOfProfileQuery>(GET_EMAIL_OF_PROFILE, { ids: [profileId as number], skip: !profileId }).profileOIDClaims?.edges[0]?.email || '';

  const findPaymentSubscription = useGetPaymentSubscription(organizationId);

  const getDaysLeft = useMemo(() => {
    if (!findPaymentSubscription?.node.untilValid) {
      return;
    }
    const today = state?.storybookDate || new Date().toISOString();

    const startDate = parseISO(today);
    const endDate = parseISO(findPaymentSubscription.node.untilValid.toString());

    return differenceInCalendarDays(endDate, startDate);
  }, [findPaymentSubscription?.node.untilValid, state?.storybookDate]);

  // If there is no "untilValid" field, this is a new organization with a profile which has not been filled out yet. All new organizations have a free trial period.
  // To prevent the subscription purchase modal from appearing when entering data for the first time, set the default value to isExpiredSubscription === false
  const isExpiredSubscription = useMemo(() => {
    if (getDaysLeft === undefined) {
      return false;
    }
    return getDaysLeft <= 0;
  }, [getDaysLeft]);

  const onHandleToggleSubscriptionModal = (open: boolean) => () => {
    onHandleSendProductAnalytics(open ? productAnalyticsEvents.openModal : productAnalyticsEvents.closeModal, organizationId, profileId, email)();
    setShowPaymentSubscriptionModal({ showPaymentSubscriptionModal: open });
  };

  useEffect(() => {
    if (isExpiredSubscription) {
      onHandleSendProductAnalytics(productAnalyticsEvents.autoOpenExpiredModal, organizationId, profileId, email)();
    }
  }, [email, isExpiredSubscription, organizationId, profileId]);

  if (!findPaymentSubscription) {
    return null;
  }

  return (
    <>
      <Modal onClose={onHandleToggleSubscriptionModal(false)} show={openPaymentSubscriptionModal.showPaymentSubscriptionModal || state?.storybookShowModal}>
        <PaymentSubscriptionModalContent onClose={onHandleToggleSubscriptionModal(false)} />
      </Modal>
      <PaymentSubscriptionView
        subscriptionPlan={findPaymentSubscription?.node.product.perma === PaymentSubscriptionType.TRIAL ? PaymentSubscriptionName.FREE : PaymentSubscriptionName.PRO}
      >
        <button onClick={onHandleToggleSubscriptionModal(true)} className="flex hover:animate-pulse text-blue rounded-sm font-medium text-xs px-1">
          {intl.formatMessage({ id: 'subscription_upgrade' })} <ArrowIcon className="!w-4 !h-4" />
        </button>
      </PaymentSubscriptionView>
    </>
  );
};

export default HireSubscriptionProduct;
