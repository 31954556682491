//packages
import { useMemo } from 'react';
// mocks
import { MOCK_DURATION_OF_POSITION_WITH_NAME, MOCK_LIST_OF_INDUSTRIES, MOCK_SALARY_PERIOD, MOCK_STARTING_CONDITION } from 'mocks/mockData';
// enums
import { FILTER_TYPE } from 'models/enums';
// components
import SelectedCommonFilterElements from './SelectedCommonFilterElements';
// hooks
import useJobOpeningsFiltersQuery from 'hooks/searchFilter/useJobOpeningsFiltersQuery';
// models
import { FilterDynamicProps } from 'models/commonTypes';

const SelectedJobOpeningsFilterElements = ({ margins }: { margins?: string }) => {
  const { listOfHiringArea, allDuties, vesselsType } = useJobOpeningsFiltersQuery();

  const allFiltersBySelectValue = useMemo<FilterDynamicProps>(
    () => ({
      [FILTER_TYPE.DUTIES]: allDuties,
      [FILTER_TYPE.VESSEL_TYPES]: vesselsType,
      [FILTER_TYPE.HIRING_AREA]: listOfHiringArea,
      [FILTER_TYPE.INDUSTRY]: MOCK_LIST_OF_INDUSTRIES,
      [FILTER_TYPE.DURATION]: MOCK_DURATION_OF_POSITION_WITH_NAME,
      [FILTER_TYPE.STARTING_CONDITIONAL]: MOCK_STARTING_CONDITION,
      [FILTER_TYPE.SALARY_PERIOD]: MOCK_SALARY_PERIOD,
    }),
    [allDuties, listOfHiringArea, vesselsType],
  );

  return <SelectedCommonFilterElements allFiltersBySelectValue={allFiltersBySelectValue} margins={margins} />;
};

export default SelectedJobOpeningsFilterElements;
