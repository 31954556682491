import { PropsWithChildren } from 'react';
import Skeleton from 'react-loading-skeleton';

const SeaServiceEmployerViewWrapperSkeleton = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex w-full min-h-[96px] rounded-[15px] border border-specialGray-2 items-center text-darkBlue bg-white">
      <div className="flex w-full p-4">
        {/*time*/}
        <div className="flex flex-col justify-center space-y-1 mr-6 items-center min-w-[60px]">
          <Skeleton containerClassName="h-5" width={56} height={14} />
          <Skeleton containerClassName="h-5" width={56} height={14} />
        </div>

        {/*avatar*/}
        <Skeleton containerClassName="flex" width={64} height={64} borderRadius={6} />

        {/*content*/}
        <div className="flex w-full flex-col ml-4">
          {/*header*/}
          <div className="flex w-full h-7 items-center">
            {/*duty item*/}
            <Skeleton width={40} height={26} borderRadius={100} />
            {/*title item*/}
            <Skeleton className="ml-3" width={60} height={18} />
          </div>
          <div className="flex w-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default SeaServiceEmployerViewWrapperSkeleton;
