/**
 * @generated SignedSource<<e6ca3038cfff3c63782bb81635e156a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type CertificateFragmentsCertificateInfo$data = ReadonlyArray<{
  readonly 'certificate': {
    readonly alts: ReadonlyArray<string>;
    readonly id: string;
    readonly kind: {
      readonly id: string;
      readonly name: string;
      readonly perma: string;
    } | null;
    readonly name: string;
    readonly neverExpires: boolean;
    readonly priority: number;
  };
  readonly 'document': {
    readonly id: string;
    readonly kind: {
      readonly id: string;
      readonly name: string;
      readonly perma: string;
    } | null;
    readonly urls: ReadonlyArray<string>;
    readonly xlarge_urls: ReadonlyArray<string> | null;
  } | null;
  readonly 'expires': String | null;
  readonly 'id': string;
  readonly 'owner': {
    readonly id: string;
  };
  readonly ' $fragmentType': 'CertificateFragmentsCertificateInfo';
}>;
export type CertificateFragmentsCertificateInfo$key = ReadonlyArray<{
  readonly ' $data'?: CertificateFragmentsCertificateInfo$data;
  readonly ' $fragmentSpreads': FragmentRefs<'CertificateFragmentsCertificateInfo'>;
}>;

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v2 = [
      v0 /*: any*/,
      v1 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'perma',
        storageKey: null,
      },
    ];
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: {
      plural: true,
    },
    name: 'CertificateFragmentsCertificateInfo',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'expires',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Profile',
        kind: 'LinkedField',
        name: 'owner',
        plural: false,
        selections: [v0 /*: any*/],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Certificate',
        kind: 'LinkedField',
        name: 'certificate',
        plural: false,
        selections: [
          v0 /*: any*/,
          v1 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'alts',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'priority',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'neverExpires',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'CertificateKind',
            kind: 'LinkedField',
            name: 'kind',
            plural: false,
            selections: v2 /*: any*/,
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Document',
        kind: 'LinkedField',
        name: 'document',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'urls',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'xlarge_urls',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'DocumentKind',
            kind: 'LinkedField',
            name: 'kind',
            plural: false,
            selections: v2 /*: any*/,
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
    type: 'CertificateInfo',
    abstractKey: null,
  };
})();

(node as any).hash = '6b4952c9e8fb498b19b243d3fbb8c743';

export default node;
