import { useMemo } from 'react';
//hooks
import { useGetHireProfileVacancies } from './useGetHireProfileVacancies';

export const useIsInvitedCrewEvenForOneOfVacancies = (crewProfileId?: string): boolean => {
  const getVacancies = useGetHireProfileVacancies();
  return useMemo(() => {
    if (!getVacancies?.length || !crewProfileId) {
      return false;
    }
    const invitedUserToOneOfVacancies = getVacancies.find(vacanciesItems => {
      return vacanciesItems?.node?.invites?.find(vacancyItem => vacancyItem.owner.id === crewProfileId);
    });
    return !!invitedUserToOneOfVacancies?.node?.id;
  }, [crewProfileId, getVacancies]);
};
