// packages
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { Form, FormikProvider, useFormik } from 'formik';
import { useLazyLoadQuery, useMutation } from 'react-relay';
import { Link } from 'react-router-dom';
// components
import { Modal } from 'system/Modal';
import { Button } from 'system/Button';
import AlertForError from 'system/Alert/AlertForError';
import UploadingDashboard from 'system/UploadingDashboard';
import { TextareaInputGroup } from 'system/TextareaInputGroup';
import LabelComponent from 'system/LabelComponent/LabelComponent';
import SelectSeaServiceComponent from './components/SelectSeaServiceComponent';
import RenderAttachedDocument from 'system/RenderAttachedDocument/RenderAttachedDocument';
// models
import { IAppraisalFormData } from 'models/modelsOfForms';
// types
import { AppraisalsFormProps } from '../types';
import { ENTITY_WITH_PERMA } from 'models/commonTypes';
// formHelpers
import { getConnectionId } from 'formHelpers/utils';
import { AppraisalFormValidateSchema } from 'formHelpers/validationsOfForms';
import { AppraisalFormInitialValues } from 'formHelpers/initialValuesOfForms';
// generated
import { DocumentQueriesGetKindsQuery } from 'schemas/document/__generated__/DocumentQueriesGetKindsQuery.graphql';
import { AppraisalFragmentsOneAppraisal$data } from 'schemas/appraisals/__generated__/AppraisalFragmentsOneAppraisal.graphql';
import { AppraisalMutationsFormUpdateMutation } from 'schemas/appraisals/__generated__/AppraisalMutationsFormUpdateMutation.graphql';
import { AppraisalMutationsFormCreateMutation } from 'schemas/appraisals/__generated__/AppraisalMutationsFormCreateMutation.graphql';
import { DocumentMutationsCreateDocumentMutation } from 'schemas/document/__generated__/DocumentMutationsCreateDocumentMutation.graphql';
import { DocumentMutationsUpdateDocumentMutation } from 'schemas/document/__generated__/DocumentMutationsUpdateDocumentMutation.graphql';
// schemas
import { GET_DOCUMENT_KINDS } from 'schemas/document/DocumentQueries';
import { CREATE_DOCUMENT, UPDATE_DOCUMENT } from 'schemas/document/DocumentMutations';
import { CREATE_APPRAISAL, UPDATE_APPRAISAL } from 'schemas/appraisals/AppraisalMutations';
// hooks
import { useAuth } from 'authentication';
// utils
import { getLastElementFromArray } from 'utils';

const AppraisalsForm = ({ appraisal, onCloseModal, defaultSubmitting = false, defaultError, dropdownIsOpenByDefault, profileId }: AppraisalsFormProps) => {
  const { identity } = useAuth();
  const intl = useIntl();
  const storyID = getConnectionId('AppraisalListFromProfile_appraisals');

  const [error, setError] = useState<Error | undefined>(defaultError);
  const [urls, setUrls] = useState<Array<string>>([]);
  const [largeUrls, setLargeUrls] = useState<Array<string>>([]);
  const [showAttachedFile, setShowAttachedFile] = useState<boolean>(false);

  const { documentKinds } = useLazyLoadQuery<DocumentQueriesGetKindsQuery>(GET_DOCUMENT_KINDS, {});
  const [showFullImageModal, setShowFullImageModal] = useState<boolean>(false);
  const documentKind = useMemo<ENTITY_WITH_PERMA>(() => documentKinds?.edges.find(kind => kind.perma === '1') as ENTITY_WITH_PERMA, [documentKinds]);
  const [currentImageUrl, setCurrentImageUrl] = useState<string>('');

  const [commitCreateDocument] = useMutation<DocumentMutationsCreateDocumentMutation>(CREATE_DOCUMENT);
  const [commitUpdateDocument] = useMutation<DocumentMutationsUpdateDocumentMutation>(UPDATE_DOCUMENT);
  const [commitCreateAppraisal] = useMutation<AppraisalMutationsFormCreateMutation>(CREATE_APPRAISAL);
  const [commitUpdateAppraisal] = useMutation<AppraisalMutationsFormUpdateMutation>(UPDATE_APPRAISAL);

  const formik = useFormik<IAppraisalFormData>({
    onSubmit,
    initialValues: AppraisalFormInitialValues(
      (appraisal as AppraisalFragmentsOneAppraisal$data)?.document?.id as string,
      appraisal?.seaService?.id as string,
      appraisal?.review as string,
    ),
    validationSchema: AppraisalFormValidateSchema,
  });

  useEffect(() => {
    if (defaultSubmitting && !formik.isSubmitting) formik.setSubmitting(true);
  }, [formik, defaultSubmitting]);

  function onSubmit(data: IAppraisalFormData) {
    const onError = (error: Error) => {
      setError(error);
      formik.setSubmitting(false);
    };

    if (appraisal) {
      commitUpdateDocument({
        onError,
        onCompleted: () => {
          window.analytics?.track('wui-update-appraisal', {
            uploadedAppraisalId: appraisal.id,
          });
          commitUpdateAppraisal({
            onError,
            onCompleted: () => {
              if (onCloseModal) {
                onCloseModal();
              }
            },
            variables: {
              id: appraisal.id,
              data: {
                ownerId: appraisal.owner.id,
                documentId: data.documentId,
                seaServiceId: data.seaServiceId,
                review: data.review,
              },
            },
          });
        },
        variables: {
          id: (appraisal as AppraisalFragmentsOneAppraisal$data).document?.id!,
          data: {
            urls: isEmpty(urls) ? ((appraisal as AppraisalFragmentsOneAppraisal$data)?.document?.urls as Array<string>) : urls,
            xlarge_urls: isEmpty(largeUrls) ? ((appraisal as AppraisalFragmentsOneAppraisal$data)?.document?.xlarge_urls as Array<string>) : largeUrls,
            kindId: documentKind.id,
            ownerId: `${identity?.profileId}`,
          },
        },
      });
    } else {
      if (isEmpty(urls) && isEmpty(largeUrls)) {
        formik.getFieldHelpers('documentId').setError(intl.formatMessage({ id: 'error_not_attached_file' }));
        formik.setSubmitting(false);
      } else {
        formik.getFieldHelpers('documentId').setTouched(false);
        commitCreateDocument({
          onError: (error: Error) => {
            setError(error);
            formik.setSubmitting(false);
            formik.getFieldHelpers('documentId').setTouched(true);
          },
          onCompleted: document => {
            const documentId = document.createDocument.node?.id;

            if (documentId) {
              commitCreateAppraisal({
                onError,
                onCompleted: response => {
                  formik.getFieldHelpers('documentId').setError(undefined);
                  window.analytics?.track('wui-create-appraisal', {
                    createdAppraisalId: response.createAppraisal?.node?.id,
                  });
                  if (onCloseModal) {
                    onCloseModal();
                  }
                },
                variables: {
                  data: {
                    ownerId: `${identity?.profileId}`,
                    documentId,
                    seaServiceId: data.seaServiceId,
                    review: data.review,
                  },
                  connections: [`${storyID}(ownerId:"${identity?.profileId}")`],
                },
              });
            }
          },
          variables: {
            data: {
              urls,
              xlarge_urls: largeUrls,
              kindId: documentKind.id,
              ownerId: `${identity?.profileId}`,
            },
          },
        });
      }
    }
  }

  useEffect(() => {
    if (!isEmpty(urls) && !isEmpty(largeUrls)) {
      formik.getFieldHelpers('documentId').setTouched(false);
    } else if (formik.touched.documentId) {
      formik.getFieldHelpers('documentId').setTouched(false);
    }
    // eslint-disable-next-line
  }, [urls, largeUrls]);

  const handleClick = useCallback(
    (index: number) => () => {
      setShowFullImageModal(true);
      const getLargeUrl: string = (appraisal as AppraisalFragmentsOneAppraisal$data)?.document?.xlarge_urls?.[index] as string;
      setCurrentImageUrl(getLargeUrl);
    },
    [appraisal],
  );

  const handleShowAttachedFile = useCallback(() => {
    setShowAttachedFile(true);
  }, []);

  return (
    <FormikProvider value={formik}>
      <Modal
        onClose={() => {
          setShowFullImageModal(false);
        }}
        show={showFullImageModal}
      >
        {getLastElementFromArray<string>(currentImageUrl?.split('.') as Array<string>) === 'pdf' ? (
          <>
            <object data={currentImageUrl} type="application/pdf" width="100%" height="500px" className="border-2 border-solid border-grayIcon rounded-[10px]">
              <p>
                {intl.formatMessage({ id: 'error_pdf_plugin' })}
                <Link to={currentImageUrl ?? ''} className="block text-center mt-1">
                  {intl.formatMessage({ id: 'pdf_plugin_download' })}
                </Link>
              </p>
            </object>
          </>
        ) : (
          <img key={currentImageUrl} src={currentImageUrl} alt={currentImageUrl} className="block m-auto border-2 border-solid border-grayIcon rounded-[10px]" />
        )}
      </Modal>
      <Form className="space-y-6">
        {error && <AlertForError heading={{ id: 'service_form_failedSubmit' }} error={error} />}
        <SelectSeaServiceComponent
          nameField="seaServiceId"
          defaultSelectedSeaService={appraisal?.seaService}
          dropdownIsOpenByDefault={dropdownIsOpenByDefault}
          profileId={profileId}
        />
        <TextareaInputGroup
          name="review"
          label={{ id: 'appraisal_modal_review_label' }}
          placeholderText={{ id: 'placeholder_form_type' }}
          description={{ id: 'form_limit_characters' }}
          maxCharCount={200}
        />
        <div className="flex mt-3.5 flex-col">
          <LabelComponent classes="text-sm font-medium text-specialGray-1 mb-4" label={{ id: 'appraisal_upload_file_label' }} />
          {(appraisal as AppraisalFragmentsOneAppraisal$data)?.document?.id && (appraisal as AppraisalFragmentsOneAppraisal$data).document?.urls && !showAttachedFile ? (
            <div className="flex sm:justify-start justify-center flex-wrap gap-4">
              {(appraisal as AppraisalFragmentsOneAppraisal$data)?.document?.urls.map((url, index) => (
                <RenderAttachedDocument key={url} src={url} alt={(appraisal as AppraisalFragmentsOneAppraisal$data)?.seaService?.vessel.name} onClick={handleClick(index)} />
              ))}
            </div>
          ) : (
            <>
              <UploadingDashboard setSmallUrls={setUrls} setLargeUrls={setLargeUrls} templateId={process.env.REACT_APP_TRANSLOADIT_CERTIFICATE_TEMPLATE_ID} maxNumberOfFiles={1} />
              {(formik.touched.documentId || formik.errors.documentId) && <p className="mt-2 text-sm text-red-600">{intl.formatMessage({ id: 'error_not_attached_file' })}</p>}
            </>
          )}
        </div>
        <div className="flex justify-end">
          {(appraisal as AppraisalFragmentsOneAppraisal$data)?.document?.id && (
            <Button
              label={{ id: 'button_upload_new_file_label' }}
              type="button"
              disabled={formik.isSubmitting}
              buttonType="secondary"
              onClick={handleShowAttachedFile}
              additionalClasses="mr-5"
            />
          )}
          <Button type="submit" label={{ id: 'appraisal_form_save' }} disabled={formik.isSubmitting} />
        </div>
      </Form>
    </FormikProvider>
  );
};

export default AppraisalsForm;
