// packages
import { useEffect, useState } from 'react';
import { useFragment, useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
// hooks
import { useAuth } from 'authentication';
import useGetCurrentOpenedProfileOrganization from './useGetCurrentOpenedProfileOrganization';
// models
import { VACANCY_STATUS } from 'models/enums';
// generated
import { ProfileFragments$key } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { ProfileQueriesRootQuery } from 'schemas/profile/__generated__/ProfileQueriesRootQuery.graphql';
import { ProfileQueriesRootRefreshQuery } from 'schemas/profile/__generated__/ProfileQueriesRootRefreshQuery.graphql';
import { VacancyQueriesGetTotalCountOfVacanciesQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetTotalCountOfVacanciesQuery.graphql';
import { ProfileFragmentsOrganizationPlural_profile$key } from 'schemas/profile/__generated__/ProfileFragmentsOrganizationPlural_profile.graphql';
// schemas
import { PROFILE_QUERY } from 'schemas/profile/ProfileQueries';
import { GET_TOTAL_COUNT_OF_VACANCIES } from 'schemas/vacancy/VacancyQueries';
import { PROFILE_FRAGMENT, PROFILE_ORGANIZATION_FRAGMENT } from 'schemas/profile/ProfileFragments';

export function useGetHireProfile() {
  const { identity } = useAuth();

  const profileQueryRef = useLazyLoadQuery<ProfileQueriesRootQuery>(PROFILE_QUERY, { id: `${identity?.profileId}`, skip: !identity?.profileId });

  const [profileData, reFetch] = useRefetchableFragment<ProfileQueriesRootRefreshQuery, ProfileFragments$key>(PROFILE_FRAGMENT, profileQueryRef.node || null);
  const profileOrganizations = useFragment<ProfileFragmentsOrganizationPlural_profile$key>(PROFILE_ORGANIZATION_FRAGMENT, profileData?.membership || null);
  const { profileOrganization } = useGetCurrentOpenedProfileOrganization(profileOrganizations);

  return {
    reFetch,
    profileId: identity?.profileId || null,
    profileOrganizations,
    currentOrganizationData: profileOrganization || null,
    organizationId: profileOrganization?.organization?.id || null,
  };
}

export function useGetNumberOfOpenedAndClosedVacancies() {
  const { organizationId } = useGetHireProfile();

  const [getNumberOfOpenedVacancies, setOpenedVacancies] = useState<number>(0);
  const [getNumberOfClosedVacancies, setClosedVacancies] = useState<number>(0);

  const numberOfVacancies = useLazyLoadQuery<VacancyQueriesGetTotalCountOfVacanciesQuery>(
    GET_TOTAL_COUNT_OF_VACANCIES,
    {
      orgId: organizationId as string,
      skip: !organizationId,
    },
    { fetchPolicy: 'store-or-network' },
  ).organizationVacancyStats;

  useEffect(() => {
    if (numberOfVacancies) {
      numberOfVacancies.stats.forEach(vacancyState => {
        if (vacancyState.status === VACANCY_STATUS.OPEN) {
          setOpenedVacancies(vacancyState.totalCount);
        }
        if (vacancyState.status === VACANCY_STATUS.CLOSED) {
          setClosedVacancies(vacancyState.totalCount);
        }
      });
    }
  }, [numberOfVacancies]);

  return {
    organizationId,
    getNumberOfOpenedVacancies,
    getNumberOfClosedVacancies,
  };
}
