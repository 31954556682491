import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSkeletonContext } from 'Context/StorybookContext';

const HeaderSkeleton: React.FC<{ widthBigTitle: number }> = ({ widthBigTitle }) => {
  const { isSelfProfile } = useSkeletonContext();

  return (
    <div className="flex flex-col sm:flex-row my-5 justify-between sm:items-center">
      <div className="flex flex-col">
        <Skeleton width={widthBigTitle} height={24} />
        <Skeleton width={100} />
      </div>
      {isSelfProfile && <Skeleton width={160} height={34} />}
    </div>
  );
};

export default HeaderSkeleton;
