// packages
import { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSearchParams } from 'react-router-dom';
// models
import { FILTER_TYPE } from 'models/enums';
import { FilterDynamicProps, SelectedFilterElementsType } from 'models/commonTypes';
//mocks
import { allNumericFilters, filterByValue } from 'system/FilterSlideComponents/filtersMock';
//hooks
import { useDeleteFilterAnalytics } from 'hooks/anaytics/useFiltersAnalytics';

export function useGetSelectedFilterElements(listOfSelectValue: FilterDynamicProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { deleteFilterAnalytics } = useDeleteFilterAnalytics();

  const [selectedItems, setSelectedItems] = useState<Array<SelectedFilterElementsType>>([]);
  const isFilterByValues = useCallback((key: string) => filterByValue[key] === filterByValue[FILTER_TYPE.CREW_VESSEL_IMO], []);

  useEffect(() => {
    const currentFiltersSearchParams = Object.fromEntries([...searchParams]);

    const resultArray: SelectedFilterElementsType[] = [];
    if (!isEmpty(currentFiltersSearchParams)) {
      for (const [key, value] of Object.entries(currentFiltersSearchParams)) {
        //case for numeric filters
        if (allNumericFilters[key] || filterByValue[key]) {
          if (isFilterByValues(key)) {
            value.split(',').forEach(val => {
              if (!!val) {
                resultArray.push({ id: val, name: val, key: val });
              }
            });
          } else {
            const numericAndValueFilters = { ...allNumericFilters, ...filterByValue };
            resultArray.push({ name: numericAndValueFilters[key], id: key, value: value, key });
          }
        } else {
          const selectedFilterByValue = !isEmpty(listOfSelectValue[key]) && listOfSelectValue[key];
          //case for selected filters by select value : country , duties , activities etc.
          if (!!selectedFilterByValue) {
            const parsedIds = value.split(',');
            parsedIds.forEach(id => {
              const findSearchItem = (selectedFilterByValue as [{ label?: string; name: string; isoCode?: string; id: string; perma?: string | null }]).find(item => {
                const compareByIsoCode = item.isoCode === id; //for countries
                const compareById = item.id === id; //for filters by select value
                return item.isoCode ? compareByIsoCode : compareById;
              });
              if (findSearchItem)
                resultArray.push({
                  name: findSearchItem.label || findSearchItem.name,
                  id: findSearchItem.isoCode || findSearchItem.id,
                  key: `${findSearchItem.isoCode || findSearchItem.id}-${key}`,
                  perma: findSearchItem?.perma,
                });
            });
          }
        }
      }
    }
    setSelectedItems(resultArray);
  }, [searchParams, listOfSelectValue, isFilterByValues]);

  const handleDeleteSelectedItem = (id: string) => () => {
    const currentParams = Object.fromEntries([...searchParams]);

    for (const [key, value] of Object.entries(currentParams)) {
      const searchIdArray = value.split(',');
      const isNumericOrValueFilter = id === key;
      const findAnalyticsItem = selectedItems.find(selectedItem => selectedItem.id === id);

      if (searchIdArray.length > 1 && searchIdArray.includes(id)) {
        findAnalyticsItem && deleteFilterAnalytics(findAnalyticsItem, key as FILTER_TYPE);

        const withoutSelectedId = searchIdArray.filter(urlId => urlId !== id).join(',');
        setSearchParams({ ...currentParams, [key]: withoutSelectedId });
      }
      //delete filter from search url if this is the last item
      if ((searchIdArray.length === 1 && searchIdArray.includes(id)) || isNumericOrValueFilter) {
        findAnalyticsItem && deleteFilterAnalytics(findAnalyticsItem, key as FILTER_TYPE);

        searchParams.delete(key);
        setSearchParams(searchParams);
      }
    }
  };

  return { selectedItems, handleDeleteSelectedItem };
}
