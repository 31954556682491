// packages
import { Suspense, useCallback, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
// routing
import { RoutesPathNames } from 'app/routing';
// components
import { Button } from 'system/Button';
import SlideOver from 'system/SlideOver';
import SpinnerIcon from 'icons/SpinnerIcon';
import JobOpeningStatus from 'system/JobOpeningStatus/JobOpeningStatus';
import AppliedCrewSlider from 'app/ProfileEmployer/components/ReviewApplicationsPage/components/AppliedCrewSlider/AppliedCrewSlider';
// hooks
import { useToggleShowWindow } from 'hooks/useToggleShowModal';
// models
import { VACANCY_STATUS } from 'models/enums';
// types
import { VacancyOfApplicationType, VacancyWithContactType } from 'app/ProfileCrew/type/ProfileCrewTypes';
// recoil
import { contactInformationAtom } from 'recoil/ContactInformation/atoms/contactInformationAtom';

const VacancyWithContactButton = ({ vacancy }: { vacancy: VacancyOfApplicationType }) => {
  const intl = useIntl();
  const params = useParams();
  const [getShowConfirmModalInSlideOver, setShowConfirmModalInSlideOver] = useState<boolean>(false);
  const [isTouchedToggle, setIsTouchedToggle] = useState<boolean>(false);
  const getShowContactInformation = useRecoilValue(contactInformationAtom);

  const { showWindow, toggleShowWindow } = useToggleShowWindow();

  const getContactIsRelatedToCurrentCrew = useMemo(() => {
    return vacancy.contacts?.find(contact => contact.owner.id === params[RoutesPathNames.id] || contact.owner.id === getShowContactInformation.profileId);
  }, [getShowContactInformation.profileId, params, vacancy.contacts]);

  const handleCloseSlide = useCallback(() => {
    if (isTouchedToggle) {
      toggleShowWindow(false)();
      return;
    }
    setShowConfirmModalInSlideOver(!getContactIsRelatedToCurrentCrew);
    if (!getContactIsRelatedToCurrentCrew) {
      return;
    }
    toggleShowWindow(false)();
  }, [getContactIsRelatedToCurrentCrew, toggleShowWindow, isTouchedToggle]);

  const handleHideModalAndConfirm = useCallback(() => {
    setShowConfirmModalInSlideOver(false);
    toggleShowWindow(false)();
  }, [toggleShowWindow]);

  return (
    <>
      <SlideOver show={showWindow} onClose={handleCloseSlide} size="max-w-sm" zIndex="z-[60]">
        <Suspense fallback={<SpinnerIcon />}>
          <AppliedCrewSlider
            contactIsRelatedToCurrentCrew={getContactIsRelatedToCurrentCrew as VacancyWithContactType}
            isShowConfirm={getShowConfirmModalInSlideOver}
            handleHideModalAndConfirm={handleHideModalAndConfirm}
            profileId={params?.[RoutesPathNames.id] || getShowContactInformation.profileId || ''}
            vacancyId={vacancy.id}
            isTouchedConnectionToggle={isTouchedToggle}
            setIsTouchedToggle={setIsTouchedToggle}
          />
        </Suspense>
      </SlideOver>
      <div className="p-2 flex min-h-[62px] first:rounded-t-xl first:border-t border-t-0 last:rounded-b-xl border border-specialGray-02 w-full">
        <div className="mr-1 flex min-h-full items-center w-10%">
          <JobOpeningStatus status={vacancy.status as VACANCY_STATUS} />
        </div>

        <div className="flex-col w-full text-specialGray-1 w-[70%]">
          <h3 className="text-sm mb-1 truncate">{vacancy.title}</h3>

          <div className="flex text-xs">
            {/*POSITIONS*/}
            <div className="mr-4">
              <span className="text-specialGray-075"> {intl.formatMessage({ id: 'hire_information_position' })} </span>
              {vacancy?.duties?.length ? (
                <span>{vacancy?.duties.map(({ name }) => name).join(', ')}</span>
              ) : (
                <span className="text-specialGray-075">{intl.formatMessage({ id: 'search_modal_position_empty' })}</span>
              )}
            </div>
            {/*DATE*/}
            <div className="mr-4">
              <span className="text-specialGray-075"> {intl.formatMessage({ id: 'hire_job_description_posting_date' })} </span>
              <span className="whitespace-pre">{vacancy?.createdAt && format(new Date(`${vacancy?.createdAt}`), 'dd LLL yyyy')}</span>
            </div>
            {/*DEADLINE DATE*/}
            <div>
              <span className="text-specialGray-075"> {intl.formatMessage({ id: 'hire_information_deadline_date' })} </span>
              <span className="whitespace-pre text-specialGray-075">{intl.formatMessage({ id: 'search_modal_posting_date_dead_line_empty' })}</span>
            </div>
          </div>
        </div>

        <div className="ml-auto flex min-h-full items-center w-fil justify-end">
          {!!getContactIsRelatedToCurrentCrew ? (
            <Button
              buttonType="white-primary"
              additionalClasses="h-8 rounded-lg border border-green-500 text-green-500"
              type="button"
              label={{ id: 'review_applications_contacted_label' }}
              onClick={toggleShowWindow(true)}
            />
          ) : (
            <Button buttonType="white-primary" type="button" label={{ id: 'review_applications_contact_label' }} onClick={toggleShowWindow(true)} />
          )}
        </div>
      </div>
    </>
  );
};

export default VacancyWithContactButton;
