/**
 * @generated SignedSource<<60aa7c8f9a93429f85f201f7803d2c56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GeneralSettingData = {
  availableForWork?: boolean | null;
  hideContactInfo?: boolean | null;
  ownerId: string;
};
export type ProfileMutationsUpdateGeneralSettingsMutation$variables = {
  data: GeneralSettingData;
  id: string;
};
export type ProfileMutationsUpdateGeneralSettingsMutation$data = {
  readonly updateGeneralSetting: {
    readonly availableForWork: boolean;
    readonly hideContactInfo: boolean;
    readonly id: string;
  };
};
export type ProfileMutationsUpdateGeneralSettingsMutation = {
  response: ProfileMutationsUpdateGeneralSettingsMutation$data;
  variables: ProfileMutationsUpdateGeneralSettingsMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'data',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'data',
            variableName: 'data',
          },
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        concreteType: 'GeneralSetting',
        kind: 'LinkedField',
        name: 'updateGeneralSetting',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'availableForWork',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'hideContactInfo',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileMutationsUpdateGeneralSettingsMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'ProfileMutationsUpdateGeneralSettingsMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '2327d26214569bf451b5b9a2a5157341',
      id: null,
      metadata: {},
      name: 'ProfileMutationsUpdateGeneralSettingsMutation',
      operationKind: 'mutation',
      text: 'mutation ProfileMutationsUpdateGeneralSettingsMutation(\n  $id: ID!\n  $data: GeneralSettingData!\n) {\n  updateGeneralSetting(id: $id, data: $data) {\n    id\n    availableForWork\n    hideContactInfo\n  }\n}\n',
    },
  };
})();

(node as any).hash = '9bef5389dba1ecab003c27d9260075fa';

export default node;
