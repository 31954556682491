import { FC } from 'react';

const SimCardDownload: FC = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_9198_80155" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_9198_80155)">
        <path
          d="M9.99998 14.168L13.3333 10.8346L12.1666 9.66797L10.8333 10.9596V7.5013H9.16665V10.9596L7.83331 9.66797L6.66665 10.8346L9.99998 14.168ZM4.99998 18.3346C4.54165 18.3346 4.14942 18.1716 3.82331 17.8455C3.49665 17.5188 3.33331 17.1263 3.33331 16.668V6.66797L8.33331 1.66797H15C15.4583 1.66797 15.8508 1.83102 16.1775 2.15714C16.5036 2.4838 16.6666 2.8763 16.6666 3.33464V16.668C16.6666 17.1263 16.5036 17.5188 16.1775 17.8455C15.8508 18.1716 15.4583 18.3346 15 18.3346H4.99998ZM4.99998 16.668H15V3.33464H9.04165L4.99998 7.3763V16.668Z"
          fill="#082051"
          fillOpacity="0.5"
        />
      </g>
    </svg>
  );
};

export default SimCardDownload;
