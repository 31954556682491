// packages
import { useEffect } from 'react';
// context
import { useSkeletonContext } from 'Context/StorybookContext';
// components
import MainTabSkeleton from '../profileTabs/MainTabSkeleton';
import CrewProfileBasicInfo from './ProfileCrewSkeleton/components/CrewProfileBasicInfo';

const ProfileShareFullSkeleton = () => {
  const { setIsSelfProfile } = useSkeletonContext();

  useEffect(() => {
    setIsSelfProfile(false);
  }, [setIsSelfProfile]);

  return (
    <main className="w-full max-w-7xl">
      <CrewProfileBasicInfo sharedProfile />
      <MainTabSkeleton />
    </main>
  );
};

export default ProfileShareFullSkeleton;
