const PersonAdd = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_9191_76827" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_9191_76827)">
        <path
          d="M15 11.6654V9.16536H12.5V7.4987H15V4.9987H16.6666V7.4987H19.1666V9.16536H16.6666V11.6654H15ZM7.49998 9.9987C6.58331 9.9987 5.79859 9.67231 5.14581 9.01953C4.49304 8.36675 4.16665 7.58203 4.16665 6.66536C4.16665 5.7487 4.49304 4.96398 5.14581 4.3112C5.79859 3.65842 6.58331 3.33203 7.49998 3.33203C8.41665 3.33203 9.20137 3.65842 9.85415 4.3112C10.5069 4.96398 10.8333 5.7487 10.8333 6.66536C10.8333 7.58203 10.5069 8.36675 9.85415 9.01953C9.20137 9.67231 8.41665 9.9987 7.49998 9.9987ZM0.833313 16.6654V14.332C0.833313 13.8598 0.95498 13.4256 1.19831 13.0295C1.44109 12.634 1.76387 12.332 2.16665 12.1237C3.02776 11.6931 3.90276 11.3701 4.79165 11.1545C5.68054 10.9395 6.58331 10.832 7.49998 10.832C8.41665 10.832 9.31942 10.9395 10.2083 11.1545C11.0972 11.3701 11.9722 11.6931 12.8333 12.1237C13.2361 12.332 13.5589 12.634 13.8016 13.0295C14.045 13.4256 14.1666 13.8598 14.1666 14.332V16.6654H0.833313ZM2.49998 14.9987H12.5V14.332C12.5 14.1793 12.4619 14.0404 12.3858 13.9154C12.3091 13.7904 12.2083 13.6931 12.0833 13.6237C11.3333 13.2487 10.5764 12.9673 9.81248 12.7795C9.04859 12.5923 8.27776 12.4987 7.49998 12.4987C6.7222 12.4987 5.95137 12.5923 5.18748 12.7795C4.42359 12.9673 3.66665 13.2487 2.91665 13.6237C2.79165 13.6931 2.69109 13.7904 2.61498 13.9154C2.53831 14.0404 2.49998 14.1793 2.49998 14.332V14.9987ZM7.49998 8.33203C7.95831 8.33203 8.35081 8.1687 8.67748 7.84203C9.00359 7.51592 9.16665 7.1237 9.16665 6.66536C9.16665 6.20703 9.00359 5.81481 8.67748 5.4887C8.35081 5.16203 7.95831 4.9987 7.49998 4.9987C7.04165 4.9987 6.64942 5.16203 6.32331 5.4887C5.99665 5.81481 5.83331 6.20703 5.83331 6.66536C5.83331 7.1237 5.99665 7.51592 6.32331 7.84203C6.64942 8.1687 7.04165 8.33203 7.49998 8.33203Z"
          fill="#082051"
          fillOpacity="0.5"
        />
      </g>
    </svg>
  );
};

export default PersonAdd;
