// packages
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useFragment, useLazyLoadQuery, useMutation } from 'react-relay';
//components
import LineSvg from 'icons/LineSvg';
import { Modal } from 'system/Modal';
import CakeIcon from 'icons/CakeIcon';
import { Button } from 'system/Button';
import LocationIcon from 'icons/LocationIcon';
import PhoneForwardedIcon from 'icons/PhoneForwardedIcon';
import SettingsCheckBox from 'system/SettingsCheckBox/SettingsCheckBox';
import { ProfileAvatar } from 'app/Avatars/ProfileAvatar/ProfileAvatar';
import ProfileFullName from 'app/ProfileEmployer/components/ReviewApplicationsPage/components/ProfileFullName';
import SlideProfileInfoFieldWithIcon from 'app/ProfileEmployer/components/ReviewApplicationsPage/components/SlideProfileInfoFieldWithIcon';
// generated
import { CountryFragments_info$key } from 'schemas/country/__generated__/CountryFragments_info.graphql';
import { ServiceFragmentsOneDuty$key } from 'schemas/services/__generated__/ServiceFragmentsOneDuty.graphql';
import { ProfileFragmentsCrewProfile$key } from 'schemas/profile/__generated__/ProfileFragmentsCrewProfile.graphql';
import { ProfileQueriesCrewProfileQuery } from 'schemas/profile/__generated__/ProfileQueriesCrewProfileQuery.graphql';
import { ProfileFragments_info$data, ProfileFragments_info$key } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
import { ProfileQueriesGetProfileOIDClaimsQuery } from 'schemas/profile/__generated__/ProfileQueriesGetProfileOIDClaimsQuery.graphql';
import { ContactMutationsCreateVacancyContactMutation } from 'schemas/contacts/__generated__/ContactMutationsCreateVacancyContactMutation.graphql';
import { ContactMutationsDeleteVacancyContactMutation } from 'schemas/contacts/__generated__/ContactMutationsDeleteVacancyContactMutation.graphql';
// schemas
import { GET_ONE_DUTY } from 'schemas/services/ServiceFragments';
import { COUNTRY_FRAGMENT } from 'schemas/country/CountryFragments';
import { CREW_PROFILE_QUERY } from 'schemas/profile/ProfileQueries';
import { GET_PROFILE_OID_CLAIMS } from 'schemas/profile/ProfileQueries';
import { CREATE_CONTACT, DELETE_CONTACT } from 'schemas/contacts/ContactMutations';
import { CREW_PROFILE_FRAGMENT, PROFILE_INFO_FRAGMENT } from 'schemas/profile/ProfileFragments';
// utils
import { getUserYears } from 'utils';
// formHelpers
import { CreateContactUpdater, RemoveContactUpdater } from 'formHelpers/updaters/updatersOfContacts';
// hooks
import { useToggleShowWindow } from 'hooks/useToggleShowModal';
// types
import { AppliedCrewSliderProps } from '../../types';

const AppliedCrewSlider = ({
  contactIsRelatedToCurrentCrew,
  isShowConfirm = false,
  handleHideModalAndConfirm,
  profileId,
  vacancyId,
  setIsTouchedToggle,
  isTouchedConnectionToggle,
}: AppliedCrewSliderProps) => {
  const intl = useIntl();
  const [checked, setChecked] = useState<boolean>(!!contactIsRelatedToCurrentCrew?.id);
  const { toggleShowWindow, showWindow } = useToggleShowWindow();

  const profileFragment = useLazyLoadQuery<ProfileQueriesCrewProfileQuery>(CREW_PROFILE_QUERY, { id: profileId! });
  const profileContacts = useLazyLoadQuery<ProfileQueriesGetProfileOIDClaimsQuery>(GET_PROFILE_OID_CLAIMS, { ids: [+profileId!], skip: !profileId });

  const profileData = useFragment<ProfileFragmentsCrewProfile$key>(CREW_PROFILE_FRAGMENT, profileFragment.node || null);
  const profileInfoData = useFragment<ProfileFragments_info$key>(PROFILE_INFO_FRAGMENT, profileData?.info || null);
  const primaryDuty = useFragment<ServiceFragmentsOneDuty$key>(GET_ONE_DUTY, profileInfoData?.primaryDuty || null);
  const countryData = useFragment<CountryFragments_info$key>(COUNTRY_FRAGMENT, profileInfoData?.country || null);

  const [commitCreateContact, creatingLoading] = useMutation<ContactMutationsCreateVacancyContactMutation>(CREATE_CONTACT);
  const [commitRemoveContact, removingLoading] = useMutation<ContactMutationsDeleteVacancyContactMutation>(DELETE_CONTACT);

  const handleRemoveContact = useCallback(
    (contactId: string) => {
      commitRemoveContact({
        variables: {
          id: contactId,
        },
        onCompleted: () => {
          setChecked(false);
        },
        updater: RemoveContactUpdater(`${vacancyId}`),
      });
    },
    [commitRemoveContact, vacancyId],
  );

  const handleCreateContact = useCallback(() => {
    commitCreateContact({
      variables: {
        data: { vacancyId: vacancyId.toString(), ownerId: profileId! },
      },
      onCompleted: () => {
        setChecked(true);
      },
      updater: CreateContactUpdater(`${vacancyId}`),
    });
  }, [commitCreateContact, vacancyId, profileId]);

  const onChangeField = useCallback(
    (checked: boolean) => {
      if (!isTouchedConnectionToggle && setIsTouchedToggle) {
        setIsTouchedToggle(true);
      }
      if (checked) {
        handleCreateContact();
        return;
      }
      if (contactIsRelatedToCurrentCrew?.id) {
        handleRemoveContact(contactIsRelatedToCurrentCrew.id.toString());
      }
    },
    [contactIsRelatedToCurrentCrew, handleCreateContact, handleRemoveContact, isTouchedConnectionToggle, setIsTouchedToggle],
  );

  const handleConfirm = useCallback(() => {
    handleCreateContact();
    toggleShowWindow(false)();
    if (handleHideModalAndConfirm) {
      handleHideModalAndConfirm();
    }
  }, [handleCreateContact, handleHideModalAndConfirm, toggleShowWindow]);

  const handleRejectConfirm = useCallback(() => {
    toggleShowWindow(false)();
    if (handleHideModalAndConfirm) {
      handleHideModalAndConfirm();
    }
  }, [handleHideModalAndConfirm, toggleShowWindow]);

  return (
    <>
      {/*show "Did you contact this user?" modal only if connection toggle button doesn't touched and falsy*/}
      <Modal onClose={handleRejectConfirm} show={!isTouchedConnectionToggle && (showWindow || isShowConfirm)} tinyModal zIndex="z-[70]">
        <div className="flex flex-col items-center">
          <h2 className="font-semibold text-specialGray-1 text-lg mt-5 text-center mb-3">{intl.formatMessage({ id: 'slide_over_with_contact_confirm_title' })}</h2>

          <section className="flex mt-8 justify-between w-full space-x-5">
            <Button label={{ id: 'no' }} buttonType="white-primary" fullSize onClick={handleRejectConfirm} />
            <Button label={{ id: 'yes' }} buttonType="primary" fullSize onClick={handleConfirm} />
          </section>
        </div>
      </Modal>
      <div className="flex flex-col -mx-6">
        <section className="flex space-x-6 px-8">
          <ProfileAvatar avatarUrl={profileInfoData?.avatarUrl} size="w-16 h-16" />
          <div className="flex flex-col">
            <span className="uppercase text-blue text-xs">{primaryDuty?.name}</span>
            <ProfileFullName profileInfoData={profileInfoData as ProfileFragments_info$data} />
          </div>
        </section>
        <LineSvg classes="my-5" />
        <section className="flex flex-col px-8 space-y-6">
          <SlideProfileInfoFieldWithIcon icon={<CakeIcon />} description={{ id: 'profile_age' }}>
            {intl.formatMessage({ id: 'short_years_old' }, { years: getUserYears(profileInfoData?.birthDate as string) })}
          </SlideProfileInfoFieldWithIcon>
          <SlideProfileInfoFieldWithIcon icon={<LocationIcon size="w-6 h-6" />} description={{ id: 'profile_location' }}>
            {countryData?.name}&#44;&nbsp;{countryData?.isoCode}
          </SlideProfileInfoFieldWithIcon>
        </section>
        <LineSvg classes="my-5" />
        <section className="flex flex-col px-8 space-y-6">
          {profileContacts?.profileOIDClaims?.edges[0]?.phoneNumber && (
            <SlideProfileInfoFieldWithIcon icon={<PhoneForwardedIcon />} description={{ id: 'profile_mobile' }}>
              {profileContacts?.profileOIDClaims?.edges[0].phoneNumber}
            </SlideProfileInfoFieldWithIcon>
          )}
          {profileContacts?.profileOIDClaims?.edges[0]?.email && (
            <SlideProfileInfoFieldWithIcon icon={<LocationIcon size="w-6 h-6" />} description={{ id: 'profile_email' }}>
              {profileContacts?.profileOIDClaims?.edges[0]?.email}
            </SlideProfileInfoFieldWithIcon>
          )}
        </section>
        {(profileContacts?.profileOIDClaims?.edges[0]?.phoneNumber || profileContacts?.profileOIDClaims?.edges[0]?.email) && <LineSvg classes="my-5" />}
        <div className="flex flex-col px-8 gap-y-5">
          {profileInfoData?.bio && <p className="flex flex-col space-y-7">{profileInfoData?.bio}</p>}
          {vacancyId ? (
            <SettingsCheckBox label="slide_over_with_contact_toggle_contact_label" checked={checked} eventFunction={onChangeField} isLoading={creatingLoading || removingLoading} />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default AppliedCrewSlider;
