// packages
import { Suspense } from 'react';
// routes
import { RoutePaths } from 'app/routing';
// components
import SpinnerIcon from 'icons/SpinnerIcon';
import PrivateRoutes from 'app/PrivateRoutes/PrivateRoutes';
import OrganizationInvites from 'app/Profile/ProfileOrganizationInvites';
import OrganizationInfoPage from 'app/OrganizationInfoPage/OrganizationInfoPage';
import EmployerInfoSkeleton from 'system/skeletons/employers/EmployerInfoSkeleton';
import JobDescriptionView from 'app/ProfileEmployer/components/JobDescriptionPage/JobDescriptionView/JobDescriptionView';
import JobDescriptionCommonPreviewSkeleton from 'system/skeletons/hireProfile/JobDescriptionTabsSkeleton/JobDescriptionCommonPreviewSkeleton';

export const SimpleMainRoute = [
  {
    path: `${RoutePaths.ProfileBase}/${RoutePaths.JobOpeningId}`,
    element: (
      <Suspense fallback={<JobDescriptionCommonPreviewSkeleton />}>
        <JobDescriptionView />
      </Suspense>
    ),
  },
  {
    path: RoutePaths.orgInfoById,
    element: (
      <Suspense fallback={<EmployerInfoSkeleton />}>
        <OrganizationInfoPage />
      </Suspense>
    ),
  },
  {
    path: RoutePaths.ProfileOrganizationInvites,
    element: (
      <PrivateRoutes>
        <Suspense fallback={<SpinnerIcon />}>
          <OrganizationInvites />
        </Suspense>
      </PrivateRoutes>
    ),
  },
];
