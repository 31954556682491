import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
//types
import { CustomRouterLinkButtonProps } from './types';

const CustomRouterLinkButton = ({ label, children, classNameStyle = '', isDisabled = false, to, callBackFunction }: CustomRouterLinkButtonProps) => {
  const intl = useIntl();
  const disabledStyle = isDisabled && 'text-specialGray-075 pointer-events-none';

  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isDisabled) return e.preventDefault();
    if (callBackFunction) callBackFunction();
  };

  return (
    <Link aria-disabled={isDisabled} onClick={onClick} to={to} className={`flex w-fit items-center text-darkBlue text-sm font-medium ${classNameStyle} ${disabledStyle}`}>
      {children}
      {label && <span>{intl.formatMessage(label)}</span>}
    </Link>
  );
};

export default CustomRouterLinkButton;
