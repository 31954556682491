import { useAuth } from 'authentication';
import Skeleton from 'react-loading-skeleton';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const NavBarSkeleton = () => {
  const { identity } = useAuth();

  return (
    <div className="w-full sm:px-8 xl:px-16 relative flex h-[72px] bg-white shadow justify-center sm:justify-between items-center">
      <div className="flex items-center justify-center sm:items-stretch sm:justify-start">
        <div className="flex flex-col justify-center space-y-1.5">
          <Skeleton width={75} height={30} containerClassName="h-8" />
          <Skeleton width={75} height={10} />
        </div>
      </div>
      {identity && (
        <>
          <div className="flex w-1/3 items-center px-1">
            <Skeleton width={430} height={48} borderRadius={12} />
          </div>
          <div className="flex items-center space-x-9">
            <div className="flex items-center sm:gap-x-12">
              <Skeleton width={104} height={14} />
              <Skeleton width={116} height={14} />
            </div>
            <div className="flex gap-x-2.5">
              <ChevronDownIcon className="w-3" />
              <Skeleton width={36} height={36} borderRadius={6} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NavBarSkeleton;
