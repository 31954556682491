import Skeleton from 'react-loading-skeleton';
import { useBreakpoints } from 'hooks/useBreakpoints';

const PhoneNumberSkeleton = () => {
  const { sm } = useBreakpoints();

  return (
    <div className="flex flex-col w-full">
      <Skeleton width={110} />
      <div className="flex w-full flex-col sm:flex-row">
        <div className="flex sm:w-4/5 w-full sm:justify-start justify-between">
          <Skeleton width={sm ? 130 : 150} height={sm ? 34 : 38} className="mr-2 sm:mr-0" />
          <div className="flex flex-col w-2/4 relative sm:mx-auto">
            <Skeleton width="100%" height={sm ? 34 : 38} />
          </div>
        </div>
        <div className="flex sm:w-1/4 w-full justify-between mt-4 sm:mt-0">
          <Skeleton width={66} height={34} />
          <Skeleton width={24} height={28} className="mt-1.5" />
        </div>
      </div>
    </div>
  );
};

export default PhoneNumberSkeleton;
