import Skeleton from 'react-loading-skeleton';
import { useSkeletonContext } from 'Context/StorybookContext';
import ProfileRatingSkeleton from './ProfileRatingSkeleton';

const ProfileBasicInfoSkeleton = () => {
  const { isSelfProfile } = useSkeletonContext();

  return (
    <div className="flex space-x-5">
      <Skeleton width={83} height={83} borderRadius="0.375rem" />
      <div className="flex flex-col md:flex-row w-full items-center sm:items-start h-full sm:space-y-3 md:space-y-0 justify-between">
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row w-full space-x-5">
            <Skeleton width={150} height={40} />
            {isSelfProfile ? (
              <>
                <Skeleton width={44} height={40} borderRadius={10} />
                <Skeleton width={44} height={40} borderRadius={10} />
              </>
            ) : (
              <Skeleton width={155} height={34} borderRadius={6} />
            )}
          </div>
          <div className="flex flex-col space-y-2">
            <div className="flex space-x-2">
              <Skeleton width={180} />
              <Skeleton width={65} />
            </div>
            <div className="flex space-x-4">
              <div className="flex space-x-1">
                <Skeleton width={24} height={18} />
                <Skeleton width={60} />
              </div>
              <Skeleton width={100} />
              <Skeleton width={50} />
              <Skeleton width={50} />
              <Skeleton width={100} />
              <Skeleton width={100} />
              <Skeleton width={100} />
            </div>
          </div>
        </div>
        <ProfileRatingSkeleton />
      </div>
    </div>
  );
};

export default ProfileBasicInfoSkeleton;
