/**
 * @generated SignedSource<<18abd06dadcd15afff3181b26c2f78c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type VacancyStatus = 'CLOSED' | 'OPEN' | '%future added value';
export type ApplicationQueriesGetCrewApplicationsQuery$variables = {
  after?: any | null;
  first?: number | null;
  ownerId: string;
  skip: boolean;
};
export type ApplicationQueriesGetCrewApplicationsQuery$data = {
  readonly userApplications?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdAt: String;
        readonly id: string;
        readonly vacancy: {
          readonly contacts: ReadonlyArray<{
            readonly id: string;
            readonly owner: {
              readonly id: string;
            };
          }> | null;
          readonly createdAt: String;
          readonly duties: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
          }> | null;
          readonly id: string;
          readonly organization: {
            readonly id: string;
          };
          readonly status: VacancyStatus;
          readonly title: string;
        };
      } | null;
    } | null> | null;
  } | null;
};
export type ApplicationQueriesGetCrewApplicationsQuery = {
  response: ApplicationQueriesGetCrewApplicationsQuery$data;
  variables: ApplicationQueriesGetCrewApplicationsQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'after',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'first',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'ownerId',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'skip',
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'createdAt',
      storageKey: null,
    },
    v6 = [v4 /*: any*/],
    v7 = [
      {
        condition: 'skip',
        kind: 'Condition',
        passingValue: false,
        selections: [
          {
            alias: null,
            args: [
              {
                kind: 'Variable',
                name: 'after',
                variableName: 'after',
              },
              {
                kind: 'Variable',
                name: 'first',
                variableName: 'first',
              },
              {
                kind: 'Variable',
                name: 'ownerId',
                variableName: 'ownerId',
              },
            ],
            concreteType: 'ApplicationConnection',
            kind: 'LinkedField',
            name: 'userApplications',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'ApplicationEdge',
                kind: 'LinkedField',
                name: 'edges',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    concreteType: 'Application',
                    kind: 'LinkedField',
                    name: 'node',
                    plural: false,
                    selections: [
                      v4 /*: any*/,
                      v5 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        concreteType: 'Vacancy',
                        kind: 'LinkedField',
                        name: 'vacancy',
                        plural: false,
                        selections: [
                          v4 /*: any*/,
                          {
                            alias: null,
                            args: null,
                            kind: 'ScalarField',
                            name: 'title',
                            storageKey: null,
                          },
                          {
                            alias: null,
                            args: null,
                            concreteType: 'Organization',
                            kind: 'LinkedField',
                            name: 'organization',
                            plural: false,
                            selections: v6 /*: any*/,
                            storageKey: null,
                          },
                          {
                            alias: null,
                            args: null,
                            kind: 'ScalarField',
                            name: 'status',
                            storageKey: null,
                          },
                          v5 /*: any*/,
                          {
                            alias: null,
                            args: null,
                            concreteType: 'Duty',
                            kind: 'LinkedField',
                            name: 'duties',
                            plural: true,
                            selections: [
                              v4 /*: any*/,
                              {
                                alias: null,
                                args: null,
                                kind: 'ScalarField',
                                name: 'name',
                                storageKey: null,
                              },
                            ],
                            storageKey: null,
                          },
                          {
                            alias: null,
                            args: null,
                            concreteType: 'Contact',
                            kind: 'LinkedField',
                            name: 'contacts',
                            plural: true,
                            selections: [
                              v4 /*: any*/,
                              {
                                alias: null,
                                args: null,
                                concreteType: 'Profile',
                                kind: 'LinkedField',
                                name: 'owner',
                                plural: false,
                                selections: v6 /*: any*/,
                                storageKey: null,
                              },
                            ],
                            storageKey: null,
                          },
                        ],
                        storageKey: null,
                      },
                    ],
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'ApplicationQueriesGetCrewApplicationsQuery',
      selections: v7 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v2 /*: any*/, v0 /*: any*/, v1 /*: any*/, v3 /*: any*/],
      kind: 'Operation',
      name: 'ApplicationQueriesGetCrewApplicationsQuery',
      selections: v7 /*: any*/,
    },
    params: {
      cacheID: 'b5ec819f6b53a5d5970cc79d662c91fc',
      id: null,
      metadata: {},
      name: 'ApplicationQueriesGetCrewApplicationsQuery',
      operationKind: 'query',
      text: 'query ApplicationQueriesGetCrewApplicationsQuery(\n  $ownerId: ID!\n  $after: Cursor\n  $first: Int\n  $skip: Boolean!\n) {\n  userApplications(ownerId: $ownerId, first: $first, after: $after) @skip(if: $skip) {\n    edges {\n      node {\n        id\n        createdAt\n        vacancy {\n          id\n          title\n          organization {\n            id\n          }\n          status\n          createdAt\n          duties {\n            id\n            name\n          }\n          contacts {\n            id\n            owner {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '28af30ad5665a5f609989ce9898d3274';

export default node;
