import { useIntl } from 'react-intl';
import { PropsWithChildren } from 'react';
//components
import ProfileEmptyIcon from 'icons/ProfileEmptyIcon';

const EmptyOverviewTab = ({ children }: PropsWithChildren) => {
  const intl = useIntl();
  return (
    <>
      <section className="flex justify-center mt-14 sm:mt-28">
        <ProfileEmptyIcon />
      </section>

      <div className="flex flex-col items-center mt-5">
        <h2 className="text-specialGray-1 sm:text-2xl text-xl font-semibold">{intl.formatMessage({ id: 'self_profile_no_certificate_yet' })}</h2>
        <>
          <span className="text-xs text-specialGray-075 text-center mt-3 w-3/4 mb-5">{intl.formatMessage({ id: 'profile_text' })}</span>
          {children}
        </>
      </div>
    </>
  );
};

export default EmptyOverviewTab;
