// packages
import React, { Suspense, useCallback, useEffect, useRef, useState, KeyboardEvent } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { useField } from 'formik';
import { MessageDescriptor, useIntl } from 'react-intl';
import { debounce, differenceBy, find, findLast, includes, isEmpty, isEqual } from 'lodash';
// components
import ChoseElement from 'system/ChoseElement';
import { OptionType } from 'system/SelectInputGroup';
import SelectInputCommon from 'system/SelectInputCommon';
import { FieldErrorMessage } from 'system/FieldErrorMessage';
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';
import DropDownListSkeleton from 'system/skeletons/lists/DropDownListSkeleton';
import DropDownListWithTags from 'system/TagInputGroup/components/DropDownListWithTags';
// generated
import { ServiceFragmentsActivities$data } from 'schemas/services/__generated__/ServiceFragmentsActivities.graphql';
// models
import { IActivities } from 'models/IActivity';
import { TagInputGroupProps } from 'models/modelsOfComponents';

const TagInputGroup: React.FC<TagInputGroupProps> = ({
  name,
  openByDefault,
  defaultSelectedElement,
  defaultFocusedElement,
  label,
  defaultArrayOfTags,
  helpText,
  autoComplete,
  maxCharCount,
  ...props
}) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isOpenList, setIsOpenList] = useState<boolean>(openByDefault || false);
  const containerRef = useRef<HTMLDivElement>(null);

  const [elementInFocus, setElementInFocus] = useState<string | undefined>('');
  const [indexChoseElement, setIndexChoseElement] = useState<number>(0);
  const [arrayOfTags, setArrayOfTags] = useState<ServiceFragmentsActivities$data>(defaultArrayOfTags || []);
  const [substr, setSubstr] = useState<string>('');

  const [arrayOfOptionsList, setArrayOfOptionsList] = useState<IActivities | null>([]);
  const [options, setOptions] = useState<IActivities>([]);

  const [{ onBlur, ...field }, { error, touched, initialError, initialTouched }, { setValue, setTouched }] = useField<readonly OptionType[]>({
    name,
  });

  const [count, setCount] = useState(0);
  const [valueForInput, setValueForInput] = useState<string>('');
  const isFindElement = useCallback((array: IActivities, value: string) => findLast(array, element => includes(element.id, value)), []);
  const [popOverHelperIsShow, setPopOverHelperIsShow] = useState<boolean>(false);

  const [showError, setShowError] = useState<string | false | undefined>(touched && !!valueForInput && error);

  const classesDefault = 'relative border border-solid shadow-sm flex-col-reverse sm:flex-row sm:items-center w-full border-specialGray-012 rounded-md flex p-1 flex-wrap pr-8';
  const classesDisabled = props.disabled ? 'cursor-not-allowed' : '';
  const classesWithError = showError ? 'block w-full pr-10 border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' : '';
  const placeholderWithError = showError
    ? 'placeholder-red-300 focus:outline-none focus:border-red-500 focus:ring-red-500'
    : 'focus:border-blue-500 focus:ring-blue-500 placeholder:text-specialGray-05';

  useEffect(() => {
    if (initialTouched && initialError) {
      setShowError(initialTouched && initialError);
    } else if (!elementInFocus) {
      setShowError(touched && !!valueForInput && error);
    }
  }, [touched, error, valueForInput, initialTouched, initialError, elementInFocus]);

  useEffect(() => {
    return () => {
      setTouched(false);
    };
    // eslint-disable-next-line
  }, []);

  const debouncedSetSubtitle = useRef(
    debounce((newValue: string | undefined, isOpenList: boolean) => {
      if (isOpenList) {
        setSubstr(newValue as string);
      }
    }, 500),
  );

  const debouncedSetError = useRef(
    debounce((newValue: string | undefined) => {
      if (newValue && maxCharCount) {
        if ((newValue as string).length > maxCharCount) {
          setShowError('service_tag_name_long');
          setIsOpenList(false);
        } else {
          setIsOpenList(true);
          setShowError(undefined);
        }
      }
    }, 500),
  );

  useEffect(() => {
    debouncedSetSubtitle.current(valueForInput, isOpenList);
  }, [valueForInput, isOpenList]);

  useEffect(() => {
    setCount(valueForInput.length);
    debouncedSetError.current(valueForInput);
  }, [valueForInput, maxCharCount]);

  useEffect(() => {
    const differenceArray = differenceBy(options, Array.prototype.concat(arrayOfTags), 'id');
    if (!isEmpty(options) && !isEmpty(differenceArray)) {
      setArrayOfOptionsList(() => differenceArray);
    } else if (valueForInput) {
      setArrayOfOptionsList(() => [{ id: valueForInput, label: valueForInput }]);
    } else {
      setArrayOfOptionsList([]);
    }
  }, [options, arrayOfTags, valueForInput]);

  useEffect(() => {
    if (options && !isEmpty(options) && !isFindElement(options, valueForInput) && !!valueForInput) {
      setArrayOfOptionsList(prevState => {
        if (!isFindElement(prevState as IActivities, valueForInput)) {
          return [...(prevState as IActivities), { id: valueForInput, label: valueForInput }];
        } else {
          return prevState;
        }
      });
    }
  }, [options, valueForInput, isFindElement]);

  useEffect(() => {
    if (options && !isEmpty(options) && !!valueForInput) {
      setArrayOfOptionsList(prevState => {
        if (find(prevState, element => element.label === valueForInput && element.id !== valueForInput)) {
          return prevState?.filter(element => element.label !== valueForInput) as IActivities;
        } else {
          return prevState;
        }
      });
    }
  }, [valueForInput, options]);

  useEffect(() => {
    if (defaultSelectedElement && defaultFocusedElement) {
      setElementInFocus(defaultFocusedElement?.id);
    } else if (defaultSelectedElement) {
      setElementInFocus(defaultSelectedElement?.id);
    }
  }, [defaultSelectedElement, defaultFocusedElement]);

  const handleBlur = useCallback(
    (event?: React.ChangeEvent) => {
      if (event) {
        onBlur(event);
      }
      setValue(Array.prototype.concat(arrayOfTags));
      setValueForInput('');
      setElementInFocus('');
    },
    [arrayOfTags, onBlur, setValue],
  );

  const handleSetValueForInput = useCallback(
    (value: string) => {
      arrayOfOptionsList?.forEach(({ id, label }: { id: string; label: string }) => {
        if (value === id) {
          setArrayOfTags((prevState: ServiceFragmentsActivities$data) => {
            if (prevState) {
              return Array.prototype.concat(prevState, [{ id, label }]);
            } else {
              return [];
            }
          });
        }
      });
      handleBlur();
    },
    [arrayOfOptionsList, handleBlur],
  );

  const handleChooseElement = useCallback(
    (event: KeyboardEvent<HTMLDivElement>, value: string) => {
      event.stopPropagation();
      handleSetValueForInput(value);
      setIsOpenList(false);
    },
    [handleSetValueForInput],
  );

  const handleMouseUp = useCallback(() => {
    setIndexChoseElement(0);
    setElementInFocus(arrayOfOptionsList?.[0]?.id!);
  }, [arrayOfOptionsList]);

  const removeItemFromArrayOfTags = useCallback(
    (tagId: string) => () => {
      setArrayOfTags((prevState: ServiceFragmentsActivities$data) => prevState?.filter(({ id }: { id: string }) => id !== tagId));
    },
    [],
  );

  useEffect(() => {
    if (!showError) {
      setValue(arrayOfTags);
    }
    // eslint-disable-next-line
  }, [arrayOfTags, showError]);

  useEffect(() => {
    setElementInFocus(arrayOfOptionsList?.[0]?.id);
    setIndexChoseElement(0);
  }, [arrayOfOptionsList]);

  useEffect(() => {
    if (props.isResetInput) {
      setArrayOfTags([]);
    }
  }, [props.isResetInput]);

  const handleInputInFocus = useCallback(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  const getOtherActivityLabel: () => MessageDescriptor | undefined = useCallback(() => {
    return !isEqual(options, arrayOfOptionsList) && find(arrayOfOptionsList, element => includes(element.id, valueForInput)) && !!valueForInput
      ? { id: 'service_form_tags_other_activities' }
      : undefined;
  }, [options, arrayOfOptionsList, valueForInput]);

  const handleClickByWindow = useCallback(
    (event: MouseEvent) => {
      if (!containerRef.current?.contains(event.target as HTMLDivElement)) {
        setIsOpenList(false);
        setValueForInput('');
        inputRef.current?.blur();
      }
    },
    [containerRef, inputRef],
  );

  const handleInputFocus = useCallback(() => {
    setPopOverHelperIsShow(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setPopOverHelperIsShow(false);
    setTouched(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window?.addEventListener('mouseup', handleClickByWindow);
    return () => {
      window.removeEventListener('mouseup', handleClickByWindow);
    };
  }, [isOpenList, handleClickByWindow, inputRef]);

  useEffect(() => {
    const input = inputRef.current;
    input?.addEventListener('focus', handleInputFocus);
    return () => {
      input?.removeEventListener('focus', handleInputFocus);
    };
  }, [handleInputFocus]);

  useEffect(() => {
    const input = inputRef.current;
    input?.addEventListener('blur', handleInputBlur);
    return () => {
      input?.removeEventListener('blur', handleInputBlur);
    };
  }, [handleInputBlur]);

  return (
    <div className="relative flex flex-col mb-2 w-full" ref={containerRef}>
      <div className="flex justify-between">
        <label className="inline-block text-sm font-medium text-specialGray-darkBlue mb-2">
          {intl.formatMessage(label)}&nbsp;
          {helpText && (
            <PopoverHelper
              position="bottom-7 sm:left-1/2 sm:-translate-x-1/2 -left-4"
              positionArrow="sm:left-1/2 left-6 rotate-45 -bottom-[3px]"
              defaultText={intl.formatMessage(helpText)}
              symbol="?"
              classes="relative left-1 -bottom-1"
              autoShow={popOverHelperIsShow}
              darkTheme
              widthContainer="w-60 sm:w-[300px]"
            />
          )}
        </label>
        {(maxCharCount as number) > 0 && (
          <span className={`text-xs sm:text-sm ${showError ? 'text-red-600' : 'text-specialGray-05'} my-auto sm:my-0`}>
            ({count}/{maxCharCount})
          </span>
        )}
      </div>
      <div className={`${classesWithError} ${classesDefault}`}>
        <div className="flex flex-row flex-wrap">
          {!isEmpty(arrayOfTags) && arrayOfTags?.map((tag: IActivities[0]) => <ChoseElement key={tag.id} label={tag.label} onClick={removeItemFromArrayOfTags(tag.id)} />)}
        </div>
        <div className="flex items-center flex-auto">
          <MagnifyingGlassIcon className="sm:ml-2 w-6 h-6 text-specialGray-05" />
          <SelectInputCommon
            ref={inputRef}
            onBlur={handleBlur}
            onMouseUp={handleMouseUp}
            onChangeSelect={setSubstr}
            classes={`${classesDisabled} ${placeholderWithError} w-full rounded-md text-xs sm:text-sm border border-transparent focus:border-solid`}
            setValueForInput={setValueForInput}
            valueForInput={valueForInput}
            setIndexChoseElement={setIndexChoseElement}
            indexChoseElement={indexChoseElement}
            setIsOpenList={setIsOpenList}
            elementInFocus={elementInFocus}
            isOpenList={isOpenList}
            onInputInFocus={handleInputInFocus}
            autoComplete={autoComplete}
            {...props}
            {...field}
          />
        </div>
      </div>
      <div className="relative">
        {isOpenList && (
          <Suspense fallback={<DropDownListSkeleton positionAbsolute={true} />}>
            <DropDownListWithTags
              substr={substr}
              setOptions={setOptions}
              options={arrayOfOptionsList}
              arrayOfTags={arrayOfTags}
              getOtherActivityLabel={getOtherActivityLabel}
              handleChooseElement={handleChooseElement}
            />
          </Suspense>
        )}
      </div>
      {showError && <FieldErrorMessage error={error || showError} />}
    </div>
  );
};

export default TagInputGroup;
