import { useFragment, useMutation } from 'react-relay';
import { useRecoilValue } from 'recoil';
//schemas
import { DELETE_VACANCY } from 'schemas/vacancy/VacancyMutations';
import { VACANCY_FRAGMENT } from 'schemas/vacancy/VacancyFragments';
//generated
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { VacancyMutationsDeleteVacancyMutation } from 'schemas/vacancy/__generated__/VacancyMutationsDeleteVacancyMutation.graphql';
//updaters
import { deleteJobOpening } from 'formHelpers/updaters/updatersOfJobOpening';
// recoil
import { fetchDashboardVacanciesByOpenParamsSelector } from 'recoil/Organisation/Dashboard/selectors/fetchDashboardVacanciesByOpenParamsSelector';
import { fetchDashboardVacanciesByShowAllParamsSelector } from 'recoil/Organisation/Dashboard/selectors/fetchDashboardVacanciesByShowAllParamsSelector';

const useDeleteVacancy = (vacancyFragment: VacancyFragments$key, callback?: () => void) => {
  const vacancyData = useFragment<VacancyFragments$key>(VACANCY_FRAGMENT, vacancyFragment);
  const [mutationDeleteVacancy, mutationDeleteLoading] = useMutation<VacancyMutationsDeleteVacancyMutation>(DELETE_VACANCY);
  const allOpenSelectedFilters = useRecoilValue(fetchDashboardVacanciesByOpenParamsSelector);
  const allShowAllSelectedFilters = useRecoilValue(fetchDashboardVacanciesByShowAllParamsSelector);

  const handleDeleteVacancy = () => () => {
    mutationDeleteVacancy({
      variables: {
        id: vacancyData.id,
      },
      onCompleted: () => {
        window.analytics?.track('wui-delete-job-opening', {
          vacancyId: vacancyData.id,
        });
        if (callback) callback();
      },
      updater: deleteJobOpening(allOpenSelectedFilters, allShowAllSelectedFilters),
    });
  };

  return {
    handleDeleteVacancy,
    mutationDeleteLoading,
  };
};

export default useDeleteVacancy;
