// packages
import React, { RefObject, useCallback, useEffect, useState } from 'react';
// hooks
import { useBreakpoints } from 'hooks/useBreakpoints';

export function useGetPositions(refContainer: RefObject<HTMLDivElement>, refWrapper: RefObject<HTMLDivElement>, autoShow?: boolean) {
  const { sm } = useBreakpoints();

  const [hoverElement, setHoverElement] = useState<boolean>(autoShow || false);

  const handleShowPopover = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setHoverElement(prevState => !prevState);
  }, []);

  const handleMouseOver = useCallback(() => {
    if (autoShow || sm) {
      return;
    }
    setHoverElement(true);
  }, [autoShow, sm]);

  const handleMouseLeave = useCallback(
    (event: MouseEvent) => {
      if (hoverElement && !(refWrapper.current as HTMLDivElement)?.contains(event.target as HTMLElement) && !autoShow) {
        setHoverElement(false);
      }
    },
    [hoverElement, refWrapper, autoShow],
  );

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseLeave);
    return () => {
      window.removeEventListener('mousemove', handleMouseLeave);
    };
  }, [handleMouseLeave]);

  return {
    hoverElement,
    handleMouseOver,
    handleShowPopover,
  };
}
