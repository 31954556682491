/**
 * @generated SignedSource<<10b438daa7d7d3e0f4d8d7b5ee132b9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type SalaryPeriod = 'DAILY' | 'MONTHLY' | 'WEEKLY' | '%future added value';
export type StartingCondition = 'CONTINUOUSLY' | 'DATE' | 'IMMEDIATELY' | '%future added value';
export type VacancyDuration = 'ONETERM' | 'PERMANENT' | 'TEMPORARY' | '%future added value';
export type VacancyIndustry = 'NAUTICAL_WORLDWIDE' | 'OFFSHORE_OIL_GAS' | 'OFFSHORE_WIND' | '%future added value';
export type VacancyStatus = 'CLOSED' | 'OPEN' | '%future added value';
export type VacancyData = {
  certificates?: ReadonlyArray<string> | null;
  currencyId?: string | null;
  description?: string | null;
  duration?: VacancyDuration | null;
  duties?: ReadonlyArray<string> | null;
  hires?: ReadonlyArray<string> | null;
  hiringAreaId?: string | null;
  industry?: VacancyIndustry | null;
  organizationId: string;
  responsibilities?: ReadonlyArray<string> | null;
  salaryFrom?: number | null;
  salaryPeriod?: SalaryPeriod | null;
  salaryTo?: number | null;
  startingCondition?: StartingCondition | null;
  startingDate?: String | null;
  status: VacancyStatus;
  title: string;
  tornWeeksOff?: number | null;
  tornWeeksOn?: number | null;
  vesselKindId?: string | null;
};
export type VacancyMutationsUpdateVacancyMutation$variables = {
  data: VacancyData;
  id: string;
};
export type VacancyMutationsUpdateVacancyMutation$data = {
  readonly updateVacancy: {
    readonly 'id': string;
    readonly ' $fragmentSpreads': FragmentRefs<'VacancyFragments'>;
  };
};
export type VacancyMutationsUpdateVacancyMutation = {
  response: VacancyMutationsUpdateVacancyMutation$data;
  variables: VacancyMutationsUpdateVacancyMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'data',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v2 = [
      {
        kind: 'Variable',
        name: 'data',
        variableName: 'data',
      },
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'status',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'description',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'avatarUrl',
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'size',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'websiteUrl',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'alts',
      storageKey: null,
    },
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'perma',
      storageKey: null,
    },
    v12 = [v3 /*: any*/, v9 /*: any*/, v11 /*: any*/],
    v13 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'firstName',
      storageKey: null,
    },
    v14 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'lastName',
      storageKey: null,
    },
    v15 = [
      v3 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'Profile',
        kind: 'LinkedField',
        name: 'owner',
        plural: false,
        selections: [v3 /*: any*/],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'VacancyMutationsUpdateVacancyMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'Vacancy',
          kind: 'LinkedField',
          name: 'updateVacancy',
          plural: false,
          selections: [
            v3 /*: any*/,
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'VacancyFragments',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'VacancyMutationsUpdateVacancyMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'Vacancy',
          kind: 'LinkedField',
          name: 'updateVacancy',
          plural: false,
          selections: [
            v3 /*: any*/,
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'createdAt',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'title',
              storageKey: null,
            },
            v4 /*: any*/,
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'industry',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'salaryPeriod',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'Organization',
              kind: 'LinkedField',
              name: 'organization',
              plural: false,
              selections: [
                v3 /*: any*/,
                v5 /*: any*/,
                v6 /*: any*/,
                v7 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'headquarters',
                  storageKey: null,
                },
                v8 /*: any*/,
                v9 /*: any*/,
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'salaryFrom',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'salaryTo',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'duration',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'responsibilities',
              storageKey: null,
            },
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'Certificate',
              kind: 'LinkedField',
              name: 'certificates',
              plural: true,
              selections: [
                v3 /*: any*/,
                v9 /*: any*/,
                v10 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'priority',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'neverExpires',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'CertificateKind',
                  kind: 'LinkedField',
                  name: 'kind',
                  plural: false,
                  selections: v12 /*: any*/,
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'VesselKind',
              kind: 'LinkedField',
              name: 'vesselKind',
              plural: false,
              selections: v12 /*: any*/,
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'Duty',
              kind: 'LinkedField',
              name: 'duties',
              plural: true,
              selections: [v3 /*: any*/, v9 /*: any*/, v10 /*: any*/, v11 /*: any*/],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'HiringArea',
              kind: 'LinkedField',
              name: 'hiringArea',
              plural: false,
              selections: [v3 /*: any*/, v11 /*: any*/, v9 /*: any*/],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'tornWeeksOff',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'tornWeeksOn',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'Application',
              kind: 'LinkedField',
              name: 'applications',
              plural: true,
              selections: [
                v3 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'Profile',
                  kind: 'LinkedField',
                  name: 'owner',
                  plural: false,
                  selections: [
                    v3 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'ProfileInfo',
                      kind: 'LinkedField',
                      name: 'info',
                      plural: false,
                      selections: [v13 /*: any*/, v14 /*: any*/, v6 /*: any*/, v3 /*: any*/],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                v4 /*: any*/,
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'Invite',
              kind: 'LinkedField',
              name: 'invites',
              plural: true,
              selections: v15 /*: any*/,
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'Currency',
              kind: 'LinkedField',
              name: 'currency',
              plural: false,
              selections: v12 /*: any*/,
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'startingCondition',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'startingDate',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'Contact',
              kind: 'LinkedField',
              name: 'contacts',
              plural: true,
              selections: v15 /*: any*/,
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'OrganizationMember',
              kind: 'LinkedField',
              name: 'organizationMember',
              plural: false,
              selections: [
                v3 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'Profile',
                  kind: 'LinkedField',
                  name: 'profile',
                  plural: false,
                  selections: [
                    v3 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'ProfileInfo',
                      kind: 'LinkedField',
                      name: 'info',
                      plural: false,
                      selections: [v13 /*: any*/, v14 /*: any*/, v3 /*: any*/],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Organization',
                  kind: 'LinkedField',
                  name: 'organization',
                  plural: false,
                  selections: [v3 /*: any*/, v9 /*: any*/, v6 /*: any*/, v7 /*: any*/, v5 /*: any*/, v8 /*: any*/],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '4610185d013a14573301f88a1541e805',
      id: null,
      metadata: {},
      name: 'VacancyMutationsUpdateVacancyMutation',
      operationKind: 'mutation',
      text: 'mutation VacancyMutationsUpdateVacancyMutation(\n  $id: ID!\n  $data: VacancyData!\n) {\n  updateVacancy(id: $id, data: $data) {\n    id\n    ...VacancyFragments\n  }\n}\n\nfragment VacancyFragments on Vacancy {\n  id\n  createdAt\n  title\n  status\n  industry\n  salaryPeriod\n  organization {\n    id\n    description\n    avatarUrl\n    size\n    headquarters\n    websiteUrl\n    name\n  }\n  salaryFrom\n  salaryTo\n  duration\n  responsibilities\n  description\n  certificates {\n    id\n    name\n    alts\n    priority\n    neverExpires\n    kind {\n      id\n      name\n      perma\n    }\n  }\n  vesselKind {\n    id\n    name\n    perma\n  }\n  duties {\n    id\n    name\n    alts\n    perma\n  }\n  hiringArea {\n    id\n    perma\n    name\n  }\n  tornWeeksOff\n  tornWeeksOn\n  applications {\n    id\n    owner {\n      id\n      info {\n        firstName\n        lastName\n        avatarUrl\n        id\n      }\n    }\n    status\n  }\n  invites {\n    id\n    owner {\n      id\n    }\n  }\n  currency {\n    id\n    name\n    perma\n  }\n  startingCondition\n  startingDate\n  contacts {\n    id\n    owner {\n      id\n    }\n  }\n  organizationMember {\n    id\n    profile {\n      id\n      info {\n        firstName\n        lastName\n        id\n      }\n    }\n    organization {\n      id\n      name\n      avatarUrl\n      size\n      description\n      websiteUrl\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'f2690f6baf2c7ae4e3c4187ebc7ef296';

export default node;
