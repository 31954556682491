import CertificateExpiryDateItem from 'app/Profile/Certificates/CertificateItem/components/CertificateExpiyDate/components/CertificateExpiryDateItem';
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
//schemas
import { LIST_OF_CERTIFICATE_INFOS } from 'schemas/certificates/CertificateFragments';
import { ProfileFragments$data } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { CertificateFragmentsCertificateInfo$key } from 'schemas/certificates/__generated__/CertificateFragmentsCertificateInfo.graphql';
//models
import { ICertificateInfoEdge } from 'models/ICertificate';
//components
import PaperIcon from 'icons/PaperSmall';
import FileImageComponentEv from '../components/FileImageComponentEV';
import OverviewCrewInfoEvTitle from '../components/OverviewCrewInfoEVTitle';
import OverviewCrewInfoEmptyItem from '../components/OverviewCrewInfoEmptyItem';
import OverviewTextItemWithPopover from '../components/OverviewTextItemWithPopover';
import CertificateTitle from 'app/Profile/Certificates/CertificateItem/components/CertificateTitle';
//hooks
import useSortedCertificates from 'hooks/useSortedCertificates';

const CrewsCertificatesEmployerView = ({ profileCrewData }: { profileCrewData: ProfileFragments$data['certificateInfos'] }) => {
  const intl = useIntl();
  const certificateFromProfile = useFragment<CertificateFragmentsCertificateInfo$key>(LIST_OF_CERTIFICATE_INFOS, profileCrewData || null);
  const sortedCertificates = useSortedCertificates(certificateFromProfile);

  const isCertificatesExist = sortedCertificates && sortedCertificates?.length > 0;

  return (
    <section className="flex w-full flex-col space-y-6">
      <OverviewCrewInfoEvTitle
        title={intl.formatMessage({ id: 'profile_certificatesTitle' })}
        description={intl.formatMessage({ id: 'profile_certificatesAmount' }, { amount: certificateFromProfile?.length })}
      />
      {isCertificatesExist ? (
        <div className="flex w-full flex-col space-y-6">
          {sortedCertificates.map((certificatesData, index) => {
            const [certificateKind, certificates] = certificatesData;
            return (
              <div key={index} className="overflow-hidden border border-specialGray-012 rounded-xl w-full">
                <table className="min-w-full divide-y divide-specialGray-012">
                  <thead className="bg-specialGray-004 text-specialGray-075 text-xs uppercase">
                    <tr>
                      <th scope="col" className="text-left w-full font-normal h-[40px] pl-5 pr-[46px]">
                        {certificateKind}
                      </th>
                      <th scope="col" className="text-left font-normal pl-5 pr-[46px]">
                        {intl.formatMessage({ id: 'title_file' })}
                      </th>
                      <th scope="col" className="text-left font-normal pr-[46px] whitespace-nowrap">
                        {intl.formatMessage({ id: 'certificate_expiry_date' })}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-specialGray-012 bg-white text-specialGray-075 text-xs">
                    {certificates.map(certificate => {
                      const isDocumentsExist = !!certificate.document?.urls && !!certificate.document.xlarge_urls;
                      return (
                        <tr key={certificate.id}>
                          <td className="flex min-h-[64px] items-center w-full justify-between pl-5 pr-[46px]">
                            <CertificateTitle certificateInfo={certificate as ICertificateInfoEdge} />
                            <OverviewTextItemWithPopover additionalClasses="max-w-[45%] text-end" description={certificate?.certificate.alts.join(', ')} />
                          </td>
                          <td className="text-left font-normal min-h-[64px] pl-5 pr-[46px]">
                            {isDocumentsExist ? (
                              <FileImageComponentEv
                                title={{ id: 'certificate_files' }}
                                smallImages={certificate.document?.urls}
                                fullSizeImages={certificate.document?.xlarge_urls}
                              />
                            ) : (
                              <span className="whitespace-nowrap text-specialGray-03">{intl.formatMessage({ id: 'cert_no_file' })}</span>
                            )}
                          </td>
                          <td className="text-left font-normal pr-[46px] min-w-[136px]">
                            {certificate.expires ? (
                              <CertificateExpiryDateItem expiryDate={certificate.expires} popoverText="crew_certificate_expired" />
                            ) : (
                              <span className="text-specialGray-03 text-xs">{intl.formatMessage({ id: 'unknown' })}</span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
      ) : (
        <OverviewCrewInfoEmptyItem title={intl.formatMessage({ id: 'profile_no_certificates_added' })}>
          <PaperIcon />
        </OverviewCrewInfoEmptyItem>
      )}
    </section>
  );
};

export default CrewsCertificatesEmployerView;
