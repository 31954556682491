// packages
import React from 'react';
import { useIntl } from 'react-intl';
import { ExclamationTriangleIcon, InformationCircleIcon, XCircleIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import translations from 'translations';
// models
import { AlertProps } from 'models/modelsOfComponents';

// Alert implements: https://tailwindui.com/components/application-ui/feedback/alerts
export const Alert: React.FC<AlertProps> = ({ heading, message, type = 'warning', errorId }) => {
  const intl = useIntl();
  let additionalData = {};

  if (message.description) {
    Object.assign(additionalData, message.description);
  }

  switch (type) {
    case 'error':
      return (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">{intl.formatMessage(heading)}</h3>
              {errorId && <h3 className="text-sm font-medium text-red-800">{intl.formatMessage({ id: 'error_id' }, { id: errorId })}</h3>}
              <div className={'mt-2 text-sm text-red-700'}>
                <p>{!JSON.stringify(message).includes(translations.error_boundary_text) ? intl.formatMessage(message, additionalData) : translations.error_boundary_text}</p>
              </div>
            </div>
          </div>
        </div>
      );
    case 'warning':
      return (
        <div className="rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">{intl.formatMessage(heading)}</h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>{intl.formatMessage(message, additionalData)}</p>
              </div>
            </div>
          </div>
        </div>
      );
    case 'info':
      return (
        <div className="rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-blue-800">{intl.formatMessage(heading)}</h3>
              <div className="mt-2 text-sm text-blue-700">
                <p>{intl.formatMessage(message, additionalData)}</p>
              </div>
            </div>
          </div>
        </div>
      );
    case 'success':
      return (
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">{intl.formatMessage(heading)}</h3>
              <div className="mt-2 text-sm text-green-700">
                <p>{intl.formatMessage(message, additionalData)}</p>
              </div>
            </div>
          </div>
        </div>
      );
  }
};
