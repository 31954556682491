import React, { PropsWithChildren, useContext, useState } from 'react';

interface HireProfileContextInterface {
  hireOrganization: { id: string } | null;
  setHireOrganization: React.Dispatch<React.SetStateAction<{ id: string } | null>>;
}

const hireProfileContext: HireProfileContextInterface = {
  hireOrganization: null,
  setHireOrganization: () => {},
};

const HireProfileCtx = React.createContext<HireProfileContextInterface>(hireProfileContext);

export function useHireProfileContext() {
  return useContext(HireProfileCtx);
}

const HireProfileContext = ({ children }: PropsWithChildren) => {
  const [hireOrganization, setHireOrganization] = useState<{ id: string } | null>(null);
  return <HireProfileCtx.Provider value={{ hireOrganization, setHireOrganization }}>{children}</HireProfileCtx.Provider>;
};

export default HireProfileContext;
