import graphql from 'babel-plugin-relay/macro';

export const ADD_CERTIFICATE = graphql`
  mutation CertificateMutationsAddMutation($pid: ID!, $cid: ID!, $data: CertificateInfoData!) {
    createCertificateInfo(pid: $pid, cid: $cid, data: $data) {
      node {
        id
        certificate {
          ...CertificateFragments_single
        }
      }
    }
  }
`;

export const REMOVE_CERTIFICATE_ITEM_MUTATION = graphql`
  mutation CertificateMutationsRemoveMutation($id: ID!) {
    deleteCertificateInfo(id: $id) {
      id
    }
  }
`;

export const UPDATE_CERTIFICATE_ITEM_MUTATION = graphql`
  mutation CertificateMutationsUpdateCertificateInfoMutation($id: ID!, $certificatesInfoData: CertificateInfoData!) {
    updateCertificateInfo(id: $id, data: $certificatesInfoData) {
      id
      expires
      owner {
        id
      }
      certificate {
        ...CertificateFragments_single
      }
      document {
        id
        urls
        xlarge_urls
      }
    }
  }
`;
