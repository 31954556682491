/**
 * @generated SignedSource<<b339300edd9a8ce649d0551f443c94a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProgressData = {
  addedFirstCertificate?: boolean | null;
  addedFirstSeaService?: boolean | null;
  createdFirstEducationItem?: boolean | null;
  cvDownloaded?: boolean | null;
  filledOutBasicInfo?: boolean | null;
  wroteABio?: boolean | null;
};
export type ProfileMutationsProfileProgressMutation$variables = {
  data: ProgressData;
  pid: string;
};
export type ProfileMutationsProfileProgressMutation$data = {
  readonly updateProgress: {
    readonly addedFirstCertificate: boolean;
    readonly addedFirstSeaService: boolean;
    readonly createdFirstEducationItem: boolean;
    readonly cvDownloaded: boolean;
    readonly filledOutBasicInfo: boolean;
    readonly wroteABio: boolean;
  };
};
export type ProfileMutationsProfileProgressMutation = {
  response: ProfileMutationsProfileProgressMutation$data;
  variables: ProfileMutationsProfileProgressMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'data',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'pid',
    },
    v2 = [
      {
        kind: 'Variable',
        name: 'data',
        variableName: 'data',
      },
      {
        kind: 'Variable',
        name: 'pid',
        variableName: 'pid',
      },
    ],
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'filledOutBasicInfo',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'addedFirstSeaService',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'wroteABio',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'createdFirstEducationItem',
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'addedFirstCertificate',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cvDownloaded',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileMutationsProfileProgressMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'Progress',
          kind: 'LinkedField',
          name: 'updateProgress',
          plural: false,
          selections: [v3 /*: any*/, v4 /*: any*/, v5 /*: any*/, v6 /*: any*/, v7 /*: any*/, v8 /*: any*/],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'ProfileMutationsProfileProgressMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'Progress',
          kind: 'LinkedField',
          name: 'updateProgress',
          plural: false,
          selections: [
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/,
            v8 /*: any*/,
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'id',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '3b7517a6fbd7f3c8775b9717fb1738eb',
      id: null,
      metadata: {},
      name: 'ProfileMutationsProfileProgressMutation',
      operationKind: 'mutation',
      text: 'mutation ProfileMutationsProfileProgressMutation(\n  $pid: ID!\n  $data: ProgressData!\n) {\n  updateProgress(pid: $pid, data: $data) {\n    filledOutBasicInfo\n    addedFirstSeaService\n    wroteABio\n    createdFirstEducationItem\n    addedFirstCertificate\n    cvDownloaded\n    id\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'fdde9bd02774b20174fa45489065c9e5';

export default node;
