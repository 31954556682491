// packages
import { FC, useState } from 'react';
import { useMutation, useRefetchableFragment } from 'react-relay';
//hooks
import { useAuth } from 'authentication';
//enums
import { NOTIFICATION_PREFERENCE } from 'models/enums';
//schemas
import { PROFILE_FRAGMENT } from 'schemas/profile/ProfileFragments';
import { CREATE_NOTIFICATION_PREFERENCE, UPDATE_NOTIFICATION_PREFERENCE } from 'schemas/profile/ProfileMutations';
//generated
import { ProfileFragments$key } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { ProfileQueriesRootQuery$data } from 'schemas/profile/__generated__/ProfileQueriesRootQuery.graphql';
import { ProfileQueriesRootRefreshQuery } from 'schemas/profile/__generated__/ProfileQueriesRootRefreshQuery.graphql';
import { ProfileMutationsCreateNotificationPreferenceMutation } from 'schemas/profile/__generated__/ProfileMutationsCreateNotificationPreferenceMutation.graphql';
import { ProfileMutationsUpdateNotificationPreferenceMutation } from 'schemas/profile/__generated__/ProfileMutationsUpdateNotificationPreferenceMutation.graphql';
//models
import { PreferencesNotificationStateProps } from 'models/modelsOfComponents';
//mocks
import { notificationPreferences } from 'mocks/mockData';
//components
import SettingsCheckBox from 'system/SettingsCheckBox/SettingsCheckBox';
//utils
import { correctAnalyticsEventName } from 'utils';

const NotificationPreferences: FC<{ selfProfileQuery: ProfileQueriesRootQuery$data | null }> = ({ selfProfileQuery }) => {
  const { identity } = useAuth();

  const [selfProfileData, refetchProfileData] = useRefetchableFragment<ProfileQueriesRootRefreshQuery, ProfileFragments$key>(PROFILE_FRAGMENT, selfProfileQuery?.node || null);

  const [commitCreateNotificationPreference] = useMutation<ProfileMutationsCreateNotificationPreferenceMutation>(CREATE_NOTIFICATION_PREFERENCE);
  const [commitUpdateNotificationPreference] = useMutation<ProfileMutationsUpdateNotificationPreferenceMutation>(UPDATE_NOTIFICATION_PREFERENCE);

  const [isLoadingElements, setIsLoadingElements] = useState<string[]>([]);

  const [preferencesNotifications, setPreferencesNotifications] = useState<PreferencesNotificationStateProps>({
    [NOTIFICATION_PREFERENCE.jobOpeningNotifications]: selfProfileData?.notificationPreference?.jobOpening || false,
    [NOTIFICATION_PREFERENCE.tipsToImproveNotifications]: selfProfileData?.notificationPreference?.tipsToImprove || false,
  });

  const createMixedPreferences = (checkBoxPreference: NOTIFICATION_PREFERENCE): PreferencesNotificationStateProps => {
    return {
      ...preferencesNotifications,
      [checkBoxPreference]: !preferencesNotifications[checkBoxPreference],
    };
  };
  const removeLoadingElement = (el: NOTIFICATION_PREFERENCE) => {
    setIsLoadingElements(prevState => {
      return prevState.filter(listOfLoadIds => listOfLoadIds !== el);
    });
  };

  const updateNotificationPreference = (checkBoxPreference: NOTIFICATION_PREFERENCE) => () => {
    if (!identity?.profileId) {
      return;
    }
    setIsLoadingElements(prevState => [...prevState, checkBoxPreference]);
    //update preferences
    if (selfProfileData?.notificationPreference?.id) {
      commitUpdateNotificationPreference({
        variables: {
          id: selfProfileData?.notificationPreference?.id,
          data: {
            ownerId: identity.profileId.toString(),
            ...createMixedPreferences(checkBoxPreference),
          },
        },
        onError: error => {
          removeLoadingElement(checkBoxPreference);
          throw error;
        },

        onCompleted: data => {
          //from "jobOpening" to "job-opening" etc.
          const eventName = correctAnalyticsEventName(checkBoxPreference);
          const analyticsTrackSentence = data.updateNotificationPreference[checkBoxPreference] ? `subscribe-${eventName}` : `unsubscribe-${eventName}`;
          //example of the compared event name - "wui-notification-preference-unsubscribe-tips-to-improve" / "wui-notification-preference-subscribe-tips-to-improve"
          window.analytics?.track(`wui-notification-preference-${analyticsTrackSentence}`);
          removeLoadingElement(checkBoxPreference);
          setPreferencesNotifications(prev => {
            return { ...prev, [checkBoxPreference]: data.updateNotificationPreference[checkBoxPreference] };
          });
        },
      });
      //create preferences
    } else {
      commitCreateNotificationPreference({
        variables: {
          data: {
            ownerId: identity.profileId.toString(),
            ...createMixedPreferences(checkBoxPreference),
          },
        },
        onError: error => {
          removeLoadingElement(checkBoxPreference);
          throw error;
        },
        onCompleted: data => {
          const eventName = correctAnalyticsEventName(checkBoxPreference);
          window.analytics?.track(`wui-settings-notification-preference-subscribe-${eventName}`);

          removeLoadingElement(checkBoxPreference);
          //refetch for first create necessary to get notificationPreference?.id
          refetchProfileData({}, { fetchPolicy: 'network-only' });
          setPreferencesNotifications(prev => {
            return { ...prev, [checkBoxPreference]: data.createNotificationPreference[checkBoxPreference] };
          });
        },
      });
    }
  };

  return (
    <div className="flex flex-col justify-items-start w-auto space-y-8 sm:w-2/3">
      {notificationPreferences.map(({ id, label, description }) => {
        const isLoadingElement = isLoadingElements.includes(id);
        return (
          <SettingsCheckBox
            eventFunction={updateNotificationPreference(id)}
            checked={preferencesNotifications[id]}
            key={id}
            label={label}
            isLoading={isLoadingElement}
            description={description}
          />
        );
      })}
    </div>
  );
};

export default NotificationPreferences;
