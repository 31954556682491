import { format } from 'date-fns';
import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: 'default_requiredField',
    notType: function notType({ type }: { type: string }) {
      switch (type) {
        case 'date':
          return 'default_invalid_date';
        default:
          return 'default_invalid_type';
      }
    },
    oneOf: 'default_agree_with_privacy_policy',
    notOneOf: 'change_password_form_comparison_of_password',
  },
  string: {
    email: 'default_invalid_email',
    min: ({ min }: { min: number }) => {
      return { message: { id: 'default_too_short' }, values: { min } };
    },
    max: ({ max }: { max: number }) => {
      return { message: { id: 'default_too_long' }, values: { max } };
    },
    matches: ({ path }: { path: string }) => {
      if (path === 'phoneNumber') {
        return { message: { id: 'default_phone_wrong' } };
      }
      return '';
    },
  },
  number: {
    lessThan: function lessThan({ less }: { less: number }) {
      if (less) {
        return {
          message: { id: 'default_salary_less' },
          values: { less },
        };
      }
      return;
    },
    moreThan: function moreThan({ more }: { more: number }) {
      if (more) {
        return {
          message: { id: 'default_salary_higher' },
          values: { more },
        };
      }
      return;
    },
    positive: () => {
      return { message: { id: 'default_negative_number' } };
    },
    min: ({ min }: { min: number }) => {
      return { message: { id: 'default_too_low' }, values: { min } };
    },
    max: ({ max }: { max: number }) => {
      return { message: { id: 'default_too_hight' }, values: { max } };
    },
  },
  array: {
    min: ({ min }: { min: number }) => {
      return { message: { id: 'default_array_is_empty' }, values: { min } };
    },
  },
  date: {
    max: ({ max }: { max: string | Date }) => {
      return { message: { id: 'default_end_date_is_max' }, values: { value: format(new Date(max), 'dd.MM.yyyy') } };
    },
    min: ({ min }: { min: string | Date }) => {
      return { message: { id: 'default_date_is_min' }, values: { value: format(new Date(min), 'dd.MM.yyyy') } };
    },
  },
});

const translations = {
  // auth section titles
  'auth_forgotConfirmTitle': 'Confirm new password',
  'auth_forgotTitle': 'Reset your password',
  'auth_loginTitle': 'Sign in to Crewlinker',
  'auth_registerConfirmTitle': 'Confirm account creation',
  'auth_registerTitle': 'Create your account',
  'auth_register_checkbox_privacy': 'Agree to the',
  'privacy_policy': 'privacy statement',
  'auth_incorrect_login_or_password_title': 'Invalid email address or password.',
  'auth_incorrect_login_or_password_description': 'Please double check if your credentials were entered correctly.',

  // auth register steps
  'pick_your_profile_type_title': 'Pick your profile type',
  'pick_your_profile_type_description': 'Choose one option',
  'pick_crew_profile_type_title': "I'm crew",
  'pick_crew_profile_type_description': 'looking for a job',
  'pick_hire_profile_type_title': "I'm an employer",
  'pick_hire_profile_type_description': 'looking to hire',
  'email_or_phone_title': 'Enter email',
  'email_or_phone_description': 'Add your email to sign up',
  'email_or_phone_organization_description': 'Add your business email to sign up',
  'password_title': 'Create a password',
  'step_with_experience_title': 'Enter experience',
  'step_with_experience_description': 'Your number of years of experience',
  'step_with_experience_label': 'Year of experience',
  'password_description': 'A strong password is a combination of letters, numbers and punctuation marks.',
  'confirm_code_title': 'Enter the confirmation code',
  'confirm_code_description_part_1': 'Please enter the 6 digit code',
  'confirm_email_code_description_part_2': 'sent to your email',
  'confirm_phone_code_description_part_2': 'sent to your phone',
  'confirm_resend_code_title': "Didn't receive any code?",
  'confirm_resend_code_description': 'Resent code',
  'confirm_resend_another_code_description': 'Request another code',
  'enter_name_title': 'Enter name',
  'enter_name_description': 'Add your name and surname',
  'enter_country_title': 'Enter country of residence',
  'enter_country_description': 'Enter you place of living',
  'enter_rank_title': 'Enter rank',
  'enter_rank_description': 'The highest ranking duty you have performed',
  'enter_rank_select_title': 'Rank',
  'enter_rank_select_placeholder': 'Your rank',
  'enter_rank_checkbox_title': 'I have no work experience',
  'enter_rank_confirm_title': 'Smaller chance of getting a job',
  'enter_rank_confirm_description': 'Lack of experience on your profile may reduce your chances of getting a job.',
  'most_recent_sea_service_title': 'Enter your most recent work experience',
  'most_recent_sea_service_descriptor': 'Having recent work experience on your profile makes it more attractive to employers',
  'most_recent_sea_service_add_vesel_title': 'Add vessel',
  'enter_phone_number_title': 'Enter your phone number',
  'enter_phone_number_description': 'Please select code operator and enter your phone number',
  'get_linked_smarty_title': 'Get linked smartly!',

  // login
  'login_title': 'Sign in to your account',
  'login_emailRequired': 'Email required',
  'login_passwordRequired': 'Password required',
  'login_forgotPassword': 'Forgot your password?',
  'login_signIn': 'Sign in?',
  'login_emailPlaceholder': 'you@mail.com',
  'login_emailDescription': 'we will only use this for spam',
  'login_loginCta': 'Login',
  'login_sign_upCta': 'Sign up',
  'login_failedAlert': 'Failed to login',
  'login_registerCta': 'Register new account',

  // register
  'register_failedAlert': 'Failed to register account',
  'register_registerCta': 'Register Account',
  'register_alreadyAccount': 'Already have an account?',
  'register_sign_in': 'Sign in',
  'register_info_meessage': "We've sent you an confirmation email to confirm the address, please open the email and enter the code below.",
  'register_info_meessage_spam': 'Please check your ',
  'register_info_meessage_spam_folder': 'spam folder ',
  'register_info_meessage_spam_immediately': "if you can't immediately find the confirmation email.",
  'register_info_existing_account_code': 'Or, you have a code?',
  'register_are_you_a_crew': 'Are you a crew?',

  // confirm account
  'confirm_confirmCta': 'Confirm Email Address',
  'confirm_resendCta': 'Resend code',
  'confirm_failedConfirmOrResend': 'Action failed',

  // forgot password recovery
  'forgot_forgotCta': 'Reset Password',
  'forgot_failed': 'Failed to start password reset',
  'forgot_info_message': "We've sent you an email to complete the password recovery process, please open the email and enter the code below.",

  // forgot password confirm
  'forgot_confirm_failed': 'Failed to confirm password reset',
  'forgot_confirm_confirmCta': 'Confirm reset',
  'forgot_confirm_not_completed_account_registration':
    'You may not have completed your account registration and therefore are not receiving a code. To complete your account registration',

  // browserGate
  'browserGate_title': 'We noticed that you use Internet Explorer',
  'browserGate_explanation': 'To proceed using this website you need to update your browser or try another one',

  // Cert list
  'cert_list_no_certificates': 'No certificates',
  'cert_button_add': 'Add',
  'cert_button_add_expiry_date': 'Add Expiry Date',
  'cert_button_dissmiss': 'Dismiss',
  'cert_no_file': 'No file',

  // Rating
  'rating_profile_completion': 'Profile completion',
  'rating_profile_not_completion': 'Profile is not completion',
  'rating_profile_score': 'Your score is:',
  'rating_how_can_better': 'How can I do better?',
  'rating_fill_form': 'Fill in your personal details',
  'rating_fill_form_add_bio': 'Add profile bio',
  'rating_add_sea_services': 'Add sea services',
  'rating_add_certificates': 'Add certificates',
  'rating_add_education': 'Add education',
  'rating_add_appraisals': 'Add appraisals',
  'rating_add_certs_expiry_date': 'Add certificates date',
  'rating_add_certs_documents': 'Add certificates documents',
  'rating_add_avatar': 'Add avatar',
  'rating_profile_shared': 'Share your profile',
  'rating_cv_downloaded': 'Download your CV',
  'rating_profile_complete': 'Your rating is excellent. Way to go!',
  'rating_well': 'Well',
  'rating_bed': 'Bed',
  'rating_very_well': 'Very well',
  'helper_title_for_icon': 'Please click by button to open hte action popover',
  'rating_completion_score': 'Your completion score:',
  'rating_how_do_better': 'How can I do better?',
  'rating_extend_profile': 'Extend your profile to increase your completion score',
  'rating_excellent_score': 'Your score is excellent. Way to go!',
  'rating_improve_your_profile': 'Improve your profile to get better score and perfect job offer',
  'rating_profile_is_fully_filled_title_congratulations': 'Congratulations!',
  'rating_profile_is_fully_filled_title_description': "You've completed your profile",
  'rating_profile_is_fully_filled_description': "Employers will be happy to consider you're profile, you can now start searching for a job",
  'rating_profile_ready_to_apply': 'Are you sure you are ready to apply?',
  'rating_profile_low_score_hire': 'Seafarers with a 100/100 profile score have a bigger chance to get hired',
  'rating_profile_apply_with_a_lower_score': 'Apply with a lower profile score',
  'rating_profile_want_increase': 'I want to increase my profile score first',
  'rating_profile_not_enough_score_to_apply': 'Your account score is not enough to apply for a job',
  'rating_profile_minimum_to_apply': 'To apply for a sea job, your profile score must be at least 20. Boost it by adding information to your profile.',
  'rating_add_info_to_profile': 'Add information to my profile now',

  // global messages
  'default_requiredField': 'This field is required',
  'default_invalidField': 'This field is invalid',
  'default_too_short': 'Must be at least {min} long',
  'default_too_long': 'May be at most {max} long',
  'default_too_hight': 'Must be {max} or less',
  'default_too_low': 'Must be {min} or higher',
  'default_array_is_empty': 'The additional tasks field is empty. Select a task',
  'default_too_high': 'Must be {max} or lower',
  'default_auth_emailLabel': 'Email address',
  'default_auth_passwordLabel': 'Password',
  'default_auth_confirmCodeLabel': 'Confirmation code',
  'default_auth_confirm_digits_code': 'Enter digits',
  'default_auth_setPasswordDescription': 'Needs to be at least 8 characters long',
  'default_auth_newPasswordLabel': 'New Password',
  'default_invalid_type': 'Value has an invalid type',
  'default_invalid_date': 'A valid date is required',
  'default_invalid_email': 'Invalid email address',
  'default_agree_with_privacy_policy': 'You must accept the privacy statement',
  'default_end_date_is_max': 'The end date must not exceed {value}',
  'default_end_date_is_max_then_start': 'The end date must be more {value}',
  'default_date_is_min': 'This date must not be less than {value}',
  'default_start_date_is_max': "The start date mustn't exceed {value}",
  'default_not_expired_date': 'The entered date must be greater than the current one',
  'default_success_full': 'Password has been changed',
  'copy_link_success_full_title': 'Link has been copied',
  'default_phone_wrong': 'Phone number is not valid',
  'default_negative_number': 'Should be positive number',
  'default_salary_higher': 'Should be higher than: {more}',
  'default_salary_less': 'Should be less than: {less}',
  'default_responsibilities_length': 'Each new task should be longer than 2 chars',
  'default_responsibilities_length_too_long': 'Each new task should be no longer than 200 chars',
  'default_responsibilities_length_of_tasks': 'Should be not more than 10 separate tasks',
  'default_duties_length': 'Should be not more than 5 positions',
  'default_should_be_not_same_value': 'Fields cannot be the same',
  'default_should_be_value': 'This field should be filled',
  'default_duties_required': 'This field is required',
  'default_max_certificates_number': 'Should be no more than 20 required certificates',
  'not_found_heading': 'Page not found',
  'not_found_message': "The page you requested couldn't be found",
  'input_placeholder_from': 'From',
  'input_placeholder_to': 'To',
  'short_years_old': '{years} y.o.',
  'short_years_single_y_o': 'y/o',
  'chose_one_of_two_fields_email_phone': 'You should fill an email or a phone number.',
  'business_email_validation_with_email': 'Please use your business email address instead of a {value} email address',

  'suspense_loading': 'Loading...',
  'suspense_loading_time': 'Searching, this may take up to a minute.',
  'suspense_loading_retrying': 'Retrying search, this may take some extra time',
  'close': 'Close',
  'clear_all': 'Clear All',

  // admin area
  'admin_no_duties': 'No duties to administrate',
  'admin_no_certs': 'No certs to administrate',
  'assume_other_user': 'Assume other user',
  'assume_profile_modal_title': 'Assume other profile',
  'assume_profile_modal_description': 'Enter a profile id to assume this profile.',
  'assume_profile_modal_form_input_label': 'Enter the profile id',
  'assume_profile_modal_form_input_placeholder': 'Profile id',
  'assume_profile_modal_form_button_label': 'Assume',
  'assume_profile_stop_assume_button_label': 'Stop assuming user',
  'assume_profile_not_found_error': "There isn't account by this id. Try it again.",

  // duty selector
  'no_duties': 'No duties',
  'no_name': 'No name',

  // error
  'error_boundary_text': 'Oops, something went wrong',
  'error_go_to_back': 'Back to Profile',
  'error_incorrect__confirm_code': 'Code is incorrect',
  'error_empty_array_of_countries': 'Your country is not on the list, please enter another',
  'error_empty_array': 'This list is empty',
  'error_tag_already_added': 'Tag already added',
  'error_not_attached_file': 'Please attach a file',
  'error_id': 'Error id - {id}',
  'error_vacancy_closed': 'Sorry , this vacancy was closed',
  'error_pdf_plugin': "Your web browser doesn't have a PDF plugin.",
  'error_user_is_disabled': "You're account needs to be manually enabled, we're working on this. If you feel like it takes too long, please contact us at {value}",
  'error_user_is_disabled_message': 'User is disabled.',
  'error_vessel_imo': 'Should be 7 numbers',
  'error_incorrect_password_for_confirmation_step': 'It looks like you have entered a different password for this account. To recover your password {value}',

  //info
  'account_pending_confirmation': 'Account pending confirmation',
  'account_password_incorrect': 'Incorrect password',

  // warning
  'warning_head_data': 'Warning! Maximum file size is 7.5 MB',
  'warning_incorrect__size': 'You try to upload file with {uploadedSize}',
  'warning_length_of_job_positions':
    "You've added more than 2 positions. We recommend creating multiple job openings. This improves our ability to present the job post to the crew and it makes it easier for you to review applicants.",

  // drop down profile menu
  'navbar_your_crew_profile': 'Your crew profile',
  'navbar_your_company_profile': 'Your company profile',
  'navbar_go_to_crew_profile': 'Go to crew profile',
  'navbar_signOut': 'Sign out',
  'navbar_sr_openUserMenu': 'Open user menu',
  'navbar_sr_openMainMenu': 'Open main menu',
  'navbar_sr_your_crew_account': 'Your crew account',

  'profile_logout': 'Logout',
  'profile_seaServiceTitle': 'Sea services',
  'profile_seaServiceTitle_highlighted_text_helper': 'Employers only check your sea services that match the job you are looking for. Put these sea services on top.',
  'profile_noSeaService': 'No sea service yet',
  'profile_noAppraisal': 'No appraisals yet',
  'profile_noEducation': 'No education yet',
  'profile_addMoreInformation': 'Add more information to complete your profile',
  'profile_user_has__no_information': 'The user has not added information yet',
  'profile_certificatesTitle': 'Certificates',
  'profile_educationTitle': 'Education',
  'profile_educationsTitle': 'Educations',
  'profile_add_documents_improve': 'Attach certificate documents',
  'profile_addSeaServiceCta': 'Add sea service',
  'profile_addEducationCta': 'Add  an education',
  'profile_addCertCta': 'Add certificate',
  'profile_addEduCta': 'Add education',
  'profile_appraisalsTitle': 'Appraisals',
  'profile_appraisal': 'Add an appraisal',
  'profile_addCertificateCta': 'Add a certificate',
  'profile_editBasicCta': 'Edit profile',
  'profile_setupBasicCta': 'Setup profile',
  'profile_bio_help_description': 'Please write a short introduction about yourself',
  'profile_organization_bio_help_description': 'Please write a short introduction about the company',
  'profile_age': 'Age',
  'profile_suggestions': 'Suggestions {amount}',
  'profile_about_me': 'About me',
  'profile_position': 'Position',
  'profile_location': 'Location',
  'profile_mobile': 'Mobile',
  'profile_work': 'Work',
  'profile_email': 'Email',
  'profile_see_profile': 'See profile',
  'profile_service_other_duties': 'Other duties',
  'profile_frequently_used_tags': 'Frequently used tags',
  'profile_sea_services': 'Sea Services',
  'profile_certificates': 'Certificates',
  'profile_highlighted_sea_services': 'Highlighted Sea Service',
  'profile_sea_service': 'Sea Service',
  'profile_certificate': 'Certificate',
  'profile_sea_services_total': 'Total',
  'profile_load_more_crew': 'Load more crew',
  'profile_is_empty': 'Profile is empty',
  'profile_inform_me_checkbox_label': 'Inform me of new job openings and tips to update my profile',
  'phone_number_form_label': 'Phone number',
  'phone_number_form_button_label': 'Add a phone number',
  'phone_added_successfully_title': 'Phone added successfully',
  'phone_added_successfully_description': 'Your phone number has been successfully added',
  'phone_added_form_not_correct': 'The phone number is not in the correct format',
  'phone_placeholder': '00 000000',
  'phone_code_is_empty': 'Please, choose country code',
  'phone_code_is_not_correct': 'Phone code is not correct',
  'phone_number_is_empty': 'Please, write your phone number',
  'phone_modal_confirm_title': 'Edit the security code',
  'phone_modal_confirm_description': 'Please enter the 6 digit code sent to {phoneNumber}',

  // Profile slide
  'profile_slide_service_year': 'year',
  'profile_slide_service_years': 'years',
  'profile_slide_service_month': 'month',
  'profile_slide_service_months': 'months',
  'profile_slide_service_day': 'day',
  'profile_slide_service_days': 'days',
  'profile_slide_show_all_sea_services': 'Show all sea services',
  'profile_slide_show_all_certificates': 'Show all certificates',
  'profile_slide_show_all_educations': 'Show all educations',
  'profile_slide_hide_elements': 'Hide',

  //Slide with crew contacts
  'slide_over_with_contact_toggle_contact_label': 'Contacted with this user.',
  'slide_over_with_contact_confirm_title': 'Did you contact this user?',

  // Slide with profile certificates
  'slide_with_profile_certificates_title': "{nameProfile}'s certificates",

  'self_profile_no_highlighted_sea_services': "You don't have added highlighted sea services.",
  'profile_no_sea_services': "The user didn't add any sea services.",
  'profile_no_appraisals': "The user doesn't have appraisals.",
  'self_profile_no_education_added': "You don't have added education certificates.",
  'profile_no_education_added': "The user didn't add any education.",
  'profile_no_certificates_added': "The user didn't add any certificates.",
  'self_profile_no_certificates_added': "You don't have added certificates.",
  'click_to_add_some': 'Click the button to add some.',
  'click_to_add_highlighted_services': 'Add highlighted sea services.',

  'profile_seaServiceAmount': '{amount} sea services',
  'profile_educationAmount': '{amount} educations',
  'profile_appraisalAmount': '{amount} appraisals',

  'profile_data_restriction': 'This information is not visible to you',
  'profile_education_with_restriction_amount': 'And {amount} more educations',
  'profile_seaService_with_restriction_amount': 'And {amount} more sea services',
  'profile_certificate_with_restriction_amount': 'And {amount} more certificates',

  'profile_certificatesAmount': '{amount} certificates',
  'profile_vesselsAmount': '{amount} vessels',
  'profile_vessels': 'Vessels',
  'profile_more_certificates': 'and {amount} more',
  'profile_hide_certificates': 'hide',
  'profile_available_for_work': 'Available for work',
  'profile_not_available_for_work': 'Not available for work',

  'profile_notEmpty': 'Highlighted sea service',
  'self_profile_no_certificate_yet': 'No certificates yet',
  'profile_isEmpty': "The user's profile is empty",
  'profile_add_first_certificate': 'Add your first certificate',
  'profile_text': 'Add information to completely fill out your profile',
  'profile_settings_description': 'Update your username and manage your account',
  'profile_view_jobs_for_you': 'View jobs for you',

  'profile_name_at_least': 'Must be at least 2 characters long',
  'profile_name_at_most': 'Must be at most 30 characters long',

  'profile_employer_applied_with_crew': 'applied to your job opening.',
  'profile_employer_matched_with_crew_description_start': 'This user applied to your',
  'profile_employer_applied_with_crew_description_job': 'job opening',

  'profile_failedSave': 'Failed save',
  'profile_basic_info_about_me': 'About me',
  'profile_first_name': 'First name',
  'profile_last_name': 'Last name',
  'profile_date_of_birth': 'Date of Birth',
  'profile_years_of_work_experience': 'Years of work experience',
  'profile_date_of_birth_error_message_for_old_people': "Your age mustn't be over 120",
  'profile_date_of_birth_error_message_for_young_people': 'Your age must be over 10',
  'profile_country_of_residence': 'Country of Residence',
  'profile_operating_country': 'Operating country',
  'profile_city': 'City',
  'profile_country_of_residence_v2': 'Country of residence',
  'profile_country_label': 'Country',
  'years_old': '{years} years old',
  'born_date': 'Born {day}-{month}-{year}',
  'save': 'Save',
  'save_info': 'Save info',
  'save_and_add_another': 'Save and add another',
  'save_profile': 'Save profile',
  'delete': 'Delete',
  'remove_document': 'Remove Document',
  'remove': 'Remove',
  'cancel': 'Cancel',
  'yes': 'Yes',
  'no': 'No',
  'empty': '',
  'ok': 'Ok',
  'here': 'Here',
  'back': 'Back',
  'done': 'Done',
  'publish': 'Publish',
  'next_step': 'Next step',
  'next': 'Next',
  'copy_link': 'Copy link',
  'copy': 'Copy',
  'copied_link': 'Copied',
  'no_info': 'No info',
  'info': 'Info',
  'skip': 'Skip',
  'confirm': 'Confirm',
  'your_birthday': 'Your birthday',
  'profile_bio': 'Bio',
  'membership': 'Membership',
  'profile_bio_modal_description': 'Full description of the user',
  'bio_placeholder': 'Some information about yourself',
  'choose_country': 'Choose country',
  'city_name': 'City name',
  'empty_country': 'The country field is empty. Select a country',
  'i_got_it': 'I got it',
  'crews': '{value} Crews',
  'available': '{value} Available',
  'online': '{value} Online',
  'add_to_existing_one': 'Add to existing one',
  'vacancy': 'Vacancy',

  // trial | premium subscription modal

  'subscription_plan_title': 'Plan',
  'subscription_email_us': 'Email Crewlinker',
  'subscription_book_meeting': 'Book a Meeting',
  'subscription_go_to_profile': 'To your crew account',
  'subscription_days_left': 'Days left',
  'subscription_upgrade': 'Upgrade',
  'subscription_trial_has_ended': 'Your trial has ended',
  'subscription_trial_update_to_pro': 'Upgrade to a pro account',
  'subscription_change_plan_pro_subtitle': 'Ensure uninterrupted access to the features you already rely on.',
  'subscription_change_plan_pro_subtitle_1': 'Complete a simple form for contract set up.',
  'subscription_change_plan_pro_subtitle_2': "Once signed, you'll retain full access to Crewlinker",
  'subscription_upgrade_to_pro': 'Upgrade to PRO',
  'subscription_make_switch': 'Make the switch today!',
  'subscription_more_information': 'Need more information?',
  'subscription_free_information_description': 'Our experts are ready to assist and guide you towards your goals.',
  'subscription_modal_small_title': 'Pricing',
  'subscription_modal_big_title': 'Pricing plans for teams of all sizes',
  'subscription_modal_description': 'Choose an affordable plan that’s packed with the best features for engaging your audience, creating customer loyalty, and driving sales.',
  'subscription_modal_plan_buy_button': 'Buy plan',
  'subscription_modal_plan_try_in_beta_button': 'Try in beta',
  'subscription_modal_plan_free_trail_button': 'Use for free',

  'subscription_job_opening_limit_title': 'Job opening limit reached',
  'subscription_job_opening_limit_description': "You've reached your limit of 1 open vacancy. Switch to {value} today and manage up to 10 open vacancies at once.",

  // employer profile
  'employer_self_basic_modalTitle': 'Setup organization',
  'employer_self_basic_no_name': 'No Name',
  'employer_self_basic_modalDescription': 'Set up your organization name.',
  'employer_edit_self_basic_modalDescription': 'Fill in the information about your company.',
  'employer_save_organization_information': 'Save information',
  'employer_org_label': 'Company name',
  'employer_org_size_label': 'Company size',
  'employer_org_placeholder': 'Enter your company name',
  'employer_org_bio_label': 'About the company',
  'employer_self_basic_edit_modalTitle': 'Edit your company profile',
  'employer_edit_profile_title': 'Set up your company profile',
  'employer_edit_profile_description': "There's not much left to do. Enter your company name and start using your profile.",
  'employer_info_about_us': 'About us',
  'employer_vacancy_status_open': 'Open jobs',
  'employer_vacancy_status_open_with_number': '{amount} open jobs',
  'employer_vacancy_status_closed': 'closed jobs',
  'employer_vacancy_status_archived': 'archived jobs',

  // search by
  'search_by_description': 'This information will be displayed publicly so be careful what you share.',
  'search_by_duty_did_not_any_results': "Your search didn't yield any results, please try again with a different query.",
  'number_of_vessels_found': '{number} vessels found',

  // service
  'sea_service_position': 'Position',
  'sea_service_type': 'Type:',
  'sea_service_duration': 'Duration',
  'sea_service_currently_working_here': 'Currently working here',
  'sea_service_operator': 'Operator:',
  'sea_service_workers': 'Workers',
  'sea_service_gross_tonnage': 'GT',
  'sea_service_gross_tonnage_item': 'GT:',
  'sea_service_length_item': 'Length:',
  'sea_service_length': 'Length',
  'sea_service_cn_iso': 'Cn Iso',
  'sea_service_activities': 'Additional activities:',
  'sea_service_activities_help_text': 'Additional activities help description',
  'sea_service_modal_help_text': "Employer don't read large text. Instead, use relevant tags to make your profile stand out",
  'sea_service_description': 'Note:',
  'sea_service_modal_more_rotations': 'Nr of rotations',
  'sea_service_modal_more_imo': 'IMO',
  'sea_service_modal_more_owner': 'Owner',
  'sea_service_modal_more_operator': 'Operator',
  'sea_service_find_shipmates': 'Find shipmates',

  // dates inputs
  'DD': 'DD',
  'MM': 'MM',
  'YYYY': 'YYYY',

  // day
  'day_required': 'Day is required',
  'day_min_length': 'Day should has at least 1 number',
  'day_max_length': "Day shouldn't be longer than 2 numbers",
  'day_min': 'Day should be at least 1',
  'day_max': 'Day should be no greater than {max}',
  'day_pattern': 'Day value should be a number',

  // month
  'month_required': 'Month is required',
  'month_min_length': 'Month should has at least 1 number',
  'month_max_length': "Month shouldn't be longer than 2 numbers",
  'month_min': 'Month should be at least 1',
  'month_max': 'Month should be no greater than 12',
  'month_pattern': 'Month value should be a number',

  // year
  'year_required': 'Year is required',
  'year_min_length': 'Year should has at least 1 number',
  'year_max_length': "Year shouldn't be longer than 2 numbers",
  'year_min': 'Year should be at least {min}',
  'year_max': 'Year should be no greater than {max}',
  'year_pattern': 'Year value should be a number',

  // bio
  'bio_max_length': "Bio shouldn't be longer than 90 characters",
  'bio_required': 'Bio is required',
  'optional': '(optional)',
  'bio_description': 'Brief description for your profile. You can enter up to {maxChar} characters',
  'bio_organization_description': 'Brief description about the company. You can enter up to {maxChar} characters',

  // values
  'value_required': 'Value is required',
  'value_min_length': 'Value min length is {length}',
  'value_max_length': 'Value max length is {length}',
  'to_more_than_from': `Wrong date. "To" can't be less than "From"`,

  'self_seaService_edit_modalTitle': 'Edit sea service',
  'self_seaServiceHighligted_edit_modalTitle': 'Edit highlighted sea services: {total}/3',
  'self_seaService_add_modalTitle': 'Add sea service',
  'self_certs_modalTitle': 'Edit certificate',
  'self_certs_modalSaveCertificatesButton': 'Save added certificates',
  'self_edus_modalTitle': 'Edit education',
  'self_basic_modalTitle': 'Edit profile',
  'self_basic_link': 'Link of profile',
  'self_basic_modalDescription': 'You can share the link with social media or send it to a friend. Choose one of the convenient ways for you',
  'self_basic_modalCertificateDescription': 'You can choose the certificates that are offered to you below. They were chosen based on your travel experience.',
  'self_basic_modalHighligtedDescription': 'Select up to 3 most significant sea services to show on the profile page.',
  'self_basic_modalHighligtedSave': 'Save pins',
  'self_basic_modalSearchPlaceholder': 'Search sea services',
  'self_basic_confirm_modal_with_verified_pn_title': "You don't save latest changes",
  'self_basic_confirm_modal_with_verified_pn_description': 'Do you want save latest changes?',
  'self_basic_confirm_modal_with_removed_pn_title': 'You removed phone number',
  'self_basic_confirm_modal_with_removed_pn_description': 'Are you sure you want to continue?',
  'self_basic_confirm_modal_with_not_verify_pn_title': "You don't verify your phone number",
  'self_basic_confirm_modal_with_not_verify_pn_description': 'Please, verify your phone number.',
  'something_went_wrong': 'Something went wrong',
  'select_a_tab': 'Select a tab',

  // Certificate
  'certificate_list_all': 'All',
  'certificate_info_modal': 'Certificate info',
  'certificate_files': 'Certificate files',
  'certificate_attached_file_modal_title': 'Drop files here or import from:',
  'certificate_attached_file_modal_description': 'PNG, JPG, GIF, 1–3 files, up to 1 MB',
  'certificate_expiry_date': 'Expiry date',
  'certificate_expired': 'Your certificate is out of date. Update your information.',
  'certificate_not_configure': "This certificate doesn't need an expiry to be configured",
  'certificate_form_failedSubmit': 'Failed to save certificate',
  'certificate_form_remove_expiry': 'Remove expiry',
  'certificate_without_expiry_date': 'Without expiry date',
  'certificate_without_attachments': 'Certificates without attachments',
  'certificate_documents_attached': 'All documents attached',
  'certificate_without_empty_attachments_description': 'With documents attached to all certificates your profile will be more attractive to employers.',
  'crew_certificate_expired': 'This certificate is out of date.',

  // Modal Download CV
  'downloadCV_buttonLabel': 'Download CV',
  'downloadCV_buttonLabel_profile_view': 'Download cv',
  'shape_profile_buttonLabel': 'Share Profile',
  'create_link_buttonLabel': 'Create link',
  'open_your_cv': 'Open your CV',
  'open_cv': 'Open CV',
  'downloadCV_button_in_modal': 'Create CV',
  'creatingCV': 'Creating your cv...',
  'createCV_title': 'Share your profile resume',

  // highlighted
  'highlighted_services_no_highlighted_services': 'No highlighted services',
  'highlighted_services_add_instruction': "You can highlight services by clicking 'Edit highlighted' button",

  // Messages from the AWS Amplify library that we don't control
  'Incorrect username or password.': 'Incorrect username or password.',
  'Temporary password has expired and must be reset by an administrator.': 'Temporary password has expired and must be reset by an administrator.',
  'An account with the given email already exists.': 'An account with the given email already exists.',
  "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6": 'Password must be 8 characters or longer',
  'Password did not conform with policy: Password not long enough': 'Password must be 8 characters or longer',
  'Network error': "Failed to connect to the server, make sure you're internet is working or contact us if the problem persists.",
  'Invalid verification code provided, please try again.': 'Invalid verification code provided, please try again.',
  'Invalid code provided, please request a code again.': 'Invalid code provided, please request a code again.',
  'Attempt limit exceeded, please try after some time.': 'Attempt limit exceeded, please try after some time.',
  'User cannot be confirmed. Current status is CONFIRMED': 'User is already activiated, please login to continue.',
  "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
    'Password must be 8 characters or longer',

  // for story preview
  'temporary_visible_title': 'Visible title',
  'temporary_hidden_title': 'Hidden title',

  // duty form
  'create_duty_title': 'Create duty',
  'create_duty_text': 'To create new duty you just need to type in duty name',
  'duty_name': 'Duty name',
  'create_duty': 'Create duty',

  // avatar upload
  'avatar_alt': 'Profile image',
  'no_avatar': 'No profile image',
  'avatar_upload_alt': 'Avatar upload preview',
  'avatar_upload_save': 'Save avatar',
  'avatar_upload_thumbnail_alt': 'Avatar thumbnail',

  // avatar upload cropp
  'avatar_upload_label': 'Upload a photo...',
  'avatar_employer_upload_label': 'Upload company logo',
  'avatar_upload_modal_title': 'Profile picture',
  'avatar_cropper_confirm_cta': 'Upload profile photo',
  'avatar_cropper_failed_upload': 'Failed to save avatar',
  'avatar_cropper_unknown_error': 'Unknown failure while uploading',
  'One of your files was declined': 'Invalid file. Minimum size 200x200 , maximum size 7.5 Mb . Supported types : JPG, PNG',

  'service_form_tags_label': 'Additional project Tags',
  'service_form_tags_other_activities': 'Other activity: ',
  'service_form_duty_placeholder': 'Select duty',
  'service_form_tag_placeholder': 'Select tag',
  'service_form_vessel_info_title': 'Vessel information',
  'service_form_vessel_info_text':
    'Enter the information about the ship, after entering the IMO number all additional information should be automatically filled in, but you can edit it if necessary.',
  'service_form_rotations': 'Rotations',
  'service_form_failedSubmit': 'Failed to save sea service',
  'service_form_note_failedSubmit': 'Failed to save sea service info',
  'service_form_vessel_did_not_find': "A vessel didn't find",
  'service_form_vessel_imo': 'IMO Number',
  'service_imo_incorrect': 'IMO number incorrect',
  'service_imo_incomplete': 'Please, fill IMO number',
  'service_not_exist_vessel_by_imo':
    'We cannot find a vessel with that IMO number, double check that you have entered the correct number. If you are sure you can still fill in the vessel information yourself below.',
  'service_form_type_label': 'Type',
  'service_form_vessel_operator_name': 'Operator',
  'service_form_save': 'Save sea service',
  'service_form_working_now': 'At the moment I am working in this position',
  'service_present': 'Present',
  'service_note': 'Add a text note',
  'service_three_dots': '...',
  'service_form_note_title': 'Text note about sea service',
  'service_form_about_sea_service': 'About sea service',
  'service_form_note_save_info': 'Save Info',
  'service_form_rotations_help_message': 'Number of rotations made on the same vessel and same position',
  'service_form_note_description':
    'Write a little about your journey, clarify the specifics of your position. For example "It was a busy trip, I did various jobs that elevated me to a new level of my position".',
  'service_form_tagging_placeholder': 'Create your own tag...',
  'service_tag_name_long': 'The tag name is too long',

  'sea_service_rotations': 'Nr of rotations:',
  'sea_service_highlighted_see_info': 'See more info',
  'sea_service_highlighted_edit': 'Edit highlighted',
  'sea_service_on_the': 'on the',
  'sea_service_load_more': 'Load more sea services',

  'field_is_name': 'Name',
  'search_bar_imo': 'IMO:',
  'search_bar_country': 'Country:',
  'search_bar_length': 'Length:',
  'sea_service_modal_more_imo_vessel': 'IMO:',

  'education_form_failedSubmit': 'Failed to save education',
  'education_form_save': 'Save education',
  'education_form_level_label': 'Level',
  'education_form_studying_here': 'I am still studying here',
  'education_item_title_level': 'Level',
  'education_item_title_start': 'Start date',
  'education_item_title_end': 'End date',
  'education_item_present_end': 'Present',

  'date_input_group_invalid_date': 'Valid date is required',
  'text_area_input_group_too_many_chars': 'The provided value is too long',

  'confirm_remove_item_title': 'Delete item',
  'confirm_remove_item_text_1': 'Are you sure you want to delete data?',
  'confirm_remove_item_text_2': 'All of your data will be permanently removed from our servers forever. This action cannot be undone.',
  'confirm_archive_item_title': 'Archive job opening',
  'confirm_archive_item_text_1': 'Are you sure you want to archive job opening?',
  'vessels_not_found': 'No vessel found',
  'vessels_not_found_text': "If the spelling of the name is correct, we do not have information about your sea service - enter your ship's information manually.",
  'type_vessel_name': 'Type vessel name...',
  'confirm_uninvite_this_crew_title': 'Uninvite this crew member',
  'confirm_uninvite_this_crew_text_1': 'Are you sure you want to uninvite this crew member?',
  'confirm_hire_this_crew_title': 'Hire this crew member',
  'confirm_hire_this_crew_text_1': 'Are you sure you want to hire this crew member?',
  'confirm_deny_this_crew_title': 'Deny the application for this user',
  'confirm_deny_this_crew_text_1': 'Are you sure you want to deny the application for this user?',

  // Delete account modal
  'confirm_remove_delete_text_1': 'Are you sure you want to delete your account?',
  'confirm_remove_delete_text_2': 'All of your data will be permanently removed from our servers forever. This action cannot be undone.',

  // Profile progress compoenent
  'profile_progress_title': 'Start filling out your profile',
  'profile_progress_title_persentage': 'Your profile is {percentage} complete',

  'profile_progress_pre_title_zero_info': 'Fill out information about yourself ',
  'profile_progress_pre_text_zero_info': 'Fill out basic information in your profile so that your colleagues can find you faster.',
  'profile_progress_button_zero_info': 'Setup Profile',
  'profile_progress_pre_title_zero_info_add_bio': 'You forgot to write a biography',
  'profile_progress_pre_title_zero_info_add_bio_pretitle': 'Add biography in your profile so that your colleagues can find you faster.',

  // Interest
  'interest_profile_views': 'Profile views',
  'interest_profile_statistics': 'Statistics of how your profile is viewed by other users',

  // Default info set in profile progress
  'profile_progress_pre_title_default_info_set': 'What sea services do you have?',
  'profile_progress_pre_title_pre_info_set': "Add all the sea services you've participated in.",
  'profile_progress_button_add_seaservice': 'Add sea services',

  // All info set in profile progress
  'profile_progress_pre_title_all_info_set': 'Download your CV',
  'profile_progress_pre_title_pre_all_info_set': 'Download and use your CV for free.',
  'profile_progress_button_add_seaservice_all_infoset_button': 'Download CV',

  // Certificates info show in profile progress
  'profile_progress_pre_title_certificates': 'What certificates do you have?',
  'profile_progress_pre_title_text_certificates': 'Add all available certificates so operators can see your qualifications.',
  'profile_progress_button_certificates': 'Add certificates',

  // Sea services info show in profile progress
  'profile_progress_pre_title_sea_services': 'What sea services do you have?',
  'profile_progress_pre_title_text_sea_services': "Add all the sea services you've participated in.",
  'profile_progress_button_sea_services': 'Add sea services',

  // Education info show in profile progress
  'profile_progress_pre_title_education': 'What school did you attend?',
  'profile_progress_pre_title_text_education': 'Enter your educational information.',
  'profile_progress_button_education': 'Add an education',

  // Account Settings
  'label_account_settings': 'Account Settings',
  'label_change_password': 'Change password',
  'change_password_description': 'It’s better to change your password once half a year',
  'change_email_button': 'Apply the new email',
  'change_password_button': 'Apply the new password',
  'change_password_form_new_passwordLabel': 'New password*',
  'change_password_form_new_password_placeholder': 'Enter a new password',
  'change_password_form_new_password_description': '*It needs to be at least 8 characters long. A strong password is a combination of letters, numbers and punctuation marks.',
  'change_password_form_old_passwordLabel': 'Old password',
  'change_password_form_old_password_placeholder': 'Enter an old password',
  'change_password_form_comparison_of_password': 'Passwords should be different',
  'change_password_success': 'Your password has been successfully changed',
  'copy_link_success_full_message': 'Your link has been successfully copied',
  'delete_account_button': 'Delete account',
  'account_delete_description': 'This action will permanently delete your account and information from the system',
  'Incorrect current password.': 'Incorrect current password.',
  'hide_account_from_search_label_checkbox': 'Hide account from a search result',
  'account_visibility_title': 'Account visibility',
  'account_availability_title': 'Availability',
  'account_availability_details': 'Indicate your availability for job offers',
  'account_visibility_description': 'Set up viewing of your account for other users',
  'my_subscriptions_label': 'My subscriptions',
  'my_subscriptions_description': 'Customize the notifications you want to receive',
  'job_openings_notification_label': 'Get notified about new jobs',
  'job_openings_notification_description': 'Be the first one to receive new job openings that match your profile',
  'general_settings_available_for_work': 'Available',
  'general_settings_hide_contact_info': 'Hide contact info',
  'tips_general_settings_available_for_work': 'Indicate to employers that you are available for work',
  'tips_general_settings_hide_contact_info': 'Hide contact information from employers',
  'tips_notification_label': 'Get tips on how to improve your profile',
  'tips_notification_description': 'Receive valuable information on how to increase your profile score and get more job offers',

  // Search Profile Page
  'search_list_title': 'Find crew',
  'search_list_item_invite_button_label': 'Invite',
  'search_list_item_confirm_invite': 'Invite to vacancy',
  'search_filter_primary_duty_label': 'Primary duty',
  'search_filter_most_recent_service_duty_label': 'Most recent service duty',
  'search_filter_experience_with_duty_label': 'Experience with duty:',
  'search_filter_popover_activity_title': 'Additional activities',
  'search_filter_popover_number_of_sea_services_title': 'Number of sea services',
  'search_filter_popover_number_of_sea_services_years_experience': 'Years of experience',
  'search_filter_popover_number_of_appraisals': 'Number of appraisals',
  'search_filter_popover_education_kinds': 'Education kinds',
  'search_filter_available_for_work_description': 'Show crews available for work',
  'search_filter_popover_worked_for': 'Vessel operators and managers',
  'search_filter_popover_crews_vessel_imo': 'Vessel imo',
  'search_filter_popover_worked_for_item': 'worked for {place}',
  'search_filter_number_of_sea_services_chose_element': '{range} number of sea services',
  'search_filter_number_of_appraisals': '{range} number of appraisals',
  'search_filter_hiring_area_label': 'Hiring area',
  'search_filter_imo_is_already_used': 'This imo you have already used for filtering.',
  'search_modal_title': 'Invite user to a job opening',
  'search_modal_description': 'Select one the job openings below to invite the crew member for that job opening',
  'search_modal_position_empty': 'Empty',
  'search_modal_posting_date_dead_line_empty': 'No date',

  // Appraisals
  'appraisal_add_modalTitle': 'Add on appraisal to this sea service',
  'appraisal_edit_modalTitle': 'Edit on appraisal to this sea service',
  'appraisal_modal_description': 'This information will be displayed publicly so be careful what you share',
  'appraisal_modal_choose_sea_service_title': 'Choose one of your sea services',
  'appraisal_modal_choose_sea_service_placeholder': "Enter the vessel's name",
  'appraisal_modal_review_label': "Write the captain's review",
  'appraisal_form_save': 'Save appraisal',
  'appraisal_files': 'Appraisal Files',
  'appraisal_upload_file_label': 'Upload appraisal file',
  'appraisals_captain_review': "Captain's review",
  'appraisals_form_failedSubmit': 'Failed to save appraisal',
  'appraisal_form_sea_service_working_here': `(You're still working here)`,
  'appraisal_form_sea_service_already_exist': '(Already has an appraisal)',
  'appraisal_captains_review': 'Captains review',
  'appraisal_modal_with_attached_files_title': 'Appraisal file',

  // Other
  'about': 'About',
  'label_danger_zone': 'Danger zone',
  'label_form_duty': 'Duty',
  'label_filter': 'Filter',
  'label_apply_filter': 'Apply filter',
  'label_profile': 'Profile',
  'label_attached_files': 'Attached files',
  'label_on_the': 'on the',
  'label_edit_button': 'Edit',
  'label_show_more_button': 'Show more',
  'placeholder_form_type': 'Start typing...',
  'placeholder_form_type_without_dots': 'Start typing',
  'form_limit_characters': 'You can enter up to {maxChar} characters',
  'button_upload_new_file_label': 'Upload new file',
  'search_input_no_results': 'No results.',
  'title_file': 'File',
  'title_file_with_colon': 'File:',
  'and_message': 'and',
  'attach_file': 'Attach file',
  'pdf_plugin_download': 'Click here to download the PDF file.',
  'search_for_a_job': 'Search for a job',
  'do_it_later': 'Do it later',
  'click_here': 'Click here',

  // Navbar links
  'crew_members_link_label': 'Crew members',
  'navbar_hire_find_crew_link': 'Find crew',
  'navbar_crew_find_job': 'Find a job',

  // Hire Dashboard header
  'hire_dashboard_header_title': 'Hiring status',
  'hire_dashboard_header_status_open': '{count} open',
  'hire_dashboard_header_status_closed': '{count} closed',
  'hire_dashboard_header_status_total': '{count} total',
  'hire_dashboard_header_button_job_opening': 'Job opening',
  'hire_dashboard_header_button_job_archived_show': 'Show {count} archived',
  'hire_dashboard_header_button_job_archived_hide': 'Hide {count} archived',
  'hire_dashboard_header_tabs_show_open': 'Show open',
  'hire_dashboard_header_tabs_show_all': 'Show all',
  // Hire Dashboard table
  'hire_dashboard_table__header_order_number': '№',
  'hire_dashboard_table_header_status': 'Status',
  'hire_dashboard_table_empty_fields': 'All your vacancies are closed',
  'hire_dashboard_table_new_element': 'New',
  'hire_information_position_title': 'Position title',
  'hire_information_position': 'Position',
  'hire_information_job_title': 'Job title',
  'hire_information_position_no_value': 'No value',
  'hire_information_position_placeholder': 'Start typing...',
  'hire_information_vessel_name': 'Vessel name',

  'hire_information_industry_nautical_worldwide': 'Nautical worldwide',
  'hire_information_industry_offshore_wind': 'Offshore wind',
  'hire_information_industry_offshore_oil_gas': 'Offshore oil & gas',

  'hire_information_duration_one_term': 'One term',
  'hire_information_duration_temporary': 'Temporary',
  'hire_information_duration_permanent': 'Permanent',

  'hire_vessel_type_label': 'Vessel type',
  'hire_starting_date_label': 'Starting date',
  'hire_location_label': 'Location',
  'hire_manager_label': 'Manager',
  'hire_location_placeholder': 'Country name',
  'hire_information_salary_range': 'Salary range',
  'hire_information_salary': 'Salary',
  'hire_information_min_salary': 'Minimum salary',
  'hire_information_salary_period': 'Salary period',
  'hire_information_posting_date': 'Posting date',
  'hire_information_deadline_date': 'Deadline date',
  'hire_information_industry': 'Industry',
  'hire_button_label_open': 'Open',
  'hire_button_label_close': 'Close',
  'hire_button_label_archive': 'Archive',
  'hire_button_label_share': 'Share',
  'hire_button_label_draft': 'Draft',
  'hire_button_label_apply': 'Apply',
  'hire_label_applied': 'Applied',
  'hire_label_denied': 'Denied',
  'hire_label_deny': 'Deny',
  'unknown': 'Unknown',
  'hire_button_label_accept_invite': 'Accept invite',
  'hire_button_label_closed': 'Job opening has been closed',
  // Hire Job opening form general
  'hire_form_general_step_1_title': 'General',
  'hire_form_general_step_2_title': 'Requirements',
  'hire_form_general_step_3_title': 'Description',
  'hire_form_general_step_4_title': 'Status',
  'hire_form_general_step': 'Step {step}',
  'hire_form_general_step_fraction': '/{fraction}',
  'hire_form_general_form_cancel_changes': 'Cancel changes',
  // Hire Job opening form
  'hire_form_step_1_title': 'General information',
  'hire_form_step_1_torn_weeks_off_label': 'Weeks off-board',
  'hire_form_step_1_torn_weeks_off_placeholder': 'Weeks off-board',
  'hire_form_step_1_torn_weeks_on_label': 'Weeks on-board',
  'hire_form_step_1_torn_weeks_on_placeholder': 'Weeks on-board',
  'hire_form_step_1_empty': ' ',
  'hire_form_step_1_currency': 'Currency',
  'hire_form_step_1_rotations': 'Rotations',
  'hire_form_step_1_positions_placeholder': 'Start typing...',
  'hire_form_step_1_job_description': 'Job description',
  'hire_form_step_1_job_responsibilities': 'Tasks and responsibilities',
  'hire_form_step_1_job_description_limit_of_chars': 'You can enter up to {count} characters',
  'hire_form_step_1_job_description_starting_condition_label': 'Starting Condition',
  'hire_form_step_1_job_description_org_member_label': 'Organization member',
  'hire_form_step_description': 'This information will be displayed publicly so be careful what you share. ',
  'hire_form_step_2_title': 'Requirements',
  'hire_form_step_2_field_placeholder': 'Start typing and select from the list',
  'hire_form_step_3_title': 'Job description',
  'hire_form_step_4_field_title': 'Status for this position',
  'hire_form_failedSubmit': 'Failed to save job opening',
  'hire_form_default_error': 'Something went wrong',
  // Hire not have a Job opening
  'hire_noVacancies_title': 'No job openings yet',
  'hire_noVacancies_description': 'Your organization has no job openings yet',
  'hire_noVacancies_description_for_crew': 'Please create a job opening and this user will receive an invitation to consider the offer.',
  'hire_no_organization': 'Your profile is empty',
  'hire_no_organization_description': 'Add information to receive suggestions to do better',
  // Hire Job description page
  'hire_job_description_posting_date': 'Posting date',
  'hire_job_description_positions': 'Positions',
  'hire_job_description_number_of_positions': 'Number of positions',
  'hire_job_description_salary_range': 'Salary range',
  'hire_job_description_duration_of_position': 'Duration of position',
  'hire_job_description_duration_of_one_torn': 'Duration of one torn',
  'hire_job_description_duration_of_one_torn_placeholder': 'Start typing and select from the list',
  'hire_job_description_industry': 'Industry',
  'hire_job_tabs_popover_content_title': 'Job opening has not published yet',
  'hire_job_tabs_popover_content_description_invite': 'Publish the job opening to start inviting users',
  'hire_job_tabs_popover_content_description_review': 'Publish job opening to receive applications from users.',
  'hire_description_required_certificates_section': 'Required certificates',
  'hire_description_job_description_section': 'Job description',
  'hire_description_responsibilities_section': 'Responsibilities',
  'hire_description_show_all_required_certificates': 'Show all required certificates',
  'hire_description_not_found_vacancy': 'Vacancy not found',
  'hire_confirm_apply_to_vacancy_title': 'Applying to a job opening will allow the employer to see your full profile',
  'hire_share_profile_share_vacancy_link': 'Share vacancy link',
  'hire_confirm_not_publish_vacancy': "Your vacancy didn't publish.",
  'hire_confirm_publish_vacancy': 'If you want to share your vacancy, you have to publish it.',
  'hire_publish_vacancy': 'Publish job opening',
  'hire_job_description_right_side_button_label': 'See all',
  'hire_job_description_right_side_button_label_review': 'Review all',
  'hire_job_description_right_side_button_label_invite_users': 'Invite more users',
  'hire_job_description_i_a_title': 'I/A',
  'hire_job_description_i_a_title_full': 'Invited/Applied',
  'hire_job_description_applied_crew_title': 'Applied crew',
  'hire_job_description_review_applied_crew_title': 'Review applied crew',
  'hire_job_applied_no_crew_title': 'No crew',
  'hire_job_description_empty_applied_crew_title': 'No applied crew yet',
  'hire_job_description_invited_crew_title': 'Invited crew',
  'hire_job_description_no_invited_user_label': 'No invited users yet',
  'hire_job_invited_label': 'Invited',
  'hire_job_description_no_invited_user_description': 'Invite users to this job to get feedback',
  'hire_job_description_closed_job_description': 'Open job opening to invite users',
  'hire_job_description_no_applied_user_label': 'No applied users yet',
  'hire_job_description_no_applied_user_description': 'No one has applied for your job yet',
  'hire_job_description_closed_user_applied_description': 'Open job opening to allow users to apply',
  'hire_job_description_invite_users_label': 'Invite users',
  'hire_job_description_amount_users': '{amount} users',
  'hire_job_description_amount_matched_users': '{amount} matched',
  'hire_job_description_empty_invited_crew_title': 'No invited crew yet',
  'hire_job_description_discard_title': 'Discard',
  'hire_job_description_un_invite_title': 'Uninvite',
  'hire_job_description_invite_to_interview_title': 'Invite to interview',
  'job_description_apply_popover': 'Your profile score must be more then 20',

  //hire organization member
  'hire_add_new_organization_team_member': 'Team member',
  'hire_add_new_organization_add_member_submit': 'Add a member',
  'hire_add_new_organization_member_form': 'Add user to the team members list',
  'hire_add_new_organization_member_form_description': 'Enter the user’s name and press ‘Invite’ button',
  'hire_organization_members_page_title': 'Team members',
  'hire_organization_members_total': '{value} total members',
  'hire_organization_members_total_confirmed': '{value} confirmed',
  'hire_organization_delete_members_title': 'Delete team member',
  'hire_organization_delete_members_warning_title': 'Member cannot be deleted because it is still assigned to some job openings',
  'hire_organization_delete_members_description': 'Are you sure you want to remove the team member from the list?',
  'hire_organization_member_id': 'Member - {value}',
  'hire_organization_member_name': 'Organization: {value}',
  'hire_organization_invites': 'Total organization invites: {value}',
  'hire_organization_empty_invites': 'You don’t have any invites',
  'label_organization': 'Organization',
  'label_organization_invites': 'Organization invites',
  'label_setup_organization': 'Setup your organization',
  'label_organizations': 'Organizations',
  'label_exit_from_selected_organisation': 'Exit from the selected organisation',
  'label_no_organizations_yet': 'No organizations yet',
  'label_organizations_list': 'Organizations list',
  'label_organizations_empty_ist': 'Organizations list is empty',
  'hire_organization_load_more_members': 'Load more members',
  'hire_organisation_no_team_members': 'This employer has no team members yet',

  //hire crew pools
  'hire_add_crew_pool': 'Add crew pool',
  'hire_crew_pools': 'Crew pools',
  'hire_crew_added': '{value} crew added',
  'hire_create_new_crew_pool': 'Create new pool',
  'hire_create_new': 'Create new',
  'hire_create_new_crew_pool_placeholder': 'Crew pool name',
  'hire_crew_pools_is_empty': 'Your crew pools is empty',
  'hire_crew_pools_new_pool_name': 'New pool name',
  'hire_crew_pool_delete_title': 'Delete crew pool',
  'hire_crew_pool_delete_user_title': 'Delete user from pool',
  'hire_crew_pool_delete_user_description': 'Are you sure you want to delete this user from pool?',
  'hire_crew_pool_delete_description': 'Are you sure you want to delete crew pool?',
  'hire_crew_pool_is_empty': 'Your pool is empty',
  'hire_crew_pool_invite_user_title': 'Save this user to a crew pool',
  'hire_crew_pool_invite_user_description': 'Add and structure users by a category',

  // Hire congratulation modal
  'hire_congratulation_modal_title': 'Welcome to Crewlinker!',
  'hire_congratulation_modal_description': 'The #1 recruiting platform in the maritime and offshore industry',
  'hire_congratulation_modal_button_title': 'Explore Crewlinker',

  // Search input in header
  'search_input_placeholder': 'Search',
  'search_input_placeholder_job_or_crew': 'Search crew or job, employers...',
  'search_input_placeholder_job_openings': 'Search job openings...',
  'search_input_placeholder_search_crew': 'Search crew...',
  'search_input_placeholder_search_organisation': 'Search organisation...',
  'search_input_placeholder_search_member': 'Enter user’s name',
  'search_input_no_profiles_results': 'No member was found',
  'search_input_no_employer_results': 'No employers were found',
  'search_input_no_jobs_results': 'No job opening was found',
  'see_all_crew': 'Crew',
  'see_all_employers': 'Employers',
  'crew_job_openings': 'Job openings',
  'crew_find_a_job_opening': 'Find a job opening',
  'crew_find_organisation': 'Find an organisation',

  // Job responses
  'job_responses_main_title': 'Job responses',
  'job_responses_empty_invites_title': 'No invitations yet',
  'job_responses_empty_applied_title': 'No job applications yet',
  'job_responses_empty_invites_description': 'Apply for a job so employer will notice you and send you an invitation for a job.',
  'job_responses_empty_applied_description': 'We could not find a job opening for your request. Try searching for a job in a global search.',
  'job_responses_empty_search_button_label': 'Search for a job opening',

  // Job opening
  'job_openings_page_please_login_to_apply': 'Please login to apply',
  'job_openings_page_amount_found': '{amount} found',
  'job_opening_empty_page_title': 'No job opening found',
  'job_opening_show_all_job_openings': 'Show all job openings',
  'job_opening_delete_title': 'Delete job opening',
  'job_opening_delete_description': 'Are you sure you want to delete job opening',
  'job_opening_empty_page_description': 'We could not find a job opening for your request. Try to change filter items.',
  'job_opening_load_more_gob_openings': 'Load more job openings',
  'job_opening_save_and_review': 'Save and review job opening',
  'job_opening_empty_page_description_for_operator': 'This employer has no job openings yet',
  'job_opening_browser_other_employers': 'Browse through job openings from other employers',

  // Job opening slide over
  'slide_button_label_see_all_information': 'See all information',
  'slide_button_label_see_all_info': 'See all info',

  // currency
  'usd': 'USD',

  // Review applications
  'review_applications_empty_page': "Currently vacancy doesn't have invitations or applications",
  'review_applications_applied_crews_empty_page': "Currently vacancy doesn't have applied crews",
  'review_applications_invited_crews_empty_page': "Currently vacancy doesn't have invited crews",
  'review_applications_contact_label': 'Contact',
  'review_applications_contacted_label': 'Contacted',
  'review_applications_filter_show_only': 'Show only',

  // Crew account view for hire account
  'modal_with_job_openings_with_contact_button_title': 'For which job opening do you wanna contact?',

  // Vacancy's organization info
  'about_label': 'About',
  'other_job_openings': 'Other job openings',

  // Helmet meta tags
  'main_page_meta_title': 'Your seafarers account | Crewlinker',
  'main_page_meta_description':
    'The place for you to be! Get the 100/100 score by filling in all information in your seafarers account. Contact employers and search for maritime vacancies.',
  'register_form_meta_title': 'Create your seafarers account | Crewlinker',
  'register_form_meta_description':
    "You're navigating in the right direction. This is step one in finding sea jobs. Create your seafarers account and explore the current maritime vacancies.",

  // Chromatic static strings
  'chromatic_sea_service_job_experience': '38 years 10 months total',

  // User's availability for work
  'available_for_work_label': "You're now available for work",
  'unavailable_for_work_label': "You're no longer available for work",
};

export default translations;
