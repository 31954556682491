import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-relay';
// models
import { SearchProfileNode } from 'models/IProfiles';
// generated
import { ProfileMutationsCreateOrganizationMemberMutation } from 'schemas/profile/__generated__/ProfileMutationsCreateOrganizationMemberMutation.graphql';
// schemas
import { ADD_NEW_MEMBER_TO_ORGANIZATION } from 'schemas/profile/ProfileMutations';
// updaters
import { addNewMemberToHireOrgUpdater } from 'formHelpers/updaters/updatersOfHireNewMembers';
// components
import SpinnerIcon from 'icons/SpinnerIcon';
import DoneSingleIcon from 'icons/DoneSingleIcon';
import CustomButton from 'system/Buttons/CustomButton';
import SearchNodeProfileItem from 'system/SearchNodeProfileItem/SearchNodeProfileItem';

const EmployerOrganizationAddMemberListItem = ({ profileNode, isInvitedProfile }: { profileNode: SearchProfileNode; isInvitedProfile: boolean }) => {
  const intl = useIntl();
  const { orgId } = useParams();

  const [addNewMember, addNewMemberLoading] = useMutation<ProfileMutationsCreateOrganizationMemberMutation>(ADD_NEW_MEMBER_TO_ORGANIZATION);

  const addNewTeamMember = () => {
    addNewMember({
      variables: {
        data: {
          organizationId: orgId!,
          profileId: profileNode.objectID,
        },
      },
      updater: addNewMemberToHireOrgUpdater(`${orgId}`),
    });
  };
  return (
    <div className="w-full border-b last:border-b-0 py-2.5 sm:py-2 px-2.5 flex items-start hover:bg-blue-50 hover:font-medium space-x-3">
      <SearchNodeProfileItem profile={profileNode as SearchProfileNode} />
      <CustomButton
        onClick={addNewTeamMember}
        disabled={isInvitedProfile}
        className={`flex w-fit h-8 items-center justify-center text-sm ${
          isInvitedProfile ? 'border-green-500 text-green-500 pl-1 pr-2.5' : 'text-specialGray-075 border-grayIcon hover:bg-specialGray-002 px-3.5'
        } border rounded-[10px] font-medium self-center`}
      >
        {isInvitedProfile ? <DoneSingleIcon additionalClasses="w-8 h-8 mr-1" /> : null}
        {addNewMemberLoading ? (
          <SpinnerIcon additionalClasses="w-5 h-5" />
        ) : (
          intl.formatMessage({ id: isInvitedProfile ? 'hire_job_invited_label' : 'search_list_item_invite_button_label' })
        )}
      </CustomButton>
    </div>
  );
};

export default EmployerOrganizationAddMemberListItem;
