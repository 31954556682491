import graphql from 'babel-plugin-relay/macro';

export const ALLOW_ASSUMING_PROFILE_BY_ID = graphql`
  mutation AdminAssumeMutationsAllowByIdMutation($id: ID!) {
    assumeProfileWithID(id: $id)
  }
`;

export const STOP_ALLOW_ASSUMING_PROFILE_BY_ID = graphql`
  mutation AdminAssumeMutationsStopAllowByIdMutation {
    clearAssumedProfile
  }
`;
