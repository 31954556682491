// packages
import { useCallback, useState } from 'react';
// components
import ShipPhoto from 'icons/ShipPhoto';
import { PinCodev2 } from 'system/PinCodeComponent/PinCodev2';
import { InputGroup } from 'system/InputGroups/InputGroup';
import SelectInputGroup, { OptionType } from 'system/SelectInputGroup';
// models
import { IVesselKind } from 'models/IVesselKind';
// generated
import { ServiceFragmentsVesselKind$data } from 'schemas/services/__generated__/ServiceFragmentsVesselKind.graphql';
// types
import { VesselInformationProps } from '../types';

const VesselInformation = ({ dataOfVesselKind, maxInputLength, ServicesData, isResetForm, setGetDetailsVessel, vesselKinds, handleError, globalError }: VesselInformationProps) => {
  const [selectedType, setSelectedType] = useState<ServiceFragmentsVesselKind$data | null>(dataOfVesselKind);

  const [types, setTypes] = useState<IVesselKind>(vesselKinds || []);

  const handleChangeType = useCallback(
    (value: string) => {
      if (value) {
        setTypes(() => vesselKinds.filter(({ name }: OptionType) => name?.toLowerCase().includes(value.toLowerCase())) as IVesselKind);
      } else {
        setTypes(() => vesselKinds);
      }
    },
    [vesselKinds],
  );

  return (
    <>
      <section className="flex flex-col sm:flex-row sm:justify-between">
        <section className="bg-avatar-bg mr-0 w-full h-36 sm:mr-5 sm:w-1/5 sm:h-20 flex items-center justify-center mx-auto rounded-xl">
          <span className="w-[37px] h-[33px]">
            <ShipPhoto />
          </span>
        </section>

        {/* hardcoded part, update it */}
        <div className="sm:w-4/5 w-full relative flex flex-col mt-5 sm:mt-0 justify-between">
          <PinCodev2 name="vessel.imo" label={{ id: 'service_form_vessel_imo' }} maxLength={maxInputLength} inputType="number" />
        </div>
      </section>

      <section className="flex flex-row">
        <div className="w-full sm:w-8/12 sm:mr-5">
          <InputGroup name="vessel.name" label={{ id: 'field_is_name' }} placeholderText={{ id: 'field_is_name' }} />
        </div>
        <div className="hidden sm:flex w-2/6">
          <SelectInputGroup
            name="vessel.kind"
            options={[...types].sort((a, b) => a.name.localeCompare(b.name))}
            label={{ id: 'service_form_type_label' }}
            onChangeSelect={handleChangeType}
            placeholder={{ id: 'placeholder_form_type' }}
            defaultSelectedElement={selectedType}
            setSelectedItem={setSelectedType}
            selectedItem={selectedType}
            isResetInput={isResetForm}
            autoComplete="off"
          />
        </div>
      </section>

      <div className="mt-5">
        <InputGroup name="vessel.operatorName" label={{ id: 'service_form_vessel_operator_name' }} placeholderText={{ id: 'service_form_vessel_operator_name' }} />
      </div>

      <div className="sm:hidden flex w-full">
        <SelectInputGroup
          name="vessel.kind"
          options={[...types].sort((a, b) => a.name.localeCompare(b.name))}
          label={{ id: 'service_form_type_label' }}
          onChangeSelect={handleChangeType}
          placeholder={{ id: 'placeholder_form_type' }}
          defaultSelectedElement={selectedType}
          setSelectedItem={setSelectedType}
          selectedItem={selectedType}
          isResetInput={isResetForm}
          autoComplete="off"
        />
      </div>
    </>
  );
};

export default VesselInformation;
