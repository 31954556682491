/**
 * @generated SignedSource<<f9cd3e7f7bfcdcd61cc9e25d18630c25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type OrganisationQueriesGetListOfOrganisationsByIdsQuery$variables = {
  ids: ReadonlyArray<string>;
};
export type OrganisationQueriesGetListOfOrganisationsByIdsQuery$data = {
  readonly nodes: ReadonlyArray<{
    readonly 'id': string;
    readonly ' $fragmentSpreads': FragmentRefs<'OrganisationFragmentsForSearchFragment'>;
  } | null>;
};
export type OrganisationQueriesGetListOfOrganisationsByIdsQuery = {
  response: OrganisationQueriesGetListOfOrganisationsByIdsQuery$data;
  variables: OrganisationQueriesGetListOfOrganisationsByIdsQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'ids',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'ids',
        variableName: 'ids',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'OrganisationQueriesGetListOfOrganisationsByIdsQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'nodes',
          plural: true,
          selections: [
            v2 /*: any*/,
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'OrganisationFragmentsForSearchFragment',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'OrganisationQueriesGetListOfOrganisationsByIdsQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'nodes',
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: '__typename',
              storageKey: null,
            },
            v2 /*: any*/,
            {
              kind: 'InlineFragment',
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'name',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'description',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'avatarUrl',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'websiteUrl',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'headquarters',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'fleetsize',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'numberOfMembers',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'numberOfVacancies',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'size',
                  storageKey: null,
                },
              ],
              type: 'Organization',
              abstractKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '697e75d8e45ce5f84154a8b6c0dcfbf8',
      id: null,
      metadata: {},
      name: 'OrganisationQueriesGetListOfOrganisationsByIdsQuery',
      operationKind: 'query',
      text: 'query OrganisationQueriesGetListOfOrganisationsByIdsQuery(\n  $ids: [ID!]!\n) {\n  nodes(ids: $ids) {\n    __typename\n    id\n    ...OrganisationFragmentsForSearchFragment\n  }\n}\n\nfragment OrganisationFragmentsForSearchFragment on Organization {\n  id\n  name\n  description\n  avatarUrl\n  websiteUrl\n  headquarters\n  fleetsize\n  numberOfMembers\n  numberOfVacancies\n  size\n}\n',
    },
  };
})();

(node as any).hash = '0bd1b6c235053f2ef762b4b2623dc06a';

export default node;
