/**
 * @generated SignedSource<<720efbe1ebe3ca9a342c2dcdc06709d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type VacancyStatus = 'CLOSED' | 'OPEN' | '%future added value';
export type VacancyQueriesGetTotalCountOfVacanciesQuery$variables = {
  orgId: string;
  skip: boolean;
};
export type VacancyQueriesGetTotalCountOfVacanciesQuery$data = {
  readonly organizationVacancyStats?: {
    readonly stats: ReadonlyArray<{
      readonly status: VacancyStatus;
      readonly totalCount: number;
    }>;
  } | null;
};
export type VacancyQueriesGetTotalCountOfVacanciesQuery = {
  response: VacancyQueriesGetTotalCountOfVacanciesQuery$data;
  variables: VacancyQueriesGetTotalCountOfVacanciesQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'orgId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'skip',
      },
    ],
    v1 = [
      {
        condition: 'skip',
        kind: 'Condition',
        passingValue: false,
        selections: [
          {
            alias: null,
            args: [
              {
                kind: 'Variable',
                name: 'orgId',
                variableName: 'orgId',
              },
            ],
            concreteType: 'OrganizationVacancyStats',
            kind: 'LinkedField',
            name: 'organizationVacancyStats',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'VacancyStats',
                kind: 'LinkedField',
                name: 'stats',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'totalCount',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'status',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'VacancyQueriesGetTotalCountOfVacanciesQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'VacancyQueriesGetTotalCountOfVacanciesQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'd3a3e50cca16002e98541378cf40366e',
      id: null,
      metadata: {},
      name: 'VacancyQueriesGetTotalCountOfVacanciesQuery',
      operationKind: 'query',
      text: 'query VacancyQueriesGetTotalCountOfVacanciesQuery(\n  $orgId: ID!\n  $skip: Boolean!\n) {\n  organizationVacancyStats(orgId: $orgId) @skip(if: $skip) {\n    stats {\n      totalCount\n      status\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'c76308e019936eb2fee02c0145bf68a4';

export default node;
