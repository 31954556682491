// packages
import { useEffect, useState } from 'react';
import ReactConfetti from 'react-confetti';
import { useLocation, useNavigate } from 'react-router-dom';
// hooks
import { useCurrentWidth } from 'hooks/useBreakpoints';
// components
import WelcomeForHireModal from './components/WelcomeForHireModal';
// routes
import { RoutePaths } from 'app/routing';

const WelcomeHireCongratulation = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const windowSize = useCurrentWidth();

  const [showConfettiComponent, setShowConfettiComponent] = useState(!!state?.isNewAccount);

  useEffect(() => {
    if (!state?.isNewAccount) {
      navigate(RoutePaths.HomePath);
    }
  }, [navigate, state?.isNewAccount]);

  return showConfettiComponent ? (
    <>
      <ReactConfetti width={windowSize.width} height={windowSize.height} style={{ zIndex: 60 }} numberOfPieces={500} />
      <WelcomeForHireModal handleShowConfetti={setShowConfettiComponent} />
    </>
  ) : null;
};

export default WelcomeHireCongratulation;
