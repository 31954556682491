//components
import NavBarSkeleton from 'system/skeletons/NavBar/NavBarSkeleton';
import TabsSkeleton from 'system/skeletons/hireProfile/JobDescriptionTabsSkeleton/components/TabsSkeleton';
import JobDescriptionEditPreviewSkeleton from 'system/skeletons/hireProfile/JobDescriptionTabsSkeleton/JobDescriptionEditPreviewSkeleton';

const JobDescriptionTabsPreviewSkeleton = () => {
  return (
    <div className="flex flex-col">
      <NavBarSkeleton />
      <TabsSkeleton />
      <div className="my-10">
        <JobDescriptionEditPreviewSkeleton />
      </div>
    </div>
  );
};

export default JobDescriptionTabsPreviewSkeleton;
