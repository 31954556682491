import { FC } from 'react';

const BigInvitationIcon: FC = () => {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7980_69697)">
        <path
          d="M33.8333 6.66797H7.16665C5.33331 6.66797 3.83331 8.16797 3.83331 10.0013V30.0013C3.83331 31.8346 5.33331 33.3346 7.16665 33.3346H33.8333C35.6666 33.3346 37.1666 31.8346 37.1666 30.0013V10.0013C37.1666 8.16797 35.6666 6.66797 33.8333 6.66797ZM33.1666 13.7513L22.2666 20.568C21.1833 21.2513 19.8166 21.2513 18.7333 20.568L7.83331 13.7513C7.41665 13.4846 7.16665 13.0346 7.16665 12.5513C7.16665 11.4346 8.38331 10.768 9.33331 11.3513L20.5 18.3346L31.6666 11.3513C32.6166 10.768 33.8333 11.4346 33.8333 12.5513C33.8333 13.0346 33.5833 13.4846 33.1666 13.7513Z"
          fill="#082051"
          fillOpacity="0.3"
        />
        <path
          d="M20.5 32.3084C20.5 28.3433 23.7144 25.1289 27.6795 25.1289H35.8846C39.8497 25.1289 43.0641 28.3433 43.0641 32.3084C43.0641 36.2735 39.8497 39.4879 35.8846 39.4879H27.6795C23.7144 39.4879 20.5 36.2735 20.5 32.3084Z"
          fill="#FCFCFC"
        />
        <path
          d="M30.5467 27.3299C30.5467 26.5642 31.4655 26.1899 31.9929 26.7344L36.7398 31.4813C37.08 31.8215 37.08 32.349 36.7398 32.6892L31.9929 37.4361C31.4655 37.9636 30.5467 37.5892 30.5467 36.8236V33.7781H25.4425C24.5068 33.7781 23.7411 33.0125 23.7411 32.0767C23.7411 31.141 24.5068 30.3754 25.4425 30.3754H30.5467V27.3299Z"
          fill="#082051"
          fillOpacity="0.3"
        />
      </g>
      <defs>
        <clipPath id="clip0_7980_69697">
          <rect width="40" height="40" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BigInvitationIcon;
