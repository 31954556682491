import { StrictMode, PropsWithChildren } from 'react';
import { RecoilRoot } from 'recoil';
import { Helmet } from 'react-helmet';
import BrowserGate from './BrowserGate';
import { IntlProvider } from 'react-intl';
import SkeletonContext from './Context/StorybookContext';
import translations from './translations';

import { RelayEnvironmentProvider } from 'react-relay/hooks';
import environment from './environment';
import { AuthProvider } from 'authentication';
import ErrorBoundary from './system/ErrorBoundary';
import HireProfileContext from 'Context/HireProfileContext';
import NavbarSearchContext from './Context/NavbarSearchContext';

const Providers = ({ children }: PropsWithChildren) => {
  return (
    <IntlProvider locale={navigator.language} messages={translations}>
      <RelayEnvironmentProvider environment={environment}>
        <ErrorBoundary>
          <StrictMode>
            <RecoilRoot>
              <BrowserGate>
                <SkeletonContext>
                  <HireProfileContext>
                    <NavbarSearchContext>
                      <AuthProvider>
                        <Helmet>
                          <title>Your seafarers account | Crewlinker</title>
                          <meta
                            name="description"
                            content="The place for you to be! Get the 100/100 score by filling in all information in your seafarers account. Contact employers and search for maritime vacancies."
                          />
                        </Helmet>
                        {children}
                      </AuthProvider>
                    </NavbarSearchContext>
                  </HireProfileContext>
                </SkeletonContext>
              </BrowserGate>
            </RecoilRoot>
          </StrictMode>
        </ErrorBoundary>
      </RelayEnvironmentProvider>
    </IntlProvider>
  );
};

export default Providers;
