// packages
import { atom } from 'recoil';
// enums
import { ORDER_DIRECTION, VACANCY_ORDER_FIELD } from 'models/enums';
import { vacancyFiltersTypes } from 'recoil/Organisation/Dashboard/types';

// only orderBy
export const selectedDashboardVacanciesByParamsOpenAtom = atom<vacancyFiltersTypes>({
  key: 'selectedDashboardVacanciesByParamsOpenAtom',
  default: [
    {
      orderBy: {
        field: VACANCY_ORDER_FIELD.LAST_INVITED_AT,
        direction: ORDER_DIRECTION.DESC,
      },
    },
  ],
});
