import React from 'react';
import { ServiceTimeProps } from 'models/modelsOfComponents';

const ServiceTime: React.FC<ServiceTimeProps> = ({ startDate, endDate }) => {
  return (
    <div className="font-semibold">
      {`${startDate ?? ''}`}
      {endDate && <>&nbsp;&#8208;&nbsp;{endDate}</>}
    </div>
  );
};
export default ServiceTime;
