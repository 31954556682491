// packages
import React, { useEffect, useState } from 'react';
import { useFragment, useMutation } from 'react-relay';
import { Form, FormikProvider, useFormik } from 'formik';
// components
import { Button } from 'system/Button';
import AlertForError from 'system/Alert/AlertForError';
import { TextareaInputGroup } from 'system/TextareaInputGroup';
// generated
import { ServiceFragments$data, ServiceFragments$key } from 'schemas/services/__generated__/ServiceFragments.graphql';
import { ServiceMutationsAddDescriptionToSeaServiceMutation } from 'schemas/services/__generated__/ServiceMutationsAddDescriptionToSeaServiceMutation.graphql';
// helpers
import { ServiceNoteFormValidateSchema } from 'formHelpers/validationsOfForms';
import { ServiceNoteFormInitialValues } from 'formHelpers/initialValuesOfForms';
// schemas
import { SERVICE_FRAGMENT } from 'schemas/services/ServiceFragments';
import { ADD_DESCRIPTION_TO_SEA_SERVICE } from 'schemas/services/ServiceMutations';
// models
import { ServiceNoteFormData } from 'models/modelsOfForms';
import { ServiceFormNoteProps } from 'models/modelsOfComponents';

const ServiceNoteForm: React.FC<ServiceFormNoteProps> = ({ defaultError = null, defaultSubmitting, onCloseModal, selectedService }) => {
  const dataOfService = useFragment<ServiceFragments$key>(SERVICE_FRAGMENT, selectedService || null);
  const [error, setError] = useState<Error | null>(defaultError);
  const [commitAddDescription] = useMutation<ServiceMutationsAddDescriptionToSeaServiceMutation>(ADD_DESCRIPTION_TO_SEA_SERVICE);

  const onSubmit = (formData: ServiceNoteFormData) => {
    const onError = (error: Error) => {
      setError(error);
      formik.setSubmitting(false);
    };

    commitAddDescription({
      onError,
      onCompleted: () => {
        window.analytics?.track('wui-added-note', {
          label: 'Added a note to sea service',
        });
        if (onCloseModal) {
          onCloseModal();
        }
      },
      variables: {
        id: dataOfService?.id as string,
        description: formData.note,
      },
    });
  };

  const formik = useFormik<ServiceNoteFormData>({
    onSubmit,
    initialValues: ServiceNoteFormInitialValues(dataOfService as ServiceFragments$data),
    validationSchema: ServiceNoteFormValidateSchema,
  });

  useEffect(() => {
    if (defaultSubmitting && !formik.isSubmitting) formik.setSubmitting(true);
  }, [formik, defaultSubmitting]);

  return (
    <FormikProvider value={formik}>
      <Form className="space-y-6">
        {error && <AlertForError heading={{ id: 'service_form_note_failedSubmit' }} error={error} />}
        <TextareaInputGroup
          name="note"
          label={{ id: 'service_form_about_sea_service' }}
          description={{ id: 'form_limit_characters', description: { name: 0 } }}
          maxCharCount={200}
          placeholderText={{ id: 'placeholder_form_type' }}
          autoFocus
        />
        <div className="flex justify-end">
          <Button type="button" label={{ id: 'cancel' }} buttonType="white-primary" additionalClasses="mr-4" disabled={formik.isSubmitting} onClick={onCloseModal} />
          <Button type="submit" label={{ id: 'service_form_note_save_info' }} disabled={formik.isSubmitting} />
        </div>
      </Form>
    </FormikProvider>
  );
};

export default ServiceNoteForm;
