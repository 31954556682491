import graphql from 'babel-plugin-relay/macro';

export const GET_DOCUMENT_KINDS = graphql`
  query DocumentQueriesGetKindsQuery {
    documentKinds {
      edges {
        id
        name
        perma
      }
    }
  }
`;
