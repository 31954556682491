/**
 * @generated SignedSource<<51f62e9adbda2a2df064edf579cf6b3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DocumentData = {
  kindId: string;
  original?: string | null;
  ownerId?: string | null;
  urls: ReadonlyArray<string>;
  xlarge_urls?: ReadonlyArray<string> | null;
};
export type DocumentMutationsUpdateDocumentMutation$variables = {
  data: DocumentData;
  id: string;
};
export type DocumentMutationsUpdateDocumentMutation$data = {
  readonly updateDocument: {
    readonly id: string;
    readonly urls: ReadonlyArray<string>;
    readonly xlarge_urls: ReadonlyArray<string> | null;
  };
};
export type DocumentMutationsUpdateDocumentMutation = {
  response: DocumentMutationsUpdateDocumentMutation$data;
  variables: DocumentMutationsUpdateDocumentMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'data',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'data',
            variableName: 'data',
          },
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        concreteType: 'Document',
        kind: 'LinkedField',
        name: 'updateDocument',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'urls',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'xlarge_urls',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'DocumentMutationsUpdateDocumentMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'DocumentMutationsUpdateDocumentMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '93f9d8b04e94f1bfc75df5dbd2317d81',
      id: null,
      metadata: {},
      name: 'DocumentMutationsUpdateDocumentMutation',
      operationKind: 'mutation',
      text: 'mutation DocumentMutationsUpdateDocumentMutation(\n  $id: ID!\n  $data: DocumentData!\n) {\n  updateDocument(id: $id, data: $data) {\n    id\n    urls\n    xlarge_urls\n  }\n}\n',
    },
  };
})();

(node as any).hash = '3c509edfabf525cd71d15188eb93be4c';

export default node;
