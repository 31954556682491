import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { isEmpty } from 'lodash';
import { useLazyLoadQuery } from 'react-relay';
import { useSearchParams } from 'react-router-dom';
//utils
import { yearsToMonths } from 'formHelpers/utils';
import { createRangeFilter } from 'utils/filterHelpers';
//models
import { FILTER_BOOLEAN_ID, FILTER_TYPE, SEARCH_INDEX } from 'models/enums';
// schemas
import { SEARCH_QUERY } from 'schemas/search/SearchQueries';
// generated
import { SearchQueriesQuery } from 'schemas/search/__generated__/SearchQueriesQuery.graphql';
import { useSearchArrayFromFiltersType } from 'hooks/searchFilter/useSearchArrayFromFiltersType';
// context
import { useNavbarSearchContext } from 'Context/NavbarSearchContext';
// recoil
import { profileSearchScrollWithPaginationSelector } from 'recoil/ScrollWithPagination/selectors/SearchScrollWithPaginationSelector';

const useSearchProfileFilterQuery = (searchQuery: string | null) => {
  const [filters, setFilters] = useState<string>('');
  const [searchUseSearch] = useSearchParams();
  const searchArrayFromFiltersType = useSearchArrayFromFiltersType();
  const { queryParams } = useNavbarSearchContext();
  const countOfSearchMembers = useRecoilValue(profileSearchScrollWithPaginationSelector);

  const listOfProfiles = useLazyLoadQuery<SearchQueriesQuery>(SEARCH_QUERY, {
    first: countOfSearchMembers.numberOfFetchNumber,
    filters,
    query: searchQuery ?? queryParams,
    index: SEARCH_INDEX.PROFILE,
    skip: false,
  });

  useEffect(() => {
    const arrayOfAllFilters: Array<Array<string>> = [];

    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.VESSEL_TYPES, 'vesselTypes', true);
    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.ACTIVITIES, 'seaServiceActivities', true);
    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.CERTIFICATES, 'certificates', true);
    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.EDUCATION, 'educationKinds', true);
    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.DUTIES, 'seaServiceDuties:dutyId', true, 'dutyId');
    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.PRIMARY_DUTIES, 'primaryDuty', true);
    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.MOST_RECENT_SERVICE_DUTY, 'mostRecentSeaServiceDuty', true);
    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.COUNTRIES, 'country', true);
    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.WORKED_FOR, 'vesselOperatorsAndManagers');
    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.CREW_VESSEL_IMO, 'vesselImos', true);
    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.AVAILABLE_FOR_WORK, 'availableForWork', true, undefined, [
      { booleanFilterId: FILTER_BOOLEAN_ID.AVAILABLE_FOR_WORK, boolValue: true },
      { booleanFilterId: FILTER_BOOLEAN_ID.NOT_AVAILABLE_FOR_WORK, boolValue: false },
    ]);

    createRangeFilter(arrayOfAllFilters, 'numSeaServices', {
      from: searchUseSearch.get(FILTER_TYPE.NUMBER_OF_SEA_SERVICES_FROM) || '',
      to: searchUseSearch.get(FILTER_TYPE.NUMBER_OF_SEA_SERVICES_TO) || '',
    });
    createRangeFilter(arrayOfAllFilters, 'seaServiceMonthsOfExperience', {
      from: yearsToMonths(searchUseSearch.get(FILTER_TYPE.NUMBER_OF_EXPERIENCE_FROM) || ''),
      to: yearsToMonths(searchUseSearch.get(FILTER_TYPE.NUMBER_OF_EXPERIENCE_TO) || ''),
    });
    createRangeFilter(arrayOfAllFilters, 'numAppraisals', {
      from: searchUseSearch.get(FILTER_TYPE.NUMBER_OF_APPRAISALS_FROM) || '',
      to: searchUseSearch.get(FILTER_TYPE.NUMBER_OF_APPRAISALS_TO) || '',
    });

    if (!isEmpty(arrayOfAllFilters)) {
      const mappingArray = arrayOfAllFilters.map((element, index, array) => {
        // When an employer filters on certificates, it should narrow down the results instead of making it bigger. They should have AND certificate 1 AND certificate 2. Not OR.
        const isCertificateElement = element[0].includes(FILTER_TYPE.CERTIFICATES);

        return !isEmpty(element) ? `(${element.join(isCertificateElement ? ' AND ' : ' OR ')})${array[index + 1] ? ' AND ' : ''}` : '';
      });
      setFilters(mappingArray.join(''));
    } else {
      setFilters('');
    }
  }, [searchArrayFromFiltersType, searchUseSearch]);

  return {
    listOfProfiles,
  };
};

export default useSearchProfileFilterQuery;
