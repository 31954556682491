// packages
import { useCallback, Suspense, useState, useMemo, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
// components
import SlideOver from 'system/SlideOver';
import SpinnerIcon from 'icons/SpinnerIcon';
import AppliedCrewSlider from 'app/ProfileEmployer/components/ReviewApplicationsPage/components/AppliedCrewSlider/AppliedCrewSlider';
// routes
import { RoutesPathNames } from 'app/routing';
//hooks
import useGetAppliedHireProfileVacancies from 'hooks/hireProfileHooks/useGetAppliedHireProfileVacancies';
// recoil
import { contactInformationAtom } from 'recoil/ContactInformation/atoms/contactInformationAtom';

const SlideOverWithCrewContact = () => {
  const params = useParams();

  const [getContactInfoData, setContactInfoButton] = useRecoilState(contactInformationAtom);

  const dynamicProfileId = params[RoutesPathNames.id] ?? getContactInfoData.profileId;

  const appliedVacancies = useGetAppliedHireProfileVacancies();

  const [showConfirmModalInSlideOver, toggleShowConfirmModalInSlideOver] = useState<boolean>(false);
  const [isTouchedToggle, setIsTouchedToggle] = useState<boolean>(false);

  const handleHideModalAndConfirm = useCallback(() => {
    toggleShowConfirmModalInSlideOver(false);
    setContactInfoButton(prev => ({ ...prev, showContactInformation: false }));
  }, [setContactInfoButton]);

  const currentAppliedVacancy = useMemo(() => {
    const vacancyId = params?.jobDescriptionEditId;
    if (vacancyId) {
      return appliedVacancies?.find(nodeItem => nodeItem?.node?.vacancy.id === vacancyId);
    }
    return appliedVacancies?.[0];
  }, [appliedVacancies, params?.jobDescriptionEditId]);

  const getContactIsRelatedToCurrentCrew = useMemo(() => {
    return currentAppliedVacancy?.node?.vacancy?.contacts?.find(contact => contact.owner.id === dynamicProfileId);
  }, [currentAppliedVacancy?.node?.vacancy?.contacts, dynamicProfileId]);

  const handleCloseSlide = useCallback(() => {
    //hide slider if touched toggle button or this is crews page without applied vacancy for current employer account
    if (isTouchedToggle || !currentAppliedVacancy?.node?.vacancy.id) {
      setContactInfoButton(prev => ({ ...prev, showContactInformation: false }));

      return;
    }
    if (!getContactIsRelatedToCurrentCrew?.owner?.id && !isTouchedToggle) {
      setContactInfoButton(prev => ({ ...prev, showContactInformation: true }));

      toggleShowConfirmModalInSlideOver(true);
      return;
    }
    setContactInfoButton(prev => ({ ...prev, showContactInformation: false }));
  }, [isTouchedToggle, currentAppliedVacancy?.node?.vacancy.id, getContactIsRelatedToCurrentCrew?.owner?.id, setContactInfoButton]);

  useEffect(() => {
    // Stop the spinner of Contact button when the component has loaded
    setContactInfoButton(prev => ({ ...prev, isLoading: false }));
  }, [getContactInfoData.showContactInformation, setContactInfoButton]);

  return (
    <SlideOver show={getContactInfoData.showContactInformation} onClose={handleCloseSlide} size="max-w-sm">
      <Suspense fallback={<SpinnerIcon />}>
        <AppliedCrewSlider
          contactIsRelatedToCurrentCrew={getContactIsRelatedToCurrentCrew}
          profileId={dynamicProfileId || ''}
          vacancyId={params?.jobDescriptionEditId || appliedVacancies?.[0]?.node?.vacancy.id || ''}
          isShowConfirm={showConfirmModalInSlideOver}
          handleHideModalAndConfirm={handleHideModalAndConfirm}
          setIsTouchedToggle={setIsTouchedToggle}
          isTouchedConnectionToggle={isTouchedToggle}
        />
      </Suspense>
    </SlideOver>
  );
};

export default SlideOverWithCrewContact;
