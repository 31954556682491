import { useFragment, useMutation } from 'react-relay';
//schemas
import { PROFILE_GENERAL_SETTINGS_FRAGMENT } from 'schemas/profile/ProfileFragments';
import { ProfileFragments$data } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { CREATE_GENERAL_PROFILE_SETTINGS, UPDATE_GENERAL_PROFILE_SETTINGS } from 'schemas/profile/ProfileMutations';
import { ProfileMutationsCreateGeneralSettingsMutation } from 'schemas/profile/__generated__/ProfileMutationsCreateGeneralSettingsMutation.graphql';
import { ProfileMutationsUpdateGeneralSettingsMutation } from 'schemas/profile/__generated__/ProfileMutationsUpdateGeneralSettingsMutation.graphql';
import { ProfileFragmentsGeneralSettings$key } from 'schemas/profile/__generated__/ProfileFragmentsGeneralSettings.graphql';
//models
import { PERSONAL_GENERAL_SETTINGS } from 'models/enums';
//utils
import { correctAnalyticsEventName } from 'utils';

const useGeneralSettings = (profileData: ProfileFragments$data | null) => {
  const generalSettings = useFragment<ProfileFragmentsGeneralSettings$key>(PROFILE_GENERAL_SETTINGS_FRAGMENT, profileData?.generalSetting || null);

  const [commitCreateGeneralSettings, createLoading] = useMutation<ProfileMutationsCreateGeneralSettingsMutation>(CREATE_GENERAL_PROFILE_SETTINGS);
  const [commitUpdateGeneralSettings, updateLoading] = useMutation<ProfileMutationsUpdateGeneralSettingsMutation>(UPDATE_GENERAL_PROFILE_SETTINGS);

  const onChangeFieldGeneralSettings = (checkBoxGeneralSetting: PERSONAL_GENERAL_SETTINGS) => (value: boolean) => {
    if (!profileData?.id) {
      return;
    }
    if (generalSettings?.id) {
      const { availableForWork, hideContactInfo } = generalSettings;
      commitUpdateGeneralSettings({
        variables: {
          id: generalSettings.id,
          data: {
            ownerId: profileData.id,
            availableForWork,
            hideContactInfo,
            [checkBoxGeneralSetting]: value,
          },
        },
        onError: error => {
          throw error;
        },

        updater: store => {
          const profileRecord = store?.getRoot().getLinkedRecord(`node(id:"${profileData?.id}")`);
          const generalSettingRecord = store.getRootField('updateGeneralSetting');
          profileRecord?.setLinkedRecord(generalSettingRecord, 'generalSetting');
        },

        onCompleted: () => {
          const analyticsOption = !generalSettings[checkBoxGeneralSetting] ? 'enable' : 'disable';
          window.analytics?.track(`wui-settings-${correctAnalyticsEventName(checkBoxGeneralSetting)}-${analyticsOption}`);
        },
      });
    } else {
      commitCreateGeneralSettings({
        variables: {
          data: {
            ownerId: profileData?.id,
            availableForWork: true,
            hideContactInfo: false,
            [checkBoxGeneralSetting]: value,
          },
        },
        onError: error => {
          throw error;
        },
        updater: store => {
          const profileRecord = store?.getRoot().getLinkedRecord(`node(id:"${profileData?.id}")`);
          const generalSettingRecord = store.getRootField('createGeneralSetting');
          profileRecord?.setLinkedRecord(generalSettingRecord, 'generalSetting');
        },

        onCompleted: resp => {
          const analyticsOption = resp.createGeneralSetting[checkBoxGeneralSetting] ? 'enable' : 'disable';
          window.analytics?.track(`wui-settings-${correctAnalyticsEventName(checkBoxGeneralSetting)}-${analyticsOption}`);
        },
      });
    }
  };
  return {
    onChangeFieldGeneralSettings,
    generalSettings,
    loading: createLoading || updateLoading,
  };
};

export default useGeneralSettings;
