// packages
import { Suspense, useCallback, useLayoutEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFragment, useLazyLoadQuery } from 'react-relay';
// router
import { RoutePaths } from 'app/routing';
// components
import { Button } from 'system/Button';
import { Navbar } from 'app/Navbar/Navbar';
import NavBarSkeleton from 'system/skeletons/NavBar/NavBarSkeleton';
// hooks
import { useAuth } from 'authentication';
import useGeneralSettings from 'hooks/accountSettings/useGeneralSettings';
// models
import { PERSONAL_GENERAL_SETTINGS, PLATFORM_GROUP } from 'models/enums';
// generated
import { ProfileFragments$key } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { ProfileQueriesRootQuery, ProfileQueriesRootQuery$data } from 'schemas/profile/__generated__/ProfileQueriesRootQuery.graphql';
// schemas
import { PROFILE_FRAGMENT } from 'schemas/profile/ProfileFragments';
import { PROFILE_QUERY } from 'schemas/profile/ProfileQueries';

const AvailableForWork = ({ isAvailable }: { isAvailable: boolean }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { identity } = useAuth();
  const { pathname } = useLocation();

  const selfProfileQueryRef: ProfileQueriesRootQuery$data = useLazyLoadQuery<ProfileQueriesRootQuery>(PROFILE_QUERY, {
    id: `${identity?.profileId}`,
    skip: !identity?.profileId,
  });
  const profileData = useFragment<ProfileFragments$key>(PROFILE_FRAGMENT, selfProfileQueryRef.node || null);

  const { onChangeFieldGeneralSettings, generalSettings } = useGeneralSettings(profileData);

  useLayoutEffect(() => {
    onChangeFieldGeneralSettings(PERSONAL_GENERAL_SETTINGS.availableForWork)(isAvailable);
    // eslint-disable-next-line
  }, [isAvailable, profileData]);

  const handleClickByButton = useCallback(() => {
    navigate(RoutePaths.ProfileBase);
  }, [navigate]);

  useLayoutEffect(() => {
    if (generalSettings?.availableForWork === undefined && identity?.platformGroup === PLATFORM_GROUP.GUEST) {
      navigate(RoutePaths.AuthLogin, { state: { previousPathname: pathname } });
    }
  }, [generalSettings?.availableForWork, identity?.platformGroup, navigate, pathname]);

  if (generalSettings?.availableForWork === undefined && identity?.platformGroup !== PLATFORM_GROUP.GUEST) {
    return null;
  }

  return (
    <>
      <Suspense fallback={<NavBarSkeleton />}>
        <Navbar />
      </Suspense>
      <div className="flex flex-col w-screen h-[65vh] items-center justify-center gap-y-10">
        <h1 className="text-darkBlue text-3xl text-center">{intl.formatMessage({ id: isAvailable ? 'available_for_work_label' : 'unavailable_for_work_label' })}</h1>
        <Button buttonType="primary" label={{ id: 'error_go_to_back' }} onClick={handleClickByButton} />
      </div>
    </>
  );
};

export default AvailableForWork;
