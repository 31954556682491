const OrganisationEmptyIcon = () => {
  return (
    <svg width="44" height="31" viewBox="0 0 44 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_14822_29543" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="10" y="6" width="25" height="25">
        <rect x="10.8408" y="6.36768" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_14822_29543)">
        <path
          d="M13.8408 27.3677V13.3677H17.8408V9.36768H27.8408V17.3677H31.8408V27.3677H23.8408V23.3677H21.8408V27.3677H13.8408ZM15.8408 25.3677H17.8408V23.3677H15.8408V25.3677ZM15.8408 21.3677H17.8408V19.3677H15.8408V21.3677ZM15.8408 17.3677H17.8408V15.3677H15.8408V17.3677ZM19.8408 21.3677H21.8408V19.3677H19.8408V21.3677ZM19.8408 17.3677H21.8408V15.3677H19.8408V17.3677ZM19.8408 13.3677H21.8408V11.3677H19.8408V13.3677ZM23.8408 21.3677H25.8408V19.3677H23.8408V21.3677ZM23.8408 17.3677H25.8408V15.3677H23.8408V17.3677ZM23.8408 13.3677H25.8408V11.3677H23.8408V13.3677ZM27.8408 25.3677H29.8408V23.3677H27.8408V25.3677ZM27.8408 21.3677H29.8408V19.3677H27.8408V21.3677Z"
          fill="#082051"
          fillOpacity="0.3"
        />
      </g>
      <path
        d="M39.1229 2.33875C40.3427 2.60164 41.2452 3.14655 41.8304 3.97348C42.4275 4.80299 42.601 5.79776 42.351 6.95779C42.0907 8.16565 41.5121 8.99206 40.6153 9.43702C39.8922 9.79578 39.0486 9.92111 38.0844 9.813C37.6803 9.76769 37.2857 10.0028 37.18 10.3955C37.0743 10.7882 36.6794 11.0291 36.2819 10.9434L36.1118 10.9068C35.6122 10.7991 35.2883 10.3143 35.38 9.8116L35.6241 8.47363C35.6836 8.14773 36.0009 7.93569 36.3247 8.00548C37.4848 8.25549 38.4033 8.29075 39.0802 8.11126C39.7691 7.93435 40.2076 7.40939 40.3957 6.53638C40.5323 5.90255 40.4539 5.3663 40.1605 4.92763C39.8791 4.49153 39.4155 4.2039 38.7697 4.06472C38.1239 3.92554 37.5782 3.9894 37.1328 4.25632C36.9429 4.37008 36.7813 4.51609 36.648 4.69435C36.3233 5.12857 35.8338 5.49689 35.3038 5.38266C34.7738 5.26842 34.4227 4.73687 34.6543 4.2466C34.8089 3.9192 35.0048 3.62392 35.2421 3.36077C35.6861 2.86829 36.2406 2.53101 36.9056 2.34895C37.5825 2.16946 38.3216 2.16606 39.1229 2.33875ZM35.2221 14.8636C34.8514 14.7837 34.5675 14.5911 34.3705 14.2858C34.1735 13.9806 34.1149 13.6426 34.1948 13.2718C34.2747 12.9011 34.4673 12.6172 34.7726 12.4202C35.0779 12.2232 35.4159 12.1647 35.7866 12.2446C36.1454 12.3219 36.4232 12.5132 36.6203 12.8184C36.8173 13.1237 36.8758 13.4617 36.7959 13.8324C36.716 14.2032 36.5235 14.487 36.2182 14.6841C35.9129 14.8811 35.5809 14.9409 35.2221 14.8636Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <path
        d="M39.0598 8.03181L39.0592 8.03198C38.4006 8.20659 37.4971 8.17423 36.342 7.9253C35.9729 7.84574 35.6112 8.08744 35.5434 8.45891L35.2993 9.79688C35.1997 10.3432 35.5516 10.87 36.0945 10.9869L36.2646 11.0236C36.7049 11.1185 37.1422 10.8517 37.2592 10.4168C37.353 10.0682 37.7062 9.85313 38.0753 9.89452C39.0519 10.004 39.9119 9.87756 40.6518 9.5105C41.5762 9.05186 42.167 8.20105 42.4312 6.97507C42.6852 5.7965 42.5099 4.77721 41.8971 3.92583C41.2975 3.07861 40.375 2.5247 39.1402 2.25856C38.3284 2.08361 37.576 2.08633 36.8846 2.26966L36.8846 2.26966L36.8839 2.26983C36.2045 2.45587 35.6361 2.80123 35.1812 3.30585C34.9384 3.57507 34.7381 3.87708 34.5801 4.21157C34.32 4.76218 34.7194 5.34061 35.2865 5.46284C35.866 5.58774 36.3842 5.18414 36.7137 4.74347C36.8406 4.57371 36.9943 4.43493 37.1749 4.32668C37.5981 4.07314 38.1212 4.00887 38.7524 4.1449C39.383 4.28081 39.8249 4.55893 40.0916 4.97211L40.0916 4.97211L40.0923 4.97323C40.3707 5.38936 40.4486 5.90169 40.3155 6.5191C40.1319 7.37102 39.7104 7.86474 39.0598 8.03181ZM34.3016 14.3303C34.511 14.6547 34.8139 14.8595 35.2048 14.9438C35.5846 15.0256 35.9389 14.9619 36.2627 14.753C36.5871 14.5436 36.7919 14.2407 36.8761 13.8497C36.9604 13.4588 36.8986 13.0984 36.6892 12.774C36.4802 12.4502 36.1837 12.2462 35.8039 12.1644C35.4129 12.0801 35.0525 12.1419 34.7281 12.3513C34.4037 12.5607 34.1989 12.8636 34.1147 13.2546C34.0304 13.6455 34.0922 14.0059 34.3016 14.3303Z"
        stroke="#082051"
        strokeOpacity="0.3"
        strokeWidth="0.164053"
      />
      <path
        d="M3.76394 2.17972C4.65931 1.63203 5.52508 1.4402 6.36124 1.60423C7.20618 1.7629 7.88907 2.26797 8.40991 3.11945C8.95223 4.00604 9.07942 4.84501 8.79146 5.63634C8.55929 6.27438 8.11827 6.8402 7.46841 7.3338C7.19604 7.54069 7.09212 7.91288 7.25561 8.21331C7.41911 8.51374 7.31843 8.8896 7.02665 9.06808L6.90176 9.14447C6.53508 9.36877 6.05656 9.26151 5.8207 8.90215L5.193 7.94576C5.0401 7.7128 5.11032 7.39953 5.34803 7.25413C6.19951 6.73329 6.77929 6.22183 7.08736 5.71975C7.40422 5.2123 7.36666 4.63817 6.97468 3.99737C6.6901 3.53213 6.3312 3.25106 5.89799 3.15417C5.47356 3.05192 5.02434 3.14576 4.55032 3.43572C4.0763 3.72567 3.78109 4.08116 3.66468 4.50218C3.61507 4.68164 3.60139 4.86431 3.62364 5.0502C3.67787 5.50303 3.5935 6.01133 3.20445 6.24931C2.8154 6.4873 2.29318 6.3673 2.14972 5.93438C2.05392 5.64528 2.00192 5.35177 1.99371 5.05386C1.97835 4.49631 2.12032 3.96918 2.41962 3.47247C2.7277 2.9704 3.17581 2.53948 3.76394 2.17972ZM8.65589 12.0702C8.38377 12.2367 8.09916 12.2841 7.80206 12.2126C7.50496 12.141 7.27318 11.9691 7.10673 11.697C6.94027 11.4249 6.89283 11.1403 6.96441 10.8432C7.03599 10.5461 7.20785 10.3143 7.47997 10.1478C7.74331 9.98675 8.02354 9.942 8.32064 10.0136C8.61774 10.0852 8.84952 10.257 9.01597 10.5291C9.18243 10.8013 9.22986 11.0859 9.15828 11.383C9.0867 11.6801 8.91924 11.9092 8.65589 12.0702Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <path
        d="M7.02884 5.6832L7.02856 5.68366C6.72884 6.17211 6.15984 6.67667 5.31203 7.19527C5.04108 7.36101 4.96104 7.71808 5.13532 7.98362L5.76302 8.94001C6.01932 9.33051 6.53931 9.44707 6.93777 9.20333L7.06266 9.12694C7.38578 8.92928 7.49728 8.51304 7.31622 8.18033C7.17108 7.91364 7.26133 7.57774 7.51014 7.38875C8.1684 6.88876 8.61875 6.31274 8.8563 5.65994C9.1531 4.84428 9.01922 3.98334 8.46877 3.08345C7.9396 2.21836 7.24139 1.69939 6.37425 1.53648C5.5175 1.36848 4.63437 1.5664 3.72794 2.12086C3.13209 2.48534 2.67549 2.92357 2.36081 3.43639L2.36081 3.43639L2.36053 3.43687C2.0547 3.9444 1.909 4.48448 1.92474 5.05576C1.93314 5.36056 1.98635 5.66073 2.08423 5.95609C2.24535 6.44229 2.82414 6.56283 3.24045 6.30817C3.66583 6.04797 3.74718 5.50156 3.69215 5.042C3.67095 4.86496 3.68397 4.69132 3.73118 4.52057C3.84175 4.12065 4.12301 3.77798 4.58632 3.49458C5.0492 3.21144 5.47971 3.12437 5.88183 3.22125L5.88183 3.22126L5.88294 3.22151C6.29389 3.31342 6.63861 3.58018 6.91583 4.03337C7.29833 4.65869 7.32808 5.20397 7.02884 5.6832ZM7.7859 12.2796C8.10164 12.3557 8.40493 12.3046 8.6919 12.1291C8.97067 11.9586 9.14944 11.7142 9.22536 11.3991C9.30143 11.0834 9.25037 10.7801 9.07483 10.4931C8.89929 10.2062 8.65254 10.0226 8.3368 9.9465C8.0217 9.87059 7.72274 9.91845 7.44397 10.089C7.157 10.2645 6.97341 10.5113 6.89734 10.827C6.82127 11.1428 6.87233 11.446 7.04787 11.733C7.22341 12.02 7.47016 12.2036 7.7859 12.2796Z"
        stroke="#082051"
        strokeOpacity="0.3"
        strokeWidth="0.137991"
      />
    </svg>
  );
};

export default OrganisationEmptyIcon;
