// packages
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// routing
import { RoutePaths } from 'app/routing';
// components
import Logo from 'icons/LogoIcon';
import EmployerLogo from 'icons/EmployerLogo';

export const AuthSection = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const intl = useIntl();

  useEffect(() => {
    if (pathname === RoutePaths.AuthBase) {
      navigate(RoutePaths.AuthLogin);
    }
  }, [navigate, pathname]);

  const getTitle = useMemo<string>(() => {
    switch (pathname) {
      case RoutePaths.AuthForgetConfirm:
      case RoutePaths.HireAuthForgotConfirm:
        return 'auth_forgotConfirmTitle';
      case RoutePaths.AuthForget:
      case RoutePaths.HireAuthForgot:
        return 'auth_forgotTitle';
      case RoutePaths.AuthLogin:
      case RoutePaths.HireAuthLogin:
        return 'auth_loginTitle';
      case RoutePaths.AuthRegisterConfirm:
      case RoutePaths.HireAuthRegisterConfirm:
        return 'auth_registerConfirmTitle';
      default:
        return 'empty';
    }
  }, [pathname]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 w-full">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex w-full justify-center"> {window.location.href.includes('hire') ? <EmployerLogo isBigScreenLogo /> : <Logo isBigScreenLogo />}</div>
        <h2 className="mt-6 text-center text-2xl font-extrabold text-darkBlue">
          {/* Show different titles depending on the auth part we're in */}
          {intl.formatMessage({ id: getTitle })}
        </h2>
      </div>

      <div className="mt-8 mx-4 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 border border-specialGray-012 sm:px-10" style={{ borderRadius: '20px' }}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
