/**
 * @generated SignedSource<<03f052250e13b62769d5576712099268>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProfileYearsOfExperience = 'YEARS_0_1' | 'YEARS_10_20' | 'YEARS_1_2' | 'YEARS_2_5' | 'YEARS_5_10' | 'YEARS_MORE_20' | '%future added value';
import { FragmentRefs } from 'relay-runtime';
export type ProfileFragments_info$data = {
  readonly 'avatarUrl': string | null;
  readonly 'bio': string | null;
  readonly 'birthDate': String;
  readonly 'country': {
    readonly ' $fragmentSpreads': FragmentRefs<'CountryFragments_info'>;
  };
  readonly 'firstName': string;
  readonly 'id': string;
  readonly 'isHidden': boolean | null;
  readonly 'lastName': string;
  readonly 'owner': {
    readonly createdAt: String;
    readonly id: string;
  };
  readonly 'primaryDuty': {
    readonly 'id': string;
    readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragmentsOneDuty'>;
  } | null;
  readonly 'yearsOfExperience': ProfileYearsOfExperience | null;
  readonly ' $fragmentType': 'ProfileFragments_info';
};
export type ProfileFragments_info$key = {
  readonly ' $data'?: ProfileFragments_info$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragments_info'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'id',
    storageKey: null,
  };
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'ProfileFragments_info',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'firstName',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'lastName',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'bio',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'birthDate',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'avatarUrl',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Duty',
        kind: 'LinkedField',
        name: 'primaryDuty',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'ServiceFragmentsOneDuty',
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Profile',
        kind: 'LinkedField',
        name: 'owner',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'createdAt',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Country',
        kind: 'LinkedField',
        name: 'country',
        plural: false,
        selections: [
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'CountryFragments_info',
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'isHidden',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'yearsOfExperience',
        storageKey: null,
      },
    ],
    type: 'ProfileInfo',
    abstractKey: null,
  };
})();

(node as any).hash = '804fe86bb098eabff810af76b0c30494';

export default node;
