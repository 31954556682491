// packages
import { useParams } from 'react-router-dom';
import { useFragment, useLazyLoadQuery } from 'react-relay';
// routes
import { RoutesPathNames } from 'app/routing';
// generated
import { ProfileFragments$key } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { ProfileFragments_info$key } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
import { ProfileQueriesRootQuery } from 'schemas/profile/__generated__/ProfileQueriesRootQuery.graphql';
// schemas
import { PROFILE_QUERY } from 'schemas/profile/ProfileQueries';
import { PROFILE_FRAGMENT, PROFILE_INFO_FRAGMENT } from 'schemas/profile/ProfileFragments';

export function useReceiveCrewProfile() {
  const params = useParams();
  const dynamicProfileId = params[RoutesPathNames.id];

  const profileQueryRef = useLazyLoadQuery<ProfileQueriesRootQuery>(PROFILE_QUERY, { id: dynamicProfileId!, skip: !dynamicProfileId });
  const profileData = useFragment<ProfileFragments$key>(PROFILE_FRAGMENT, profileQueryRef.node || null);
  const profileInfo = useFragment<ProfileFragments_info$key>(PROFILE_INFO_FRAGMENT, profileData?.info || null);

  return {
    dynamicProfileId,
    profileData,
    profileInfo,
  };
}
