import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useIntl } from 'react-intl';
import { SelectInputCommonProps } from 'models/modelsOfComponents';

const SelectInputCommon = React.forwardRef<HTMLInputElement, SelectInputCommonProps>(
  (
    {
      onBlur,
      onMouseUp,
      onChangeSelect,
      classes,
      setValueForInput,
      valueForInput,
      setIndexChoseElement,
      indexChoseElement,
      setIsOpenList,
      elementInFocus,
      placeholder,
      isResetInput,
      isOpenList,
      onInputInFocus,
      altsElements,
      autoComplete,
      isCleanInput,
      onCleanInput,
      name,
      ...props
    },
    ref,
  ) => {
    const intl = useIntl();
    const [isPseudoFocus, setIsPseudoFocus] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement | null>();

    useEffect(() => {
      if (isResetInput) {
        setValueForInput('');
      }
    }, [isResetInput, setValueForInput]);

    const handleOnChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = event.target.value;
        if (props.onChange) {
          props.onChange(event);
        }
        setValueForInput(inputValue);

        if (isPseudoFocus && inputValue.length > 1) {
          setValueForInput(inputValue.charAt(inputValue.length - 1));
        }

        setIsPseudoFocus(false);
      },
      [isPseudoFocus, props, setValueForInput],
    );

    const handleMouseDown = useCallback(
      (event: React.MouseEvent<HTMLInputElement>) => {
        inputRef.current = event.target as HTMLInputElement;
        const inputValue = (event.target as HTMLInputElement).value;
        (event.target as HTMLInputElement).selectionStart = inputValue.length;

        setIsOpenList(false);
        setIsPseudoFocus(false);
      },
      [setIsOpenList],
    );

    const handleFocus = useCallback(
      (event: React.FocusEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement>) => {
        if (onInputInFocus) {
          onInputInFocus();
        }
        inputRef.current = event.target as HTMLInputElement;
      },
      [onInputInFocus],
    );

    const handleClickByArrow = useCallback(() => {
      if (onInputInFocus) {
        onInputInFocus();
      }
      setIsOpenList(true);
      setIsPseudoFocus(true);
    }, [setIsOpenList, onInputInFocus]);

    const handleMouseUp = useCallback(
      (event: React.MouseEvent<HTMLInputElement>) => {
        if (inputRef.current === event.target) {
          onChangeSelect('');
          setIsOpenList(true);
          setIsPseudoFocus(true);

          onMouseUp(event);
        }
      },
      [onChangeSelect, onMouseUp, setIsOpenList],
    );

    const handlePressTab = useCallback(
      (event: KeyboardEvent) => {
        if (event.code === 'Tab') {
          if (inputRef.current) {
            setIsOpenList(true);
            setIsPseudoFocus(true);
          } else {
            setIsOpenList(false);
            setIsPseudoFocus(false);
          }
        }
      },
      [setIsOpenList],
    );

    useEffect(() => {
      window.addEventListener('keyup', handlePressTab);
      return () => {
        window.removeEventListener('keyup', handlePressTab);
      };
    }, [handlePressTab]);

    const handleBlur = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
          onBlur(event);
        }
        setIsPseudoFocus(false);
        inputRef.current = null;
      },
      [onBlur],
    );

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
          setIsOpenList(true);
          setIsPseudoFocus(true);
        } else {
          setIsOpenList(true);
        }
      },
      [setIsOpenList],
    );

    return (
      <>
        <input
          {...props}
          ref={ref}
          type="text"
          className={classes}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          placeholder={intl.formatMessage(placeholder)}
          disabled={props.disabled}
          onChange={handleOnChange}
          value={valueForInput}
          // Setup pr for check auto complete
          spellCheck="false"
          autoComplete={autoComplete}
          onFocus={handleFocus}
          data-test-id={name}
          id={name}
        />
        <div className="absolute top-1/2 -mt-2.5 right-2 flex items-center text-specialGray-05">
          {isCleanInput && <XMarkIcon onClick={onCleanInput} className="w-4 h-4 cursor-pointer" />}
          {!isOpenList ? (
            <ChevronDownIcon className="w-5 h-5 cursor-pointer" onClick={handleClickByArrow} />
          ) : (
            <ChevronUpIcon className="w-5 h-5 cursor-pointer" onClick={() => setIsOpenList(false)} />
          )}
        </div>
      </>
    );
  },
);

export default SelectInputCommon;
