// packages
import { Suspense } from 'react';
// components
import TableHeader from './components/TableHeader/TableHeader';
import PanelTabWithDesktopTable from './PanelTabWithDesktopTable';
import DashboardTableBodyListSkeleton from 'system/skeletons/hireProfile/components/DashboardSkeleton/components/DashboardTableBodyListSkeleton';

const WrapperPanelDesktop = () => {
  return (
    <div className="sm:flex flex-col hidden">
      <TableHeader />
      <Suspense fallback={<DashboardTableBodyListSkeleton numberOfItems={10} />}>
        <PanelTabWithDesktopTable />
      </Suspense>
    </div>
  );
};

export default WrapperPanelDesktop;
