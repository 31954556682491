// packages
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
import { useRecoilValue } from 'recoil';
// generated
import { CertificateFragmentsCertificateInfo$data, CertificateFragmentsCertificateInfo$key } from 'schemas/certificates/__generated__/CertificateFragmentsCertificateInfo.graphql';
// schemas
import { LIST_OF_CERTIFICATE_INFOS } from 'schemas/certificates/CertificateFragments';
//components
import { Button } from 'system/Button';
import HiddenCrewDataPopover from 'system/ProfileSlide/components/HiddenCrewDataPopover';
import CertificateComponent from 'system/ProfileSlide/components/CertificateComponent';
// types
import { ProfileSlideCertificatesProps } from 'models/modelsOfComponents';
import { RESTRICTED_PROFILE_DATA_COUNTER } from 'models/enums';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';

const ProfileSlideCertificates = ({ certificateInfosFragment }: ProfileSlideCertificatesProps) => {
  const intl = useIntl();
  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);

  const certificateInfos = useFragment<CertificateFragmentsCertificateInfo$key>(LIST_OF_CERTIFICATE_INFOS, certificateInfosFragment || null);

  const [showAllCertificates, setShowAllCertificates] = useState<boolean>(false);

  const certificatesCounter = certificateInfosFragment?.length || 0;
  const showMoreCertificatesCounter = certificatesCounter - RESTRICTED_PROFILE_DATA_COUNTER.CERTIFICATES;

  const showCertificates = () => {
    const amountOfRendering = getCurrentOrganisation.isSelected && showAllCertificates ? certificatesCounter : RESTRICTED_PROFILE_DATA_COUNTER.CERTIFICATES;
    return (certificate: CertificateFragmentsCertificateInfo$data[number], index: number) =>
      index < amountOfRendering && <CertificateComponent key={certificate.id} certificate={certificate} />;
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex mb-3">
        <p className="uppercase text-specialGray-075 font-semibold w-1/2 text-xs">{intl.formatMessage({ id: 'profile_certificates' })}</p>
        <p className="text-sm leading-6 text-specialGray-1 relative bottom-1">
          {certificatesCounter} {certificatesCounter > 1 ? intl.formatMessage({ id: 'profile_certificates' }) : intl.formatMessage({ id: 'profile_certificate' })}
        </p>
      </div>
      <div className="flex flex-col">{certificateInfos?.map(showCertificates())}</div>
      {certificatesCounter > RESTRICTED_PROFILE_DATA_COUNTER.CERTIFICATES && getCurrentOrganisation.isSelected && (
        <Button
          type="button"
          buttonType="white-primary"
          label={{ id: showAllCertificates ? 'profile_slide_hide_elements' : 'profile_slide_show_all_certificates' }}
          onClick={() => setShowAllCertificates(prevState => !prevState)}
          additionalClasses="mx-auto"
        />
      )}

      {certificatesCounter > RESTRICTED_PROFILE_DATA_COUNTER.CERTIFICATES && !getCurrentOrganisation.isSelected && (
        <HiddenCrewDataPopover label={intl.formatMessage({ id: 'profile_certificate_with_restriction_amount' }, { amount: showMoreCertificatesCounter })} />
      )}
    </div>
  );
};

export default ProfileSlideCertificates;
