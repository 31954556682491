// packages
import { useMemo } from 'react';
import { useFragment } from 'react-relay';
// components
import { ProfileAvatar } from 'app/Avatars/ProfileAvatar/ProfileAvatar';
import CommercialAvatar from 'app/Avatars/CommercialAvatar/CommercialAvatar';
// generated
import { ProfileFragmentsOrganizationMemberInfo$key } from 'schemas/profile/__generated__/ProfileFragmentsOrganizationMemberInfo.graphql';
// schemas
import { ORGANIZATION_MEMBER_INFO_FRAGMENT } from 'schemas/profile/ProfileFragments';

const MemberComponent = ({ member }: { member: ProfileFragmentsOrganizationMemberInfo$key }) => {
  const memberData = useFragment<ProfileFragmentsOrganizationMemberInfo$key>(ORGANIZATION_MEMBER_INFO_FRAGMENT, member || null);
  const wrapperStyles = useMemo<string>(() => 'border border-specialGray-012 rounded-2xl w-full sm:w-[49%] min-h-[87px] flex h-full p-4 flex-row justify-between gap-x-4', []);
  const titleStyle = useMemo<string>(() => 'text-lg sm:text-base md:text-lg font-medium text-darkBlue truncate max-w-[80%]', []);
  const avatarSizes = useMemo<string>(() => 'w-[55px] h-[55px]', []);

  if (memberData?.role === 'owner') {
    return (
      <div className={wrapperStyles}>
        <CommercialAvatar size={avatarSizes} avatarUrl={memberData?.organization.avatarUrl} />
        <div className="flex flex-col w-full">
          <span className={titleStyle}>{memberData?.organization?.name}</span>
        </div>
      </div>
    );
  }
  return (
    <div className={wrapperStyles}>
      <ProfileAvatar size={avatarSizes} avatarUrl={memberData?.profile?.info?.avatarUrl} />
      <div className="flex flex-col w-full justify-between">
        <span className={titleStyle}>
          {memberData?.profile?.info?.firstName}&nbsp;{memberData?.profile?.info?.lastName}
        </span>
        <span className="text-sm text-specialGray-075">{memberData?.profile?.info?.primaryDuty?.name}</span>
      </div>
    </div>
  );
};

export default MemberComponent;
