// packages
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import PhoneInput, { Country, Value, getCountries, getCountryCallingCode } from 'react-phone-number-input';
// components
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import { FieldErrorMessage } from 'system/FieldErrorMessage';
import LabelComponent from 'system/LabelComponent/LabelComponent';
// hooks
import { useHandlePhoneBlur } from 'hooks/PhoneInputHooks/useHandlePhoneBlur';

const PhoneComponent = ({ name }: { name: string }) => {
  const intl = useIntl();
  const { state } = useLocation();
  const [allCountriesForPhone] = useState<Array<Country>>(getCountries());

  const [, { error }, { setValue, setError }] = useField(name);
  const inputPhoneRef = useRef<HTMLInputElement>(null);
  const [phoneCodeValue, setPhoneCodeValue] = useState<Value | undefined>('');

  const { getErrorPhoneCode, handlePhoneCodeBlur } = useHandlePhoneBlur(setPhoneCodeValue);
  const classNames = error
    ? 'block w-ful pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 text-xs sm:text-sm rounded-md'
    : 'shadow-sm focus:ring-blue-500 focus:border-blue-500 block text-xs sm:text-sm border-specialGray-012 rounded-md w-full placeholder:text-specialGray-05';

  const handleChangePhoneBodyInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (inputPhoneRef.current) {
      inputPhoneRef.current.value = e.target.value.replace(/\D/g, '');
    }
  };

  const handleCountryChange = useCallback(
    (country: Country) => {
      if (country) {
        inputPhoneRef.current?.focus();
        setError(undefined);
      }
    },
    [setError],
  );

  const handleInputBlur = useCallback(() => {
    setValue(`${phoneCodeValue}${inputPhoneRef.current?.value}`);
  }, [phoneCodeValue, setValue]);

  useEffect(() => {
    if (state?.phoneNumber) {
      const findCountry = allCountriesForPhone.find(country => state?.phoneNumber?.includes(`+${getCountryCallingCode(country)}`));
      if (findCountry) {
        (inputPhoneRef.current as HTMLInputElement).value = state?.phoneNumber.replace(`+${getCountryCallingCode(findCountry)}`, '');
        setPhoneCodeValue(`+${getCountryCallingCode(findCountry)}`);
      }
    }
  }, [allCountriesForPhone, state?.phoneNumber]);

  return (
    <div className="flex flex-col gap-y-2">
      <LabelComponent label={{ id: 'phone_number_form_label' }} />
      <div className="flex w-full justify-between">
        <div className="flex flex-col w-1/2">
          <PhoneInput
            internationalIcon={GlobeAltIcon}
            id="phone_input_id"
            onChange={() => {}}
            className={`[&>.PhoneInputInput]:px-0 [&>.PhoneInputInput]:focus:ring-0 [&>.PhoneInputInput]:!bg-transparent [&>.PhoneInputCountry>.PhoneInputCountrySelectArrow]:!text-specialGray-05 [&>.PhoneInputCountry>.PhoneInputCountryIcon]:text-specialGray-05 border focus:ring-blue-500 focus:border-blue-500 h-[38px] ${
              getErrorPhoneCode ? 'border-[rgba(253,164,175,1)]' : 'border-specialGray-012'
            }`}
            value={phoneCodeValue}
            international
            onCountryChange={handleCountryChange}
            onBlur={handlePhoneCodeBlur}
          />
          {getErrorPhoneCode ? <FieldErrorMessage error={getErrorPhoneCode} /> : null}
        </div>
        <div className="flex flex-col w-1/2 relative sm:mx-auto">
          <input
            ref={inputPhoneRef}
            type="text"
            className={classNames}
            placeholder={intl.formatMessage({ id: 'phone_placeholder' })}
            onChange={handleChangePhoneBodyInput}
            onBlur={handleInputBlur}
          />
          {error && <FieldErrorMessage error={error} />}
        </div>
      </div>
    </div>
  );
};

export default PhoneComponent;
