// hooks
import { useMemo } from 'react';
import useGetIsProSubscription from 'hooks/subscription/useGetIsProSubscription';

// This hook we are using to prevent show full last name for Organization with Trial Subscription and "Profile active"
const useGetRestrictedProfileLastName = (lastName?: string): string => {
  const isProSubscription = useGetIsProSubscription();
  return useMemo(() => {
    if (!lastName) {
      return '';
    }
    return isProSubscription ? lastName : `${lastName[0].toUpperCase()}.`;
  }, [isProSubscription, lastName]);
};

export default useGetRestrictedProfileLastName;
