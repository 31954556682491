const ProfileProgressBarIcon3 = () => {
  return (
    <svg width="205" height="102" viewBox="0 0 205 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M198.699 95.7459C198.699 46.2755 155.728 6.17188 102.722 6.17188C49.7156 6.17188 6.74548 46.2755 6.74548 95.7459"
        stroke="#F1F2F4"
        strokeWidth="11.6337"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.74548 95.7448C6.74548 76.0162 13.5794 57.7772 25.1585 42.9774C37.064 27.7603 53.9861 16.179 73.654 10.3525"
        stroke="#F1F2F4"
        strokeWidth="11.6337"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.74548 95.7448C6.74548 76.0162 13.5794 57.7772 25.1585 42.9774C37.064 27.7603 53.9861 16.179 73.654 10.3525"
        stroke="url(#paint0_linear_6750_59252)"
        strokeWidth="11.6337"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient id="paint0_linear_6750_59252" x1="81.5484" y1="10.1545" x2="18.51" y2="196.889" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9E52C" />
          <stop offset="0.838679" stopColor="#FC9318" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProfileProgressBarIcon3;
