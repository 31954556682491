// packages
import React, { ChangeEvent, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
// routes
import { RoutePaths, RoutesPathNames } from 'app/routing';
// components
import { XMarkIcon } from '@heroicons/react/24/solid';
import { SearchInputIcon } from 'icons/SearchInputIcon';
import DropDownSliderWrapper from 'system/Layouts/DropDownSlider/DropDownSliderWrapper';
import DropDownWithOrganisations from './components/DropDownWithOrganisations/DropDownWithOrganisations';
import MembersDropDown from 'app/Navbar/components/SearchInput/components/DropDownWithProfile/MembersDropDown';
import VacanciesDropDown from 'app/Navbar/components/SearchInput/components/DropDownWithVacancies/VacanciesDropDown';
//skeletons
import NavbarSearchDropdownMembersSkeleton from 'system/skeletons/Dropdowns/NavbarSearchDropdownMembersSkeleton';
import NavbarSearchDropdownJobOpeningsSkeleton from 'system/skeletons/Dropdowns/NavbarSearchDropdownJobOpeningsSkeleton';
// hooks
import { useBreakpoints } from 'hooks/useBreakpoints';
// context
import { useNavbarSearchContext } from 'Context/NavbarSearchContext';

const SearchInput = () => {
  const intl = useIntl();
  const { sm } = useBreakpoints();
  const { pathname } = useLocation();
  const { setShowDropDown, setQueryParams, queryParams, showDropDown } = useNavbarSearchContext();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [, setAnalyticsQuery] = useState<string>('');
  const [searchPlaceholder, setSearchPlaceholder] = useState<string>('search_input_placeholder_job_or_crew');
  const banPath = useMemo(() => [RoutePaths.SearchProfileBase, RoutePaths.JobOpenings, RoutesPathNames.employers], []);

  const handleBanPath = useMemo(() => banPath.find(path => pathname.includes(path)), [banPath, pathname]);

  useEffect(() => {
    const arrayPaths = pathname.split('/');
    const isSearchProfilePage = arrayPaths.find(urlPath => urlPath === RoutesPathNames.searchProfile);
    const isSearchJobOpeningPage = arrayPaths.find(urlPath => urlPath === RoutesPathNames.JobOpenings);
    const isSearchOrganisationPage = arrayPaths.find(urlPath => urlPath === RoutesPathNames.employers);

    if (isSearchJobOpeningPage) {
      setSearchPlaceholder('search_input_placeholder_job_openings');
    } else if (isSearchProfilePage) {
      setSearchPlaceholder('search_input_placeholder_search_crew');
    } else if (isSearchOrganisationPage) {
      setSearchPlaceholder('search_input_placeholder_search_organisation');
    } else {
      setSearchPlaceholder('search_input_placeholder_job_or_crew');
    }
  }, [pathname]);

  const handleClickByWindow = useCallback(
    async (element: HTMLElement) => {
      if (!document.getElementById('containerId')?.contains(element)) {
        setShowDropDown(false);
      }
    },
    [setShowDropDown],
  );

  useEffect(() => {
    if (!sm) {
      window.addEventListener('click', event => handleClickByWindow(event.target as HTMLElement));
    }
    return () => {
      window.removeEventListener('click', event => handleClickByWindow(event.target as HTMLElement));
    };
  }, [handleClickByWindow, sm]);

  useEffect(() => {
    (searchInputRef.current as HTMLInputElement).value = queryParams;
    if (sm && !handleBanPath) {
      setShowDropDown(true);
      searchInputRef.current?.focus();
    }
  }, [handleBanPath, queryParams, setShowDropDown, sm]);

  const handleClearInput = useCallback(() => {
    (searchInputRef.current as HTMLInputElement).value = '';
    setQueryParams('');
    setShowDropDown(false);
  }, [setQueryParams, setShowDropDown]);

  const handleOnChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setQueryParams(event.target.value);
  }, 500);

  const handleInputFocus = () => {
    if (!handleBanPath) {
      setShowDropDown(true);
    }
  };

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (searchInputRef.current?.value === '') {
      handleClearInput();
    }

    //send analytics query search string by unfocused of the search component , for example : click by vacancy or See All button etc.
    const targetValue = event.target.value;
    if (!!targetValue) {
      setAnalyticsQuery(prevState => {
        if (prevState !== targetValue) {
          window.analytics?.track('wui-type-in-search-bar', {
            searchValue: (searchInputRef.current as HTMLInputElement).value,
          });
        }
        return targetValue;
      });
    }
  };

  const handleCloseDropDown = useCallback(() => {
    setShowDropDown(false);
  }, [setShowDropDown]);

  // Mobile version Search bar
  if (sm && !!queryParams && showDropDown) {
    return (
      <div className="sm:hidden flex flex-col absolute w-full top-0 left-0 h-[calc(100vh_-_56px)] bg-white z-20 px-4 gap-y-3 pt-3" id="containerId">
        <div className="flex w-full h-12 relative">
          <SearchInputIcon className="w-5 h-5 text-grayIcon absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 left-1 ml-4" />
          <input
            ref={searchInputRef}
            onChange={handleOnChange}
            type="text"
            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block text-xs sm:text-base border-specialGray-012 placeholder-specialGray-05 text-darkBlue rounded-xl w-full pl-10 pt-2 bg-specialGray-002"
            placeholder={intl.formatMessage({ id: searchPlaceholder })}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
          {!!queryParams && (
            <div
              onClick={handleClearInput}
              className="absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 right-2.5 w-6 h-6 rounded-md bg-specialGray-012 flex justify-center items-center cursor-pointer"
            >
              <XMarkIcon className="w-4 h-4 text-specialGray-05" />
            </div>
          )}
        </div>
        <div className="flex flex-col w-full border border-solid border-specialGray-012 rounded-lg bg-white">
          <Suspense fallback={<NavbarSearchDropdownMembersSkeleton />}>
            <MembersDropDown setShowDropDown={handleCloseDropDown} />
          </Suspense>
          <Suspense fallback={<NavbarSearchDropdownJobOpeningsSkeleton />}>
            <VacanciesDropDown setShowDropDown={handleCloseDropDown} />
          </Suspense>
          <Suspense fallback={<NavbarSearchDropdownMembersSkeleton />}>
            <DropDownWithOrganisations setShowDropDown={handleCloseDropDown} />
          </Suspense>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="relative w-full flex flex-col xl:min-w-[415px]" id="containerId">
        <div className="flex w-full h-12">
          <SearchInputIcon className="w-5 h-5 text-grayIcon absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 left-1 ml-4" />
          <input
            ref={searchInputRef}
            onChange={handleOnChange}
            type="text"
            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block text-xs sm:text-base border-specialGray-012 placeholder-specialGray-05 text-darkBlue rounded-xl w-full pl-10 pt-2 bg-specialGray-002"
            placeholder={intl.formatMessage({ id: searchPlaceholder })}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
          {!!queryParams && (
            <div
              onClick={handleClearInput}
              className="absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 right-2.5 w-6 h-6 rounded-md bg-specialGray-012 flex justify-center items-center cursor-pointer"
            >
              <XMarkIcon className="w-4 h-4 text-specialGray-05 hover:text-specialGray-075" />
            </div>
          )}
        </div>

        <DropDownSliderWrapper isShowed={!!queryParams && showDropDown}>
          <div className="flex flex-col w-full border border-solid border-specialGray-012 rounded-lg">
            <Suspense fallback={<NavbarSearchDropdownMembersSkeleton />}>
              <MembersDropDown setShowDropDown={handleCloseDropDown} />
            </Suspense>
            <Suspense fallback={<NavbarSearchDropdownJobOpeningsSkeleton />}>
              <VacanciesDropDown setShowDropDown={handleCloseDropDown} />
            </Suspense>
            <Suspense fallback={<NavbarSearchDropdownMembersSkeleton />}>
              <DropDownWithOrganisations setShowDropDown={handleCloseDropDown} />
            </Suspense>
          </div>
        </DropDownSliderWrapper>
      </div>
    </>
  );
};

export default SearchInput;
