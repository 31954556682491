/**
 * @generated SignedSource<<fb9110689ab022d52215c97520c0ec90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ServiceFragmentsOneDuty$data = {
  readonly 'alts': ReadonlyArray<string> | null;
  readonly 'id': string;
  readonly 'name': string;
  readonly 'perma': string;
  readonly ' $fragmentType': 'ServiceFragmentsOneDuty';
};
export type ServiceFragmentsOneDuty$key = {
  readonly ' $data'?: ServiceFragmentsOneDuty$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragmentsOneDuty'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ServiceFragmentsOneDuty',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'alts',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'perma',
      storageKey: null,
    },
  ],
  type: 'Duty',
  abstractKey: null,
};

(node as any).hash = '48dba913e831d2cf5687df3f05eb3de9';

export default node;
