// packages
import { useNavigate } from 'react-router-dom';
// routes
import { RoutePaths } from 'app/routing';
// components
import { ConfirmForm } from 'auth/ConfirmForm';

const CrewConfirm = () => {
  const navigate = useNavigate();

  return <ConfirmForm onAccountConfirmed={() => navigate(RoutePaths.ProfileBase)} />;
};

export default CrewConfirm;
