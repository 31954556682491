/**
 * @generated SignedSource<<0539d98bef011622d9a723e1eff1f995>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SearchIndex = 'ORGANIZATION' | 'PROFILE' | 'VACANCY' | '%future added value';
export type SearchQueriesIdOfProfilesQuery$variables = {
  after?: any | null;
  filters?: string | null;
  first?: number | null;
  index: SearchIndex;
  query?: string | null;
  skip: boolean;
};
export type SearchQueriesIdOfProfilesQuery$data = {
  readonly search?: {
    readonly edges: ReadonlyArray<{
      readonly cursor: any;
      readonly node: {
        readonly objectID?: string;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: any | null;
      readonly hasNextPage: boolean;
    };
    readonly totalCount: number;
  } | null;
};
export type SearchQueriesIdOfProfilesQuery = {
  response: SearchQueriesIdOfProfilesQuery$data;
  variables: SearchQueriesIdOfProfilesQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'after',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'filters',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'first',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'index',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'query',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'skip',
    },
    v6 = [
      {
        kind: 'Variable',
        name: 'after',
        variableName: 'after',
      },
      {
        kind: 'Variable',
        name: 'filters',
        variableName: 'filters',
      },
      {
        kind: 'Variable',
        name: 'first',
        variableName: 'first',
      },
      {
        kind: 'Variable',
        name: 'index',
        variableName: 'index',
      },
      {
        kind: 'Variable',
        name: 'query',
        variableName: 'query',
      },
    ],
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'totalCount',
      storageKey: null,
    },
    v8 = {
      kind: 'InlineFragment',
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'objectID',
          storageKey: null,
        },
      ],
      type: 'SearchableProfile',
      abstractKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cursor',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      concreteType: 'PageInfo',
      kind: 'LinkedField',
      name: 'pageInfo',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'hasNextPage',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'endCursor',
          storageKey: null,
        },
      ],
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/, v5 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'SearchQueriesIdOfProfilesQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v6 /*: any*/,
              concreteType: 'SearchConnection',
              kind: 'LinkedField',
              name: 'search',
              plural: false,
              selections: [
                v7 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'SearchEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: null,
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [v8 /*: any*/],
                      storageKey: null,
                    },
                    v9 /*: any*/,
                  ],
                  storageKey: null,
                },
                v10 /*: any*/,
              ],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v0 /*: any*/, v2 /*: any*/, v4 /*: any*/, v3 /*: any*/, v1 /*: any*/, v5 /*: any*/],
      kind: 'Operation',
      name: 'SearchQueriesIdOfProfilesQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v6 /*: any*/,
              concreteType: 'SearchConnection',
              kind: 'LinkedField',
              name: 'search',
              plural: false,
              selections: [
                v7 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'SearchEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: null,
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: '__typename',
                          storageKey: null,
                        },
                        v8 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    v9 /*: any*/,
                  ],
                  storageKey: null,
                },
                v10 /*: any*/,
              ],
              storageKey: null,
            },
          ],
        },
      ],
    },
    params: {
      cacheID: '190de5c96c34e5b5e45c44c4aef4374e',
      id: null,
      metadata: {},
      name: 'SearchQueriesIdOfProfilesQuery',
      operationKind: 'query',
      text: 'query SearchQueriesIdOfProfilesQuery(\n  $after: Cursor\n  $first: Int\n  $query: String\n  $index: SearchIndex!\n  $filters: String\n  $skip: Boolean!\n) {\n  search(after: $after, first: $first, query: $query, index: $index, filters: $filters) @skip(if: $skip) {\n    totalCount\n    edges {\n      node {\n        __typename\n        ... on SearchableProfile {\n          objectID\n        }\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'd0f1eb39a134e6f5a6963210cd7c7b09';

export default node;
