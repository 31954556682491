import MainProfileCrewComponentSkeletonEv from './components/MainProfileCrewComponentSkeletonEV/MainProfileCrewComponentSkeletonEV';
import OverviewProfileCrewEvComponentSkeleton from './OverviewProfileCrewEVComponentSkeleton/OverviewProfileCrewEVComponentSkeleton';

export const ProfileCrewEmployerViewSkeleton = () => {
  return (
    <>
      <MainProfileCrewComponentSkeletonEv />
      <OverviewProfileCrewEvComponentSkeleton />
    </>
  );
};
export default ProfileCrewEmployerViewSkeleton;
