// packages
import { Suspense } from 'react';
import { isEmpty } from 'lodash';
import { useLazyLoadQuery } from 'react-relay';
// schemas
import { LIST_OF_VACANCIES } from 'schemas/vacancy/VacancyQueries';
// models
import { VACANCY_DESCRIPTION_TYPE_OF_LIST } from 'models/enums';
// components
import SpinnerIcon from 'icons/SpinnerIcon';
import InvitationItem from './components/InvitationItem';
import EmptyInvitationsIcon from 'icons/EmptyInvitationsIcon';
import { LazyLoader } from 'system/skeletons/wrapper/LazyLoader';
import EmptyList from 'app/JobResponsesPage/components/EmptyList';
// generated
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { VacancyQueriesListOfVacanciesQuery } from 'schemas/vacancy/__generated__/VacancyQueriesListOfVacanciesQuery.graphql';

const ListOfInvitations = () => {
  const vacancies = useLazyLoadQuery<VacancyQueriesListOfVacanciesQuery>(LIST_OF_VACANCIES, {
    skip: false,
    filters: [{ field: VACANCY_DESCRIPTION_TYPE_OF_LIST.INVITED, values: [] }],
  });

  return !isEmpty(vacancies.vacancies?.edges) ? (
    <div className="flex flex-col gap-y-6 pt-6">
      {vacancies.vacancies?.edges?.map(vacancy => (
        <Suspense
          key={vacancy?.node?.id}
          fallback={
            <LazyLoader mainComponent={<InvitationItem vacancy={vacancy?.node as VacancyFragments$key} />}>
              <SpinnerIcon />
            </LazyLoader>
          }
        >
          <InvitationItem vacancy={vacancy?.node as VacancyFragments$key} />
        </Suspense>
      ))}
    </div>
  ) : (
    <EmptyList icon={<EmptyInvitationsIcon />} title={{ id: 'job_responses_empty_invites_title' }} description={{ id: 'job_responses_empty_invites_description' }} />
  );
};

export default ListOfInvitations;
