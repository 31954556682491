import { FC } from 'react';

const DefaultProfileAvatar: FC = () => {
  return (
    <svg width="50%" height="100%" className="m-auto" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7792 12.7974C29.7792 17.7838 25.7811 21.782 20.7912 21.782C15.803 21.782 11.8033 17.7838 11.8033 12.7974C11.8033 7.81102 15.803 3.81445 20.7912 3.81445C25.7811 3.81445 29.7792 7.81102 29.7792 12.7974ZM20.7911 37.7724C13.4268 37.7724 7.20898 36.5755 7.20898 31.9575C7.20898 27.3379 13.4659 26.1834 20.7911 26.1834C28.157 26.1834 34.3732 27.3803 34.3732 31.9983C34.3732 36.6179 28.1163 37.7724 20.7911 37.7724Z"
        fill="#ADAFBC"
      />
    </svg>
  );
};

export default DefaultProfileAvatar;
