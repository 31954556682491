/**
 * @generated SignedSource<<89fce060bf159cb1ddbb9f1e22df9a28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VacancyStatus = 'CLOSED' | 'OPEN' | '%future added value';
export type VacancyMutationsUpdateVacancyManagerMutation$variables = {
  id: string;
  orgMemberId: string;
};
export type VacancyMutationsUpdateVacancyManagerMutation$data = {
  readonly updateVacancyManager: {
    readonly id: string;
    readonly organization: {
      readonly id: string;
    };
    readonly organizationMember: {
      readonly id: string;
      readonly profile: {
        readonly info: {
          readonly firstName: string;
          readonly id: string;
          readonly lastName: string;
        } | null;
      };
    };
    readonly status: VacancyStatus;
    readonly title: string;
  };
};
export type VacancyMutationsUpdateVacancyManagerMutation = {
  response: VacancyMutationsUpdateVacancyManagerMutation$data;
  variables: VacancyMutationsUpdateVacancyManagerMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'orgMemberId',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
      {
        kind: 'Variable',
        name: 'orgMemberId',
        variableName: 'orgMemberId',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'title',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'status',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      concreteType: 'Organization',
      kind: 'LinkedField',
      name: 'organization',
      plural: false,
      selections: [v2 /*: any*/],
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      concreteType: 'ProfileInfo',
      kind: 'LinkedField',
      name: 'info',
      plural: false,
      selections: [
        v2 /*: any*/,
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'lastName',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'firstName',
          storageKey: null,
        },
      ],
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'VacancyMutationsUpdateVacancyManagerMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'Vacancy',
          kind: 'LinkedField',
          name: 'updateVacancyManager',
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'OrganizationMember',
              kind: 'LinkedField',
              name: 'organizationMember',
              plural: false,
              selections: [
                v2 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'Profile',
                  kind: 'LinkedField',
                  name: 'profile',
                  plural: false,
                  selections: [v6 /*: any*/],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'VacancyMutationsUpdateVacancyManagerMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'Vacancy',
          kind: 'LinkedField',
          name: 'updateVacancyManager',
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'OrganizationMember',
              kind: 'LinkedField',
              name: 'organizationMember',
              plural: false,
              selections: [
                v2 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'Profile',
                  kind: 'LinkedField',
                  name: 'profile',
                  plural: false,
                  selections: [v6 /*: any*/, v2 /*: any*/],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '7da249d8f3a3b3812d4200af989e0f90',
      id: null,
      metadata: {},
      name: 'VacancyMutationsUpdateVacancyManagerMutation',
      operationKind: 'mutation',
      text: 'mutation VacancyMutationsUpdateVacancyManagerMutation(\n  $id: ID!\n  $orgMemberId: ID!\n) {\n  updateVacancyManager(id: $id, orgMemberId: $orgMemberId) {\n    id\n    title\n    status\n    organization {\n      id\n    }\n    organizationMember {\n      id\n      profile {\n        info {\n          id\n          lastName\n          firstName\n        }\n        id\n      }\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '60cd9e0c6f17a384cf9921d255d61dae';

export default node;
