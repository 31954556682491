const ProfileProgressBarIcon1 = () => {
  return (
    <svg width="208" height="104" viewBox="0 0 208 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M201.35 97.2314C201.35 46.9423 157.668 6.1748 103.784 6.1748C49.9006 6.1748 6.21924 46.9423 6.21924 97.2314"
        stroke="#F1F2F4"
        strokeWidth="11.8263"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.21924 97.2317C6.21924 92.3454 6.63163 87.549 7.42571 82.8711" stroke="#F1F2F4" strokeWidth="11.8263" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.21924 97.2317C6.21924 92.3454 6.63163 87.549 7.42571 82.8711"
        stroke="url(#paint0_linear_6750_57486)"
        strokeWidth="11.8263"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.21924 97.2317C6.21924 92.3454 6.63163 87.549 7.42571 82.8711"
        stroke="url(#paint1_linear_6750_57486)"
        strokeWidth="11.8263"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient id="paint0_linear_6750_57486" x1="26.1069" y1="31.5706" x2="-9.23671" y2="39.7921" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FC9318" />
          <stop offset="0.984375" stopColor="#E75B3C" />
        </linearGradient>
        <linearGradient id="paint1_linear_6750_57486" x1="105.068" y1="-50.1747" x2="15.2069" y2="128.22" gradientUnits="userSpaceOnUse">
          <stop offset="0.428164" stopColor="#FC9318" />
          <stop offset="0.899658" stopColor="#E75B3C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProfileProgressBarIcon1;
