import { useState } from 'react';

export const useHover = () => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const hovered = () => {
    setIsHovered(true);
  };
  const unHovered = () => {
    setIsHovered(false);
  };
  return { hovered, unHovered, isHovered };
};

export const useHoverById = () => {
  const [selectedId, setSelectedId] = useState<string>('');
  const handleMouseEnter = (id: string) => () => {
    setSelectedId(id);
  };
  const handleMouseOut = () => {
    setSelectedId('');
  };
  return {
    selectedId,
    handleMouseEnter,
    handleMouseOut,
  };
};
