// packages
import React from 'react';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInMonths, differenceInYears, format, getDaysInMonth } from 'date-fns';
// models
import { DutyService, IListOfDutyService } from 'models/IListOfDutyService';

export const onChangeDuty = (options: IListOfDutyService, setDuties: React.Dispatch<React.SetStateAction<IListOfDutyService>>) => (value: string) => {
  if (value) {
    setDuties(
      () =>
        options.filter(({ name, alts }: DutyService) => {
          const foundItem = name.toLowerCase().includes(value.toLowerCase());
          if (!isEmpty(alts) && !foundItem) {
            return alts?.some((altDuty: string) => {
              return altDuty.toLowerCase().includes(value.toLowerCase());
            });
          }
          return foundItem;
        }) as IListOfDutyService,
    );
  } else {
    setDuties(() => options);
  }
};

export const sortingListOfItems = (a: { id: string; name: string }, b: { id: string; name: string }) => {
  if (!a.id || !b.id) {
    return 1;
  }
  return a.name.localeCompare(b.name);
};

export const envVariableTransform = (string: string | undefined) => JSON.parse(string || 'false');

export const formatDate = (dateString: string) => {
  return format(new Date(dateString), 'dd.MM.yy');
};

export const addBorderStyle = (index: number, amount: number): string => {
  const certsAmount = amount > 4 ? 4 : amount;

  let classes = 'border-specialGray-01 w-full md:w-1/2';

  // second row logic
  if (certsAmount >= 3 && index >= 2) {
    const lastIndex = index === certsAmount - 1;
    if (!lastIndex) classes += ' border-b md:border-b-0';
  } else {
    // first row logic
    const lastIndex = index === certsAmount - 1;
    if (certsAmount > 2) classes += ' border-b';
    else if (!lastIndex) classes += ' border-b md:border-b-0';
  }

  // left side blocks logic
  if (index % 2 === 0) classes += ' md:border-r';

  return classes;
};

export function getLastElementFromArray<T>(array: Array<T>): T | null {
  if (array) {
    return array[array.length - 1];
  }
  return null;
}

export function sliceAltsIfLong(array: Array<string>, symbolsToSliceInAlts: number): string {
  const alts: string[] = [];
  array.forEach(entry => alts.push(entry));
  return alts.join(', ').length > 30 ? `${alts.join(', ').slice(0, symbolsToSliceInAlts)}...` : alts.join(', ');
}

const canvas = document.createElement('canvas');
export function getTextWidth(text: string, font: string) {
  const context = canvas.getContext('2d')!;
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}
export function getCleanWidth(el: HTMLElement) {
  const style = window.getComputedStyle(el, null);
  return el.clientWidth - (parseFloat(style.paddingLeft) + parseFloat(style.paddingRight));
}
export function getCanvasFontSize(el = document.body) {
  const { fontWeight = 'normal', fontSize = '16px', fontFamily = 'Times New Roman' } = window.getComputedStyle(el, null);
  return `${fontWeight} ${fontSize} ${fontFamily}`;
}

export function getVacancyDescription(description: string) {
  if (description && description?.length > 200) {
    return `${description?.slice(0, 197)}...`;
  }
  return description;
}

const getFormatTime = (count: number, type: string) => {
  if (count === 1) {
    return `${count} ${type} ago`;
  } else {
    return `${count} ${type}s ago`;
  }
};

export const getDifferentTime = (createAt: string) => {
  const minutes = differenceInMinutes(new Date(), new Date(createAt));
  const hours = differenceInHours(new Date(), new Date(createAt));
  const days = differenceInDays(new Date(), new Date(createAt));
  const months = differenceInMonths(new Date(), new Date(createAt));
  const years = differenceInYears(new Date(), new Date(createAt));
  const daysInCurrentMonth = getDaysInMonth(new Date());

  if (minutes < 60) {
    return `${minutes} min ago`;
  }
  if (hours <= 24) {
    return getFormatTime(hours, 'hour');
  }
  if (days <= daysInCurrentMonth) {
    return getFormatTime(days, 'day');
  }
  if (months <= 12) {
    return getFormatTime(months, 'month');
  }
  return getFormatTime(years, 'year');
};

export const getUserYears = (data: string) => {
  return differenceInYears(new Date(), new Date(data));
};

export const correctAnalyticsEventName = (checkBoxPreference: string) =>
  checkBoxPreference
    .split(/(?=[A-Z])/)
    .join('-')
    .toLowerCase();

export const getRequestErrorIdFromMessage = (error: Error): string => {
  const headerXRequestId = error.message.match(/x-request-id:(\S+)/);
  return headerXRequestId ? headerXRequestId[1] : uuidv4();
};
