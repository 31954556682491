/**
 * @generated SignedSource<<ac0557598bf86972f7f08af0b4e82702>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type VacancyQueriesGetListOfContactsFromVacancyQuery$variables = {
  id: string;
  skip: boolean;
};
export type VacancyQueriesGetListOfContactsFromVacancyQuery$data = {
  readonly node?: {
    readonly contacts?: ReadonlyArray<{
      readonly id: string;
      readonly owner: {
        readonly id: string;
      };
    }> | null;
  } | null;
};
export type VacancyQueriesGetListOfContactsFromVacancyQuery = {
  response: VacancyQueriesGetListOfContactsFromVacancyQuery$data;
  variables: VacancyQueriesGetListOfContactsFromVacancyQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'skip',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      kind: 'InlineFragment',
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'Contact',
          kind: 'LinkedField',
          name: 'contacts',
          plural: true,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'Profile',
              kind: 'LinkedField',
              name: 'owner',
              plural: false,
              selections: [v2 /*: any*/],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Vacancy',
      abstractKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'VacancyQueriesGetListOfContactsFromVacancyQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: null,
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [v3 /*: any*/],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'VacancyQueriesGetListOfContactsFromVacancyQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: null,
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: '__typename',
                  storageKey: null,
                },
                v3 /*: any*/,
                v2 /*: any*/,
              ],
              storageKey: null,
            },
          ],
        },
      ],
    },
    params: {
      cacheID: '06ebf9f42d9376528cf232a82bc463a0',
      id: null,
      metadata: {},
      name: 'VacancyQueriesGetListOfContactsFromVacancyQuery',
      operationKind: 'query',
      text: 'query VacancyQueriesGetListOfContactsFromVacancyQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on Vacancy {\n      contacts {\n        id\n        owner {\n          id\n        }\n      }\n    }\n    id\n  }\n}\n',
    },
  };
})();

(node as any).hash = '219f267c4d1aecc22d4e00c95921e603';

export default node;
