// packages
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useFragment, useLazyLoadQuery } from 'react-relay';
// components
import { Age } from 'app/Profile/Age';
import { Button } from 'system/Button';
import Country from 'app/Profile/Country';
import AvailableForWorkLabel from './AvailableForWorkLabel';
import { ProfileAvatar } from 'app/Avatars/ProfileAvatar/ProfileAvatar';
//icons
import AnimateSpin from 'icons/AnimateSpin';
import { ArrowDownIcon } from '@heroicons/react/24/solid';
import { BriefcaseIcon, StarIcon } from '@heroicons/react/24/outline';
// generated
import { CountryFragments_info$key } from 'schemas/country/__generated__/CountryFragments_info.graphql';
import { ProfileFragments_info$key } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
import { ServiceQueriesFormGetOneDutyQuery } from 'schemas/services/__generated__/ServiceQueriesFormGetOneDutyQuery.graphql';
// schemas
import { COUNTRY_FRAGMENT } from 'schemas/country/CountryFragments';
import { GET_ONE_DUTY_QUERY } from 'schemas/services/ServiceQueries';
import { PROFILE_GENERAL_SETTINGS_FRAGMENT, PROFILE_INFO_FRAGMENT } from 'schemas/profile/ProfileFragments';
import { ProfileFragmentsGeneralSettings$key } from 'schemas/profile/__generated__/ProfileFragmentsGeneralSettings.graphql';
// hooks
import { useAuth } from 'authentication';
import { useGenerateResume } from 'hooks/useGenerateResume';
import useGetRestrictedProfileLastName from 'hooks/ProfileDataHooks/useGetRestrictedProfileLastName';
// types
import { ProfileCrewInfoProps } from 'app/ProfileCrew/type/ProfileCrewTypes';

const ProfileCrewInfo = ({ profile, fullShareProfile }: ProfileCrewInfoProps) => {
  const intl = useIntl();
  const { generateResume, loading } = useGenerateResume(profile.id);
  const { isAdminProfile } = useAuth();
  const profileCrewInfoData = useFragment<ProfileFragments_info$key>(PROFILE_INFO_FRAGMENT, profile.info || null);
  const countryData = useFragment<CountryFragments_info$key>(COUNTRY_FRAGMENT, profileCrewInfoData?.country || null);
  const generalSettings = useFragment<ProfileFragmentsGeneralSettings$key>(PROFILE_GENERAL_SETTINGS_FRAGMENT, profile?.generalSetting || null);
  const restrictedLastName = useGetRestrictedProfileLastName(profileCrewInfoData?.lastName);

  const DutyFromProfileCrew = useLazyLoadQuery<ServiceQueriesFormGetOneDutyQuery>(GET_ONE_DUTY_QUERY, {
    id: profileCrewInfoData?.primaryDuty?.id || '',
    skip: !profileCrewInfoData?.primaryDuty?.id,
  });

  const handleGenerateResume = useCallback(() => {
    generateResume();
    window.analytics?.track('wui-download-crew-cv');
  }, [generateResume]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div className="mx-auto md:flex md:items-center md:justify-between">
      <div className="block md:flex md:space-x-5 w-full">
        <div className="flex gap-x-5 w-full">
          <ProfileAvatar
            avatarUrl={profileCrewInfoData?.avatarUrl}
            nameAbbreviation={profileCrewInfoData ? `${profileCrewInfoData?.firstName[0]}${profileCrewInfoData?.lastName[0]}` : ''}
            profile={profile}
          />

          <div className="w-full">
            <div className="flex sm:flex-row sm:items-center items-start flex-col mb-5 w-full">
              {/* no name = no name header */}
              {profileCrewInfoData && (
                <h1 className="text-2xl font-bold text-specialGray-1 mr-5">
                  {profileCrewInfoData.firstName}&nbsp;{fullShareProfile ? profileCrewInfoData.lastName : restrictedLastName}
                </h1>
              )}

              {profileCrewInfoData && (fullShareProfile || isAdminProfile) && (
                <section className="flex cursor-pointer" onClick={handleGenerateResume}>
                  <div className="flex items-center bg-blue-600 rounded-l-md hover:bg-blue-700">
                    {loading ? <AnimateSpin /> : <ArrowDownIcon className="px-2 h-4 text-white" />}
                  </div>
                  <Button buttonType="primary" additionalClasses="rounded-l-none" label={{ id: 'downloadCV_buttonLabel' }} />
                </section>
              )}
            </div>

            <div className="flex">
              <div className="sm:mr-5 md:mr-0 lg:mr-5 xl:mr-0 mr-0 sm:2/4 md:w-1/3 lg:w-1/4">
                {/* Left side info */}
                {fullShareProfile && (
                  <div className="mb-2 flex items-center w-130px text-specialGray-075">
                    <StarIcon className="h-4 w-4 inline mr-1 text-specialGray-075" />
                    {profileCrewInfoData?.birthDate && <Age birthday={profileCrewInfoData?.birthDate as string} />}
                  </div>
                )}
                <div className="flex flex-col text-specialGray-075 gap-y-2">
                  {profileCrewInfoData?.primaryDuty?.id && (
                    <p className="flex items-center">
                      <BriefcaseIcon className="h-4 w-4 inline mr-1 text-specialGray-05" />
                      {DutyFromProfileCrew.node?.name}
                    </p>
                  )}
                  <Country countryData={countryData} />
                  {(fullShareProfile || isAdminProfile) && <AvailableForWorkLabel isAvailable={generalSettings === null || generalSettings.availableForWork} />}
                </div>
              </div>

              {profileCrewInfoData?.bio && (
                <div className="hidden md:block sm:w-2/4 md:w-2/3 lg:w-3/4">
                  <p className="text-specialGray-1 font-semibold">{intl.formatMessage({ id: 'profile_basic_info_about_me' })}</p>
                  <p className="text-specialGray-075 break-words whitespace-pre-wrap">{profileCrewInfoData?.bio}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {profileCrewInfoData?.bio && (
        <div className="block md:hidden mt-5">
          <p className="text-specialGray-1 font-semibold">{intl.formatMessage({ id: 'profile_basic_info_about_me' })}</p>
          <p className="text-specialGray-075 break-words whitespace-pre-wrap">{profileCrewInfoData?.bio}</p>
        </div>
      )}
    </div>
  );
};

export default ProfileCrewInfo;
