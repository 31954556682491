/**
 * @generated SignedSource<<bdb125f7e458617e1ec073772b773be3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type CountryFragments_info$data = {
  readonly 'id': string;
  readonly 'isoCode': string;
  readonly 'name': string;
  readonly ' $fragmentType': 'CountryFragments_info';
};
export type CountryFragments_info$key = {
  readonly ' $data'?: CountryFragments_info$data;
  readonly ' $fragmentSpreads': FragmentRefs<'CountryFragments_info'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'CountryFragments_info',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isoCode',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
  ],
  type: 'Country',
  abstractKey: null,
};

(node as any).hash = '353c67bda8ec9a53627a2589da9d55b2';

export default node;
