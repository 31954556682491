// packages
import React, { useEffect, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
// components
import DropDownList from 'system/DropDownList';
import DropDownListSkeleton from 'system/skeletons/lists/DropDownListSkeleton';
// generated
import { ServiceQueriesFormGetAllTagsQuery } from 'schemas/services/__generated__/ServiceQueriesFormGetAllTagsQuery.graphql';
// models
import { IActivity } from 'models/IActivity';
import { DropDownListWithTagsProps } from 'models/modelsOfComponents';
// schemas
import { GET_ALL_TAGS_QUERY } from 'schemas/services/ServiceQueries';

const DropDownListWithTags: React.FC<DropDownListWithTagsProps> = ({ substr, setOptions, options, getOtherActivityLabel, handleChooseElement, arrayOfTags }) => {
  const allTags = useLazyLoadQuery<ServiceQueriesFormGetAllTagsQuery>(GET_ALL_TAGS_QUERY, { substr: substr as string, skip: false });

  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (allTags.activities?.edges) {
      setOptions(allTags.activities.edges);
    }
  }, [allTags.activities?.edges, setOptions]);

  useEffect(() => {
    setIsError(() => {
      return !!arrayOfTags?.find((element: IActivity) => element.label.toLowerCase() === substr.toLowerCase());
    });
  }, [arrayOfTags, substr]);

  return (
    <DropDownList
      options={options}
      onChooseElement={handleChooseElement}
      errorMessage={isError && substr ? { id: 'error_tag_already_added' } : undefined}
      otherActivity={getOtherActivityLabel()}
    >
      <DropDownListSkeleton positionAbsolute={false} />
    </DropDownList>
  );
};

export default DropDownListWithTags;
