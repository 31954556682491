export const EmptyCompanyIcon = () => {
  return (
    <svg className="m-auto" width="90%" height="100%" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_6931_61833"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="53"
        height="53"
      >
        <rect x="0.828125" y="0.828125" width="51.3438" height="51.3438" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_6931_61833)">
        <path
          d="M11.525 45.7539C10.3484 45.7539 9.34074 45.3353 8.50212 44.4981C7.66493 43.6595 7.24634 42.6519 7.24634 41.4753V20.082C7.24634 18.9054 7.66493 17.8978 8.50212 17.0592C9.34074 16.222 10.3484 15.8034 11.525 15.8034H15.8036V11.5247C15.8036 10.3481 16.2229 9.34049 17.0616 8.50188C17.8987 7.66469 18.9057 7.24609 20.0823 7.24609H32.9182C34.0949 7.24609 35.1025 7.66469 35.9411 8.50188C36.7783 9.34049 37.1969 10.3481 37.1969 11.5247V24.3607H41.4755C42.6522 24.3607 43.6598 24.7793 44.4984 25.6165C45.3356 26.4551 45.7542 27.4627 45.7542 28.6393V41.4753C45.7542 42.6519 45.3356 43.6595 44.4984 44.4981C43.6598 45.3353 42.6522 45.7539 41.4755 45.7539H28.6396V37.1966H24.3609V45.7539H11.525ZM11.525 41.4753H15.8036V37.1966H11.525V41.4753ZM11.525 32.918H15.8036V28.6393H11.525V32.918ZM11.525 24.3607H15.8036V20.082H11.525V24.3607ZM20.0823 32.918H24.3609V28.6393H20.0823V32.918ZM20.0823 24.3607H24.3609V20.082H20.0823V24.3607ZM20.0823 15.8034H24.3609V11.5247H20.0823V15.8034ZM28.6396 32.918H32.9182V28.6393H28.6396V32.918ZM28.6396 24.3607H32.9182V20.082H28.6396V24.3607ZM28.6396 15.8034H32.9182V11.5247H28.6396V15.8034ZM37.1969 41.4753H41.4755V37.1966H37.1969V41.4753ZM37.1969 32.918H41.4755V28.6393H37.1969V32.918Z"
          fill="#ADAFBC"
        />
      </g>
    </svg>
  );
};
