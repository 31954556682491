import React from 'react';
import { useIntl } from 'react-intl';
import { InfoFieldProps } from 'models/modelsOfComponents';
//components
import EmptyFieldItem from 'app/ProfileEmployer/components/JobDescriptionPage/components/VacancyInformation/components/EmptyFieldItem';

const InfoField: React.FC<InfoFieldProps> = ({ title, children }) => {
  const intl = useIntl();

  return (
    <div className="flex w-full items-center sm:justify-start mb-3">
      <p className="uppercase text-specialGray-075 font-semibold sm:w-1/3 w-1/2 text-xs sm:text-sm">{intl.formatMessage(title)}</p>
      <div data-test-id={intl.formatMessage(title)} className="flex text-darkBlue flex-wrap sm:w-2/3 w-1/2 text-right sm:text-left justify-end sm:justify-start text-sm">
        {children || <EmptyFieldItem />}
      </div>
    </div>
  );
};

export default InfoField;
