// packages
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
// components
import DefaultProfileAvatar from 'icons/DefaultProfileAvatar';
// generated
//models
import { AvatarProps } from 'models/modelsOfComponents';
// generated
import { PROFILE_FRAGMENT_WITH_AVATAR_VERSION } from 'schemas/profile/ProfileFragments';
import { ProfileFragmentsAvatarInfo$key } from 'schemas/profile/__generated__/ProfileFragmentsAvatarInfo.graphql';

// Avatars component that will first try to render a user's image but falls back to showing initials
export const ProfileAvatar: React.FC<AvatarProps> = ({
  size = 'w-40 h-40',
  nameAbbreviation,
  abbreviationSize = 'text-3xl',
  avatarUrl,
  updateAvatar,
  profile,
  avatarRadius = 'rounded-md',
}) => {
  const data = useFragment<ProfileFragmentsAvatarInfo$key>(PROFILE_FRAGMENT_WITH_AVATAR_VERSION, profile || null);
  const [avatarURL, setAvatarUrl] = useState<string | null | undefined>(avatarUrl);

  useEffect(() => {
    setAvatarUrl(avatarUrl);
  }, [avatarUrl]);

  useEffect(() => {
    if (updateAvatar) {
      setAvatarUrl(updateAvatar);
    }
  }, [updateAvatar]);

  const intl = useIntl();
  const blockClassName = `inline-flex flex-shrink-0 items-center justify-center bg-avatar-bg ${abbreviationSize} ${avatarRadius} ${size}`;
  const [noImage, setNoImage] = useState(false);

  function handleImageError() {
    setNoImage(true); // if loading the image results in an error, switch to no-image avatar
  }
  // if the local store has an avatar_version above 0 or noImage is false we will render an image. We change
  // the url so the browser will try to reload the image again

  const image = avatarURL ? (
    <img
      data-test-id="profile-image-avatar"
      className={`${size} ${avatarRadius} object-cover`}
      alt={intl.formatMessage({ id: 'avatar_alt' })}
      src={avatarURL?.toString()}
      key={avatarURL?.toString()}
      onError={handleImageError}
    />
  ) : (
    <DefaultProfileAvatar />
  );

  const avatar = () => {
    if (!avatarURL && !!nameAbbreviation) {
      return <span className={`${blockClassName} font-medium leading-none text-avatar-text uppercase`}>{nameAbbreviation}</span>;
    } else {
      return <span className={blockClassName}>{image}</span>;
    }
  };

  return data && (data.avatar_version >= 1 || !noImage) ? avatar() : <span className={blockClassName}>{image}</span>;
};
