import { SummaryDescriptionItemProps } from 'app/ProfileCrew/type/ProfileCrewTypes';

const SummaryDescriptionItem = ({ count, title }: SummaryDescriptionItemProps) => {
  return (
    <div className="flex text-sm font-medium mr-4">
      <span className="mr-1 text-darkBlue leading-[21px]">{count}</span>
      <span className="text-specialGray-075 lowercase leading-[21px] font-normal">{title}</span>
    </div>
  );
};

export default SummaryDescriptionItem;
