// packages
import { useNavigate } from 'react-router-dom';
// components
import { ForgotForm } from 'auth/ForgotForm';
// routes
import { RoutePaths } from 'app/routing';
// hooks
import { useAuth } from 'authentication';

const CrewForgetComponent = () => {
  const { setEmail } = useAuth();
  const navigate = useNavigate();

  return (
    <ForgotForm
      loginLink={RoutePaths.AuthLogin}
      onVerificationSent={d => {
        if (setEmail) {
          setEmail(d.email);
        }
        navigate(RoutePaths.AuthForgetConfirm);
      }}
    />
  );
};

export default CrewForgetComponent;
