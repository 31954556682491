// packages
import { Suspense } from 'react';
// routes
import { RoutePaths, RoutesPathNames } from 'app/routing';
// components
import ProfileSlide from 'system/ProfileSlide/ProfileSlide';
import PrivateRoutes from 'app/PrivateRoutes/PrivateRoutes';
import ProfileSearchPage from 'app/ProfileSearch/ProfileSearchPage';
import SearchProfileSlideSkeleton from 'system/skeletons/profile/SeachProfileSlideSkeleton/SearchProfileSlideSkeleton';

export const SearchProfileRoute = {
  path: `${RoutePaths.SearchProfileBase}/*`,
  element: (
    <PrivateRoutes>
      <ProfileSearchPage />
    </PrivateRoutes>
  ),
  children: [
    {
      path: `${RoutesPathNames.show}/:${RoutesPathNames.profileSideBarId}/*`,
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<SearchProfileSlideSkeleton />}>
              <ProfileSlide />
            </Suspense>
          ),
        },
      ],
    },
  ],
};
