// packages
import { useEffect, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
// mocks
import { listOfAppliedCrewFilterParams } from 'mocks/mockData';
// generated
import { CountryQueriesCountriesOfResidenceQuery } from 'schemas/country/__generated__/CountryQueriesCountriesOfResidenceQuery.graphql';
import { GET_PROFILE_COUNTRIES_OF_RESIDENCE } from 'schemas/country/CountryQueries';
// types
import { FILTER_TYPE } from 'models/enums';
// components
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { SearchFilterWithDropDown } from 'system/FilterSlideComponents/components/SearchFilterWithDropDown';
// utils
import { formatterFilterByName } from 'utils/filterHelpers';

const FilterListSlider = ({ defaultStorybookFilterValue }: { defaultStorybookFilterValue?: string }) => {
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();

  const listOfCountries = useLazyLoadQuery<CountryQueriesCountriesOfResidenceQuery>(GET_PROFILE_COUNTRIES_OF_RESIDENCE, {
    skip: false,
  })?.countries?.edges;

  const [hoverId, setHoverId] = useState<string>('');
  const [selectId, setSelectId] = useState<string>('');

  useEffect(() => {
    if (defaultStorybookFilterValue) {
      setSelectId(defaultStorybookFilterValue);
    } else {
      setSelectId(searchParams.get('show-only') ?? '');
    }
  }, [searchParams, defaultStorybookFilterValue]);

  const handleMouseEnter = (id: string) => () => {
    setHoverId(id);
  };

  const handleMouseOut = () => {
    setHoverId('');
  };

  const handleSelectElement = (id: string) => () => {
    searchParams.delete('show-only');
    if (selectId === id) {
      setSearchParams(Object.fromEntries([...searchParams]));
    } else {
      setSearchParams({ 'show-only': id, ...Object.fromEntries([...searchParams]) });
    }
  };

  return (
    <div className="flex flex-col space-y-7 mx-9 my-5 [&>#counter-filter-main>#country-filter-container]:mx-0">
      <span>{intl.formatMessage({ id: 'review_applications_filter_show_only' })}</span>
      <div className="flex space-x-3">
        {listOfAppliedCrewFilterParams.map(filterName => (
          <div
            key={filterName}
            onMouseEnter={handleMouseEnter(filterName)}
            onMouseLeave={handleMouseOut}
            onClick={handleSelectElement(filterName)}
            className={`flex w-fit p-1 rounded-xl cursor-pointer ${
              selectId === filterName ? 'bg-blue-100 text-blue pr-3' : 'bg-specialGray-012 text-specialGray-075 px-3'
            } text-sm items-center space-x-2`}
          >
            {selectId === filterName && (
              <div className="bg-blue-200 rounded-full h-fit">{hoverId === filterName ? <XMarkIcon className="w-5 h-5" /> : <CheckIcon className="w-5 h-5" />}</div>
            )}
            <span>{filterName}</span>
          </div>
        ))}
      </div>

      {listOfCountries && (
        <SearchFilterWithDropDown
          listOfFilters={listOfCountries}
          formatter={formatterFilterByName}
          filterType={FILTER_TYPE.COUNTRIES}
          title={{ id: 'hire_location_placeholder' }}
        />
      )}
    </div>
  );
};

export default FilterListSlider;
