import graphql from 'babel-plugin-relay/macro';

export const GET_LIST_OF_ORGANISATIONS_BY_IDS = graphql`
  query OrganisationQueriesGetListOfOrganisationsByIdsQuery($ids: [ID!]!) {
    nodes(ids: $ids) {
      id
      ...OrganisationFragmentsForSearchFragment
    }
  }
`;
