import CertificatesSkeleton from 'system/skeletons/profileTabs/CertificatesSkeleton';
import CertificateList from 'app/Profile/Certificates/CertificateList/CertificateList';
import { Suspense } from 'react';
import { ProfileFragmentsCrewProfile$data, ProfileFragmentsCrewProfile$key } from 'schemas/profile/__generated__/ProfileFragmentsCrewProfile.graphql';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { CREW_PROFILE_FRAGMENT } from 'schemas/profile/ProfileFragments';
import { useSearchParams } from 'react-router-dom';
import { ProfileQueriesProfileShareQuery } from 'schemas/profile/__generated__/ProfileQueriesProfileShareQuery.graphql';
import { GET_PROFILE_SHARE } from 'schemas/profile/ProfileQueries';

const CrewShareCertificates = () => {
  const [searchParams] = useSearchParams();
  const profileShare = useLazyLoadQuery<ProfileQueriesProfileShareQuery>(GET_PROFILE_SHARE, { uuid: searchParams.get('t') || '' });

  const profileCrew = useFragment<ProfileFragmentsCrewProfile$key>(CREW_PROFILE_FRAGMENT, profileShare.profileShare?.owner || null);

  return (
    <Suspense fallback={<CertificatesSkeleton />}>
      <CertificateList profile={profileCrew as ProfileFragmentsCrewProfile$data} />
    </Suspense>
  );
};

export default CrewShareCertificates;
