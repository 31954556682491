import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
//components
import BoatIcon from 'icons/BoatSmall';
import CrewsAppraisalEvItem from './components/CrewsAppraisalEVItem';
import OverviewCrewInfoEvTitle from '../components/OverviewCrewInfoEVTitle';
import OverviewCrewInfoEmptyItem from '../components/OverviewCrewInfoEmptyItem';
//models
import { ProfileFragments$data } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { GET_APPRAISAL_WITH_DOCUMENT_PLURAL_FRAGMENT } from 'schemas/appraisals/AppraisalFragments';
import { AppraisalFragmentsListPlural_profile$key } from 'schemas/appraisals/__generated__/AppraisalFragmentsListPlural_profile.graphql';
//hooks
import useSortedByEndDate from 'hooks/useSortedByEndDate';

const CrewsAppraisalsEmployerView = ({ profileAppraisals }: { profileAppraisals: ProfileFragments$data['appraisals'] }) => {
  const intl = useIntl();

  const appraisals = useFragment<AppraisalFragmentsListPlural_profile$key>(GET_APPRAISAL_WITH_DOCUMENT_PLURAL_FRAGMENT, profileAppraisals);

  const getSortedField = useCallback((item: { seaService: { end: String | null } }) => item.seaService.end, []);
  const sortedEducationsByEndDate = useSortedByEndDate([...(appraisals || [])], getSortedField);

  return (
    <section className="flex w-full flex-col space-y-6">
      <OverviewCrewInfoEvTitle
        title={intl.formatMessage({ id: 'profile_appraisalsTitle' })}
        description={intl.formatMessage({ id: 'profile_appraisalAmount' }, { amount: appraisals?.length })}
      />
      {sortedEducationsByEndDate ? (
        sortedEducationsByEndDate.map((item, i) => {
          return <CrewsAppraisalEvItem key={i} appraisalFr={item} />;
        })
      ) : (
        <OverviewCrewInfoEmptyItem title={intl.formatMessage({ id: 'profile_no_appraisals' })}>
          <BoatIcon />
        </OverviewCrewInfoEmptyItem>
      )}
    </section>
  );
};

export default CrewsAppraisalsEmployerView;
