// packages
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
// routing
import { RoutePaths } from 'app/routing';
// models
import { PLATFORM_GROUP, VACANCY_STATUS } from 'models/enums';
import { JobDescriptionViewPropsStorybook } from '../types';
// generated
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { VacancyQueriesGetVacancyQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetVacancyQuery.graphql';
import { VacancyQueriesGetVacancyRefreshQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetVacancyRefreshQuery.graphql';
import { VACANCY_FRAGMENT } from 'schemas/vacancy/VacancyFragments';
// schemas
import { GET_VACANCY } from 'schemas/vacancy/VacancyQueries';
import { ConfirmModalBody } from 'system/ConfirmModalBody';
import { Modal } from 'system/Modal';
// components
import { LoginForm } from 'auth/LoginForm';
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';
import JobOrganizationInfo from '../components/JobOrganizationInfo/JobOrganizationInfo';
import VacancyInformationLayout from '../components/VacancyInformation/components/VacancyInformationLayout';
import ProfileLowScoreBody from 'app/ProfileEmployer/components/JobDescriptionPage/components/ProfileLowScoreBody';
import LowProfileScoreBarNotification from '../components/LowProfileScoreBarNotification/LowProfileScoreBarNotification';
// hooks
import { useAuth } from 'authentication';
import { useGetProfileScore } from 'hooks/useGetProfileScore';
import { useGetUserAlreadyApplied } from 'hooks/useGetUserAlreadyApplied';
import { useCreateVacancyApplication } from 'hooks/useCreateVacancyApplication';
import { useRemoveVacancyApplication } from 'hooks/useRemoveVacancyApplication';
import { envVariableTransform } from 'utils';

const JobDescriptionView = ({
  defaultUserAlreadyInvited,
  defaultShowConfirmModal,
  defaultShowAuthModal,
  defaultUserAlreadyApplied,
  defaultIsLowProfileScoreModal,
}: JobDescriptionViewPropsStorybook) => {
  const intl = useIntl();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { identity } = useAuth();
  const { profileScoreSummary } = useGetProfileScore();

  const vacancyFragment = useLazyLoadQuery<VacancyQueriesGetVacancyQuery>(GET_VACANCY, { id: id!, skip: !id });
  const [vacancy, reFetch] = useRefetchableFragment<VacancyQueriesGetVacancyRefreshQuery, VacancyFragments$key>(VACANCY_FRAGMENT, vacancyFragment.node as VacancyFragments$key);

  const { userAlreadyApplied } = useGetUserAlreadyApplied(defaultUserAlreadyApplied || false, vacancy);

  const [isOpenApplyModal, setIsOpenApplyModal] = useState<boolean>(false);

  const handleShowConfirmModalForAppliedUser = useCallback(() => {
    const nameOfSearchParams: string = 'open-apply-modal';
    const getIsOpenApplyModalFromSearchParams: string | null = searchParams.get(nameOfSearchParams);

    if (userAlreadyApplied && getIsOpenApplyModalFromSearchParams) {
      searchParams.delete(nameOfSearchParams);
      setSearchParams(searchParams);
    } else {
      setIsOpenApplyModal(envVariableTransform(getIsOpenApplyModalFromSearchParams ?? ''));
    }
  }, [searchParams, setSearchParams, userAlreadyApplied]);

  useEffect(() => {
    handleShowConfirmModalForAppliedUser();
  }, [handleShowConfirmModalForAppliedUser]);

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(defaultShowConfirmModal ?? false);

  const [showAuthModal, setShowAuthModal] = useState<boolean>(defaultShowAuthModal || false);
  const [userAlreadyInvited, setUserAlreadyInvited] = useState<boolean>(defaultUserAlreadyInvited || false);

  const isProfileWithScoreLowerThanTwenty = useMemo<boolean>(
    () => profileScoreSummary < 20 && identity?.platformGroup !== PLATFORM_GROUP.GUEST,
    [identity?.platformGroup, profileScoreSummary],
  );
  const [isLowProfileScoreModal, setIsLowProfileScoreModal] = useState<boolean>(defaultIsLowProfileScoreModal || false);
  const isLowProfileScore = useMemo<boolean>(
    () =>
      profileScoreSummary < (process.env.REACT_APP_MINIMAL_PROFILE_SCORE || process.env.STORYBOOK_FEATURE_MINIMAL_PROFILE_SCORE || 0) &&
      identity?.platformGroup !== PLATFORM_GROUP.GUEST,
    [identity?.platformGroup, profileScoreSummary],
  );

  const handleCreateApplication = useCreateVacancyApplication(vacancy, reFetch);
  const handleRemoveApplication = useRemoveVacancyApplication(vacancy, `${identity?.profileId}`, reFetch);

  useEffect(() => {
    vacancy?.invites?.forEach(app => {
      if (app.owner.id === `${identity?.profileId}`) {
        setUserAlreadyInvited(true);
      } else {
        setUserAlreadyInvited(false);
      }
    });
  }, [vacancy?.invites, identity?.profileId]);

  const handleClearSearchParams = useCallback(() => {
    if (isOpenApplyModal) {
      searchParams.delete('open-apply-modal');
      setSearchParams(searchParams);
    }
  }, [isOpenApplyModal, searchParams, setSearchParams]);

  const handleToggleShowModal = useCallback(
    (openModal: boolean) => () => {
      if (isProfileWithScoreLowerThanTwenty) {
        return;
      }

      if (isLowProfileScore) {
        setIsLowProfileScoreModal(true);
        return;
      }

      if (identity?.platformGroup === 'guest') {
        setShowAuthModal(openModal);
      } else {
        const trackEventName = openModal ? 'wui-applied-single-job-opening-click' : 'wui-cancel-applied-single-job-opening-click';
        window.analytics?.track(trackEventName, { vacancyId: vacancy?.id });
        setShowConfirmModal(openModal);
      }
      handleClearSearchParams();
    },
    [handleClearSearchParams, identity?.platformGroup, isLowProfileScore, isProfileWithScoreLowerThanTwenty, vacancy?.id],
  );

  const handleConfirmationApplying = useCallback(() => {
    handleCreateApplication();
    window.analytics?.track('wui-confirm-applied-single-job-opening', { vacancyId: vacancy?.id });
    handleToggleShowModal(false)();
  }, [handleCreateApplication, vacancy?.id, handleToggleShowModal]);

  const handleLoginSuccess = useCallback(() => {
    handleConfirmationApplying();
    navigate(`${RoutePaths.ProfileBase}/job/${id}`);
  }, [navigate, id, handleConfirmationApplying]);

  const GetAppliedButton = useCallback(
    () =>
      userAlreadyApplied ? (
        <button
          className="h-[62px] sm:h-10 rounded-lg border border-solid border-gray-300 bg-white flex px-8 items-center justify-center text-specialGray-075 gap-x-3.5 sm:text-sm text-lg sm:w-fit w-full hover:bg-gray-50"
          onClick={handleRemoveApplication}
        >
          {intl.formatMessage({ id: 'hire_label_applied' })}
        </button>
      ) : null,
    [handleRemoveApplication, intl, userAlreadyApplied],
  );

  const GetInviteButton = useCallback(
    () =>
      userAlreadyInvited && !userAlreadyApplied ? (
        <button
          className="h-[62px] sm:h-10 rounded-lg bg-blue flex px-8 items-center justify-center text-white gap-x-3.5 sm:text-sm text-lg sm:w-fit w-full hover:bg-blue-700"
          onClick={handleCreateApplication}
        >
          {intl.formatMessage({ id: 'hire_button_label_accept_invite' })}
        </button>
      ) : null,
    [handleCreateApplication, intl, userAlreadyApplied, userAlreadyInvited],
  );

  const GetApplyButton = useCallback(
    () =>
      !userAlreadyApplied && !userAlreadyInvited ? (
        <button
          className={`h-[62px] sm:h-10 rounded-lg flex px-8 items-center justify-center gap-x-3.5 sm:text-sm text-lg sm:w-fit w-full ${
            isProfileWithScoreLowerThanTwenty ? 'bg-[#D4D7DD] text-specialGray-03 cursor-not-allowed' : 'text-white hover:bg-blue-700 bg-blue'
          }`}
          onClick={handleToggleShowModal(true)}
        >
          <PlusIcon className="sm:h-3.5 h- sm:w-3.5 h-5 w-5" />
          {intl.formatMessage({ id: 'hire_button_label_apply' })}
        </button>
      ) : null,
    [handleToggleShowModal, intl, isProfileWithScoreLowerThanTwenty, userAlreadyApplied, userAlreadyInvited],
  );

  const GetApplyButtonWithPopover = useCallback(
    () =>
      !userAlreadyApplied && !userAlreadyInvited ? (
        <PopoverHelper
          element={
            <>
              <GetApplyButton />
            </>
          }
          position="bottom-[70px] sm:-bottom-3 left-1/2 sm:-right-2 -translate-x-1/2 sm:translate-x-full"
          positionArrow="left-1/2 sm:left-0 rotate-45 sm:bottom-[45%] -bottom-0.5 sm:translate-x-0 translate-x-1/2"
          widthContainer="w-[200px] sm:min-w-fit"
          darkTheme
          autoShow={state?.storybookShowPopoverByDefault}
        >
          {intl.formatMessage({ id: 'job_description_apply_popover' })}
        </PopoverHelper>
      ) : null,
    [GetApplyButton, intl, state?.storybookShowPopoverByDefault, userAlreadyApplied, userAlreadyInvited],
  );

  const renderCrewControlButton = useMemo(
    () =>
      vacancy?.status === VACANCY_STATUS.OPEN && (
        <>
          <GetAppliedButton />
          <GetInviteButton />
          {isProfileWithScoreLowerThanTwenty ? <GetApplyButtonWithPopover /> : <GetApplyButton />}
        </>
      ),
    [GetAppliedButton, GetApplyButton, GetApplyButtonWithPopover, GetInviteButton, isProfileWithScoreLowerThanTwenty, vacancy?.status],
  );

  return (
    <div className="flex w-full flex-col gap-y-8">
      {isProfileWithScoreLowerThanTwenty ? <LowProfileScoreBarNotification /> : null}

      <div className="flex w-full gap-x-12 gap-y-9 lg:flex-row flex-col relative">
        <div className="xl:w-3/5 lg:w-1/2 w-full">
          <>
            {identity?.platformGroup === PLATFORM_GROUP.GUEST ? (
              <Modal
                title={{ id: 'job_openings_page_please_login_to_apply' }}
                onClose={() => {
                  setShowAuthModal(false);
                }}
                show={showAuthModal}
                tinyModal
              >
                <LoginForm forgotPasswordLink={RoutePaths.AuthForget} onLoginSuccess={handleLoginSuccess} />
              </Modal>
            ) : (
              <Modal
                onClose={() => {
                  handleClearSearchParams();
                  setShowConfirmModal(false);
                }}
                show={showConfirmModal || (isOpenApplyModal && !userAlreadyApplied)}
                tinyModal
              >
                <ConfirmModalBody
                  title={{ id: 'hire_confirm_apply_to_vacancy_title' }}
                  onClick={handleConfirmationApplying}
                  onCancel={handleToggleShowModal(false)}
                  buttonLabel={{ id: 'ok' }}
                  typeButtonConfirm="primary"
                />
              </Modal>
            )}
            {isLowProfileScore && (
              <Modal onClose={() => setIsLowProfileScoreModal(false)} show={isLowProfileScoreModal} tinyModal>
                <ProfileLowScoreBody vacancyId={vacancy.id} setIsLowProfileScoreModal={setIsLowProfileScoreModal} handleCreateApplication={handleCreateApplication} />
              </Modal>
            )}

            <VacancyInformationLayout vacancy={vacancy}>
              <div className="sm:flex hidden">{renderCrewControlButton}</div>
            </VacancyInformationLayout>
          </>
        </div>
        <JobOrganizationInfo vacancyFragment={vacancyFragment.node as VacancyFragments$key} />
        <div className="flex sm:hidden sticky bottom-0 left-0 w-full justify-center py-2.5 bg-[#FCFCFC]">{renderCrewControlButton}</div>
      </div>
    </div>
  );
};

export default JobDescriptionView;
