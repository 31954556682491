import { useSearchParams } from 'react-router-dom';
import React, { useState } from 'react';
import { FILTER_TYPE } from 'models/enums';
//hooks
import { useNumericFilterAnalytics } from 'hooks/anaytics/useFiltersAnalytics';

export const useFilterSearchNumeric = (filterSearchName: FILTER_TYPE) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedNumber, setSelectedNumber] = useState<string>(searchParams.get(filterSearchName) ?? '');
  const { sendRangeNumericFilterAnalytics } = useNumericFilterAnalytics();

  const createSearchNumericUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const number = event.target.value;
    const currentSearchNumericParam = searchParams.get(filterSearchName);
    setSelectedNumber(number);

    sendRangeNumericFilterAnalytics.current(filterSearchName, number);

    if (!!currentSearchNumericParam && number === '') {
      searchParams.delete(filterSearchName);
      setSearchParams(searchParams);
      return;
    } else {
      const comparedSearchParas = Object.fromEntries([...searchParams]);
      setSearchParams({ ...comparedSearchParas, [filterSearchName]: number });
    }
  };

  return {
    createSearchNumericUrl,
    selectedNumber,
  };
};

export default useFilterSearchNumeric;
