// packages
import { useMutation, useRefetchableFragment } from 'react-relay';
import { useRecoilValue } from 'recoil';
// models
import { VACANCY_STATUS } from 'models/enums';
// schemas
import { VACANCY_FRAGMENT } from 'schemas/vacancy/VacancyFragments';
import { SWITCH_VACANCY_STATUS } from 'schemas/vacancy/VacancyMutations';
// generated
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { VacancyQueriesGetVacancyRefreshQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetVacancyRefreshQuery.graphql';
import { VacancyMutationsSwitchVacancyStatusMutation } from 'schemas/vacancy/__generated__/VacancyMutationsSwitchVacancyStatusMutation.graphql';
//formHelpers
import { switchStatusWithNode } from 'formHelpers/updaters/updatersOfJobOpening';
// recoil
import { fetchDashboardVacanciesByOpenParamsSelector } from 'recoil/Organisation/Dashboard/selectors/fetchDashboardVacanciesByOpenParamsSelector';
import { fetchDashboardVacanciesByShowAllParamsSelector } from 'recoil/Organisation/Dashboard/selectors/fetchDashboardVacanciesByShowAllParamsSelector';

export function useSwitchVacancyStatus(vacancyFragment: VacancyFragments$key, callback?: () => void) {
  const [commitSwitchVacancyStatus, isCommitSwitchVacancyLoading] = useMutation<VacancyMutationsSwitchVacancyStatusMutation>(SWITCH_VACANCY_STATUS);
  const [vacancy, reFetch] = useRefetchableFragment<VacancyQueriesGetVacancyRefreshQuery, VacancyFragments$key>(VACANCY_FRAGMENT, vacancyFragment as VacancyFragments$key);

  const allOpenSelectedFilters = useRecoilValue(fetchDashboardVacanciesByOpenParamsSelector);
  const allClosedSelectedFilters = useRecoilValue(fetchDashboardVacanciesByShowAllParamsSelector);

  const handleSwitchStatus = (status: VACANCY_STATUS) => () => {
    commitSwitchVacancyStatus({
      variables: {
        id: vacancy.id,
        status,
      },
      onCompleted: () => {
        reFetch({});
        if (callback) {
          callback();
        }
      },
      updater: switchStatusWithNode(allOpenSelectedFilters, allClosedSelectedFilters),
    });
  };

  return {
    vacancy,
    isCommitSwitchVacancyLoading,
    handleSwitchStatus,
  };
}
