import { FC } from 'react';

const InviteIcon: FC = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.1054 8.12111L10.4416 13.8227L3.81064 9.74147C2.94176 9.20657 3.11787 7.88697 4.0967 7.60287L19.7522 3.04743C20.6425 2.78978 21.4656 3.62446 21.199 4.51889L16.554 20.1582C16.263 21.1369 14.9582 21.3064 14.4309 20.4325L10.4416 13.8227"
        stroke="#8490A8"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InviteIcon;
