// packages
import { useFragment } from 'react-relay';
// components
import AppraisalsListItem from './components/AppraisalsListItem';
// types
import { AppraisalItemWithDocumentProps } from '../types';
// schemas
import { GET_APPRAISAL_FRAGMENT } from 'schemas/appraisals/AppraisalFragments';
// generated
import { AppraisalFragmentsOneAppraisal$key } from 'schemas/appraisals/__generated__/AppraisalFragmentsOneAppraisal.graphql';

const AppraisalItemWithDocument = ({ appraisalFragment, ...props }: AppraisalItemWithDocumentProps) => {
  const appraisalData = useFragment<AppraisalFragmentsOneAppraisal$key>(GET_APPRAISAL_FRAGMENT, appraisalFragment);

  return <AppraisalsListItem appraisalData={appraisalData} {...props} />;
};

export default AppraisalItemWithDocument;
