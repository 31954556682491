import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';
//components
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
//models
import { FilteringByInputValueProps } from '../types';
//hooks
import useFilterWithValue from 'hooks/searchFilter/useFilterWithValue';
//components
import CommonInput from 'system/CommonInput/CommonInput';
//hooks
import { useValueFilterAnalytics } from 'hooks/anaytics/useFiltersAnalytics';

export const FilteringByInputValue = ({ filterType, title, validationFunction }: FilteringByInputValueProps) => {
  const intl = useIntl();
  const { createSearchUrlWithValue, searchUrlValue } = useFilterWithValue(filterType);
  const { sendValueFilterAnalytics } = useValueFilterAnalytics();

  const [value, setValue] = useState<string>(searchUrlValue || '');
  const [validationError, setValidationError] = useState<string>('');

  const debouncedSearch = useRef(
    debounce(
      (
        urlSetterFunction: (value: string, setSearchValue: React.Dispatch<React.SetStateAction<string>>) => void,
        searchValue: string,
        setInputValue: React.Dispatch<React.SetStateAction<string>>,
      ) => {
        urlSetterFunction(searchValue, setInputValue);
      },
      450,
    ),
  );

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (validationFunction) {
      const validationData = validationFunction(e.target.value);
      setValidationError(validationData?.error || '');
      return validationData && setValue(validationData.validatedValue);
    }
    setValue(e.target.value);
  };

  useEffect(() => {
    if (validationError && !value.length) {
      setValidationError('');
      debouncedSearch.current(createSearchUrlWithValue, value, setValue);
    }
    if (!validationError) {
      debouncedSearch.current(createSearchUrlWithValue, value, setValue);
    }
  }, [value, createSearchUrlWithValue, validationError]);

  useEffect(() => {
    if (value && !validationError) {
      sendValueFilterAnalytics.current(filterType, value);
    }
  }, [sendValueFilterAnalytics, filterType, value, validationError]);

  return (
    <div className="flex flex-col pt-8 px-4 sm:px-8 pb-0 border-b border-b-specialGray-01">
      <CommonInput validationError={validationError} id={filterType} label={title} onChange={onChangeValue} inputValue={value}>
        <MagnifyingGlassIcon className={`absolute w-5 h-5 ${validationError ? 'text-red-600' : 'text-grayIcon'} top-2.5 right-0 mr-2`} />
      </CommonInput>
      {validationError && <span className="relative bottom-4 text-sm text-red-600">{intl.formatMessage({ id: validationError })}</span>}
    </div>
  );
};
