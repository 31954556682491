/**
 * @generated SignedSource<<1f1569799b99f38887d5443784d44903>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContactData = {
  ownerId: string;
  vacancyId: string;
};
export type ContactMutationsCreateVacancyContactMutation$variables = {
  data: ContactData;
};
export type ContactMutationsCreateVacancyContactMutation$data = {
  readonly createContact: {
    readonly node: {
      readonly id: string;
      readonly owner: {
        readonly id: string;
      };
    } | null;
  };
};
export type ContactMutationsCreateVacancyContactMutation = {
  response: ContactMutationsCreateVacancyContactMutation$data;
  variables: ContactMutationsCreateVacancyContactMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'data',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'data',
            variableName: 'data',
          },
        ],
        concreteType: 'ContactEdge',
        kind: 'LinkedField',
        name: 'createContact',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'Contact',
            kind: 'LinkedField',
            name: 'node',
            plural: false,
            selections: [
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                concreteType: 'Profile',
                kind: 'LinkedField',
                name: 'owner',
                plural: false,
                selections: [v1 /*: any*/],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ContactMutationsCreateVacancyContactMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ContactMutationsCreateVacancyContactMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '91e14cd79c8bec41142e1756d60bec1e',
      id: null,
      metadata: {},
      name: 'ContactMutationsCreateVacancyContactMutation',
      operationKind: 'mutation',
      text: 'mutation ContactMutationsCreateVacancyContactMutation(\n  $data: ContactData!\n) {\n  createContact(data: $data) {\n    node {\n      id\n      owner {\n        id\n      }\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'ae147de8c0ded6ca1260e1b310cf8adb';

export default node;
