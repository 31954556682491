// packages
import { useIntl } from 'react-intl';
//components
import SpinnerIcon from 'icons/SpinnerIcon';
//models
import { Switch } from '@headlessui/react';
// types
import { SettingsCheckBoxProps } from 'app/AccountSettings/types';

const SettingsCheckBox = ({ eventFunction, checked, label, isLoading, description }: SettingsCheckBoxProps) => {
  const intl = useIntl();

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={checked}
        onChange={eventFunction}
        className={`${
          checked ? 'bg-[#34C759]' : 'bg-specialGray-016'
        } relative inline-flex h-[31px] w-[51px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
      >
        {isLoading ? (
          <div className={`h-[27px] w-[27px] z-0 ${checked ? 'translate-x-5' : 'translate-x-0'}`}>
            <SpinnerIcon />
          </div>
        ) : (
          <span
            aria-hidden="true"
            className={`${
              checked ? 'translate-x-5' : 'translate-x-0'
            } pointer-events-none inline-block h-[27px] w-[27px] transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
          />
        )}
      </Switch>
      <Switch.Label as="span" className="flex flex-col ml-3">
        <span className="text-sm font-medium text-darkBlue">{intl.formatMessage({ id: label })}</span>
        {description ? <span className="text-xs xs:text-sm text-specialGray-075">{intl.formatMessage({ id: description })}</span> : null}
      </Switch.Label>
    </Switch.Group>
  );
};

export default SettingsCheckBox;
