import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLazyLoadQuery } from 'react-relay';
//components
import { Age } from 'app/Profile/Age';
import { ProfileAvatar } from 'app/Avatars/ProfileAvatar/ProfileAvatar';
import CountryComponent from 'app/ProfileSearch/components/ProfileListItem/components/CountryComponent';
//schemas
import { GET_CREW_PROFILE_INFO } from 'schemas/profile/ProfileQueries';
//generated
import { CountryFragments_info$data } from 'schemas/country/__generated__/CountryFragments_info.graphql';
import { ProfileQueriesCrewProfileInfoQuery } from 'schemas/profile/__generated__/ProfileQueriesCrewProfileInfoQuery.graphql';
// hooks
import useGetRestrictedProfileLastName from 'hooks/ProfileDataHooks/useGetRestrictedProfileLastName';

const EmployerAddUserToCrewPoolInfo = ({ userId }: { userId?: string }) => {
  const intl = useIntl();
  const data = useLazyLoadQuery<ProfileQueriesCrewProfileInfoQuery>(GET_CREW_PROFILE_INFO, { id: userId! }).node;
  const profileInfo = data?.info;
  const lastName = useGetRestrictedProfileLastName(profileInfo?.lastName);

  const vesselsCount = useMemo(() => {
    const vesselsUniqIMO: number[] = [];
    data?.seaServices?.forEach(seaService => {
      const currentVesselImo = seaService.vessel.imo;
      if (!vesselsUniqIMO.includes(currentVesselImo)) {
        vesselsUniqIMO.push(currentVesselImo);
      }
    });
    return vesselsUniqIMO.length;
  }, [data?.seaServices]);

  return (
    <div className="flex flex-col w-1/2 gap-y-4 border-r border-r-1  pr-2 sm:pr-6  mr-2 sm:mr-6 text-darkBlue">
      <ProfileAvatar size="h-[117px] w-[117px] 2xl:h-[160px] 2xl:w-[160px]" avatarUrl={profileInfo?.avatarUrl} />
      <div className="flex gap-x-2 font-medium">
        <span>{profileInfo?.firstName || ''}</span>
        <span>{lastName}</span>
      </div>

      <section className="grid grid-cols-1 sm:grid-cols-2 gap-y-2">
        <div className="flex flex-col w-full text-xs">
          <span className="text-specialGray-075">{intl.formatMessage({ id: 'profile_age' })}</span>
          <span>{profileInfo?.birthDate ? <Age classes="text-darkBlue text-sm" birthday={`${profileInfo.birthDate}`} /> : <>&#8208;</>}</span>
        </div>

        <div className="flex flex-col w-full text-xs">
          <span className="text-specialGray-075">{intl.formatMessage({ id: 'profile_vessels' })}</span>
          <span>{vesselsCount}</span>
        </div>

        <div className="flex flex-col w-full text-xs">
          <span className="text-specialGray-075">{intl.formatMessage({ id: 'label_form_duty' })}</span>
          <span>{data?.info?.primaryDuty?.name ? data.info.primaryDuty.name : <>&#8208;</>}</span>
        </div>

        <div className="flex flex-col w-full text-xs">
          <span className="text-specialGray-075">{intl.formatMessage({ id: 'profile_sea_services' })}</span>
          <span>{data?.seaServices?.length || 0}</span>
        </div>

        <div className="flex flex-col w-full text-xs">
          {profileInfo?.country ? (
            <CountryComponent
              classNameContainer="flex flex-col w-fit!"
              classNameCountryContainer="flex flex-row gap-x-2"
              classNameTitle="text-xs"
              classNameDescription="text-xs self-center"
              profileCountry={profileInfo.country as CountryFragments_info$data}
            />
          ) : null}
        </div>

        <div className="flex flex-col w-full text-xs">
          <span className="text-specialGray-075">{intl.formatMessage({ id: 'profile_certificates' })}</span>
          <span>{data?.certificateInfos?.length || 0}</span>
        </div>
      </section>
    </div>
  );
};

export default EmployerAddUserToCrewPoolInfo;
