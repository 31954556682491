// packages
import { useCallback, useState } from 'react';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { useLazyLoadQuery } from 'react-relay';
// components
import SelectInputGroup from 'system/SelectInputGroup';
// models
import { ISeaServices } from 'models/ISeaServices';
// types
import { SelectSeaServiceComponentProps } from '../../types';
// generated
import { ServiceFragments$key } from 'schemas/services/__generated__/ServiceFragments.graphql';
import { ServiceQueriesListOfServicesFromProfileQuery } from 'schemas/services/__generated__/ServiceQueriesListOfServicesFromProfileQuery.graphql';
// schemas
import { LIST_OF_SEA_SERVICE_FROM_PROFILE } from 'schemas/services/ServiceQueries';
import { AppraisalQueriesListQuery } from 'schemas/appraisals/__generated__/AppraisalQueriesListQuery.graphql';
import { GET_ALL_APPRAISALS_LIST } from 'schemas/appraisals/AppraisalQueries';

const SelectSeaServiceComponent = ({ nameField, defaultSelectedSeaService, dropdownIsOpenByDefault, profileId }: SelectSeaServiceComponentProps) => {
  const intl = useIntl();

  const [selectedSeaService, setSelectedSeaService] = useState<ServiceFragments$key>();
  const seaServicesFromProfile = useLazyLoadQuery<ServiceQueriesListOfServicesFromProfileQuery>(LIST_OF_SEA_SERVICE_FROM_PROFILE, {
    ownerId: profileId,
    skip: !profileId,
  });
  const { appraisals } = useLazyLoadQuery<AppraisalQueriesListQuery>(GET_ALL_APPRAISALS_LIST, { ownerId: profileId, skip: !profileId });

  const mappingListOfSeaServices = useCallback(() => {
    const idOfSeaServicesWithAppraisals = appraisals?.edges?.map(item => item?.node?.seaService.id);

    return seaServicesFromProfile?.seaServices?.edges?.map(seaService => {
      const isAppraisalAlreadyExist = idOfSeaServicesWithAppraisals?.includes(seaService?.node?.id);
      const vesselFromDate = format(new Date(seaService?.node?.start as string), 'dd.MM.yyyy');
      const vesselToDate = seaService?.node?.end ? format(new Date(seaService?.node?.end as string), 'dd.MM.yyyy') : intl.formatMessage({ id: 'service_present' });

      return {
        ...seaService?.node,
        name: `${seaService?.node?.vessel.name} - (${vesselFromDate} - ${vesselToDate}) ${
          isAppraisalAlreadyExist ? intl.formatMessage({ id: 'appraisal_form_sea_service_already_exist' }) : ''
        }`,
        disabled: isAppraisalAlreadyExist,
        alts: seaService?.node?.end ? null : [intl.formatMessage({ id: 'appraisal_form_sea_service_working_here' })],
      };
    }) as ISeaServices;
  }, [seaServicesFromProfile?.seaServices?.edges, appraisals?.edges, intl]);

  const [seaServices, setSeaServices] = useState<ISeaServices>(mappingListOfSeaServices() || []);
  const handleChangeSeaServices = useCallback(
    (value: string) => {
      if (value) {
        setSeaServices(() => mappingListOfSeaServices()?.filter(seaService => seaService?.name.toLowerCase().includes(value.toLowerCase())) as ISeaServices);
      } else {
        setSeaServices(() => mappingListOfSeaServices() as ISeaServices);
      }
    },
    [mappingListOfSeaServices],
  );

  const getDefaultSelectedElement = useCallback(() => {
    if (defaultSelectedSeaService) {
      return { id: defaultSelectedSeaService?.id, name: defaultSelectedSeaService?.vessel.name };
    } else {
      return undefined;
    }
  }, [defaultSelectedSeaService]);

  return (
    <SelectInputGroup
      name={nameField}
      options={seaServices}
      label={{ id: 'appraisal_modal_choose_sea_service_title' }}
      onChangeSelect={handleChangeSeaServices}
      placeholder={{ id: 'appraisal_modal_choose_sea_service_placeholder' }}
      defaultSelectedElement={getDefaultSelectedElement()}
      setSelectedItem={setSelectedSeaService}
      selectedItem={selectedSeaService}
      sortOptionsByAlphabetically
      autoComplete="chrome-off"
      disabled={!!defaultSelectedSeaService}
      openByDefault={dropdownIsOpenByDefault}
    />
  );
};

export default SelectSeaServiceComponent;
