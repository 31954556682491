import { useState, useCallback, useEffect } from 'react';
import { useMutation } from 'react-relay';
import { isEmpty, includes } from 'lodash';
// components
import { Button } from 'system/Button';
import ShieldIcon from 'icons/ShieldIcon';
import AllCertificatesListItemTextPopover from './AllCertificatesListItemTextPopover';
//generated
import { CertificateMutationsAddMutation } from 'schemas/certificates/__generated__/CertificateMutationsAddMutation.graphql';
import { CertificateMutationsRemoveMutation } from 'schemas/certificates/__generated__/CertificateMutationsRemoveMutation.graphql';
// schemas
import { ADD_CERTIFICATE, REMOVE_CERTIFICATE_ITEM_MUTATION } from 'schemas/certificates/CertificateMutations';
// hooks
import { useAuth } from 'authentication';
// helpers
import { certificateInfoCreate, certificateRemove } from 'formHelpers/updaters/updatersOfCertificate';
//types
import { CertificateProps } from '../../types';

const AllCertificateListItem = ({ cert, added = false, addedCerts }: CertificateProps) => {
  const { identity } = useAuth();
  const [commitCreate] = useMutation<CertificateMutationsAddMutation>(ADD_CERTIFICATE);
  const [commitRemoveListItem] = useMutation<CertificateMutationsRemoveMutation>(REMOVE_CERTIFICATE_ITEM_MUTATION);
  const { id, name, alts, kind } = cert;
  const [isAdded, setIsAdded] = useState<boolean>(added);

  useEffect(() => {
    addedCerts?.forEach(entry => {
      includes(entry.certificate.id, id) && setIsAdded(true);
    });
  }, [addedCerts, id]);

  const addCertificate = (id: string) => {
    if (identity?.profileId) {
      commitCreate({
        variables: { pid: `${identity?.profileId}`, cid: id, data: {} },
        onCompleted: () => {
          window.analytics?.track('wui-added-certificate-to-profile', {
            label: 'Sea service was highlighted',
            addedCertificateId: id,
          });
          setIsAdded(true);
        },
        updater: certificateInfoCreate(`${identity?.profileId}`),
      });
    }
  };

  const removeCertificate = useCallback(
    (certId: string) => {
      const certificateInfoId = addedCerts?.find(({ certificate }) => certificate.id === certId)?.id;
      commitRemoveListItem({
        variables: { id: certificateInfoId as string },
        onCompleted: () => {
          window.analytics?.track('wui-removed-certificate-from-profile', {
            label: 'Sea service was highlighted',
            removedCertificateID: certId,
          });
          setIsAdded(false);
        },
        updater: certificateRemove(`${identity?.profileId}`, certificateInfoId as string),
      });
    },
    [commitRemoveListItem, identity?.profileId, addedCerts],
  );

  return (
    <div className="flex flex-row px-3 py-3 sm:h-16 border-b">
      <div className="flex flex-wrap sm:flex-nowrap justify-between items-center">
        <div className="flex [&>svg]:min-w-[18px] items-center mb-1 sm:mb-0">
          <ShieldIcon kind={kind?.name} />
          <section className="inline-grid text-specialGray-1 text-xs sm:text-sm ml-5">
            <span className="truncate">{name}</span>
          </section>
        </div>
        {!isEmpty(alts) && <AllCertificatesListItemTextPopover description={alts.join(',')} />}
      </div>
      <div className="flex flex-col ml-auto justify-around">
        {!isAdded ? (
          <Button onClick={() => addCertificate(id)} additionalClasses="text-sm font-medium right-6" withoutWidth buttonType="secondary" label={{ id: 'cert_button_add' }} />
        ) : (
          <Button onClick={() => removeCertificate(id)} additionalClasses="text-sm font-medium right-6" buttonType="icon-button" label={{ id: 'cert_button_dissmiss' }} />
        )}
      </div>
    </div>
  );
};

export default AllCertificateListItem;
