import { Dispatch, SetStateAction, useCallback, useState, FocusEvent } from 'react';
import { Country, getCountries, getCountryCallingCode, Value } from 'react-phone-number-input';

export function useHandlePhoneBlur(setPhoneCodeValue: Dispatch<SetStateAction<Value | undefined>>) {
  const [allCountriesForPhone] = useState<Array<Country>>(getCountries());
  const [getErrorPhoneCode, setErrorPhoneCode] = useState<string | null>(null);

  const handlePhoneCodeBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const findCountry = allCountriesForPhone.find(country => `+${getCountryCallingCode(country)}` === value);

      if (!findCountry && value) {
        setPhoneCodeValue('');
        setErrorPhoneCode('phone_code_is_not_correct');
      } else {
        setPhoneCodeValue(value);
        setErrorPhoneCode(null);
      }
    },
    [allCountriesForPhone, setPhoneCodeValue],
  );

  return {
    getErrorPhoneCode,
    handlePhoneCodeBlur,
  };
}
