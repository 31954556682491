//components
import getRatingIcon from 'icons/RatingIcons';
// types
import { RatingScoreWithIconProps } from 'system/ProfileReting/types';

const RatingScoreWithIcon = ({ summaryRating, maxSummary }: RatingScoreWithIconProps) => {
  return (
    <>
      {getRatingIcon(summaryRating)}
      <span className="ml-2 mt-0.5 sm:text-xl">
        <span className="font-semibold">{summaryRating}</span>
        <span className="text-specialGray-05">/{maxSummary}</span>
      </span>
    </>
  );
};

export default RatingScoreWithIcon;
