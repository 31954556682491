const ErrorCircleRoundedIcon = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_10505_82823" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <rect className="fill-current" width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_10505_82823)">
        <path
          className="fill-current"
          d="M9 12.75C9.2125 12.75 9.39075 12.678 9.53475 12.534C9.67825 12.3905 9.75 12.2125 9.75 12C9.75 11.7875 9.67825 11.6093 9.53475 11.4653C9.39075 11.3217 9.2125 11.25 9 11.25C8.7875 11.25 8.6095 11.3217 8.466 11.4653C8.322 11.6093 8.25 11.7875 8.25 12C8.25 12.2125 8.322 12.3905 8.466 12.534C8.6095 12.678 8.7875 12.75 9 12.75ZM9 16.5C7.9625 16.5 6.9875 16.303 6.075 15.909C5.1625 15.5155 4.36875 14.9813 3.69375 14.3063C3.01875 13.6313 2.4845 12.8375 2.091 11.925C1.697 11.0125 1.5 10.0375 1.5 9C1.5 7.9625 1.697 6.9875 2.091 6.075C2.4845 5.1625 3.01875 4.36875 3.69375 3.69375C4.36875 3.01875 5.1625 2.48425 6.075 2.09025C6.9875 1.69675 7.9625 1.5 9 1.5C10.0375 1.5 11.0125 1.69675 11.925 2.09025C12.8375 2.48425 13.6313 3.01875 14.3063 3.69375C14.9813 4.36875 15.5155 5.1625 15.909 6.075C16.303 6.9875 16.5 7.9625 16.5 9C16.5 10.0375 16.303 11.0125 15.909 11.925C15.5155 12.8375 14.9813 13.6313 14.3063 14.3063C13.6313 14.9813 12.8375 15.5155 11.925 15.909C11.0125 16.303 10.0375 16.5 9 16.5ZM9 9.75C9.2125 9.75 9.39075 9.678 9.53475 9.534C9.67825 9.3905 9.75 9.2125 9.75 9V6C9.75 5.7875 9.67825 5.60925 9.53475 5.46525C9.39075 5.32175 9.2125 5.25 9 5.25C8.7875 5.25 8.6095 5.32175 8.466 5.46525C8.322 5.60925 8.25 5.7875 8.25 6V9C8.25 9.2125 8.322 9.3905 8.466 9.534C8.6095 9.678 8.7875 9.75 9 9.75Z"
        />
      </g>
    </svg>
  );
};

export default ErrorCircleRoundedIcon;
