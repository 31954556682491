/**
 * @generated SignedSource<<758194b9b66cf955ce258a63fa148fe8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ServiceQueriesSearchByQuery$variables = {
  imo?: number | null;
  name?: string | null;
  skip: boolean;
};
export type ServiceQueriesSearchByQuery$data = {
  readonly vessels?: {
    readonly edges: ReadonlyArray<{
      readonly cnISO: string | null;
      readonly countryName: string | null;
      readonly fleetmonID: string | null;
      readonly grossTonnage: number | null;
      readonly image: string | null;
      readonly imo: number;
      readonly kind: {
        readonly id: string;
        readonly name: string;
        readonly perma: string;
      } | null;
      readonly length: number | null;
      readonly name: string;
      readonly operatorName: string | null;
    }>;
  } | null;
};
export type ServiceQueriesSearchByQuery = {
  response: ServiceQueriesSearchByQuery$data;
  variables: ServiceQueriesSearchByQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'imo',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'name',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'skip',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v2 = [
      {
        condition: 'skip',
        kind: 'Condition',
        passingValue: false,
        selections: [
          {
            alias: null,
            args: [
              {
                kind: 'Variable',
                name: 'imo',
                variableName: 'imo',
              },
              {
                kind: 'Variable',
                name: 'name',
                variableName: 'name',
              },
            ],
            concreteType: 'VesselList',
            kind: 'LinkedField',
            name: 'vessels',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'VesselDetails',
                kind: 'LinkedField',
                name: 'edges',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'fleetmonID',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'imo',
                    storageKey: null,
                  },
                  v1 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'grossTonnage',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'operatorName',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'image',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    concreteType: 'VesselKind',
                    kind: 'LinkedField',
                    name: 'kind',
                    plural: false,
                    selections: [
                      {
                        alias: null,
                        args: null,
                        kind: 'ScalarField',
                        name: 'id',
                        storageKey: null,
                      },
                      v1 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        kind: 'ScalarField',
                        name: 'perma',
                        storageKey: null,
                      },
                    ],
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'length',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'countryName',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'cnISO',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ServiceQueriesSearchByQuery',
      selections: v2 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ServiceQueriesSearchByQuery',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '8f314ab5ad554d2fd978b69adca29cab',
      id: null,
      metadata: {},
      name: 'ServiceQueriesSearchByQuery',
      operationKind: 'query',
      text: 'query ServiceQueriesSearchByQuery(\n  $imo: Int\n  $name: String\n  $skip: Boolean!\n) {\n  vessels(imo: $imo, name: $name) @skip(if: $skip) {\n    edges {\n      fleetmonID\n      imo\n      name\n      grossTonnage\n      operatorName\n      image\n      kind {\n        id\n        name\n        perma\n      }\n      length\n      countryName\n      cnISO\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '40293596becb9f9fa2bb54ea939d72ff';

export default node;
