import { useIntl } from 'react-intl';
import { useLazyLoadQuery } from 'react-relay';
import { useMemo } from 'react';
//components
import { Modal } from 'system/Modal';
import { PlusIcon } from '@heroicons/react/24/outline';
import CustomButton from 'system/Buttons/CustomButton';
import EmployerOrganizationList from './EmployerOrganizationList';
import EmployerOrganizationAddMember from './EmployerOrganizationAddMember';
//hooks
import { useGetHireProfile } from 'hooks/hireProfileHooks';
import { useToggleShowWindow } from 'hooks/useToggleShowModal';
//generated
import { ProfileQueriesGetOrganizationMembersConnectionQuery } from 'schemas/profile/__generated__/ProfileQueriesGetOrganizationMembersConnectionQuery.graphql';
//schema
import { GET_ALL_HIRE_ORGANIZATION_MEMBERS } from 'schemas/profile/ProfileQueries';

const EmployerOrganizationMain = ({ defaultStorybookShowAddModal }: { defaultStorybookShowAddModal?: string }) => {
  const intl = useIntl();

  const { toggleShowWindow, showWindow } = useToggleShowWindow();

  const { organizationId } = useGetHireProfile();

  const allTeamMembers = useLazyLoadQuery<ProfileQueriesGetOrganizationMembersConnectionQuery>(GET_ALL_HIRE_ORGANIZATION_MEMBERS, {
    orgId: organizationId!,
  });

  const confirmedCount = useMemo(() => {
    return allTeamMembers?.organizationMembers?.edges?.filter(member => member?.node?.role !== 'invited').length;
  }, [allTeamMembers]);

  return (
    <div className="flex flex-col w-full text-darkBlue py-8">
      <div className="flex w-full justify-between mb-6">
        <div className="flex flex-col">
          <h2 className="text-specialGray-1 font-semibold">{intl.formatMessage({ id: 'hire_organization_members_page_title' })}</h2>
          <div className="flex gap-x-6">
            <span className="text-specialGray-075 text-sm">{intl.formatMessage({ id: 'hire_organization_members_total_confirmed' }, { value: confirmedCount })}</span>
            <span className="text-specialGray-075 text-sm">
              {intl.formatMessage({ id: 'hire_organization_members_total' }, { value: allTeamMembers?.organizationMembers?.totalCount || 0 })}
            </span>
          </div>
        </div>
        <CustomButton onClick={toggleShowWindow(true)} className="flex w-fit bg-blue text-white rounded-[10px] px-5 h-10 text-sm items-center font-medium self-end">
          <PlusIcon className="text-white h-4 mr-3" /> {intl.formatMessage({ id: 'hire_add_new_organization_team_member' })}
        </CustomButton>
      </div>

      <EmployerOrganizationList />
      <Modal
        onClose={toggleShowWindow(false)}
        show={showWindow || !!defaultStorybookShowAddModal}
        title={{ id: 'hire_add_new_organization_member_form' }}
        description={{ id: 'hire_add_new_organization_member_form_description' }}
      >
        <EmployerOrganizationAddMember defaultStorybookShowAddModal={defaultStorybookShowAddModal} />
      </Modal>
    </div>
  );
};

export default EmployerOrganizationMain;
