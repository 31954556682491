import graphql from 'babel-plugin-relay/macro';

export const CREATE_VACANCY_INVITE = graphql`
  mutation InviteMutationsCreateInviteMutation($data: InviteData!) {
    createInvite(data: $data) {
      node {
        id
        owner {
          id
        }
      }
    }
  }
`;

export const REMOVE_VACANCY_INVITE = graphql`
  mutation InviteMutationsRemoveInviteMutation($id: ID!) {
    deleteInvite(id: $id) {
      id
    }
  }
`;
