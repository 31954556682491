// packages
import { useNavigate } from 'react-router-dom';
// routes
import { RoutePaths } from 'app/routing';
// components
import { ConfirmForm } from 'auth/ConfirmForm';

const HireForgotConfirm = () => {
  const navigate = useNavigate();
  return <ConfirmForm hire onAccountConfirmed={() => navigate(RoutePaths.ProfileBase)} />;
};

export default HireForgotConfirm;
