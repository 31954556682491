/**
 * @generated SignedSource<<a79ad0c3879c38c9f1de7ae431f84eed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ServiceFragmentsActivities$data = ReadonlyArray<{
  readonly 'id': string;
  readonly 'label': string;
  readonly 'perma': string | null;
  readonly 'timesUsed': number;
  readonly ' $fragmentType': 'ServiceFragmentsActivities';
}>;
export type ServiceFragmentsActivities$key = ReadonlyArray<{
  readonly ' $data'?: ServiceFragmentsActivities$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragmentsActivities'>;
}>;

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: {
    plural: true,
  },
  name: 'ServiceFragmentsActivities',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'label',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'perma',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'timesUsed',
      storageKey: null,
    },
  ],
  type: 'Activity',
  abstractKey: null,
};

(node as any).hash = '92b169aaa602f2a5e8ea82aaeb7817c2';

export default node;
