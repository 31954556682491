//hooks
import { useBreakpoints } from 'hooks/useBreakpoints';
//components
import TextInputSkeleton from 'system/skeletons/components/TextInputSkeleton';
import DateInputSkeleton from 'system/skeletons/components/DateInputSkeleton';
import RangeNumbersSkeleton from 'system/skeletons/components/RangeNumbersSkeleton';

const JobOpeningFilterComponentSkeleton = () => {
  const { sm } = useBreakpoints();
  return (
    <div className="flex-col w-full">
      <TextInputSkeleton
        additionalStyle="flex flex-col space-y-4 py-6 sm:py-8 border-b border-b-specialGray-01 px-4 sm:px-8"
        widthTitle={65}
        borderRadius="0.375rem"
        heightInput={sm ? 34 : 38}
      />
      <TextInputSkeleton
        additionalStyle="flex flex-col space-y-4 py-6 sm:py-8 border-b border-b-specialGray-01 px-4 sm:px-8"
        widthTitle={65}
        borderRadius="0.375rem"
        heightInput={sm ? 34 : 38}
      />
      <TextInputSkeleton
        additionalStyle="flex flex-col space-y-4 py-6 sm:py-8 border-b border-b-specialGray-01 px-4 sm:px-8"
        widthTitle={80}
        borderRadius="0.375rem"
        heightInput={sm ? 34 : 38}
      />
      <TextInputSkeleton
        additionalStyle="flex flex-col space-y-4 py-6 sm:py-8 border-b border-b-specialGray-01 px-4 sm:px-8"
        widthTitle={60}
        borderRadius="0.375rem"
        heightInput={sm ? 34 : 38}
      />

      <RangeNumbersSkeleton additionalStyle="py-6 sm:py-8 border-b border-b-specialGray-01 px-4 sm:px-8" widthTitle={100} borderRadius="0.375rem" heightInput={sm ? 34 : 38} />

      <TextInputSkeleton
        additionalStyle="flex flex-col space-y-4 py-6 sm:py-8 border-b border-b-specialGray-01 px-4 sm:px-8"
        widthTitle={95}
        borderRadius="0.375rem"
        heightInput={sm ? 34 : 38}
      />
      <DateInputSkeleton labelWidth={100} wrapperClasses="flex flex-col px-4 py-6 sm:py-8 sm:p-8 border-b border-b-specialGray-01 space-y-4" />

      <TextInputSkeleton
        additionalStyle="flex flex-col space-y-4 px-4 sm:py-8 border-b border-b-specialGray-01 sm:px-8"
        widthTitle={95}
        borderRadius="0.375rem"
        heightInput={sm ? 34 : 38}
      />
    </div>
  );
};

export default JobOpeningFilterComponentSkeleton;
