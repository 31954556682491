import Skeleton from 'react-loading-skeleton';

const ListItemSkeleton = () => {
  return (
    <div className="border border-specialGray-01 rounded-2xl cursor-pointer w-full min-h-[94px] flex h-full p-4 flex-col sm:flex-row justify-between gap-y-2 sm:gap-0">
      <div className="flex w-full justify-between">
        <div className="flex flex-col w-[calc(100%_-_100px)] md:w-full gap-y-2.5 gap-x-4">
          <div className="flex items-center gap-x-4">
            <Skeleton width={150} height={18} />
            <div className="hidden sm:inline-block">
              <div className="flex gap-x-2 text-specialGray-05 text-sm">
                <Skeleton width={150} height={28} borderRadius={50} />
                <Skeleton width={45} height={28} borderRadius={50} />
              </div>
            </div>
          </div>
          <div className="flex w-full flex-wrap gap-x-[18px]">
            {/*Hiring area*/}
            <Skeleton width={100} />
            {/*Salary range*/}
            <div className="hidden sm:flex gap-x-1.5">
              <Skeleton width={30} height={14} />
              &nbsp;&#8725;
              <Skeleton width={60} height={14} />
              <Skeleton width={35} height={14} />
              &nbsp;&#45;
              <Skeleton width={35} height={14} className="mr-2" />
            </div>
            {/*Vessel kind*/}
            <Skeleton width={120} />
          </div>
        </div>
        <Skeleton width={65} height={18} />
      </div>
      <div className="sm:hidden flex justify-between">
        <div className="flex">
          <Skeleton width={30} height={14} />
          &nbsp;&#8725;
          <Skeleton width={60} height={14} />
        </div>
        <div className="flex">
          <Skeleton width={35} height={14} />
          &nbsp;&#45;
          <Skeleton width={35} height={14} />
        </div>
      </div>
    </div>
  );
};

export default ListItemSkeleton;
