import React, { ReactElement } from 'react';
import { isIE } from 'react-device-detect';
import { Alert } from 'system/Alert/Alert';

// BrowserGate will check if the users browser is new enough to run our app. If not, it will gently
// ask them to install a more modern browser.
const BrowserGate: React.FC<{ children: ReactElement }> = props => {
  return isIE ? <Alert heading={{ id: 'browserGate_title' }} message={{ id: 'browserGate_explanation' }} type={'warning'} /> : <React.Fragment>{props.children}</React.Fragment>;
};

export default BrowserGate;
