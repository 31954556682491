const AccountCircleIcon = () => {
  return (
    <svg className="fill-current" width="1em" height="1em" viewBox="0 0 25 25" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_9954_78695" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
        <rect className="fill-current" x="0.0429688" y="0.5" width="24" height="24" />
      </mask>
      <g mask="url(#mask0_9954_78695)">
        <path
          className="fill-current"
          d="M5.89297 17.6C6.74297 16.95 7.69297 16.4373 8.74297 16.062C9.79297 15.6873 10.893 15.5 12.043 15.5C13.193 15.5 14.293 15.6873 15.343 16.062C16.393 16.4373 17.343 16.95 18.193 17.6C18.7763 16.9167 19.2306 16.1417 19.556 15.275C19.8806 14.4083 20.043 13.4833 20.043 12.5C20.043 10.2833 19.264 8.39567 17.706 6.837C16.1473 5.279 14.2596 4.5 12.043 4.5C9.8263 4.5 7.93897 5.279 6.38097 6.837C4.8223 8.39567 4.04297 10.2833 4.04297 12.5C4.04297 13.4833 4.20564 14.4083 4.53097 15.275C4.85564 16.1417 5.30964 16.9167 5.89297 17.6ZM12.043 13.5C11.0596 13.5 10.2303 13.1627 9.55497 12.488C8.8803 11.8127 8.54297 10.9833 8.54297 10C8.54297 9.01667 8.8803 8.18733 9.55497 7.512C10.2303 6.83733 11.0596 6.5 12.043 6.5C13.0263 6.5 13.8556 6.83733 14.531 7.512C15.2056 8.18733 15.543 9.01667 15.543 10C15.543 10.9833 15.2056 11.8127 14.531 12.488C13.8556 13.1627 13.0263 13.5 12.043 13.5ZM12.043 22.5C10.6596 22.5 9.35964 22.2373 8.14297 21.712C6.9263 21.1873 5.86797 20.475 4.96797 19.575C4.06797 18.675 3.35564 17.6167 2.83097 16.4C2.30564 15.1833 2.04297 13.8833 2.04297 12.5C2.04297 11.1167 2.30564 9.81667 2.83097 8.6C3.35564 7.38333 4.06797 6.325 4.96797 5.425C5.86797 4.525 6.9263 3.81233 8.14297 3.287C9.35964 2.76233 10.6596 2.5 12.043 2.5C13.4263 2.5 14.7263 2.76233 15.943 3.287C17.1596 3.81233 18.218 4.525 19.118 5.425C20.018 6.325 20.7303 7.38333 21.255 8.6C21.7803 9.81667 22.043 11.1167 22.043 12.5C22.043 13.8833 21.7803 15.1833 21.255 16.4C20.7303 17.6167 20.018 18.675 19.118 19.575C18.218 20.475 17.1596 21.1873 15.943 21.712C14.7263 22.2373 13.4263 22.5 12.043 22.5Z"
        />
      </g>
    </svg>
  );
};

export default AccountCircleIcon;
