// packages
import React from 'react';
import { useFragment } from 'react-relay';
// models
import { InfoPrimaryDutyProps } from 'models/modelsOfComponents';
// generated
import { ServiceFragmentsOneDuty$key } from 'schemas/services/__generated__/ServiceFragmentsOneDuty.graphql';
// schemas
import { GET_ONE_DUTY } from 'schemas/services/ServiceFragments';

const InfoPrimaryDuty: React.FC<InfoPrimaryDutyProps> = ({ primaryDutyFragment, classes }) => {
  const primaryDuty = useFragment<ServiceFragmentsOneDuty$key>(GET_ONE_DUTY, primaryDutyFragment || null);
  return <span className={classes}>{primaryDuty?.name}</span>;
};

export default InfoPrimaryDuty;
