// packages
import { Component, ErrorInfo } from 'react';
import * as Sentry from '@sentry/react';
import translations from '../translations';
// components
import Logo from 'icons/LogoIcon';
import { Button } from './Button';
import { RoutePaths } from 'app/routing';
import LogoFull from 'icons/LogoFullIcon';
import ErrorBoundaryLogoutButton from './ErrorBoundaryLogoutButton';
// utils
import { getRequestErrorIdFromMessage } from '../utils';
// models
import { ErrorBoundaryProps, ErrorBoundaryState } from 'models/modelsOfComponents';
// providers
import { AuthProvider } from 'authentication';

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    error: null,
    hasError: false,
    errorId: '',
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.handleGoToBack = this.handleGoToBack.bind(this);
  }

  public static getDerivedStateFromError(err: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: err, errorId: getRequestErrorIdFromMessage(err) };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.warn('Uncaught error:', error, errorInfo);
    if (error) {
      Sentry.captureException(error);
    }
  }

  public handleGoToBack() {
    const getCurrentOrganisation = JSON.parse(localStorage.getItem('currentOrganisation') as string);
    if (getCurrentOrganisation.isSelected) {
      window.location.assign(`${RoutePaths.ProfileOrganizationBase}/${getCurrentOrganisation.organisationId}`);
    } else {
      window.location.assign(RoutePaths.ProfileBase);
    }
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="pl-3">
          <div className="flex max-w-7xl lg:mx-20 mx-10 items-center h-16">
            <div onClick={this.handleGoToBack}>
              <Logo />
            </div>
            <div onClick={this.handleGoToBack}>
              <LogoFull classes="hidden lg:block" />
            </div>
          </div>
          <div className="w-full flex items-center mt-52 flex-col mb-4">
            <h1 className="lg:text-3xl text-lg font-bold mb-5">{translations.error_boundary_text}</h1>
            <div className="flex gap-x-2">
              <Button buttonType="primary" label={{ id: translations.error_go_to_back }} onClick={this.handleGoToBack} />
              <AuthProvider>
                <ErrorBoundaryLogoutButton />
              </AuthProvider>
            </div>
          </div>
          <div className="w-4/6 mx-auto">
            <span>Error id: {this.state.errorId}</span>
            <details className="whitespace-pre-wrap">
              {this.state.error && this.state.error.toString()}
              <br />
              {process.env.NODE_ENV === 'production' && this.state.error && this.state.error.stack}
            </details>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
