import EducationCrewEvSkeletonComponent from './components/EducationCrewEVSkeletonComponent';
import AppraisalsCrewEvComponentSkeleton from './components/AppraisalsCrewEVComponentSkeleton';
import SeaServiceCrewEvComponentsSkeleton from './components/SeaServiceCrewEVComponentsSkeleton';
import CertificatesCrewEvComponentSkeleton from './components/CertificatesCrewEVComponentSkeleton';

const OverviewProfileCrewEvComponentSkeleton = () => {
  return (
    <div className="flex w-full flex-col [&>section]:mb-8">
      <SeaServiceCrewEvComponentsSkeleton />
      <CertificatesCrewEvComponentSkeleton />
      <EducationCrewEvSkeletonComponent />
      <AppraisalsCrewEvComponentSkeleton />
    </div>
  );
};

export default OverviewProfileCrewEvComponentSkeleton;
