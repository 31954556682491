import React from 'react';
import Skeleton from 'react-loading-skeleton';

const DashboardTableBodySkeleton: React.FC<{ lastIndex?: boolean }> = ({ lastIndex }) => {
  return (
    <div className={`flex flex-col md:flex-row items-left md:items-center p-3 border border-t-0 bg-white ${lastIndex && 'rounded-2xl rounded-t-none'}`}>
      {/*serialNumber*/}
      <Skeleton width={16} height={16} containerClassName="flex items-center pl-3 w-5%" />
      {/*STATUS*/}
      <Skeleton width={50} height={24} containerClassName="flex items-center w-5%" />
      {/*POSITION TITLE*/}
      <Skeleton width={200} height={14} containerClassName="w-1/5 flex items-center pl-4" />
      {/*POSITIONS (DUTIES)*/}
      <Skeleton width={120} height={14} containerClassName="w-[13%] flex items-center pl-4" />
      {/*VESSEL TYPE*/}
      <Skeleton width={150} height={14} containerClassName="w-[15%] flex items-center pl-4" />
      {/*SALARY RANGE*/}
      <Skeleton width={60} height={14} containerClassName="w-[10%] flex items-center" />
      {/*INDUSTRY*/}
      <Skeleton width={100} height={14} containerClassName="w-[10%] flex items-center" />
      {/*APPLIED CREW*/}
      <div className="w-[7%] flex">
        <Skeleton width={27} height={27} circle className="border-2 border-white" />
        <Skeleton width={27} height={27} circle className="border-2 border-white relative -left-2" />
        <Skeleton width={27} height={27} circle className="border-2 border-white -left-4" />
        <Skeleton width={27} height={27} circle className="border-2 border-white -left-6" />
      </div>
      {/*POSTING DATE*/}
      <Skeleton width={80} height={14} containerClassName="w-[10%] flex items-center" />
      {/*setting button*/}
      <Skeleton width={40} height={40} containerClassName="w-[5%] flex" borderRadius="0.75rem" />
    </div>
  );
};

export default DashboardTableBodySkeleton;
