import { RecordSourceSelectorProxy } from 'relay-runtime';
import { ContactMutationsCreateVacancyContactMutation$data } from 'schemas/contacts/__generated__/ContactMutationsCreateVacancyContactMutation.graphql';
import { ContactMutationsDeleteVacancyContactMutation$data } from 'schemas/contacts/__generated__/ContactMutationsDeleteVacancyContactMutation.graphql';

export const CreateContactUpdater = (vacancyId: string) => (store: RecordSourceSelectorProxy<ContactMutationsCreateVacancyContactMutation$data>) => {
  const payload = store.getRootField('createContact');
  const vacancyRecord = store.get(vacancyId!);
  const contactsRecords = vacancyRecord?.getLinkedRecords('contacts');
  if (contactsRecords) {
    contactsRecords.push(payload.getLinkedRecord('node'));
    vacancyRecord?.setLinkedRecords(contactsRecords, 'contacts');
  }
};

export const RemoveContactUpdater = (vacancyId: string) => (store: RecordSourceSelectorProxy<ContactMutationsDeleteVacancyContactMutation$data>) => {
  const payload = store.getRootField('deleteContact');
  const vacancyRecord = store.get(vacancyId!);
  const contactsRecords = vacancyRecord?.getLinkedRecords('contacts');
  if (contactsRecords) {
    const filteringContactsRecords = contactsRecords.filter(contact => contact.getDataID() !== payload.getDataID());
    vacancyRecord?.setLinkedRecords(filteringContactsRecords, 'contacts');
  }
};
