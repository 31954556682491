import { useIntl } from 'react-intl';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFragment } from 'react-relay';
//generated
import { ProfileFragmentsEmployerCrewPools$key } from 'schemas/profile/__generated__/ProfileFragmentsEmployerCrewPools.graphql';
import { ProfileFragmentsEmployerCrewPoolStats$key } from 'schemas/profile/__generated__/ProfileFragmentsEmployerCrewPoolStats.graphql';
//schemas
import { EMPLOYER_CREW_POOL_STATS_FRAGMENT, EMPLOYER_CREW_POOLS_FRAGMENT } from 'schemas/profile/ProfileFragments';
//icons
import DefaultProfileAvatar from 'icons/DefaultProfileAvatar';
//routing
import { RoutesPathNames } from 'app/routing';
//components
import DeleteStrictIcon from 'icons/DeleteStrictIcon';

const EmployerCrewPoolItem = ({ crewPool, poolStats }: { crewPool: ProfileFragmentsEmployerCrewPools$key; poolStats: ProfileFragmentsEmployerCrewPoolStats$key | null }) => {
  const intl = useIntl();

  const [emptyAvatars] = useState(['emptyAvatar1', 'emptyAvatar2', 'emptyAvatar3']);
  const [avatarSizeStyle] = useState('h-11 w-11');

  const pool = useFragment<ProfileFragmentsEmployerCrewPools$key>(EMPLOYER_CREW_POOLS_FRAGMENT, crewPool);
  const statsData = useFragment<ProfileFragmentsEmployerCrewPoolStats$key>(EMPLOYER_CREW_POOL_STATS_FRAGMENT, poolStats);

  return (
    <Link
      data-test-id={pool.name}
      to={`${RoutesPathNames.currentPool}/${pool.id}`}
      className="flex transition-all border border-[#DADEE5] rounded-[15px] min-h-[94px] h-fit w-full p-5 text-darkBlue bg-white hover:drop-shadow-md gap-y-2.5 items-center overflow-hidden"
    >
      <div className="flex flex-col items-center justify-center mr-3">
        <div className="flex isolate -space-x-4">
          {statsData?.crewPoolAvatars
            ? statsData?.crewPoolAvatars.map((avatarUrl, i) => {
                return (
                  <div key={i} className={`relative flex ${avatarSizeStyle} rounded-full bg-avatar-bg ring ring-white`}>
                    {avatarUrl ? (
                      <img className={`inline-block ${avatarSizeStyle} rounded-full ring ring-white hover:drop-shadow-md object-cover`} src={avatarUrl} alt="profile-img" />
                    ) : (
                      <DefaultProfileAvatar />
                    )}
                  </div>
                );
              })
            : emptyAvatars.map(emptyItem => {
                return <div key={emptyItem} className={`relative flex ${avatarSizeStyle} rounded-full bg-avatar-bg ring ring-white`} />;
              })}
        </div>
      </div>

      <div className="flex flex-col w-full justify-center">
        <div className="flex items-center">
          <div className="inline-grid max-w-[85%]">
            <span className="font-semibold text-lg truncate">{pool?.name}</span>
          </div>
          <div className="flex ml-auto">
            <Link
              data-test-id={`delete-crew-pool-${pool.name}`}
              className="flex font-semibold rounded-md justify-center items-center"
              to={`${RoutesPathNames.deletePool}/${pool.id}`}
            >
              <DeleteStrictIcon />
            </Link>
          </div>
        </div>

        <div className="flex w-full flex-wrap gap-x-2 text-specialGray-075 text-sm">
          <span data-test-id={`${pool.name}-crew-pool-item-crews-amount`} className="flex lowercase">
            {intl.formatMessage({ id: 'crews' }, { value: statsData?.totalCrewCount || 0 })}
          </span>
          <span className="flex lowercase">{intl.formatMessage({ id: 'available' }, { value: statsData?.availableCrewCount || 0 })}</span>
          {/*mock pool info*/}
          <span className="flex lowercase">{intl.formatMessage({ id: 'online' }, { value: 0 })}</span>
        </div>
      </div>
    </Link>
  );
};

export default EmployerCrewPoolItem;
