// packages
import { Fragment, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
import { Menu, Transition } from '@headlessui/react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
// components
import { RoutePaths, RoutesPathNames } from 'app/routing';
import CustomNavLink from './components/CustomNavLink';
import { ProfileAvatar } from 'app/Avatars/ProfileAvatar/ProfileAvatar';
import OrganizationInvitesMenu from './components/OrganizationInvitesMenu/OrganizationInvitesMenu';
// schemas
import { PROFILE_INFO_FRAGMENT } from 'schemas/profile/ProfileFragments';
// generated
import { ProfileFragments_info$key } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
// icons
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
// models
import { NavbarProfileProps } from './types';
// hooks
import { useAuth } from 'authentication';
// recoil
import { assumeProfileAtom } from 'recoil/Admin/atoms/assumeProfileAtom';
import { personalAccountSelector } from 'recoil/Profile/personalAccount/selectors/personalAccountSelector';
import { emptyOrganisationAtom } from 'recoil/Organisation/getCurrentOrganization/atoms/emptyOrganisationAtom';
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';

// NavbarProfile holds the profile section and menu of the navbar
export const NavbarProfile = ({ selfProfileData, openNavbarProfileByDefault }: NavbarProfileProps) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const getIsAssumeProfile = useRecoilValue(assumeProfileAtom);
  const setIsPersonalAccount = useSetRecoilState(personalAccountSelector);
  const [getCurrentOrganisation, setCurrentOrganisation] = useRecoilState(currentOrganisationSelector);
  const [getIsEmptyOrganisation, setIsEmptyOrganisation] = useRecoilState(emptyOrganisationAtom);

  const profileInfoData = useFragment<ProfileFragments_info$key>(PROFILE_INFO_FRAGMENT, selfProfileData?.info);

  const { setEmail, setPassword, identity, isAdminProfile } = useAuth();

  const handleLogout = async () => {
    if (setEmail && setPassword) {
      setEmail('');
      setPassword('');
    }
    window.analytics?.track('wui-sing-out-click', {
      profileId: identity?.profileId,
    });
    setIsEmptyOrganisation({ isEmpty: false });
    setCurrentOrganisation({ isSelected: false, organisationId: '', profileId: '' });
    setIsPersonalAccount({ personalAccountIsSelected: true });
    navigate(RoutePaths.AuthLogin);
    await Auth.signOut();
  };

  const handleAddAnalytics = useCallback(
    (eventName: string) => () => {
      window.analytics?.track(eventName, {
        profileId: identity?.profileId,
      });
    },
    [identity?.profileId],
  );

  const handleClickByProfileButton = useCallback(() => {
    handleAddAnalytics('wui-your-profile-click')();
    setCurrentOrganisation({ isSelected: false, organisationId: '', profileId: '' });
    setIsPersonalAccount({ personalAccountIsSelected: true });
  }, [handleAddAnalytics, setCurrentOrganisation, setIsPersonalAccount]);

  const handleAssumeUser = useCallback(() => {
    navigate(`${RoutesPathNames.profileBase}/${RoutesPathNames.assume}`, { state: { isShowAssumeModal: true, prevPath: pathname } });
  }, [navigate, pathname]);

  return (
    <div className="flex items-center sm:static sm:inset-auto">
      {/* Profile dropdown */}
      <Menu as="div" className="lg:ml-3 relative">
        {({ open }) => (
          <>
            <div className="flex">
              <Menu.Button className="bg-white flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                <span className="hidden md:inline text-specialGray-05">
                  {open || openNavbarProfileByDefault ? <ChevronUpIcon className="w-3 mt-2.5 mr-2.5" /> : <ChevronDownIcon className="w-3 mt-2.5 mr-2.5" />}
                </span>
                <span className="sr-only">{intl.formatMessage({ id: 'navbar_sr_openUserMenu' })}</span>
                <ProfileAvatar size="w-8 h-8" profile={selfProfileData} avatarUrl={profileInfoData?.avatarUrl} />
                <span className="md:hidden inline text-specialGray-05">
                  {open || openNavbarProfileByDefault ? <ChevronUpIcon className="w-3 mt-2.5 ml-2.5" /> : <ChevronDownIcon className="w-3 mt-2.5 ml-2.5" />}
                </span>
              </Menu.Button>
            </div>
            <Transition
              show={open || openNavbarProfileByDefault}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-specialGray-012 rounded-md bg-white shadow-lg ring-1 ring-specialGray-012 focus:outline-none"
              >
                <div className="flex flex-col">
                  {getCurrentOrganisation.isSelected ? (
                    <CustomNavLink
                      onClick={handleAddAnalytics('wui-your-organisation-click')}
                      to={`${RoutesPathNames.ProfileOrganizationBase}/${getCurrentOrganisation.organisationId}`}
                      label={{ id: 'navbar_your_company_profile' }}
                    />
                  ) : (
                    <CustomNavLink onClick={handleClickByProfileButton} to={RoutePaths.ProfileBase} label={{ id: 'navbar_your_crew_profile' }} state={{ isNewHire: true }} />
                  )}
                  {!getCurrentOrganisation.isSelected ? (
                    <CustomNavLink onClick={handleAddAnalytics('wui-job-responses-click')} to={RoutePaths.JobResponses} label={{ id: 'job_responses_main_title' }} />
                  ) : null}
                  <CustomNavLink onClick={handleAddAnalytics('wui-account-settings-click')} to={RoutePaths.AccountSettings} label={{ id: 'label_account_settings' }} />
                </div>

                <div className="flex flex-col">
                  {!getCurrentOrganisation.isSelected ? (
                    <CustomNavLink
                      onClick={handleAddAnalytics('wui-organization-invites-click')}
                      to={RoutePaths.ProfileOrganizationInvites}
                      label={{ id: 'label_organization_invites' }}
                    />
                  ) : null}

                  {getIsEmptyOrganisation.isEmpty ? (
                    <CustomNavLink
                      onClick={handleAddAnalytics('wui-organization-invites-click')}
                      to={RoutePaths.ProfileOrganizationBase}
                      label={{ id: 'label_setup_organization' }}
                    />
                  ) : null}

                  <OrganizationInvitesMenu selfAvatarUrl={profileInfoData?.avatarUrl ?? ''} />
                </div>

                {isAdminProfile && !getIsAssumeProfile ? (
                  <div className="flex">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={handleAssumeUser}
                          className={
                            active
                              ? 'bg-specialGray-005 block w-full text-left px-4 py-2 text-sm text-specialGray-075'
                              : 'block w-full text-left px-4 py-2 text-sm text-specialGray-075'
                          }
                        >
                          {intl.formatMessage({ id: 'assume_other_user' })}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                ) : null}

                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handleLogout}
                      className={
                        active
                          ? 'bg-specialGray-005 block w-full text-left px-4 py-2 text-sm text-darkBlue'
                          : 'hover:bg-specialGray-005 block w-full text-left px-4 py-2 text-sm text-darkBlue'
                      }
                    >
                      {intl.formatMessage({ id: 'navbar_signOut' })}
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};
