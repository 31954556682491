// packages
import { useEffect, useState } from 'react';
import ReactConfetti from 'react-confetti';
//components
import CompletedProfileModal from './components/CompletedProfileModal';
// hooks
import { useGetProfileScore } from 'hooks/useGetProfileScore';
import { useCurrentWidth } from 'hooks/useBreakpoints';

const ConfettiProfileScore = () => {
  const { profileScoreSummary } = useGetProfileScore();
  const windowSize = useCurrentWidth();

  const [maximumSummary] = useState<number>(100);
  const [runConfetti, setRunConfetti] = useState<boolean>(true);
  const [, setSummary] = useState<number>(profileScoreSummary);
  const [showConfettiComponent, setShowConfettiComponent] = useState(false);

  useEffect(() => {
    if (!!profileScoreSummary) {
      setSummary(prev => {
        if (!!prev && prev < maximumSummary && (profileScoreSummary as number) >= maximumSummary) {
          setShowConfettiComponent(true);
          setRunConfetti(true);
        }
        return profileScoreSummary;
      });
    }
  }, [maximumSummary, profileScoreSummary]);

  //delete content from tree after 4 sec (when confetti action is ends)
  useEffect(() => {
    const timerId = setTimeout(() => {
      if (runConfetti) {
        return;
      }
      setShowConfettiComponent(false);
    }, 4000);
    return () => {
      clearTimeout(timerId);
    };
  }, [runConfetti]);

  return (
    <>
      {showConfettiComponent && (
        <>
          <ReactConfetti width={windowSize.width} height={windowSize.height} style={{ zIndex: 60 }} numberOfPieces={500} recycle={runConfetti} />
          <CompletedProfileModal runConfetti={runConfetti} handleRunConfetti={setRunConfetti} />
        </>
      )}
    </>
  );
};

export default ConfettiProfileScore;
