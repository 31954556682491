// routes
import { RoutesPathNames } from 'app/routing';
// components
import CrewConfirm from '../components/crew/CrewConfirm/CrewConfirm';
import CrewLoginComponent from '../components/crew/CrewLogin/CrewLoginComponent';
import HireForgotConfirm from '../components/hire/HireForgotConfirm/HireForgotConfirm';
import HireLoginComponent from '../components/hire/HireLoginComponent/HireLoginComponent';
import CrewForgetComponent from '../components/crew/CrewForgetComponent/CrewForgetComponent';
import ForgotForgotConfirm from '../components/crew/ForgotForgotConfirm/ForgotForgotConfirm';
import HireForgotComponent from '../components/hire/HireForgotComponent/HireForgotComponent';

export const ChildrenRoutes = [
  {
    path: RoutesPathNames.login,
    Component: CrewLoginComponent,
  },
  {
    path: RoutesPathNames.forgot,
    Component: CrewForgetComponent,
  },
  {
    path: RoutesPathNames.forgotConfirm,
    Component: ForgotForgotConfirm,
  },
  {
    path: RoutesPathNames.registerConfirm,
    Component: CrewConfirm,
  },
  {
    path: RoutesPathNames.hireLogin,
    Component: HireLoginComponent,
  },
  {
    path: RoutesPathNames.hireForgot,
    Component: HireForgotComponent,
  },
  {
    path: RoutesPathNames.hireForgotConfirm,
    Component: HireForgotConfirm,
  },
];
