// packages
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
import { Link } from 'react-router-dom';
// components
import ItemTitle from '../ItemTitle';
import JobAvatar from 'app/Avatars/JobAvatar/JobAvatar';
import JobOpeningStatus from 'system/JobOpeningStatus/JobOpeningStatus';
// models
import { VACANCY_STATUS } from 'models/enums';
import { DropDownListOfVacanciesItemProps } from 'app/Navbar/types';
// routing
import { RoutePaths } from 'app/routing';
// generated
import { VacancyFragmentsForSearchFragment$key } from 'schemas/vacancy/__generated__/VacancyFragmentsForSearchFragment.graphql';
// schemas
import { VACANCY_FRAGMENT_FOR_SEARCH } from 'schemas/vacancy/VacancyFragments';
// context
import { useNavbarSearchContext } from 'Context/NavbarSearchContext';

const DropDownListOfVacanciesItem = ({ setShowDropDown, vacancy }: DropDownListOfVacanciesItemProps) => {
  const intl = useIntl();
  const { queryParams } = useNavbarSearchContext();

  const vacancyData = useFragment<VacancyFragmentsForSearchFragment$key>(VACANCY_FRAGMENT_FOR_SEARCH, vacancy);
  const isSalaryDataExist = vacancyData.salaryFrom && vacancyData.salaryTo;

  const handleClickByLink = () => {
    if (vacancyData.status === VACANCY_STATUS.OPEN) {
      window.analytics?.track('wui-opened-single-job-opening-in-search-dropdown', {
        vacancyId: vacancyData.id,
      });
    }
    setShowDropDown();
  };

  return (
    <Link
      to={`${RoutePaths.ProfileBase}/job/${vacancyData?.id}`}
      state={{ queryParams }}
      onClick={handleClickByLink}
      className="w-full border-b last:border-0 py-2.5 sm:py-2 px-2.5 flex items-start cursor-pointer hover:bg-specialGray-005 hover:font-medium space-x-3"
    >
      <JobAvatar size="w-10 h-10 sm:w-14 sm:h-14" avatarUrl={vacancyData?.organization.avatarUrl} alt={vacancyData.id} />
      <div className="flex-col w-full truncate">
        <div className="flex space-x-2">
          <JobOpeningStatus additionalClasses="text-[10px] h-4 px-1 mt-1" status={vacancyData?.status as VACANCY_STATUS} />
          <ItemTitle title={vacancyData.title} />
        </div>
        <div className="flex text-specialGray-075 mt-0.5 font-normal flex-wrap text-xs sm:text-sm">
          {vacancyData.hiringArea?.name && <span className="pr-1">{vacancyData.hiringArea?.name}</span>}
          {!!isSalaryDataExist && (
            <div className="space-x-1">
              <span>
                {vacancyData?.salaryFrom} &#x2D; {vacancyData?.salaryTo}
              </span>
              <span className="uppercase">{vacancyData.currency?.name || intl.formatMessage({ id: 'usd' })}</span>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default DropDownListOfVacanciesItem;
