// packages
import { PropsWithChildren } from 'react';
import { useSubscription } from 'react-relay';
// schemas
import { PROFILE_SCORE_SCORE_DID_CHANGE_SUBSCRIPTION } from 'schemas/profile/ProfileSubscriptions';
// hooks
import { useAuth } from '../authentication';

const WrappersForSubscribes = ({ children }: PropsWithChildren) => {
  const { identity } = useAuth();
  useSubscription({
    subscription: PROFILE_SCORE_SCORE_DID_CHANGE_SUBSCRIPTION,
    variables: { pid: +identity?.profileId!, skip: !identity?.profileId },
  });

  return <>{children}</>;
};

export default WrappersForSubscribes;
