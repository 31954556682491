import graphql from 'babel-plugin-relay/macro';

export const VACANCY_FRAGMENT = graphql`
  fragment VacancyFragments on Vacancy @refetchable(queryName: "VacancyQueriesGetVacancyRefreshQuery") {
    id
    createdAt
    title
    status
    industry
    salaryPeriod
    organization {
      id
      description
      avatarUrl
      size
      headquarters
      websiteUrl
      name
    }
    salaryFrom
    salaryTo
    duration
    responsibilities
    description
    certificates {
      id
      name
      alts
      priority
      neverExpires
      kind {
        id
        name
        perma
      }
    }
    vesselKind {
      id
      name
      perma
    }
    duties {
      id
      name
      alts
      perma
    }
    hiringArea {
      id
      perma
      name
    }
    tornWeeksOff
    tornWeeksOn
    applications {
      id
      owner {
        id
        info {
          firstName
          lastName
          avatarUrl
        }
      }
      status
    }
    invites {
      id
      owner {
        id
      }
    }
    currency {
      id
      name
      perma
    }
    startingCondition
    startingDate
    contacts {
      id
      owner {
        id
      }
    }
    organizationMember {
      id
      profile {
        id
        info {
          firstName
          lastName
        }
      }
      organization {
        id
        name
        avatarUrl
        size
        description
        websiteUrl
      }
    }
  }
`;

export const VACANCY_FRAGMENT_FOR_DASHBOARD = graphql`
  fragment VacancyFragmentsForDashboardFragment on Vacancy {
    id
    createdAt
    title
    status
    industry
    salaryFrom
    salaryTo
    vesselKind {
      id
      name
    }
    duties {
      id
      name
    }
    applications {
      id
      owner {
        id
        info {
          firstName
          lastName
          avatarUrl
        }
      }
    }
    organization {
      id
      avatarUrl
    }
  }
`;

export const VACANCY_FRAGMENT_FOR_SEARCH = graphql`
  fragment VacancyFragmentsForSearchFragment on Vacancy @refetchable(queryName: "VacancyQueriesGetVacancyForSearchRefreshQuery") {
    id
    createdAt
    title
    status
    organization {
      id
      avatarUrl
    }
    salaryPeriod
    salaryFrom
    salaryTo
    description
    hiringArea {
      id
      perma
      name
    }
    currency {
      id
      name
      perma
    }
    duties {
      id
      name
    }
    vesselKind {
      id
      name
    }
    startingDate
    duration
  }
`;
