import { useFragment } from 'react-relay';
import { Link } from 'react-router-dom';
// models
import { DropDownListOfProfilesItemProps } from 'app/Navbar/types';
// hooks
import useCrewUrlPath from 'hooks/useCrewURLPath';
import useGetRestrictedProfileLastName from 'hooks/ProfileDataHooks/useGetRestrictedProfileLastName';
// components
import { ProfileAvatar } from 'app/Avatars/ProfileAvatar/ProfileAvatar';
import OrganizationsMembershipInfo from 'app/Profile/OrganizationsMembershipInfo/OrganizationsMembershipInfo';
// generated
import { ProfileFragmentsSearchDropDown$key } from 'schemas/profile/__generated__/ProfileFragmentsSearchDropDown.graphql';
import { ProfileFragments_SearchDropDown_info$key } from 'schemas/profile/__generated__/ProfileFragments_SearchDropDown_info.graphql';
import { ProfileFragmentsOrganizationPlural_profile$key } from 'schemas/profile/__generated__/ProfileFragmentsOrganizationPlural_profile.graphql';
// schemas
import { PROFILE_ORGANIZATION_FRAGMENT, PROFILE_SEARCH_DROP_DOWN_FRAGMENT, PROFILE_SEARCH_INFO } from 'schemas/profile/ProfileFragments';
// context
import { useNavbarSearchContext } from 'Context/NavbarSearchContext';

const DropDownListItem = ({ setShowDropDown, profile }: DropDownListOfProfilesItemProps) => {
  const crewPagePath = useCrewUrlPath();
  const profileData = useFragment<ProfileFragmentsSearchDropDown$key>(PROFILE_SEARCH_DROP_DOWN_FRAGMENT, profile);
  const profileInfo = useFragment<ProfileFragments_SearchDropDown_info$key>(PROFILE_SEARCH_INFO, profileData?.info || null);
  const profileOrganizations = useFragment<ProfileFragmentsOrganizationPlural_profile$key>(PROFILE_ORGANIZATION_FRAGMENT, profileData?.membership || null);
  const lastName = useGetRestrictedProfileLastName(profileInfo?.lastName);
  const { queryParams } = useNavbarSearchContext();

  const handleNavigateToProfile = (profileId: string) => () => {
    window.analytics?.track('wui-select-profile-in-search-dropdown', {
      selectedProfileId: profileId,
    });
    setShowDropDown();
  };

  return (
    <Link
      to={`${crewPagePath}/${profileData.id}`}
      state={{ queryParams }}
      onClick={handleNavigateToProfile(profileInfo?.id as string)}
      className="w-full border-b last:border-b-0 py-2.5 sm:py-2 px-2.5 flex items-start cursor-pointer hover:bg-specialGray-005 font-medium space-x-3 z-20"
    >
      <ProfileAvatar size="w-10 h-10 sm:w-14 sm:h-14" avatarUrl={profileInfo?.avatarUrl} />
      <section className="flex flex-col w-full py-1">
        <span className="text-specialGray-1 text-sm sm:text-base truncate">{`${profileInfo?.firstName} ${lastName}`}</span>
        <div className="flex gap-x-3 items-center flex-wrap gap-y-2">
          {profileInfo?.primaryDuty?.name && <span className="text-specialGray-075 font-normal text-xs sm:text-sm">{profileInfo?.primaryDuty?.name}</span>}
          {!!profileOrganizations?.length && <OrganizationsMembershipInfo profileOrganizations={profileOrganizations} profileId={profileData?.id as string} />}
        </div>
      </section>
    </Link>
  );
};

export default DropDownListItem;
