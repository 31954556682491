// packages
import { useState, useEffect, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
// mocks
import { JOB_OPENING_FORM_STEPS } from 'mocks/mockData';
// models
import { JobOpeningFormStepType } from 'models/commonTypes';
// routing
import { RoutePaths } from 'app/routing';
// utils
import { getLastElementFromArray } from 'utils';
// components
import StepFormComponent from './components/StepFormComponent';

const JobOpeningForm = ({ children }: PropsWithChildren) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const [steps, setSteps] = useState<Array<JobOpeningFormStepType>>(JOB_OPENING_FORM_STEPS);
  const [step, setStep] = useState<JobOpeningFormStepType>({ ...steps[0], active: true });

  useEffect(() => {
    switch (getLastElementFromArray<string>(pathname.split('/'))) {
      case RoutePaths.JobOpeningStep2: {
        setSteps(prevState => {
          return prevState.map((step, index) => {
            if (index <= 1) {
              if (index === 1) {
                setStep({ ...step, active: true });
                return { ...step, active: true, current: true };
              }
              return { ...step, active: true, current: false };
            } else {
              return { ...step, current: false };
            }
          });
        });
        return;
      }
      case RoutePaths.JobOpeningStep3: {
        setSteps(prevState => {
          return prevState.map((step, index) => {
            if (index <= 2) {
              if (index === 2) {
                setStep({ ...step, active: true });
                return { ...step, active: true, current: true };
              }
              return { ...step, active: true, current: false };
            } else {
              return { ...step, current: false };
            }
          });
        });
        return;
      }
      default: {
        setSteps(prevState => {
          return prevState.map((step, index) => {
            if (index === 0) {
              setStep({ ...step, active: true });
              return { ...step, active: true, current: true };
            } else {
              return { ...step, current: false };
            }
          });
        });
        return;
      }
    }
  }, [pathname]);

  return (
    <div className="mx-auto w-full md:w-5/6 xl:w-4/5 max-w-[1024px]">
      <div className="mt-3 text-specialGray-1 mb-10">
        <div className="flex mb-12 space-x-2.5 justify-center">
          {steps.map((step, index, array) => (
            <StepFormComponent step={step} key={step.path} nextSibling={array[index + 1]} />
          ))}
        </div>
        <h3 className="text-2xl mb-1 leading-6 font-medium">{intl.formatMessage(step.bigTitle)}</h3>
        <p className="font-normal text-specialGray-075 text-sm leading-5">{intl.formatMessage({ id: 'hire_form_step_description' })}</p>
      </div>
      {children}
    </div>
  );
};

export default JobOpeningForm;
