// packages
import { Suspense, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
// components
import { Modal } from 'system/Modal';
import { employerTabs, RoutePaths, RoutesPathNames } from 'app/routing';
import SpinnerIcon from 'icons/SpinnerIcon';
import CountryFlag from 'system/CountryFlag';
import CustomButton from 'system/Buttons/CustomButton';
import CustomNavLink from 'system/CustomNavLink/CustomNavLink';
import HireSubscriptionProduct from 'app/ProfileEmployer/HireProduct';
import CommercialAvatar from 'app/Avatars/CommercialAvatar/CommercialAvatar';
import { BasicInfoForm } from 'app/ProfileEmployer/components/BasicInfoForm/BasicInfoForm';
import EmployerProfileBasicInfo from './components/EmployerProfileBasicInfo/EmployerProfileBasicInfo';
// models
import { EmployerProfileProps } from 'models/modelsOfComponents';
// hooks
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useGetHireProfile, useGetNumberOfOpenedAndClosedVacancies } from 'hooks/hireProfileHooks';
// icons
import { PencilEditIcon } from 'icons/PencilEditIcon';
// utils
import { envVariableTransform } from 'utils';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';

// Profile employer renders only if isHireAccount or member role trues from a backend set
export const Profile = ({ defaultShowModal = false, defaultShowSupaDemoModal = false }: EmployerProfileProps) => {
  const intl = useIntl();
  const { pathname, state } = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { sm } = useBreakpoints();
  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);

  useLayoutEffect(() => {
    if (!getCurrentOrganisation.isSelected && params.orgId) {
      navigate(`/${RoutesPathNames.orgInfo}/${params.orgId}`, { replace: true });
    }
  }, [getCurrentOrganisation.isSelected, navigate, params]);

  const [showModal, setShowModal] = useState<boolean>(defaultShowModal);
  const [showSupaDemo, setShowSupaDemo] = useState<boolean>(defaultShowSupaDemoModal);
  const { currentOrganizationData, organizationId, reFetch } = useGetHireProfile();
  const { getNumberOfOpenedVacancies, getNumberOfClosedVacancies } = useGetNumberOfOpenedAndClosedVacancies();

  const handleShowSupaDemoModal = useCallback(() => {
    if (process.env.STORYBOOK_IS_STORYBOOK && defaultShowSupaDemoModal !== undefined) {
      return;
    }
    const isManualCloseSupDemoModal = JSON.parse(localStorage.getItem('manual_close_supademo_modal') ?? 'false');
    if (!currentOrganizationData && !isManualCloseSupDemoModal && !state?.isNewAccount) {
      setShowSupaDemo(true);
    } else if (!currentOrganizationData && isManualCloseSupDemoModal) {
      setShowSupaDemo(false);
    } else {
      setShowSupaDemo(false);
    }
  }, [currentOrganizationData, defaultShowSupaDemoModal, state?.isNewAccount]);

  useEffect(() => {
    handleShowSupaDemoModal();
  }, [handleShowSupaDemoModal]);

  const handleCloseSupaDemo = useCallback(() => {
    setShowSupaDemo(false);
    localStorage.setItem('manual_close_supademo_modal', JSON.stringify(true));
  }, []);

  const tabsNavigation = (path: string, isFirstTab: boolean) => () => {
    if (isFirstTab && currentOrganizationData?.organization.id) {
      navigate(`${path}/${currentOrganizationData?.organization.id}`);
      return;
    }
    navigate(path);
  };

  useEffect(() => {
    if (currentOrganizationData?.organization.id && pathname === `${RoutePaths.ProfileOrganizationBase}`) {
      navigate(`${RoutePaths.ProfileOrganizationBase}/${currentOrganizationData?.organization.id}`);
    }
  }, [currentOrganizationData?.organization.id, navigate, pathname]);

  const handleOpenOrCloseEditModal =
    (openModal = false) =>
    () => {
      if (state?.isShowEditModal) {
        navigate(pathname, { state: { isShowEditModal: false }, replace: true });
      }
      setShowModal(openModal);
    };
  const organizationCountry = currentOrganizationData?.organization?.country;
  const organizationCity = currentOrganizationData?.organization?.city;

  return (
    <main className="w-full max-w-7xl">
      {!sm ? (
        <Modal show={showSupaDemo} onClose={handleCloseSupaDemo} bigModal childrenClass="h-full mt-5 flex items-center">
          <iframe
            id="supademo"
            title="crewlinker supademo video"
            width="100%"
            height="100%"
            src={process.env.REACT_APP_SUPADEMO_VIDEO_LINK || process.env.STORYBOOK_SUPADEMO_VIDEO_LINK}
          />
        </Modal>
      ) : null}
      <Modal
        onClose={handleOpenOrCloseEditModal()}
        show={showModal || state?.isShowEditModal}
        title={{ id: currentOrganizationData ? 'employer_self_basic_edit_modalTitle' : 'employer_edit_profile_title' }}
        description={{ id: currentOrganizationData ? 'employer_edit_self_basic_modalDescription' : 'employer_edit_profile_description' }}
        additionalClasses="max-w-[720px]"
      >
        <Suspense fallback={<SpinnerIcon />}>
          <BasicInfoForm profileOrganization={currentOrganizationData} onCloseModal={handleOpenOrCloseEditModal()} reFetch={reFetch} />
        </Suspense>
      </Modal>
      <div className="flex w-full sm:gap-x-5 sm:w-full sm:flex-row flex-col items-center sm:items-start space-y-3 sm:space-y-0">
        <CommercialAvatar avatarUrl={currentOrganizationData?.organization?.avatarUrl} size="w-20 h-20" />
        <div className="flex flex-col">
          <EmployerProfileBasicInfo profileOrganization={currentOrganizationData} setShowModal={setShowModal} />
          <div className="flex flex-col w-full gap-y-2 sm:gap-y-3">
            {organizationCountry && (
              <div className="flex gap-x-1 text-darkBlue">
                <CountryFlag classes="h-[17px]" countryName={organizationCountry.name} countryId={organizationCountry.isoCode} />
                <span data-test-id="profile-country-name" className="font-medium">
                  {organizationCountry.name}&#44;&nbsp;{organizationCity}
                </span>
              </div>
            )}
            <div className="flex gap-x-6 text-sm lowercase">
              <span data-test-id="number-of-opened-vacancies" className="text-darkBlue">
                {getNumberOfOpenedVacancies}&nbsp;
                <span className="text-specialGray-075">{intl.formatMessage({ id: 'employer_vacancy_status_open' })}</span>
              </span>

              <span data-test-id="number-of-closed-vacancies" className="text-darkBlue">
                {getNumberOfClosedVacancies}&nbsp;
                <span className="text-specialGray-075">{intl.formatMessage({ id: 'employer_vacancy_status_closed' })}</span>
              </span>
            </div>
          </div>
        </div>

        {(envVariableTransform(process.env.REACT_APP_FEATURE_PAYMENT_SUBSCRIPTION) || envVariableTransform(process.env.STORYBOOK_FEATURE_PAYMENT_SUBSCRIPTION)) && (
          <div className="flex sm:ml-auto">
            <HireSubscriptionProduct />
          </div>
        )}
      </div>

      {currentOrganizationData?.organization?.description && (
        <div className="block sm:hidden mt-5">
          <p className="text-darkBlue font-semibold text-center">{intl.formatMessage({ id: 'employer_info_about_us' })}</p>
          <p data-test-id="organization-description" className="text-specialGray-075 break-words text-center">
            {currentOrganizationData?.organization.description}
          </p>
        </div>
      )}

      {currentOrganizationData?.role !== 'member' && (
        <div className="mt-5 w-full sm:hidden flex justify-center">
          <CustomButton
            label={currentOrganizationData ? null : { id: 'employer_self_basic_modalTitle' }}
            onClick={handleOpenOrCloseEditModal(true)}
            className={`px-3 h-10 items-center border-solid border border-specialGray-012 rounded-[10px] hover:bg-specialGray-002 text-specialGray-075 text-sm bg-white flex sm:hidden ${
              currentOrganizationData ? '' : '[&>svg]:mr-2'
            }`}
          >
            <PencilEditIcon />
          </CustomButton>
        </div>
      )}

      <nav className="-mb-px flex mt-5 border-b border-b-specialGray-012 overflow-x-auto" aria-label="Tabs">
        {employerTabs.map((tab, i) => {
          const isFirstTab = i === 0;
          const isOwnerTab = currentOrganizationData?.role === 'owner';

          const matchPathForOtherTabs = `${RoutePaths.ProfileOrganizationBase}/${organizationId}/${tab.href}`;
          const matchForFirstTab =
            isFirstTab && currentOrganizationData?.organization.id ? `${RoutePaths.ProfileOrganizationBase}/${currentOrganizationData?.organization.id}` : tab.href;

          return isFirstTab || tab.showTabToUser ? (
            <CustomNavLink
              data-test-id={`${tab.href}-tab`}
              disabled={!isFirstTab && !isOwnerTab}
              matchByEndOfPath={tab?.matchByEndOfPath}
              onClick={tabsNavigation(tab.href, isFirstTab)}
              to={matchForFirstTab || matchPathForOtherTabs}
              key={tab.name}
              className={`${
                currentOrganizationData?.role === 'member' && !isFirstTab && 'hidden'
              } whitespace-nowrap py-4 px-5 disabled:hover:border-0 disabled:hover:text-specialGray-075 disabled:hover:cursor-not-allowed`}
            >
              {tab.name}
            </CustomNavLink>
          ) : null;
        })}
      </nav>
      <Outlet />
    </main>
  );
};
