/**
 * @generated SignedSource<<c357a0b66c25ed78da325aadd4290ddf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ProfileFragmentsScoreSubscription$data = {
  readonly 'details': string;
  readonly 'id': string;
  readonly 'summary': number;
  readonly ' $fragmentType': 'ProfileFragmentsScoreSubscription';
};
export type ProfileFragmentsScoreSubscription$key = {
  readonly ' $data'?: ProfileFragmentsScoreSubscription$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsScoreSubscription'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ProfileFragmentsScoreSubscription',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'details',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'summary',
      storageKey: null,
    },
  ],
  type: 'ProfileScore',
  abstractKey: null,
};

(node as any).hash = '666f19a8be48f449d5065f21bcb90614';

export default node;
