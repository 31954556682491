/**
 * @generated SignedSource<<8bb0abdf61e73921c6caf66801e5cd2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type AppraisalFragmentsListWithoutDocumentPlural_profile$data = ReadonlyArray<{
  readonly ' $fragmentSpreads': FragmentRefs<'AppraisalFragmentsOneAppraisalWithoutDocument'>;
  readonly ' $fragmentType': 'AppraisalFragmentsListWithoutDocumentPlural_profile';
}>;
export type AppraisalFragmentsListWithoutDocumentPlural_profile$key = ReadonlyArray<{
  readonly ' $data'?: AppraisalFragmentsListWithoutDocumentPlural_profile$data;
  readonly ' $fragmentSpreads': FragmentRefs<'AppraisalFragmentsListWithoutDocumentPlural_profile'>;
}>;

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: {
    plural: true,
  },
  name: 'AppraisalFragmentsListWithoutDocumentPlural_profile',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'AppraisalFragmentsOneAppraisalWithoutDocument',
    },
  ],
  type: 'Appraisal',
  abstractKey: null,
};

(node as any).hash = '0ef24f98b3f6eef4328d545f793a45bd';

export default node;
