// packages
import { useIntl } from 'react-intl';
// generated
import { VacancyFragmentsForSearchFragment$data } from 'schemas/vacancy/__generated__/VacancyFragmentsForSearchFragment.graphql';

const SalaryComponent = ({ vacancy, styles }: { vacancy: VacancyFragmentsForSearchFragment$data; styles: string }) => {
  const intl = useIntl();

  return (
    <div className={`${styles} items-center gap-x-1.5 sm:mr-[18px] text-sm text-darkBlue`}>
      <span className="text-specialGray-075">
        {vacancy?.currency ? vacancy?.currency.name : intl.formatMessage({ id: 'usd' })}&nbsp;&#8725;&nbsp;{vacancy?.salaryPeriod?.toLocaleLowerCase()}
      </span>
      <span className="sm:mr-1.5">
        {vacancy?.salaryFrom || ''}
        {vacancy?.salaryTo ? `\u00A0\u2212\u00A0${vacancy?.salaryTo}` : null}
      </span>
    </div>
  );
};

export default SalaryComponent;
