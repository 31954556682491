/**
 * @generated SignedSource<<8129d1eb5484bd2e4647b77246fdd3ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type EducationOrderField = 'START_DATE' | '%future added value';
export type OrderDirection = 'ASC' | 'DESC' | '%future added value';
export type EducationOrder = {
  direction: OrderDirection;
  field?: EducationOrderField | null;
};
export type EducationQueriesListOfEducationsFromProfileQuery$variables = {
  after?: any | null;
  first?: number | null;
  orderBy?: EducationOrder | null;
  ownerId: string;
  skip: boolean;
};
export type EducationQueriesListOfEducationsFromProfileQuery$data = {
  readonly educations?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly 'end': String | null;
        readonly 'id': string;
        readonly ' $fragmentSpreads': FragmentRefs<'EducationFragments'>;
      } | null;
    } | null> | null;
  } | null;
};
export type EducationQueriesListOfEducationsFromProfileQuery = {
  response: EducationQueriesListOfEducationsFromProfileQuery$data;
  variables: EducationQueriesListOfEducationsFromProfileQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'after',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'first',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'orderBy',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'ownerId',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'skip',
    },
    v5 = {
      kind: 'Variable',
      name: 'orderBy',
      variableName: 'orderBy',
    },
    v6 = {
      kind: 'Variable',
      name: 'ownerId',
      variableName: 'ownerId',
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'end',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: '__typename',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cursor',
      storageKey: null,
    },
    v11 = {
      alias: null,
      args: null,
      concreteType: 'PageInfo',
      kind: 'LinkedField',
      name: 'pageInfo',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'endCursor',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'hasNextPage',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v12 = [
      {
        kind: 'Variable',
        name: 'after',
        variableName: 'after',
      },
      {
        kind: 'Variable',
        name: 'first',
        variableName: 'first',
      },
      v5 /*: any*/,
      v6 /*: any*/,
    ],
    v13 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'EducationQueriesListOfEducationsFromProfileQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: 'educations',
              args: [v5 /*: any*/, v6 /*: any*/],
              concreteType: 'EducationConnection',
              kind: 'LinkedField',
              name: '__EducationsListFromProfile_educations_connection',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'EducationEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Education',
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [
                        v7 /*: any*/,
                        v8 /*: any*/,
                        {
                          args: null,
                          kind: 'FragmentSpread',
                          name: 'EducationFragments',
                        },
                        v9 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    v10 /*: any*/,
                  ],
                  storageKey: null,
                },
                v11 /*: any*/,
              ],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v3 /*: any*/, v0 /*: any*/, v1 /*: any*/, v4 /*: any*/, v2 /*: any*/],
      kind: 'Operation',
      name: 'EducationQueriesListOfEducationsFromProfileQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v12 /*: any*/,
              concreteType: 'EducationConnection',
              kind: 'LinkedField',
              name: 'educations',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'EducationEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Education',
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [
                        v7 /*: any*/,
                        v8 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'start',
                          storageKey: null,
                        },
                        v13 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: 'EducationKind',
                          kind: 'LinkedField',
                          name: 'kind',
                          plural: false,
                          selections: [
                            v7 /*: any*/,
                            v13 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'perma',
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                        v9 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    v10 /*: any*/,
                  ],
                  storageKey: null,
                },
                v11 /*: any*/,
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: v12 /*: any*/,
              filters: ['ownerId', 'orderBy'],
              handle: 'connection',
              key: 'EducationsListFromProfile_educations',
              kind: 'LinkedHandle',
              name: 'educations',
            },
          ],
        },
      ],
    },
    params: {
      cacheID: 'e759d13336b84c8a6d0fb6e489ca6a51',
      id: null,
      metadata: {
        connection: [
          {
            count: 'first',
            cursor: 'after',
            direction: 'forward',
            path: ['educations'],
          },
        ],
      },
      name: 'EducationQueriesListOfEducationsFromProfileQuery',
      operationKind: 'query',
      text: 'query EducationQueriesListOfEducationsFromProfileQuery(\n  $ownerId: ID!\n  $after: Cursor\n  $first: Int\n  $skip: Boolean!\n  $orderBy: EducationOrder\n) {\n  educations(ownerId: $ownerId, first: $first, after: $after, orderBy: $orderBy) @skip(if: $skip) {\n    edges {\n      node {\n        id\n        end\n        ...EducationFragments\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment EducationFragments on Education {\n  id\n  start\n  end\n  name\n  kind {\n    id\n    name\n    perma\n  }\n}\n',
    },
  };
})();

(node as any).hash = '39eb7522dac1481bcd3065361461c050';

export default node;
