import { PropsWithChildren } from 'react';

const OrgInfoField = ({ label, children }: PropsWithChildren<{ label: string }>) => {
  return (
    <div className="flex items-center text-sm text-specialGray-075 gap-x-1.5">
      {children}
      <span>{label}</span>
    </div>
  );
};

export default OrgInfoField;
