import graphql from 'babel-plugin-relay/macro';

export const PROFILE_FRAGMENT_WITH_AVATAR_VERSION = graphql`
  fragment ProfileFragmentsAvatarInfo on Profile {
    id
    avatar_version # local only
  }
`;
export const PROFILE_GENERAL_SETTINGS_FRAGMENT = graphql`
  fragment ProfileFragmentsGeneralSettings on GeneralSetting {
    id
    availableForWork
    hideContactInfo
  }
`;

export const PROFILE_FRAGMENT = graphql`
  fragment ProfileFragments on Profile @refetchable(queryName: "ProfileQueriesRootRefreshQuery") {
    id
    createdAt
    info {
      ...ProfileFragments_info
    }
    certificateInfos {
      ...CertificateFragmentsCertificateInfo
    }
    seaServices {
      ...ServiceFragmentsListPlural_profile
    }
    educations {
      end
      id
      ...EducationFragments
    }
    seaServicesHighlighted {
      ...ServiceFragmentsListPlural_profile
    }
    appraisals {
      ...AppraisalFragmentsListPlural_profile
    }
    membership {
      ...ProfileFragmentsOrganizationPlural_profile
    }
    ...ProfileFragmentsAvatarInfo
    seaServicesAddedCount
    notificationPreference {
      id
      jobOpening
      tipsToImprove
    }
    generalSetting {
      ...ProfileFragmentsGeneralSettings
    }
  }
`;

export const PROFILE_SEARCH_INFO = graphql`
  fragment ProfileFragments_SearchDropDown_info on ProfileInfo {
    id
    primaryDuty {
      id
      name
    }
    firstName
    lastName
    avatarUrl
  }
`;
export const PROFILE_SEARCH_DROP_DOWN_FRAGMENT = graphql`
  fragment ProfileFragmentsSearchDropDown on Profile {
    id
    info {
      ...ProfileFragments_SearchDropDown_info
    }

    membership {
      ...ProfileFragmentsOrganizationPlural_profile
    }
  }
`;

export const CREW_PROFILE_FRAGMENT = graphql`
  fragment ProfileFragmentsCrewProfile on Profile {
    id
    createdAt
    info {
      ...ProfileFragments_info
    }
    certificateInfos {
      ...CertificateFragmentsCertificateInfo
    }
    seaServices {
      ...ServiceFragmentsListPlural_profile
    }
    educations {
      id
      ...EducationFragments
    }
    seaServicesHighlighted {
      ...ServiceFragmentsListPlural_profile
    }
    seaServicesHighlighted {
      ...ServiceFragmentsListPlural_profile
    }
    appraisals {
      ...AppraisalFragmentsListWithoutDocumentPlural_profile
    }
    membership {
      ...ProfileFragmentsOrganizationPlural_profile
    }
    generalSetting {
      ...ProfileFragmentsGeneralSettings
    }
    ...ProfileFragmentsAvatarInfo
    seaServicesAddedCount
  }
`;

export const PROFILE_INFO_FRAGMENT = graphql`
  fragment ProfileFragments_info on ProfileInfo {
    id
    firstName
    lastName
    bio
    birthDate
    avatarUrl
    primaryDuty {
      id
      ...ServiceFragmentsOneDuty
    }
    owner {
      id
      createdAt
    }
    country {
      ...CountryFragments_info
    }
    isHidden
    yearsOfExperience
  }
`;

export const PROFILE_ORGANIZATION_FRAGMENT = graphql`
  fragment ProfileFragmentsOrganizationPlural_profile on OrganizationMember @relay(plural: true) {
    id
    createdAt
    role
    organization {
      id
      city
      country {
        id
        isoCode
        name
      }
      name
      description
      avatarUrl
      size
    }
  }
`;

export const PROFILE_SCORE_FRAGMENT = graphql`
  fragment ProfileFragmentsScoreSubscription on ProfileScore {
    id
    details
    summary
  }
`;

export const EMPLOYER_CREW_POOLS_FRAGMENT = graphql`
  fragment ProfileFragmentsEmployerCrewPools on CrewPool {
    name
    id
    kind
    Organization {
      id
    }
    CreatedAt
    UpdatedAt
  }
`;

export const EMPLOYER_CREW_POOL_STATS_FRAGMENT = graphql`
  fragment ProfileFragmentsEmployerCrewPoolStats on CrewPoolStat {
    availableCrewCount
    totalCrewCount
    crewPoolAvatars
  }
`;

export const ORGANIZATION_MEMBER_INFO_FRAGMENT = graphql`
  fragment ProfileFragmentsOrganizationMemberInfo on OrganizationMember {
    id
    profile {
      id
      educations {
        id
      }
      seaServices {
        vessel {
          imo
        }
      }
      certificateInfos {
        id
      }
      info {
        primaryDuty {
          name
        }
        country {
          name
          isoCode
        }
        birthDate
        avatarUrl
        firstName
        lastName
      }
    }
    organization {
      id
      avatarUrl
      name
    }
    role
  }
`;

export const PROFILE_ORGANIZATION_MEMBER_INVITATIONS_FRAGMENT = graphql`
  fragment ProfileFragmentsOrganizationMemberInvitation on OrganizationMember {
    id
    role
    organization {
      id
      avatarUrl
      name
    }
    profile {
      id
      info {
        id
        avatarUrl
        firstName
        lastName
      }
    }
  }
`;
