import React from 'react';

const NoVacanciesIcon: React.FC = () => {
  return (
    <svg width="55" height="51" viewBox="0 0 55 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2156 35.0566C11.2156 35.0566 11.467 38.1343 11.5255 39.1048C11.6034 40.4063 12.1063 41.8602 12.9457 42.8696C14.1304 44.3004 15.5258 44.8051 17.3887 44.8086C19.5792 44.8122 35.5079 44.8122 37.6984 44.8086C39.5613 44.8051 40.9567 44.3004 42.1414 42.8696C42.9808 41.8602 43.4837 40.4063 43.5634 39.1048C43.6201 38.1343 43.8715 35.0566 43.8715 35.0566"
        stroke="#B3BBC9"
        strokeWidth="2.65625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2949 17.0885V16.4315C21.2949 14.2711 23.0445 12.5215 25.2049 12.5215H29.7772C31.9359 12.5215 33.6872 14.2711 33.6872 16.4315L33.689 17.0885"
        stroke="#B3BBC9"
        strokeWidth="2.65625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M27.4911 37.186V34.8945" stroke="#B3BBC9" strokeWidth="2.65625" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1196 22.5051V28.6445C14.5161 30.8846 18.5855 32.4536 23.0515 33.0752C23.5863 31.1255 25.3447 29.6964 27.4821 29.6964C29.5859 29.6964 31.3797 31.1255 31.8791 33.0929C36.3629 32.4713 40.4482 30.9023 43.8623 28.6445V22.5051C43.8623 19.5053 41.4487 17.0898 38.4489 17.0898H16.5508C13.551 17.0898 11.1196 19.5053 11.1196 22.5051Z"
        stroke="#B3BBC9"
        strokeWidth="2.65625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.94346 4.9413C2.79399 4.15516 3.68938 3.7605 4.62963 3.75731C5.57822 3.74642 6.42632 4.14539 7.17392 4.95422C7.95235 5.79641 8.27127 6.67676 8.13068 7.59529C8.01732 8.33587 7.66237 9.04344 7.06582 9.71801C6.81579 10.0007 6.78377 10.4259 7.02561 10.7157V10.7157C7.26745 11.0055 7.23972 11.4339 6.96256 11.6901L6.84392 11.7998C6.49561 12.1217 5.95462 12.1091 5.62175 11.7712L4.73584 10.8719C4.52005 10.6529 4.52834 10.2987 4.75414 10.09V10.09C5.56297 9.34239 6.07983 8.66365 6.3047 8.05378C6.53791 7.43621 6.37321 6.82306 5.81058 6.21435C5.40209 5.77241 4.953 5.54583 4.46329 5.5346C3.98191 5.51566 3.51609 5.71429 3.06581 6.13048C2.61553 6.54667 2.3729 6.99514 2.33792 7.47588C2.32301 7.68078 2.34768 7.88141 2.41194 8.07775C2.56846 8.55605 2.58702 9.1243 2.21745 9.4659V9.4659C1.84788 9.80749 1.25687 9.79049 1.00808 9.35303C0.841937 9.0609 0.722239 8.75453 0.648982 8.43393C0.51188 7.83393 0.551598 7.23285 0.768133 6.63068C0.993007 6.02081 1.38478 5.45769 1.94346 4.9413ZM9.3743 14.5867C9.11581 14.8256 8.81808 14.9384 8.48112 14.9252C8.14416 14.9119 7.85622 14.7761 7.6173 14.5176C7.37837 14.2591 7.26554 13.9614 7.27879 13.6244C7.29205 13.2874 7.42792 12.9995 7.68642 12.7606C7.93657 12.5293 8.23013 12.4204 8.56709 12.4336C8.90405 12.4469 9.19199 12.5828 9.43092 12.8412C9.66984 13.0997 9.78267 13.3975 9.76942 13.7344C9.75616 14.0714 9.62446 14.3555 9.3743 14.5867Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <path
        d="M6.23347 8.02689L6.23327 8.02744C6.0145 8.62076 5.50782 9.28969 4.70246 10.0341C4.44509 10.272 4.43564 10.6757 4.6816 10.9253L5.56751 11.8246C5.92923 12.1918 6.5171 12.2055 6.8956 11.8557L7.01423 11.746C7.32118 11.4623 7.35188 10.9878 7.08406 10.6669C6.86938 10.4097 6.89444 10.0267 7.12285 9.76844C7.72711 9.08516 8.08995 8.36453 8.20593 7.60681C8.35085 6.66004 8.01993 5.75736 7.22983 4.90254C6.47028 4.08078 5.6026 3.67009 4.62906 3.68118C3.66568 3.68453 2.75282 4.08954 1.89178 4.88539C1.32578 5.40855 0.926387 5.98142 0.696701 6.60434L0.696698 6.60434L0.696491 6.60492C0.475234 7.22021 0.434283 7.83611 0.574761 8.45089C0.64971 8.7789 0.772163 9.09221 0.941898 9.39067C1.22131 9.88197 1.87367 9.88733 2.26913 9.52181C2.6732 9.14832 2.64315 8.53947 2.4843 8.05407C2.4231 7.86709 2.39967 7.67638 2.41385 7.4814C2.44708 7.02476 2.67738 6.59318 3.11749 6.18639C3.55719 5.77998 4.00422 5.59273 4.46029 5.61067L4.46029 5.61068L4.46154 5.61071C4.92609 5.62137 5.35677 5.83554 5.75467 6.26603C6.3037 6.86003 6.45372 7.44365 6.23347 8.02689ZM8.47813 15.0013C8.83623 15.0154 9.15338 14.8946 9.42598 14.6426C9.69079 14.3978 9.83143 14.0948 9.84549 13.7374C9.85958 13.3793 9.73879 13.0622 9.48682 12.7896C9.23486 12.517 8.92818 12.3716 8.57008 12.3575C8.21271 12.3435 7.89955 12.4599 7.63474 12.7047C7.36214 12.9566 7.21681 13.2633 7.20272 13.6214C7.18863 13.9795 7.30943 14.2967 7.56139 14.5692C7.81335 14.8418 8.12003 14.9872 8.47813 15.0013Z"
        stroke="#082051"
        strokeOpacity="0.3"
        strokeWidth="0.152268"
      />
      <path
        d="M52.2855 0.954503C53.4165 1.616 54.1386 2.45502 54.4519 3.47157C54.7762 4.49461 54.6238 5.54392 53.9947 6.61951C53.3397 7.73945 52.492 8.37464 51.4516 8.5251C50.6127 8.64641 49.73 8.49537 48.8034 8.07199C48.4151 7.89454 47.9443 7.99997 47.7104 8.35717V8.35717C47.4765 8.71437 47.0036 8.82553 46.6351 8.60997L46.4773 8.5177C46.0141 8.2468 45.8496 7.65714 46.1056 7.18556L46.787 5.9305C46.9529 5.62479 47.339 5.51708 47.6392 5.6927V5.6927C48.7148 6.32176 49.6197 6.65752 50.3538 6.69997C51.099 6.7489 51.7083 6.36863 52.1818 5.55917C52.5255 4.97148 52.6227 4.41079 52.4736 3.87709C52.3355 3.34988 51.967 2.91117 51.3682 2.56097C50.7695 2.21077 50.2042 2.09593 49.6724 2.21646C49.4457 2.26783 49.2367 2.36063 49.0454 2.49485C48.5793 2.82184 47.9704 3.02914 47.4789 2.7417V2.7417C46.9875 2.45427 46.8112 1.80904 47.2026 1.39566C47.4641 1.1196 47.7562 0.889121 48.079 0.704214C48.6832 0.358156 49.3469 0.203108 50.0699 0.239069C50.804 0.281514 51.5426 0.519992 52.2855 0.954503ZM44.2948 12.1744C43.951 11.9734 43.7308 11.6883 43.6341 11.3193C43.5375 10.9502 43.5897 10.5938 43.7907 10.2501C43.9918 9.90636 44.2768 9.68615 44.6458 9.58949C45.0149 9.49282 45.3713 9.545 45.715 9.74605C46.0477 9.9406 46.2623 10.2224 46.359 10.5915C46.4557 10.9605 46.4035 11.3169 46.2025 11.6606C46.0014 12.0044 45.7164 12.2246 45.3473 12.3213C44.9783 12.4179 44.6274 12.369 44.2948 12.1744Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <path
        d="M50.3594 6.61402L50.3588 6.61398C49.6446 6.57268 48.7537 6.24471 47.6827 5.61835C47.3405 5.41817 46.9005 5.54095 46.7113 5.8894L46.0299 7.14446C45.7517 7.65691 45.9305 8.29767 46.4338 8.59205L46.5916 8.68432C46.9997 8.92304 47.5234 8.79994 47.7825 8.40436C47.9901 8.08728 48.4129 7.98823 48.7676 8.15033C49.7062 8.57919 50.6057 8.73446 51.4639 8.61034C52.5363 8.45526 53.4042 7.79972 54.0691 6.66299C54.7082 5.57022 54.8668 4.49585 54.5341 3.44588C54.213 2.40435 53.474 1.54982 52.329 0.880154C51.5763 0.439947 50.8247 0.196436 50.0749 0.153082L50.0749 0.153079L50.0742 0.153044C49.3354 0.116299 48.6553 0.274894 48.0362 0.629475C47.7059 0.818654 47.4072 1.05441 47.1401 1.33643C46.7004 1.80069 46.9096 2.50849 47.4354 2.81605C47.9728 3.13032 48.6218 2.8972 49.0948 2.56536C49.277 2.43753 49.4757 2.34934 49.6914 2.30046C50.1965 2.18597 50.7395 2.29303 51.3248 2.63532C51.9095 2.97729 52.2594 3.39942 52.3902 3.89892L52.3902 3.89892L52.3906 3.90028C52.5321 4.40656 52.4422 4.94323 52.1074 5.51569C51.6454 6.30559 51.0632 6.66023 50.3594 6.61402ZM43.5508 11.3411C43.6536 11.7333 43.8888 12.0368 44.2513 12.2488C44.6034 12.4547 44.9777 12.5071 45.3691 12.4046C45.7613 12.3018 46.0648 12.0666 46.2768 11.7041C46.4888 11.3416 46.5451 10.9618 46.4423 10.5696C46.3398 10.1782 46.1107 9.87766 45.7585 9.6717C45.396 9.45969 45.0162 9.40343 44.624 9.50617C44.2318 9.6089 43.9284 9.84412 43.7164 10.2066C43.5043 10.5691 43.4481 10.9489 43.5508 11.3411Z"
        stroke="#082051"
        strokeOpacity="0.3"
        strokeWidth="0.172261"
      />
    </svg>
  );
};

export default NoVacanciesIcon;
