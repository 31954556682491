import { useCallback, useState } from 'react';
import { useMutation } from 'react-relay';
// routing
import { RoutePaths } from 'app/routing';
// generated
import { ProfileMutationsShareMutation } from 'schemas/profile/__generated__/ProfileMutationsShareMutation.graphql';
// schemas
import { CREATE_PROFILE_SHARE } from 'schemas/profile/ProfileMutations';

const useCreateProfileShareLink = (profileId?: string, callback?: () => void) => {
  const [profileShareLink, setProfileShareLink] = useState<string>('');

  const [commitCreateProfileShare, loadingCreateProfileShare] = useMutation<ProfileMutationsShareMutation>(CREATE_PROFILE_SHARE);

  const handleCreateProfileShare = useCallback(() => {
    if (!profileId) {
      return;
    }
    commitCreateProfileShare({
      variables: { data: { ownerId: `${profileId}` } },
      onCompleted: result => {
        if (callback) {
          callback();
        }
        setProfileShareLink(`${document.location.origin}${RoutePaths.ProfileBase}/crew/${profileId}/?t=${result.createProfileShare.uuid}&k=e`);
        window.analytics?.track('wui-created-share-link', {
          label: 'Link for share profile has been created',
        });
      },
    });
  }, [profileId, commitCreateProfileShare, callback]);

  return {
    handleCreateProfileShare,
    loadingCreateProfileShare,
    profileShareLink,
  };
};
export default useCreateProfileShareLink;
