import Skeleton from 'react-loading-skeleton';

const ListOfEmployersItemSkeleton = () => {
  return (
    <div className="flex flex-col sm:flex-row sm:items-center p-4 border border-specialGray-01 rounded-2xl w-full">
      <div className="flex w-full sm:w-4/5 gap-x-4">
        <Skeleton width={56} height={56} borderRadius={6} />
        <div className="flex flex-col justify-between w-[calc(100%_-_72px)]">
          <Skeleton width={300} height={18} containerClassName="hidden sm:inline" />
          <Skeleton height={18} containerClassName="inline sm:hidden" />
          <div className="hidden sm:flex w-full flex-wrap gap-x-[18px]">
            <Skeleton width={100} height={12} />
            <Skeleton width={130} height={12} />
          </div>
        </div>
      </div>
      <div className="sm:hidden flex w-full flex-wrap gap-x-[18px]">
        <Skeleton width={100} height={12} />
        <Skeleton width={130} height={12} />
      </div>
    </div>
  );
};

export default ListOfEmployersItemSkeleton;
