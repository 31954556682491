// packages
import { useCallback, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-relay';
import { useSetRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
// routes
import { RoutePaths } from 'app/routing';
// schemas
import { ALLOW_ASSUMING_PROFILE_BY_ID } from 'schemas/AdminAssume/AdminAssumeMutations';
// generated
import { AdminAssumeMutationsAllowByIdMutation } from 'schemas/AdminAssume/__generated__/AdminAssumeMutationsAllowByIdMutation.graphql';
// components
import SpinnerIcon from 'icons/SpinnerIcon';
// recoil
import { personalAccountSelector } from 'recoil/Profile/personalAccount/selectors/personalAccountSelector';
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';
// components
import { Navbar } from 'app/Navbar/Navbar';
import AssumeProfileForm from './AssumeProfileForm';

const AssumeComponent = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [assumeProfileById] = useMutation<AdminAssumeMutationsAllowByIdMutation>(ALLOW_ASSUMING_PROFILE_BY_ID);

  const setIsPersonalAccount = useSetRecoilState(personalAccountSelector);
  const setCurrentOrganisation = useSetRecoilState(currentOrganisationSelector);

  const onCompleted = useCallback(() => {
    setCurrentOrganisation({ isSelected: false, organisationId: '', profileId: '' });
    setIsPersonalAccount({ personalAccountIsSelected: true });
    navigate(RoutePaths.AuthLogin);
    Auth.signOut().then();
  }, [navigate, setCurrentOrganisation, setIsPersonalAccount]);

  const onError = useCallback(
    (error: Error) => {
      if (error.message.includes('profile_oid_claims not found')) {
        setErrorMessage(intl.formatMessage({ id: 'assume_profile_not_found_error' }));
        return;
      }
      setErrorMessage(error.message);
    },
    [intl],
  );

  useEffect(() => {
    if (params.userId) {
      assumeProfileById({
        variables: {
          id: params.userId,
        },
        onCompleted,
        onError,
      });
    }
  }, [assumeProfileById, onCompleted, onError, params]);

  return (
    <>
      <Navbar />
      <div className="flex flex-col justify-center items-center pt-64 px-10">
        {errorMessage ? (
          <div className="flex flex-col items-center w-full sm:w-96 gap-y-10">
            <span className="w-[265px] text-lg text-center font-medium">{errorMessage}</span>
            <AssumeProfileForm />
          </div>
        ) : (
          <SpinnerIcon />
        )}
      </div>
    </>
  );
};

export default AssumeComponent;
