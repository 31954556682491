// packages
import React from 'react';
import { useIntl } from 'react-intl';
import { TrashIcon } from '@heroicons/react/24/outline';
import DangerConfirmIcon from 'system/DangerConfirmIcon';
// components
import { Button } from './Button';
// models
import { ConfirmModalBodyProps } from 'models/modelsOfComponents';

export const ConfirmModalBody: React.FC<ConfirmModalBodyProps> = ({
  title,
  textOne,
  textTwo,
  onClick,
  onCancel,
  buttonLabel,
  typeButtonConfirm,
  buttonWithIcon = true,
  buttonCancelLabel = { id: 'cancel' },
}) => {
  const intl = useIntl();

  return (
    <div className="flex flex-col items-center">
      {typeButtonConfirm === 'red-primary' && <DangerConfirmIcon />}
      <h2 className="font-semibold text-specialGray-1 text-lg mt-5 text-center mb-3">{intl.formatMessage(title)}</h2>

      {textOne && <p className="text-center text-specialGray-075 text-sm mb-1">{intl.formatMessage(textOne)}</p>}
      {textTwo && <p className="text-center text-specialGray-075 text-sm">{intl.formatMessage(textTwo)}</p>}

      <section className="flex mt-8 justify-between w-full space-x-5">
        <Button label={buttonCancelLabel} buttonType="white-primary" onClick={onCancel} fullSize />
        <Button
          label={buttonLabel}
          buttonType={typeButtonConfirm}
          onClick={event => onClick(event)}
          prefixIcon={typeButtonConfirm === 'red-primary' && buttonWithIcon ? TrashIcon : undefined}
          fullSize={true}
        />
      </section>
    </div>
  );
};
