// packages
import { atom } from 'recoil';
// enums
import { VACANCY_STATUS } from 'models/enums';
// types
import { fetchDashboardVacanciesByParamsType } from '../../types';

export const fetchDashboardVacanciesByParamsAtom = atom<fetchDashboardVacanciesByParamsType>({
  key: 'sortingByStatusAtom',
  default: {
    filterByStatus: VACANCY_STATUS.OPEN,
  },
});
