import { useLocation } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { Fragment, useRef } from 'react';
// components
import CommercialAvatar from 'app/Avatars/CommercialAvatar/CommercialAvatar';
// hooks
import useOnClickOutside from 'hooks/useOutsideAlerter';
import { useToggleShowWindow } from 'hooks/useToggleShowModal';
// generated
import { ProfileFragmentsOrganizationPlural_profile$data } from 'schemas/profile/__generated__/ProfileFragmentsOrganizationPlural_profile.graphql';

const OrganizationsMembershipInfoDropDown = ({ orgMembership }: { orgMembership: ProfileFragmentsOrganizationPlural_profile$data }) => {
  const { state } = useLocation();

  const { toggleShowWindow, showWindow } = useToggleShowWindow();
  const orgContainerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(orgContainerRef, toggleShowWindow(false));

  return (
    <div
      ref={orgContainerRef}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        toggleShowWindow(!showWindow)();
      }}
      className="relative flex h-[22px] w-[22px] text-xs rounded-md text-darkBlue bg-specialGray-015 items-center justify-center cursor-pointer"
    >
      &#43;{orgMembership.length}
      <Transition
        show={state?.storybookOpenedOrgMembershipDropDown || showWindow}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <ul className="absolute top-8 sm:right-0 bg-white rounded-[10px] border border-gray-200 z-10 text-xs sm:text-sm max-w-[350px] cursor-auto">
          {orgMembership.map(organizationItem => (
            <li key={organizationItem.id} className="flex gap-x-3 min-h-[40px] px-3 items-center text-start border-b border-b-gray-200 last:border-b-0">
              <CommercialAvatar avatarUrl={organizationItem?.organization?.avatarUrl} size="w-6 h-6" /> {organizationItem.organization.name}
            </li>
          ))}
        </ul>
      </Transition>
    </div>
  );
};

export default OrganizationsMembershipInfoDropDown;
