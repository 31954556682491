/**
 * @generated SignedSource<<e11f64ff159cd74dbf5dbf18a3a31dca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type AppraisalQueriesListQuery$variables = {
  after?: any | null;
  first?: number | null;
  ownerId: string;
  skip: boolean;
};
export type AppraisalQueriesListQuery$data = {
  readonly appraisals?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly 'id': string;
        readonly 'seaService': {
          readonly end: String | null;
          readonly id: string;
          readonly start: String;
        };
        readonly ' $fragmentSpreads': FragmentRefs<'AppraisalFragmentsOneAppraisal'>;
      } | null;
    } | null> | null;
  } | null;
};
export type AppraisalQueriesListQuery = {
  response: AppraisalQueriesListQuery$data;
  variables: AppraisalQueriesListQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'after',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'first',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'ownerId',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'skip',
    },
    v4 = {
      kind: 'Variable',
      name: 'ownerId',
      variableName: 'ownerId',
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'start',
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'end',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: '__typename',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cursor',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      concreteType: 'PageInfo',
      kind: 'LinkedField',
      name: 'pageInfo',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'endCursor',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'hasNextPage',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v11 = [
      {
        kind: 'Variable',
        name: 'after',
        variableName: 'after',
      },
      {
        kind: 'Variable',
        name: 'first',
        variableName: 'first',
      },
      v4 /*: any*/,
    ],
    v12 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'AppraisalQueriesListQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: 'appraisals',
              args: [v4 /*: any*/],
              concreteType: 'AppraisalConnection',
              kind: 'LinkedField',
              name: '__AppraisalListFromProfile_appraisals_connection',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'AppraisalEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Appraisal',
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [
                        v5 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: 'SeaService',
                          kind: 'LinkedField',
                          name: 'seaService',
                          plural: false,
                          selections: [v5 /*: any*/, v6 /*: any*/, v7 /*: any*/],
                          storageKey: null,
                        },
                        {
                          args: null,
                          kind: 'FragmentSpread',
                          name: 'AppraisalFragmentsOneAppraisal',
                        },
                        v8 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    v9 /*: any*/,
                  ],
                  storageKey: null,
                },
                v10 /*: any*/,
              ],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v2 /*: any*/, v0 /*: any*/, v1 /*: any*/, v3 /*: any*/],
      kind: 'Operation',
      name: 'AppraisalQueriesListQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v11 /*: any*/,
              concreteType: 'AppraisalConnection',
              kind: 'LinkedField',
              name: 'appraisals',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'AppraisalEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Appraisal',
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [
                        v5 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: 'SeaService',
                          kind: 'LinkedField',
                          name: 'seaService',
                          plural: false,
                          selections: [
                            v5 /*: any*/,
                            v6 /*: any*/,
                            v7 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              concreteType: 'Duty',
                              kind: 'LinkedField',
                              name: 'duty',
                              plural: false,
                              selections: [v5 /*: any*/, v12 /*: any*/],
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              concreteType: 'Vessel',
                              kind: 'LinkedField',
                              name: 'vessel',
                              plural: false,
                              selections: [
                                v12 /*: any*/,
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'image',
                                  storageKey: null,
                                },
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'cnISO',
                                  storageKey: null,
                                },
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'countryName',
                                  storageKey: null,
                                },
                                v5 /*: any*/,
                              ],
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'review',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Document',
                          kind: 'LinkedField',
                          name: 'document',
                          plural: false,
                          selections: [
                            v5 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'urls',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'xlarge_urls',
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Profile',
                          kind: 'LinkedField',
                          name: 'owner',
                          plural: false,
                          selections: [v5 /*: any*/],
                          storageKey: null,
                        },
                        v8 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    v9 /*: any*/,
                  ],
                  storageKey: null,
                },
                v10 /*: any*/,
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: v11 /*: any*/,
              filters: ['ownerId'],
              handle: 'connection',
              key: 'AppraisalListFromProfile_appraisals',
              kind: 'LinkedHandle',
              name: 'appraisals',
            },
          ],
        },
      ],
    },
    params: {
      cacheID: 'e7999ac05098483a36d72675ce1f90a8',
      id: null,
      metadata: {
        connection: [
          {
            count: 'first',
            cursor: 'after',
            direction: 'forward',
            path: ['appraisals'],
          },
        ],
      },
      name: 'AppraisalQueriesListQuery',
      operationKind: 'query',
      text: 'query AppraisalQueriesListQuery(\n  $ownerId: ID!\n  $after: Cursor\n  $first: Int\n  $skip: Boolean!\n) {\n  appraisals(ownerId: $ownerId, first: $first, after: $after) @skip(if: $skip) {\n    edges {\n      node {\n        id\n        seaService {\n          id\n          start\n          end\n        }\n        ...AppraisalFragmentsOneAppraisal\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment AppraisalFragmentsOneAppraisal on Appraisal {\n  id\n  review\n  document {\n    id\n    urls\n    xlarge_urls\n  }\n  owner {\n    id\n  }\n  seaService {\n    id\n    start\n    end\n    duty {\n      id\n      name\n    }\n    vessel {\n      name\n      image\n      cnISO\n      countryName\n      id\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'ebde38d59ff7cc62f5a95d42b5777187';

export default node;
