import React from 'react';
import Skeleton from 'react-loading-skeleton';
import TextInputSkeleton from '../components/TextInputSkeleton';
import VesselInformationSkeleton from './VesselInformationSkeleton';
import RangeDateInputSkeleton from 'system/skeletons/components/RangeDateInputSkeleton';
import CheckboxSkeleton from '../components/CheckboxSkeleton';

const ServiceFormSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col w-full space-y-6">
      <RangeDateInputSkeleton />
      <CheckboxSkeleton widthText={300} />
      <div className="flex w-full">
        <div className="w-3/4 mr-5">
          <TextInputSkeleton widthTitle={40} borderRadius="0.375rem" heightInput={38} />
        </div>
        <div className="w-1/4">
          <TextInputSkeleton widthTitle={80} borderRadius="0.375rem" heightInput={38} />
        </div>
      </div>
      <TextInputSkeleton widthTitle={180} borderRadius="0.375rem" heightInput={48} />
      <div className="block">
        <Skeleton width={190} height={24} className="mb-3" />
        <Skeleton width="100%" />
        <Skeleton width="68%" />
      </div>
      <VesselInformationSkeleton />
      <div className="flex justify-end">
        <Skeleton width={140} height={34} borderRadius="0.375rem" />
        <Skeleton width={180} height={34} className="sm:ml-2 mt-5 sm:mt-0" borderRadius="0.375rem" />
      </div>
    </div>
  );
};

export default ServiceFormSkeleton;
