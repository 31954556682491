import graphql from 'babel-plugin-relay/macro';

export const GET_APPRAISAL_WITH_DOCUMENT_PLURAL_FRAGMENT = graphql`
  fragment AppraisalFragmentsListPlural_profile on Appraisal @relay(plural: true) {
    seaService {
      end
    }
    ...AppraisalFragmentsOneAppraisal
  }
`;

export const GET_APPRAISAL_WITHOUT_DOCUMENT_PLURAL_FRAGMENT = graphql`
  fragment AppraisalFragmentsListWithoutDocumentPlural_profile on Appraisal @relay(plural: true) {
    ...AppraisalFragmentsOneAppraisalWithoutDocument
  }
`;

export const GET_APPRAISAL_WITHOUT_DOCUMENT_FRAGMENT = graphql`
  fragment AppraisalFragmentsOneAppraisalWithoutDocument on Appraisal {
    id
    review
    owner {
      id
    }
    seaService {
      id
      start
      end
      vessel {
        name
        image
        cnISO
        countryName
      }
      duty {
        id
        name
      }
    }
  }
`;

export const GET_APPRAISAL_FRAGMENT = graphql`
  fragment AppraisalFragmentsOneAppraisal on Appraisal {
    id
    review
    document {
      id
      urls
      xlarge_urls
    }
    owner {
      id
    }
    seaService {
      id
      start
      end
      duty {
        id
        name
      }
      vessel {
        name
        image
        cnISO
        countryName
      }
    }
  }
`;
