/**
 * @generated SignedSource<<9904774ca79e4eafdd24380e787b22aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type VacancyQueriesGetHiringAreaListQuery$variables = {
  skip: boolean;
};
export type VacancyQueriesGetHiringAreaListQuery$data = {
  readonly hiringAreas?: {
    readonly edges: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly perma: string;
    }>;
  } | null;
};
export type VacancyQueriesGetHiringAreaListQuery = {
  response: VacancyQueriesGetHiringAreaListQuery$data;
  variables: VacancyQueriesGetHiringAreaListQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'skip',
      },
    ],
    v1 = [
      {
        condition: 'skip',
        kind: 'Condition',
        passingValue: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'HiringAreaList',
            kind: 'LinkedField',
            name: 'hiringAreas',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'HiringArea',
                kind: 'LinkedField',
                name: 'edges',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'id',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'name',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'perma',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'VacancyQueriesGetHiringAreaListQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'VacancyQueriesGetHiringAreaListQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '93b75ffe4116f673c1d1de2c166ba320',
      id: null,
      metadata: {},
      name: 'VacancyQueriesGetHiringAreaListQuery',
      operationKind: 'query',
      text: 'query VacancyQueriesGetHiringAreaListQuery(\n  $skip: Boolean!\n) {\n  hiringAreas @skip(if: $skip) {\n    edges {\n      id\n      name\n      perma\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '0b5c8ec22b596847abcdd789eaee100f';

export default node;
