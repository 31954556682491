const BlueSpinnerIcon = () => {
  return (
    <div className="h-full flex justify-center items-center">
      <svg xmlns="http://www.w3.org/2000/svg" className="animate-spin w-8 h-8 text-gray-600 mx-auto" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" stroke="#2A6FF5" strokeWidth="7" r="36" strokeDasharray="169.64600329384882 58.548667764616276" />
      </svg>
    </div>
  );
};

export default BlueSpinnerIcon;
