const GlassDiscoveryPurple = () => {
  return (
    <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_9266_78834)">
        <path
          d="M50.12 33.7235C50.12 42.96 42.6328 50.4471 33.3964 50.4471C24.16 50.4471 16.6729 42.96 16.6729 33.7235C16.6729 24.4888 24.16 17 33.3964 17C42.6328 17 50.12 24.4888 50.12 33.7235Z"
          fill="url(#paint0_linear_9266_78834)"
        />
      </g>
      <g filter="url(#filter1_f_9266_78834)">
        <path
          d="M33.4399 22.8398C27.5014 22.8398 22.6816 27.6606 22.6816 33.5981C22.6816 39.5378 27.5014 44.3565 33.4399 44.3565C39.3893 44.3565 44.1983 39.5378 44.1983 33.5981C44.1983 27.6606 39.3893 22.8398 33.4399 22.8398V22.8398Z"
          fill="#B822FF"
        />
      </g>
      <g filter="url(#filter2_b_9266_78834)">
        <path
          d="M56.6727 40.2782C56.6727 49.5147 49.1856 57.0018 39.9491 57.0018C30.7127 57.0018 23.2256 49.5147 23.2256 40.2782C23.2256 31.0435 30.7127 23.5547 39.9491 23.5547C49.1856 23.5547 56.6727 31.0435 56.6727 40.2782Z"
          fill="#D095FF"
          fillOpacity="0.4"
        />
        <path
          d="M39.9491 57.5018C49.4617 57.5018 57.1727 49.7908 57.1727 40.2782C57.1727 30.7674 49.4617 23.0547 39.9491 23.0547C30.4365 23.0547 22.7256 30.7674 22.7256 40.2782C22.7256 49.7908 30.4366 57.5018 39.9491 57.5018Z"
          stroke="url(#paint1_linear_9266_78834)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#filter3_bd_9266_78834)">
        <mask id="path-5Inside-1_9266_78834" fill="white">
          <path d="M46.4046 34.3564L43.6954 42.9189C43.5951 43.2701 43.3108 43.5544 42.9596 43.6564L34.4306 46.3305C33.862 46.5161 33.3101 45.9626 33.4941 45.394L36.1698 36.8148C36.2702 36.4636 36.5545 36.196 36.9057 36.079L45.4681 33.4032C46.0534 33.2192 46.5886 33.7711 46.4046 34.3564" />
        </mask>
        <path
          d="M46.4046 34.3564L43.6954 42.9189C43.5951 43.2701 43.3108 43.5544 42.9596 43.6564L34.4306 46.3305C33.862 46.5161 33.3101 45.9626 33.4941 45.394L36.1698 36.8148C36.2702 36.4636 36.5545 36.196 36.9057 36.079L45.4681 33.4032C46.0534 33.2192 46.5886 33.7711 46.4046 34.3564"
          fill="url(#paint2_linear_9266_78834)"
        />
        <path
          d="M43.6954 42.9189L43.3139 42.7982L43.3108 42.809L43.6954 42.9189ZM42.9596 43.6564L42.848 43.2722L42.8399 43.2747L42.9596 43.6564ZM34.4306 46.3305L34.3109 45.9488L34.3064 45.9502L34.4306 46.3305ZM33.4941 45.394L33.8747 45.5171L33.8759 45.5131L33.4941 45.394ZM36.1698 36.8148L36.5518 36.9339L36.5544 36.9247L36.1698 36.8148ZM36.9057 36.079L36.7863 35.6971L36.7792 35.6995L36.9057 36.079ZM45.4681 33.4032L45.5874 33.785L45.588 33.7848L45.4681 33.4032ZM46.0233 34.2358L43.314 42.7982L44.0768 43.0396L46.786 34.4771L46.0233 34.2358ZM43.3108 42.809C43.2485 43.0271 43.0679 43.2084 42.848 43.2723L43.0712 44.0405C43.5537 43.9004 43.9417 43.5131 44.08 43.0288L43.3108 42.809ZM42.8399 43.2747L34.3109 45.9488L34.5502 46.7122L43.0792 44.0381L42.8399 43.2747ZM34.3064 45.9502C34.0507 46.0338 33.7906 45.7768 33.8746 45.5171L33.1135 45.2709C32.8296 46.1483 33.6733 46.9985 34.5547 46.7107L34.3064 45.9502ZM33.8759 45.5131L36.5517 36.9339L35.788 36.6957L33.1122 45.2749L33.8759 45.5131ZM36.5544 36.9247C36.6137 36.7173 36.7886 36.5396 37.0321 36.4584L36.7792 35.6995C36.3204 35.8524 35.9266 36.2099 35.7852 36.7049L36.5544 36.9247ZM37.025 36.4608L45.5874 33.785L45.3488 33.0214L36.7863 35.6972L37.025 36.4608ZM45.588 33.7848C45.8526 33.7016 46.114 33.9472 46.023 34.2365L46.7862 34.4764C47.0632 33.595 46.2543 32.7368 45.3482 33.0216L45.588 33.7848Z"
          fill="url(#paint3_linear_9266_78834)"
          mask="url(#path-5Inside-1_9266_78834)"
        />
      </g>
      <defs>
        <filter id="filter0_b_9266_78834" x="3.67285" y="4" width="59.4473" height="59.4453" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_9266_78834" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_9266_78834" result="shape" />
        </filter>
        <filter id="filter1_f_9266_78834" x="0.681641" y="0.839844" width="65.5166" height="65.5156" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="11" result="effect1_foregroundBlur_9266_78834" />
        </filter>
        <filter id="filter2_b_9266_78834" x="7.22559" y="7.55469" width="65.4473" height="65.4453" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_9266_78834" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_9266_78834" result="shape" />
        </filter>
        <filter id="filter3_bd_9266_78834" x="18.458" y="18.3672" width="42.9834" height="43" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_9266_78834" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.760078 0 0 0 0 0.368627 0 0 0 0 1 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_9266_78834" result="effect2_dropShadow_9266_78834" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_9266_78834" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_9266_78834" x1="53.9208" y1="11.9033" x2="15.0759" y2="38.1618" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BE7BEE" />
          <stop offset="0.0001" stopColor="#C98AF5" />
          <stop offset="1" stopColor="#9219E8" />
        </linearGradient>
        <linearGradient id="paint1_linear_9266_78834" x1="28.5498" y1="27.4513" x2="49.8855" y2="53.8761" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint2_linear_9266_78834" x1="40.1025" y1="35.6349" x2="37.7933" y2="45.6041" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint3_linear_9266_78834" x1="35.5248" y1="34.8817" x2="43.8195" y2="45.1419" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GlassDiscoveryPurple;
