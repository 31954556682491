// packages
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
//schemas
import { GET_ONE_DUTY } from 'schemas/services/ServiceFragments';
import { PROFILE_ORGANIZATION_FRAGMENT } from 'schemas/profile/ProfileFragments';
//generated
import { ServiceFragmentsOneDuty$key } from 'schemas/services/__generated__/ServiceFragmentsOneDuty.graphql';
import { ProfileFragmentsOrganizationPlural_profile$key } from 'schemas/profile/__generated__/ProfileFragmentsOrganizationPlural_profile.graphql';
//components
import CountryFlag from 'system/CountryFlag';
import ProfileAgeInfo from './ProfileAgeInfo';
import DoneSingleIcon from 'icons/DoneSingleIcon';
import ProfileBasicInformationItem from './ProfileBasicInformationItem';
import OrganizationsMembershipInfo from '../../OrganizationsMembershipInfo/OrganizationsMembershipInfo';
// types
import { ProfileBasicSummaryInfoProps } from '../types';
// hooks
import { useAuth } from 'authentication';
import { useGetYearsOfExperience } from 'hooks/crewProfileHooks/useGetYearsOfExperience';

const ProfileBasicSummaryInfo = ({ profileInfoData, selfData, countryData }: ProfileBasicSummaryInfoProps) => {
  const intl = useIntl();
  const { identity } = useAuth();
  const profileOrganizations = useFragment<ProfileFragmentsOrganizationPlural_profile$key>(PROFILE_ORGANIZATION_FRAGMENT, selfData?.membership || null);

  const usersDuty = useFragment<ServiceFragmentsOneDuty$key>(GET_ONE_DUTY, profileInfoData?.primaryDuty || null);
  const { getYearsOfExperience } = useGetYearsOfExperience(profileInfoData?.yearsOfExperience);

  return (
    <section className="w-full sm:w-auto flex flex-col text-darkBlue text-sm items-center sm:items-start space-y-2">
      {usersDuty?.name ? (
        <span data-test-id="profile-primary-duty" className="flex sm:hidden font-medium mx-auto">
          {usersDuty?.name}
        </span>
      ) : null}
      {getYearsOfExperience ? (
        <div className="flex space-x-2">
          <span className="font-medium">{intl.formatMessage({ id: 'profile_years_of_work_experience' })}&#58;</span>
          &nbsp;
          <span data-test-id="profile-experience" className="text-specialGray-075">
            {getYearsOfExperience.name}
          </span>
        </div>
      ) : null}
      <div className="flex justify-center md:justify-start w-full sm:gap-x-0 gap-x-4 whitespace-pre md:flex-row sm:flex-col flex-wrap ">
        <div className="flex sm:flex-row flex-col mr-0 flex-wrap md:[&>div]:mr-4 sm:[&>div]:mr-2 lg:[&>div]:mt-0 [&>div]:mt-0 sm:[&>div]:mt-2 sm:mb-2 items-center">
          {countryData?.isoCode && (
            <div className="flex space-x-1 items-center">
              <CountryFlag classes="h-[17px]" countryName={countryData.name} countryId={countryData.isoCode} />
              <span data-test-id="profile-country-name" className="font-medium">
                {countryData.name}
              </span>
            </div>
          )}
          {usersDuty?.name ? (
            <div data-test-id="profile-primary-duty" className="sm:flex hidden font-medium">
              {usersDuty?.name}
            </div>
          ) : null}
          <ProfileAgeInfo birthDate={profileInfoData?.birthDate} />

          <ProfileBasicInformationItem label={{ id: 'membership' }} classNameContainer="order-2 sm:order-1">
            <OrganizationsMembershipInfo profileOrganizations={profileOrganizations} profileId={`${identity?.profileId}`} />
          </ProfileBasicInformationItem>

          <ProfileBasicInformationItem popoverContent={profileInfoData?.bio} label={{ id: 'profile_bio' }} classNameContainer="order-1 sm:order-2">
            <span data-test-id="profile-bio" className="w-6 sm:w-auto">
              {profileInfoData?.bio ? <DoneSingleIcon /> : <span className="font-semibold">&#8208;</span>}
            </span>
          </ProfileBasicInformationItem>
        </div>
        <div className="flex flex-col sm:flex-row gap-y-1 sm:gap-y-0 sm:gap-x-2 md:gap-x-4 md:mb-2">
          <ProfileBasicInformationItem label={{ id: 'profile_sea_services' }}>
            <span className="w-4 sm:w-auto">{selfData.seaServices?.length}</span>
          </ProfileBasicInformationItem>

          <ProfileBasicInformationItem label={{ id: 'profile_certificates' }}>
            <span className="w-4 sm:w-auto">{selfData.certificateInfos?.length}</span>
          </ProfileBasicInformationItem>

          <ProfileBasicInformationItem label={{ id: 'profile_educationsTitle' }}>
            <span className="w-4 sm:w-auto">{selfData.educations?.length}</span>
          </ProfileBasicInformationItem>
        </div>
      </div>
    </section>
  );
};

export default ProfileBasicSummaryInfo;
