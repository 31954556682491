/**
 * @generated SignedSource<<9884c92c9ac804f3f3dddac5f8afc091>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type SalaryPeriod = 'DAILY' | 'MONTHLY' | 'WEEKLY' | '%future added value';
export type VacancyDuration = 'ONETERM' | 'PERMANENT' | 'TEMPORARY' | '%future added value';
export type VacancyStatus = 'CLOSED' | 'OPEN' | '%future added value';
import { FragmentRefs } from 'relay-runtime';
export type VacancyFragmentsForSearchFragment$data = {
  readonly 'createdAt': String;
  readonly 'currency': {
    readonly id: string;
    readonly name: string;
    readonly perma: string;
  } | null;
  readonly 'description': string | null;
  readonly 'duration': VacancyDuration | null;
  readonly 'duties': ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }> | null;
  readonly 'hiringArea': {
    readonly id: string;
    readonly name: string;
    readonly perma: string;
  } | null;
  readonly 'id': string;
  readonly 'organization': {
    readonly avatarUrl: string | null;
    readonly id: string;
  };
  readonly 'salaryFrom': number | null;
  readonly 'salaryPeriod': SalaryPeriod | null;
  readonly 'salaryTo': number | null;
  readonly 'startingDate': String | null;
  readonly 'status': VacancyStatus;
  readonly 'title': string;
  readonly 'vesselKind': {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly ' $fragmentType': 'VacancyFragmentsForSearchFragment';
};
export type VacancyFragmentsForSearchFragment$key = {
  readonly ' $data'?: VacancyFragmentsForSearchFragment$data;
  readonly ' $fragmentSpreads': FragmentRefs<'VacancyFragmentsForSearchFragment'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'perma',
      storageKey: null,
    },
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v3 = [v0 /*: any*/, v2 /*: any*/];
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: {
      refetch: {
        connection: null,
        fragmentPathInResult: ['node'],
        operation: require('./VacancyQueriesGetVacancyForSearchRefreshQuery.graphql'),
        identifierField: 'id',
      },
    },
    name: 'VacancyFragmentsForSearchFragment',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'createdAt',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'title',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'status',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Organization',
        kind: 'LinkedField',
        name: 'organization',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'avatarUrl',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'salaryPeriod',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'salaryFrom',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'salaryTo',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'description',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'HiringArea',
        kind: 'LinkedField',
        name: 'hiringArea',
        plural: false,
        selections: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Currency',
        kind: 'LinkedField',
        name: 'currency',
        plural: false,
        selections: [v0 /*: any*/, v2 /*: any*/, v1 /*: any*/],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Duty',
        kind: 'LinkedField',
        name: 'duties',
        plural: true,
        selections: v3 /*: any*/,
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'VesselKind',
        kind: 'LinkedField',
        name: 'vesselKind',
        plural: false,
        selections: v3 /*: any*/,
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'startingDate',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'duration',
        storageKey: null,
      },
    ],
    type: 'Vacancy',
    abstractKey: null,
  };
})();

(node as any).hash = '1b42cf1a404e691419b8ba0a18b530f0';

export default node;
