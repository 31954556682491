// packages
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { RoutePaths } from 'app/routing';
import { useMutation } from 'react-relay';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
// formHelper
import { switchStatusWithNode } from 'formHelpers/updaters/updatersOfJobOpening';
// components
import PublishIcon from 'icons/PublishIcon';
import ArrowDownTray from 'icons/ArrowDownTray';
import CloseButtonIcon from 'icons/CloseButtonIcon';
import EditIconWithUnderline from 'icons/EditIconWithUnderline';
import PaymentSubscriptionProLabel from 'app/ProfileEmployer/HireProduct/components/PaymentSubscriptionProLabel';
// models
import { VACANCY_STATUS } from 'models/enums';
import { DashboardControlButtonsProps } from '../../../types';
// generated
import { VacancyMutationsSwitchVacancyStatusMutation } from 'schemas/vacancy/__generated__/VacancyMutationsSwitchVacancyStatusMutation.graphql';
// schemas
import { SWITCH_VACANCY_STATUS } from 'schemas/vacancy/VacancyMutations';
// recoil
import { paymentSubscriptionModalAtom } from 'recoil/ContactInformation/atoms/paymentSubscriptionModalAtom';
import { RestrictedFromPublishJobAtom } from 'recoil/RestrictedFromPublishJob/atoms/RestrictedFromPublishJobAtom';
import { fetchDashboardVacanciesByOpenParamsSelector } from 'recoil/Organisation/Dashboard/selectors/fetchDashboardVacanciesByOpenParamsSelector';
import { fetchDashboardVacanciesByShowAllParamsSelector } from 'recoil/Organisation/Dashboard/selectors/fetchDashboardVacanciesByShowAllParamsSelector';

const ControlButtons = ({ vacancy, callback }: DashboardControlButtonsProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const [commitSwitchVacancyStatus] = useMutation<VacancyMutationsSwitchVacancyStatusMutation>(SWITCH_VACANCY_STATUS);

  const allOpenSelectedFilters = useRecoilValue(fetchDashboardVacanciesByOpenParamsSelector);
  const allShowAllSelectedFilters = useRecoilValue(fetchDashboardVacanciesByShowAllParamsSelector);

  const setShowPaymentSubscriptionModal = useSetRecoilState(paymentSubscriptionModalAtom);
  const isRestrictedFromPublishClosedJob = useRecoilValue(RestrictedFromPublishJobAtom);

  const getButtonStyle = useMemo(
    () =>
      'w-full sm:w-auto flex justify-center sm:justify-start sm:pl-5 py-2 sm:pt-4 sm:pb-2.5 text-darkBlue cursor-pointer hover:bg-specialGray-005 [&:not(:last-child)]:sm:border-b [&:not(:last-child)]:sm:border-b-specialGray-012',
    [],
  );

  const handleEditJobOpening = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();
      navigate(`${RoutePaths.ProfileOrganizationBase}/${orgId}/${RoutePaths.JobOpeningStep1}`, {
        state: {
          organization: {
            id: orgId as string,
            vacancyId: vacancy?.id,
            positionTitle: vacancy?.title,
          },
        },
      });
    },
    [navigate, vacancy?.title, vacancy?.id, orgId],
  );

  const handleClickByOpenOrClose = (newVacancyStatus: VACANCY_STATUS) => (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (newVacancyStatus === VACANCY_STATUS.OPEN && isRestrictedFromPublishClosedJob) {
      setShowPaymentSubscriptionModal({ showPaymentSubscriptionModal: true });
      return;
    }

    commitSwitchVacancyStatus({
      variables: {
        id: vacancy.id,
        status: newVacancyStatus,
      },
      onCompleted: callback,
      updater: switchStatusWithNode(allOpenSelectedFilters, allShowAllSelectedFilters),
    });
  };
  return (
    <>
      <div className={`${getButtonStyle} border-r sm:border-r-0`} onClick={handleEditJobOpening}>
        <EditIconWithUnderline />
        <span className="ml-2">{intl.formatMessage({ id: 'label_edit_button' })}</span>
      </div>
      {vacancy?.status === VACANCY_STATUS.CLOSED && (
        <div className={getButtonStyle} onClick={handleClickByOpenOrClose(VACANCY_STATUS.OPEN)}>
          {isRestrictedFromPublishClosedJob ? (
            <>
              <PublishIcon className="self-center ml-1 sm:ml-0" />
              <PaymentSubscriptionProLabel className="order-last self-center ml-1 sm:ml-2" />
            </>
          ) : (
            <ArrowDownTray additionalClasses="w-4 h-4 rotate-180 text-specialGray-05 self-center" />
          )}
          <span className={`${isRestrictedFromPublishClosedJob ? 'text-specialPurple ml-1 sm:ml:2 font-medium' : 'ml-2'}`}>{intl.formatMessage({ id: 'publish' })}</span>
        </div>
      )}
      {vacancy?.status !== VACANCY_STATUS.CLOSED && (
        <div className={getButtonStyle} onClick={handleClickByOpenOrClose(VACANCY_STATUS.CLOSED)}>
          <CloseButtonIcon />
          <span className="sm:ml-2">{intl.formatMessage({ id: 'hire_button_label_close' })}</span>
        </div>
      )}
    </>
  );
};

export default ControlButtons;
