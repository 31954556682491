// packages
import { useRecoilValue } from 'recoil';
import { useLazyLoadQuery } from 'react-relay';
// hooks
import { useGetHireProfile } from './index';
// generated
import { VacancyQueriesListOfVacanciesQuery } from 'schemas/vacancy/__generated__/VacancyQueriesListOfVacanciesQuery.graphql';
// schemas
import { LIST_OF_VACANCIES } from 'schemas/vacancy/VacancyQueries';
// types
import { VACANCY_ORDER_FIELD, VACANCY_STATUS } from 'models/enums';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';

export const useGetHireProfileVacancies = () => {
  const { organizationId } = useGetHireProfile();
  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);

  return useLazyLoadQuery<VacancyQueriesListOfVacanciesQuery>(LIST_OF_VACANCIES, {
    orgId: getCurrentOrganisation.organisationId || (organizationId as string),
    filters: [{ field: VACANCY_ORDER_FIELD.STATUS, values: [VACANCY_STATUS.OPEN] }],
    skip: !(organizationId || getCurrentOrganisation.organisationId),
  }).vacancies?.edges;
};
