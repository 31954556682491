/**
 * @generated SignedSource<<4308550559aff4037251aa9edc11a9f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type CertificateInfoData = {
  documentId?: string | null;
  expires?: String | null;
};
export type CertificateMutationsUpdateCertificateInfoMutation$variables = {
  certificatesInfoData: CertificateInfoData;
  id: string;
};
export type CertificateMutationsUpdateCertificateInfoMutation$data = {
  readonly updateCertificateInfo: {
    readonly certificate: {
      readonly ' $fragmentSpreads': FragmentRefs<'CertificateFragments_single'>;
    };
    readonly document: {
      readonly id: string;
      readonly urls: ReadonlyArray<string>;
      readonly xlarge_urls: ReadonlyArray<string> | null;
    } | null;
    readonly expires: String | null;
    readonly id: string;
    readonly owner: {
      readonly id: string;
    };
  };
};
export type CertificateMutationsUpdateCertificateInfoMutation = {
  response: CertificateMutationsUpdateCertificateInfoMutation$data;
  variables: CertificateMutationsUpdateCertificateInfoMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'certificatesInfoData',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v2 = [
      {
        kind: 'Variable',
        name: 'data',
        variableName: 'certificatesInfoData',
      },
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'expires',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      concreteType: 'Profile',
      kind: 'LinkedField',
      name: 'owner',
      plural: false,
      selections: [v3 /*: any*/],
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      concreteType: 'Document',
      kind: 'LinkedField',
      name: 'document',
      plural: false,
      selections: [
        v3 /*: any*/,
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'urls',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'xlarge_urls',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'CertificateMutationsUpdateCertificateInfoMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'CertificateInfo',
          kind: 'LinkedField',
          name: 'updateCertificateInfo',
          plural: false,
          selections: [
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'Certificate',
              kind: 'LinkedField',
              name: 'certificate',
              plural: false,
              selections: [
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'CertificateFragments_single',
                },
              ],
              storageKey: null,
            },
            v6 /*: any*/,
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'CertificateMutationsUpdateCertificateInfoMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'CertificateInfo',
          kind: 'LinkedField',
          name: 'updateCertificateInfo',
          plural: false,
          selections: [
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'Certificate',
              kind: 'LinkedField',
              name: 'certificate',
              plural: false,
              selections: [
                v3 /*: any*/,
                v7 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'alts',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'priority',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'neverExpires',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'CertificateKind',
                  kind: 'LinkedField',
                  name: 'kind',
                  plural: false,
                  selections: [
                    v3 /*: any*/,
                    v7 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'perma',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            v6 /*: any*/,
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '14105293aef70751e780e72fe5bf1773',
      id: null,
      metadata: {},
      name: 'CertificateMutationsUpdateCertificateInfoMutation',
      operationKind: 'mutation',
      text: 'mutation CertificateMutationsUpdateCertificateInfoMutation(\n  $id: ID!\n  $certificatesInfoData: CertificateInfoData!\n) {\n  updateCertificateInfo(id: $id, data: $certificatesInfoData) {\n    id\n    expires\n    owner {\n      id\n    }\n    certificate {\n      ...CertificateFragments_single\n      id\n    }\n    document {\n      id\n      urls\n      xlarge_urls\n    }\n  }\n}\n\nfragment CertificateFragments_single on Certificate {\n  id\n  name\n  alts\n  priority\n  neverExpires\n  kind {\n    id\n    name\n    perma\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'c9197f85f65793963ba7c699b120f550';

export default node;
