// packages
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
// components
import getEducationIcon from 'icons/EducationIcons';
// generated
import { EducationFragments$key } from 'schemas/educations/__generated__/EducationFragments.graphql';
// schemas
import { EDUCATION_FRAGMENT } from 'schemas/educations/EducationFragments';
// hooks
import { useGetProfileIdFromAuthOrParams } from 'hooks/useGetProfileIdFromAuthOrParams';
// types
import { EducationItemProps } from './types';

const TableEducationItem = ({ education, disabled, openUpdateModal, removeFunction }: EducationItemProps) => {
  const intl = useIntl();

  const dataOfEducation = useFragment<EducationFragments$key>(EDUCATION_FRAGMENT, education);
  const { isSelfProfile } = useGetProfileIdFromAuthOrParams();

  return (
    <tr className="flex flex-col sm:flex-row items-left sm:items-center border border-specialGray-012 border-t-0 bg-white last:rounded-2xl last:rounded-t-none text-darkBlue">
      <td className="ml-0 sm:ml-8 pr-3 py-3 w-1/3 overflow-hidden truncate flex items-center justify-between sm:justify-start">
        <div className="sm:contents flex items-center w-auto sm:w-4/5">
          <div className="w-8 h-8">{dataOfEducation.kind?.name && getEducationIcon(dataOfEducation.kind.perma)}</div>
          <span className="ml-3 mr-auto text-specialGray-1 font-medium text-sm mt-1.5 sm:mt-0 whitespace-pre-wrap">{dataOfEducation.name}</span>
        </div>
      </td>
      <td className="w-1/4 pr-3 py-3 text-specialGray-1 text-sm flex justify-between sm:justify-start mt-1 sm:mt-0">
        <span>{dataOfEducation.kind?.name}</span>
      </td>
      <td className="w-15 pr-3 py-3 text-specialGray-075 text-sm flex justify-between sm:justify-start mt-1 sm:mt-0">
        <span>{format(new Date(`${dataOfEducation.start}`), 'dd.MM.yyyy')}</span>
      </td>
      <td className="w-15 pr-3 py-3 text-specialGray-075 text-sm flex justify-between sm:justify-start mt-1 sm:mt-0">
        <span>{dataOfEducation?.end ? format(new Date(`${dataOfEducation.end}`), 'dd.MM.yyyy') : intl.formatMessage({ id: 'education_item_present_end' })}</span>
      </td>

      {isSelfProfile && (
        <>
          <td className="flex justify-center items-center w-10 h-8 border border-solid border-specialGray-05 rounded-[10px] mr-2.5">
            <PencilIcon aria-disabled={disabled} className="w-5 h-5 text-specialGray-05 cursor-pointer" onClick={() => openUpdateModal(dataOfEducation)} />
          </td>
          <td className="flex justify-center items-center w-10 h-8 border border-solid border-specialGray-05 rounded-[10px] mr-4">
            <TrashIcon aria-disabled={disabled} className="w-5 h-5 text-specialGray-05 cursor-pointer" onClick={() => removeFunction(dataOfEducation.id)} />
          </td>
        </>
      )}
    </tr>
  );
};

export default TableEducationItem;
