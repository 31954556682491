const RestrictedPublishIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <mask id="mask0_52_35107" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
        <rect x="32" y="32" width="32" height="32" transform="rotate(-180 32 32)" fill="#6F1CF4" />
      </mask>
      <g mask="url(#mask0_52_35107)">
        <path
          d="M5.63269 1.83229L9.13268 5.33229L23.9994 5.33229C24.7327 5.33229 25.3605 5.5934 25.8827 6.11562C26.4049 6.63785 26.666 7.26562 26.666 7.99896L26.666 11.999L23.9994 11.999L23.9994 7.99896L11.7993 7.99896L15.2327 11.4323L15.9994 10.6656L22.666 17.3323L21.8993 18.099L30.166 26.3656L28.266 28.2656L3.73268 3.73229L5.63269 1.83229ZM11.4327 15.2323L13.3327 17.1323L11.1993 19.2656L9.33268 17.3323L11.4327 15.2323ZM14.666 18.4656L17.3327 21.1323L17.3327 26.6656L14.666 26.6656L14.666 18.4656ZM5.33268 9.13229L7.99935 11.799L7.99935 11.999L5.33268 11.999L5.33268 9.13229Z"
          fill="#6F1CF4"
        />
      </g>
    </svg>
  );
};

export default RestrictedPublishIcon;
