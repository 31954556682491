import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { MessageDescriptor } from '@formatjs/intl';
import { PlusIcon } from '@heroicons/react/24/outline';
// hooks
import { useHoverById } from 'hooks/useHover';
import useFilterSearchUrl from 'hooks/searchFilter/useFilterSearchUrl';
import { useDeleteFilterAnalytics, useValueFilterAnalytics } from 'hooks/anaytics/useFiltersAnalytics';
// components
import CommonInput from '../../CommonInput/CommonInput';
import { FilterSelectedItem } from 'system/FilterSlideComponents/components/FilterItems/FilterSelectedItem';
// models
import { FILTER_TYPE } from 'models/enums';
// utils
import { validationSearchCrewByVesselImo } from '../filterSlideUtils';

export const FilterByValues = ({ filterType, title }: { filterType: FILTER_TYPE; title: MessageDescriptor }) => {
  const intl = useIntl();
  const { deleteFilterAnalytics } = useDeleteFilterAnalytics();
  const { sendValueFilterAnalytics } = useValueFilterAnalytics();
  const { createSearchUrl, selectedItemsFromUrl } = useFilterSearchUrl(filterType);

  const [value, setValue] = useState<string>('');
  const [validationError, setValidationError] = useState<string>('');
  const [imoIsUsed, setImoIsUsed] = useState<string>('');

  const { selectedId, handleMouseEnter, handleMouseOut } = useHoverById();
  const inputRef = useRef<HTMLInputElement>(null);

  const [selectedFilterItems, setSelectedFilterItems] = useState<Array<string>>(selectedItemsFromUrl || []);

  const handleDeleteSelectedItemFromDropDownList = (inputRef?: React.RefObject<HTMLInputElement>) => (filterItem: string) => () => {
    createSearchUrl({ id: filterItem }, inputRef)();
    setSelectedFilterItems(prev => prev.filter(selectedItem => selectedItem !== filterItem));
    deleteFilterAnalytics({ value: filterItem }, filterType);
  };

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validationData = validationSearchCrewByVesselImo(e.target.value);
    setValidationError(validationData?.error || '');
    if (!!validationData) {
      setValue(validationData.validatedValue);
      setImoIsUsed('');
    } else {
      setValue(e.target.value);
    }
  };

  useEffect(() => {
    if (validationError && !value.length) {
      setValidationError('');
      setImoIsUsed('');
    }
  }, [value, validationError]);

  const handleAddOneMoreImo = useCallback(() => {
    if (!!value && value.length === 7) {
      setSelectedFilterItems(prev => {
        if (prev.includes(value)) {
          setImoIsUsed('search_filter_imo_is_already_used');
          return prev;
        }
        setValue('');
        setImoIsUsed('');
        sendValueFilterAnalytics.current(filterType, value);
        createSearchUrl({ id: value }, inputRef)();
        return [...prev, value];
      });
    }
  }, [createSearchUrl, filterType, value, sendValueFilterAnalytics]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.code === 'Enter') {
        handleAddOneMoreImo();
      }
    },
    [handleAddOneMoreImo],
  );

  return (
    <div className="flex flex-col pt-8 px-4 sm:px-8 border-b border-b-specialGray-01 sm:p-8">
      <CommonInput
        ref={inputRef}
        validationError={validationError || imoIsUsed}
        id={filterType}
        label={title}
        onChange={onChangeValue}
        inputValue={value}
        onKeyDown={handleKeyDown}
      >
        <PlusIcon
          onClick={handleAddOneMoreImo}
          width={24}
          height={24}
          className={`absolute w-5 h-5 cursor-pointer ${validationError || imoIsUsed ? 'text-red-600' : 'text-blue'} ${
            value.length === 7 ? 'cursor-pointer' : 'cursor-not-allowed'
          } top-2.5 right-0 mr-2`}
        />
      </CommonInput>
      {validationError ? <span className="relative bottom-4 text-sm text-red-600">{intl.formatMessage({ id: validationError })}</span> : null}
      {imoIsUsed ? <span className="relative bottom-4 text-sm text-red-600">{intl.formatMessage({ id: imoIsUsed })}</span> : null}
      {!isEmpty(selectedFilterItems) && (
        <div className="flex w-full flex-wrap mb-3">
          {selectedFilterItems.map(item => {
            return (
              <div className="flex" onMouseEnter={handleMouseEnter(item)} onMouseLeave={handleMouseOut} key={item}>
                <FilterSelectedItem className="mr-2 mt-2" isHovered={item === selectedId} name={item} onClickEvent={handleDeleteSelectedItemFromDropDownList()(item)} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
