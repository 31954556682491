// packages
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
// components
import Header from './components/Header';
import LogoFull from 'icons/LogoFullIcon';
import CustomButton from 'system/Buttons/CustomButton';
import YellowCircleWithRightArrow from 'icons/YellowCircleWithRightArrow';
import ChoiceTypeOfAccountElement from './components/ChoiceTypeOfAccountElement';
// routes
import { RoutePaths } from 'app/routing';

const ChoiceTypeOfAccount = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const getSearchParams = searchParams.get('default_selection');

  const handleMoveToFirstRegisterStep = useCallback(() => {
    if (getSearchParams === 'hire') {
      searchParams.delete('default_selection');
      navigate({ pathname: RoutePaths.HireAuthRegisterStepOne, search: searchParams.toString() }, { state: { choseTypeOfAccount: getSearchParams } });
      window.analytics?.track('wui-hire-signup-choice-type-of-account', {
        type_of_account: getSearchParams,
      });
    } else {
      navigate(RoutePaths.AuthRegisterStepOne, { state: { choseTypeOfAccount: getSearchParams } });
      window.analytics?.track('wui-signup-choice-type-of-account', {
        type_of_account: getSearchParams,
      });
    }
  }, [getSearchParams, searchParams, navigate]);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'register_form_meta_title' })}</title>
        <meta name="description" content={intl.formatMessage({ id: 'register_form_meta_description' })} />
        {/*hide register pages from crawlers*/}
        <meta name="robots" content="noindex" />
      </Helmet>
      <header className="w-full h-[72px] flex justify-center items-center">
        <Link to={getSearchParams === 'hire' ? RoutePaths.HireAuthLogin : RoutePaths.AuthLogin}>
          <LogoFull />
        </Link>
      </header>
      <main className="flex flex-col h-[calc(100vh_-_72px)] w-full">
        <section className="sm:my-auto mx-auto w-full sm:max-w-md h-full sm:h-auto flex flex-col pt-5 pb-7 gap-y-5 px-4">
          <Header title={{ id: 'pick_your_profile_type_title' }} description={{ id: 'pick_your_profile_type_description' }} icon={<YellowCircleWithRightArrow />} />
          <div className="flex gap-x-3 justify-between">
            <ChoiceTypeOfAccountElement id="crew" />
            <ChoiceTypeOfAccountElement id="hire" />
          </div>
          <CustomButton
            dataTestId="moveToRegisterForm"
            label={{ id: 'next' }}
            className="flex bg-blue rounded-[10px] text-white flex-row-reverse w-full h-12 items-center justify-center space-x-3 space-x-reverse disabled:bg-[#D4D7DD] disabled:text-specialGray-03"
            onClick={handleMoveToFirstRegisterStep}
            disabled={!getSearchParams}
          >
            <ChevronRightIcon height={24} />
          </CustomButton>
          <div className="flex text-xs space-x-2 mx-auto">
            <span className="text-specialGray-075">{intl.formatMessage({ id: 'register_alreadyAccount' })}</span>
            <Link className="block text-blue" to={getSearchParams === 'hire' ? RoutePaths.HireAuthLogin : RoutePaths.AuthLogin}>
              {intl.formatMessage({ id: 'register_sign_in' })}
            </Link>
          </div>
        </section>
      </main>
    </>
  );
};

export default ChoiceTypeOfAccount;
