import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLazyLoadQuery, useMutation } from 'react-relay';
// generated
import { ResumeMutation, ResumeMutation$variables } from 'schemas/resume/__generated__/ResumeMutation.graphql';
import { ResumeQueriesBackgroundTaskQuery } from 'schemas/resume/__generated__/ResumeQueriesBackgroundTaskQuery.graphql';
// schemas
import { GENERATE_RESUME_MUTATION } from 'schemas/resume/ResumeMutation';
import { GET_BACKGROUND_TASK } from 'schemas/resume/ResumeQueries';

export const useGenerateResume = (profileId?: string) => {
  const intl = useIntl();

  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [addPreviewId, setAddPreviewId] = useState<string>('');
  const [runTriggers, setRunTriggers] = useState(false);
  const [uuid, setUuid] = useState<string>('');
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState<any>(null);
  const [counterOfRequests, setCounterOfRequests] = useState<number>(30);

  const [delay, setDelay] = useState(2000);
  const [requestCount, setRequestCount] = useState(1);

  const [commitGenerateResume] = useMutation<ResumeMutation>(GENERATE_RESUME_MUTATION);

  const backgroundTask = useLazyLoadQuery<ResumeQueriesBackgroundTaskQuery>(GET_BACKGROUND_TASK, { uuid: uuid, skip: !uuid }, refreshedQueryOptions ?? {});

  let timerId = useRef<NodeJS.Timer | null>(null);
  let openWindow = useRef<Window | null>(null);

  const refetchData = useCallback(() => {
    // This counter to prevent too many queries
    setCounterOfRequests(prevState => prevState - 1);

    // Trigger a re-render of useLazyLoadQuery with the same variables,
    // but an updated fetchKey and fetchPolicy.
    // The new fetchKey will ensure that the query is fully
    // re-evaluated and refetched.
    // The fetchPolicy ensures that we always fetch from the network
    // and skip the local data cache.
    setRefreshedQueryOptions((prev: any) => ({
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: 'network-only',
    }));
  }, []);

  const delayCalc = (ms: number, n: number) => ms + ms * (n * 0.2);

  useEffect(() => {
    const statusOfBgTask = backgroundTask?.backgroundTask?.status;

    if (statusOfBgTask === 'FINISHED') {
      const linkToPDF = JSON.parse(backgroundTask?.backgroundTask?.response || '')?.link;

      if (openWindow?.current) {
        if (linkToPDF) {
          openWindow.current.location.href = linkToPDF;
        }
      }
    }
  }, [backgroundTask?.backgroundTask?.status, runTriggers, backgroundTask?.backgroundTask?.response, intl]);

  useEffect(() => {
    const statusOfBgTask = backgroundTask?.backgroundTask?.status;
    if (runTriggers && statusOfBgTask === 'NEW') {
      const getBgDataWithExpectedState = () => {
        refetchData();
        setRequestCount(prev => prev + 1);
        setDelay(delayCalc(2000, requestCount));
      };
      if (delay !== null) {
        timerId.current = setTimeout(getBgDataWithExpectedState, delay);
        return () => {
          timerId.current && clearTimeout(timerId.current);
        };
      }
    }

    if (statusOfBgTask === 'FINISHED') {
      setRunTriggers(false);
      setLoading(false);
      setDisabled(true);
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    }
    return () => {
      timerId.current && clearTimeout(timerId.current);
    };
  }, [delay, requestCount, backgroundTask?.backgroundTask?.status, backgroundTask?.backgroundTask?.response, intl, runTriggers, refetchData]);

  //Prevent too many queries
  useEffect(() => {
    if (counterOfRequests === 0) {
      if (timerId.current) {
        clearTimeout(timerId.current);
        setRunTriggers(false);
        setDisabled(false);
        setLoading(false);
      }
    }
  }, [counterOfRequests]);

  useEffect(() => {
    let location = window.location.href;
    const indexFromDelete = location.indexOf('.app');
    if (indexFromDelete) {
      location = location.slice(0, indexFromDelete);
    } else {
      location = '';
    }

    let numb: string = '';

    location.split('-').forEach(item => (isNaN(+item) ? null : (numb = item)));

    if (numb) {
      setAddPreviewId(location.includes('deploy-preview-') ? numb : '');
    }
    return () => {
      setLoading(false);
      setAddPreviewId('');
    };
  }, [profileId]);

  const generateResume = () => {
    let obj: ResumeMutation$variables = addPreviewId ? { pid: profileId as string, previewID: addPreviewId } : { pid: profileId as string };
    commitGenerateResume({
      variables: obj,
      onCompleted: res => {
        if (res?.generateResumeBackground?.uuid) {
          setUuid(res?.generateResumeBackground?.uuid);
          setRunTriggers(true);
          setLoading(true);
          openWindow.current = window.open(``, '_blank');
          if (openWindow?.current) {
            openWindow.current.document.write(intl.formatMessage({ id: 'creatingCV' }));
          }
        }
        window.analytics?.track('wui-resume-generated', {
          label: 'Generated a resume pdf',
        });
      },
      onError: err => {
        setDisabled(false);
        setLoading(false);
        console.warn(err);
      },
    });
  };

  return {
    generateResume,
    loading,
    disabled,
  };
};
