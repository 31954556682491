// packages
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { Tab } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
// routing
import { RoutePaths } from 'app/routing';
// components
import EmptyDashboard from './components/EmptyDashboard';
import { EmptyCompanyIcon } from 'icons/EmptyCompanyIcon';
import BlueLinkButton from 'system/Buttons/BlueLinkButton';
import PanelsTabLayout from 'system/Layouts/PanelsTabLayout/PanelsTabLayout';
import PanelTabForAllJobs from './components/PanelTabForAllJobs/PanelTabForAllJobs';
import PanelTabForOpenJobs from './components/PanelTabForOpenJobs/PanelTabForOpenJobs';
// hooks
import { useGetHireProfile, useGetNumberOfOpenedAndClosedVacancies } from 'hooks/hireProfileHooks';
import useIsRestrictedFromPublishJobOpening from 'hooks/subscription/useIsRestrictedFromPublishJobOpening';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';
import { RestrictedFromPublishJobAtom } from 'recoil/RestrictedFromPublishJob/atoms/RestrictedFromPublishJobAtom';

const Dashboard = ({ defaultSelectTabIndex }: { defaultSelectTabIndex?: number }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { currentOrganizationData } = useGetHireProfile();
  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);

  const setRestrictedFromPublishJobAtom = useSetRecoilState(RestrictedFromPublishJobAtom);

  const { getNumberOfOpenedVacancies, getNumberOfClosedVacancies } = useGetNumberOfOpenedAndClosedVacancies();
  const isRestrictedFromOpenJobOpenings = useIsRestrictedFromPublishJobOpening();

  const createJobPostingLink: string = `${RoutePaths.ProfileOrganizationBase}/${getCurrentOrganisation.organisationId}/${RoutePaths.JobOpeningStep1}`;

  useEffect(() => {
    setRestrictedFromPublishJobAtom(isRestrictedFromOpenJobOpenings);
  }, [isRestrictedFromOpenJobOpenings, setRestrictedFromPublishJobAtom]);

  if (!getNumberOfOpenedVacancies && !getNumberOfClosedVacancies) {
    return currentOrganizationData ? (
      <EmptyDashboard isRenderBigButton />
    ) : (
      <EmptyDashboard
        titleId="hire_no_organization"
        descriptionId="hire_no_organization_description"
        svgIcon={<EmptyCompanyIcon />}
        actionFunction={() => {
          navigate(RoutePaths.ProfileOrganizationBase, { state: { isShowEditModal: true }, replace: true });
        }}
        actionDescriptionId="employer_self_basic_modalTitle"
      />
    );
  }

  return (
    <>
      <div className="flex justify-between items-center mt-8 relative sm:flex-row flex-col space-y-3 sm:space-y-0">
        <div className="flex flex-col text-center sm:text-start space-y-1 sm:space-y-0">
          <span className="text-lg font-medium">{intl.formatMessage({ id: 'hire_dashboard_header_title' })}</span>
          <div className="flex space-x-6 text-specialGray-075 text-sm">
            <span data-test-id="hire-dashboard-opened-vacancies">{intl.formatMessage({ id: 'hire_dashboard_header_status_open' }, { count: getNumberOfOpenedVacancies })}</span>
            <span data-test-id="hire-dashboard-closed-vacancies">{intl.formatMessage({ id: 'hire_dashboard_header_status_closed' }, { count: getNumberOfClosedVacancies })}</span>
          </div>
        </div>
        <BlueLinkButton
          withIcon
          to={createJobPostingLink}
          title={{ id: 'hire_dashboard_header_button_job_opening' }}
          state={{
            organization: {
              id: getCurrentOrganisation.organisationId,
            },
          }}
        />
      </div>
      <div className="flex relative flex-col w-full">
        <PanelsTabLayout widthTabList="w-[210px]" position="sm:absolute sm:-top-[45px] sm:right-[172px]" defaultSelectTabIndex={defaultSelectTabIndex}>
          <Tab.Panels className="w-full mt-6">
            <PanelTabForOpenJobs />
            <PanelTabForAllJobs />
          </Tab.Panels>
        </PanelsTabLayout>
      </div>
    </>
  );
};

export default Dashboard;
