// packages
import React, { useState, ChangeEvent } from 'react';
import { commitLocalUpdate, useRelayEnvironment } from 'react-relay';
// components
import { Modal } from 'system/Modal';
import { AvatarUploadCropper } from './AvatarUploadCropper';
import LabelComponent from 'system/LabelComponent/LabelComponent';
// generated
import { ProfileFragmentsAvatarInfo$data } from 'schemas/profile/__generated__/ProfileFragmentsAvatarInfo.graphql';
// hooks
import { useAuth } from 'authentication';
// models
import { AvatarUploadProps } from 'models/modelsOfComponents';

// AvatarUpload allows for uploading avatar using Transloadit
export const AvatarUpload: React.FC<AvatarUploadProps> = ({ defaultShow = false, onUploaded = () => {}, onUrlReady = () => {}, fileSize, warningSize, buttonText }) => {
  const { identity } = useAuth();
  const env = useRelayEnvironment();
  const [show, setShow] = useState(defaultShow);
  const [file, setFile] = useState<File | undefined>();
  const [img, setImg] = useState<string>();

  function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  function handleChange(ev: ChangeEvent<HTMLInputElement>) {
    if (!ev.target.files || ev.target.files.length < 1) {
      return;
    }

    if (ev.target.files[0].size > fileSize) {
      warningSize(true, formatBytes(ev.target.files[0].size));
      return;
    } else {
      warningSize(false, formatBytes(ev.target.files[0].size));
      setImg(URL.createObjectURL(ev.target.files[0]));
      setFile(ev.target.files[0]);
      setShow(true);
      ev.target.value = ''; // hack to allow triggering of onChange when selecting the same file
    }
  }

  function handleUploaded() {
    commitLocalUpdate(env, async store => {
      const rec = store.get<ProfileFragmentsAvatarInfo$data>(`${identity?.profileId}`);
      if (rec) {
        const av = rec.getValue('avatar_version') ? rec.getValue('avatar_version') + 1 : 1;
        rec.setValue(av, 'avatar_version'); // set on relay store so all avatars know to update 'reactively'
        window.analytics?.track('wui-uploaded-avatar');
      }
    });

    setShow(false);
    onUploaded();
  }

  // Render a file input but show JUST the label
  return (
    <>
      <LabelComponent
        id="avatar-file-input"
        label={buttonText}
        classes="block text-blue bg-lightBlue cursor-pointer rounded-lg border border-lightBlue shadow-sm text-sm px-5 py-2 font-medium"
      />
      <input className="hidden" id="avatar-file-input" type="file" onChange={handleChange} accept="image/jpeg, image/jpg, image/png" />
      {show && (
        <Modal initialScale={1} title={{ id: 'avatar_upload_modal_title' }} show={show} additionalClasses="relative" onClose={() => setShow(false)}>
          <>
            {file && img && (
              <AvatarUploadCropper
                preview={img}
                file={file}
                onUploaded={handleUploaded}
                onClose={() => setShow(false)}
                onUrlReady={(url: any) => {
                  onUrlReady(url);
                }}
              />
            )}
          </>
        </Modal>
      )}
    </>
  );
};
