import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

const EssentialCertificatesSkeleton = () => {
  const renderCertificateItem = useMemo(
    () => (
      <div className="flex flex-col gap-y-2 sm:flex-row p-4 border border-specialGray-01 w-full first:rounded-t-xl first:border-t border-t-0 border-b last:rounded-b-xl">
        <div className="flex items-center w-full">
          <Skeleton width={18} height={21} />
          <Skeleton width="100%" height={14} containerClassName="align-middle ml-4 w-32 sm:w-48" />
        </div>
        <Skeleton width="100%" height={14} containerClassName="text-right align-middle w-24 sm:w-36" />
      </div>
    ),
    [],
  );

  return (
    <div className="flex flex-col w-full mt-8">
      <Skeleton width={270} height={24} className="mb-2" />
      <div className="flex flex-col w-full">
        {renderCertificateItem}
        {renderCertificateItem}
        {renderCertificateItem}
      </div>
      <div className="mx-auto">
        <Skeleton width={235} height={34} className="text-center mt-4" />
      </div>
    </div>
  );
};

export default EssentialCertificatesSkeleton;
