// packages
import Skeleton from 'react-loading-skeleton';
// components
import DashboardTableSkeleton from './components/DashboardTableSkeleton';

const DashboardSkeleton = () => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center mt-8">
        <div className="flex flex-col">
          <Skeleton width={120} height={24} />
          <div className="flex mt-2">
            <Skeleton width={50} height={14} className="mr-6" />
            <Skeleton width={60} height={14} className="mr-6" />
          </div>
        </div>
        <div className="flex space-x-8">
          <Skeleton width={200} height={40} borderRadius="0.5rem" />
          <Skeleton width={140} height={40} borderRadius="0.5rem" />
        </div>
      </div>
      <DashboardTableSkeleton />
    </div>
  );
};

export default DashboardSkeleton;
