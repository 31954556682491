const LogoIcon = ({ isBigScreenLogo = false }: { isBigScreenLogo?: boolean }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="192.9" height="71.54" viewBox="0 0 192.9 71.54" className={`block ${isBigScreenLogo ? 'h-10' : 'lg:hidden h-5'} w-auto`}>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            style={{ fill: '#48feba' }}
            d="M74,25.47a23.12,23.12,0,0,0-22.4,17.44A21.86,21.86,0,0,0,51,46a22.61,22.61,0,0,0-.16,2.62c0,.06,0,.11,0,.17H68.32c0-.06,0-.11,0-.17A5.7,5.7,0,0,1,71,43.79a5.65,5.65,0,0,1,3-.88h22.1c.08-.38.16-.77.25-1.14a29.19,29.19,0,0,1,10.6-16.3Z"
          />
          <path style={{ fill: '#48feba' }} d="M96.39,0a10.46,10.46,0,1,0,10.47,10.46A10.46,10.46,0,0,0,96.39,0Z" />
          <path style={{ fill: '#ffea29' }} d="M45.52,0A10.46,10.46,0,1,0,56,10.46,10.46,10.46,0,0,0,45.52,0Z" />
          <path
            style={{ fill: '#2a6ff5' }}
            d="M169.73,25.49H124.8a23.44,23.44,0,0,0-2.91.19,23.19,23.19,0,0,0-19.56,17.23,23.43,23.43,0,0,0-.55,3.05,24,24,0,0,0-.15,2.56c0,.08,0,.17,0,.25h17.5c0-.08,0-.17,0-.25a5.68,5.68,0,0,1,5.67-5.66h44.93a5.66,5.66,0,1,1,0,11.32H147.47a29.22,29.22,0,0,1-10.64,17.36h32.9a23,23,0,1,0,0-46.05Z"
          />
          <path style={{ fill: '#2a6ff5' }} d="M147.27,0a10.46,10.46,0,1,0,10.46,10.46A10.46,10.46,0,0,0,147.27,0Z" />
          <path
            style={{ fill: '#48feba' }}
            d="M124.46,48.77h0a5.66,5.66,0,0,1-.61,2.17h0a5.71,5.71,0,0,1-5,3H96.57a2,2,0,0,0,0,.24l-.21.92A29.29,29.29,0,0,1,85.76,71.43l0,0h33.15a22.8,22.8,0,0,0,2.49-.15,23.16,23.16,0,0,0,19.79-17.11c0-.06,0-.12.05-.17a22.84,22.84,0,0,0,.53-2.88,1.1,1.1,0,0,0,0-.18c.08-.71.13-1.44.14-2.18Z"
          />
          <path
            style={{ fill: '#ffea29' }}
            d="M73.59,48.77h0a5.57,5.57,0,0,1-.62,2.16l0,0a5.73,5.73,0,0,1-2,2.14l-.2.11A5.58,5.58,0,0,1,68,54H23a5.66,5.66,0,0,1-5.57-5.23h0c0-.06,0-.11,0-.17a5.67,5.67,0,0,1,5.67-5.67H45.33A29.24,29.24,0,0,1,56.14,25.47h-33A23.14,23.14,0,0,0,0,48.58v.17c0,.14,0,.27,0,.4,0,.6.06,1.2.13,1.78A23.89,23.89,0,0,0,.69,54,23.15,23.15,0,0,0,23,71.42H68c.67,0,1.34,0,2-.09.3,0,.6-.08.89-.11a23.15,23.15,0,0,0,19.37-17c0-.08.05-.16.07-.24s.41-1.87.53-2.82c0-.08.16-1.69.17-2.42Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoIcon;
