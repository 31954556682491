// components
import ProfileHowCanIDoBetter from './components/ProfileHowCanIDoBetter/ProfileHowCanIDoBetter';
import ProfileCompletionScoreDetails from './components/ProfileCompletionScoreDetails/ProfileCompletionScoreDetails';

const ProfileScoreCommon = () => {
  return (
    <div className="flex flex-col space-x-0 items-center space-y-4 sm:space-y-0 sm:flex-row w-full mt-8 sm:justify-between sm:space-x-8">
      <ProfileCompletionScoreDetails />
      <ProfileHowCanIDoBetter />
    </div>
  );
};

export default ProfileScoreCommon;
