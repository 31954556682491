import graphql from 'babel-plugin-relay/macro';

export const ORGANISATION_FRAGMENT_FOR_SEARCH = graphql`
  fragment OrganisationFragmentsForSearchFragment on Organization {
    id
    name
    description
    avatarUrl
    websiteUrl
    headquarters
    fleetsize
    numberOfMembers
    numberOfVacancies
    size
  }
`;
