// components
import MainLayoutWrapper from './components/MainLayoutWrapper';
// routes
import { RoutePaths } from 'app/routing';
import { NotFoundRoute } from './routes/notFound/NotFoundRoute';
import { SimpleMainRoute } from './routes/SimpleMainRoute/SimpleMainRoute';
import { JobResponsesRoute } from './routes/jobResponses/JobResponsesRoute';
import { SearchProfileRoute } from './routes/search/profile/SearchProfileRoute';
import { SelfProfileRoute } from './routes/profiles/selfProfile/SelfProfileRoute';
import { SearchEmployersRoute } from './routes/search/employers/SearchEmployersRoute';
import { SearchJobOpeningsRoute } from './routes/search/jobOpenings/SearchJobOpeningsRoute';
import { CrewDetailsProfileRoute } from './routes/profiles/crewDetails/CrewDetailsProfileRoute';
import { CrewVisitProfileRoute } from './routes/profiles/crewVisitProfile/CrewVisitProfileRoute';
import { OrganisationDashboardRoute } from './routes/organisation/Dashboard/OrganisationDashboardRoute';
import { CreateEditVacancyFormRoute } from './routes/organisation/CreateEditVacancyForm/CreateEditVacancyFormRoute';

export const MainLayoutRoute = {
  path: RoutePaths.HomePath,
  Component: MainLayoutWrapper,
  children: [
    SelfProfileRoute,
    CrewVisitProfileRoute,
    CrewDetailsProfileRoute,
    ...OrganisationDashboardRoute,
    CreateEditVacancyFormRoute,
    SearchProfileRoute,
    SearchJobOpeningsRoute,
    SearchEmployersRoute,
    JobResponsesRoute,
    ...SimpleMainRoute,
    NotFoundRoute,
  ],
};
