//packages
import { FC } from 'react';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
// hooks
import { useFragment } from 'react-relay';
import { useFoundInvite } from 'hooks/useFoundInvite';
import { useCreateInvite, useDeleteInvite } from 'hooks/useInvite';
import { useGetUserAlreadyApplied } from 'hooks/useGetUserAlreadyApplied';
// components
import { Button } from 'system/Button';
import JobOpeningStatus from 'system/JobOpeningStatus/JobOpeningStatus';
// models
import { VACANCY_STATUS } from 'models/enums';
import { ItemForListOfVacanciesProps } from 'models/modelsOfComponents';
//schemas
import { VACANCY_FRAGMENT } from 'schemas/vacancy/VacancyFragments';
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';

const ItemForListOfVacancies: FC<ItemForListOfVacanciesProps> = ({ vacancy, crewProfileId }) => {
  const intl = useIntl();
  const vacancyData = useFragment<VacancyFragments$key>(VACANCY_FRAGMENT, vacancy);

  const foundInvite = useFoundInvite(vacancyData, crewProfileId);
  const handleCreateInvite = useCreateInvite(vacancy, crewProfileId);
  const handleRemoveInvite = useDeleteInvite(vacancy, crewProfileId, foundInvite?.id);
  const { userAlreadyApplied } = useGetUserAlreadyApplied(false, vacancyData, crewProfileId);

  return (
    <div className="p-2 flex min-h-[62px] border-b border-b-specialGray-02 last:border-y-0 w-full">
      <div className="mr-1 flex min-h-full items-center w-10%">
        <JobOpeningStatus status={vacancyData?.status as VACANCY_STATUS} />
      </div>

      <div className="flex-col w-full text-specialGray-1 w-[75%]">
        <h3 className="text-sm mb-1 truncate ">{vacancyData?.title}</h3>

        <div className="flex text-xs">
          {/*POSITIONS*/}
          <div className="mr-4">
            <span className="text-specialGray-075"> {intl.formatMessage({ id: 'hire_information_position' })} </span>
            {vacancyData?.duties?.length ? (
              <span>{vacancyData?.duties.map(({ name }) => `${name}`).join(', ')}</span>
            ) : (
              <span className="text-specialGray-075">{intl.formatMessage({ id: 'search_modal_position_empty' })}</span>
            )}
          </div>
          {/*DATE*/}
          <div className="mr-4">
            <span className="text-specialGray-075 "> {intl.formatMessage({ id: 'hire_job_description_posting_date' })} </span>
            <span className="whitespace-pre">{vacancyData?.createdAt && format(new Date(`${vacancyData?.createdAt}`), 'dd LLL yyyy')}</span>
          </div>
          {/*DEADLINE DATE*/}
          <div>
            <span className="text-specialGray-075"> {intl.formatMessage({ id: 'hire_information_deadline_date' })} </span>
            <span className="whitespace-pre text-specialGray-075">{intl.formatMessage({ id: 'search_modal_posting_date_dead_line_empty' })}</span>
          </div>
        </div>
      </div>

      <div className="ml-auto flex min-h-full items-center w-15 justify-end">
        {userAlreadyApplied ? (
          <Button
            buttonType="white-primary"
            additionalClasses="h-8 rounded-lg border border-green-500 text-green-500 cursor-default hover:bg-transparent"
            type="button"
            label={{ id: 'hire_label_applied' }}
          />
        ) : null}
        {!!foundInvite && !userAlreadyApplied ? (
          <Button
            buttonType="white-primary"
            additionalClasses="h-8 rounded-lg border border-green-500 text-green-500"
            type="button"
            label={{ id: 'hire_job_invited_label' }}
            onClick={handleRemoveInvite}
          />
        ) : null}
        {!foundInvite && !userAlreadyApplied ? (
          <Button buttonType="white-primary" additionalClasses="h-8 rounded-lg" type="button" label={{ id: 'search_list_item_invite_button_label' }} onClick={handleCreateInvite} />
        ) : null}
      </div>
    </div>
  );
};

export default ItemForListOfVacancies;
