import React, { ReactElement, useContext, useState } from 'react';

interface SkeletonContextInterface {
  isSelfProfile: boolean;
  isSelfVacancy: boolean | undefined;
  setIsSelfProfile: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSelfVacancy: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const storybookContext: SkeletonContextInterface = {
  isSelfProfile: false,
  isSelfVacancy: undefined,
  setIsSelfProfile: () => {},
  setIsSelfVacancy: () => {},
};

const StorybookCtx = React.createContext<SkeletonContextInterface>(storybookContext);

export function useSkeletonContext() {
  return useContext(StorybookCtx);
}

const SkeletonContext: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [isSelfProfile, setIsSelfProfile] = useState<boolean>(false);
  const [isSelfVacancy, setIsSelfVacancy] = useState<boolean | undefined>(undefined);

  return <StorybookCtx.Provider value={{ isSelfProfile, setIsSelfProfile, isSelfVacancy, setIsSelfVacancy }}>{children}</StorybookCtx.Provider>;
};

export default SkeletonContext;
