import graphql from 'babel-plugin-relay/macro';

export const GET_SINGLE_CERTIFICATE = graphql`
  fragment CertificateFragments_single on Certificate {
    id
    name
    alts
    priority
    neverExpires
    kind {
      id
      name
      perma
    }
  }
`;

export const LIST_OF_CERTIFICATE_INFOS = graphql`
  fragment CertificateFragmentsCertificateInfo on CertificateInfo @relay(plural: true) {
    id
    expires
    owner {
      id
    }
    certificate {
      id
      name
      alts
      priority
      neverExpires
      kind {
        id
        name
        perma
      }
    }
    document {
      id
      urls
      xlarge_urls
      kind {
        id
        name
        perma
      }
    }
  }
`;
