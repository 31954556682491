const NoJobOpeningWasFoundIcon = () => {
  return (
    <svg width="48" height="31" viewBox="0 0 48 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.047 11.3193V8.77954C19.047 8.0811 19.2959 7.48298 19.7937 6.98518C20.2907 6.48822 20.8883 6.23975 21.5868 6.23975H26.6664C27.3648 6.23975 27.9629 6.48822 28.4607 6.98518C28.9577 7.48298 29.2062 8.0811 29.2062 8.77954V11.3193H34.2858C34.9842 11.3193 35.5823 11.5682 36.0801 12.066C36.5771 12.563 36.8256 13.1607 36.8256 13.8591V27.828C36.8256 28.5264 36.5771 29.1246 36.0801 29.6224C35.5823 30.1193 34.9842 30.3678 34.2858 30.3678H13.9674C13.269 30.3678 12.6713 30.1193 12.1743 29.6224C11.6765 29.1246 11.4276 28.5264 11.4276 27.828V13.8591C11.4276 13.1607 11.6765 12.563 12.1743 12.066C12.6713 11.5682 13.269 11.3193 13.9674 11.3193H19.047ZM21.5868 11.3193H26.6664V8.77954H21.5868V11.3193ZM34.2858 22.7484H27.9363V25.2882H20.3169V22.7484H13.9674V27.828H34.2858V22.7484ZM22.8567 22.7484H25.3965V20.2086H22.8567V22.7484ZM13.9674 20.2086H20.3169V17.6688H27.9363V20.2086H34.2858V13.8591H13.9674V20.2086Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <path
        d="M43.6229 2.55798C44.8427 2.82088 45.7452 3.36579 46.3304 4.19272C46.9275 5.02223 47.101 6.017 46.851 7.17702C46.5907 8.38489 46.0121 9.2113 45.1153 9.65626C44.3922 10.015 43.5486 10.1403 42.5844 10.0322C42.1803 9.98693 41.7857 10.2221 41.68 10.6147C41.5743 11.0074 41.1794 11.2483 40.7819 11.1627L40.6118 11.126C40.1122 11.0183 39.7883 10.5336 39.88 10.0308L40.1241 8.69287C40.1836 8.36697 40.5009 8.15492 40.8247 8.22472C41.9848 8.47473 42.9033 8.50999 43.5802 8.3305C44.2691 8.15359 44.7076 7.62863 44.8957 6.75562C45.0323 6.12179 44.9539 5.58554 44.6605 5.14686C44.3791 4.71077 43.9155 4.42313 43.2697 4.28395C42.6239 4.14477 42.0782 4.20864 41.6328 4.47555C41.4429 4.58932 41.2813 4.73533 41.148 4.91358C40.8233 5.34781 40.3338 5.71613 39.8038 5.60189C39.2738 5.48766 38.9227 4.95611 39.1543 4.46584C39.3089 4.13844 39.5048 3.84316 39.7421 3.58001C40.1861 3.08753 40.7406 2.75025 41.4056 2.56819C42.0825 2.3887 42.8216 2.3853 43.6229 2.55798ZM39.7221 15.0828C39.3514 15.0029 39.0675 14.8103 38.8705 14.5051C38.6735 14.1998 38.6149 13.8618 38.6948 13.4911C38.7747 13.1204 38.9673 12.8365 39.2726 12.6395C39.5779 12.4425 39.9159 12.3839 40.2866 12.4638C40.6454 12.5411 40.9232 12.7324 41.1203 13.0377C41.3173 13.3429 41.3758 13.6809 41.2959 14.0517C41.216 14.4224 41.0235 14.7063 40.7182 14.9033C40.4129 15.1003 40.0809 15.1602 39.7221 15.0828Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <path
        d="M43.5598 8.25105L43.5592 8.25121C42.9006 8.42583 41.9971 8.39347 40.842 8.14453C40.4729 8.06498 40.1112 8.30668 40.0434 8.67815L39.7993 10.0161C39.6997 10.5624 40.0516 11.0892 40.5945 11.2062L40.7646 11.2429C41.2049 11.3377 41.6422 11.0709 41.7592 10.636C41.853 10.2875 42.2062 10.0724 42.5753 10.1138C43.5519 10.2233 44.4119 10.0968 45.1518 9.72974C46.0762 9.2711 46.667 8.42028 46.9312 7.19431C47.1852 6.01573 47.0099 4.99645 46.3971 4.14507C45.7975 3.29785 44.875 2.74394 43.6402 2.4778C42.8284 2.30285 42.076 2.30557 41.3846 2.4889L41.3846 2.4889L41.3839 2.48907C40.7045 2.67511 40.1361 3.02047 39.6812 3.52509C39.4384 3.79431 39.2381 4.09632 39.0801 4.43081C38.82 4.98142 39.2194 5.55985 39.7865 5.68208C40.366 5.80698 40.8842 5.40338 41.2137 4.96271C41.3406 4.79295 41.4943 4.65417 41.6749 4.54592C42.0981 4.29238 42.6212 4.2281 43.2524 4.36414C43.883 4.50005 44.3249 4.77817 44.5916 5.19134L44.5916 5.19135L44.5923 5.19247C44.8707 5.6086 44.9486 6.12093 44.8155 6.73834C44.6319 7.59026 44.2104 8.08398 43.5598 8.25105ZM38.8016 14.5496C39.011 14.874 39.3139 15.0788 39.7048 15.163C40.0846 15.2449 40.4389 15.1812 40.7627 14.9722C41.0871 14.7628 41.2919 14.4599 41.3761 14.069C41.4604 13.678 41.3986 13.3176 41.1892 12.9932C40.9802 12.6694 40.6837 12.4655 40.3039 12.3836C39.9129 12.2994 39.5525 12.3612 39.2281 12.5705C38.9037 12.7799 38.6989 13.0828 38.6147 13.4738C38.5304 13.8648 38.5922 14.2251 38.8016 14.5496Z"
        stroke="#082051"
        strokeOpacity="0.3"
        strokeWidth="0.164053"
      />
      <path
        d="M3.26394 2.40091C4.15931 1.85322 5.02508 1.66139 5.86124 1.82543C6.70618 1.98409 7.38907 2.48916 7.90991 3.34064C8.45223 4.22724 8.57942 5.0662 8.29146 5.85753C8.05929 6.49557 7.61827 7.06139 6.96841 7.555C6.69604 7.76188 6.59212 8.13408 6.75561 8.43451C6.91911 8.73494 6.81843 9.11079 6.52665 9.28927L6.40176 9.36566C6.03508 9.58996 5.55656 9.4827 5.3207 9.12334L4.693 8.16695C4.5401 7.93399 4.61032 7.62072 4.84803 7.47532C5.69951 6.95448 6.27929 6.44302 6.58736 5.94094C6.90422 5.43349 6.86666 4.85937 6.47468 4.21856C6.1901 3.75332 5.8312 3.47225 5.39799 3.37537C4.97356 3.27311 4.52434 3.36696 4.05032 3.65691C3.5763 3.94686 3.28109 4.30235 3.16468 4.72337C3.11507 4.90283 3.10139 5.0855 3.12364 5.27139C3.17787 5.72422 3.0935 6.23252 2.70445 6.47051C2.3154 6.70849 1.79318 6.58849 1.64972 6.15558C1.55392 5.86647 1.50192 5.57297 1.49371 5.27505C1.47835 4.7175 1.62032 4.19037 1.91962 3.69367C2.2277 3.19159 2.67581 2.76067 3.26394 2.40091ZM8.15589 12.2914C7.88377 12.4579 7.59916 12.5053 7.30206 12.4338C7.00496 12.3622 6.77318 12.1903 6.60673 11.9182C6.44027 11.6461 6.39283 11.3615 6.46441 11.0644C6.53599 10.7673 6.70785 10.5355 6.97997 10.369C7.24331 10.2079 7.52354 10.1632 7.82064 10.2348C8.11774 10.3064 8.34952 10.4782 8.51597 10.7503C8.68243 11.0224 8.72986 11.3071 8.65828 11.6042C8.5867 11.9013 8.41924 12.1304 8.15589 12.2914Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <path
        d="M6.52884 5.9044L6.52856 5.90485C6.22884 6.39331 5.65984 6.89786 4.81203 7.41646C4.54108 7.5822 4.46104 7.93927 4.63532 8.20481L5.26302 9.1612C5.51932 9.5517 6.03931 9.66826 6.43777 9.42452L6.56266 9.34813C6.88578 9.15047 6.99728 8.73423 6.81622 8.40153C6.67108 8.13484 6.76133 7.79893 7.01014 7.60994C7.6684 7.10995 8.11875 6.53393 8.3563 5.88113C8.6531 5.06547 8.51922 4.20453 7.96877 3.30464C7.4396 2.43955 6.74139 1.92058 5.87425 1.75767C5.0175 1.58967 4.13437 1.78759 3.22794 2.34205C2.63209 2.70653 2.17549 3.14476 1.86081 3.65758L1.86081 3.65758L1.86053 3.65806C1.5547 4.16559 1.409 4.70567 1.42474 5.27695C1.43314 5.58175 1.48635 5.88192 1.58423 6.17728C1.74535 6.66348 2.32414 6.78402 2.74045 6.52936C3.16583 6.26916 3.24718 5.72275 3.19215 5.26319C3.17095 5.08615 3.18397 4.91252 3.23118 4.74176C3.34175 4.34184 3.62301 3.99917 4.08632 3.71577C4.5492 3.43263 4.97971 3.34556 5.38183 3.44244L5.38183 3.44245L5.38294 3.4427C5.79389 3.53461 6.13861 3.80138 6.41583 4.25456C6.79833 4.87989 6.82808 5.42516 6.52884 5.9044ZM7.2859 12.5008C7.60164 12.5769 7.90493 12.5258 8.1919 12.3503C8.47067 12.1798 8.64944 11.9354 8.72536 11.6203C8.80143 11.3046 8.75037 11.0013 8.57483 10.7143C8.39929 10.4274 8.15254 10.2438 7.8368 10.1677C7.5217 10.0918 7.22274 10.1396 6.94397 10.3102C6.657 10.4857 6.47341 10.7325 6.39734 11.0482C6.32127 11.3639 6.37233 11.6672 6.54787 11.9542C6.72341 12.2412 6.97016 12.4248 7.2859 12.5008Z"
        stroke="#082051"
        strokeOpacity="0.3"
        strokeWidth="0.137991"
      />
    </svg>
  );
};
export default NoJobOpeningWasFoundIcon;
