import React from 'react';

const LinkIcon: React.FC = () => {
  return (
    <svg width="41" height="21" viewBox="0 0 41 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.291 0.710938H24.416C23.3389 0.710938 22.4577 1.59219 22.4577 2.66927C22.4577 3.74635 23.3389 4.6276 24.416 4.6276H30.291C33.5223 4.6276 36.166 7.27135 36.166 10.5026C36.166 13.7339 33.5223 16.3776 30.291 16.3776H24.416C23.3389 16.3776 22.4577 17.2589 22.4577 18.3359C22.4577 19.413 23.3389 20.2943 24.416 20.2943H30.291C35.696 20.2943 40.0827 15.9076 40.0827 10.5026C40.0827 5.0976 35.696 0.710938 30.291 0.710938ZM12.666 10.5026C12.666 11.5797 13.5473 12.4609 14.6243 12.4609H26.3743C27.4514 12.4609 28.3327 11.5797 28.3327 10.5026C28.3327 9.42552 27.4514 8.54427 26.3743 8.54427H14.6243C13.5473 8.54427 12.666 9.42552 12.666 10.5026ZM16.5827 16.3776H10.7077C7.47643 16.3776 4.83268 13.7339 4.83268 10.5026C4.83268 7.27135 7.47643 4.6276 10.7077 4.6276H16.5827C17.6598 4.6276 18.541 3.74635 18.541 2.66927C18.541 1.59219 17.6598 0.710938 16.5827 0.710938H10.7077C5.30268 0.710938 0.916016 5.0976 0.916016 10.5026C0.916016 15.9076 5.30268 20.2943 10.7077 20.2943H16.5827C17.6598 20.2943 18.541 19.413 18.541 18.3359C18.541 17.2589 17.6598 16.3776 16.5827 16.3776Z"
        fill="#2A6FF5"
      />
    </svg>
  );
};

export default LinkIcon;
