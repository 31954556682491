import { PropsWithChildren } from 'react';
import Skeleton from 'react-loading-skeleton';

const SettingsOptionWrapperSkeleton = ({
  children,
  titleClassName,
  subtitleClassName,
  arrayOfExtraLines,
}: { titleClassName: string; subtitleClassName: string; arrayOfExtraLines?: { extraLineClassName: string }[] } & PropsWithChildren) => {
  return (
    <section className="flex w-full lg:flex-row flex-col gap-y-6 gap-x-2">
      <div className="lg:w-1/3 w-full flex flex-col">
        <Skeleton height={16} containerClassName={`h-6 ${titleClassName}`} />
        <Skeleton height={14} containerClassName={`h-5 ${subtitleClassName}`} />
        {arrayOfExtraLines?.length &&
          arrayOfExtraLines.map(({ extraLineClassName }, index) => <Skeleton key={index} height={14} containerClassName={`h-5 ${extraLineClassName}`} />)}
      </div>
      <div className="flex w-full lg:w-2/3 flex-col space-y-8">{children}</div>
    </section>
  );
};

export default SettingsOptionWrapperSkeleton;
