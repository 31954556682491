/**
 * @generated SignedSource<<378dd6c539069020e1e0e84847f37ef0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OrganizationFleetsize = 'SIZE_0_5' | 'SIZE_25_100' | 'SIZE_26_100' | 'SIZE_2_25' | 'SIZE_6_25' | 'SIZE_MORE_100' | '%future added value';
export type OrganizationSize = 'SIZE_11_50' | 'SIZE_1_10' | 'SIZE_201_500' | 'SIZE_501_1000' | 'SIZE_51_200' | 'SIZE_MORE_1000' | '%future added value';
export type OrganizationData = {
  avatarUrl?: string | null;
  city?: string | null;
  countryId?: string | null;
  description?: string | null;
  fleetsize?: OrganizationFleetsize | null;
  headquarters?: string | null;
  name: string;
  size?: OrganizationSize | null;
  websiteUrl?: string | null;
};
export type ProfileMutationsUpdateOrganizationMutation$variables = {
  data: OrganizationData;
  id: string;
};
export type ProfileMutationsUpdateOrganizationMutation$data = {
  readonly updateOrganization: {
    readonly city: string | null;
    readonly country: {
      readonly id: string;
      readonly isoCode: string;
      readonly name: string;
    } | null;
    readonly id: string;
    readonly name: string;
    readonly size: OrganizationSize | null;
  };
};
export type ProfileMutationsUpdateOrganizationMutation = {
  response: ProfileMutationsUpdateOrganizationMutation$data;
  variables: ProfileMutationsUpdateOrganizationMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'data',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v4 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'data',
            variableName: 'data',
          },
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        concreteType: 'Organization',
        kind: 'LinkedField',
        name: 'updateOrganization',
        plural: false,
        selections: [
          v2 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'Country',
            kind: 'LinkedField',
            name: 'country',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'isoCode',
                storageKey: null,
              },
              v3 /*: any*/,
              v2 /*: any*/,
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'city',
            storageKey: null,
          },
          v3 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'size',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileMutationsUpdateOrganizationMutation',
      selections: v4 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'ProfileMutationsUpdateOrganizationMutation',
      selections: v4 /*: any*/,
    },
    params: {
      cacheID: '2a377948c9a76d144300da3b5f3cbdf3',
      id: null,
      metadata: {},
      name: 'ProfileMutationsUpdateOrganizationMutation',
      operationKind: 'mutation',
      text: 'mutation ProfileMutationsUpdateOrganizationMutation(\n  $id: ID!\n  $data: OrganizationData!\n) {\n  updateOrganization(id: $id, data: $data) {\n    id\n    country {\n      isoCode\n      name\n      id\n    }\n    city\n    name\n    size\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'e124c287cd8cd11a12e332c1a8c8d170';

export default node;
