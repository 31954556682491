// packages
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
import { TrashIcon, PencilIcon } from '@heroicons/react/24/outline';
// hooks
import { useGetProfileIdFromAuthOrParams } from 'hooks/useGetProfileIdFromAuthOrParams';
// components
import { Button } from 'system/Button';
import CountryFlag from 'system/CountryFlag';
import PeriodDate from 'system/PeriodDate/PeriodDate';
import ItemField from 'system/ListComponents/ItemField';
import ArrowSeaServiceIcon from 'icons/ArrowSeaServiceIcon';
import VesselImageComponents from 'system/VesselImageComponents/VesselImageComponents';
import ListWithShowMoreItemsWithPopover from 'system/ListWithShowMoreItems/ListWithShowMoreItemsWithPopover';
// generated
import { ServiceFragments$key } from 'schemas/services/__generated__/ServiceFragments.graphql';
import { ServiceFragmentsVessel$key } from 'schemas/services/__generated__/ServiceFragmentsVessel.graphql';
import { ServiceFragmentsOneDuty$key } from 'schemas/services/__generated__/ServiceFragmentsOneDuty.graphql';
import { ServiceFragmentsActivities$key } from 'schemas/services/__generated__/ServiceFragmentsActivities.graphql';
// schemas
import { VESSEL_FRAGMENT } from 'schemas/services/ServiceFragments';
import { GET_ONE_DUTY, SERVICE_ACTIVITIES, SERVICE_FRAGMENT } from 'schemas/services/ServiceFragments';
// models
import { ServiceItemProps } from 'models/modelsOfComponents';

export const ServiceItem: React.FC<ServiceItemProps> = ({ service, disabled, openUpdateModal, removeFunction, onClickItem, onOpenNoteModal }) => {
  const intl = useIntl();
  const { isSelfProfile } = useGetProfileIdFromAuthOrParams();

  const dataOfService = useFragment<ServiceFragments$key>(SERVICE_FRAGMENT, service);
  const vesselData = useFragment<ServiceFragmentsVessel$key>(VESSEL_FRAGMENT, dataOfService?.vessel || null);
  const dataOfDuty = useFragment<ServiceFragmentsOneDuty$key>(GET_ONE_DUTY, dataOfService.duty);
  const dataOfActivities = useFragment<ServiceFragmentsActivities$key>(SERVICE_ACTIVITIES, dataOfService.activities);

  const serviceBottomPartVisible: boolean = !!(vesselData.grossTonnage || vesselData.operatorName || vesselData?.kind?.name);
  const [isShowMoreClicked, setShowMoreClicked] = useState<boolean>(false);

  const showMoreNote = useCallback(() => {
    setShowMoreClicked(true);
  }, []);

  useEffect(() => {
    if (dataOfService?.description && dataOfService?.description.length < 70) {
      setShowMoreClicked(true);
    } else {
      setShowMoreClicked(false);
    }
  }, [dataOfService?.description]);

  const renderControlButton = useMemo(
    () => (
      <>
        {!dataOfService?.description && (
          <Button
            additionalClasses="text-sm xs:mr-5 mr-1"
            label={{ id: 'service_note' }}
            onClick={e => {
              e.stopPropagation();
              if (onOpenNoteModal) {
                onOpenNoteModal(service);
              }
            }}
            buttonType="white-primary-rounded-xl"
          />
        )}
        <Button
          additionalClasses="text-sm xs:mr-5 mr-1"
          label={{ id: 'label_edit_button' }}
          disabled={disabled}
          onClick={e => {
            e.stopPropagation();
            openUpdateModal(service);
          }}
          buttonType="white-primary-rounded-xl"
        />

        <Button
          disabled={disabled}
          icon={TrashIcon}
          onClick={e => {
            e.stopPropagation();
            removeFunction(dataOfService.id);
          }}
          buttonType="just-icon-rounded-xl"
        />
      </>
    ),
    [dataOfService?.description, dataOfService.id, disabled, onOpenNoteModal, openUpdateModal, removeFunction, service],
  );

  const renderDescription = useMemo(
    () => (
      <div className="flex flex-wrap flex-row mt-2">
        <span className="mr-2">
          <ArrowSeaServiceIcon />
        </span>
        <ItemField
          contentFieldClasses="ml-1"
          labelField={{ id: 'sea_service_description' }}
          contentField={isShowMoreClicked ? dataOfService?.description : dataOfService?.description?.substring(0, 70)}
        >
          {!isShowMoreClicked && (
            <Button
              additionalClasses="text-sm ml-1"
              label={{ id: 'service_three_dots' }}
              labelClasses={'font-medium'}
              disabled={disabled}
              buttonType="icon-blue-only"
              onClick={e => {
                e.stopPropagation();
                showMoreNote();
              }}
            />
          )}
          <button
            className="text-blue font-medium text-sm"
            onClick={e => {
              e.stopPropagation();
              if (onOpenNoteModal) {
                onOpenNoteModal(service);
              }
            }}
          >
            <PencilIcon className=" relative top-0.5 ml-2 h-4 w-4" />
          </button>
        </ItemField>
      </div>
    ),
    [dataOfService?.description, disabled, isShowMoreClicked, onOpenNoteModal, service, showMoreNote],
  );

  return (
    <div
      className="sm:mx-5 mt-5 rounded-2xl border border-specialGray-012 bg-white cursor-pointer md:flex block"
      onClick={() => {
        onClickItem(dataOfService);
      }}
    >
      {/* dates */}
      <div className="w-auto md:w-40 text-sm border-b md:border-b-0 md:border-r text-center px-5 flex flex-col justify-center align-middle text-specialGray-1 py-3 md:py-0">
        <PeriodDate start={dataOfService.start} end={dataOfService.end} withDuration />
      </div>
      {/*150px - width of section with date*/}
      <section className="w-full sm:w-[calc(100%_-_150px)]">
        <div className="relative flex md:flex-row flex-col px-3 py-3">
          <div className="flex flex-row-reverse md:flex-row justify-between md:justify-start">
            {/* Ship photo */}
            <VesselImageComponents src={vesselData?.image} alt={vesselData?.name} />

            <section className="flex flex-col md:ml-5 justify-around">
              <section className="flex flex-wrap flex-col sm:flex-row">
                {/* duty */}
                {vesselData?.name && (
                  <ItemField contentField={dataOfDuty.name} contentFieldClasses="text-specialGray-075 font-normal" labelFieldAfter={{ id: 'sea_service_on_the' }} />
                )}
              </section>
              {/* vessel name */}
              <div>
                <CountryFlag countryId={vesselData?.cnISO} countryName={vesselData?.countryName} />
                <span className="ml-2 text-darkBlue">{vesselData?.name}</span>
              </div>
            </section>
          </div>

          {dataOfService?.description ? <div className="flex sm:hidden">{renderDescription}</div> : null}

          {/* edit / remove buttons */}
          {isSelfProfile && <section className="flex sm:hidden md:flex items-center ml-auto mt-[18px] md:mb-0">{renderControlButton}</section>}
        </div>

        {serviceBottomPartVisible && (
          <div className="px-3 py-3 pt-0 sm:flex flex-col hidden w-full">
            <div className="flex">
              <span className="text-sm text-specialGray-075 mr-3">{intl.formatMessage({ id: 'info' })}&#58;</span>
              <div className="flex flex-wrap flex-col sm:flex-row">
                {/* rotations */}
                {dataOfService.rotations && dataOfService.rotations > 1 && (
                  <ItemField labelField={{ id: 'sea_service_rotations' }} contentField={dataOfService.rotations} contentFieldClasses="text-specialGray-1" />
                )}
                {/* operator name */}
                {vesselData?.operatorName && (
                  <ItemField labelField={{ id: 'sea_service_operator' }} contentField={vesselData.operatorName} contentFieldClasses="text-specialGray-1" />
                )}
                {/* vessel length */}
                {!!vesselData?.length && (
                  <ItemField labelField={{ id: 'sea_service_length_item' }} contentField={`${vesselData.length} m`} contentFieldClasses="text-specialGray-1" />
                )}
                {/* gross tonnage */}
                {!!vesselData?.grossTonnage && (
                  <ItemField labelField={{ id: 'sea_service_gross_tonnage_item' }} contentField={vesselData.grossTonnage} contentFieldClasses="text-specialGray-1" />
                )}
                {/* type */}
                {vesselData?.name && <ItemField labelField={{ id: 'sea_service_type' }} contentField={vesselData?.name} contentFieldClasses="text-specialGray-1" />}
              </div>
            </div>
            {dataOfService?.description && renderDescription}

            {!isEmpty(dataOfActivities) && (
              <div className="hidden sm:flex mt-3">
                <ListWithShowMoreItemsWithPopover list={dataOfActivities} field="label" />
              </div>
            )}
            {isSelfProfile && <section className="hidden sm:flex md:hidden items-center ml-auto mt-4">{renderControlButton}</section>}
          </div>
        )}
      </section>
    </div>
  );
};
