import { differenceInYears } from 'date-fns';
// components
import ProfileBasicInformationItem from './ProfileBasicInformationItem';

const ProfileAgeInfo = ({ birthDate }: { birthDate?: String }) => {
  return (
    <ProfileBasicInformationItem label={{ id: 'short_years_single_y_o' }}>
      <span data-test-id="profile-age" className="w-6 sm:w-auto">
        {birthDate ? differenceInYears(new Date(), new Date(birthDate as string)) : <span className="font-semibold">&#8208;</span>}
      </span>
    </ProfileBasicInformationItem>
  );
};

export default ProfileAgeInfo;
