import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
// components
import CommercialAvatar from 'app/Avatars/CommercialAvatar/CommercialAvatar';
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';
import OrganizationsMembershipInfoDropDown from './OrganizationsMembershipInfoDropDown';
// types
import { OrganizationMembershipItemProps } from './types';
// generated
import { ProfileFragmentsOrganizationPlural_profile$data } from 'schemas/profile/__generated__/ProfileFragmentsOrganizationPlural_profile.graphql';
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';
// hooks
import { useAuth } from 'authentication';

const OrganizationsMembershipInfo = ({ profileOrganizations, profileId }: { profileOrganizations: ProfileFragmentsOrganizationPlural_profile$data | null; profileId: string }) => {
  const { identity } = useAuth();
  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);

  const getHighlightItemStyle = useCallback(
    (orgId: string) => (`${identity?.profileId}` === profileId && getCurrentOrganisation.organisationId === orgId ? 'border-2 border-blue rounded-md' : ''),
    [getCurrentOrganisation.organisationId, identity?.profileId, profileId],
  );

  const sortedProfileOrg = useMemo(() => {
    if (!profileOrganizations) {
      return;
    }

    return profileOrganizations?.reduce(
      (acc: { firstOrganizationsMembership: OrganizationMembershipItemProps[]; dropDownOrgMembership: OrganizationMembershipItemProps[] }, item) => {
        if (!item || item.role === 'invited') {
          return acc;
        }

        if (acc.firstOrganizationsMembership.length < 3) {
          if (item.organization.id === getCurrentOrganisation.organisationId) {
            acc.firstOrganizationsMembership?.unshift(item);
          } else {
            acc.firstOrganizationsMembership?.push(item);
          }
        } else {
          if (item.organization.id === getCurrentOrganisation.organisationId) {
            const firstOrganisation = acc.firstOrganizationsMembership.shift();
            acc.firstOrganizationsMembership.unshift(item);
            if (!!firstOrganisation) {
              acc.dropDownOrgMembership.push(firstOrganisation);
            }
          } else {
            acc.dropDownOrgMembership?.push(item);
          }
        }
        return acc;
      },
      {
        firstOrganizationsMembership: [],
        dropDownOrgMembership: [],
      },
    );
  }, [getCurrentOrganisation.organisationId, profileOrganizations]);

  return (
    <div className="flex flex-wrap gap-1 items-center justify-center">
      {profileOrganizations?.length ? (
        <>
          {sortedProfileOrg?.firstOrganizationsMembership?.map(organizationItem => (
            <PopoverHelper
              key={organizationItem.organization.id}
              widthContainer="w-fit"
              positionArrow="-bottom-[3px] left-1/2 rotate-45"
              position="-translate-y-full -top-2 -translate-x-1/2 left-3"
              darkTheme
              element={
                <div className={getHighlightItemStyle(organizationItem.organization.id)}>
                  <CommercialAvatar avatarUrl={organizationItem?.organization?.avatarUrl} size="w-[22px] h-[22px]" />
                </div>
              }
            >
              {organizationItem.organization.name}
            </PopoverHelper>
          ))}
          {!!sortedProfileOrg?.dropDownOrgMembership?.length && <OrganizationsMembershipInfoDropDown orgMembership={sortedProfileOrg?.dropDownOrgMembership} />}
        </>
      ) : (
        <span className="flex w-5 sm:w-fit font-semibold items-center justify-center">&#8208;</span>
      )}
    </div>
  );
};

export default OrganizationsMembershipInfo;
