import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from 'authentication';
import { PLATFORM_GROUP } from '../models/enums';

export function useGetProfileIdFromAuthOrParams() {
  const { id } = useParams();
  const { identity } = useAuth();

  const isSelfProfile = useMemo<boolean>(
    () => !id && !!identity?.profileId && identity?.platformGroup !== PLATFORM_GROUP.GUEST,
    [id, identity?.platformGroup, identity?.profileId],
  );
  const getProfileIdFromAuthOrParams = useMemo<string>(() => id || `${identity?.profileId}`, [id, identity?.profileId]);

  return {
    isSelfProfile,
    getProfileIdFromAuthOrParams,
  };
}
