// packages
import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLazyLoadQuery } from 'react-relay';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
// routing
import { JOB_RESPONSES_TABS, RoutePaths } from 'app/routing';
import { VacancyQueriesListOfVacanciesQuery } from 'schemas/vacancy/__generated__/VacancyQueriesListOfVacanciesQuery.graphql';
import { LIST_OF_VACANCIES } from 'schemas/vacancy/VacancyQueries';
// components
import CustomNavLink from 'system/CustomNavLink/CustomNavLink';

const JobResponsesPage = () => {
  const invitationVacancies = useLazyLoadQuery<VacancyQueriesListOfVacanciesQuery>(
    LIST_OF_VACANCIES,
    {
      skip: false,
      filters: [{ field: 'INVITED', values: [] }],
    },
    { fetchPolicy: 'store-and-network' },
  );

  const appliedVacancies = useLazyLoadQuery<VacancyQueriesListOfVacanciesQuery>(
    LIST_OF_VACANCIES,
    {
      skip: false,
      filters: [{ field: 'APPLIED', values: [] }],
    },
    { fetchPolicy: 'store-and-network' },
  );

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === RoutePaths.JobResponses) {
      navigate(`${RoutePaths.JobResponses}/${RoutePaths.JobResponsesInvitation}`, { replace: true });
    }
  }, [navigate, pathname]);

  const handleMoveByLink = useCallback(
    (tabHref: string) => (event: React.MouseEvent) => {
      event.preventDefault();
      if (tabHref === RoutePaths.JobResponsesInvitation) window.analytics?.track('wui-job-responses-invitations-click');
      if (tabHref === RoutePaths.JobResponsesApplied) window.analytics?.track('wui-job-responses-applied-click');
      navigate(tabHref);
    },
    [navigate],
  );

  const getNumberOfVacancies = useCallback(
    (href: string) => {
      switch (href) {
        case RoutePaths.JobResponsesApplied:
          return appliedVacancies.vacancies?.edges?.length;
        default:
          return invitationVacancies.vacancies?.edges?.length;
      }
    },
    [invitationVacancies.vacancies?.edges?.length, appliedVacancies.vacancies?.edges?.length],
  );

  const intl = useIntl();
  return (
    <div className="flex flex-col w-full">
      <h2 id="sea-service-title" className="flex-1 min-w-0 text-2xl leading-6 font-semibold text-specialGray-1">
        {intl.formatMessage({ id: 'job_responses_main_title' })}
      </h2>
      <div className="flex items-center justify-between space-x-5">
        <div className="w-full border-b border-gray-200">
          <nav className="-mb-px flex mt-5" aria-label="Tabs">
            <>
              {JOB_RESPONSES_TABS.map(tab => (
                <CustomNavLink
                  to={tab.href}
                  key={tab.name}
                  className="whitespace-nowrap py-4 px-5"
                  onClick={handleMoveByLink(tab.href)}
                  PrefixIcon={tab.activeIcon}
                  count={`${getNumberOfVacancies(tab.href)}`}
                >
                  {tab.name}
                </CustomNavLink>
              ))}
            </>
          </nav>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default JobResponsesPage;
