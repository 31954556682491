//components
import CertificateWithoutDocDateItem from './CertificateWithoutDocDateItem';
//types
import { CertificateWithoutDocumentsItemProps } from 'app/Profile/Overview/types';

const CertificateWithoutDocumentsItem = ({ children, title, expiryDate, alts }: CertificateWithoutDocumentsItemProps) => {
  return (
    <>
      <div className="flex flex-col sm:flex-row w-full items-center py-2 sm:py-0 space-y-1 sm:space-y-0">
        <CertificateWithoutDocDateItem expiryDate={expiryDate} />
        <div className="flex flex-col sm:flex-row w-full sm:ml-6 lg:ml-10 sm:justify-between space-y-2 sm:space-y-0 items-start=start sm:items-center">
          <span className="text-darkBlue">{title}</span>
          <div className="inline-grid sm:max-w-[50%]">
            <span className="sm:text-end sm:truncate">{alts}</span>
          </div>
        </div>
        {children}
      </div>
    </>
  );
};

export default CertificateWithoutDocumentsItem;
