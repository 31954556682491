// packages
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { useIntl } from 'react-intl';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
// components
import { FieldErrorMessage } from './FieldErrorMessage';
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';
// models
import { TextareaInputGroupProps } from 'models/modelsOfComponents';

export const TextareaInputGroup: React.FC<TextareaInputGroupProps> = ({
  name,
  label,
  description,
  helpText,
  placeholderText,
  autoFocus = false,
  maxCharCount = 0,
  optional = false,
  minRows,
  autoHeight,
  breakLinesLimit,
}) => {
  const intl = useIntl();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [field, meta, helper] = useField<string>({
    name,
    validate: v => (maxCharCount && v.length > maxCharCount ? 'text_area_input_group_too_many_chars' : ''),
  });
  const showError = meta.touched && meta.error;
  const classNames = showError
    ? 'block w-full pr-10 border-red-300 text-red-900 placeholder:text-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
    : 'shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full text-darkBlue text-xs sm:text-sm placeholder:text-specialGray-05 border-specialGray-012 rounded-md';

  const [count, setCount] = useState(meta.initialValue ? meta.initialValue.length : 0);

  const onChange = field.onChange;
  field.onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setCount(ev.target.value.length);
    onChange(ev);

    if (!breakLinesLimit) return;

    let newText = ev.target.value;
    const event = (ev.nativeEvent as InputEvent).inputType;

    //case for copy/paste with line breaks
    if (event.includes('insertFrom') || event.includes('delete')) {
      newText = newText.replace(/\n{3,}/gm, '\n\n');
      const cursor = ev.target.selectionStart;
      //return cursor to correct place after removing the line
      window.requestAnimationFrame(() => {
        ev.target.setSelectionRange(cursor, cursor);
      });
    }
    helper.setValue(newText.trimStart());
  };

  //auto resize textarea by height
  useEffect(() => {
    if (textareaRef.current && autoHeight) {
      textareaRef.current.style.height = `${autoHeight + 2}px`;
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    }
  }, [field.value, minRows, autoHeight]);

  useEffect(() => {
    if (!meta.touched) {
      setCount(meta.initialValue?.length || 0);
    }
  }, [meta.initialValue, meta.touched]);

  useEffect(() => {
    if (autoFocus) {
      (textareaRef.current as HTMLTextAreaElement).focus();
    }
  }, [autoFocus, name]);

  const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = e => {
    if (e.key !== 'Enter' || !breakLinesLimit) return;
    if (!e.currentTarget.value.length) return e.preventDefault();

    const value = e.currentTarget.value;
    const currentPlace = e.currentTarget.selectionStart;
    const isPrevNewLine = value[currentPlace - 1] === '\n';
    const isNextNewLine = value[currentPlace] === '\n';
    if (isPrevNewLine && isNextNewLine) return e.preventDefault();

    const isNextDoubleNewLine = isNextNewLine && value[currentPlace + 1] === '\n';
    if (isNextDoubleNewLine) return e.preventDefault();

    const isPrevDoubleNewLine = isPrevNewLine && value[currentPlace - 2] === '\n';
    if (isPrevDoubleNewLine) return e.preventDefault();
  };

  return (
    <div>
      <div className="flex justify-between">
        <label className="inline-block text-sm font-medium text-darkBlue">
          {intl.formatMessage(label)}&nbsp;
          {optional && <span className="ml-1 text-specialGray-05 text-xs font-medium">{intl.formatMessage({ id: 'optional' })}</span>}
          {helpText && (
            <PopoverHelper
              position="bottom-7 sm:left-1/2 sm:-translate-x-1/2 -left-4"
              positionArrow="sm:left-1/2 left-6 rotate-45 -bottom-[3px]"
              defaultText={intl.formatMessage(helpText)}
              symbol="?"
              classes="relative left-1 -bottom-1"
              darkTheme
              widthContainer="w-60 sm:w-[300px]"
            />
          )}
        </label>

        {maxCharCount > 0 && (
          <span className="text-xs sm:text-sm text-specialGray-05 my-auto sm:my-0">
            ({count}/{maxCharCount})
          </span>
        )}
      </div>

      <div className={'mt-2 ' + (showError ? 'relative rounded-md shadow-sm' : '')}>
        <textarea
          {...field}
          id={name}
          data-test-id={name}
          onKeyDown={onKeyDown}
          className={classNames}
          placeholder={placeholderText && intl.formatMessage(placeholderText)}
          aria-describedby={description && name + '-description'}
          ref={textareaRef}
          rows={minRows}
        />

        {showError && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {/* show field errors in various configurations */}
      {showError && <FieldErrorMessage error={meta.error} />}

      {/* Only show the description if there is no error */}
      {!showError && description && (
        <p className="mt-2 text-sm text-specialGray-075" id={name + '-description'}>
          {intl.formatMessage(description, { maxChar: maxCharCount })}
        </p>
      )}
    </div>
  );
};
