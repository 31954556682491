/**
 * @generated SignedSource<<98a569a663b350936250fa4d0b05169c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NotificationPreferenceData = {
  jobOpening?: boolean | null;
  ownerId: string;
  tipsToImprove?: boolean | null;
};
export type ProfileMutationsCreateNotificationPreferenceMutation$variables = {
  data: NotificationPreferenceData;
};
export type ProfileMutationsCreateNotificationPreferenceMutation$data = {
  readonly createNotificationPreference: {
    readonly id: string;
    readonly jobOpening: boolean;
    readonly tipsToImprove: boolean;
  };
};
export type ProfileMutationsCreateNotificationPreferenceMutation = {
  response: ProfileMutationsCreateNotificationPreferenceMutation$data;
  variables: ProfileMutationsCreateNotificationPreferenceMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'data',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'data',
            variableName: 'data',
          },
        ],
        concreteType: 'NotificationPreference',
        kind: 'LinkedField',
        name: 'createNotificationPreference',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'jobOpening',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'tipsToImprove',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileMutationsCreateNotificationPreferenceMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileMutationsCreateNotificationPreferenceMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '1bc37f7a1495baa507a7fc88dee6325e',
      id: null,
      metadata: {},
      name: 'ProfileMutationsCreateNotificationPreferenceMutation',
      operationKind: 'mutation',
      text: 'mutation ProfileMutationsCreateNotificationPreferenceMutation(\n  $data: NotificationPreferenceData!\n) {\n  createNotificationPreference(data: $data) {\n    id\n    jobOpening\n    tipsToImprove\n  }\n}\n',
    },
  };
})();

(node as any).hash = '9c51735ed8333027aec8b284be012090';

export default node;
