//components
import ShieldIcon from 'icons/ShieldIcon';
//models
import { CertificateComponentProps } from 'models/modelsOfComponents';

const CertificateComponent = ({ certificate }: CertificateComponentProps) => {
  return (
    <div className="flex items-start p-4 border border-specialGray-012 w-full first:rounded-t-xl last:mb-4 [&:not(:first-child)]:border-t-0 last:rounded-b-xl">
      <ShieldIcon kind={certificate.certificate?.kind?.name} />
      <span className="text-sm leading-6 text-specialGray-1 relative bottom-0.5 ml-4">{certificate.certificate.name}</span>
    </div>
  );
};

export default CertificateComponent;
