// routes
import { RoutesPathNames } from 'app/routing';
// components
import { AuthSection } from 'auth/AuthSection';
import { ChildrenRoutes } from './childrenRoutes/ChildrenRoutes';

export const AuthRoute = {
  path: `/${RoutesPathNames.authBase}/*`,
  Component: AuthSection,
  children: ChildrenRoutes,
};
