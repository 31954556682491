import { FC } from 'react';

const ReviewProposalsIcon: FC = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7323_64730)">
        <path
          d="M7.5 13L6.9625 12.4625C6.225 11.725 6.23125 10.525 6.975 9.8L7.5 9.2875C7.25625 9.2625 7.075 9.25 6.875 9.25C5.20625 9.25 1.875 10.0875 1.875 11.75V13H7.5ZM6.875 8C8.25625 8 9.375 6.88125 9.375 5.5C9.375 4.11875 8.25625 3 6.875 3C5.49375 3 4.375 4.11875 4.375 5.5C4.375 6.88125 5.49375 8 6.875 8Z"
          fill="white"
        />
        <path
          d="M10.1123 12.8656C9.86856 13.1094 9.46855 13.1094 9.2248 12.8656L7.93105 11.5594C7.69355 11.3156 7.69355 10.9281 7.93105 10.6906L7.9373 10.6844C8.18105 10.4406 8.57481 10.4406 8.81231 10.6844L9.66856 11.5406L12.4373 8.75313C12.6811 8.50937 13.0748 8.50937 13.3186 8.75313L13.3248 8.75938C13.5623 9.00313 13.5623 9.39063 13.3248 9.62813L10.1123 12.8656Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7323_64730">
          <rect width="15" height="15" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReviewProposalsIcon;
