// packages
import { Suspense, useCallback, useState } from 'react';
// components
import { ServiceForm } from './ServiceForm';
import AlertForError from 'system/Alert/AlertForError';
import ServiceFormSkeleton from 'system/skeletons/servies/ServiceFormSkeleton';
// types
import { ServiceErrorBoundaryProps } from './types';

const ErrorBoundaryForm = (props: ServiceErrorBoundaryProps) => {
  const [error, setError] = useState<Error | null>(null);

  const getHeadForAlert = useCallback(() => {
    return (error as Error)?.message === 'service_not_exist_vessel_by_imo' ? 'service_form_vessel_did_not_find' : 'service_form_failedSubmit';
  }, [error]);

  const getTypeForAlert = useCallback(() => {
    return (error as Error)?.message === 'service_not_exist_vessel_by_imo' ? 'warning' : 'error';
  }, [error]);

  const handleError = useCallback((error: Error | null) => {
    setError(error);
  }, []);

  return (
    <>
      {error ? <AlertForError heading={{ id: getHeadForAlert() }} error={error as Error} type={getTypeForAlert()} /> : null}
      <Suspense fallback={<ServiceFormSkeleton />}>
        <ServiceForm {...props} handleError={handleError} globalError={error as Error} />
      </Suspense>
    </>
  );
};

export default ErrorBoundaryForm;
