// packages
import { useCallback, useEffect, useRef } from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
// models
import { DataListProps } from 'models/modelsOfComponents';
// hooks
import { useChooseAndFocusElement } from 'hooks/useChooseAndFocusElement';

const DropDownList = ({ options, onChooseElement, errorMessage, otherActivity, defaultFocusedElement, defaultSelectedElement, children, isLong }: DataListProps) => {
  const intl = useIntl();
  const { setChooseElement, setElementInFocus, setIndexChoseElement, indexChoseElement, elementInFocus, chooseElement } = useChooseAndFocusElement(options, defaultFocusedElement);
  const dataListRef = useRef<HTMLDivElement>(null);
  const defaultElementStyles = 'relative py-1.5 px-8 flex items-center text-xs sm:text-sm cursor-pointer';
  const hoverElementStyles = (disabled: boolean) => {
    return disabled ? 'hover:bg-gray-100 hover:text-specialGray-03' : 'hover:bg-specialGray-005 hover:text-darkBlue';
  };

  useEffect(() => {
    if (defaultSelectedElement && defaultFocusedElement) {
      setChooseElement(defaultSelectedElement?.id);
      setElementInFocus(defaultFocusedElement?.id);
    } else if (defaultSelectedElement) {
      setChooseElement(defaultSelectedElement?.id);
      setElementInFocus(defaultSelectedElement?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectedElement, defaultFocusedElement]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (elementInFocus) {
        const element = document.getElementById(elementInFocus);
        if (element) {
          element.scrollIntoView({ block: 'nearest' });
        }
      }
    }, 50);

    return () => {
      clearTimeout(timer as NodeJS.Timeout);
    };
  }, [elementInFocus]);

  useEffect(() => {
    return () => {
      setElementInFocus('');
    };
  }, [setElementInFocus]);

  const handleFocus = useCallback(
    (elementValue: string, disabled: false) => {
      if (elementValue === elementInFocus) {
        return disabled ? 'bg-gray-100 text-specialGray-03' : 'bg-specialGray-005 text-specialGray-1';
      } else {
        return disabled ? 'text-specialGray-03' : 'text-specialGray-08';
      }
    },
    [elementInFocus],
  );

  const handleChoose = useCallback(
    (elementValue: string) => {
      return elementValue === chooseElement;
    },
    [chooseElement],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const array = dataListRef?.current?.children;

      if (array) {
        const element = array[indexChoseElement];
        let previousElementValue = '';
        let nextElementValue = '';
        if (element && element.previousElementSibling) {
          previousElementValue = (element.previousElementSibling as HTMLDivElement)?.id;
        }
        if (element && element.nextElementSibling) {
          nextElementValue = (element.nextElementSibling as HTMLDivElement)?.id;
        }
        if (event.code === 'ArrowUp' && !!previousElementValue) {
          const prevIndex = indexChoseElement - 1;
          setElementInFocus(previousElementValue);
          setIndexChoseElement(prevIndex < 0 ? 0 : prevIndex);
          return;
        } else if (event.code === 'ArrowDown' && !!nextElementValue) {
          const nextIndex = indexChoseElement + 1;
          setElementInFocus(nextElementValue);
          setIndexChoseElement(nextIndex);
          return;
        } else if (event.code === 'Enter' || event.code === 'NumpadEnter') {
          event.preventDefault();
          if ((element as HTMLDivElement)?.id === elementInFocus) {
            const findElementFormOptions = options.find((el: any) => el.id === elementInFocus);
            !findElementFormOptions.disabled && onChooseElement(event, elementInFocus);
            return;
          }
        }
      }
    },
    [dataListRef, elementInFocus, indexChoseElement, setElementInFocus, setIndexChoseElement, onChooseElement, options],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div
      className={`flex absolute b-0 flex-col bg-white w-full border-solid border border-specialGray-012 rounded-md ${
        isLong ? 'max-h-80' : 'max-h-40'
      } overflow-y-auto z-10 shadow-xl`}
      ref={dataListRef}
    >
      {!isEmpty(options) && !errorMessage ? (
        options?.map((option: any, index: number) => {
          return (
            <div
              data-test-id={option.name}
              id={option?.id}
              key={`${option?.id}-${index}`}
              onMouseDown={event => !option.disabled && onChooseElement(event, option?.id!)}
              className={`${handleFocus(option?.id, option?.disabled)} ${hoverElementStyles(option?.disabled)} ${defaultElementStyles} ${
                option.disabled ? 'cursor-not-allowed bg-gray-100' : ''
              }`}
            >
              {otherActivity && index === options?.length - 1 && intl.formatMessage(otherActivity)}
              {handleChoose(option?.id) && <CheckIcon className="absolute left-2 w-5 h-5 mr-2" aria-hidden="true" />}
              {option?.label || option?.name}
              {!isEmpty(option.alts) && (
                <>
                  {option.alts.length === 0 || option.alts.length === 1 ? <span>&nbsp;</span> : <span>&#44;&nbsp;</span>}
                  <span className="text-specialGray-05" id={option.alts.join(', ')}>
                    {option.alts && option.alts.join(', ')}
                  </span>
                </>
              )}
            </div>
          );
        })
      ) : errorMessage ? (
        <div className="mx-auto my-2">{intl.formatMessage(errorMessage)}</div>
      ) : (
        children
      )}
    </div>
  );
};

export default DropDownList;
