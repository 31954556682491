import Skeleton from 'react-loading-skeleton';
//components
import SettingsOptionToggleSkeleton from './components/SettingsOptionToggleSkeleton';
import SettingsOptionWrapperSkeleton from './components/SettingsOptionWrapperSkeleton';

const CrewSettingsSkeletonRightSide = () => (
  <div className="w-full">
    <div className="flex flex-col pl-4 md:pl-8 sm:pt-[49px] pt-7 pr-4 md:pr-8 lg:pr-16 w-full">
      {/*main title*/}
      <section className="sm:mb-9 mb-5 w-full">
        <Skeleton height={24} containerClassName="flex items-end h-9 w-3/4 sm:w-[200px]" />
        <Skeleton height={14} containerClassName="flex items-end w-full sm:w-[350px] h-5" />
      </section>
      {/*change password*/}
      <section className="flex w-full lg:flex-row flex-col gap-y-6 gap-x-2 sm:mb-9">
        <div className="lg:w-1/3 w-full flex flex-col">
          <Skeleton height={16} containerClassName="flex w-[160px] h-6 items-end" />
          <Skeleton height={14} containerClassName="flex w-full max-w-[265px] items-end h-5" />
          <Skeleton height={14} containerClassName="hidden lg:flex w-full max-w-[130px] items-end h-5" />
        </div>
        <div className="flex flex-col w-full lg:w-[430px] xl:w-[640px] gap-y-6 items-end">
          <div className="flex flex-col w-full">
            <Skeleton height={14} width={100} containerClassName="h-5" />
            <Skeleton height={48} borderRadius={10} />
          </div>

          <div className="flex flex-col w-full">
            <Skeleton height={14} width={100} containerClassName="h-5" />
            <Skeleton height={48} borderRadius={10} />
            <Skeleton height={14} containerClassName="w-full h-5" />
            <Skeleton height={14} containerClassName="w-2/6 h-5" />
            <Skeleton containerClassName="flex mt-5 w-full max-w-[240px] h-5" height={48} borderRadius={10} />
          </div>
        </div>
      </section>
      {/*toggle buttons*/}
      <div className="flex w-full flex-col sm:space-y-[58px] space-y-8 mt-12 mb-12">
        {/*Availability*/}
        <SettingsOptionWrapperSkeleton titleClassName="w-20" subtitleClassName="w-full max-w-[265px]">
          <SettingsOptionToggleSkeleton titleClassName="w-full max-w-[65px]" subtitleClassName="w-full sm:w-[370px]" />
        </SettingsOptionWrapperSkeleton>

        {/*Account visibility*/}
        <SettingsOptionWrapperSkeleton titleClassName="w-32" subtitleClassName="w-full sm:w-56" arrayOfExtraLines={[{ extraLineClassName: 'w-full max-w-[80px]' }]}>
          <SettingsOptionToggleSkeleton titleClassName="w-full max-w-[250px]" subtitleClassName="w-full max-w-[320px]" />
          <SettingsOptionToggleSkeleton titleClassName="w-full max-w-[145px]" subtitleClassName="w-full max-w-[290px]" />
        </SettingsOptionWrapperSkeleton>

        {/*Notification preferences*/}
        <SettingsOptionWrapperSkeleton titleClassName="w-44" subtitleClassName="w-full max-w-[240px]" arrayOfExtraLines={[{ extraLineClassName: 'w-full max-w-[60px]' }]}>
          <SettingsOptionToggleSkeleton titleClassName="w-full sm:w-56" subtitleClassName="w-full max-w-[600px]" />
          <SettingsOptionToggleSkeleton titleClassName="w-full sm:w-52" subtitleClassName="w-full max-w-[470px]" />
        </SettingsOptionWrapperSkeleton>
      </div>
      {/*delete button*/}
      <div className="flex w-full lg:flex-row flex-col gap-y-6 gap-x-2">
        <div className="lg:w-1/3 w-full flex flex-col">
          <Skeleton height={16} containerClassName="flex w-full max-w-[125px] h-6 items-end h-5" />
          <Skeleton height={14} containerClassName="flex w-full max-w-[250px] items-end h-5" />
          <Skeleton height={14} containerClassName="flex w-full max-w-[250px] items-end h-5" />
          <Skeleton height={14} containerClassName="hidden lg:flex w-full max-w-[80px] items-end h-5" />
        </div>
        <Skeleton width={140} height={40} borderRadius={10} containerClassName="flex w-full max-w-[140px]" />
      </div>
    </div>
  </div>
);

export default CrewSettingsSkeletonRightSide;
