import HeaderSettings from './components/HeaderSettings';
import { ProfileFragments$data } from 'schemas/profile/__generated__/ProfileFragments.graphql';

const SettingsLeftSide = ({ profileData }: { profileData: ProfileFragments$data | null }) => {
  return (
    <aside className="w-fit md:w-1/4 border-r border-solid border-specialGray-012 sm:pt-[53px] pt-8 xl:pl-[51px] lg:pl-6 px-3 lg:space-y-[44px] space-y-6">
      <HeaderSettings profileData={profileData} />
    </aside>
  );
};

export default SettingsLeftSide;
