/**
 * @generated SignedSource<<cbe6fe819f6211464035195044ed0413>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CertificateQueriesListQuery$variables = {
  skip: boolean;
};
export type CertificateQueriesListQuery$data = {
  readonly certificates?: {
    readonly edges: ReadonlyArray<{
      readonly alts: ReadonlyArray<string>;
      readonly id: string;
      readonly kind: {
        readonly id: string;
        readonly name: string;
        readonly perma: string;
      } | null;
      readonly name: string;
      readonly neverExpires: boolean;
      readonly priority: number;
    }>;
  } | null;
};
export type CertificateQueriesListQuery = {
  response: CertificateQueriesListQuery$data;
  variables: CertificateQueriesListQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'skip',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v3 = [
      {
        condition: 'skip',
        kind: 'Condition',
        passingValue: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'CertificateList',
            kind: 'LinkedField',
            name: 'certificates',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'Certificate',
                kind: 'LinkedField',
                name: 'edges',
                plural: true,
                selections: [
                  v1 /*: any*/,
                  v2 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'alts',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'priority',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'neverExpires',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    concreteType: 'CertificateKind',
                    kind: 'LinkedField',
                    name: 'kind',
                    plural: false,
                    selections: [
                      v1 /*: any*/,
                      v2 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        kind: 'ScalarField',
                        name: 'perma',
                        storageKey: null,
                      },
                    ],
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'CertificateQueriesListQuery',
      selections: v3 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'CertificateQueriesListQuery',
      selections: v3 /*: any*/,
    },
    params: {
      cacheID: 'f6dd12ad0c4f73574bdb340b4354f533',
      id: null,
      metadata: {},
      name: 'CertificateQueriesListQuery',
      operationKind: 'query',
      text: 'query CertificateQueriesListQuery(\n  $skip: Boolean!\n) {\n  certificates @skip(if: $skip) {\n    edges {\n      id\n      name\n      alts\n      priority\n      neverExpires\n      kind {\n        id\n        name\n        perma\n      }\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'ff971003a01cd6e17868fb715cf54578';

export default node;
