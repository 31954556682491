// packages
import { Suspense, useState } from 'react';
import { useIntl } from 'react-intl';
// components
import FilterIcon from 'icons/FilterIcon';
import CustomButton from 'system/Buttons/CustomButton';
import MainOrgInfo from './components/MainOrgInfo/MainOrgInfo';
import ListOfMembers from './components/ListOfMembers/ListOfMembers';
import ListOfVacancies from './components/ListOfVacancies/ListOfVacancies';
import FilterSliderWrapper from 'system/Layouts/FilterSliderWrapper/FilterSliderWrapper';
import JobOpeningFilterComponentSkeleton from 'system/skeletons/filters/JobOpeningFilterComponentSkeleton';
import JobOpeningFilterComponent from 'system/FilterSlideComponents/JobOpeningFiltering/JobOpeningFilterComponent';
import ListOfJobOpeningsSkeleton from 'system/skeletons/lists/ListOfJobOpeningsSkeleton/ListOfJobOpeningsSkeleton';
import SelectedJobOpeningsFilterElements from 'system/FilterSlideComponents/components/SelectedFilterElements/SelectedJobOpeningsFilterElements';
// hooks
import { useToggleShowWindow } from 'hooks/useToggleShowModal';

const OrganizationInfoPage = () => {
  const intl = useIntl();
  const { toggleShowWindow, showWindow } = useToggleShowWindow();

  const [amountVacancies, setAmountVacancies] = useState<number>(0);

  return (
    <>
      <section className="flex flex-col w-full gap-y-8">
        <MainOrgInfo />
        <div className="flex flex-col w-full">
          <div className="flex justify-between items-center w-full mb-2">
            <div className="flex text-lg gap-x-3 font-medium">
              <h2 className="text-darkBlue">{intl.formatMessage({ id: 'crew_job_openings' })}</h2>
              <span className="text-specialGray-075">{amountVacancies}</span>
            </div>
            <CustomButton
              label={{ id: 'label_filter' }}
              onClick={toggleShowWindow(true)}
              classNameStyle="w-28 justify-between text-sm disabled:opacity-50 px-5 py-3.5 border border-specialGray-05 text-sm leading-4 font-normal rounded-xl hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 mr-1 max-h-12"
              disabled={amountVacancies <= 1}
            >
              <FilterIcon />
            </CustomButton>
          </div>
          <Suspense>
            <SelectedJobOpeningsFilterElements margins="mb-2" />
          </Suspense>
          <Suspense fallback={<ListOfJobOpeningsSkeleton />}>
            <ListOfVacancies setAmountVacancies={setAmountVacancies} />
          </Suspense>
        </div>
        <ListOfMembers />
      </section>
      <FilterSliderWrapper filterButtonEvent={toggleShowWindow(false)} isOpenListOfFilters={showWindow}>
        <Suspense fallback={<JobOpeningFilterComponentSkeleton />}>{showWindow && <JobOpeningFilterComponent />}</Suspense>
      </FilterSliderWrapper>
    </>
  );
};

export default OrganizationInfoPage;
