// packages
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
// models
import { BREAKPOINTS } from 'models/enums';

export const useCurrentWidth = () => {
  const getWidth = useCallback(() => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth, []);
  const getHeight = useCallback(() => window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight, []);

  const [windowSize, setWindowSize] = useState<{ width: number; height: number }>({
    width: getWidth(),
    height: getHeight(),
  });

  //this function for React Confetti dynamic screen adaptive
  const handleWindowResize = useCallback(
    () =>
      debounce(() => {
        setWindowSize({
          width: getWidth(),
          height: getHeight(),
        });
      }, 500),
    [getHeight, getWidth],
  );

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize());
    // Call handler right away so state gets updated with initial window size
    return window.removeEventListener('resize', handleWindowResize());
  }, [handleWindowResize]);

  return windowSize;
};

export const useBreakpoints = () => {
  const { width } = useCurrentWidth();
  let result: { [key: string]: boolean } = {};

  for (const key of Object.keys(BREAKPOINTS)) {
    if (BREAKPOINTS[key].min !== parseInt(`${BREAKPOINTS[key].min}`, 10)) {
      throw new Error('Min value should be an integer!');
    }

    if (BREAKPOINTS[key].max && BREAKPOINTS[key].max !== parseInt(`${BREAKPOINTS[key].max}`, 10)) {
      throw new Error('Max value should be an integer!');
    }

    result[key] = width >= BREAKPOINTS[key].min && (!BREAKPOINTS[key].max || width <= BREAKPOINTS[key].max);
  }

  return result;
};

export const useHandleBreakpoints = () => {
  const { sm, md, lg, xl } = useBreakpoints();

  if (sm) {
    return 70;
  }
  if (md) {
    return 80;
  }
  if (lg) {
    return 100;
  }
  if (xl) {
    return 145;
  }

  return 145;
};
