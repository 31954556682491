// packages
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
// components
import DoneIcon from 'icons/DoneIcon';
import { XMarkIcon } from '@heroicons/react/24/outline';
import ResponseCommonItem from '../../ResponseCommonItem/ResponseCommonItem';
// schemas
import { VACANCY_FRAGMENT } from 'schemas/vacancy/VacancyFragments';
// generated
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
// models
import { VACANCY_APPLICATION_STATUS, VACANCY_DESCRIPTION_TYPE_OF_LIST } from 'models/enums';

const AppliedItem = ({ vacancy }: { vacancy: VacancyFragments$key }) => {
  const intl = useIntl();

  const vacancyData = useFragment<VacancyFragments$key>(VACANCY_FRAGMENT, vacancy);

  const handleRenderJobRightSide = useMemo(
    () => (
      <div className="flex gap-x-5">
        {vacancyData.applications?.[0]?.status === VACANCY_APPLICATION_STATUS.PENDING ? (
          <div className="text-green-500 text-sm flex sm:items-center font-medium gap-x-1 sm:gap-x-2">
            <DoneIcon />
            <span>{intl.formatMessage({ id: 'hire_label_applied' })}</span>
          </div>
        ) : null}
        {vacancyData.applications?.[0]?.status === VACANCY_APPLICATION_STATUS.DECLINED ? (
          <div className="text-red-500 text-sm flex sm:items-center font-medium">
            <XMarkIcon className="w-4 h-4" />
            <span className="ml-2">{intl.formatMessage({ id: 'hire_label_denied' })}</span>
          </div>
        ) : null}
        <div className="hidden md:flex items-center text-darkBlue text-sm gap-x-3.5 whitespace-nowrap">
          <span>{format(new Date(`${vacancyData?.createdAt}`), 'dd MMM yyyy')}</span>
          <span>{format(new Date(`${vacancyData?.createdAt}`), 'HH:mm')}</span>
        </div>
      </div>
    ),
    [intl, vacancyData.applications, vacancyData?.createdAt],
  );

  return (
    <ResponseCommonItem vacancyData={vacancyData} avatarSize="w-[66px] sm:w-[56px] lg:w-16 h-[66px] sm:h-[56px] lg:h-16" typeOfList={VACANCY_DESCRIPTION_TYPE_OF_LIST.APPLIED}>
      {handleRenderJobRightSide}
    </ResponseCommonItem>
  );
};

export default AppliedItem;
