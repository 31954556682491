import { format } from 'date-fns';
import { useIntl } from 'react-intl';
//type
import { CrewsEmployerViewTimeItemProps } from 'app/ProfileCrew/type/ProfileCrewTypes';

const CrewsEmployerViewTimeItem = ({ time, additionalClasses }: CrewsEmployerViewTimeItemProps) => {
  const intl = useIntl();
  return <span className={`text-sm whitespace-nowrap ${additionalClasses}`}>{time ? format(new Date(time), 'dd MMM yy') : intl.formatMessage({ id: 'service_present' })}</span>;
};

export default CrewsEmployerViewTimeItem;
