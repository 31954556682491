import React from 'react';
import Skeleton from 'react-loading-skeleton';

const HighlightedSeaServiceItemSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col w-full md:w-30 mb-5 md:mb-0 rounded-lg pt-3 border px-3">
      <div className="flex items-center h-7">
        <Skeleton width={24} height={20} className="mr-1" />
        <Skeleton width={200} height={20} />
        <Skeleton width={100} className="ml-2.5" />
      </div>
      <Skeleton width="100%" height={128} borderRadius="0.5rem" className="my-3" />
      <div className="flex flex-col">
        <div className="flex justify-between border-b pb-1">
          <Skeleton width={40} />
          <Skeleton width={60} />
        </div>
        <div className="flex justify-between">
          <Skeleton width={80} />
          <div className="flex">
            <Skeleton width={100} className="mr-2" />
            <Skeleton width={50} />
          </div>
        </div>
      </div>
      <div className="mt-5 py-4 bg-specialGray-002 -mx-3 pl-3">
        <Skeleton width={110} />
      </div>
    </div>
  );
};

export default HighlightedSeaServiceItemSkeleton;
