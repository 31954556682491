import { FC } from 'react';
//components
import Skeleton from 'react-loading-skeleton';
//models
import { TextInputSkeletonProps } from 'models/modelsOfComponents';

const RangeNumbersSkeleton: FC<TextInputSkeletonProps> = ({ widthTitle, additionalStyle, heightInput, borderRadius }) => {
  return (
    <div className={`flex-col w-full ${additionalStyle}`}>
      <Skeleton width={widthTitle} />
      <div className="flex w-full justify-between items-end pt-3">
        <Skeleton containerClassName="w-[45%]" height={heightInput} borderRadius={borderRadius} />
        <Skeleton containerClassName="w-5%" height={2} borderRadius={borderRadius} />
        <Skeleton containerClassName="w-[45%]" height={heightInput} borderRadius={borderRadius} />
      </div>
    </div>
  );
};

export default RangeNumbersSkeleton;
