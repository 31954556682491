// packages
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
// components
import ProfileEmptyIcon from 'icons/ProfileEmptyIcon';
import { LatestCrewSeaServices } from './components/LatestCrewSeaServices';
import { HighlightedCrewEducation } from './components/HighlightedCrewEducation';
import { HighlightedCrewCertificates } from './components/HighlightedCrewCertificates';
// generated
import { ProfileFragmentsCrewProfile$data } from 'schemas/profile/__generated__/ProfileFragmentsCrewProfile.graphql';

const OverviewComponent = ({ profileData }: { profileData: ProfileFragmentsCrewProfile$data }) => {
  const intl = useIntl();

  // when the user has no sea services it should always ONLY show "your profile is empty
  const isEmptyProfileData = useMemo(
    () => isEmpty(profileData.educations) && isEmpty(profileData.seaServices) && isEmpty(profileData.certificateInfos),
    [profileData.certificateInfos, profileData.educations, profileData.seaServices],
  );
  const showOverviewBody = profileData?.info && !isEmptyProfileData;

  return (
    <>
      {/* Sea services */}
      {showOverviewBody && <LatestCrewSeaServices profileCrewData={profileData} />}

      {/* Certificates */}
      {/* when the user has at least 1 sea service it should show Certificates */}
      {showOverviewBody && <HighlightedCrewCertificates profileCrewData={profileData} />}

      {/* Education */}
      {/* when the user has at least 1 sea service it should show Educations */}
      {showOverviewBody && <HighlightedCrewEducation profileCrewData={profileData} />}

      {/* when the user has no sea services it should always ONLY show "your profile is empty" */}
      {(!profileData?.info || isEmptyProfileData) && (
        <>
          <section className="flex justify-center mt-28">
            <ProfileEmptyIcon />
          </section>

          <div className="flex flex-col items-center mt-5">
            <h2 className="text-specialGray-1 text-2xl font-semibold">{intl.formatMessage({ id: 'profile_isEmpty' })}</h2>
          </div>
        </>
      )}
    </>
  );
};

export default OverviewComponent;
