// types
import { PaymentSubscriptionLabelSizes } from 'models/enums';
import { PaymentSubscriptionLabelProps } from 'app/ProfileEmployer/HireProduct/types';

const PaymentSubscriptionLabel = ({ subscriptionPlan, bg = 'bg-white', size, textColor = 'text-inherit', additionalClassName = '' }: PaymentSubscriptionLabelProps) => {
  const sizesClassName: { [key in PaymentSubscriptionLabelSizes]: string } = {
    xs: 'text-xs px-1 rounded-[14px]',
    sm: 'text-sm px-2 rounded-[18px]',
    md: 'text-lg px-[13px] rounded-[22px]',
  };

  return <span className={`flex w-fit h-fit items-center font-semibold ${bg} ${sizesClassName[size]} ${textColor} ${additionalClassName}`}>{subscriptionPlan}</span>;
};

export default PaymentSubscriptionLabel;
