// packages
import { useCallback, useMemo, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { isEmpty } from 'lodash';
// components
import SelectInputGroup from '../SelectInputGroup';
// generated
import { ServiceQueriesFormGetOneDutyQuery } from 'schemas/services/__generated__/ServiceQueriesFormGetOneDutyQuery.graphql';
// models
import { DutyService, IListOfDutyService } from 'models/IListOfDutyService';
// schemas
import { GET_ONE_DUTY_QUERY } from 'schemas/services/ServiceQueries';
// helpers
import { onChangeDuty, sortingListOfItems } from 'utils';
// types
import { SelectDutyComponentProps } from './types';

const SelectDutyComponent = ({
  dataOfDuty,
  isResetForm,
  nameField,
  optional,
  defaultByNull,
  allDuties,
  autoFocus,
  label = 'label_form_duty',
  placeholder = 'service_form_duty_placeholder',
  disabled,
}: SelectDutyComponentProps) => {
  const [duties, setDuties] = useState<IListOfDutyService>(allDuties?.duties?.edges || []);
  const oneDuty = useLazyLoadQuery<ServiceQueriesFormGetOneDutyQuery>(GET_ONE_DUTY_QUERY, {
    id: dataOfDuty?.id || '',
    skip: !dataOfDuty?.id,
  });

  const newListOfDuties = useMemo<IListOfDutyService>(() => {
    if (defaultByNull) {
      const emptyDuty = {
        id: 'none',
        name: 'None',
        alts: [],
        perma: '',
      };

      return [emptyDuty, ...[...duties].sort(sortingListOfItems)] as IListOfDutyService;
    } else {
      return [...duties].sort(sortingListOfItems);
    }
  }, [duties, defaultByNull]);

  const [selectedDuty, setSelectedDuty] = useState<DutyService>(isEmpty(oneDuty) && defaultByNull ? (newListOfDuties[0] as DutyService) : (oneDuty?.node as DutyService));

  const handleChangeDuty = useCallback((value: string) => onChangeDuty(allDuties?.duties?.edges as IListOfDutyService, setDuties)(value), [allDuties]);

  return (
    <div className="w-full mr-5">
      <SelectInputGroup
        name={nameField}
        options={newListOfDuties}
        label={{ id: label }}
        onChangeSelect={handleChangeDuty}
        placeholder={{ id: placeholder }}
        defaultSelectedElement={selectedDuty}
        setSelectedItem={setSelectedDuty}
        selectedItem={selectedDuty}
        isResetInput={isResetForm}
        autoComplete="off"
        optional={optional}
        autoFocus={autoFocus}
        disabled={disabled}
      />
    </div>
  );
};

export default SelectDutyComponent;
