import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { RoutePaths } from 'app/routing';
import { useFragment, useMutation } from 'react-relay';
import { useCallback, useState, Suspense } from 'react';
//models
import { FILTER_TYPE } from 'models/enums';
//generated
import { ProfileFragments$data } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { ProfileFragments_info$key } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
import { CertificateFragmentsCertificateInfo$key } from 'schemas/certificates/__generated__/CertificateFragmentsCertificateInfo.graphql';
import { CertificateMutationsUpdateCertificateInfoMutation } from 'schemas/certificates/__generated__/CertificateMutationsUpdateCertificateInfoMutation.graphql';
//schemas
import { PROFILE_INFO_FRAGMENT } from 'schemas/profile/ProfileFragments';
import { LIST_OF_CERTIFICATE_INFOS } from 'schemas/certificates/CertificateFragments';
import { UPDATE_CERTIFICATE_ITEM_MUTATION } from 'schemas/certificates/CertificateMutations';
//hooks
import { useToggleShowWindow } from 'hooks/useToggleShowModal';
//components
import { Modal } from 'system/Modal';
import SpinnerIcon from 'icons/SpinnerIcon';
import CustomButton from 'system/Buttons/CustomButton';
import OverviewTitleSection from 'system/OverviewTitleSection';
import CertificateWithoutDocAllFilled from './components/CertificateWithoutDocAllFilled';
import CertificateWithoutDocumentsItem from './components/CertificateWithoutDocumentsItem';
import ModalWithAttachedFiles from 'system/AttachedFileComponent/components/ModalWithAttachedFiles';

const CertificatesWithoutDocumentsCommon = ({ profileData }: { profileData: ProfileFragments$data | null }) => {
  const intl = useIntl();

  const [chosenCertificate, setChosenCertificate] = useState<string>('');
  const { showWindow, toggleShowWindow } = useToggleShowWindow();

  const certificateFromProfile = useFragment<CertificateFragmentsCertificateInfo$key>(LIST_OF_CERTIFICATE_INFOS, profileData?.certificateInfos || null);
  const profileInfoData = useFragment<ProfileFragments_info$key>(PROFILE_INFO_FRAGMENT, profileData?.info || null);
  const [commitUpdateCertificateInfoDate] = useMutation<CertificateMutationsUpdateCertificateInfoMutation>(UPDATE_CERTIFICATE_ITEM_MUTATION);

  const getFilteredCertificatesWithoutDocs = useCallback(() => {
    if (!certificateFromProfile?.length) return [];
    return certificateFromProfile.filter(c => !c.document);
  }, [certificateFromProfile]);

  const handleAddAttachedFile = useCallback(
    (documentId: string, handleError: (error: Error) => void) => {
      commitUpdateCertificateInfoDate({
        onError: handleError,
        onCompleted: () => {
          toggleShowWindow(false)();
        },
        variables: {
          id: chosenCertificate,
          certificatesInfoData: { documentId },
        },
      });
    },
    [commitUpdateCertificateInfoDate, chosenCertificate, toggleShowWindow],
  );

  const handleClick = (certificateId: string) => () => {
    toggleShowWindow(true)();
    setChosenCertificate(certificateId);
  };

  if (getFilteredCertificatesWithoutDocs().length === 0) {
    return (
      <div className="flex w-full flex-col">
        <CertificateWithoutDocAllFilled title={{ id: 'certificate_documents_attached' }} description={{ id: 'certificate_without_empty_attachments_description' }} />
        <Link
          to={profileInfoData?.primaryDuty?.id ? `${RoutePaths.JobOpenings}?${FILTER_TYPE.DUTIES}=${profileInfoData?.primaryDuty.id}` : RoutePaths.JobOpenings}
          className={`flex w-fit px-3.5 xs:px-8 py-3.5 mt-5 xs:mx-0 mx-auto rounded-[10px] text-sm font-medium space-x-2 bg-lightBlue text-blue self-center`}
        >
          {intl.formatMessage({ id: 'profile_view_jobs_for_you' })}
        </Link>
      </div>
    );
  }

  return (
    <section>
      <OverviewTitleSection title={{ id: 'profile_add_documents_improve' }} countLabel={{ id: 'profile_suggestions' }} count={getFilteredCertificatesWithoutDocs().length} />
      <div className="flex min-w-full border border-specialGray-012 rounded-2xl mt-4">
        <Modal
          title={{ id: 'certificate_attached_file_modal_title' }}
          onClose={toggleShowWindow(false)}
          description={{ id: 'certificate_attached_file_modal_description' }}
          show={showWindow}
        >
          <Suspense fallback={<SpinnerIcon />}>
            <ModalWithAttachedFiles modalTitle={{ id: 'hire_form_step_1_empty' }} onCloseModal={() => toggleShowWindow(false)} onAddAttachedFile={handleAddAttachedFile} />
          </Suspense>
        </Modal>

        <table className="min-w-full rounded-2xl overflow-hidden">
          <thead className="bg-specialGray-004 text-specialGray-075 text-xs uppercase">
            <tr>
              <th scope="col" className="text-left w-full font-normal h-10 pl-5 pr-[46px]">
                {intl.formatMessage({ id: 'certificate_without_attachments' })}
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-specialGray-012 bg-white text-specialGray-075 text-sm">
            {getFilteredCertificatesWithoutDocs().map(item => {
              return (
                <tr key={item.id}>
                  <td className="flex sm:min-h-[64px] items-center w-full px-4">
                    <CertificateWithoutDocumentsItem expiryDate={item.expires?.toString()} title={item.certificate.name} alts={item.certificate.alts.join(',')}>
                      <CustomButton
                        onClick={handleClick(item.id)}
                        className="border border-specialGray-05 text-spacialGray-075 rounded-[10px] w-full sm:w-auto py-1.5 px-3.5 font-medium hover:bg-specialGray-002 ml-0 sm:ml-12 sm:whitespace-nowrap"
                        label={{ id: 'attach_file' }}
                      />
                    </CertificateWithoutDocumentsItem>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default CertificatesWithoutDocumentsCommon;
