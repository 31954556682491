// packages
import { useRecoilValue } from 'recoil';
import Skeleton from 'react-loading-skeleton';
// components
import DashboardSkeleton from './components/DashboardSkeleton/DashboardSkeleton';
import ProfileHireBasicInfoSkeleton from './components/ProfileHireBasicInfoSkeleton';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';

const ProfileHireSkeleton = () => {
  const gerCurrentOrganisation = useRecoilValue(currentOrganisationSelector);
  return (
    <>
      <ProfileHireBasicInfoSkeleton />
      <div className="hidden sm:flex items-center justify-between space-x-5 w-full border-b border-gray-200 mt-5 pb-5 pt-5">
        <Skeleton width={100} className="mx-4" />
      </div>
      {gerCurrentOrganisation.isSelected && <DashboardSkeleton />}
    </>
  );
};

export default ProfileHireSkeleton;
