// packages
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { getUnixTime } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
// models
import { FILTER_TYPE } from 'models/enums';
// hooks
import { useSearchArrayFromFiltersType } from 'hooks/searchFilter/useSearchArrayFromFiltersType';

export function useSearchJobOpeningFilterQuery() {
  const [filters, setFilters] = useState<string>('');

  const [searchUseSearch] = useSearchParams();
  const searchArrayFromFiltersType = useSearchArrayFromFiltersType();

  useEffect(() => {
    const arrayOfAllFilters: Array<Array<string>> = [];

    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.HIRING_AREA, 'hiringArea', true);
    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.INDUSTRY, 'industry', true);
    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.DUTIES, 'duties', true);
    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.VESSEL_TYPES, 'vesselKind', true);
    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.DURATION, 'duration', true);
    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.STARTING_CONDITIONAL, 'startingCondition', true);
    searchArrayFromFiltersType(arrayOfAllFilters, FILTER_TYPE.SALARY_PERIOD, 'salaryPeriod', true);

    if (!!searchUseSearch.get(FILTER_TYPE.NUMBER_OF_MIN_SALARY)) {
      arrayOfAllFilters.push([`salaryTo >= ${searchUseSearch.get(FILTER_TYPE.NUMBER_OF_MIN_SALARY)}`]);
    }

    if (!!searchUseSearch.get(FILTER_TYPE.STARTING_DATE)) {
      arrayOfAllFilters.push([`startingDateTimestamp >= ${getUnixTime(new Date(searchUseSearch.get(FILTER_TYPE.STARTING_DATE)!))}`]);
    }

    if (!isEmpty(arrayOfAllFilters)) {
      setFilters(
        arrayOfAllFilters
          .map((element, index, array) => {
            return !isEmpty(element) ? `(${element.join(' OR ')})${array[index + 1] ? ' AND ' : ''}` : '';
          })
          .join(''),
      );
    } else {
      setFilters('');
    }
  }, [searchArrayFromFiltersType, searchUseSearch]);

  return {
    filters,
  };
}
