// packages
import { useEffect, useRef } from 'react';
// components
import { Alert } from 'system/Alert/Alert';
import { Button } from 'system/Button';
// hooks
import { useCopyLink } from 'hooks/useCopyLink';

const CopyLinkComponent = ({ profileShareLink }: { profileShareLink: string }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { isCopied, handleCopyLink, handleCopyLinkByPath } = useCopyLink(profileShareLink);

  useEffect(() => {
    if (profileShareLink) {
      (inputRef.current as HTMLInputElement).focus();
      (inputRef.current as HTMLInputElement).select();
    }
  }, [profileShareLink]);

  return (
    <div className="flex flex-col items-center w-full">
      <input
        onCopy={handleCopyLinkByPath}
        ref={inputRef}
        type="text"
        defaultValue={profileShareLink}
        className="w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 block text-xs sm:text-sm border-specialGray-012 rounded-md mb-3"
      />
      {isCopied ? (
        <Alert heading={{ id: 'copy_link_success_full_title' }} message={{ id: 'copy_link_success_full_message' }} type="success" />
      ) : (
        <Button onClick={handleCopyLink} label={{ id: 'copy_link' }} buttonType="white-primary" type="button" />
      )}
    </div>
  );
};

export default CopyLinkComponent;
