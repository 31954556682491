// packages
import { FC } from 'react';
import { useIntl } from 'react-intl';
// components
import InfoField from 'system/JobOpeningComponents/components/InfoField';
// generated
import { VacancyFragments$data } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { VacancyFragmentsForSearchFragment$data } from 'schemas/vacancy/__generated__/VacancyFragmentsForSearchFragment.graphql';

const SalaryRangeComponent: FC<{ vacancy: VacancyFragments$data | VacancyFragmentsForSearchFragment$data }> = ({ vacancy }) => {
  const intl = useIntl();

  return (
    <InfoField title={{ id: 'hire_job_description_salary_range' }}>
      <>
        <span className="mr-2">
          {vacancy?.salaryFrom || ''}
          {vacancy?.salaryTo ? `\u00A0\u2212\u00A0${vacancy?.salaryTo}` : null}
        </span>
        <span className="text-specialGray-075 ml-1.5">
          {vacancy?.currency ? vacancy?.currency.name : intl.formatMessage({ id: 'usd' })}&nbsp;&#8725;&nbsp;{vacancy?.salaryPeriod?.toLocaleLowerCase()}
        </span>
      </>
    </InfoField>
  );
};

export default SalaryRangeComponent;
