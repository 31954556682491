// packages
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useFragment } from 'react-relay';
// schemas
import { ORGANISATION_FRAGMENT_FOR_SEARCH } from 'schemas/organisations/OrganisationFragments';
// generated
import { OrganisationFragmentsForSearchFragment$key } from 'schemas/organisations/__generated__/OrganisationFragmentsForSearchFragment.graphql';
// types
import { DropDownListOfOrganisationsItemProps } from 'app/Navbar/types';
// components
import ItemTitle from '../../ItemTitle';
import CommercialAvatar from 'app/Avatars/CommercialAvatar/CommercialAvatar';
// routes
import { RoutesPathNames } from 'app/routing';
// context
import { useNavbarSearchContext } from 'Context/NavbarSearchContext';

const DropDownListItem = ({ employer, setShowDropDown }: DropDownListOfOrganisationsItemProps) => {
  const intl = useIntl();
  const employerData = useFragment<OrganisationFragmentsForSearchFragment$key>(ORGANISATION_FRAGMENT_FOR_SEARCH, employer);
  const { queryParams } = useNavbarSearchContext();

  return (
    <Link
      to={`${RoutesPathNames.orgInfo}/${employerData?.id}`}
      state={{ queryParams }}
      className="w-full border-b last:border-0 py-2.5 sm:py-2 px-2.5 flex items-start cursor-pointer hover:bg-specialGray-005 font-medium space-x-3"
      onClick={setShowDropDown}
    >
      <CommercialAvatar size="w-[55px] h-[55px]" avatarUrl={employerData.avatarUrl} />
      <div className="flex-col w-full truncate">
        <ItemTitle title={employerData.name} />
        <div className="flex">
          <span className="text-sm text-specialGray-075 font-normal">
            {intl.formatMessage({ id: 'employer_vacancy_status_open_with_number' }, { amount: employerData.numberOfVacancies })}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default DropDownListItem;
