import React from 'react';
import Skeleton from 'react-loading-skeleton';

const EducationItemSkeleton: React.FC<{ width?: string }> = ({ width = 'w-48%' }) => {
  return (
    <div className={`flex flex-row mb-5 rounded-lg px-3 py-3 border bg-white w-100% md:${width} justify-between`}>
      <div className="flex flex-row">
        <Skeleton width={45} height={45} borderRadius="0.5rem" />
        <div className="flex-col ml-5">
          <Skeleton width={100} />
          <Skeleton width={40} />
        </div>
      </div>
      <div className="my-auto">
        <Skeleton width={130} />
      </div>
    </div>
  );
};

export default EducationItemSkeleton;
