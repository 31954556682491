/**
 * @generated SignedSource<<08409b789e12db93d8a23d166aac4eff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ProfileQueriesScoreSubscriptionQuery$variables = {
  pid: number;
  skip: boolean;
};
export type ProfileQueriesScoreSubscriptionQuery$data = {
  readonly profileScore?: {
    readonly 'id': string;
    readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsScoreSubscription'>;
  };
};
export type ProfileQueriesScoreSubscriptionQuery = {
  response: ProfileQueriesScoreSubscriptionQuery$data;
  variables: ProfileQueriesScoreSubscriptionQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'pid',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'skip',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'pid',
        variableName: 'pid',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileQueriesScoreSubscriptionQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: 'ProfileScore',
              kind: 'LinkedField',
              name: 'profileScore',
              plural: false,
              selections: [
                v2 /*: any*/,
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'ProfileFragmentsScoreSubscription',
                },
              ],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileQueriesScoreSubscriptionQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: 'ProfileScore',
              kind: 'LinkedField',
              name: 'profileScore',
              plural: false,
              selections: [
                v2 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'details',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'summary',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
        },
      ],
    },
    params: {
      cacheID: '9fb8fca1fba6b2abfd251eb0665a4f32',
      id: null,
      metadata: {},
      name: 'ProfileQueriesScoreSubscriptionQuery',
      operationKind: 'query',
      text: 'query ProfileQueriesScoreSubscriptionQuery(\n  $pid: Int!\n  $skip: Boolean!\n) {\n  profileScore(pid: $pid) @skip(if: $skip) {\n    id\n    ...ProfileFragmentsScoreSubscription\n  }\n}\n\nfragment ProfileFragmentsScoreSubscription on ProfileScore {\n  id\n  details\n  summary\n}\n',
    },
  };
})();

(node as any).hash = 'e1a326132074e01f95baeda226b4eca0';

export default node;
