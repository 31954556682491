// packages
import { differenceInDays } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { Form, FormikProvider, useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyLoadQuery, useMutation } from 'react-relay';
// context
import { useJobOpeningFormContext } from 'Context/JobOpeningFormContext';
// routing
import { RoutePaths } from 'app/routing';
// helpers
import { HireFormInitialValuesStepOne } from 'formHelpers/initialValuesOfForms';
import { JobOpeningStep1FormValidateSchema } from 'formHelpers/validationsOfForms';
// models
import { IDuty } from 'models/IDuty';
import { FormStepProps } from 'models/modelsOfComponents';
import { IJobOpeningStep1Form } from 'models/modelsOfForms';
import { typeFormVacancyState } from 'models/routeLocationState';
import { SALARY_PERIOD_TYPE, STARTING_CONDITION_TYPE } from 'models/commonTypes';
// schemas
import { GET_LIST_OF_CURRENCIES } from 'schemas/vacancy/VacancyQueries';
import { CREATE_VACANCY, UPDATE_VACANCY, UPDATE_VACANCY_MANAGER } from 'schemas/vacancy/VacancyMutations';
// components
import { Button } from 'system/Button';
import BottomFormLayout from '../BottomFormLayout';
import AlertForError from 'system/Alert/AlertForError';
import SelectInputGroup from 'system/SelectInputGroup';
import ChooseInputGroup from 'system/ChooseInputGroup';
import { DateInputGroup } from 'system/DateInputGroup';
import { InputGroup } from 'system/InputGroups/InputGroup';
import LabelComponent from 'system/LabelComponent/LabelComponent';
import HireCardInformation from './components/HireCardInformation/HireCardInformation';
import SelectInputGroupWithOrgMembers from './components/SelectInputGroupWithOrgMembers';
// generated
import { ServiceQueriesFormGetAllDutiesQuery } from 'schemas/services/__generated__/ServiceQueriesFormGetAllDutiesQuery.graphql';
import { VacancyMutationsUpdateVacancyMutation } from 'schemas/vacancy/__generated__/VacancyMutationsUpdateVacancyMutation.graphql';
import { VacancyQueriesGetListOfCurrenciesQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetListOfCurrenciesQuery.graphql';
import { VacancyMutationsUpdateVacancyManagerMutation } from 'schemas/vacancy/__generated__/VacancyMutationsUpdateVacancyManagerMutation.graphql';
import { VacancyMutationsCreateVacancyMutation, VacancyMutationsCreateVacancyMutation$data } from 'schemas/vacancy/__generated__/VacancyMutationsCreateVacancyMutation.graphql';
// utils
import { DURATION_LIST_ENUMS, INDUSTRY_LIST_ENUMS, STARTING_CONDITION_ENUMS, VACANCY_STATUS } from 'models/enums';
// hooks
import { useGetVacancy } from 'hooks/useGetVacancy';
import { useUpdateCompletedFormVacancy } from 'hooks/UseUpdateCompletedFormVacancy';
import { useGetConnectionVacancyRecordId } from 'hooks/useGetConnectionVacancyRecordId';
//mocked
import { GET_ALL_DUTIES_QUERY } from 'schemas/services/ServiceQueries';
import { MOCK_DURATION_OF_POSITION, MOCK_INDUSTRY_LIST, MOCK_SALARY_PERIOD, MOCK_STARTING_CONDITION } from 'mocks/mockData';
// updaters
import { JobOpeningCommitCreateVacancy, JobOpeningCommitUpdateVacancy, JobOpeningUpdateVacancyManager } from 'formHelpers/updaters/updatersOfJobOpening';

const FormStepOne = ({ defaultError, defaultVacancyId = '' }: FormStepProps) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isChange, setIsChange, nextStep } = useJobOpeningFormContext();
  const { handleUpdateCompleted } = useUpdateCompletedFormVacancy(RoutePaths.JobOpeningStep2);

  const vacancy = useGetVacancy(defaultVacancyId);
  const connectionRecordID = useGetConnectionVacancyRecordId();
  const allDuties = useLazyLoadQuery<ServiceQueriesFormGetAllDutiesQuery>(GET_ALL_DUTIES_QUERY, { skip: false })?.duties?.edges.map(item => {
    return { ...item, label: item?.name };
  });

  const listOfCurrencies = useLazyLoadQuery<VacancyQueriesGetListOfCurrenciesQuery>(GET_LIST_OF_CURRENCIES, {});

  const [commitCreateVacancy] = useMutation<VacancyMutationsCreateVacancyMutation>(CREATE_VACANCY);
  const [commitUpdateVacancy] = useMutation<VacancyMutationsUpdateVacancyMutation>(UPDATE_VACANCY);
  const [commitUpdateVacancyManager] = useMutation<VacancyMutationsUpdateVacancyManagerMutation>(UPDATE_VACANCY_MANAGER);

  const [error, setError] = useState<Error | undefined>(defaultError);
  const [defaultDuties, setDefaultDuties] = useState<IDuty[] | null>(null);
  const [industrySelected, setIndustrySelected] = useState<INDUSTRY_LIST_ENUMS>(MOCK_INDUSTRY_LIST[0].id);
  const [durationSelected, setDurationSelected] = useState<DURATION_LIST_ENUMS>(MOCK_DURATION_OF_POSITION[0].id);
  const [selectedSalaryPeriod, setSelectedSalaryPeriod] = useState<SALARY_PERIOD_TYPE>(MOCK_SALARY_PERIOD[0]);
  const [selectedCondition, setSelectedCondition] = useState<STARTING_CONDITION_TYPE>(MOCK_STARTING_CONDITION[0]);
  const [selectedCurrency, setSelectedCurrency] = useState<{ name: string; id: string }>(listOfCurrencies.currencies?.edges?.[0] as { name: string; id: string });

  const findSelectedElementForField = (array: any[], idOfTheSelectedElement: string, setSelectedState: React.Dispatch<React.SetStateAction<any>>) => {
    const isElementExist = array.find(({ id }) => id === idOfTheSelectedElement);
    return setSelectedState(isElementExist ? isElementExist?.id : array[0]?.id);
  };
  useEffect(() => {
    if (vacancy?.duties) {
      setDefaultDuties(
        vacancy?.duties.map(item => {
          return { ...item, label: item?.name };
        }),
      );
    }
    if (vacancy?.industry) {
      //set default Industry item
      findSelectedElementForField(MOCK_INDUSTRY_LIST, vacancy?.industry, setIndustrySelected);
    }
    if (vacancy?.duration) {
      findSelectedElementForField(MOCK_DURATION_OF_POSITION, vacancy?.duration, setDurationSelected);
    }
    if (vacancy?.salaryPeriod) {
      const findDefaultSalaryPeriod = MOCK_SALARY_PERIOD.find(period => period?.id === vacancy?.salaryPeriod);
      setSelectedSalaryPeriod(findDefaultSalaryPeriod?.id ? findDefaultSalaryPeriod : MOCK_SALARY_PERIOD[0]);
    }

    if (vacancy?.currency) {
      setSelectedCurrency(vacancy?.currency);
    }

    if (vacancy?.startingCondition) {
      const foundStartingCondition = MOCK_STARTING_CONDITION.find(condition => condition?.id === vacancy?.startingCondition);
      setSelectedCondition(foundStartingCondition || MOCK_STARTING_CONDITION[0]);
    }
  }, [vacancy?.currency, vacancy?.duration, vacancy?.duties, vacancy?.industry, vacancy?.salaryPeriod, vacancy?.startingCondition]);

  const formik = useFormik<IJobOpeningStep1Form>({
    onSubmit,
    validateOnBlur: false,
    enableReinitialize: !!vacancy,
    initialValues: HireFormInitialValuesStepOne(vacancy),
    validationSchema: JobOpeningStep1FormValidateSchema(selectedCondition.id),
  });

  useEffect(() => {
    if (vacancy && nextStep?.active) {
      const localVacancy = {
        ...vacancy,
        positionTitle: vacancy.title,
        duties: vacancy.duties?.map(item => {
          return { ...item, label: item?.name };
        }),
        tornWeeksOff: vacancy.tornWeeksOff || '',
        tornWeeksOn: vacancy.tornWeeksOn || '',
        currencyId: vacancy?.currency?.id || '',
      };
      const formikValues = {
        ...vacancy,
        ...formik.values,
        tornWeeksOff: formik.values.tornWeeksOff ? +formik.values.tornWeeksOff : '',
        tornWeeksOn: formik.values.tornWeeksOn ? +formik.values.tornWeeksOn : '',
        duties: Array.isArray(formik.values.duties)
          ? formik.values.duties?.map(item => {
              return { ...item, label: item?.name };
            })
          : [],
        startingDate: !differenceInDays(new Date(vacancy.startingDate as string), new Date(formik.values.startingDate?.toISOString() as string))
          ? vacancy?.startingDate
          : formik.values.startingDate?.toISOString(),
      };
      setIsChange(!isEqual(localVacancy, formikValues));
    } else {
      setIsChange(false);
    }
    // eslint-disable-next-line
  }, [vacancy]);

  const handleFormError = (error: Error) => {
    setError(error);
    formik.setSubmitting(false);
  };

  const handleCreateCompleted = (response: VacancyMutationsCreateVacancyMutation$data) => {
    navigate(`${RoutePaths.ProfileOrganizationBase}/${state?.organization?.id}/${RoutePaths.JobOpeningStep2}`, {
      state: {
        ...(state as typeFormVacancyState),
        organization: {
          ...(state as typeFormVacancyState).organization,
          vacancyId: response.createVacancy.node?.id,
        },
      },
    });
  };

  function onSubmit(data: IJobOpeningStep1Form) {
    const { salaryFrom, salaryTo, duties, industry, salaryPeriod, duration, tornWeeksOn, tornWeeksOff, currencyId, startingCondition, startingDate } = data;

    const variableData = {
      title: data.positionTitle,
      organizationId: (state as typeFormVacancyState).organization?.id as string,
      salaryFrom: salaryFrom ? salaryFrom : null,
      salaryTo: salaryTo ? salaryTo : null,
      description: `${vacancy?.description ?? ''}`,
      responsibilities: vacancy?.responsibilities ? [...(vacancy.responsibilities as Array<string>)] : [],
      duties: !isEmpty(duties) ? duties.map(pos => pos?.id) : [],
      industry: industry ? industry : industrySelected,
      salaryPeriod: salaryPeriod ? salaryPeriod : selectedSalaryPeriod?.id,
      duration: duration ? duration : durationSelected,
      tornWeeksOn: !!tornWeeksOn ? +(tornWeeksOn as string) : 0,
      tornWeeksOff: !!tornWeeksOff ? +(tornWeeksOff as string) : 0,
      currencyId: !!currencyId ? currencyId : selectedCurrency.id,
      startingCondition,
      startingDate: startingDate && startingCondition === STARTING_CONDITION_ENUMS.DATE ? startingDate.toISOString() : null,
    };

    if ((state as typeFormVacancyState)?.organization.vacancyId) {
      commitUpdateVacancy({
        onError: handleFormError,
        variables: {
          id: (state as typeFormVacancyState)?.organization?.vacancyId as string,
          data: {
            ...variableData,
            status: vacancy.status === VACANCY_STATUS.OPEN ? vacancy.status : VACANCY_STATUS.CLOSED,
            description: `${vacancy.description ?? ''}`,
            responsibilities: vacancy.responsibilities ? [...(vacancy?.responsibilities as Array<string>)] : [],
            certificates: vacancy?.certificates ? vacancy?.certificates?.map(certificate => certificate.id) : [],
          },
        },
        onCompleted: response => {
          commitUpdateVacancyManager({
            variables: { id: response.updateVacancy?.id as string, orgMemberId: data.orgMemberId },
            updater: JobOpeningUpdateVacancyManager,
            onCompleted: () => {
              handleUpdateCompleted();
              formik.setSubmitting(false);
            },
          });
        },
        updater: JobOpeningCommitUpdateVacancy,
      });
    } else {
      commitCreateVacancy({
        onError: handleFormError,
        variables: {
          data: { ...variableData, status: VACANCY_STATUS.CLOSED },
          connections: [connectionRecordID],
        },
        updater: JobOpeningCommitCreateVacancy,
        onCompleted: createVacancyResponse => {
          if (!data.orgMemberId) {
            handleCreateCompleted(createVacancyResponse);
            formik.setSubmitting(false);
            return;
          }
          commitUpdateVacancyManager({
            variables: { id: createVacancyResponse.createVacancy.node?.id as string, orgMemberId: data.orgMemberId },
            updater: JobOpeningUpdateVacancyManager,
            onCompleted: () => {
              handleCreateCompleted(createVacancyResponse);
              formik.setSubmitting(false);
            },
          });
        },
      });
    }
  }

  const handleCloseForm = useCallback(() => {
    if ((state as typeFormVacancyState)?.prevPath) {
      navigate((state as typeFormVacancyState).prevPath);
    } else {
      navigate(`${RoutePaths.ProfileOrganizationBase}/${state?.organization?.id}`);
    }
  }, [navigate, state]);

  const handleSelectIndustry = (id: string) => {
    setIndustrySelected(id as INDUSTRY_LIST_ENUMS);
    formik.setFieldValue('industry', id);
  };
  const handleSelectDuration = (id: string) => {
    setDurationSelected(id as DURATION_LIST_ENUMS);
    formik.setFieldValue('duration', id);
  };

  return (
    <FormikProvider value={formik}>
      <Form className="rounded-xl bg-white shadow mx-auto">
        <div className="flex flex-col w-full px-5 sm:px-20 gap-y-8 py-8">
          {error && <AlertForError heading={{ id: 'hire_form_failedSubmit' }} error={error} />}
          <section className="inline-block align-bottom text-left sm:align-middle w-full">
            <InputGroup label={{ id: 'hire_information_job_title' }} name="positionTitle" placeholderText={{ id: 'hire_information_position_title' }} autoFocus />
          </section>

          <ChooseInputGroup
            groupData={allDuties}
            customFieldAdding={false}
            autoComplete="off"
            defaultArrayOfValues={defaultDuties}
            name="duties"
            label={{ id: 'hire_information_position' }}
            placeholder={{ id: 'hire_information_position_placeholder' }}
          />

          <section>
            <LabelComponent label={{ id: 'hire_job_description_industry' }} />
            <div className="flex justify-between gap-x-1.5 mt-2">
              {MOCK_INDUSTRY_LIST.map(card => {
                const isSelected = industrySelected === card.id;
                return <HireCardInformation key={card.id} cardData={card} isActive={isSelected} handleSelect={handleSelectIndustry} />;
              })}
            </div>
          </section>

          <section>
            <LabelComponent label={{ id: 'hire_job_description_salary_range' }} />
            <div className="flex gap-x-12">
              <div className="flex w-2/5">
                <InputGroup typeInput="digital" name="salaryFrom" label={{ id: 'hire_form_step_1_empty' }} />
                <div className="m-2 pt-1 text-specialGray-03">&#45;</div>
                <InputGroup typeInput="digital" name="salaryTo" label={{ id: 'hire_form_step_1_empty' }} />
              </div>
              <div className="flex w-1/4">
                <SelectInputGroup
                  name="currencyId"
                  containerMargin="mb-0"
                  options={listOfCurrencies.currencies?.edges}
                  label={{ id: 'hire_form_step_1_empty' }}
                  placeholder={{ id: 'hire_form_step_1_currency' }}
                  onChangeSelect={() => {}}
                  defaultSelectedElement={selectedCurrency}
                  setSelectedItem={setSelectedCurrency}
                  selectedItem={selectedCurrency}
                  autoComplete="off"
                />
              </div>
              <div className="flex w-1/3">
                <SelectInputGroup
                  containerMargin="mb-0"
                  name="salaryPeriod"
                  options={MOCK_SALARY_PERIOD}
                  label={{ id: 'hire_form_step_1_empty' }}
                  placeholder={{ id: 'hire_form_step_1_empty' }}
                  onChangeSelect={() => {}}
                  defaultSelectedElement={selectedSalaryPeriod}
                  setSelectedItem={setSelectedSalaryPeriod}
                  selectedItem={selectedSalaryPeriod}
                  autoComplete="off"
                />
              </div>
            </div>
          </section>

          <section>
            <LabelComponent label={{ id: 'hire_job_description_duration_of_position' }} />
            <div className="flex justify-between gap-x-1.5 mt-2">
              {MOCK_DURATION_OF_POSITION.map(card => {
                const isSelected = durationSelected === card.id;
                return <HireCardInformation key={card.id} cardData={card} isActive={isSelected} handleSelect={handleSelectDuration} />;
              })}
            </div>
          </section>
          <div className="flex w-full gap-x-5">
            <div className="flex flex-col w-1/2">
              <InputGroup
                label={{ id: 'hire_form_step_1_torn_weeks_on_label' }}
                typeInput="digital"
                name="tornWeeksOn"
                placeholderText={{ id: 'hire_form_step_1_torn_weeks_on_placeholder' }}
              />
            </div>
            <div className="flex flex-col w-1/2 gap-y-1">
              <InputGroup
                label={{ id: 'hire_form_step_1_torn_weeks_off_label' }}
                typeInput="digital"
                name="tornWeeksOff"
                placeholderText={{ id: 'hire_form_step_1_torn_weeks_off_placeholder' }}
              />
            </div>
          </div>
          <div className="flex w-full gap-x-4">
            <div className="w-1/3">
              <SelectInputGroup
                containerMargin="mb-0"
                name="startingCondition"
                options={MOCK_STARTING_CONDITION}
                label={{ id: 'hire_form_step_1_job_description_starting_condition_label' }}
                placeholder={{ id: 'hire_form_step_1_empty' }}
                onChangeSelect={() => {}}
                defaultSelectedElement={selectedCondition}
                setSelectedItem={setSelectedCondition}
                selectedItem={selectedCondition}
                autoComplete="off"
              />
            </div>
            {selectedCondition.id === STARTING_CONDITION_ENUMS.DATE && (
              <div className="w-2/3 flex items-end">
                <DateInputGroup name="startingDate" label={{ id: 'hire_form_step_1_empty' }} />
              </div>
            )}
          </div>
          <SelectInputGroupWithOrgMembers vacancy={vacancy} />
        </div>
        <BottomFormLayout>
          <>
            <Button
              onMouseDown={e => e.preventDefault()}
              type="button"
              buttonType="white-primary"
              label={{ id: 'cancel' }}
              onClick={handleCloseForm}
              disabled={formik.isSubmitting}
            />
            <Button buttonType="primary" type="submit" label={{ id: isChange ? 'save' : 'next_step' }} disabled={formik.isSubmitting} additionalClasses="ml-auto" />
          </>
        </BottomFormLayout>
      </Form>
    </FormikProvider>
  );
};

export default FormStepOne;
