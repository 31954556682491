/**
 * @generated SignedSource<<7a0a5c574c42a13b4470fd5652f6d27f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ProfileYearsOfExperience = 'YEARS_0_1' | 'YEARS_10_20' | 'YEARS_1_2' | 'YEARS_2_5' | 'YEARS_5_10' | 'YEARS_MORE_20' | '%future added value';
export type ProfileInfoData = {
  avatarUrl?: string | null;
  bio?: string | null;
  birthDate: String;
  countryId: string;
  firstName: string;
  lastName: string;
  ownerId: string;
  primaryDutyId?: string | null;
  yearsOfExperience?: ProfileYearsOfExperience | null;
};
export type BasicInfoFormMutationsCreateMutation$variables = {
  data: ProfileInfoData;
};
export type BasicInfoFormMutationsCreateMutation$data = {
  readonly createProfileInfo: {
    readonly avatarUrl: string | null;
    readonly bio: string | null;
    readonly birthDate: String;
    readonly country: {
      readonly ' $fragmentSpreads': FragmentRefs<'CountryFragments_info'>;
    };
    readonly firstName: string;
    readonly id: string;
    readonly lastName: string;
    readonly owner: {
      readonly id: string;
      readonly info: {
        readonly firstName: string;
      } | null;
    };
    readonly primaryDuty: {
      readonly 'id': string;
      readonly 'name': string;
      readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragmentsOneDuty'>;
    } | null;
  };
};
export type BasicInfoFormMutationsCreateMutation = {
  response: BasicInfoFormMutationsCreateMutation$data;
  variables: BasicInfoFormMutationsCreateMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'data',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'data',
        variableName: 'data',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'firstName',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'lastName',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'bio',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'birthDate',
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'avatarUrl',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'BasicInfoFormMutationsCreateMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'ProfileInfo',
          kind: 'LinkedField',
          name: 'createProfileInfo',
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'Duty',
              kind: 'LinkedField',
              name: 'primaryDuty',
              plural: false,
              selections: [
                v2 /*: any*/,
                v8 /*: any*/,
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'ServiceFragmentsOneDuty',
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'Profile',
              kind: 'LinkedField',
              name: 'owner',
              plural: false,
              selections: [
                v2 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'ProfileInfo',
                  kind: 'LinkedField',
                  name: 'info',
                  plural: false,
                  selections: [v3 /*: any*/],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'Country',
              kind: 'LinkedField',
              name: 'country',
              plural: false,
              selections: [
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'CountryFragments_info',
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'BasicInfoFormMutationsCreateMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'ProfileInfo',
          kind: 'LinkedField',
          name: 'createProfileInfo',
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'Duty',
              kind: 'LinkedField',
              name: 'primaryDuty',
              plural: false,
              selections: [
                v2 /*: any*/,
                v8 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'alts',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'perma',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'Profile',
              kind: 'LinkedField',
              name: 'owner',
              plural: false,
              selections: [
                v2 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'ProfileInfo',
                  kind: 'LinkedField',
                  name: 'info',
                  plural: false,
                  selections: [v3 /*: any*/, v2 /*: any*/],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'Country',
              kind: 'LinkedField',
              name: 'country',
              plural: false,
              selections: [
                v2 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'isoCode',
                  storageKey: null,
                },
                v8 /*: any*/,
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '09027108ee3c053aba11e8cc89e89c7c',
      id: null,
      metadata: {},
      name: 'BasicInfoFormMutationsCreateMutation',
      operationKind: 'mutation',
      text: 'mutation BasicInfoFormMutationsCreateMutation(\n  $data: ProfileInfoData!\n) {\n  createProfileInfo(data: $data) {\n    id\n    firstName\n    lastName\n    bio\n    birthDate\n    avatarUrl\n    primaryDuty {\n      id\n      name\n      ...ServiceFragmentsOneDuty\n    }\n    owner {\n      id\n      info {\n        firstName\n        id\n      }\n    }\n    country {\n      ...CountryFragments_info\n      id\n    }\n  }\n}\n\nfragment CountryFragments_info on Country {\n  id\n  isoCode\n  name\n}\n\nfragment ServiceFragmentsOneDuty on Duty {\n  id\n  name\n  alts\n  perma\n}\n',
    },
  };
})();

(node as any).hash = '8b0b5b023c2dfabe3255f34214f030e1';

export default node;
