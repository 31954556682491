/**
 * @generated SignedSource<<ecb97e2ace0165a475fbb35185a5667d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VacancyIndustry = 'NAUTICAL_WORLDWIDE' | 'OFFSHORE_OIL_GAS' | 'OFFSHORE_WIND' | '%future added value';
export type VacancyStatus = 'CLOSED' | 'OPEN' | '%future added value';
import { FragmentRefs } from 'relay-runtime';
export type VacancyFragmentsForDashboardFragment$data = {
  readonly 'applications': ReadonlyArray<{
    readonly id: string;
    readonly owner: {
      readonly id: string;
      readonly info: {
        readonly avatarUrl: string | null;
        readonly firstName: string;
        readonly lastName: string;
      } | null;
    };
  }> | null;
  readonly 'createdAt': String;
  readonly 'duties': ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }> | null;
  readonly 'id': string;
  readonly 'industry': VacancyIndustry | null;
  readonly 'organization': {
    readonly avatarUrl: string | null;
    readonly id: string;
  };
  readonly 'salaryFrom': number | null;
  readonly 'salaryTo': number | null;
  readonly 'status': VacancyStatus;
  readonly 'title': string;
  readonly 'vesselKind': {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly ' $fragmentType': 'VacancyFragmentsForDashboardFragment';
};
export type VacancyFragmentsForDashboardFragment$key = {
  readonly ' $data'?: VacancyFragmentsForDashboardFragment$data;
  readonly ' $fragmentSpreads': FragmentRefs<'VacancyFragmentsForDashboardFragment'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'name',
        storageKey: null,
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'avatarUrl',
      storageKey: null,
    };
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'VacancyFragmentsForDashboardFragment',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'createdAt',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'title',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'status',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'industry',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'salaryFrom',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'salaryTo',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'VesselKind',
        kind: 'LinkedField',
        name: 'vesselKind',
        plural: false,
        selections: v1 /*: any*/,
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Duty',
        kind: 'LinkedField',
        name: 'duties',
        plural: true,
        selections: v1 /*: any*/,
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Application',
        kind: 'LinkedField',
        name: 'applications',
        plural: true,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'Profile',
            kind: 'LinkedField',
            name: 'owner',
            plural: false,
            selections: [
              v0 /*: any*/,
              {
                alias: null,
                args: null,
                concreteType: 'ProfileInfo',
                kind: 'LinkedField',
                name: 'info',
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'firstName',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'lastName',
                    storageKey: null,
                  },
                  v2 /*: any*/,
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Organization',
        kind: 'LinkedField',
        name: 'organization',
        plural: false,
        selections: [v0 /*: any*/, v2 /*: any*/],
        storageKey: null,
      },
    ],
    type: 'Vacancy',
    abstractKey: null,
  };
})();

(node as any).hash = 'efc7e17710dc08ffbdda7dcc4dba81e4';

export default node;
