// packages
import { Suspense, useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Outlet, useNavigate } from 'react-router-dom';
// components
import { Footer } from 'app/Footer/Footer';
import { Navbar } from 'app/Navbar/Navbar';
import { Modal } from 'system/Modal';
import NavBarSkeleton from 'system/skeletons/NavBar/NavBarSkeleton';
import VacancyTabs from 'system/Layouts/JobDescriptionTabs/components/VacancyTabs';
import TabsSkeleton from 'system/skeletons/hireProfile/JobDescriptionTabsSkeleton/components/TabsSkeleton';
import PaymentSubscriptionModalContent from 'app/ProfileEmployer/HireProduct/components/PaymentSubscriptionModalContent/PaymentSubscriptionModalContent';
//error boundary
import ErrorBoundaryMainContent from 'system/ErrorBoundaries/ErrorBoundaryMainContent';
// routing
import { RoutePaths } from 'app/routing';
// recoil
import { personalAccountSelector } from 'recoil/Profile/personalAccount/selectors/personalAccountSelector';
import { paymentSubscriptionModalAtom } from 'recoil/ContactInformation/atoms/paymentSubscriptionModalAtom';

const JobDescriptionTabs = () => {
  const navigate = useNavigate();

  const getIsPersonalAccount = useRecoilValue(personalAccountSelector);
  const [getShowPaymentSubscriptionModal, setShowPaymentSubscriptionModal] = useRecoilState(paymentSubscriptionModalAtom);

  useEffect(() => {
    if (getIsPersonalAccount.personalAccountIsSelected) {
      navigate(RoutePaths.ProfileBase, { replace: true });
    }
  }, [getIsPersonalAccount.personalAccountIsSelected, navigate]);

  const onHandleClosePaymentSubscription = useCallback(() => {
    setShowPaymentSubscriptionModal({
      showPaymentSubscriptionModal: false,
    });
  }, [setShowPaymentSubscriptionModal]);

  return (
    <>
      <Suspense fallback={<NavBarSkeleton />}>
        <Navbar />
      </Suspense>
      <Suspense fallback={<TabsSkeleton />}>
        <VacancyTabs />
      </Suspense>
      <Suspense>
        <Modal onClose={onHandleClosePaymentSubscription} show={getShowPaymentSubscriptionModal.showPaymentSubscriptionModal}>
          <PaymentSubscriptionModalContent onClose={onHandleClosePaymentSubscription} />
        </Modal>
      </Suspense>
      <main className="flex flex-col w-full max-w-7xl mx-auto py-10">
        <ErrorBoundaryMainContent>
          <Outlet />
        </ErrorBoundaryMainContent>
      </main>
      <Footer />
    </>
  );
};
export default JobDescriptionTabs;
