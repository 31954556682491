/**
 * @generated SignedSource<<2f697cad15f7a72241cd670657e37132>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type CertificateFragments_single$data = {
  readonly 'alts': ReadonlyArray<string>;
  readonly 'id': string;
  readonly 'kind': {
    readonly id: string;
    readonly name: string;
    readonly perma: string;
  } | null;
  readonly 'name': string;
  readonly 'neverExpires': boolean;
  readonly 'priority': number;
  readonly ' $fragmentType': 'CertificateFragments_single';
};
export type CertificateFragments_single$key = {
  readonly ' $data'?: CertificateFragments_single$data;
  readonly ' $fragmentSpreads': FragmentRefs<'CertificateFragments_single'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    };
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'CertificateFragments_single',
    selections: [
      v0 /*: any*/,
      v1 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'alts',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'priority',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'neverExpires',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'CertificateKind',
        kind: 'LinkedField',
        name: 'kind',
        plural: false,
        selections: [
          v0 /*: any*/,
          v1 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'perma',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
    type: 'Certificate',
    abstractKey: null,
  };
})();

(node as any).hash = 'b959c376a2eb66454d4e00904630221f';

export default node;
