import React from 'react';
import Skeleton from 'react-loading-skeleton';
import TextInputSkeleton from '../components/TextInputSkeleton';

const VesselInformationSkeleton: React.FC = () => {
  return (
    <>
      <section className="flex flex-col sm:flex-row h-full w-full">
        <Skeleton width={126} height={80} borderRadius="0.75rem" />
        <div className="flex sm:ml-6 ml-0 w-full flex-col justify-between">
          <section className="flex mt-1 justify-between w-full">
            <Skeleton width={90} />
            <Skeleton width={165} />
          </section>
          <Skeleton width="100%" height={38} borderRadius="0.75rem" />
        </div>
      </section>

      <section className="flex w-full flex-col space-y-5">
        <div className="flex flex-row">
          <div className="w-4/6 mr-5">
            <Skeleton width={45} />
            <Skeleton width="100%" height={38} borderRadius="0.375rem" />
          </div>
          <div className="w-2/6">
            <Skeleton width={45} />
            <Skeleton width="100%" borderRadius="0.375rem" height={38} />
          </div>
        </div>
        <TextInputSkeleton widthTitle={70} borderRadius="0.375rem" heightInput={38} />
      </section>
    </>
  );
};

export default VesselInformationSkeleton;
