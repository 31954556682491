// packages
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
// components
import SettingsCheckBox from '../../SettingsCheckBox/SettingsCheckBox';
// models
import { FILTER_BOOLEAN_ID, FILTER_TYPE } from 'models/enums';

const ToggleFilter = ({ label, description, filterType, filterBooleanId }: { label: string; description: string; filterType: FILTER_TYPE; filterBooleanId: FILTER_BOOLEAN_ID }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getCheckedValue = useMemo(() => !!searchParams.get(filterType), [filterType, searchParams]);

  const handleToggleFilter = useCallback(
    (value: boolean) => {
      if (value) {
        searchParams.set(filterType, filterBooleanId);
        setSearchParams(searchParams);
      } else {
        searchParams.delete(filterType);
      }

      setSearchParams(searchParams);
    },
    [filterBooleanId, filterType, searchParams, setSearchParams],
  );

  return (
    <div className="flex flex-col pt-6 pb-6 sm:pt-8 border-b border-b-specialGray-012 px-4 sm:px-8 relative">
      <SettingsCheckBox label={label} checked={getCheckedValue} eventFunction={handleToggleFilter} isLoading={false} description={description} />
    </div>
  );
};

export default ToggleFilter;
