import { FILTER_TYPE } from 'models/enums';

export const allNumericFilters: { [key: string]: string } = {
  [FILTER_TYPE.NUMBER_OF_SEA_SERVICES_FROM]: 'Sea services from',
  [FILTER_TYPE.NUMBER_OF_SEA_SERVICES_TO]: 'Sea services to',

  [FILTER_TYPE.NUMBER_OF_APPRAISALS_FROM]: 'Appraisals from',
  [FILTER_TYPE.NUMBER_OF_APPRAISALS_TO]: 'Appraisals to',

  [FILTER_TYPE.NUMBER_OF_EXPERIENCE_FROM]: 'Experience from',
  [FILTER_TYPE.NUMBER_OF_EXPERIENCE_TO]: 'Experience to',

  [FILTER_TYPE.NUMBER_OF_MIN_SALARY]: 'Minimum salary',
};

export const filterByValue: { [key: string]: string } = {
  [FILTER_TYPE.WORKED_FOR]: 'Vessel operators and managers',
  [FILTER_TYPE.CREW_VESSEL_IMO]: 'Vessel imo',
  [FILTER_TYPE.STARTING_DATE]: 'Start date',
};
