// components
import ShieldIcon from 'icons/ShieldIcon';
// types
import { CertificateTitleProps } from 'app/Profile/Certificates/types';

const CertificateTitle = ({ certificateInfo, children }: CertificateTitleProps) => {
  return (
    <div className="text-specialGray-1 overflow-hidden text-left text-sm mr-1 flex flex-row md:mt-2 mt-0">
      <section className="w-6 block text-blue mr-3">
        <ShieldIcon kind={certificateInfo?.certificate?.kind?.name} />
      </section>
      <span>
        {certificateInfo?.certificate?.name}
        {children}
      </span>
    </div>
  );
};

export default CertificateTitle;
