import { debounce } from 'lodash';
import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
//models
import { FILTER_TYPE } from 'models/enums';
//routs
import { RoutePaths } from 'app/routing';
//mocks
import { allNumericFilters, filterByValue } from 'system/FilterSlideComponents/filtersMock';

export const usePageTypeAnalytics = () => {
  const { pathname } = useLocation();

  return useMemo(() => {
    switch (pathname) {
      case RoutePaths.JobOpenings: {
        return 'job-opening';
      }
      case RoutePaths.SearchProfileBase: {
        return 'find-crew';
      }
      case RoutePaths.HireAuthLogin || RoutePaths.HireAuthLogin: {
        return 'employer';
      }
      default: {
        return '';
      }
    }
  }, [pathname]);
};
export const useAccountTypeAnalytics = () => {
  const { pathname } = useLocation();

  return useMemo(() => {
    switch (pathname) {
      case RoutePaths.HireAuthLogin:
      case RoutePaths.HireAuthRegister:
        return 'employer';

      case RoutePaths.AuthLogin:
      case RoutePaths.AuthRegister:
        return 'crew';

      default: {
        return '';
      }
    }
  }, [pathname]);
};

export const convertToCorrectWuiAnalyticsName = (name: string): string => name.split(' ').join('-').toLowerCase();

export const useDeleteFilterAnalytics = () => {
  const pageType = usePageTypeAnalytics();

  const deleteFilterAnalytics = <T>(item: T, filterType: FILTER_TYPE) => {
    if (!pageType) return;

    const numericFilterName = allNumericFilters[filterType] && `number-of-${convertToCorrectWuiAnalyticsName(allNumericFilters[filterType])}`;
    const valueFilterName = filterByValue[filterType] && `value-${convertToCorrectWuiAnalyticsName(filterByValue[filterType])}`;

    const wuiFilterTypeName = numericFilterName || valueFilterName || filterType;
    const wuiTitle = `wui-filter-delete-${wuiFilterTypeName}-${pageType}`;
    window.analytics?.track(wuiTitle, {
      ...item,
    });
  };

  return {
    deleteFilterAnalytics,
  };
};

export const useFilterSelectedAnalytics = () => {
  const pageType = usePageTypeAnalytics();

  const sendFilterAnalytics = <T>(item: T, filterType: FILTER_TYPE) => {
    if (!pageType) return;
    const wuiTitle = `wui-filter-selected-${filterType}-${pageType}`;
    window.analytics?.track(wuiTitle, {
      ...item,
    });
  };

  return {
    sendFilterAnalytics,
  };
};

export const useNumericFilterAnalytics = () => {
  const pageType = usePageTypeAnalytics();

  const sendRangeNumericFilterAnalytics = useRef(
    debounce((filterType: FILTER_TYPE, value: string) => {
      if (!pageType) return;

      const analyticsTitle = convertToCorrectWuiAnalyticsName(allNumericFilters[filterType]);
      const wuiTitle = `wui-filter-number-of-${analyticsTitle}-${pageType}`;
      window.analytics?.track(wuiTitle, {
        value,
      });
    }, 350),
  );

  return { sendRangeNumericFilterAnalytics };
};

export const useDateFilterAnalytics = () => {
  const pageType = usePageTypeAnalytics();

  const sendDateFilterAnalytics = useRef(
    debounce(<T>(filterType: string, fullData: T) => {
      if (!pageType) return;

      const title = convertToCorrectWuiAnalyticsName(filterByValue[filterType]);
      const wuiTitle = `wui-filter-value-${title}-${pageType}`;
      window.analytics?.track(wuiTitle, {
        ...fullData,
      });
    }, 350),
  );

  return {
    sendDateFilterAnalytics,
  };
};

export const useValueFilterAnalytics = () => {
  const pageType = usePageTypeAnalytics();

  const sendValueFilterAnalytics = useRef(
    debounce((filterType: string, value: string) => {
      if (!pageType) return;

      const wuiTitle = `wui-filter-value-${filterType}-${pageType}`;
      window.analytics?.track(wuiTitle, {
        value: value,
      });
    }, 350),
  );
  return {
    sendValueFilterAnalytics,
  };
};
