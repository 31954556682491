/**
 * @generated SignedSource<<079f65cef5c71756da54d189694e0ffd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type EducationData = {
  end?: String | null;
  kindId: string;
  name: string;
  ownerId?: string | null;
  start: String;
};
export type EducationMutationsFormCreateMutation$variables = {
  connections: ReadonlyArray<string>;
  data: EducationData;
};
export type EducationMutationsFormCreateMutation$data = {
  readonly createEducation: {
    readonly cursor: any;
    readonly node: {
      readonly 'id': string;
      readonly ' $fragmentSpreads': FragmentRefs<'EducationFragments'>;
    } | null;
  };
};
export type EducationMutationsFormCreateMutation = {
  response: EducationMutationsFormCreateMutation$data;
  variables: EducationMutationsFormCreateMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'connections',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'data',
    },
    v2 = [
      {
        kind: 'Variable',
        name: 'data',
        variableName: 'data',
      },
    ],
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cursor',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'EducationMutationsFormCreateMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'EducationEdge',
          kind: 'LinkedField',
          name: 'createEducation',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'Education',
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                v3 /*: any*/,
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'EducationFragments',
                },
              ],
              storageKey: null,
            },
            v4 /*: any*/,
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'EducationMutationsFormCreateMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'EducationEdge',
          kind: 'LinkedField',
          name: 'createEducation',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'Education',
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                v3 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'start',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'end',
                  storageKey: null,
                },
                v5 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'EducationKind',
                  kind: 'LinkedField',
                  name: 'kind',
                  plural: false,
                  selections: [
                    v3 /*: any*/,
                    v5 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'perma',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              filters: null,
              handle: 'appendNode',
              key: '',
              kind: 'LinkedHandle',
              name: 'node',
              handleArgs: [
                {
                  kind: 'Variable',
                  name: 'connections',
                  variableName: 'connections',
                },
                {
                  kind: 'Literal',
                  name: 'edgeTypeName',
                  value: 'EducationEdge',
                },
              ],
            },
            v4 /*: any*/,
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '2b7a268737344dac61ffd7ececa03e66',
      id: null,
      metadata: {},
      name: 'EducationMutationsFormCreateMutation',
      operationKind: 'mutation',
      text: 'mutation EducationMutationsFormCreateMutation(\n  $data: EducationData!\n) {\n  createEducation(data: $data) {\n    node {\n      id\n      ...EducationFragments\n    }\n    cursor\n  }\n}\n\nfragment EducationFragments on Education {\n  id\n  start\n  end\n  name\n  kind {\n    id\n    name\n    perma\n  }\n}\n',
    },
  };
})();

(node as any).hash = '19f8ec1ad9137b37d8db8d0c157f66a2';

export default node;
