//icons
import AppliedIcon from 'icons/AppliedIcon';
import InviteCrewIcon from 'icons/InviteCrewIcon';
import InvitationIcon from 'icons/InvitationIcon';
import JobOpeningIcon from 'icons/jobOpeningIcon';
import ReviewProposalsIcon from 'icons/ReviewProposalsIcon';
//types
import { JobOpeningTabsType, JobTabsType, TabsEmployerType, TabsType } from 'models/commonTypes';
import { JOB_RESPONSES_TITLE_ENUMS, ModeType, EmployerModeType, JOB_OPENING_TITLE_ENUMS } from 'models/enums';
//utils
import { envVariableTransform } from 'utils';

export const RoutesPathNames = {
  profileBase: 'p',
  ProfileOrganizationBase: 'organization',
  orgInfo: 'org-info',
  JobOpenings: 'job-openings',
  employers: 'employers',
  settings: 'settings',
  authBase: 'auth',
  registerBySteps: 'register-by-steps',
  hireRegisterBySteps: 'hire-register-by-steps',
  seaServiceDetails: 'sea-service-details',
  showCertificates: 'show_certificates',
  crewDetail: 'crew-details',
  searchProfile: 's',
  deletePool: 'delete-pool',
  newPool: 'new-pool',
  addUserToPool: 'add-user-to-pool',
  currentPool: 'current-pool',
  deleteUser: 'delete-user',
  crew: 'crew',
  show: 'show',
  edit: 'edit',
  job: 'job',
  showContacts: 'show-contacts',
  JobReviewApplications: 'review-applications',
  editProfileInfo: 'edit-profile-info',
  organizationInvites: 'organization-invites',
  ProfileBaseSeaServices: 'sea-services',
  ProfileBaseCertificates: 'certificates',
  ProfileBaseEducation: 'education',
  ProfileBaseAppraisals: 'appraisals',
  // register steps
  stepOne: 'step-1',
  stepTwo: 'step-2',
  stepThree: 'step-3',
  stepFour: 'step-4',
  stepFive: 'step-5',
  stepSix: 'step-6',
  stepSeven: 'step-7',
  stepEight: 'step-8',
  // auth
  login: 'login',
  register: 'register',
  registerConfirm: 'register-confirm',
  forgot: 'forgot',
  forgotConfirm: 'forgot-confirm',
  hireForgotConfirm: 'hire-forgot-confirm',
  hireForgot: 'hire-forgot',
  hireRegisterConfirm: 'hire-register-confirm',
  hireRegister: 'hire-register',
  hireLogin: 'hire-login',

  //dynamic value
  profileSideBarId: 'profileSideBarId',
  jobOpeningSlideId: 'jobOpeningSlideId',
  id: 'id',
  userId: 'userId',
  seaServiceId: 'seaServiceId',
  poolId: 'poolId',
  orgId: 'orgId',
  userName: 'userName',

  // admin's link
  admin: 'admin',
  assume: 'assume',
  stopAssume: 'stop-assume',

  // hire congratulation
  welcome: 'welcome',
} as const;

export const RoutePaths = {
  AuthBase: `/${RoutesPathNames.authBase}`,
  AuthLogin: `/${RoutesPathNames.authBase}/${RoutesPathNames.login}`,
  AuthRegister: `/${RoutesPathNames.authBase}/${RoutesPathNames.register}`,
  AuthRegisterBySteps: `/${RoutesPathNames.authBase}/${RoutesPathNames.registerBySteps}`,
  AuthRegisterStepOne: `/${RoutesPathNames.authBase}/${RoutesPathNames.registerBySteps}/${RoutesPathNames.stepOne}`,
  AuthRegisterStepTwo: `/${RoutesPathNames.authBase}/${RoutesPathNames.registerBySteps}/${RoutesPathNames.stepTwo}`,
  AuthRegisterStepTree: `/${RoutesPathNames.authBase}/${RoutesPathNames.registerBySteps}/${RoutesPathNames.stepThree}`,
  AuthRegisterStepFour: `/${RoutesPathNames.authBase}/${RoutesPathNames.registerBySteps}/${RoutesPathNames.stepFour}`,
  AuthRegisterStepFive: `/${RoutesPathNames.authBase}/${RoutesPathNames.registerBySteps}/${RoutesPathNames.stepFive}`,
  AuthRegisterStepSix: `/${RoutesPathNames.authBase}/${RoutesPathNames.registerBySteps}/${RoutesPathNames.stepSix}`,
  AuthRegisterStepSeven: `/${RoutesPathNames.authBase}/${RoutesPathNames.registerBySteps}/${RoutesPathNames.stepSeven}`,
  AuthRegisterStepEight: `/${RoutesPathNames.authBase}/${RoutesPathNames.registerBySteps}/${RoutesPathNames.stepEight}`,
  HireAuthRegisterBySteps: `/${RoutesPathNames.authBase}/${RoutesPathNames.hireRegisterBySteps}`,
  HireAuthRegisterStepOne: `/${RoutesPathNames.authBase}/${RoutesPathNames.hireRegisterBySteps}/${RoutesPathNames.stepOne}`,
  HireAuthRegisterStepTwo: `/${RoutesPathNames.authBase}/${RoutesPathNames.hireRegisterBySteps}/${RoutesPathNames.stepTwo}`,
  HireAuthRegisterStepTree: `/${RoutesPathNames.authBase}/${RoutesPathNames.hireRegisterBySteps}/${RoutesPathNames.stepThree}`,
  HireAuthRegisterStepFour: `/${RoutesPathNames.authBase}/${RoutesPathNames.hireRegisterBySteps}/${RoutesPathNames.stepFour}`,
  HireAuthRegisterStepFive: `/${RoutesPathNames.authBase}/${RoutesPathNames.hireRegisterBySteps}/${RoutesPathNames.stepFive}`,
  HireAuthRegisterStepSix: `/${RoutesPathNames.authBase}/${RoutesPathNames.hireRegisterBySteps}/${RoutesPathNames.stepSix}`,
  AuthForget: `/${RoutesPathNames.authBase}/${RoutesPathNames.forgot}`,
  AuthForgetConfirm: `/${RoutesPathNames.authBase}/${RoutesPathNames.forgotConfirm}`,
  HireAuthLogin: `/${RoutesPathNames.authBase}/${RoutesPathNames.hireLogin}`,
  AuthRegisterConfirm: `/${RoutesPathNames.authBase}/${RoutesPathNames.registerConfirm}`,
  HireAuthRegister: `/${RoutesPathNames.authBase}/${RoutesPathNames.hireRegister}`,
  HireAuthRegisterConfirm: `/${RoutesPathNames.authBase}/${RoutesPathNames.hireRegisterConfirm}`,
  HireAuthForgot: `/${RoutesPathNames.authBase}/${RoutesPathNames.hireForgot}`,
  HireAuthForgotConfirm: `/${RoutesPathNames.authBase}/${RoutesPathNames.hireForgotConfirm}`,
  HomePath: '/',
  ProfileBase: `/${RoutesPathNames.profileBase}`,
  ProfileOrganizationBase: `/${RoutesPathNames.ProfileOrganizationBase}`,
  ProfileOrganizationInvites: `/${RoutesPathNames.profileBase}/${RoutesPathNames.organizationInvites}`,
  ProfileCrewPools: `crew-pools`,
  ProfileOrganizationMembers: `team-members`,
  ProfileSingleCrewPage: `/${RoutesPathNames.profileBase}/${RoutesPathNames.crew}`,
  ProfileSingleJobPage: `/${RoutesPathNames.profileBase}/${RoutesPathNames.job}`,
  ProfileSingleCrewPageEmployerView: `/${RoutesPathNames.profileBase}/${RoutesPathNames.crewDetail}`,
  ProfileShareByUuid: 't=:uuid_token',
  ProfileBaseOverview: '.',
  SearchProfileBase: `/${RoutesPathNames.searchProfile}`,
  SearchProfileWithAllSideBar: `${RoutesPathNames.showCertificates}/:profId`,
  AccountSettings: `/${RoutesPathNames.settings}`,
  JobOpeningStep1: 'job-opening-step-1',
  JobOpeningStep2: 'job-opening-step-2',
  JobOpeningStep3: 'job-opening-step-3',
  JobOpeningId: 'job/:id',
  JobEditId: `${RoutesPathNames.job}/${RoutesPathNames.edit}/:jobDescriptionEditId`,
  JobResponses: '/job-responses',
  JobResponsesInvitation: 'invitation',
  JobResponsesApplied: 'applied',
  JobDescription: 'job-description',
  JobInviteCrew: 'invite-crew',
  JobAppliedCrew: 'applied-crew',
  JobInvitedCrew: 'invited-crew',
  JobOpenings: `/${RoutesPathNames.JobOpenings}`,
  ShowJobOpeningSlide: `/${RoutesPathNames.JobOpenings}/${RoutesPathNames.show}`,
  ShowSeaServiceDetails: `${RoutesPathNames.seaServiceDetails}`,
  AvailableForWork: '/available-for-work',
  UnavailableForWork: '/unavailable-for-work',
  orgInfoById: `${RoutesPathNames.orgInfo}/:${RoutesPathNames.orgId}`,
  adminAssume: `/${RoutesPathNames.admin}/${RoutesPathNames.assume}`,
  adminAssumeById: `/${RoutesPathNames.admin}/${RoutesPathNames.assume}/:${RoutesPathNames.userId}`,
  adminStopAssume: `/${RoutesPathNames.admin}/${RoutesPathNames.stopAssume}`,
} as const;

export const tabs: Array<TabsType> = [
  { name: ModeType.overview, href: RoutePaths.ProfileBaseOverview },
  { name: ModeType.seaServices, href: RoutesPathNames.ProfileBaseSeaServices },
  { name: ModeType.certificates, href: RoutesPathNames.ProfileBaseCertificates },
  { name: ModeType.education, href: RoutesPathNames.ProfileBaseEducation },
  { name: ModeType.appraisals, href: RoutesPathNames.ProfileBaseAppraisals },
];
export const employerTabs: Array<TabsEmployerType> = [
  { name: EmployerModeType.jobOpenings, href: RoutePaths.ProfileOrganizationBase, showTabToUser: true },
  {
    name: EmployerModeType.crewPools,
    href: RoutePaths.ProfileCrewPools,
    matchByEndOfPath: false,
    showTabToUser: envVariableTransform(process.env.REACT_APP_FEATURE_HIRE_CREW_POOLS) || envVariableTransform(process.env.STORYBOOK_FEATURE_HIRE_CREW_POOLS),
  },
  {
    name: EmployerModeType.organizationMembers,
    href: RoutePaths.ProfileOrganizationMembers,
    showTabToUser:
      envVariableTransform(process.env.REACT_APP_FEATURE_HIRE_ORGANIZATION_TEAM_MEMBERS) || envVariableTransform(process.env.STORYBOOK_FEATURE_HIRE_ORGANIZATION_TEAM_MEMBERS),
  },
];

export const JOB_RESPONSES_TABS: Array<JobTabsType> = [
  { id: JOB_RESPONSES_TITLE_ENUMS.invitation.toUpperCase(), name: JOB_RESPONSES_TITLE_ENUMS.invitation, href: RoutePaths.JobResponsesInvitation, activeIcon: InvitationIcon },
  { id: JOB_RESPONSES_TITLE_ENUMS.applied.toUpperCase(), name: JOB_RESPONSES_TITLE_ENUMS.applied, href: RoutePaths.JobResponsesApplied, activeIcon: AppliedIcon },
];

export const navbarHireMenuItems = [
  { label: { id: 'navbar_job_openings_link' }, link: RoutePaths.JobOpenings },
  { label: { id: 'crew_members_link_label' }, link: RoutePaths.SearchProfileBase },
];

export const JOB_OPENING_TABS: Array<JobOpeningTabsType> = [
  { name: JOB_OPENING_TITLE_ENUMS.jobOpening, href: RoutePaths.JobDescription, activeIcon: JobOpeningIcon, popoverDescription: { id: '' } },
  {
    name: JOB_OPENING_TITLE_ENUMS.inviteCrew,
    href: RoutePaths.JobInviteCrew,
    activeIcon: InviteCrewIcon,
    popoverDescription: { id: 'hire_job_tabs_popover_content_description_invite' },
  },
  {
    name: JOB_OPENING_TITLE_ENUMS.reviewApplications,
    href: RoutesPathNames.JobReviewApplications,
    activeIcon: ReviewProposalsIcon,
    popoverDescription: { id: 'hire_job_tabs_popover_content_description_review' },
  },
];
