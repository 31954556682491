import { differenceInDays, differenceInYears, isValid } from 'date-fns';
import * as Yup from 'yup';
// regexp
import { phoneRegExp } from 'schemas/regexp';
// types
import { PLATFORM_GROUP, STARTING_CONDITION_ENUMS } from 'models/enums';
// mock
import { excludedEmailDomain } from './mockFormHelpers';

function validateEndDateInput(fieldName: string) {
  return Yup.date().when(fieldName, {
    is: (value: boolean) => {
      return value;
    },
    then: schema => schema,
    otherwise: schema =>
      schema
        .required()
        .max(new Date())
        .test('less-then-start-date', 'default_end_date_is_max_then_start', (value, param) => {
          if (!param.parent.start || !isValid(param.parent.start)) {
            return true;
          }
          return differenceInDays(new Date(param.parent.start), new Date(value!)) < 0;
        }),
  });
}
const validateStartDateInput = () => Yup.date().min(new Date(-1)).max(new Date(), 'default_start_date_is_max').required();

export const ServiceFormValidateSchema = () =>
  Yup.object({
    start: validateStartDateInput(),
    end: validateEndDateInput('workingNow'),
    rotations: Yup.number().required().min(1),
    vessel: Yup.object({
      imo: Yup.string().required(),
      name: Yup.string().required(),
      operatorName: Yup.string().max(255),

      kind: Yup.string().required(),
    }),
    dutyId: Yup.string().required(),
    tags: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        label: Yup.string(),
      }),
    ),
    workingNow: Yup.boolean(),
  });

export const EducationFormValidateSchema = () =>
  Yup.object({
    name: Yup.string().required().max(100),
    start: validateStartDateInput(),
    end: validateEndDateInput('studyingNow'),
    kindId: Yup.string().required(),
    studyingNow: Yup.boolean(),
  });

export const ConfirmFormValidateSchema = () =>
  Yup.object({
    code: Yup.string().required(),
  });

export const StepWithCountryValidateSchema = () =>
  Yup.object({
    countryId: Yup.string().required(),
  });

export const StepWithSeaServiceFromValidateSchema = () =>
  Yup.object({
    dutyId: Yup.string().required(),
    start: validateStartDateInput(),
    end: validateEndDateInput('workingNow'),
    vesselImo: Yup.string().required(),
    vesselName: Yup.string().required(),
    workingNow: Yup.boolean(),
  });

export const StepWithDutyValidateSchema = () =>
  Yup.object({
    dutyId: Yup.string(),
  });

export const SignUpWithNameValidateSchema = () =>
  Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
  });

export const SignUpWithPhoneNumberValidateSchema = () =>
  Yup.object({
    phoneNumber: Yup.string().required(),
  });

export const ForgotConfirmFormValidateSchema = Yup.object({
  email: Yup.string().email().required(),
  code: Yup.string().required(),
  newPassword: Yup.string().required(),
});

export const ForgotFormValidateSchema = Yup.object({ email: Yup.string().email().required() });

export const LoginFormValidateSchema = Yup.object({
  email: Yup.string().required().email(),
  password: Yup.string().required(),
});

export const RegisterStepWithEmailOrPhoneValidateSchema = (registerType: PLATFORM_GROUP) =>
  Yup.object({
    // Waiting the backend
    // phoneNumber: Yup.string().test('phoneIsRequired', 'chose_one_of_two_fields_email_phone', (value, context) => {
    //   return !!context.parent.email || !!value;
    // }),
    // email: Yup.string().when('phoneNumber', {
    //   is: (phoneNumber: string) => !phoneNumber,
    //   then: schema => schema.email().required('chose_one_of_two_fields_email_phone'),
    //   otherwise: schema => schema.email(),
    // }),
    email:
      registerType === 'hire' && process.env.NODE_ENV === 'production'
        ? Yup.string()
            .email()
            .required()
            .test('allowed-email', 'business_email_validation_with_email', value => {
              if (!value) {
                return false;
              }
              const whiteList = process.env.REACT_APP_HIRE_REGISTER_WHITE_LIST?.split(',') || [];

              const emailDomain = value.split('@')[1];
              const isEmailWhitelisted = whiteList.includes(value);
              const isEmailExcluded = excludedEmailDomain.includes(emailDomain);

              return isEmailWhitelisted || !isEmailExcluded;
            })
        : Yup.string().email().required(),
  });

export const RegisterStepWithPasswordValidateSchema = Yup.object({
  password: Yup.string().required(),
  privacy: Yup.boolean().oneOf([true]),
});

export const RegisterStepWithExperienceValidateSchema = Yup.object({
  userExperience: Yup.string().required(),
});

export const BasicInfoFormValidateSchema = Yup.object({
  firstName: Yup.string().required().min(2).max(30),
  lastName: Yup.string().required().min(2).max(30),
  birthDate: Yup.date()
    .test('check', 'profile_date_of_birth_error_message_for_young_people', value => {
      return differenceInYears(new Date(), new Date(value!)) >= 10;
    })
    .test('check', 'profile_date_of_birth_error_message_for_old_people', value => {
      return differenceInYears(new Date(), new Date(value!)) <= 120;
    })
    .required(),
  countryId: Yup.string().required(),
  phoneNumber: Yup.string().matches(phoneRegExp),
  bio: Yup.string().min(10),
});

export const BasicEmployerInfoFormValidateSchema = Yup.object({
  name: Yup.string().required().min(2).max(100),
  avatarUrl: Yup.string(),
  description: Yup.string().min(10).max(500),
  city: Yup.string()
    .nullable()
    .test('check', 'default_should_be_value', (value, param) => {
      // required if a country was set, in another case this field is optional
      const selectedCountry = param.parent.countryId;
      if ((value && !selectedCountry) || (!value && !selectedCountry)) {
        return true;
      }
      return !!selectedCountry && !!value;
    })
    .max(100),
  countryId: Yup.string()
    .nullable()
    .test('check', 'default_should_be_value', (value, param) => {
      const cityValue = param.parent.city;
      // required if city was set, in another case this field is optional
      if ((!cityValue && value) || (!cityValue && !value)) {
        return true;
      }
      return !!cityValue && !!value;
    }),
  size: Yup.string(),
});

export const CertificateInfoFormValidateSchema = Yup.object({
  expiryDate: Yup.date().required(),
});

export const ServiceNoteFormValidateSchema = Yup.object({
  note: Yup.string().min(10).max(200),
});

export const CertificateAddMoreInfoFormValidateSchema = Yup.object({
  attachedSmallFiles: Yup.array(),
  attachedLargeFiles: Yup.array(),
});

export const ChangeEmailFormValidateSchema = (currentEmail: string) =>
  Yup.object({
    email: Yup.string().notOneOf([currentEmail, null]).required(),
  });

export const ChangePasswordFormValidateSchema = Yup.object({
  oldPassword: Yup.string().required(),
  newPassword: Yup.string()
    .min(9)
    .notOneOf([Yup.ref('oldPassword'), null])
    .required(),
});

export const AppraisalFormValidateSchema = Yup.object({
  seaServiceId: Yup.string().required(),
  review: Yup.string().required(),
  documentId: Yup.string(),
});

export const CodeConfirmValidateSchema = Yup.object({
  codeConfirm: Yup.string().required(),
});

export const JobOpeningStep1FormValidateSchema = (startingCondition: STARTING_CONDITION_ENUMS) =>
  Yup.object({
    positionTitle: Yup.string().min(3).required(),
    salaryFrom: Yup.number()
      .positive()
      .test('check', 'default_should_be_value', (value: number | undefined, param) => {
        const salaryToField = param.parent.salaryTo;
        return !(!value && salaryToField);
      })
      .max(Yup.ref('salaryTo')),
    salaryTo: Yup.number()
      .positive()
      .test('check', 'default_should_be_value', (value: number | undefined, param) => {
        const salaryFromField = param.parent.salaryFrom;
        return !(!value && salaryFromField);
      })
      .min(Yup.ref('salaryFrom')),
    duties: Yup.array().test('count positions', 'default_duties_length', (value: string[] | undefined) => {
      return !(value && value.length > 5);
    }),
    startingDate:
      startingCondition === STARTING_CONDITION_ENUMS.DATE
        ? Yup.date()
            .required()
            .test('not expired', 'default_not_expired_date', (value?: Date) => {
              return differenceInDays(new Date(`${value}`), new Date()) > 0;
            })
        : Yup.date().nullable(),
  });
export const JobOpeningStepTwoValidationSchema = Yup.object({
  essentialCertificates: Yup.array().max(20, 'default_max_certificates_number'),
});

export const JobOpeningStep3FormValidateSchema = Yup.object({
  description: Yup.string().min(3).max(1000),
  responsibilities: Yup.string()
    .test('min len', 'default_responsibilities_length', (value: string | undefined) => {
      if (value) {
        const tasksArray = value.split('\n');
        const minLengthOfTask = 2;
        const checkEachResponsibilityTask = tasksArray.map((string: string) => string && string.length < minLengthOfTask).includes(true);
        return !checkEachResponsibilityTask;
      }
      return true;
    })
    .test('max len', 'default_responsibilities_length_too_long', (value: string | undefined) => {
      if (value) {
        const tasksArray = value.split('\n');
        const maxLengthOfTask = 200;
        const checkEachResponsibilityTask = tasksArray.map((string: string) => string && string.length > maxLengthOfTask).includes(true);
        return !checkEachResponsibilityTask;
      }
      return true;
    })
    .test('max tasks count', 'default_responsibilities_length_of_tasks', (value: string | undefined) => {
      if (value) {
        const tasksArray = value.split('\n').filter(task => task !== '');
        const maxLengthOfSeparateTasks = 10;
        const checkEachResponsibilityTask = tasksArray.length > maxLengthOfSeparateTasks;
        return !checkEachResponsibilityTask;
      }
      return true;
    }),
});

export const AssumeProfileFormValidateSchema = Yup.object({
  profileId: Yup.string().required(),
});
