import { FC } from 'react';

const UninvitedArrowIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_8283_74346" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_8283_74346)">
        <path
          d="M9.9 19C8.28333 19 6.896 18.475 5.738 17.425C4.57933 16.375 4 15.0667 4 13.5C4 11.9333 4.57933 10.625 5.738 9.575C6.896 8.525 8.28333 8 9.9 8H16.2L13.6 5.4L15 4L20 9L15 14L13.6 12.6L16.2 10H9.9C8.85 10 7.93767 10.3333 7.163 11C6.38767 11.6667 6 12.5 6 13.5C6 14.5 6.38767 15.3333 7.163 16C7.93767 16.6667 8.85 17 9.9 17H17V19H9.9Z"
          fill="#082051"
          fillOpacity="0.5"
        />
      </g>
    </svg>
  );
};

export default UninvitedArrowIcon;
