import React from 'react';
import Skeleton from 'react-loading-skeleton';

const MainInformationSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col my-8 space-y-3">
      <div className="flex w-full items-center text-sm sm:justify-start justify-between">
        <Skeleton width={110} height={14} containerClassName="w-52" />
        <Skeleton width={100} height={14} />
      </div>
      <div className="flex w-full items-center text-sm sm:justify-start justify-between">
        <Skeleton width={110} height={14} containerClassName="w-52" />
        <Skeleton width="100%" height={14} containerClassName="w-48 sm:w-60" />
      </div>
      <div className="flex w-full items-center text-sm sm:justify-start justify-between">
        <Skeleton width={160} height={14} containerClassName="w-52" />
        <Skeleton width={80} height={14} />
      </div>
      <div className="flex w-full items-center text-sm sm:justify-start justify-between">
        <Skeleton width={110} height={14} containerClassName="w-52" />
        <Skeleton width={15} height={14} />
      </div>
      <div className="flex w-full items-center text-sm sm:justify-start justify-between">
        <Skeleton width={120} height={14} containerClassName="w-52" />
        <Skeleton width={15} height={14} />
      </div>
      <div className="flex w-full items-center text-sm sm:justify-start justify-between">
        <Skeleton width={70} height={14} containerClassName="w-52" />
        <Skeleton width={120} height={14} />
      </div>
      <div className="flex w-full items-center text-sm sm:justify-start justify-between">
        <Skeleton width={90} height={14} containerClassName="w-52" />
        <Skeleton width={80} height={14} />
      </div>
      <div className="flex w-full items-center text-sm sm:justify-start justify-between">
        <Skeleton width={80} height={14} containerClassName="w-52" />
        <div className="flex">
          <Skeleton width={24} height={16} className="mr-1" />
          <Skeleton width={70} height={14} className="align-middle" />
        </div>
      </div>
      <div className="flex w-full items-center text-sm sm:justify-start justify-between">
        <Skeleton width={95} height={14} containerClassName="w-52" />
        <Skeleton width={120} height={14} />
      </div>
    </div>
  );
};

export default MainInformationSkeleton;
