// packages
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { orderBy } from 'lodash';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
// components
import { Button } from 'system/Button';
import AllCertificateListItem from './components/AllCertificateListItem';
// schemas
import { ICertificate } from 'models/ICertificate';
// types
import { AllCertificateListProps } from '../types';

const CertificatesAllList = ({ closeModal, dataCerts, certificateInfos }: AllCertificateListProps) => {
  const defaultClasses = 'relative border border-solid shadow-sm block w-full border-gray-300 rounded-md flex items-center p-1 ';
  const [data, setData] = useState<ReadonlyArray<ICertificate>>(orderBy(dataCerts.certificates?.edges, ['name'], ['asc']) || []);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setData(
      orderBy(
        dataCerts.certificates?.edges?.filter(
          ({ name, kind }) => name.toLowerCase().includes(event.currentTarget.value.toLowerCase()) || kind?.name.toLowerCase().includes(event.currentTarget.value.toLowerCase()),
        ) as ReadonlyArray<ICertificate>,
        ['name'],
        ['asc'],
      ),
    );
  };

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <section>
      <div className={defaultClasses}>
        <MagnifyingGlassIcon className="ml-2 w-6 h-6 text-specialGray-075" />
        <input
          type="text"
          placeholder="Search for certificate"
          onChange={handleChange}
          className="w-full rounded-md sm:text-sm border border-transparent focus:border-transparent focus:ring-transparent "
          ref={inputRef}
        />
      </div>
      <div className="relative border border-solid shadow-sm block w-full border-gray-300 rounded-md mt-6 h-96 overflow-y-scroll z-0">
        {data?.map(certificate => certificate && <AllCertificateListItem key={`${certificate.id}-${certificate.name}`} cert={certificate} addedCerts={certificateInfos} />)}
      </div>
      <div className="flex justify-between sm:justify-end mt-6">
        <Button labelClasses="text-xs sm:text-sm" onClick={closeModal} buttonType="secondary" type="submit" label={{ id: 'cancel' }} />

        <div className="ml-2">
          <Button labelClasses="text-xs sm:text-sm" onClick={closeModal} type="submit" label={{ id: 'self_certs_modalSaveCertificatesButton' }} />
        </div>
      </div>
    </section>
  );
};

export default CertificatesAllList;
