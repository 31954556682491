// packages
import { Suspense } from 'react';
// routes
import { RoutePaths } from 'app/routing';
// components
import SpinnerIcon from 'icons/SpinnerIcon';
import PrivateRoutes from 'app/PrivateRoutes/PrivateRoutes';
import ErrorBoundaryWithModal from 'system/ErrorBoundaryWithModal';
import JobResponsesPage from 'app/JobResponsesPage/JobResponsesPage';
import ListOfApplied from 'app/JobResponsesPage/components/ListOfApplied/ListOfApplied';
import ListOfInvitations from 'app/JobResponsesPage/components/ListOfInvitations/ListOfInvitations';

export const JobResponsesRoute = {
  path: `${RoutePaths.JobResponses}/*`,
  element: (
    <PrivateRoutes>
      <Suspense fallback={<SpinnerIcon />}>
        <JobResponsesPage />
      </Suspense>
    </PrivateRoutes>
  ),
  children: [
    {
      path: RoutePaths.JobResponsesInvitation,
      element: (
        <Suspense fallback={<SpinnerIcon />}>
          <ErrorBoundaryWithModal>
            <ListOfInvitations />
          </ErrorBoundaryWithModal>
        </Suspense>
      ),
    },
    {
      path: RoutePaths.JobResponsesApplied,
      element: (
        <Suspense fallback={<SpinnerIcon />}>
          <ListOfApplied />
        </Suspense>
      ),
    },
  ],
};
