// packages
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useLazyLoadQuery } from 'react-relay';
// components
import { Button } from 'system/Button';
import SpinnerIcon from 'icons/SpinnerIcon';
import OrganisationItem from './components/OrganisationItem';
// generated
import { SearchQueriesOrganisationsQuery } from 'schemas/search/__generated__/SearchQueriesOrganisationsQuery.graphql';
import { OrganisationFragmentsForSearchFragment$key } from 'schemas/organisations/__generated__/OrganisationFragmentsForSearchFragment.graphql';
import { OrganisationQueriesGetListOfOrganisationsByIdsQuery } from 'schemas/organisations/__generated__/OrganisationQueriesGetListOfOrganisationsByIdsQuery.graphql';
// schemas
import { SEARCH_ORGANISATIONS_QUERY } from 'schemas/search/SearchQueries';
import { GET_LIST_OF_ORGANISATIONS_BY_IDS } from 'schemas/organisations/OrganisationQueries';
// models
import { SEARCH_INDEX } from 'models/enums';
// context
import { useNavbarSearchContext } from 'Context/NavbarSearchContext';

const ListOfEmployers = ({ setAmountOrganisations }: { setAmountOrganisations: Dispatch<SetStateAction<number>> }) => {
  const { queryParams } = useNavbarSearchContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [numberOfOrganizations, setNumberOfOrganizations] = useState<number>(10);
  const [oldEndCourse, setOldEndCourse] = useState<string>('');

  const getSearchedOrganisations = useLazyLoadQuery<SearchQueriesOrganisationsQuery>(
    SEARCH_ORGANISATIONS_QUERY,
    {
      first: numberOfOrganizations,
      query: queryParams,
      index: SEARCH_INDEX.ORGANIZATION,
      skip: false,
    },
    { fetchPolicy: 'store-and-network' },
  ).search;

  useEffect(() => {
    setAmountOrganisations(getSearchedOrganisations?.totalCount as number);
  }, [getSearchedOrganisations?.totalCount, setAmountOrganisations]);

  const listOfOrganisations = useLazyLoadQuery<OrganisationQueriesGetListOfOrganisationsByIdsQuery>(GET_LIST_OF_ORGANISATIONS_BY_IDS, {
    ids: isEmpty(getSearchedOrganisations?.edges) ? [] : (getSearchedOrganisations?.edges?.map(organisation => organisation?.node?.objectID) as Array<string>),
  }).nodes;

  const handleLoadMore = useCallback(() => {
    setNumberOfOrganizations((prevState: number) => prevState + 10);

    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (getSearchedOrganisations?.pageInfo.endCursor !== oldEndCourse) {
      setIsLoading(false);
    }
  }, [getSearchedOrganisations?.pageInfo.endCursor, oldEndCourse]);

  useEffect(() => {
    setOldEndCourse(getSearchedOrganisations?.pageInfo.endCursor as string);
  }, [getSearchedOrganisations?.pageInfo.endCursor]);

  return (
    <div className="flex flex-col gap-y-6">
      {listOfOrganisations?.map(organisation =>
        organisation ? <OrganisationItem key={organisation?.id} organisation={organisation as OrganisationFragmentsForSearchFragment$key} /> : null,
      )}
      {getSearchedOrganisations?.pageInfo.hasNextPage && (
        <>
          {!isLoading ? (
            <Button
              type="button"
              buttonType="white-primary"
              label={{ id: 'job_opening_load_more_gob_openings' }}
              additionalClasses="mx-auto px-6 py-4 rounded-xl"
              onClick={handleLoadMore}
            />
          ) : (
            <SpinnerIcon />
          )}
        </>
      )}
    </div>
  );
};

export default ListOfEmployers;
