import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

const NavbarSearchDropdownJobOpeningsSkeleton: FC = () => {
  return (
    <>
      <div className="flex justify-between items-center border-b h-[52px] px-3 w-full text-xs sm:text-sm">
        <div className="flex space-x-2">
          <Skeleton width={95} height={14} />
          <Skeleton width={20} height={14} />
        </div>
        <div className="flex space-x-1">
          <Skeleton width={25} height={14} />
          <Skeleton width={25} height={14} />
        </div>
      </div>

      <div className="w-full px-2.5 flex items-center border-b border-solid border-gray-300 h-[73px]">
        <Skeleton width={56} height={56} className="rounded-md" />
        <div className="flex flex-col ml-3 ">
          <section className="flex space-x-2">
            <Skeleton width={35} height={14} />
            <Skeleton width={85} height={14} />
            <Skeleton width={65} height={14} />
          </section>
          <section className="flex space-x-2">
            <Skeleton width={30} height={14} />
            <Skeleton width={35} height={14} />
            <div className="flex pt-1.5">
              <Skeleton width={15} height={2} />
            </div>
            <Skeleton width={35} height={14} />
            <Skeleton width={25} height={14} />
          </section>
        </div>
      </div>
      <div className="w-full px-2.5 flex items-center border-b border-solid border-gray-300 h-[73px]">
        <Skeleton width={56} height={56} className="rounded-md" />
        <div className="flex flex-col ml-3 ">
          <section className="flex space-x-2">
            <Skeleton width={35} height={14} />
            <Skeleton width={85} height={14} />
            <Skeleton width={65} height={14} />
          </section>
          <section className="flex space-x-2">
            <Skeleton width={30} height={14} />
            <Skeleton width={35} height={14} />
            <div className="flex pt-1.5">
              <Skeleton width={15} height={2} />
            </div>
            <Skeleton width={35} height={14} />
            <Skeleton width={25} height={14} />
          </section>
        </div>
      </div>
      <div className="w-full px-2.5 flex items-center border-b border-solid border-gray-300 h-[73px]">
        <Skeleton width={56} height={56} className="rounded-md" />
        <div className="flex flex-col ml-3 ">
          <section className="flex space-x-2">
            <Skeleton width={35} height={14} />
            <Skeleton width={85} height={14} />
            <Skeleton width={65} height={14} />
          </section>
          <section className="flex space-x-2">
            <Skeleton width={30} height={14} />
            <Skeleton width={35} height={14} />
            <div className="flex pt-1.5">
              <Skeleton width={15} height={2} />
            </div>
            <Skeleton width={35} height={14} />
            <Skeleton width={25} height={14} />
          </section>
        </div>
      </div>
    </>
  );
};

export default NavbarSearchDropdownJobOpeningsSkeleton;
