/**
 * @generated SignedSource<<6b79b2e2a4ead1cb1903079e464b062b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type AppraisalFragmentsOneAppraisalWithoutDocument$data = {
  readonly 'id': string;
  readonly 'owner': {
    readonly id: string;
  };
  readonly 'review': string | null;
  readonly 'seaService': {
    readonly duty: {
      readonly id: string;
      readonly name: string;
    };
    readonly end: String | null;
    readonly id: string;
    readonly start: String;
    readonly vessel: {
      readonly cnISO: string | null;
      readonly countryName: string | null;
      readonly image: string | null;
      readonly name: string;
    };
  };
  readonly ' $fragmentType': 'AppraisalFragmentsOneAppraisalWithoutDocument';
};
export type AppraisalFragmentsOneAppraisalWithoutDocument$key = {
  readonly ' $data'?: AppraisalFragmentsOneAppraisalWithoutDocument$data;
  readonly ' $fragmentSpreads': FragmentRefs<'AppraisalFragmentsOneAppraisalWithoutDocument'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    };
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'AppraisalFragmentsOneAppraisalWithoutDocument',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'review',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Profile',
        kind: 'LinkedField',
        name: 'owner',
        plural: false,
        selections: [v0 /*: any*/],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'SeaService',
        kind: 'LinkedField',
        name: 'seaService',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'start',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'end',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'Vessel',
            kind: 'LinkedField',
            name: 'vessel',
            plural: false,
            selections: [
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'image',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'cnISO',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'countryName',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'Duty',
            kind: 'LinkedField',
            name: 'duty',
            plural: false,
            selections: [v0 /*: any*/, v1 /*: any*/],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
    type: 'Appraisal',
    abstractKey: null,
  };
})();

(node as any).hash = 'cb9c0bb1f619b5df9be6f9dfe4786d82';

export default node;
