/**
 * @generated SignedSource<<5435ecd51221c38a022a59fba938e5d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrganizationMemberRole = 'admin' | 'invited' | 'member' | 'owner' | '%future added value';
import { FragmentRefs } from 'relay-runtime';
export type ProfileFragmentsOrganizationMemberInfo$data = {
  readonly 'id': string;
  readonly 'organization': {
    readonly avatarUrl: string | null;
    readonly id: string;
    readonly name: string;
  };
  readonly 'profile': {
    readonly certificateInfos: ReadonlyArray<{
      readonly id: string;
    }> | null;
    readonly educations: ReadonlyArray<{
      readonly id: string;
    }> | null;
    readonly id: string;
    readonly info: {
      readonly avatarUrl: string | null;
      readonly birthDate: String;
      readonly country: {
        readonly isoCode: string;
        readonly name: string;
      };
      readonly firstName: string;
      readonly lastName: string;
      readonly primaryDuty: {
        readonly name: string;
      } | null;
    } | null;
    readonly seaServices: ReadonlyArray<{
      readonly vessel: {
        readonly imo: number;
      };
    }> | null;
  };
  readonly 'role': OrganizationMemberRole;
  readonly ' $fragmentType': 'ProfileFragmentsOrganizationMemberInfo';
};
export type ProfileFragmentsOrganizationMemberInfo$key = {
  readonly ' $data'?: ProfileFragmentsOrganizationMemberInfo$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsOrganizationMemberInfo'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = [v0 /*: any*/],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'avatarUrl',
      storageKey: null,
    };
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'ProfileFragmentsOrganizationMemberInfo',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'Profile',
        kind: 'LinkedField',
        name: 'profile',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'Education',
            kind: 'LinkedField',
            name: 'educations',
            plural: true,
            selections: v1 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'SeaService',
            kind: 'LinkedField',
            name: 'seaServices',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'Vessel',
                kind: 'LinkedField',
                name: 'vessel',
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'imo',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'CertificateInfo',
            kind: 'LinkedField',
            name: 'certificateInfos',
            plural: true,
            selections: v1 /*: any*/,
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'ProfileInfo',
            kind: 'LinkedField',
            name: 'info',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'Duty',
                kind: 'LinkedField',
                name: 'primaryDuty',
                plural: false,
                selections: [v2 /*: any*/],
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'Country',
                kind: 'LinkedField',
                name: 'country',
                plural: false,
                selections: [
                  v2 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'isoCode',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'birthDate',
                storageKey: null,
              },
              v3 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'firstName',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'lastName',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Organization',
        kind: 'LinkedField',
        name: 'organization',
        plural: false,
        selections: [v0 /*: any*/, v3 /*: any*/, v2 /*: any*/],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'role',
        storageKey: null,
      },
    ],
    type: 'OrganizationMember',
    abstractKey: null,
  };
})();

(node as any).hash = '467252ac5ebdafb8cd22a9913f8eef27';

export default node;
