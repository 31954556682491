import { useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
//routing
import { RoutesPathNames } from 'app/routing';
//components
import { Modal } from 'system/Modal';
import EmployerAddUserToCrewPoolCommon from './components/EmployerAddUserToCrewPoolCommon';

const EmployerAddUserToCrewPoolModalByUrl = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userId } = useParams();

  const onHandleClose = useCallback(() => navigate(location.pathname.replace(`/${RoutesPathNames.addUserToPool}/${userId}`, '')), [location.pathname, navigate, userId]);
  return (
    <Modal
      childrenClass="flex w-full text-darkBlue"
      description={{ id: 'hire_crew_pool_invite_user_description' }}
      title={{ id: 'hire_crew_pool_invite_user_title' }}
      onClose={onHandleClose}
      show={!!userId}
    >
      <EmployerAddUserToCrewPoolCommon userId={`${userId}`} onHandleClose={onHandleClose} />
    </Modal>
  );
};

export default EmployerAddUserToCrewPoolModalByUrl;
