// routes
import { RoutePaths, RoutesPathNames } from 'app/routing';
// components
import ProfileWrapper from '../components/ProfileWrapper';
import AssumeProfileModal from '../adminProfile/AssumeProfileModal';
import GetTabsForCrew from '../components/selfProfile/GetTabsForCrew';
import EditProfileInfo from '../components/selfProfile/EditProfileInfo/EditProfileInfo';
// models
import { TAB_NAMES } from 'models/enums';

export const SelfProfileRoute = {
  path: RoutePaths.ProfileBase,
  Component: ProfileWrapper,
  children: [
    {
      index: true,
      element: <GetTabsForCrew tabName={TAB_NAMES.OVERVIEW} />,
    },
    {
      path: RoutesPathNames.editProfileInfo,
      Component: EditProfileInfo,
    },
    {
      path: RoutesPathNames.ProfileBaseSeaServices,
      element: <GetTabsForCrew tabName={TAB_NAMES.SEA_SERVICE} />,
    },
    {
      path: RoutesPathNames.ProfileBaseCertificates,
      element: <GetTabsForCrew tabName={TAB_NAMES.CERTIFICATES} />,
    },
    {
      path: RoutesPathNames.ProfileBaseEducation,
      element: <GetTabsForCrew tabName={TAB_NAMES.EDUCATIONS} />,
    },
    {
      path: RoutesPathNames.ProfileBaseAppraisals,
      element: <GetTabsForCrew tabName={TAB_NAMES.APPRAISALS} />,
    },
    {
      path: RoutesPathNames.assume,
      Component: AssumeProfileModal,
    },
  ],
};
