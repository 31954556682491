import { Suspense, useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { RoutePaths, RoutesPathNames } from 'app/routing';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
//components
import SlideOver from 'system/SlideOver';
import SpinnerIcon from 'icons/SpinnerIcon';
import { LazyLoader } from 'system/skeletons/wrapper/LazyLoader';
import ProfileInviteListItem from 'app/ProfileSearch/components/ProfileListItem/ProfileInviteListItem';
import ButtonProfileSearchNextPage from 'app/ProfileSearch/components/ButtonProfileSearchNextPage/ButtonProfileSearchNextPage';
//hooks
import useCrewUrlPath from 'hooks/useCrewURLPath';
import useSearchProfileFilterQuery from 'hooks/searchFilter/useSearchProfileFilterQuery';
//models
import { SearchProfileNode } from 'models/IProfiles';
import { ProfileSearchItemState } from 'models/routeLocationState';
import { ProfileInviteSearchMembersProps } from 'models/modelsOfComponents';
// recoil
import { profileSearchScrollWithPaginationSelector } from 'recoil/ScrollWithPagination/selectors/SearchScrollWithPaginationSelector';

const ProfileInviteSearchMembers = ({ queryParams }: ProfileInviteSearchMembersProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const crewPagePath = useCrewUrlPath();
  const { state, search, pathname } = useLocation();
  const { jobDescriptionEditId } = useParams();
  const { listOfProfiles } = useSearchProfileFilterQuery(queryParams);

  const allListWithProfiles = listOfProfiles.search?.edges;
  const hasNextPage = listOfProfiles.search?.pageInfo.hasNextPage;

  const getScrollWithPagination = useRecoilValue(profileSearchScrollWithPaginationSelector);

  const handleOpenProfile = useCallback(
    (profileId: string) => () => {
      navigate(`${crewPagePath}/${profileId}`);
    },
    [navigate, crewPagePath],
  );

  const handleCloseSlide = useCallback(() => {
    navigate(
      { pathname: `${RoutePaths.ProfileBase}/${RoutesPathNames.job}/${jobDescriptionEditId}/${RoutePaths.JobInviteCrew}`, search },
      { state: { ...(state as ProfileSearchItemState), isOpenProfileSlide: false, isOpenCertificateSlide: false } },
    );
  }, [navigate, jobDescriptionEditId, search, state]);

  useEffect(() => {
    // save previous scroll place for Safari browser after Slider closing
    window.scrollTo(0, getScrollWithPagination.scrollY);
  }, [getScrollWithPagination.scrollY, pathname]);

  return (
    <>
      <SlideOver show={!!(state as ProfileSearchItemState)?.isOpenCertificateSlide} onClose={handleCloseSlide} size="max-w-xl">
        <Outlet />
      </SlideOver>
      {!isEmpty(allListWithProfiles) && allListWithProfiles ? (
        <div className="flex flex-col w-full">
          {allListWithProfiles.map(profile => {
            return (
              <div key={profile?.node?.objectID}>
                <Suspense
                  fallback={
                    <LazyLoader
                      mainComponent={<ProfileInviteListItem profile={profile?.node as SearchProfileNode} onClick={handleOpenProfile(profile?.node?.objectID as string)} />}
                    >
                      <SpinnerIcon />
                    </LazyLoader>
                  }
                >
                  <ProfileInviteListItem profile={profile?.node as SearchProfileNode} onClick={handleOpenProfile(profile?.node?.objectID as string)} />
                </Suspense>
              </div>
            );
          })}
          {hasNextPage && <ButtonProfileSearchNextPage totalNumberOfUsers={listOfProfiles.search?.edges?.length} />}
        </div>
      ) : (
        <div className="mx-auto sm:w-30 w-full text-center text-specialGray-075">{intl.formatMessage({ id: 'search_by_duty_did_not_any_results' })}</div>
      )}
    </>
  );
};

export default ProfileInviteSearchMembers;
