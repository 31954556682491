// packages
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Tab } from '@headlessui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
// components
import { Button } from 'system/Button';
import SpinnerIcon from 'icons/SpinnerIcon';
import { DashboardEmptyField } from '../DashboardEmptyField';
import WrapperPanelDesktop from '../PanelTabWithTable/WrapperPanelDesktop';
import PanelTabWithMobileList from '../PanelTabWithTable/PanelTabWithMobileList';
// recoil
import { dashboardPaginationType } from 'recoil/Organisation/types';
import { dashboardOpenPaginationAtom } from 'recoil/Organisation/Dashboard/atoms/dashboardPaginationAtom';
import { fetchDashboardVacanciesByParamsAtom } from 'recoil/Organisation/Dashboard/atoms/fetchDashboardVacanciesByParamsAtom';
// hooks
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useGetNumberOfOpenedAndClosedVacancies } from 'hooks/hireProfileHooks';
// enums
import { VACANCY_STATUS } from 'models/enums';

const PanelTabForOpenJobs = () => {
  const intl = useIntl();
  const { sm } = useBreakpoints();

  const [getDashboardPagination, setDashboardPagination] = useRecoilState(dashboardOpenPaginationAtom);
  const getFetchDashboardVacanciesByParams = useRecoilValue(fetchDashboardVacanciesByParamsAtom);

  const isOpenTab = getFetchDashboardVacanciesByParams.filterByStatus === VACANCY_STATUS.OPEN;

  const { getNumberOfOpenedVacancies } = useGetNumberOfOpenedAndClosedVacancies();

  const handleLoadMore = useCallback(() => {
    setDashboardPagination((prevState: dashboardPaginationType) => ({ ...prevState, numberOfFetch: prevState.numberOfFetch + 10, isLoading: true }));
  }, [setDashboardPagination]);

  return (
    <>
      <Tab.Panel>
        {getNumberOfOpenedVacancies ? (
          <>{sm ? <PanelTabWithMobileList /> : <WrapperPanelDesktop />}</>
        ) : (
          <DashboardEmptyField message={intl.formatMessage({ id: 'hire_dashboard_table_empty_fields' })} />
        )}
      </Tab.Panel>
      {getDashboardPagination.hasNextPage && isOpenTab && !!getNumberOfOpenedVacancies ? (
        <div className="py-4 flex justify-center w-full">
          {!getDashboardPagination.isLoading ? (
            <Button
              type="button"
              buttonType="white-primary"
              label={{ id: 'job_opening_load_more_gob_openings' }}
              additionalClasses="mx-auto px-6 py-4 rounded-xl"
              onClick={handleLoadMore}
            />
          ) : (
            <SpinnerIcon />
          )}
        </div>
      ) : null}
    </>
  );
};

export default PanelTabForOpenJobs;
