import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
//generated
import { ProfileQueriesGetCrewPoolStatsQuery } from 'schemas/profile/__generated__/ProfileQueriesGetCrewPoolStatsQuery.graphql';
import { ProfileQueriesGetProfileCrewPoolsQuery } from 'schemas/profile/__generated__/ProfileQueriesGetProfileCrewPoolsQuery.graphql';
//components
import EmployerCrewPoolItem from './components/EmployerCrewPoolItem';
import EmployerCrewPoolsHeader from './components/EmployerCrewPoolsHeader';
import { useLazyLoadQuery } from 'react-relay';
//schemas
import { GET_CREW_POOLS, GET_CREW_POOLS_STATS } from 'schemas/profile/ProfileQueries';
//hooks
import { useGetHireProfile } from 'hooks/hireProfileHooks';

const EmployerCrewPoolsMain = () => {
  const intl = useIntl();
  const { organizationId } = useGetHireProfile();

  const data = useLazyLoadQuery<ProfileQueriesGetProfileCrewPoolsQuery>(GET_CREW_POOLS, { orgId: organizationId! });

  const allPoolIds = useMemo(() => {
    const poolEdges = data.crewPools?.edges;
    const allPoolIds: string[] = [];
    if (!poolEdges) {
      return allPoolIds;
    }
    poolEdges.forEach(poolNode => poolNode?.node?.id && allPoolIds.push(poolNode.node.id));
    return allPoolIds;
  }, [data]);

  const poolStatsDataFr = useLazyLoadQuery<ProfileQueriesGetCrewPoolStatsQuery>(GET_CREW_POOLS_STATS, { poolIds: allPoolIds });

  const totalAddedUsers = useMemo(() => {
    return poolStatsDataFr.crewPoolStats?.stats.reduce((acc, currentValue) => {
      return acc + currentValue.totalCrewCount;
    }, 0);
  }, [poolStatsDataFr]);

  return (
    <section className="flex flex-col w-full pt-8">
      <EmployerCrewPoolsHeader totalAddedUsers={totalAddedUsers || 0} totalCrewPools={data.crewPools?.totalCount || 0} />

      <Outlet />

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full gap-x-6 gap-y-2 mt-[38px]">
        {!!data.crewPools?.edges?.length ? (
          data.crewPools?.edges.map(item => {
            const findPoolStatsFragment = poolStatsDataFr.crewPoolStats?.stats.find(statItem => statItem.id === item?.node?.id);
            return item?.node && <EmployerCrewPoolItem poolStats={findPoolStatsFragment || null} key={item.node.id} crewPool={item.node} />;
          })
        ) : (
          <div
            data-test-id="crew-pools-empty-message"
            className="grid w-full col-span-3 p-10 border border-specialGray-012 bg-specialGray-004 rounded-2xl text-specialGray-075 justify-center mt-6"
          >
            <span>{intl.formatMessage({ id: 'hire_crew_pools_is_empty' })}</span>
          </div>
        )}
      </div>
    </section>
  );
};

export default EmployerCrewPoolsMain;
