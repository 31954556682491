import { useRecoilValue } from 'recoil';
import Skeleton from 'react-loading-skeleton';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';

const ListOfCrewItemsSkeleton = () => {
  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);

  return (
    <div className="flex flex-col sm:flex-row border-solid border border-specialGray-01 rounded-[15px] lg:pb-7 lg:pt-[27px] lg:px-6 sm:p-4 p-3.5 cursor-pointer w-full bg-white gap-y-3.5">
      <div className="flex gap-x-4 lg:gap-x-6 w-full">
        <Skeleton width={160} height={160} borderRadius={12} containerClassName="hidden lg:inline" />
        <Skeleton width={56} height={56} borderRadius={12} containerClassName="hidden sm:flex lg:hidden" />
        <Skeleton width={66} height={66} borderRadius={12} containerClassName="flex sm:hidden" />
        <div className="flex flex-col w-full">
          <div className="flex relative w-full justify-between pb-4">
            <div className="flex flex-col sm:flex-row gap-x-4 gap-y-1">
              <Skeleton width={200} height={18} />
              <Skeleton width={100} containerClassName="mt-0.5 git inline lg:hidden" />
            </div>
            {getCurrentOrganisation.isSelected && <Skeleton width={112} height={42} borderRadius={6} containerClassName="hidden sm:flex" />}
          </div>
          <section className="hidden lg:flex w-full">
            <div className="inline-flex w-1/2 flex-col">
              <div className="flex flex-col w-full">
                <div className="flex w-full">
                  <Skeleton width={30} height={12} containerClassName="mt-[1px] w-[84px]" />
                  <Skeleton width={80} height={14} />
                </div>
                <div className="flex w-full">
                  <Skeleton width={56} height={12} containerClassName="mt-[1px] w-[84px]" />
                  <Skeleton width={80} height={14} />
                  <Skeleton width={24} containerClassName="ml-2 -mt-[1px]" />
                </div>
                <div className="flex w-full">
                  <Skeleton width={40} height={12} containerClassName="mt-[1px] w-[84px]" />
                  <Skeleton width={110} height={14} />
                </div>
                <div className="flex w-full mt-3 gap-x-3 justify-start">
                  <Skeleton width={125} height={14} containerClassName="mt-2" />
                  <div className="flex w-full flex-wrap gap-x-3">
                    <Skeleton width={140} height={28} borderRadius={8} />
                    <Skeleton width={140} height={28} borderRadius={8} />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col text-sm w-1/2 space-y-2">
              <div className="flex w-full">
                <Skeleton width={75} height={12} containerClassName="mt-[1px] w-24" />
                <Skeleton width={15} height={14} />
              </div>
              <div className="flex w-full">
                <Skeleton width={70} height={12} containerClassName="mt-[1px] w-24" />
                <Skeleton width={120} height={14} />
              </div>
              <div className="flex w-full">
                <Skeleton width={65} height={12} containerClassName="mt-[1px] w-24" />
                <div className="flex flex-col space-y-2">
                  <div className="flex space-x-2">
                    <Skeleton width={12} height={14} />
                    <Skeleton width={240} height={14} />
                  </div>
                  <div className="flex space-x-2">
                    <Skeleton width={12} height={14} />
                    <Skeleton width={220} height={14} />
                  </div>
                  <div className="flex space-x-2">
                    <Skeleton width={12} height={14} />
                    <Skeleton width={230} height={14} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*The tablet layout*/}
          <div className="hidden sm:flex lg:hidden gap-x-3">
            <div className="flex space-x-2">
              <Skeleton width={24} height={12} />
              <Skeleton width={80} height={12} />
            </div>
            <div className="flex space-x-2">
              <Skeleton width={50} height={12} />
              <Skeleton width={65} height={12} />
            </div>
            <div className="flex space-x-2">
              <Skeleton width={75} height={12} />
              <Skeleton width={12} height={12} />
            </div>
            <div className="flex space-x-2">
              <Skeleton width={70} height={12} />
              <Skeleton width={12} height={12} />
            </div>
          </div>
        </div>
      </div>
      {/*The mobile layout*/}
      <div className="flex sm:hidden flex-col w-full gap-y-2">
        <div className="flex gap-x-1.5 text-xs w-full justify-between">
          <Skeleton width={25} height={12} />
          <Skeleton width={75} height={12} />
        </div>
        <div className="flex gap-x-1.5 text-xs w-full justify-between">
          <Skeleton width={50} height={12} />
          <div className="flex gap-x-2">
            <Skeleton width={24} height={18} />
            <Skeleton width={80} height={12} containerClassName="mt-0.5" />
          </div>
        </div>
        <div className="flex gap-x-1.5 text-xs w-full justify-between">
          <Skeleton width={75} height={12} />
          <Skeleton width={85} height={12} />
        </div>
        <div className="flex gap-x-1.5 text-xs w-full justify-between">
          <Skeleton width={70} height={12} />
          <Skeleton width={80} height={12} />
        </div>
      </div>
      {getCurrentOrganisation.isSelected && <Skeleton width="100%" height={42} borderRadius={6} containerClassName="flex sm:hidden w-full xs:w-1/2 mx-auto" />}
    </div>
  );
};

export default ListOfCrewItemsSkeleton;
