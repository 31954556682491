// packages
import React, { useCallback, useState, useEffect } from 'react';
import { useField } from 'formik';
import { useIntl } from 'react-intl';
import { uniqueId } from 'lodash';
// components
import LinkWithText from './LinkWithText';
import { FieldErrorMessage } from './FieldErrorMessage';
import LabelComponent from 'system/LabelComponent/LabelComponent';
// models
import { CheckboxInputGroupProps } from 'models/modelsOfComponents';

export const CheckboxInputGroup = ({ description, label, onChange, name, value, labelLink, hrefLink, colorLabel = 'text-blue' }: CheckboxInputGroupProps) => {
  const [id] = useState(() => uniqueId('ig-'));
  const [field, meta, { setValue }] = useField<string>(name);
  const intl = useIntl();
  const showError = meta.touched && meta.error;

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      const target: HTMLInputElement = event.target as HTMLInputElement;
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        target.checked = !target.checked;
        onChange(target.checked);
        setValue(JSON.parse(target.checked.toString()));
      }
    },
    [setValue, onChange],
  );

  const onChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
    setValue(JSON.parse(e.target.checked.toString()));
  };

  useEffect(() => {
    setValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col">
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            {...field}
            onKeyDown={handleKeyDown}
            onChange={onChangeField}
            checked={JSON.parse(field.value)}
            id={name + id}
            name={name}
            data-test-id={name}
            type="checkbox"
            className="focus:border-blue text-blue h-4 w-4 border-specialGray-012 rounded  cursor-pointer"
          />
        </div>
        <div className="ml-2 sm:ml-3 text-sm">
          <LabelComponent id={name + id} label={label} classes={`${colorLabel} text-xs sm:text-sm font-normal cursor-pointer`} />
          {labelLink && hrefLink && (
            <>
              &nbsp;
              <LinkWithText label={labelLink} href={hrefLink} />
            </>
          )}
          {description && <p className="text-specialGray-075">{intl.formatMessage(description)}</p>}
        </div>
      </div>

      {showError && <FieldErrorMessage error={meta.error} />}
    </div>
  );
};
