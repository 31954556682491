import { FC } from 'react';

const LockWhiteIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" id="lock-white-icon">
      <rect width="24" height="24" rx="12" fill="#082051" fillOpacity="0.3" />
      <mask id="mask0_7331_66591" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="4" y="4" width="16" height="16">
        <rect x="4.5" y="4.5" width="15" height="15" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7331_66591)">
        <path
          d="M12 5.125C12.8646 5.125 13.6017 5.42958 14.2113 6.03875C14.8204 6.64833 15.125 7.38542 15.125 8.25V9.5H15.75C16.0938 9.5 16.3881 9.62229 16.6331 9.86687C16.8777 10.1119 17 10.4062 17 10.75V17C17 17.3438 16.8777 17.6381 16.6331 17.8831C16.3881 18.1277 16.0938 18.25 15.75 18.25H8.25C7.90625 18.25 7.61208 18.1277 7.3675 17.8831C7.1225 17.6381 7 17.3438 7 17V10.75C7 10.4062 7.1225 10.1119 7.3675 9.86687C7.61208 9.62229 7.90625 9.5 8.25 9.5H8.875V8.25C8.875 7.38542 9.17979 6.64833 9.78938 6.03875C10.3985 5.42958 11.1354 5.125 12 5.125ZM12 6.375C11.4792 6.375 11.0365 6.55729 10.6719 6.92188C10.3073 7.28646 10.125 7.72917 10.125 8.25V9.5H13.875V8.25C13.875 7.72917 13.6927 7.28646 13.3281 6.92188C12.9635 6.55729 12.5208 6.375 12 6.375ZM12 12.625C11.6562 12.625 11.3621 12.7473 11.1175 12.9919C10.8725 13.2369 10.75 13.5312 10.75 13.875C10.75 14.2188 10.8725 14.5131 11.1175 14.7581C11.3621 15.0027 11.6562 15.125 12 15.125C12.3438 15.125 12.6381 15.0027 12.8831 14.7581C13.1277 14.5131 13.25 14.2188 13.25 13.875C13.25 13.5312 13.1277 13.2369 12.8831 12.9919C12.6381 12.7473 12.3438 12.625 12 12.625Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default LockWhiteIcon;
