// packages
import { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { orderBy } from 'lodash';
import { useIntl } from 'react-intl';
// components
import DropDownList from './DropDownList';
import SelectInputCommon from './SelectInputCommon';
import { FieldErrorMessage } from './FieldErrorMessage';
import { SelectInputProps } from 'models/modelsOfComponents';
import LabelComponent from 'system/LabelComponent/LabelComponent';
import { useChooseAndFocusElement } from 'hooks/useChooseAndFocusElement';

export type OptionType = {
  readonly id?: string;
  readonly name?: string;
  readonly label?: string;
  readonly parma?: string;
  alts?: readonly string[] | null;
};

const SelectInputGroup = ({
  options,
  name,
  openByDefault,
  defaultSelectedElement,
  defaultFocusedElement,
  disabled,
  onChangeSelect,
  label,
  setSelectedItem,
  selectedItem,
  sortOptionsByAlphabetically = false,
  type,
  autoComplete,
  optional = false,
  isLongDropdown,
  containerMargin = 'mb-2',
  ...props
}: SelectInputProps) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [sortedOptions, setSortedOptions] = useState<readonly OptionType[]>(options || []);
  const [isOpenList, setIsOpenList] = useState<boolean>(openByDefault || false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { setChooseElement, setIndexChoseElement, indexChoseElement, elementInFocus, handleMouseUp } = useChooseAndFocusElement(sortedOptions, defaultFocusedElement);
  const [{ onBlur, ...field }, meta, { setValue }] = useField<string>({
    name,
  });

  const [valueForInput, setValueForInput] = useState<string | undefined>('');
  const showError = meta.touched && meta.error;
  const classesDefault = 'shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full text-xs sm:text-sm border-specialGray-012 rounded-md';
  const classesDisabled = disabled ? 'cursor-not-allowed bg-gray-100' : '';
  const classesWithError = showError
    ? 'block w-full pr-10 border-red-300 text-red-900 placeholder:text-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
    : 'placeholder:text-specialGray-05';

  useEffect(() => {
    if (selectedItem) {
      if (isOpenList && valueForInput !== selectedItem?.name) {
        onChangeSelect(valueForInput as string);
      }
    } else {
      onChangeSelect(valueForInput as string);
    }
  }, [valueForInput, isOpenList, selectedItem, onChangeSelect]);

  useEffect(() => {
    if (sortOptionsByAlphabetically) {
      setSortedOptions(() => orderBy<OptionType>(options, 'name'));
    } else if (options) {
      setSortedOptions(options);
    }
  }, [options, sortOptionsByAlphabetically]);

  useEffect(() => {
    if (defaultSelectedElement && defaultFocusedElement) {
      setValueForInput(defaultSelectedElement?.name);
    } else if (defaultSelectedElement) {
      setValueForInput(defaultSelectedElement?.name);
    }
    if (selectedItem) {
      setChooseElement(selectedItem.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectedElement, defaultFocusedElement, selectedItem]);

  const handleSetValueForInput = useCallback(
    (value: string) => {
      const findElement = sortedOptions.find(element => element.id === value);
      if (setSelectedItem) {
        setSelectedItem(findElement);
        setValueForInput(findElement?.name);
      }
    },
    [sortedOptions, setSelectedItem],
  );

  const handleInputInFocus = useCallback(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  const handleChooseElement: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | MouseEvent | KeyboardEvent, value: string) => void | Promise<void> = useCallback(
    async (event, value) => {
      handleInputInFocus();
      event.stopPropagation();
      await setChooseElement(value);
      setValue(value);
      handleSetValueForInput(value);
      setIsOpenList(false);
    },
    [setValue, setChooseElement, handleInputInFocus, handleSetValueForInput],
  );

  useEffect(() => {
    if (selectedItem?.name) {
      setValueForInput(selectedItem.name);
    } else {
      setValueForInput('');
    }
  }, [selectedItem, isOpenList]);

  useEffect(() => {
    if (props.isResetInput && setSelectedItem) {
      setSelectedItem(undefined);
      setChooseElement('');
    }
  }, [props.isResetInput, setSelectedItem, setChooseElement]);

  const handleClickByWindow = useCallback(
    (event: MouseEvent) => {
      if (containerRef.current?.contains(event.target as HTMLInputElement)) {
        inputRef?.current?.focus();
      } else {
        inputRef.current?.blur();
        setIsOpenList(false);
      }
    },
    [containerRef, inputRef],
  );

  useEffect(() => {
    window?.addEventListener('mouseup', handleClickByWindow);
    return () => {
      window.removeEventListener('mouseup', handleClickByWindow);
    };
  }, [isOpenList, handleClickByWindow, inputRef]);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!selectedItem && setSelectedItem && valueForInput) {
      const getSelectedItem = options.find((item: typeof options[number]) => item.name.toLowerCase().includes(valueForInput.toLowerCase()));
      setSelectedItem(getSelectedItem);
      setValue(getSelectedItem?.id);
    }
    onBlur(event);
  };

  return (
    <div className={`relative flex flex-col ${containerMargin} w-full`} ref={containerRef}>
      <LabelComponent label={label} optional={optional} />
      <div className="relative mt-2 text-darkBlue">
        <SelectInputCommon
          ref={inputRef}
          onMouseUp={handleMouseUp}
          onChangeSelect={onChangeSelect}
          classes={`${classesDefault} ${classesWithError} ${classesDisabled}`}
          setValueForInput={setValueForInput}
          valueForInput={valueForInput}
          setIndexChoseElement={setIndexChoseElement}
          indexChoseElement={indexChoseElement}
          setIsOpenList={setIsOpenList}
          elementInFocus={elementInFocus}
          isOpenList={isOpenList}
          onInputInFocus={handleInputInFocus}
          altsElements={selectedItem?.alts}
          autoComplete={autoComplete}
          disabled={disabled}
          onBlur={handleBlur}
          {...props}
          {...field}
        />
      </div>
      <div className="relative">
        {isOpenList && (
          <DropDownList
            options={sortedOptions}
            onChooseElement={handleChooseElement}
            defaultFocusedElement={defaultFocusedElement}
            defaultSelectedElement={defaultSelectedElement}
            isLong={isLongDropdown}
          >
            <div className="py-1.5 mx-auto">{intl.formatMessage({ id: 'search_input_no_results' })}</div>
          </DropDownList>
        )}
      </div>
      {showError && <FieldErrorMessage error={meta.error} />}
    </div>
  );
};

export default SelectInputGroup;
