import { useIntl } from 'react-intl';
import { IntlValidationMessage } from 'models/modelsOfComponents';

// FieldErrorMessage translates and renders field errors. It specifically supports error values that are not
// actually Error types but MessageDescriptors that might be returned by Yup's setLocal()
export const FieldErrorMessage = ({ error, dynamicErrorValue }: { error?: unknown; dynamicErrorValue?: string | null }) => {
  const intl = useIntl();
  if (!error) {
    return null;
  }

  let msg: string;
  if ((error as IntlValidationMessage).message !== undefined) {
    msg = intl.formatMessage((error as IntlValidationMessage).message, (error as IntlValidationMessage).values);
  } else if (typeof 'message' === 'string') {
    msg = intl.formatMessage({ id: error as string }, dynamicErrorValue ? { value: dynamicErrorValue } : {});
  } else {
    throw new Error(`unsupported field error to render: ${error}`);
  }

  return <p className="mt-2 text-sm text-red-600">{msg}</p>;
};
