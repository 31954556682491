// packages
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ButtonProps } from 'models/modelsOfComponents';

// ButtonProps

// Button components implements a button that takes a lable to be translated and a optional icon.
export const Button: React.FC<ButtonProps> = ({
  label,
  labelClasses,
  icon,
  buttonType = 'primary',
  fullSize,
  prefixIcon,
  additionalClasses,
  iconWrapperSize = '',
  customIcon,
  withoutWidth,
  disabled,
  xsText,
  children,
  ...rest
}) => {
  const intl = useIntl();
  const buttonWidth = fullSize ? 'disabled:cursor-not-allowed block w-full py-3' : `disabled:cursor-not-allowed inline-flex ${!withoutWidth && 'w-max'}  `;

  let buttonStyle = '';

  switch (buttonType) {
    case 'primary':
      buttonStyle =
        'disabled:opacity-50 items-center px-3 py-2 border border-blue-600 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 hover:border-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ';
      break;
    case 'secondary':
      buttonStyle =
        'disabled:opacity-50 items-center px-3 py-2 border border-blue-100 shadow-sm text-sm leading-4 font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 hover:border-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ';
      break;
    case 'white-primary':
      buttonStyle =
        'disabled:opacity-50 items-center px-3 py-2 border border-specialGray-05 shadow-sm text-sm leading-4 font-medium rounded-md text-specialGray-075 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 ';
      break;
    case 'white-primary-rounded-xl':
      buttonStyle =
        'disabled:opacity-50 items-center px-3 py-2 border border-specialGray-05 shadow-sm text-sm leading-4 font-medium rounded-xl text-specialGray-075 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 ';
      break;
    case 'white-secondary':
      buttonStyle = `text-blue ${xsText ? 'text-xs sm:text-sm' : 'text-sm'} font-medium cursor-pointer `;
      break;
    case 'red-primary':
      buttonStyle =
        'disabled:opacity-50 items-center px-3 py-2 border border-errorRed shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-errorRed hover:bg-errorRed-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 ';
      break;
    case 'icon-button':
      buttonStyle = 'flex disabled:opacity-50 text-grayIcon hover:text-specialGray-1 ';
      break;
    case 'icon-blue-button':
      buttonStyle = 'flex-row-reverse disabled:opacity-50 text-blue-700 hover:text-blue-600';
      break;
    case 'just-icon':
      buttonStyle =
        'disabled:opacity-50 items-center px-3 py-2 border border-specialGray-05 shadow-sm text-sm leading-4 font-medium rounded-md text-specialGray-075 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 ';
      break;
    case 'just-icon-rounded-xl':
      buttonStyle =
        'disabled:opacity-50 items-center px-3 py-2 border border-specialGray-05 shadow-sm text-sm leading-4 font-medium rounded-xl text-specialGray-075 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 ';
      break;
    case 'icon-blue-only':
      buttonStyle = 'disabled:opacity-50 text-blue-700 hover:text-blue-600 ';
      break;
    default:
  }

  const iconWrapperStyleDefault = useMemo(() => {
    if (buttonType === 'icon-button') {
      return 'ml-0';
    } else if (buttonType === 'just-icon') {
      return 'ml-0';
    } else if (buttonType === 'just-icon-rounded-xl') {
      return 'ml-0';
    } else {
      return 'ml-2';
    }
  }, [buttonType]);

  return (
    <button
      data-test-id={label ? intl.formatMessage(label) : ''}
      disabled={disabled}
      className={buttonStyle + (buttonType !== 'icon-blue-only' && buttonWidth) + additionalClasses}
      {...rest}
    >
      {prefixIcon && React.createElement(prefixIcon, { 'className': 'mr-2 w-4 inline-block', 'aria-hidden': true })}
      {label && <span className={labelClasses}>{intl.formatMessage(label)}</span>}
      {icon && (
        <div className={`${iconWrapperSize || 'h-4 w-4'} ${iconWrapperStyleDefault} flex justify-center items-center`}>
          {React.createElement(icon, { 'className': 'h-4 w-4', 'aria-hidden': true })}
        </div>
      )}
      {customIcon && <div className={` ${iconWrapperStyleDefault} flex justify-center items-center`}>{customIcon}</div>}
    </button>
  );
};
