// packages
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';
// components
import RenderTabsForMobile from 'system/ProfileTabs/Mobile/RenderTabsForMobile';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
// routes
import { RoutePaths, RoutesPathNames } from 'app/routing';
// generated
import { ProfileFragments_info$data } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';

const MobileProfileTabs = ({ profileInfoData }: { profileInfoData: ProfileFragments_info$data }) => {
  const { pathname } = useLocation();

  const containerRef = useRef<HTMLDivElement>(null);

  const [isScrollableNavbar, setIsScrollableNavbar] = useState<boolean>(false);
  const [isScrollToEnd, setIsScrollToEnd] = useState<boolean>(true);
  const [isScrollToStart, setIsScrollToStart] = useState<boolean>(true);
  const controlButtonClasses = useMemo<string>(() => ' w-9 h-9 sm:hidden rounded-full border border-solid border-[#DADEE5] absolute bg-[#FCFCFC] top-0 z-10', []);

  const memoRenderTabsForMobile = useMemo<ReactElement>(
    () => <RenderTabsForMobile ref={containerRef} profileInfoData={profileInfoData} isScrollableNavbar={isScrollableNavbar} />,
    [isScrollableNavbar, profileInfoData],
  );

  const handelScrollToEnd = () => {
    if (containerRef.current) {
      containerRef.current.scroll(containerRef.current?.scrollWidth!, 0);
    }
  };

  const handelScrollToStart = () => {
    if (containerRef.current) {
      containerRef.current.scroll(0, containerRef.current?.scrollWidth!);
    }
  };

  useEffect(() => {
    if (isScrollableNavbar) {
      if (pathname.includes(RoutesPathNames.ProfileBaseSeaServices) || pathname.includes(RoutePaths.ProfileBase)) {
        setIsScrollToStart(true);
        setIsScrollToEnd(false);
      }
      if (pathname.includes(RoutesPathNames.ProfileBaseCertificates)) {
        setIsScrollToStart(false);
        setIsScrollToEnd(false);
      }
      if (pathname.includes(RoutesPathNames.ProfileBaseEducation) || pathname.includes(RoutesPathNames.ProfileBaseAppraisals)) {
        setIsScrollToStart(false);
        setIsScrollToEnd(true);
      }
    } else {
      setIsScrollToStart(true);
      setIsScrollToEnd(true);
    }
  }, [isScrollableNavbar, pathname]);

  const handleNavbarScrollRef = useRef((navbarIsScroll: boolean) =>
    debounce((event: Event) => {
      if (!(navbarIsScroll && isScrollToStart && isScrollToStart)) {
        return;
      }
      if ((event.target as HTMLDivElement)?.scrollLeft === 0) {
        setIsScrollToStart(true);
        setIsScrollToEnd(false);
        return;
      }
      if ((event.target as HTMLDivElement)?.scrollLeft > (containerRef.current as HTMLDivElement).scrollWidth - document.body.offsetWidth) {
        setIsScrollToStart(false);
        setIsScrollToEnd(true);
        return;
      }
    }, 100),
  );

  useEffect(() => {
    containerRef.current?.addEventListener('scroll', handleNavbarScrollRef.current(isScrollableNavbar));
  }, [isScrollableNavbar]);

  const handleWindowResize = useRef<() => void>(
    debounce(() => {
      setIsScrollableNavbar((containerRef.current as HTMLDivElement)?.scrollWidth + 32 > document.body.offsetWidth);
    }, 500),
  );

  useEffect(() => {
    const memoCallback = handleWindowResize.current;
    memoCallback();
    window.addEventListener('resize', memoCallback);
    return () => {
      window.removeEventListener('resize', memoCallback);
    };
  }, []);

  return (
    <div className="relative block sm:hidden">
      <button className={`${isScrollToStart ? 'hidden' : 'block'} left-0 ${controlButtonClasses}`} onClick={handelScrollToStart}>
        <ChevronLeftIcon className="w-3.5 h-3.5 m-auto" />
      </button>
      {memoRenderTabsForMobile}
      <button className={`${isScrollToEnd ? 'hidden' : 'block'} right-0 ${controlButtonClasses}`} onClick={handelScrollToEnd}>
        <ChevronRightIcon className="w-3.5 h-3.5 m-auto" />
      </button>
    </div>
  );
};

export default MobileProfileTabs;
