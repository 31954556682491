import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
//schemas
import { SERVICES_LIST_FRAGMENT } from 'schemas/services/ServiceFragments';
import { ServiceFragmentsListPlural_profile$data, ServiceFragmentsListPlural_profile$key } from 'schemas/services/__generated__/ServiceFragmentsListPlural_profile.graphql';
//hooks
import useSortedByEndDate from 'hooks/useSortedByEndDate';
import { useConvertToCorrectServiceDate } from 'hooks/useConvertToCorrectServiceDate';
//components
import BoatIcon from 'icons/BoatSmall';
import CrewsSeaServiceEVItem from './components/CrewsSeaServiceEVItem';
import OverviewCrewInfoEvTitle from '../components/OverviewCrewInfoEVTitle';
import OverviewCrewInfoEmptyItem from '../components/OverviewCrewInfoEmptyItem';
import SeaServiceJobExperience from 'system/SeaServiceJobExpirience/SeaServiceJobExperience';
//schemas
import { ProfileFragments$data } from 'schemas/profile/__generated__/ProfileFragments.graphql';

const CrewsSeaServicesEmployerView = ({ seaServFr }: { seaServFr: ProfileFragments$data['seaServices'] }) => {
  const intl = useIntl();
  const crewsSeaServices = useFragment<ServiceFragmentsListPlural_profile$key>(SERVICES_LIST_FRAGMENT, seaServFr || null);
  const { convertDays, convertYears, convertMonths } = useConvertToCorrectServiceDate(crewsSeaServices);

  const getSortedField = useCallback((item: ServiceFragmentsListPlural_profile$data[number]) => item.end, []);
  const sortedSeaServicesByEndDate = useSortedByEndDate([...(crewsSeaServices || [])], getSortedField);

  return (
    <section className="flex w-full flex-col space-y-6">
      <OverviewCrewInfoEvTitle
        title={intl.formatMessage({ id: 'profile_seaServiceTitle' })}
        description={intl.formatMessage({ id: 'profile_seaServiceAmount' }, { amount: crewsSeaServices?.length })}
      >
        <SeaServiceJobExperience days={convertDays} months={convertMonths} years={convertYears} classes="ml-6" />
      </OverviewCrewInfoEvTitle>

      {sortedSeaServicesByEndDate ? (
        sortedSeaServicesByEndDate?.map(seaServiceItemFr => <CrewsSeaServiceEVItem key={seaServiceItemFr.id} service={seaServiceItemFr} />)
      ) : (
        <OverviewCrewInfoEmptyItem title={intl.formatMessage({ id: 'profile_no_sea_services' })}>
          <BoatIcon />
        </OverviewCrewInfoEmptyItem>
      )}
    </section>
  );
};

export default CrewsSeaServicesEmployerView;
