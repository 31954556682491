// packages
import { IActivity } from 'models/IActivity';
import React from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
import { formatDistanceStrict } from 'date-fns';
// components
import ComponentWithPopover from './ComponentWithPopover';
import ShipPhoto from 'icons/ShipPhoto';
import CountryFlag from 'system/CountryFlag';
// generated
import { ServiceFragmentsOneDuty$key } from 'schemas/services/__generated__/ServiceFragmentsOneDuty.graphql';
import { ServiceFragmentsActivities$key } from 'schemas/services/__generated__/ServiceFragmentsActivities.graphql';
// schemas
import { GET_ONE_DUTY, SERVICE_ACTIVITIES, VESSEL_FRAGMENT } from 'schemas/services/ServiceFragments';
// models
import { HighlightedSeaServiceSeeMoreItemProps } from 'models/modelsOfComponents';
// utils
import { formatDate } from 'utils';
import { ServiceFragmentsVessel$key } from 'schemas/services/__generated__/ServiceFragmentsVessel.graphql';

export const SeeMoreItem: React.FC<HighlightedSeaServiceSeeMoreItemProps> = ({ currentService }) => {
  const intl = useIntl();
  const vesselData = useFragment<ServiceFragmentsVessel$key>(VESSEL_FRAGMENT, currentService?.vessel || null);
  const dataOfDuties = useFragment<ServiceFragmentsOneDuty$key>(GET_ONE_DUTY, currentService?.duty || null);
  const dataOfActivities = useFragment<ServiceFragmentsActivities$key>(SERVICE_ACTIVITIES, currentService?.activities || null);

  return (
    <section className="flex flex-col w-full">
      <section className="flex flex-row items-center">
        {/* flag */}
        <CountryFlag countryId={vesselData?.cnISO} countryName={vesselData?.countryName} />
        {!vesselData?.cnISO && <img className="inline-block w-4 h-4 mr-2 rounded-sm filter grayscale" alt="Grey country" src="https://flagcdn.com/w40/at.png" />}

        <span className="text-specialGray-1 text-lg truncate ml-3">{vesselData?.name}</span>
        <span className="ml-3 text-specialGray-075 font-medium">{vesselData?.kind?.name}</span>
      </section>
      {/* Ship photo */}
      {vesselData?.image ? (
        <div className="h-32 md:h-auto 2xl:max-h-96 overflow-hidden rounded-lg flex items-center mt-3 mb-3">
          <img className="w-full h-auto" src={vesselData?.image} alt={vesselData.name} />
        </div>
      ) : (
        <div className="bg-avatar-bg w-full mt-3 mb-3 py-14 md:py-10 px-8 flex justify-center rounded-lg">
          <span className="w-[37px] h-[33px]">
            <ShipPhoto />
          </span>
        </div>
      )}
      <section className="text-sm flex flex-wrap flex-col">
        {/* duty */}
        <div className="flex text-specialGray-075 border-b py-3 font-medium">
          <span className="mr-auto">{intl.formatMessage({ id: 'label_form_duty' }).toLocaleUpperCase()}</span>
          <p className="text-specialGray-1 ml-2"> {dataOfDuties?.name}</p>
        </div>
        {/* gross tonage */}
        {!!vesselData?.grossTonnage && (
          <div className="flex text-specialGray-075 border-b py-3 font-medium">
            <span className="mr-auto">{intl.formatMessage({ id: 'sea_service_gross_tonnage' }).toLocaleUpperCase()}</span>
            <p className="text-specialGray-1 ml-2">{vesselData.grossTonnage}</p>
          </div>
        )}
        {/* Length */}
        {!!vesselData?.length && (
          <div className="flex text-specialGray-075 border-b py-3 font-medium">
            <span className="mr-auto">{intl.formatMessage({ id: 'sea_service_length' }).toLocaleUpperCase()}</span>
            <p className="text-specialGray-1 ml-2">{vesselData.length} m</p>
          </div>
        )}
        {/* duration */}
        <div className="flex text-specialGray-075 border-b py-3 font-medium">
          <span className="mr-auto">{intl.formatMessage({ id: 'sea_service_duration' }).toLocaleUpperCase()}</span>
          {currentService?.start && formatDate(`${currentService.start}`)} - {currentService?.end && formatDate(`${currentService.end}`)}
          {!currentService?.end && <span className="ml-1">{intl.formatMessage({ id: 'service_present' })}</span>}
          <p className="text-specialGray-1 ml-2">{currentService?.end && formatDistanceStrict(new Date(`${currentService.end}`), new Date(`${currentService.start}`))}</p>
        </div>
        {/* rotations */}
        <div className="flex text-specialGray-075 border-b py-3 font-medium">
          <span className="mr-auto">{intl.formatMessage({ id: 'sea_service_modal_more_rotations' }).toLocaleUpperCase()}</span>
          <p className="text-specialGray-1 ml-2">{currentService?.rotations}</p>
        </div>
        {/* imo */}
        <div className="flex text-specialGray-075 border-b py-3 font-medium">
          <span className="mr-auto">{intl.formatMessage({ id: 'sea_service_modal_more_imo' }).toLocaleUpperCase()}</span>
          <p className="text-specialGray-1 ml-2">{vesselData?.imo}</p>
        </div>
        {/* operator name */}
        {vesselData?.operatorName && (
          <div className="flex text-specialGray-075 border-b py-3 font-medium">
            <span className="mr-auto">{intl.formatMessage({ id: 'sea_service_modal_more_operator' }).toLocaleUpperCase()}</span>
            <p className="text-specialGray-1 ml-2">{vesselData.operatorName}</p>
          </div>
        )}
        {/* Additional activities */}
        <section className="text-sm flex flex-wrap flex-col">
          {!isEmpty(dataOfActivities) && (
            <div className="mt-3">
              <span className="mr-auto text-specialGray-075 font-medium">{intl.formatMessage({ id: 'sea_service_activities' }).toUpperCase()}</span>
              <section className="flex flex-wrap">
                {dataOfActivities?.map(
                  (tag: IActivity) =>
                    tag && (
                      <ComponentWithPopover key={`${currentService?.id}-${tag.id}`} id={tag.id} name={tag.label} additionalClasses="my-2 py-2 mb-2 mr-2" popoverPosition="-top-8" />
                    ),
                )}
              </section>
            </div>
          )}
        </section>
      </section>
    </section>
  );
};
