/**
 * @generated SignedSource<<d01eaf5887ef019605306f022d6c9403>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ProfileQueriesGetProfileInfoMembershipQuery$variables = {
  ids: ReadonlyArray<string>;
};
export type ProfileQueriesGetProfileInfoMembershipQuery$data = {
  readonly nodes: ReadonlyArray<{
    readonly 'id': string;
    readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsSearchDropDown'>;
  } | null>;
};
export type ProfileQueriesGetProfileInfoMembershipQuery = {
  response: ProfileQueriesGetProfileInfoMembershipQuery$data;
  variables: ProfileQueriesGetProfileInfoMembershipQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'ids',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'ids',
        variableName: 'ids',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'avatarUrl',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileQueriesGetProfileInfoMembershipQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'nodes',
          plural: true,
          selections: [
            v2 /*: any*/,
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'ProfileFragmentsSearchDropDown',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileQueriesGetProfileInfoMembershipQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'nodes',
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: '__typename',
              storageKey: null,
            },
            v2 /*: any*/,
            {
              kind: 'InlineFragment',
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'ProfileInfo',
                  kind: 'LinkedField',
                  name: 'info',
                  plural: false,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Duty',
                      kind: 'LinkedField',
                      name: 'primaryDuty',
                      plural: false,
                      selections: [v2 /*: any*/, v3 /*: any*/],
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'firstName',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'lastName',
                      storageKey: null,
                    },
                    v4 /*: any*/,
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'OrganizationMember',
                  kind: 'LinkedField',
                  name: 'membership',
                  plural: true,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'createdAt',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'role',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Organization',
                      kind: 'LinkedField',
                      name: 'organization',
                      plural: false,
                      selections: [
                        v2 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'city',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Country',
                          kind: 'LinkedField',
                          name: 'country',
                          plural: false,
                          selections: [
                            v2 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'isoCode',
                              storageKey: null,
                            },
                            v3 /*: any*/,
                          ],
                          storageKey: null,
                        },
                        v3 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'description',
                          storageKey: null,
                        },
                        v4 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'size',
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              type: 'Profile',
              abstractKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '6ca3ad73845420061e40133c37cd98ce',
      id: null,
      metadata: {},
      name: 'ProfileQueriesGetProfileInfoMembershipQuery',
      operationKind: 'query',
      text: 'query ProfileQueriesGetProfileInfoMembershipQuery(\n  $ids: [ID!]!\n) {\n  nodes(ids: $ids) {\n    __typename\n    id\n    ...ProfileFragmentsSearchDropDown\n  }\n}\n\nfragment ProfileFragmentsOrganizationPlural_profile on OrganizationMember {\n  id\n  createdAt\n  role\n  organization {\n    id\n    city\n    country {\n      id\n      isoCode\n      name\n    }\n    name\n    description\n    avatarUrl\n    size\n  }\n}\n\nfragment ProfileFragmentsSearchDropDown on Profile {\n  id\n  info {\n    ...ProfileFragments_SearchDropDown_info\n    id\n  }\n  membership {\n    ...ProfileFragmentsOrganizationPlural_profile\n    id\n  }\n}\n\nfragment ProfileFragments_SearchDropDown_info on ProfileInfo {\n  id\n  primaryDuty {\n    id\n    name\n  }\n  firstName\n  lastName\n  avatarUrl\n}\n',
    },
  };
})();

(node as any).hash = '05f626f274c7ca3e845b37fb3c066470';

export default node;
