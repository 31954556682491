import graphql from 'babel-plugin-relay/macro';

export const GET_ALL_CERTIFICATES_LIST = graphql`
  query CertificateQueriesListQuery($skip: Boolean!) {
    certificates @skip(if: $skip) {
      edges {
        id
        name
        alts
        priority
        neverExpires
        kind {
          id
          name
          perma
        }
      }
    }
  }
`;
