// packages
import { useCallback, useEffect, useState } from 'react';
// components
import { Button } from 'system/Button';
import SpinnerIcon from 'icons/SpinnerIcon';

const LoadMoreJobOpeningsButton = ({ totalNumberOfJobOpenings, handleIncreaseNumberOfItems }: { totalNumberOfJobOpenings: number; handleIncreaseNumberOfItems: () => void }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [numberOfItems, setNumberOfItems] = useState<number>(totalNumberOfJobOpenings);

  const handleLoadMore = useCallback(() => {
    handleIncreaseNumberOfItems();
    setIsLoading(true);
  }, [handleIncreaseNumberOfItems]);

  useEffect(() => {
    setNumberOfItems(() => {
      if (numberOfItems !== totalNumberOfJobOpenings) {
        setIsLoading(false);
      }
      return totalNumberOfJobOpenings;
    });
  }, [totalNumberOfJobOpenings, setNumberOfItems, numberOfItems]);

  return (
    <>
      {!isLoading ? (
        <Button
          type="button"
          buttonType="white-primary"
          label={{ id: 'job_opening_load_more_gob_openings' }}
          additionalClasses="mx-auto px-6 py-4 rounded-xl"
          onClick={handleLoadMore}
        />
      ) : (
        <SpinnerIcon />
      )}
    </>
  );
};

export default LoadMoreJobOpeningsButton;
