import { useCallback } from 'react';
// models
import { ProfileScoreDetailsType } from 'models/commonTypes';

const useScoreFilteredNotFilledItems = () => {
  const filteredNotFilledItems = useCallback((scoreDetails: ProfileScoreDetailsType[]): ProfileScoreDetailsType[] => {
    return scoreDetails.filter(
      ({ name, filled }) =>
        !(
          ((name === 'all_certs_has_expire_date' || name === 'all_certs_has_documents') && !!scoreDetails.find(el => el.name === 'one_certificate' && !el.filled)) ||
          (name === 'one_appraisal' && !!scoreDetails.find(el => el.name === 'one_sea_service' && !el.filled)) ||
          (name === 'basic_info' && filled) ||
          filled
        ),
    );
  }, []);

  return {
    filteredNotFilledItems,
  };
};

export default useScoreFilteredNotFilledItems;
