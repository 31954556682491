import React from 'react';
import Skeleton from 'react-loading-skeleton';

const CheckboxSkeleton: React.FC<{ widthText: number }> = ({ widthText }) => {
  return (
    <div className="flex w-full">
      <Skeleton width={16} className="mr-2" />
      <Skeleton width={widthText} />
    </div>
  );
};

export default CheckboxSkeleton;
