// packages
import { Suspense } from 'react';
// routes
import { RoutePaths, RoutesPathNames } from 'app/routing';
import PrivateRoutes from 'app/PrivateRoutes/PrivateRoutes';
// components
import ProfileSlide from 'system/ProfileSlide/ProfileSlide';
import JobDescriptionTabs from 'system/Layouts/JobDescriptionTabs/JobDescriptionTabs';
import InviteCrewPage from 'app/ProfileEmployer/components/InviteCrewPage/InviteCrewPage';
import ReviewApplicationsPage from 'app/ProfileEmployer/components/ReviewApplicationsPage/ReviewApplicationsPage';
import JobDescriptionEditPreview from 'app/ProfileEmployer/components/JobDescriptionPage/JobDescriptionEditPreview';
import SearchProfileSlideSkeleton from 'system/skeletons/profile/SeachProfileSlideSkeleton/SearchProfileSlideSkeleton';
import ListOfInvitedCrew from 'app/ProfileEmployer/components/JobDescriptionPage/components/ListOfCrew/ListOfInvitedCrew';
import ListOfReviewAppliedCrewSkeleton from 'system/skeletons/lists/ListOfReviewAppliedCrew/ListOfReviewAppliedCrewSkeleton';
import JobDescriptionTabsPreviewSkeleton from 'system/skeletons/hireProfile/JobDescriptionTabsSkeleton/JobDescriptionTabsPreviewSkeleton';
import JobDescriptionEditPreviewSkeleton from 'system/skeletons/hireProfile/JobDescriptionTabsSkeleton/JobDescriptionEditPreviewSkeleton';

export const JobEditDetailsRoute = {
  path: `${RoutePaths.ProfileOrganizationBase}/:${RoutesPathNames.orgId}/${RoutePaths.JobEditId}/*`,
  element: (
    <Suspense fallback={<JobDescriptionTabsPreviewSkeleton />}>
      <JobDescriptionTabs />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<JobDescriptionEditPreviewSkeleton />}>
          <JobDescriptionEditPreview />
        </Suspense>
      ),
    },
    {
      path: `${RoutePaths.JobInviteCrew}/*`,
      element: (
        <PrivateRoutes>
          <InviteCrewPage />
        </PrivateRoutes>
      ),
    },
    {
      path: `${RoutesPathNames.JobReviewApplications}/*`,
      element: (
        <Suspense fallback={<ListOfReviewAppliedCrewSkeleton />}>
          <ReviewApplicationsPage />
        </Suspense>
      ),
      children: [
        {
          path: `${RoutesPathNames.show}/:${RoutesPathNames.profileSideBarId}`,
          element: (
            <Suspense fallback={<SearchProfileSlideSkeleton />}>
              <ProfileSlide />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: RoutePaths.JobInvitedCrew,
      Component: ListOfInvitedCrew,
    },
  ],
};
