import { useIntl } from 'react-intl';
import { useCallback, useMemo } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import { useFragment, useLazyLoadQuery } from 'react-relay';
//routing
import { RoutePaths, RoutesPathNames } from 'app/routing';
//generated
import { ProfileFragmentsEmployerCrewPools$key } from 'schemas/profile/__generated__/ProfileFragmentsEmployerCrewPools.graphql';
import { ProfileQueriesGetProfileCrewPoolsQuery } from 'schemas/profile/__generated__/ProfileQueriesGetProfileCrewPoolsQuery.graphql';
//schemas
import { GET_CREW_POOLS } from 'schemas/profile/ProfileQueries';
import { EMPLOYER_CREW_POOLS_FRAGMENT } from 'schemas/profile/ProfileFragments';
//components
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import EmployerCrewPoolInfoItem from './components/EmployerCrewPoolInfoItem';
//hooks
import { useGetHireProfile } from 'hooks/hireProfileHooks';

const EmployerCrewPoolInfo = () => {
  const intl = useIntl();
  const { organizationId } = useGetHireProfile();
  const { poolId } = useParams();

  const data = useLazyLoadQuery<ProfileQueriesGetProfileCrewPoolsQuery>(GET_CREW_POOLS, { orgId: organizationId! });

  const allPoolIds = useMemo(() => {
    const IDs: string[] = [];
    if (data.crewPools?.edges?.length) {
      // important syntax for relay store with double quotes
      data.crewPools?.edges?.forEach(pool => pool?.node?.id && IDs.push(`"${pool.node.id}"`));
    }
    return IDs;
  }, [data]);

  const findCurrentPoolNode = useCallback(() => {
    const edges = data.crewPools?.edges;
    if (!edges?.length) {
      return null;
    }
    const pool = edges.find(dataPoolItem => {
      return dataPoolItem?.node?.id === poolId;
    });
    return pool?.node || null;
  }, [data.crewPools?.edges, poolId]);

  const pool = useFragment<ProfileFragmentsEmployerCrewPools$key>(EMPLOYER_CREW_POOLS_FRAGMENT, findCurrentPoolNode());

  const pages = [
    {
      name: intl.formatMessage({ id: 'hire_crew_pools' }),
      href: `/${RoutesPathNames.ProfileOrganizationBase}/${organizationId}/${RoutePaths.ProfileCrewPools}`,
      current: false,
    },
    { name: pool?.name, href: `.`, current: true },
  ];

  return (
    <section className="flex flex-col w-full text-darkBlue">
      <nav className="flex mt-8" aria-label="Breadcrumb">
        <ol className="flex items-center">
          {pages.map((page, index) => (
            <li key={page.name} className="flex items-center">
              {index !== 0 && <ChevronRightIcon className="h-3.5 text-specialGray-05 mx-2" aria-hidden="true" />}
              <Link
                data-test-id={`${page.name}-tab`}
                to={page.href}
                className={`font-medium text-lg ${
                  page.current ? 'text-darkBlue font-medium' : 'font-normal text-specialGray-075 hover:text-specialGray-08 opacity-80 hover:opacity-100'
                }`}
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            </li>
          ))}
        </ol>
      </nav>
      <Outlet context={allPoolIds} />
      <EmployerCrewPoolInfoItem poolInfo={pool || null} />
    </section>
  );
};

export default EmployerCrewPoolInfo;
