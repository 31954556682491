// packages
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import { useLocation, useNavigate } from 'react-router-dom';
// components
import { Button } from 'system/Button';
import EmptyOverviewTab from './components/EmptyOverviewTab';
import ProfileScoreCommon from 'app/Profile/ProfileScore/ProfileScoreCommon';
import CertificatesWithoutDocumentsCommon from './components/CertificatesWithoutDocuments/CertificatesWithoutDocumentsCommon';
// generated
import { ProfileFragments$key } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { ProfileQueriesRootQuery } from 'schemas/profile/__generated__/ProfileQueriesRootQuery.graphql';
import { ProfileQueriesRootRefreshQuery } from 'schemas/profile/__generated__/ProfileQueriesRootRefreshQuery.graphql';
// schemas
import { PROFILE_QUERY } from 'schemas/profile/ProfileQueries';
import { PROFILE_FRAGMENT } from 'schemas/profile/ProfileFragments';
// routes
import { RoutesPathNames } from 'app/routing';
// hooks
import { useAuth } from 'authentication';

const Overview = () => {
  useEffect(() => {
    window.analytics?.track('wui-open-overview-tab');
  }, []);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { identity } = useAuth();

  const profileFragment = useLazyLoadQuery<ProfileQueriesRootQuery>(PROFILE_QUERY, { id: `${identity?.profileId}`, skip: !identity?.profileId });

  const [profile] = useRefetchableFragment<ProfileQueriesRootRefreshQuery, ProfileFragments$key>(PROFILE_FRAGMENT, profileFragment.node || null);

  // when the user doesn't have certificates it should always show ONLY "your profile is empty"
  const hasCertificates = !isEmpty(profile?.certificateInfos);
  const showOverviewBody = profile?.info && hasCertificates;

  return (
    <div>
      {/*Profile score */}
      <ProfileScoreCommon />

      {showOverviewBody && <CertificatesWithoutDocumentsCommon profileData={profile} />}

      {/* when the user has no certificates it should always ONLY show "your profile is empty" */}
      {(!profile?.info || !hasCertificates) && (
        <EmptyOverviewTab>
          {/* when the user hasn't basic information, it should ONLY show "your profile is empty WITHOUT "add your first certificate" */}
          {profile?.info && (
            <Button
              type="button"
              label={{ id: 'profile_add_first_certificate' }}
              onClick={() => navigate(`${pathname}/${RoutesPathNames.ProfileBaseCertificates}`, { state: { flickeringModalOpenButton: true } })}
              buttonType="white-secondary"
            />
          )}
        </EmptyOverviewTab>
      )}
    </div>
  );
};

export default Overview;
