import { useAuth } from 'authentication';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { VacancyFragments$data } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';

export function useGetUserAlreadyApplied(initialValue: boolean, vacancy: VacancyFragments$data, profileId?: string) {
  const { identity } = useAuth();
  const [userAlreadyApplied, setUserAlreadyApplied] = useState<boolean>(initialValue);

  useEffect(() => {
    if (isEmpty(vacancy?.applications)) {
      setUserAlreadyApplied(false);
    } else {
      const findApp = vacancy?.applications?.find(app => app.owner.id === profileId || app.owner.id === identity?.profileId?.toString());
      setUserAlreadyApplied(!!findApp);
    }
  }, [vacancy?.applications, identity?.profileId, profileId]);

  return { userAlreadyApplied };
}
