import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from 'react';

type NavbarSearchContextInterface = {
  queryParams: string;
  showDropDown: boolean;
  setShowDropDown: Dispatch<SetStateAction<boolean>>;
  setQueryParams: Dispatch<SetStateAction<string>>;
};

const navbarSearchContext: NavbarSearchContextInterface = {
  queryParams: '',
  showDropDown: false,
  setShowDropDown: () => {},
  setQueryParams: () => {},
};

const NavbarSearchCtx = createContext<NavbarSearchContextInterface>(navbarSearchContext);

export function useNavbarSearchContext() {
  return useContext(NavbarSearchCtx);
}

const NavbarSearchContext = ({
  defaultOpenDropDown = false,
  defaultInputValue = '',
  children,
}: PropsWithChildren<{ defaultOpenDropDown?: boolean; defaultInputValue?: string }>) => {
  const [queryParams, setQueryParams] = useState<string>(defaultInputValue);
  const [showDropDown, setShowDropDown] = useState<boolean>(defaultOpenDropDown);

  return <NavbarSearchCtx.Provider value={{ queryParams, showDropDown, setShowDropDown, setQueryParams }}>{children}</NavbarSearchCtx.Provider>;
};

export default NavbarSearchContext;
