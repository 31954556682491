import Skeleton from 'react-loading-skeleton';
import RenderingTab from './RenderingTab';

const MainTabSkeleton = () => {
  return (
    <div className="flex flex-col">
      <div className="hidden sm:flex items-center justify-between space-x-5">
        <div className="w-full border-b border-gray-200 mt-5 pb-5 pt-5 flex">
          <Skeleton width={70} className="mx-4" />
          <Skeleton width={100} className="mx-4" />
          <Skeleton width={85} className="mx-4" />
          <Skeleton width={80} className="mx-4" />
          <Skeleton width={85} className="mx-4" />
        </div>
      </div>
      <RenderingTab />
    </div>
  );
};

export default MainTabSkeleton;
