import LineSvg from 'icons/LineSvg';
import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

const ListOfSeaServiceItemSkeleton: FC = () => {
  return (
    <div className="border-solid border border-specialGray-01 rounded-xl p-4 space-y-2">
      <div className="flex justify-between">
        <div className="flex space-x-5">
          <Skeleton width={24} height={16} />
          <Skeleton width={150} height={16} />
        </div>
        <div className="flex flex-col">
          <Skeleton width={150} height={14} />
          <div className="flex justify-end w-full">
            <Skeleton width={55} height={14} />
            &nbsp;&#8208;&nbsp;
            <Skeleton width={55} height={14} />
          </div>
        </div>
      </div>
      <div className="flex space-x-5">
        <Skeleton width={96} height={65} borderRadius={12} />
        <div className="flex flex-col space-y-2 w-full">
          <div className="flex justify-between mt-1.5">
            <Skeleton width={55} height={12} />
            <Skeleton width={65} height={14} />
          </div>
          <LineSvg />
          <div className="flex justify-between">
            <Skeleton width={55} height={12} />
            <Skeleton width={100} height={14} />
          </div>
        </div>
      </div>
      <div className="flex space-x-1">
        <Skeleton width={120} height={28} borderRadius={8} />
        <Skeleton width={120} height={28} borderRadius={8} />
        <Skeleton width={120} height={28} borderRadius={8} />
      </div>
    </div>
  );
};

export default ListOfSeaServiceItemSkeleton;
