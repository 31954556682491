import { FC } from 'react';

const LockGrayIcon: FC = () => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" id="lock-gray-icon">
      <mask id="mask0_7331_67069" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="33">
        <rect x="0.303711" y="0.652344" width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7331_67069)">
        <path
          d="M16.3039 1.98438C18.1483 1.98438 19.7208 2.63415 21.0212 3.93371C22.3208 5.23415 22.9705 6.8066 22.9705 8.65104V11.3177H24.3039C25.0372 11.3177 25.6652 11.5786 26.1879 12.1004C26.7097 12.623 26.9705 13.251 26.9705 13.9844V27.3177C26.9705 28.051 26.7097 28.679 26.1879 29.2017C25.6652 29.7235 25.0372 29.9844 24.3039 29.9844H8.30387C7.57054 29.9844 6.94298 29.7235 6.42121 29.2017C5.89854 28.679 5.63721 28.051 5.63721 27.3177V13.9844C5.63721 13.251 5.89854 12.623 6.42121 12.1004C6.94298 11.5786 7.57054 11.3177 8.30387 11.3177H9.63721V8.65104C9.63721 6.8066 10.2874 5.23415 11.5879 3.93371C12.8874 2.63415 14.4594 1.98438 16.3039 1.98438ZM16.3039 4.65104C15.1928 4.65104 14.2483 5.03993 13.4705 5.81771C12.6928 6.59549 12.3039 7.53993 12.3039 8.65104V11.3177H20.3039V8.65104C20.3039 7.53993 19.915 6.59549 19.1372 5.81771C18.3594 5.03993 17.415 4.65104 16.3039 4.65104ZM8.30387 27.3177H24.3039V13.9844H8.30387V27.3177ZM16.3039 17.9844C15.5705 17.9844 14.943 18.2453 14.4212 18.767C13.8985 19.2897 13.6372 19.9177 13.6372 20.651C13.6372 21.3844 13.8985 22.0124 14.4212 22.535C14.943 23.0568 15.5705 23.3177 16.3039 23.3177C17.0372 23.3177 17.6652 23.0568 18.1879 22.535C18.7097 22.0124 18.9705 21.3844 18.9705 20.651C18.9705 19.9177 18.7097 19.2897 18.1879 18.767C17.6652 18.2453 17.0372 17.9844 16.3039 17.9844Z"
          fill="#082051"
          fillOpacity="0.5"
        />
      </g>
    </svg>
  );
};

export default LockGrayIcon;
