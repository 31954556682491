// packages
import { PropsWithChildren, Suspense, useCallback, useEffect } from 'react';
import { toNumber } from 'lodash';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
// routing
import { RoutePaths, RoutesPathNames } from 'app/routing';
// hooks
import { useAuth } from 'authentication';
import { useGetHireProfile } from 'hooks/hireProfileHooks';
// components
import { Navbar } from 'app/Navbar/Navbar';
import { Footer } from 'app/Footer/Footer';
import NavBarSkeleton from '../skeletons/NavBar/NavBarSkeleton';
import ConfettiProfileScore from '../CongratulationModals/ConfetyProfileScore/ConfettiProfileScore';
// error boundary
import ErrorBoundaryMainContent from 'system/ErrorBoundaries/ErrorBoundaryMainContent';
// recoil
import { personalAccountSelector } from 'recoil/Profile/personalAccount/selectors/personalAccountSelector';
import { emptyOrganisationAtom } from 'recoil/Organisation/getCurrentOrganization/atoms/emptyOrganisationAtom';
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';
// models
import { PLATFORM_GROUP } from 'models/enums';

const MainLayout = ({ children, storybookError }: PropsWithChildren<{ storybookError?: Error }>) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { identity, loggedIn } = useAuth();
  const { profileOrganizations } = useGetHireProfile();

  const setIsEmptyOrganisation = useSetRecoilState(emptyOrganisationAtom);
  const [getIsPersonalAccount, setIsPersonalAccount] = useRecoilState(personalAccountSelector);
  const [getCurrentOrganisation, setCurrentOrganisation] = useRecoilState(currentOrganisationSelector);

  useEffect(() => {
    setIsEmptyOrganisation({ isEmpty: !profileOrganizations?.length && identity?.platformGroup === PLATFORM_GROUP.HIRE });
  }, [identity?.platformGroup, profileOrganizations?.length, setIsEmptyOrganisation]);

  const handleRedirect = useCallback(() => {
    if (identity?.platformGroup === PLATFORM_GROUP.GUEST) {
      return;
    }

    if (pathname !== RoutePaths.HomePath) {
      return;
    }

    if (!profileOrganizations) {
      return;
    }

    if (getIsPersonalAccount.personalAccountIsSelected) {
      navigate(RoutePaths.ProfileBase, { replace: true });
      return;
    }

    if (toNumber(profileOrganizations?.length) === 1 && !getCurrentOrganisation.isSelected && !getIsPersonalAccount.personalAccountIsSelected) {
      navigate(RoutePaths.ProfileOrganizationBase, { replace: true });
      setCurrentOrganisation({ isSelected: true, organisationId: profileOrganizations?.[0].organization.id as string, profileId: `${identity?.profileId}` });

      return;
    }
    if (toNumber(profileOrganizations?.length) > 0 && !getCurrentOrganisation.isSelected && !getIsPersonalAccount.personalAccountIsSelected) {
      navigate(`${RoutePaths.ProfileOrganizationBase}/${profileOrganizations?.[0].id}`, { replace: true });
      setCurrentOrganisation({ isSelected: true, organisationId: profileOrganizations?.[0]?.organization.id as string, profileId: `${identity?.profileId}` });
      return;
    }
    if (getCurrentOrganisation.isSelected) {
      navigate(`${RoutePaths.ProfileOrganizationBase}/${getCurrentOrganisation.organisationId}`, { replace: true });
      return;
    }

    navigate(RoutePaths.ProfileBase, { replace: true });

    if (!getIsPersonalAccount.personalAccountIsSelected && !getCurrentOrganisation.isSelected) {
      setIsPersonalAccount({ personalAccountIsSelected: true });
      setCurrentOrganisation({ isSelected: false, organisationId: '', profileId: '' });
    }
  }, [getCurrentOrganisation, getIsPersonalAccount.personalAccountIsSelected, identity, navigate, pathname, profileOrganizations, setCurrentOrganisation, setIsPersonalAccount]);

  useEffect(() => {
    handleRedirect();
  }, [handleRedirect]);

  if (
    loggedIn !== undefined &&
    !loggedIn &&
    !pathname.includes(RoutePaths.AuthBase) &&
    !pathname.includes(RoutePaths.ProfileSingleCrewPage) &&
    !pathname.includes(RoutePaths.ProfileSingleJobPage) &&
    !pathname.includes(RoutesPathNames.orgInfo)
  ) {
    return <Navigate to={RoutePaths.AuthLogin} replace />;
  }

  return (
    <>
      {!pathname.includes(RoutePaths.AuthBase) && (
        <>
          <Suspense fallback={<NavBarSkeleton />}>
            <Navbar />
          </Suspense>
        </>
      )}
      <Suspense>
        <ConfettiProfileScore />
      </Suspense>
      <main className="flex flex-col w-full justify-center items-center py-3.5 sm:py-10 px-4 sm:px-6 lg:px-8 xl:px-16 2xl:px-80">
        <ErrorBoundaryMainContent storybookError={storybookError}>{children || <Outlet />}</ErrorBoundaryMainContent>
      </main>
      <Footer />
    </>
  );
};

export default MainLayout;
