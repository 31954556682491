import MainTabSkeleton from 'system/skeletons/profileTabs/MainTabSkeleton';
import ProfileBasicInfoSkeleton from './components/ProfileBasicInfoSkeleton';

const ProfileSkeleton = () => {
  return (
    <main className="w-full max-w-7xl">
      <ProfileBasicInfoSkeleton />
      <MainTabSkeleton />
    </main>
  );
};

export default ProfileSkeleton;
