import React from 'react';
import { ShieldIconProps } from 'models/modelsOfComponents';

const ShieldIcon: React.FC<ShieldIconProps> = ({ kind, className = 'w-[18px] h-[21px]' }) => {
  let iconColor = '';

  switch (kind) {
    case 'Offshore Wind':
      iconColor = '#48FEBA';
      break;
    case 'Offshore Oil & Gas':
      iconColor = '#81ACFF';
      break;
    case 'STCW':
      iconColor = '#FFEA29';
      break;
    case 'Flag State Document':
      iconColor = '#082051';
      break;
    case 'Offshore':
      iconColor = '#FE8E6B';
      break;
    default:
      iconColor = '#2A6FF5';
  }

  return (
    <svg className={className} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.63951 20.9094C8.75579 20.9701 8.88581 21.001 9.01583 21C9.14585 20.9989 9.27481 20.967 9.39215 20.9052L13.1384 18.9026C14.2008 18.3363 15.0327 17.7031 15.6817 16.9659C17.0929 15.3596 17.8636 13.3096 17.8498 11.1958L17.8054 4.22309C17.8012 3.41997 17.2737 2.70334 16.4936 2.44285L9.52428 0.104543C9.10462 -0.0375472 8.64479 -0.0344583 8.23253 0.11175L1.28966 2.53345C0.513765 2.80425 -0.00420261 3.52602 2.56955e-05 4.33017L0.0444229 11.2977C0.0581649 13.4146 0.8552 15.4554 2.28965 17.0451C2.94504 17.7721 3.78436 18.396 4.85835 18.9531L8.63951 20.9094ZM7.648 12.7135C7.80444 12.8638 8.0074 12.9379 8.21036 12.9359C8.41332 12.9348 8.61522 12.8586 8.76956 12.7062L12.8636 8.67008C13.1712 8.36633 13.168 7.87828 12.8573 7.57866C12.5454 7.27904 12.0433 7.2811 11.7357 7.58484L8.19873 11.0712L6.75054 9.67912C6.4387 9.37949 5.93765 9.38258 5.62898 9.68633C5.32137 9.99007 5.32454 10.4781 5.63638 10.7777L7.648 12.7135Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default ShieldIcon;
