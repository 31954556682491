import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
// models
import { LinkWithTextProps } from 'models/modelsOfComponents';

const LinkWithText: React.FC<LinkWithTextProps> = ({ label, href }) => {
  const intl = useIntl();

  return (
    <a href={href} className="text-blue text-sm font-normal cursor-pointer underline" target="_blank" rel="noreferrer">
      {intl.formatMessage(label as MessageDescriptor)}
    </a>
  );
};

export default LinkWithText;
