/**
 * @generated SignedSource<<8c3f89ef9dee22ca50e0c2796e90814f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ApplicationStatus = 'DECLINED' | 'PENDING' | '%future added value';
export type OrganizationSize = 'SIZE_11_50' | 'SIZE_1_10' | 'SIZE_201_500' | 'SIZE_501_1000' | 'SIZE_51_200' | 'SIZE_MORE_1000' | '%future added value';
export type SalaryPeriod = 'DAILY' | 'MONTHLY' | 'WEEKLY' | '%future added value';
export type StartingCondition = 'CONTINUOUSLY' | 'DATE' | 'IMMEDIATELY' | '%future added value';
export type VacancyDuration = 'ONETERM' | 'PERMANENT' | 'TEMPORARY' | '%future added value';
export type VacancyIndustry = 'NAUTICAL_WORLDWIDE' | 'OFFSHORE_OIL_GAS' | 'OFFSHORE_WIND' | '%future added value';
export type VacancyStatus = 'CLOSED' | 'OPEN' | '%future added value';
import { FragmentRefs } from 'relay-runtime';
export type VacancyFragments$data = {
  readonly 'applications': ReadonlyArray<{
    readonly id: string;
    readonly owner: {
      readonly id: string;
      readonly info: {
        readonly avatarUrl: string | null;
        readonly firstName: string;
        readonly lastName: string;
      } | null;
    };
    readonly status: ApplicationStatus;
  }> | null;
  readonly 'certificates': ReadonlyArray<{
    readonly alts: ReadonlyArray<string>;
    readonly id: string;
    readonly kind: {
      readonly id: string;
      readonly name: string;
      readonly perma: string;
    } | null;
    readonly name: string;
    readonly neverExpires: boolean;
    readonly priority: number;
  }> | null;
  readonly 'contacts': ReadonlyArray<{
    readonly id: string;
    readonly owner: {
      readonly id: string;
    };
  }> | null;
  readonly 'createdAt': String;
  readonly 'currency': {
    readonly id: string;
    readonly name: string;
    readonly perma: string;
  } | null;
  readonly 'description': string | null;
  readonly 'duration': VacancyDuration | null;
  readonly 'duties': ReadonlyArray<{
    readonly alts: ReadonlyArray<string> | null;
    readonly id: string;
    readonly name: string;
    readonly perma: string;
  }> | null;
  readonly 'hiringArea': {
    readonly id: string;
    readonly name: string;
    readonly perma: string;
  } | null;
  readonly 'id': string;
  readonly 'industry': VacancyIndustry | null;
  readonly 'invites': ReadonlyArray<{
    readonly id: string;
    readonly owner: {
      readonly id: string;
    };
  }> | null;
  readonly 'organization': {
    readonly avatarUrl: string | null;
    readonly description: string | null;
    readonly headquarters: string | null;
    readonly id: string;
    readonly name: string;
    readonly size: OrganizationSize | null;
    readonly websiteUrl: string | null;
  };
  readonly 'organizationMember': {
    readonly id: string;
    readonly organization: {
      readonly avatarUrl: string | null;
      readonly description: string | null;
      readonly id: string;
      readonly name: string;
      readonly size: OrganizationSize | null;
      readonly websiteUrl: string | null;
    };
    readonly profile: {
      readonly id: string;
      readonly info: {
        readonly firstName: string;
        readonly lastName: string;
      } | null;
    };
  };
  readonly 'responsibilities': ReadonlyArray<string> | null;
  readonly 'salaryFrom': number | null;
  readonly 'salaryPeriod': SalaryPeriod | null;
  readonly 'salaryTo': number | null;
  readonly 'startingCondition': StartingCondition | null;
  readonly 'startingDate': String | null;
  readonly 'status': VacancyStatus;
  readonly 'title': string;
  readonly 'tornWeeksOff': number | null;
  readonly 'tornWeeksOn': number | null;
  readonly 'vesselKind': {
    readonly id: string;
    readonly name: string;
    readonly perma: string;
  } | null;
  readonly ' $fragmentType': 'VacancyFragments';
};
export type VacancyFragments$key = {
  readonly ' $data'?: VacancyFragments$data;
  readonly ' $fragmentSpreads': FragmentRefs<'VacancyFragments'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'status',
      storageKey: null,
    },
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'description',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'avatarUrl',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'size',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'websiteUrl',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'alts',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'perma',
      storageKey: null,
    },
    v9 = [v0 /*: any*/, v6 /*: any*/, v8 /*: any*/],
    v10 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'firstName',
      storageKey: null,
    },
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'lastName',
      storageKey: null,
    },
    v12 = [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'Profile',
        kind: 'LinkedField',
        name: 'owner',
        plural: false,
        selections: [v0 /*: any*/],
        storageKey: null,
      },
    ];
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: {
      refetch: {
        connection: null,
        fragmentPathInResult: ['node'],
        operation: require('./VacancyQueriesGetVacancyRefreshQuery.graphql'),
        identifierField: 'id',
      },
    },
    name: 'VacancyFragments',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'createdAt',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'title',
        storageKey: null,
      },
      v1 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'industry',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'salaryPeriod',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Organization',
        kind: 'LinkedField',
        name: 'organization',
        plural: false,
        selections: [
          v0 /*: any*/,
          v2 /*: any*/,
          v3 /*: any*/,
          v4 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'headquarters',
            storageKey: null,
          },
          v5 /*: any*/,
          v6 /*: any*/,
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'salaryFrom',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'salaryTo',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'duration',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'responsibilities',
        storageKey: null,
      },
      v2 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'Certificate',
        kind: 'LinkedField',
        name: 'certificates',
        plural: true,
        selections: [
          v0 /*: any*/,
          v6 /*: any*/,
          v7 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'priority',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'neverExpires',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'CertificateKind',
            kind: 'LinkedField',
            name: 'kind',
            plural: false,
            selections: v9 /*: any*/,
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'VesselKind',
        kind: 'LinkedField',
        name: 'vesselKind',
        plural: false,
        selections: v9 /*: any*/,
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Duty',
        kind: 'LinkedField',
        name: 'duties',
        plural: true,
        selections: [v0 /*: any*/, v6 /*: any*/, v7 /*: any*/, v8 /*: any*/],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'HiringArea',
        kind: 'LinkedField',
        name: 'hiringArea',
        plural: false,
        selections: [v0 /*: any*/, v8 /*: any*/, v6 /*: any*/],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'tornWeeksOff',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'tornWeeksOn',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Application',
        kind: 'LinkedField',
        name: 'applications',
        plural: true,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'Profile',
            kind: 'LinkedField',
            name: 'owner',
            plural: false,
            selections: [
              v0 /*: any*/,
              {
                alias: null,
                args: null,
                concreteType: 'ProfileInfo',
                kind: 'LinkedField',
                name: 'info',
                plural: false,
                selections: [v10 /*: any*/, v11 /*: any*/, v3 /*: any*/],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          v1 /*: any*/,
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Invite',
        kind: 'LinkedField',
        name: 'invites',
        plural: true,
        selections: v12 /*: any*/,
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Currency',
        kind: 'LinkedField',
        name: 'currency',
        plural: false,
        selections: v9 /*: any*/,
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'startingCondition',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'startingDate',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Contact',
        kind: 'LinkedField',
        name: 'contacts',
        plural: true,
        selections: v12 /*: any*/,
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'OrganizationMember',
        kind: 'LinkedField',
        name: 'organizationMember',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'Profile',
            kind: 'LinkedField',
            name: 'profile',
            plural: false,
            selections: [
              v0 /*: any*/,
              {
                alias: null,
                args: null,
                concreteType: 'ProfileInfo',
                kind: 'LinkedField',
                name: 'info',
                plural: false,
                selections: [v10 /*: any*/, v11 /*: any*/],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'Organization',
            kind: 'LinkedField',
            name: 'organization',
            plural: false,
            selections: [v0 /*: any*/, v6 /*: any*/, v3 /*: any*/, v4 /*: any*/, v2 /*: any*/, v5 /*: any*/],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
    type: 'Vacancy',
    abstractKey: null,
  };
})();

(node as any).hash = '4f551193298944d8e241a51e5f76fcb8';

export default node;
