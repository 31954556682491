import { useIntl } from 'react-intl';

const AvailableForWorkLabel = ({ isAvailable }: { isAvailable: boolean }) => {
  const intl = useIntl();
  const availableClasses = isAvailable ? 'text-[#00BF78]' : 'text-specialGray-075';
  const isAvailableForWorkDot = isAvailable ? 'bg-[#00BF78]' : 'bg-specialGray-075';

  return (
    <div className={`flex gap-x-1 w-fit justify-center font-medium items-center rounded-full text-sm sm:whitespace-nowrap ${availableClasses}`}>
      <span className={`w-3 h-3 rounded-full ${isAvailableForWorkDot}`} />
      <span>
        {intl.formatMessage({
          id: isAvailable ? 'profile_available_for_work' : 'profile_not_available_for_work',
        })}
      </span>
    </div>
  );
};

export default AvailableForWorkLabel;
