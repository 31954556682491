const DangerIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0645 3.70174L18.1218 14.2143C18.2551 14.5281 18.3134 14.7833 18.3301 15.0484C18.3634 15.6677 18.1468 16.2696 17.7218 16.7329C17.2969 17.1945 16.722 17.467 16.0971 17.5H3.89917C3.64088 17.4843 3.38259 17.4257 3.14096 17.3349C1.93283 16.8477 1.3496 15.4769 1.84118 14.2887L7.94015 3.69431C8.14845 3.3219 8.46507 3.00068 8.85667 2.79424C9.98981 2.16585 11.4312 2.57872 12.0645 3.70174ZM10.723 10.6293C10.723 11.0257 10.398 11.3568 9.9981 11.3568C9.59817 11.3568 9.26489 11.0257 9.26489 10.6293V8.29327C9.26489 7.89609 9.59817 7.57487 9.9981 7.57487C10.398 7.57487 10.723 7.89609 10.723 8.29327V10.6293ZM9.9981 14.182C9.59817 14.182 9.26489 13.8509 9.26489 13.4553C9.26489 13.0582 9.59817 12.7279 9.9981 12.7279C10.398 12.7279 10.723 13.0507 10.723 13.4463C10.723 13.8509 10.398 14.182 9.9981 14.182Z"
        fill="#FFA740"
      />
    </svg>
  );
};

export default DangerIcon;
