import React, { useCallback, useState } from 'react';
//models
import { ComponentWithPopoverProps } from 'models/modelsOfComponents';
//components
import Popover from './Popover';
//hooks
import useTextOverflow from 'hooks/useTextOverflow';

const ComponentWithPopover: React.FC<ComponentWithPopoverProps> = ({ name, id, defaultHover = '', additionalClasses = 'mb-1 py-1 mr-1', popoverPosition = '', customStyle }) => {
  const [hoverElement, setHoverElement] = useState<string>(defaultHover);

  //check if element has overflow - then add a Popover component
  const { isOverflowActive, overflowingTextRef } = useTextOverflow();

  const handleMouseOver = useCallback(() => {
    setHoverElement(id);
  }, [id]);

  const handleMouseOut = useCallback(() => {
    setHoverElement('');
  }, []);
  const additionalStyleClasses = `bg-specialGray-01 text-center font-medium min-w-20 max-w-[147px] overflow-hidden truncate text-sm px-3 rounded-lg text-specialGray-075 ${additionalClasses}`;
  const classNameStyle = customStyle ? customStyle : additionalStyleClasses;
  return (
    <div className="relative">
      <div data-test-id={name} ref={overflowingTextRef} className={classNameStyle} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        {name}
      </div>
      {hoverElement === id && isOverflowActive && (
        <Popover position={popoverPosition}>
          <>{name}</>
        </Popover>
      )}
    </div>
  );
};

export default ComponentWithPopover;
