import { useIntl } from 'react-intl';
import { useLazyLoadQuery } from 'react-relay';
// hooks
import { useAuth } from 'authentication';
// schemas
import { GET_PROFILE_ORGANIZATION_MEMBER_INVITATIONS } from 'schemas/profile/ProfileQueries';
// generated
import { ProfileQueriesGetProfileOrganizationMemberInvitationsQuery } from 'schemas/profile/__generated__/ProfileQueriesGetProfileOrganizationMemberInvitationsQuery.graphql';
//components
import OrganizationInviteItem from './components/OrganizationInviteItem';

const OrganizationInvites = () => {
  const intl = useIntl();
  const { identity } = useAuth();

  const data = useLazyLoadQuery<ProfileQueriesGetProfileOrganizationMemberInvitationsQuery>(GET_PROFILE_ORGANIZATION_MEMBER_INVITATIONS, {
    profileId: `${identity?.profileId}`,
    skip: !identity?.profileId,
  });

  const totalCount = data?.profileOrganizationMemberInvitations?.totalCount;

  return (
    <div className="flex w-full flex-col gap-y-3">
      <span className="text-sm text-specialGray-075">{intl.formatMessage({ id: 'hire_organization_invites' }, { value: totalCount })}</span>
      {totalCount ? (
        data.profileOrganizationMemberInvitations?.edges?.map(item => {
          return item?.node && <OrganizationInviteItem key={item.node.id} data={item?.node} />;
        })
      ) : (
        <div className="flex w-full border border-specialGray-012 rounded-xl py-3 px-2 justify-center text-specialGray-075 bg-specialGray-002">
          {intl.formatMessage({ id: 'hire_organization_empty_invites' })}
        </div>
      )}
    </div>
  );
};

export default OrganizationInvites;
