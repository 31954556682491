// packages
import { useCallback } from 'react';
import { Tab } from '@headlessui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
// components
import { Button } from 'system/Button';
import SpinnerIcon from 'icons/SpinnerIcon';
import WrapperPanelDesktop from '../PanelTabWithTable/WrapperPanelDesktop';
import PanelTabWithMobileList from '../PanelTabWithTable/PanelTabWithMobileList';
// hooks
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useGetNumberOfOpenedAndClosedVacancies } from 'hooks/hireProfileHooks';
// recoil
import { dashboardShowAllPaginationAtom } from 'recoil/Organisation/Dashboard/atoms/dashboardPaginationAtom';
import { fetchDashboardVacanciesByParamsAtom } from 'recoil/Organisation/Dashboard/atoms/fetchDashboardVacanciesByParamsAtom';
// types
import { dashboardPaginationType } from 'recoil/Organisation/types';
// enums
import { DASHBOARD_VACANCIES_FILTER_ENUM } from 'models/enums';

const PanelTabForAllJobs = () => {
  const { sm } = useBreakpoints();

  const [getDashboardPagination, setDashboardPagination] = useRecoilState(dashboardShowAllPaginationAtom);

  const getFetchDashboardVacanciesByParams = useRecoilValue(fetchDashboardVacanciesByParamsAtom);
  const { getNumberOfClosedVacancies, getNumberOfOpenedVacancies } = useGetNumberOfOpenedAndClosedVacancies();

  const isShowAllTab = getFetchDashboardVacanciesByParams.filterByStatus === DASHBOARD_VACANCIES_FILTER_ENUM.SHOW_ALL;

  const handleLoadMore = useCallback(() => {
    setDashboardPagination((prevState: dashboardPaginationType) => ({ ...prevState, numberOfFetch: prevState.numberOfFetch + 10, isLoading: true }));
  }, [setDashboardPagination]);

  return (
    <>
      <Tab.Panel>{getNumberOfClosedVacancies || getNumberOfOpenedVacancies ? <>{sm ? <PanelTabWithMobileList /> : <WrapperPanelDesktop />}</> : null}</Tab.Panel>
      {getDashboardPagination.hasNextPage && isShowAllTab && (!!getNumberOfClosedVacancies || !!getNumberOfOpenedVacancies) ? (
        <div className="py-4 flex justify-center w-full">
          {!getDashboardPagination.isLoading ? (
            <Button
              type="button"
              buttonType="white-primary"
              label={{ id: 'job_opening_load_more_gob_openings' }}
              additionalClasses="mx-auto px-6 py-4 rounded-xl"
              onClick={handleLoadMore}
            />
          ) : (
            <SpinnerIcon />
          )}
        </div>
      ) : null}
    </>
  );
};

export default PanelTabForAllJobs;
