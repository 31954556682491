import isChromatic from 'chromatic';
import { useMutation } from 'react-relay';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
//components
import { Modal } from 'system/Modal';
import { ConfirmModalBody } from 'system/ConfirmModalBody';
import EmployerCrewPoolsMain from '../EmployerCrewPoolsMain';
//routing
import { RoutesPathNames } from 'app/routing';
//updaters
import { deleteCrewPoolUpdater } from 'formHelpers/updaters/updatersOfHireCrewPools';
//generated
import { ProfileMutationsDeletePoolMutation } from 'schemas/profile/__generated__/ProfileMutationsDeletePoolMutation.graphql';
//schemas
import { DELETE_POOL } from 'schemas/profile/ProfileMutations';
//hooks
import { useGetHireProfile } from 'hooks/hireProfileHooks';

const EmployerDeletePool = () => {
  const navigate = useNavigate();
  const { poolId } = useParams();
  const location = useLocation();
  const { organizationId } = useGetHireProfile();

  const [deletePool] = useMutation<ProfileMutationsDeletePoolMutation>(DELETE_POOL);

  const handleNavigateToCurrentPage = () => {
    navigate(location.pathname.replace(`/${RoutesPathNames.deletePool}/${poolId}`, ''), { replace: true });
  };

  const handleDeletePool = () => {
    if (!poolId) {
      return;
    }
    deletePool({
      variables: {
        id: poolId,
      },
      onCompleted: handleNavigateToCurrentPage,
      updater: deleteCrewPoolUpdater(organizationId),
    });
  };

  return (
    <>
      <Modal onClose={handleNavigateToCurrentPage} show tinyModal>
        <ConfirmModalBody
          title={{ id: 'hire_crew_pool_delete_title' }}
          textOne={{ id: 'hire_crew_pool_delete_description' }}
          onClick={handleDeletePool}
          onCancel={handleNavigateToCurrentPage}
          buttonLabel={{ id: 'delete' }}
          typeButtonConfirm="red-primary"
        />
      </Modal>
      {!(isChromatic() || process.env.STORYBOOK_IS_STORYBOOK) ? <EmployerCrewPoolsMain /> : null}
    </>
  );
};

export default EmployerDeletePool;
