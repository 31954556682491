import { useState, useEffect, useContext, useCallback, useMemo, PropsWithChildren, createContext } from 'react';
import { useSetRecoilState } from 'recoil';
import { Amplify, Auth, Hub } from 'aws-amplify';

import type { HubCapsule } from '@aws-amplify/core';
// Identity is our application specific data taken from the ID Token payload
import { Identity } from 'models/modelsOfComponents';
// AuthState describes the global authentication state. It is available to components
// through the 'useAuth' hook.
import { AuthState } from 'models/modelsOfComponents';
// recoil
import { selfProfileIdAtom } from 'recoil/Auth/atoms/selfProfileIdAtom';
import { assumeProfileAtom } from 'recoil/Admin/atoms/assumeProfileAtom';
// models
import { PLATFORM_GROUP } from 'models/enums';

const initialIdentity: Identity = {
  userId: null,
  profileId: null,
  platformGroup: 'guest',
};

// Amplify provides authentication for our app. We configure it here manually without
// using the CLI. Reference documentation for such configuration can be found here:
// https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
});

// AuthContext holds the React context for global auth information. The default value
// here is only used when the context is used without a provider present
const AuthContext = createContext<AuthState>({});

// useAuth provides auth information to components as a hook
export function useAuth() {
  return useContext(AuthContext);
}

// MockAuthProvider can be used in tests to pretent the user is logged in with a certain identity
export const MockAuthProvider = ({
  typeAccount,
  children,
  id,
}: PropsWithChildren<{
  id: number;
  typeAccount?: PLATFORM_GROUP;
}>) => {
  const setSelfProfileIdAtom = useSetRecoilState(selfProfileIdAtom);

  useEffect(() => {
    setSelfProfileIdAtom(id.toString());
  }, [id, setSelfProfileIdAtom]);

  const getValueForAuthContext = useMemo<Identity>(() => {
    return { platformGroup: typeAccount ?? '', profileId: id, userId: `${id}` };
  }, [id, typeAccount]);

  return <AuthContext.Provider value={{ identity: getValueForAuthContext, loggedIn: true }}>{children}</AuthContext.Provider>;
};

// AuthProvider component provides the authentication information to all other components.
export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [loggedIn, setLoggedIn] = useState<boolean | undefined>();
  const [isAdminProfile, setIsAdminProfile] = useState<boolean>(false);
  const [identity, setIdentity] = useState<Identity | undefined>(initialIdentity);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const setSelfProfileIdAtom = useSetRecoilState(selfProfileIdAtom);
  const setAssumeProfiles = useSetRecoilState(assumeProfileAtom);

  const check = async () => {
    try {
      const sess = await Auth.currentSession();

      const getRefreshToken = localStorage.getItem('refreshToken') ? JSON.parse(localStorage.getItem('refreshToken') || '') : {};
      const sessionRefreshToken = getRefreshToken?.sessionRefreshToken || '';
      localStorage.setItem('refreshToken', JSON.stringify({ currentRefreshToken: sess.getRefreshToken().getToken(), sessionRefreshToken }));

      const profileId = sess.getIdToken().payload['custom:profile_id'];
      const assumingProfileId = sess.getIdToken().payload['custom:assuming_profile_id'];

      setAssumeProfiles(!!assumingProfileId);
      setSelfProfileIdAtom(parseInt(profileId).toString());

      setIdentity({
        userId: sess.getIdToken().payload['sub'],
        profileId: assumingProfileId ? parseInt(assumingProfileId) : parseInt(profileId),
        platformGroup: sess.getIdToken().payload['custom:platform_group'] ?? 'crew',
      });
      setLoggedIn(true);
      if (sess.getIdToken().payload['cognito:groups']) {
        setIsAdminProfile(sess.getIdToken().payload['cognito:groups'][0] === 'admin');
      }
    } catch (e) {
      if (!e || e !== 'No current user') {
        console.warn('failed to get current session, logging out:', e);
      }
      setIdentity({ userId: null, platformGroup: PLATFORM_GROUP.GUEST, profileId: null });
      setIsAdminProfile(false);
      setLoggedIn(false);
    }
  };

  // in case of login and logout events check and update the current user
  useEffect(() => {
    Hub.listen('auth', (ev: HubCapsule) => {
      if (ev.payload.event === 'signIn' || ev.payload.event === 'signOut') {
        check().then();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // always check the current user when this provider is first loaded
  useEffect(() => {
    check().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetEmail = useCallback((value: string) => {
    setEmail(value);
  }, []);

  const handleSetPassword = useCallback((value: string) => {
    setPassword(value);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        identity,
        loggedIn,
        email,
        password,
        setEmail: handleSetEmail,
        setPassword: handleSetPassword,
        isAdminProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
