import React from 'react';

const CombinedShapeIcon: React.FC = () => {
  return (
    <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.68542 1.80465C3.68542 0.924823 2.9727 0.210937 2.09273 0.210937C1.21275 0.210937 0.500026 0.924823 0.500026 1.80465C0.500026 2.68448 1.21275 3.39837 2.09273 3.39837C2.9727 3.39837 3.68542 2.68448 3.68542 1.80465ZM3.6854 8.00087C3.6854 7.12104 2.97268 6.40715 2.0927 6.40715C1.21273 6.40715 0.500005 7.12104 0.500005 8.00087C0.500005 8.8807 1.21273 9.59458 2.0927 9.59458C2.97268 9.59458 3.6854 8.8807 3.6854 8.00087ZM2.0927 12.6006C2.97268 12.6006 3.6854 13.3145 3.6854 14.1944C3.6854 15.0742 2.97268 15.7881 2.0927 15.7881C1.21273 15.7881 0.500005 15.0742 0.500005 14.1944C0.500005 13.3145 1.21273 12.6006 2.0927 12.6006Z"
        fill="#082051"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default CombinedShapeIcon;
