// packages
import { useIntl } from 'react-intl';
import { CountryFragments_info$data } from 'schemas/country/__generated__/CountryFragments_info.graphql';
// components
import CountryFlag from 'system/CountryFlag';

const Country = ({ countryData, autoShowPopover }: { countryData?: CountryFragments_info$data | null; autoShowPopover?: boolean }) => {
  const intl = useIntl();

  return (
    <div className="flex items-center">
      <CountryFlag countryName={countryData?.name} countryId={countryData?.isoCode} autoShowPopover={autoShowPopover} />
      {countryData?.name ? <span>{countryData.name}</span> : <span>{intl.formatMessage({ id: 'no_info' })}</span>}
    </div>
  );
};

export default Country;
