// packages
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
// components
import MortarboardIcon from 'icons/MortarboardSmall';
import OverviewTitleSection from 'system/OverviewTitleSection';
import HighlightedEducationItem from 'app/Profile/Highlighted/HighlightedEducationItem';
import HiddenCrewDataPopover from 'system/ProfileSlide/components/HiddenCrewDataPopover';
// types
import { HighlightedOverviewSectionProps } from 'app/ProfileCrew/type/ProfileCrewTypes';
import { RESTRICTED_PROFILE_DATA_COUNTER } from 'models/enums';

export const HighlightedCrewEducation = ({ profileCrewData }: HighlightedOverviewSectionProps) => {
  const intl = useIntl();

  const hasEducation: boolean = !isEmpty(profileCrewData?.educations);
  const educationsCounter = profileCrewData?.educations?.length || 0;
  const andMoreTitleCounter = educationsCounter - RESTRICTED_PROFILE_DATA_COUNTER.EDUCATIONS;

  return (
    <section>
      <OverviewTitleSection title={{ id: 'profile_educationTitle' }} countLabel={{ id: 'profile_educationAmount' }} count={educationsCounter} />

      {hasEducation && (
        <div className="flex flex-col md:flex-row justify-between flex-wrap">
          {profileCrewData?.educations?.map((educationFragmentItem, index) => {
            return index < RESTRICTED_PROFILE_DATA_COUNTER.EDUCATIONS && educationFragmentItem ? (
              <HighlightedEducationItem education={educationFragmentItem} key={educationFragmentItem.id} />
            ) : null;
          })}
        </div>
      )}

      {educationsCounter > RESTRICTED_PROFILE_DATA_COUNTER.EDUCATIONS && (
        <HiddenCrewDataPopover label={intl.formatMessage({ id: 'profile_education_with_restriction_amount' }, { amount: andMoreTitleCounter })} />
      )}

      {!hasEducation && (
        <div className="flex items-center border border-specialGray-012 rounded-xl px-4 py-2 mt-4">
          <MortarboardIcon />
          <span className="text-darkBlue ml-2 mr-1 text-sm">{intl.formatMessage({ id: 'profile_no_education_added' })}</span>
        </div>
      )}
    </section>
  );
};
