const LogoFullIcon = ({ classes = '' }: { classes?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="662.76" height="65.95" viewBox="0 0 662.76 65.95" className={`h-5 w-auto ${classes}`}>
      <g id="Слой_2" data-name="Слой 2">
        <g id="Слой_1-2" data-name="Слой 1">
          <path
            style={{ fill: '#48feba' }}
            d="M67.93,23.51a21.29,21.29,0,0,0-20.57,16,21.58,21.58,0,0,0-.51,2.8,21.18,21.18,0,0,0-.14,2.4v.16h16s0-.1,0-.16a5.22,5.22,0,0,1,5.21-5.2H88.22c.07-.36.15-.7.23-1.05a26.8,26.8,0,0,1,9.73-15Z"
          />
          <path style={{ fill: '#48feba' }} d="M88.5.13a9.6,9.6,0,1,0,9.61,9.61A9.6,9.6,0,0,0,88.5.13Z" />
          <path style={{ fill: '#ffea29' }} d="M41.79.13A9.6,9.6,0,1,0,51.4,9.74,9.6,9.6,0,0,0,41.79.13Z" />
          <path
            style={{ fill: '#2a6ff5' }}
            d="M155.84,23.54H114.59a21.54,21.54,0,0,0-2.68.17A21.28,21.28,0,0,0,94,39.53a21.58,21.58,0,0,0-.51,2.8,20.19,20.19,0,0,0-.13,2.35v.23h16.07c0-.08,0-.15,0-.23a5,5,0,0,1,.5-2.2,5.2,5.2,0,0,1,4.71-3h41.25a5.2,5.2,0,1,1,0,10.39H135.4a26.87,26.87,0,0,1-9.77,15.95h30.21a21.14,21.14,0,1,0,0-42.28Z"
          />
          <path style={{ fill: '#2a6ff5' }} d="M135.21.13a9.6,9.6,0,1,0,9.61,9.61A9.6,9.6,0,0,0,135.21.13Z" />
          <path
            style={{ fill: '#48feba' }}
            d="M114.28,44.91h0a5.15,5.15,0,0,1-.57,2h0a5.36,5.36,0,0,1-2,2.09,5.13,5.13,0,0,1-2.55.71H88.67a1.42,1.42,0,0,0,0,.22c-.06.28-.12.57-.19.85a26.89,26.89,0,0,1-9.69,14.92l0,0h30.44a19.15,19.15,0,0,0,2.29-.15,21.24,21.24,0,0,0,18.17-15.71s0-.1,0-.15a21,21,0,0,0,.49-2.65s0-.1,0-.16a18.66,18.66,0,0,0,.13-2Z"
          />
          <path
            style={{ fill: '#ffea29' }}
            d="M67.57,44.91h0a5.1,5.1,0,0,1-.57,2s0,0,0,0a5.07,5.07,0,0,1-1.82,2L65,49a5.15,5.15,0,0,1-2.52.7H21.14a5.19,5.19,0,0,1-4.55-2.8,5.32,5.32,0,0,1-.57-2h0s0-.1,0-.16a5.21,5.21,0,0,1,5.21-5.2h20.4a26.8,26.8,0,0,1,9.93-16H21.22A21.24,21.24,0,0,0,0,44.73v.16c0,.13,0,.25,0,.37,0,.55.06,1.1.12,1.63a21.19,21.19,0,0,0,21,18.82H62.43q.91,0,1.83-.09c.27,0,.54-.07.81-.1A21.28,21.28,0,0,0,82.86,49.94l.06-.22s.37-1.72.48-2.59c0-.08.15-1.55.17-2.22Z"
          />
          <path
            style={{ fill: '#082051' }}
            d="M214.42,21.48a26.34,26.34,0,0,1,10.23-10.36,29.73,29.73,0,0,1,14.88-3.7A28.44,28.44,0,0,1,257,12.8a25.77,25.77,0,0,1,9.7,14.67H251.35A12.38,12.38,0,0,0,246.5,22a13.64,13.64,0,0,0-7.14-1.88,13.31,13.31,0,0,0-10.43,4.49q-4,4.48-4,12t4,12a13.31,13.31,0,0,0,10.43,4.49,13.64,13.64,0,0,0,7.14-1.88,12.38,12.38,0,0,0,4.85-5.46h15.32A25.61,25.61,0,0,1,257,60.36a28.61,28.61,0,0,1-17.44,5.34A29.74,29.74,0,0,1,224.65,62a26.34,26.34,0,0,1-10.23-10.31,30.58,30.58,0,0,1-3.67-15.08A30.82,30.82,0,0,1,214.42,21.48Z"
          />
          <path
            style={{ fill: '#082051' }}
            d="M295.28,21.48a15.8,15.8,0,0,1,8.16-2.16V34.07h-3.83q-5.22,0-7.83,2.24t-2.61,7.87V65.29H275.23V19.81h13.94v7.58A18.42,18.42,0,0,1,295.28,21.48Z"
          />
          <path
            style={{ fill: '#082051' }}
            d="M352.75,45.89H321.21a9.4,9.4,0,0,0,2.73,6.48,8.32,8.32,0,0,0,5.91,2.25q5.2,0,7.25-4.41h14.84a20.52,20.52,0,0,1-11.58,13.7,23.93,23.93,0,0,1-10,2,24.45,24.45,0,0,1-11.91-2.86,20.36,20.36,0,0,1-8.15-8.15,25.22,25.22,0,0,1-2.93-12.39,25.43,25.43,0,0,1,2.89-12.39A20,20,0,0,1,318.35,22a24.6,24.6,0,0,1,12-2.85,24.33,24.33,0,0,1,11.74,2.77,19.93,19.93,0,0,1,8,7.9,24,24,0,0,1,2.9,12A34,34,0,0,1,352.75,45.89Zm-14-7.74a7.18,7.18,0,0,0-2.44-5.71,9,9,0,0,0-6.12-2.12,8.85,8.85,0,0,0-5.91,2,9.07,9.07,0,0,0-3,5.79Z"
          />
          <path style={{ fill: '#082051' }} d="M425.63,19.81,413.32,65.29H397.91l-7.17-29.51-7.42,29.51H368L355.61,19.81h13.94L376,52.33l7.67-32.52H398.4l7.74,32.36,6.36-32.36Z" />
          <path style={{ fill: '#082051' }} d="M444.87,5V65.29H430.93V5Z" />
          <path
            style={{ fill: '#082051' }}
            d="M456,12.92a7,7,0,0,1-2.32-5.34A7.12,7.12,0,0,1,456,2.16,8.46,8.46,0,0,1,462,0a8.35,8.35,0,0,1,5.92,2.16,7.12,7.12,0,0,1,2.32,5.42,7,7,0,0,1-2.32,5.34A8.35,8.35,0,0,1,462,15.08,8.46,8.46,0,0,1,456,12.92Zm12.92,6.89V65.29H455V19.81Z"
          />
          <path
            style={{ fill: '#082051' }}
            d="M519.49,24.5q4.77,5.16,4.77,14.22V65.29H510.4V40.59c0-3-.78-5.4-2.36-7.09a9.29,9.29,0,0,0-12.72,0A10,10,0,0,0,493,40.59v24.7H479V19.81H493v6a14.66,14.66,0,0,1,5.71-4.77,18,18,0,0,1,8.07-1.75Q514.72,19.32,519.49,24.5Z"
          />
          <path style={{ fill: '#082051' }} d="M561.76,65.29,547.9,46.22V65.29H534V5H547.9V38.31l13.78-18.5h17.2L560,42.63,579,65.29Z" />
          <path
            style={{ fill: '#082051' }}
            d="M627,45.89H595.42a9.49,9.49,0,0,0,2.73,6.48,8.35,8.35,0,0,0,5.91,2.25q5.22,0,7.26-4.41h14.83A20.37,20.37,0,0,1,622,58.28a20.59,20.59,0,0,1-7.46,5.63,23.94,23.94,0,0,1-10,2,24.44,24.44,0,0,1-11.9-2.86,20.3,20.3,0,0,1-8.15-8.15,25.11,25.11,0,0,1-2.93-12.39,25.43,25.43,0,0,1,2.89-12.39A20,20,0,0,1,592.57,22a24.59,24.59,0,0,1,12-2.85,24.27,24.27,0,0,1,11.74,2.77,19.89,19.89,0,0,1,8,7.9,24,24,0,0,1,2.9,12A36.25,36.25,0,0,1,627,45.89Zm-14-7.74a7.21,7.21,0,0,0-2.44-5.71,9,9,0,0,0-6.12-2.12,8.81,8.81,0,0,0-5.91,2,9,9,0,0,0-3,5.79Z"
          />
          <path
            style={{ fill: '#082051' }}
            d="M654.6,21.48a15.8,15.8,0,0,1,8.16-2.16V34.07h-3.83q-5.22,0-7.83,2.24t-2.61,7.87V65.29H634.55V19.81h13.94v7.58A18.42,18.42,0,0,1,654.6,21.48Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoFullIcon;
