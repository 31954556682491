// packages
import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// components
import { Modal } from 'system/Modal';
import LogoIcon from 'icons/LogoIcon';
// routes
import { RoutePaths } from 'app/routing';

const WelcomeForHireModal = ({ handleShowConfetti }: { handleShowConfetti: Dispatch<SetStateAction<boolean>> }) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const handleCloseModal = () => {
    window.analytics?.track('wui-close-welcome-hire-congratulations-modal');
    handleShowConfetti(false);
    navigate(RoutePaths.ProfileOrganizationBase, { replace: true });
  };

  return (
    <Modal wideModal show onClose={handleCloseModal}>
      <>
        <div className="flex flex-col w-full justify-center items-center gap-y-9">
          <LogoIcon isBigScreenLogo />
          <div className="flex flex-col w-full gap-y-3.5 justify-center items-center">
            <h2 className="text-[32px] leading-[48px] text-darkBlue font-bold text-center">{intl.formatMessage({ id: 'hire_congratulation_modal_title' })}</h2>
            <p className="max-w-[300px] text-center text-lg text-specialGray-075">{intl.formatMessage({ id: 'hire_congratulation_modal_description' })}</p>
          </div>
        </div>
        <div className="flex justify-center sm:justify-end px-8 py-4 items-center mt-[70px] -mb-6 -mx-6 bg-specialGray-004">
          <button onClick={handleCloseModal} className="py-3.5 px-[46px] text-white bg-blue font-medium text-sm rounded-[10px]">
            {intl.formatMessage({ id: 'hire_congratulation_modal_button_title' })}
          </button>
        </div>
      </>
    </Modal>
  );
};

export default WelcomeForHireModal;
