// packages
import { Suspense } from 'react';
// components
import { Profile } from 'app/Profile/Profile';
import { LazyLoader } from 'system/skeletons/wrapper/LazyLoader';
import ProfileSkeleton from 'system/skeletons/profile/ProfileSkeleton/ProfileSkeleton';
// routes
import PrivateRoutes from 'app/PrivateRoutes/PrivateRoutes';

const ProfileWrapper = () => (
  <PrivateRoutes>
    <Suspense
      fallback={
        <LazyLoader mainComponent={<Profile />}>
          <ProfileSkeleton />
        </LazyLoader>
      }
    >
      <Profile />
    </Suspense>
  </PrivateRoutes>
);

export default ProfileWrapper;
