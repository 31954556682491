import { ConnectionHandler, RecordProxy } from 'relay-runtime';
// types
import { ORDER_DIRECTION, VACANCY_ORDER_FIELD, VACANCY_STATUS } from 'models/enums';
import { vacancyFiltersTypes } from 'recoil/Organisation/Dashboard/types';

export const mockVacancyFiltersForJobs = <T>(payload: RecordProxy<T>, valuesArray: VACANCY_STATUS[] = [VACANCY_STATUS.OPEN]) => {
  return {
    orgId: payload?.getLinkedRecord('organization')?.getValue('id'),
    filters: [{ field: VACANCY_ORDER_FIELD.STATUS, values: valuesArray }],
    orderBy: { field: VACANCY_ORDER_FIELD.CREATED_AT, direction: ORDER_DIRECTION.DESC },
  };
};

export const getConnectionHandler = <T>(storyRecord: RecordProxy, payload: RecordProxy<T>) => {
  const connectionRecordWithOpenedJobs = ConnectionHandler.getConnection(storyRecord, 'VacancyList_vacancies', {
    ...mockVacancyFiltersForJobs(payload),
  });
  const connectionRecordWithClosedJobs = ConnectionHandler.getConnection(storyRecord, 'VacancyList_vacancies', {
    ...mockVacancyFiltersForJobs(payload, [VACANCY_STATUS.CLOSED]),
  });
  return { connectionRecordWithOpenedJobs, connectionRecordWithClosedJobs };
};

const getVacancyConnectionHandler = <T>(storyRecord: RecordProxy, payload: RecordProxy<T>, vacancyStatus: VACANCY_STATUS[], filterItem: vacancyFiltersTypes[number]) => {
  return ConnectionHandler.getConnection(storyRecord, 'VacancyList_vacancies', {
    orgId: payload?.getLinkedRecord('organization')?.getValue('id'),
    filters: [{ field: VACANCY_ORDER_FIELD.STATUS, values: vacancyStatus }],
    ...filterItem,
  });
};
export const removeVacancyFromConnectionHandlers = <T>(storyRecord: RecordProxy, payload: RecordProxy<T>, allVacancyFilters: vacancyFiltersTypes) => {
  const nodeRecord = payload?.setLinkedRecord(payload, 'node');
  allVacancyFilters?.forEach(filtersItem => {
    const currentConnectionHandler = getVacancyConnectionHandler(storyRecord, payload, [payload.getValue('status') as VACANCY_STATUS], filtersItem);
    const showAllConnectionHandler = getVacancyConnectionHandler(storyRecord, payload, [VACANCY_STATUS.OPEN, VACANCY_STATUS.CLOSED], filtersItem);

    if (showAllConnectionHandler) {
      ConnectionHandler.deleteNode(showAllConnectionHandler, `${nodeRecord?.getDataID()}`);
    }
    if (currentConnectionHandler) {
      ConnectionHandler.deleteNode(currentConnectionHandler, `${nodeRecord?.getDataID()}`);
    }
  });
};

export const switchStatusInConnectionHandlers = <T>(
  storyRecord: RecordProxy,
  payload: RecordProxy<T>,
  allOpenedVacancyFilters: vacancyFiltersTypes,
  allShowAllVacancyFilters: vacancyFiltersTypes,
) => {
  const isOpenVacancyStatus = payload.getValue('status') === VACANCY_STATUS.OPEN;
  const nodeRecord = payload?.setLinkedRecord(payload, 'node');

  allOpenedVacancyFilters.forEach(openedFiltersItem => {
    const openedConnectionHandler = getVacancyConnectionHandler(storyRecord, payload, [VACANCY_STATUS.OPEN], openedFiltersItem);
    if (openedConnectionHandler) {
      if (isOpenVacancyStatus) {
        ConnectionHandler.insertEdgeBefore(openedConnectionHandler, nodeRecord);
      } else {
        ConnectionHandler.deleteNode(openedConnectionHandler, `${nodeRecord?.getDataID()}`);
      }
    }
  });

  allShowAllVacancyFilters.forEach(closedFiltersItem => {
    const showAllConnectionHandler = getVacancyConnectionHandler(storyRecord, payload, [VACANCY_STATUS.OPEN, VACANCY_STATUS.CLOSED], closedFiltersItem);
    if (showAllConnectionHandler) {
      ConnectionHandler.deleteNode(showAllConnectionHandler, `${nodeRecord?.getDataID()}`);
      ConnectionHandler.insertEdgeBefore(showAllConnectionHandler, nodeRecord);
    }
  });
};

export const handleUpdateOrganizationStatsWhenSwitchStatus = <T>(status: VACANCY_STATUS, organizationVacancyStatsRecord?: Array<RecordProxy<T>> | null) => {
  if (!organizationVacancyStatsRecord) {
    return;
  }
  organizationVacancyStatsRecord.forEach(statRecord => {
    const statusValue = statRecord.getValue('status');
    const totalCount = statRecord.getValue('totalCount');
    if (statusValue === status) {
      statRecord.setValue((totalCount as number) + 1, 'totalCount');
      return;
    }
    statRecord.setValue((totalCount as number) - 1, 'totalCount');
  });
};
