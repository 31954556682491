/**
 * @generated SignedSource<<7bfe52b887d52bb51892ff7185a0f63c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ApplicationStatus = 'DECLINED' | 'PENDING' | '%future added value';
import { FragmentRefs } from 'relay-runtime';
export type ApplicationFragments$data = {
  readonly 'createdAt': String;
  readonly 'id': string;
  readonly 'owner': {
    readonly 'id': string;
    readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsCrewProfile'>;
  };
  readonly 'status': ApplicationStatus;
  readonly 'vacancy': {
    readonly hires: ReadonlyArray<{
      readonly id: string;
      readonly owner: {
        readonly id: string;
      };
    }> | null;
    readonly id: string;
  };
  readonly ' $fragmentType': 'ApplicationFragments';
};
export type ApplicationFragments$key = {
  readonly ' $data'?: ApplicationFragments$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ApplicationFragments'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'id',
    storageKey: null,
  };
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: {
      refetch: {
        connection: null,
        fragmentPathInResult: ['node'],
        operation: require('./ApplicationQueriesGetApplicationRefreshQuery.graphql'),
        identifierField: 'id',
      },
    },
    name: 'ApplicationFragments',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'createdAt',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Vacancy',
        kind: 'LinkedField',
        name: 'vacancy',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'Hire',
            kind: 'LinkedField',
            name: 'hires',
            plural: true,
            selections: [
              v0 /*: any*/,
              {
                alias: null,
                args: null,
                concreteType: 'Profile',
                kind: 'LinkedField',
                name: 'owner',
                plural: false,
                selections: [v0 /*: any*/],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Profile',
        kind: 'LinkedField',
        name: 'owner',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'ProfileFragmentsCrewProfile',
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'status',
        storageKey: null,
      },
    ],
    type: 'Application',
    abstractKey: null,
  };
})();

(node as any).hash = '82ed12d777f0ecc33065b6a8386916cf';

export default node;
