// packages
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Skeleton from 'react-loading-skeleton';
// components
import ItemMemberSkeleton from './components/ItemMemberSkeleton';

const ListOfMembersSkeleton = () => {
  const [emptyList] = useState<Array<string>>(new Array(4).fill(uuidv4()).map(() => uuidv4()));

  return (
    <div className="flex flex-col gap-y-3">
      <Skeleton width={150} height={18} />
      <div className="flex gap-y-3 justify-between flex-wrap">
        {emptyList.map(item => (
          <ItemMemberSkeleton key={item} />
        ))}
      </div>
      <Skeleton width={220} height={34} borderRadius={12} containerClassName="text-center" />
    </div>
  );
};

export default ListOfMembersSkeleton;
