// packages
import { Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
// routes
import { RoutePaths } from 'app/routing';
// models
import { typeFormVacancyState } from 'models/routeLocationState';
// components
import SpinnerIcon from 'icons/SpinnerIcon';
import JobOpeningForm from 'app/ProfileEmployer/components/JobOpeningForm/JobOpeningForm';
// context
import JobOpeningFormContext from 'Context/JobOpeningFormContext';

const FormWrapper = () => {
  const { state } = useLocation();

  if (!(state as typeFormVacancyState)?.organization) {
    return <Navigate to={RoutePaths.ProfileOrganizationBase} />;
  }

  return (
    <JobOpeningFormContext>
      <JobOpeningForm>
        <Suspense fallback={<SpinnerIcon />}>
          <Outlet />
        </Suspense>
      </JobOpeningForm>
    </JobOpeningFormContext>
  );
};

export default FormWrapper;
