const ArticleIcon = () => {
  return (
    <svg className="fill-current" width="1em" height="1em" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_10356_80110" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
        <rect className="fill-current" x="0.345703" y="0.5" width="24" height="24" />
      </mask>
      <g mask="url(#mask0_10356_80110)">
        <path
          className="fill-current"
          d="M8.3457 17.5H13.3457C13.629 17.5 13.8667 17.404 14.0587 17.212C14.25 17.0207 14.3457 16.7833 14.3457 16.5C14.3457 16.2167 14.25 15.979 14.0587 15.787C13.8667 15.5957 13.629 15.5 13.3457 15.5H8.3457C8.06237 15.5 7.8247 15.5957 7.6327 15.787C7.44137 15.979 7.3457 16.2167 7.3457 16.5C7.3457 16.7833 7.44137 17.0207 7.6327 17.212C7.8247 17.404 8.06237 17.5 8.3457 17.5ZM8.3457 13.5H16.3457C16.629 13.5 16.8664 13.404 17.0577 13.212C17.2497 13.0207 17.3457 12.7833 17.3457 12.5C17.3457 12.2167 17.2497 11.979 17.0577 11.787C16.8664 11.5957 16.629 11.5 16.3457 11.5H8.3457C8.06237 11.5 7.8247 11.5957 7.6327 11.787C7.44137 11.979 7.3457 12.2167 7.3457 12.5C7.3457 12.7833 7.44137 13.0207 7.6327 13.212C7.8247 13.404 8.06237 13.5 8.3457 13.5ZM8.3457 9.5H16.3457C16.629 9.5 16.8664 9.404 17.0577 9.212C17.2497 9.02067 17.3457 8.78333 17.3457 8.5C17.3457 8.21667 17.2497 7.979 17.0577 7.787C16.8664 7.59567 16.629 7.5 16.3457 7.5H8.3457C8.06237 7.5 7.8247 7.59567 7.6327 7.787C7.44137 7.979 7.3457 8.21667 7.3457 8.5C7.3457 8.78333 7.44137 9.02067 7.6327 9.212C7.8247 9.404 8.06237 9.5 8.3457 9.5ZM5.3457 21.5C4.7957 21.5 4.3247 21.3043 3.9327 20.913C3.54137 20.521 3.3457 20.05 3.3457 19.5V5.5C3.3457 4.95 3.54137 4.479 3.9327 4.087C4.3247 3.69567 4.7957 3.5 5.3457 3.5H19.3457C19.8957 3.5 20.3667 3.69567 20.7587 4.087C21.15 4.479 21.3457 4.95 21.3457 5.5V19.5C21.3457 20.05 21.15 20.521 20.7587 20.913C20.3667 21.3043 19.8957 21.5 19.3457 21.5H5.3457Z"
        />
      </g>
    </svg>
  );
};

export default ArticleIcon;
