// packages
import { useEffect, useState } from 'react';
// mocks
import { numberOfYearsOfExperience } from 'mocks/mockData';
// generated
import { ProfileYearsOfExperience } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';

export function useGetYearsOfExperience(crewYearsOfExperience?: ProfileYearsOfExperience | null) {
  const [getYearsOfExperience, setYearsOfExperience] = useState<typeof numberOfYearsOfExperience[number] | null>(null);

  useEffect(() => {
    if (crewYearsOfExperience) {
      setYearsOfExperience(numberOfYearsOfExperience.find(year => year.id === crewYearsOfExperience) as typeof numberOfYearsOfExperience[number]);
    }
  }, [crewYearsOfExperience]);

  return { getYearsOfExperience };
}
