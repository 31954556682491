// packages
import { Suspense } from 'react';
import { useFragment, useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
// components
import FieldWrapper from '../FieldWrapper';
import DeleteAccount from '../DeleteAccount';
import AvailabilitySettings from './components/AvailabilitySettings';
import ChangeVisibleAccount from './components/ChangeVisibleAccount';
import NotificationPreferences from './components/NotificationPreferences';
import SettingsOptionToggleSkeleton from 'system/skeletons/settings/components/SettingsOptionToggleSkeleton';
import SettingsOptionWrapperSkeleton from 'system/skeletons/settings/components/SettingsOptionWrapperSkeleton';
// generated
import { ProfileQueriesRootQuery, ProfileQueriesRootQuery$data } from 'schemas/profile/__generated__/ProfileQueriesRootQuery.graphql';
// schemas
import { PROFILE_QUERY } from 'schemas/profile/ProfileQueries';
import { PROFILE_FRAGMENT, PROFILE_INFO_FRAGMENT } from 'schemas/profile/ProfileFragments';
import { ProfileFragments$key } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { ProfileFragments_info$key } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
// hooks
import { useAuth } from 'authentication';

const CheckboxesAccountSettings = () => {
  const { identity } = useAuth();
  const selfProfileQueryRef: ProfileQueriesRootQuery$data = useLazyLoadQuery<ProfileQueriesRootQuery>(PROFILE_QUERY, {
    id: `${identity?.profileId}`,
    skip: !identity?.profileId,
  });
  const [profileData] = useRefetchableFragment<ProfileQueriesRootQuery, ProfileFragments$key>(PROFILE_FRAGMENT, selfProfileQueryRef.node || null);
  const profileInfo = useFragment<ProfileFragments_info$key>(PROFILE_INFO_FRAGMENT, profileData?.info || null);

  return (
    <>
      <Suspense
        fallback={
          <SettingsOptionWrapperSkeleton titleClassName="w-20" subtitleClassName="w-full max-w-[265px]">
            <SettingsOptionToggleSkeleton titleClassName="w-full max-w-[65px]" subtitleClassName="w-full sm:w-[370px]" />
          </SettingsOptionWrapperSkeleton>
        }
      >
        <FieldWrapper title={{ id: 'account_availability_title' }} description={{ id: 'account_availability_details' }}>
          <AvailabilitySettings profileData={profileData} />
        </FieldWrapper>
      </Suspense>

      <Suspense
        fallback={
          <SettingsOptionWrapperSkeleton titleClassName="w-32" subtitleClassName="w-full sm:w-56" arrayOfExtraLines={[{ extraLineClassName: 'w-full max-w-[80px]' }]}>
            <SettingsOptionToggleSkeleton titleClassName="w-full max-w-[250px]" subtitleClassName="w-full max-w-[320px]" />
            <SettingsOptionToggleSkeleton titleClassName="w-full max-w-[145px]" subtitleClassName="w-full max-w-[290px]" />
          </SettingsOptionWrapperSkeleton>
        }
      >
        <FieldWrapper title={{ id: 'account_visibility_title' }} description={{ id: 'account_visibility_description' }}>
          <ChangeVisibleAccount profileData={profileData} profileInfo={profileInfo} />
        </FieldWrapper>
      </Suspense>

      <Suspense
        fallback={
          <SettingsOptionWrapperSkeleton titleClassName="w-44" subtitleClassName="w-full max-w-[240px]" arrayOfExtraLines={[{ extraLineClassName: 'w-full max-w-[60px]' }]}>
            <SettingsOptionToggleSkeleton titleClassName="w-full sm:w-56" subtitleClassName="w-full max-w-[600px]" />
            <SettingsOptionToggleSkeleton titleClassName="w-full sm:w-52" subtitleClassName="w-full max-w-[470px]" />
          </SettingsOptionWrapperSkeleton>
        }
      >
        <FieldWrapper title={{ id: 'my_subscriptions_label' }} description={{ id: 'my_subscriptions_description' }}>
          <NotificationPreferences selfProfileQuery={selfProfileQueryRef} />
        </FieldWrapper>
      </Suspense>

      <FieldWrapper title={{ id: 'delete_account_button' }} description={{ id: 'account_delete_description' }}>
        <DeleteAccount />
      </FieldWrapper>
    </>
  );
};

export default CheckboxesAccountSettings;
