import { FC } from 'react';

const ArrowComponent: FC<{ index: number }> = ({ index }) => {
  return (
    <div id={`arrow-${index}`} className="absolute block w-full mx-2 h-0.5 bg-specialGray-012 rounded-sm">
      <div className="absolute rounded-sm -top-[3.5px] -right-[1.1px] w-2 h-0.5 rotate-45 bg-specialGray-012" />
      <div className="absolute rounded-sm top-[3.5px] -right-[1.1px] w-2 h-0.5 -rotate-45 bg-specialGray-012" />
    </div>
  );
};

export default ArrowComponent;
