/**
 * @generated SignedSource<<eefe4d752b7837aacfbbf6d39e73d957>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DocumentQueriesGetKindsQuery$variables = {};
export type DocumentQueriesGetKindsQuery$data = {
  readonly documentKinds: {
    readonly edges: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly perma: string;
    }>;
  } | null;
};
export type DocumentQueriesGetKindsQuery = {
  response: DocumentQueriesGetKindsQuery$data;
  variables: DocumentQueriesGetKindsQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'DocumentKindList',
      kind: 'LinkedField',
      name: 'documentKinds',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'DocumentKind',
          kind: 'LinkedField',
          name: 'edges',
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'id',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'name',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'perma',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'DocumentQueriesGetKindsQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'DocumentQueriesGetKindsQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: '03f63119978730b79d57bdc83eeef6c3',
      id: null,
      metadata: {},
      name: 'DocumentQueriesGetKindsQuery',
      operationKind: 'query',
      text: 'query DocumentQueriesGetKindsQuery {\n  documentKinds {\n    edges {\n      id\n      name\n      perma\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '15a6220fa8a0ba282fc8fcd413bebb8c';

export default node;
