import Skeleton from 'react-loading-skeleton';
import CountedSkeletonItemCEV from './components/CountedSkeletonItemCEV';

const MainProfileCrewComponentSkeletonEv = () => {
  return (
    <div className="flex w-full text-specialGray-1 flex-wrap">
      {/*avatar*/}
      <Skeleton containerClassName="flex mr-6" width={83} height={83} borderRadius={6} />

      <div className="flex flex-col w-auto">
        {/*title*/}
        <div className="flex mb-4 mr-8 h-12 items-center">
          <span className="flex mr-8">
            <Skeleton className="mr-2" width={120} height={32} />
            <Skeleton width={100} height={32} />
          </span>
          {/*buttons*/}
          <div className="flex flex-wrap md:flex-nowrap [&>*]:mr-5">
            <Skeleton width={160} height={40} borderRadius={10} />
            <Skeleton width={106} height={40} borderRadius={10} />
          </div>
        </div>

        {/*counted items*/}
        <div className="flex mb-3 [&>div]:mr-4 h-[21px]">
          <CountedSkeletonItemCEV widthTitle={50} />
          <CountedSkeletonItemCEV widthTitle={86} />
          <CountedSkeletonItemCEV widthTitle={78} />
          <CountedSkeletonItemCEV widthTitle={71} />
        </div>

        {/*about me title*/}
        <div className="flex flex-col">
          <div className="flex mb-2 h-6">
            <Skeleton className="mr-1" width={50} height={16} />
            <Skeleton width={20} height={16} />
          </div>

          <Skeleton width={160} height={14} />
          <Skeleton width={260} height={14} />
          <Skeleton width={360} height={14} />
        </div>
      </div>
    </div>
  );
};

export default MainProfileCrewComponentSkeletonEv;
