const PaperSmallIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.58894 17.9541H9.81336C10.1317 17.9541 10.3958 17.6874 10.3958 17.3659C10.3958 17.0443 10.1317 16.7855 9.81336 16.7855H5.58894C5.27055 16.7855 5.00653 17.0443 5.00653 17.3659C5.00653 17.6874 5.27055 17.9541 5.58894 17.9541ZM8.21367 12.8637H5.58894C5.27055 12.8637 5.00653 13.1304 5.00653 13.452C5.00653 13.7735 5.27055 14.0324 5.58894 14.0324H8.21367C8.53205 14.0324 8.79608 13.7735 8.79608 13.452C8.79608 13.1304 8.53205 12.8637 8.21367 12.8637ZM13.7553 12.1763C13.9378 12.1742 14.1366 12.1719 14.3171 12.1719C14.5113 12.1719 14.6666 12.3288 14.6666 12.5248V18.8307C14.6666 20.7758 13.1057 22.3523 11.1799 22.3523H4.99856C2.97953 22.3523 1.33325 20.6974 1.33325 18.6582V10.2033C1.33325 8.25817 2.90188 6.66602 4.83548 6.66602H8.98225C9.18415 6.66602 9.33946 6.83072 9.33946 7.0268V9.55229C9.33946 10.9876 10.5121 12.1641 11.9331 12.1719C12.2651 12.1719 12.5577 12.1744 12.8138 12.1765C13.013 12.1782 13.1901 12.1797 13.3465 12.1797C13.457 12.1797 13.6003 12.1781 13.7553 12.1763ZM13.9693 11.0311C13.331 11.0334 12.5785 11.0311 12.0373 11.0256C11.1784 11.0256 10.471 10.3111 10.471 9.4436V7.37615C10.471 7.03811 10.8771 6.87027 11.1093 7.11419C11.5301 7.55612 12.1086 8.16379 12.6842 8.76847C13.2569 9.37004 13.8267 9.96864 14.2357 10.3981C14.4625 10.6358 14.2963 11.0303 13.9693 11.0311Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <path
        d="M21.5888 4.69682H20.0348V3.1775C20.0348 2.52887 19.5146 2 18.8737 2C18.234 2 17.7125 2.52887 17.7125 3.1775V4.69682H16.1612C15.5202 4.69682 15 5.22569 15 5.87433C15 6.52296 15.5202 7.05183 16.1612 7.05183H17.7125V8.57249C17.7125 9.22113 18.234 9.75 18.8737 9.75C19.5146 9.75 20.0348 9.22113 20.0348 8.57249V7.05183H21.5888C22.2285 7.05183 22.75 6.52296 22.75 5.87433C22.75 5.22569 22.2285 4.69682 21.5888 4.69682Z"
        fill="#082051"
        fillOpacity="0.3"
      />
    </svg>
  );
};

export default PaperSmallIcon;
