// packages
import { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Skeleton from 'react-loading-skeleton';
// components
import ListOfCertificateItemSkeleton from './components/ListOfCertificateItemSkeleton';

const ListOfCertificatesSkeleton: FC = () => {
  const [emptyList] = useState<Array<string>>(new Array(4).fill('').map(() => uuidv4()));

  return (
    <div className="flex flex-col w-full mt-3">
      <div className="flex w-full mb-3">
        <Skeleton width={100} height={12} containerClassName="w-1/2" />
        <Skeleton width={110} height={14} />
      </div>
      {emptyList.map((item, index) => (
        <ListOfCertificateItemSkeleton key={item} index={index} roundedBorderBottom={index === emptyList?.length - 1} />
      ))}
      <Skeleton width={167} height={34} borderRadius={6} containerClassName="mx-auto mt-4" />
    </div>
  );
};

export default ListOfCertificatesSkeleton;
