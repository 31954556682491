// packages
import { useIntl } from 'react-intl';
// hooks
import { useGetProfileIdFromAuthOrParams } from 'hooks/useGetProfileIdFromAuthOrParams';
// models
import { ICertificateInfoEdge } from 'models/ICertificate';
// components
import HeaderGroup from './HeaderGroup';
import { CertificateItem } from '../../CertificateItem/CertificateItem';
// types
import { SectionGroupProps } from '../../types';

const SectionGroup = ({ nameGroup, certificates, deleteCertificateClick }: SectionGroupProps) => {
  const intl = useIntl();
  const { isSelfProfile } = useGetProfileIdFromAuthOrParams();

  return (
    <section className="sm:mx-6 mb-6 border border-specialGray-012 rounded-2xl">
      <table className="table-auto w-full">
        <thead>
          <tr className="bg-specialGray-004 border-b">
            <HeaderGroup nameGroup={nameGroup} classes="w-full sm:w-2/6 rounded-tl-2xl rounded-tr-2xl sm:rounded-tr-none" />
            <th className="hidden sm:table-cell w-2/6" />
            {isSelfProfile ? (
              <HeaderGroup nameGroup={intl.formatMessage({ id: 'label_attached_files' })} classes="hidden sm:table-cell w-min" />
            ) : (
              <th className="hidden sm:table-cell w-10%" />
            )}
            <HeaderGroup nameGroup={intl.formatMessage({ id: 'certificate_expiry_date' })} classes="hidden sm:table-cell w-10%" />
            <th className="hidden sm:table-cell w-1/12 rounded-tr-2xl" />
          </tr>
        </thead>
        <tbody className="sm:rounded-lg">
          {certificates.map((certificateInfo: ICertificateInfoEdge) => {
            return (
              certificateInfo && (
                <tr key={certificateInfo.id} className="sm:h-14 sm:table-row w-full border-t border-specialGray-012">
                  <CertificateItem certificateInfo={certificateInfo} onDelete={deleteCertificateClick} />
                </tr>
              )
            );
          })}
        </tbody>
      </table>
    </section>
  );
};

export default SectionGroup;
