// packages
import { isEmpty } from 'lodash';
import { useLazyLoadQuery } from 'react-relay';
// components
import EmptyList from '../EmptyList';
import ForwardToInbox from 'icons/ForwardToInbox';
import AppliedItem from './components/AppliedItem';
// schemas
import { LIST_OF_VACANCIES } from 'schemas/vacancy/VacancyQueries';
// generated
import { VacancyQueriesListOfVacanciesQuery } from 'schemas/vacancy/__generated__/VacancyQueriesListOfVacanciesQuery.graphql';
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';

const ListOfApplied = () => {
  const vacancies = useLazyLoadQuery<VacancyQueriesListOfVacanciesQuery>(
    LIST_OF_VACANCIES,
    {
      skip: false,
      filters: [{ field: 'APPLIED', values: [] }],
    },
    { fetchPolicy: 'store-and-network' },
  );

  return !isEmpty(vacancies.vacancies?.edges) ? (
    <div className="flex flex-col space-y-6 pt-6">
      {vacancies.vacancies?.edges?.map(vacancy => (
        <AppliedItem key={vacancy?.node?.id} vacancy={vacancy?.node as VacancyFragments$key} />
      ))}
    </div>
  ) : (
    <EmptyList icon={<ForwardToInbox />} title={{ id: 'job_responses_empty_applied_title' }} description={{ id: 'job_responses_empty_applied_description' }} />
  );
};

export default ListOfApplied;
