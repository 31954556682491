import { FC } from 'react';

const InvitationIcon: FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id="invitation-icon">
      <g>
        <path
          d="M16.25 3.5H3.25C2.35625 3.5 1.625 4.23125 1.625 5.125V14.875C1.625 15.7688 2.35625 16.5 3.25 16.5H16.25C17.1438 16.5 17.875 15.7688 17.875 14.875V5.125C17.875 4.23125 17.1438 3.5 16.25 3.5ZM15.925 6.95312L10.6112 10.2762C10.0831 10.6094 9.41687 10.6094 8.88875 10.2762L3.575 6.95312C3.37188 6.82312 3.25 6.60375 3.25 6.36813C3.25 5.82375 3.84313 5.49875 4.30625 5.78312L9.75 9.1875L15.1938 5.78312C15.6569 5.49875 16.25 5.82375 16.25 6.36813C16.25 6.60375 16.1281 6.82312 15.925 6.95312Z"
          fill="currentColor"
        />
        <path
          d="M9.75 16C9.75 14.067 11.317 12.5 13.25 12.5H17.25C19.183 12.5 20.75 14.067 20.75 16C20.75 17.933 19.183 19.5 17.25 19.5H13.25C11.317 19.5 9.75 17.933 9.75 16Z"
          fill="#FCFCFC"
        />
        <path
          d="M14.6478 13.5742C14.6478 13.2009 15.0956 13.0185 15.3528 13.2839L17.6669 15.598C17.8328 15.7639 17.8328 16.021 17.6669 16.1869L15.3528 18.501C15.0956 18.7581 14.6478 18.5756 14.6478 18.2024V16.7177H12.1595C11.7033 16.7177 11.33 16.3445 11.33 15.8883C11.33 15.4321 11.7033 15.0589 12.1595 15.0589H14.6478V13.5742Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6997_62261">
          <rect width="19.5" height="19.5" fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InvitationIcon;
