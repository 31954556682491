import { useIntl } from 'react-intl';
//hooks
import { useHover } from 'hooks/useHover';
//types
import { ProfileBasicInformationItemProps } from '../types';
//components
import ProfileBasicAdaptivePopover from './ProfileBasicAdaptivePopover';

const ProfileBasicInformationItem = ({ label, children, popoverContent, classNameContainer = '' }: ProfileBasicInformationItemProps) => {
  const intl = useIntl();
  const { hovered, isHovered, unHovered } = useHover();

  return (
    <div onMouseEnter={hovered} onMouseLeave={unHovered} className={`flex items-center relative h-fit ${classNameContainer}`}>
      {children}
      <span className="text-specialGray-075 ml-1 lowercase">{intl.formatMessage(label)}</span>

      {popoverContent && isHovered && (
        <ProfileBasicAdaptivePopover>
          <span className="whitespace-pre-wrap">{popoverContent.trim()}</span>
        </ProfileBasicAdaptivePopover>
      )}
    </div>
  );
};

export default ProfileBasicInformationItem;
