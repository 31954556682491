import { FC, useRef } from 'react';
import { useIntl } from 'react-intl';
// components
import CustomButton from 'system/Buttons/CustomButton';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
//hooks
import useOnClickOutside from 'hooks/useOutsideAlerter';
// models
import { FilterComponentProps } from 'models/modelsOfComponents';

const FilterSliderWrapper: FC<FilterComponentProps> = ({ isOpenListOfFilters, filterButtonEvent, children }) => {
  const intl = useIntl();
  const filterContainerRef = useRef<HTMLDivElement>(null);

  const closeOutsideClickFilter = () => {
    if (isOpenListOfFilters) {
      filterButtonEvent();
    }
  };

  useOnClickOutside(filterContainerRef, closeOutsideClickFilter);

  return (
    <div
      ref={filterContainerRef}
      className={`fixed ${
        isOpenListOfFilters ? 'w-full sm:w-96' : 'w-0'
      } right-0 top-0 bg-white h-full transition-all duration-2000 overflow-y-auto sm:max-w-sm pt-10 sm:pt-20 shadow-xl z-10`}
    >
      <div className="flex">
        <CustomButton
          onClick={filterButtonEvent}
          label={{ id: 'label_filter' }}
          classNameStyle="justify-center w-28 items-center px-3 py-3.5 text-2xl leading-4 font-medium rounded-xl text-darkBlue bg-white focus:outline-none mr-1"
        >
          <ChevronRightIcon className="w-4 h-5 mr-2" />
        </CustomButton>
      </div>
      {children}
      <div className="px-4 sm:px-8 sticky bottom-0 left-0 block sm:hidden py-6 bg-white w-full z-[1]">
        <button className="w-full bg-lightBlue py-3.5 text-center text-blue rounded-[14px]" onClick={filterButtonEvent}>
          {intl.formatMessage({ id: 'label_apply_filter' })}
        </button>
      </div>
    </div>
  );
};

export default FilterSliderWrapper;
