// packages
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
// generated
import { VacancyFragments$data } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
// components
import ComponentWithPopover from 'system/ComponentWithPopover';
import InfoField from 'system/JobOpeningComponents/components/InfoField';
import SalaryRangeComponent from 'system/JobOpeningComponents/SalaryRangeComponent';
// mocks
import { DURATION_LIST_ENUMS, INDUSTRY_LIST_ENUMS, STARTING_CONDITION_ENUMS } from 'models/enums';
import { MOCK_DURATION_OF_POSITION, MOCK_INDUSTRY_LIST, MOCK_STARTING_CONDITION } from 'mocks/mockData';
// routing
import { RoutesPathNames } from 'app/routing';

const MainInformation = ({ vacancy, withDuties }: { vacancy: VacancyFragments$data; withDuties?: boolean }) => {
  const intl = useIntl();
  const location = useLocation();

  const [durationOfPosition, setDurationOfPosition] = useState<string>('');
  const [industry, setIndustry] = useState<string>('');
  const [condition, setCondition] = useState<string | null>(null);
  const isEditPage = location.pathname.includes(RoutesPathNames.edit);

  useEffect(() => {
    if (vacancy?.duration) {
      setDurationOfPosition(MOCK_DURATION_OF_POSITION.find(({ id }) => id === (vacancy?.duration as DURATION_LIST_ENUMS))?.title as string);
    }

    if (vacancy?.industry) {
      setIndustry(MOCK_INDUSTRY_LIST.find(({ id }) => id === (vacancy?.industry as INDUSTRY_LIST_ENUMS))?.title as string);
    }

    if (vacancy?.startingCondition) {
      setCondition(MOCK_STARTING_CONDITION.find(({ id }) => id === (vacancy?.startingCondition as STARTING_CONDITION_ENUMS))?.name as string);
    }
  }, [vacancy?.duration, vacancy?.industry, vacancy?.startingCondition]);

  return (
    <div className="flex flex-col">
      <InfoField title={{ id: 'hire_job_description_posting_date' }}>{vacancy?.createdAt && format(new Date(`${vacancy?.createdAt}`), 'd MMM Y')}</InfoField>

      {withDuties ? (
        <InfoField title={{ id: 'hire_job_description_positions' }}>
          {!isEmpty(vacancy?.duties) && vacancy?.duties?.map(duty => <ComponentWithPopover key={duty.id} id={duty.id} name={duty.name} />)}
        </InfoField>
      ) : null}

      {!!(vacancy?.salaryFrom || vacancy?.salaryTo) && <SalaryRangeComponent vacancy={vacancy} />}

      <InfoField title={{ id: 'hire_job_description_duration_of_position' }}>{durationOfPosition && intl.formatMessage({ id: durationOfPosition })}</InfoField>

      <InfoField title={{ id: 'hire_form_step_1_torn_weeks_on_label' }}>{vacancy.tornWeeksOn}</InfoField>

      <InfoField title={{ id: 'hire_form_step_1_torn_weeks_off_label' }}>{vacancy.tornWeeksOff}</InfoField>

      <InfoField title={{ id: 'hire_job_description_industry' }}>{industry && intl.formatMessage({ id: industry })}</InfoField>

      <InfoField title={{ id: 'hire_vessel_type_label' }}>{vacancy.vesselKind?.name}</InfoField>

      <InfoField title={{ id: 'hire_location_label' }}>{vacancy?.hiringArea?.name}</InfoField>

      <InfoField title={{ id: 'hire_form_step_1_job_description_starting_condition_label' }}>
        {vacancy.startingCondition === STARTING_CONDITION_ENUMS.DATE && vacancy?.startingDate ? format(new Date(`${vacancy.startingDate}`), 'd MMM Y') : condition}
      </InfoField>

      <InfoField title={{ id: 'hire_manager_label' }}>
        {vacancy?.organizationMember?.profile?.info
          ? `${vacancy?.organizationMember?.profile.info.firstName} ${vacancy?.organizationMember?.profile.info.lastName}`
          : intl.formatMessage({ id: 'hire_organization_member_id' }, { value: vacancy?.organizationMember.id })}
      </InfoField>

      <InfoField title={{ id: 'service_form_vessel_operator_name' }}>
        {isEditPage ? (
          <span>{vacancy?.organization.name}</span>
        ) : (
          <Link className="hover:font-medium" to={`/${RoutesPathNames.orgInfo}/${vacancy.organization.id}`}>
            {vacancy?.organization.name}
          </Link>
        )}
      </InfoField>
    </div>
  );
};

export default MainInformation;
