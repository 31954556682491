// packages
import { GetRecoilValue, selector, SetRecoilState } from 'recoil';
// types
import { searchCounterType } from 'recoil/searchCounter/types';
// atoms
import { fetchNextOrganisationJobOpeningsAtom } from 'recoil/searchCounter/atoms/searchCountersAtom';

export const fetchNextOrganisationJobOpeningsSelector = selector({
  key: 'fetchNextOrganisationJobOpeningsSelector',
  get: ({ get }: { get: GetRecoilValue }): searchCounterType => {
    return get(fetchNextOrganisationJobOpeningsAtom);
  },
  set: ({ set, get }: { set: SetRecoilState; get: GetRecoilValue }, newValue) => {
    const count = get(fetchNextOrganisationJobOpeningsAtom).count + (newValue as searchCounterType).count;
    set(fetchNextOrganisationJobOpeningsAtom, { count });
  },
});
