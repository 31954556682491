import { useIntl } from 'react-intl';
//components
import CustomButton from 'system/Buttons/CustomButton';
//models
import { DropDownHeaderProps } from 'app/Navbar/types';

const DropDownHeader = ({ onClick, label, isShowSeeAllButton, counter }: DropDownHeaderProps) => {
  const intl = useIntl();

  return (
    <div className="flex justify-between h-[52px] items-center w-full px-2.5 border-b border-solid border-specialGray-012 md:text-xs 2xl:text-sm">
      <div className="flex sm:text-base text-xs">
        <span className="text-darkBlue uppercase mr-2 font-medium">{intl.formatMessage(label)}</span>
        <span className="text-specialGray-075">{counter}</span>
      </div>
      {isShowSeeAllButton ? (
        <CustomButton
          onClick={onClick}
          label={{ id: 'hire_job_description_right_side_button_label' }}
          classNameStyle="justify-center w-fit items-center text-sm uppercase leading-4 font-medium rounded-xl text-blue bg-white focus:outline-none mr-1"
        />
      ) : null}
    </div>
  );
};

export default DropDownHeader;
