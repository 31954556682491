// packages
import React, { ReactElement, useContext, useState } from 'react';
// models
import { JobOpeningFormStepType } from 'models/commonTypes';

interface JobOpeningFormContextInterface {
  isChange: boolean;
  nextStep: JobOpeningFormStepType | null;
  setIsChange: React.Dispatch<React.SetStateAction<boolean>>;
  setNextStep: React.Dispatch<React.SetStateAction<JobOpeningFormStepType | null>>;
}

const jobOpeningFormContext: JobOpeningFormContextInterface = {
  isChange: false,
  setIsChange: () => {},
  nextStep: null,
  setNextStep: () => {},
};

const JobOpeningFormCtx = React.createContext<JobOpeningFormContextInterface>(jobOpeningFormContext);

export function useJobOpeningFormContext() {
  return useContext(JobOpeningFormCtx);
}

const JobOpeningFormContext: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [isChange, setIsChange] = useState<boolean>(false);
  const [nextStep, setNextStep] = useState<JobOpeningFormStepType | null>(null);

  return <JobOpeningFormCtx.Provider value={{ isChange, setIsChange, nextStep, setNextStep }}>{children}</JobOpeningFormCtx.Provider>;
};

export default JobOpeningFormContext;
