/**
 * @generated SignedSource<<396e08c8b33a7302b261a16017ce9be9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ServiceQueriesDetailsVesselQuery$variables = {
  skip: boolean;
  vesselID: number;
};
export type ServiceQueriesDetailsVesselQuery$data = {
  readonly vessel?: {
    readonly cnISO: string | null;
    readonly deadWeight: number | null;
    readonly grossTonnage: number | null;
    readonly imageURL: string | null;
    readonly imoNumber: number | null;
    readonly kind: {
      readonly id: string;
      readonly name: string;
      readonly perma: string;
    } | null;
    readonly length: number | null;
    readonly manager: string | null;
    readonly name: string;
    readonly owner: string | null;
    readonly width: number | null;
  };
};
export type ServiceQueriesDetailsVesselQuery = {
  response: ServiceQueriesDetailsVesselQuery$data;
  variables: ServiceQueriesDetailsVesselQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'skip',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'vesselID',
    },
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v3 = [
      {
        condition: 'skip',
        kind: 'Condition',
        passingValue: false,
        selections: [
          {
            alias: null,
            args: [
              {
                kind: 'Variable',
                name: 'vesselID',
                variableName: 'vesselID',
              },
            ],
            concreteType: 'VesselDetails',
            kind: 'LinkedField',
            name: 'vessel',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'deadWeight',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'grossTonnage',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'imageURL',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'length',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'manager',
                storageKey: null,
              },
              v2 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'owner',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'width',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'imoNumber',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'VesselKind',
                kind: 'LinkedField',
                name: 'kind',
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'id',
                    storageKey: null,
                  },
                  v2 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'perma',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'cnISO',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'ServiceQueriesDetailsVesselQuery',
      selections: v3 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'ServiceQueriesDetailsVesselQuery',
      selections: v3 /*: any*/,
    },
    params: {
      cacheID: 'ee1760babce42752a1536c6d04bd4141',
      id: null,
      metadata: {},
      name: 'ServiceQueriesDetailsVesselQuery',
      operationKind: 'query',
      text: 'query ServiceQueriesDetailsVesselQuery(\n  $vesselID: Int!\n  $skip: Boolean!\n) {\n  vessel(vesselID: $vesselID) @skip(if: $skip) {\n    deadWeight\n    grossTonnage\n    imageURL\n    length\n    manager\n    name\n    owner\n    width\n    imoNumber\n    kind {\n      id\n      name\n      perma\n    }\n    cnISO\n  }\n}\n',
    },
  };
})();

(node as any).hash = '9bc227e43361c5ffb452b6b04dae632d';

export default node;
