// components
import { XMarkIcon } from '@heroicons/react/24/solid';
// types
import { ChoseElementProps } from 'models/modelsOfComponents';

const ChoseElement = ({ label, onClick, width }: ChoseElementProps) => {
  return (
    <div
      className="text-xs sm:text-sm whitespace-nowrap inline-flex items-center px-2 sm:px-3 rounded-lg capitalize bg-specialGray-01 text-specialGray-075 h-8 mr-2 mb-1 flex-grow-0 max-w-max"
      onClick={event => event.preventDefault()}
    >
      <span className={`max-w-fit ${width || 'w-28'} overflow-ellipsis overflow-hidden`}>{label}</span>
      <div className="w-4 h-4 bg-transparent cursor-pointer ml-1 sm:ml-2 flex justify-center items-center hover:text-white">
        <XMarkIcon className="w-3.5 h-3.5" onClick={onClick} />
      </div>
    </div>
  );
};

export default ChoseElement;
