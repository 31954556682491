// packages
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { useFragment, useLazyLoadQuery } from 'react-relay';
// hooks
import { useGetOrganizationSizeWithLabel } from 'hooks/useGetOrganizationSize';
// models
import { VACANCY_ORDER_FIELD, VACANCY_STATUS } from 'models/enums';
// components
import LineSvg from 'icons/LineSvg';
import JobOpeningItem from './components/JobOpeningItem';
import OrganizationInfoSizeIcon from 'icons/OrganizationInfoSizeIcon';
import CommercialAvatar from 'app/Avatars/CommercialAvatar/CommercialAvatar';
// schemas
import { LIST_OF_VACANCIES } from 'schemas/vacancy/VacancyQueries';
import { VACANCY_FRAGMENT } from 'schemas/vacancy/VacancyFragments';
// generated
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { VacancyQueriesListOfVacanciesQuery } from 'schemas/vacancy/__generated__/VacancyQueriesListOfVacanciesQuery.graphql';
// routes
import { RoutesPathNames } from 'app/routing';

const JobOrganizationInfo = ({ vacancyFragment }: { vacancyFragment: VacancyFragments$key }) => {
  const intl = useIntl();
  const { id } = useParams();

  const vacancyData = useFragment<VacancyFragments$key>(VACANCY_FRAGMENT, vacancyFragment!);
  const [getFilteringListOfVacancies, setFilteringListOfVacancies] = useState<Array<{ node: VacancyFragments$key; cursor: string }>>([]);

  const listOfVacancies = useLazyLoadQuery<VacancyQueriesListOfVacanciesQuery>(LIST_OF_VACANCIES, {
    orgId: vacancyData.organization.id,
    skip: !vacancyData.organization.id,
    filters: [{ field: VACANCY_ORDER_FIELD.STATUS, values: [VACANCY_STATUS.OPEN] }],
  });

  useEffect(() => {
    setFilteringListOfVacancies(
      () => listOfVacancies?.vacancies?.edges?.filter(vacancy => vacancy?.node?.id !== id) as unknown as Array<{ node: VacancyFragments$key; cursor: string }>,
    );
  }, [id, listOfVacancies]);

  const { selectedOrganizationSize } = useGetOrganizationSizeWithLabel(vacancyData.organization.size);

  return (
    <section className="flex flex-col border border-1 border-specialGray-012 rounded-2xl xl:w-2/5 lg:w-1/2 w-full space-y-4 h-fit pb-5">
      <section className="flex flex-col pt-4 px-6">
        <Link to={`/${RoutesPathNames.orgInfo}/${vacancyData.organization.id}`} className="flex space-x-6 items-center">
          <CommercialAvatar size="w-14 h-14" avatarUrl={vacancyData.organization.avatarUrl} />
          <span className="text-darkBlue text-lg font-medium">{vacancyData.organization.name}</span>
        </Link>
        <div className="flex mt-3">
          {selectedOrganizationSize?.name ? (
            <div className="flex items-center text-sm text-specialGray-075 space-x-1.5">
              <OrganizationInfoSizeIcon />
              <span>{selectedOrganizationSize?.name}</span>
            </div>
          ) : null}
        </div>
      </section>
      {vacancyData.organization.description ? (
        <>
          <LineSvg />
          <section className="flex flex-col px-6 space-y-3">
            <span className="uppercase text-darkBlue text-xs">{intl.formatMessage({ id: 'about_label' })}</span>
            <p className="text-sm text-specialGray-075 leading-6 whitespace-pre-wrap">{vacancyData.organization.description}</p>
          </section>
        </>
      ) : null}
      {!isEmpty(getFilteringListOfVacancies) ? (
        <>
          <LineSvg />
          <section className="flex flex-col space-y-3">
            <div className="flex items-center space-x-3 px-6">
              <span className="uppercase text-darkBlue text-xs">{intl.formatMessage({ id: 'other_job_openings' })}</span>
              <span className="text-specialGray-075 text-sm">{getFilteringListOfVacancies.length < 3 ? getFilteringListOfVacancies.length : 3}</span>
            </div>
            <div className="flex flex-col">
              {getFilteringListOfVacancies?.map((vacancy, index) =>
                index < 3 ? <JobOpeningItem key={`${vacancy?.cursor}${index}`} vacancy={vacancy?.node as VacancyFragments$key} /> : null,
              )}
              {getFilteringListOfVacancies.length > 3 ? (
                <Link to={`/${RoutesPathNames.orgInfo}/${vacancyData.organization.id}`} className="flex w-fit items-center text-blue text-sm font-medium self-center mt-2">
                  {intl.formatMessage({ id: 'hire_job_description_right_side_button_label' })}
                </Link>
              ) : null}
            </div>
          </section>
        </>
      ) : null}
    </section>
  );
};

export default JobOrganizationInfo;
