/**
 * @generated SignedSource<<3488654c9136c0cc6ec6f365b8253bcd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ResumeMutation$variables = {
  pid: string;
  previewID?: string | null;
};
export type ResumeMutation$data = {
  readonly generateResumeBackground: {
    readonly uuid: string;
  };
};
export type ResumeMutation = {
  response: ResumeMutation$data;
  variables: ResumeMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'pid',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'previewID',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'pid',
        variableName: 'pid',
      },
      {
        kind: 'Variable',
        name: 'previewID',
        variableName: 'previewID',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'uuid',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ResumeMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'BackgroundTask',
          kind: 'LinkedField',
          name: 'generateResumeBackground',
          plural: false,
          selections: [v2 /*: any*/],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ResumeMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'BackgroundTask',
          kind: 'LinkedField',
          name: 'generateResumeBackground',
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'id',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '64e1b5b66a31d0e1edb99b1fe1f6dfa4',
      id: null,
      metadata: {},
      name: 'ResumeMutation',
      operationKind: 'mutation',
      text: 'mutation ResumeMutation(\n  $pid: ID!\n  $previewID: String\n) {\n  generateResumeBackground(pid: $pid, previewID: $previewID) {\n    uuid\n    id\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'e92680c2b592b646b34f37492b22fa17';

export default node;
