import graphql from 'babel-plugin-relay/macro';

export const CREATE_DOCUMENT = graphql`
  mutation DocumentMutationsCreateDocumentMutation($data: DocumentData!) {
    createDocument(data: $data) {
      node {
        id
        urls
        xlarge_urls
      }
    }
  }
`;

export const UPDATE_DOCUMENT = graphql`
  mutation DocumentMutationsUpdateDocumentMutation($id: ID!, $data: DocumentData!) {
    updateDocument(id: $id, data: $data) {
      id
      urls
      xlarge_urls
    }
  }
`;

export const DELETE_DOCUMENT = graphql`
  mutation DocumentMutationsDeleteDocumentMutation($id: ID!) {
    deleteDocument(id: $id) {
      id
    }
  }
`;
