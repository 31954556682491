// packages
import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { FormikProvider, Form, useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { Path } from 'history';
// components
import { Button } from 'system/Button';
import { InputGroup } from 'system/InputGroups/InputGroup';
import AlertForError from 'system/Alert/AlertForError';
import { PinCodev2 } from 'system/PinCodeComponent/PinCodev2';
import { ConfirmFormValidateSchema } from 'formHelpers/validationsOfForms';
import { ConfirmFormInitialValues } from 'formHelpers/initialValuesOfForms';
// models
import { ConfirmFormData, ConfirmFormProps } from 'models/modelsOfComponents';
// hooks
import { useAuth } from 'authentication';

// ConfirmForm implements a form that can be used for new user account confirmation
export const ConfirmForm: React.FC<ConfirmFormProps> = ({ onAccountConfirmed, hire, emailByDefault, code, defaultError, defaultSubmitting = false }) => {
  const [error, setError] = useState<Error | undefined>(defaultError);
  const { state } = useLocation();
  const [isHireCrewPage] = useState<boolean | undefined>(hire);

  const { email, password, setEmail } = useAuth();

  useEffect(() => {
    if (setEmail && emailByDefault) {
      setEmail(emailByDefault);
    }
  }, [emailByDefault, setEmail]);

  const intl = useIntl();
  const formik = useFormik({
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: ConfirmFormInitialValues(email, code),
    validationSchema: ConfirmFormValidateSchema,
  });

  useEffect(() => {
    if (defaultSubmitting && !formik.isSubmitting) formik.setSubmitting(true);
  });

  async function onSubmit(d: ConfirmFormData) {
    let err: Error | undefined;
    try {
      await Auth.confirmSignUp(d.email, d.code, { clientMetadata: { 'custom:platform_group': isHireCrewPage || (state as { isHire: boolean })?.isHire ? 'hire' : 'crew' } });
      if (email && password) {
        await Auth.signIn(email, password);
        const currentUser = await Auth.currentAuthenticatedUser();

        window.analytics?.identify(currentUser.attributes?.['custom:profile_id'], {
          email: d.email,
          platformGroup: currentUser.attributes?.['custom:platform_group'],
        });

        window.analytics?.track('wui-signup-verification', {
          userId: currentUser.attributes?.['custom:profile_id'],
          platformGroup: currentUser.attributes?.['custom:platform_group'],
        });
      }
      err = undefined;
    } catch (e: unknown) {
      err = e as Error;
    }

    setError(err);
    formik.setSubmitting(false);
    onAccountConfirmed && !err && onAccountConfirmed(d);
  }

  async function resentConfirmationCode() {
    let err: Error | undefined;
    formik.setSubmitting(true);
    try {
      await Auth.resendSignUp(formik.values.email);
      err = undefined;
    } catch (err) {
      console.warn('error resending code: ', err);
    }

    setError(err);
    formik.setSubmitting(false);
  }

  return (
    <FormikProvider value={formik}>
      <Form className="space-y-6">
        {error && <AlertForError heading={{ id: 'confirm_failedConfirmOrResend' }} error={error} />}

        {!email && <InputGroup autoFocus type="email" name="email" label={{ id: 'default_auth_emailLabel' }} />}

        <p className="text-sm text-darkBlue">{intl.formatMessage({ id: 'register_info_meessage' })}</p>
        <p className="text-sm text-darkBlue">
          {intl.formatMessage({ id: 'register_info_meessage_spam' })}
          <span className="font-semibold">{intl.formatMessage({ id: 'register_info_meessage_spam_folder' })}</span>
          {intl.formatMessage({ id: 'register_info_meessage_spam_immediately' })}
        </p>

        <PinCodev2 name="code" maxLength={6} label={{ id: 'default_auth_confirmCodeLabel' }} skipQuery inputType="string" />

        <div className="space-y-3">
          <Button type="button" buttonType={'secondary'} label={{ id: 'confirm_resendCta' }} onClick={resentConfirmationCode} fullSize disabled={formik.isSubmitting} />
          <Button type="submit" label={{ id: 'confirm_confirmCta' }} fullSize disabled={formik.isSubmitting} />
          <Link className="flex w-full block text-blue justify-center" to={-1 as string | Partial<Path>}>
            {intl.formatMessage({ id: 'back' })}
          </Link>
        </div>
      </Form>
    </FormikProvider>
  );
};
