// components
import { AuthSection } from 'auth/AuthSection';

const CypressWrapper = () => {
  if (typeof Cypress !== 'undefined' && Cypress.env('CYPRESS_ENV') === 'test') {
    return <AuthSection />;
  }
  return null;
};

export default CypressWrapper;
