// packages
import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useRecoilState, useRecoilValue } from 'recoil';
// recoil
import { fetchDashboardVacanciesByParamsAtom } from 'recoil/Organisation/Dashboard/atoms/fetchDashboardVacanciesByParamsAtom';
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';
import { dashboardOpenPaginationAtom, dashboardShowAllPaginationAtom } from 'recoil/Organisation/Dashboard/atoms/dashboardPaginationAtom';
// generated
import { VacancyQueriesListOfVacanciesForDashboardQuery } from 'schemas/vacancy/__generated__/VacancyQueriesListOfVacanciesForDashboardQuery.graphql';
// schemas
import { LIST_OF_VACANCIES_FOR_DASHBOARD } from 'schemas/vacancy/VacancyQueries';
// enums
import { ORDER_DIRECTION, VACANCY_ORDER_FIELD, VACANCY_STATUS } from 'models/enums';
// types
import { dashboardPaginationType } from 'recoil/Organisation/types';
// helpers
import { vacanciesSearchParams } from 'utils/filterHelpers';

export const useGettingVacanciesWithPagination = () => {
  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);

  const [oldEndCourse, setOldEndCourse] = useState<string>('');

  const [searchParams] = useSearchParams();

  const getFieldSortParams = useMemo(() => {
    return vacanciesSearchParams(searchParams);
  }, [searchParams]);

  const handleSetDefaultVacancyOrder = () =>
    getFetchDashboardVacanciesByParams.filterByStatus === VACANCY_STATUS.OPEN ? VACANCY_ORDER_FIELD.LAST_INVITED_AT : VACANCY_ORDER_FIELD.CREATED_AT;

  const getFetchDashboardVacanciesByParams = useRecoilValue(fetchDashboardVacanciesByParamsAtom);

  const [getDashboardOpenTabPagination, setDashboardOpenTabPagination] = useRecoilState(dashboardOpenPaginationAtom);
  const [getDashboardShowAllTabPagination, setDashboardShowAllTabPagination] = useRecoilState(dashboardShowAllPaginationAtom);

  const isOpenTab = getFetchDashboardVacanciesByParams.filterByStatus === VACANCY_STATUS.OPEN;
  const filterValues = isOpenTab ? [VACANCY_STATUS.OPEN] : [VACANCY_STATUS.OPEN, VACANCY_STATUS.CLOSED];

  const listOfVacanciesForCurrentTab = useLazyLoadQuery<VacancyQueriesListOfVacanciesForDashboardQuery>(LIST_OF_VACANCIES_FOR_DASHBOARD, {
    orgId: getCurrentOrganisation.organisationId as string,
    skip: !getCurrentOrganisation.isSelected,
    filters: [{ field: VACANCY_ORDER_FIELD.STATUS, values: filterValues }],
    orderBy: {
      field: getFieldSortParams.fieldName || handleSetDefaultVacancyOrder(),
      direction: getFieldSortParams.direction || ORDER_DIRECTION.DESC,
    },
    first: isOpenTab ? getDashboardOpenTabPagination.numberOfFetch : getDashboardShowAllTabPagination.numberOfFetch,
  }).vacancies;

  useEffect(() => {
    setOldEndCourse(listOfVacanciesForCurrentTab?.pageInfo.endCursor as string);
  }, [listOfVacanciesForCurrentTab?.pageInfo.endCursor]);

  useEffect(() => {
    if (isOpenTab && listOfVacanciesForCurrentTab?.pageInfo.endCursor !== oldEndCourse) {
      setDashboardOpenTabPagination((prevState: dashboardPaginationType) => ({
        ...prevState,
        isLoading: false,
        hasNextPage: listOfVacanciesForCurrentTab?.pageInfo.hasNextPage ?? false,
      }));
    }
    if (!isOpenTab && listOfVacanciesForCurrentTab?.pageInfo.endCursor !== oldEndCourse) {
      setDashboardShowAllTabPagination((prevState: dashboardPaginationType) => ({
        ...prevState,
        isLoading: false,
        hasNextPage: listOfVacanciesForCurrentTab?.pageInfo.hasNextPage ?? false,
      }));
    }
  }, [oldEndCourse, listOfVacanciesForCurrentTab?.pageInfo, setDashboardOpenTabPagination, isOpenTab, setDashboardShowAllTabPagination]);

  if (isOpenTab) {
    return listOfVacanciesForCurrentTab?.edges;
  } else {
    const arrayOfSortedClosedAndOpenedVacancies = listOfVacanciesForCurrentTab?.edges ? [...listOfVacanciesForCurrentTab.edges] : [];
    return arrayOfSortedClosedAndOpenedVacancies.sort((a, b) => {
      const aStatus = a?.node?.status;
      const bStatus = b?.node?.status;
      if (aStatus === VACANCY_STATUS.OPEN && bStatus === VACANCY_STATUS.CLOSED) {
        return -1;
      } else {
        return 0;
      }
    });
  }
};
