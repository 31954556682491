// packages
import { Suspense, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// routes
import { RoutePaths } from 'app/routing';
// components
import PrivateRoutes from 'app/PrivateRoutes/PrivateRoutes';
import { Profile as ProfileEmployer } from 'app/ProfileEmployer/Profile';
import ProfileHireSkeleton from 'system/skeletons/hireProfile/ProfileHireSkeleton';
// models
import { PLATFORM_GROUP } from 'models/enums';
// hooks
import { useAuth } from 'authentication';

const OrganisationView = ({ accountTypes }: { accountTypes: Array<PLATFORM_GROUP> }) => {
  const { identity } = useAuth();
  const { pathname, search } = useLocation();

  const isAllowedAccount = useMemo(() => identity && accountTypes.includes(identity?.platformGroup as PLATFORM_GROUP), [accountTypes, identity]);

  if (!isAllowedAccount && !!identity?.profileId) {
    return <Navigate to={{ pathname: RoutePaths.ProfileBase }} state={{ previousPathname: pathname, search }} replace />;
  }

  return (
    <PrivateRoutes redirectPath={RoutePaths.ProfileBase}>
      <>
        <Suspense fallback={<ProfileHireSkeleton />}>
          {/*For first hire profile create it will be without profile organization
                    for prevent redirect we need withoutOrganizationRedirect props.
                    Let the hire account fill own organization*/}
          <ProfileEmployer />
        </Suspense>
      </>
    </PrivateRoutes>
  );
};

export default OrganisationView;
