//packages
import { useLocation } from 'react-router-dom';
// components
import ShareIcon from 'icons/ShareIcon';
import { PencilEditIcon } from 'icons/PencilEditIcon';
import CustomButton from 'system/Buttons/CustomButton';
import HighlightElementWrapper from 'system/wrappers/HighlightElementWrapper';
// types
import { ProfileBasicInfoControlButtonsProps } from '../types';
// models
import { typeProfileRatingState } from 'models/routeLocationState';

const ProfileBasicInfoControlButtons = ({ profileInfoData, handleOpenBasicInfoForm, handleOpenCVModal }: ProfileBasicInfoControlButtonsProps) => {
  const { state } = useLocation();

  return (
    <>
      {profileInfoData && (
        <>
          <HighlightElementWrapper
            enableFlickering={(state as typeProfileRatingState)?.profileShare || (state as typeProfileRatingState)?.downloadCV}
            addPadding="outline-offset-4 block sm:flex"
          >
            <CustomButton onClick={handleOpenCVModal} className="flex h-10 w-11 rounded-[10px] sm:mr-5 items-center justify-center bg-lightBlue [&>svg]:text-blue [&>svg]:text-xl">
              <ShareIcon />
            </CustomButton>
          </HighlightElementWrapper>
        </>
      )}
      <div className="hidden sm:block">
        <CustomButton
          dataTestId="setup-profile"
          onClick={handleOpenBasicInfoForm}
          label={!profileInfoData ? { id: 'profile_setupBasicCta' } : undefined}
          className={`flex h-10 ${
            profileInfoData ? 'min-w-[44px]' : 'w-fit px-2 [&>span]:ml-2 text-sm text-specialGray-05'
          } rounded-[10px] items-center justify-center bg-white border border-specialGray-05 sm:mr-5`}
        >
          <PencilEditIcon />
        </CustomButton>
      </div>
      <div className="sm:hidden block">
        <CustomButton
          onClick={handleOpenBasicInfoForm}
          className="flex h-10 w-fit px-2 [&>span]:ml-2 text-sm text-specialGray-05 rounded-[10px] items-center justify-center bg-white border border-specialGray-05"
        >
          <PencilEditIcon />
        </CustomButton>
      </div>
    </>
  );
};

export default ProfileBasicInfoControlButtons;
