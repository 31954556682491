/**
 * @generated SignedSource<<d69be0d97ee70dc026d3db546d62b232>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OrganizationMemberRole = 'admin' | 'invited' | 'member' | 'owner' | '%future added value';
export type OrganizationMemberData = {
  organizationId: string;
  profileId: string;
  role?: string | null;
};
export type ProfileMutationsCreateOrganizationMemberMutation$variables = {
  data: OrganizationMemberData;
};
export type ProfileMutationsCreateOrganizationMemberMutation$data = {
  readonly createOrganizationMember: {
    readonly node: {
      readonly id: string;
      readonly organization: {
        readonly avatarUrl: string | null;
        readonly id: string;
        readonly name: string;
      };
      readonly profile: {
        readonly certificateInfos: ReadonlyArray<{
          readonly id: string;
        }> | null;
        readonly educations: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
        }> | null;
        readonly id: string;
        readonly info: {
          readonly avatarUrl: string | null;
          readonly birthDate: String;
          readonly country: {
            readonly isoCode: string;
            readonly name: string;
          };
          readonly firstName: string;
          readonly lastName: string;
          readonly primaryDuty: {
            readonly name: string;
          } | null;
        } | null;
        readonly seaServices: ReadonlyArray<{
          readonly vessel: {
            readonly imo: number;
          };
        }> | null;
      };
      readonly role: OrganizationMemberRole;
    } | null;
  };
};
export type ProfileMutationsCreateOrganizationMemberMutation = {
  response: ProfileMutationsCreateOrganizationMemberMutation$data;
  variables: ProfileMutationsCreateOrganizationMemberMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'data',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'data',
        variableName: 'data',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      concreteType: 'Education',
      kind: 'LinkedField',
      name: 'educations',
      plural: true,
      selections: [v2 /*: any*/, v3 /*: any*/],
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'imo',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      concreteType: 'CertificateInfo',
      kind: 'LinkedField',
      name: 'certificateInfos',
      plural: true,
      selections: [v2 /*: any*/],
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isoCode',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'birthDate',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'avatarUrl',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'firstName',
      storageKey: null,
    },
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'lastName',
      storageKey: null,
    },
    v12 = {
      alias: null,
      args: null,
      concreteType: 'Organization',
      kind: 'LinkedField',
      name: 'organization',
      plural: false,
      selections: [v2 /*: any*/, v9 /*: any*/, v3 /*: any*/],
      storageKey: null,
    },
    v13 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'role',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileMutationsCreateOrganizationMemberMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'OrganizationMemberEdge',
          kind: 'LinkedField',
          name: 'createOrganizationMember',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'OrganizationMember',
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                v2 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'Profile',
                  kind: 'LinkedField',
                  name: 'profile',
                  plural: false,
                  selections: [
                    v2 /*: any*/,
                    v4 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'SeaService',
                      kind: 'LinkedField',
                      name: 'seaServices',
                      plural: true,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Vessel',
                          kind: 'LinkedField',
                          name: 'vessel',
                          plural: false,
                          selections: [v5 /*: any*/],
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                    v6 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'ProfileInfo',
                      kind: 'LinkedField',
                      name: 'info',
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Duty',
                          kind: 'LinkedField',
                          name: 'primaryDuty',
                          plural: false,
                          selections: [v3 /*: any*/],
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Country',
                          kind: 'LinkedField',
                          name: 'country',
                          plural: false,
                          selections: [v3 /*: any*/, v7 /*: any*/],
                          storageKey: null,
                        },
                        v8 /*: any*/,
                        v9 /*: any*/,
                        v10 /*: any*/,
                        v11 /*: any*/,
                      ],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                v12 /*: any*/,
                v13 /*: any*/,
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileMutationsCreateOrganizationMemberMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'OrganizationMemberEdge',
          kind: 'LinkedField',
          name: 'createOrganizationMember',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'OrganizationMember',
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                v2 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'Profile',
                  kind: 'LinkedField',
                  name: 'profile',
                  plural: false,
                  selections: [
                    v2 /*: any*/,
                    v4 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'SeaService',
                      kind: 'LinkedField',
                      name: 'seaServices',
                      plural: true,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Vessel',
                          kind: 'LinkedField',
                          name: 'vessel',
                          plural: false,
                          selections: [v5 /*: any*/, v2 /*: any*/],
                          storageKey: null,
                        },
                        v2 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    v6 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'ProfileInfo',
                      kind: 'LinkedField',
                      name: 'info',
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Duty',
                          kind: 'LinkedField',
                          name: 'primaryDuty',
                          plural: false,
                          selections: [v3 /*: any*/, v2 /*: any*/],
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Country',
                          kind: 'LinkedField',
                          name: 'country',
                          plural: false,
                          selections: [v3 /*: any*/, v7 /*: any*/, v2 /*: any*/],
                          storageKey: null,
                        },
                        v8 /*: any*/,
                        v9 /*: any*/,
                        v10 /*: any*/,
                        v11 /*: any*/,
                        v2 /*: any*/,
                      ],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                v12 /*: any*/,
                v13 /*: any*/,
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: 'a1142a3f2ea5b80ca5b46af68050d831',
      id: null,
      metadata: {},
      name: 'ProfileMutationsCreateOrganizationMemberMutation',
      operationKind: 'mutation',
      text: 'mutation ProfileMutationsCreateOrganizationMemberMutation(\n  $data: OrganizationMemberData!\n) {\n  createOrganizationMember(data: $data) {\n    node {\n      id\n      profile {\n        id\n        educations {\n          id\n          name\n        }\n        seaServices {\n          vessel {\n            imo\n            id\n          }\n          id\n        }\n        certificateInfos {\n          id\n        }\n        info {\n          primaryDuty {\n            name\n            id\n          }\n          country {\n            name\n            isoCode\n            id\n          }\n          birthDate\n          avatarUrl\n          firstName\n          lastName\n          id\n        }\n      }\n      organization {\n        id\n        avatarUrl\n        name\n      }\n      role\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '598aab38d4750ecfc82e9af9f9f6c764';

export default node;
