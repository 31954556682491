// packages
import React, { useCallback, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Form, FormikProvider, useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyLoadQuery, useMutation } from 'react-relay';
// hooks
import { useToggleShowWindow } from 'hooks/useToggleShowModal';
// routing
import { RoutePaths } from 'app/routing';
import { ConfirmModalBody } from 'system/ConfirmModalBody';
import { Modal } from 'system/Modal';
// components
import Header from '../components/Header';
import BagsPurpleIcon from 'icons/BagsPurpleIcon';
import AlertForError from 'system/Alert/AlertForError';
import Footer from 'auth/SignUpSteps/components/Footer';
import LabelComponent from 'system/LabelComponent/LabelComponent';
import SelectDutyComponent from 'system/SelectDutyComponent/SelectDutyComponent';
// formHelpers
import { StepWithDutyValidateSchema } from 'formHelpers/validationsOfForms';
// models
import { PLATFORM_GROUP } from 'models/enums';
import { SignUpStepsState } from 'models/routeLocationState';
// generated
import { BasicInfoFormMutationsUpdateMutation } from 'schemas/forms/__generated__/BasicInfoFormMutationsUpdateMutation.graphql';
import { ServiceQueriesFormGetAllDutiesQuery } from 'schemas/services/__generated__/ServiceQueriesFormGetAllDutiesQuery.graphql';
// schemas
import { GET_ALL_DUTIES_QUERY } from 'schemas/services/ServiceQueries';
import { UPDATE_PROFILE_INFO_MUTATION } from 'schemas/forms/BasicInfoFormMutations';

const StepWithRank = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { toggleShowWindow, showWindow } = useToggleShowWindow();

  const [error, setError] = useState<Error | undefined>();
  const [isSelectedCheckbox, setIsSelectedCheckbox] = useState<boolean>(false);

  const allDuties = useLazyLoadQuery<ServiceQueriesFormGetAllDutiesQuery>(GET_ALL_DUTIES_QUERY, { skip: false });

  const [commitUpdate, updateLoading] = useMutation<BasicInfoFormMutationsUpdateMutation>(UPDATE_PROFILE_INFO_MUTATION);

  const formik = useFormik<{ dutyId: string }>({
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { dutyId: '' },
    validationSchema: StepWithDutyValidateSchema,
  });

  async function onSubmit(data: { dutyId: string }) {
    const getCurrentUser = await Auth.currentAuthenticatedUser();

    if (!data.dutyId && isSelectedCheckbox) {
      toggleShowWindow(true)();
      return;
    }

    commitUpdate({
      variables: {
        id: (state as SignUpStepsState).profileInfo.id,
        data: {
          ownerId: getCurrentUser.attributes?.['custom:profile_id'],
          firstName: (state as SignUpStepsState).profileInfo.firstName,
          lastName: (state as SignUpStepsState).profileInfo.lastName,
          birthDate: (state as SignUpStepsState).profileInfo.birthDate,
          countryId: (state as SignUpStepsState).countryId,
          primaryDutyId: data.dutyId,
        },
      },
      onCompleted: () => {
        window.analytics?.track('wui-signup-step-with-enter-rank', {
          primaryDutyId: data.dutyId,
        });
        navigate(RoutePaths.AuthRegisterStepSeven, { state: { ...state, primaryDutyId: data.dutyId } });
      },
      onError: (error: Error) => {
        setError(error);
        formik.setSubmitting(false);
      },
    });
  }

  const handleChangeCheckbox = useCallback(() => {
    setIsSelectedCheckbox(prevState => !prevState);
    formik.resetForm({});
  }, [formik]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' || e.key === 'NumpadEnter') {
        handleChangeCheckbox();
      }
    },
    [handleChangeCheckbox],
  );

  const handleConfirmation = useCallback(() => {
    window.analytics?.track('wui-signup-step-with-enter-rank', {
      notWorkExperience: isSelectedCheckbox,
      type_of_account: PLATFORM_GROUP.CREW,
    });

    navigate(RoutePaths.HomePath);
  }, [isSelectedCheckbox, navigate]);

  return (
    <>
      <Modal onClose={toggleShowWindow(false)} show={showWindow} tinyModal>
        <ConfirmModalBody
          title={{ id: 'enter_rank_confirm_title' }}
          textOne={{ id: 'enter_rank_confirm_description' }}
          onClick={handleConfirmation}
          onCancel={toggleShowWindow(false)}
          buttonLabel={{ id: 'i_got_it' }}
          typeButtonConfirm="primary"
        />
      </Modal>
      <section className="flex w-full flex-col h-full sm:h-auto pt-5 pb-7">
        <Header title={{ id: 'enter_rank_title' }} description={{ id: 'enter_rank_description' }} icon={<BagsPurpleIcon />} />
        <FormikProvider value={formik}>
          <Form className="flex flex-col mt-7 sm:mt-8 w-full sm:py-8 px-4 sm:border sm:border-specialGray-012 sm:px-10 rounded-[20px] space-y-[18px] h-full justify-between sm:justify-start">
            <fieldset className="[&>input]:sm:h-[34px] [&_input]:h-12 [&_input]:text-sm">
              {error && <AlertForError heading={{ id: 'register_failedAlert' }} error={error} />}
              <SelectDutyComponent
                dataOfDuty={null}
                allDuties={allDuties}
                nameField="dutyId"
                autoFocus
                label="enter_rank_select_title"
                placeholder="enter_rank_select_placeholder"
                isResetForm={isSelectedCheckbox}
                disabled={isSelectedCheckbox}
              />
              <div className="flex w-auto items-center">
                <div className="flex items-center h-5">
                  <input
                    onKeyDown={handleKeyPress}
                    onChange={handleChangeCheckbox}
                    checked={isSelectedCheckbox}
                    id="enter_rank_checkbox_title"
                    type="checkbox"
                    className="focus:border-blue last-of-type:h-4 w-4 text-blue border-bluegit rounded cursor-pointer"
                    data-test-id="enter_rank_checkbox_title"
                  />
                </div>

                <div className="ml-2 sm:ml-3 text-sm mr-2.5">
                  <LabelComponent id="enter_rank_checkbox_title" label={{ id: 'enter_rank_checkbox_title' }} classes="text-blue text-xs sm:text-sm font-normal cursor-pointer" />
                </div>
              </div>
            </fieldset>
            <Footer error={error} pathnameToPrevStep={RoutePaths.AuthRegisterStepFive} isDisabled={(!formik.values.dutyId || updateLoading) && !isSelectedCheckbox} />
          </Form>
        </FormikProvider>
      </section>
    </>
  );
};

export default StepWithRank;
