// packages
import { isEmpty } from 'lodash';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';
// hooks
import { useHover } from 'hooks/useHover';
// components
import DefaultProfileAvatar from 'icons/DefaultProfileAvatar';
// generated
import { VacancyFragmentsForDashboardFragment$data } from 'schemas/vacancy/__generated__/VacancyFragmentsForDashboardFragment.graphql';

const AppliedCrewPortraits = ({ listOfAppliedCrew }: { listOfAppliedCrew: VacancyFragmentsForDashboardFragment$data['applications'] }) => {
  const intl = useIntl();
  const { hovered, isHovered, unHovered } = useHover();

  return !isEmpty(listOfAppliedCrew) ? (
    <div className="flex -space-x-1 justify-center" onMouseOver={hovered} onMouseLeave={unHovered}>
      {listOfAppliedCrew?.map((application, index) => (
        <Fragment key={application.id}>
          {index < 3 ? (
            <>
              {application.owner?.info?.avatarUrl ? (
                <img
                  className={`inline-block h-6 w-6 rounded-full ring-2 ring-white ${isHovered ? 'drop-shadow-md' : ''}`}
                  src={application.owner.info.avatarUrl}
                  alt={`${application.owner.info.firstName} ${application.owner.info.lastName}`}
                />
              ) : (
                <div className={`h-6 w-6 rounded-full bg-avatar-bg ring-2 ring-white ${isHovered ? 'drop-shadow-md' : ''}`}>
                  <DefaultProfileAvatar />
                </div>
              )}
            </>
          ) : null}
        </Fragment>
      ))}
      {listOfAppliedCrew && listOfAppliedCrew?.length > 3 ? (
        <div
          className={`flex h-6 w-6 rounded-full bg-avatar-bg ring-2 ring-white text-[10px] justify-center items-center font-medium text-specialGray-3 ${
            isHovered ? 'drop-shadow-md' : ''
          }`}
        >
          <span>{listOfAppliedCrew?.length - 3}</span>
        </div>
      ) : null}
    </div>
  ) : (
    <div className="flex items-center justify-center text-specialGray-03 text-sm">
      <span>{intl.formatMessage({ id: 'hire_job_applied_no_crew_title' })}</span>
    </div>
  );
};

export default AppliedCrewPortraits;
