import React, { Fragment, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
// models
import { IVacancyCertificate } from 'models/ICertificate';
// components
import { Button } from 'system/Button';
import ShieldIcon from 'icons/ShieldIcon';
import EmptyFieldItem from '../components/EmptyFieldItem';
// utils
import { sliceAltsIfLong } from 'utils';

const EssentialCertificates = ({ certificates }: { certificates: Array<IVacancyCertificate> }) => {
  const intl = useIntl();
  const { jobDescriptionEditId } = useParams();
  const [showAllCertificates, setShowAllCertificates] = useState<boolean>(false);
  const handleShowAllCertificates = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    setShowAllCertificates(prevState => {
      if (!prevState) {
        window.analytics?.track('wui-show-all-certificates-click', {
          vacancyId: jobDescriptionEditId,
        });
      }
      return !prevState;
    });
  };

  const renderCertificateItem = useCallback(
    (certificate: IVacancyCertificate) => (
      <div className="flex flex-col sm:flex-row gap-y-2 sm:items-center justify-between p-4 border-specialGray-012 w-full first:rounded-t-xl first:border-t border-t-0 last:rounded-b-xl border">
        <div className="flex items-center">
          <ShieldIcon kind={certificate?.kind?.name} />
          <span data-test-id={certificate?.name} className="text-sm text-specialGray-1 ml-4">
            {certificate?.name}
          </span>
        </div>
        {certificate?.alts && <div className="text-specialGray-03 text-xs sm:ml-auto">{sliceAltsIfLong(certificate?.alts as Array<string>, 30)}</div>}
      </div>
    ),
    [],
  );

  return (
    <div className="flex flex-col w-full">
      <h2 id="sea-service-title" className="flex-1 min-w-0 text-lg sm:text-2xl leading-6 font-semibold text-specialGray-1 mb-2">
        {intl.formatMessage({ id: 'hire_description_required_certificates_section' })}
      </h2>
      {!!certificates.length ? (
        <div className="flex flex-col w-full">
          {showAllCertificates
            ? certificates?.map(renderCertificateItem)
            : certificates?.map((certificate, index) => index < 3 && <Fragment key={certificate?.id}>{renderCertificateItem(certificate)}</Fragment>)}
          {certificates.length > 3 && (
            <Button
              type="button"
              buttonType="white-primary"
              label={{ id: showAllCertificates ? 'profile_slide_hide_elements' : 'hire_description_show_all_required_certificates' }}
              onClick={handleShowAllCertificates}
              additionalClasses="mx-auto mt-4"
            />
          )}
        </div>
      ) : (
        <EmptyFieldItem />
      )}
    </div>
  );
};

export default EssentialCertificates;
