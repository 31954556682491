//components
import ProfileProgressBarIcon1 from 'icons/profileProgressScale/profileProgressBarIcon1';
import ProfileProgressBarIcon2 from 'icons/profileProgressScale/profileProgressBarIcon2';
import ProfileProgressBarIcon3 from 'icons/profileProgressScale/profileProgressBarIcon3';
import ProfileProgressBarIcon6 from 'icons/profileProgressScale/profileProgressBarIcon6';
import ProfileProgressBarIcon4 from 'icons/profileProgressScale/profileProgressBarIcon4';
import ProfileProgressBarIcon5 from 'icons/profileProgressScale/profileProgressBarIcon5';

const ProfileProgressBarItem = ({ score }: { score: number }) => {
  if (100 <= score) {
    return <ProfileProgressBarIcon6 />;
  }
  if (70 <= score) {
    return <ProfileProgressBarIcon5 />;
  }
  if (50 <= score) {
    return <ProfileProgressBarIcon4 />;
  }
  if (30 <= score) {
    return <ProfileProgressBarIcon3 />;
  }
  if (score > 0 && 10 <= score) {
    return <ProfileProgressBarIcon2 />;
  }

  return <ProfileProgressBarIcon1 />;
};

export default ProfileProgressBarItem;
