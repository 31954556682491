import Skeleton from 'react-loading-skeleton';

const ItemMemberSkeleton = () => {
  return (
    <div className="border border-specialGray-01 rounded-2xl cursor-pointer w-full sm:w-[49%] min-h-[87px] flex h-full p-4 flex-row justify-between gap-x-4">
      <Skeleton width={55} height={55} borderRadius={6} />
      <div className="flex flex-col w-full justify-between">
        <Skeleton width={150} height={18} />
        <Skeleton width={100} height={14} />
      </div>
    </div>
  );
};

export default ItemMemberSkeleton;
