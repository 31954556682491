import { ConnectionHandler, RecordSourceSelectorProxy } from 'relay-runtime';
//schema
import { ProfileMutationsDeletePoolMutation$data } from 'schemas/profile/__generated__/ProfileMutationsDeletePoolMutation.graphql';
import { ProfileMutationsCreateNewPoolMutation$data } from 'schemas/profile/__generated__/ProfileMutationsCreateNewPoolMutation.graphql';
import { ProfileMutationsAddProfileToCrewPoolMutation$data } from 'schemas/profile/__generated__/ProfileMutationsAddProfileToCrewPoolMutation.graphql';
import { ProfileMutationsDeleteCrewFromPoolMutation$data } from 'schemas/profile/__generated__/ProfileMutationsDeleteCrewFromPoolMutation.graphql';

export const deleteCrewPoolUpdater = (orgId?: string | null) => (store: RecordSourceSelectorProxy<ProfileMutationsDeletePoolMutation$data>) => {
  const storyRecord = store.getRoot();
  const payloadId = store.getRootField('deleteCrewPool').getValue('id');
  const connection = ConnectionHandler.getConnection(storyRecord, 'CrewPoolsList_crewPools', { orgId });
  const connectionEdges = connection?.getLinkedRecords('edges');
  if (connectionEdges) {
    const filteredEdges = connectionEdges.filter(edge => {
      const edgeId = edge.getLinkedRecord('node')?.getValue('id');
      return edgeId !== payloadId;
    });
    connection?.setValue(filteredEdges.length, 'totalCount');
    connection?.setLinkedRecords(filteredEdges, 'edges');
  }
};

export const deleteUserFromCrewPoolUpdater = (userId: string, arrayOfPoolIDs: string[]) => (store: RecordSourceSelectorProxy<ProfileMutationsDeleteCrewFromPoolMutation$data>) => {
  const storyRecord = store.getRoot();
  const payloadPoolId = store.getRootField('removeCrewPoolProfiles').getValue('id');
  const connection = ConnectionHandler.getConnection(storyRecord, 'crewPoolProfilesList_crewPoolProfiles', {
    poolId: `${payloadPoolId}`,
  });
  const connectionEdges = connection?.getLinkedRecords('edges');
  if (connectionEdges && connection) {
    // set total added crews to separate pool
    const connectionTotalValue = connection?.getValue('totalCount');
    connection.setValue((connectionTotalValue as number) - 1, 'totalCount');

    const crewPoolStatsStoreData = store.get(`client:root:crewPoolStats(poolIds:[${arrayOfPoolIDs}])`)?.getLinkedRecords('stats');
    const filteredProfiles = connectionEdges.filter(edge => {
      const edgeNode = edge.getLinkedRecord('node');
      const profileNodeId = edgeNode?.getValue('id');

      // find deleted user and change crewPoolStats (counters for each pool on Crew pools tab) store data
      if (profileNodeId === userId) {
        const isAvailableUser = edgeNode?.getLinkedRecord('generalSetting')?.getValue('availableForWork');

        crewPoolStatsStoreData?.forEach(crewPoolStatsItem => {
          // find current opened pool
          if (payloadPoolId === crewPoolStatsItem.getValue('id')) {
            const totalCrewCount = crewPoolStatsItem.getValue('totalCrewCount');
            const availableCrewCount = crewPoolStatsItem.getValue('availableCrewCount');
            crewPoolStatsItem.setValue((totalCrewCount as number) - 1, 'totalCrewCount');
            crewPoolStatsItem.setValue(isAvailableUser ? (availableCrewCount as number) - 1 : availableCrewCount, 'availableCrewCount');
          }
        });
      }

      return profileNodeId !== userId;
    });

    const currentPoolStatsData = crewPoolStatsStoreData?.find(crewPoolStatsItem => crewPoolStatsItem.getValue('id') === payloadPoolId);

    if (filteredProfiles.length && currentPoolStatsData) {
      //get 3 last profiles avatars for crew pools component avatars preview
      const lastProfilesOfTheList: string[] = filteredProfiles.slice(0, 3).map(edge => {
        const avatarUrl = edge.getLinkedRecord('node')?.getLinkedRecord('info')?.getValue('avatarUrl');
        return (avatarUrl as string) || '';
      });
      currentPoolStatsData?.setValue(lastProfilesOfTheList, 'crewPoolAvatars');
    } else {
      currentPoolStatsData?.setValue(null, 'crewPoolAvatars');
    }

    connection?.setLinkedRecords(filteredProfiles, 'edges');
  }
};

export const crewNewCrewPoolUpdater = (orgId?: string) => (store: RecordSourceSelectorProxy<ProfileMutationsCreateNewPoolMutation$data>) => {
  const storyRecord = store.getRoot();
  const payload = store.getRootField('createCrewPool');

  const connection = ConnectionHandler.getConnection(storyRecord, 'CrewPoolsList_crewPools', { orgId });
  const connectionEdges = connection?.getLinkedRecords('edges');
  if (payload && connectionEdges) {
    connection?.setValue(connectionEdges.length + 1, 'totalCount');
    connection?.setLinkedRecords([payload, ...connectionEdges], 'edges');
  }
};

export const addUserToPoolUpdater =
  (userId?: string) => (store: RecordSourceSelectorProxy<ProfileMutationsAddProfileToCrewPoolMutation$data>, response: ProfileMutationsAddProfileToCrewPoolMutation$data) => {
    const storyRecord = store.getRoot();
    const payload = store.getRootField('addCrewPoolProfiles');
    const connection = ConnectionHandler.getConnection(storyRecord, 'crewPoolsByProfileList_crewPoolsByProfile', {
      profileId: `${userId}`,
      orgId: response.addCrewPoolProfiles.edges[0].Organization.id,
    });
    const connectionEdges = connection?.getLinkedRecords('edges');
    if (payload && connectionEdges && connection) {
      const newPoolsByProfileNodes = payload.getLinkedRecords('edges').map(edge => {
        return ConnectionHandler.createEdge(store, connection, edge, 'crewPoolsByProfile');
      });
      connection?.setLinkedRecords([...newPoolsByProfileNodes, ...connectionEdges], 'edges');
    }
  };
