// packages
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { TrashIcon } from '@heroicons/react/24/outline';
// components
import { Button } from 'system/Button';
import ShipPhoto from 'icons/ShipPhoto';
import CountryFlag from 'system/CountryFlag';
import PeriodDate from 'system/PeriodDate/PeriodDate';
import ItemField from 'system/ListComponents/ItemField';
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';
import AttachedFileComponent from 'system/AttachedFileComponent/AttachedFileComponent';
// models
import { IDocument } from 'models/IDocument';
// types
import { AppraisalItemDataProps } from '../../types';
// generated
import { AppraisalFragmentsOneAppraisal$data } from 'schemas/appraisals/__generated__/AppraisalFragmentsOneAppraisal.graphql';

const AppraisalsListItem = ({
  appraisalData,
  openUpdateModal,
  onRemoveItem,
  disabled = false,
  showModalWithAttachedDocumentsByDefault = false,
  showModalWithAttachedSingleDocumentByDefault = false,
}: AppraisalItemDataProps) => {
  const intl = useIntl();
  const [getReviewLength] = useState<number>(80);
  const [, setCutReview] = useState<string>('');

  useEffect(() => {
    if ((appraisalData.review?.length as number) > getReviewLength) {
      setCutReview(`${(appraisalData.review as string).slice(0, 80)}...`);
    }
  }, [appraisalData.review, getReviewLength]);

  return (
    <div className="sm:mx-5 mt-5 rounded-2xl border border-specialGray-012 bg-white flex md:pr-5 md:pl-6 sm:px-2 sm:py-3 flex-col sm:flex-row">
      <div className="w-auto sm:mr-2 md:mr-0 md:w-28 text-sm border-b sm:border-b-0 text-center flex flex-col justify-center align-middle text-specialGray-1 py-4 px-3 sm:p-0">
        <PeriodDate start={appraisalData.seaService.start} end={appraisalData.seaService.end} withDuration />
      </div>
      <div className="flex sm:contents flex-col-reverse">
        <div className="flex flex-col sm:w-1/3 sm:min-w-[150px] md:min-w-[235px] w-full lg:mr-5 md:mr-3 sm:mr-2 sm:mb-0 text-xs sm:text-sm text-gray-500 justify-center p-4 sm:p-0">
          <span className="text-specialGray-1 font-medium text-sm">{intl.formatMessage({ id: 'appraisals_captain_review' })}</span>
          {(appraisalData.review?.length as number) > getReviewLength ? (
            <PopoverHelper
              position="left-0 -translate-y-[calc(100%_+_8px)]"
              positionArrow="rotate-45 -bottom-[3px] md:left-1/2 sm:left-1/4 left-1/2"
              defaultText={appraisalData.review as string}
              element={<span className="truncate">{appraisalData.review}</span>}
              darkTheme
              widthContainer="w-[200px] sm:w-[300px]"
            />
          ) : (
            <span className="text-specialGray-075">{appraisalData.review}</span>
          )}
        </div>
        {(appraisalData as AppraisalFragmentsOneAppraisal$data).document && (
          <div className="hidden lg:flex flex-col w-10% mr-6">
            <span className="text-specialGray-1 font-medium text-sm mb-2 whitespace-nowrap">{intl.formatMessage({ id: 'label_attached_files' })}</span>
            <div className="w-8 h-8">
              <AttachedFileComponent
                modalTitle={{ id: 'appraisal_modal_with_attached_files_title' }}
                src={(appraisalData as AppraisalFragmentsOneAppraisal$data).document?.urls[0]}
                alt={(appraisalData as AppraisalFragmentsOneAppraisal$data).document?.id}
                document={(appraisalData as AppraisalFragmentsOneAppraisal$data).document as IDocument}
                defaultOpenAddMoreInfoModal={showModalWithAttachedDocumentsByDefault}
                showModalWithSingleDocument={showModalWithAttachedSingleDocumentByDefault}
              />
            </div>
          </div>
        )}
        <div className={`flex w-full sm:items-center sm:w-1/3 sm:flex-row pt-3 px-4 sm:p-0 ${!(appraisalData as AppraisalFragmentsOneAppraisal$data).document ? 'ml-auto' : ''}`}>
          {appraisalData.seaService.vessel.image ? (
            <div className="w-14 h-14 sm:w-10 sm:h-10 md:w-14 md:h-14 lg:w-28 lg:h-16 overflow-hidden rounded-lg flex items-center">
              <img className="w-full h-full" src={appraisalData.seaService.vessel.image} alt={appraisalData.seaService.vessel.name} />
            </div>
          ) : (
            <div className="bg-avatar-bg py-2 px-3 sm:py-3 sm:px-6 flex justify-center items-center rounded-lg w-20 h-12 sm:w-28 sm:h-16">
              <span className="w-[37px] h-[33px]">
                <ShipPhoto />
              </span>
            </div>
          )}
          <div className="flex flex-col lg:ml-5 ml-3 justify-center">
            {appraisalData.seaService.vessel && (
              <>
                <div className="sm:flex flex-wrap flex-col sm:flex-row hidden">
                  {/* duty */}
                  <ItemField
                    contentField={appraisalData.seaService.duty.name}
                    contentFieldClasses="text-specialGray-1 font-normal"
                    labelFieldAfter={{ id: 'sea_service_on_the' }}
                  />
                </div>
                <span className="text-specialGray-075 font-medium text-sm sm:hidden">{appraisalData.seaService.duty.name}</span>
              </>
            )}
            {/* vessel name */}
            <div>
              <CountryFlag countryId={appraisalData.seaService.vessel.cnISO} countryName={appraisalData.seaService.vessel.countryName} />
              <span className="sm:ml-2 ml-1">{appraisalData.seaService.vessel.name}</span>
            </div>
          </div>
        </div>
      </div>
      {(appraisalData as AppraisalFragmentsOneAppraisal$data).document && (
        <div className="flex items-center sm:justify-start justify-end gap-x-1.5 lg:gap-x-5 justify-self-end px-4 pb-4 sm:p-0">
          <div className="flex lg:hidden w-8 h-8">
            <AttachedFileComponent
              modalTitle={{ id: 'appraisal_modal_with_attached_files_title' }}
              src={(appraisalData as AppraisalFragmentsOneAppraisal$data).document?.urls[0]}
              alt={(appraisalData as AppraisalFragmentsOneAppraisal$data).document?.id}
              document={(appraisalData as AppraisalFragmentsOneAppraisal$data).document as IDocument}
              defaultOpenAddMoreInfoModal={showModalWithAttachedDocumentsByDefault}
            />
          </div>
          <Button
            additionalClasses="text-sm"
            label={{ id: 'label_edit_button' }}
            onClick={e => {
              e.stopPropagation();
              if (openUpdateModal) {
                openUpdateModal(appraisalData);
              }
            }}
            buttonType="white-primary-rounded-xl"
          />

          <Button
            additionalClasses="!text-specialGray-05"
            icon={TrashIcon}
            onClick={e => {
              e.stopPropagation();
              if (onRemoveItem) {
                onRemoveItem(appraisalData.id);
              }
            }}
            buttonType="just-icon-rounded-xl"
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};

export default AppraisalsListItem;
