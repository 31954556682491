const EmptyJobIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_15032_29443" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_15032_29443)">
        <path
          d="M5.33329 27.9998C4.59996 27.9998 3.97218 27.7387 3.44996 27.2165C2.92774 26.6943 2.66663 26.0665 2.66663 25.3332V10.6665C2.66663 9.93317 2.92774 9.30539 3.44996 8.78317C3.97218 8.26095 4.59996 7.99984 5.33329 7.99984H10.6666V5.33317C10.6666 4.59984 10.9277 3.97206 11.45 3.44984C11.9722 2.92762 12.6 2.6665 13.3333 2.6665H18.6666C19.4 2.6665 20.0277 2.92762 20.55 3.44984C21.0722 3.97206 21.3333 4.59984 21.3333 5.33317V7.99984H26.6666C27.4 7.99984 28.0277 8.26095 28.55 8.78317C29.0722 9.30539 29.3333 9.93317 29.3333 10.6665V25.3332C29.3333 26.0665 29.0722 26.6943 28.55 27.2165C28.0277 27.7387 27.4 27.9998 26.6666 27.9998H5.33329ZM13.3333 7.99984H18.6666V5.33317H13.3333V7.99984Z"
          fill="#082051"
          fillOpacity="0.3"
        />
      </g>
    </svg>
  );
};

export default EmptyJobIcon;
