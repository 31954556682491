const DoneSingleIcon = ({ additionalClasses = 'w-5 h-5' }: { additionalClasses?: string }) => {
  return (
    <svg data-test-id="done-icon" className={`${additionalClasses} fill-current`} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_9752_4477"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="21"
      >
        <rect x="0.619507" y="0.5" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_9752_4477)">
        <path d="M8.9528 11.8333L13.8695 6.91667C14.0222 6.76389 14.2167 6.6875 14.4528 6.6875C14.6889 6.6875 14.8834 6.76389 15.0361 6.91667C15.1889 7.06944 15.2653 7.26389 15.2653 7.5C15.2653 7.73611 15.1889 7.93056 15.0361 8.08333L9.53613 13.5833C9.36947 13.75 9.17502 13.8333 8.9528 13.8333C8.73058 13.8333 8.53613 13.75 8.36947 13.5833L6.2028 11.4167C6.05002 11.2639 5.97363 11.0694 5.97363 10.8333C5.97363 10.5972 6.05002 10.4028 6.2028 10.25C6.35558 10.0972 6.55002 10.0208 6.78613 10.0208C7.02224 10.0208 7.21669 10.0972 7.36947 10.25L8.9528 11.8333Z" />
      </g>
    </svg>
  );
};

export default DoneSingleIcon;
