import { RoutesPathNames } from 'app/routing';

export const CrewSteps = {
  stepOne: RoutesPathNames.stepOne,
  stepTwo: RoutesPathNames.stepTwo,
  stepThree: RoutesPathNames.stepThree,
  stepFour: RoutesPathNames.stepFour,
  stepFive: RoutesPathNames.stepFive,
  stepSix: RoutesPathNames.stepSix,
  stepSeven: RoutesPathNames.stepSeven,
  stepEight: RoutesPathNames.stepEight,
} as const;

export const HireSteps = {
  stepOne: RoutesPathNames.stepOne,
  stepTwo: RoutesPathNames.stepTwo,
  stepThree: RoutesPathNames.stepThree,
  stepFour: RoutesPathNames.stepFour,
  stepFive: RoutesPathNames.stepFive,
  stepSix: RoutesPathNames.stepSix,
} as const;
