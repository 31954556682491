// packages
// hooks
import { useAuth } from 'authentication';
import { useCreateVacancyApplication } from 'hooks/useCreateVacancyApplication';
import { useGetUserAlreadyApplied } from 'hooks/useGetUserAlreadyApplied';
import { useRemoveVacancyApplication } from 'hooks/useRemoveVacancyApplication';
// components
import DoneIcon from 'icons/DoneIcon';
// models
import { VACANCY_DESCRIPTION_TYPE_OF_LIST, VACANCY_STATUS } from 'models/enums';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useRefetchableFragment } from 'react-relay';
// generated
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { VacancyQueriesGetVacancyRefreshQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetVacancyRefreshQuery.graphql';
// schemas
import { VACANCY_FRAGMENT } from 'schemas/vacancy/VacancyFragments';
import { Button } from 'system/Button';
import ResponseCommonItem from '../../ResponseCommonItem/ResponseCommonItem';

const InvitationItem = ({ vacancy }: { vacancy: VacancyFragments$key }) => {
  const intl = useIntl();
  const { identity } = useAuth();

  const [vacancyData, reFetch] = useRefetchableFragment<VacancyQueriesGetVacancyRefreshQuery, VacancyFragments$key>(VACANCY_FRAGMENT, vacancy as VacancyFragments$key);

  const handleCreateApplication = useCreateVacancyApplication(vacancyData, reFetch);
  const handleRemoveApplication = useRemoveVacancyApplication(vacancyData, `${identity?.profileId}`, reFetch);

  const { userAlreadyApplied } = useGetUserAlreadyApplied(false, vacancyData);

  const handleRenderButton = useCallback(
    (alwaysRender: boolean) => (
      <div className={`${alwaysRender ? 'w-full [&>button]:w-full [&>button]:justify-center' : 'hidden sm:block'}`}>
        {vacancyData?.status !== VACANCY_STATUS.OPEN && <Button buttonType="white-primary" type="button" label={{ id: 'hire_button_label_closed' }} disabled />}
        {vacancyData?.status === VACANCY_STATUS.OPEN && (
          <>
            {userAlreadyApplied ? (
              <button className="flex px-3 py-1 border border-green-500 rounded-md text-green-500 text-sm leading-4 font-medium items-center" onClick={handleRemoveApplication}>
                <DoneIcon />
                <span className="ml-2">{intl.formatMessage({ id: 'hire_label_applied' })}</span>
              </button>
            ) : (
              <Button buttonType="white-primary" type="button" label={{ id: 'hire_button_label_accept_invite' }} onClick={handleCreateApplication} />
            )}
          </>
        )}
      </div>
    ),
    [handleCreateApplication, handleRemoveApplication, intl, userAlreadyApplied, vacancyData?.status],
  );

  return (
    <ResponseCommonItem
      vacancyData={vacancyData}
      renderButton={handleRenderButton(true)}
      avatarSize="w-[66px] sm:w-[56px] lg:w-[138px] h-[66px] sm:h-[56px] lg:h-[138px]"
      typeOfList={VACANCY_DESCRIPTION_TYPE_OF_LIST.INVITED}
    >
      {handleRenderButton(false)}
    </ResponseCommonItem>
  );
};

export default InvitationItem;
