import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useBreakpoints } from 'hooks/useBreakpoints';
import TextInputSkeleton from '../components/TextInputSkeleton';

const AppraisalsFormSkeleton: React.FC<{ isAppraisal: boolean }> = ({ isAppraisal }) => {
  const { sm } = useBreakpoints();

  return (
    <div className="flex flex-col w-full space-y-6">
      <TextInputSkeleton widthTitle={230} borderRadius="0.375rem" heightInput={sm ? 34 : 38} />
      <div className="flex flex-col">
        <TextInputSkeleton widthTitle={185} borderRadius="0.375rem" heightInput={sm ? 50 : 58} />
        <Skeleton width={250} className="mt-2" />
      </div>
      <div className="flex flex-col">
        <Skeleton width={150} />
        {isAppraisal ? (
          <Skeleton width={80} height={75} className="mt-4" />
        ) : (
          <div className="flex my-20">
            <Skeleton width={sm ? 130 : 190} height={sm ? 134 : 153} className="sm:mr-8" />
            <Skeleton width={sm ? 130 : 190} height={sm ? 134 : 153} />
          </div>
        )}
      </div>
      <div className="w-full flex justify-end">
        {isAppraisal && <Skeleton width={sm ? 123 : 132} height={sm ? 48 : 34} borderRadius="0.375rem" className="mr-5" />}
        <Skeleton width={sm ? 121 : 132} height={sm ? 48 : 34} borderRadius="0.375rem" />
      </div>
    </div>
  );
};

export default AppraisalsFormSkeleton;
