// packages
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useLazyLoadQuery, useMutation } from 'react-relay';
import { Form, FormikProvider, useFormik } from 'formik';
// components
import { Modal } from 'system/Modal';
import { Button } from 'system/Button';
import AlertForError from 'system/Alert/AlertForError';
import UploadingDashboard from 'system/UploadingDashboard';
import RenderAttachedDocument from 'system/RenderAttachedDocument/RenderAttachedDocument';
// schemas
import { CREATE_DOCUMENT, UPDATE_DOCUMENT, DELETE_DOCUMENT } from 'schemas/document/DocumentMutations';
import { GET_DOCUMENT_KINDS } from 'schemas/document/DocumentQueries';
// generated
import { DocumentQueriesGetKindsQuery } from 'schemas/document/__generated__/DocumentQueriesGetKindsQuery.graphql';
import { DocumentMutationsCreateDocumentMutation } from 'schemas/document/__generated__/DocumentMutationsCreateDocumentMutation.graphql';
import { DocumentMutationsUpdateDocumentMutation } from 'schemas/document/__generated__/DocumentMutationsUpdateDocumentMutation.graphql';
import { DocumentMutationsDeleteDocumentMutation } from 'schemas/document/__generated__/DocumentMutationsDeleteDocumentMutation.graphql';
// models
import { ENTITY_WITH_PERMA } from 'models/commonTypes';
import { CertificateAddMoreInfoModalForm } from 'models/modelsOfForms';
// helpers
import { CertificateAddMoreInfoFormValidateSchema } from 'formHelpers/validationsOfForms';
import { DocumentCommitCreate } from 'formHelpers/updaters/updatersOfDocument';
// hooks
import { useAuth } from 'authentication';
//types
import { ModalWithAttachedFilesProps } from 'system/AttachedFileComponent/types';

const ModalWithAttachedFiles = ({
  modalTitle,
  document,
  onCloseModal,
  defaultError,
  onAddAttachedFile,
  onDeleteFile,
  showModalWithSingleDocument = false,
}: ModalWithAttachedFilesProps) => {
  const { identity } = useAuth();
  const intl = useIntl();
  const [error, setError] = useState<Error | undefined>(defaultError);
  const [showFullImageModal, setShowFullImageModal] = useState<boolean>(showModalWithSingleDocument);
  const [showAttachedFile, setAttachedFile] = useState<boolean>(false);
  const [currentImageUrl, setCurrentImageUrl] = useState<string>('');

  useEffect(() => {
    if (showModalWithSingleDocument) {
      setCurrentImageUrl(document?.xlarge_urls?.[0] || '');
    }
  }, [document, showModalWithSingleDocument]);

  const [commitCreateDocument] = useMutation<DocumentMutationsCreateDocumentMutation>(CREATE_DOCUMENT);
  const [commitUpdateDocument] = useMutation<DocumentMutationsUpdateDocumentMutation>(UPDATE_DOCUMENT);
  const [commitDeleteDocument] = useMutation<DocumentMutationsDeleteDocumentMutation>(DELETE_DOCUMENT);

  const { documentKinds } = useLazyLoadQuery<DocumentQueriesGetKindsQuery>(GET_DOCUMENT_KINDS, {});
  const documentKind = useMemo<ENTITY_WITH_PERMA>(() => documentKinds?.edges.find(kind => kind.perma === '1') as ENTITY_WITH_PERMA, [documentKinds]);

  const formik = useFormik<CertificateAddMoreInfoModalForm>({
    onSubmit,
    initialValues: {
      attachedSmallFiles: [],
      attachedLargeFiles: [],
    },
    validationSchema: CertificateAddMoreInfoFormValidateSchema,
  });

  const onError = useCallback(
    (error: Error) => {
      setError(error);
      formik.setSubmitting(false);
    },
    [formik],
  );

  const onCompleted = useCallback(() => {
    if (onCloseModal) {
      onCloseModal();
    }
    formik.setSubmitting(false);
  }, [formik, onCloseModal]);

  function onSubmit(formData: CertificateAddMoreInfoModalForm) {
    const data = {
      urls: formData.attachedSmallFiles,
      xlarge_urls: formData.attachedLargeFiles,
      kindId: documentKind.id,
      ownerId: `${identity?.profileId}`,
    };

    if (document) {
      commitUpdateDocument({
        onError,
        onCompleted,
        variables: {
          id: document.id,
          data,
        },
      });
      return;
    }

    commitCreateDocument({
      onError,
      onCompleted,
      variables: {
        data,
      },
      updater: DocumentCommitCreate(onAddAttachedFile, onError),
    });
  }

  const handleDeleteDocument = useCallback(() => {
    if (document) {
      commitDeleteDocument({
        onError,
        onCompleted,
        variables: {
          id: document?.id,
        },
        updater: onDeleteFile,
      });
    }
  }, [document, commitDeleteDocument, onError, onCompleted, onDeleteFile]);

  const handleShowAttachedFile = useCallback(() => {
    setAttachedFile(true);
  }, []);

  const handleClick = useCallback(
    (index: number) => () => {
      setShowFullImageModal(true);
      const getLargeUrl: string = document?.xlarge_urls?.[index] as string;
      setCurrentImageUrl(getLargeUrl);
    },
    [document?.xlarge_urls],
  );

  const handleSetUrls = useCallback(
    (fieldName: string) => (urls: Array<string>) => {
      formik.setFieldValue(fieldName, urls);
    },
    [formik],
  );

  const isDisabledButtons = formik.isSubmitting || !formik.values.attachedSmallFiles.length;

  return (
    <FormikProvider value={formik}>
      <Modal
        onClose={() => {
          setShowFullImageModal(false);
        }}
        show={showFullImageModal}
        title={modalTitle}
      >
        {currentImageUrl.includes('.pdf') ? (
          <>
            <object data={currentImageUrl} type="application/pdf" width="100%" height="500px" className="border-2 border-solid border-grayIcon rounded-[10px]">
              <p>
                {intl.formatMessage({ id: 'error_pdf_plugin' })}
                <Link to={currentImageUrl ?? ''} className="block text-center mt-1">
                  {intl.formatMessage({ id: 'pdf_plugin_download' })}
                </Link>
              </p>
            </object>
          </>
        ) : (
          <img key={currentImageUrl} src={currentImageUrl} alt={currentImageUrl} className="block m-auto border-2 border-solid border-grayIcon rounded-[10px]" />
        )}
      </Modal>
      <Form className="space-y-6">
        {error && <AlertForError heading={{ id: 'certificate_form_failedSubmit' }} error={error} />}
        {document?.id && !showAttachedFile ? (
          <div className="flex flex-wrap justify-center sm:justify-start gap-4 ">
            {document.xlarge_urls?.map((url, index) => {
              const isPdfFile = url.includes('.pdf');
              return <RenderAttachedDocument key={url} src={isPdfFile ? document.urls[index] : url} alt={url} onClick={handleClick(index)} />;
            })}
          </div>
        ) : (
          <UploadingDashboard
            setSmallUrls={handleSetUrls('attachedSmallFiles')}
            setLargeUrls={handleSetUrls('attachedLargeFiles')}
            templateId={process.env.REACT_APP_TRANSLOADIT_CERTIFICATE_TEMPLATE_ID}
            maxNumberOfFiles={3}
          />
        )}
        <div className="hidden sm:flex justify-between gap-4 flex-wrap">
          <div className="flex flex-col sm:flex-row gap-5">
            <Button type="button" label={{ id: 'cancel' }} disabled={formik.isSubmitting} buttonType="white-primary" onClick={onCloseModal} withoutWidth />
            {!isEmpty(document) && onDeleteFile && <Button type="button" label={{ id: 'remove_document' }} buttonType="red-primary" onClick={handleDeleteDocument} withoutWidth />}
          </div>
          <div className="flex gap-5">
            {document?.id && (
              <Button
                label={{ id: 'button_upload_new_file_label' }}
                type="button"
                disabled={formik.isSubmitting}
                buttonType="secondary"
                onClick={handleShowAttachedFile}
                withoutWidth
              />
            )}
            <Button label={{ id: 'save_info' }} type="submit" disabled={isDisabledButtons} additionalClasses="justify-center" />
          </div>
        </div>
        <div className="flex sm:hidden flex-col gap-4">
          <div className="flex gap-x-4 justify-between">
            {!isEmpty(document) && onDeleteFile && (
              <Button type="button" label={{ id: 'remove' }} buttonType="red-primary" onClick={handleDeleteDocument} additionalClasses="w-full justify-center" withoutWidth />
            )}
            {document?.id && (
              <Button
                label={{ id: 'button_upload_new_file_label' }}
                type="button"
                disabled={formik.isSubmitting}
                buttonType="secondary"
                onClick={handleShowAttachedFile}
                additionalClasses="w-full justify-center"
                withoutWidth
              />
            )}
          </div>
          <div className="flex gap-x-4 justify-between">
            <Button
              type="button"
              label={{ id: 'cancel' }}
              disabled={formik.isSubmitting}
              buttonType="white-primary"
              onClick={onCloseModal}
              additionalClasses="w-full justify-center"
              withoutWidth
            />
            <Button label={{ id: 'save_info' }} type="submit" disabled={isDisabledButtons} additionalClasses="w-full justify-center" withoutWidth />
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default ModalWithAttachedFiles;
