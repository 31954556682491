// packages
import { useCallback, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-relay';
// components
import { Modal } from 'system/Modal';
import CertificateInfoForm from '../CertificateInfoForm';
import CertificateTitle from './components/CertificateTitle';
import AttachedFileComponent from 'system/AttachedFileComponent/AttachedFileComponent';
import CertificateExpiryDate from './components/CertificateExpiyDate/CertificateExpiryDate';
// generated
import { CertificateMutationsUpdateCertificateInfoMutation } from 'schemas/certificates/__generated__/CertificateMutationsUpdateCertificateInfoMutation.graphql';
// models
import { IDocument } from 'models/IDocument';
import { ICertificateInfoEdge } from 'models/ICertificate';
// schemas
import { UPDATE_CERTIFICATE_ITEM_MUTATION } from 'schemas/certificates/CertificateMutations';
// hooks
import { useGetProfileIdFromAuthOrParams } from 'hooks/useGetProfileIdFromAuthOrParams';
// utils
import { sliceAltsIfLong } from 'utils';
//types
import { CertificateItemProps } from '../types';

export const CertificateItem = ({
  certificateInfo,
  onDelete = () => {},
  defaultOpenCertificateInfoModal,
  defaultOpenAddMoreInfoModal,
  symbolsToSliceInAlts = 30,
  defaultShowModalWithFile = false,
}: CertificateItemProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [commitUpdateCertificateInfoDate] = useMutation<CertificateMutationsUpdateCertificateInfoMutation>(UPDATE_CERTIFICATE_ITEM_MUTATION);
  const [isOpenCertificateInfoModal, setIsOpenCertificateInfoModal] = useState<boolean>(defaultOpenCertificateInfoModal || false);

  const { isSelfProfile } = useGetProfileIdFromAuthOrParams();

  const handleOpenCertificateInfoModal = useCallback(() => {
    setIsOpenCertificateInfoModal(true);
  }, []);

  const handleCloseCertificateInfoModal = useCallback(() => {
    setIsOpenCertificateInfoModal(false);
    navigate(pathname, {
      replace: true,
      state: null,
    });
  }, [navigate, pathname]);

  const handleAddAttachedFile = useCallback(
    (documentId: string, handleError: (error: Error) => void) => {
      commitUpdateCertificateInfoDate({
        onError: handleError,
        onCompleted: () => {
          navigate(pathname, {
            replace: true,
            state: null,
          });
        },
        variables: {
          id: certificateInfo?.id as string,
          certificatesInfoData: { documentId, expires: certificateInfo?.expires },
        },
      });
    },
    [commitUpdateCertificateInfoDate, certificateInfo?.id, certificateInfo?.expires, navigate, pathname],
  );

  const handleDeleteFile = useCallback(() => {
    commitUpdateCertificateInfoDate({
      variables: {
        id: certificateInfo?.id as string,
        certificatesInfoData: { documentId: null, expires: certificateInfo?.expires },
      },
    });
  }, [commitUpdateCertificateInfoDate, certificateInfo?.expires, certificateInfo?.id]);

  return (
    <>
      <Modal title={{ id: 'certificate_info_modal' }} onClose={handleCloseCertificateInfoModal} show={isOpenCertificateInfoModal}>
        <CertificateInfoForm onCloseModal={handleCloseCertificateInfoModal} certificate={certificateInfo as ICertificateInfoEdge} />
      </Modal>
      <>
        <td className="flex-col sm:block px-4 py-3.5 sm:py-3.5 sm:px-0 sm:pl-4 sm:justify-center">
          <CertificateTitle certificateInfo={certificateInfo as ICertificateInfoEdge}>
            {/*mobile version inline*/}
            <span className="sm:hidden text-specialGray-03 text-xs ml-1">
              {certificateInfo?.certificate?.alts && sliceAltsIfLong(certificateInfo?.certificate?.alts as Array<string>, symbolsToSliceInAlts)}
            </span>
          </CertificateTitle>

          <div className="sm:hidden flex w-full mt-2 items-center space-x-2">
            <CertificateExpiryDate
              expiryDate={certificateInfo?.expires}
              neverExpires={certificateInfo?.certificate.neverExpires}
              handleOpenModal={handleOpenCertificateInfoModal}
            />

            {isSelfProfile && (
              <>
                <div className="ml-2.5 w-7 h-7 flex items-center justify-center">
                  <AttachedFileComponent
                    modalTitle={{ id: 'certificate_attached_file_modal_title' }}
                    src={certificateInfo?.document?.urls[0]}
                    alt={certificateInfo?.certificate.name}
                    defaultOpenAddMoreInfoModal={defaultOpenAddMoreInfoModal}
                    document={certificateInfo?.document as IDocument}
                    onAddAttachedFile={handleAddAttachedFile}
                    onDeleteFile={handleDeleteFile}
                    showModalWithSingleDocument={defaultShowModalWithFile}
                  />
                </div>
                <button
                  onClick={() => onDelete(certificateInfo?.certificate?.id)}
                  className="border border-grayIcon self-center flex justify-center items-center rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 w-7 h-7"
                >
                  <XMarkIcon className="h-4 w-4 block text-grayIcon hover:text-specialGray-075" />
                </button>
              </>
            )}
          </div>
          {/*mobile version inline end*/}
        </td>

        <td className={`hidden sm:table-cell text-specialGray-03 text-xs md:pl-12 md:pr-20 sm:text-right text-center`}>
          {certificateInfo?.certificate?.alts && sliceAltsIfLong(certificateInfo?.certificate?.alts as Array<string>, symbolsToSliceInAlts)}
        </td>
        <td className="hidden sm:table-cell justify-center">
          {isSelfProfile && (
            <div className="ml-2.5 w-7 h-7 flex items-center justify-center">
              <AttachedFileComponent
                modalTitle={{ id: 'certificate_attached_file_modal_title' }}
                src={certificateInfo?.document?.urls[0]}
                alt={certificateInfo?.certificate.name}
                defaultOpenAddMoreInfoModal={defaultOpenAddMoreInfoModal}
                document={certificateInfo?.document as IDocument}
                onAddAttachedFile={handleAddAttachedFile}
                onDeleteFile={handleDeleteFile}
                showModalWithSingleDocument={defaultShowModalWithFile}
              />
            </div>
          )}
        </td>
        <td className="hidden sm:table-cell justify-center">
          <CertificateExpiryDate expiryDate={certificateInfo?.expires} neverExpires={certificateInfo?.certificate.neverExpires} handleOpenModal={handleOpenCertificateInfoModal} />
        </td>
        <td className="hidden sm:table-cell justify-center m-1 sm:m-0 relative left-1 sm:left-0">
          {isSelfProfile && (
            <button
              onClick={() => onDelete(certificateInfo?.certificate?.id)}
              className="m-auto border border-specialGray-05 text-specialGray-05 flex justify-center items-center rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 w-7 h-7"
            >
              <XMarkIcon className="h-4 w-4 block text-grayIcon hover:text-specialGray-075" />
            </button>
          )}
        </td>
      </>
    </>
  );
};
