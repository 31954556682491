export const backListErrors: string[] = [
  'Incorrect username or password.',
  'An account with the given email already exists.',
  'Invalid verification code provided.',
  'Invalid verification code provided, please try again.',
  'Password did not conform with policy: Password not long enough',
  'Invalid phone number format.',
  'service_imo_incomplete',
  'One of your files was declined',
  'User cannot be confirmed. Current status is CONFIRMED',
  'One of your files was declined',
  'TypeError: Illegal invocation',
  'Invalid code provided, please request a code again.',
  'Network error',
  'Invalid phone number format.',
  'Transloadit: Could not create Assembly: This looks like a network error, the endpoint might be blocked by an internet provider or a firewall.',
  'service_not_exist_vessel_by_imo',
];
