/**
 * @generated SignedSource<<942d5ba190208c7d1f867d28e02d4019>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProfileQueriesGetEmailOfProfileQuery$variables = {
  ids: ReadonlyArray<number>;
  skip: boolean;
};
export type ProfileQueriesGetEmailOfProfileQuery$data = {
  readonly profileOIDClaims?: {
    readonly edges: ReadonlyArray<{
      readonly email: string;
    }>;
  } | null;
};
export type ProfileQueriesGetEmailOfProfileQuery = {
  response: ProfileQueriesGetEmailOfProfileQuery$data;
  variables: ProfileQueriesGetEmailOfProfileQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'ids',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'skip',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'ids',
        variableName: 'ids',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'email',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileQueriesGetEmailOfProfileQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: 'ProfileOIDClaimsList',
              kind: 'LinkedField',
              name: 'profileOIDClaims',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'ProfileOIDClaims',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [v2 /*: any*/],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileQueriesGetEmailOfProfileQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: 'ProfileOIDClaimsList',
              kind: 'LinkedField',
              name: 'profileOIDClaims',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'ProfileOIDClaims',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'id',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
        },
      ],
    },
    params: {
      cacheID: '3e4020978900938643ef66d6c1da0ede',
      id: null,
      metadata: {},
      name: 'ProfileQueriesGetEmailOfProfileQuery',
      operationKind: 'query',
      text: 'query ProfileQueriesGetEmailOfProfileQuery(\n  $ids: [Int!]!\n  $skip: Boolean!\n) {\n  profileOIDClaims(ids: $ids) @skip(if: $skip) {\n    edges {\n      email\n      id\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'f530b8c303fe106789a1bca6bfacd83b';

export default node;
