const ShipPhoto = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 37 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5422 1.94168C21.5422 0.920575 20.7115 0.0898438 19.6903 0.0898438H16.927C15.9059 0.0898438 15.0752 0.920575 15.0752 1.94168V4.71029H21.5422V1.94168Z"
        fill="#ADAFBC"
      />
      <path
        d="M18.5797 11.6752L29.314 14.4799V7.5579C29.314 7.17057 28.9989 6.85547 28.6115 6.85547H8.00565C7.61832 6.85547 7.30322 7.17057 7.30322 7.5579V14.4799L18.0374 11.6752C18.2152 11.6287 18.402 11.6287 18.5797 11.6752Z"
        fill="#ADAFBC"
      />
      <path
        d="M35.5435 29.9425C34.3595 29.9425 33.8254 29.6765 33.1492 29.3397C32.8317 29.1817 32.4969 29.015 32.0975 28.873C31.5428 28.6759 30.8632 28.5263 29.9318 28.5132L33.8595 18.6259C33.9464 18.3911 33.8769 18.1957 33.8274 18.0996C33.7774 18.0028 33.6571 17.8308 33.4116 17.7666L18.3081 13.8203L3.20464 17.7666C2.95905 17.8308 2.83869 18.0028 2.78884 18.0996C2.73928 18.1957 2.6697 18.3914 2.75688 18.6263L6.67575 28.5134C5.74475 28.5274 5.06578 28.6779 4.51153 28.8758C4.1154 29.0171 3.78278 29.1827 3.46732 29.3398C2.79099 29.6765 2.25684 29.9426 1.07274 29.9426C0.480302 29.9426 0 30.4229 0 31.0153C0 31.6077 0.480302 32.088 1.07274 32.088C2.76138 32.088 3.64446 31.6484 4.42356 31.2605C4.72715 31.1093 5.00241 30.9727 5.3203 30.8678C5.71071 30.7388 6.16548 30.6578 6.81814 30.6578C6.9671 30.6578 7.1057 30.662 7.23572 30.6701C8.13882 30.7259 8.62149 30.9662 9.21271 31.2605C9.99181 31.6484 10.8749 32.0881 12.5635 32.0881C14.2521 32.0881 15.1351 31.6484 15.9143 31.2605C16.5905 30.9238 17.1247 30.6578 18.3086 30.6578C19.4926 30.6578 20.0267 30.9238 20.7029 31.2605C21.482 31.6484 22.365 32.0881 24.0536 32.0881C25.7422 32.0881 26.6252 31.6484 27.4043 31.2605C27.9974 30.9652 28.4813 30.7244 29.3898 30.6696C29.5173 30.6619 29.653 30.6578 29.7986 30.6578C30.4441 30.6578 30.896 30.7372 31.2835 30.8638C31.6067 30.9692 31.8854 31.1074 32.1929 31.2605C32.972 31.6484 33.855 32.0881 35.5436 32.0881C36.136 32.0881 36.6163 31.6078 36.6163 31.0154C36.6163 30.4229 36.1359 29.9425 35.5435 29.9425ZM8.39042 19.3102L18.0369 16.7897C18.2147 16.7433 18.4015 16.7433 18.5793 16.7897L28.2257 19.3102C28.7989 19.4599 29.1422 20.0461 28.9924 20.6193C28.8664 21.1016 28.4315 21.4211 27.9553 21.4211C27.8656 21.4211 27.7743 21.4098 27.6834 21.386L18.3081 18.9364L8.9328 21.386C8.35959 21.5358 7.77345 21.1925 7.62369 20.6193C7.47394 20.0461 7.81722 19.4599 8.39042 19.3102Z"
        fill="#ADAFBC"
      />
    </svg>
  );
};

export default ShipPhoto;
