// packages
import { FC, useCallback, useEffect, useState } from 'react';
import { useField } from 'formik';
import { useLazyLoadQuery } from 'react-relay';
import { differenceBy, isEmpty, uniq } from 'lodash';
// components
import SelectInputGroup from 'system/SelectInputGroup';
import RequiredCertificateItem from './components/RequiredCertificateItem';
// schemas
import { GET_ALL_CERTIFICATES_LIST } from 'schemas/certificates/CertificateQueries';
// models
import { ICertificate } from 'models/ICertificate';
import { SelectRequiredCertificatesComponentProps } from 'models/modelsOfComponents';
// generated
import { CertificateFragments_single$data } from 'schemas/certificates/__generated__/CertificateFragments_single.graphql';
import { CertificateQueriesListQuery } from 'schemas/certificates/__generated__/CertificateQueriesListQuery.graphql';

const SelectRequiredCertificatesComponent: FC<SelectRequiredCertificatesComponentProps> = ({ name, certificates }) => {
  const [, , { setValue }] = useField<Array<string>>({ name });
  const dataOfAllCertificates = useLazyLoadQuery<CertificateQueriesListQuery>(GET_ALL_CERTIFICATES_LIST, { skip: false });
  const [options, setOptions] = useState<ReadonlyArray<ICertificate> | undefined>(undefined);
  const [selectedCertificates, setSelectedCertificates] = useState<Array<CertificateFragments_single$data>>([]);

  useEffect(() => {
    if (dataOfAllCertificates.certificates?.edges) {
      setOptions(differenceBy(dataOfAllCertificates.certificates?.edges, selectedCertificates, 'id'));
    }
  }, [dataOfAllCertificates.certificates?.edges, selectedCertificates]);

  useEffect(() => {
    setSelectedCertificates(certificates as Array<CertificateFragments_single$data>);
  }, [certificates]);

  const filterRequiredCertificates = (title: string) => (certificate: ICertificate) => {
    return certificate?.kind?.name.toLowerCase().includes(title.toLowerCase()) || certificate.name.toLowerCase().includes(title.toLowerCase());
  };

  const handleFilteringOptions = useCallback(
    (title: string) => {
      if (title) {
        setOptions(differenceBy(dataOfAllCertificates.certificates?.edges, selectedCertificates, 'id').filter(filterRequiredCertificates(title)));
      } else {
        setOptions(() => differenceBy(dataOfAllCertificates.certificates?.edges, selectedCertificates, 'id'));
      }
    },
    [dataOfAllCertificates.certificates?.edges, selectedCertificates],
  );

  const handleSelectItems = useCallback(
    (certificate: CertificateFragments_single$data) => {
      const selectedCertificatesData = [...selectedCertificates, options?.find(cert => cert.id === certificate.id) as ICertificate] as Array<CertificateFragments_single$data>;
      setSelectedCertificates(uniq(selectedCertificatesData));
    },
    [options, selectedCertificates],
  );

  useEffect(() => {
    setValue(selectedCertificates?.map(certificate => certificate.id));
    // eslint-disable-next-line
  }, [selectedCertificates]);

  const handleRemoveItemFromSelectedCertificates = useCallback(
    (certificateId: string) => () => {
      setSelectedCertificates(() => {
        return selectedCertificates?.filter(cert => cert.id !== certificateId) as Array<CertificateFragments_single$data>;
      });
      setOptions(prevState => {
        if (prevState) {
          return [
            ...(prevState as ReadonlyArray<ICertificate>),
            dataOfAllCertificates.certificates?.edges.find(certificate => certificate.id === certificateId),
          ] as ReadonlyArray<ICertificate>;
        } else {
          return undefined;
        }
      });
    },
    [selectedCertificates, dataOfAllCertificates.certificates?.edges],
  );

  return options ? (
    <div className="flex flex-col">
      <SelectInputGroup
        name={name}
        label={{ id: 'hire_description_required_certificates_section' }}
        onChangeSelect={handleFilteringOptions}
        placeholder={{ id: 'hire_form_step_2_field_placeholder' }}
        setSelectedItem={handleSelectItems}
        options={options}
        containerMargin="mb-0"
        sortOptionsByAlphabetically
        isLongDropdown
      />
      {!isEmpty(selectedCertificates) && (
        <div className="mt-2">
          {selectedCertificates?.map(certificate => (
            <RequiredCertificateItem key={certificate.id} certificate={certificate} removeItem={handleRemoveItemFromSelectedCertificates} />
          ))}
        </div>
      )}
    </div>
  ) : null;
};

export default SelectRequiredCertificatesComponent;
