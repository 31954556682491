import DashboardTableHeaderSkeleton from './DashboardTableHeaderSkeleton';
import DashboardTableBodyListSkeleton from './DashboardTableBodyListSkeleton';

const DashboardTableSkeleton = () => {
  return (
    <>
      <DashboardTableHeaderSkeleton />
      <DashboardTableBodyListSkeleton numberOfItems={5} />
    </>
  );
};

export default DashboardTableSkeleton;
