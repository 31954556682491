/**
 * @generated SignedSource<<6e4fa0932a8de048e8a7257c912f4e6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ServiceQueriesFormGetAllDutiesQuery$variables = {
  skip: boolean;
};
export type ServiceQueriesFormGetAllDutiesQuery$data = {
  readonly duties?: {
    readonly edges: ReadonlyArray<{
      readonly alts: ReadonlyArray<string> | null;
      readonly id: string;
      readonly name: string;
      readonly perma: string;
    }>;
  } | null;
};
export type ServiceQueriesFormGetAllDutiesQuery = {
  response: ServiceQueriesFormGetAllDutiesQuery$data;
  variables: ServiceQueriesFormGetAllDutiesQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'skip',
      },
    ],
    v1 = [
      {
        condition: 'skip',
        kind: 'Condition',
        passingValue: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'DutyList',
            kind: 'LinkedField',
            name: 'duties',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'Duty',
                kind: 'LinkedField',
                name: 'edges',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'id',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'name',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'alts',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'perma',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ServiceQueriesFormGetAllDutiesQuery',
      selections: v1 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ServiceQueriesFormGetAllDutiesQuery',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '2f9bb6150092674ab90a63f51cbb209f',
      id: null,
      metadata: {},
      name: 'ServiceQueriesFormGetAllDutiesQuery',
      operationKind: 'query',
      text: 'query ServiceQueriesFormGetAllDutiesQuery(\n  $skip: Boolean!\n) {\n  duties @skip(if: $skip) {\n    edges {\n      id\n      name\n      alts\n      perma\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '35f5f56ba1680499c1f3ea9d8531ca4c';

export default node;
