import { useEffect, useState } from 'react';
import { useAuth } from 'authentication';
import { RoutePaths } from 'app/routing';

const useCrewUrlPath = () => {
  const [pathToCrewPage, setPathToCrewPage] = useState<string>(RoutePaths.ProfileSingleCrewPage);
  const { identity } = useAuth();
  useEffect(() => {
    const accountType = identity?.platformGroup;
    if (accountType === 'hire') return setPathToCrewPage(RoutePaths.ProfileSingleCrewPageEmployerView);
    setPathToCrewPage(RoutePaths.ProfileSingleCrewPage);
  }, [identity?.platformGroup]);

  return pathToCrewPage;
};

export default useCrewUrlPath;
