// packages
import { isEmpty } from 'lodash';
import { useLazyLoadQuery } from 'react-relay';
import { useNavigate } from 'react-router-dom';
// components
import NoSearchResult from '../NoSearchResult';
import NoJobOpeningWasFoundIcon from 'icons/NoJobOpeningWasFoundIcon';
import DropDownListOfVacanciesItem from './DropDownListOfVacanciesItem';
import DropDownHeader from 'app/Navbar/components/SearchInput/components/DropDownHeader';
// routing
import { RoutePaths } from 'app/routing';
// generated
import { SearchQueriesVacanciesQuery } from 'schemas/search/__generated__/SearchQueriesVacanciesQuery.graphql';
import { VacancyFragmentsForSearchFragment$key } from 'schemas/vacancy/__generated__/VacancyFragmentsForSearchFragment.graphql';
import { VacancyQueriesGetListOfVacanciesByIdsQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetListOfVacanciesByIdsQuery.graphql';
// schemas
import { SEARCH_VACANCIES_QUERY } from 'schemas/search/SearchQueries';
import { GET_LIST_OF_VACANCIES_BY_IDS } from 'schemas/vacancy/VacancyQueries';
// enums
import { SEARCH_INDEX } from 'models/enums';
// context
import { useNavbarSearchContext } from 'Context/NavbarSearchContext';

const VacanciesDropDown = ({ setShowDropDown }: { setShowDropDown: () => void }) => {
  const navigate = useNavigate();
  const { queryParams } = useNavbarSearchContext();

  const vacancies = useLazyLoadQuery<SearchQueriesVacanciesQuery>(SEARCH_VACANCIES_QUERY, {
    first: 3,
    query: queryParams,
    index: SEARCH_INDEX.VACANCY,
    skip: !queryParams,
  }).search;

  const listOfVacancies = useLazyLoadQuery<VacancyQueriesGetListOfVacanciesByIdsQuery>(GET_LIST_OF_VACANCIES_BY_IDS, {
    ids: isEmpty(vacancies) ? [] : (vacancies?.edges?.map(vacancy => vacancy?.node?.objectID) as Array<string>),
  }).nodes;

  const handleNavigationToJobOpeningPage = () => {
    setShowDropDown();
    navigate(RoutePaths.JobOpenings, {
      state: { queryParams },
    });
  };

  return (
    <>
      <DropDownHeader counter={vacancies?.totalCount} label={{ id: 'crew_job_openings' }} onClick={handleNavigationToJobOpeningPage} isShowSeeAllButton={!!vacancies?.totalCount} />
      {!isEmpty(listOfVacancies) ? (
        <>
          {listOfVacancies?.map(vacancy => (
            <DropDownListOfVacanciesItem key={vacancy?.id} setShowDropDown={setShowDropDown} vacancy={vacancy as VacancyFragmentsForSearchFragment$key} />
          ))}
        </>
      ) : (
        <NoSearchResult message={{ id: 'search_input_no_jobs_results' }} showBorderBottom>
          <NoJobOpeningWasFoundIcon />
        </NoSearchResult>
      )}
    </>
  );
};

export default VacanciesDropDown;
