/**
 * @generated SignedSource<<f6a192dda7fe6ff292210f487f8feb08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ProfileQueriesGetProfileOrganizationMemberInvitationsQuery$variables = {
  first?: number | null;
  last?: number | null;
  profileId: string;
  skip: boolean;
};
export type ProfileQueriesGetProfileOrganizationMemberInvitationsQuery$data = {
  readonly profileOrganizationMemberInvitations?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly 'id': string;
        readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsOrganizationMemberInvitation'>;
      } | null;
    } | null> | null;
    readonly totalCount: number;
  } | null;
};
export type ProfileQueriesGetProfileOrganizationMemberInvitationsQuery = {
  response: ProfileQueriesGetProfileOrganizationMemberInvitationsQuery$data;
  variables: ProfileQueriesGetProfileOrganizationMemberInvitationsQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'first',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'last',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'profileId',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'skip',
    },
    v4 = {
      kind: 'Variable',
      name: 'profileId',
      variableName: 'profileId',
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'totalCount',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: '__typename',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cursor',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      concreteType: 'PageInfo',
      kind: 'LinkedField',
      name: 'pageInfo',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'endCursor',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'hasNextPage',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'hasPreviousPage',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'startCursor',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v10 = [
      {
        kind: 'Variable',
        name: 'first',
        variableName: 'first',
      },
      {
        kind: 'Variable',
        name: 'last',
        variableName: 'last',
      },
      v4 /*: any*/,
    ],
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'avatarUrl',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileQueriesGetProfileOrganizationMemberInvitationsQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: 'profileOrganizationMemberInvitations',
              args: [v4 /*: any*/],
              concreteType: 'OrganizationMemberConnection',
              kind: 'LinkedField',
              name: '__profileOrganizationMemberInvitationsList_profileOrganizationMemberInvitations_connection',
              plural: false,
              selections: [
                v5 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'OrganizationMemberEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'OrganizationMember',
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [
                        v6 /*: any*/,
                        {
                          args: null,
                          kind: 'FragmentSpread',
                          name: 'ProfileFragmentsOrganizationMemberInvitation',
                        },
                        v7 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    v8 /*: any*/,
                  ],
                  storageKey: null,
                },
                v9 /*: any*/,
              ],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v2 /*: any*/, v3 /*: any*/, v0 /*: any*/, v1 /*: any*/],
      kind: 'Operation',
      name: 'ProfileQueriesGetProfileOrganizationMemberInvitationsQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v10 /*: any*/,
              concreteType: 'OrganizationMemberConnection',
              kind: 'LinkedField',
              name: 'profileOrganizationMemberInvitations',
              plural: false,
              selections: [
                v5 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'OrganizationMemberEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'OrganizationMember',
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [
                        v6 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'role',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Organization',
                          kind: 'LinkedField',
                          name: 'organization',
                          plural: false,
                          selections: [
                            v6 /*: any*/,
                            v11 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'name',
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Profile',
                          kind: 'LinkedField',
                          name: 'profile',
                          plural: false,
                          selections: [
                            v6 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              concreteType: 'ProfileInfo',
                              kind: 'LinkedField',
                              name: 'info',
                              plural: false,
                              selections: [
                                v6 /*: any*/,
                                v11 /*: any*/,
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'firstName',
                                  storageKey: null,
                                },
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'lastName',
                                  storageKey: null,
                                },
                              ],
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                        v7 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    v8 /*: any*/,
                  ],
                  storageKey: null,
                },
                v9 /*: any*/,
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: v10 /*: any*/,
              filters: ['profileId'],
              handle: 'connection',
              key: 'profileOrganizationMemberInvitationsList_profileOrganizationMemberInvitations',
              kind: 'LinkedHandle',
              name: 'profileOrganizationMemberInvitations',
            },
          ],
        },
      ],
    },
    params: {
      cacheID: 'daee3e203679dbcc2223351d0d30e112',
      id: null,
      metadata: {
        connection: [
          {
            count: null,
            cursor: null,
            direction: 'bidirectional',
            path: ['profileOrganizationMemberInvitations'],
          },
        ],
      },
      name: 'ProfileQueriesGetProfileOrganizationMemberInvitationsQuery',
      operationKind: 'query',
      text: 'query ProfileQueriesGetProfileOrganizationMemberInvitationsQuery(\n  $profileId: ID!\n  $skip: Boolean!\n  $first: Int\n  $last: Int\n) {\n  profileOrganizationMemberInvitations(profileId: $profileId, first: $first, last: $last) @skip(if: $skip) {\n    totalCount\n    edges {\n      node {\n        id\n        ...ProfileFragmentsOrganizationMemberInvitation\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n      hasPreviousPage\n      startCursor\n    }\n  }\n}\n\nfragment ProfileFragmentsOrganizationMemberInvitation on OrganizationMember {\n  id\n  role\n  organization {\n    id\n    avatarUrl\n    name\n  }\n  profile {\n    id\n    info {\n      id\n      avatarUrl\n      firstName\n      lastName\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '3068228e0b55ae00cef4665dd5f21078';

export default node;
