// packages
import { useState } from 'react';
import { useMutation } from 'react-relay';
import { Form, FormikProvider, useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
// routing
import { RoutePaths } from 'app/routing';
// components
import LocationPink from 'icons/LocationPink';
import AlertForError from 'system/Alert/AlertForError';
import Footer from 'auth/SignUpSteps/components/Footer';
import Header from 'auth/SignUpSteps/components/Header';
import SelectCountryInputGroup from 'system/SelectCountryInputGroup/SelectCountryInputGroup';
// formHelpers
import { StepWithCountryValidateSchema } from 'formHelpers/validationsOfForms';
// models
import { PLATFORM_GROUP } from 'models/enums';
import { SignUpStepsState } from 'models/routeLocationState';
// generated
import { BasicInfoFormMutationsUpdateMutation } from 'schemas/forms/__generated__/BasicInfoFormMutationsUpdateMutation.graphql';
// schemas
import { UPDATE_PROFILE_INFO_MUTATION } from 'schemas/forms/BasicInfoFormMutations';

const StepWithCountry = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [commitUpdate, updateLoading] = useMutation<BasicInfoFormMutationsUpdateMutation>(UPDATE_PROFILE_INFO_MUTATION);

  const [error, setError] = useState<Error | undefined>();

  const formik = useFormik<{ countryId: string }>({
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { countryId: (state as SignUpStepsState)?.countryId || '' },
    validationSchema: StepWithCountryValidateSchema,
  });

  async function onSubmit(data: { countryId: string }) {
    if ((state as SignUpStepsState).countryId !== data.countryId) {
      commitUpdate({
        variables: {
          id: (state as SignUpStepsState).profileInfo.id,
          data: {
            ownerId: (state as SignUpStepsState).profileInfo.owner.id,
            firstName: (state as SignUpStepsState).profileInfo.firstName,
            lastName: (state as SignUpStepsState).profileInfo.lastName,
            birthDate: (state as SignUpStepsState).profileInfo.birthDate,
            countryId: data.countryId,
          },
        },
        onCompleted: () => {
          window.analytics?.track('wui-signup-step-with-enter-country', {
            countryId: data.countryId,
            type_of_account: PLATFORM_GROUP.CREW,
          });
          navigate({ pathname: RoutePaths.AuthRegisterStepSix }, { state: { ...state, countryId: data.countryId } });
        },
        onError: (error: Error) => {
          setError(error);
          formik.setSubmitting(false);
        },
      });
    }
  }

  return (
    <section className="flex w-full flex-col h-full sm:h-auto pt-5 pb-7">
      <Header title={{ id: 'enter_country_title' }} description={{ id: 'enter_country_description' }} icon={<LocationPink />} />
      <FormikProvider value={formik}>
        <Form className="flex flex-col mt-7 sm:mt-8 w-full sm:py-8 px-4 sm:border sm:border-specialGray-012 sm:px-10 rounded-[20px] space-y-[18px] h-full justify-between sm:justify-start">
          <fieldset className="[&>input]:sm:h-[34px] [&_input]:h-12 [&_input]:text-sm">
            {error && <AlertForError heading={{ id: 'register_failedAlert' }} error={error} />}
            <SelectCountryInputGroup name="countryId" label={{ id: 'profile_country_of_residence_v2' }} placeholder={{ id: 'choose_country' }} autoFocus />
          </fieldset>
          <Footer error={error} pathnameToPrevStep={RoutePaths.AuthRegisterStepFour} isDisabled={!formik.values.countryId || updateLoading} />
        </Form>
      </FormikProvider>
    </section>
  );
};

export default StepWithCountry;
