import { useIntl } from 'react-intl';
//component
import LabelComponent from 'system/LabelComponent/LabelComponent';
//models
import { FilteringByNumbersProps } from '../types';
//hooks
import useFilterSearchNumeric from 'hooks/searchFilter/useFilterSearchNumeric';

export const FilteringByNumbers = ({ title, filterNameFrom, filterNameTo }: FilteringByNumbersProps) => {
  const intl = useIntl();

  const { createSearchNumericUrl, selectedNumber } = useFilterSearchNumeric(filterNameFrom);
  const { createSearchNumericUrl: createNumberUrlForSecondValue, selectedNumber: selectedNumberForSecondValue } = useFilterSearchNumeric(filterNameTo);

  return (
    <div className="flex pb-8 flex-col border-b border-b-specialGray-01">
      <LabelComponent classes="pt-6 sm:pt-8 pb-3 px-4 sm:pl-8 text-sm font-medium text-darkBlue" id="number_of_sea_services_from" label={title} />
      <div className="mt-1 relative flex space-x-2 items-center mx-4 sm:mx-8">
        <input
          onChange={createSearchNumericUrl}
          id="number_of_sea_services_from"
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm rounded-md border-specialGray-012 placeholder:text-specialGray-05 pl-2.5"
          type="number"
          placeholder={intl.formatMessage({ id: 'input_placeholder_from' })}
          value={selectedNumber}
        />
        <div className="w-8 h-0.5 bg-specialGray-05" />
        <input
          onChange={createNumberUrlForSecondValue}
          id="number_of_sea_services_from"
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-specialGray-012 placeholder:text-specialGray-05 rounded-md pl-2.5"
          type="number"
          placeholder={intl.formatMessage({ id: 'input_placeholder_to' })}
          value={selectedNumberForSecondValue}
        />
      </div>
    </div>
  );
};
