// packages
import { isEmpty } from 'lodash';
import parseISO from 'date-fns/parseISO';
import { numberOfYearsOfExperience } from 'mocks/mockData';
import { ProfileFragments$data } from 'schemas/profile/__generated__/ProfileFragments.graphql';
// generated
import { ServiceFragments$data } from 'schemas/services/__generated__/ServiceFragments.graphql';
import { EducationFragments$data } from 'schemas/educations/__generated__/EducationFragments.graphql';
import { ProfileFragments_info$data } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
import { CountryFragments_info$data } from 'schemas/country/__generated__/CountryFragments_info.graphql';
import { ServiceFragmentsOneDuty$data } from 'schemas/services/__generated__/ServiceFragmentsOneDuty.graphql';
// models
import { IDuty } from 'models/IDuty';
import { IActivities } from 'models/IActivity';
import { ORGANIZATION_SIZE, STARTING_CONDITION_ENUMS } from 'models/enums';
import { IVacancyCertificate } from 'models/ICertificate';
import { EducationFormData, IServiceFormData } from 'models/modelsOfForms';
// __generated__
import { VacancyFragments$data } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { ServiceFragmentsVessel$data } from 'schemas/services/__generated__/ServiceFragmentsVessel.graphql';
import { ProfileOrganizationMembershipProps } from 'models/modelsOfComponents';

export const HireFormInitialValuesStepOne = (defaultData?: VacancyFragments$data) => {
  if (defaultData) {
    const {
      title: positionTitle,
      salaryFrom,
      salaryTo,
      duties,
      industry,
      salaryPeriod,
      duration,
      tornWeeksOff,
      tornWeeksOn,
      currency,
      startingDate,
      startingCondition,
    } = defaultData;

    return {
      positionTitle,
      salaryFrom,
      salaryTo,
      duties: duties ? (duties as ReadonlyArray<IDuty>) : [],
      industry,
      salaryPeriod,
      duration,
      tornWeeksOff: tornWeeksOff || '',
      tornWeeksOn: tornWeeksOn || '',
      currencyId: currency?.id || '',
      startingDate: startingDate ? parseISO(`${startingDate}`) : undefined,
      startingCondition: startingCondition || STARTING_CONDITION_ENUMS.IMMEDIATELY,
      orgMemberId: defaultData.organizationMember.id,
    };
  }
  return {
    positionTitle: '',
    salaryFrom: 100,
    salaryTo: 200,
    industry: null,
    duration: null,
    duties: [],
    salaryPeriod: null,
    tornDurationId: null,
    tornWeeksOff: '',
    tornWeeksOn: '',
    currencyId: '',
    startingDate: null,
    startingCondition: STARTING_CONDITION_ENUMS.IMMEDIATELY,
    orgMemberId: '',
  };
};

export const HireFormInitialValuesStepTwo = (defaultData: VacancyFragments$data) => {
  return {
    essentialCertificates: !isEmpty(defaultData?.certificates) ? (defaultData?.certificates as Array<IVacancyCertificate>).map(certificate => certificate.id) : [],
    vesselKindId: defaultData?.vesselKind?.id ?? '',
    hiringAreaId: defaultData?.hiringArea?.id ?? '',
  };
};

export const ServiceFormInitialValues = (
  defaultData: ServiceFragments$data | null,
  vessel: ServiceFragmentsVessel$data,
  dutyId?: string,
  activities?: IActivities,
): IServiceFormData => {
  if (defaultData) {
    return {
      start: parseISO(`${defaultData.start}`),
      end: defaultData.end ? parseISO(`${defaultData.end}`) : null,
      rotations: defaultData.rotations,
      vesselId: defaultData.id,
      note: defaultData?.description,
      workingNow: false,
      dutyId: dutyId || '',
      vessel: {
        imo: `${vessel.imo}`,
        name: vessel.name,
        fleetmonId: '',
        operatorName: vessel.operatorName || '',
        image: vessel.image || '',
        kind: vessel?.kind?.name || '',
        cn_iso: vessel?.cnISO || '',
        gross_tonnage: vessel?.grossTonnage || 0,
        length: vessel?.length || 0,
      },
      tags: activities || [],
    };
  } else {
    return {
      start: undefined,
      end: undefined,
      rotations: 1,
      vesselId: '',
      workingNow: false,
      dutyId: '',
      note: '',
      vessel: {
        imo: '',
        name: '',
        fleetmonId: '',
        operatorName: '',
        kind: '',
        cn_iso: '',
        gross_tonnage: 0,
        length: 0,
      },
      tags: [] as IActivities,
    };
  }
};

export const EducationFormInitialValues = (education: EducationFragments$data | null): EducationFormData =>
  education
    ? {
        name: education.name,
        start: parseISO(`${education.start}`),
        end: education.end ? parseISO(`${education.end}`) : null,
        kindId: education?.kind?.id as string,
        studyingNow: false,
      }
    : {
        name: '',
        start: null,
        end: null,
        kindId: null,
        studyingNow: false,
      };

export const ConfirmFormInitialValues = (email: string | undefined, code: string | undefined) => ({
  email: email || '',
  code: code || '',
});

export const ConfirmFormByPhoneInitialValues = {
  code: '',
};

export const ForgotConfirmFormInitialValues = (email: string | undefined) => ({
  email: email || '',
  code: '',
  newPassword: '',
});

export const ForgotFormInitialValues = { email: '' };

export const LoginFormInitialValues = { email: '', password: '' };

export const RegisterFormInitialValues = { email: '', password: '', privacy: 'false' };

export const RegisterWithEmailOrPhoneValues = (initialEmail: string = '') => ({ email: initialEmail, phoneNumber: '' });

export const RegisterWithPasswordValues = (initialPassword: string = '') => ({ password: initialPassword, privacy: 'false' });

export const RegisterWithSeaServiceFormValues = {
  dutyId: '',
  start: null,
  end: null,
  vesselName: '',
  vesselImo: '',
  fleetmonId: '',
  vesselKindId: '',
  vesselOperatorName: '',
  vesselGrossTonnage: 0,
  vesselImage: '',
  vesselCnIso: '',
  vesselLength: 0,
  workingNow: false,
};

export const RegisterWithExperience = { userExperience: numberOfYearsOfExperience[0].id };

export const BasicInfoFormInitialValues = (
  profileInfo: ProfileFragments_info$data | null,
  countryData: CountryFragments_info$data | null,
  primaryDuty: ServiceFragmentsOneDuty$data | null,
  notificationPreference?: ProfileFragments$data['notificationPreference'],
) =>
  profileInfo
    ? {
        firstName: profileInfo.firstName,
        lastName: profileInfo.lastName,
        bio: profileInfo.bio ? profileInfo.bio : '',
        birthDate: parseISO(`${profileInfo.birthDate}`),
        countryId: countryData?.id || '',
        privacy: false,
        mainDutyId: primaryDuty?.id || '',
        informMe: notificationPreference ? notificationPreference?.jobOpening && notificationPreference?.tipsToImprove : true,
        yearsOfExperience: profileInfo.yearsOfExperience || null,
      }
    : {
        firstName: '',
        lastName: '',
        bio: '',
        birthDate: undefined,
        countryId: '',
        mainDutyId: '',
        phoneNumber: '',
        informMe: true,
        yearsOfExperience: null,
      };

export const BasicEmployerInfoFormInitialValues = (profileOrganization: ProfileOrganizationMembershipProps | null) =>
  profileOrganization
    ? {
        name: profileOrganization?.organization?.name || '',
        description: profileOrganization?.organization?.description || '',
        avatarUrl: profileOrganization?.organization?.avatarUrl || '',
        size: profileOrganization?.organization?.size || ORGANIZATION_SIZE.SIZE_1_10,
        countryId: profileOrganization?.organization.country?.id || null,
        city: profileOrganization?.organization.city || null,
      }
    : {
        name: '',
        description: '',
        avatarUrl: '',
        size: ORGANIZATION_SIZE.SIZE_1_10,
        countryId: null,
        city: null,
      };

export const ServiceNoteFormInitialValues = (service: ServiceFragments$data) => ({
  note: service?.description || '',
});

export const ChangeEmailFormInitialValues = (email: string) => ({
  email,
});

export const ChangePasswordFormInitialValues = {
  oldPassword: '',
  newPassword: '',
};

export const AppraisalFormInitialValues = (documentId: string, seaServiceId: string, review: string) => ({
  documentId: documentId || '',
  seaServiceId: seaServiceId || '',
  review: review || '',
});

export const PhoneConfirmInitialValues = {
  codeConfirm: '',
};

export const HireFormInitialValuesStepTree = (defaultData: VacancyFragments$data) => {
  if (defaultData) {
    const { description, responsibilities } = defaultData;

    const prepareInitialValue = (metaInitialValue: string[]) => {
      return metaInitialValue.join('\n');
    };

    return {
      description,
      responsibilities: responsibilities ? prepareInitialValue(responsibilities as string[]) : '',
    };
  }
  return {
    description: '',
    responsibilities: '',
  };
};

export const SignUpWithNameInitialValues = (initialFirstName: string = '', initialLastName: string = '') => ({
  firstName: initialFirstName,
  lastName: initialLastName,
});

export const SignUpWithPhoneNumberInitialValues = {
  phoneNumber: '',
};

export const AssumeProfileInitialValue = {
  profileId: '',
};
