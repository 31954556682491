import { Suspense } from 'react';
// models
import { TAB_NAMES } from 'models/enums';
// components
import Overview from 'app/Profile/Overview/Overview';
import ServiceList from 'app/Profile/Service/ServiceList';
import SelfCertificates from './Certificates/SelfCertificates';
import EducationList from 'app/Profile/Education/EducationList';
import AppraisalsList from 'app/Profile/Appraisals/AppraisalsList/AppraisalsList';
import ServicesListTabSkeleton from 'system/skeletons/profileTabs/ServicesListTabSkeleton';

const GetTabsForCrew = ({ tabName }: { tabName: TAB_NAMES }) => {
  switch (tabName) {
    case TAB_NAMES.OVERVIEW:
      return <Overview />;
    case TAB_NAMES.SEA_SERVICE:
      return (
        <Suspense fallback={<ServicesListTabSkeleton />}>
          <ServiceList />
        </Suspense>
      );
    case TAB_NAMES.CERTIFICATES:
      return <SelfCertificates />;
    case TAB_NAMES.EDUCATIONS:
      return <EducationList />;
    case TAB_NAMES.APPRAISALS:
      return <AppraisalsList />;
    default:
      return null;
  }
};

export default GetTabsForCrew;
