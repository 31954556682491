import { useState } from 'react';
//components
import ProfileProgressBarItem from './ProfileProgressBarItem';
import RatingScoreWithIcon from 'system/ProfileReting/components/RatingScoreWithIcon';
//types
import { ProfileProgressBarCommonProps } from 'app/Profile/ProfileScore/types';

const ProfileProgressBarCommon = ({ score, additionalClasses }: ProfileProgressBarCommonProps) => {
  const [maximumSummary] = useState<number>(100);
  return (
    <div className={`flex relative text-xs text-darkBlue w-full max-w-[152px] max-h-[75px] ${additionalClasses}`}>
      <ProfileProgressBarItem score={score} />
      <span className="absolute left-0 -bottom-8 text-specialGray-075 text-[9.6px]">0</span>
      <span className="absolute -right-1 -bottom-8 text-specialGray-075 text-[9.6px]">{maximumSummary}</span>

      <div className="flex absolute -bottom-6 left-1/2 transform -translate-x-1/2 -translate-y-1/2 [&>svg]:w-7 [&>svg]:h-7 [&>span]:text-xl">
        <RatingScoreWithIcon maxSummary={maximumSummary} summaryRating={score} />
      </div>
    </div>
  );
};

export default ProfileProgressBarCommon;
