import { useRecoilState } from 'recoil';
import { Suspense, useEffect } from 'react';
// types
import { VacancyOfApplicationType } from 'app/ProfileCrew/type/ProfileCrewTypes';
// hooks
import useGetAppliedHireProfileVacancies from 'hooks/hireProfileHooks/useGetAppliedHireProfileVacancies';
// recoil
import { contactInformationAtom } from 'recoil/ContactInformation/atoms/contactInformationAtom';
// components
import { Modal } from 'system/Modal';
import VacancyWithContactButton from 'app/ProfileCrew/ProfileCrewViews/ProfileCrewEmployerView/MainCrewInformationEmployerView/components/ButtonToContactWithCrew/VacancyWithContactButton';
import SlideOverWithCrewContact from 'app/ProfileCrew/ProfileCrewViews/ProfileCrewEmployerView/MainCrewInformationEmployerView/components/ButtonToContactWithCrew/SlideOverWithCrewContact';

const ProfileContactContent = () => {
  const [getShowContactInformation, setShowContactInformation] = useRecoilState(contactInformationAtom);
  const appliedVacancies = useGetAppliedHireProfileVacancies();

  useEffect(() => {
    setShowContactInformation(prev => ({ ...prev, isLoading: false }));
  }, [getShowContactInformation.showContactInformation, setShowContactInformation]);

  return (
    <>
      {appliedVacancies && appliedVacancies?.length > 1 ? (
        <Modal
          title={{ id: 'modal_with_job_openings_with_contact_button_title' }}
          onClose={() => setShowContactInformation({ showContactInformation: false, profileId: '', isLoading: false })}
          show={getShowContactInformation.showContactInformation}
        >
          <>
            {appliedVacancies?.map(appliedVacancy => (
              <VacancyWithContactButton key={appliedVacancy?.node?.id} vacancy={appliedVacancy?.node?.vacancy as VacancyOfApplicationType} />
            ))}
          </>
        </Modal>
      ) : (
        <Suspense>
          <SlideOverWithCrewContact />
        </Suspense>
      )}
    </>
  );
};

export default ProfileContactContent;
