import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
// components
import { Button } from './Button';
import LinkIcon from 'icons/LinkIcon';
import SpinnerIcon from 'icons/SpinnerIcon';
import BlueSpinnerIcon from 'icons/BlueSpinnerIcon';
import FileDownloadIcon from 'icons/FileDownloadIcon';
import CopyLinkComponent from 'system/CopyLinkComponent';
import HighlightElementWrapper from './wrappers/HighlightElementWrapper';
// models
import { BasicCvPresenceProps } from 'models/modelsOfComponents';
import { typeProfileRatingState } from 'models/routeLocationState';
// hooks
import { useAuth } from 'authentication';
import { useGenerateResume } from 'hooks/useGenerateResume';
import useCreateProfileShareLink from 'hooks/useCreateProfileShareLink';

export const BasicCvPresence = ({ onCloseModal, profileId }: BasicCvPresenceProps) => {
  const intl = useIntl();
  const { identity } = useAuth();
  const { state } = useLocation();
  const { generateResume, loading } = useGenerateResume(profileId);

  const { handleCreateProfileShare, profileShareLink, loadingCreateProfileShare } = useCreateProfileShareLink(`${identity?.profileId}`);

  return (
    <>
      <div className={`flex flex-row ${profileShareLink ? '' : 'mb-20'} gap-x-3 sm:gap-x-6`}>
        <HighlightElementWrapper className="flex w-full max-w-[192px]" enableFlickering={(state as typeProfileRatingState)?.profileShare} addPadding="outline-offset-4">
          <div className="flex flex-col w-full items-center text-sm cursor-pointer" onClick={handleCreateProfileShare}>
            <div className="rounded-lg w-full bg-lightBlue h-28 flex justify-center items-center mb-2">
              {loadingCreateProfileShare ? <SpinnerIcon spinnerColor="#2563EB" additionalClasses="h-7 w-7" /> : <LinkIcon />}
            </div>
            {intl.formatMessage({ id: 'create_link_buttonLabel' })}
          </div>
        </HighlightElementWrapper>

        <HighlightElementWrapper className="flex w-full max-w-[192px]" enableFlickering={(state as typeProfileRatingState)?.downloadCV} addPadding="outline-offset-4">
          <div className="flex flex-col items-center text-sm w-full">
            <div className="rounded-lg bg-lightBlue w-full h-28 flex justify-center items-center mb-2 cursor-pointer" onClick={generateResume}>
              {loading ? <BlueSpinnerIcon /> : <FileDownloadIcon />}
            </div>
            {intl.formatMessage({ id: 'downloadCV_buttonLabel' })}
          </div>
        </HighlightElementWrapper>
      </div>
      {profileShareLink && (
        <div className="mb-20 mt-5">
          <CopyLinkComponent profileShareLink={profileShareLink} />
        </div>
      )}
      <div className="w-full flex justify-end items-center mt-10 bg-specialGray-002 absolute bottom-0 left-0 py-4 pr-8">
        <Button onClick={onCloseModal} label={{ id: 'cancel' }} buttonType="white-primary" type="button" />
      </div>
    </>
  );
};
