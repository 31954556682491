// packages
import Skeleton from 'react-loading-skeleton';
// components
import HeaderSkeleton from './components/HeaderSkeleton';
import EducationListItemSkeleton from './components/EducationListItemSkeleton';

const EducationsTabSkeleton = () => {
  return (
    <div className="flex flex-col px-6">
      <HeaderSkeleton widthBigTitle={125} />
      <div className="flex flex-row rounded-2xl rounded-b-none px-3 py-3 border border-b-0 md:border-b-1 bg-white md:bg-specialGray-004">
        <div className="hidden md:flex ml-5 w-30">
          <Skeleton width={40} />
        </div>
        <div className="hidden md:flex w-30">
          <Skeleton width={40} />
        </div>
        <div className="hidden md:flex w-15">
          <Skeleton width={70} />
        </div>
        <div className="hidden md:flex w-15">
          <Skeleton width={60} />
        </div>
        <div className="hidden md:flex ml-auto w-12" />
        <div className="hidden md:flex w-12" />
      </div>
      <div className="flex flex-col md:flex-row items-left md:items-center p-3 border border-t-0 bg-white">
        <EducationListItemSkeleton />
      </div>
      <div className="flex flex-col md:flex-row items-left md:items-center p-3 border border-t-0 bg-white">
        <EducationListItemSkeleton />
      </div>
      <div className="flex flex-col md:flex-row items-left md:items-center p-3 border border-t-0 bg-white rounded-2xl rounded-t-none">
        <EducationListItemSkeleton />
      </div>
    </div>
  );
};

export default EducationsTabSkeleton;
