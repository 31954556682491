// packages
import { Suspense } from 'react';
// components
import MainLayout from 'system/Layouts/MainLayout';
import NavBarSkeleton from 'system/skeletons/NavBar/NavBarSkeleton';

const MainLayoutWrapper = () => {
  return (
    <Suspense fallback={<NavBarSkeleton />}>
      <MainLayout />
    </Suspense>
  );
};

export default MainLayoutWrapper;
