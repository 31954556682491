// packages
import { selector } from 'recoil';
// types
import { scrollWithPaginationType } from '../types';
// atoms
import { scrollWithPaginationAtom } from '../atoms/ScrollWithPaginationAtom';
// utils
import { getItemFromLocalStorageByName } from 'utils/localStorageHelpers';

export const jobOpeningsSearchScrollWithPaginationSelector = selector({
  key: 'JobOpeningsSearchScrollWithPaginationSelector',
  get: ({ get }) => {
    const getScrollWithPaginationFromLocalStorage = getItemFromLocalStorageByName('jobOpeningsSearchScrollWithPagination');

    const getScrollWithPaginationRecoil = get(scrollWithPaginationAtom);
    return getScrollWithPaginationFromLocalStorage ?? getScrollWithPaginationRecoil;
  },
  set: ({ set, get }, newValue) => {
    set(scrollWithPaginationAtom, newValue);
    localStorage.setItem(
      'jobOpeningsSearchScrollWithPagination',
      JSON.stringify({ scrollY: (newValue as scrollWithPaginationType).scrollY, numberOfFetchNumber: (newValue as scrollWithPaginationType).numberOfFetchNumber }),
    );
  },
});

export const profileSearchScrollWithPaginationSelector = selector({
  key: 'ProfileSearchScrollWithPaginationSelector',
  get: ({ get }) => {
    const getScrollWithPaginationFromLocalStorage = getItemFromLocalStorageByName('profileSearchScrollWithPagination');
    const getScrollWithPaginationRecoil = get(scrollWithPaginationAtom);
    return getScrollWithPaginationFromLocalStorage ?? getScrollWithPaginationRecoil;
  },
  set: ({ set, get }, newValue) => {
    set(scrollWithPaginationAtom, newValue);
    localStorage.setItem(
      'profileSearchScrollWithPagination',
      JSON.stringify({ scrollY: (newValue as scrollWithPaginationType).scrollY, numberOfFetchNumber: (newValue as scrollWithPaginationType).numberOfFetchNumber }),
    );
  },
});
