import React, { useCallback, useEffect, useState } from 'react';
import { OptionType } from 'system/SelectInputGroup';

export const useChooseAndFocusElement = (options: readonly OptionType[], defaultFocusedElement?: any) => {
  const [chooseElement, setChooseElement] = useState<string | undefined>('');
  const [elementInFocus, setElementInFocus] = useState<string | undefined>('');
  const [indexChoseElement, setIndexChoseElement] = useState<number>(0);

  const handleChangeIndex = useCallback(
    (array: readonly OptionType[]) => {
      let elementValue = array?.[0];
      let idx = 0;
      array?.forEach((el: OptionType, index: number) => {
        if (el?.id === chooseElement) {
          elementValue = el;
          idx = index;
        }
      });

      if (array?.includes(elementValue)) {
        setIndexChoseElement(idx);
      }
    },
    [chooseElement],
  );

  const handleSetFocusedFirstElement = useCallback(() => {
    if (options) {
      if (!defaultFocusedElement) {
        setElementInFocus(options?.[0]?.id);
      }
      setIndexChoseElement(0);
    }
  }, [options, defaultFocusedElement]);

  useEffect(() => {
    if (chooseElement && options) {
      const listOfValues = options.map(({ id }: OptionType) => id);

      if (listOfValues.includes(chooseElement)) {
        if (!defaultFocusedElement) {
          setElementInFocus(chooseElement);
        }
        handleChangeIndex(options);
      } else {
        handleSetFocusedFirstElement();
      }
    } else {
      handleSetFocusedFirstElement();
    }
  }, [options, chooseElement, handleChangeIndex, handleSetFocusedFirstElement, defaultFocusedElement]);

  const handleMouseUp = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      const target: HTMLInputElement = event.target as HTMLInputElement;
      const inputValue = target.value;
      target.selectionStart = inputValue.length;

      if (!chooseElement) {
        setElementInFocus(options?.[0]?.id!);
      } else {
        setElementInFocus(chooseElement);
      }

      handleChangeIndex(options);
    },
    [options, chooseElement, handleChangeIndex],
  );

  return {
    elementInFocus,
    chooseElement,
    indexChoseElement,
    setChooseElement,
    setElementInFocus,
    setIndexChoseElement,
    handleMouseUp,
  };
};
