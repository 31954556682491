import Skeleton from 'react-loading-skeleton';
import EssentialCertificatesSkeleton from './components/EssentialCertificatesSkeleton';
import MainInformationSkeleton from './components/MainInformationSkeleton';

const VacancySkeleton = () => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center">
        <Skeleton width={50} height={24} />
        <Skeleton width={250} height={24} className="ml-3 mr-6" />
      </div>
      <MainInformationSkeleton />

      <Skeleton width={270} height={24} className="mb-2 mt-8" />
      <Skeleton width="100%" height={14} />
      <Skeleton width="100%" height={14} />
      <Skeleton width="100%" height={14} />
      <Skeleton width="35%" height={14} />

      <Skeleton width={270} height={24} className="mb-2 mt-8" />
      <Skeleton width="100%" height={14} />
      <Skeleton width="40%" height={14} />
      <Skeleton width="100%" height={14} />
      <Skeleton width="40%" height={14} />
      <Skeleton width="100%" height={14} />
      <Skeleton width="40%" height={14} />
      <Skeleton width="100%" height={14} />
      <Skeleton width="40%" height={14} />
      <EssentialCertificatesSkeleton />
    </div>
  );
};

export default VacancySkeleton;
