/**
 * @generated SignedSource<<a67341b26830757d03660672555ddb42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EducationMutationsListRemoveMutation$variables = {
  sid: string;
};
export type EducationMutationsListRemoveMutation$data = {
  readonly deleteEducation: {
    readonly id: string;
  };
};
export type EducationMutationsListRemoveMutation = {
  response: EducationMutationsListRemoveMutation$data;
  variables: EducationMutationsListRemoveMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'sid',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'sid',
          },
        ],
        concreteType: 'Education',
        kind: 'LinkedField',
        name: 'deleteEducation',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'EducationMutationsListRemoveMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'EducationMutationsListRemoveMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '55d8b9c8d204b6175536b8ba5a99af32',
      id: null,
      metadata: {},
      name: 'EducationMutationsListRemoveMutation',
      operationKind: 'mutation',
      text: 'mutation EducationMutationsListRemoveMutation(\n  $sid: ID!\n) {\n  deleteEducation(id: $sid) {\n    id\n  }\n}\n',
    },
  };
})();

(node as any).hash = '1274ff74bed69a45e28ca9b5acb37ad6';

export default node;
