const EducationIcon = () => {
  return (
    <svg className="mx-auto" width="51" height="39" viewBox="0 0 51 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#2A6FF5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3568 0L1.45088 9.34831C0.879004 9.56257 0.5 10.1092 0.5 10.72V15.6028C0.5 16.4118 1.15586 17.0676 1.96484 17.0676H3.48467V36.9215C3.48467 37.7304 4.14053 38.3863 4.94951 38.3863H46.0504C46.8594 38.3863 47.5152 37.7304 47.5152 36.9215V17.0676H49.0352C49.8441 17.0676 50.5 16.4118 50.5 15.6028V10.72C50.5 10.1092 50.121 9.56257 49.5491 9.34831L25.5334 0H25.3568ZM12.2371 35.4566H15.1789V24.3919C15.1789 23.5842 14.5218 22.927 13.7141 22.927H13.702C12.8942 22.927 12.2371 23.5842 12.2371 24.3919V35.4566ZM23.9802 22.4096V35.4566H26.9221V22.4096C26.9221 21.6019 26.2649 20.9448 25.4572 20.9448H25.445C24.6373 20.9448 23.9802 21.6019 23.9802 22.4096ZM35.8212 35.4566H38.763V24.3919C38.763 23.5842 38.1059 22.927 37.2981 22.927H37.286C36.4783 22.927 35.8212 23.5842 35.8212 24.3919V35.4566ZM25.5 3.46609L3.42969 11.7354V14.138H17.6346C18.4436 14.138 19.0994 14.7938 19.0994 15.6028C19.0994 16.4118 18.4436 17.0676 17.6346 17.0676H6.41436V35.4566H9.30732V24.3919C9.30732 21.9687 11.2787 19.9973 13.7019 19.9973H13.714C16.1371 19.9973 18.1085 21.9687 18.1085 24.3919V35.4566H21.0504V22.4096C21.0504 19.9865 23.0218 18.0151 25.4449 18.0151H25.4571C27.8803 18.0151 29.8517 19.9865 29.8517 22.4096V35.4566H32.8914V24.3919C32.8914 21.9687 34.8628 19.9973 37.2859 19.9973H37.298C39.7212 19.9973 41.6926 21.9687 41.6926 24.3919V35.4566H44.5855V17.0676H33.2676C32.4586 17.0676 31.8027 16.4118 31.8027 15.6028C31.8027 14.7938 32.4586 14.138 33.2676 14.138H47.5703V11.7354L25.5 3.46609Z"
      />
    </svg>
  );
};

export default EducationIcon;
