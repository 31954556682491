import { useMemo } from 'react';
import { useLazyLoadQuery } from 'react-relay';
// schemas
import { GET_PROFILE_PRODUCT_OWNERSHIP } from 'schemas/profile/ProfileQueries';
// generated
import { ProfileQueriesGetProfileProductOwnershipQuery } from 'schemas/profile/__generated__/ProfileQueriesGetProfileProductOwnershipQuery.graphql';
// models
import { PaymentSubscriptionType } from 'models/enums';

const useGetPaymentSubscription = (organizationId: string | null) => {
  const productData = useLazyLoadQuery<ProfileQueriesGetProfileProductOwnershipQuery>(GET_PROFILE_PRODUCT_OWNERSHIP, { orgId: `${organizationId}`, skip: !organizationId });

  return useMemo(() => {
    return productData.productOwnerships?.edges.find(item => {
      return item.node.product.perma === PaymentSubscriptionType.PREMIUM || item.node.product.perma === PaymentSubscriptionType.TRIAL;
    });
  }, [productData]);
};

export default useGetPaymentSubscription;
