// packages
import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
// models
import { VACANCY_DESCRIPTION_TYPE_OF_LIST } from 'models/enums';

const ListOfCrewItemSkeleton: FC<{ typeOfList: VACANCY_DESCRIPTION_TYPE_OF_LIST }> = ({ typeOfList }) => {
  return (
    <div className="flex justify-between cursor-pointer rounded-md border border-gray-300 p-4 w-full h-[74px]">
      <div className="flex items-center space-x-3">
        <Skeleton width={36} height={36} borderRadius={6} />
        <div className="flex flex-col justify-between">
          <Skeleton width={150} />
          <Skeleton width={75} height={14} />
        </div>
      </div>
      <div className="flex flex-col space-y-2 justify-center items-center">
        {typeOfList === VACANCY_DESCRIPTION_TYPE_OF_LIST.INVITED && (
          <>
            <Skeleton width={32} height={12} />
            <Skeleton width={75} height={12} />
          </>
        )}
        {typeOfList === VACANCY_DESCRIPTION_TYPE_OF_LIST.APPLIED && (
          <>
            <Skeleton width={75} height={12} />
          </>
        )}
      </div>
    </div>
  );
};

export default ListOfCrewItemSkeleton;
