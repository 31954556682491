//components
import CrewsEducationEmployerView from './CrewsEducationEmployerView/CrewsEducationEmployerView';
import CrewsAppraisalsEmployerView from './CrewsAppraisalsEmployerView/CrewsAppraisalsEmployerView';
import CrewsSeaServicesEmployerView from './CrewsSeaServicesEmployerView/CrewsSeaServicesEmployerView';
import CrewsCertificatesEmployerView from './CrewsCertificatesEmployerView/CrewsCertificatesEmployerView';
// hooks
import { useReceiveCrewProfile } from 'hooks/useReceiveCrewProfile';

const OverviewCrewInformationEmployerView = () => {
  const { profileData } = useReceiveCrewProfile();

  if (!profileData) {
    return null;
  }

  return (
    <div className="flex w-full flex-col [&>section]:mb-8">
      <CrewsSeaServicesEmployerView seaServFr={profileData.seaServices} />
      <CrewsCertificatesEmployerView profileCrewData={profileData.certificateInfos} />
      <CrewsEducationEmployerView profileEducations={profileData.educations} />
      <CrewsAppraisalsEmployerView profileAppraisals={profileData.appraisals} />
    </div>
  );
};

export default OverviewCrewInformationEmployerView;
