// packages
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useRecoilState } from 'recoil';
import { useLazyLoadQuery } from 'react-relay';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
// routing
import { RoutePaths, RoutesPathNames } from 'app/routing';
// components
import NoJobs from './NoJobs';
import SlideOver from 'system/SlideOver';
import JobOpeningItem from 'app/ListOfJobOpenings/components/JobOpeningItem/JobOpeningItem';
import LoadMoreJobOpeningsButton from 'system/LoadMoreJobOpeningsButton/LoadMoreJobOpeningsButton';
// context
import { useNavbarSearchContext } from 'Context/NavbarSearchContext';
// hooks
import { useSearchJobOpeningFilterQuery } from 'hooks/searchFilter/useSearchJobOpeningFilterQuery';
// generated
import { SearchQueriesVacanciesQuery } from 'schemas/search/__generated__/SearchQueriesVacanciesQuery.graphql';
import { VacancyFragmentsForSearchFragment$key } from 'schemas/vacancy/__generated__/VacancyFragmentsForSearchFragment.graphql';
import { VacancyQueriesGetListOfVacanciesByIdsQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetListOfVacanciesByIdsQuery.graphql';
// schemas
import { SEARCH_VACANCIES_QUERY } from 'schemas/search/SearchQueries';
import { GET_LIST_OF_VACANCIES_BY_IDS } from 'schemas/vacancy/VacancyQueries';
// enums
import { SEARCH_INDEX, VACANCY_STATUS } from 'models/enums';
// recoil
import { jobOpeningsSearchScrollWithPaginationSelector } from 'recoil/ScrollWithPagination/selectors/SearchScrollWithPaginationSelector';
import { scrollWithPaginationType } from '../../../recoil/ScrollWithPagination/types';

const ListOfJobOpeningsComponent = ({ setAmountVacancies }: { setAmountVacancies?: Dispatch<SetStateAction<number>> }) => {
  const navigate = useNavigate();
  const params = useParams();
  const { queryParams } = useNavbarSearchContext();
  const { filters } = useSearchJobOpeningFilterQuery();
  const { pathname, state, search } = useLocation();

  const [getScrollWithPagination, setScrollWithPagination] = useRecoilState(jobOpeningsSearchScrollWithPaginationSelector);

  const vacancies = useLazyLoadQuery<SearchQueriesVacanciesQuery>(SEARCH_VACANCIES_QUERY, {
    first: getScrollWithPagination.numberOfFetchNumber,
    query: queryParams,
    index: SEARCH_INDEX.VACANCY,
    skip: false,
    filters: `status: ${VACANCY_STATUS.OPEN}${!!filters ? ` AND ${filters}` : ''}`,
  });

  const listOfVacancies = useLazyLoadQuery<VacancyQueriesGetListOfVacanciesByIdsQuery>(GET_LIST_OF_VACANCIES_BY_IDS, {
    ids: isEmpty(vacancies) ? [] : (vacancies.search?.edges?.map(vacancy => vacancy?.node?.objectID) as Array<string>),
  }).nodes;

  useEffect(() => {
    if (setAmountVacancies) {
      setAmountVacancies(vacancies.search?.totalCount as number);
    }
  }, [vacancies.search?.totalCount, setAmountVacancies]);

  const handleCloseSlide = () => {
    navigate({ pathname: RoutePaths.JobOpenings, search }, { state, replace: true });
  };

  useEffect(() => {
    // save previous scroll place for Safari browser after Slider closing
    window.scrollTo(0, getScrollWithPagination.scrollY);
  }, [getScrollWithPagination.scrollY, pathname]);

  const handleIncreaseNumberOfItems = useCallback(() => {
    setScrollWithPagination((prevState: scrollWithPaginationType) => ({ scrollY: window.scrollY, numberOfFetchNumber: prevState.numberOfFetchNumber + 10 }));
  }, [setScrollWithPagination]);

  if (isEmpty(listOfVacancies)) {
    return <NoJobs />;
  }

  return (
    <>
      <SlideOver show={!!params[RoutesPathNames.jobOpeningSlideId]} onClose={handleCloseSlide}>
        <Outlet />
      </SlideOver>
      <div className="flex flex-col gap-y-6">
        {listOfVacancies?.map(vacancy => (vacancy ? <JobOpeningItem key={vacancy?.id} vacancy={vacancy as VacancyFragmentsForSearchFragment$key} /> : null))}
        {vacancies.search?.pageInfo.hasNextPage && (
          <LoadMoreJobOpeningsButton totalNumberOfJobOpenings={vacancies.search.edges?.length || 0} handleIncreaseNumberOfItems={handleIncreaseNumberOfItems} />
        )}
      </div>
    </>
  );
};

export default ListOfJobOpeningsComponent;
