import { FC } from 'react';

const FilterListIcon: FC = () => {
  return (
    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_8312_73684" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="26">
        <rect x="0.858398" y="0.929688" width="24.1416" height="24.1416" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_8312_73684)">
        <path
          d="M10.9173 19.0356V17.0238H14.9409V19.0356H10.9173ZM3.87598 8.97664V6.96484H21.9821V8.97664H3.87598ZM6.89367 14.0061V11.9943H18.9644V14.0061H6.89367Z"
          fill="#05173D"
          fillOpacity="0.6"
        />
      </g>
    </svg>
  );
};

export default FilterListIcon;
