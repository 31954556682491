// packages
import { useIntl } from 'react-intl';
// components
import NoTeamMembersForOperatorIcon from 'icons/NoTeamMembersForOperatorIcon';

const NoTeamMembers = () => {
  const intl = useIntl();

  return (
    <div className="flex flex-col items-center w-full sm:w-[400px] mx-auto mt-11 gap-y-2">
      <NoTeamMembersForOperatorIcon />
      <span className="text-xs text-specialGray-075 text-center w-[135px]">{intl.formatMessage({ id: 'hire_organisation_no_team_members' })}</span>
    </div>
  );
};

export default NoTeamMembers;
