import { useIntl } from 'react-intl';
// packages
import { useEffect, useMemo, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
// components
import SelectInputGroup from 'system/SelectInputGroup';
// generated
import { VacancyFragments$data } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { VacancyQueriesOrganizationMembersQuery } from 'schemas/vacancy/__generated__/VacancyQueriesOrganizationMembersQuery.graphql';
// schemas
import { GET_ORGANIZATION_MEMBERS } from 'schemas/vacancy/VacancyQueries';
// hooks
import { useGetHireProfile } from 'hooks/hireProfileHooks';

const SelectInputGroupWithOrgMembers = ({ vacancy }: { vacancy: VacancyFragments$data }) => {
  const intl = useIntl();

  const { organizationId } = useGetHireProfile();

  const [selectedOrganizationMember, setSelectedOrganizationMember] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const { organizationMembers } = useLazyLoadQuery<VacancyQueriesOrganizationMembersQuery>(GET_ORGANIZATION_MEMBERS, {
    orgId: organizationId || '',
    skip: !organizationId,
  });

  const mappingOrganizationMembers = useMemo(() => {
    return organizationMembers?.edges?.reduce((acc: { id: string; name: string }[], org) => {
      if (org?.node?.role !== 'invited') {
        acc.push({
          id: org?.node?.id as string,
          name: org?.node?.profile?.info
            ? `${org?.node?.profile.info?.firstName} ${org?.node?.profile.info?.lastName}`
            : intl.formatMessage({ id: 'hire_organization_member_id' }, { value: org?.node?.id }),
        });
      }
      return acc;
    }, []);
  }, [intl, organizationMembers?.edges]);

  const defaultOwnerProfile = useMemo(() => {
    const ownerData = organizationMembers?.edges?.find(members => members?.node?.role === 'owner');
    if (!ownerData) {
      return null;
    }
    const ownerInfo = ownerData?.node?.profile?.info;
    return {
      id: `${ownerData?.node?.id}`,
      name: ownerInfo?.firstName ? `${ownerInfo.firstName} ${ownerInfo.lastName}` : intl.formatMessage({ id: 'hire_organization_member_id' }, { value: ownerData?.node?.id }),
    };
  }, [organizationMembers, intl]);

  useEffect(() => {
    if (!selectedOrganizationMember) {
      if (vacancy?.organizationMember?.id) {
        const profileInfo = vacancy?.organizationMember?.profile.info;
        setSelectedOrganizationMember({
          id: vacancy?.organizationMember?.id,
          name: profileInfo?.firstName
            ? `${profileInfo.firstName} ${profileInfo.lastName}`
            : intl.formatMessage({ id: 'hire_organization_member_id' }, { value: vacancy.organizationMember.id }),
        });
      } else {
        setSelectedOrganizationMember(defaultOwnerProfile);
      }
    }
  }, [organizationId, vacancy?.organizationMember, selectedOrganizationMember, defaultOwnerProfile, intl]);

  return (
    <div className="flex w-1/3">
      <SelectInputGroup
        name="orgMemberId"
        containerMargin="mb-0"
        options={mappingOrganizationMembers}
        label={{ id: 'hire_form_step_1_job_description_org_member_label' }}
        placeholder={{ id: 'hire_form_step_1_empty' }}
        onChangeSelect={() => {}}
        defaultSelectedElement={selectedOrganizationMember}
        setSelectedItem={setSelectedOrganizationMember}
        selectedItem={selectedOrganizationMember}
        autoComplete="off"
      />
    </div>
  );
};

export default SelectInputGroupWithOrgMembers;
