// packages
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
// components
import { Button } from 'system/Button';
import HiddenCrewDataPopover from 'system/ProfileSlide/components/HiddenCrewDataPopover';
import HighlightedEducationItem from 'app/Profile/Highlighted/HighlightedEducationItem';
// models
import { educationItemFragmentType } from '../types';
import { ProfileSlideEducationsProps } from 'models/modelsOfComponents';
import { RESTRICTED_PROFILE_DATA_COUNTER } from 'models/enums';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';

const ProfileSlideEducations = ({ educationFragments }: ProfileSlideEducationsProps) => {
  const intl = useIntl();
  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);

  const [showAllEducation, setShowAllEducation] = useState<boolean>(false);
  const educationsCounter = educationFragments?.length || 0;
  const showMoreEducations = educationsCounter - RESTRICTED_PROFILE_DATA_COUNTER.EDUCATIONS;

  const showEducations = () => {
    const amountOfRendering = getCurrentOrganisation.isSelected && showAllEducation ? educationsCounter : RESTRICTED_PROFILE_DATA_COUNTER.EDUCATIONS;
    return (education: educationItemFragmentType, index: number) => index < amountOfRendering && <HighlightedEducationItem key={education.id} education={education} full />;
  };

  return (
    <div className="flex flex-col mb-4">
      <div className="flex mb-3">
        <p className="uppercase text-specialGray-075 font-semibold w-1/2 text-xs">{intl.formatMessage({ id: 'profile_educationsTitle' })}</p>
        <p className="text-sm leading-6 text-specialGray-1 relative bottom-1">
          {educationsCounter}&nbsp;
          {educationsCounter > 1 ? intl.formatMessage({ id: 'profile_educationsTitle' }) : intl.formatMessage({ id: 'profile_educationTitle' })}
        </p>
      </div>
      <div className="flex flex-col w-full">{educationFragments?.map(showEducations())}</div>
      {educationsCounter > RESTRICTED_PROFILE_DATA_COUNTER.EDUCATIONS && getCurrentOrganisation.isSelected && (
        <Button
          type="button"
          buttonType="white-primary"
          label={{ id: showAllEducation ? 'profile_slide_hide_elements' : 'profile_slide_show_all_educations' }}
          onClick={() => setShowAllEducation(prevState => !prevState)}
          additionalClasses="mx-auto"
        />
      )}

      {educationsCounter > RESTRICTED_PROFILE_DATA_COUNTER.EDUCATIONS && !getCurrentOrganisation.isSelected && (
        <HiddenCrewDataPopover label={intl.formatMessage({ id: 'profile_education_with_restriction_amount' }, { amount: showMoreEducations })} />
      )}
    </div>
  );
};

export default ProfileSlideEducations;
