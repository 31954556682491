import Skeleton from 'react-loading-skeleton';
import { PropsWithChildren } from 'react';

const OverviewProfileCrewEVTitleSkeleton = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex flex-col w-full">
      <Skeleton containerClassName="flex mb-2 h-8" className="mr-4" width={150} height={24} />
      <div className="flex h-5">
        <Skeleton width={83} height={14} />
        {children}
      </div>
    </div>
  );
};

export default OverviewProfileCrewEVTitleSkeleton;
