// packages
import { Suspense, useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useLazyLoadQuery } from 'react-relay';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
// components
import LineSvg from 'icons/LineSvg';
import SlideOver from 'system/SlideOver';
import FilterListIcon from 'icons/FilterListIcon';
import CustomButton from 'system/Buttons/CustomButton';
import AppliedCrewItem from './components/AppliedCrewItem';
import { LazyLoader } from 'system/skeletons/wrapper/LazyLoader';
import FilterListSlider from './components/FilterListSlider/FilterListSlider';
import FilterSliderWrapper from 'system/Layouts/FilterSliderWrapper/FilterSliderWrapper';
import ListOfReviewAppliedCrewItemSkeleton from 'system/skeletons/lists/ListOfReviewAppliedCrew/components/ListOfReviewAppliedCrewItemSkeleton';
import SlideOverWithCrewContact from 'app/ProfileCrew/ProfileCrewViews/ProfileCrewEmployerView/MainCrewInformationEmployerView/components/ButtonToContactWithCrew/SlideOverWithCrewContact';
// generated
import { ApplicationQueriesGetListOfAppliedCrewQuery } from 'schemas/Application/__generated__/ApplicationQueriesGetListOfAppliedCrewQuery.graphql';
import { ApplicationQueriesGetListOfMatchedCrewQuery } from 'schemas/Application/__generated__/ApplicationQueriesGetListOfMatchedCrewQuery.graphql';
// models
import { FILTER_TYPE, VACANCY_DESCRIPTION_TYPE_OF_LIST } from 'models/enums';
// schemas
import { GET_LIST_OF_APPLIED_CREW, GET_LIST_OF_MATCHED_CREW } from 'schemas/Application/ApplicationQueries';
// hooks
import { useToggleShowWindow } from 'hooks/useToggleShowModal';
// route
import { RedirectState } from 'models/routeLocationState';
// generated
import { ApplicationFragments$key } from 'schemas/Application/__generated__/ApplicationFragments.graphql';
import { AppliedAndInvitedCrewDataProps } from 'app/ProfileEmployer/components/ReviewApplicationsPage/types';

const ReviewApplicationsPage = () => {
  const intl = useIntl();
  const { jobDescriptionEditId, profileSideBarId } = useParams();

  const navigate = useNavigate();

  const { search, state } = useLocation();
  const [searchParams] = useSearchParams();

  const { toggleShowWindow, showWindow } = useToggleShowWindow();
  const [filterValue, setFilterValue] = useState<string | null>(null);

  const appliedCrews = useLazyLoadQuery<ApplicationQueriesGetListOfAppliedCrewQuery>(
    GET_LIST_OF_APPLIED_CREW,
    { vacancyId: jobDescriptionEditId!, skip: !jobDescriptionEditId },
    { fetchPolicy: 'store-and-network' },
  );

  const matchedCrews = useLazyLoadQuery<ApplicationQueriesGetListOfMatchedCrewQuery>(
    GET_LIST_OF_MATCHED_CREW,
    { vacancyId: jobDescriptionEditId!, skip: !jobDescriptionEditId },
    { fetchPolicy: 'store-and-network' },
  );

  const handleFiltering = useCallback(
    (data: AppliedAndInvitedCrewDataProps) => {
      const countryFilters = searchParams.get(FILTER_TYPE.COUNTRIES)?.split(',') || '';
      return countryFilters
        ? data?.filter(item => {
            const profileCountry = item?.node?.owner?.info?.country.isoCode || '';
            return countryFilters.includes(profileCountry);
          })
        : data;
    },
    [searchParams],
  );

  const appliedCrewsFiltered = handleFiltering(appliedCrews.applications?.edges);
  const matchedCrewsFiltered = handleFiltering(matchedCrews.applicationInviteCross?.edges);

  const handleCloseSlide = useCallback(() => {
    navigate({
      pathname: `${(state as RedirectState)?.previousPathname}`,
      search,
    });
  }, [navigate, state, search]);

  useEffect(() => {
    setFilterValue(searchParams.get('show-only'));
  }, [searchParams]);

  return (
    <>
      <SlideOver show={!!profileSideBarId} onClose={handleCloseSlide} size="max-w-xl">
        <Outlet />
      </SlideOver>

      <Suspense>
        <SlideOverWithCrewContact />
      </Suspense>

      <FilterSliderWrapper filterButtonEvent={toggleShowWindow(false)} isOpenListOfFilters={showWindow}>
        {showWindow && <FilterListSlider />}
      </FilterSliderWrapper>
      <div className="flex flex-col">
        <div className="flex justify-between mb-8">
          <div className="flex space-x-8 items-center">
            <h2 className="text-2xl text-darkBlue mb-1 font-medium">{intl.formatMessage({ id: 'hire_job_description_review_applied_crew_title' })}</h2>
            <span className="text-specialGray-075 text-sm">
              {intl.formatMessage({ id: 'hire_job_description_amount_users' }, { amount: appliedCrews?.applications?.edges?.length ?? 0 })}
            </span>
            {!isEmpty(matchedCrews?.applicationInviteCross?.edges) && (
              <span className="text-specialGray-075 text-sm">
                {intl.formatMessage({ id: 'hire_job_description_amount_matched_users' }, { amount: matchedCrews?.applicationInviteCross?.edges?.length ?? 0 })}
              </span>
            )}
          </div>
          <CustomButton
            label={{ id: 'label_filter' }}
            onClick={toggleShowWindow(true)}
            classNameStyle="w-28 justify-center text-sm disabled:opacity-50 px-3 py-3.5 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-xl bg-specialGray-004 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 mr-1 space-x-2"
          >
            <FilterListIcon />
          </CustomButton>
        </div>
        {!isEmpty(matchedCrewsFiltered) && filterValue !== 'Uninvited' && (
          <>
            <div className="flex flex-col gap-y-4">
              {matchedCrewsFiltered?.map(app => (
                <Suspense
                  key={app?.node?.id}
                  fallback={
                    <LazyLoader
                      mainComponent={<AppliedCrewItem typeOfList={VACANCY_DESCRIPTION_TYPE_OF_LIST.MATCHED} applicationFragment={app?.node as ApplicationFragments$key} />}
                    >
                      <ListOfReviewAppliedCrewItemSkeleton />
                    </LazyLoader>
                  }
                >
                  <AppliedCrewItem key={app?.node?.id} typeOfList={VACANCY_DESCRIPTION_TYPE_OF_LIST.MATCHED} applicationFragment={app?.node as ApplicationFragments$key} />
                </Suspense>
              ))}
            </div>
          </>
        )}
        {!isEmpty(matchedCrewsFiltered) && !isEmpty(appliedCrewsFiltered) && !filterValue && <LineSvg classes="my-5 px-4" />}
        {!isEmpty(appliedCrewsFiltered) && filterValue !== 'Invited' && (
          <div className="flex flex-col gap-y-4">
            {appliedCrewsFiltered?.map(app => (
              <Suspense
                key={app?.node?.id}
                fallback={
                  <LazyLoader mainComponent={<AppliedCrewItem typeOfList={VACANCY_DESCRIPTION_TYPE_OF_LIST.APPLIED} applicationFragment={app?.node as ApplicationFragments$key} />}>
                    <ListOfReviewAppliedCrewItemSkeleton />
                  </LazyLoader>
                }
              >
                <AppliedCrewItem key={app?.node?.id} typeOfList={VACANCY_DESCRIPTION_TYPE_OF_LIST.APPLIED} applicationFragment={app?.node as ApplicationFragments$key} />
              </Suspense>
            ))}
          </div>
        )}
        {isEmpty(matchedCrewsFiltered) && isEmpty(appliedCrewsFiltered) && (
          <div className="mx-auto mt-10">{intl.formatMessage({ id: 'review_applications_applied_crews_empty_page' })}</div>
        )}
      </div>
    </>
  );
};

export default ReviewApplicationsPage;
