import React from 'react';

const CloseButtonIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6121 1.66797H6.38795C3.55664 1.66797 1.66711 3.69513 1.66711 6.59797V13.4046C1.66711 16.3102 3.55159 18.3346 6.38795 18.3346H13.6113C16.4485 18.3346 18.3338 16.3102 18.3338 13.4046V6.59797C18.3338 3.69256 16.4486 1.66797 13.6121 1.66797ZM6.38795 2.91797H13.6121C15.7375 2.91797 17.0838 4.36377 17.0838 6.59797V13.4046C17.0838 15.6389 15.7374 17.0846 13.6113 17.0846H6.38795C4.26275 17.0846 2.91711 15.6391 2.91711 13.4046V6.59797C2.91711 4.36661 4.2674 2.91797 6.38795 2.91797ZM7.55897 7.55183C7.78088 7.32996 8.1281 7.30983 8.37276 7.4914L8.44285 7.55192L9.99923 9.10862L11.5543 7.5535C11.7984 7.30942 12.1941 7.30942 12.4382 7.5535C12.6601 7.77539 12.6803 8.12261 12.4987 8.36729L12.4382 8.43738L10.883 9.99259L12.4395 11.5494C12.6836 11.7935 12.6835 12.1892 12.4394 12.4333C12.2175 12.6552 11.8703 12.6753 11.6256 12.4937L11.5555 12.4332L9.99913 10.8765L8.44489 12.4307C8.20081 12.6748 7.80509 12.6748 7.56101 12.4307C7.33912 12.2088 7.31895 11.8616 7.50049 11.6169L7.56101 11.5468L9.11534 9.9925L7.55888 8.43571C7.31483 8.19161 7.31487 7.79588 7.55897 7.55183Z"
        fill="#082051"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default CloseButtonIcon;
