// packages
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useField } from 'formik';
import { useIntl } from 'react-intl';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
// components
import { FieldErrorMessage } from '../FieldErrorMessage';
import LabelComponent from '../LabelComponent/LabelComponent';
// models
import { InputGroupProps } from 'models/modelsOfComponents';

export const InputGroup = ({
  name,
  label,
  description,
  helpText,
  placeholderText,
  autoFocus = false,
  type = 'text',
  typeInput,
  inputCustomStyle = '',
  children,
  trackingCallback,
  valueForDynamicError,
}: InputGroupProps) => {
  const [field, meta, helper] = useField<string>(name);

  const intl = useIntl();
  const showError = meta.touched && meta.error;
  const inputRef = useRef<HTMLInputElement>(null);
  const validationByType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldValue = e.target.value;
    if (!isDigitOrEmpty(fieldValue) && typeInput === 'digital') {
      return;
    }
    helper.setValue(fieldValue);
  };

  const classNames = useMemo(
    () =>
      showError
        ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 text-xs sm:text-sm rounded-md'
        : 'shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full text-xs sm:text-sm border-specialGray-012 rounded-md text-darkBlue placeholder:text-specialGray-05',
    [showError],
  );

  useEffect(() => {
    helper.setValue(!!meta.initialValue ? meta.initialValue : '');
    // eslint-disable-next-line
  }, [meta.initialValue]);

  const handleBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      field.onBlur(event);
      if (trackingCallback && inputRef?.current?.value) {
        trackingCallback(inputRef.current.value);
      }
    },
    [field, trackingCallback],
  );

  return (
    <fieldset>
      {label && (
        <LabelComponent
          id={name}
          label={label}
          helpText={helpText}
          classesPopover="relative left-2 -bottom-0.5"
          popoverPosition="sm:pl-3 sm:left-full -left-[87px] sm:-top-5 sm:bottom bottom-[26px] sm:bottom-0"
          popoverArrowPosition="sm:rotate-[135deg] sm:top-7 sm:left-1.5 left-[94px] rotate-45 -bottom-[3px] sm:bottom-0"
        />
      )}
      <div className={`mt-2 relative ${showError ? 'rounded-md shadow-sm' : ''}`}>
        <input
          {...field}
          onBlur={handleBlur}
          onChange={validationByType}
          data-test-id={name}
          type={type}
          id={name}
          className={`${classNames} ${inputCustomStyle}`}
          placeholder={placeholderText ? intl.formatMessage(placeholderText) : ''}
          aria-describedby={description && name + '-description'}
          ref={inputRef}
          autoFocus={autoFocus}
        />

        {children ? <div className={`absolute inset-y-0 right-0 flex items-center ${showError ? 'pr-10' : 'pr-3'}`}>{children}</div> : null}
        {showError && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>

      {/* show field errors in various configurations */}
      {showError && <FieldErrorMessage error={meta.error} dynamicErrorValue={valueForDynamicError} />}

      {/* Only show the description if there is no error */}
      {!showError && description && (
        <p className="mt-2 text-xs sm:text-sm text-specialGray-075 my-auto sm:my-0" id={name + '-description'}>
          {intl.formatMessage(description)}
        </p>
      )}
    </fieldset>
  );
};
function isDigitOrEmpty(v: string) {
  const digitExp = /^[0-9\b]+$/;
  if (!v) {
    return true;
  }
  return digitExp.test(v);
}
