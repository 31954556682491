/**
 * @generated SignedSource<<db2b5f3b5ac952ee2c2d4705ad0fd649>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ProfileFragments_SearchDropDown_info$data = {
  readonly 'avatarUrl': string | null;
  readonly 'firstName': string;
  readonly 'id': string;
  readonly 'lastName': string;
  readonly 'primaryDuty': {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly ' $fragmentType': 'ProfileFragments_SearchDropDown_info';
};
export type ProfileFragments_SearchDropDown_info$key = {
  readonly ' $data'?: ProfileFragments_SearchDropDown_info$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragments_SearchDropDown_info'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'id',
    storageKey: null,
  };
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'ProfileFragments_SearchDropDown_info',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'Duty',
        kind: 'LinkedField',
        name: 'primaryDuty',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'name',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'firstName',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'lastName',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'avatarUrl',
        storageKey: null,
      },
    ],
    type: 'ProfileInfo',
    abstractKey: null,
  };
})();

(node as any).hash = '0b7479d5b28ad2b40f3841083f145d15';

export default node;
