/**
 * @generated SignedSource<<0807ca84148af2103110e7d817a50451>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ProfileSubscriptionsProfileScoreDidChangeSubscription$variables = {
  pid: string;
  skip: boolean;
};
export type ProfileSubscriptionsProfileScoreDidChangeSubscription$data = {
  readonly profileScoreDidChange?: {
    readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsScoreSubscription'>;
  };
};
export type ProfileSubscriptionsProfileScoreDidChangeSubscription = {
  response: ProfileSubscriptionsProfileScoreDidChangeSubscription$data;
  variables: ProfileSubscriptionsProfileScoreDidChangeSubscription$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'pid',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'skip',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'pid',
        variableName: 'pid',
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileSubscriptionsProfileScoreDidChangeSubscription',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: 'ProfileScore',
              kind: 'LinkedField',
              name: 'profileScoreDidChange',
              plural: false,
              selections: [
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'ProfileFragmentsScoreSubscription',
                },
              ],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Subscription',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileSubscriptionsProfileScoreDidChangeSubscription',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: 'ProfileScore',
              kind: 'LinkedField',
              name: 'profileScoreDidChange',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'id',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'details',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'summary',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
        },
      ],
    },
    params: {
      cacheID: '3eb00abcd179a18b5f511a5cf32143a4',
      id: null,
      metadata: {},
      name: 'ProfileSubscriptionsProfileScoreDidChangeSubscription',
      operationKind: 'subscription',
      text: 'subscription ProfileSubscriptionsProfileScoreDidChangeSubscription(\n  $pid: ID!\n  $skip: Boolean!\n) {\n  profileScoreDidChange(pid: $pid) @skip(if: $skip) {\n    ...ProfileFragmentsScoreSubscription\n    id\n  }\n}\n\nfragment ProfileFragmentsScoreSubscription on ProfileScore {\n  id\n  details\n  summary\n}\n',
    },
  };
})();

(node as any).hash = 'ac26b9b4050085609d68aba184388c9c';

export default node;
