// packages
import { useState, useEffect, Suspense, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import { useFormik, FormikProvider, Form } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFragment, useLazyLoadQuery, useMutation, useRefetchableFragment } from 'react-relay';
// routing
import { RoutePaths } from 'app/routing';
// components
import { Button } from 'system/Button';
import { Alert } from 'system/Alert/Alert';
import { DateInputGroup } from 'system/DateInputGroup';
import AlertForError from 'system/Alert/AlertForError';
import SelectInputGroup from 'system/SelectInputGroup';
import { InputGroup } from 'system/InputGroups/InputGroup';
import { CheckboxInputGroup } from 'system/CheckboxInputGroup';
import { TextareaInputGroup } from 'system/TextareaInputGroup';
import PhoneInputComponent from './components/PhoneInputComponent';
import { ProfileAvatar } from 'app/Avatars/ProfileAvatar/ProfileAvatar';
import { AvatarUpload } from 'app/Avatars/ProfileAvatar/Upload/AvatarUpload';
import HighlightElementWrapper from 'system/wrappers/HighlightElementWrapper';
import SelectDutyComponent from 'system/SelectDutyComponent/SelectDutyComponent';
import PhoneNumberSkeleton from 'system/skeletons/components/PhoneNumberSkeleton';
import SelectCountryInputGroup from 'system/SelectCountryInputGroup/SelectCountryInputGroup';
// generated
import { CountryFragments_info$key } from 'schemas/country/__generated__/CountryFragments_info.graphql';
import { ProfileFragments_info$key } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
import { ProfileQueriesRootQuery } from 'schemas/profile/__generated__/ProfileQueriesRootQuery.graphql';
import { ProfileFragments$data, ProfileFragments$key } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { ProfileQueriesRootRefreshQuery } from 'schemas/profile/__generated__/ProfileQueriesRootRefreshQuery.graphql';
import { ServiceQueriesFormGetAllDutiesQuery } from 'schemas/services/__generated__/ServiceQueriesFormGetAllDutiesQuery.graphql';
import { ServiceFragmentsOneDuty$data, ServiceFragmentsOneDuty$key } from 'schemas/services/__generated__/ServiceFragmentsOneDuty.graphql';
import { ProfileMutationsUpsertProfileOIDClaimsMutation } from 'schemas/profile/__generated__/ProfileMutationsUpsertProfileOIDClaimsMutation.graphql';
import { ProfileMutationsCreateNotificationPreferenceMutation } from 'schemas/profile/__generated__/ProfileMutationsCreateNotificationPreferenceMutation.graphql';
import { ProfileMutationsUpdateNotificationPreferenceMutation } from 'schemas/profile/__generated__/ProfileMutationsUpdateNotificationPreferenceMutation.graphql';
import { BasicInfoFormMutationsUpdateMutation, BasicInfoFormMutationsUpdateMutation$data } from 'schemas/forms/__generated__/BasicInfoFormMutationsUpdateMutation.graphql';
import { BasicInfoFormMutationsCreateMutation, BasicInfoFormMutationsCreateMutation$data } from 'schemas/forms/__generated__/BasicInfoFormMutationsCreateMutation.graphql';
// schemas
import { PROFILE_QUERY } from 'schemas/profile/ProfileQueries';
import { GET_ONE_DUTY } from 'schemas/services/ServiceFragments';
import { COUNTRY_FRAGMENT } from 'schemas/country/CountryFragments';
import { GET_ALL_DUTIES_QUERY } from 'schemas/services/ServiceQueries';
import { PROFILE_FRAGMENT, PROFILE_INFO_FRAGMENT } from 'schemas/profile/ProfileFragments';
import { CREATE_PROFILE_INFO_MUTATION, UPDATE_PROFILE_INFO_MUTATION } from 'schemas/forms/BasicInfoFormMutations';
import { CREATE_NOTIFICATION_PREFERENCE, UPDATE_NOTIFICATION_PREFERENCE, UPDATE_PROFILE_OID_CLAIM } from 'schemas/profile/ProfileMutations';
// hooks
import { useAuth } from 'authentication';
import { BasicInfoFormValidateSchema } from 'formHelpers/validationsOfForms';
import { BasicInfoFormInitialValues } from 'formHelpers/initialValuesOfForms';
// models
import { ICountry } from 'models/ICountry';
import { BasicInfoFormProps } from './types';
import { BasicInfoFormData } from 'models/modelsOfForms';
import { numberOfYearsOfExperience } from 'mocks/mockData';
import { SIGN_UP_STEP_WITH_USER_EXPERIENCE } from 'models/enums';
// styles
import 'react-phone-number-input/style.css';

// BasicInfoForm shows the form that allows users to edit their profile's basic info or setup their profile
// when the user the app for the first time.
export const BasicInfoForm = ({ defaultSubmitting = false, defaultError }: BasicInfoFormProps) => {
  const { identity } = useAuth();

  const navigate = useNavigate();
  const { state } = useLocation();

  const selfProfileQueryRef = useLazyLoadQuery<ProfileQueriesRootQuery>(PROFILE_QUERY, { id: `${identity?.profileId}`, skip: !identity?.profileId });

  const [profile] = useRefetchableFragment<ProfileQueriesRootRefreshQuery, ProfileFragments$key>(PROFILE_FRAGMENT, selfProfileQueryRef.node!);
  const profileInfo = useFragment<ProfileFragments_info$key>(PROFILE_INFO_FRAGMENT, profile?.info || null);
  const countryData = useFragment<CountryFragments_info$key>(COUNTRY_FRAGMENT, profileInfo?.country || null);

  //skip unnecessary query if it's hire account (we don't need duty field)
  const allDuties = useLazyLoadQuery<ServiceQueriesFormGetAllDutiesQuery>(GET_ALL_DUTIES_QUERY, { skip: false });

  const [commitCreate] = useMutation<BasicInfoFormMutationsCreateMutation>(CREATE_PROFILE_INFO_MUTATION);
  const [commitUpdateProfileInfo] = useMutation<BasicInfoFormMutationsUpdateMutation>(UPDATE_PROFILE_INFO_MUTATION);
  const [commitUpdateProfileOIDClaim] = useMutation<ProfileMutationsUpsertProfileOIDClaimsMutation>(UPDATE_PROFILE_OID_CLAIM);
  const [commitCreateNotificationPreference] = useMutation<ProfileMutationsCreateNotificationPreferenceMutation>(CREATE_NOTIFICATION_PREFERENCE);
  const [commitUpdateNotificationPreference] = useMutation<ProfileMutationsUpdateNotificationPreferenceMutation>(UPDATE_NOTIFICATION_PREFERENCE);

  const dataOfDuty = useFragment<ServiceFragmentsOneDuty$key>(GET_ONE_DUTY, profileInfo?.primaryDuty || null);

  const [uploadedSize, setUploadedSize] = useState<string>('');
  const [error, setError] = useState<Error | undefined>(defaultError);
  const [selectedUserExperience, setSelectedUserExperience] = useState<{ id: SIGN_UP_STEP_WITH_USER_EXPERIENCE | 'none'; name: string }>();

  const [getPhoneNumberInProfile, setPhoneNumberInProfile] = useState<string>('');
  const [currentUserFromCognito, setCurrentUserFromCognito] = useState<any>(null);

  useEffect(() => {
    if (currentUserFromCognito && !getPhoneNumberInProfile) {
      setPhoneNumberInProfile(currentUserFromCognito.attributes.phone_number);
    }
  }, [currentUserFromCognito, getPhoneNumberInProfile]);

  const [showSizeWarning, setShowSizeWarning] = useState<boolean>(false);
  const [isInformMe, setIsInformMe] = useState<boolean>(
    profile?.notificationPreference ? profile?.notificationPreference?.jobOpening && profile?.notificationPreference?.tipsToImprove : true,
  );

  const [url, setUrl] = useState<string>('');

  const formik = useFormik<BasicInfoFormData>({
    initialValues: BasicInfoFormInitialValues(profileInfo, countryData, dataOfDuty, profile?.notificationPreference),
    validationSchema: BasicInfoFormValidateSchema,
    onSubmit,
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(response => setCurrentUserFromCognito(response))
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    if (profileInfo?.yearsOfExperience) {
      setSelectedUserExperience(numberOfYearsOfExperience.find(exp => exp.id === (profileInfo?.yearsOfExperience as SIGN_UP_STEP_WITH_USER_EXPERIENCE)));
    } else {
      setSelectedUserExperience({ id: 'none', name: 'None' });
    }
  }, [profileInfo?.yearsOfExperience]);

  const onCompleted = useCallback(
    (response: BasicInfoFormMutationsCreateMutation$data | BasicInfoFormMutationsUpdateMutation$data) => {
      const getPrimaryDuty =
        (response as BasicInfoFormMutationsCreateMutation$data)?.createProfileInfo?.primaryDuty ||
        (response as BasicInfoFormMutationsUpdateMutation$data)?.updateProfileInfo?.primaryDuty;

      if (getPrimaryDuty?.id !== profileInfo?.primaryDuty?.id) {
        window.analytics?.track('wui-added-duty-to-profile', {
          primaryDutyName: getPrimaryDuty?.name,
        });
      }
      setError(undefined);
      formik.setSubmitting(false);
    },
    [formik, profileInfo?.primaryDuty?.id],
  );

  const handleCatchError = useCallback((error: Error) => {
    setError(error);
    formik.setSubmitting(false);
    // eslint-disable-next-line
  }, []);

  const handleSavePhoneNumber = async (phoneNumber?: string) => {
    if ((!phoneNumber && !currentUserFromCognito.attributes.phone_number_verified) || phoneNumber === currentUserFromCognito.attributes.phone_number) {
      return;
    }
    try {
      await Auth.updateUserAttributes(currentUserFromCognito, {
        phone_number: phoneNumber,
      });
      commitUpdateProfileOIDClaim({
        variables: { username: '' },
      });
    } catch (e) {
      handleCatchError(e as Error);
    }
  };

  const handleCreateOrUpdateProfile = useCallback(
    (data: BasicInfoFormData) => {
      const profileInfoVariables = {
        id: profileInfo?.id || '',
        data: {
          ownerId: (profile as ProfileFragments$data).id,
          birthDate: data.birthDate ? data.birthDate.toISOString() : '',
          firstName: data.firstName,
          lastName: data.lastName,
          bio: data.bio ? data.bio : null,
          countryId: data.countryId || '',
          avatarUrl: url === '' ? profileInfo?.avatarUrl : url,
          primaryDutyId: !!data.mainDutyId && data.mainDutyId !== 'none' ? data.mainDutyId : null,
          yearsOfExperience: data.yearsOfExperience !== 'none' ? data.yearsOfExperience : undefined,
        },
      };

      if (!profileInfo) {
        commitCreate({
          variables: profileInfoVariables,
          onCompleted,
          onError: handleCatchError,
        });
      } else {
        commitUpdateProfileInfo({
          variables: profileInfoVariables,
          onCompleted,
          onError: handleCatchError,
        });
      }
    },
    [commitCreate, commitUpdateProfileInfo, handleCatchError, onCompleted, profile, profileInfo, url],
  );

  const handleCreateOrUpdateNotificationPreference = (value: boolean) => {
    const profileNotificationPreferenceVariablesData = {
      ownerId: profile?.id as string,
      tipsToImprove: JSON.parse(String(value)),
      jobOpening: JSON.parse(String(value)),
    };
    if (!profile?.notificationPreference?.id) {
      commitCreateNotificationPreference({
        variables: {
          data: profileNotificationPreferenceVariablesData,
        },
      });
    } else {
      commitUpdateNotificationPreference({
        variables: {
          id: profile?.notificationPreference?.id as string,
          data: profileNotificationPreferenceVariablesData,
        },
      });
    }
  };

  async function onSubmit(data: BasicInfoFormData) {
    if (data.bio !== profileInfo?.bio) {
      window.analytics?.track('wui-added-bio-in-profile');
    }
    await handleSavePhoneNumber(data.phoneNumber);
    handleCreateOrUpdateProfile(data);
    handleCreateOrUpdateNotificationPreference(data.informMe);
    navigate({ pathname: RoutePaths.ProfileBase }, { state: { isNewHire: true } });
  }

  const handleChangeCheckbox = useCallback((value: boolean) => {
    setIsInformMe(value);
  }, []);

  const closeEditModal = useCallback(() => {
    navigate({ pathname: RoutePaths.ProfileBase }, { state: { isNewHire: true } });
  }, [navigate]);

  return (
    <FormikProvider value={formik}>
      <Form className="space-y-6">
        {error && <AlertForError heading={{ id: 'profile_failedSave' }} error={error} />}

        <section className="flex sm:items-end flex-col sm:flex-row">
          <div className="mx-auto sm:mx-0">
            <ProfileAvatar
              avatarUrl={profileInfo?.avatarUrl}
              updateAvatar={url}
              nameAbbreviation={profileInfo ? `${profileInfo?.firstName[0]}${profileInfo?.lastName[0]}` : ''}
              profile={profile}
            />
          </div>

          <div className="sm:ml-5 sm:mr-5 mx-auto mt-5 sm:mt-0">
            <AvatarUpload
              fileSize={7864320}
              onUrlReady={url => {
                setUrl(url);
              }}
              warningSize={(warningSizeError: boolean, uploadedSize: string) => {
                setShowSizeWarning(warningSizeError);
                setUploadedSize(uploadedSize);
              }}
              buttonText={{ id: 'avatar_upload_label' }}
            />
          </div>
        </section>

        {showSizeWarning && <Alert heading={{ id: 'warning_head_data' }} message={{ id: 'warning_incorrect__size', description: { uploadedSize } }} type="warning" />}
        <InputGroup name="firstName" label={{ id: 'profile_first_name' }} autoFocus />
        <InputGroup name="lastName" label={{ id: 'profile_last_name' }} />
        <DateInputGroup name="birthDate" label={{ id: 'profile_date_of_birth' }} />

        <div className="flex gap-x-4">
          <SelectDutyComponent dataOfDuty={dataOfDuty as ServiceFragmentsOneDuty$data} nameField="mainDutyId" optional defaultByNull allDuties={allDuties} />

          <SelectInputGroup
            name="yearsOfExperience"
            options={[{ id: 'none', name: 'None' }, ...numberOfYearsOfExperience]}
            label={{ id: 'profile_years_of_work_experience' }}
            onChangeSelect={() => {}}
            defaultSelectedElement={selectedUserExperience}
            setSelectedItem={setSelectedUserExperience}
            selectedItem={selectedUserExperience}
            autoComplete="chrome-off"
            placeholder={{ id: 'step_with_experience_label' }}
          />
        </div>

        <SelectCountryInputGroup name="countryId" label={{ id: 'profile_country_of_residence' }} placeholder={{ id: 'choose_country' }} initialValue={countryData as ICountry} />

        <Suspense fallback={<PhoneNumberSkeleton />}>
          <HighlightElementWrapper enableFlickering={state?.phoneInputFlickering} addPadding="outline-offset-4">
            <PhoneInputComponent
              name="phoneNumber"
              currentUserFromCognito={currentUserFromCognito}
              setCurrentUserFromCognito={setCurrentUserFromCognito}
              setFormError={setError}
              onCreateOrUpdateProfileInfo={handleCreateOrUpdateProfile}
            />
          </HighlightElementWrapper>
        </Suspense>

        <TextareaInputGroup
          name="bio"
          label={{ id: 'profile_bio' }}
          description={{ id: 'bio_description' }}
          maxCharCount={400}
          optional
          autoHeight={58}
          breakLinesLimit
          helpText={{ id: 'profile_bio_help_description' }}
        />

        <CheckboxInputGroup
          name="informMe"
          value={isInformMe.toString()}
          label={{ id: 'profile_inform_me_checkbox_label' }}
          onChange={handleChangeCheckbox}
          colorLabel="text-darkBlue"
        />

        <div className="text-right">
          <Button
            type="button"
            className="inline-flex leading-4 items-center justify-center px-6 py-3 mr-5 border border-gray-300 shadow-sm text-sm font-medium rounded-lg text-specialGray-075 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
            buttonType="secondary"
            label={{ id: 'cancel' }}
            onMouseDown={closeEditModal}
          />
          <Button type="submit" label={{ id: 'save_profile' }} disabled={formik.isSubmitting || defaultSubmitting} additionalClasses="px-6 !py-3" />
        </div>
      </Form>
    </FormikProvider>
  );
};
