// packages
import React, { useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { useIntl } from 'react-intl';
import { uniqueId } from 'lodash';
// components
import { FieldErrorMessage } from 'system/FieldErrorMessage';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
// models
import { TextareaWithMarkProps } from 'models/modelsOfComponents';
//utils
import { charsCounterWithoutDots } from 'formHelpers/utils';
import { getCanvasFontSize, getCleanWidth, getTextWidth } from 'utils';

export const TextAreaWithMark: React.FC<TextareaWithMarkProps> = ({ name, label, description, placeholderText, autoFocus = false, maxCharCount = 0, optional = false }) => {
  const intl = useIntl();
  const [id] = useState(() => uniqueId('ig-'));
  const [field, meta] = useField<string>({
    name,
    validate: v => (maxCharCount && v.length > maxCharCount ? 'text_area_input_group_too_many_chars' : ''),
  });
  const [count, setCount] = useState(0);
  const [onFocus, setOnFocus] = useState(false);
  const isCalled = useRef(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const divEl = useRef<HTMLDivElement>(null);

  const changeEvent = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCount(charsCounterWithoutDots(ev.target.value));
    field.onChange(ev);
  };

  const isEnoughLengthOfEachTask = field?.value.split('\n').every(el => el.length > 1);
  //Add live validation without unnecessary cases
  const showLiveErrorByLimitOfChars = meta.error && isEnoughLengthOfEachTask && meta.error;
  //Add validation after touched
  const showError = meta.touched && meta.error && meta.error;
  const classNames =
    showLiveErrorByLimitOfChars || showError
      ? 'block w-full pr-10 border-red-300 border-none text-red-900 placeholder:text-red-300 focus:outline-none focus:!border-none sm:text-sm rounded-md'
      : 'shadow-sm border-none focus:outline-none focus:!border-none block w-full text-xs sm:text-sm rounded-md placeholder:text-specialGray-05';

  useEffect(() => {
    if (autoFocus && textAreaRef?.current && !isCalled.current) {
      textAreaRef.current.focus();
    }
  }, [autoFocus, field.value]);

  useEffect(() => {
    //set initial counter
    if (!meta.touched) {
      setCount(meta.initialValue ? charsCounterWithoutDots(meta.initialValue) : 0);
    }
  }, [meta.initialValue, meta.touched]);

  function addCustomDotsToEachTask(target: HTMLTextAreaElement) {
    if (target) {
      const dots: HTMLDivElement | null = divEl.current;
      target.style.height = '0px';
      target.style.height = target.scrollHeight + 'px';
      if (dots) {
        dots.style.marginTop = -target.scrollHeight + 10 + 'px';
        let str = '';
        const containerWidth = getCleanWidth(target);
        target.value.split('\n').forEach(v => {
          //check line break , and if rows > 1 add additional \n for row (without this function dot will be always on the second row)
          const rows = Math.ceil(getTextWidth(v, getCanvasFontSize(target)) / containerWidth);
          let additionalLineBreak = '';
          //add dot in the right row
          for (let i = 0; i < rows - 1; i++) {
            additionalLineBreak += '\n';
          }
          //dot with space
          str += '&bull;&#10;' + additionalLineBreak;
        });
        dots.innerHTML = str;
        if (!target.value) {
          dots.innerHTML = '';
        }
      }
    }
  }

  useEffect(() => {
    if (textAreaRef?.current?.value && !isCalled.current) {
      //prevent re renders
      isCalled.current = true;
      addCustomDotsToEachTask(textAreaRef.current as HTMLTextAreaElement);
    }
  }, [field.value]);

  function calculateColor(): string {
    if (showLiveErrorByLimitOfChars || showError) {
      //colors based on TailWind
      return onFocus ? 'rgb(244, 63, 94)' : 'rgb(253, 164, 175)';
    }
    return onFocus ? '#3B82F6' : '#0820511F';
  }

  return (
    <>
      <div className="flex justify-between">
        <label htmlFor={id} className="block text-sm font-medium text-specialGray-1">
          {intl.formatMessage(label)}
          {optional && <span className="ml-1 text-specialGray-075 text-xs font-medium">{intl.formatMessage({ id: 'optional' })}</span>}
        </label>

        {maxCharCount > 0 && (
          <span className="text-xs sm:text-sm text-specialGray-05 my-auto sm:my-0">
            ({count}/{maxCharCount})
          </span>
        )}
      </div>
      <div className={'mt-2' + (showLiveErrorByLimitOfChars || showError ? 'relative rounded-md mt-2' : '')}>
        <div
          //using Style here because TailWind does not allow to use outline correctly
          style={{
            outlineWidth: onFocus ? '2px' : '1px',
            outlineStyle: 'solid',
            outlineColor: calculateColor(),
          }}
          className="relative overflow-y-auto block w-full text-xs sm:text-sm rounded-md h-28"
        >
          <textarea
            onFocus={() => setOnFocus(true)}
            {...field}
            ref={textAreaRef}
            rows={5}
            onBlur={e => {
              // add Focus coz TailWind does not allow to use focus-within correctly
              setOnFocus(false);
              field.onBlur(e);
            }}
            id="text-area-with-mark"
            data-test-id="text-area-with-mark"
            onChange={changeEvent}
            onInput={() => addCustomDotsToEachTask(textAreaRef.current as HTMLTextAreaElement)}
            className={`${classNames} resize-none overflow-hidden relative z-40 ${field.value ? 'p-2 pl-6' : 'py-2'} min-h-full leading-5`}
            placeholder={placeholderText && intl.formatMessage(placeholderText)}
            aria-describedby={description && id + '-description'}
          />
          <div ref={divEl} className="relative text-specialGray-015 text-2xl leading-5 whitespace-pre-line pl-2 z-50 select-none w-5" />
          {(showLiveErrorByLimitOfChars || showError) && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none z-50">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
          )}
        </div>
        {/* show field errors in various configurations */}

        {(showLiveErrorByLimitOfChars || showError) && <FieldErrorMessage error={meta.error} />}

        {/* Only show the description if there is no error */}
        {!showLiveErrorByLimitOfChars && !showError && description && (
          <p className="mt-2 text-xs sm:text-sm text-specialGray-075 my-auto" id={id + '-description'}>
            {intl.formatMessage(description, { count: maxCharCount })}
          </p>
        )}
      </div>
    </>
  );
};
