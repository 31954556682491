import React, { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
//models
import { FILTER_TYPE } from 'models/enums';

export const useFilterWithValue = (filterSearchName: FILTER_TYPE) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchUrlValue, setSearchUrlValue] = useState<string>(searchParams.get(filterSearchName) ?? '');

  const createSearchUrlWithValue = useCallback(
    (value: string, setValue: React.Dispatch<React.SetStateAction<string>>) => {
      const currentSearchParam = searchParams.get(filterSearchName);

      if (!!currentSearchParam && value === '') {
        searchParams.delete(filterSearchName);
        setValue('');
        setSearchParams(searchParams);
        setSearchUrlValue('');
      } else if (!!value) {
        //case when we click clear all search url
        if (currentSearchParam === null && searchUrlValue) {
          setValue('');
          setSearchUrlValue('');
          return;
        }
        setSearchUrlValue(value);
        const comparedSearchParas = Object.fromEntries([...searchParams]);
        setSearchParams({ ...comparedSearchParas, [filterSearchName]: value });
      }
    },
    [filterSearchName, searchParams, setSearchParams, searchUrlValue],
  );

  return {
    createSearchUrlWithValue,
    searchUrlValue,
  };
};

export default useFilterWithValue;
