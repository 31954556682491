// packages
import Skeleton from 'react-loading-skeleton';
// components
import EducationItemSkeleton from './components/EducationItemSkeleton';
import HighlightedSeaServiceItemSkeleton from './components/HighlightedSeaServiceItemSkeleton';

const CrewOverviewTabSkeleton = () => {
  return (
    <div className="flex flex-col">
      <div className="flex-col my-5">
        <Skeleton width={120} />
        <div className="flex justify-between">
          <div className="flex">
            <Skeleton width={100} />
            <Skeleton width={150} className="ml-6" />
          </div>
        </div>
      </div>
      <div className="flex w-full justify-between">
        <HighlightedSeaServiceItemSkeleton />
        <HighlightedSeaServiceItemSkeleton />
        <HighlightedSeaServiceItemSkeleton />
      </div>
      <div className="flex-col my-5">
        <Skeleton width={120} />
        <div className="flex justify-between">
          <Skeleton width={100} />
        </div>
      </div>
      <div className="border border-specialGray-01 rounded-2xl overflow-hidden">
        <div className="bg-specialGray-004 border-b">
          <Skeleton width={30} className="my-2 mx-4" />
        </div>
        <div className="flex flex-col md:flex-row flex-wrap justify-between sm:rounded-lg">
          <div className="border-specialGray-01 w-full md:w-1/2 border-b md:border-r py-4 pl-4 flex items-center">
            <Skeleton width={20} height={24} className="mr-5" />
            <Skeleton width={300} />
          </div>
          <div className="border-specialGray-01 w-full md:w-1/2 border-b py-4 pl-4 flex items-center">
            <Skeleton width={20} height={24} className="mr-5" />
            <Skeleton width={300} />
          </div>
          <div className="border-specialGray-01 w-full md:w-1/2 border-b md:border-b-0 md:border-r py-4 pl-4 flex items-center">
            <Skeleton width={20} height={24} className="mr-5" />
            <Skeleton width={300} />
          </div>
          <div className="border-specialGray-01 w-full md:w-1/2 py-4 pl-4 flex items-center">
            <Skeleton width={20} height={24} className="mr-5" />
            <Skeleton width={300} />
          </div>
        </div>
      </div>
      <div className="flex-col my-5">
        <Skeleton width={110} />
        <div className="flex justify-between">
          <Skeleton width={100} />
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between flex-wrap">
        <EducationItemSkeleton />
        <EducationItemSkeleton />
        <EducationItemSkeleton />
        <EducationItemSkeleton />
      </div>
    </div>
  );
};

export default CrewOverviewTabSkeleton;
