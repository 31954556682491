// packages
import { useCallback, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// components
import { Button } from 'system/Button';
import { Alert } from 'system/Alert/Alert';
import { InputGroup } from 'system/InputGroups/InputGroup';
import { LoginFormInitialValues } from 'formHelpers/initialValuesOfForms';
import { LoginFormValidateSchema } from 'formHelpers/validationsOfForms';
import { LoginFormData, LoginFormProps } from 'models/modelsOfComponents';
//hooks
import { useAccountTypeAnalytics } from 'hooks/anaytics/useFiltersAnalytics';
//routing
import { RoutePaths } from 'app/routing';
// models
import { PLATFORM_GROUP } from 'models/enums';

// LoginForm implements a form that can be used for user sign-in
export const LoginForm = ({ forgotPasswordLink, onLoginSuccess, defaultError, defaultSubmitting = false, onLoginEmailConfirmedFault }: LoginFormProps) => {
  const intl = useIntl();

  const [error, setError] = useState<Error | undefined>(defaultError);

  const analyticsAccountType = useAccountTypeAnalytics();
  const formik = useFormik<LoginFormData>({
    onSubmit,
    initialValues: LoginFormInitialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: LoginFormValidateSchema,
  });

  useEffect(() => {
    if (defaultSubmitting && !formik.isSubmitting) formik.setSubmitting(true);
  });

  async function onSubmit(d: LoginFormData) {
    let currentUserProfile: PLATFORM_GROUP | undefined = undefined;
    let err: Error | undefined;
    try {
      try {
        await Auth.signIn(d.email, d.password);
      } catch (e: unknown) {
        err = e as Error;
        if ((e as Error)?.name === 'QuotaExceededError') {
          window.localStorage.clear();
          await Auth.signIn(d.email, d.password);
          onLoginSuccess && !err && onLoginSuccess(d);
          setError(undefined);
          return;
        }
        throw Error((e as Error)?.message);
      }

      // NOTE: there is a race condition where the autenticated user is not
      // yet available when the redirect finishes after login. Instead we wait
      // here for the current user to be available immediately before considering
      // the signIn to be finished.
      const currentUser = await Auth.currentAuthenticatedUser();
      currentUserProfile = currentUser.attributes?.['custom:platform_group'];
      window.analytics?.identify(currentUser.attributes?.['custom:profile_id'], {
        email: d.email,
        platformGroup: currentUserProfile,
      });

      window.analytics?.track('wui-sign-in', {
        userId: currentUser.attributes?.['custom:profile_id'],
        platformGroup: currentUserProfile,
      });

      setError(undefined);
    } catch (e: unknown) {
      if ((e as Error)?.message === 'User is not confirmed.') {
        onLoginEmailConfirmedFault && onLoginEmailConfirmedFault(d.email, d.password);
      }
      err = e as Error;
    }
    setError(err as Error);
    formik.setSubmitting(false);
    onLoginSuccess && !err && onLoginSuccess({ platformGroup: currentUserProfile, ...d });
  }

  const handleMoveToRegisterForm = useCallback(() => {
    window.analytics?.track(`wui-${analyticsAccountType}-sign-in-registration-button-click`, {
      type_of_account: analyticsAccountType === 'employer' ? PLATFORM_GROUP.HIRE : PLATFORM_GROUP.CREW,
    });
  }, [analyticsAccountType]);

  return (
    <FormikProvider value={formik}>
      <Form className="gap-y-6 flex flex-col">
        {error ? (
          error.message === intl.formatMessage({ id: 'error_user_is_disabled_message' }) ? (
            <Alert heading={{ id: 'account_pending_confirmation' }} message={{ id: error.message }} type="info" />
          ) : (
            <Alert heading={{ id: 'auth_incorrect_login_or_password_title' }} message={{ id: 'auth_incorrect_login_or_password_description' }} type="info" />
          )
        ) : null}

        <InputGroup autoFocus type="email" name="email" label={{ id: 'default_auth_emailLabel' }} />
        <InputGroup type="password" name="password" label={{ id: 'default_auth_passwordLabel' }} />

        <div className="flex justify-end">
          <Link
            onClick={() => window.analytics?.track(`wui-${analyticsAccountType}-sign-in-forgot-your-password-button-click`)}
            className="text-blue text-xs text-right"
            to={forgotPasswordLink}
          >
            {intl.formatMessage({ id: 'login_forgotPassword' })}
          </Link>
        </div>

        <Button type="submit" label={{ id: 'login_loginCta' }} fullSize disabled={formik.isSubmitting} />

        <div className="flex justify-center text-xs">
          <Link data-test-id="register-link" onClick={handleMoveToRegisterForm} className="block text-blue" to={RoutePaths.AuthRegister}>
            {intl.formatMessage({ id: 'login_registerCta' })}
          </Link>
        </div>
      </Form>
    </FormikProvider>
  );
};
