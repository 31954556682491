/**
 * @generated SignedSource<<9fe4b874967376c62c5611184e838c54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type SeaServiceData = {
  description?: string | null;
  dutyId: string;
  end?: String | null;
  ownerId: string;
  rotations: number;
  start: String;
  vesselCnIso?: string | null;
  vesselGrossTonnage?: number | null;
  vesselImage?: string | null;
  vesselImo: number;
  vesselKindId?: string | null;
  vesselLength?: number | null;
  vesselName: string;
  vesselOperatorName?: string | null;
};
export type ServiceMutationsFormCreateMutation$variables = {
  connections: ReadonlyArray<string>;
  data: SeaServiceData;
};
export type ServiceMutationsFormCreateMutation$data = {
  readonly createSeaService: {
    readonly node: {
      readonly 'id': string;
      readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragments'>;
    } | null;
  };
};
export type ServiceMutationsFormCreateMutation = {
  response: ServiceMutationsFormCreateMutation$data;
  variables: ServiceMutationsFormCreateMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'connections',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'data',
    },
    v2 = [
      {
        kind: 'Variable',
        name: 'data',
        variableName: 'data',
      },
    ],
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'perma',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'ServiceMutationsFormCreateMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'SeaServiceEdge',
          kind: 'LinkedField',
          name: 'createSeaService',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'SeaService',
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                v3 /*: any*/,
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'ServiceFragments',
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'ServiceMutationsFormCreateMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'SeaServiceEdge',
          kind: 'LinkedField',
          name: 'createSeaService',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'SeaService',
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                v3 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'start',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'end',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'rotations',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'description',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Vessel',
                  kind: 'LinkedField',
                  name: 'vessel',
                  plural: false,
                  selections: [
                    v3 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'imo',
                      storageKey: null,
                    },
                    v4 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'grossTonnage',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'operatorName',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'VesselKind',
                      kind: 'LinkedField',
                      name: 'kind',
                      plural: false,
                      selections: [v3 /*: any*/, v4 /*: any*/, v5 /*: any*/],
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'length',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'image',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'cnISO',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'countryName',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Duty',
                  kind: 'LinkedField',
                  name: 'duty',
                  plural: false,
                  selections: [
                    v3 /*: any*/,
                    v4 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'alts',
                      storageKey: null,
                    },
                    v5 /*: any*/,
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Activity',
                  kind: 'LinkedField',
                  name: 'activities',
                  plural: true,
                  selections: [
                    v3 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'label',
                      storageKey: null,
                    },
                    v5 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'timesUsed',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              filters: null,
              handle: 'appendNode',
              key: '',
              kind: 'LinkedHandle',
              name: 'node',
              handleArgs: [
                {
                  kind: 'Variable',
                  name: 'connections',
                  variableName: 'connections',
                },
                {
                  kind: 'Literal',
                  name: 'edgeTypeName',
                  value: 'SeaServiceEdge',
                },
              ],
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '4b1a727c7e0e8399a286d5798546cf80',
      id: null,
      metadata: {},
      name: 'ServiceMutationsFormCreateMutation',
      operationKind: 'mutation',
      text: 'mutation ServiceMutationsFormCreateMutation(\n  $data: SeaServiceData!\n) {\n  createSeaService(data: $data) {\n    node {\n      id\n      ...ServiceFragments\n    }\n  }\n}\n\nfragment ServiceFragments on SeaService {\n  id\n  start\n  end\n  rotations\n  description\n  vessel {\n    ...ServiceFragmentsVessel\n    id\n  }\n  duty {\n    ...ServiceFragmentsOneDuty\n    id\n  }\n  activities {\n    ...ServiceFragmentsActivities\n    id\n  }\n}\n\nfragment ServiceFragmentsActivities on Activity {\n  id\n  label\n  perma\n  timesUsed\n}\n\nfragment ServiceFragmentsOneDuty on Duty {\n  id\n  name\n  alts\n  perma\n}\n\nfragment ServiceFragmentsVessel on Vessel {\n  id\n  imo\n  name\n  grossTonnage\n  operatorName\n  kind {\n    id\n    name\n    perma\n  }\n  length\n  image\n  cnISO\n  countryName\n}\n',
    },
  };
})();

(node as any).hash = '782cf105e22fed65351bf19acd7c7685';

export default node;
