//components
import CertificateTitle from './components/CertificateTitle';
//models
import { ICertificateInfoEdge } from 'models/ICertificate';
//utils
import { sliceAltsIfLong } from 'utils';
//types
import { OverviewCertificateItemProps } from '../types';

export const OverviewCertificateItem = ({ certificateInfo }: OverviewCertificateItemProps) => {
  return (
    <>
      <div className="pl-4">
        <CertificateTitle certificateInfo={certificateInfo as ICertificateInfoEdge} />
      </div>
      <div className={`text-specialGray-03 text-xs text-right`}>{sliceAltsIfLong(certificateInfo?.certificate?.alts as Array<string>, 30)}</div>
    </>
  );
};
export default OverviewCertificateItem;
