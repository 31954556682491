import { ConnectionHandler, RecordProxy, RecordSourceSelectorProxy } from 'relay-runtime';
//schemas
import { EducationMutationsFormCreateMutation$data } from 'schemas/educations/__generated__/EducationMutationsFormCreateMutation.graphql';
import { EducationMutationsListRemoveMutation$data } from 'schemas/educations/__generated__/EducationMutationsListRemoveMutation.graphql';
//helpers
import { getConnectionId } from 'formHelpers/utils';

export const EducationCreateUpdater =
  (profileId?: string) => (store: RecordSourceSelectorProxy<EducationMutationsFormCreateMutation$data>, response: EducationMutationsFormCreateMutation$data) => {
    if (profileId && response.createEducation.node?.id) {
      const profileRecord = store?.getRoot().getLinkedRecord(`node(id:"${profileId}")`);
      const educationInfos: RecordProxy<{}>[] | null | undefined = profileRecord?.getLinkedRecords('educations');
      const payload: RecordProxy<{}> | null | undefined = store.get(response.createEducation.node?.id);
      if (payload && educationInfos) {
        const newEducations = [...educationInfos, payload];
        profileRecord?.setLinkedRecords(newEducations, 'educations');
      }
    }
  };

export const EducationDeleteUpdater =
  (profileId?: string) => (store: RecordSourceSelectorProxy<EducationMutationsListRemoveMutation$data>, response: EducationMutationsListRemoveMutation$data) => {
    const storyID = getConnectionId('EducationsListFromProfile_educations');

    const profileRecord = store?.getRoot().getLinkedRecord(`node(id:"${profileId}")`);
    const educationRecords = profileRecord?.getLinkedRecords('educations');

    const filteredEducations = educationRecords?.filter(record => record?.getValue('id') !== response.deleteEducation.id);
    profileRecord?.setLinkedRecords(filteredEducations, 'educations');

    const payload = store.getRootField('deleteEducation');
    const record = store.get(`${storyID}(ownerId:"${profileId}")`);

    if (record) {
      ConnectionHandler.deleteNode(record, `${payload.getDataID()}`);
    }
  };
