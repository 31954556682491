/**
 * @generated SignedSource<<70a080ed781a1f7efedbd86d3b510085>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ProfileFragmentsEmployerCrewPoolStats$data = {
  readonly 'availableCrewCount': number;
  readonly 'crewPoolAvatars': ReadonlyArray<string>;
  readonly 'totalCrewCount': number;
  readonly ' $fragmentType': 'ProfileFragmentsEmployerCrewPoolStats';
};
export type ProfileFragmentsEmployerCrewPoolStats$key = {
  readonly ' $data'?: ProfileFragmentsEmployerCrewPoolStats$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsEmployerCrewPoolStats'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ProfileFragmentsEmployerCrewPoolStats',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'availableCrewCount',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'totalCrewCount',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'crewPoolAvatars',
      storageKey: null,
    },
  ],
  type: 'CrewPoolStat',
  abstractKey: null,
};

(node as any).hash = 'bd3768d0eed657a219d0b1964caa1cb5';

export default node;
