const ArrowSeaServiceIcon = () => {
  return (
    <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.21875 10.6476L2.21875 10.6469L2.21875 0.700449C2.21875 0.425785 2.49719 0.203125 2.84067 0.203125C3.15552 0.203125 3.41573 0.390221 3.45691 0.632965L3.46258 0.700449L3.46258 10.0257L13.0156 10.0257L9.27122 6.24215C9.04705 6.01564 9.04627 5.64759 9.26947 5.4201C9.47239 5.21328 9.79056 5.19383 10.0152 5.36218L10.0795 5.41833L14.7 10.0872C14.9044 10.2937 14.923 10.6178 14.7557 10.8457L14.7 10.911L10.0795 15.5806C9.8554 15.8071 9.49273 15.8064 9.26951 15.5789C9.06658 15.3721 9.04875 15.0491 9.21559 14.8219L9.27119 14.7568L12.7214 11.2695L2.73672 11.2695C2.45065 11.2695 2.21875 10.9911 2.21875 10.6476Z"
        fill="#8490A8"
      />
    </svg>
  );
};

export default ArrowSeaServiceIcon;
