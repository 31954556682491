import Skeleton from 'react-loading-skeleton';

const CountedSkeletonItemCEV = ({ widthTitle = 100 }: { widthTitle?: number }) => {
  return (
    <div className="flex">
      <Skeleton className="mr-1" width={10} height={14} />
      <Skeleton width={widthTitle} height={14} />
    </div>
  );
};

export default CountedSkeletonItemCEV;
