/**
 * @generated SignedSource<<ec6248a79adec2546771bd0110536313>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ProfileFragmentsGeneralSettings$data = {
  readonly 'availableForWork': boolean;
  readonly 'hideContactInfo': boolean;
  readonly 'id': string;
  readonly ' $fragmentType': 'ProfileFragmentsGeneralSettings';
};
export type ProfileFragmentsGeneralSettings$key = {
  readonly ' $data'?: ProfileFragmentsGeneralSettings$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsGeneralSettings'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ProfileFragmentsGeneralSettings',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'availableForWork',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'hideContactInfo',
      storageKey: null,
    },
  ],
  type: 'GeneralSetting',
  abstractKey: null,
};

(node as any).hash = 'aaefcb3f1ae3c4d80664bbb6e4c46ab6';

export default node;
