import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { RoutePaths } from 'app/routing';
//types
import { AppliedAlertMessageItemProps } from 'app/ProfileCrew/type/ProfileCrewTypes';

const AppliedAlertMessageItem = ({ index, vacancy }: AppliedAlertMessageItemProps) => {
  const intl = useIntl();

  const [startOfMessage, setStartOfMessage] = useState<string>(intl.formatMessage({ id: 'profile_employer_matched_with_crew_description_start' }));

  const applicationTime = vacancy?.createdAt.toString()!;
  const vacancyName = vacancy?.vacancy.title;
  const vacancyId = vacancy?.vacancy.id;

  const uniQuotesOpen = '\u201C';
  const uniQuotesClose = '\u201D';
  const uniSpace = '\u00a0';
  const uniComma = '\u002C';

  const vacancyLink = `${RoutePaths.ProfileBase}/job/${vacancyId}`;

  //Connect applied messages if more than one - we will add "and" or ","
  useEffect(() => {
    if (index !== 0) {
      setStartOfMessage(index === 1 ? `${uniSpace}${intl.formatMessage({ id: 'and_message' })}${uniSpace}` : `${uniComma}${uniSpace}`);
    }
  }, [index, intl]);

  return (
    <>
      {startOfMessage}
      <Link to={vacancyLink} className="font-semibold">
        {uniSpace}
        {uniQuotesOpen}
        <span className="border-b border-b-specialGray-05">{vacancyName}</span>
        {uniQuotesClose}
        {uniSpace}
      </Link>
      {intl.formatMessage({ id: 'profile_employer_applied_with_crew_description_job' })}
      <span className="font-semibold">
        {uniSpace}
        {intl.formatMessage({ id: 'label_on_the' })}
        {uniSpace}
        <span>{format(new Date(applicationTime), 'dd MMM yyyy')}</span>
      </span>
    </>
  );
};

export default AppliedAlertMessageItem;
