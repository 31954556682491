import graphql from 'babel-plugin-relay/macro';

export const INVITE_FRAGMENT = graphql`
  fragment InviteFragments on Invite {
    id
    createdAt
    vacancy {
      id
    }
    owner {
      id
      ...ProfileFragmentsCrewProfile
    }
  }
`;
