// packages
import { useAuth } from 'authentication';
import { useLocation, useNavigate } from 'react-router-dom';
// routes
import { RoutePaths } from 'app/routing';
// components
import { LoginForm } from 'auth/LoginForm';
// models
import { RedirectState } from 'models/routeLocationState';

const CrewLoginComponent = () => {
  const { setEmail, setPassword } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <LoginForm
      onLoginEmailConfirmedFault={(email, password) => {
        if (setEmail && setPassword) {
          setEmail(email);
          setPassword(password);
        }
        navigate(RoutePaths.AuthRegisterConfirm, { state: { isHire: false } });
      }}
      onLoginSuccess={() => {
        navigate({ pathname: (state as RedirectState)?.previousPathname || RoutePaths.HomePath, search: state?.search });
      }}
      forgotPasswordLink={RoutePaths.AuthForget}
    />
  );
};

export default CrewLoginComponent;
