// packages
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
// components
import DashboardTableBodySkeleton from './DashboardTableBodySkeleton';

const DashboardTableBodyListSkeleton = ({ numberOfItems }: { numberOfItems: number }) => {
  const [emptyList] = useState<Array<string>>(new Array(numberOfItems).fill(uuidv4()).map(() => uuidv4()));

  return (
    <>
      {emptyList.map((key, index) => (
        <DashboardTableBodySkeleton key={key} lastIndex={index === emptyList.length - 1} />
      ))}
    </>
  );
};

export default DashboardTableBodyListSkeleton;
