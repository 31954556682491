import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
//generated
import { ProfileFragmentsOrganizationPlural_profile$data } from 'schemas/profile/__generated__/ProfileFragmentsOrganizationPlural_profile.graphql';

const useGetCurrentOpenedProfileOrganization = (allProfileOrganizations: ProfileFragmentsOrganizationPlural_profile$data | null) => {
  const { orgId } = useParams();
  const { state } = useLocation();

  const findCurrentOrganization = useMemo(
    () => allProfileOrganizations?.find(organization => organization.organization.id === orgId || organization.organization.id === state?.organization?.id),
    [allProfileOrganizations, orgId, state?.organization?.id],
  );
  const findCurrentOwnerOrganization = useMemo(() => allProfileOrganizations?.find(organization => organization.role === 'owner'), [allProfileOrganizations]);

  return {
    profileOrganization: findCurrentOrganization || findCurrentOwnerOrganization,
  };
};

export default useGetCurrentOpenedProfileOrganization;
