// components
import DashboardMobileListItem from './mobileComponents/DashboardMobileListItem';
// generated
import { VacancyFragmentsForDashboardFragment$key } from 'schemas/vacancy/__generated__/VacancyFragmentsForDashboardFragment.graphql';
// hooks
import { useGettingVacanciesWithPagination } from 'hooks/dashboard/useGettingVacanciesWithPagination';

const PanelTabWithMobileList = () => {
  const listOfVacancies = useGettingVacanciesWithPagination();

  return (
    <div className="flex sm:hidden flex-col space-y-3">
      {listOfVacancies?.map((item, index) => (
        <DashboardMobileListItem key={item?.node?.id} vacancyFragment={item?.node as unknown as VacancyFragmentsForDashboardFragment$key} orderNumber={index + 1} />
      ))}
    </div>
  );
};

export default PanelTabWithMobileList;
