import { isEmpty } from 'lodash';
//models
import { FILTER_BY_NUMBER_RANGE } from 'models/commonTypes';
import { ORDER_DIRECTION, VACANCY_ORDER_FIELD } from 'models/enums';

export const sortingFiltersByAlphabet = <T extends { name?: string; label?: string; readonly id: string }>(a: T, b: T) => {
  if (a.name && b.name) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  } else if (a.label && b.label) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }
  return 0;
};

export const withoutSelectedFilters = <T extends { readonly id: string }>(filters: readonly T[], selectedFiltersKinds: { id: string }[]) => {
  return filters
    ?.filter(item => {
      if (!isEmpty(selectedFiltersKinds)) {
        return !selectedFiltersKinds.find(selectedItem => selectedItem?.id === item.id);
      }
      return true;
    })
    .sort(sortingFiltersByAlphabet);
};
export const findSelectedFiltersFromUrl = <T extends { readonly id: string; readonly isoCode?: string }>(listOfAllFilters: readonly T[], searchUrl: string[]) => {
  return listOfAllFilters.filter(filter => searchUrl.includes(filter.isoCode ? filter.isoCode : filter.id));
};

export const createRangeFilter = (arrayOfFilters: Array<Array<string>>, filterName: string, range: FILTER_BY_NUMBER_RANGE) => {
  if (!!range.from && !!range.to) {
    arrayOfFilters.push([`${filterName}:${range.from} TO ${range.to}`]);
  } else if (!!range.from) {
    arrayOfFilters.push([`${filterName} >= ${range.from}`]);
  } else if (!!range.to) {
    arrayOfFilters.push([`${filterName} <= ${range.to}`]);
  }
};

export const vacanciesSearchParams = (searchParams: URLSearchParams) => {
  const initialValues = {
    fieldName: null,
    direction: null,
  };

  if (!Array.from(searchParams.keys()).length) {
    return initialValues;
  }
  const params: { fieldName: VACANCY_ORDER_FIELD | null; direction: ORDER_DIRECTION | null } = initialValues;

  for (const [key, value] of searchParams) {
    params.fieldName = key.toUpperCase() as VACANCY_ORDER_FIELD;
    params.direction = value.toUpperCase() as ORDER_DIRECTION;
  }
  return params;
};

export const uniqArrayHelper = <T>(array: T[]) => {
  const jsonObject = array.map(item => JSON.stringify(item));
  const uniqueSet = new Set(jsonObject);
  return Array.from(uniqueSet).map(item => JSON.parse(item));
};

export const formatterFilterByName = (item: { name: string }) => item.name;
export const formatterFilterByLabel = (item: { label: string }) => item.label;
