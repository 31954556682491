// hooks
import { useGettingVacanciesWithPagination } from 'hooks/dashboard/useGettingVacanciesWithPagination';
// components
import DashboardDesktopTableItem from './components/DashboardDesktopTableItem';
// generated
import { VacancyFragmentsForDashboardFragment$key } from 'schemas/vacancy/__generated__/VacancyFragmentsForDashboardFragment.graphql';

const PanelTabWithDesktopTable = () => {
  const listOfVacancies = useGettingVacanciesWithPagination();

  return (
    <>
      {listOfVacancies?.map((item, index) => (
        <DashboardDesktopTableItem
          key={item?.node?.id}
          lastIndex={index === (listOfVacancies?.length as number) - 1}
          item={item?.node as unknown as VacancyFragmentsForDashboardFragment$key}
          serialNumber={index + 1}
        />
      ))}
    </>
  );
};
export default PanelTabWithDesktopTable;
