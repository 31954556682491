// packages
import { Navigate, Outlet, useParams, useSearchParams } from 'react-router-dom';
// routes
import { RoutePaths, RoutesPathNames } from 'app/routing';
// hooks
import { useAuth } from 'authentication';

const PrivateCrewAccountViewRoute = () => {
  const params = useParams();
  const { identity } = useAuth();
  const [searchParams] = useSearchParams();
  const profileId = params[RoutesPathNames.id];

  const isNotSharedAccountByLink: boolean = !searchParams.get('t');

  if (!identity?.profileId && isNotSharedAccountByLink) return <Navigate to={`${RoutePaths.ProfileSingleCrewPage}/${profileId}`} replace />;

  return <Outlet />;
};

export default PrivateCrewAccountViewRoute;
