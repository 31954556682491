// packages
import { ChangeEvent, Suspense, useCallback, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useIntl } from 'react-intl';
import { XMarkIcon } from '@heroicons/react/24/solid';
// components
import FilterIcon from 'icons/FilterIcon';
import CustomButton from 'system/Buttons/CustomButton';
import { SearchInputIcon } from 'icons/SearchInputIcon';
import FilterComponentSkeleton from 'system/skeletons/filters/FilterComponentSkeleton';
import FilterSliderWrapper from 'system/Layouts/FilterSliderWrapper/FilterSliderWrapper';
import ListOfCrewSkeleton from 'system/skeletons/lists/ListOfCrewSkeleton/ListOfCrewSkeleton';
import FilterSlideComponent from 'system/FilterSlideComponents/ProfileFiltering/FilterSlideComponent';
import ProfileInviteSearchMembers from 'app/ProfileEmployer/components/ProfileInviteSearchMembers/ProfileInviteSearchMembers';
import SelectedProfileFilterElements from 'system/FilterSlideComponents/components/SelectedFilterElements/SelectedProfileFilterElements';

const InviteCrewPage = () => {
  const intl = useIntl();
  const refInput = useRef<HTMLInputElement>(null);
  const [queryParams, setQueryParams] = useState<string>('');
  const [isOpenListOfFilters, setIsOpenListOfFilters] = useState<boolean>(false);

  const openFilter = () => {
    setIsOpenListOfFilters(prev => !prev);
  };

  const handleClearInput = useCallback(() => {
    (refInput.current as HTMLInputElement).value = '';
    setQueryParams('');
  }, []);

  const handleOnChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setQueryParams(event.target.value as string);
  }, 500);

  return (
    <div className="flex-col justify-between items-center mb-6 relative w-full">
      <div className="flex-col w-full">
        <div className="flex py-2 justify-between items-center">
          <div className="flex h-12 relative text-darkBlue">
            <SearchInputIcon className="w-5 h-5 text-grayIcon absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 left-1 ml-4" />
            <input
              ref={refInput}
              onChange={handleOnChange}
              type="text"
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block text-xs sm:text-sm border-specialGray-012 placeholder:text-specialGray-05 rounded-xl w-full max-w-[236px] pl-10 pt-2"
              placeholder={intl.formatMessage({ id: 'search_input_placeholder' })}
            />
            {!!queryParams && (
              <div
                onClick={handleClearInput}
                className="absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 right-2.5 w-6 h-6 rounded-md bg-gray-200 flex justify-center items-center cursor-pointer"
              >
                <XMarkIcon className="w-4 h-4 text-gray-400" />
              </div>
            )}
          </div>
          <CustomButton
            label={{ id: 'label_filter' }}
            onClick={openFilter}
            classNameStyle="w-28 justify-between text-sm disabled:opacity-50 px-5 py-3.5 border border-specialGray-05 text-sm leading-4 font-normal rounded-xl hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 mr-1 max-h-12"
          >
            <FilterIcon />
          </CustomButton>
        </div>
        <Suspense>
          <SelectedProfileFilterElements />
        </Suspense>
        <Suspense fallback={<ListOfCrewSkeleton />}>
          <ProfileInviteSearchMembers queryParams={queryParams} />
        </Suspense>
      </div>
      <FilterSliderWrapper filterButtonEvent={openFilter} isOpenListOfFilters={isOpenListOfFilters}>
        <Suspense fallback={<FilterComponentSkeleton />}>{isOpenListOfFilters && <FilterSlideComponent />}</Suspense>
      </FilterSliderWrapper>
    </div>
  );
};

export default InviteCrewPage;
