import { FC } from 'react';
import NoShipIcon from 'icons/NoShipIcon';

export const AvatarCrewsOverviewItemEmployerView: FC<{ avatarUrl: string | null }> = ({ avatarUrl = '' }) => {
  return (
    <div className="flex justify-center items-center min-w-[64px] min-h-[64px]">
      {avatarUrl ? (
        <img className="object-cover rounded-md w-[64px] h-[64px]" src={avatarUrl} alt="sea service img" />
      ) : (
        <div className="flex w-full h-full max-w-[64px] max-h-[64px] justify-center items-center bg-specialGray-012 rounded-md">
          <NoShipIcon fill="fill-specialGray-03" className="w-8 h-[30px]" />
        </div>
      )}
    </div>
  );
};
export default AvatarCrewsOverviewItemEmployerView;
