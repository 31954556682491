/**
 * @generated SignedSource<<c5c09ee5c6bb45913c1631c4aaae9f23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type AppraisalQueriesOneQuery$variables = {
  id: string;
};
export type AppraisalQueriesOneQuery$data = {
  readonly node: {
    readonly ' $fragmentSpreads': FragmentRefs<'AppraisalFragmentsOneAppraisal'>;
  } | null;
};
export type AppraisalQueriesOneQuery = {
  response: AppraisalQueriesOneQuery$data;
  variables: AppraisalQueriesOneQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'AppraisalQueriesOneQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'AppraisalFragmentsOneAppraisal',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'AppraisalQueriesOneQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: '__typename',
              storageKey: null,
            },
            v2 /*: any*/,
            {
              kind: 'InlineFragment',
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'review',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Document',
                  kind: 'LinkedField',
                  name: 'document',
                  plural: false,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'urls',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'xlarge_urls',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Profile',
                  kind: 'LinkedField',
                  name: 'owner',
                  plural: false,
                  selections: [v2 /*: any*/],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'SeaService',
                  kind: 'LinkedField',
                  name: 'seaService',
                  plural: false,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'start',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'end',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Duty',
                      kind: 'LinkedField',
                      name: 'duty',
                      plural: false,
                      selections: [v2 /*: any*/, v3 /*: any*/],
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Vessel',
                      kind: 'LinkedField',
                      name: 'vessel',
                      plural: false,
                      selections: [
                        v3 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'image',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'cnISO',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'countryName',
                          storageKey: null,
                        },
                        v2 /*: any*/,
                      ],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              type: 'Appraisal',
              abstractKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '227c86e0cfd2ca15c43f6fce004912bc',
      id: null,
      metadata: {},
      name: 'AppraisalQueriesOneQuery',
      operationKind: 'query',
      text: 'query AppraisalQueriesOneQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...AppraisalFragmentsOneAppraisal\n    id\n  }\n}\n\nfragment AppraisalFragmentsOneAppraisal on Appraisal {\n  id\n  review\n  document {\n    id\n    urls\n    xlarge_urls\n  }\n  owner {\n    id\n  }\n  seaService {\n    id\n    start\n    end\n    duty {\n      id\n      name\n    }\n    vessel {\n      name\n      image\n      cnISO\n      countryName\n      id\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '8e8b5ba8fc8c04ec05430cb63d7682c7';

export default node;
