import React, { useMemo } from 'react';
import { Uppy } from '@uppy/core';
import { Dashboard } from '@uppy/react';
import Webcam from '@uppy/webcam';
import Transloadit from '@uppy/transloadit';
// Styles for uppy
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
// models
import { UploadingDashboardProps } from 'models/modelsOfComponents';

const UploadingDashboard: React.FC<UploadingDashboardProps> = ({ setSmallUrls, templateId, setLargeUrls, maxNumberOfFiles }) => {
  const uppy = useMemo(() => {
    return new Uppy({
      debug: true,
      meta: { type: 'avatar' },
      autoProceed: true,
      allowMultipleUploadBatches: true,
      restrictions: {
        maxFileSize: 7864320,
        maxNumberOfFiles: maxNumberOfFiles,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*', '.pdf'],
      },
    })
      .use(Webcam, { showVideoSourceDropdown: true, modes: ['picture'] })
      .use(Transloadit, {
        params: {
          auth: { key: process.env.REACT_APP_TRANSLOADIT_KEY || '00000000000000000000000000000000' },
          template_id: templateId,
        },
        waitForEncoding: true,
        waitForMetadata: true,
        limit: 1,
      });
  }, [templateId, maxNumberOfFiles]);

  const setUrlsFromUppy = <T extends { ssl_url: string }>(array: Array<T>) => {
    return array.map((url: { ssl_url: string }) => url.ssl_url);
  };

  uppy.on('complete', response => {
    // @ts-ignore types fail because we have the transloadit plugin
    if (response.transloadit && response.transloadit[0]) {
      // @ts-ignore
      const fillCropped = response.transloadit[0].results.fillcropped;
      // @ts-ignore
      const thumbnailed_pdf = response.transloadit[0].results.thumbnailed_pdf;
      // @ts-ignore
      const convert_image_png = response.transloadit[0].results.convert_image_png;
      // @ts-ignore
      const filter_pdf = response.transloadit[0].results.filter_pdf;

      if (fillCropped && !thumbnailed_pdf && convert_image_png && !filter_pdf) {
        setSmallUrls(setUrlsFromUppy(fillCropped));
        setLargeUrls(setUrlsFromUppy(convert_image_png));
      } else if (!fillCropped && thumbnailed_pdf && !convert_image_png && filter_pdf) {
        setSmallUrls(setUrlsFromUppy(thumbnailed_pdf));
        setLargeUrls(setUrlsFromUppy(filter_pdf));
      } else if (fillCropped && thumbnailed_pdf && convert_image_png && filter_pdf) {
        setSmallUrls(setUrlsFromUppy([...fillCropped, ...thumbnailed_pdf]));
        setLargeUrls(setUrlsFromUppy([...convert_image_png, ...filter_pdf]));
      }
    }
  });

  return <Dashboard uppy={uppy} plugins={['Webcam']} showProgressDetails />;
};

export default UploadingDashboard;
