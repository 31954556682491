// packages
import { useIntl } from 'react-intl';
import { Tab } from '@headlessui/react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSearchParams } from 'react-router-dom';
// models
import { PanelsTabLayoutType } from 'models/modelsOfComponents';
// recoil
import { fetchDashboardVacanciesByParamsAtom } from 'recoil/Organisation/Dashboard/atoms/fetchDashboardVacanciesByParamsAtom';
// mocks
import { DASHBOARD_TABLE_TABS } from 'mocks/mockData';
// models
import { DASHBOARD_TABS_ID, DASHBOARD_VACANCIES_FILTER_ENUM, VACANCY_STATUS } from 'models/enums';

const PanelsTabLayout = ({ widthTabList, position, defaultSelectTabIndex, children }: PanelsTabLayoutType) => {
  const intl = useIntl();
  const setSortingByOrder = useSetRecoilState(fetchDashboardVacanciesByParamsAtom);
  const getFetchDashboardVacanciesByParams = useRecoilValue(fetchDashboardVacanciesByParamsAtom);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleToggleTab = (tabId: DASHBOARD_TABS_ID) => () => {
    for (const [key] of searchParams) {
      searchParams.delete(key);
    }
    setSearchParams(searchParams);
    if (tabId === DASHBOARD_TABS_ID.OPENED_TAB) {
      setSortingByOrder({
        filterByStatus: VACANCY_STATUS.OPEN,
      });
    } else {
      setSortingByOrder({
        filterByStatus: DASHBOARD_VACANCIES_FILTER_ENUM.SHOW_ALL,
      });
    }
  };

  // show default selected tab when user returns to the previous page without page refreshing
  const defaultTabIndexSelected = getFetchDashboardVacanciesByParams.filterByStatus === VACANCY_STATUS.OPEN ? 0 : 1;
  return (
    <Tab.Group selectedIndex={defaultSelectTabIndex || defaultTabIndexSelected}>
      <Tab.List className={`flex mx-auto gap-x-1 rounded-xl bg-specialGray-012 mt-4 sm:mt-0 ${widthTabList} ${position}`}>
        {DASHBOARD_TABLE_TABS.map(tab => {
          return (
            <Tab
              key={tab.id}
              className={({ selected }) => {
                return `w-full rounded-xl py-2.5 text-sm font-medium leading-5 text-specialGray-075 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none ${
                  selected ? 'bg-white shadow border border-solid border-grayIcon' : 'hover:text-spacialGray-03'
                }
          `;
              }}
              onClick={handleToggleTab(tab.id)}
            >
              {intl.formatMessage({ id: tab.title })}
            </Tab>
          );
        })}
      </Tab.List>
      {children}
    </Tab.Group>
  );
};

export default PanelsTabLayout;
