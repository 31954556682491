// skeletons
import CrewProfileBasicInfo from './components/CrewProfileBasicInfo';
import CrewOverviewTabSkeleton from 'system/skeletons/profileTabs/CrewOverviewTabSkeleton';

const ProfileCrewSkeleton = () => {
  return (
    <main className="flex flex-col w-full max-w-7xl mx-auto">
      <CrewProfileBasicInfo sharedProfile={false} />
      <CrewOverviewTabSkeleton />
    </main>
  );
};

export default ProfileCrewSkeleton;
