import HeaderSkeleton from './components/HeaderSkeleton';
import TableWithCertificatesSkeleton from './components/TableWithCertificatesSkeleton';

const CertificatesSkeleton = () => {
  return (
    <div className="flex flex-col px-6">
      <HeaderSkeleton widthBigTitle={145} />

      <TableWithCertificatesSkeleton />
      <TableWithCertificatesSkeleton />
      <TableWithCertificatesSkeleton />
      <TableWithCertificatesSkeleton />
      <TableWithCertificatesSkeleton />
      <TableWithCertificatesSkeleton />
    </div>
  );
};

export default CertificatesSkeleton;
