import { PaymentSubscriptionTierType } from 'models/enums';
import { PricingType } from '../../types';

export const pricing: PricingType = {
  frequencies: [
    { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
    { value: 'annually', label: 'Annually', priceSuffix: '/year' },
  ],
  tiers: [
    {
      name: 'Free',
      id: PaymentSubscriptionTierType.FREE,
      href: '#',
      price: { monthly: '€0', annually: '€0' },
      description: 'The essentials to provide your best work for clients.',
      features: ['10 active vacancies', 'Unlimited vacancy applications', 'Unlimited crew contact details', 'Unlimited crew pools', 'No commercial support'],
      mostPopular: false,
    },
    {
      name: 'Pro',
      id: PaymentSubscriptionTierType.PRO,
      href: 'https://587c4nix1n3.typeform.com/to/RLLv4zie',
      price: { monthly: '€100', annually: '€1200' },
      description: 'A plan that scales with your rapidly growing business.',
      features: ['100 active vacancies', 'Unlimited vacancy applications', 'Unlimited crew contact details', 'Unlimited crew pools', 'No commercial support while'],
      mostPopular: true,
    },
    {
      name: 'Custom',
      id: PaymentSubscriptionTierType.CUSTOM,
      href: 'https://587c4nix1n3.typeform.com/to/RLLv4zie',
      price: { monthly: 'Custom', annually: 'Custom' },
      description: 'Dedicated support and infrastructure for your company.',
      features: [
        'Unlimited vacancies',
        'Unlimited vacancy applications',
        'Unlimited crew contact details',
        'Unlimited crewpools',
        'Automate your job postings',
        'Commercial support',
      ],
      mostPopular: false,
    },
  ],
};
