import { FC } from 'react';

const AppliedIcon: FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id="applied-icon">
      <g clipPath="url(#clip0_6997_65802)">
        <path
          d="M16.75 3.5H3.75C2.85625 3.5 2.125 4.23125 2.125 5.125V14.875C2.125 15.7688 2.85625 16.5 3.75 16.5H16.75C17.6438 16.5 18.375 15.7688 18.375 14.875V5.125C18.375 4.23125 17.6438 3.5 16.75 3.5ZM16.425 6.95312L11.1112 10.2762C10.5831 10.6094 9.91687 10.6094 9.38875 10.2762L4.075 6.95312C3.87188 6.82312 3.75 6.60375 3.75 6.36813C3.75 5.82375 4.34313 5.49875 4.80625 5.78312L10.25 9.1875L15.6938 5.78312C16.1569 5.49875 16.75 5.82375 16.75 6.36813C16.75 6.60375 16.6281 6.82312 16.425 6.95312Z"
          fill="currentColor"
        />
        <path
          d="M10.25 16C10.25 14.067 11.817 12.5 13.75 12.5H17.75C19.683 12.5 21.25 14.067 21.25 16C21.25 17.933 19.683 19.5 17.75 19.5H13.75C11.817 19.5 10.25 17.933 10.25 16Z"
          fill="#FCFCFC"
        />
        <path
          d="M14.9738 18.2051C14.9738 18.5783 14.5259 18.7608 14.2688 18.4954L11.9547 16.1813C11.7888 16.0154 11.7888 15.7583 11.9547 15.5924L14.2688 13.2783C14.5259 13.0212 14.9738 13.2037 14.9738 13.5769L14.9738 15.0616L17.4621 15.0616C17.9183 15.0616 18.2915 15.4348 18.2915 15.891C18.2915 16.3472 17.9183 16.7204 17.4621 16.7204L14.9738 16.7204L14.9738 18.2051Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6997_65802">
          <rect width="19.5" height="19.5" fill="white" transform="translate(0.5 0.25)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AppliedIcon;
