import { useIntl } from 'react-intl';
//components
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';

const AvailableForWorkIconWithPopover = ({ isAvailable }: { isAvailable: boolean }) => {
  const intl = useIntl();
  return (
    <PopoverHelper
      position="-translate-y-full -top-2 -translate-x-1/2 left-6 sm:left-4"
      positionArrow="-bottom-[3px] left-[45%] sm:left-1/2 rotate-45"
      darkTheme
      widthContainer="w-fit"
      element={
        <div className="flex items-center justify-center py-1">
          <CheckCircleIcon className={`flex w-8 h-8 ${isAvailable ? 'text-green-500' : 'text-specialGray-03'}`} />
        </div>
      }
    >
      <span className="flex sm:whitespace-nowrap items-center px-2">
        {intl.formatMessage({ id: isAvailable ? 'profile_available_for_work' : 'profile_not_available_for_work' })}
      </span>
    </PopoverHelper>
  );
};

export default AvailableForWorkIconWithPopover;
