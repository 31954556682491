import { useIntl } from 'react-intl';
// components
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';

const HiddenCrewDataPopover = ({ label }: { label: string }) => {
  const intl = useIntl();
  return (
    <PopoverHelper
      classes="flex max-w-fit text-xs text-darkBlue whitespace-nowrap normal-case font-normal"
      element={<span className="text-sm">{label}</span>}
      position="bottom-8 left-1/2 -translate-x-[40%]"
      positionArrow="left-[40%] sm:-translate-x-1/2 rotate-45 -bottom-0.5"
      widthContainer="w-60 w-[190px] sm:w-[200px] md:w-[230px]"
      darkTheme
    >
      {intl.formatMessage({ id: 'profile_data_restriction' })}
    </PopoverHelper>
  );
};

export default HiddenCrewDataPopover;
