import { UseMutationConfig } from 'react-relay';
import { ConnectionHandler, Disposable, RecordProxy, RecordSourceSelectorProxy } from 'relay-runtime';
// generated
import { ServiceFragments$data } from 'schemas/services/__generated__/ServiceFragments.graphql';
import { ServiceMutationsFormCreateMutation$data } from 'schemas/services/__generated__/ServiceMutationsFormCreateMutation.graphql';
import { ServiceMutationsFormUpdateMutation$data } from 'schemas/services/__generated__/ServiceMutationsFormUpdateMutation.graphql';
import { ServiceMutationsListRemoveMutation$data } from 'schemas/services/__generated__/ServiceMutationsListRemoveMutation.graphql';
import { ServiceMutationsAddTagsToSeaServiceMutation } from 'schemas/services/__generated__/ServiceMutationsAddTagsToSeaServiceMutation.graphql';
//models
import { IServiceFormData } from 'models/modelsOfForms';

export const ServiceFormCommitUpdater =
  (
    formData: IServiceFormData,
    onError: (error: Error) => void,
    defaultData: ServiceFragments$data,
    commitAddTags: (config: UseMutationConfig<ServiceMutationsAddTagsToSeaServiceMutation>) => Disposable,
  ) =>
  (store: RecordSourceSelectorProxy<ServiceMutationsFormUpdateMutation$data>) => {
    const payload = store.getRootField('updateSeaService');
    const tags = payload?.getLinkedRecords('activities');

    if (tags && formData?.tags) {
      const filteringTags = tags.filter((recordTag: RecordProxy) => {
        return formData.tags?.some(tag => tag?.toString() === recordTag.getDataID());
      });
      payload?.setLinkedRecords(filteringTags, 'activities');
      commitAddTags({
        onError,
        variables: {
          sid: defaultData?.id!,
          tags: formData.tags?.map(({ id }) => id),
        },
      });
    }
  };

export const ServiceFormCommitCreate =
  (
    formData: IServiceFormData,
    onError: (error: Error) => void,
    commitAddTags: (config: UseMutationConfig<ServiceMutationsAddTagsToSeaServiceMutation>) => Disposable,
    profileId: string,
  ) =>
  (store: RecordSourceSelectorProxy<ServiceMutationsFormCreateMutation$data>, response: ServiceMutationsFormCreateMutation$data) => {
    const payload = store.getRootField('createSeaService'); // the result from our mutation
    const profileRecord = store.get(`${profileId}`);
    const nodeRecord = payload.getLinkedRecord('node');
    const seaServiceCount = (profileRecord?.getValue('seaServicesAddedCount') as number) + 1;
    profileRecord?.setValue(seaServiceCount, 'seaServicesAddedCount');

    if (formData.tags && nodeRecord?.getValue('id')) {
      commitAddTags({
        onError,
        variables: {
          sid: nodeRecord?.getValue('id')?.toString()!,
          tags: formData.tags.map(({ id }) => id),
        },
      });
    }

    const seaServices: RecordProxy[] | null | undefined = profileRecord?.getLinkedRecords('seaServices');
    if (seaServices && response.createSeaService.node?.id) {
      const payloadSeaService: RecordProxy | null | undefined = store.get(response.createSeaService.node?.id);
      if (payloadSeaService) {
        const newSeaServices = [...seaServices, payloadSeaService];
        profileRecord?.setLinkedRecords(newSeaServices, 'seaServices');
      }
    }
  };

export const ServiceListCommitRemove = (seaServiceStoryID: string, appraisalStoryID: string) => (store: RecordSourceSelectorProxy<ServiceMutationsListRemoveMutation$data>) => {
  const payload = store.getRootField('deleteSeaService');
  const seaServiceRecord = store.get(seaServiceStoryID);
  const appraisalRecord = store.get(appraisalStoryID);

  if (seaServiceRecord) {
    ConnectionHandler.deleteNode(seaServiceRecord, `${payload?.getDataID()}`);
  }
  if (appraisalRecord) {
    const foundRemoveAppraisal = appraisalRecord
      .getLinkedRecords('edges')
      ?.find((appraisalRecord: RecordProxy) => appraisalRecord.getLinkedRecord('node')?.getLinkedRecord('seaService')?.getValue('id') === `${payload?.getDataID()}`);
    ConnectionHandler.deleteNode(appraisalRecord, `${foundRemoveAppraisal?.getLinkedRecord('node')?.getDataID()}`);
  }
};
