import { FC } from 'react';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
// fragments
import { EDUCATION_FRAGMENT } from 'schemas/educations/EducationFragments';
//generated
import { EducationFragments$key } from 'schemas/educations/__generated__/EducationFragments.graphql';
//components
import getEducationIcon from 'icons/EducationIcons';

const CrewsEducationEvItem: FC<{ education: EducationFragments$key; additionalClasses?: string }> = ({ education, additionalClasses = '' }) => {
  const intl = useIntl();

  const dataOfEducation = useFragment<EducationFragments$key>(EDUCATION_FRAGMENT, education || null);
  const hyphenUni = `\u002D`;
  const spaceUni = `\u0020`;

  return (
    <div className={`flex w-full min-h-[86px] pl-[15px] pr-[25px] py-[15px] rounded-lg bg-white border items-center text-sm ${additionalClasses}`}>
      <div className="flex mr-6 [&>svg]:w-14 [&>svg]:h-14">{dataOfEducation?.kind?.name && getEducationIcon(dataOfEducation?.kind?.perma)}</div>

      <div className="flex flex-col w-full pr-2">
        <h5 className="text-sm text-specialGray-075 mb-1 whitespace-nowrap">{dataOfEducation?.kind?.name}</h5>
        <h6 className="text-specialGray-1 text-sm font-medium">{dataOfEducation?.name}</h6>
      </div>

      <section className="text-specialGray-075 lg:whitespace-nowrap self-center">
        {dataOfEducation && dataOfEducation.start && (
          <span>
            {format(new Date(`${dataOfEducation.start}`), 'dd MMM yyyy')}
            {spaceUni}
            {hyphenUni}
          </span>
        )}
        {dataOfEducation && dataOfEducation.end ? (
          <span>{format(new Date(`${dataOfEducation.end}`), 'dd MMM yyyy')}</span>
        ) : (
          <span className="ml-1">{intl.formatMessage({ id: 'education_item_present_end' })}</span>
        )}
      </section>
    </div>
  );
};

export default CrewsEducationEvItem;
