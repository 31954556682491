const ShareIcon = () => {
  return (
    <svg className="fill-current" id="share-icon" width="1em" height="1em" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className="fill-current"
        d="M15.5436 6.66797H13.877C13.4186 6.66797 13.0436 7.04297 13.0436 7.5013C13.0436 7.95964 13.4186 8.33464 13.877 8.33464H15.5436V17.5013H5.54362V8.33464H7.21029C7.66862 8.33464 8.04362 7.95964 8.04362 7.5013C8.04362 7.04297 7.66862 6.66797 7.21029 6.66797H5.54362C4.62695 6.66797 3.87695 7.41797 3.87695 8.33464V17.5013C3.87695 18.418 4.62695 19.168 5.54362 19.168H15.5436C16.4603 19.168 17.2103 18.418 17.2103 17.5013V8.33464C17.2103 7.41797 16.4603 6.66797 15.5436 6.66797Z"
      />
      <path
        className="fill-current"
        d="M10.5434 13.3367C11.0017 13.3367 11.3767 12.9617 11.3767 12.5034V4.17005H12.8684C13.2434 4.17005 13.4267 3.72005 13.16 3.46172L10.835 1.13672C10.6684 0.970052 10.41 0.970052 10.2434 1.13672L7.91837 3.46172C7.66003 3.72005 7.84337 4.17005 8.21837 4.17005H9.71003V12.5034C9.71003 12.9617 10.085 13.3367 10.5434 13.3367Z"
      />
    </svg>
  );
};

export default ShareIcon;
