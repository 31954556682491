// packages
import { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Skeleton from 'react-loading-skeleton';
// models
import { VACANCY_DESCRIPTION_TYPE_OF_LIST } from 'models/enums';
// components
import ListOfCrewItemSkeleton from './ListOfCrewItemSkeleton';

const ListOfCrewSkeleton: FC<{ typeOfList: VACANCY_DESCRIPTION_TYPE_OF_LIST }> = ({ typeOfList }) => {
  const [emptyList] = useState<Array<string>>(new Array(3).fill('').map(() => uuidv4()));
  return (
    <div className="flex flex-col">
      <div className="flex justify-between mb-3 items-center">
        <div className="flex space-x-3 items-center">
          <Skeleton width={typeOfList === VACANCY_DESCRIPTION_TYPE_OF_LIST.INVITED ? 110 : 118} height={18} />
          <Skeleton width={50} height={14} containerClassName="mt-1" />
        </div>
        <div className="flex space-x-8">
          {typeOfList === VACANCY_DESCRIPTION_TYPE_OF_LIST.INVITED ? <Skeleton width={130} height={14} containerClassName="mt-1" /> : null}
          <Skeleton width={47} height={14} containerClassName="mt-1" />
        </div>
      </div>
      <div className="flex flex-col space-y-3">
        {emptyList.map(item => (
          <ListOfCrewItemSkeleton key={item} typeOfList={typeOfList} />
        ))}
      </div>
    </div>
  );
};

export default ListOfCrewSkeleton;
