import graphql from 'babel-plugin-relay/macro';

export const EDUCATION_FRAGMENT = graphql`
  fragment EducationFragments on Education {
    id
    start
    end
    name
    kind {
      id
      name
      perma
    }
  }
`;
