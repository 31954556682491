import Skeleton from 'react-loading-skeleton';
import OverviewProfileCrewEVTitleSkeleton from './OverviewProfileCrewEVTitleSkeleton';
import SeaServiceEmployerViewWrapperSkeleton from './SeaServiceEmployerViewWrapperSkeleton';

const AppraisalsCrewEvComponentSkeleton = () => {
  const contentWidth = [300, 600, 400];

  return (
    <section className="flex w-full flex-col space-y-6">
      <OverviewProfileCrewEVTitleSkeleton />
      {contentWidth.map((width, i) => {
        return (
          <SeaServiceEmployerViewWrapperSkeleton key={i}>
            <div className="flex w-full mt-4 flex-wrap items-center">
              <Skeleton className="mr-2" width={117} height={14} />
              <Skeleton className="mr-1.5" width={width} height={14} />
            </div>
          </SeaServiceEmployerViewWrapperSkeleton>
        );
      })}
    </section>
  );
};

export default AppraisalsCrewEvComponentSkeleton;
