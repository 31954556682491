// packages
import React, { useCallback } from 'react';
import { RefetchFnDynamic, useMutation } from 'react-relay';
// schemas
import { REMOVE_APPLICATION } from 'schemas/Application/ApplicationMutations';
// generated
import { VacancyFragments$data, VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { VacancyQueriesGetVacancyRefreshQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetVacancyRefreshQuery.graphql';
import { ApplicationMutationsRemoveApplicationMutation } from 'schemas/Application/__generated__/ApplicationMutationsRemoveApplicationMutation.graphql';

export function useRemoveVacancyApplication(
  vacancy: VacancyFragments$data,
  profileId: string,
  reFetch: RefetchFnDynamic<VacancyQueriesGetVacancyRefreshQuery, VacancyFragments$key>,
) {
  const [commitRemoveApplication] = useMutation<ApplicationMutationsRemoveApplicationMutation>(REMOVE_APPLICATION);

  return useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const foundApplication = vacancy?.applications?.find(application => application.owner.id === profileId);

      commitRemoveApplication({
        variables: {
          id: `${foundApplication?.id}`,
        },
        onCompleted: () => {
          reFetch({}, { fetchPolicy: 'network-only' });
        },
      });
    },
    [commitRemoveApplication, reFetch, profileId, vacancy?.applications],
  );
}
