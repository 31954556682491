//types
import { OverviewCrewInfoEmptyItemProps } from 'app/ProfileCrew/type/ProfileCrewTypes';

const OverviewCrewInfoEmptyItem = ({ title, children }: OverviewCrewInfoEmptyItemProps) => {
  return (
    <div className="flex items-center border border-specialGray-012 rounded-xl px-4 py-2">
      {children}
      <span className="text-specialGray-darkBlue ml-2 mr-1 text-sm">{title}</span>
    </div>
  );
};

export default OverviewCrewInfoEmptyItem;
