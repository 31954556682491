// packages
import { GetRecoilValue, selector } from 'recoil';
// recoil
import { selectedDashboardVacanciesByParamsOpenAtom } from '../atoms/selectedDashboardVacanciesByParamsOpenAtom';
import { fetchDashboardVacanciesByParamsAtom } from '../atoms/fetchDashboardVacanciesByParamsAtom';
// types
import { VACANCY_STATUS } from 'models/enums';
import { vacancyFiltersTypes } from '../types';
// helpers
import { uniqArrayHelper, vacanciesSearchParams } from 'utils/filterHelpers';

// We collect all the filters that the user used into one array in order to correctly change the relay Connection.handler
export const fetchDashboardVacanciesByOpenParamsSelector = selector({
  key: 'fetchDashboardVacanciesByOpenParamsSelector',
  get: ({ get }: { get: GetRecoilValue }): vacancyFiltersTypes => {
    const searchParams: URLSearchParams = new URL(document.location.href).searchParams;
    const isSearchParamsExist = Array.from(searchParams.keys()).length;

    const tabStatus = get(fetchDashboardVacanciesByParamsAtom).filterByStatus;
    const isOpenTab = tabStatus === VACANCY_STATUS.OPEN;

    const selectedOpenedFilters = get(selectedDashboardVacanciesByParamsOpenAtom);

    if (!isSearchParamsExist || !isOpenTab) {
      return selectedOpenedFilters;
    }

    const params = vacanciesSearchParams(searchParams);

    const newFilters = [
      ...selectedOpenedFilters,
      {
        orderBy: {
          field: params.fieldName,
          direction: params.direction,
        },
      },
    ];

    return uniqArrayHelper(newFilters);
  },
  set: ({ set }, newValue) => {
    set(selectedDashboardVacanciesByParamsOpenAtom, uniqArrayHelper(newValue as vacancyFiltersTypes));
  },
});
