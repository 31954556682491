import React, { useState } from 'react';
//utils
import { isEmpty } from 'lodash';
import { useSearchParams } from 'react-router-dom';
//enums
import { FILTER_TYPE } from 'models/enums';

export const useFilterSearchUrl = (filterSearchName: FILTER_TYPE) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedItemsFromUrl, setSelectedItemsFromUrl] = useState<string[]>((searchParams.get(filterSearchName) as string)?.split(',').filter(item => !!item) ?? []);

  const createSearchUrl =
    <ItemType extends { id: string; isoCode?: string }>(filteredItem: ItemType, ref?: React.RefObject<HTMLElement>) =>
    () => {
      // after each added filter to url ref will be replaced back to input
      ref?.current?.focus();
      const currentSearchParam =
        searchParams
          .get(filterSearchName)
          ?.split(',')
          .filter(item => !!item) || [];
      //for countries, we should add isoCode instead of id to search url
      const itemIdentify = filteredItem.isoCode ? filteredItem.isoCode : filteredItem.id;

      setSelectedItemsFromUrl(prev => {
        if (prev.find(itemId => itemId === itemIdentify)) {
          return prev.filter(item => item !== itemIdentify);
        }
        return [...prev, itemIdentify];
      });

      const filteredCurrentParam = currentSearchParam.includes(itemIdentify) ? currentSearchParam.filter(urlId => urlId !== itemIdentify) : [...currentSearchParam, itemIdentify];

      if (!isEmpty(currentSearchParam) && isEmpty(filteredCurrentParam)) {
        searchParams.delete(filterSearchName);
        setSearchParams(searchParams);
      } else {
        const comparedSearchParas = Object.fromEntries([...searchParams]);
        setSearchParams({ ...comparedSearchParas, [filterSearchName]: filteredCurrentParam.join(',') });
      }
    };

  return {
    createSearchUrl,
    selectedItemsFromUrl,
  };
};
export default useFilterSearchUrl;
