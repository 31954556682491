import React from 'react';
import RangeDateInputSkeleton from 'system/skeletons/components/RangeDateInputSkeleton';
import CheckboxSkeleton from '../components/CheckboxSkeleton';
import TextInputSkeleton from '../components/TextInputSkeleton';
import Skeleton from 'react-loading-skeleton';

const EducationFormSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col w-full space-y-6">
      <RangeDateInputSkeleton />
      <CheckboxSkeleton widthText={165} />
      <TextInputSkeleton widthTitle={40} borderRadius="0.375rem" heightInput={38} />
      <TextInputSkeleton widthTitle={40} borderRadius="0.375rem" heightInput={38} />
      <div className="flex justify-end">
        <Skeleton width={140} height={34} borderRadius="0.375rem" />
        <Skeleton width={180} height={34} className="sm:ml-2 mt-5 sm:mt-0" borderRadius="0.375rem" />
      </div>
    </div>
  );
};

export default EducationFormSkeleton;
