/**
 * @generated SignedSource<<4d7a4e127af5dd5949eb01d2eb9d9a76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ProfileFragmentsCrewProfile$data = {
  readonly 'appraisals': ReadonlyArray<{
    readonly ' $fragmentSpreads': FragmentRefs<'AppraisalFragmentsListWithoutDocumentPlural_profile'>;
  }> | null;
  readonly 'certificateInfos': ReadonlyArray<{
    readonly ' $fragmentSpreads': FragmentRefs<'CertificateFragmentsCertificateInfo'>;
  }> | null;
  readonly 'createdAt': String;
  readonly 'educations': ReadonlyArray<{
    readonly 'id': string;
    readonly ' $fragmentSpreads': FragmentRefs<'EducationFragments'>;
  }> | null;
  readonly 'generalSetting': {
    readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsGeneralSettings'>;
  } | null;
  readonly 'id': string;
  readonly 'info': {
    readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragments_info'>;
  } | null;
  readonly 'membership': ReadonlyArray<{
    readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsOrganizationPlural_profile'>;
  }> | null;
  readonly 'seaServices': ReadonlyArray<{
    readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragmentsListPlural_profile'>;
  }> | null;
  readonly 'seaServicesAddedCount': number;
  readonly 'seaServicesHighlighted': ReadonlyArray<{
    readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragmentsListPlural_profile'>;
  }> | null;
  readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsAvatarInfo'>;
  readonly ' $fragmentType': 'ProfileFragmentsCrewProfile';
};
export type ProfileFragmentsCrewProfile$key = {
  readonly ' $data'?: ProfileFragmentsCrewProfile$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsCrewProfile'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = [
      {
        args: null,
        kind: 'FragmentSpread',
        name: 'ServiceFragmentsListPlural_profile',
      },
    ];
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'ProfileFragmentsCrewProfile',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'createdAt',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'ProfileInfo',
        kind: 'LinkedField',
        name: 'info',
        plural: false,
        selections: [
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'ProfileFragments_info',
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'CertificateInfo',
        kind: 'LinkedField',
        name: 'certificateInfos',
        plural: true,
        selections: [
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'CertificateFragmentsCertificateInfo',
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'SeaService',
        kind: 'LinkedField',
        name: 'seaServices',
        plural: true,
        selections: v1 /*: any*/,
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Education',
        kind: 'LinkedField',
        name: 'educations',
        plural: true,
        selections: [
          v0 /*: any*/,
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'EducationFragments',
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'SeaService',
        kind: 'LinkedField',
        name: 'seaServicesHighlighted',
        plural: true,
        selections: v1 /*: any*/,
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Appraisal',
        kind: 'LinkedField',
        name: 'appraisals',
        plural: true,
        selections: [
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'AppraisalFragmentsListWithoutDocumentPlural_profile',
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'OrganizationMember',
        kind: 'LinkedField',
        name: 'membership',
        plural: true,
        selections: [
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'ProfileFragmentsOrganizationPlural_profile',
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'GeneralSetting',
        kind: 'LinkedField',
        name: 'generalSetting',
        plural: false,
        selections: [
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'ProfileFragmentsGeneralSettings',
          },
        ],
        storageKey: null,
      },
      {
        args: null,
        kind: 'FragmentSpread',
        name: 'ProfileFragmentsAvatarInfo',
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'seaServicesAddedCount',
        storageKey: null,
      },
    ],
    type: 'Profile',
    abstractKey: null,
  };
})();

(node as any).hash = 'd6da1f525f4c184464fff18bcde72d1f';

export default node;
