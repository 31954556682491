const SearchThin = ({ className = 'text-darkBlue w-5 h-5' }: { className?: string }) => {
  return (
    <svg className={className} viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_14248_108323" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <rect x="0.119629" width="24" height="24" fill="currentColor" />
      </mask>
      <g mask="url(#mask0_14248_108323)">
        <path
          d="M9.61963 16C7.80296 16 6.26546 15.3708 5.00713 14.1125C3.7488 12.8542 3.11963 11.3167 3.11963 9.5C3.11963 7.68333 3.7488 6.14583 5.00713 4.8875C6.26546 3.62917 7.80296 3 9.61963 3C11.4363 3 12.9738 3.62917 14.2321 4.8875C15.4905 6.14583 16.1196 7.68333 16.1196 9.5C16.1196 10.2333 16.003 10.925 15.7696 11.575C15.5363 12.225 15.2196 12.8 14.8196 13.3L20.4196 18.9C20.603 19.0833 20.6946 19.3167 20.6946 19.6C20.6946 19.8833 20.603 20.1167 20.4196 20.3C20.2363 20.4833 20.003 20.575 19.7196 20.575C19.4363 20.575 19.203 20.4833 19.0196 20.3L13.4196 14.7C12.9196 15.1 12.3446 15.4167 11.6946 15.65C11.0446 15.8833 10.353 16 9.61963 16ZM9.61963 14C10.8696 14 11.9321 13.5625 12.8071 12.6875C13.6821 11.8125 14.1196 10.75 14.1196 9.5C14.1196 8.25 13.6821 7.1875 12.8071 6.3125C11.9321 5.4375 10.8696 5 9.61963 5C8.36963 5 7.30713 5.4375 6.43213 6.3125C5.55713 7.1875 5.11963 8.25 5.11963 9.5C5.11963 10.75 5.55713 11.8125 6.43213 12.6875C7.30713 13.5625 8.36963 14 9.61963 14Z"
          fill="currentColor"
          fillOpacity="1"
        />
      </g>
    </svg>
  );
};

export default SearchThin;
