import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
// routing
import { RoutePaths } from 'app/routing';
// icons
import NoVacanciesIcon from 'icons/NoVacanciesIcon';
import { PlusIcon } from '@heroicons/react/24/solid';
// models
import { EmptyDashboardProps } from '../types';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';
// components
import BlueLinkButton from 'system/Buttons/BlueLinkButton';

const EmptyDashboard = ({
  svgIcon,
  titleId = 'hire_noVacancies_title',
  descriptionId = 'hire_noVacancies_description',
  actionFunction,
  actionDescriptionId = 'hire_dashboard_header_button_job_opening',
  isRenderBigButton,
}: EmptyDashboardProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);

  const createJobPostingLink: string = `${RoutePaths.ProfileOrganizationBase}/${getCurrentOrganisation.organisationId}/${RoutePaths.JobOpeningStep1}`;

  const handleOpenForm = useCallback(() => {
    navigate(createJobPostingLink, {
      state: {
        organization: {
          id: getCurrentOrganisation.organisationId,
        },
      },
    });
  }, [createJobPostingLink, getCurrentOrganisation.organisationId, navigate]);

  return (
    <div className="flex flex-col w-64 mx-auto mt-20 items-center">
      {svgIcon ? <div className="w-14 h-14">{svgIcon}</div> : <NoVacanciesIcon />}

      <div className="flex flex-col items-center mt-4">
        <div className="text-center text-specialGray-1 text-2xl font-semibold">{intl.formatMessage({ id: titleId })}</div>
        <span className="text-xs text-specialGray-075 text-center mt-3 w-3/4 mb-[18px]">{intl.formatMessage({ id: descriptionId })}</span>
        {isRenderBigButton ? (
          <BlueLinkButton
            dataTestId="empty-dashboard-event"
            withIcon
            to={createJobPostingLink}
            title={{ id: actionDescriptionId }}
            handleClick={actionFunction ?? handleOpenForm}
            state={{
              organization: {
                id: getCurrentOrganisation.organisationId,
              },
            }}
          />
        ) : (
          <div data-test-id="empty-dashboard-event" onClick={actionFunction ?? handleOpenForm} className="text-blue text-sm font-medium cursor-pointer flex items-center">
            <PlusIcon className="w-4 h-4 mr-3" />
            {intl.formatMessage({ id: actionDescriptionId })}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmptyDashboard;
