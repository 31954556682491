// packages
import { Suspense } from 'react';
// components
import { Footer } from 'app/Footer/Footer';
import { Navbar } from 'app/Navbar/Navbar';
import AccountSettings from './AccountSettings';
import NavBarSkeleton from 'system/skeletons/NavBar/NavBarSkeleton';
import AccountSettingsSkeletonCommon from 'system/skeletons/settings/AccountSettingsSkeletonCommon';
//error boundary
import ErrorBoundaryMainContent from 'system/ErrorBoundaries/ErrorBoundaryMainContent';

const AccountSettingsCommon = () => {
  return (
    <>
      <Suspense fallback={<NavBarSkeleton />}>
        <Navbar />
      </Suspense>
      <ErrorBoundaryMainContent>
        <Suspense fallback={<AccountSettingsSkeletonCommon />}>
          <AccountSettings />
        </Suspense>
      </ErrorBoundaryMainContent>
      <Footer />
    </>
  );
};

export default AccountSettingsCommon;
