// packages
import { useState } from 'react';
import { useMutation } from 'react-relay';
// components
import SettingsCheckBox from 'system/SettingsCheckBox/SettingsCheckBox';
// schemas
import { UPDATE_PROFILE_INFO_HIDDEN } from 'schemas/profile/ProfileMutations';
// generated
import { ProfileFragments$data } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { ProfileFragments_info$data } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
import { ProfileMutationsUpdateProfileInfoHiddenMutation } from 'schemas/profile/__generated__/ProfileMutationsUpdateProfileInfoHiddenMutation.graphql';
//hooks
import { useAuth } from 'authentication';
import useGeneralSettings from 'hooks/accountSettings/useGeneralSettings';
//enums
import { PERSONAL_GENERAL_SETTINGS } from 'models/enums';

const ChangeVisibleAccount = ({ profileInfo, profileData }: { profileInfo: ProfileFragments_info$data | null; profileData: ProfileFragments$data | null }) => {
  const { identity } = useAuth();
  const [isChecked, setIsChecked] = useState<boolean>(profileInfo?.isHidden || false);

  const [commitUpdateVisibleProfile, loading] = useMutation<ProfileMutationsUpdateProfileInfoHiddenMutation>(UPDATE_PROFILE_INFO_HIDDEN);

  const { loading: generalLoading, onChangeFieldGeneralSettings, generalSettings } = useGeneralSettings(profileData);

  const onChangeField = (checked: boolean) => {
    commitUpdateVisibleProfile({
      variables: {
        ownerId: identity?.profileId ? (identity?.profileId.toString() as string) : '',
        value: checked,
      },
      onError: err => {
        throw err;
      },
      onCompleted: data => {
        const analyticsEventName = isChecked ? 'show-profile' : 'hide-profile';
        window.analytics?.track(`wui-visibility-${analyticsEventName}`);
        setIsChecked(!!data.updateProfileInfoHidden.isHidden);
      },
    });
  };

  return (
    <div className="flex flex-col justify-items-start w-auto space-y-8 sm:w-2/3">
      <SettingsCheckBox
        label="hide_account_from_search_label_checkbox"
        checked={isChecked}
        eventFunction={onChangeField}
        isLoading={loading}
        description="account_visibility_description"
      />
      <SettingsCheckBox
        label="general_settings_hide_contact_info"
        checked={generalSettings?.hideContactInfo ?? false}
        eventFunction={onChangeFieldGeneralSettings(PERSONAL_GENERAL_SETTINGS.hideContactInfo)}
        isLoading={generalLoading}
        description="tips_general_settings_hide_contact_info"
      />
    </div>
  );
};

export default ChangeVisibleAccount;
