import React from 'react';
import Skeleton from 'react-loading-skeleton';

const DropDownListSkeleton: React.FC<{ positionAbsolute: boolean }> = ({ positionAbsolute }) => {
  return (
    <div
      className={`flex ${positionAbsolute ? 'absolute b-0' : ''} flex-col bg-white w-full border-solid border border-gray-300 rounded-md max-h-40 overflow-y-auto z-10 shadow-xl`}
    >
      <div className="h-8 flex items-center bg-gray-200">
        <Skeleton width={150} className="align-sub ml-8" />
      </div>
      <div className="h-8 flex items-center">
        <Skeleton width={150} className="align-sub ml-8" />
      </div>
      <div className="h-8 flex items-center">
        <Skeleton width={150} className="align-sub ml-8" />
      </div>
      <div className="h-8 flex items-center">
        <Skeleton width={150} className="align-sub ml-8" />
      </div>
      <div className="h-8 flex items-center">
        <Skeleton width={150} className="align-sub ml-8" />
      </div>
    </div>
  );
};

export default DropDownListSkeleton;
