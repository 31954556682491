// packages
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
// routing
import { RoutesPathNames } from 'app/routing';
// models
import { VACANCY_DESCRIPTION_TYPE_OF_LIST, VACANCY_STATUS } from 'models/enums';
import { ApplicationFragments$key } from 'schemas/Application/__generated__/ApplicationFragments.graphql';
// generated
import { ApplicationQueriesGetListOfAppliedCrewQuery } from 'schemas/Application/__generated__/ApplicationQueriesGetListOfAppliedCrewQuery.graphql';
// schemas
import { GET_LIST_OF_APPLIED_CREW } from 'schemas/Application/ApplicationQueries';
// components
import BigAppliedIcon from 'icons/BigAppliedIcon';
import RightSideEmptyMessage from './RightSideEmptyMessage';
import RightSideCommonListOfCrew from './RightSideCommonListOfCrew';
import RightSideApplicationItemListOfCrew from './RightSideApplicationItemListOfCrew';
//types
import { RightSideListOfCrewItemProps } from '../types';

const RightSideListOfAppliedCrew = ({ handleSwitchStatus, vacancy, isCommitSwitchVacancyLoading }: RightSideListOfCrewItemProps) => {
  const { jobDescriptionEditId } = useParams();

  const appliedCrews = useLazyLoadQuery<ApplicationQueriesGetListOfAppliedCrewQuery>(
    GET_LIST_OF_APPLIED_CREW,
    { vacancyId: jobDescriptionEditId!, skip: !jobDescriptionEditId },
    { fetchPolicy: 'store-and-network' },
  );

  const emptyMessageDescription =
    vacancy.status === VACANCY_STATUS.OPEN ? 'hire_job_description_no_applied_user_description' : 'hire_job_description_closed_user_applied_description';

  return (
    <RightSideCommonListOfCrew
      title={{ id: 'hire_job_description_applied_crew_title' }}
      seeAllLink={RoutesPathNames.JobReviewApplications}
      typeOfList={VACANCY_DESCRIPTION_TYPE_OF_LIST.APPLIED}
      amountOfUsers={appliedCrews?.applications?.edges?.length ?? 0}
    >
      {!isEmpty(appliedCrews?.applications?.edges) ? (
        appliedCrews?.applications?.edges?.map((item, index) =>
          index < 3 ? <RightSideApplicationItemListOfCrew key={item?.node?.id} applicationFragment={item?.node as ApplicationFragments$key} /> : null,
        )
      ) : (
        <RightSideEmptyMessage
          isCommitSwitchVacancyLoading={isCommitSwitchVacancyLoading}
          vacancy={vacancy}
          handleSwitchStatus={handleSwitchStatus}
          title={{ id: 'hire_job_description_no_applied_user_label' }}
          description={{ id: emptyMessageDescription }}
          typeOfList={VACANCY_DESCRIPTION_TYPE_OF_LIST.APPLIED}
        >
          <BigAppliedIcon />
        </RightSideEmptyMessage>
      )}
    </RightSideCommonListOfCrew>
  );
};

export default RightSideListOfAppliedCrew;
