// packages
import { RoutePaths } from 'app/routing';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// models
import { EmptyListProps } from 'models/modelsOfComponents';

const EmptyList: FC<EmptyListProps> = ({ icon, title, description }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleClickByButton = () => {
    navigate(RoutePaths.JobOpenings);
  };

  return (
    <div className="flex flex-col w-64 mx-auto mt-28 items-center">
      {icon}
      <div className="flex flex-col items-center mt-4">
        <div className="text-center text-specialGray-1 text-2xl font-semibold">{intl.formatMessage(title)}</div>
        <span className="text-xs text-specialGray-075 text-center mt-3">{intl.formatMessage(description)}</span>
      </div>
      <span onClick={handleClickByButton} className="text-blue text-sm font-medium cursor-pointer mt-5">
        {intl.formatMessage({ id: 'job_responses_empty_search_button_label' })}
      </span>
    </div>
  );
};

export default EmptyList;
