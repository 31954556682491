import Skeleton from 'react-loading-skeleton';

const OverviewProfileScoreSkeleton = () => {
  return (
    <div className="flex flex-col space-x-0 items-center space-y-4 sm:space-y-0 sm:flex-row w-full mt-8 sm:justify-between sm:space-x-8">
      <Skeleton width="100%" height="100%" containerClassName="sm:w-[236px] lg:w-[400px] h-[236px] lg:h-[137px]" borderRadius={15} />
      <Skeleton width="100%" height="100%" containerClassName="lg:w-[855px] sm:h-[236px] lg:h-[137px]" borderRadius={15} />
    </div>
  );
};

export default OverviewProfileScoreSkeleton;
