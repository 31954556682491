import Skeleton from 'react-loading-skeleton';
import OverviewProfileScoreSkeleton from 'system/skeletons/profile/ProfileSkeleton/components/OverviewProfileScoreSkeleton';

const OverviewTabSkeleton = () => {
  return (
    <>
      <OverviewProfileScoreSkeleton />
      <div className="flex flex-col items-center w-56 mx-auto mt-12">
        <Skeleton width={57} height={45} borderRadius="0.75rem" />
        <Skeleton width={250} height={24} className="mt-3 mb-1.5" />
        <Skeleton width={350} height={10} containerClassName="h-4" />
        <Skeleton width={250} height={10} containerClassName="h-4" />
        <Skeleton width={185} height={48} className="mt-5" borderRadius={10} />
      </div>
    </>
  );
};

export default OverviewTabSkeleton;
