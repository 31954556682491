import { useIntl } from 'react-intl';

const EducationItemTitle = () => {
  const intl = useIntl();

  return (
    <thead>
      <tr className="hidden sm:flex flex-row rounded-2xl rounded-b-none border border-b-0 sm:border-b-1 bg-white sm:bg-specialGray-004">
        <th className="flex pr-3 py-3 ml-8 w-1/3 text-specialGray-075 font-medium text-xs uppercase">{intl.formatMessage({ id: 'field_is_name' })}</th>
        <th className="flex pr-3 py-3 w-1/4 text-specialGray-075 font-medium text-xs uppercase">{intl.formatMessage({ id: 'education_item_title_level' })}</th>
        <th className="flex pr-3 py-3 w-15 text-specialGray-075 font-medium text-xs uppercase">{intl.formatMessage({ id: 'education_item_title_start' })}</th>
        <th className="flex pr-3 py-3 w-15 text-specialGray-075 font-medium text-xs uppercase">{intl.formatMessage({ id: 'education_item_title_end' })}</th>
        <th className="flex w-12" />
        <th className="flex w-12" />
      </tr>
    </thead>
  );
};

export default EducationItemTitle;
