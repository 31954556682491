const SpinnerIcon = ({
  additionalClasses = 'w-20 h-20 mx-auto text-specialGray-05',
  spinnerColor = 'rgba(8, 32, 81, 0.5)',
}: {
  additionalClasses?: string;
  spinnerColor?: string;
}) => {
  return (
    <div className="h-full flex justify-center items-center">
      <svg xmlns="http://www.w3.org/2000/svg" className={`animate-spin ${additionalClasses}`} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" stroke={spinnerColor} strokeWidth="5" r="36" strokeDasharray="169.64600329384882 58.548667764616276" />
      </svg>
    </div>
  );
};

export default SpinnerIcon;
