// The RootProfileQuery is reponsibile for fetching any profile including the one of the
// currently logged-in user.
import graphql from 'babel-plugin-relay/macro';

export const PROFILE_QUERY = graphql`
  query ProfileQueriesRootQuery($id: ID!, $skip: Boolean!) {
    node(id: $id) @skip(if: $skip) {
      ... on Profile {
        ...ProfileFragments
      }
    }
  }
`;

export const CREW_PROFILE_QUERY = graphql`
  query ProfileQueriesCrewProfileQuery($id: ID!) {
    node(id: $id) {
      ... on Profile {
        ...ProfileFragmentsCrewProfile
      }
    }
  }
`;
export const GET_CREW_PROFILE_INFO = graphql`
  query ProfileQueriesCrewProfileInfoQuery($id: ID!) {
    node(id: $id) {
      ... on Profile {
        seaServices {
          id
          vessel {
            name
            imo
          }
        }
        certificateInfos {
          certificate {
            id
          }
        }
        info {
          firstName
          lastName
          avatarUrl
          birthDate
          primaryDuty {
            name
          }
          country {
            id
            name
            isoCode
          }
        }
      }
    }
  }
`;

export const GET_PROFILE_SHARE = graphql`
  query ProfileQueriesProfileShareQuery($uuid: String!) {
    profileShare(uuid: $uuid) {
      id
      owner {
        ...ProfileFragmentsCrewProfile
      }
    }
  }
`;

export const GET_PROFILE_NODE = graphql`
  query ProfileQueriesProfileNodeQuery($id: ID!, $skip: Boolean!) {
    node(id: $id) @skip(if: $skip) {
      ... on Profile {
        id
        info {
          id
          firstName
          lastName
        }
        certificateInfos {
          id
          certificate {
            id
            name
            kind {
              id
              name
              perma
            }
          }
        }
      }
    }
  }
`;

export const GET_PROFILE_VESSELS = graphql`
  query ProfileQueriesProfileVesselsListQuery($id: ID) {
    vesselList(ownerId: $id) {
      totalCount
    }
  }
`;

export const GET_PROFILE_OID_CLAIMS = graphql`
  query ProfileQueriesGetProfileOIDClaimsQuery($ids: [Int!]!, $skip: Boolean!) {
    profileOIDClaims(ids: $ids) @skip(if: $skip) {
      edges {
        email
        phoneNumber
        phoneNumberVerified
      }
    }
  }
`;

export const GET_EMAIL_OF_PROFILE = graphql`
  query ProfileQueriesGetEmailOfProfileQuery($ids: [Int!]!, $skip: Boolean!) {
    profileOIDClaims(ids: $ids) @skip(if: $skip) {
      edges {
        email
      }
    }
  }
`;

export const PROFILE_SCORE_FOR_SUBSCRIPTION = graphql`
  query ProfileQueriesScoreSubscriptionQuery($pid: Int!, $skip: Boolean!) {
    profileScore(pid: $pid) @skip(if: $skip) {
      id
      ...ProfileFragmentsScoreSubscription
    }
  }
`;

export const GET_CREW_POOLS = graphql`
  query ProfileQueriesGetProfileCrewPoolsQuery($orgId: ID!, $after: Cursor, $first: Int, $before: Cursor, $last: Int, $orderBy: CrewPoolOrder) {
    crewPools(orgId: $orgId, after: $after, first: $first, before: $before, last: $last, orderBy: $orderBy) @connection(key: "CrewPoolsList_crewPools") {
      totalCount
      edges {
        node {
          id
          name
          ...ProfileFragmentsEmployerCrewPools
        }
      }
    }
  }
`;

export const GET_SELECTED_POOLS_FOR_PROFILE = graphql`
  query ProfileQueriesGetSelectedPoolsForProfileQuery(
    $profileId: ID!
    $orgId: ID!
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $orderBy: CrewPoolOrder
    $skip: Boolean!
  ) {
    crewPoolsByProfile(profileId: $profileId, orgId: $orgId, after: $after, before: $before, orderBy: $orderBy, first: $first, last: $last)
      @skip(if: $skip)
      @connection(key: "crewPoolsByProfileList_crewPoolsByProfile") {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const GET_CREW_POOL_PROFILES = graphql`
  query ProfileQueriesGetProfileCrewPoolProfilesQuery($poolId: ID!, $after: Cursor, $first: Int, $before: Cursor, $last: Int, $orderBy: ProfileOrder) {
    crewPoolProfiles(poolId: $poolId, before: $before, first: $first, last: $last, after: $after, orderBy: $orderBy) @connection(key: "crewPoolProfilesList_crewPoolProfiles") {
      totalCount
      edges {
        node {
          id
          generalSetting {
            availableForWork
          }
          info {
            id
            primaryDuty {
              id
            }
            firstName
            lastName
            birthDate
            country {
              isoCode
            }
            avatarUrl
            primaryDuty {
              id
            }
          }
          certificateInfos {
            certificate {
              id
            }
          }
          seaServices {
            id
            start
            end
            duty {
              id
            }
            activities {
              id
            }
          }
          generalSetting {
            availableForWork
          }
        }
      }
    }
  }
`;

export const GET_CREW_POOLS_STATS = graphql`
  query ProfileQueriesGetCrewPoolStatsQuery($poolIds: [ID!]!) {
    crewPoolStats(poolIds: $poolIds) {
      stats {
        id
        totalCrewCount
        ...ProfileFragmentsEmployerCrewPoolStats
      }
    }
  }
`;
export const GET_ALL_HIRE_ORGANIZATION_MEMBERS = graphql`
  query ProfileQueriesGetOrganizationMembersConnectionQuery($orgId: ID!, $first: Int) {
    organizationMembers(orgId: $orgId, first: $first) @connection(key: "organizationMembersList_organizationMembers") {
      totalCount
      edges {
        node {
          id
          role
          profile {
            id
          }
          ...ProfileFragmentsOrganizationMemberInfo
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_PROFILE_ORGANIZATION_MEMBER_INVITATIONS = graphql`
  query ProfileQueriesGetProfileOrganizationMemberInvitationsQuery($profileId: ID!, $skip: Boolean!, $first: Int, $last: Int) {
    profileOrganizationMemberInvitations(profileId: $profileId, first: $first, last: $last)
      @skip(if: $skip)
      @connection(key: "profileOrganizationMemberInvitationsList_profileOrganizationMemberInvitations") {
      totalCount
      edges {
        node {
          id
          ...ProfileFragmentsOrganizationMemberInvitation
        }
      }
    }
  }
`;

export const GET_PROFILE_SEARCH_INFO_MEMBERSHIP = graphql`
  query ProfileQueriesGetProfileInfoMembershipQuery($ids: [ID!]!) {
    nodes(ids: $ids) {
      id
      ...ProfileFragmentsSearchDropDown
    }
  }
`;

export const GET_PROFILE_PRODUCT_OWNERSHIP = graphql`
  query ProfileQueriesGetProfileProductOwnershipQuery($orgId: ID!, $skip: Boolean!) {
    productOwnerships(orgId: $orgId) @skip(if: $skip) {
      totalCount
      edges {
        node {
          id
          product {
            id
            perma
          }
          organization {
            id
          }
          untilValid
        }
      }
    }
  }
`;
