import Skeleton from 'react-loading-skeleton';

const EmployerAddUserToCrewPoolMainSkeleton = () => {
  return (
    <div className="flex flex-col w-1/2 pt-3">
      <div className="flex flex-col w-full gap-y-3">
        <Skeleton width={160} height={16} className="leading-6" />
        <Skeleton height={38} className="flex w-full leading-6" />
      </div>

      <div className="flex flex-col w-full gap-y-4">
        <div className="flex flex-col w-full gap-y-4">
          <Skeleton width={60} height={16} className="leading-6" />
          <div className="flex flex-col w-full gap-y-1 max-h-[140px] overflow-y-scroll">
            <Skeleton height={58} containerClassName="flex w-full h-[58px]" />
            <Skeleton height={58} containerClassName="flex w-full h-[58px]" />
            <Skeleton height={58} containerClassName="flex w-full h-[58px]" />
          </div>
        </div>

        <Skeleton height={44} containerClassName="flex w-full" />
      </div>
    </div>
  );
};

export default EmployerAddUserToCrewPoolMainSkeleton;
