// packages
import { useIntl } from 'react-intl';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// mocks
import { RoutePaths } from 'app/routing';
// models
import { JOB_OPENING_FORM_STEPS } from 'mocks/mockData';
import { JobOpeningFormStepType } from 'models/commonTypes';
// context
import { useJobOpeningFormContext } from 'Context/JobOpeningFormContext';

const StepFormComponent = ({ step, nextSibling }: { step: JobOpeningFormStepType; nextSibling: JobOpeningFormStepType }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { isChange, setNextStep } = useJobOpeningFormContext();

  useEffect(() => {
    if (nextSibling && step.active && step.current) {
      setNextStep(nextSibling);
    }
  }, [setNextStep, nextSibling, step]);

  const handleClickByStep = useCallback(() => {
    if (isChange) {
      return;
    }
    if (step.active) {
      navigate(`${RoutePaths.ProfileOrganizationBase}/${state?.organization?.id}/${step.path}`, {
        state,
        replace: true,
      });
    }
  }, [navigate, step.path, step.active, isChange, state]);

  const handleReload = useCallback(() => {
    navigate(pathname, {
      state,
      replace: true,
    });
  }, [navigate, pathname, state]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleReload);
    return () => {
      window.removeEventListener('beforeunload', handleReload);
    };
  }, [handleReload]);

  return (
    <div
      className={`flex flex-col w-1/4 space-y-2 ${isChange ? 'cursor-not-allowed opacity-50' : `${step.active ? 'cursor-pointer' : 'cursor-not-allowed'} opacity-100`}`}
      onClick={handleClickByStep}
    >
      <div className={`flex space-x-2 ${step.current ? 'text-darkBlue' : 'text-specialGray-03'}`}>
        <p className="whitespace-nowrap">
          {intl.formatMessage({ id: 'hire_form_general_step' }, { step: step.step })}
          <span className="text-specialGray-03">{intl.formatMessage({ id: 'hire_form_general_step_fraction' }, { fraction: JOB_OPENING_FORM_STEPS.length })}</span>
        </p>
        <span>{intl.formatMessage(step.title)}</span>
      </div>
      <div className={`${step.active ? 'bg-[#00BF78]' : 'bg-gray-200'} h-2 rounded-lg w-full`} />
    </div>
  );
};

export default StepFormComponent;
