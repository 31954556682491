/**
 * @generated SignedSource<<ee7f13a14ce61bb6c7c3988393940d2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProfileQueriesGetProfileProductOwnershipQuery$variables = {
  orgId: string;
  skip: boolean;
};
export type ProfileQueriesGetProfileProductOwnershipQuery$data = {
  readonly productOwnerships?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly organization: {
          readonly id: string;
        };
        readonly product: {
          readonly id: string;
          readonly perma: string;
        };
        readonly untilValid: String | null;
      };
    }>;
    readonly totalCount: number;
  } | null;
};
export type ProfileQueriesGetProfileProductOwnershipQuery = {
  response: ProfileQueriesGetProfileProductOwnershipQuery$data;
  variables: ProfileQueriesGetProfileProductOwnershipQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'orgId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'skip',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v2 = [
      {
        condition: 'skip',
        kind: 'Condition',
        passingValue: false,
        selections: [
          {
            alias: null,
            args: [
              {
                kind: 'Variable',
                name: 'orgId',
                variableName: 'orgId',
              },
            ],
            concreteType: 'ProductOwnershipConnection',
            kind: 'LinkedField',
            name: 'productOwnerships',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'totalCount',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'ProductOwnershipEdge',
                kind: 'LinkedField',
                name: 'edges',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    concreteType: 'ProductOwnership',
                    kind: 'LinkedField',
                    name: 'node',
                    plural: false,
                    selections: [
                      v1 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        concreteType: 'Product',
                        kind: 'LinkedField',
                        name: 'product',
                        plural: false,
                        selections: [
                          v1 /*: any*/,
                          {
                            alias: null,
                            args: null,
                            kind: 'ScalarField',
                            name: 'perma',
                            storageKey: null,
                          },
                        ],
                        storageKey: null,
                      },
                      {
                        alias: null,
                        args: null,
                        concreteType: 'Organization',
                        kind: 'LinkedField',
                        name: 'organization',
                        plural: false,
                        selections: [v1 /*: any*/],
                        storageKey: null,
                      },
                      {
                        alias: null,
                        args: null,
                        kind: 'ScalarField',
                        name: 'untilValid',
                        storageKey: null,
                      },
                    ],
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileQueriesGetProfileProductOwnershipQuery',
      selections: v2 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileQueriesGetProfileProductOwnershipQuery',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '3c9010bc65bb50f62086ec9e9b87d04d',
      id: null,
      metadata: {},
      name: 'ProfileQueriesGetProfileProductOwnershipQuery',
      operationKind: 'query',
      text: 'query ProfileQueriesGetProfileProductOwnershipQuery(\n  $orgId: ID!\n  $skip: Boolean!\n) {\n  productOwnerships(orgId: $orgId) @skip(if: $skip) {\n    totalCount\n    edges {\n      node {\n        id\n        product {\n          id\n          perma\n        }\n        organization {\n          id\n        }\n        untilValid\n      }\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '8a5c08fd7eaafa829adbb162c86d7516';

export default node;
