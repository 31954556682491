import { useState } from 'react';
//components
import PopoverTitle from './PopoverTitle';
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';

const TableCellWithPopover = ({ text }: { text: string }) => {
  const [isRenderPopover, setIsRenderPopover] = useState<boolean>(false);

  const renderPopoverTitle = (title: string) => <span className="truncate text-sm">{title}</span>;

  return isRenderPopover ? (
    <PopoverHelper
      classes="max-w-fit flex"
      element={renderPopoverTitle(text)}
      position="bottom-7 left-1/2 -translate-x-1/2"
      positionArrow="left-1/2 rotate-45 -bottom-[3px]"
      defaultText={text}
    />
  ) : (
    <PopoverTitle title={text} setIsRenderPopover={setIsRenderPopover} />
  );
};

export default TableCellWithPopover;
