import { ConnectionHandler, RecordSourceSelectorProxy } from 'relay-runtime';
//generated
import { ProfileMutationsCreateOrganizationMemberMutation$data } from 'schemas/profile/__generated__/ProfileMutationsCreateOrganizationMemberMutation.graphql';
import { ProfileMutationsDeleteOrganizationMemberMutation$data } from 'schemas/profile/__generated__/ProfileMutationsDeleteOrganizationMemberMutation.graphql';

export const addNewMemberToHireOrgUpdater = (orgId: string) => (store: RecordSourceSelectorProxy<ProfileMutationsCreateOrganizationMemberMutation$data>) => {
  const storyRecord = store.getRoot();
  const payload = store.getRootField('createOrganizationMember');
  const connection = ConnectionHandler.getConnection(storyRecord, 'organizationMembersList_organizationMembers', { orgId });
  const connectionForForm = ConnectionHandler.getConnection(storyRecord, 'organizationMembersListForForm_organizationMembers', { orgId });
  const connectionEdges = connection?.getLinkedRecords('edges');
  if (payload && connectionEdges) {
    connection?.setValue(connectionEdges.length + 1, 'totalCount');
    connection?.setLinkedRecords([payload, ...connectionEdges], 'edges');
    connectionForForm?.setLinkedRecords([payload, ...connectionEdges], 'edges');
  }
};

export const deleteOrgMemberFromList = (orgId: string) => (store: RecordSourceSelectorProxy<ProfileMutationsDeleteOrganizationMemberMutation$data>) => {
  const storyRecord = store.getRoot();
  const payloadDeletedNodeId = store.getRootField('deleteOrganizationMember').getValue('id');

  const connection = ConnectionHandler.getConnection(storyRecord, 'organizationMembersList_organizationMembers', { orgId });
  const connectionForForm = ConnectionHandler.getConnection(storyRecord, 'organizationMembersListForForm_organizationMembers', { orgId });
  const connectionEdges = connection?.getLinkedRecords('edges');

  if (payloadDeletedNodeId && connectionEdges) {
    connection?.setValue(connectionEdges.length - 1, 'totalCount');
    const filteredNodes = connectionEdges.filter(item => {
      const nodeId = item.getLinkedRecord('node')?.getValue('id');
      return nodeId !== payloadDeletedNodeId;
    });
    connection?.setLinkedRecords(filteredNodes, 'edges');
    connectionForForm?.setLinkedRecords(filteredNodes, 'edges');
  }
};
