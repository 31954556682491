// packages
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
// components
import JobOpeningsIcon from 'icons/JobOpeningsIcon';
import OrgInfoField from './components/OrgInfoField';
import OrganizationInfoSizeIcon from 'icons/OrganizationInfoSizeIcon';
import CommercialAvatar from 'app/Avatars/CommercialAvatar/CommercialAvatar';
// generated
import { VacancyQueriesListOfVacanciesQuery } from 'schemas/vacancy/__generated__/VacancyQueriesListOfVacanciesQuery.graphql';
import { OrganizationQueryGetOrganizationNodeQuery } from 'schemas/Organization/__generated__/OrganizationQueryGetOrganizationNodeQuery.graphql';
// schemas
import { GET_ORGANIZATION_NODE } from 'schemas/Organization/OrganizationQuery';
import { LIST_OF_VACANCIES } from 'schemas/vacancy/VacancyQueries';
// hooks
import { useGetOrganizationSize } from 'hooks/useGetOrganizationSize';
// models
import { VACANCY_ORDER_FIELD, VACANCY_STATUS } from 'models/enums';

const MainOrgInfo = () => {
  const intl = useIntl();
  const { orgId } = useParams();
  const organizationInfo = useLazyLoadQuery<OrganizationQueryGetOrganizationNodeQuery>(GET_ORGANIZATION_NODE, { id: orgId!, skip: !orgId })?.node;

  const { selectedOrganizationSize } = useGetOrganizationSize(organizationInfo?.size || null);

  const listOfVacancies = useLazyLoadQuery<VacancyQueriesListOfVacanciesQuery>(LIST_OF_VACANCIES, {
    orgId: organizationInfo?.id,
    skip: !organizationInfo?.id,
    filters: [{ field: VACANCY_ORDER_FIELD.STATUS, values: [VACANCY_STATUS.OPEN] }],
  });

  return (
    <>
      <div className="flex gap-x-6">
        <CommercialAvatar size="w-14 h-14" avatarUrl={organizationInfo?.avatarUrl} />
        <div className="flex flex-col">
          <span className="text-darkBlue text-lg font-medium">{organizationInfo?.name}</span>
          <div className="flex mt-3 gap-x-3 sm:gap-x-6">
            <OrgInfoField label={intl.formatMessage({ id: 'employer_vacancy_status_open_with_number' }, { amount: listOfVacancies?.vacancies?.edges?.length ?? 0 })}>
              <JobOpeningsIcon />
            </OrgInfoField>
            {selectedOrganizationSize?.name ? (
              <OrgInfoField label={selectedOrganizationSize.name}>
                <OrganizationInfoSizeIcon />
              </OrgInfoField>
            ) : null}
          </div>
        </div>
      </div>
      {!!organizationInfo?.description && (
        <div className="flex flex-col gap-y-3">
          <span className="font-medium text-lg">{intl.formatMessage({ id: 'about_label' })}</span>
          <span className="text-sm text-specialGray-075">{organizationInfo.description}</span>
        </div>
      )}
    </>
  );
};

export default MainOrgInfo;
