import { useState } from 'react';
import { useIntl } from 'react-intl';
//hooks
import { useToggleShowWindow } from 'hooks/useToggleShowModal';
//components
import { Modal } from 'system/Modal';
//type
import { FileImageComponentEvItemProps } from 'app/ProfileCrew/type/ProfileCrewTypes';
//utils
import { getLastElementFromArray } from 'utils';

const FileImageComponentEvItem = ({ largeImgUrl, smallImgUrl }: FileImageComponentEvItemProps) => {
  const intl = useIntl();
  const { showWindow, toggleShowWindow } = useToggleShowWindow();
  const [isPDF] = useState(getLastElementFromArray(largeImgUrl?.split('.')) === 'pdf');

  return (
    <div className="flex w-16 h-16">
      <Modal show={showWindow} onClose={toggleShowWindow(false)}>
        {isPDF ? (
          <object data={largeImgUrl} type="application/pdf" width="100%" height="500px">
            <p>{intl.formatMessage({ id: 'error_pdf_plugin' })}</p>
          </object>
        ) : (
          <img src={largeImgUrl} alt="large-img" />
        )}
      </Modal>
      <div className="flex cursor-pointer w-max" onClick={toggleShowWindow(true)}>
        <img className="rounded-md" src={smallImgUrl} alt="" />
      </div>
    </div>
  );
};

export default FileImageComponentEvItem;
