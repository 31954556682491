// packages
import { useLocation } from 'react-router-dom';
import { ConnectionHandler } from 'relay-runtime';
// models
import { typeFormVacancyState } from 'models/routeLocationState';
import { ORDER_DIRECTION, VACANCY_ORDER_FIELD, VACANCY_STATUS } from 'models/enums';

export function useGetConnectionVacancyRecordId() {
  const { state } = useLocation();

  return ConnectionHandler.getConnectionID('client:root', 'VacancyList_vacancies', {
    filters: [{ field: VACANCY_ORDER_FIELD.STATUS, values: [VACANCY_STATUS.CLOSED] }],
    orderBy: { field: VACANCY_ORDER_FIELD.CREATED_AT, direction: ORDER_DIRECTION.DESC },
    orgId: (state as typeFormVacancyState)?.organization?.id as string,
  });
}
