import { useState } from 'react';
// components
import NavbarSearchDropdownMembersSkeletonItem from '../Dropdowns/NavbarSearchDropdownMembersSkeletonItem';
import Skeleton from 'react-loading-skeleton';

const AddOrgMembersSkeleton = () => {
  const [items] = useState<string[]>(['001', '002', '003', '004', '005']);
  return (
    <>
      {items.map(mockItem => (
        <NavbarSearchDropdownMembersSkeletonItem key={mockItem}>
          <Skeleton containerClassName="ml-auto" borderRadius={10} width={67} height={32} />
        </NavbarSearchDropdownMembersSkeletonItem>
      ))}
    </>
  );
};

export default AddOrgMembersSkeleton;
