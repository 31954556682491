import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

const CrewPoolSkeletonItem = () => {
  const [roundedAvatar] = useState<string[]>(['round1', 'round2', 'round3']);
  const [infoItems] = useState<number[]>([56, 77, 58]);

  return (
    <div className="flex flex-col border border-specialGray-01 rounded-2xl w-full min-h-[165px] p-5 text-darkBlue bg-white gap-y-2.5">
      <div className="flex w-full items-center">
        {roundedAvatar.map(round => {
          return <Skeleton key={round} width={24} height={24} borderRadius={50} />;
        })}
        <Skeleton width={20} height={20} containerClassName="ml-auto" borderRadius={2} />
      </div>

      <Skeleton width={140} height={32} className="leading-12" />

      <div className="flex w-full gap-x-5">
        {infoItems.map((itemWidth, i) => {
          return <Skeleton key={i + itemWidth} width={itemWidth} height={14} containerClassName="flex items-end" className="leading-5" />;
        })}
      </div>
    </div>
  );
};

export default CrewPoolSkeletonItem;
