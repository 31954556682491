/**
 * @generated SignedSource<<9c90888f275a88b9300520a9d568ee65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type AppraisalFragmentsListPlural_profile$data = ReadonlyArray<{
  readonly 'seaService': {
    readonly end: String | null;
  };
  readonly ' $fragmentSpreads': FragmentRefs<'AppraisalFragmentsOneAppraisal'>;
  readonly ' $fragmentType': 'AppraisalFragmentsListPlural_profile';
}>;
export type AppraisalFragmentsListPlural_profile$key = ReadonlyArray<{
  readonly ' $data'?: AppraisalFragmentsListPlural_profile$data;
  readonly ' $fragmentSpreads': FragmentRefs<'AppraisalFragmentsListPlural_profile'>;
}>;

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: {
    plural: true,
  },
  name: 'AppraisalFragmentsListPlural_profile',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'SeaService',
      kind: 'LinkedField',
      name: 'seaService',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'end',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'AppraisalFragmentsOneAppraisal',
    },
  ],
  type: 'Appraisal',
  abstractKey: null,
};

(node as any).hash = '47cb234d13de3a138d32224ee3bc2a74';

export default node;
