// packages
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';
import { HeaderListProps } from 'system/ListComponents/types';
// components
import { Button } from '../Button';
import HighlightElementWrapper from 'system/wrappers/HighlightElementWrapper';
// models
import { typeHighlightedElementState } from 'models/routeLocationState';
// hooks
import { useAuth } from 'authentication';
import { PLATFORM_GROUP } from '../../models/enums';

const HeaderList = ({ title, onClick, buttonLabel, amountLabel, amount }: HeaderListProps) => {
  const { id } = useParams();
  const { identity } = useAuth();
  const intl = useIntl();
  const { state } = useLocation();

  return (
    <div className="py-5 sm:px-6 flex items-center justify-between">
      <div className="flex flex-col space-y-1 sm:space-y-2">
        <h2 className="flex-1 min-w-0 text-base sm:text-lg lg:text-2xl leading-6 font-semibold text-specialGray-1">{intl.formatMessage(title)}</h2>

        <p className="text-specialGray-075 text-sm">
          <span>{intl.formatMessage(amountLabel, { amount })}</span>
        </p>
      </div>

      {!id && identity?.platformGroup !== PLATFORM_GROUP.GUEST ? (
        <HighlightElementWrapper enableFlickering={(state as typeHighlightedElementState)?.flickeringModalOpenButton} addPadding="outline-offset-4">
          <Button onClick={onClick} buttonType="secondary" prefixIcon={PlusIcon} label={buttonLabel} />
        </HighlightElementWrapper>
      ) : null}
    </div>
  );
};

export default HeaderList;
