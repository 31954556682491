import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ProfileHireBasicInfoSkeleton: React.FC = () => {
  return (
    <div className="flex w-full">
      <Skeleton width={80} height={80} borderRadius="0.375rem" />
      <div className="flex flex-col w-full ml-5">
        <div className="flex items-center w-full space-x-4">
          <Skeleton width={240} height={34} />
          <Skeleton width={46} height={46} borderRadius="0.5rem" />
        </div>
        <div className="flex flex-col mt-5">
          <Skeleton width={100} />
          <Skeleton width={400} />
          <Skeleton width={400} />
        </div>
        <div className="flex mt-4 space-x-6">
          <Skeleton width={90} />
          <Skeleton width={90} />
        </div>
      </div>
    </div>
  );
};

export default ProfileHireBasicInfoSkeleton;
