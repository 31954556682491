import { useMemo } from 'react';
import { isEmpty } from 'lodash';
//generated
import { CertificateFragmentsCertificateInfo$data } from 'schemas/certificates/__generated__/CertificateFragmentsCertificateInfo.graphql';
//helpers
import { sortingByAlphabeticallyCertificates } from 'formHelpers/utils';

const useSortedCertificates = (profileCertificates: CertificateFragmentsCertificateInfo$data | null) => {
  const stcwKind = 'STCW';
  const otherKind = 'Other';

  const collectedCertificatesByType = useMemo(
    () =>
      !isEmpty(profileCertificates) && profileCertificates
        ? profileCertificates.reduce((accumulator: { [key: string]: CertificateFragmentsCertificateInfo$data }, item) => {
            const certificateKind = item.certificate.kind?.name || otherKind;
            if (accumulator[certificateKind]) {
              accumulator[certificateKind] = [...accumulator[certificateKind], item].sort(sortingByAlphabeticallyCertificates);
            } else {
              accumulator[certificateKind] = [item];
            }
            return accumulator;
          }, {})
        : [],
    [profileCertificates],
  );

  // group sorted elements of all Certificates
  // example of result : [ ['STCW' , [certObj,certObj,certObj] , ['Other' , [[certObj,certObj,certObj]] ]
  return useMemo(
    () =>
      Object.entries(collectedCertificatesByType).sort((a, b) => {
        const kindA = a[0];
        const kindB = b[0];

        if (kindA === stcwKind || kindB === stcwKind) {
          return kindA === stcwKind ? -1 : kindB === stcwKind ? 1 : 0;
        }

        if (kindA === otherKind || kindB === otherKind) {
          return -1;
        }
        return kindA.localeCompare(kindB);
      }),
    [collectedCertificatesByType],
  );
};

export default useSortedCertificates;
