/**
 * @generated SignedSource<<5e3812d2e6e40d8c61f12b0064530cdd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type CrewPoolOrderField = 'CREATED_AT' | 'NAME' | '%future added value';
export type OrderDirection = 'ASC' | 'DESC' | '%future added value';
export type CrewPoolOrder = {
  direction: OrderDirection;
  field?: CrewPoolOrderField | null;
};
export type ProfileQueriesGetProfileCrewPoolsQuery$variables = {
  after?: any | null;
  before?: any | null;
  first?: number | null;
  last?: number | null;
  orderBy?: CrewPoolOrder | null;
  orgId: string;
};
export type ProfileQueriesGetProfileCrewPoolsQuery$data = {
  readonly crewPools: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly 'id': string;
        readonly 'name': string;
        readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsEmployerCrewPools'>;
      } | null;
    } | null> | null;
    readonly totalCount: number;
  } | null;
};
export type ProfileQueriesGetProfileCrewPoolsQuery = {
  response: ProfileQueriesGetProfileCrewPoolsQuery$data;
  variables: ProfileQueriesGetProfileCrewPoolsQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'after',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'before',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'first',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'last',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'orderBy',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'orgId',
    },
    v6 = {
      kind: 'Variable',
      name: 'orderBy',
      variableName: 'orderBy',
    },
    v7 = {
      kind: 'Variable',
      name: 'orgId',
      variableName: 'orgId',
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'totalCount',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: '__typename',
      storageKey: null,
    },
    v12 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cursor',
      storageKey: null,
    },
    v13 = {
      alias: null,
      args: null,
      concreteType: 'PageInfo',
      kind: 'LinkedField',
      name: 'pageInfo',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'endCursor',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'hasNextPage',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'hasPreviousPage',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'startCursor',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v14 = [
      {
        kind: 'Variable',
        name: 'after',
        variableName: 'after',
      },
      {
        kind: 'Variable',
        name: 'before',
        variableName: 'before',
      },
      {
        kind: 'Variable',
        name: 'first',
        variableName: 'first',
      },
      {
        kind: 'Variable',
        name: 'last',
        variableName: 'last',
      },
      v6 /*: any*/,
      v7 /*: any*/,
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/, v5 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileQueriesGetProfileCrewPoolsQuery',
      selections: [
        {
          alias: 'crewPools',
          args: [v6 /*: any*/, v7 /*: any*/],
          concreteType: 'CrewPoolConnection',
          kind: 'LinkedField',
          name: '__CrewPoolsList_crewPools_connection',
          plural: false,
          selections: [
            v8 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'CrewPoolEdge',
              kind: 'LinkedField',
              name: 'edges',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'CrewPool',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    v9 /*: any*/,
                    v10 /*: any*/,
                    {
                      args: null,
                      kind: 'FragmentSpread',
                      name: 'ProfileFragmentsEmployerCrewPools',
                    },
                    v11 /*: any*/,
                  ],
                  storageKey: null,
                },
                v12 /*: any*/,
              ],
              storageKey: null,
            },
            v13 /*: any*/,
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v5 /*: any*/, v0 /*: any*/, v2 /*: any*/, v1 /*: any*/, v3 /*: any*/, v4 /*: any*/],
      kind: 'Operation',
      name: 'ProfileQueriesGetProfileCrewPoolsQuery',
      selections: [
        {
          alias: null,
          args: v14 /*: any*/,
          concreteType: 'CrewPoolConnection',
          kind: 'LinkedField',
          name: 'crewPools',
          plural: false,
          selections: [
            v8 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'CrewPoolEdge',
              kind: 'LinkedField',
              name: 'edges',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'CrewPool',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    v9 /*: any*/,
                    v10 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'kind',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Organization',
                      kind: 'LinkedField',
                      name: 'Organization',
                      plural: false,
                      selections: [v9 /*: any*/],
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'CreatedAt',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'UpdatedAt',
                      storageKey: null,
                    },
                    v11 /*: any*/,
                  ],
                  storageKey: null,
                },
                v12 /*: any*/,
              ],
              storageKey: null,
            },
            v13 /*: any*/,
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: v14 /*: any*/,
          filters: ['orgId', 'orderBy'],
          handle: 'connection',
          key: 'CrewPoolsList_crewPools',
          kind: 'LinkedHandle',
          name: 'crewPools',
        },
      ],
    },
    params: {
      cacheID: 'fc13fa65592883be7a532259adfecfa1',
      id: null,
      metadata: {
        connection: [
          {
            count: null,
            cursor: null,
            direction: 'bidirectional',
            path: ['crewPools'],
          },
        ],
      },
      name: 'ProfileQueriesGetProfileCrewPoolsQuery',
      operationKind: 'query',
      text: 'query ProfileQueriesGetProfileCrewPoolsQuery(\n  $orgId: ID!\n  $after: Cursor\n  $first: Int\n  $before: Cursor\n  $last: Int\n  $orderBy: CrewPoolOrder\n) {\n  crewPools(orgId: $orgId, after: $after, first: $first, before: $before, last: $last, orderBy: $orderBy) {\n    totalCount\n    edges {\n      node {\n        id\n        name\n        ...ProfileFragmentsEmployerCrewPools\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n      hasPreviousPage\n      startCursor\n    }\n  }\n}\n\nfragment ProfileFragmentsEmployerCrewPools on CrewPool {\n  name\n  id\n  kind\n  Organization {\n    id\n  }\n  CreatedAt\n  UpdatedAt\n}\n',
    },
  };
})();

(node as any).hash = '9101fe1cc9721340b165932393a12aee';

export default node;
