/**
 * @generated SignedSource<<b6183304c1db346a82f08998392b4bd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ServiceFragmentsListPlural_profile$data = ReadonlyArray<{
  readonly 'end': String | null;
  readonly 'id': string;
  readonly 'start': String;
  readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragments'>;
  readonly ' $fragmentType': 'ServiceFragmentsListPlural_profile';
}>;
export type ServiceFragmentsListPlural_profile$key = ReadonlyArray<{
  readonly ' $data'?: ServiceFragmentsListPlural_profile$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragmentsListPlural_profile'>;
}>;

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: {
    plural: true,
  },
  name: 'ServiceFragmentsListPlural_profile',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'ServiceFragments',
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'start',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'end',
      storageKey: null,
    },
  ],
  type: 'SeaService',
  abstractKey: null,
};

(node as any).hash = 'b9d47af4c0fc5326b2901b6ae06609ea';

export default node;
