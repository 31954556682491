//packages
import React from 'react';
import { useIntl } from 'react-intl';
//models
import { HireCardInformationProps } from 'models/modelsOfComponents';

export const HireCardInformation: React.FC<HireCardInformationProps> = ({ isActive = false, handleSelect, cardData }) => {
  const intl = useIntl();
  const { id, title, SvgComponent } = cardData;
  return (
    <section
      data-test-id={`${id}${isActive ? '-ACTIVE' : ''}`}
      onClick={() => handleSelect(id)}
      className={`flex flex-col p-4 w-64 h-28 min-h-full ${isActive && 'bg-specialGray-005'} rounded-lg border-solid border relative cursor-pointer hover:bg-specialGray-005`}
    >
      <div>
        <SvgComponent isActive={isActive} />
      </div>
      <span className="mt-4 text-sm text-darkBlue">{intl.formatMessage({ id: title })}</span>
    </section>
  );
};

export default HireCardInformation;
