// packages
import { FC } from 'react';
import { useIntl } from 'react-intl';
// components
import CountryFlag from 'system/CountryFlag';
// models
import { CountryComponentProps } from 'models/modelsOfComponents';

const CountryComponent: FC<CountryComponentProps> = ({
  profileCountry,
  classNameTitle = 'uppercase font-semibold mb-3.5',
  classNameDescription,
  classNameContainer = 'justify-between',
  classNameCountryContainer = 'flex flex-row-reverse sm:flex-row gap-x-2',
}) => {
  const intl = useIntl();

  return (
    <div className={`flex w-full ${classNameContainer}`}>
      <p className={`text-specialGray-075 ${classNameTitle}`}>{intl.formatMessage({ id: 'profile_location' })}</p>
      <section className={classNameCountryContainer}>
        <div className={`flex flex-row-reverse sm:flex-row text-darkBlue ${classNameDescription}`}>
          <span>{profileCountry?.isoCode}</span>&#44;&nbsp;<span>{profileCountry?.name}</span>
        </div>
        <CountryFlag classes="-mt-1" countryId={profileCountry.isoCode} countryName={profileCountry.name} />
      </section>
    </div>
  );
};

export default CountryComponent;
