// packages
import { createElement, useRef } from 'react';
// models
import { PopoverHelperCommonPropsType } from 'system/PopoverHelper/types';
// hooks
import { useGetPositions } from 'hooks/useGetPositions';

const PopoverHelper = ({
  defaultText,
  symbol,
  element,
  classes = '',
  autoShow,
  position,
  positionArrow,
  darkTheme,
  children,
  widthContainer = 'w-300',
  symbolClasses = 'bg-specialGray-05 text-white',
}: PopoverHelperCommonPropsType) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const refWrapper = useRef<HTMLDivElement>(null);
  const { handleShowPopover, handleMouseOver, hoverElement } = useGetPositions(refContainer, refWrapper, autoShow);

  const Element = createElement('div', {
    ...element?.props,
    onMouseOver: handleMouseOver,
    onClick: handleShowPopover,
  });

  return (
    <div className={`relative inline-block ${classes}`} ref={refWrapper}>
      {hoverElement || autoShow ? (
        <div className={`absolute z-10 ${position}`} ref={refContainer}>
          <div className={`relative ${widthContainer} right-0`}>
            <div className={`absolute h-2 w-2 transform origin-top-left ${positionArrow} ${darkTheme ? 'bg-darkBlue' : 'border border-l-0 border-t-0 bg-white'}`} />
            <div className={`p-2 text-center rounded-md ${darkTheme ? 'bg-darkBlue text-white' : 'border bg-white text-specialGray-075'}`}>
              {defaultText && <>{defaultText}</>}
              {children && <>{children}</>}
            </div>
          </div>
        </div>
      ) : null}
      {symbol ? (
        <div className={`table-cell w-4 h-4 text-center rounded-full text-xs ${symbolClasses}`} onMouseOver={handleMouseOver} onClick={handleShowPopover}>
          {symbol}
        </div>
      ) : null}

      {element ? Element : null}
    </div>
  );
};

export default PopoverHelper;
