import { useIntl } from 'react-intl';
import { useCallback } from 'react';
//components
import MortarboardIcon from 'icons/MortarboardSmall';
import CrewsEducationEvItem from './components/CrewsEducationEVItem';
import OverviewCrewInfoEvTitle from '../components/OverviewCrewInfoEVTitle';
import OverviewCrewInfoEmptyItem from '../components/OverviewCrewInfoEmptyItem';
//hooks
import useSortedByEndDate from 'hooks/useSortedByEndDate';
//schemas
import { ProfileFragments$data } from 'schemas/profile/__generated__/ProfileFragments.graphql';

const CrewsEducationEmployerView = ({ profileEducations }: { profileEducations: ProfileFragments$data['educations'] }) => {
  const intl = useIntl();

  const getSortedField = useCallback((item: { end: String | null }) => item.end, []);
  const sortedEducationsByEndDate = useSortedByEndDate([...(profileEducations || [])], getSortedField);

  const isOnlyOneEducation = sortedEducationsByEndDate && sortedEducationsByEndDate?.length === 1;
  const isEducationsExist = sortedEducationsByEndDate && sortedEducationsByEndDate?.length > 0;

  return (
    <section className="flex w-full flex-col space-y-6">
      <OverviewCrewInfoEvTitle
        title={intl.formatMessage({ id: 'profile_educationTitle' })}
        description={intl.formatMessage({ id: 'profile_educationAmount' }, { amount: profileEducations?.length || 0 })}
      />

      {isEducationsExist ? (
        <div className="flex flex-col md:flex-row justify-between flex-wrap">
          {sortedEducationsByEndDate.map(item => {
            return <CrewsEducationEvItem additionalClasses={isOnlyOneEducation ? 'w-full' : 'lg:w-[49%] mb-6'} education={item} key={item.id} />;
          })}
        </div>
      ) : (
        <OverviewCrewInfoEmptyItem title={intl.formatMessage({ id: 'profile_no_education_added' })}>
          <MortarboardIcon />
        </OverviewCrewInfoEmptyItem>
      )}
    </section>
  );
};

export default CrewsEducationEmployerView;
