// packages
import { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
//components
import { PencilEditIcon } from 'icons/PencilEditIcon';
import CustomButton from 'system/Buttons/CustomButton';
// models
import { EmployerProfileBasicInfoProps } from 'models/modelsOfComponents';

const EmployerProfileBasicInfo = ({ profileOrganization, setShowModal }: PropsWithChildren<EmployerProfileBasicInfoProps>) => {
  const intl = useIntl();
  const handleOpenEditModal = () => {
    setShowModal(true);
  };
  return (
    <div className="w-auto">
      <div className="flex sm:flex-row sm:items-start items-start flex-col mb-2 sm:mb-3 w-full">
        {/* no name = no name header */}
        {profileOrganization && profileOrganization?.organization?.name ? (
          <h1 data-test-id="organization-name" className="inline-block sm:text-xl md:text-[32px] self-center sm:mr-4 font-bold text-darkBlue">
            {profileOrganization?.organization?.name}
          </h1>
        ) : (
          <h1 className="inline-block sm:text-xl md:text-[32px] sm:mr-4 font-bold self-center text-darkBlue">{intl.formatMessage({ id: 'employer_self_basic_no_name' })}</h1>
        )}
        {profileOrganization?.role !== 'member' && (
          <CustomButton
            onClick={handleOpenEditModal}
            label={profileOrganization ? null : { id: 'employer_self_basic_modalTitle' }}
            className={`px-3 h-10 items-center border-solid border border-specialGray-05 rounded-[10px] hover:bg-specialGray-002 text-specialGray-075 text-sm bg-white sm:flex hidden ${
              profileOrganization ? '' : '[&>svg]:mr-2'
            }`}
          >
            <PencilEditIcon />
          </CustomButton>
        )}
      </div>

      {profileOrganization?.organization.description && (
        <div className="flex mb-6 hidden sm:block">
          <p className="text-darkBlue font-semibold">{intl.formatMessage({ id: 'employer_info_about_us' })}</p>
          <p className="text-specialGray-075 break-words whitespace-pre-wrap">{profileOrganization?.organization?.description}</p>
        </div>
      )}
    </div>
  );
};

export default EmployerProfileBasicInfo;
