// packages
import { format } from 'date-fns';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
//components
import CountryFlag from 'system/CountryFlag';
//models
import { FilterSelectedItemProps } from '../../types';

export const FilterSelectedItem = ({ name, value, isoCode, onClickEvent, isHovered, className, isDate }: FilterSelectedItemProps) => {
  const itemTitle = value ? `${name} ${value}` : name;
  const uniSpace = '\u00a0';

  return (
    <div onClick={onClickEvent} className={`flex max-w-[198px] p-1 pr-3 text-blue rounded-xl cursor-pointer bg-blue-100 text-sm items-center h-8 ${className}`}>
      <div className="bg-blue-200 rounded-full h-fit">{isHovered ? <XMarkIcon className="w-5 h-5" /> : <CheckIcon className="w-5 h-5" />}</div>
      {isoCode ? (
        <div className="ml-2">
          <CountryFlag countryId={isoCode} countryName={itemTitle} /> <span className="ml-1">{itemTitle}</span>
        </div>
      ) : (
        <span className="ml-2 truncate">{isDate ? `${name}${uniSpace}${format(new Date(value as string), 'dd.MM.yyyy')}` : itemTitle}</span>
      )}
    </div>
  );
};
