import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useSearchUrlAnalytics = (firstRenderTitle: string, withFiltersTitle: string) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    //send once by render page
    window.analytics?.track(firstRenderTitle);
  }, [firstRenderTitle]);

  useEffect(() => {
    //send if search filters exist in url
    if (search) {
      window.analytics?.track(withFiltersTitle, {
        url: `${pathname}`,
        filters: `${search}`,
      });
    }
  }, [search, pathname, withFiltersTitle]);
};

export default useSearchUrlAnalytics;
