// packages
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
// components
import { Button } from 'system/Button';
// types
import { ProfileRatingLayoutButtonProps } from 'system/ProfileReting/types';

const ProfileRatingLayoutButton = ({ label, onClick }: ProfileRatingLayoutButtonProps) => (
  <Button
    additionalClasses=" flex text-sm text-left justify-between items-center"
    customIcon={<ArrowLongRightIcon className=" relative top-0.5 h-4 w-4 mb-2" />}
    label={label}
    buttonType="icon-blue-only"
    onClick={onClick}
    iconWrapperSize="w-12 h-7"
  />
);
export default ProfileRatingLayoutButton;
