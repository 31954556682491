// packages
import { useState, useCallback, useEffect } from 'react';
import { useLazyLoadQuery, useMutation } from 'react-relay';
import { useLocation, useNavigate } from 'react-router-dom';
// components
import { Modal } from 'system/Modal';
import NoShipIcon from 'icons/NoShipIcon';
import { ServiceItem } from './ServiceItem';
import ServiceNoteForm from './ServiceNoteForm';
import { SeeMoreItem } from 'system/SeeMoreItem';
import ErrorBoundaryForm from './ErrorBoundaryForm';
import EmptyList from 'system/ListComponents/EmptyList';
import HeaderList from 'system/ListComponents/HeaderList';
import { ConfirmModalBody } from 'system/ConfirmModalBody';
// generated
import { ServiceFragments$data, ServiceFragments$key } from 'schemas/services/__generated__/ServiceFragments.graphql';
import { ServiceMutationsListRemoveMutation } from 'schemas/services/__generated__/ServiceMutationsListRemoveMutation.graphql';
import { ProfileMutationsProfileProgressMutation } from 'schemas/profile/__generated__/ProfileMutationsProfileProgressMutation.graphql';
import { ServiceQueriesListOfServicesFromProfileQuery } from 'schemas/services/__generated__/ServiceQueriesListOfServicesFromProfileQuery.graphql';
// schemas
import { UPDATE_PROFILE_DOCUMENT } from 'schemas/profile/ProfileMutations';
import { LIST_OF_SEA_SERVICE_FROM_PROFILE } from 'schemas/services/ServiceQueries';
import { REMOVE_SERVICE_LIST_ITEM_MUTATION } from 'schemas/services/ServiceMutations';
// helpers
import { getConnectionId } from 'formHelpers/utils';
import { ServiceListCommitRemove } from 'formHelpers/updaters/updatersOfService';
// hooks
import useSortedByEndDate from 'hooks/useSortedByEndDate';
import { useGetProfileIdFromAuthOrParams } from 'hooks/useGetProfileIdFromAuthOrParams';
// models
import { ServiceListProps } from 'models/modelsOfComponents';

const ServiceList = ({ defaultShowModal = false, defaultShowNoteModal = false }: ServiceListProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { getProfileIdFromAuthOrParams } = useGetProfileIdFromAuthOrParams();

  useEffect(() => {
    window.analytics?.track('wui-open-service-list-tab');
  }, []);

  const { seaServices } = useLazyLoadQuery<ServiceQueriesListOfServicesFromProfileQuery>(LIST_OF_SEA_SERVICE_FROM_PROFILE, {
    ownerId: getProfileIdFromAuthOrParams,
    skip: !getProfileIdFromAuthOrParams,
  });

  const getSortedField = useCallback((item: { node: { end: String | null } | null } | null) => item?.node?.end ?? null, []);
  const sortedSeaServicesByEndDate = useSortedByEndDate([...(seaServices?.edges || [])], getSortedField);

  const seaServiceStoryID = getConnectionId('ServiceListFromProfile_seaServices');
  const appraisalStoryID = getConnectionId('AppraisalListFromProfile_appraisals');

  const [currentService, setCurrentService] = useState<ServiceFragments$data>();
  const [showSeeMoreModal, setShowSeeMoreModal] = useState(false);
  const [selected, setSelected] = useState<ServiceFragments$key | undefined>();
  const [showModal, setShowModal] = useState<boolean>(defaultShowModal);

  const [showNoteModal, setShowNoteModal] = useState<boolean>(defaultShowNoteModal);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [removingItemId, setRemovingItemId] = useState<string>('');

  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [commitRemoveListItem] = useMutation<ServiceMutationsListRemoveMutation>(REMOVE_SERVICE_LIST_ITEM_MUTATION);

  // Update progress profile if last item zero, backend will fix it in a while
  const [commitCreate] = useMutation<ProfileMutationsProfileProgressMutation>(UPDATE_PROFILE_DOCUMENT);

  function openUpdateModal(service: ServiceFragments$key) {
    setSelected(service);
    setShowModal(true);
  }
  function openCreateModal() {
    setSelected(undefined);
    setShowModal(true);
    setIsOpenCreateModal(true);
  }
  function closeModal() {
    setShowModal(false);
    setIsOpenCreateModal(false);
    navigate(pathname, {
      replace: true,
      state: null,
    });
  }

  const handleOpenNoteModal = useCallback((service: ServiceFragments$key) => {
    setSelected(service);
    setShowNoteModal(true);
  }, []);

  const handleCloseNoteModal = useCallback(() => {
    setShowNoteModal(false);
  }, []);

  function onFormSubmitted(reopenFormAgain: boolean) {
    closeModal();
    if (reopenFormAgain) {
      const HALF_SECOND = 500;
      setTimeout(() => openCreateModal(), HALF_SECOND);
    }
  }

  function closeConfirmModal() {
    setShowConfirmModal(false);
  }

  const onItemRemoveClick = useCallback((id: string) => {
    setShowConfirmModal(true);
    setRemovingItemId(id);
  }, []);

  const removeListItem = (id: string) => () => {
    setLoading(true);

    const onError = (error: Error) => {
      console.warn(error);
    };

    commitRemoveListItem({
      variables: { id },
      onCompleted: ({ deleteSeaService }) => {
        window.analytics?.track('wui-removed-sea-service', {
          removedSeaServiceId: deleteSeaService,
        });
        setLoading(false);
        closeConfirmModal();
        if (seaServices?.edges?.length === 1) {
          commitCreate({
            variables: {
              pid: getProfileIdFromAuthOrParams || '',
              data: { addedFirstSeaService: false },
            },
            onError,
          });
        }
      },
      updater: ServiceListCommitRemove(`${seaServiceStoryID}(ownerId:"${getProfileIdFromAuthOrParams}")`, `${appraisalStoryID}(ownerId:"${getProfileIdFromAuthOrParams}")`),
    });
  };

  const closeSeeMoreModal = useCallback(() => {
    setShowSeeMoreModal(false);
  }, []);

  const openSeeMoreModal = useCallback((service: ServiceFragments$data) => {
    setCurrentService(service);
    setShowSeeMoreModal(true);
  }, []);

  return (
    <section aria-labelledby="sea-service-title">
      <Modal onClose={closeModal} show={showModal} title={{ id: selected ? 'self_seaService_edit_modalTitle' : 'self_seaService_add_modalTitle' }}>
        <ErrorBoundaryForm onSubmitted={onFormSubmitted} service={selected} isOpenCreateModal={isOpenCreateModal} />
      </Modal>
      <Modal title={{ id: 'service_form_note_title' }} description={{ id: 'service_form_note_description' }} onClose={handleCloseNoteModal} show={showNoteModal}>
        <ServiceNoteForm onCloseModal={handleCloseNoteModal} selectedService={selected} />
      </Modal>

      <Modal onClose={closeConfirmModal} show={showConfirmModal} tinyModal={true}>
        <ConfirmModalBody
          title={{ id: 'confirm_remove_item_title' }}
          textOne={{ id: 'confirm_remove_item_text_1' }}
          textTwo={{ id: 'confirm_remove_item_text_2' }}
          onClick={removeListItem(removingItemId)}
          onCancel={closeConfirmModal}
          buttonLabel={{ id: 'delete' }}
          typeButtonConfirm="red-primary"
        />
      </Modal>

      <Modal onClose={closeSeeMoreModal} show={showSeeMoreModal} tinyModal childrenClass="mt-0 mb-2.5">
        <SeeMoreItem currentService={currentService} />
      </Modal>

      <div className="rounded-lg">
        <HeaderList
          title={{ id: 'profile_seaServiceTitle' }}
          onClick={openCreateModal}
          buttonLabel={{ id: 'profile_addSeaServiceCta' }}
          amountLabel={{ id: 'profile_seaServiceAmount' }}
          amount={seaServices?.edges?.length}
        />

        <div className="flex flex-col">
          <div className="align-middle inline-block w-full">
            {sortedSeaServicesByEndDate ? (
              <div className="sm:rounded-lg">
                <div className="min-w-full">
                  {sortedSeaServicesByEndDate.map(edge => {
                    return (
                      edge?.node && (
                        <ServiceItem
                          key={edge?.node?.id}
                          service={edge?.node}
                          disabled={loading}
                          openUpdateModal={openUpdateModal}
                          removeFunction={onItemRemoveClick}
                          onClickItem={openSeeMoreModal}
                          onOpenNoteModal={handleOpenNoteModal}
                        />
                      )
                    );
                  })}
                </div>
              </div>
            ) : (
              <EmptyList
                navigationTitle={{ id: 'profile_addSeaServiceCta' }}
                onClick={openCreateModal}
                title={{ id: 'profile_noSeaService' }}
                icon={<NoShipIcon className="mx-auto" />}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceList;
