import { PropsWithChildren, useEffect, useState } from 'react';

const DropDownSliderWrapper = ({
  children,
  isShowed,
}: PropsWithChildren<{
  isShowed: boolean;
}>) => {
  const [showDropDown, setShowDropDown] = useState<boolean | null>(null);

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!isShowed) {
      //delete children from the DOM with delay - for close animation
      id = setTimeout(() => setShowDropDown(false), 400);
    }
    if (isShowed) {
      setShowDropDown(true);
    }
    return () => {
      clearTimeout(id);
    };
  }, [isShowed]);

  return (
    <div className="relative empty:hidden">
      <div
        children={showDropDown && children}
        className={`w-full absolute flex flex-col z-10 transition-all duration-300 truncate bg-white top-1.5 left-0 rounded-lg ${isShowed ? 'max-h-[1000px]' : 'max-h-0'}`}
      />
    </div>
  );
};

export default DropDownSliderWrapper;
