import { useIntl } from 'react-intl';
import { useLazyLoadQuery } from 'react-relay';
import { Link, useNavigate } from 'react-router-dom';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
//generated
import { ProfileFragmentsEmployerCrewPools$data } from 'schemas/profile/__generated__/ProfileFragmentsEmployerCrewPools.graphql';
import { ProfileQueriesGetProfileCrewPoolProfilesQuery } from 'schemas/profile/__generated__/ProfileQueriesGetProfileCrewPoolProfilesQuery.graphql';
//schemas
import { GET_CREW_POOL_PROFILES } from 'schemas/profile/ProfileQueries';
//routing
import { RoutePaths, RoutesPathNames } from 'app/routing';
//types
import { ProfileSeaService, SearchProfileNode } from 'models/IProfiles';
//components
import { Modal } from 'system/Modal';
import DoneIcon from 'icons/DoneIcon';
import InviteIcon from 'icons/InviteIcon';
import SpinnerIcon from 'icons/SpinnerIcon';
import DeleteStrictIcon from 'icons/DeleteStrictIcon';
import ProfileListItem from 'app/ProfileSearch/components/ProfileListItem/ProfileListItem';
import ListOfVacancies from 'app/ProfileSearch/components/ProfileListItem/components/ListOfVacancies/ListOfVacancies';
import AvailableForWorkIconWithPopover from 'app/ProfileSearch/components/ProfileListItem/components/AvailableForWorkIconWithPopover';
//hooks
import useInvitedProfilesToVacancies from 'hooks/hireProfileHooks/useInvitedProfilesToVacancies';

const EmployerCrewPoolInfoItem = ({ poolInfo }: { poolInfo: ProfileFragmentsEmployerCrewPools$data | null }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const allInvitedUsersIds = useInvitedProfilesToVacancies();

  const [showListOfVacancies, setShowListOfVacancies] = useState<string>();

  const profilesPoolData = useLazyLoadQuery<ProfileQueriesGetProfileCrewPoolProfilesQuery>(GET_CREW_POOL_PROFILES, { poolId: poolInfo?.id! });
  const profilesPoolDataTotal = profilesPoolData.crewPoolProfiles?.totalCount;
  const profilesPoolDataEdges = profilesPoolData.crewPoolProfiles?.edges;

  const handleOpenModalWithListOfVacancies = useCallback(
    (profileId?: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setShowListOfVacancies(profileId);
    },
    [],
  );

  const handleClickByProfile = useCallback(
    (profileId: string) => () => {
      window.analytics?.track('wui-open-profile-details-page-from-pool', {
        openedProfileId: profileId,
      });
      navigate(`${RoutePaths.ProfileSingleCrewPageEmployerView}/${profileId}`);
    },
    [navigate],
  );

  const renderInviteButton = useCallback(
    (profileId?: string, isAvailableForWorkProfile?: boolean) => {
      const isInvitedProfileEvenForOneOfVacancies = profileId && allInvitedUsersIds.includes(profileId);

      return (
        <div className="flex items-center gap-x-3 text-sm">
          <AvailableForWorkIconWithPopover isAvailable={!!isAvailableForWorkProfile} />

          <Link
            to={`${RoutesPathNames.deleteUser}/${profileId}`}
            onClick={e => e.stopPropagation()}
            className="flex w-12 h-10 md:mt-0 border border-grayIcon items-center justify-center rounded-[10px] text-specialGray-05 hover:text-blue self-center"
          >
            <DeleteStrictIcon className="w-6 h-6" />
          </Link>

          <button
            className={`flex justify-center items-center gap-x-2 border border-grayIcon font-medium ${
              isInvitedProfileEvenForOneOfVacancies ? 'border-green-500 text-green-500 hover:bg-green-50' : 'hover:bg-gray-50 border-gray-300 text-specialGray-075'
            } border-solid bg-white shadow-sm h-10 rounded-[10px] pl-3.5 pr-5  w-fit`}
            onClick={handleOpenModalWithListOfVacancies(profileId)}
          >
            {isInvitedProfileEvenForOneOfVacancies ? <DoneIcon /> : <InviteIcon />}

            {intl.formatMessage({ id: isInvitedProfileEvenForOneOfVacancies ? 'review_applications_contacted_label' : 'review_applications_contact_label' })}
          </button>
        </div>
      );
    },
    [handleOpenModalWithListOfVacancies, intl, allInvitedUsersIds],
  );

  const prepareDataForProfileList = useMemo(() => {
    if (!profilesPoolDataEdges) return [];

    const comparedData: SearchProfileNode[] = [];

    profilesPoolDataEdges.forEach(item => {
      const node = item?.node;
      if (!node) {
        return;
      }

      const { info, certificateInfos, seaServices, id, generalSetting } = node;
      const profileActivities: string[] = [];

      const seaServicesDuration: ProfileSeaService[] = [];
      seaServices?.forEach(seaServiceItem => {
        seaServicesDuration.push({ end: seaServiceItem.end, start: seaServiceItem.start });

        if (seaServiceItem.activities) {
          seaServiceItem.activities.forEach(activityItem => profileActivities.push(activityItem.id));
        }
      });

      comparedData.push({
        objectID: id,
        firstName: info?.firstName || '',
        lastName: info?.lastName || '',
        primaryDuty: info?.primaryDuty?.id || null,
        seaServiceDuties: seaServices ? seaServices.map(item => item.duty.id) : null,
        birthDate: info?.birthDate ? new Date(`${info.birthDate}`).getTime() / 1000 : 0,
        certificates: certificateInfos ? certificateInfos.map(cert => cert.certificate.id) : null,
        country: info?.country ? info.country.isoCode : '',
        seaServiceActivities: profileActivities.length ? profileActivities : null,
        numSeaServices: seaServices ? seaServices.length : 0,
        avatarUrl: info?.avatarUrl || '',
        availableForWork: generalSetting === null || generalSetting.availableForWork,
        seaServicesDuration: seaServicesDuration,
        poolName: poolInfo?.name,
      });
    });

    return comparedData;
  }, [poolInfo?.name, profilesPoolDataEdges]);

  return (
    <div className="flex flex-col w-full">
      <span data-test-id="crew-pool-added-counter" className="lowercase mb-6 mt-2 text-specialGray-075 text-sm">
        {intl.formatMessage({ id: 'hire_crew_added' }, { value: profilesPoolDataTotal || 0 })}
      </span>

      <Modal
        description={{ id: 'search_modal_description' }}
        additionalClasses="md:w-8/12 lg:max-w-[720px] h-[500px]"
        title={{ id: 'search_modal_title' }}
        onClose={() => setShowListOfVacancies('')}
        show={!!showListOfVacancies}
      >
        <Suspense fallback={<SpinnerIcon />}>
          <ListOfVacancies crewProfileId={showListOfVacancies!} />
        </Suspense>
      </Modal>

      {prepareDataForProfileList?.length ? (
        prepareDataForProfileList.map(profile => {
          return <ProfileListItem key={profile?.objectID} onClick={handleClickByProfile(profile.objectID)} profile={profile} inviteButton={renderInviteButton} />;
        })
      ) : (
        <div className="grid w-full col-span-3 p-10 border bg-specialGray-004 rounded-2xl text-specialGray-075 justify-center mt-6">
          <span>{intl.formatMessage({ id: 'hire_crew_pool_is_empty' })}</span>
        </div>
      )}
    </div>
  );
};

export default EmployerCrewPoolInfoItem;
