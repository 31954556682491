// packages
import React from 'react';
import { format } from 'date-fns';
import { useFragment } from 'react-relay';
import { useIntl } from 'react-intl';
// components
import getEducationIcon from 'icons/EducationIcons';
// generated
import { EducationFragments$key } from 'schemas/educations/__generated__/EducationFragments.graphql';
// schemas
import { EDUCATION_FRAGMENT } from 'schemas/educations/EducationFragments';
// models
import { HighlightedEducationItemProps } from 'models/modelsOfComponents';

const HighlightedEducationItem: React.FC<HighlightedEducationItemProps> = ({ education, full }) => {
  const intl = useIntl();
  const dataOfEducation = useFragment<EducationFragments$key>(EDUCATION_FRAGMENT, education || null);

  return (
    <div key={dataOfEducation?.id} className={`flex flex-row mb-5 rounded-lg px-3 py-3 border bg-white w-100% ${full ? 'w-full' : 'md:w-48%'}`}>
      <section className="flex items-center w-full">
        <div className="h-11 w-11">{dataOfEducation?.kind?.name && getEducationIcon(dataOfEducation?.kind?.perma, true)}</div>
        <section className="ml-4 sm:mr-4 mr-auto w-4/6">
          <span className="text-sm text-specialGray-075">{dataOfEducation?.kind?.name}</span>
          <p className="overflow-hidden truncate text-specialGray-1 text-sm font-medium whitespace-pre-wrap">{dataOfEducation?.name}</p>
        </section>

        <section className="text-specialGray-075 text-sm sm:w-30 w-2/5 text-right">
          {dataOfEducation && dataOfEducation.start && <span>{format(new Date(`${dataOfEducation.start}`), 'dd.MM.yyyy')}</span>}
          <span>-</span>

          {dataOfEducation && dataOfEducation.end ? (
            <span>{format(new Date(`${dataOfEducation.end}`), 'dd.MM.yyyy')}</span>
          ) : (
            <span className="ml-1">{intl.formatMessage({ id: 'service_present' })}</span>
          )}
        </section>
      </section>
    </div>
  );
};

export default HighlightedEducationItem;
