// packages
import { Auth } from 'aws-amplify';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { FormikProvider, useFormik, Form } from 'formik';
// components
import { Button } from 'system/Button';
import SpinnerIcon from '../icons/SpinnerIcon';
import AlertForError from 'system/Alert/AlertForError';
import { InputGroup } from 'system/InputGroups/InputGroup';
import { PinCodev2 } from 'system/PinCodeComponent/PinCodev2';
// formHelpers
import { ForgotConfirmFormValidateSchema } from 'formHelpers/validationsOfForms';
import { ForgotConfirmFormInitialValues } from 'formHelpers/initialValuesOfForms';
// hooks
import { useAuth } from 'authentication';
//models
import { ForgotConfirmData, ForgotConfirmProps } from 'models/modelsOfComponents';
//routing
import { RoutePaths } from '../app/routing';

// ForgotConfirmForm implements confirming a new password after a 'forgot password' reset
export const ForgotConfirmForm: React.FC<ForgotConfirmProps> = ({ emailByDefault, onPasswordReset, defaultSubmitting = false, defaultError, userType = 'crew' }) => {
  const [error, setError] = useState<Error | undefined>(defaultError);
  const [loadingResendCode, setLoadingResendCode] = useState(false);
  const [showRedirectMessage, setShowRedirectMessage] = useState(false);

  const intl = useIntl();
  const { email, setEmail } = useAuth();

  useEffect(() => {
    if (loadingResendCode && !showRedirectMessage) {
      setShowRedirectMessage(true);
    }
  }, [loadingResendCode, showRedirectMessage]);

  useEffect(() => {
    if (setEmail && emailByDefault) {
      setEmail(emailByDefault);
    }
  }, [emailByDefault, setEmail]);

  const formik = useFormik({
    onSubmit,
    initialValues: ForgotConfirmFormInitialValues(email || emailByDefault),
    validationSchema: ForgotConfirmFormValidateSchema,
  });

  useEffect(() => {
    if (defaultSubmitting && !formik.isSubmitting) formik.setSubmitting(true);
  });

  async function onSubmit(d: ForgotConfirmData) {
    let err: Error | undefined;
    try {
      await Auth.forgotPasswordSubmit(d.email, d.code, d.newPassword);
      if (d.email && d.newPassword) {
        await Auth.signIn(d.email, d.newPassword);
        await Auth.currentAuthenticatedUser();
      }
      err = undefined;
    } catch (e: unknown) {
      err = e as Error;
    }
    setError(err);
    formik.setSubmitting(false);
    onPasswordReset && !err && onPasswordReset(d);
  }
  const handleResendCode = async () => {
    if (formik.errors.email) {
      return;
    }
    try {
      setLoadingResendCode(true);
      await Auth.forgotPassword(formik.values.email);
      setError(undefined);
    } catch (e: unknown) {
      setError(e as Error);
    }
    setLoadingResendCode(false);
  };
  const handleNavigateConfStep = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    formik.validateField('email');
    if (formik.errors.email) {
      e.preventDefault();
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form className="space-y-6 text-xs sm:text-sm">
        {error && <AlertForError heading={{ id: 'forgot_confirm_failed' }} error={error} />}

        <p className="text-sm text-darkBlue">{intl.formatMessage({ id: 'forgot_info_message' })}</p>

        {!email && <InputGroup autoFocus type="email" name="email" label={{ id: 'default_auth_emailLabel' }} />}

        <PinCodev2 name="code" maxLength={6} label={{ id: 'default_auth_confirmCodeLabel' }} inputType="string" skipQuery />

        <InputGroup type="password" name="newPassword" description={{ id: 'default_auth_setPasswordDescription' }} label={{ id: 'default_auth_newPasswordLabel' }} />

        <button
          type="button"
          disabled={!formik.values.email || loadingResendCode}
          className="flex text-xs sm:text-sm gap-x-2 items-center text-blue disabled:text-specialGray-075"
          onClick={handleResendCode}
        >
          {intl.formatMessage({ id: 'confirm_resend_another_code_description' })}
          {loadingResendCode && <SpinnerIcon additionalClasses="w-3.5 h-3.5" />}
        </button>
        {showRedirectMessage && (
          <div className="flex-inline bg-blue-50 p-2 text-specialGray-075 rounded-md">
            {intl.formatMessage({ id: 'forgot_confirm_not_completed_account_registration' })}&#58;&nbsp;
            <Link
              state={{ forgotPasswordAccountType: userType, email: formik.values.email }}
              onClick={handleNavigateConfStep}
              className="underline text-blue"
              to={userType === 'crew' ? RoutePaths.AuthRegisterStepTree : RoutePaths.HireAuthRegisterStepTree}
            >
              {intl.formatMessage({ id: 'click_here' })}
            </Link>
          </div>
        )}
        <Button type="submit" label={{ id: 'forgot_confirm_confirmCta' }} fullSize disabled={formik.isSubmitting} />
      </Form>
    </FormikProvider>
  );
};
