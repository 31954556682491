import { useFragment } from 'react-relay';
import { VACANCY_DESCRIPTION_TYPE_OF_LIST } from 'models/enums';
import RightSideCommonItemListOfCrew from './RightSideCommonItemListOfCrew';
import { APPLICATION_FRAGMENT } from 'schemas/Application/ApplicationFragments';
import { ApplicationFragments$key } from 'schemas/Application/__generated__/ApplicationFragments.graphql';

const RightSideApplicationItemListOfCrew = ({ applicationFragment }: { applicationFragment: ApplicationFragments$key }) => {
  const application = useFragment<ApplicationFragments$key>(APPLICATION_FRAGMENT, applicationFragment);
  return <RightSideCommonItemListOfCrew vacancyCreateAt={`${application.createdAt}`} profileFragment={application.owner} typeOfList={VACANCY_DESCRIPTION_TYPE_OF_LIST.APPLIED} />;
};

export default RightSideApplicationItemListOfCrew;
