// packages
import { DefaultValue, GetRecoilValue, selector, SetRecoilState } from 'recoil';
// types
import { personalAccountType } from '../../types';
// atoms
import { personalAccountAtom } from '../atoms/personalAccountAtom';

export const personalAccountSelector = selector({
  key: 'personalAccountSelector',
  get: ({ get }: { get: GetRecoilValue }): personalAccountType => {
    const getIsPersonalAccount: personalAccountType = get(personalAccountAtom);
    const currentOrganisationFromStorage: personalAccountType = JSON.parse(localStorage.getItem('personalAccount') as string);

    return currentOrganisationFromStorage ?? getIsPersonalAccount;
  },
  set: ({ set }: { set: SetRecoilState }, newValue) => {
    set(personalAccountAtom, newValue as DefaultValue);
    if (!process.env.STORYBOOK_IS_STORYBOOK) {
      localStorage.setItem('personalAccount', JSON.stringify(newValue));
    }
  },
});
