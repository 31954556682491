import { FC } from 'react';

const EmployerLogo: FC<{ isBigScreenLogo?: boolean }> = ({ isBigScreenLogo = false }) => {
  return (
    <svg className={`${isBigScreenLogo ? 'h-16' : 'h-11'}`} viewBox="0 0 106 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4511_33550)">
        <path
          d="M40.9713 13.9258C38.1594 13.9272 35.4282 14.8658 33.2095 16.5932C30.9908 18.3206 29.4111 20.7383 28.7203 23.464C28.5705 24.0187 28.4608 24.5835 28.3922 25.154C28.3353 25.6295 28.3061 26.1079 28.3047 26.5869C28.3047 26.6197 28.3047 26.647 28.3047 26.6799H37.8648C37.8648 26.647 37.8648 26.6197 37.8648 26.5869C37.8652 26.0584 37.9999 25.5387 38.2563 25.0767C38.5127 24.6146 38.8823 24.2253 39.3305 23.9453C39.8213 23.6337 40.39 23.4669 40.9713 23.464H53.0581C53.1018 23.2562 53.1456 23.0429 53.1948 22.8405C54.0517 19.2937 56.0977 16.1475 58.9921 13.9258H40.9713Z"
          fill="#48FEBA"
        />
        <path
          d="M53.2172 -0.00195051C52.086 -0.000869037 50.9805 0.335565 50.0404 0.964825C49.1004 1.59408 48.368 2.48792 47.9359 3.53334C47.5037 4.57876 47.3912 5.72884 47.6125 6.83819C47.8339 7.94755 48.3792 8.96637 49.1795 9.76589C49.9797 10.5654 50.9991 11.1097 52.1086 11.33C53.2182 11.5503 54.3682 11.4367 55.4132 11.0035C56.4582 10.5704 57.3513 9.83714 57.9797 8.89649C58.6081 7.95584 58.9434 6.85001 58.9434 5.71879C58.9434 4.96707 58.7953 4.22272 58.5074 3.52829C58.2196 2.83386 57.7977 2.20297 57.2659 1.67168C56.7341 1.14039 56.1028 0.719116 55.4081 0.431944C54.7134 0.144772 53.9689 -0.00266917 53.2172 -0.00195051V-0.00195051Z"
          fill="#48FEBA"
        />
        <path
          d="M25.3953 -0.00194267C24.2644 0.000219835 23.1594 0.337572 22.2201 0.967488C21.2807 1.5974 20.5492 2.49161 20.1179 3.53713C19.6866 4.58265 19.5748 5.73255 19.7968 6.84154C20.0188 7.95054 20.5645 8.96885 21.365 9.76782C22.1655 10.5668 23.1848 11.1105 24.2942 11.3304C25.4037 11.5502 26.5533 11.4363 27.598 11.003C28.6427 10.5697 29.5355 9.83646 30.1637 8.89592C30.7918 7.95539 31.127 6.84978 31.127 5.71879C31.127 4.96661 30.9787 4.22182 30.6905 3.52703C30.4023 2.83225 29.98 2.20111 29.4476 1.66975C28.9152 1.13839 28.2833 0.717229 27.5879 0.430379C26.8926 0.143529 26.1475 -0.00338087 25.3953 -0.00194267V-0.00194267Z"
          fill="#FFEA29"
        />
        <path
          d="M93.328 13.9395H68.755C68.2229 13.941 67.6914 13.9757 67.1635 14.0434C64.6414 14.3611 62.2729 15.4294 60.3654 17.1097C58.4578 18.7901 57.0992 21.0048 56.4659 23.4667C56.3289 24.0155 56.2284 24.5728 56.165 25.1348C56.1128 25.5997 56.0854 26.0671 56.083 26.5349C56.083 26.5787 56.083 26.6279 56.083 26.6716H65.654C65.654 26.6279 65.654 26.5787 65.654 26.5349C65.6569 25.7139 65.9847 24.9274 66.5658 24.3473C67.1469 23.7672 67.934 23.4408 68.755 23.4394H93.328C94.1489 23.4394 94.9363 23.7655 95.5168 24.346C96.0974 24.9266 96.4235 25.7139 96.4235 26.5349C96.4235 27.3559 96.0974 28.1433 95.5168 28.7238C94.9363 29.3043 94.1489 29.6305 93.328 29.6305H81.1536C80.415 33.3906 78.3498 36.7601 75.3344 39.1249H93.328C96.6678 39.1249 99.8708 37.7982 102.232 35.4366C104.594 33.075 105.921 29.872 105.921 26.5322C105.921 23.1924 104.594 19.9894 102.232 17.6278C99.8708 15.2662 96.6678 13.9395 93.328 13.9395V13.9395Z"
          fill="#2A6FF5"
        />
        <path
          d="M81.044 -0.00195312C79.9125 -0.00195312 78.8065 0.333562 77.8657 0.962165C76.9249 1.59077 76.1917 2.48422 75.7587 3.52955C75.3257 4.57488 75.2124 5.72513 75.4332 6.83484C75.6539 7.94456 76.1987 8.96389 76.9988 9.76395C77.7989 10.564 78.8182 11.1089 79.9279 11.3296C81.0376 11.5503 82.1879 11.437 83.2332 11.0041C84.2785 10.5711 85.172 9.83782 85.8006 8.89705C86.4292 7.95628 86.7647 6.85024 86.7647 5.71878C86.7647 4.20155 86.162 2.74646 85.0892 1.67361C84.0163 0.600766 82.5612 -0.00195312 81.044 -0.00195312V-0.00195312Z"
          fill="#2A6FF5"
        />
        <path
          d="M68.5689 26.6734C68.5384 27.0874 68.425 27.491 68.2353 27.8602V27.8602C67.969 28.3542 67.5745 28.7673 67.0933 29.056C66.6121 29.3447 66.0619 29.4984 65.5008 29.501H53.3155C53.3128 29.5447 53.3128 29.5885 53.3155 29.6323L53.2006 30.1354C52.3431 33.6864 50.2976 36.8376 47.4033 39.0665V39.0665H65.5336C65.9887 39.0641 66.4433 39.0367 66.8954 38.9845C69.4324 38.6973 71.8235 37.6501 73.7549 35.9802C75.6864 34.3103 77.0681 32.0957 77.7188 29.6268C77.7188 29.594 77.7189 29.5612 77.7462 29.5338C77.8764 29.0155 77.9732 28.4894 78.0361 27.9587C78.0387 27.9259 78.0387 27.893 78.0361 27.8602C78.0798 27.4719 78.1072 27.0727 78.1126 26.668L68.5689 26.6734Z"
          fill="#48FEBA"
        />
        <path
          d="M40.7475 26.6689C40.7159 27.0816 40.6005 27.4836 40.4084 27.8503V27.8503C40.1514 28.3286 39.7744 28.7319 39.3146 29.0207L39.2052 29.0808C38.7483 29.3607 38.2259 29.5154 37.6903 29.5293H13.0791C12.3058 29.5192 11.5642 29.22 11.0004 28.6907C10.4366 28.1613 10.0914 27.4401 10.0327 26.6689V26.6689C10.0327 26.6361 10.0327 26.6088 10.0327 26.5759C10.0327 25.7535 10.3595 24.9647 10.941 24.3832C11.5226 23.8016 12.3113 23.4749 13.1337 23.4749H25.2917C26.0431 19.6818 28.1434 16.2894 31.2038 13.9258H13.1556C9.80199 13.9258 6.58546 15.2569 4.21254 17.6267C1.83962 19.9966 0.504348 23.2114 0.5 26.565L0.5 26.658C0.5 26.7345 0.5 26.8056 0.5 26.8767C0.5 27.2049 0.532815 27.533 0.571099 27.8503C0.636488 28.416 0.738797 28.9769 0.877372 29.5293C1.5707 32.2434 3.14456 34.651 5.35251 36.375C7.56045 38.099 10.2778 39.0421 13.0791 39.0566H37.6903C38.0567 39.0566 38.4231 39.0566 38.7841 39.0073C38.9482 39.0073 39.1122 38.9636 39.2708 38.9472C41.7593 38.6218 44.0949 37.5643 45.9812 35.9089C47.8674 34.2534 49.2191 32.0748 49.8646 29.6496C49.8646 29.6059 49.8919 29.5621 49.9029 29.5183C49.9138 29.4746 50.1271 28.4956 50.1927 27.976C50.1927 27.9323 50.2803 27.0518 50.2857 26.6525L40.7475 26.6689Z"
          fill="#FFEA29"
        />
        <path d="M3.6337 56.495V59.1694H6.78394V60.3945H3.6337V63.1893H7.17772V64.4089H2.14062V55.2754H7.18319V56.495H3.6337Z" fill="#2A6FF5" />
        <path
          d="M24.1214 55.2871V64.4097H22.6283V58.1639L19.8445 64.4097H18.8054L16.0107 58.1639V64.4097H14.5176V55.2871H16.131L19.3304 62.4408L22.5244 55.2871H24.1214Z"
          fill="#2A6FF5"
        />
        <path
          d="M37.6407 59.3157C37.4012 59.7469 37.0371 60.0957 36.5961 60.3166C36.022 60.5984 35.3865 60.7319 34.7475 60.7049H33.1068V64.4075H31.5918V55.285H34.742C35.3544 55.2667 35.9627 55.3903 36.5195 55.6459C36.9735 55.856 37.3544 56.1969 37.6133 56.6249C37.8561 57.0432 37.9808 57.5195 37.9743 58.0031C37.9749 58.4617 37.8602 58.9131 37.6407 59.3157V59.3157ZM35.9999 59.097C36.1441 58.9553 36.2563 58.7843 36.3289 58.5957C36.4016 58.407 36.433 58.205 36.4211 58.0031C36.4211 57.0023 35.8741 56.5046 34.7256 56.5046H33.0849V59.4853H34.7256C35.1896 59.5245 35.6511 59.3857 36.0163 59.097H35.9999Z"
          fill="#2A6FF5"
        />
        <path d="M46.3974 63.201H49.482V64.4097H44.9043V55.2871H46.3974V63.201Z" fill="#2A6FF5" />
        <path
          d="M58.0466 63.9053C57.3448 63.52 56.7593 62.9533 56.3511 62.2646C55.9422 61.5285 55.7275 60.7002 55.7275 59.8582C55.7275 59.0161 55.9422 58.1878 56.3511 57.4517C56.7582 56.7621 57.344 56.1952 58.0466 55.811C58.7665 55.416 59.5744 55.209 60.3956 55.209C61.2167 55.209 62.0246 55.416 62.7446 55.811C63.4473 56.1931 64.0318 56.7606 64.4345 57.4517C64.8366 58.1901 65.0473 59.0174 65.0473 59.8582C65.0473 60.6989 64.8366 61.5262 64.4345 62.2646C64.03 62.9543 63.446 63.5214 62.7446 63.9053C62.0235 64.2969 61.2161 64.502 60.3956 64.502C59.5751 64.502 58.7676 64.2969 58.0466 63.9053V63.9053ZM62.0062 62.8115C62.4756 62.5296 62.8551 62.1198 63.1001 61.6302C63.3782 61.0777 63.5172 60.4656 63.5048 59.8472C63.5174 59.2323 63.3784 58.6237 63.1001 58.0752C62.8561 57.5891 62.476 57.1843 62.0062 56.9103C61.51 56.6413 60.9545 56.5004 60.3901 56.5004C59.8257 56.5004 59.2702 56.6413 58.774 56.9103C58.3059 57.1865 57.9264 57.5907 57.6801 58.0752C57.4068 58.6253 57.2699 59.2331 57.2809 59.8472C57.2694 60.4649 57.4063 61.0763 57.6801 61.6302C57.9273 62.1183 58.3062 62.5275 58.774 62.8115C59.2702 63.0805 59.8257 63.2214 60.3901 63.2214C60.9545 63.2214 61.51 63.0805 62.0062 62.8115Z"
          fill="#2A6FF5"
        />
        <path d="M78.7799 55.2871L75.7609 61.1008V64.3823H74.2623V61.1008L71.2324 55.2871H72.8732L74.9843 59.7609L77.0954 55.2871H78.7799Z" fill="#2A6FF5" />
        <path d="M86.9566 56.495V59.1694H90.1068V60.3945H86.9566V63.1893H90.5006V64.4089H85.458V55.2754H90.5006V56.495H86.9566Z" fill="#2A6FF5" />
        <path
          d="M102.576 64.4073L100.476 60.7594H99.3327V64.4073H97.8506V55.2848H100.995C101.61 55.2676 102.22 55.393 102.778 55.6512C103.232 55.8649 103.612 56.2073 103.872 56.6356C104.114 57.0542 104.239 57.5303 104.233 58.0139C104.241 58.6024 104.059 59.1779 103.714 59.6546C103.322 60.168 102.756 60.5202 102.122 60.6445L104.375 64.4237L102.576 64.4073ZM99.3327 59.5616H100.973C101.202 59.5794 101.432 59.5518 101.649 59.4804C101.867 59.4091 102.069 59.2954 102.242 59.146C102.387 58.9965 102.5 58.8189 102.573 58.6242C102.646 58.4294 102.679 58.2217 102.669 58.0139C102.677 57.8124 102.644 57.6114 102.572 57.4232C102.5 57.235 102.389 57.0638 102.248 56.92C101.89 56.6284 101.434 56.4855 100.973 56.5208H99.3327V59.5616Z"
          fill="#2A6FF5"
        />
      </g>
      <defs>
        <clipPath id="clip0_4511_33550">
          <rect width="105.5" height="64.5032" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmployerLogo;
