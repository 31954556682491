import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { useLazyLoadQuery, useMutation } from 'react-relay';
// schema
import { GET_ALL_HIRE_ORGANIZATION_MEMBERS } from 'schemas/profile/ProfileQueries';
import { DELETE_MEMBER_FROM_ORGANIZATION } from 'schemas/profile/ProfileMutations';
// generated
import { ProfileMutationsDeleteOrganizationMemberMutation } from 'schemas/profile/__generated__/ProfileMutationsDeleteOrganizationMemberMutation.graphql';
import { ProfileQueriesGetOrganizationMembersConnectionQuery } from 'schemas/profile/__generated__/ProfileQueriesGetOrganizationMembersConnectionQuery.graphql';
import { VacancyQueriesListOfVacanciesForOrgMembershipQuery } from 'schemas/vacancy/__generated__/VacancyQueriesListOfVacanciesForOrgMembershipQuery.graphql';
//components
import LineSvg from 'icons/LineSvg';
import { Modal } from 'system/Modal';
import { ConfirmModalBody } from 'system/ConfirmModalBody';
import EmployerOrganizationListItem from './EmployerOrganizationListItem';
import EmployerWarningDeleteMemberList from './EmployerWarningDeleteMemberList';
// updaters
import { deleteOrgMemberFromList } from 'formHelpers/updaters/updatersOfHireNewMembers';
//schemas
import { LIST_OF_VACANCIES_FOR_ORGANIZATION_MEMBERSHIP } from 'schemas/vacancy/VacancyQueries';
// types
import { ORDER_DIRECTION, VACANCY_ORDER_FIELD } from 'models/enums';
import { allMembersSortedByRoleProps, MemberListOfManagedVacanciesProps } from './../types';

const EmployerOrganizationList = () => {
  const intl = useIntl();
  const { orgId } = useParams();
  const { state } = useLocation();

  const [removedIdOfMember, setRemovedIdOfMember] = useState<string>(state?.defaultDeleteIdStorybook || '');

  const [showWarningMemberModal, setShowWarningMemberModal] = useState<boolean>(state?.defaultShowWarningModalStorybook || false);

  const [openDeleteModal, setOpenOpenDeleteModal] = useState<boolean>(state?.defaultShowDeleteModalStorybook || false);

  // This query we need it for warning modal if a deleted member is managed one of organization vacancies
  const organizationVacancies = useLazyLoadQuery<VacancyQueriesListOfVacanciesForOrgMembershipQuery>(LIST_OF_VACANCIES_FOR_ORGANIZATION_MEMBERSHIP, {
    orgId: orgId as string,
    skip: !orgId,
    orderBy: { field: VACANCY_ORDER_FIELD.STATUS, direction: ORDER_DIRECTION.ASC },
  }).vacancies;

  const allOrganizationMembers = useLazyLoadQuery<ProfileQueriesGetOrganizationMembersConnectionQuery>(GET_ALL_HIRE_ORGANIZATION_MEMBERS, {
    orgId: orgId!,
  }).organizationMembers?.edges;

  const allMembersSortedByRole = useMemo(() => {
    const allMembersByRole: allMembersSortedByRoleProps = {
      invited: [],
      member: [],
      owner: [],
      admin: [],
    };

    allOrganizationMembers?.forEach(member => {
      const memberRole = member?.node?.role;
      if (member?.node?.role && memberRole && allMembersByRole.hasOwnProperty(memberRole) && memberRole !== '%future added value') {
        allMembersByRole[memberRole].push({ fragmentNode: member.node, id: member.node.id });
      }
    });

    return allMembersByRole;
  }, [allOrganizationMembers]);

  const [deleteMember] = useMutation<ProfileMutationsDeleteOrganizationMemberMutation>(DELETE_MEMBER_FROM_ORGANIZATION);

  const membersManagedVacancies = useCallback(
    (id: string) => {
      const findVacanciesOfCurrentMember: MemberListOfManagedVacanciesProps[] = [];
      const listOfOpenedJobOpenings = organizationVacancies?.edges ?? [];

      listOfOpenedJobOpenings?.forEach(vacancy => {
        if (vacancy?.node?.organizationMember.id === id) {
          const profileInfo = vacancy?.node.organizationMember.profile.info;
          findVacanciesOfCurrentMember.push({
            title: `${vacancy?.node.title}`,
            status: vacancy?.node?.status,
            vacancyId: vacancy?.node.id,
            member: profileInfo?.firstName
              ? `${profileInfo?.firstName} ${profileInfo?.lastName}`
              : intl.formatMessage({ id: 'hire_organization_member_id' }, { value: vacancy?.node?.organizationMember?.id }),
          });
        }
      });
      return findVacanciesOfCurrentMember;
    },
    [intl, organizationVacancies?.edges],
  );

  const handleOpenDeleteMemberModal = (id?: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!id) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();

    const listOfOpenedJobOpenings = organizationVacancies?.edges ?? [];

    const isManagerExist = listOfOpenedJobOpenings.find(item => item?.node?.organizationMember?.id === id);

    if (isManagerExist) {
      setShowWarningMemberModal(!!isManagerExist);
    } else {
      setOpenOpenDeleteModal(!isManagerExist);
    }

    setRemovedIdOfMember(id);
  };

  const handleRemoveMember = () => {
    if (!removedIdOfMember) {
      return;
    }
    deleteMember({
      variables: {
        id: removedIdOfMember,
      },
      onCompleted: () => {
        setRemovedIdOfMember('');
        setOpenOpenDeleteModal(prev => !prev);
      },
      updater: deleteOrgMemberFromList(orgId!),
    });
  };
  const handleCloseModal = () => {
    setOpenOpenDeleteModal(prev => !prev);
  };
  const onHandleCloseMemberWarningModal = () => {
    setShowWarningMemberModal(false);
  };

  return (
    <section className="flex flex-col w-full gap-y-3 mt-3">
      <Modal onClose={handleCloseModal} show={openDeleteModal} tinyModal>
        <ConfirmModalBody
          title={{ id: 'hire_organization_delete_members_title' }}
          textOne={{ id: 'hire_organization_delete_members_description' }}
          onClick={handleRemoveMember}
          onCancel={handleCloseModal}
          buttonLabel={{ id: 'delete' }}
          typeButtonConfirm="red-primary"
        />
      </Modal>

      <Modal title={{ id: 'hire_organization_delete_members_warning_title' }} tinyModal onClose={onHandleCloseMemberWarningModal} show={showWarningMemberModal}>
        <EmployerWarningDeleteMemberList memberListOfManagedVacancies={membersManagedVacancies(removedIdOfMember)} />
      </Modal>

      {!!allMembersSortedByRole.invited.length &&
        allMembersSortedByRole.invited.map(item => (
          <EmployerOrganizationListItem key={item.id} handleDeleteMemberModal={handleOpenDeleteMemberModal} memberProfileFragment={item.fragmentNode} />
        ))}

      {!!allMembersSortedByRole.invited.length && <LineSvg classes="my-8" />}

      {!!allMembersSortedByRole.member.length &&
        allMembersSortedByRole.member.map(item => (
          <EmployerOrganizationListItem key={item.id} handleDeleteMemberModal={handleOpenDeleteMemberModal} memberProfileFragment={item.fragmentNode} />
        ))}

      {!!allMembersSortedByRole.owner.length &&
        allMembersSortedByRole.owner.map(item => (
          <EmployerOrganizationListItem key={item.id} handleDeleteMemberModal={handleOpenDeleteMemberModal} memberProfileFragment={item.fragmentNode} />
        ))}
    </section>
  );
};

export default EmployerOrganizationList;
