const MortarBoardSmallIcon = () => {
  return (
    <svg width="31" height="24" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.5308 10.0259L12.234 6.04008C12.0826 5.98664 11.9175 5.98664 11.7661 6.04008L0.469219 10.0259C0.188344 10.125 0.000375562 10.3903 5.61892e-07 10.6882C-0.000374438 10.986 0.186985 11.2517 0.467626 11.3515L11.7645 15.3676C11.8407 15.3947 11.9204 15.4082 12 15.4082C12.0797 15.4082 12.1593 15.3947 12.2355 15.3676L23.5324 11.3515C23.813 11.2517 24.0003 10.986 24 10.6882C23.9996 10.3903 23.8117 10.125 23.5308 10.0259Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <path
        d="M22.3113 17.7953V13.2754L20.905 13.7753V17.7953C20.4815 18.0391 20.196 18.4959 20.196 19.0198C20.196 19.5435 20.4815 20.0004 20.905 20.2442V21.7992C20.905 22.1875 21.2198 22.5023 21.6082 22.5023C21.9965 22.5023 22.3113 22.1875 22.3113 21.7992V20.2442C22.7348 20.0005 23.0203 19.5436 23.0203 19.0198C23.0203 18.496 22.7349 18.0391 22.3113 17.7953Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <path
        d="M12.0007 16.8171C11.7593 16.8171 11.5216 16.7761 11.2941 16.6952L4.94336 14.4375V17.5316C4.94336 18.2886 5.73306 18.9288 7.29053 19.4343C8.64808 19.8749 10.3209 20.1176 12.0007 20.1176C13.6805 20.1176 15.3532 19.8749 16.7108 19.4343C18.2683 18.9288 19.058 18.2886 19.058 17.5316V14.4375L12.7073 16.6952C12.4797 16.7761 12.242 16.8171 12.0007 16.8171Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <path
        d="M29.8388 4.69682H28.2848V3.1775C28.2848 2.52887 27.7646 2 27.1237 2C26.484 2 25.9625 2.52887 25.9625 3.1775V4.69682H24.4112C23.7702 4.69682 23.25 5.22569 23.25 5.87433C23.25 6.52296 23.7702 7.05183 24.4112 7.05183H25.9625V8.57249C25.9625 9.22113 26.484 9.75 27.1237 9.75C27.7646 9.75 28.2848 9.22113 28.2848 8.57249V7.05183H29.8388C30.4785 7.05183 31 6.52296 31 5.87433C31 5.22569 30.4785 4.69682 29.8388 4.69682Z"
        fill="#082051"
        fillOpacity="0.3"
      />
    </svg>
  );
};

export default MortarBoardSmallIcon;
