import graphql from 'babel-plugin-relay/macro';

export const CREATE_EDUCATION = graphql`
  mutation EducationMutationsFormCreateMutation($data: EducationData!, $connections: [ID!]!) {
    createEducation(data: $data) {
      node @appendNode(connections: $connections, edgeTypeName: "EducationEdge") {
        id
        ...EducationFragments
      }
      cursor
    }
  }
`;

export const UPDATE_EDUCATION = graphql`
  mutation EducationMutationsFormUpdateMutation($sid: ID!, $data: EducationData!) {
    updateEducation(id: $sid, data: $data) {
      ...EducationFragments
    }
  }
`;

export const REMOVE_EDUCATION_LIST_ITEM_MUTATION = graphql`
  mutation EducationMutationsListRemoveMutation($sid: ID!) {
    deleteEducation(id: $sid) {
      id
    }
  }
`;
