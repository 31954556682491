const BoatSmallIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.001 5.85976C10.001 5.38569 9.61531 5 9.14124 5H7.8583C7.38422 5 6.99854 5.38569 6.99854 5.85976V7.14515H10.001V5.85976Z" fill="#082051" fillOpacity="0.3" />
      <path
        d="M8.62577 10.3802L13.6094 11.6824V8.4687C13.6094 8.28887 13.4631 8.14258 13.2833 8.14258H3.7165C3.53667 8.14258 3.39038 8.28887 3.39038 8.4687V11.6824L8.37399 10.3802C8.4565 10.3587 8.54323 10.3587 8.62577 10.3802Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <path
        d="M16.5019 18.8621C15.9522 18.8621 15.7042 18.7386 15.3903 18.5822C15.2429 18.5089 15.0875 18.4315 14.902 18.3655C14.6445 18.274 14.3289 18.2046 13.8965 18.1985L15.7201 13.6081C15.7604 13.4991 15.7282 13.4083 15.7051 13.3637C15.682 13.3188 15.6261 13.2389 15.5121 13.2091L8.49995 11.377L1.48783 13.2091C1.37381 13.2389 1.31793 13.3188 1.29479 13.3637C1.27178 13.4084 1.23947 13.4992 1.27994 13.6082L3.09937 18.1986C2.66714 18.2051 2.35191 18.2749 2.09458 18.3668C1.91067 18.4324 1.75624 18.5093 1.60978 18.5823C1.29578 18.7386 1.04779 18.8621 0.498046 18.8621C0.222992 18.8621 0 19.0851 0 19.3601C0 19.6352 0.222992 19.8582 0.498046 19.8582C1.28204 19.8582 1.69203 19.6541 2.05374 19.474C2.19469 19.4038 2.32249 19.3404 2.47008 19.2916C2.65133 19.2318 2.86247 19.1942 3.16548 19.1942C3.23464 19.1942 3.29899 19.1961 3.35935 19.1999C3.77864 19.2258 4.00273 19.3373 4.27722 19.474C4.63893 19.6541 5.04892 19.8582 5.83291 19.8582C6.61687 19.8582 7.02683 19.6541 7.38858 19.474C7.70254 19.3177 7.95054 19.1942 8.50021 19.1942C9.04989 19.1942 9.29785 19.3176 9.61182 19.474C9.97353 19.6541 10.3835 19.8582 11.1674 19.8582C11.9514 19.8582 12.3614 19.6541 12.7231 19.474C12.9984 19.3369 13.2231 19.2251 13.6449 19.1996C13.7041 19.1961 13.7671 19.1942 13.8347 19.1942C14.1344 19.1942 14.3442 19.2311 14.5241 19.2898C14.6742 19.3388 14.8035 19.4029 14.9463 19.474C15.308 19.6541 15.718 19.8582 16.502 19.8582C16.777 19.8582 17 19.6352 17 19.3602C17 19.0851 16.7769 18.8621 16.5019 18.8621ZM3.89545 13.9258L8.37404 12.7555C8.45659 12.734 8.54331 12.734 8.62586 12.7555L13.1045 13.9258C13.3706 13.9953 13.5299 14.2674 13.4604 14.5335C13.4019 14.7575 13.2 14.9058 12.9789 14.9058C12.9372 14.9058 12.8949 14.9005 12.8527 14.8895L8.49995 13.7522L4.14726 14.8895C3.88114 14.959 3.60901 14.7997 3.53948 14.5335C3.46995 14.2674 3.62933 13.9953 3.89545 13.9258Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <path
        d="M21.7853 7.28817C21.6749 7.39516 21.6242 7.54988 21.6493 7.70162L22.0282 9.79873C22.0602 9.97647 21.9852 10.1563 21.8364 10.2591C21.6907 10.3656 21.4967 10.3784 21.3377 10.2932L19.4499 9.30855C19.3843 9.2736 19.3114 9.25485 19.2368 9.25272H19.1213C19.0812 9.25868 19.042 9.27147 19.0062 9.29108L17.1179 10.2804C17.0246 10.3273 16.9189 10.3439 16.8153 10.3273C16.563 10.2795 16.3946 10.0391 16.436 9.78552L16.8153 7.68841C16.8405 7.53539 16.7897 7.37981 16.6793 7.27112L15.1402 5.77928C15.0115 5.65439 14.9667 5.46684 15.0255 5.29762C15.0826 5.12883 15.2284 5.00565 15.4044 4.97794L17.5229 4.67062C17.684 4.654 17.8255 4.55596 17.898 4.41104L18.8314 2.49722C18.8536 2.4546 18.8822 2.41538 18.9167 2.38213L18.955 2.3523C18.9751 2.33013 18.9981 2.3118 19.0237 2.29689L19.0701 2.27984L19.1426 2.25H19.322C19.4823 2.26662 19.6234 2.36253 19.6971 2.50574L20.643 4.41104C20.7112 4.55042 20.8437 4.64718 20.9967 4.67062L23.1152 4.97794C23.2942 5.00352 23.4438 5.12713 23.503 5.29762C23.5589 5.46855 23.5107 5.65609 23.3794 5.77928L21.7853 7.28817Z"
        fill="#082051"
        fillOpacity="0.3"
      />
    </svg>
  );
};

export default BoatSmallIcon;
