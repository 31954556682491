// packages
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
import { useParams } from 'react-router-dom';
// models
import { seaServiceItemType } from 'system/ProfileSlide/types';
import { RESTRICTED_PROFILE_DATA_COUNTER } from 'models/enums';
import { ProfileSlideSeaServicesProps } from 'models/modelsOfComponents';
// components
import { Button } from 'system/Button';
import { useRecoilValue } from 'recoil';
import HiddenCrewDataPopover from 'system/ProfileSlide/components/HiddenCrewDataPopover';
import { useConvertToCorrectServiceDate } from 'hooks/useConvertToCorrectServiceDate';
import ProfileSlideServiceItem from 'system/ProfileSlide/components/ProfileSlideServiceItem';
// generated
import { ServiceFragmentsListPlural_profile$key } from 'schemas/services/__generated__/ServiceFragmentsListPlural_profile.graphql';
// schemas
import { SERVICES_LIST_FRAGMENT } from 'schemas/services/ServiceFragments';
import SeaServiceJobExperience from 'system/SeaServiceJobExpirience/SeaServiceJobExperience';
// hooks
import useRestrictedCrewProfileSeaServices from 'hooks/ProfileDataHooks/useRestrictedCrewProfileSeaServices';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';
// routing
import { RoutesPathNames } from 'app/routing';

const ProfileSlideServices = ({ seaServicesFragments }: ProfileSlideSeaServicesProps) => {
  const intl = useIntl();
  const params = useParams();
  const profileSideBarId = params[RoutesPathNames.profileSideBarId];

  const services = useFragment<ServiceFragmentsListPlural_profile$key>(SERVICES_LIST_FRAGMENT, seaServicesFragments || null);
  const [showAllSeaServices, setShowAllSeaServices] = useState<boolean>(false);
  const isSelectedOrganization = useRecoilValue(currentOrganisationSelector).isSelected;

  const { getLatestSeaServices } = useRestrictedCrewProfileSeaServices(profileSideBarId);

  const { convertDays, convertMonths, convertYears } = useConvertToCorrectServiceDate(services);
  const seaServicesCounter = seaServicesFragments?.length || 0;

  const showMoreSeaServicesCounter = seaServicesCounter - RESTRICTED_PROFILE_DATA_COUNTER.SEA_SERVICES;

  const handleShowOrganizationSeaServices = () => {
    const amountOfRendering = isSelectedOrganization && showAllSeaServices ? seaServicesCounter : RESTRICTED_PROFILE_DATA_COUNTER.SEA_SERVICES;
    return (service: seaServiceItemType, index: number) => index < amountOfRendering && <ProfileSlideServiceItem serviceFragment={service} key={service.id} />;
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex w-full mb-4 relative bottom-1">
        <p className="uppercase text-specialGray-075 font-semibold w-1/2 text-xs">{intl.formatMessage({ id: 'profile_sea_services' })}</p>
        <p className="text-specialGray-1 text-xs relative">
          {services?.length} {seaServicesCounter > 1 ? intl.formatMessage({ id: 'profile_sea_services' }) : intl.formatMessage({ id: 'profile_sea_service' })}
        </p>
        {!isEmpty(services) && (
          <SeaServiceJobExperience days={convertDays} months={convertMonths} years={convertYears} classes="text-specialGray-075 text-xs relative left-1 left-2.5" />
        )}
      </div>
      <div className="flex flex-col">
        {isSelectedOrganization
          ? // view for a hire account
            services?.map(handleShowOrganizationSeaServices())
          : // view for a crew account
            getLatestSeaServices?.map(service => (service?.node ? <ProfileSlideServiceItem serviceFragment={service.node} key={service.node.id} /> : null))}
      </div>
      {seaServicesCounter > RESTRICTED_PROFILE_DATA_COUNTER.SEA_SERVICES && !isSelectedOrganization && (
        <HiddenCrewDataPopover label={intl.formatMessage({ id: 'profile_seaService_with_restriction_amount' }, { amount: showMoreSeaServicesCounter })} />
      )}
      {seaServicesCounter > RESTRICTED_PROFILE_DATA_COUNTER.SEA_SERVICES && isSelectedOrganization && (
        <Button
          type="button"
          buttonType="white-primary"
          label={{ id: showAllSeaServices ? 'profile_slide_hide_elements' : 'profile_slide_show_all_sea_services' }}
          onClick={() => setShowAllSeaServices(prevState => !prevState)}
          additionalClasses="mx-auto"
        />
      )}
    </div>
  );
};

export default ProfileSlideServices;
