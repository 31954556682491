/**
 * @generated SignedSource<<be850036335ef616652e175fffdffa6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InviteMutationsRemoveInviteMutation$variables = {
  id: string;
};
export type InviteMutationsRemoveInviteMutation$data = {
  readonly deleteInvite: {
    readonly id: string;
  };
};
export type InviteMutationsRemoveInviteMutation = {
  response: InviteMutationsRemoveInviteMutation$data;
  variables: InviteMutationsRemoveInviteMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        concreteType: 'Invite',
        kind: 'LinkedField',
        name: 'deleteInvite',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'InviteMutationsRemoveInviteMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'InviteMutationsRemoveInviteMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '2bf1a8acb33c7163c2568375a6257c40',
      id: null,
      metadata: {},
      name: 'InviteMutationsRemoveInviteMutation',
      operationKind: 'mutation',
      text: 'mutation InviteMutationsRemoveInviteMutation(\n  $id: ID!\n) {\n  deleteInvite(id: $id) {\n    id\n  }\n}\n',
    },
  };
})();

(node as any).hash = '9ba848be7c3b951c2e2a1708d4e2754d';

export default node;
