import { ConnectionHandler, RecordSourceSelectorProxy } from 'relay-runtime';
import { ProfileMutationsAcceptOrganizationMemberInviteMutation$data } from 'schemas/profile/__generated__/ProfileMutationsAcceptOrganizationMemberInviteMutation.graphql';

export const organizationAcceptInviteUpdater = (
  store: RecordSourceSelectorProxy<ProfileMutationsAcceptOrganizationMemberInviteMutation$data>,
  data: ProfileMutationsAcceptOrganizationMemberInviteMutation$data,
) => {
  const storyRecord = store.getRoot();
  const profileId = data.acceptOrganizationMemberInvitation?.profile?.id;
  const connection = ConnectionHandler.getConnection(storyRecord, 'profileOrganizationMemberInvitationsList_profileOrganizationMemberInvitations', { profileId: profileId });
  if (!connection) {
    return;
  }
  const totalInvites = connection?.getValue('totalCount');
  connection.setValue((totalInvites as number) - 1, 'totalCount');
  ConnectionHandler.deleteNode(connection, data.acceptOrganizationMemberInvitation.id);
};
