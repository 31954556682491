import { useFormik } from 'formik';
import isChromatic from 'chromatic';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-relay';
import { useLocation, useNavigate } from 'react-router-dom';
//updater
import { ProfileMutationsCreateNewPoolMutation } from 'schemas/profile/__generated__/ProfileMutationsCreateNewPoolMutation.graphql';
//schemas
import { CREATE_NEW_POOL } from 'schemas/profile/ProfileMutations';
//updaters
import { crewNewCrewPoolUpdater } from 'formHelpers/updaters/updatersOfHireCrewPools';
//route
import { RoutesPathNames } from 'app/routing';
//components
import { Modal } from 'system/Modal';
import SpinnerIcon from 'icons/SpinnerIcon';
import CustomButton from 'system/Buttons/CustomButton';
//types
import { CreateNewPoolFormData } from 'models/modelsOfForms';
import { useGetHireProfile } from 'hooks/hireProfileHooks';
import EmployerCrewPoolsMain from '../EmployerCrewPoolsMain';

const EmployerCrewPoolCreateForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { organizationId } = useGetHireProfile();

  const [createPool, loadingPoolCreation] = useMutation<ProfileMutationsCreateNewPoolMutation>(CREATE_NEW_POOL);

  const onCloseWindow = () => {
    navigate(location.pathname.replace(`/${RoutesPathNames.newPool}`, ''));
  };

  const onSubmit = (values: CreateNewPoolFormData) => {
    if (values.crewsPoolName && organizationId) {
      createPool({
        variables: {
          data: {
            name: values.crewsPoolName,
            organizationId: organizationId,
            kind: 'REGULAR',
          },
        },
        updater: crewNewCrewPoolUpdater(organizationId),
        onCompleted: onCloseWindow,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      crewsPoolName: '',
    },
    onSubmit,
  });

  return (
    <>
      <Modal onClose={onCloseWindow} show tinyModal title={{ id: 'hire_create_new_crew_pool' }}>
        <form className="flex flex-col w-full" onSubmit={formik.handleSubmit}>
          <input
            data-test-id="create-new-pool-name"
            placeholder={intl.formatMessage({ id: 'hire_crew_pools_new_pool_name' })}
            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 border-specialGray-012 block w-full sm:text-sm rounded-md mb-4 placeholder:text-specialGray-05 text-darkBlue"
            id="crewsPoolName"
            name="crewsPoolName"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.crewsPoolName}
          />

          <CustomButton
            type="submit"
            disabled={loadingPoolCreation || !formik.values.crewsPoolName.length}
            className="flex text-sm bg-blue text-white py-3 mt-1 font-semibold items-center justify-center rounded-lg disabled:bg-blue-100"
          >
            {intl.formatMessage({ id: 'hire_create_new_crew_pool' })}
            {loadingPoolCreation ? <SpinnerIcon additionalClasses="w-5 h-5 ml-2" /> : null}
          </CustomButton>
        </form>
      </Modal>
      {!(isChromatic() || process.env.STORYBOOK_IS_STORYBOOK) ? <EmployerCrewPoolsMain /> : null}
    </>
  );
};

export default EmployerCrewPoolCreateForm;
