// packages
import { useState, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import { useMutation } from 'react-relay';
// components
import { Modal } from 'system/Modal';
import CustomButton from 'system/Buttons/CustomButton';
import AlertForError from 'system/Alert/AlertForError';
import { ConfirmModalBody } from 'system/ConfirmModalBody';
// hooks
import { useAuth } from 'authentication';
// schemas
import { REMOVE_ACCOUNT_MUTATION } from 'schemas/settings/SettingsMutations';
// generated
import { SettingsMutationsDeleteAccountMutation } from 'schemas/settings/__generated__/SettingsMutationsDeleteAccountMutation.graphql';

const DeleteAccount = ({ defaultError }: { defaultError?: Error }) => {
  const { identity } = useAuth();
  const { setEmail, setPassword } = useAuth();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(defaultError);
  const [commitCreate] = useMutation<SettingsMutationsDeleteAccountMutation>(REMOVE_ACCOUNT_MUTATION);

  const removeAccount = useCallback(() => {
    const variables = {
      pid: identity?.profileId?.toString() || '',
    };

    const onError = (error: Error) => {
      setError(error);
    };

    const handleDeleteCognitoUser = async () => {
      const user = await Auth.currentAuthenticatedUser();
      // @ts-ignore
      user.deleteUser(error => {
        if (error) {
          throw error;
        }
        window.analytics?.track('wui-settings-delete-account', {
          profileId: identity?.profileId,
        });
        Auth.signOut();
      });
    };

    if (setEmail && setPassword) {
      setEmail('');
      setPassword('');
    }

    if (identity?.profileId) {
      commitCreate({
        variables,
        onCompleted: () => {
          setError(undefined);
          setShowConfirmModal(false);
          handleDeleteCognitoUser().then();
        },
        onError,
      });
    }
  }, [commitCreate, identity?.profileId, setEmail, setPassword]);

  return (
    <div className="flex">
      {error && <AlertForError heading={{ id: 'profile_failedSave' }} error={error} />}
      <Modal
        onClose={() => {
          setShowConfirmModal(false);
        }}
        show={showConfirmModal}
        tinyModal={true}
      >
        <ConfirmModalBody
          title={{ id: 'delete_account_button' }}
          textOne={{ id: 'confirm_remove_delete_text_1' }}
          textTwo={{ id: 'confirm_remove_delete_text_2' }}
          buttonLabel={{ id: 'delete' }}
          onClick={() => {
            removeAccount();
          }}
          onCancel={() => {
            setShowConfirmModal(false);
          }}
          typeButtonConfirm="red-primary"
        />
      </Modal>

      <CustomButton
        dataTestId="remove-account"
        type="button"
        label={{ id: 'delete_account_button' }}
        className="flex w-fit h-10 px-[17px] py-2.5 text-specialGray-075 rounded-[10px] text-sm border border-specialGray-05"
        onClick={() => {
          setShowConfirmModal(true);
        }}
      />
    </div>
  );
};

export default DeleteAccount;
