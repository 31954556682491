import React, { useCallback, useEffect, useRef } from 'react';
//models
import { PopoverWithButtonsProps } from 'models/modelsOfComponents';

const LittlePopoverWrapper: React.FC<PopoverWithButtonsProps> = ({ isOpen, setIsOpen, children, additionalClasses }) => {
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleClickOutsidePopover = useCallback(
    (event: MouseEvent) => {
      if (isOpen && popoverRef.current && !(popoverRef.current as HTMLInputElement)?.contains(event.target as HTMLDivElement)) {
        setIsOpen(false);
      }
    },
    [isOpen, setIsOpen],
  );

  useEffect(() => {
    window.addEventListener('click', handleClickOutsidePopover);
    return () => {
      window.removeEventListener('click', handleClickOutsidePopover);
    };
  }, [handleClickOutsidePopover]);

  return isOpen ? (
    <div ref={popoverRef} className={`flex flex-col rounded-lg border border-specialGray-008 absolute top-12 left-0 w-44 z-10 bg-white overflow-auto ${additionalClasses}`}>
      {children}
    </div>
  ) : null;
};

export default LittlePopoverWrapper;
