import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ListOfCrewItemsSkeleton from './components/ListOfCrewItemsSkeleton';

const ListOfCrewSkeleton = () => {
  const [emptyList] = useState<Array<string>>(new Array(3).fill('').map(() => uuidv4()));

  return (
    <div className="flex flex-col space-y-6">
      {emptyList.map(item => (
        <ListOfCrewItemsSkeleton key={item} />
      ))}
    </div>
  );
};

export default ListOfCrewSkeleton;
