const YellowCircleWithRightArrow = () => {
  return (
    <svg width="77" height="73" viewBox="0 0 77 73" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.5107 50.0644C41.6542 50.0644 49.9199 41.8171 49.9199 31.6552C49.9199 21.5118 41.6542 13.2461 31.5107 13.2461C21.3489 13.2461 13.1016 21.5118 13.1016 31.6552C13.1016 41.6449 21.0717 49.7843 30.9957 50.0573L31.5107 50.0644Z"
        fill="url(#paint0_linear_12811_88524)"
      />
      <g opacity="0.5" filter="url(#filter0_f_12811_88524)">
        <path
          d="M31.3905 41.4724C36.8003 41.4724 41.2087 37.0738 41.2087 31.6541C41.2087 26.2443 36.8003 21.8359 31.3905 21.8359C25.9708 21.8359 21.5723 26.2443 21.5723 31.6541C21.5723 36.9819 25.823 41.323 31.1158 41.4686L31.3905 41.4724Z"
          fill="#F0F41E"
        />
      </g>
      <g filter="url(#filter1_b_12811_88524)">
        <path
          d="M49.8167 67.0435C63.623 67.0435 74.8736 55.818 74.8736 41.9866C74.8736 28.1802 63.623 16.9297 49.8167 16.9297C35.9853 16.9297 24.7598 28.1802 24.7598 41.9866C24.7598 55.5836 35.608 66.6622 49.1157 67.0339L49.8167 67.0435Z"
          fill="#FFF50D"
          fillOpacity="0.35"
        />
        <path
          d="M49.8074 67.7159V67.7159H49.8167C63.994 67.7159 75.546 56.1898 75.546 41.9866C75.546 27.8089 63.9944 16.2572 49.8167 16.2572C35.6134 16.2572 24.0873 27.8093 24.0873 41.9866C24.0873 55.9488 35.2265 67.3244 49.0972 67.706L49.0972 67.7061L49.1064 67.7062L49.8074 67.7159Z"
          stroke="url(#paint1_linear_12811_88524)"
          strokeWidth="1.34489"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#filter2_bd_12811_88524)">
        <path
          d="M44.8795 34.6213C44.1277 33.8947 44.1277 32.717 44.8544 31.9653C45.2302 31.5895 45.7063 31.4141 46.2075 31.4141C46.6835 31.4141 47.1596 31.5895 47.5104 31.9653L56.2553 40.6601C56.6061 41.0109 56.8065 41.4869 56.8065 41.9881C56.8065 42.4892 56.6061 42.9653 56.2553 43.3161L47.5104 52.0108C46.7838 52.7625 45.6061 52.7625 44.8544 52.0108C44.1277 51.2842 44.1277 50.0815 44.8795 49.3548L52.2712 41.9881L44.8795 34.6213Z"
          fill="url(#paint2_linear_12811_88524)"
        />
        <path
          d="M44.9744 34.5261L44.9744 34.5261L44.9729 34.5246C44.2768 33.8517 44.2742 32.7598 44.9503 32.0596C45.2988 31.7115 45.7395 31.5486 46.2075 31.5486C46.6507 31.5486 47.0896 31.7116 47.4121 32.0571L47.412 32.0571L47.4156 32.0607L56.1602 40.7552C56.1602 40.7552 56.1603 40.7552 56.1603 40.7553C56.4861 41.0811 56.672 41.5231 56.672 41.9881C56.672 42.4531 56.4861 42.895 56.1603 43.2209C56.1603 43.2209 56.1602 43.221 56.1602 43.221L47.4156 51.9155L47.4156 51.9154L47.4137 51.9174C46.7408 52.6135 45.6496 52.6158 44.9495 51.9157C44.2756 51.2419 44.2756 50.1256 44.9729 49.4515L44.9729 49.4515L44.9744 49.4501L52.3662 42.0833L52.4618 41.9881L52.3662 41.8928L44.9744 34.5261Z"
          stroke="url(#paint3_linear_12811_88524)"
          strokeOpacity="0.5"
          strokeWidth="0.268978"
        />
      </g>
      <defs>
        <filter id="filter0_f_12811_88524" x="0.0540142" y="0.317686" width="62.6732" height="62.6732" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="10.7591" result="effect1_foregroundBlur_12811_88524" />
        </filter>
        <filter id="filter1_b_12811_88524" x="-8.86332" y="-16.6914" width="117.359" height="117.356" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="16.1387" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_12811_88524" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_12811_88524" result="shape" />
        </filter>
        <filter id="filter2_bd_12811_88524" x="24.1391" y="11.2407" width="52.8409" height="61.5069" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="10.0867" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_12811_88524" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="6.72445" dy="6.72445" />
          <feGaussianBlur stdDeviation="6.72445" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.870588 0 0 0 0 0.850824 0 0 0 0 0.376471 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_12811_88524" result="effect2_dropShadow_12811_88524" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_12811_88524" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_12811_88524" x1="49.9199" y1="13.2461" x2="23.9596" y2="43.6079" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F7F163" />
          <stop offset="1" stopColor="#D9DD16" />
        </linearGradient>
        <linearGradient id="paint1_linear_12811_88524" x1="32.7371" y1="22.768" x2="64.7043" y2="62.3602" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint2_linear_12811_88524" x1="55.9207" y1="35.2352" x2="41.9939" y2="35.5033" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint3_linear_12811_88524" x1="45.6584" y1="38.5124" x2="56.2678" y2="38.547" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default YellowCircleWithRightArrow;
