import { useRecoilValue } from 'recoil';
import { useIntl } from 'react-intl';
// components
import { LinkIcon } from '@heroicons/react/24/solid';
// hooks
import useGetIsProSubscription from 'hooks/subscription/useGetIsProSubscription';
import { useIsUserAppliedForAnyVacancies } from 'hooks/hireProfileHooks/useIsUserAppliedForAnyVacancies';
// recoil
import { contactInformationAtom } from 'recoil/ContactInformation/atoms/contactInformationAtom';
// components
import SpinnerIcon from 'icons/SpinnerIcon';
import PaymentSubscriptionProLabel from 'app/ProfileEmployer/HireProduct/components/PaymentSubscriptionProLabel';

const ContactButton = ({ onClick, profileId }: { onClick: () => void; profileId?: string }) => {
  const intl = useIntl();
  const isProSubscription = useGetIsProSubscription();
  const isUserAppliedForAnyVacancies = useIsUserAppliedForAnyVacancies(profileId!);
  const getShowContactInformation = useRecoilValue(contactInformationAtom);

  const buttonClassName =
    isProSubscription || isUserAppliedForAnyVacancies ? 'bg-lightBlue text-blue' : 'border border-specialGray-05 text-specialGray-075 [&>svg]:text-specialGray-05';
  return (
    <button
      className={`flex gap-x-2 rounded-[10px] h-10 text-sm px-[18px] items-center font-medium ${buttonClassName}`}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
    >
      {getShowContactInformation.isLoading && getShowContactInformation.profileId === profileId ? (
        <SpinnerIcon spinnerColor="#2A6FF5" additionalClasses="w-5 h-5 text-blue" />
      ) : (
        <LinkIcon className="w-5 h-5" />
      )}

      {intl.formatMessage({ id: 'review_applications_contact_label' })}
      {!isProSubscription && !isUserAppliedForAnyVacancies ? <PaymentSubscriptionProLabel /> : null}
    </button>
  );
};

export default ContactButton;
