/**
 * @generated SignedSource<<2e7cf6882ce42a7a7935ceaf3d13f44f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OrganizationMemberRole = 'admin' | 'invited' | 'member' | 'owner' | '%future added value';
export type ProfileMutationsAcceptOrganizationMemberInviteMutation$variables = {
  orgMemberId: string;
};
export type ProfileMutationsAcceptOrganizationMemberInviteMutation$data = {
  readonly acceptOrganizationMemberInvitation: {
    readonly id: string;
    readonly organization: {
      readonly id: string;
      readonly name: string;
    };
    readonly profile: {
      readonly id: string;
      readonly info: {
        readonly avatarUrl: string | null;
        readonly firstName: string;
        readonly id: string;
        readonly lastName: string;
      } | null;
    };
    readonly role: OrganizationMemberRole;
  };
};
export type ProfileMutationsAcceptOrganizationMemberInviteMutation = {
  response: ProfileMutationsAcceptOrganizationMemberInviteMutation$data;
  variables: ProfileMutationsAcceptOrganizationMemberInviteMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'orgMemberId',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'orgMemberId',
            variableName: 'orgMemberId',
          },
        ],
        concreteType: 'OrganizationMember',
        kind: 'LinkedField',
        name: 'acceptOrganizationMemberInvitation',
        plural: false,
        selections: [
          v1 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'role',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'Organization',
            kind: 'LinkedField',
            name: 'organization',
            plural: false,
            selections: [
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'name',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'Profile',
            kind: 'LinkedField',
            name: 'profile',
            plural: false,
            selections: [
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                concreteType: 'ProfileInfo',
                kind: 'LinkedField',
                name: 'info',
                plural: false,
                selections: [
                  v1 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'avatarUrl',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'firstName',
                    storageKey: null,
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'lastName',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileMutationsAcceptOrganizationMemberInviteMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileMutationsAcceptOrganizationMemberInviteMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: '5c26640babc6e6db8a19f0cee76b6736',
      id: null,
      metadata: {},
      name: 'ProfileMutationsAcceptOrganizationMemberInviteMutation',
      operationKind: 'mutation',
      text: 'mutation ProfileMutationsAcceptOrganizationMemberInviteMutation(\n  $orgMemberId: ID!\n) {\n  acceptOrganizationMemberInvitation(orgMemberId: $orgMemberId) {\n    id\n    role\n    organization {\n      id\n      name\n    }\n    profile {\n      id\n      info {\n        id\n        avatarUrl\n        firstName\n        lastName\n      }\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '96c4aa2ba860d8b3cff01b33413acb7c';

export default node;
