const DoneIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.9249 6.29931C17.5349 5.90931 16.9049 5.90931 16.5149 6.29931L10.8749 11.9393L12.2849 13.3493L17.9249 7.69931C18.3049 7.31931 18.3049 6.67931 17.9249 6.29931ZM22.1649 6.28931L12.2849 16.1693L8.80489 12.6993C8.41489 12.3093 7.78489 12.3093 7.39489 12.6993C7.00489 13.0893 7.00489 13.7193 7.39489 14.1093L11.5749 18.2893C11.9649 18.6793 12.5949 18.6793 12.9849 18.2893L23.5749 7.70931C23.9649 7.31931 23.9649 6.68931 23.5749 6.29931H23.5649C23.1849 5.89931 22.5549 5.89931 22.1649 6.28931ZM1.74489 14.1193L5.92489 18.2993C6.31489 18.6893 6.94489 18.6893 7.33489 18.2993L8.03489 17.5993L3.15489 12.6993C2.76489 12.3093 2.13489 12.3093 1.74489 12.6993C1.35489 13.0893 1.35489 13.7293 1.74489 14.1193Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DoneIcon;
