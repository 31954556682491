import React, { useCallback, useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useLazyLoadQuery, useMutation } from 'react-relay';
// components
import { InputGroup } from 'system/InputGroups/InputGroup';
import CustomButton from 'system/Buttons/CustomButton';
import SelectInputGroup from 'system/SelectInputGroup';
import AlertForError from 'system/Alert/AlertForError';
import { DateInputGroup } from 'system/DateInputGroup';
import { CheckboxInputGroup } from 'system/CheckboxInputGroup';
// generated
import { EducationFragments$data } from 'schemas/educations/__generated__/EducationFragments.graphql';
import { EducationQueriesKindsQuery } from 'schemas/educations/__generated__/EducationQueriesKindsQuery.graphql';
import { EducationMutationsFormCreateMutation } from 'schemas/educations/__generated__/EducationMutationsFormCreateMutation.graphql';
import { EducationMutationsFormUpdateMutation } from 'schemas/educations/__generated__/EducationMutationsFormUpdateMutation.graphql';
// schemas
import { GET_EDUCATION_KIND } from 'schemas/educations/EducationQueries';
import { CREATE_EDUCATION, UPDATE_EDUCATION } from 'schemas/educations/EducationMutations';
// hooks
import { useAuth } from 'authentication';
// helpers
import { getConnectionId } from 'formHelpers/utils';
import { EducationCreateUpdater } from 'formHelpers/updaters/updatersOfEducation';
import { EducationFormValidateSchema } from 'formHelpers/validationsOfForms';
import { EducationFormInitialValues } from 'formHelpers/initialValuesOfForms';
// models
import { IEducation } from 'models/IEducation';
import { EducationFormProps } from 'models/modelsOfComponents';
import { EducationFormData } from 'models/modelsOfForms';

const EducationForm: React.FC<EducationFormProps> = ({ education = null, defaultError = null, isOpenCreateModal, onSubmitted = () => {}, defaultSubmitting }) => {
  const { identity } = useAuth();

  const storyID = getConnectionId('EducationsListFromProfile_educations');
  const [commitCreate] = useMutation<EducationMutationsFormCreateMutation>(CREATE_EDUCATION);
  const [commitUpdate] = useMutation<EducationMutationsFormUpdateMutation>(UPDATE_EDUCATION);
  const educationKinds = useLazyLoadQuery<EducationQueriesKindsQuery>(GET_EDUCATION_KIND, { skip: false });

  const [error, setError] = useState<Error | null>(defaultError);
  const [types, setTypes] = useState<IEducation>(educationKinds.educationKinds?.edges || []);
  const [selectedType, setSelectedType] = useState<EducationFragments$data | undefined>(undefined);

  const [isResetForm, setIsResetForm] = useState(false);
  const [isClosedModal, setIsClosedModal] = useState<boolean>(true);
  const [isDisabledTo, setDisabledTo] = useState<boolean>(education?.end === null);

  const formik = useFormik<EducationFormData>({
    onSubmit,
    initialValues: EducationFormInitialValues(education),
    validationSchema: EducationFormValidateSchema(),
  });

  const handleCompleted = () => {
    setError(null);
    formik.setSubmitting(false);
    formik.resetForm();
    setIsResetForm(true);
    onSubmitted(!isClosedModal);
  };

  function onSubmit(formData: EducationFormData) {
    const onError = (error: Error) => {
      setError(error);
      formik.setSubmitting(false);
    };

    const data = {
      ownerId: `${identity?.profileId}`,
      name: formData.name,
      start: formData.start ? formData.start.toISOString() : '',
      end: formData.end ? formData.end.toISOString() : null,
      kindId: selectedType?.id || '',
    };

    if (education) {
      commitUpdate({
        onError,
        onCompleted: () => {
          handleCompleted();
          window.analytics?.track('wui-updated-education', {
            uploadedEducationId: education.id,
          });
        },
        variables: {
          sid: education.id,
          data,
        },
      });
    } else if (identity?.profileId) {
      commitCreate({
        onError,
        onCompleted: response => {
          handleCompleted();
          window.analytics?.track('wui-created-education', {
            createdEducationId: response.createEducation?.node?.id,
          });
        },
        variables: {
          data,
          connections: [`${storyID}(ownerId:"${identity?.profileId}")`],
        },
        updater: EducationCreateUpdater(identity.profileId.toString()),
      });
    }
  }

  const handleChangeType = useCallback(
    (value: string) => {
      if (value) {
        setTypes(() => educationKinds.educationKinds?.edges?.filter(({ name }) => name?.toLowerCase().includes(value.toLowerCase())) as IEducation);
      } else {
        setTypes(() => educationKinds.educationKinds?.edges as IEducation);
      }
    },
    [educationKinds.educationKinds],
  );

  useEffect(() => {
    if (defaultSubmitting && !formik.isSubmitting) formik.setSubmitting(true);
  }, [formik, defaultSubmitting]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (isResetForm) {
      timer = setTimeout(() => {
        setIsResetForm(false);
      }, 100);
    }
    return () => {
      clearTimeout(timer!);
    };
  }, [isResetForm]);

  useEffect(() => {
    if (education?.kind?.name) {
      setSelectedType({ id: education?.kind?.id?.toString(), name: education.kind.name.toString() } as EducationFragments$data);
    }
  }, [education]);

  const handleChangeCheckbox = useCallback((value: boolean) => {
    setDisabledTo(value);
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form className="space-y-6">
        {error && <AlertForError heading={{ id: 'education_form_failedSubmit' }} error={error} />}

        <section className="flex flex-col sm:flex-row">
          <div className="mb-3.5 sm:mb-0">
            <DateInputGroup autoFocus name="start" label={{ id: 'input_placeholder_from' }} isResetInput={isResetForm} />
          </div>
          <div className="flex-col justify-end mx-3.5 text-specialGray-012 hidden sm:flex">
            <span className="mb-[7px]">&#9472;</span>
          </div>
          <DateInputGroup name="end" label={{ id: 'input_placeholder_to' }} isResetInput={isResetForm} disabled={isDisabledTo} />
        </section>

        <CheckboxInputGroup name="studyingNow" value={isDisabledTo.toString()} label={{ id: 'education_form_studying_here' }} onChange={handleChangeCheckbox} />

        <div className="mt-5">
          <SelectInputGroup
            name="kindId"
            options={[...types].sort((a, b) => a.name.localeCompare(b.name))}
            label={{ id: 'education_form_level_label' }}
            onChangeSelect={handleChangeType}
            placeholder={{ id: 'placeholder_form_type' }}
            defaultSelectedElement={selectedType}
            setSelectedItem={setSelectedType}
            selectedItem={selectedType}
            isResetInput={isResetForm}
            type="Level"
            autoComplete="off"
          />
        </div>

        <InputGroup name="name" label={{ id: 'field_is_name' }} placeholderText={{ id: 'field_is_name' }} />

        <div className="flex flex-col sm:flex-row sm:justify-end items-center sm:items-start space-x-0 sm:space-x-2 space-y-2 sm:space-y-0">
          <CustomButton
            type="submit"
            label={{ id: 'education_form_save' }}
            onClick={() => {
              setIsClosedModal(true);
            }}
            className="sm:w-auto w-full disabled:opacity-50 items-center px-3 py-2 border border-blue-100 shadow-sm text-sm leading-4 font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 hover:border-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            disabled={formik.isSubmitting}
          />
          {isOpenCreateModal ? (
            <CustomButton
              type="submit"
              label={{ id: 'save_and_add_another' }}
              onClick={() => {
                setIsClosedModal(true);
              }}
              className="sm:w-auto w-full disabled:opacity-50 items-center px-3 py-2 border border-blue-600 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 hover:border-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              disabled={formik.isSubmitting}
            />
          ) : null}
        </div>
      </Form>
    </FormikProvider>
  );
};

export default EducationForm;
