import CrewSettingsSkeletonLeftSide from './CrewSettingsSkeletonLeftSide';
import CrewSettingsSkeletonRightSide from './CrewSettingsSkeletonRightSide';

const AccountSettingsSkeletonCommon = () => {
  return (
    <div className="flex w-full min-h-[calc(100vh_-_136px)]">
      <CrewSettingsSkeletonLeftSide />
      <div className="w-full md:w-3/4">
        <CrewSettingsSkeletonRightSide />
      </div>
    </div>
  );
};

export default AccountSettingsSkeletonCommon;
