import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
//components
import { Modal } from 'system/Modal';
import SpinnerIcon from 'icons/SpinnerIcon';
import CustomButton from 'system/Buttons/CustomButton';
import CopyLinkComponent from 'system/CopyLinkComponent';
import { ConfirmModalBody } from 'system/ConfirmModalBody';
import ThreeDotsPopover from './components/ThreeDotsPopover';
import EditIconWithUnderline from 'icons/EditIconWithUnderline';
import VacancyInformationLayout from './components/VacancyInformationLayout';
import PaymentSubscriptionProLabel from 'app/ProfileEmployer/HireProduct/components/PaymentSubscriptionProLabel';
//models
import { VACANCY_STATUS } from 'models/enums';
//hooks
import { useSwitchVacancyStatus } from 'hooks/useSwitchVacancyStatus';
//schemas
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
//types
import { VacancyInformationForHireProps } from 'app/ProfileEmployer/components/JobDescriptionPage/types';
// routing
import { RoutePaths } from 'app/routing';
// recoil
import { paymentSubscriptionModalAtom } from 'recoil/ContactInformation/atoms/paymentSubscriptionModalAtom';
import { RestrictedFromPublishJobAtom } from 'recoil/RestrictedFromPublishJob/atoms/RestrictedFromPublishJobAtom';

const VacancyInformationForHire = ({ vacancyFragment, defaultShowShareModal, defaultShowShareLink, defaultOpenDropdown = false }: VacancyInformationForHireProps) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const setShowPaymentSubscriptionModal = useSetRecoilState(paymentSubscriptionModalAtom);
  const isRestrictedFromPublishClosedJob = useRecoilValue(RestrictedFromPublishJobAtom);

  const handleClickByPublishButton = () => {
    if (isRestrictedFromPublishClosedJob) {
      window.analytics?.track('wui-job-opening-page-show-upgrade-to-pro-modal');
      setShowPaymentSubscriptionModal({
        showPaymentSubscriptionModal: true,
      });
      return;
    }
    handleSwitchStatus(VACANCY_STATUS.OPEN)();
  };

  const [showShareModal, setShowShareModal] = useState<boolean>(defaultShowShareModal || false);

  const { vacancy, handleSwitchStatus, isCommitSwitchVacancyLoading } = useSwitchVacancyStatus(vacancyFragment);

  const handleToggleShowShareModal = useCallback((value: boolean) => {
    setShowShareModal(value);
  }, []);

  const isOpenVacancy = vacancy.status === VACANCY_STATUS.OPEN;

  const publishButtonClassName = isRestrictedFromPublishClosedJob
    ? 'flex-row-reverse gap-x-2 bg-white text-specialGray-075 border border-specialGray-03'
    : 'bg-blue text-white w-[179px]';
  return (
    <>
      <Modal onClose={() => handleToggleShowShareModal(false)} show={showShareModal} tinyModal>
        {vacancy?.status === VACANCY_STATUS.OPEN ? (
          <div className="flex flex-col items-center">
            <span className="mb-2">{intl.formatMessage({ id: 'hire_share_profile_share_vacancy_link' })}</span>
            <CopyLinkComponent profileShareLink={defaultShowShareLink || `${document.location.origin}${pathname}`} />
          </div>
        ) : (
          <ConfirmModalBody
            title={{ id: 'hire_confirm_not_publish_vacancy' }}
            textOne={{ id: 'hire_confirm_publish_vacancy' }}
            onClick={handleSwitchStatus(VACANCY_STATUS.OPEN)}
            onCancel={() => handleToggleShowShareModal(false)}
            buttonLabel={{ id: 'publish' }}
            typeButtonConfirm="primary"
          />
        )}
      </Modal>
      <VacancyInformationLayout vacancy={vacancy}>
        <div className="flex gap-x-6">
          {!isOpenVacancy && (
            <CustomButton
              dataTestId="open-vacancy-button"
              onClick={handleClickByPublishButton}
              disabled={isCommitSwitchVacancyLoading}
              classNameStyle={`flex rounded-[10px] h-10  font-medium w-full text-sm justify-center ${publishButtonClassName} pl-4.5 pr-3.5`}
              label={{ id: isCommitSwitchVacancyLoading ? 'suspense_loading' : 'hire_publish_vacancy' }}
            >
              {isCommitSwitchVacancyLoading && <SpinnerIcon spinnerColor="#fff" additionalClasses="w-5 h-5 mr-1" />}
              {isRestrictedFromPublishClosedJob ? <PaymentSubscriptionProLabel /> : null}
            </CustomButton>
          )}
          <Link
            className="py-[9px] px-3 text-blue hover:bg-blue-100 bg-lightBlue rounded-[10px]"
            state={{
              organization: {
                id: vacancy?.organization?.id as string,
                vacancyId: vacancy?.id,
                positionTitle: vacancy?.title,
              },
              prevPath: pathname,
            }}
            to={`${RoutePaths.ProfileOrganizationBase}/${vacancy.organization.id}/${RoutePaths.JobOpeningStep1}`}
          >
            <EditIconWithUnderline className="w-5 h-5 text-blue" />
          </Link>
          <ThreeDotsPopover
            withoutEditOption
            vacancyFragment={vacancyFragment as VacancyFragments$key}
            defaultOpenDropdown={defaultOpenDropdown}
            onToggleShowShareModal={handleToggleShowShareModal}
          />
        </div>
      </VacancyInformationLayout>
    </>
  );
};

export default VacancyInformationForHire;
