import { SVGAttributes, useState } from 'react';
import { getYear } from 'date-fns';
import { AtSymbolIcon } from '@heroicons/react/24/outline';

export const Footer = () => {
  const [getCurrentYear] = useState<number>(getYear(new Date()));

  const navigation = [
    {
      name: 'Linkedin',
      href: process.env.REACT_APP_LINKED_IN_LINK,
      icon: (props: SVGAttributes<HTMLOrSVGElement>) => (
        <svg fill="currentColor" viewBox="0 0 400 400" {...props}>
          <path
            d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889
            C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056
            H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806
            c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1
            s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73
            c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079
            c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426
            c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472
            L341.91,330.654L341.91,330.654z"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: process.env.REACT_APP_INSTAGRAM_LINK,
      icon: (props: SVGAttributes<HTMLOrSVGElement>) => (
        <svg className="h-6 md:h-4 w-6 md:w-4" width="15" height="15" viewBox="0 0 15 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.13691 0.499023C8.05237 0.531721 9.00053 0.531721 9.91601 0.564415C10.7334 0.597109 11.4854 0.72789 12.2047 1.15293C13.1201 1.70874 13.676 2.52612 13.8721 3.57236C13.9702 4.19357 14.0029 4.84747 14.0356 5.46868C14.0683 6.84187 14.0356 8.21507 14.0356 9.58825C14.0356 10.3075 14.0029 11.0268 13.774 11.7134C13.3163 13.0539 12.3681 13.8713 10.9949 14.1329C10.3737 14.2637 9.71983 14.2637 9.09862 14.2963C7.72542 14.329 6.38491 14.2963 5.01171 14.2963C4.29242 14.2963 3.57312 14.2637 2.88652 14.0348C1.54601 13.5771 0.728632 12.6289 0.467069 11.2557C0.33629 10.6345 0.33629 9.98059 0.303595 9.35939C0.270897 7.9862 0.303595 6.613 0.303595 5.23981C0.303595 4.52052 0.33629 3.80123 0.565156 3.11463C1.02289 1.77413 1.97105 0.956758 3.34425 0.695196C3.96547 0.564419 4.61937 0.564419 5.24058 0.531721C5.86179 0.499023 6.483 0.499023 7.13691 0.499023ZM12.7932 7.2669C12.7932 7.2669 12.7605 7.2669 12.7932 7.2669C12.7605 6.71108 12.7605 6.18795 12.7605 5.63214C12.7605 5.10902 12.7278 4.5859 12.6624 4.06278C12.5317 2.88575 11.845 2.10107 10.7007 1.87221C10.1122 1.74143 9.45829 1.74143 8.86978 1.74143C7.69275 1.70873 6.54842 1.70873 5.37139 1.74143C4.78288 1.74143 4.19436 1.77412 3.63854 1.87221C2.65768 2.03568 1.97108 2.5915 1.67683 3.57235C1.57874 3.8993 1.54604 4.22625 1.51335 4.5532C1.48065 5.79562 1.48065 7.03803 1.48065 8.28044C1.48065 9.06512 1.51335 9.8825 1.57874 10.6672C1.67683 11.8442 2.39612 12.6616 3.57315 12.8578C4.16166 12.9558 4.78288 12.9885 5.40408 12.9885C6.54842 13.0212 7.69275 12.9885 8.86978 12.9885C9.39291 12.9885 9.91603 12.9558 10.4392 12.8904C10.9296 12.8578 11.3873 12.6943 11.7797 12.3673C12.4336 11.8442 12.6624 11.1576 12.6951 10.3729C12.7605 9.39207 12.7605 8.34583 12.7932 7.2669Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6676 7.39751C10.6676 9.3592 9.09828 10.9286 7.13656 10.9286C5.17484 10.9286 3.60547 9.3592 3.60547 7.36482C3.60547 5.43581 5.20754 3.86646 7.16925 3.86646C9.09828 3.86646 10.6676 5.43581 10.6676 7.39751ZM7.13653 9.68616C8.37894 9.68616 9.42519 8.63992 9.42519 7.39752C9.42519 6.15511 8.37894 5.10887 7.13653 5.10887C5.86141 5.10887 4.84785 6.15511 4.84785 7.39752C4.81516 8.63992 5.86141 9.68616 7.13653 9.68616Z"
            fill="currentColor"
          />
          <path
            d="M11.6162 3.70288C11.6162 4.16061 11.2565 4.55296 10.7988 4.55296C10.341 4.55296 9.9487 4.16061 9.9814 3.70288C9.9814 3.24515 10.341 2.8855 10.7988 2.8855C11.2565 2.8855 11.6162 3.24514 11.6162 3.70288Z"
            fill="currentColor"
          />
        </svg>
      ),
    },
    {
      name: 'hello@crewlinker.com',
      href: process.env.REACT_APP_EMAIL_LINK,
      icon: (props: SVGAttributes<HTMLOrSVGElement>) => <AtSymbolIcon {...props} />,
    },
  ];

  return (
    <footer className="bg-white border-t border-t-specialGray-012">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center items-center space-x-6 md:order-2">
          {navigation.map(item => (
            <a key={item.name} href={item.href} target="_blank" rel="noreferrer noopener" className="text-specialGray-05 hover:text-darkBlue">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-4 w-4" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-xs text-specialGray-05">&copy;&nbsp;{getCurrentYear}&nbsp;Crewlinker, Inc. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};
