const SuggestionIcon = () => {
  return (
    <svg width="57" height="45" viewBox="0 0 57 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48.6038 8.51854L50.557 12.8157L52.5102 8.51854L56.8074 6.5653L52.5102 4.61206L50.557 0.314941L48.6038 4.61206L44.3066 6.5653L48.6038 8.51854Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <path
        d="M48.6038 40.7031L50.557 45.0002L52.5102 40.7031L56.8074 38.7499L52.5102 36.7966L50.557 32.4995L48.6038 36.7966L44.3066 38.7499L48.6038 40.7031Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <path
        d="M4.48853 24.7817L6.44177 29.0788L8.395 24.7817L12.6921 22.8285L8.395 20.8752L6.44177 16.5781L4.48853 20.8752L0.191406 22.8285L4.48853 24.7817Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <mask id="mask0_10515_84264" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="12" y="0" width="39" height="38">
        <rect x="12.6914" width="37.5022" height="37.5022" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_10515_84264)">
        <path
          d="M18.9416 34.3773C18.0822 34.3773 17.3467 34.0715 16.7352 33.4601C16.1227 32.8475 15.8164 32.1115 15.8164 31.2521V14.0636C15.8164 13.2042 16.1227 12.4682 16.7352 11.8557C17.3467 11.2442 18.0822 10.9384 18.9416 10.9384H26.7545V6.25067C26.7545 5.39124 27.0608 4.65526 27.6733 4.04273C28.2848 3.43123 29.0203 3.12549 29.8797 3.12549H33.0049C33.8643 3.12549 34.6003 3.43123 35.2128 4.04273C35.8243 4.65526 36.1301 5.39124 36.1301 6.25067V10.9384H43.943C44.8025 10.9384 45.5384 11.2442 46.151 11.8557C46.7625 12.4682 47.0682 13.2042 47.0682 14.0636V31.2521C47.0682 32.1115 46.7625 32.8475 46.151 33.4601C45.5384 34.0715 44.8025 34.3773 43.943 34.3773H18.9416ZM18.9416 31.2521H43.943V14.0636H36.1301C36.1301 14.923 35.8243 15.6585 35.2128 16.27C34.6003 16.8825 33.8643 17.1888 33.0049 17.1888H29.8797C29.0203 17.1888 28.2848 16.8825 27.6733 16.27C27.0608 15.6585 26.7545 14.923 26.7545 14.0636H18.9416V31.2521ZM22.0668 28.1269H31.4423V27.4238C31.4423 26.981 31.3183 26.5706 31.0704 26.1924C30.8235 25.8153 30.4787 25.5226 30.036 25.3143C29.5151 25.0799 28.988 24.9043 28.4546 24.7877C27.9202 24.67 27.3535 24.6111 26.7545 24.6111C26.1555 24.6111 25.5894 24.67 25.056 24.7877C24.5216 24.9043 23.994 25.0799 23.4731 25.3143C23.0304 25.5226 22.6856 25.8153 22.4387 26.1924C22.1907 26.5706 22.0668 26.981 22.0668 27.4238V28.1269ZM34.5675 25.783H40.8178V23.4392H34.5675V25.783ZM26.7545 23.4392C27.4056 23.4392 27.9588 23.211 28.414 22.7547C28.8703 22.2995 29.0984 21.7464 29.0984 21.0953C29.0984 20.4442 28.8703 19.891 28.414 19.4358C27.9588 18.9795 27.4056 18.7514 26.7545 18.7514C26.1035 18.7514 25.5503 18.9795 25.0951 19.4358C24.6388 19.891 24.4107 20.4442 24.4107 21.0953C24.4107 21.7464 24.6388 22.2995 25.0951 22.7547C25.5503 23.211 26.1035 23.4392 26.7545 23.4392ZM34.5675 21.0953H40.8178V18.7514H34.5675V21.0953ZM29.8797 14.0636H33.0049V6.25067H29.8797V14.0636Z"
          fill="#082051"
          fillOpacity="0.3"
        />
      </g>
    </svg>
  );
};

export default SuggestionIcon;
