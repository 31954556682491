import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { TextInputSkeletonProps } from 'models/modelsOfComponents';

const TextInputSkeleton: React.FC<TextInputSkeletonProps> = ({ widthTitle, borderRadius, heightInput, additionalStyle }) => {
  return (
    <div className={`w-full ${additionalStyle}`}>
      <Skeleton width={widthTitle} />
      <Skeleton width="100%" height={heightInput} borderRadius={borderRadius} />
    </div>
  );
};

export default TextInputSkeleton;
