// packages
import { GetRecoilValue, selector } from 'recoil';
// recoil
import { selectedDashboardVacanciesByParamsClosedAtom } from '../atoms/selectedDashboardVacanciesByParamsClosedAtom';
import { fetchDashboardVacanciesByParamsAtom } from '../atoms/fetchDashboardVacanciesByParamsAtom';
// types
import { DASHBOARD_VACANCIES_FILTER_ENUM } from 'models/enums';
import { vacancyFiltersTypes } from '../types';
// helpers
import { uniqArrayHelper, vacanciesSearchParams } from 'utils/filterHelpers';

// We collect all the filters that the user used into one array in order to correctly change the relay Connection.handler
export const fetchDashboardVacanciesByShowAllParamsSelector = selector({
  key: 'fetchDashboardVacanciesByClosedParamsSelector',
  get: ({ get }: { get: GetRecoilValue }): vacancyFiltersTypes => {
    const searchParams: URLSearchParams = new URL(document.location.href).searchParams;
    const isSearchParamsExist = Array.from(searchParams.keys()).length;

    const tabStatus = get(fetchDashboardVacanciesByParamsAtom).filterByStatus;
    const isShowAllTab = tabStatus === DASHBOARD_VACANCIES_FILTER_ENUM.SHOW_ALL;

    const selectedShowAllFilters = get(selectedDashboardVacanciesByParamsClosedAtom);

    if (!isSearchParamsExist || !isShowAllTab) {
      return selectedShowAllFilters;
    }

    const params = vacanciesSearchParams(searchParams);

    const newFilters = [
      ...selectedShowAllFilters,
      {
        orderBy: {
          field: params.fieldName,
          direction: params.direction,
        },
      },
    ];

    return uniqArrayHelper(newFilters);
  },
  set: ({ set }, newValue) => {
    set(selectedDashboardVacanciesByParamsClosedAtom, uniqArrayHelper(newValue as vacancyFiltersTypes));
  },
});
