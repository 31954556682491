// packages
import { useRef } from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import ChevronUpIcon from '@heroicons/react/24/solid/ChevronUpIcon';
// component
import LabelComponent from 'system/LabelComponent/LabelComponent';
import { FilterSelectedItem } from './FilterItems/FilterSelectedItem';
import { FilterDoubleListDropDown } from './FilterItems/FilterDoubleListDropDown';
// models
import { FilteringByNumberProps } from '../types';
// hooks
import { useHoverById } from 'hooks/useHover';
import useOnClickOutside from 'hooks/useOutsideAlerter';
import { useDropDownTools } from '../hooks/useDropDownTools';
import useFilterSearchNumeric from 'hooks/searchFilter/useFilterSearchNumeric';
// models
import { FILTER_TYPE } from 'models/enums';
// utils
import { formatterFilterByName } from 'utils/filterHelpers';
// mocks
import { MOCK_SALARY_PERIOD } from 'mocks/mockData';

export const FilteringByMinSalary = ({ title, filterName }: FilteringByNumberProps) => {
  const intl = useIntl();
  const { createSearchNumericUrl, selectedNumber } = useFilterSearchNumeric(filterName);

  const filterContainerRef = useRef<HTMLDivElement>(null);
  const { selectedId, handleMouseEnter, handleMouseOut } = useHoverById();

  const {
    isOpenList,
    inputValue,
    filters,
    handleChangeInput,
    handleAddSearchFilter,
    handleDeleteSelectedItemFromDropDownList,
    openDropDownList,
    closeListIfOutsideClick,
    selectedFilterItems,
    inputReference,
  } = useDropDownTools<{
    readonly id: string;
    readonly name: string;
  }>(MOCK_SALARY_PERIOD, FILTER_TYPE.SALARY_PERIOD, formatterFilterByName);

  useOnClickOutside(filterContainerRef, closeListIfOutsideClick);

  return (
    <div className="flex flex-col border-b border-b-specialGray-012">
      <LabelComponent classes="pt-8 pl-4 sm:pl-8 text-sm font-medium text-specialGray-1" id="number_of_sea_services_from" label={title} />
      <div className="relative flex mx-4 sm:mx-8 flex-col">
        <div className="flex w-full gap-x-2 items-center">
          <input
            onChange={createSearchNumericUrl}
            id="number_of_sea_services_from"
            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-1/2 sm:text-sm rounded-md border-specialGray-012 placeholder:text-specialGray-05 pl-2.5 text-darkBlue"
            type="number"
            placeholder={intl.formatMessage({ id: 'input_placeholder_from' })}
            value={selectedNumber}
          />
          <div className="flex flex-col mt-2">
            <div ref={filterContainerRef} className="relative">
              <div className="my-1 mb-6 relative mt-4" onClick={openDropDownList}>
                <input
                  ref={inputReference}
                  placeholder={intl.formatMessage({ id: 'hire_information_salary_period' })}
                  id={FILTER_TYPE.SALARY_PERIOD}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 border-specialGray-012 placeholder:text-specialGray-05 block w-full sm:text-sm rounded-md pl-2 text-darkBlue"
                  value={inputValue}
                  onChange={handleChangeInput}
                  type="text"
                />
                <ChevronUpIcon
                  className={`w-5 h-5 text-specialGray-05 ${isOpenList ? 'rotate-0' : 'rotate-180'} absolute top-2.5 right-0 mr-2 transition-all duration-2000 cursor-pointer`}
                />
              </div>
              {isOpenList && (
                <div className="w-full absolute -top-6">
                  <FilterDoubleListDropDown
                    filterItems={filters}
                    nameFormatter={formatterFilterByName}
                    selectedFilterItems={selectedFilterItems}
                    onClickDeleteEvent={handleDeleteSelectedItemFromDropDownList(inputReference)}
                    onClickEvent={handleAddSearchFilter}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {!isEmpty(selectedFilterItems) && (
          <div className="flex w-full flex-wrap mb-3">
            {selectedFilterItems.map(item => {
              return (
                <div className="flex" onMouseEnter={handleMouseEnter(item.id)} onMouseLeave={handleMouseOut} key={item.id}>
                  <FilterSelectedItem
                    className="mr-2 mt-2"
                    isHovered={item.id === selectedId}
                    name={formatterFilterByName(item)}
                    onClickEvent={handleDeleteSelectedItemFromDropDownList()(item)}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
