import Skeleton from 'react-loading-skeleton';

const ListOfReviewAppliedCrewItemSkeleton = () => {
  return (
    <section className="flex rounded-2xl border w-full p-4 justify-between">
      <div className="gap-x-4 flex">
        <Skeleton width={64} height={64} borderRadius={6} />
        <div className="flex flex-col justify-between">
          <div className="flex items-center font-medium space-x-4">
            <Skeleton width={150} height={20} />
            <Skeleton width={110} height={18} containerClassName="mt-0.5" />
            <Skeleton width={40} height={14} containerClassName="mt-1" />
            <Skeleton width={24} />
          </div>
          <div className="flex text-sm space-x-4 text-specialGray-05">
            <Skeleton width={100} height={14} />
            <Skeleton width={100} height={14} />
            <Skeleton width={100} height={14} />
          </div>
        </div>
      </div>
      <div className="flex gap-x-4 items-center">
        <Skeleton width={77} height={36} borderRadius={8} />
        <Skeleton width={77} height={36} borderRadius={8} />
        <Skeleton width={100} />
      </div>
    </section>
  );
};

export default ListOfReviewAppliedCrewItemSkeleton;
