import { Suspense, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import { useFragment, useLazyLoadQuery } from 'react-relay';
//generated
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { VacancyQueriesGetVacancyQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetVacancyQuery.graphql';
//components
import RestrictedJobOpeningNotification from './components/ResctrictedJobOpeneningNotification';
import RightSideListsOfCrew from 'app/ProfileEmployer/components/JobDescriptionPage/components/RightSideListsOfCrews/RightSideListsOfCrew';
import VacancyInformationForHire from 'app/ProfileEmployer/components/JobDescriptionPage/components/VacancyInformation/VacancyInformationForHire';
import RightSideListsOfCrewSkeleton from 'system/skeletons/hireProfile/JobDescriptionTabsSkeleton/components/RightSide/RightSideListsOfCrewSkeleton';
//models
import { VACANCY_STATUS } from 'models/enums';
import { GET_VACANCY } from 'schemas/vacancy/VacancyQueries';
import { VACANCY_FRAGMENT } from 'schemas/vacancy/VacancyFragments';
// recoil
import { RestrictedFromPublishJobAtom } from 'recoil/RestrictedFromPublishJob/atoms/RestrictedFromPublishJobAtom';
// hooks
import useIsRestrictedFromPublishJobOpening from 'hooks/subscription/useIsRestrictedFromPublishJobOpening';

const JobDescriptionEditPreview = () => {
  const { jobDescriptionEditId } = useParams();
  const vacancyFragment = useLazyLoadQuery<VacancyQueriesGetVacancyQuery>(GET_VACANCY, { id: jobDescriptionEditId!, skip: !jobDescriptionEditId });
  const vacancyStatus = useFragment<VacancyFragments$key>(VACANCY_FRAGMENT, vacancyFragment?.node || null)?.status;

  const [getRestrictedFromPublishJobAtom, setRestrictedFromPublishJobAtom] = useRecoilState(RestrictedFromPublishJobAtom);

  const isRestrictedFromPublishJobOpening = useIsRestrictedFromPublishJobOpening();
  const isRestrictedFromPublishClosedJobOpening = isRestrictedFromPublishJobOpening && vacancyStatus === VACANCY_STATUS.CLOSED;

  useEffect(() => {
    setRestrictedFromPublishJobAtom(isRestrictedFromPublishClosedJobOpening);
  }, [isRestrictedFromPublishClosedJobOpening, isRestrictedFromPublishJobOpening, setRestrictedFromPublishJobAtom]);

  return (
    <div className="flex w-full flex-col gap-y-8 pl-[54px] pr-[65px]">
      {getRestrictedFromPublishJobAtom ? <RestrictedJobOpeningNotification /> : null}
      <div className="flex w-full gap-x-12">
        <div className="w-3/5">
          <VacancyInformationForHire vacancyFragment={vacancyFragment.node as VacancyFragments$key} />
        </div>
        <Suspense fallback={<RightSideListsOfCrewSkeleton />}>
          <RightSideListsOfCrew vacancyFragment={vacancyFragment.node as VacancyFragments$key} />
        </Suspense>
      </div>
    </div>
  );
};

export default JobDescriptionEditPreview;
