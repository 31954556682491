/**
 * @generated SignedSource<<47558e8ec6f099586682d9af02819c35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ProfileYearsOfExperience = 'YEARS_0_1' | 'YEARS_10_20' | 'YEARS_1_2' | 'YEARS_2_5' | 'YEARS_5_10' | 'YEARS_MORE_20' | '%future added value';
export type ProfileInfoData = {
  avatarUrl?: string | null;
  bio?: string | null;
  birthDate: String;
  countryId: string;
  firstName: string;
  lastName: string;
  ownerId: string;
  primaryDutyId?: string | null;
  yearsOfExperience?: ProfileYearsOfExperience | null;
};
export type BasicInfoFormMutationsUpdateMutation$variables = {
  data: ProfileInfoData;
  id: string;
};
export type BasicInfoFormMutationsUpdateMutation$data = {
  readonly updateProfileInfo: {
    readonly avatarUrl: string | null;
    readonly bio: string | null;
    readonly birthDate: String;
    readonly country: {
      readonly ' $fragmentSpreads': FragmentRefs<'CountryFragments_info'>;
    };
    readonly firstName: string;
    readonly id: string;
    readonly lastName: string;
    readonly owner: {
      readonly id: string;
      readonly info: {
        readonly firstName: string;
      } | null;
    };
    readonly primaryDuty: {
      readonly 'id': string;
      readonly 'name': string;
      readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragmentsOneDuty'>;
    } | null;
    readonly yearsOfExperience: ProfileYearsOfExperience | null;
  };
};
export type BasicInfoFormMutationsUpdateMutation = {
  response: BasicInfoFormMutationsUpdateMutation$data;
  variables: BasicInfoFormMutationsUpdateMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'data',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v2 = [
      {
        kind: 'Variable',
        name: 'data',
        variableName: 'data',
      },
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'firstName',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'lastName',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'bio',
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'birthDate',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'avatarUrl',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'yearsOfExperience',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'BasicInfoFormMutationsUpdateMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'ProfileInfo',
          kind: 'LinkedField',
          name: 'updateProfileInfo',
          plural: false,
          selections: [
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/,
            v8 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'Duty',
              kind: 'LinkedField',
              name: 'primaryDuty',
              plural: false,
              selections: [
                v3 /*: any*/,
                v9 /*: any*/,
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'ServiceFragmentsOneDuty',
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'Profile',
              kind: 'LinkedField',
              name: 'owner',
              plural: false,
              selections: [
                v3 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'ProfileInfo',
                  kind: 'LinkedField',
                  name: 'info',
                  plural: false,
                  selections: [v4 /*: any*/],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'Country',
              kind: 'LinkedField',
              name: 'country',
              plural: false,
              selections: [
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'CountryFragments_info',
                },
              ],
              storageKey: null,
            },
            v10 /*: any*/,
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'BasicInfoFormMutationsUpdateMutation',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'ProfileInfo',
          kind: 'LinkedField',
          name: 'updateProfileInfo',
          plural: false,
          selections: [
            v3 /*: any*/,
            v4 /*: any*/,
            v5 /*: any*/,
            v6 /*: any*/,
            v7 /*: any*/,
            v8 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'Duty',
              kind: 'LinkedField',
              name: 'primaryDuty',
              plural: false,
              selections: [
                v3 /*: any*/,
                v9 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'alts',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'perma',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'Profile',
              kind: 'LinkedField',
              name: 'owner',
              plural: false,
              selections: [
                v3 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'ProfileInfo',
                  kind: 'LinkedField',
                  name: 'info',
                  plural: false,
                  selections: [v4 /*: any*/, v3 /*: any*/],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'Country',
              kind: 'LinkedField',
              name: 'country',
              plural: false,
              selections: [
                v3 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'isoCode',
                  storageKey: null,
                },
                v9 /*: any*/,
              ],
              storageKey: null,
            },
            v10 /*: any*/,
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '52edee0d03fb5ff8737983decd40a508',
      id: null,
      metadata: {},
      name: 'BasicInfoFormMutationsUpdateMutation',
      operationKind: 'mutation',
      text: 'mutation BasicInfoFormMutationsUpdateMutation(\n  $id: ID!\n  $data: ProfileInfoData!\n) {\n  updateProfileInfo(id: $id, data: $data) {\n    id\n    firstName\n    lastName\n    bio\n    birthDate\n    avatarUrl\n    primaryDuty {\n      id\n      name\n      ...ServiceFragmentsOneDuty\n    }\n    owner {\n      id\n      info {\n        firstName\n        id\n      }\n    }\n    country {\n      ...CountryFragments_info\n      id\n    }\n    yearsOfExperience\n  }\n}\n\nfragment CountryFragments_info on Country {\n  id\n  isoCode\n  name\n}\n\nfragment ServiceFragmentsOneDuty on Duty {\n  id\n  name\n  alts\n  perma\n}\n',
    },
  };
})();

(node as any).hash = '0266178adfe9adcc0ee3aede3adbc2cf';

export default node;
