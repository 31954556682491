import { useIntl } from 'react-intl';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useRecoilValue, useSetRecoilState } from 'recoil';
// routing
import { RoutePaths } from 'app/routing';
//models
import { VACANCY_DESCRIPTION_TYPE_OF_LIST, VACANCY_STATUS } from 'models/enums';
// components
import SpinnerIcon from 'icons/SpinnerIcon';
import ArrowDownTray from 'icons/ArrowDownTray';
import CustomButton from 'system/Buttons/CustomButton';
import CustomRouterLinkButton from 'system/Buttons/CustomRouterLinkButton';
import PaymentSubscriptionProLabel from 'app/ProfileEmployer/HireProduct/components/PaymentSubscriptionProLabel';
//types
import { RightSideEmptyMessageProps } from '../types';
// recoil
import { paymentSubscriptionModalAtom } from 'recoil/ContactInformation/atoms/paymentSubscriptionModalAtom';
import { RestrictedFromPublishJobAtom } from 'recoil/RestrictedFromPublishJob/atoms/RestrictedFromPublishJobAtom';

const RightSideEmptyMessage = ({ title, description, typeOfList, children, vacancy, handleSwitchStatus, isCommitSwitchVacancyLoading }: RightSideEmptyMessageProps) => {
  const intl = useIntl();
  const setOpenSubscriptionModal = useSetRecoilState(paymentSubscriptionModalAtom);
  const isRestrictedFromPublishClosedJob = useRecoilValue(RestrictedFromPublishJobAtom);

  const isOpenStatus = vacancy?.status === VACANCY_STATUS.OPEN;

  const handleClickByPublishButton = () => {
    if (isRestrictedFromPublishClosedJob) {
      window.analytics?.track('wui-job-opening-page-show-upgrade-to-pro-modal');

      setOpenSubscriptionModal({ showPaymentSubscriptionModal: true });
      return;
    }
    handleSwitchStatus(VACANCY_STATUS.OPEN)();
  };

  return (
    <div className="flex flex-col justify-center w-full items-center border border-specialGray-012 rounded-2xl min-h-[225px]">
      {children}
      <span className="text-darkBlue font-medium mt-4 mb-2">{intl.formatMessage(title)}</span>
      <span className="text-specialGray-075 text-sm">{intl.formatMessage(description)}</span>
      {typeOfList === VACANCY_DESCRIPTION_TYPE_OF_LIST.INVITED && isOpenStatus && (
        <CustomRouterLinkButton classNameStyle="text-blue mt-3.5" label={{ id: 'hire_job_description_invite_users_label' }} to={RoutePaths.JobInviteCrew}>
          <PlusIcon className="h-[21px] mr-3" />
        </CustomRouterLinkButton>
      )}
      {!isOpenStatus && (
        <CustomButton
          disabled={isCommitSwitchVacancyLoading}
          onClick={handleClickByPublishButton}
          classNameStyle={`text-sm font-medium mt-3.5 gap-x-2 ${isRestrictedFromPublishClosedJob ? 'text-specialPurple' : 'text-blue'}`}
          label={{ id: 'publish' }}
        >
          {isCommitSwitchVacancyLoading ? (
            <SpinnerIcon spinnerColor="#2A6FF5" additionalClasses="w-[13px] h-[13px]" />
          ) : (
            <>
              <ArrowDownTray additionalClasses="w-4 h-4 rotate-180" />
              {isRestrictedFromPublishClosedJob ? <PaymentSubscriptionProLabel className="order-last" /> : null}
            </>
          )}
        </CustomButton>
      )}
    </div>
  );
};

export default RightSideEmptyMessage;
