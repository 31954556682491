// packages
import { atom } from 'recoil';
// types
import { dashboardPaginationType } from '../../types';

export const dashboardOpenPaginationAtom = atom<dashboardPaginationType>({
  key: 'dashboardOpenTabPaginationAtom',
  default: {
    numberOfFetch: 10,
    isLoading: false,
    hasNextPage: true,
  },
});

export const dashboardShowAllPaginationAtom = atom<dashboardPaginationType>({
  key: 'dashboardClosePaginationAtom',
  default: {
    numberOfFetch: 10,
    isLoading: false,
    hasNextPage: true,
  },
});
