// packages
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { useMutation } from 'react-relay';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useFormik, FormikProvider, Form } from 'formik';
// routing
import { RoutePaths } from 'app/routing';
// components
import { Button } from 'system/Button';
import { Alert } from 'system/Alert/Alert';
import AlertForError from 'system/Alert/AlertForError';
import SelectInputGroup from 'system/SelectInputGroup';
import { InputGroup } from 'system/InputGroups/InputGroup';
import { TextareaInputGroup } from 'system/TextareaInputGroup';
import { AvatarUpload } from 'app/Avatars/ProfileAvatar/Upload/AvatarUpload';
import CommercialAvatar from 'app/Avatars/CommercialAvatar/CommercialAvatar';
import SelectCountryInputGroup from 'system/SelectCountryInputGroup/SelectCountryInputGroup';
// hooks
import { useAuth } from 'authentication';
import { useGetOrganizationSizeWithLabel } from 'hooks/useGetOrganizationSize';
import { BasicEmployerInfoFormInitialValues } from 'formHelpers/initialValuesOfForms';
// models
import { ICountry } from 'models/ICountry';
import { BasicEmployerInfoFormData } from 'models/modelsOfForms';
import { MOCK_ORGANIZATION_SIZE_WITH_LABEL } from 'mocks/mockData';
import { BasicEmployerInfoFormProps } from 'models/modelsOfComponents';
import { BasicEmployerInfoFormValidateSchema } from 'formHelpers/validationsOfForms';
// schemas
import { CREATE_ORGANIZATION } from 'schemas/profile/ProfileMutations';
import { UPDATE_ORGANIZATION } from 'schemas/profile/ProfileMutations';
// generated
import {
  ProfileMutationsCreateOrganizationMutation,
  ProfileMutationsCreateOrganizationMutation$data,
} from 'schemas/profile/__generated__/ProfileMutationsCreateOrganizationMutation.graphql';
import { ProfileMutationsUpdateOrganizationMutation } from 'schemas/profile/__generated__/ProfileMutationsUpdateOrganizationMutation.graphql';
// recoil
import { personalAccountSelector } from 'recoil/Profile/personalAccount/selectors/personalAccountSelector';
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';

export const BasicInfoForm = ({ profileOrganization, defaultError, onCloseModal = () => {}, reFetch }: BasicEmployerInfoFormProps) => {
  const navigate = useNavigate();
  const { identity } = useAuth();

  const [error, setError] = useState<Error | undefined>(defaultError);
  const [commitCreate] = useMutation<ProfileMutationsCreateOrganizationMutation>(CREATE_ORGANIZATION);
  const [commitUpdate] = useMutation<ProfileMutationsUpdateOrganizationMutation>(UPDATE_ORGANIZATION);

  const [url, setUrl] = useState<string>('');
  const [showSizeWarning, setShowSizeWarning] = useState<boolean>(false);
  const [uploadedSize, setUploadedSize] = useState<string>('');

  const setCurrentOrganisation = useSetRecoilState(currentOrganisationSelector);
  const setIsPersonalAccount = useSetRecoilState(personalAccountSelector);

  const { selectedOrganizationSize, setSelectedOrganizationSize } = useGetOrganizationSizeWithLabel(profileOrganization?.organization?.size || null);

  const formik = useFormik<BasicEmployerInfoFormData>({
    initialValues: BasicEmployerInfoFormInitialValues(profileOrganization),
    validationSchema: BasicEmployerInfoFormValidateSchema,
    onSubmit,
  });

  const onCompleted =
    (isCreated: boolean = false) =>
    (resp: unknown) => {
      if (reFetch) {
        reFetch({}, { fetchPolicy: 'network-only' });
      }

      setError(undefined);
      formik.setSubmitting(false);
      onCloseModal();
      if (isCreated) {
        navigate(`${RoutePaths.ProfileOrganizationBase}/${(resp as ProfileMutationsCreateOrganizationMutation$data).createOrganization.id}`);
        setCurrentOrganisation({
          isSelected: true,
          organisationId: (resp as ProfileMutationsCreateOrganizationMutation$data).createOrganization.id,
          profileId: `${identity?.profileId}`,
        });
        setIsPersonalAccount({ personalAccountIsSelected: false });
      }
    };

  function onSubmit(data: BasicEmployerInfoFormData) {
    const onError = (error: Error) => {
      setError(error);
      formik.setSubmitting(false);
    };

    if (isEmpty(profileOrganization)) {
      window.analytics?.track('wui-employer-user-create-organization');

      commitCreate({
        variables: {
          data: {
            name: data.name,
            description: data.description,
            avatarUrl: url || profileOrganization?.organization?.avatarUrl,
            size: data.size,
            countryId: data.countryId,
            city: data.city,
          },
        },
        onCompleted: onCompleted(true),
        onError,
      });
    } else {
      if (profileOrganization) {
        window.analytics?.track('wui-employer-user-update-organization');

        commitUpdate({
          variables: {
            id: profileOrganization?.organization?.id || '',
            data: {
              name: data.name,
              description: data.description,
              avatarUrl: url || profileOrganization?.organization?.avatarUrl,
              size: data.size,
              countryId: data.countryId,
              city: data.city,
            },
          },
          onCompleted: onCompleted(),
          onError,
        });
      }
    }
  }

  return (
    <FormikProvider value={formik}>
      <Form className="space-y-6 text-darkBlue">
        {error && <AlertForError error={error} heading={{ id: 'profile_failedSave' }} />}
        <section className="flex sm:items-end flex-col sm:flex-row">
          <div className="flex mx-auto sm:mx-0">
            <CommercialAvatar avatarUrl={profileOrganization?.organization?.avatarUrl} size="w-40 h-40" updateAvatar={url} />
          </div>

          <div className="sm:ml-5 sm:mr-5 mx-auto mt-5 sm:mt-0">
            <AvatarUpload
              fileSize={7864320}
              onUrlReady={(url: string) => {
                setUrl(url);
              }}
              warningSize={(warningSizeError: boolean, uploadedSize: string) => {
                setShowSizeWarning(warningSizeError);
                setUploadedSize(uploadedSize);
              }}
              buttonText={{ id: 'avatar_employer_upload_label' }}
            />
          </div>
        </section>
        {showSizeWarning && <Alert heading={{ id: 'warning_head_data' }} message={{ id: 'warning_incorrect__size', description: { uploadedSize } }} type="warning" />}

        <div className="flex basis-1">
          <div className="basis-3/5 2xl:basis-2/3">
            <InputGroup name="name" label={{ id: 'employer_org_label' }} placeholderText={{ id: 'employer_org_placeholder' }} />
          </div>
          <div className="basis-2/5 2xl:basis-1/3 ml-4">
            <SelectInputGroup
              name="size"
              options={MOCK_ORGANIZATION_SIZE_WITH_LABEL}
              label={{ id: 'employer_org_size_label' }}
              onChangeSelect={() => {}}
              defaultSelectedElement={selectedOrganizationSize}
              setSelectedItem={setSelectedOrganizationSize}
              selectedItem={selectedOrganizationSize}
              autoComplete="chrome-off"
              placeholder={{ id: 'employer_org_size_label' }}
            />
          </div>
        </div>
        <div className="flex basis-1">
          <div className="basis-3/5 2xl:basis-2/3">
            <SelectCountryInputGroup
              name="countryId"
              label={{ id: 'profile_operating_country' }}
              placeholder={{ id: 'profile_operating_country' }}
              initialValue={profileOrganization?.organization.country as ICountry}
            />
          </div>
          <div className="basis-2/5 2xl:basis-1/3 ml-4">
            <InputGroup name="city" label={{ id: 'profile_city' }} placeholderText={{ id: 'city_name' }} />
          </div>
        </div>
        <TextareaInputGroup
          name="description"
          label={{ id: 'employer_org_bio_label' }}
          placeholderText={{ id: 'placeholder_form_type' }}
          description={{ id: 'bio_organization_description' }}
          maxCharCount={500}
          optional={true}
          autoHeight={58}
          breakLinesLimit
          helpText={{ id: 'profile_organization_bio_help_description' }}
        />

        <div className="flex sm:justify-end justify-center">
          <Button
            type="button"
            className="inline-flex leading-4 items-center justify-center px-6 py-3 mr-5 border border-gray-300 shadow-sm text-sm font-medium rounded-lg text-specialGray-075 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
            buttonType="secondary"
            label={{ id: 'cancel' }}
            onClick={onCloseModal}
          />
          <Button
            type="submit"
            label={{ id: 'employer_save_organization_information' }}
            disabled={formik.isSubmitting}
            className="items-center px-6 py-3 border border-blue-600 shadow-sm text-sm leading-4 font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 hover:border-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          />
        </div>
      </Form>
    </FormikProvider>
  );
};
