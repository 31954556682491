// packages
import { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
// components
import ProfileCrew from 'app/ProfileCrew/ProfileCrew';
import ProfileShareFull from 'app/ProfileShareFull/ProfileShareFull';
import ProfileShareFullSkeleton from 'system/skeletons/profile/ProfileShareFullSkeleton';
import ProfileCrewSkeleton from 'system/skeletons/profile/ProfileCrewSkeleton/ProfileCrewSkeleton';
// routes
import { RoutePaths, RoutesPathNames } from 'app/routing';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';

const CrewVisitWrapper = () => {
  const params = useParams();
  const profileId = params[RoutesPathNames.id];
  const [searchParams] = useSearchParams();
  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);
  const isNotSharedAccountByLink: boolean = !searchParams.get('t');

  if (getCurrentOrganisation.isSelected && isNotSharedAccountByLink) {
    return <Navigate to={`${RoutePaths.ProfileSingleCrewPageEmployerView}/${profileId}`} replace />;
  }

  return (
    <>
      {!isNotSharedAccountByLink ? (
        <Suspense fallback={<ProfileShareFullSkeleton />}>
          <ProfileShareFull />
        </Suspense>
      ) : (
        <Suspense fallback={<ProfileCrewSkeleton />}>
          <ProfileCrew />
        </Suspense>
      )}
    </>
  );
};

export default CrewVisitWrapper;
