import { useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { Suspense, useRef, useState } from 'react';
// components
import { SearchInputIcon } from 'icons/SearchInputIcon';
import EmployerOrganizationAddMemberList from './EmployerOrganizationAddMemberList';
// skeleton
import AddOrgMembersSkeleton from 'system/skeletons/organizationMembers/AddOrgMembersSkeleton';

const EmployerOrganizationAddMember = ({ defaultStorybookShowAddModal = '' }: { defaultStorybookShowAddModal?: string }) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchProfileNameValue, setSearchProfileNameValue] = useState<string>(defaultStorybookShowAddModal);

  const onHandleChange = debounce(() => {
    setSearchProfileNameValue(inputRef.current?.value || '');
  }, 500);

  return (
    <section className="flex flex-col w-full gap-y-2">
      <div className="flex relative">
        <SearchInputIcon className="w-5 h-5 text-specialGray-05 absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 left-1 ml-4" />
        <input
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block text-xs sm:text-base border-specialGray-012 placeholder:text-specialGray-05 text-darkBlue rounded-xl w-full pl-10 pt-2 bg-specialGray-002"
          onChange={onHandleChange}
          placeholder={intl.formatMessage({ id: 'search_input_placeholder_search_member' })}
          type="text"
          ref={inputRef}
        />
      </div>

      <div className="flex w-full flex-col overflow-y-scroll border border-specialGray-012 rounded-xl [&>#empty-search-member]:border-b-0 mt-6">
        <Suspense fallback={<AddOrgMembersSkeleton />}>
          <EmployerOrganizationAddMemberList searchValue={searchProfileNameValue} />
        </Suspense>
      </div>
    </section>
  );
};

export default EmployerOrganizationAddMember;
