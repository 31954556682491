// packages
import { useNavigate } from 'react-router-dom';
// routes
import { RoutePaths } from 'app/routing';
// components
import { ForgotConfirmForm } from 'auth/ForgotConfirmForm';

const ForgotForgotConfirm = () => {
  const navigate = useNavigate();

  return (
    <ForgotConfirmForm
      onPasswordReset={() => {
        navigate(RoutePaths.ProfileBase);
      }}
    />
  );
};

export default ForgotForgotConfirm;
