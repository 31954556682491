import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSkeletonContext } from 'Context/StorybookContext';
import { useBreakpoints } from 'hooks/useBreakpoints';

const EducationListItemSkeleton: React.FC = () => {
  const { sm } = useBreakpoints();
  const { isSelfProfile } = useSkeletonContext();

  return (
    <>
      <div className="ml-0 md:ml-5 w-auto md:w-30 overflow-hidden truncate flex items-center justify-between md:justify-start">
        <span className="flex md:hidden ">
          <Skeleton width={50} />
        </span>
        <span className="md:contents flex">
          <Skeleton width={30} height={30} className="mr-3" />
          <Skeleton width={100} />
        </span>
      </div>
      <div className="md:w-30 w-auto text-specialGray-1 text-sm flex justify-between md:justify-start mt-1 md:mt-0">
        <span className="flex md:hidden ">
          <Skeleton width={50} />
        </span>
        <span className="md:contents flex">
          <Skeleton width={sm ? 100 : 150} />
        </span>
      </div>
      <div className="md:w-15 w-auto text-specialGray-05 text-sm flex justify-between md:justify-start mt-1 md:mt-0">
        <span className="flex md:hidden ">
          <Skeleton width={75} />
        </span>
        <span className="md:contents flex">
          <Skeleton width={60} />
        </span>
      </div>
      <div className="md:w-15 w-auto text-specialGray-05 text-sm flex justify-between md:justify-start mt-1 md:mt-0">
        <span className="flex md:hidden ">
          <Skeleton width={60} />
        </span>
        <span className="md:contents flex">
          <Skeleton width={60} />
        </span>
      </div>
      {isSelfProfile && (
        <div className="flex flex-col sm:flex-row sm:justify-around items-center sm:items-start w-auto sm:pl-4">
          <Skeleton width={20} height={20} className="sm:mr-6" />
          <Skeleton width={20} height={20} />
        </div>
      )}
    </>
  );
};

export default EducationListItemSkeleton;
