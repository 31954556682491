import { RecordProxy, RecordSourceSelectorProxy } from 'relay-runtime';
import { CertificateMutationsRemoveMutation$data } from 'schemas/certificates/__generated__/CertificateMutationsRemoveMutation.graphql';
import { CertificateMutationsAddMutation$data } from 'schemas/certificates/__generated__/CertificateMutationsAddMutation.graphql';

export const certificateRemove = (profileId: string, certId: string) => (store: RecordSourceSelectorProxy<CertificateMutationsRemoveMutation$data>) => {
  const profileRecord = store?.getRoot().getLinkedRecord(`node(id:"${profileId}")`);
  const certificateInfoRecords = profileRecord?.getLinkedRecords('certificateInfos');
  const newCertificateInfoRecords = certificateInfoRecords?.filter(record => record?.getValue('id') !== certId);
  profileRecord?.setLinkedRecords(newCertificateInfoRecords, 'certificateInfos');
};

export const certificateInfoCreate = (profileId: string) => (store: RecordSourceSelectorProxy<CertificateMutationsAddMutation$data>) => {
  const profileRecord = store?.getRoot().getLinkedRecord(`node(id:"${profileId}")`);
  const certificateInfoRecords = profileRecord?.getLinkedRecords('certificateInfos');
  const payload = store?.getRootField('createCertificateInfo').getLinkedRecord('node');
  const newCertificateInfoRecords = [...(certificateInfoRecords as Array<RecordProxy>), payload];
  profileRecord?.setLinkedRecords(newCertificateInfoRecords, 'certificateInfos');
};
