const ProfileEmptyIcon = ({ defaultClasses = 'w-14 h-[50px]' }: { defaultClasses?: string }) => {
  return (
    <svg className={defaultClasses} viewBox="0 0 56 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25.5065" cy="15.0109" r="9.85469" stroke="#082051" strokeOpacity="0.3" strokeWidth="3.09375" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.87503 38.5721C9.87239 37.8793 10.0273 37.195 10.3281 36.5709C11.2721 34.6831 13.934 33.6825 16.1428 33.2294C17.7358 32.8895 19.3508 32.6623 20.9757 32.5498C23.9842 32.2855 27.0101 32.2855 30.0186 32.5498C31.6435 32.6636 33.2584 32.8907 34.8516 33.2294C37.0604 33.6825 39.7223 34.5887 40.6662 36.5709C41.2711 37.8431 41.2711 39.32 40.6662 40.5921C39.7223 42.5744 37.0604 43.4805 34.8516 43.9148C33.2604 44.2688 31.6449 44.5023 30.0186 44.6133C27.5699 44.8209 25.1098 44.8587 22.6559 44.7265C22.0896 44.7265 21.5421 44.7265 20.9757 44.6133C19.3556 44.5036 17.7462 44.2701 16.1616 43.9148C13.934 43.4805 11.2909 42.5744 10.3281 40.5921C10.0289 39.9608 9.87408 39.2707 9.87503 38.5721Z"
        stroke="#082051"
        strokeOpacity="0.3"
        strokeWidth="3.09375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.4337 14.4796C53.7429 15.5454 54.4964 16.7564 54.6941 18.1126C54.9047 19.4792 54.5033 20.785 53.4897 22.03C52.4343 23.3263 51.2295 23.9674 49.8751 23.9532C48.7831 23.9418 47.6891 23.575 46.5932 22.8527C46.1338 22.55 45.5136 22.5912 45.1452 22.9997C44.7767 23.4082 44.1527 23.4562 43.7261 23.1089L43.5435 22.9602C43.0074 22.5237 42.9145 21.7405 43.3337 21.1907L44.4494 19.7276C44.7211 19.3712 45.2339 19.3104 45.5814 19.5933C46.8264 20.6069 47.9121 21.2133 48.8383 21.4125C49.7774 21.6221 50.6284 21.2585 51.3912 20.3215C51.945 19.6413 52.1797 18.9467 52.0953 18.2377C52.0237 17.5392 51.6414 16.9078 50.9483 16.3435C50.2552 15.7792 49.5582 15.5213 48.8573 15.5696C48.5585 15.5901 48.2741 15.667 48.004 15.8C47.3459 16.1242 46.5297 16.2677 45.9609 15.8046C45.392 15.3414 45.2951 14.4851 45.8753 14.0362C46.2627 13.7364 46.6802 13.5008 47.1278 13.3292C47.9654 13.0081 48.841 12.9419 49.7544 13.1306C50.6807 13.3298 51.5738 13.7795 52.4337 14.4796ZM40.0418 27.1842C39.6439 26.8603 39.4199 26.4539 39.3698 25.9649C39.3197 25.4759 39.4566 25.0325 39.7805 24.6346C40.1045 24.2367 40.5109 24.0127 40.9999 23.9626C41.4888 23.9125 41.9323 24.0494 42.3302 24.3734C42.7152 24.6868 42.9328 25.0881 42.9829 25.577C43.033 26.066 42.8961 26.5094 42.5721 26.9073C42.2482 27.3052 41.8418 27.5292 41.3528 27.5793C40.8638 27.6294 40.4268 27.4977 40.0418 27.1842Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <path
        d="M48.8625 21.3042L48.8617 21.304C47.9605 21.1102 46.8911 20.5165 45.6515 19.5073C45.2553 19.1847 44.6709 19.254 44.3611 19.6603L43.2455 21.1234C42.7899 21.7208 42.8908 22.572 43.4735 23.0463L43.6561 23.1949C44.1285 23.5796 44.8196 23.5264 45.2276 23.074C45.5547 22.7114 46.1125 22.6688 46.5321 22.9454C47.6422 23.677 48.7567 24.0525 49.8739 24.0642C51.2699 24.0788 52.5045 23.4158 53.5757 22.1C54.6055 20.8351 55.0199 19.4987 54.8039 18.0961C54.6011 16.7066 53.8291 15.4725 52.5038 14.3935C51.6326 13.6843 50.7238 13.2256 49.7777 13.0222L49.7777 13.0222L49.7769 13.022C48.8435 12.8291 47.9463 12.8965 47.0881 13.2256C46.6301 13.4011 46.2032 13.6422 45.8074 13.9484C45.1558 14.4526 45.2821 15.3951 45.8908 15.8906C46.5128 16.397 47.3852 16.2285 48.053 15.8996C48.3103 15.7728 48.5806 15.6998 48.8649 15.6803C49.5307 15.6344 50.2008 15.8781 50.8782 16.4296C51.555 16.9806 51.9171 17.5872 51.9849 18.249L51.9849 18.249L51.9851 18.2508C52.0651 18.9234 51.8446 19.5888 51.3051 20.2515C50.5607 21.1658 49.7494 21.5022 48.8625 21.3042ZM39.2594 25.9762C39.3126 26.4958 39.5521 26.9287 39.9717 27.2703C40.3793 27.6021 40.8455 27.7428 41.3641 27.6897C41.8838 27.6364 42.3166 27.397 42.6582 26.9774C42.9998 26.5578 43.1465 26.0854 43.0933 25.5657C43.0401 25.0471 42.8078 24.6192 42.4002 24.2873C41.9806 23.9457 41.5082 23.799 40.9886 23.8522C40.4689 23.9055 40.0361 24.1449 39.6945 24.5645C39.3529 24.9841 39.2062 25.4565 39.2594 25.9762Z"
        stroke="#082051"
        strokeOpacity="0.3"
        strokeWidth="0.221944"
      />
      <path
        d="M2.40433 17.1893C3.4471 16.2255 4.54487 15.7416 5.69765 15.7377C6.86065 15.7244 7.90044 16.2135 8.81703 17.2052C9.77141 18.2377 10.1624 19.317 9.99004 20.4432C9.85106 21.3512 9.41588 22.2187 8.68449 23.0457C8.37795 23.3923 8.33869 23.9136 8.63519 24.2689C8.93169 24.6242 8.8977 25.1495 8.55789 25.4636L8.41244 25.598C7.98539 25.9927 7.32213 25.9772 6.91402 25.5629L5.82787 24.4604C5.56331 24.1919 5.57347 23.7576 5.85031 23.5018C6.84196 22.5852 7.47564 21.753 7.75134 21.0053C8.03727 20.2481 7.83533 19.4964 7.14553 18.7501C6.64472 18.2083 6.09411 17.9305 5.49371 17.9167C4.90354 17.8935 4.33242 18.137 3.78037 18.6473C3.22831 19.1576 2.93084 19.7074 2.88795 20.2968C2.86967 20.548 2.89992 20.794 2.9787 21.0347C3.1706 21.6211 3.19335 22.3178 2.74025 22.7366C2.28715 23.1554 1.56255 23.1346 1.25752 22.5982C1.05383 22.2401 0.907077 21.8645 0.817262 21.4714C0.649172 20.7358 0.697866 19.9988 0.963346 19.2606C1.23905 18.5128 1.71938 17.8224 2.40433 17.1893ZM11.5148 29.0148C11.1978 29.3078 10.8328 29.4461 10.4197 29.4299C10.0066 29.4136 9.65355 29.247 9.36062 28.9301C9.06769 28.6132 8.92935 28.2482 8.94561 27.835C8.96186 27.4219 9.12844 27.0689 9.44536 26.776C9.75206 26.4925 10.112 26.3589 10.5251 26.3751C10.9382 26.3914 11.2912 26.558 11.5842 26.8749C11.8771 27.1918 12.0154 27.5568 11.9992 27.9699C11.9829 28.3831 11.8215 28.7314 11.5148 29.0148Z"
        fill="#082051"
        fillOpacity="0.3"
      />
      <path
        d="M7.66401 20.9723L7.66376 20.973C7.39554 21.7004 6.77434 22.5206 5.78695 23.4332C5.4714 23.7249 5.45981 24.2198 5.76138 24.5259L6.84753 25.6284C7.29101 26.0786 8.01174 26.0955 8.4758 25.6666L8.62125 25.5321C8.99757 25.1843 9.03521 24.6025 8.70685 24.2091C8.44365 23.8937 8.47438 23.4242 8.75441 23.1075C9.49525 22.2698 9.94011 21.3863 10.0823 20.4573C10.26 19.2965 9.85426 18.1898 8.88557 17.1418C7.95434 16.1343 6.89054 15.6308 5.69696 15.6444C4.51582 15.6485 3.39663 16.145 2.34097 17.1208C1.64704 17.7622 1.15737 18.4645 0.875767 19.2283L0.875764 19.2283L0.875509 19.229C0.604242 19.9833 0.554035 20.7384 0.726265 21.4922C0.818155 21.8943 0.968286 22.2785 1.17639 22.6444C1.51895 23.2467 2.31876 23.2533 2.80361 22.8052C3.29901 22.3473 3.26217 21.6008 3.06741 21.0057C2.99239 20.7764 2.96365 20.5426 2.98105 20.3036C3.02179 19.7437 3.30414 19.2146 3.84372 18.7158C4.38281 18.2176 4.93089 17.988 5.49004 18.01L5.49004 18.01L5.49157 18.01C6.06112 18.0231 6.58914 18.2857 7.07698 18.8135C7.75012 19.5417 7.93405 20.2573 7.66401 20.9723ZM10.416 29.5231C10.8551 29.5404 11.2439 29.3923 11.5781 29.0834C11.9028 28.7833 12.0752 28.4118 12.0925 27.9736C12.1097 27.5346 11.9616 27.1457 11.6527 26.8115C11.3438 26.4773 10.9678 26.2991 10.5288 26.2819C10.0906 26.2646 9.70667 26.4073 9.38201 26.7074C9.04779 27.0163 8.86961 27.3923 8.85234 27.8314C8.83506 28.2704 8.98316 28.6593 9.29207 28.9935C9.60099 29.3277 9.97698 29.5059 10.416 29.5231Z"
        stroke="#082051"
        strokeOpacity="0.3"
        strokeWidth="0.186685"
      />
    </svg>
  );
};

export default ProfileEmptyIcon;
