// packages
import { isEmpty } from 'lodash';
//hooks
import { useHoverById } from 'hooks/useHover';
import { useGetSelectedFilterElements } from 'hooks/searchFilter/useGetSelectedFilterElements';
//components
import FilterIcon from 'icons/FilterIcon';
import { FILTER_TYPE } from 'models/enums';
import { FilterSelectedItem } from 'system/FilterSlideComponents/components/FilterItems/FilterSelectedItem';
// models
import { FilterDynamicProps } from 'models/commonTypes';

const SelectedCommonFilterElements = ({ allFiltersBySelectValue, margins = 'mt-6 mb-4' }: { allFiltersBySelectValue: FilterDynamicProps; margins?: string }) => {
  const { selectedId, handleMouseEnter, handleMouseOut } = useHoverById();

  const { selectedItems, handleDeleteSelectedItem } = useGetSelectedFilterElements(allFiltersBySelectValue);

  return (
    <>
      {!isEmpty(selectedItems) && (
        <div className={`flex w-full ${margins}`}>
          <FilterIcon className="mr-4 mt-3" />
          <div className="flex w-full sm:flex-wrap py-1 items-center overflow-x-auto [&::-webkit-scrollbar]:hidden">
            {selectedItems.map(({ name, id, value, key }) => {
              return (
                <div className="flex" onMouseEnter={handleMouseEnter(id)} onMouseLeave={handleMouseOut} key={key}>
                  <FilterSelectedItem
                    isDate={id === FILTER_TYPE.STARTING_DATE}
                    className="mr-2 mt-1"
                    isHovered={id === selectedId}
                    name={name}
                    value={value}
                    onClickEvent={handleDeleteSelectedItem(id)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default SelectedCommonFilterElements;
