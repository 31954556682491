/**
 * @generated SignedSource<<f5b9c2fd0ca5ba3978e0606f4eb40d69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CrewPoolKind = 'REGULAR' | '%future added value';
import { FragmentRefs } from 'relay-runtime';
export type ProfileFragmentsEmployerCrewPools$data = {
  readonly 'CreatedAt': String;
  readonly 'Organization': {
    readonly id: string;
  };
  readonly 'UpdatedAt': String;
  readonly 'id': string;
  readonly 'kind': CrewPoolKind;
  readonly 'name': string;
  readonly ' $fragmentType': 'ProfileFragmentsEmployerCrewPools';
};
export type ProfileFragmentsEmployerCrewPools$key = {
  readonly ' $data'?: ProfileFragmentsEmployerCrewPools$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsEmployerCrewPools'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'id',
    storageKey: null,
  };
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'ProfileFragmentsEmployerCrewPools',
    selections: [
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'name',
        storageKey: null,
      },
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'kind',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Organization',
        kind: 'LinkedField',
        name: 'Organization',
        plural: false,
        selections: [v0 /*: any*/],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'CreatedAt',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'UpdatedAt',
        storageKey: null,
      },
    ],
    type: 'CrewPool',
    abstractKey: null,
  };
})();

(node as any).hash = '5aef78e26bb623e97abd526f26f54328';

export default node;
