import { Fragment, KeyboardEvent, MouseEvent } from 'react';
import { useIntl } from 'react-intl';
// models
import { ModalProps } from 'models/modelsOfComponents';
//component
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
//error boundary
import ErrorBoundaryForModal from 'system/ErrorBoundaries/ErrorBoundaryForModal';

export const Modal = ({
  title,
  titleVariables = {},
  description,
  descriptionValue,
  show = false,
  Icon,
  onClose = () => {},
  tinyModal,
  wideModal,
  bigModal,
  additionalClasses,
  children,
  childrenClass = 'mt-5',
  zIndex = 'z-50',
  storybookError,
  withoutCloseWindow = false,
}: ModalProps) => {
  const intl = useIntl();

  const escPressed = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Escape') {
      onClose();
    }
  };

  let dialogClasses = 'flex flex-col sm:inline-block bg-white rounded-xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6 sm:h-auto';

  if (tinyModal) {
    dialogClasses += ' w-full sm:w-8/12 md:w-6/12 lg:w-5/12 xl:w-4/12 ';
  } else if (wideModal) {
    dialogClasses += ' w-full md:w-10/12 lg:w-8/12 ';
  } else if (bigModal) {
    dialogClasses += ' sm:w-11/12 h-full sm:h-4/5 ';
  } else {
    dialogClasses += ' w-full lg:w-8/12 xl:w-1/2 ';
  }

  dialogClasses += additionalClasses ?? '';

  const handleCloseModal = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog onClose={onClose} as="div" className={`fixed ${zIndex} inset-0 overflow-y-scroll`}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className={`flex items-end justify-center sm:items-center text-center lg:p-0 ${bigModal ? 'h-full' : 'min-h-full p-4'}`} onKeyDown={escPressed}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={dialogClasses}>
                {withoutCloseWindow ? null : (
                  <div
                    className="bg-specialGray-012 rounded-md text-specialGray-05 hover:text-specialGray-075 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 absolute top-4 right-4 cursor-pointer transform overflow-hidden transition-all"
                    onMouseUp={handleCloseModal}
                  >
                    <span className="sr-only">{intl.formatMessage({ id: 'close' })}</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                )}

                <div className="flex items-start">
                  {/* icon */}
                  {Icon ? (
                    <div className="mr-3">
                      <Icon />
                    </div>
                  ) : null}

                  {/* title */}
                  <div className="mt-3 text-specialGray-1 sm:mt-0">
                    {title && (
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium">
                        {intl.formatMessage(title, titleVariables)}
                      </Dialog.Title>
                    )}

                    {/* Description */}
                    {description && (
                      <Dialog.Description as="p" className="font-normal text-specialGray-075 text-sm leading-5">
                        {intl.formatMessage(description, descriptionValue)}
                      </Dialog.Description>
                    )}
                  </div>
                </div>
                <ErrorBoundaryForModal storybookError={storybookError}>
                  {/* children */}
                  <div className={childrenClass}>{children}</div>
                </ErrorBoundaryForModal>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
