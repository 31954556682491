const FolderWithCheckMark = ({ className = 'w-6 h-6 text-blue' }: { className?: string }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect width="24" height="24" rx="12" fill="currentColor" fillOpacity="0.12" />
      <mask id="mask0_14275_110628" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18">
        <rect x="3.83545" y="3.83594" width="16.3291" height="16.3291" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_14275_110628)">
        <path
          d="M6.55705 17.4408C6.18284 17.4408 5.86249 17.3075 5.59601 17.041C5.32953 16.7746 5.19629 16.4542 5.19629 16.08V7.91545C5.19629 7.54124 5.32953 7.22089 5.59601 6.95441C5.86249 6.68793 6.18284 6.55469 6.55705 6.55469H10.078C10.2594 6.55469 10.4324 6.58871 10.5968 6.65674C10.7612 6.72478 10.9058 6.82117 11.0305 6.94591L12.0001 7.91545H17.4431C17.8173 7.91545 18.1377 8.04869 18.4042 8.31517C18.6706 8.58165 18.8039 8.902 18.8039 9.2762V16.08C18.8039 16.4542 18.6706 16.7746 18.4042 17.041C18.1377 17.3075 17.8173 17.4408 17.4431 17.4408H6.55705Z"
          fill="currentColor"
        />
      </g>
      <mask id="mask1_14275_110628" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="9" y="8" width="10" height="10">
        <rect x="9.40234" y="8.28906" width="8.90678" height="8.90678" fill="#E5E5E5" />
      </mask>
      <g mask="url(#mask1_14275_110628)">
        <path
          d="M13.2028 13.1713L12.3028 12.2868C12.189 12.1731 12.0468 12.1162 11.8761 12.1162C11.7054 12.1162 11.558 12.1782 11.4339 12.3024C11.3201 12.4162 11.2632 12.561 11.2632 12.7368C11.2632 12.9127 11.3201 13.0575 11.4339 13.1713L12.7683 14.5057C12.8924 14.6299 13.0373 14.6919 13.2028 14.6919C13.3683 14.6919 13.5131 14.6299 13.6372 14.5057L16.2751 11.8679C16.3992 11.7438 16.4587 11.5989 16.4535 11.4334C16.4484 11.2679 16.3889 11.1231 16.2751 10.999C16.151 10.8748 16.0035 10.8102 15.8329 10.805C15.6622 10.7998 15.5148 10.8593 15.3906 10.9834L13.2028 13.1713Z"
          fill="#CEDEFC"
        />
      </g>
    </svg>
  );
};

export default FolderWithCheckMark;
