import { RenderAttachedDocumentProps } from 'models/modelsOfComponents';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

const RenderAttachedDocument = ({ src, alt, onClick }: RenderAttachedDocumentProps) => {
  return (
    <div className="relative cursor-pointer w-28 h-36 last:mr-0 rounded-2xl border-grayIcon border border-solid" onClick={onClick} data-tag={src}>
      <span className="absolute inset-0 z-10 bg-white text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-90 duration-300 rounded-2xl">
        <span className="tracking-wider">
          <ArrowTopRightOnSquareIcon className="h-5 w-5" />
        </span>
      </span>
      <div className="flex flex-wrap content-center w-full h-full">
        <img key={src} src={src} alt={alt} className="w-full h-full object-cover rounded-2xl" />
      </div>
    </div>
  );
};

export default RenderAttachedDocument;
