import { Suspense, useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
// components
import { Modal } from 'system/Modal';
import ProfileContactContent from './components/ProfileContactContent';
import PaymentSubscriptionModalContent from 'app/ProfileEmployer/HireProduct/components/PaymentSubscriptionModalContent/PaymentSubscriptionModalContent';
// recoil
import { contactInformationAtom } from 'recoil/ContactInformation/atoms/contactInformationAtom';
import { paymentSubscriptionModalAtom } from 'recoil/ContactInformation/atoms/paymentSubscriptionModalAtom';

const ProfileContactInfoCommon = () => {
  const [getShowPaymentSubscriptionModal, setShowPaymentSubscriptionModal] = useRecoilState(paymentSubscriptionModalAtom);
  const getContactInformation = useRecoilValue(contactInformationAtom);

  const onHandleClosePaymentSubscription = useCallback(() => {
    setShowPaymentSubscriptionModal({
      showPaymentSubscriptionModal: false,
    });
  }, [setShowPaymentSubscriptionModal]);

  return (
    <Suspense>
      {getContactInformation.showContactInformation ? <ProfileContactContent /> : null}
      <Modal onClose={onHandleClosePaymentSubscription} show={getShowPaymentSubscriptionModal.showPaymentSubscriptionModal}>
        <PaymentSubscriptionModalContent onClose={onHandleClosePaymentSubscription} />
      </Modal>
    </Suspense>
  );
};

export default ProfileContactInfoCommon;
