/**
 * @generated SignedSource<<4398104699d04049654ca09e554a092b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ResumeQueriesBackgroundTaskQuery$variables = {
  skip: boolean;
  uuid: string;
};
export type ResumeQueriesBackgroundTaskQuery$data = {
  readonly backgroundTask?: {
    readonly response: string | null;
    readonly status: string;
  } | null;
};
export type ResumeQueriesBackgroundTaskQuery = {
  response: ResumeQueriesBackgroundTaskQuery$data;
  variables: ResumeQueriesBackgroundTaskQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'skip',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'uuid',
    },
    v2 = [
      {
        kind: 'Variable',
        name: 'uuid',
        variableName: 'uuid',
      },
    ],
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'status',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'response',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'ResumeQueriesBackgroundTaskQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v2 /*: any*/,
              concreteType: 'BackgroundTask',
              kind: 'LinkedField',
              name: 'backgroundTask',
              plural: false,
              selections: [v3 /*: any*/, v4 /*: any*/],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v1 /*: any*/, v0 /*: any*/],
      kind: 'Operation',
      name: 'ResumeQueriesBackgroundTaskQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v2 /*: any*/,
              concreteType: 'BackgroundTask',
              kind: 'LinkedField',
              name: 'backgroundTask',
              plural: false,
              selections: [
                v3 /*: any*/,
                v4 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'id',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
        },
      ],
    },
    params: {
      cacheID: '486dfe5afedc7f0682d714c198feca57',
      id: null,
      metadata: {},
      name: 'ResumeQueriesBackgroundTaskQuery',
      operationKind: 'query',
      text: 'query ResumeQueriesBackgroundTaskQuery(\n  $uuid: String!\n  $skip: Boolean!\n) {\n  backgroundTask(uuid: $uuid) @skip(if: $skip) {\n    status\n    response\n    id\n  }\n}\n',
    },
  };
})();

(node as any).hash = '6d6873ed633f7a8516490f001dc315c3';

export default node;
