import { useIntl } from 'react-intl';
//types
import { CustomButtonProps } from './types';

const CustomButton = ({ label, children, classNameStyle, dataTestId, ...rest }: CustomButtonProps) => {
  const intl = useIntl();
  return (
    <button data-test-id={dataTestId ?? ''} className={`flex w-max items-center text-darkBlue ${classNameStyle ?? ''}`} {...rest}>
      {children}
      {label && <span>{intl.formatMessage(label)}</span>}
    </button>
  );
};

export default CustomButton;
