/**
 * @generated SignedSource<<686bdc7ccae6e200ffb6a4b746229a1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type VacancyQueriesGetListOfCurrenciesQuery$variables = {};
export type VacancyQueriesGetListOfCurrenciesQuery$data = {
  readonly currencies: {
    readonly edges: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly perma: string;
    }>;
  } | null;
};
export type VacancyQueriesGetListOfCurrenciesQuery = {
  response: VacancyQueriesGetListOfCurrenciesQuery$data;
  variables: VacancyQueriesGetListOfCurrenciesQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      concreteType: 'CurrencyList',
      kind: 'LinkedField',
      name: 'currencies',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'Currency',
          kind: 'LinkedField',
          name: 'edges',
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'id',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'name',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'perma',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ];
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'VacancyQueriesGetListOfCurrenciesQuery',
      selections: v0 /*: any*/,
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'VacancyQueriesGetListOfCurrenciesQuery',
      selections: v0 /*: any*/,
    },
    params: {
      cacheID: '968b572f511a84a7bc4e39945b869333',
      id: null,
      metadata: {},
      name: 'VacancyQueriesGetListOfCurrenciesQuery',
      operationKind: 'query',
      text: 'query VacancyQueriesGetListOfCurrenciesQuery {\n  currencies {\n    edges {\n      id\n      name\n      perma\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'fffe5a0929b3a9253f21a484140c9d04';

export default node;
