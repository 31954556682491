import { useEffect, useState, FC } from 'react';
//models
import { LazyLoaderProps } from 'models/modelsOfComponents';

export const LazyLoader: FC<LazyLoaderProps> = ({ delayCustom, children, mainComponent }) => {
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [show, setShow] = useState(false);
  const [delay] = useState(delayCustom || 450);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    }
  }, [firstRender]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return show || firstRender ? children : mainComponent;
};
