import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSetRecoilState } from 'recoil';
// components
import { XMarkIcon } from '@heroicons/react/24/solid';
import RestrictedPublishIcon from 'icons/RestrictedPublishIcon';
import PaymentSubscriptionProLabel from 'app/ProfileEmployer/HireProduct/components/PaymentSubscriptionProLabel';
// recoil
import { paymentSubscriptionModalAtom } from 'recoil/ContactInformation/atoms/paymentSubscriptionModalAtom';

const RestrictedJobOpeningNotification = () => {
  const intl = useIntl();
  const setShowPaymentSubscriptionModal = useSetRecoilState(paymentSubscriptionModalAtom);
  const [showNotification, setShowNotification] = useState<boolean>(true);

  const onHandleOpenPaymentsSubscriptionModal = () => {
    window.analytics?.track('wui-job-opening-page-show-upgrade-to-pro-modal');
    setShowPaymentSubscriptionModal({ showPaymentSubscriptionModal: true });
  };

  const onHandleCloseNotification = () => {
    window.analytics?.track('wui-job-opening-page-click-close-upgrade-to-pro-notification');
    setShowNotification(false);
  };

  if (!showNotification) {
    return null;
  }

  return (
    <div className="flex text-blue flex-col sm:flex-row items-center border border-specialPurple rounded-[15px] bg-specialPurple-012 py-4 px-6 gap-x-[18px]">
      <div className="flex flex-row gap-x-[18px] items-center">
        <RestrictedPublishIcon />
        <div className="flex flex-col text-xs gap-y-1 w-full">
          <span className="font-semibold text-darkBlue">{intl.formatMessage({ id: 'subscription_job_opening_limit_title' })}</span>
          <span className="inline text-specialGray-075 gap-x-1">
            {intl.formatMessage(
              { id: 'subscription_job_opening_limit_description' },
              {
                value: (
                  <button onClick={onHandleOpenPaymentsSubscriptionModal} className="inline-flex">
                    <PaymentSubscriptionProLabel />
                  </button>
                ),
              },
            )}
          </span>
        </div>
      </div>
      <button onClick={onHandleOpenPaymentsSubscriptionModal} className="px-8 py-[9px] rounded-[10px] font-medium text-white bg-specialPurple hover:bg-specialPurple-1 sm:ml-auto">
        {intl.formatMessage({ id: 'subscription_upgrade_to_pro' })}
      </button>
      <XMarkIcon onClick={onHandleCloseNotification} className="w-5 text-specialGray-075 cursor-pointer" />
    </div>
  );
};

export default RestrictedJobOpeningNotification;
