import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
//schemas
import { AppraisalFragmentsOneAppraisal$key } from 'schemas/appraisals/__generated__/AppraisalFragmentsOneAppraisal.graphql';
//models
import { GET_APPRAISAL_FRAGMENT } from 'schemas/appraisals/AppraisalFragments';
//components
import CrewsSeaServiceEVWrapperView from 'app/ProfileCrew/ProfileCrewViews/ProfileCrewEmployerView/OverviewCrewInformationEmployerView/components/CrewsSeaServiceEVWrapperView';
import FileImageComponentEv from 'app/ProfileCrew/ProfileCrewViews/ProfileCrewEmployerView/OverviewCrewInformationEmployerView/components/FileImageComponentEV';

const CrewsAppraisalEvItem = ({ appraisalFr }: { appraisalFr: AppraisalFragmentsOneAppraisal$key }) => {
  const intl = useIntl();

  const appraisal = useFragment<AppraisalFragmentsOneAppraisal$key>(GET_APPRAISAL_FRAGMENT, appraisalFr);
  const timeFrom = appraisal.seaService.start ? appraisal.seaService.start.toString() : null;
  const timeTo = appraisal.seaService.end ? appraisal.seaService.end.toString() : null;
  const vesselName = appraisal.seaService.vessel.name;
  const avatarUrl = appraisal.seaService.vessel.image;
  const duty = appraisal.seaService.duty.name;
  const review = appraisal.review || '';
  const uniColon = `\u003A`;
  const appraisalDocumentsUrls = appraisal.document?.urls;
  const appraisalLargeDocumentsUrls = appraisal.document?.xlarge_urls;
  const isDocumentsExist = appraisalDocumentsUrls && !!appraisalDocumentsUrls.length && !!appraisalLargeDocumentsUrls?.length;

  return (
    <CrewsSeaServiceEVWrapperView
      dutyName={duty}
      avatarUrl={avatarUrl}
      vesselName={vesselName}
      timeTo={timeTo}
      timeFrom={timeFrom}
      additionalFieldComponent={
        isDocumentsExist ? (
          <FileImageComponentEv title={{ id: 'appraisal_files' }} smallImages={appraisalDocumentsUrls} fullSizeImages={appraisalLargeDocumentsUrls} />
        ) : (
          <span className="flex text-sm text-specialGray-03">{intl.formatMessage({ id: 'cert_no_file' })}</span>
        )
      }
    >
      <div className="flex w-full mt-3 text-sm">
        <span className="text-specialGray-075 mr-1.5 whitespace-nowrap">
          {intl.formatMessage({ id: 'appraisal_captains_review' })}
          {uniColon}
        </span>
        <span className="text-darkBlue">{review}</span>
      </div>
    </CrewsSeaServiceEVWrapperView>
  );
};

export default CrewsAppraisalEvItem;
