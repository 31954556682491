// packages
import { MouseEvent } from 'react';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
import { useSetRecoilState } from 'recoil';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// components
import LineSvg from 'icons/LineSvg';
import LocationIcon from 'icons/LocationIcon';
import JobAvatar from 'app/Avatars/JobAvatar/JobAvatar';
import ComponentWithPopover from 'system/ComponentWithPopover';
import ListWithShowMoreItems from 'system/ListWithShowMoreItems/ListWithShowMoreItems';
// routing
import { RoutePaths, RoutesPathNames } from 'app/routing';
// generated
import { VacancyFragmentsForSearchFragment$key } from 'schemas/vacancy/__generated__/VacancyFragmentsForSearchFragment.graphql';
// schemas
import { VACANCY_FRAGMENT_FOR_SEARCH } from 'schemas/vacancy/VacancyFragments';
// recoil
import { scrollWithPaginationType } from 'recoil/ScrollWithPagination/types';
import { jobOpeningsSearchScrollWithPaginationSelector } from 'recoil/ScrollWithPagination/selectors/SearchScrollWithPaginationSelector';

const JobOpeningItem = ({ vacancy }: { vacancy: VacancyFragmentsForSearchFragment$key }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { search, state } = useLocation();
  const vacancyData = useFragment<VacancyFragmentsForSearchFragment$key>(VACANCY_FRAGMENT_FOR_SEARCH, vacancy as VacancyFragmentsForSearchFragment$key);

  const setScrollWithPagination = useSetRecoilState(jobOpeningsSearchScrollWithPaginationSelector);

  const handleClickByLink = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (vacancyData.status === 'OPEN') {
      window.analytics?.track('wui-open-job-in-side-drawer', {
        vacancyId: vacancyData.id,
      });
    }
  };

  const handleOpenSlide = (vacancyId: string) => () => {
    setScrollWithPagination((prevState: scrollWithPaginationType) => ({ ...prevState, scrollY: window.scrollY }));
    navigate({ pathname: `${RoutesPathNames.show}/${vacancyId}`, search }, { state });
  };

  return (
    <Link
      to={`${RoutePaths.ProfileBase}/job/${vacancyData.id}`}
      className="border border-specialGray-012 rounded-2xl cursor-pointer w-full min-h-[94px]"
      onClick={handleClickByLink}
    >
      <div className="flex w-full h-full p-4 flex-col sm:flex-row" onClick={handleOpenSlide(vacancyData.id)}>
        <div className="flex w-full sm:w-4/5 gap-x-4">
          <JobAvatar alt={vacancyData.id} size="w-14 h-14" avatarUrl={vacancyData?.organization?.avatarUrl} />
          {/* 72px - this is the size of the avatar + the margin between the avatar and the title */}
          <div className="flex flex-col w-[calc(100%_-_72px)] gap-y-2.5">
            <div className="flex w-full items-center gap-x-4">
              <h2 className="text-specialGray-1 text-lg font-semibold truncate max-w-full sm:max-w-[50%] xl:max-w-[65%]">{vacancyData?.title}</h2>
              <div className="hidden sm:inline-block">
                <ListWithShowMoreItems list={vacancyData.duties} field="name" />
              </div>
            </div>
            <div className="flex w-full flex-wrap">
              {/*Hiring area*/}
              {vacancyData?.hiringArea && (
                <div className="flex items-center gap-x-1.5 sm:mr-[18px]">
                  <p className="hidden sm:inline text-xs text-specialGray-075">{intl.formatMessage({ id: 'hire_location_label' })}</p>
                  <LocationIcon size="w-4 h-4 sm:hidden" />
                  <ComponentWithPopover
                    popoverPosition="bottom-[calc(100%_+_5px)]"
                    id={vacancyData.hiringArea.name}
                    name={vacancyData.hiringArea.name}
                    customStyle="text-sm text-darkBlue max-w-[150px] truncate"
                  />
                </div>
              )}
              {/*Salary range*/}
              {!!(vacancyData?.salaryFrom || vacancyData?.salaryTo) ? (
                <div className="hidden sm:flex items-center space-x-1.5 mr-[18px]">
                  <p className="text-xs text-specialGray-075 whitespace-nowrap">{intl.formatMessage({ id: 'hire_job_description_salary_range' })}</p>
                  <span className="text-sm text-darkBlue">
                    <span className="mr-1.5">
                      {vacancyData?.salaryFrom || ''}
                      {vacancyData?.salaryTo ? `\u00A0\u2212\u00A0${vacancyData?.salaryTo}` : null}
                    </span>
                    <span className="text-specialGray-075">
                      {vacancyData?.currency ? vacancyData?.currency.name : intl.formatMessage({ id: 'usd' })}&nbsp;&#8725;&nbsp;{vacancyData?.salaryPeriod?.toLocaleLowerCase()}
                    </span>
                  </span>
                </div>
              ) : null}
              {/*Starting date*/}
              {vacancyData?.startingDate && (
                <div className="hidden sm:flex items-center space-x-1.5 mr-[18px]">
                  <p className="text-xs text-specialGray-075">{intl.formatMessage({ id: 'hire_starting_date_label' })}</p>
                  <span className="text-sm text-darkBlue">{format(new Date(`${vacancyData.startingDate}`), 'MMMM Y')}</span>
                </div>
              )}
              {/*Duration*/}
              {vacancyData?.duration && (
                <div className="hidden sm:flex items-center space-x-1.5 mr-[18px]">
                  <p className="text-xs text-specialGray-075">{intl.formatMessage({ id: 'sea_service_duration' })}</p>
                  <span className="text-sm text-darkBlue lowercase first-letter:uppercase">{vacancyData.duration}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="hidden sm:flex items-center w-1/5">
          {/*Vessel kind*/}
          {vacancyData?.vesselKind && (
            <div className="w-1/2">
              <ComponentWithPopover
                popoverPosition="bottom-[calc(100%_+_5px)]"
                id={vacancyData?.vesselKind.name}
                name={vacancyData?.vesselKind.name}
                customStyle="text-sm text-darkBlue truncate"
              />
            </div>
          )}
          <div className={`text-end text-specialGray-075 text-sm ${vacancyData?.vesselKind ? 'w-1/2' : 'w-full'}`}>
            {vacancyData?.createdAt && format(new Date(`${vacancyData.createdAt}`), 'dd MMM yyyy')}
          </div>
        </div>
        {!!(vacancyData?.salaryFrom || vacancyData?.salaryTo) ? (
          <>
            <LineSvg classes="sm:hidden mt-3 mb-2 w-[calc(100%_+_32px)] relative -left-4" />
            <div className="flex sm:hidden w-full text-sm text-darkBlue justify-between">
              <span className="text-specialGray-075">
                {vacancyData?.currency ? vacancyData?.currency.name : intl.formatMessage({ id: 'usd' })}&nbsp;&#8725;&nbsp;{vacancyData?.salaryPeriod?.toLocaleLowerCase()}
              </span>
              <span className="mr-1.5">
                {vacancyData?.salaryFrom || ''}
                {vacancyData?.salaryTo ? `\u00A0\u2212\u00A0${vacancyData?.salaryTo}` : null}
              </span>
            </div>
          </>
        ) : null}
      </div>
    </Link>
  );
};

export default JobOpeningItem;
