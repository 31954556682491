/**
 * @generated SignedSource<<79dc5ff1f0cd94eeb41284c5a50e3dee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CountryQueriesOneCountryQuery$variables = {
  id: string;
  skip: boolean;
};
export type CountryQueriesOneCountryQuery$data = {
  readonly node?: {
    readonly id?: string;
    readonly isoCode?: string;
    readonly name?: string;
  } | null;
};
export type CountryQueriesOneCountryQuery = {
  response: CountryQueriesOneCountryQuery$data;
  variables: CountryQueriesOneCountryQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'skip',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isoCode',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'CountryQueriesOneCountryQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: null,
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                {
                  kind: 'InlineFragment',
                  selections: [v2 /*: any*/, v3 /*: any*/, v4 /*: any*/],
                  type: 'Country',
                  abstractKey: null,
                },
              ],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'CountryQueriesOneCountryQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: null,
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: '__typename',
                  storageKey: null,
                },
                v2 /*: any*/,
                {
                  kind: 'InlineFragment',
                  selections: [v3 /*: any*/, v4 /*: any*/],
                  type: 'Country',
                  abstractKey: null,
                },
              ],
              storageKey: null,
            },
          ],
        },
      ],
    },
    params: {
      cacheID: 'd891c94c1244c6369b4456dbdcf7176b',
      id: null,
      metadata: {},
      name: 'CountryQueriesOneCountryQuery',
      operationKind: 'query',
      text: 'query CountryQueriesOneCountryQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on Country {\n      id\n      isoCode\n      name\n    }\n    id\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'ee9401c9be2e2f81455f7d2ce23a8f29';

export default node;
