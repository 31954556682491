import React, { useCallback } from 'react';
import { useMutation, useRefetchableFragment } from 'react-relay';
//schemas
import { VACANCY_FRAGMENT } from 'schemas/vacancy/VacancyFragments';
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { CREATE_VACANCY_INVITE, REMOVE_VACANCY_INVITE } from 'schemas/Invite/InviteMutations';
// generated
import { InviteMutationsRemoveInviteMutation } from 'schemas/Invite/__generated__/InviteMutationsRemoveInviteMutation.graphql';
import { InviteMutationsCreateInviteMutation } from 'schemas/Invite/__generated__/InviteMutationsCreateInviteMutation.graphql';
import { VacancyQueriesGetVacancyRefreshQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetVacancyRefreshQuery.graphql';

export const useCreateInvite = (vacancyFragmentNode: VacancyFragments$key, profileId: string) => {
  const [vacancyData, reFetch] = useRefetchableFragment<VacancyQueriesGetVacancyRefreshQuery, VacancyFragments$key>(VACANCY_FRAGMENT, vacancyFragmentNode as VacancyFragments$key);

  const [commitCreateInvite] = useMutation<InviteMutationsCreateInviteMutation>(CREATE_VACANCY_INVITE);

  return useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
      commitCreateInvite({
        variables: {
          data: {
            vacancyId: vacancyData.id || '',
            ownerId: profileId,
          },
        },
        onCompleted: () => {
          reFetch({}, { fetchPolicy: 'network-only' });
        },
      });
    },
    [profileId, commitCreateInvite, vacancyData.id, reFetch],
  );
};

export const useDeleteInvite = (vacancyFragmentNode: VacancyFragments$key, profileId: string, foundInvite?: string) => {
  const [, reFetch] = useRefetchableFragment<VacancyQueriesGetVacancyRefreshQuery, VacancyFragments$key>(VACANCY_FRAGMENT, vacancyFragmentNode as VacancyFragments$key);

  const [commitRemoveInvite] = useMutation<InviteMutationsRemoveInviteMutation>(REMOVE_VACANCY_INVITE);

  return useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();

      commitRemoveInvite({
        variables: {
          id: foundInvite || '',
        },
        onCompleted: () => {
          reFetch({}, { fetchPolicy: 'network-only' });
        },
      });
    },
    [foundInvite, commitRemoveInvite, reFetch],
  );
};
