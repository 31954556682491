import graphql from 'babel-plugin-relay/macro';

export const CREATE_VACANCY = graphql`
  mutation VacancyMutationsCreateVacancyMutation($data: VacancyData!, $connections: [ID!]!) {
    createVacancy(data: $data) {
      node @prependNode(connections: $connections, edgeTypeName: "VacancyEdge") {
        id
        ...VacancyFragments
      }
    }
  }
`;

export const UPDATE_VACANCY = graphql`
  mutation VacancyMutationsUpdateVacancyMutation($id: ID!, $data: VacancyData!) {
    updateVacancy(id: $id, data: $data) {
      id
      ...VacancyFragments
    }
  }
`;

export const SWITCH_VACANCY_STATUS = graphql`
  mutation VacancyMutationsSwitchVacancyStatusMutation($id: ID!, $status: VacancyStatus!) {
    switchVacancyStatus(id: $id, status: $status) {
      ...VacancyFragments
    }
  }
`;

export const DELETE_VACANCY = graphql`
  mutation VacancyMutationsDeleteVacancyMutation($id: ID!) {
    deleteVacancy(id: $id) {
      id
    }
  }
`;

export const UPDATE_VACANCY_MANAGER = graphql`
  mutation VacancyMutationsUpdateVacancyManagerMutation($id: ID!, $orgMemberId: ID!) {
    updateVacancyManager(id: $id, orgMemberId: $orgMemberId) {
      id
      title
      status
      organization {
        id
      }
      organizationMember {
        id
        profile {
          info {
            id
            lastName
            firstName
          }
        }
      }
    }
  }
`;
