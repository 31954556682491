// packages
import { useLocation } from 'react-router-dom';
// routes
import { RoutesPathNames } from 'app/routing';
// components
import OverviewTabSkeleton from './OverviewTabSkeleton';
import CertificatesSkeleton from './CertificatesSkeleton';
import AppraisalsTabSkeleton from './AppraisalsTabSkeleton';
import EducationsTabSkeleton from './EducationsTabSkeleton';
import ServicesListTabSkeleton from './ServicesListTabSkeleton';

const RenderingTab = () => {
  const location = useLocation();
  if (location.pathname.includes(RoutesPathNames.ProfileBaseSeaServices)) {
    return <ServicesListTabSkeleton />;
  }
  if (location.pathname.includes(RoutesPathNames.ProfileBaseCertificates)) {
    return <CertificatesSkeleton />;
  }
  if (location.pathname.includes(RoutesPathNames.ProfileBaseEducation)) {
    return <EducationsTabSkeleton />;
  }
  if (location.pathname.includes(RoutesPathNames.ProfileBaseAppraisals)) {
    return <AppraisalsTabSkeleton />;
  }
  return <OverviewTabSkeleton />;
};

export default RenderingTab;
