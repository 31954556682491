// packages
import { Suspense, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { useSetRecoilState } from 'recoil';
// utils
import { envVariableTransform } from 'utils';
// components
import { Modal } from 'system/Modal';
import DoneIcon from 'icons/DoneIcon';
import { Button } from 'system/Button';
import PersonAdd from 'icons/PersonAdd';
import FolderPlus from 'icons/FolderPlus';
import SpinnerIcon from 'icons/SpinnerIcon';
import CountryFlag from 'system/CountryFlag';
import { LinkIcon } from '@heroicons/react/24/solid';
import CustomButton from 'system/Buttons/CustomButton';
import CopyLinkComponent from 'system/CopyLinkComponent';
import { ProfileAvatar } from 'app/Avatars/ProfileAvatar/ProfileAvatar';
import EmployerViewDownloadCv from './components/EmployerViewDownloadCV';
import SummaryDescriptionItem from './components/SummaryDescriptionItem';
import AvailableForWorkLabel from '../../../components/AvailableForWorkLabel';
import ProfileAgeInfo from 'app/Profile/ProfileBasicInfo/components/ProfileAgeInfo';
import ListOfVacancies from 'app/ProfileSearch/components/ProfileListItem/components/ListOfVacancies/ListOfVacancies';
import ProfileContactInfoCommon from 'app/ProfileSearch/components/ProfileContactInfoCommon/ProfileContactInfoCommon';
import ContactButton from 'app/ProfileCrew/ProfileCrewViews/ProfileCrewEmployerView/MainCrewInformationEmployerView/components/ButtonToContactWithCrew/ContactButton';
// schemas
import { COUNTRY_FRAGMENT } from 'schemas/country/CountryFragments';
import { GET_PROFILE_VESSELS } from 'schemas/profile/ProfileQueries';
import { PROFILE_GENERAL_SETTINGS_FRAGMENT } from 'schemas/profile/ProfileFragments';
// generated
import { ProfileFragments$data } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { CountryFragments_info$key } from 'schemas/country/__generated__/CountryFragments_info.graphql';
import { ServiceFragmentsOneDuty$key } from 'schemas/services/__generated__/ServiceFragmentsOneDuty.graphql';
import { ProfileQueriesProfileVesselsQuery } from 'schemas/profile/__generated__/ProfileQueriesProfileVesselsQuery.graphql';
import { ProfileFragmentsGeneralSettings$key } from 'schemas/profile/__generated__/ProfileFragmentsGeneralSettings.graphql';
// hooks
import { useGetHireProfile } from 'hooks/hireProfileHooks';
import { useToggleShowWindow } from 'hooks/useToggleShowModal';
import { useReceiveCrewProfile } from 'hooks/useReceiveCrewProfile';
import useCreateProfileShareLink from 'hooks/useCreateProfileShareLink';
import useGetIsProSubscription from 'hooks/subscription/useGetIsProSubscription';
import { useGetYearsOfExperience } from 'hooks/crewProfileHooks/useGetYearsOfExperience';
import useGetRestrictedProfileLastName from 'hooks/ProfileDataHooks/useGetRestrictedProfileLastName';
import { useIsUserAppliedForAnyVacancies } from 'hooks/hireProfileHooks/useIsUserAppliedForAnyVacancies';
import { useIsInvitedCrewEvenForOneOfVacancies } from 'hooks/hireProfileHooks/useIsInvitedCrewEvenForOneOfVacancies';
//routing
import { RoutesPathNames } from 'app/routing';
import { GET_ONE_DUTY } from 'schemas/services/ServiceFragments';
// recoil
import { contactInformationAtom } from 'recoil/ContactInformation/atoms/contactInformationAtom';
import { paymentSubscriptionModalAtom } from 'recoil/ContactInformation/atoms/paymentSubscriptionModalAtom';

const MainCrewInformationEmployerView = () => {
  const intl = useIntl();
  const { state } = useLocation();

  const isProSubscription = useGetIsProSubscription();
  const { organizationId } = useGetHireProfile();

  const setShowPaymentSubscriptionModal = useSetRecoilState(paymentSubscriptionModalAtom);
  const setContactInfoButton = useSetRecoilState(contactInformationAtom);

  const { toggleShowWindow, showWindow } = useToggleShowWindow();
  const { dynamicProfileId, profileInfo, profileData } = useReceiveCrewProfile();
  const { getYearsOfExperience } = useGetYearsOfExperience(profileInfo?.yearsOfExperience);
  const isInvitedUserEvenForOneOfVacancies = useIsInvitedCrewEvenForOneOfVacancies(dynamicProfileId);
  const { handleCreateProfileShare, profileShareLink, loadingCreateProfileShare } = useCreateProfileShareLink(dynamicProfileId, toggleShowWindow(true));
  const lastName = useGetRestrictedProfileLastName(profileInfo?.lastName);
  const isUserAppliedForAnyVacancies = useIsUserAppliedForAnyVacancies(profileData?.id!);

  const primaryDuty = useFragment<ServiceFragmentsOneDuty$key>(GET_ONE_DUTY, profileInfo?.primaryDuty || null);
  const profileCountry = useFragment<CountryFragments_info$key>(COUNTRY_FRAGMENT, profileInfo?.country || null);
  const vessels = useLazyLoadQuery<ProfileQueriesProfileVesselsQuery>(GET_PROFILE_VESSELS, { id: dynamicProfileId });
  const generalSettings = useFragment<ProfileFragmentsGeneralSettings$key>(PROFILE_GENERAL_SETTINGS_FRAGMENT, profileData?.generalSetting || null);

  const [showModalWithVacanciesForInvite, toggleShowModalWithVacanciesForInvite] = useState<boolean>(false);

  const handleOpenContactModal = useCallback(() => {
    window.analytics?.track('wui-clicked-contact-button', {
      hireOrganizationId: organizationId,
      profileId: profileInfo?.id,
    });

    if (isProSubscription || isUserAppliedForAnyVacancies) {
      setContactInfoButton({
        profileId: profileInfo?.id || '',
        showContactInformation: true,
        isLoading: true,
      });
      return;
    }
    setShowPaymentSubscriptionModal({ showPaymentSubscriptionModal: true });
  }, [organizationId, profileInfo?.id, isProSubscription, isUserAppliedForAnyVacancies, setShowPaymentSubscriptionModal, setContactInfoButton]);

  if (!profileData && !profileInfo) {
    return null;
  }

  return (
    <div className="flex w-full flex-col text-specialGray-1 flex-wrap gap-y-6">
      <div className="flex flex-col w-auto gap-y-2">
        <div className="flex w-full">
          <ProfileAvatar
            size="h-[83px] w-[83px]"
            avatarUrl={profileInfo?.avatarUrl}
            abbreviationSize="rounded-md outline outline-1 outline-specialGray-012"
            nameAbbreviation={profileInfo ? `${profileInfo?.firstName[0]}${profileInfo?.lastName[0]}` : ''}
          />

          <div className="flex flex-col ml-6 gap-y-3">
            <div className="flex">
              <h1 className="text-[32px] font-bold mr-8 leading-[48px]">
                {profileInfo?.firstName} {lastName}
              </h1>
              <div className="flex flex-wrap md:flex-nowrap gap-x-5 gap-y-1">
                <ContactButton profileId={profileData?.id} onClick={handleOpenContactModal} />

                <ProfileContactInfoCommon />

                {/*invite button*/}
                {(envVariableTransform(process.env.REACT_APP_FEATURE_INVITE_BUTTON) || envVariableTransform(process.env.STORYBOOK_FEATURE_INVITE_BUTTON)) && (
                  <>
                    <Modal
                      description={{ id: 'search_modal_description' }}
                      title={{ id: 'search_modal_title' }}
                      onClose={() => toggleShowModalWithVacanciesForInvite(false)}
                      show={showModalWithVacanciesForInvite}
                    >
                      <Suspense fallback={<SpinnerIcon />}>
                        <ListOfVacancies crewProfileId={profileData?.id as string} />
                      </Suspense>
                    </Modal>
                    <Button
                      buttonType="white-primary"
                      type="button"
                      prefixIcon={isInvitedUserEvenForOneOfVacancies ? DoneIcon : PersonAdd}
                      label={{
                        id: isInvitedUserEvenForOneOfVacancies ? 'hire_job_invited_label' : 'search_list_item_invite_button_label',
                      }}
                      additionalClasses={`space-x-2 h-10 rounded-[10px] px-5 ${isInvitedUserEvenForOneOfVacancies ? 'border-green-500 !text-green-500' : 'border-specialGray-05'}`}
                      labelClasses="font-medium"
                      onClick={() => toggleShowModalWithVacanciesForInvite(true)}
                    />
                  </>
                )}

                {/*download CV button*/}
                <EmployerViewDownloadCv profileData={profileData as ProfileFragments$data} />

                {/*Crews profile link*/}
                {isInvitedUserEvenForOneOfVacancies && (
                  <>
                    <CustomButton
                      onClick={handleCreateProfileShare}
                      className="flex h-10 text-sm border border-specialGray-05 rounded-[10px] items-center gap-x-2 px-5 hover:bg-gray-50 text-specialGray-075 font-medium"
                      label={{ id: 'create_link_buttonLabel' }}
                    >
                      {loadingCreateProfileShare ? <SpinnerIcon additionalClasses="w-5 h-5" /> : <LinkIcon className="w-5 h-5 text-specialGray-05" />}
                    </CustomButton>
                    <Modal
                      tinyModal
                      description={{ id: 'self_basic_modalDescription' }}
                      title={{ id: 'self_basic_link' }}
                      onClose={toggleShowWindow(false)}
                      show={showWindow || !!state?.storybookProfileShare}
                    >
                      <CopyLinkComponent profileShareLink={state?.storybookProfileShare || profileShareLink} />
                    </Modal>
                  </>
                )}

                {(envVariableTransform(process.env.REACT_APP_FEATURE_HIRE_CREW_POOLS) || envVariableTransform(process.env.STORYBOOK_FEATURE_INVITE_BUTTON)) && (
                  <>
                    <Link
                      data-test-id="profile-page-add-user-to-pool"
                      className="flex bg-white px-3 items-center border h-10 border-specialGray-05 rounded-[10px] hover:bg-gray-50 text-specialGray-05"
                      to={`${RoutesPathNames.addUserToPool}/${profileData?.id}`}
                    >
                      <FolderPlus />
                    </Link>

                    <Outlet />
                  </>
                )}
              </div>
            </div>
            <div className="flex gap-x-3 sm:gap-x-5 text-sm">
              <AvailableForWorkLabel isAvailable={generalSettings === null || generalSettings.availableForWork} />

              {profileCountry ? <CountryFlag classes="-mt-1" countryId={profileCountry.isoCode} countryName={profileCountry.name} /> : null}

              {primaryDuty?.name ? (
                <span>
                  {primaryDuty.name}&nbsp;<span className="lowercase text-specialGray-075">{intl.formatMessage({ id: 'label_form_duty' })}</span>
                </span>
              ) : null}

              <ProfileAgeInfo birthDate={profileInfo?.birthDate} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col gap-y-3">
        <div className="flex flex-col max-w-[593px] text-sm">
          <h3 className="font-medium">{intl.formatMessage({ id: 'profile_about_me' })}</h3>
          <span className="whitespace-pre-line text-specialGray-075 text-sm leading-[160%]">{profileInfo?.bio}</span>
        </div>

        {getYearsOfExperience ? (
          <div className="flex gap-x-1 text-darkBlue text-sm">
            <span className="text-darkBlue">{getYearsOfExperience.name.replace('years', '')}</span>
            <span className="text-specialGray-075 lowercase">{intl.formatMessage({ id: 'profile_years_of_work_experience' })}</span>
          </div>
        ) : null}
        <div className="flex">
          <SummaryDescriptionItem count={vessels.vesselList?.totalCount || 0} title={intl.formatMessage({ id: 'profile_vessels' })} />
          <SummaryDescriptionItem count={profileData?.seaServices?.length || 0} title={intl.formatMessage({ id: 'profile_seaServiceTitle' })} />
          <SummaryDescriptionItem count={profileData?.certificateInfos?.length || 0} title={intl.formatMessage({ id: 'profile_certificatesTitle' })} />
          <SummaryDescriptionItem count={profileData?.educations?.length || 0} title={intl.formatMessage({ id: 'profile_educationTitle' })} />
        </div>
      </div>
    </div>
  );
};

export default MainCrewInformationEmployerView;
