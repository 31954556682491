// packages
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { useLazyLoadQuery } from 'react-relay';
import { MessageDescriptor } from '@formatjs/intl';
// helpers
import { onHandleSendProductAnalytics } from '../../../productHelpers';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';
// schemas
import { GET_EMAIL_OF_PROFILE } from 'schemas/profile/ProfileQueries';
// generated
import { ProfileQueriesGetEmailOfProfileQuery } from 'schemas/profile/__generated__/ProfileQueriesGetEmailOfProfileQuery.graphql';

const FooterLinkButtonWithBorder = ({
  title,
  href,
  productAnalyticsEvents,
  isBlank = false,
}: {
  title: MessageDescriptor;
  href: string;
  productAnalyticsEvents: string;
  isBlank?: boolean;
}) => {
  const intl = useIntl();
  const { organisationId, profileId } = useRecoilValue(currentOrganisationSelector);

  const email = useLazyLoadQuery<ProfileQueriesGetEmailOfProfileQuery>(GET_EMAIL_OF_PROFILE, { ids: [Number(profileId)], skip: !profileId }).profileOIDClaims?.edges[0].email || '';

  return (
    <a
      href={href}
      target={isBlank ? '_blank' : ''}
      className="flex justify-center items-center w-[205px] h-12 text-center font-medium text-[#6F1DF4] ring-1 ring-inset ring-[#6F1DF4]/[.3] hover:ring-[#6F1DF4] rounded-[10px]"
      rel="noreferrer"
      onClick={onHandleSendProductAnalytics(productAnalyticsEvents, organisationId, profileId, email)}
    >
      {intl.formatMessage(title)}
    </a>
  );
};

export default FooterLinkButtonWithBorder;
