// packages
import { useFragment, useLazyLoadQuery } from 'react-relay';
// components
import { ProfileAvatar } from 'app/Avatars/ProfileAvatar/ProfileAvatar';
// generated
import { ProfileFragments$data } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { ProfileFragments_info$key } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
import { ProfileQueriesGetProfileOIDClaimsQuery } from 'schemas/profile/__generated__/ProfileQueriesGetProfileOIDClaimsQuery.graphql';
// schemas
import { GET_PROFILE_OID_CLAIMS } from 'schemas/profile/ProfileQueries';
import { PROFILE_INFO_FRAGMENT } from 'schemas/profile/ProfileFragments';

const HeaderSettings = ({ profileData }: { profileData: ProfileFragments$data | null }) => {
  const profileEmail = useLazyLoadQuery<ProfileQueriesGetProfileOIDClaimsQuery>(GET_PROFILE_OID_CLAIMS, { ids: [profileData?.id as unknown as number], skip: !profileData?.id })
    .profileOIDClaims?.edges[0];

  const profileInfo = useFragment<ProfileFragments_info$key>(PROFILE_INFO_FRAGMENT, profileData?.info || null);

  return (
    <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-2 lg:space-x-3 space-x-0 items-center">
      <ProfileAvatar avatarUrl={profileInfo?.avatarUrl} size="w-10 h-10" />
      <div className="hidden md:flex flex-col justify-between lg:items-start items-center text-darkBlue">
        {profileInfo ? (
          <h5>
            {profileInfo?.firstName}&nbsp;{profileInfo?.lastName}
          </h5>
        ) : null}
        <address className="text-xs lg:text-sm text-specialGray-075 not-italic">{profileEmail?.email}</address>
      </div>
    </div>
  );
};

export default HeaderSettings;
