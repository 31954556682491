import { useCallback, useEffect, useRef, useState } from 'react';

export const useTextOverflow = () => {
  const [isOverflowActive, setIsOverflowActive] = useState<boolean>(false);
  const overflowingTextRef = useRef<HTMLDivElement | null>(null);

  const checkOverflow = useCallback((textContainer: HTMLElement | null): boolean => {
    if (textContainer) return textContainer.offsetHeight < textContainer.scrollHeight || textContainer.offsetWidth < textContainer.scrollWidth;
    return false;
  }, []);

  useEffect(() => {
    setIsOverflowActive(checkOverflow(overflowingTextRef.current));
  }, [checkOverflow]);

  return {
    overflowingTextRef,
    isOverflowActive,
  };
};
export default useTextOverflow;
