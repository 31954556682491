/**
 * @generated SignedSource<<26b32892271712ac149576fdfeecbd87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProfileShareData = {
  expires?: String | null;
  ownerId: string;
};
export type ProfileMutationsShareMutation$variables = {
  data: ProfileShareData;
};
export type ProfileMutationsShareMutation$data = {
  readonly createProfileShare: {
    readonly uuid: string;
  };
};
export type ProfileMutationsShareMutation = {
  response: ProfileMutationsShareMutation$data;
  variables: ProfileMutationsShareMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'data',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'data',
        variableName: 'data',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'uuid',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileMutationsShareMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'ProfileShare',
          kind: 'LinkedField',
          name: 'createProfileShare',
          plural: false,
          selections: [v2 /*: any*/],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileMutationsShareMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'ProfileShare',
          kind: 'LinkedField',
          name: 'createProfileShare',
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'id',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: 'a75ffc252c798adaabe85d4ced113c3e',
      id: null,
      metadata: {},
      name: 'ProfileMutationsShareMutation',
      operationKind: 'mutation',
      text: 'mutation ProfileMutationsShareMutation(\n  $data: ProfileShareData!\n) {\n  createProfileShare(data: $data) {\n    uuid\n    id\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'e1dcd927e7b5cff79d1378b689d9f26c';

export default node;
