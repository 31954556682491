// packages
import { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFragment, useLazyLoadQuery, useMutation } from 'react-relay';
// components
import { Modal } from 'system/Modal';
import SectionGroup from './components/SectionGroup';
import EmptyList from 'system/ListComponents/EmptyList';
import CertificatesAllList from '../AllCertificatesList/AllCertificatesList';
import HeaderList from 'system/ListComponents/HeaderList';
import { ConfirmModalBody } from 'system/ConfirmModalBody';
import CertificateFileIcon from 'icons/CertificateFileIcon';
// hooks
import { useAuth } from 'authentication';
import useSortedCertificates from 'hooks/useSortedCertificates';
// generated
import { CertificateQueriesListQuery } from 'schemas/certificates/__generated__/CertificateQueriesListQuery.graphql';
import { CertificateMutationsRemoveMutation } from 'schemas/certificates/__generated__/CertificateMutationsRemoveMutation.graphql';
import { ProfileMutationsProfileProgressMutation } from 'schemas/profile/__generated__/ProfileMutationsProfileProgressMutation.graphql';
import { CertificateFragmentsCertificateInfo$key } from 'schemas/certificates/__generated__/CertificateFragmentsCertificateInfo.graphql';
// schemas
import { UPDATE_PROFILE_DOCUMENT } from 'schemas/profile/ProfileMutations';
import { GET_ALL_CERTIFICATES_LIST } from 'schemas/certificates/CertificateQueries';
import { LIST_OF_CERTIFICATE_INFOS } from 'schemas/certificates/CertificateFragments';
import { REMOVE_CERTIFICATE_ITEM_MUTATION } from 'schemas/certificates/CertificateMutations';
// helpers
import { certificateRemove } from 'formHelpers/updaters/updatersOfCertificate';
// types
import { CertificateListProps } from '../types';

const CertificateList = ({ defaultShowModal = false, profile }: CertificateListProps) => {
  const navigate = useNavigate();
  const { identity } = useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    window.analytics?.track('wui-open-certificateList-tab');
  }, []);

  const certificateFromProfile = useFragment<CertificateFragmentsCertificateInfo$key>(LIST_OF_CERTIFICATE_INFOS, profile?.certificateInfos);
  const dataOfAllCertificates = useLazyLoadQuery<CertificateQueriesListQuery>(GET_ALL_CERTIFICATES_LIST, { skip: false });

  const [commitRemoveListItem] = useMutation<CertificateMutationsRemoveMutation>(REMOVE_CERTIFICATE_ITEM_MUTATION);
  // Update progress profile if last item zero, backend will fix it in a while
  const [commitCreate] = useMutation<ProfileMutationsProfileProgressMutation>(UPDATE_PROFILE_DOCUMENT);

  const sortedCertificates = useSortedCertificates(certificateFromProfile);

  const [showModal, setShowModal] = useState<boolean>(defaultShowModal);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [removeCertificateId, setRemoveCertificateId] = useState<string>('');

  const closeModal = useCallback(() => {
    setShowModal(false);
    navigate(pathname, {
      replace: true,
      state: null,
    });
  }, [navigate, pathname]);

  const openCreateModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setShowConfirmModal(false);
  }, []);

  const removeCertificateItem = useCallback(
    (certId: string) => {
      const certificateInfoId = certificateFromProfile?.find(({ certificate }) => certificate.id === certId)?.id;

      const onError = (error: Error) => {
        console.warn(error);
      };

      commitRemoveListItem({
        variables: { id: certificateInfoId as string },
        onCompleted: () => {
          window.analytics?.track('wui-removed-certificate-from-profile', {
            removedCertificateId: certificateInfoId,
          });
          closeConfirmModal();
          setShowConfirmModal(false);
          if (certificateFromProfile?.length === 1) {
            commitCreate({
              variables: {
                pid: identity?.profileId ? identity?.profileId.toString() : '',
                data: { addedFirstCertificate: false },
              },
              onError,
            });
          }
        },
        updater: certificateRemove(`${identity?.profileId}`, certificateInfoId as string),
      });
    },
    [closeConfirmModal, identity?.profileId, commitRemoveListItem, certificateFromProfile, commitCreate],
  );

  const deleteCertificateClick = useCallback((id: string | undefined) => {
    if (id) {
      setShowConfirmModal(true);
      setRemoveCertificateId(id);
    }
  }, []);

  return (
    <section>
      <div className="rounded-lg">
        <Modal onClose={closeModal} show={showModal} title={{ id: 'profile_addCertCta' }} description={{ id: 'self_basic_modalCertificateDescription' }}>
          <CertificatesAllList closeModal={closeModal} dataCerts={dataOfAllCertificates} certificateInfos={certificateFromProfile} profileId={profile?.id} />
        </Modal>
        <Modal onClose={closeConfirmModal} show={showConfirmModal} tinyModal>
          <ConfirmModalBody
            title={{ id: 'confirm_remove_item_title' }}
            textOne={{ id: 'confirm_remove_item_text_1' }}
            textTwo={{ id: 'confirm_remove_item_text_2' }}
            onClick={() => removeCertificateItem(removeCertificateId)}
            onCancel={closeConfirmModal}
            buttonLabel={{ id: 'delete' }}
            typeButtonConfirm="red-primary"
          />
        </Modal>
        <HeaderList
          title={{ id: 'profile_certificatesTitle' }}
          onClick={openCreateModal}
          buttonLabel={{ id: 'profile_addCertCta' }}
          amountLabel={{ id: 'profile_certificatesAmount' }}
          amount={certificateFromProfile?.length}
        />
        {!isEmpty(sortedCertificates) ? (
          sortedCertificates.map((certificatesDataByKind, index) => {
            const [certificateKind, certificates] = certificatesDataByKind;
            return (
              <SectionGroup
                key={`${certificateKind}-${index}`}
                nameGroup={certificateKind}
                allCertificates={certificateFromProfile}
                certificates={certificates}
                deleteCertificateClick={deleteCertificateClick}
              />
            );
          })
        ) : (
          <EmptyList navigationTitle={{ id: 'profile_addCertCta' }} onClick={openCreateModal} title={{ id: 'cert_list_no_certificates' }} icon={<CertificateFileIcon />} />
        )}
      </div>
    </section>
  );
};

export default CertificateList;
