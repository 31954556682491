// packages
import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { FormikProvider, useFormik, Form } from 'formik';
// components
import { Button } from 'system/Button';
import { InputGroup } from 'system/InputGroups/InputGroup';
import AlertForError from 'system/Alert/AlertForError';
import { ForgotFormValidateSchema } from 'formHelpers/validationsOfForms';
import { ForgotFormInitialValues } from 'formHelpers/initialValuesOfForms';
import { ForgotFormData, ForgotFormProps } from 'models/modelsOfComponents';

// ForgotForm implements a form that can be used start the password recovery process for
// users that forgot their password.
export const ForgotForm: React.FC<ForgotFormProps> = ({ loginLink, onVerificationSent, defaultSubmitting = false, defaultError }) => {
  const intl = useIntl();
  const [error, setError] = useState<Error | undefined>(defaultError);
  const formik = useFormik<ForgotFormData>({
    onSubmit,
    initialValues: ForgotFormInitialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ForgotFormValidateSchema,
  });

  useEffect(() => {
    if (defaultSubmitting && !formik.isSubmitting) formik.setSubmitting(true);
  });

  async function onSubmit(d: ForgotFormData) {
    let err: Error | undefined;
    try {
      await Auth.forgotPassword(d.email);
      err = undefined;
    } catch (e: unknown) {
      err = e as Error;
    }
    setError(err);
    formik.setSubmitting(false);
    onVerificationSent && !err && onVerificationSent(d);
  }

  return (
    <FormikProvider value={formik}>
      <Form className="space-y-6">
        {error && <AlertForError heading={{ id: 'forgot_failed' }} error={error} />}

        <InputGroup autoFocus type="email" name="email" label={{ id: 'default_auth_emailLabel' }} />

        <Button type="submit" disabled={formik.isSubmitting} fullSize label={{ id: 'forgot_forgotCta' }} />
        <div className="flex justify-center text-xs">
          <Link className="block text-blue" to={loginLink}>
            {intl.formatMessage({ id: 'back' })}
          </Link>
        </div>
      </Form>
    </FormikProvider>
  );
};
