import { Suspense, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import Skeleton from 'react-loading-skeleton';
import { Navigate, useParams } from 'react-router-dom';
//components
import MainCrewInformationEmployerView from './MainCrewInformationEmployerView/MainCrewInformationEmployerView';
import AppliedAlertMessageWrapper from './MainCrewInformationEmployerView/components/AppliedAlertMessageWrapper';
import OverviewCrewInformationEmployerView from './OverviewCrewInformationEmployerView/OverviewCrewInformationEmployerView';
import ProfileCrewEmployerViewSkeleton from 'system/skeletons/profile/ProfileCrewSkeleton/ProfileCrewEmployerViewSkeleton/ProfileCrewEmployerViewSkeleton';
// routing
import { RoutePaths } from 'app/routing';
// recoil
import { personalAccountSelector } from 'recoil/Profile/personalAccount/selectors/personalAccountSelector';

const ProfileCrewEmployerView = () => {
  const getIsPersonalAccount = useRecoilValue(personalAccountSelector);
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  if (getIsPersonalAccount.personalAccountIsSelected) {
    return <Navigate to={`${RoutePaths.ProfileSingleCrewPage}/${id}`} replace />;
  }

  return (
    <main className="w-full md:flex-col md:items-center md:justify-between space-y-8">
      <Suspense fallback={<Skeleton width="100%" height={70} borderRadius={15} />}>
        <AppliedAlertMessageWrapper />
      </Suspense>
      <Suspense fallback={<ProfileCrewEmployerViewSkeleton />}>
        <MainCrewInformationEmployerView />
        <OverviewCrewInformationEmployerView />
      </Suspense>
    </main>
  );
};

export default ProfileCrewEmployerView;
