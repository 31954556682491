import { useEffect, useState } from 'react';
import isChromatic from 'chromatic';
import * as Sentry from '@sentry/react';
//components
import { Alert } from 'system/Alert/Alert';
//models
import { AlertForErrorProps } from 'models/modelsOfComponents';
//utils
import { getRequestErrorIdFromMessage } from 'utils';

const AlertForError = ({ error, heading, type = 'error' }: AlertForErrorProps) => {
  const [errorId, setErrorId] = useState<string>('');

  useEffect(() => {
    if (!isChromatic()) {
      setErrorId(getRequestErrorIdFromMessage(error));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return <Alert heading={heading} message={{ id: error.message }} type={type} errorId={errorId} />;
};

export default AlertForError;
