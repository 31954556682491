// packages
import { ForgotForm } from 'auth/ForgotForm';
import { useNavigate } from 'react-router-dom';
// routes
import { RoutePaths } from 'app/routing';
// hooks
import { useAuth } from 'authentication';

const HireForgotComponent = () => {
  const navigate = useNavigate();
  const { setEmail } = useAuth();

  return (
    <ForgotForm
      loginLink={RoutePaths.HireAuthLogin}
      onVerificationSent={d => {
        if (setEmail) {
          setEmail(d.email);
        }
        navigate(RoutePaths.HireAuthForgotConfirm);
      }}
    />
  );
};

export default HireForgotComponent;
