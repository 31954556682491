// packages
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
// hooks
import useFilterSearchUrl from 'hooks/searchFilter/useFilterSearchUrl';
import { useDeleteFilterAnalytics, useFilterSelectedAnalytics } from 'hooks/anaytics/useFiltersAnalytics';
// utils
import { findSelectedFiltersFromUrl } from 'utils/filterHelpers';
// models
import { FILTER_TYPE } from 'models/enums';
// types
import { ListOfFilterProps } from '../types';

export function useDropDownTools<T extends ListOfFilterProps>(listOfFilters: readonly T[] = [], filterType: FILTER_TYPE, formatter: (item: T) => string) {
  const { createSearchUrl, selectedItemsFromUrl } = useFilterSearchUrl(filterType);
  const { sendFilterAnalytics } = useFilterSelectedAnalytics();
  const { deleteFilterAnalytics } = useDeleteFilterAnalytics();

  const [isOpenList, setIsOpenList] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [filters, setFilters] = useState<typeof listOfFilters>(listOfFilters);
  const [selectedFilterItems, setSelectedFilterItems] = useState<T[]>([]);

  const inputReference = useRef<HTMLInputElement>(null);

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setInputValue(value);
      setFilters(
        () =>
          listOfFilters.filter(({ alts, ...item }: T) => {
            const foundItem = formatter(item as T)
              .toLowerCase()
              .includes(value.toLowerCase());
            if (!isEmpty(alts) && !foundItem) {
              return alts?.some((alt: string) => {
                return alt.toLowerCase().includes(value.toLowerCase());
              });
            }
            return foundItem;
          }) as typeof listOfFilters,
      );
    },
    [listOfFilters, formatter],
  );

  const handleAddSearchFilter = useCallback(
    (filterItem: T) => () => {
      createSearchUrl(filterItem, inputReference)();
      setSelectedFilterItems(prev => [...prev, filterItem]);
      sendFilterAnalytics(filterItem, filterType);
    },
    [createSearchUrl, filterType, sendFilterAnalytics],
  );

  const handleDeleteSelectedItemFromDropDownList = useCallback(
    (inputRef?: React.RefObject<HTMLInputElement>) => (filterItem: T) => () => {
      createSearchUrl(filterItem, inputRef)();
      setSelectedFilterItems(prev => prev.filter(selectedItem => selectedItem.id !== filterItem.id));
      deleteFilterAnalytics(filterItem, filterType);
    },
    [createSearchUrl, deleteFilterAnalytics, filterType],
  );

  useEffect(() => {
    if (!isEmpty(selectedItemsFromUrl) && isEmpty(selectedFilterItems)) {
      setSelectedFilterItems(findSelectedFiltersFromUrl(listOfFilters, selectedItemsFromUrl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDropDownList = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsOpenList(prev => !prev);
  }, []);

  const closeListIfOutsideClick = useCallback(() => {
    if (isOpenList) {
      setIsOpenList(false);
    }
  }, [isOpenList]);

  return {
    isOpenList,
    inputValue,
    filters,
    handleChangeInput,
    handleAddSearchFilter,
    handleDeleteSelectedItemFromDropDownList,
    openDropDownList,
    closeListIfOutsideClick,
    selectedFilterItems,
    inputReference,
  };
}
