import React from 'react';

const FileDownloadIcon: React.FC = () => {
  return (
    <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0325 10.75H18.25V2C18.25 1.0375 17.4625 0.25 16.5 0.25H9.5C8.5375 0.25 7.75 1.0375 7.75 2V10.75H4.9675C3.41 10.75 2.6225 12.64 3.725 13.7425L11.7575 21.775C12.44 22.4575 13.5425 22.4575 14.225 21.775L22.2575 13.7425C23.36 12.64 22.59 10.75 21.0325 10.75ZM0.75 28.25C0.75 29.2125 1.5375 30 2.5 30H23.5C24.4625 30 25.25 29.2125 25.25 28.25C25.25 27.2875 24.4625 26.5 23.5 26.5H2.5C1.5375 26.5 0.75 27.2875 0.75 28.25Z"
        fill="#2A6FF5"
      />
    </svg>
  );
};

export default FileDownloadIcon;
