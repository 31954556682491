import { ProfileFragments_info$data } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
// hooks
import useGetRestrictedProfileLastName from 'hooks/ProfileDataHooks/useGetRestrictedProfileLastName';

const ProfileFullName = ({ profileInfoData }: { profileInfoData: ProfileFragments_info$data }) => {
  const lastName = useGetRestrictedProfileLastName(profileInfoData?.lastName);
  return (
    <span className="text-darkBlue text-lg font-medium">
      {profileInfoData?.firstName}&nbsp;{lastName}
    </span>
  );
};

export default ProfileFullName;
