/**
 * @generated SignedSource<<5a890a521eeca60f9d8237e2c64ca1db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProfileMutationsDeleteCrewFromPoolMutation$variables = {
  poolId: string;
  profileIDs: ReadonlyArray<string>;
};
export type ProfileMutationsDeleteCrewFromPoolMutation$data = {
  readonly removeCrewPoolProfiles: {
    readonly id: string;
  };
};
export type ProfileMutationsDeleteCrewFromPoolMutation = {
  response: ProfileMutationsDeleteCrewFromPoolMutation$data;
  variables: ProfileMutationsDeleteCrewFromPoolMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'poolId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'profileIDs',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'poolId',
            variableName: 'poolId',
          },
          {
            kind: 'Variable',
            name: 'profileIDs',
            variableName: 'profileIDs',
          },
        ],
        concreteType: 'CrewPool',
        kind: 'LinkedField',
        name: 'removeCrewPoolProfiles',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileMutationsDeleteCrewFromPoolMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileMutationsDeleteCrewFromPoolMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'e29dc7450f936d9452e3a8fbc3b180bd',
      id: null,
      metadata: {},
      name: 'ProfileMutationsDeleteCrewFromPoolMutation',
      operationKind: 'mutation',
      text: 'mutation ProfileMutationsDeleteCrewFromPoolMutation(\n  $poolId: ID!\n  $profileIDs: [ID!]!\n) {\n  removeCrewPoolProfiles(profileIDs: $profileIDs, poolId: $poolId) {\n    id\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'bd44001cf9165d20b8bb376662ff6743';

export default node;
