// packages
import { SignUpStepsFooter } from '../types';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
// components
import { Button } from 'system/Button';
import CustomButton from 'system/Buttons/CustomButton';

const Footer = ({ pathnameToPrevStep, isDisabled, labelForRightLabel = 'next', error }: SignUpStepsFooter) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate({ pathname: pathnameToPrevStep }, { state });
  };

  return (
    <div className="flex space-x-5">
      {error ? (
        <Button
          type="button"
          buttonType="white-primary"
          label={{ id: 'back' }}
          onClick={handleGoBack}
          additionalClasses=" h-12 rounded-[10px] font-medium"
          fullSize
          prefixIcon={ChevronLeftIcon}
        />
      ) : null}
      <CustomButton
        type="submit"
        label={{ id: labelForRightLabel }}
        className="flex bg-blue rounded-[10px] text-white flex-row-reverse w-full h-12 items-center justify-center space-x-3 space-x-reverse disabled:border-none disabled:bg-[#D4D7DD] disabled:text-specialGray-03 disabled:cursor-not-allowed"
        disabled={isDisabled}
      />
    </div>
  );
};

export default Footer;
