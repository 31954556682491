const CheckCircleIcon = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_10505_82817" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_10505_82817)">
        <path
          className="fill-current"
          d="M7.95 12.45L13.2375 7.1625L12.1875 6.1125L7.95 10.35L5.8125 8.2125L4.7625 9.2625L7.95 12.45ZM9 16.5C7.9625 16.5 6.9875 16.303 6.075 15.909C5.1625 15.5155 4.36875 14.9813 3.69375 14.3063C3.01875 13.6313 2.4845 12.8375 2.091 11.925C1.697 11.0125 1.5 10.0375 1.5 9C1.5 7.9625 1.697 6.9875 2.091 6.075C2.4845 5.1625 3.01875 4.36875 3.69375 3.69375C4.36875 3.01875 5.1625 2.48425 6.075 2.09025C6.9875 1.69675 7.9625 1.5 9 1.5C10.0375 1.5 11.0125 1.69675 11.925 2.09025C12.8375 2.48425 13.6313 3.01875 14.3063 3.69375C14.9813 4.36875 15.5155 5.1625 15.909 6.075C16.303 6.9875 16.5 7.9625 16.5 9C16.5 10.0375 16.303 11.0125 15.909 11.925C15.5155 12.8375 14.9813 13.6313 14.3063 14.3063C13.6313 14.9813 12.8375 15.5155 11.925 15.909C11.0125 16.303 10.0375 16.5 9 16.5Z"
        />
      </g>
    </svg>
  );
};

export default CheckCircleIcon;
