/**
 * @generated SignedSource<<43738dd5e210dd89b94cccd15922f442>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProfileMutationsUpdateProfileInfoHiddenMutation$variables = {
  ownerId: string;
  value: boolean;
};
export type ProfileMutationsUpdateProfileInfoHiddenMutation$data = {
  readonly updateProfileInfoHidden: {
    readonly id: string;
    readonly isHidden: boolean | null;
  };
};
export type ProfileMutationsUpdateProfileInfoHiddenMutation = {
  response: ProfileMutationsUpdateProfileInfoHiddenMutation$data;
  variables: ProfileMutationsUpdateProfileInfoHiddenMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'ownerId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'value',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'ownerId',
            variableName: 'ownerId',
          },
          {
            kind: 'Variable',
            name: 'value',
            variableName: 'value',
          },
        ],
        concreteType: 'ProfileInfo',
        kind: 'LinkedField',
        name: 'updateProfileInfoHidden',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'isHidden',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileMutationsUpdateProfileInfoHiddenMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileMutationsUpdateProfileInfoHiddenMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'dec8889980655141fd5a45538705454f',
      id: null,
      metadata: {},
      name: 'ProfileMutationsUpdateProfileInfoHiddenMutation',
      operationKind: 'mutation',
      text: 'mutation ProfileMutationsUpdateProfileInfoHiddenMutation(\n  $ownerId: ID!\n  $value: Boolean!\n) {\n  updateProfileInfoHidden(ownerId: $ownerId, value: $value) {\n    id\n    isHidden\n  }\n}\n',
    },
  };
})();

(node as any).hash = '37eb67eef4752f0c95908348e0cede6f';

export default node;
