/**
 * @generated SignedSource<<f64f29839b31050e8a54218773908174>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CrewPoolKind = 'REGULAR' | '%future added value';
export type CrewPoolData = {
  kind: CrewPoolKind;
  name: string;
  organizationId: string;
};
export type ProfileMutationsCreateNewPoolMutation$variables = {
  data: CrewPoolData;
};
export type ProfileMutationsCreateNewPoolMutation$data = {
  readonly createCrewPool: {
    readonly node: {
      readonly CreatedAt: String;
      readonly Organization: {
        readonly id: string;
      };
      readonly UpdatedAt: String;
      readonly id: string;
      readonly kind: CrewPoolKind;
      readonly name: string;
    } | null;
  };
};
export type ProfileMutationsCreateNewPoolMutation = {
  response: ProfileMutationsCreateNewPoolMutation$data;
  variables: ProfileMutationsCreateNewPoolMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'data',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'data',
            variableName: 'data',
          },
        ],
        concreteType: 'CrewPoolEdge',
        kind: 'LinkedField',
        name: 'createCrewPool',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'CrewPool',
            kind: 'LinkedField',
            name: 'node',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'name',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'kind',
                storageKey: null,
              },
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                concreteType: 'Organization',
                kind: 'LinkedField',
                name: 'Organization',
                plural: false,
                selections: [v1 /*: any*/],
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'CreatedAt',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'UpdatedAt',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileMutationsCreateNewPoolMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileMutationsCreateNewPoolMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: 'c2f043de34c3b69d9f2b3ce5814df572',
      id: null,
      metadata: {},
      name: 'ProfileMutationsCreateNewPoolMutation',
      operationKind: 'mutation',
      text: 'mutation ProfileMutationsCreateNewPoolMutation(\n  $data: CrewPoolData!\n) {\n  createCrewPool(data: $data) {\n    node {\n      name\n      kind\n      id\n      Organization {\n        id\n      }\n      CreatedAt\n      UpdatedAt\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '971965aee5156f0785b5db847cf4e7ed';

export default node;
