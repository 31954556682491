import graphql from 'babel-plugin-relay/macro';

export const GET_ORGANIZATION_NODE = graphql`
  query OrganizationQueryGetOrganizationNodeQuery($id: ID!, $skip: Boolean!) {
    node(id: $id) @skip(if: $skip) {
      ... on Organization {
        id
        name
        description
        avatarUrl
        size
        websiteUrl
      }
    }
  }
`;
