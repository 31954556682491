import graphql from 'babel-plugin-relay/macro';

export const SEARCH_QUERY = graphql`
  query SearchQueriesQuery($after: Cursor, $first: Int, $query: String, $index: SearchIndex!, $filters: String, $skip: Boolean!) {
    search(after: $after, first: $first, query: $query, index: $index, filters: $filters) @connection(key: "SearchQueries_search") @skip(if: $skip) {
      totalCount
      edges {
        node {
          ... on SearchableProfile {
            objectID
            firstName
            lastName
            primaryDuty
            seaServiceDuties
            birthDate
            certificates
            country
            seaServiceActivities
            numSeaServices
            avatarUrl
            availableForWork
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const SEARCH_ONLY_PROFILES_ID_QUERY = graphql`
  query SearchQueriesIdOfProfilesQuery($after: Cursor, $first: Int, $query: String, $index: SearchIndex!, $filters: String, $skip: Boolean!) {
    search(after: $after, first: $first, query: $query, index: $index, filters: $filters) @skip(if: $skip) {
      totalCount
      edges {
        node {
          ... on SearchableProfile {
            objectID
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const SEARCH_VACANCIES_QUERY = graphql`
  query SearchQueriesVacanciesQuery($after: Cursor, $first: Int, $query: String, $index: SearchIndex!, $filters: String, $skip: Boolean!) {
    search(after: $after, first: $first, query: $query, index: $index, filters: $filters) @connection(key: "SearchVacanciesQueries_search") @skip(if: $skip) {
      totalCount
      edges {
        node {
          ... on SearchableVacancy {
            objectID
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const SEARCH_ORGANISATIONS_QUERY = graphql`
  query SearchQueriesOrganisationsQuery($after: Cursor, $first: Int, $query: String, $index: SearchIndex!, $filters: String, $skip: Boolean!) {
    search(after: $after, first: $first, query: $query, index: $index, filters: $filters) @connection(key: "SearchOrganisationsQueries_search") @skip(if: $skip) {
      totalCount
      edges {
        node {
          objectID
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
