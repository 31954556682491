// packages
import { useIntl } from 'react-intl';
// icons
import ShieldIcon from 'icons/ShieldIcon';
// models
import { CertificatesInfoProps } from 'models/modelsOfComponents';

const CertificatesInfo = ({ certificates }: CertificatesInfoProps) => {
  const intl = useIntl();
  const amountCertificates = (certificates?.length as number) - 3;

  return (
    <div className="flex flex-col justify-between lowercase text-sm text-darkBlue w-3/4">
      {certificates?.map(({ certificate, owner }, index) => {
        return index < 3 ? (
          <div className="mb-2 flex items-start w-full" key={`${certificate.id}-${owner.id}`}>
            <ShieldIcon className="min-w-[12px] h-3.5 mt-1" kind={certificate?.kind?.name} />
            <span className="ml-2 lg:truncate">{certificate?.name}</span>

            {/* show more */}
            {!!amountCertificates && index === 2 && (
              <div className="cursor-pointer text-sm text-darkBlue inline ml-2">
                <span>{intl.formatMessage({ id: 'profile_more_certificates' }, { amount: amountCertificates })}</span>
              </div>
            )}
          </div>
        ) : null;
      })}
    </div>
  );
};

export default CertificatesInfo;
