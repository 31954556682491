//packages
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { format, format as formatDate, parse, parseISO } from 'date-fns';
// components
import LabelComponent from 'system/LabelComponent/LabelComponent';
// models
import { FILTER_TYPE } from 'models/enums';
// hooks
import { useHover } from 'hooks/useHover';
import { useDateFilterAnalytics, useDeleteFilterAnalytics } from 'hooks/anaytics/useFiltersAnalytics';

const FilteringByDate = () => {
  const intl = useIntl();
  const refs = { day: useRef<HTMLInputElement>(null), month: useRef<HTMLInputElement>(null), year: useRef<HTMLInputElement>(null) };
  const { hovered, isHovered, unHovered } = useHover();
  const [searchParams, setSearchParams] = useSearchParams();
  const { sendDateFilterAnalytics } = useDateFilterAnalytics();
  const { deleteFilterAnalytics } = useDeleteFilterAnalytics();
  const [date, setDate] = useState<{ [key: string]: string }>({ day: '', month: '', year: '' });

  useEffect(() => {
    const getDateFromSearch: string = searchParams.get(FILTER_TYPE.STARTING_DATE) || '';
    if (getDateFromSearch) {
      setDate({
        day: formatDate(parseISO(getDateFromSearch), 'dd'),
        month: formatDate(parseISO(getDateFromSearch), 'MM'),
        year: formatDate(parseISO(getDateFromSearch), 'yyyy'),
      });
    }
  }, [searchParams]);

  const createSearchUrlWithDate = (date: string) => {
    const comparedSearchParas = Object.fromEntries([...searchParams]);
    setSearchParams({ ...comparedSearchParas, [FILTER_TYPE.STARTING_DATE]: date });
  };

  const [inputClasses] = useState<string>(
    'shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full text-sm border-specialGray-012 placeholder:text-specialGray-05 rounded-md text-darkBlue',
  );

  const handleDateChange = (currentField: 'day' | 'month' | 'year', length: number, nextField: 'month' | 'year' | null) => () => {
    if (!refs[currentField]?.current) {
      return;
    }
    const currentFieldLength: number = (refs[currentField]?.current as HTMLInputElement)?.value.length;

    if (currentFieldLength <= length) {
      setDate(prevState => ({ ...prevState, [currentField]: refs[currentField]?.current?.value || '' }));
    }

    const { d, m, y } = { d: refs.day.current?.value, m: refs.month.current?.value, y: refs.year.current?.value };

    if ((refs.year.current?.value.length as number) === 4) {
      sendDateFilterAnalytics.current(FILTER_TYPE.STARTING_DATE, { day: d, month: m, year: y });

      createSearchUrlWithDate(parse(`${d}-${m}-${y}`, 'dd-MM-yyyy', new Date()).toISOString());
    } else {
      searchParams.delete(FILTER_TYPE.STARTING_DATE);
      setSearchParams(searchParams);
    }

    if (!nextField) {
      return;
    }

    if (currentFieldLength >= length) {
      refs[nextField]?.current?.focus();
    }
  };

  const handleDeleteSelectedItem = () => {
    const date = { day: refs.day.current?.value, month: refs.month.current?.value, year: refs.year.current?.value };
    deleteFilterAnalytics(date, FILTER_TYPE.STARTING_DATE);

    searchParams.delete(FILTER_TYPE.STARTING_DATE);
    setSearchParams(searchParams);
    setDate({ day: '', month: '', year: '' });
    unHovered();
  };

  return (
    <div className="flex flex-col px-4 sm:px-8 py-8 border-b border-b-specialGray-012 gap-y-4">
      <LabelComponent label={{ id: 'hire_starting_date_label' }} classes="block text-sm font-medium text-darkBlue" />
      <div className="flex justify-between items-baseline w-full gap-x-4">
        <input
          type="number"
          placeholder={intl.formatMessage({ id: 'DD' })}
          className={inputClasses}
          ref={refs.day}
          onChange={handleDateChange('day', 2, 'month')}
          value={date.day}
        />

        <input
          type="number"
          placeholder={intl.formatMessage({ id: 'MM' })}
          className={inputClasses}
          ref={refs.month}
          onChange={handleDateChange('month', 2, 'year')}
          value={date.month}
        />

        <input
          type="number"
          placeholder={intl.formatMessage({ id: 'YYYY' })}
          className={inputClasses}
          ref={refs.year}
          onChange={handleDateChange('year', 4, null)}
          value={date.year}
        />
      </div>
      {searchParams.get(FILTER_TYPE.STARTING_DATE) ? (
        <div
          onClick={handleDeleteSelectedItem}
          className="flex w-fit p-1 pr-3 text-blue rounded-xl cursor-pointer bg-blue-100 text-sm items-center h-8"
          onMouseOver={hovered}
          onMouseLeave={unHovered}
        >
          <div className="bg-blue-200 rounded-full h-fit">{isHovered ? <XMarkIcon className="w-5 h-5" /> : <CheckIcon className="w-5 h-5" />}</div>

          <span className="ml-2 truncate">{format(new Date(searchParams.get(FILTER_TYPE.STARTING_DATE) as string), 'dd.MMM.yyyy')}</span>
        </div>
      ) : null}
    </div>
  );
};

export default FilteringByDate;
