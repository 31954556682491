const ContactPageIcon = () => {
  return (
    <svg className="fill-current" width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_10196_79149" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect className="fill-current" width="24" height="24" />
      </mask>
      <g mask="url(#mask0_10196_79149)">
        <path
          className="fill-current"
          d="M12 14C12.55 14 13.021 13.804 13.413 13.412C13.8043 13.0207 14 12.55 14 12C14 11.45 13.8043 10.979 13.413 10.587C13.021 10.1957 12.55 10 12 10C11.45 10 10.9793 10.1957 10.588 10.587C10.196 10.979 10 11.45 10 12C10 12.55 10.196 13.0207 10.588 13.412C10.9793 13.804 11.45 14 12 14ZM8 18H16V17.425C16 17.025 15.8917 16.6583 15.675 16.325C15.4583 15.9917 15.1583 15.7417 14.775 15.575C14.3417 15.3917 13.896 15.25 13.438 15.15C12.9793 15.05 12.5 15 12 15C11.5 15 11.0207 15.05 10.562 15.15C10.104 15.25 9.65833 15.3917 9.225 15.575C8.84167 15.7417 8.54167 15.9917 8.325 16.325C8.10833 16.6583 8 17.025 8 17.425V18ZM18 22H6C5.45 22 4.97933 21.8043 4.588 21.413C4.196 21.021 4 20.55 4 20V4C4 3.45 4.196 2.979 4.588 2.587C4.97933 2.19567 5.45 2 6 2H14L20 8V20C20 20.55 19.8043 21.021 19.413 21.413C19.021 21.8043 18.55 22 18 22Z"
        />
      </g>
    </svg>
  );
};

export default ContactPageIcon;
