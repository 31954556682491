import HeaderSkeleton from './components/HeaderSkeleton';
import AppraisalsListItem from './components/AppraisalsListItem';

const AppraisalsTabSkeleton = () => {
  return (
    <div className="flex flex-col px-6">
      <HeaderSkeleton widthBigTitle={135} />
      <AppraisalsListItem />
      <AppraisalsListItem />
      <AppraisalsListItem />
      <AppraisalsListItem />
    </div>
  );
};

export default AppraisalsTabSkeleton;
