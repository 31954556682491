/**
 * @generated SignedSource<<7b6bdd53cc015ef6dabe1ec70b521387>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SearchIndex = 'ORGANIZATION' | 'PROFILE' | 'VACANCY' | '%future added value';
export type SearchQueriesQuery$variables = {
  after?: any | null;
  filters?: string | null;
  first?: number | null;
  index: SearchIndex;
  query?: string | null;
  skip: boolean;
};
export type SearchQueriesQuery$data = {
  readonly search?: {
    readonly edges: ReadonlyArray<{
      readonly cursor: any;
      readonly node: {
        readonly availableForWork?: boolean;
        readonly avatarUrl?: string;
        readonly birthDate?: number;
        readonly certificates?: ReadonlyArray<string> | null;
        readonly country?: string;
        readonly firstName?: string;
        readonly lastName?: string;
        readonly numSeaServices?: number;
        readonly objectID?: string;
        readonly primaryDuty?: string | null;
        readonly seaServiceActivities?: ReadonlyArray<string> | null;
        readonly seaServiceDuties?: ReadonlyArray<string> | null;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: any | null;
      readonly hasNextPage: boolean;
    };
    readonly totalCount: number;
  } | null;
};
export type SearchQueriesQuery = {
  response: SearchQueriesQuery$data;
  variables: SearchQueriesQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'after',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'filters',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'first',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'index',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'query',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'skip',
    },
    v6 = {
      kind: 'Variable',
      name: 'filters',
      variableName: 'filters',
    },
    v7 = {
      kind: 'Variable',
      name: 'index',
      variableName: 'index',
    },
    v8 = {
      kind: 'Variable',
      name: 'query',
      variableName: 'query',
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'totalCount',
      storageKey: null,
    },
    v10 = {
      kind: 'InlineFragment',
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'objectID',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'firstName',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'lastName',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'primaryDuty',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'seaServiceDuties',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'birthDate',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'certificates',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'country',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'seaServiceActivities',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'numSeaServices',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'avatarUrl',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'availableForWork',
          storageKey: null,
        },
      ],
      type: 'SearchableProfile',
      abstractKey: null,
    },
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: '__typename',
      storageKey: null,
    },
    v12 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'cursor',
      storageKey: null,
    },
    v13 = {
      alias: null,
      args: null,
      concreteType: 'PageInfo',
      kind: 'LinkedField',
      name: 'pageInfo',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'hasNextPage',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'endCursor',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v14 = [
      {
        kind: 'Variable',
        name: 'after',
        variableName: 'after',
      },
      v6 /*: any*/,
      {
        kind: 'Variable',
        name: 'first',
        variableName: 'first',
      },
      v7 /*: any*/,
      v8 /*: any*/,
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/, v5 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'SearchQueriesQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: 'search',
              args: [v6 /*: any*/, v7 /*: any*/, v8 /*: any*/],
              concreteType: 'SearchConnection',
              kind: 'LinkedField',
              name: '__SearchQueries_search_connection',
              plural: false,
              selections: [
                v9 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'SearchEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: null,
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [v10 /*: any*/, v11 /*: any*/],
                      storageKey: null,
                    },
                    v12 /*: any*/,
                  ],
                  storageKey: null,
                },
                v13 /*: any*/,
              ],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v0 /*: any*/, v2 /*: any*/, v4 /*: any*/, v3 /*: any*/, v1 /*: any*/, v5 /*: any*/],
      kind: 'Operation',
      name: 'SearchQueriesQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v14 /*: any*/,
              concreteType: 'SearchConnection',
              kind: 'LinkedField',
              name: 'search',
              plural: false,
              selections: [
                v9 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'SearchEdge',
                  kind: 'LinkedField',
                  name: 'edges',
                  plural: true,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: null,
                      kind: 'LinkedField',
                      name: 'node',
                      plural: false,
                      selections: [v11 /*: any*/, v10 /*: any*/],
                      storageKey: null,
                    },
                    v12 /*: any*/,
                  ],
                  storageKey: null,
                },
                v13 /*: any*/,
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: v14 /*: any*/,
              filters: ['query', 'index', 'filters'],
              handle: 'connection',
              key: 'SearchQueries_search',
              kind: 'LinkedHandle',
              name: 'search',
            },
          ],
        },
      ],
    },
    params: {
      cacheID: '13bbbfb8cf66b48c0b7c4917da325ebe',
      id: null,
      metadata: {
        connection: [
          {
            count: 'first',
            cursor: 'after',
            direction: 'forward',
            path: ['search'],
          },
        ],
      },
      name: 'SearchQueriesQuery',
      operationKind: 'query',
      text: 'query SearchQueriesQuery(\n  $after: Cursor\n  $first: Int\n  $query: String\n  $index: SearchIndex!\n  $filters: String\n  $skip: Boolean!\n) {\n  search(after: $after, first: $first, query: $query, index: $index, filters: $filters) @skip(if: $skip) {\n    totalCount\n    edges {\n      node {\n        __typename\n        ... on SearchableProfile {\n          objectID\n          firstName\n          lastName\n          primaryDuty\n          seaServiceDuties\n          birthDate\n          certificates\n          country\n          seaServiceActivities\n          numSeaServices\n          avatarUrl\n          availableForWork\n        }\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'fd59a199d9d2c9e5755266e4e5c6b4b8';

export default node;
