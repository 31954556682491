const LocationPink = () => {
  return (
    <svg width="57" height="50" viewBox="0 0 57 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.144 12.7539C15.9542 12.7539 10.8369 17.7034 10.8369 23.8271C10.8369 26.9129 11.9578 29.7777 13.8063 32.2059C15.8266 34.8844 18.3383 37.218 21.1815 39.0498C21.8916 39.5051 22.4756 39.4731 23.1226 39.0498C25.9497 37.218 28.4614 34.8844 30.4992 32.2059C32.3463 29.7777 33.4686 26.9129 33.4686 23.8271C33.4686 17.7034 28.3513 12.7539 22.144 12.7539Z"
        fill="#FE40F6"
      />
      <g opacity="0.5" filter="url(#filter0_f_9266_78531)">
        <path
          d="M22.1714 18.9688C18.8224 18.9688 16.0537 21.6092 16.0537 24.8761C16.0537 26.5223 16.6602 28.0506 17.6603 29.3459C18.7534 30.7748 20.1123 32.0198 21.6507 32.997C22.0349 33.2399 22.3508 33.2228 22.7009 32.997C24.2305 32.0198 25.5895 30.7748 26.692 29.3459C27.6914 28.0506 28.2986 26.5223 28.2986 24.8761C28.2986 21.6092 25.5299 18.9688 22.1714 18.9688Z"
          fill="#FF00E5"
        />
      </g>
      <g filter="url(#filter1_b_9266_78531)">
        <path
          d="M36.15 1C26.8508 1 19.1631 8.43578 19.1631 17.6355C19.1631 22.2714 20.8471 26.5753 23.6241 30.2231C26.6593 34.247 30.4326 37.7529 34.704 40.5049C35.7708 41.1888 36.6481 41.1408 37.6202 40.5049C41.8674 37.7529 45.6407 34.247 48.7021 30.2231C51.4771 26.5753 53.1631 22.2714 53.1631 17.6355C53.1631 8.43578 45.4753 1 36.15 1Z"
          fill="#FE3ED4"
          fillOpacity="0.35"
        />
        <path
          d="M34.4332 40.9252L34.4341 40.9258C35.0247 41.3044 35.6058 41.5074 36.2039 41.4998C36.8008 41.4922 37.3548 41.2758 37.8928 40.924C42.1884 38.1406 46.004 34.5952 49.1 30.5259L49.1 30.5259C51.9307 26.8048 53.6631 22.3969 53.6631 17.6355C53.6631 8.14755 45.7392 0.5 36.15 0.5C26.5864 0.5 18.6631 8.14804 18.6631 17.6355C18.6631 22.3965 20.3931 26.8041 23.2257 30.5253C26.2963 34.5958 30.1128 38.1417 34.4332 40.9252Z"
          stroke="url(#paint0_linear_9266_78531)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#filter2_bd_9266_78531)">
        <mask id="path-5Inside-1_9266_78531" fill="white">
          <path d="M36.0271 23.6565C32.9436 23.6565 30.4287 21.2325 30.4287 18.1506C30.4287 15.0927 32.9436 12.5508 36.0271 12.5508C39.1127 12.5508 41.6518 15.0927 41.6518 18.1506C41.6518 21.2325 39.1127 23.6565 36.0271 23.6565Z" />
        </mask>
        <path
          d="M36.0271 23.6565C32.9436 23.6565 30.4287 21.2325 30.4287 18.1506C30.4287 15.0927 32.9436 12.5508 36.0271 12.5508C39.1127 12.5508 41.6518 15.0927 41.6518 18.1506C41.6518 21.2325 39.1127 23.6565 36.0271 23.6565Z"
          fill="url(#paint1_linear_9266_78531)"
        />
        <path
          d="M36.0271 23.4565C33.0497 23.4565 30.6287 21.1178 30.6287 18.1506H30.2287C30.2287 21.3473 32.8375 23.8565 36.0271 23.8565V23.4565ZM30.6287 18.1506C30.6287 15.2025 33.0547 12.7508 36.0271 12.7508V12.3508C32.8325 12.3508 30.2287 14.9829 30.2287 18.1506H30.6287ZM36.0271 12.7508C39.0028 12.7508 41.4518 15.2037 41.4518 18.1506H41.8518C41.8518 14.9817 39.2227 12.3508 36.0271 12.3508V12.7508ZM41.4518 18.1506C41.4518 21.1166 39.0078 23.4565 36.0271 23.4565V23.8565C39.2177 23.8565 41.8518 21.3484 41.8518 18.1506H41.4518Z"
          fill="url(#paint2_linear_9266_78531)"
          fillOpacity="0.5"
          mask="url(#path-5Inside-1_9266_78531)"
        />
      </g>
      <defs>
        <filter id="filter0_f_9266_78531" x="0.0537109" y="2.96875" width="44.2451" height="46.2031" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_9266_78531" />
        </filter>
        <filter id="filter1_b_9266_78531" x="-5.83691" y="-24" width="84" height="90" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_9266_78531" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_9266_78531" result="shape" />
        </filter>
        <filter id="filter2_bd_9266_78531" x="15.4287" y="-2.44922" width="41.2227" height="41.1055" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_9266_78531" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.885075 0 0 0 0 0.15375 0 0 0 0 0.9 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_9266_78531" result="effect2_dropShadow_9266_78531" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_9266_78531" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_9266_78531" x1="24.5753" y1="5.66005" x2="50.6427" y2="33.1023" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear_9266_78531" x1="40.8561" y1="14.5562" x2="28.3549" y2="14.9681" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint2_linear_9266_78531" x1="31.6377" y1="16.2762" x2="41.1676" y2="16.3294" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LocationPink;
