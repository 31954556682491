import DateInputSkeleton from 'system/skeletons/components/DateInputSkeleton';

const RangeDateInputSkeleton = () => {
  return (
    <div className="flex">
      <DateInputSkeleton labelWidth={35} wrapperClasses="flex flex-col w-1/2" />
      <div className="flex flex-col justify-end mx-3.5 text-specialGray-03 hidden sm:flex">
        <span className="mb-[7px]">&#9472;</span>
      </div>
      <DateInputSkeleton labelWidth={35} wrapperClasses="flex flex-col w-1/2" />
    </div>
  );
};

export default RangeDateInputSkeleton;
