import React from 'react';

const CertificateFileIcon: React.FC = () => {
  return (
    <svg className="mx-auto" width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.4745 4.60887C33.3758 4.59123 33.2742 4.58203 33.1703 4.58203C33.0665 4.58203 32.9649 4.59123 32.8661 4.60887H18.5255C12.8255 4.60887 8.02051 9.23306 8.02051 14.8732V39.4239C8.02051 45.3835 12.6144 50.1053 18.5255 50.1053H36.8336C42.4558 50.1053 47.098 45.1509 47.098 39.4239V18.4185C47.098 17.9748 46.9264 17.5482 46.6191 17.2282L35.0118 5.13732C34.6877 4.79971 34.24 4.60887 33.772 4.60887H33.4745ZM31.4516 8.04443L18.5255 8.04627C14.6951 8.04627 11.458 11.1616 11.458 14.8731V39.4238C11.458 43.5057 14.5343 46.6677 18.5255 46.6677H36.8336C40.5055 46.6677 43.6605 43.3005 43.6605 39.4238L43.659 20.5849L41.6531 20.5918C40.8885 20.5909 40.0188 20.5892 39.0517 20.587C34.9965 20.5785 31.687 17.4 31.4636 13.399L31.4516 12.9672V8.04443ZM41.778 17.1492L39.0593 17.1495C36.755 17.1447 34.8891 15.2737 34.8891 12.9672V9.97339L41.778 17.1492ZM32.735 33.9328C33.6843 33.9328 34.4538 34.7023 34.4538 35.6516C34.4538 36.5217 33.8072 37.2408 32.9683 37.3546L32.735 37.3703H20.3669C19.4177 37.3703 18.6482 36.6008 18.6482 35.6516C18.6482 34.7814 19.2948 34.0623 20.1337 33.9485L20.3669 33.9328H32.735ZM29.7747 24.3028C29.7747 23.3536 29.0052 22.5841 28.056 22.5841H20.3652L20.1319 22.5998C19.293 22.7136 18.6464 23.4327 18.6464 24.3028C18.6464 25.2521 19.4159 26.0216 20.3652 26.0216H28.056L28.2892 26.0059C29.1281 25.8921 29.7747 25.173 29.7747 24.3028Z"
        fill="#2A6FF5"
      />
    </svg>
  );
};

export default CertificateFileIcon;
