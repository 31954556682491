/**
 * @generated SignedSource<<d075f687a40c6c79ef20046f584bd6a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ServiceMutationsAddTagsToSeaServiceMutation$variables = {
  sid: string;
  tags: ReadonlyArray<string>;
};
export type ServiceMutationsAddTagsToSeaServiceMutation$data = {
  readonly setActivitiesForSeaService: {
    readonly activities: ReadonlyArray<{
      readonly id: string;
      readonly label: string;
    }> | null;
    readonly id: string;
  };
};
export type ServiceMutationsAddTagsToSeaServiceMutation = {
  response: ServiceMutationsAddTagsToSeaServiceMutation$data;
  variables: ServiceMutationsAddTagsToSeaServiceMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'sid',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'tags',
      },
    ],
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v2 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'activities',
            variableName: 'tags',
          },
          {
            kind: 'Variable',
            name: 'sid',
            variableName: 'sid',
          },
        ],
        concreteType: 'SeaService',
        kind: 'LinkedField',
        name: 'setActivitiesForSeaService',
        plural: false,
        selections: [
          v1 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'Activity',
            kind: 'LinkedField',
            name: 'activities',
            plural: true,
            selections: [
              v1 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'label',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ServiceMutationsAddTagsToSeaServiceMutation',
      selections: v2 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ServiceMutationsAddTagsToSeaServiceMutation',
      selections: v2 /*: any*/,
    },
    params: {
      cacheID: 'f690ae2068330ece392e93e446c6c135',
      id: null,
      metadata: {},
      name: 'ServiceMutationsAddTagsToSeaServiceMutation',
      operationKind: 'mutation',
      text: 'mutation ServiceMutationsAddTagsToSeaServiceMutation(\n  $sid: ID!\n  $tags: [String!]!\n) {\n  setActivitiesForSeaService(sid: $sid, activities: $tags) {\n    id\n    activities {\n      id\n      label\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '6294ebe1bdc8c18827c0d4e34b26dd54';

export default node;
