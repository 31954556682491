// packages
import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
// routing
import { RoutePaths } from 'app/routing';
// components
import { Button } from 'system/Button';
import ShareIcon from 'icons/ShareIcon';
import PublishIcon from 'icons/PublishIcon';
import ArrowDownTray from 'icons/ArrowDownTray';
import ThreeDotsPopoverItem from './ThreeDotsPopoverItem';
import LittlePopoverWrapper from 'system/LittlePopoverWrapper/LittlePopoverWrapper';
import PaymentSubscriptionProLabel from 'app/ProfileEmployer/HireProduct/components/PaymentSubscriptionProLabel';
// models
import { VACANCY_STATUS } from 'models/enums';
// icons
import CloseButtonIcon from 'icons/CloseButtonIcon';
import { TrashIcon } from '@heroicons/react/24/outline';
import CombinedShapeIcon from 'icons/CombinedShapeIcon';
import EditIconWithUnderline from 'icons/EditIconWithUnderline';
// hooks
import useDeleteVacancy from 'hooks/useDeleteVacancy';
import { useSwitchVacancyStatus } from 'hooks/useSwitchVacancyStatus';
// generated
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
//types
import { ThreeDotsPopoverProps } from 'app/ProfileEmployer/components/JobDescriptionPage/types';
// recoil
import { paymentSubscriptionModalAtom } from 'recoil/ContactInformation/atoms/paymentSubscriptionModalAtom';
import { RestrictedFromPublishJobAtom } from 'recoil/RestrictedFromPublishJob/atoms/RestrictedFromPublishJobAtom';

const ThreeDotsPopover = ({ defaultOpenDropdown, onToggleShowShareModal, vacancyFragment, withoutEditOption = false }: ThreeDotsPopoverProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(defaultOpenDropdown);

  const setShowPaymentSubscriptionModal = useSetRecoilState(paymentSubscriptionModalAtom);
  const isRestrictedFromPublishClosedJob = useRecoilValue(RestrictedFromPublishJobAtom);

  const { vacancy, handleSwitchStatus } = useSwitchVacancyStatus(vacancyFragment as VacancyFragments$key, () => setIsOpenPopover(false));
  const { handleDeleteVacancy } = useDeleteVacancy(vacancyFragment, () => navigate(`${RoutePaths.ProfileOrganizationBase}/${vacancy?.organization?.id}`));

  const handleClickByEdit = () => {
    navigate(`${RoutePaths.ProfileOrganizationBase}/${vacancy.organization.id}/${RoutePaths.JobOpeningStep1}`, {
      state: {
        organization: {
          id: vacancy?.organization?.id as string,
          vacancyId: vacancy?.id,
          positionTitle: vacancy?.title,
        },
        prevPath: pathname,
      },
    });
  };

  const handleToggleModal = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpenPopover(prevState => !prevState);
  }, []);

  const handleClickByShareButton = () => {
    onToggleShowShareModal(true);
    setIsOpenPopover(false);
  };
  const onHandleClickPublish = () => {
    if (isRestrictedFromPublishClosedJob) {
      setShowPaymentSubscriptionModal({ showPaymentSubscriptionModal: true });
      return;
    }
    handleSwitchStatus(VACANCY_STATUS.OPEN)();
  };

  const publishClasses = isRestrictedFromPublishClosedJob ? 'items-center text-specialPurple font-medium' : '';
  return (
    <div className="relative">
      <Button buttonType="just-icon-rounded-xl" icon={CombinedShapeIcon} additionalClasses="w-10 h-10" onClick={handleToggleModal} />
      <LittlePopoverWrapper additionalClasses="left-[-134px]" isOpen={isOpenPopover} setIsOpen={setIsOpenPopover}>
        {withoutEditOption ? null : (
          <ThreeDotsPopoverItem title={{ id: 'label_edit_button' }} onClickFunction={handleClickByEdit}>
            <EditIconWithUnderline />
          </ThreeDotsPopoverItem>
        )}
        {vacancy?.status !== VACANCY_STATUS.OPEN && (
          <ThreeDotsPopoverItem additionalClasses={publishClasses} title={{ id: 'publish' }} onClickFunction={onHandleClickPublish}>
            {isRestrictedFromPublishClosedJob ? (
              <>
                <PublishIcon />
                <PaymentSubscriptionProLabel className="ml-2 order-last" />
              </>
            ) : (
              <ArrowDownTray additionalClasses="w-4 h-4 rotate-180 text-specialGray-05 self-center" />
            )}
          </ThreeDotsPopoverItem>
        )}
        <ThreeDotsPopoverItem additionalClasses="[&>svg]:text-xl [&>svg]:text-specialGray-05" title={{ id: 'hire_button_label_share' }} onClickFunction={handleClickByShareButton}>
          <ShareIcon />
        </ThreeDotsPopoverItem>

        {vacancy?.status !== VACANCY_STATUS.CLOSED && (
          <ThreeDotsPopoverItem title={{ id: 'hire_button_label_close' }} onClickFunction={handleSwitchStatus(VACANCY_STATUS.CLOSED)}>
            <CloseButtonIcon />
          </ThreeDotsPopoverItem>
        )}
        <ThreeDotsPopoverItem title={{ id: 'delete' }} modalEventFunction={handleDeleteVacancy()}>
          <TrashIcon className="w-5 h-5 text-specialGray-05" />
        </ThreeDotsPopoverItem>
      </LittlePopoverWrapper>
    </div>
  );
};

export default ThreeDotsPopover;
