import { useMemo } from 'react';
// packages
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
// components
import NoSearchResult from '../NoSearchResult';
import ProfileEmptyIcon from 'icons/ProfileEmptyIcon';
import MembersDropDownListItem from './DropDownListItem';
import DropDownHeader from 'app/Navbar/components/SearchInput/components/DropDownHeader';
// schemas
import { SEARCH_ONLY_PROFILES_ID_QUERY } from 'schemas/search/SearchQueries';
import { GET_PROFILE_SEARCH_INFO_MEMBERSHIP } from 'schemas/profile/ProfileQueries';
// generated
import { SearchQueriesIdOfProfilesQuery } from 'schemas/search/__generated__/SearchQueriesIdOfProfilesQuery.graphql';
import { ProfileFragmentsSearchDropDown$key } from 'schemas/profile/__generated__/ProfileFragmentsSearchDropDown.graphql';
import { ProfileQueriesGetProfileInfoMembershipQuery } from 'schemas/profile/__generated__/ProfileQueriesGetProfileInfoMembershipQuery.graphql';
// routing
import { RoutePaths } from 'app/routing';
// enums
import { SEARCH_INDEX } from 'models/enums';
// context
import { useNavbarSearchContext } from 'Context/NavbarSearchContext';

const MembersDropDown = ({ setShowDropDown }: { setShowDropDown: () => void }) => {
  const navigate = useNavigate();
  const { queryParams } = useNavbarSearchContext();

  const getSearchedProfiles = useLazyLoadQuery<SearchQueriesIdOfProfilesQuery>(SEARCH_ONLY_PROFILES_ID_QUERY, {
    first: 3,
    query: queryParams,
    index: SEARCH_INDEX.PROFILE,
    skip: !queryParams,
  });

  const allSearchableProfileIds = useMemo(() => {
    if (!getSearchedProfiles?.search?.edges) {
      return [];
    }
    return getSearchedProfiles?.search?.edges.reduce((acc: string[], item) => {
      if (item?.node?.objectID) {
        acc.push(item.node.objectID);
      }
      return acc;
    }, []);
  }, [getSearchedProfiles?.search?.edges]);

  const profiles = useLazyLoadQuery<ProfileQueriesGetProfileInfoMembershipQuery>(GET_PROFILE_SEARCH_INFO_MEMBERSHIP, { ids: allSearchableProfileIds });

  const handleNavigationToSearchPage = () => {
    window.analytics?.track('wui-show-all-in-search-bar');

    setShowDropDown();
    navigate(RoutePaths.SearchProfileBase, {
      state: { queryParams },
    });
  };

  return (
    <>
      <DropDownHeader
        label={{ id: 'see_all_crew' }}
        counter={getSearchedProfiles.search?.totalCount}
        onClick={handleNavigationToSearchPage}
        isShowSeeAllButton={!!getSearchedProfiles.search?.totalCount}
      />
      {!isEmpty(profiles.nodes) ? (
        <>
          {profiles.nodes.map(profile => {
            return profile ? <MembersDropDownListItem key={profile?.id} setShowDropDown={setShowDropDown} profile={profile as ProfileFragmentsSearchDropDown$key} /> : null;
          })}
        </>
      ) : (
        <NoSearchResult message={{ id: 'search_input_no_profiles_results' }} showBorderBottom>
          <ProfileEmptyIcon defaultClasses="w-12 h-8" />
        </NoSearchResult>
      )}
    </>
  );
};

export default MembersDropDown;
