// packages
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// components
import { Modal } from '../Modal';
import UploadIcon from 'icons/UploadIcon';
import UploadWhiteIcon from 'icons/UploadWhiteIcon';
import ModalWithAttachedFiles from './components/ModalWithAttachedFiles';
// models
import { typeCertificateState } from 'models/routeLocationState';
// hooks
import HighlightElementWrapper from 'system/wrappers/HighlightElementWrapper';
import { useGetProfileIdFromAuthOrParams } from 'hooks/useGetProfileIdFromAuthOrParams';
//types
import { AttachedFileComponentProps } from './types';

const AttachedFileComponent = ({
  modalTitle,
  src,
  alt,
  defaultOpenAddMoreInfoModal,
  onAddAttachedFile,
  onDeleteFile,
  document,
  showModalWithSingleDocument,
}: AttachedFileComponentProps) => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const getProfileIdForTab = useGetProfileIdFromAuthOrParams();

  const [mouseAboveImage, setMouseAboveImage] = useState<boolean>(false);
  const [isOpenAddMoreInfoModal, setIsOpenAddMoreInfoModal] = useState<boolean>(defaultOpenAddMoreInfoModal || false);

  const handleMouseOver = () => {
    setMouseAboveImage(true);
  };
  const handleMouseLeave = () => {
    setMouseAboveImage(false);
  };

  const handleOpenAddMoreInfoModal = useCallback(() => {
    setIsOpenAddMoreInfoModal(true);
  }, []);

  const handleCloseAddMoreInfoModal = useCallback(() => {
    setIsOpenAddMoreInfoModal(false);
    navigate(pathname, {
      replace: true,
      state: null,
    });
  }, [navigate, pathname]);

  return (
    <>
      <Modal title={modalTitle} onClose={handleCloseAddMoreInfoModal} description={{ id: 'certificate_attached_file_modal_description' }} show={isOpenAddMoreInfoModal}>
        <ModalWithAttachedFiles
          modalTitle={modalTitle}
          onCloseModal={handleCloseAddMoreInfoModal}
          onAddAttachedFile={onAddAttachedFile}
          document={document}
          onDeleteFile={onDeleteFile}
          showModalWithSingleDocument={showModalWithSingleDocument}
        />
      </Modal>
      {src ? (
        <div
          className={`h-full w-full ${!!getProfileIdForTab ? 'cursor-pointer' : ''} relative flex justify-center align-center border border-solid border-specialGray-05 rounded-md`}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          onClick={handleOpenAddMoreInfoModal}
        >
          <img className="h-full w-full rounded-md" src={src} alt={alt} />
          {mouseAboveImage && !!getProfileIdForTab && (
            <button onClick={handleOpenAddMoreInfoModal} className="w-full h-full absolute top-0 left-0 bg-gray-400 text-specialGray-05 opacity-80 rounded-md">
              <UploadWhiteIcon />
            </button>
          )}
        </div>
      ) : (
        <HighlightElementWrapper enableFlickering={!document && (state as typeCertificateState)?.attachFile} addPadding="outline-offset-4">
          <div className="w-7 h-7 flex justify-center items-center">
            <button onClick={handleOpenAddMoreInfoModal} className="w-6 h-6 text-specialGray-05 border-specialGray-05 hover:text-specialGray-1">
              <UploadIcon />
            </button>
          </div>
        </HighlightElementWrapper>
      )}
    </>
  );
};

export default AttachedFileComponent;
