import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
//components
import CountryFlag from 'system/CountryFlag';
import CustomButton from 'system/Buttons/CustomButton';
import DeleteStrictIcon from 'icons/DeleteStrictIcon';
import { ProfileAvatar } from 'app/Avatars/ProfileAvatar/ProfileAvatar';
//types
import { useFragment } from 'react-relay';
import { ORGANIZATION_MEMBER_INFO_FRAGMENT } from 'schemas/profile/ProfileFragments';
//generated
import { ProfileFragmentsOrganizationMemberInfo$key } from 'schemas/profile/__generated__/ProfileFragmentsOrganizationMemberInfo.graphql';
//utils
import { getUserYears } from 'utils';
// hooks
import useGetRestrictedProfileLastName from 'hooks/ProfileDataHooks/useGetRestrictedProfileLastName';

const EmployerOrganizationListItem = ({
  handleDeleteMemberModal,
  memberProfileFragment,
}: {
  memberProfileFragment: ProfileFragmentsOrganizationMemberInfo$key;
  handleDeleteMemberModal: (id?: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  const intl = useIntl();

  const memberProfile = useFragment<ProfileFragmentsOrganizationMemberInfo$key>(ORGANIZATION_MEMBER_INFO_FRAGMENT, memberProfileFragment);
  const profileInfo = memberProfile?.profile?.info;
  const lastName = useGetRestrictedProfileLastName(profileInfo?.lastName);

  const isOwnerProfile = memberProfile.role === 'owner';
  const isInvited = memberProfile.role === 'invited';

  const vesselsCount = useMemo(() => {
    const vesselsImoArr: number[] = [];
    memberProfile?.profile?.seaServices?.forEach(seaItem => {
      if (!vesselsImoArr.includes(seaItem.vessel.imo)) vesselsImoArr.push(seaItem.vessel.imo);
    });
    return vesselsImoArr.length;
  }, [memberProfile?.profile?.seaServices]);

  return (
    <div className="flex rounded-2xl border border-specialGray-012 p-4 items-center gap-x-4 bg-white">
      <ProfileAvatar size="w-14 h-14" avatarUrl={profileInfo?.avatarUrl} />
      <div className="flex flex-col md:flex-row w-full justify-between text-sm">
        <div className="flex flex-col w-fit ml-2 text-lg">
          <div className="flex flex-col">
            <div className="flex  flex-wrap gap-x-4 items-center mb-3">
              {isInvited && (
                <div className="flex uppercase border border-green-500 text-green-500 text-[10px] h-5 w-16 items-center justify-center rounded-md font-semibold">
                  {intl.formatMessage({ id: 'hire_job_invited_label' })}
                </div>
              )}
              <span className="text-md font-medium">
                {profileInfo ? `${profileInfo?.firstName} ${lastName}` : intl.formatMessage({ id: 'hire_organization_member_id' }, { value: memberProfile.id })}
              </span>
              {profileInfo?.primaryDuty?.name && <span className="text-base font-medium text-blue">{profileInfo?.primaryDuty.name}</span>}
              {profileInfo?.birthDate && (
                <span className="text-specialGray-075 text-xs">{intl.formatMessage({ id: 'short_years_old' }, { years: getUserYears(profileInfo.birthDate as string) })}</span>
              )}
              {profileInfo?.country?.isoCode && <CountryFlag size="w-[17px] h-3.5" countryName={profileInfo?.country?.name} countryId={profileInfo?.country?.isoCode} />}
            </div>

            <div className="text-sm space-x-4 text-specialGray-075">
              <span>{intl.formatMessage({ id: 'profile_vesselsAmount' }, { amount: vesselsCount })}</span>
              <span>{intl.formatMessage({ id: 'profile_seaServiceAmount' }, { amount: memberProfile?.profile?.seaServices?.length || 0 })}</span>
              <span>{intl.formatMessage({ id: 'profile_certificatesAmount' }, { amount: memberProfile?.profile?.certificateInfos?.length })}</span>
              <span>{intl.formatMessage({ id: 'profile_educationAmount' }, { amount: memberProfile?.profile?.educations?.length })}</span>
            </div>
          </div>
        </div>

        {!isOwnerProfile &&
          (isInvited ? (
            <CustomButton
              onClick={handleDeleteMemberModal(memberProfile?.id)}
              className="flex w-full mt-2 md:mt-0 md:w-[86px] h-10 items-center justify-center text-specialGray-075 border border-grayIcon rounded-[10px] font-medium self-center ml-2 hover:bg-specialGray-002"
            >
              {intl.formatMessage({ id: 'hire_job_description_un_invite_title' })}
            </CustomButton>
          ) : (
            <CustomButton
              onClick={handleDeleteMemberModal(memberProfile?.id)}
              className="flex w-12 h-10 mt-2 md:mt-0 border border-grayIcon items-center justify-center rounded-[10px] text-specialGray-075 hover:text-blue self-center [&>svg]:hover:text-blue"
            >
              <DeleteStrictIcon className="w-6 h-6 text-specialGray-05" />
            </CustomButton>
          ))}
      </div>
    </div>
  );
};

export default EmployerOrganizationListItem;
