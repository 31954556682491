// packages
import { useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { RoutePaths } from 'app/routing';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// components
import SlideOver from 'system/SlideOver';
import ProfileSearchListItem from 'app/ProfileSearch/components/ProfileListItem/ProfileSearchListItem';
import ProfileContactInfoCommon from 'app/ProfileSearch/components/ProfileContactInfoCommon/ProfileContactInfoCommon';
import ButtonProfileSearchNextPage from 'app/ProfileSearch/components/ButtonProfileSearchNextPage/ButtonProfileSearchNextPage';
// models
import { SearchProfileNode } from 'models/IProfiles';
import { ProfileSearchItemState } from 'models/routeLocationState';
// hooks
import useSearchProfileFilterQuery from 'hooks/searchFilter/useSearchProfileFilterQuery';
import useInvitedProfilesToVacancies from 'hooks/hireProfileHooks/useInvitedProfilesToVacancies';
// context
import { useNavbarSearchContext } from 'Context/NavbarSearchContext';
import { useAuth } from 'authentication';
// recoil
import { profileSearchScrollWithPaginationSelector } from 'recoil/ScrollWithPagination/selectors/SearchScrollWithPaginationSelector';
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';

const ProfileSearchMembers = () => {
  const intl = useIntl();
  const { identity } = useAuth();
  const navigate = useNavigate();
  const { state, search, pathname } = useLocation();
  const { queryParams } = useNavbarSearchContext();

  const { listOfProfiles } = useSearchProfileFilterQuery(null);
  const allInvitedToVacanciesIDs = useInvitedProfilesToVacancies();

  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);

  const getScrollWithPagination = useRecoilValue(profileSearchScrollWithPaginationSelector);

  const allListWithProfiles = listOfProfiles.search?.edges;
  const hasNextPage = listOfProfiles.search?.pageInfo.hasNextPage;

  const handleCloseSlide = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    // save previous scroll place for Safari browser after Slider closing
    window.scrollTo(0, getScrollWithPagination.scrollY);
  }, [getScrollWithPagination.scrollY, pathname]);

  const handleOpenSlide = useCallback(
    (profileId: string) => () => {
      window.analytics?.track('wui-open-profile-in-side-drawer', {
        openedProfileId: profileId,
      });

      if (profileId === `${identity?.profileId}`) {
        navigate(RoutePaths.ProfileBase);
        return;
      }

      navigate(
        { pathname: `show/${profileId}`, search },
        {
          state: {
            queryParams: queryParams,
            isShowDropDown: false,
            isOpenProfileSlide: true,
          },
        },
      );
    },
    [identity?.profileId, navigate, search, queryParams],
  );
  return (
    <>
      <SlideOver show={!!(state as ProfileSearchItemState)?.isOpenProfileSlide} onClose={handleCloseSlide} title={{ id: 'label_profile' }}>
        <Outlet />
      </SlideOver>

      {getCurrentOrganisation.isSelected ? <ProfileContactInfoCommon /> : null}

      <div className="flex flex-col w-full">
        {!isEmpty(allListWithProfiles) && allListWithProfiles ? (
          <>
            {allListWithProfiles.map(profile => {
              return (
                <ProfileSearchListItem
                  isInvitedProfileEvenForOneOfVacancies={allInvitedToVacanciesIDs.includes(`${profile?.node?.objectID}`)}
                  key={profile?.node?.objectID}
                  profile={profile?.node as SearchProfileNode}
                  onClick={handleOpenSlide(profile?.node?.objectID as string)}
                />
              );
            })}
            {hasNextPage && <ButtonProfileSearchNextPage totalNumberOfUsers={listOfProfiles.search?.edges?.length} />}
          </>
        ) : (
          <div className="mx-auto sm:w-30 w-full text-center text-darkBlue">{intl.formatMessage({ id: 'search_by_duty_did_not_any_results' })}</div>
        )}
      </div>
    </>
  );
};

export default ProfileSearchMembers;
