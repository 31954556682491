export const productAnalyticsEvents = {
  openModal: 'wui-hire-open-payment-subscription-modal',
  autoOpenExpiredModal: 'wui-hire-open-payment-subscription-after-trial-modal',
  closeModal: 'wui-hire-opened-payment-subscription-modal-click-cancel',

  upgradeSubscription: 'wui-hire-opened-payment-subscription-modal-click-upgrade',
  sendEmail: 'wui-hire-opened-payment-subscription-modal-click-email',
  bookMeeting: 'wui-hire-opened-payment-subscription-modal-click-meeting',
  expiredToProfilePage: 'wui-hire-after-trial-payment-subscription-go-to-profile-page',
};

export const onHandleSendProductAnalytics = (eventName: string, organizationId: string | null, profileId: string | number | null, email: string) => () => {
  window.analytics?.track(eventName, {
    organizationId,
    profileId,
    email,
  });
};
