// packages
import { Suspense } from 'react';
import { isEmpty } from 'lodash';
// components
import SpinnerIcon from 'icons/SpinnerIcon';
import { LazyLoader } from 'system/skeletons/wrapper/LazyLoader';
import ItemForListOfVacancies from './components/ItemForListOfVacancies';
// generated
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
// hooks
import { useGetHireProfileVacancies } from 'hooks/hireProfileHooks/useGetHireProfileVacancies';
// models
import { ListOfVacanciesProps } from 'models/modelsOfComponents';
import EmptyDashboard from 'app/ProfileEmployer/components/Dashboard/components/EmptyDashboard';

const ListOfVacancies = ({ crewProfileId }: ListOfVacanciesProps) => {
  const getVacancies = useGetHireProfileVacancies();

  const containerClasses = isEmpty(getVacancies) ? 'mb-20' : 'h-[350px] w-[calc(100%_-_25px)]';

  return (
    <>
      <div className="flex flex-col">
        <section className="relative py-2">
          <div className="absolute top-0 border border-specialGray-012 rounded-xl w-[calc(100%_-_25px)] h-full" />
          <div className="relative overflow-y-auto z-10">
            <div className={containerClasses}>
              {getVacancies?.length ? (
                getVacancies?.map(vacancy => {
                  return (
                    <Suspense
                      key={vacancy?.node?.id}
                      fallback={
                        <LazyLoader
                          mainComponent={<ItemForListOfVacancies key={vacancy?.node?.id} vacancy={vacancy?.node as VacancyFragments$key} crewProfileId={crewProfileId} />}
                        >
                          <SpinnerIcon />
                        </LazyLoader>
                      }
                    >
                      <ItemForListOfVacancies key={vacancy?.node?.id} vacancy={vacancy?.node as VacancyFragments$key} crewProfileId={crewProfileId} />
                    </Suspense>
                  );
                })
              ) : (
                <EmptyDashboard descriptionId="hire_noVacancies_description_for_crew" />
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ListOfVacancies;
