import TextInputSkeleton from 'system/skeletons/components/TextInputSkeleton';

const VesselInformationSkeleton = () => {
  return (
    <div className="relative flex flex-col space-y-[18px]">
      <TextInputSkeleton heightInput={48} borderRadius="6px" widthTitle={100} />
      <TextInputSkeleton heightInput={48} borderRadius="12px" widthTitle={100} />
    </div>
  );
};

export default VesselInformationSkeleton;
