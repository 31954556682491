/**
 * @generated SignedSource<<3a28bd3c922cd9ad29a257290a3cf9ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ProfileQueriesGetCrewPoolStatsQuery$variables = {
  poolIds: ReadonlyArray<string>;
};
export type ProfileQueriesGetCrewPoolStatsQuery$data = {
  readonly crewPoolStats: {
    readonly stats: ReadonlyArray<{
      readonly 'id': string;
      readonly 'totalCrewCount': number;
      readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsEmployerCrewPoolStats'>;
    }>;
  } | null;
};
export type ProfileQueriesGetCrewPoolStatsQuery = {
  response: ProfileQueriesGetCrewPoolStatsQuery$data;
  variables: ProfileQueriesGetCrewPoolStatsQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'poolIds',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'poolIds',
        variableName: 'poolIds',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'totalCrewCount',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileQueriesGetCrewPoolStatsQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'CrewPoolStats',
          kind: 'LinkedField',
          name: 'crewPoolStats',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'CrewPoolStat',
              kind: 'LinkedField',
              name: 'stats',
              plural: true,
              selections: [
                v2 /*: any*/,
                v3 /*: any*/,
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'ProfileFragmentsEmployerCrewPoolStats',
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileQueriesGetCrewPoolStatsQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'CrewPoolStats',
          kind: 'LinkedField',
          name: 'crewPoolStats',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'CrewPoolStat',
              kind: 'LinkedField',
              name: 'stats',
              plural: true,
              selections: [
                v2 /*: any*/,
                v3 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'availableCrewCount',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'crewPoolAvatars',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '5bfab0d282785fccdad2bcb526652c65',
      id: null,
      metadata: {},
      name: 'ProfileQueriesGetCrewPoolStatsQuery',
      operationKind: 'query',
      text: 'query ProfileQueriesGetCrewPoolStatsQuery(\n  $poolIds: [ID!]!\n) {\n  crewPoolStats(poolIds: $poolIds) {\n    stats {\n      id\n      totalCrewCount\n      ...ProfileFragmentsEmployerCrewPoolStats\n    }\n  }\n}\n\nfragment ProfileFragmentsEmployerCrewPoolStats on CrewPoolStat {\n  availableCrewCount\n  totalCrewCount\n  crewPoolAvatars\n}\n',
    },
  };
})();

(node as any).hash = 'dddc4197a9216e49e2742af577e75919';

export default node;
