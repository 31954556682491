const FolderPlus = ({ className = 'w-6 h-6 text-darkBlue' }: { className?: string }) => {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_14244_112679" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="100%" height="100%">
        <rect width="100%" height="100%" fill="currentColor" />
      </mask>
      <g mask="url(#mask0_14244_112679)">
        <path
          d="M15 16C15.2833 16 15.5208 15.9042 15.7125 15.7125C15.9042 15.5208 16 15.2833 16 15V14H17C17.2833 14 17.5208 13.9042 17.7125 13.7125C17.9042 13.5208 18 13.2833 18 13C18 12.7167 17.9042 12.4792 17.7125 12.2875C17.5208 12.0958 17.2833 12 17 12H16V11C16 10.7167 15.9042 10.4792 15.7125 10.2875C15.5208 10.0958 15.2833 10 15 10C14.7167 10 14.4792 10.0958 14.2875 10.2875C14.0958 10.4792 14 10.7167 14 11V12H13C12.7167 12 12.4792 12.0958 12.2875 12.2875C12.0958 12.4792 12 12.7167 12 13C12 13.2833 12.0958 13.5208 12.2875 13.7125C12.4792 13.9042 12.7167 14 13 14H14V15C14 15.2833 14.0958 15.5208 14.2875 15.7125C14.4792 15.9042 14.7167 16 15 16ZM4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H9.175C9.44167 4 9.69583 4.05 9.9375 4.15C10.1792 4.25 10.3917 4.39167 10.575 4.575L12 6H20C20.55 6 21.0208 6.19583 21.4125 6.5875C21.8042 6.97917 22 7.45 22 8V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM4 6V18H20V8H11.175L9.175 6H4Z"
          fill="currentColor"
          fillOpacity="0.5"
        />
      </g>
    </svg>
  );
};

export default FolderPlus;
