/**
 * @generated SignedSource<<e13fab5ab86a2fce796dbcd1419356d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrganizationFleetsize = 'SIZE_0_5' | 'SIZE_25_100' | 'SIZE_26_100' | 'SIZE_2_25' | 'SIZE_6_25' | 'SIZE_MORE_100' | '%future added value';
export type OrganizationSize = 'SIZE_11_50' | 'SIZE_1_10' | 'SIZE_201_500' | 'SIZE_501_1000' | 'SIZE_51_200' | 'SIZE_MORE_1000' | '%future added value';
import { FragmentRefs } from 'relay-runtime';
export type OrganisationFragmentsForSearchFragment$data = {
  readonly 'avatarUrl': string | null;
  readonly 'description': string | null;
  readonly 'fleetsize': OrganizationFleetsize | null;
  readonly 'headquarters': string | null;
  readonly 'id': string;
  readonly 'name': string;
  readonly 'numberOfMembers': number;
  readonly 'numberOfVacancies': number;
  readonly 'size': OrganizationSize | null;
  readonly 'websiteUrl': string | null;
  readonly ' $fragmentType': 'OrganisationFragmentsForSearchFragment';
};
export type OrganisationFragmentsForSearchFragment$key = {
  readonly ' $data'?: OrganisationFragmentsForSearchFragment$data;
  readonly ' $fragmentSpreads': FragmentRefs<'OrganisationFragmentsForSearchFragment'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'OrganisationFragmentsForSearchFragment',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'description',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'avatarUrl',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'websiteUrl',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'headquarters',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'fleetsize',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'numberOfMembers',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'numberOfVacancies',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'size',
      storageKey: null,
    },
  ],
  type: 'Organization',
  abstractKey: null,
};

(node as any).hash = 'a612c129baa056df3092bcde312d24d5';

export default node;
