/**
 * @generated SignedSource<<e7c4da076278ed9e031c84b6c9d0c10f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContactMutationsDeleteVacancyContactMutation$variables = {
  id: string;
};
export type ContactMutationsDeleteVacancyContactMutation$data = {
  readonly deleteContact: {
    readonly id: string;
  };
};
export type ContactMutationsDeleteVacancyContactMutation = {
  response: ContactMutationsDeleteVacancyContactMutation$data;
  variables: ContactMutationsDeleteVacancyContactMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        concreteType: 'Contact',
        kind: 'LinkedField',
        name: 'deleteContact',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ContactMutationsDeleteVacancyContactMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ContactMutationsDeleteVacancyContactMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: 'c40c06a87b004ce94be0002b6f598f88',
      id: null,
      metadata: {},
      name: 'ContactMutationsDeleteVacancyContactMutation',
      operationKind: 'mutation',
      text: 'mutation ContactMutationsDeleteVacancyContactMutation(\n  $id: ID!\n) {\n  deleteContact(id: $id) {\n    id\n  }\n}\n',
    },
  };
})();

(node as any).hash = '7f19dd3b594a4113eb34fde28f93bf8d';

export default node;
