import { useRef } from 'react';
import { isEmpty } from 'lodash';
//hooks
import useOnClickOutside from 'hooks/useOutsideAlerter';
//models
import { ListOfFilterProps, SearchFilterWithDropDownProps } from '../types';
//components
import CommonInput from 'system/CommonInput/CommonInput';
import ChevronUpIcon from '@heroicons/react/24/solid/ChevronUpIcon';
import { FilterSelectedItem } from 'system/FilterSlideComponents/components/FilterItems/FilterSelectedItem';
import { FilterDoubleListDropDown } from 'system/FilterSlideComponents/components/FilterItems/FilterDoubleListDropDown';
//hooks
import { useHoverById } from 'hooks/useHover';
import { useDropDownTools } from '../hooks/useDropDownTools';

export const SearchFilterWithDropDown = <T extends ListOfFilterProps>({ listOfFilters = [], filterType, formatter, title, placeholder }: SearchFilterWithDropDownProps<T>) => {
  const filterContainerRef = useRef<HTMLDivElement>(null);
  const { selectedId, handleMouseEnter, handleMouseOut } = useHoverById();

  const {
    isOpenList,
    inputValue,
    filters,
    handleChangeInput,
    handleAddSearchFilter,
    handleDeleteSelectedItemFromDropDownList,
    openDropDownList,
    closeListIfOutsideClick,
    selectedFilterItems,
    inputReference,
  } = useDropDownTools(listOfFilters, filterType, formatter);

  useOnClickOutside(filterContainerRef, closeListIfOutsideClick);

  return (
    <div id="counter-filter-main" className="flex flex-col pt-6 sm:pt-8 border-b border-b-specialGray-012">
      <div id="country-filter-container" ref={filterContainerRef} className="mx-4 sm:mx-8 relative">
        <CommonInput
          clickEvent={openDropDownList}
          id={filterType}
          label={title}
          ref={inputReference}
          inputValue={inputValue}
          onChange={handleChangeInput}
          placeholder={placeholder}
        >
          <ChevronUpIcon
            className={`w-5 h-5 text-specialGray-05 ${isOpenList ? 'rotate-0' : 'rotate-180'} absolute top-2.5 right-0 mr-2 transition-all duration-2000 cursor-pointer`}
          />
        </CommonInput>
        {!isEmpty(selectedFilterItems) && (
          <div className="flex w-full flex-wrap mb-3">
            {selectedFilterItems.map(item => {
              return (
                <div className="flex" onMouseEnter={handleMouseEnter(item.id)} onMouseLeave={handleMouseOut} key={item.id}>
                  <FilterSelectedItem
                    className="mr-2 mt-2"
                    isHovered={item.id === selectedId}
                    name={formatter(item)}
                    isoCode={item.isoCode}
                    onClickEvent={handleDeleteSelectedItemFromDropDownList()(item)}
                  />
                </div>
              );
            })}
          </div>
        )}
        {isOpenList && (
          <FilterDoubleListDropDown
            filterItems={filters}
            nameFormatter={formatter}
            selectedFilterItems={selectedFilterItems}
            onClickDeleteEvent={handleDeleteSelectedItemFromDropDownList(inputReference)}
            onClickEvent={handleAddSearchFilter}
          />
        )}
      </div>
    </div>
  );
};
