// routes
import { RoutePaths, RoutesPathNames } from 'app/routing';
// components
import FormWrapper from './components/FormWrapper';
import FormStepOne from 'app/ProfileEmployer/components/JobOpeningForm/components/FormStepOne/FormStepOne';
import FormStepTwo from 'app/ProfileEmployer/components/JobOpeningForm/components/FormStepTwo/FormStepTwo';
import FormStepThree from 'app/ProfileEmployer/components/JobOpeningForm/components/FormStepTree/FormStepThree';

export const CreateEditVacancyFormRoute = {
  path: `${RoutePaths.ProfileOrganizationBase}/:${RoutesPathNames.orgId}/*`,
  Component: FormWrapper,
  children: [
    {
      path: RoutePaths.JobOpeningStep1,
      Component: FormStepOne,
    },
    {
      path: RoutePaths.JobOpeningStep2,
      Component: FormStepTwo,
    },
    {
      path: RoutePaths.JobOpeningStep3,
      Component: FormStepThree,
    },
  ],
};
