// packages
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { chunk, isEmpty, sortBy } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
// context
import { ProfileScoreDetailsType } from 'models/commonTypes';
// mocks
import { GetPopoverProfileRatingButtons } from 'mocks/mockData';
// components
import { ArrowIcon } from 'icons/ArrowIcon';
import CustomButton from 'system/Buttons/CustomButton';
import AccountCircleIcon from 'icons/AccountCircleIcon';
import HighlightElementWrapper from 'system/wrappers/HighlightElementWrapper';
// hooks
import { useGetProfileScore } from 'hooks/useGetProfileScore';
import useScoreFilteredNotFilledItems from 'hooks/useScoreFilteredNotFilledItems';
// routes
import { RoutesPathNames } from 'app/routing';

const ProfileHowCanIDoBetter = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { profileScoreDetails, profileScoreSummary } = useGetProfileScore();
  const { filteredNotFilledItems } = useScoreFilteredNotFilledItems();

  const [listNumber, setListNumber] = useState(0);

  const sortedProfileDetails = useMemo(() => {
    const sortedNotFilledElements = sortBy(
      profileScoreDetails.filter(element => !!element.weight),
      'order',
    );
    return chunk(filteredNotFilledItems(sortedNotFilledElements), 4);
  }, [filteredNotFilledItems, profileScoreDetails]);

  useEffect(() => {
    if (!sortedProfileDetails[listNumber]) {
      setListNumber(0);
    }
  }, [sortedProfileDetails, listNumber]);

  return (
    <HighlightElementWrapper className="w-full" borderRadius="rounded-[15px]" addPadding="p-0" enableFlickering={state?.profileHowCanIDoBetterFlickering}>
      <section className="flex lg:flex-row flex-col w-full lg:max-w-[855px] border border-specialGray-012 rounded-[15px] py-6 px-5 2xl:py-4 h-full sm:h-[236px] lg:h-[137px] text-sm">
        <div className="flex flex-col w-full lg:max-w-[30%] lg:mr-3 justify-center">
          <span className="uppercase text-xs font-medium mb-1 text-darkBlue">{intl.formatMessage({ id: 'rating_how_do_better' })}</span>
          <span className="text-specialGray-075 mb-2 sm:mb-0">{intl.formatMessage({ id: 'rating_improve_your_profile' })}</span>
        </div>

        {profileScoreSummary === 100 ? (
          <div className="flex w-full h-full justify-center items-center pt-2.5 text-specialGray-075">
            <span>{intl.formatMessage({ id: 'rating_excellent_score' })}</span>
          </div>
        ) : (
          <div className="flex w-full h-full">
            <div className="flex w-full flex-wrap items-center h-full justify-between">
              {!isEmpty(sortedProfileDetails) ? (
                sortedProfileDetails[listNumber]?.map(({ name, filled }: ProfileScoreDetailsType) => {
                  const ratingButton = GetPopoverProfileRatingButtons(navigate, pathname)[name];
                  if (!ratingButton) {
                    return null;
                  }
                  const IconElement = ratingButton?.icon;

                  return !filled && GetPopoverProfileRatingButtons(navigate, pathname)[name] ? (
                    <CustomButton
                      classNameStyle="h-fit w-full sm:min-w-[45%] sm:whitespace-nowrap text-darkBlue text-base font-medium [&>span]:text-start [&>svg:first-child]:mr-2 [&>svg:first-child]:text-2xl [&>svg]:text-grayIcon [&:hover>svg]:text-blue hover:text-blue"
                      key={name}
                      label={ratingButton?.label}
                      onClick={ratingButton?.onClick}
                    >
                      {IconElement && <IconElement className="w-5 h-5" />}

                      <ArrowIcon className="order-last text-specialGray-03" />
                    </CustomButton>
                  ) : null;
                })
              ) : (
                <CustomButton
                  classNameStyle="sm:whitespace-nowrap text-base font-medium self-center hover:text-blue [&>span]:text-start [&>svg:first-child]:text-2xl [&>svg:first-child]:mr-2 [&>svg]:text-grayIcon [&:hover>svg]:text-blue"
                  label={{ id: 'rating_fill_form' }}
                  onClick={e => {
                    e.preventDefault();
                    navigate(RoutesPathNames.editProfileInfo);
                  }}
                >
                  <AccountCircleIcon />
                  <ArrowIcon className="order-last" />
                </CustomButton>
              )}
            </div>

            {/*bullet buttons*/}
            {!isEmpty(sortedProfileDetails) && sortedProfileDetails.length > 1 && (
              <div className="flex flex-col w-[5%] ml-auto space-y-3 items-center justify-center">
                {sortedProfileDetails?.map((item, index) => {
                  const isActiveButton = listNumber === index ? 'bg-specialGray-03' : 'bg-specialGray-015';
                  return (
                    <CustomButton
                      key={index}
                      onClick={e => {
                        e.preventDefault();
                        setListNumber(index);
                      }}
                      className={`rounded-full w-2 h-2 ${isActiveButton}`}
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}
      </section>
    </HighlightElementWrapper>
  );
};

export default ProfileHowCanIDoBetter;
