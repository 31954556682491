const ProfileProgressBarIcon6 = () => {
  return (
    <svg width="204" height="102" viewBox="0 0 204 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M197.949 95.7353C197.949 46.2708 154.984 6.17188 101.984 6.17188C48.9839 6.17188 6.01886 46.2708 6.01886 95.7353"
        stroke="#F1F2F4"
        strokeWidth="11.6324"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M197.949 95.735C197.949 75.0753 190.454 56.0495 177.863 40.8966C166.071 26.7049 149.809 15.9105 131.049 10.3528C112.213 4.77302 91.755 4.77302 72.9195 10.3528C53.2538 16.1785 36.3338 27.7585 24.4297 42.9738C12.852 57.7719 6.01886 76.0087 6.01886 95.735"
        stroke="#F1F2F4"
        strokeWidth="11.6324"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M197.949 95.735C197.949 75.0753 190.454 56.0495 177.863 40.8966C166.071 26.7049 149.809 15.9105 131.049 10.3528C112.213 4.77302 91.755 4.77302 72.9195 10.3528C53.2538 16.1785 36.3338 27.7585 24.4297 42.9738C12.852 57.7719 6.01886 76.0087 6.01886 95.735"
        stroke="url(#paint0_linear_6750_59442)"
        strokeWidth="11.6324"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient id="paint0_linear_6750_59442" x1="214.585" y1="95.7345" x2="30.3681" y2="166.012" gradientUnits="userSpaceOnUse">
          <stop offset="0.0323759" stopColor="#26CA85" />
          <stop offset="1" stopColor="#7EE065" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProfileProgressBarIcon6;
