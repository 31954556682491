import { useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
//components
import { Button } from 'system/Button';
import SpinnerIcon from 'icons/SpinnerIcon';
// recoil
import { scrollWithPaginationType } from 'recoil/ScrollWithPagination/types';
import { profileSearchScrollWithPaginationSelector } from 'recoil/ScrollWithPagination/selectors/SearchScrollWithPaginationSelector';

const ButtonProfileSearchNextPage = ({ totalNumberOfUsers = 0 }: { totalNumberOfUsers?: number }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [numberOfItems, setNumberOfItems] = useState<number>(totalNumberOfUsers);
  const setScrollWithPagination = useSetRecoilState(profileSearchScrollWithPaginationSelector);

  const handleLoadMore = useCallback(() => {
    setScrollWithPagination((prevState: scrollWithPaginationType) => ({ scrollY: window.scrollY, numberOfFetchNumber: prevState.numberOfFetchNumber + 10 }));
    setIsLoading(true);
  }, [setScrollWithPagination]);

  useEffect(() => {
    setNumberOfItems(() => {
      if (numberOfItems !== totalNumberOfUsers) {
        setIsLoading(false);
      }
      return totalNumberOfUsers;
    });
  }, [totalNumberOfUsers, setNumberOfItems, numberOfItems]);

  return (
    <>
      {!isLoading ? (
        <Button type="button" buttonType="white-primary" label={{ id: 'profile_load_more_crew' }} additionalClasses="mx-auto px-8 py-3.5 rounded-xl" onClick={handleLoadMore} />
      ) : (
        <SpinnerIcon />
      )}
    </>
  );
};

export default ButtonProfileSearchNextPage;
