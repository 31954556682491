import { useLazyLoadQuery } from 'react-relay';
import { useSearchParams } from 'react-router-dom';
//models
import { FILTER_TYPE } from 'models/enums';
//schemas
import { GET_EDUCATION_KIND } from 'schemas/educations/EducationQueries';
import { GET_ALL_CERTIFICATES_LIST } from 'schemas/certificates/CertificateQueries';
import { GET_PROFILE_COUNTRIES_OF_RESIDENCE } from 'schemas/country/CountryQueries';
import { GET_ALL_DUTIES_QUERY, GET_ALL_TAGS_QUERY, GET_VESSELS_KINDS } from 'schemas/services/ServiceQueries';
//generated
import { EducationQueriesKindsQuery } from 'schemas/educations/__generated__/EducationQueriesKindsQuery.graphql';
import { CertificateQueriesListQuery } from 'schemas/certificates/__generated__/CertificateQueriesListQuery.graphql';
import { ServiceQueriesVesselKindsQuery } from 'schemas/services/__generated__/ServiceQueriesVesselKindsQuery.graphql';
import { ServiceQueriesFormGetAllTagsQuery } from 'schemas/services/__generated__/ServiceQueriesFormGetAllTagsQuery.graphql';
import { ServiceQueriesFormGetAllDutiesQuery } from 'schemas/services/__generated__/ServiceQueriesFormGetAllDutiesQuery.graphql';
import { CountryQueriesCountriesOfResidenceQuery } from 'schemas/country/__generated__/CountryQueriesCountriesOfResidenceQuery.graphql';

const useProfileFiltersQuery = (isSkippedRequest = true) => {
  const [searchParams] = useSearchParams();

  const allDuties = useLazyLoadQuery<ServiceQueriesFormGetAllDutiesQuery>(GET_ALL_DUTIES_QUERY, {
    skip: isSkippedRequest
      ? !isSkippedRequest
      : !searchParams.get(FILTER_TYPE.DUTIES) && !searchParams.get(FILTER_TYPE.PRIMARY_DUTIES) && !searchParams.get(FILTER_TYPE.MOST_RECENT_SERVICE_DUTY),
  }).duties?.edges;

  const listOfCountries = useLazyLoadQuery<CountryQueriesCountriesOfResidenceQuery>(GET_PROFILE_COUNTRIES_OF_RESIDENCE, {
    skip: isSkippedRequest ? !isSkippedRequest : !searchParams.get(FILTER_TYPE.COUNTRIES),
  })?.countries?.edges;

  const allCertificates = useLazyLoadQuery<CertificateQueriesListQuery>(GET_ALL_CERTIFICATES_LIST, {
    skip: isSkippedRequest ? !isSkippedRequest : !searchParams.get(FILTER_TYPE.CERTIFICATES),
  })?.certificates?.edges;

  const allActivities = useLazyLoadQuery<ServiceQueriesFormGetAllTagsQuery>(GET_ALL_TAGS_QUERY, {
    substr: '',
    skip: isSkippedRequest ? !isSkippedRequest : !searchParams.get(FILTER_TYPE.ACTIVITIES),
  })?.activities?.edges;

  const vesselsType = useLazyLoadQuery<ServiceQueriesVesselKindsQuery>(GET_VESSELS_KINDS, {
    skip: isSkippedRequest ? !isSkippedRequest : !searchParams.get(FILTER_TYPE.VESSEL_TYPES),
  })?.vesselKinds?.edges;

  const educationKind = useLazyLoadQuery<EducationQueriesKindsQuery>(GET_EDUCATION_KIND, { skip: isSkippedRequest ? !isSkippedRequest : !searchParams.get(FILTER_TYPE.EDUCATION) })
    ?.educationKinds?.edges;

  return {
    allDuties,
    listOfCountries,
    allCertificates,
    allActivities,
    vesselsType,
    educationKind,
  };
};

export default useProfileFiltersQuery;
