import graphql from 'babel-plugin-relay/macro';

graphql`
  query ApplicationQueriesGetApplicationQuery($id: ID!) {
    node(id: $id) {
      ...ApplicationFragments
    }
  }
`;

export const GET_LIST_OF_MATCHED_CREW = graphql`
  query ApplicationQueriesGetListOfMatchedCrewQuery($vacancyId: ID!, $after: Cursor, $first: Int, $skip: Boolean!) {
    applicationInviteCross(vacancyId: $vacancyId, first: $first, after: $after) @skip(if: $skip) @connection(key: "ListWithApplicationInviteCross_applicationInviteCross") {
      edges {
        node {
          id
          owner {
            info {
              country {
                isoCode
              }
            }
          }
          ...ApplicationFragments
        }
      }
    }
  }
`;

export const GET_LIST_OF_APPLIED_CREW = graphql`
  query ApplicationQueriesGetListOfAppliedCrewQuery($vacancyId: ID!, $after: Cursor, $first: Int, $skip: Boolean!) {
    applications(vacancyId: $vacancyId, first: $first, after: $after) @skip(if: $skip) @connection(key: "ListWithApplications_applications") {
      edges {
        node {
          id
          owner {
            info {
              country {
                isoCode
              }
            }
          }
          ...ApplicationFragments
        }
      }
    }
  }
`;

export const GET_CREW_APPLICATIONS = graphql`
  query ApplicationQueriesGetCrewApplicationsQuery($ownerId: ID!, $after: Cursor, $first: Int, $skip: Boolean!) {
    userApplications(ownerId: $ownerId, first: $first, after: $after) @skip(if: $skip) {
      edges {
        node {
          id
          createdAt
          vacancy {
            id
            title
            organization {
              id
            }
            status
            createdAt
            duties {
              id
              name
            }
            contacts {
              id
              owner {
                id
              }
            }
          }
        }
      }
    }
  }
`;
