import { useEffect, useState } from 'react';
// mocks
import { MOCK_ORGANIZATION_SIZE, MOCK_ORGANIZATION_SIZE_WITH_LABEL } from 'mocks/mockData';
// generated
import { OrganizationSize } from 'schemas/profile/__generated__/ProfileMutationsCreateOrganizationMutation.graphql';

export function useGetOrganizationSizeWithLabel(organizationSize: OrganizationSize | null) {
  const [selectedOrganizationSize, setSelectedOrganizationSize] = useState<typeof MOCK_ORGANIZATION_SIZE_WITH_LABEL[number]>();

  useEffect(() => {
    if (organizationSize) {
      setSelectedOrganizationSize(MOCK_ORGANIZATION_SIZE_WITH_LABEL.find(orgSize => orgSize.id === (organizationSize as string)));
    }
  }, [organizationSize]);

  return { selectedOrganizationSize, setSelectedOrganizationSize };
}

export function useGetOrganizationSize(organizationSize: OrganizationSize | null) {
  const [selectedOrganizationSize, setSelectedOrganizationSize] = useState<typeof MOCK_ORGANIZATION_SIZE[number]>();

  useEffect(() => {
    if (organizationSize) {
      setSelectedOrganizationSize(MOCK_ORGANIZATION_SIZE.find(orgSize => orgSize.id === (organizationSize as string)));
    }
  }, [organizationSize]);

  return { selectedOrganizationSize, setSelectedOrganizationSize };
}
