import { useIntl } from 'react-intl';
import { PropsWithChildren } from 'react';
// types
import { PaymentSubscriptionLabelSizes, PaymentSubscriptionName } from 'models/enums';
import { PaymentSubscriptionViewProps } from '../types';
// components
import PaymentSubscriptionLabel from './PaymentSubscriptionLabel';

const PaymentSubscriptionView = ({ subscriptionPlan, children }: PropsWithChildren<PaymentSubscriptionViewProps>) => {
  const intl = useIntl();

  const mainContainerClasses = subscriptionPlan === PaymentSubscriptionName.PRO ? 'bg-[#00BF78] text-[#00BF78]' : 'bg-blue text-blue';

  return (
    <section className={`flex flex-col text-bold ${mainContainerClasses} min-h-[126px] min-w-[119px] px-1 pt-2 pb-1 text-xs rounded-[20px] justify-between`}>
      <div className="flex w-full justify-center items-center font-semibold gap-x-2">
        <PaymentSubscriptionLabel size={PaymentSubscriptionLabelSizes.XS} subscriptionPlan={subscriptionPlan} />
        <span className="text-white lowercase text-sm">{intl.formatMessage({ id: 'subscription_plan_title' })}</span>
      </div>

      <div className="flex w-full flex-col bg-white items-center justify-center text-specialGray-075 min-h-[85px] rounded-[17px] text-[25px]">{children}</div>
    </section>
  );
};

export default PaymentSubscriptionView;
