import { RoutePaths, RoutesPathNames } from 'app/routing';
import PrivateCrewAccountViewRoute from 'app/PrivateRoutes/PrivateCrewAccountViewRoute';
import ProfileCrewEmployerView from 'app/ProfileCrew/ProfileCrewViews/ProfileCrewEmployerView/ProfileCrewEmployerView';
import EmployerAddUserToCrewPoolModalByUrl from 'app/ProfileEmployer/components/EmployerCrewPools/components/EmployerAddUserToCrewPool/EmployerAddUserToCrewPoolModalByUrl';
import SeaServiceDetailsModalEV from 'app/ProfileCrew/ProfileCrewViews/ProfileCrewEmployerView/OverviewCrewInformationEmployerView/CrewsSeaServicesEmployerView/components/SeaServiceDetailsModalEV';

export const CrewDetailsProfileRoute = {
  path: `${RoutePaths.ProfileSingleCrewPageEmployerView}/*`,
  Component: PrivateCrewAccountViewRoute,
  children: [
    {
      path: `:${RoutesPathNames.id}/*`,
      Component: ProfileCrewEmployerView,
      children: [
        {
          path: `${RoutePaths.ShowSeaServiceDetails}/:${RoutesPathNames.seaServiceId}`,
          Component: SeaServiceDetailsModalEV,
        },
        {
          path: `${RoutesPathNames.addUserToPool}/:${RoutesPathNames.userId}`,
          Component: EmployerAddUserToCrewPoolModalByUrl,
        },
      ],
    },
  ],
};
