import { useRef, useEffect } from 'react';
import { PopoverTitleProps } from '../../../types';

const PopoverTitle = ({ title, setIsRenderPopover }: PopoverTitleProps) => {
  const titleRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    const spanWidth = titleRef.current?.offsetWidth;
    const wrapperSpanWidth = titleRef.current?.parentElement?.offsetWidth;
    if (setIsRenderPopover) {
      setIsRenderPopover((spanWidth as number) > (wrapperSpanWidth as number));
    }
  }, [setIsRenderPopover]);

  return (
    <span ref={titleRef} className="truncate text-sm">
      {title}
    </span>
  );
};

export default PopoverTitle;
