import { Component, ReactNode } from 'react';
import { Button } from 'system/Button';
import { Modal } from 'system/Modal';
import { VACANCY_ERRORS } from 'mocks/mockData';

class ErrorBoundaryWithModal extends Component<{ showDefaultModal?: boolean; children: ReactNode }, { errorMessage: string }> {
  public state = {
    errorMessage: '',
  };

  public static getDerivedStateFromError(err: Error) {
    // Update state so the next render will show the fallback UI.
    return { errorMessage: err.message };
  }

  componentDidMount() {
    if (!!this.props.showDefaultModal) {
      this.setState({ errorMessage: 'ErrVacancyNotOpen' });
    }
  }

  handleCloseModal = () => {
    this.setState({ errorMessage: '' });
  };

  public render() {
    return (
      <>
        <Modal tinyModal show={!!this.state.errorMessage} title={VACANCY_ERRORS[this.state.errorMessage]?.modalTitle} onClose={this.handleCloseModal}>
          <div className="flex w-full justify-center">
            <Button label={{ id: 'close' }} onClick={this.handleCloseModal} />
          </div>
        </Modal>
        {this.props.children}
      </>
    );
  }
}

export default ErrorBoundaryWithModal;
