/**
 * @generated SignedSource<<d87f98a95b25b805bf4946d944edbb6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrganizationMemberRole = 'admin' | 'invited' | 'member' | 'owner' | '%future added value';
export type OrganizationSize = 'SIZE_11_50' | 'SIZE_1_10' | 'SIZE_201_500' | 'SIZE_501_1000' | 'SIZE_51_200' | 'SIZE_MORE_1000' | '%future added value';
import { FragmentRefs } from 'relay-runtime';
export type ProfileFragmentsOrganizationPlural_profile$data = ReadonlyArray<{
  readonly 'createdAt': String;
  readonly 'id': string;
  readonly 'organization': {
    readonly avatarUrl: string | null;
    readonly city: string | null;
    readonly country: {
      readonly id: string;
      readonly isoCode: string;
      readonly name: string;
    } | null;
    readonly description: string | null;
    readonly id: string;
    readonly name: string;
    readonly size: OrganizationSize | null;
  };
  readonly 'role': OrganizationMemberRole;
  readonly ' $fragmentType': 'ProfileFragmentsOrganizationPlural_profile';
}>;
export type ProfileFragmentsOrganizationPlural_profile$key = ReadonlyArray<{
  readonly ' $data'?: ProfileFragmentsOrganizationPlural_profile$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsOrganizationPlural_profile'>;
}>;

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    };
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: {
      plural: true,
    },
    name: 'ProfileFragmentsOrganizationPlural_profile',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'createdAt',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'role',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Organization',
        kind: 'LinkedField',
        name: 'organization',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'city',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'Country',
            kind: 'LinkedField',
            name: 'country',
            plural: false,
            selections: [
              v0 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'isoCode',
                storageKey: null,
              },
              v1 /*: any*/,
            ],
            storageKey: null,
          },
          v1 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'description',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'avatarUrl',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'size',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
    type: 'OrganizationMember',
    abstractKey: null,
  };
})();

(node as any).hash = '3782388de71cf658968f8973e808c8f1';

export default node;
