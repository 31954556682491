// packages
import { Dispatch, SetStateAction, MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// components
import { Modal } from 'system/Modal';
import { GiveFiveRatingIcon } from 'icons/RatingIcons';
import CustomButton from 'system/Buttons/CustomButton';
// routes
import { RoutePaths } from 'app/routing';

const CompletedProfileModal = ({ handleRunConfetti, runConfetti }: { runConfetti: boolean; handleRunConfetti: Dispatch<SetStateAction<boolean>> }) => {
  const intl = useIntl();
  const navigation = useNavigate();

  const handleChangePage = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.analytics?.track('wui-click-search-a-job-fully-filled-profile-congratulations-modal');
    navigation(RoutePaths.JobOpenings);
    handleRunConfetti(false);
  };

  const handleCloseModal = () => {
    window.analytics?.track('wui-close-fully-filled-profile-congratulations-modal');
    handleRunConfetti(false);
  };

  return (
    <Modal tinyModal show={runConfetti} onClose={handleCloseModal}>
      <div className="flex flex-col w-full items-center text-darkBlue">
        <div className="flex items-center">
          <h2 className="flex flex-col sm:flex-row font-medium text-md sm:text-lg text-center">
            {intl.formatMessage({ id: 'rating_profile_is_fully_filled_title_congratulations' })}
          </h2>
          <div className="flex ml-2 [&>svg]:w-7 [&>svg]:h-7">
            <GiveFiveRatingIcon />
          </div>
        </div>
        <h2 className="flex flex-col sm:flex-row font-medium text-md sm:text-lg text-center mb-2">
          {intl.formatMessage({ id: 'rating_profile_is_fully_filled_title_description' })}
        </h2>

        <span className="text-sm text-specialGray-075 text-center mb-2">{intl.formatMessage({ id: 'rating_profile_is_fully_filled_description' })}</span>

        <div className="flex w-full text-sm gap-x-2 justify-between">
          <CustomButton
            onClick={handleChangePage}
            classNameStyle="bg-blue-600 hover:bg-blue-700 px-[18px] text-white rounded-[10px] h-10 font-medium text-xs sm:text-sm"
            label={{ id: 'search_for_a_job' }}
          />
          <CustomButton
            onClick={handleCloseModal}
            classNameStyle="bg-blue-100 hover:bg-blue-200 px-[18px] text-white rounded-[10px] h-10 text-blue-700 font-medium text-xs sm:text-sm"
            label={{ id: 'do_it_later' }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CompletedProfileModal;
