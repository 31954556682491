import { useEffect, useState } from 'react';

export function useCopyLink(urlLink: string, analyticsEvent?: string) {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleCopyLinkByPath = () => {
    const copiedValue = window?.getSelection()?.toString();
    if (copiedValue) {
      const isFullPathCopied = copiedValue === urlLink;
      if (isFullPathCopied && analyticsEvent) {
        window.analytics?.track(analyticsEvent);
      }
      //trigger copied event if copied by using keyboard for mac or windows, or by using mouse copy event
      setIsCopied(isFullPathCopied);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timer | null = null;

    if (isCopied) {
      timer = setTimeout(() => setIsCopied(false), 2000);
    }

    return () => {
      clearTimeout(timer as NodeJS.Timer);
    };
  }, [isCopied]);

  const handleCopyLink = () => {
    window.navigator.clipboard.writeText(urlLink).then(() => {
      if (analyticsEvent) {
        window.analytics?.track(analyticsEvent);
      }
      setIsCopied(true);
    });
  };

  return { isCopied, handleCopyLink, handleCopyLinkByPath };
}
