import Skeleton from 'react-loading-skeleton';

const CrewProfileBasicInfo = ({ sharedProfile }: { sharedProfile: boolean }) => {
  return (
    <div className="flex w-1/2">
      <Skeleton width={160} height={160} borderRadius="0.375rem" />
      <div className="flex flex-col w-full ml-5">
        <div className="flex flex-row w-full space-x-5">
          <Skeleton width={150} height={34} />
          <Skeleton width={155} height={34} borderRadius={6} />
        </div>
        <div className="flex mt-5">
          <div className="flex flex-col gap-y-2">
            <div className="flex gap-x-2 w-[275px]">
              <Skeleton width={16} />
              <Skeleton width={100} />
            </div>
            <div className="flex flex-col gap-y-2">
              {sharedProfile && (
                <div className="flex gap-x-2">
                  <Skeleton width={16} />
                  <Skeleton width={110} />
                </div>
              )}
              <div className="flex gap-x-2">
                <Skeleton width={24} />
                <Skeleton width={120} />
              </div>
              {sharedProfile && <Skeleton width={152} height={34} borderRadius="9999px" />}
            </div>
          </div>
          <div className="flex flex-col">
            <Skeleton width={100} />
            <Skeleton width={200} />
            <Skeleton width={200} />
            <Skeleton width={200} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrewProfileBasicInfo;
