import graphql from 'babel-plugin-relay/macro';

export const GET_BACKGROUND_TASK = graphql`
  query ResumeQueriesBackgroundTaskQuery($uuid: String!, $skip: Boolean!) {
    backgroundTask(uuid: $uuid) @skip(if: $skip) {
      status
      response
    }
  }
`;
