// packages
import { useCallback, useMemo, MouseEvent, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useIntl } from 'react-intl';
import { Form, FormikProvider, useFormik } from 'formik';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// components
import Footer from '../components/Footer';
import { Alert } from 'system/Alert/Alert';
import ShieldYellowIcon from 'icons/ShieldYellowIcon';
import AlertForError from 'system/Alert/AlertForError';
import { PinCodev2 } from 'system/PinCodeComponent/PinCodev2';
// formHelper
import { ConfirmFormInitialValues } from 'formHelpers/initialValuesOfForms';
import { ConfirmFormValidateSchema } from 'formHelpers/validationsOfForms';
// models
import { SignUpStepsState } from 'models/routeLocationState';
import { registerStepWithTypeAccountType } from 'models/commonTypes';
// routing
import { RoutePaths } from 'app/routing';
// hooks
import { useAuth } from 'authentication';

const StepWithConfirmCode = ({ registerType, defaultError }: { registerType: registerStepWithTypeAccountType; defaultError?: Error }) => {
  const intl = useIntl();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { setEmail } = useAuth();
  const [error, setError] = useState<Error | undefined>(defaultError);

  const formik = useFormik<{ code: string }>({
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: ConfirmFormInitialValues((state as SignUpStepsState)?.email, (state as SignUpStepsState)?.confirmCode),
    validationSchema: ConfirmFormValidateSchema,
  });

  const handleSendWuiSignUpAnalytics = (accountType: registerStepWithTypeAccountType) => {
    window.analytics?.track(accountType === 'hire' ? 'wui-hire-signup-step-with-confirm-code' : 'wui-signup-step-with-confirm-code', {
      type_of_account: accountType,
    });
  };

  const handleConfirmAccountByEmail = async (code: string) => {
    let err: Error | undefined;

    try {
      await Auth.confirmSignUp((state as SignUpStepsState)?.email, code, { clientMetadata: { 'custom:platform_group': `${state.forgotPasswordAccountType}` } });
      err = undefined;
      handleSendWuiSignUpAnalytics(state.forgotPasswordAccountType);
      navigate(state.forgotPasswordAccountType === 'hire' ? RoutePaths.HireAuthLogin : RoutePaths.AuthLogin);
    } catch (e: unknown) {
      err = e as Error;
    }
    setError(err);
  };

  const handleConfirmAccountAndSignIn = async (code: string) => {
    let err: Error | undefined;

    try {
      await Auth.confirmSignUp((state as SignUpStepsState)?.email, code, { clientMetadata: { 'custom:platform_group': `${registerType}` } });

      await Auth.signIn((state as SignUpStepsState)?.email!, (state as SignUpStepsState).password);

      const getCurrentUser = await Auth.currentAuthenticatedUser();

      window.analytics?.identify(getCurrentUser.attributes?.['custom:profile_id'], {
        email: (state as SignUpStepsState)?.email,
        platformGroup: getCurrentUser.attributes?.['custom:platform_group'],
      });

      handleSendWuiSignUpAnalytics(registerType);

      navigate({ pathname: registerType === 'hire' ? RoutePaths.HireAuthRegisterStepFour : RoutePaths.AuthRegisterStepFour }, { state: { ...state, confirmCode: code } });

      err = undefined;
    } catch (e: unknown) {
      err = e as Error;
    }

    setError(err);
  };

  async function onSubmit(d: { code: string }) {
    if ((state as SignUpStepsState).confirmCode) {
      navigate({ pathname: RoutePaths.AuthRegisterStepFour }, { state });
      return;
    }

    formik.setSubmitting(true);

    if (state?.forgotPasswordAccountType) {
      await handleConfirmAccountByEmail(d.code);
    } else {
      await handleConfirmAccountAndSignIn(d.code);
    }
    formik.setSubmitting(false);
  }

  async function resentConfirmationCode() {
    let err: Error | undefined;
    formik.setSubmitting(true);
    try {
      await Auth.resendSignUp((state as SignUpStepsState)?.email!);
      err = undefined;
    } catch (err) {
      setError(err as unknown as Error);
      console.warn('error resending code: ', err);
    }

    setError(err);
    formik.setSubmitting(false);
  }

  // we use this flow for case when users register same account by second time with different password and we need redirect them to recovery password page with code
  // flow to implement this issue :
  // go to create account page step 1 -> enter your new email -> write you password -> on confirm code page don't enter the code and go back go login page again ->
  // go to step 1 and repeat all steps with another password and on confirm account page enter the code from email.
  // You will see this error with "Click here" button.
  // It happens because when user register account by the first time aws-amplify save his first password.
  // Video you can see in this issue : https://github.com/crewlinker/wui/issues/1729
  const onClickForgotPassword = useCallback(
    async (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (state?.email && setEmail) {
        setEmail(state?.email);
        try {
          await Auth.forgotPassword(state?.email);
        } catch (e: unknown) {
          setError(e as Error);
        }
        navigate(registerType === 'crew' ? '/auth/forgot-confirm' : '/auth/hire-forgot-confirm');
      }
    },
    [navigate, registerType, setEmail, setError, state?.email],
  );

  const customMessageInfoError = useMemo(
    () => [
      {
        errorMessage: 'Incorrect username or password.',
        showErrorHead: 'account_password_incorrect',
        showErrorMessage: 'error_incorrect_password_for_confirmation_step',
        value: (
          <Link onClick={onClickForgotPassword} className="inline-flex text-blue lowercase underline" to="">
            {intl.formatMessage({ id: 'click_here' })}
          </Link>
        ),
      },
      {
        errorMessage: 'User is disabled.',
        showErrorHead: 'account_pending_confirmation',
        showErrorMessage: 'error_user_is_disabled',
        value: (
          <span>
            <a href="mailto:info@crewlinker.com" className="underline text-blue">
              info@crewlinker.com
            </a>
          </span>
        ),
      },
    ],
    [intl, onClickForgotPassword],
  );

  const handleCustomMessageInfoError = () => {
    const informationError = customMessageInfoError.find(({ errorMessage }) => errorMessage === error?.message);
    return informationError ?? null;
  };

  return (
    <section className="flex w-full flex-col h-full sm:h-auto pt-5 pb-7">
      <div className="flex w-full flex-col items-center">
        <ShieldYellowIcon />
        <h1 className="mt-3 mb-1 text-darkBlue font-semibold sm:text-lg">{intl.formatMessage({ id: 'confirm_code_title' })}</h1>
        <h2 className="text-specialGray-075 text-xs sm:text-sm text-center">
          {intl.formatMessage({ id: 'confirm_code_description_part_1' })}&nbsp;
          <span className="font-semibold">{intl.formatMessage({ id: 'confirm_email_code_description_part_2' })}</span>
        </h2>
      </div>
      <FormikProvider value={formik}>
        <Form className="flex flex-col mt-7 sm:mt-8 w-full sm:py-8 px-4 sm:border sm:border-specialGray-012 sm:px-10 rounded-[20px] space-y-[18px] h-full justify-between sm:justify-start">
          <fieldset className="space-y-[18px]">
            {error &&
              (handleCustomMessageInfoError() ? (
                <Alert
                  heading={{ id: handleCustomMessageInfoError()?.showErrorHead }}
                  message={{ id: handleCustomMessageInfoError()?.showErrorMessage, description: { value: handleCustomMessageInfoError()?.value } }}
                  type="info"
                />
              ) : (
                <AlertForError heading={{ id: 'register_failedAlert' }} error={error} />
              ))}

            <PinCodev2 name="code" maxLength={6} label={{ id: 'default_auth_confirm_digits_code' }} skipQuery inputType="number" autoFocus />
          </fieldset>
          <Footer
            error={error}
            pathnameToPrevStep={registerType === 'hire' ? RoutePaths.HireAuthRegisterStepTwo : RoutePaths.AuthRegisterStepTwo}
            isDisabled={!formik.values.code || formik.isSubmitting}
          />
          <div className="flex text-xs space-x-2 mx-auto">
            <span className="text-specialGray-075">{intl.formatMessage({ id: 'confirm_resend_code_title' })}</span>
            <button type="button" className="block text-blue" onClick={resentConfirmationCode}>
              {intl.formatMessage({ id: 'confirm_resend_code_description' })}
            </button>
          </div>
        </Form>
      </FormikProvider>
    </section>
  );
};

export default StepWithConfirmCode;
