/**
 * @generated SignedSource<<415d62c84e5540c184fe2d00d9fdc140>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type VacancyQueriesGetVacancyQuery$variables = {
  id: string;
  skip: boolean;
};
export type VacancyQueriesGetVacancyQuery$data = {
  readonly node?: {
    readonly ' $fragmentSpreads': FragmentRefs<'VacancyFragments'>;
  } | null;
};
export type VacancyQueriesGetVacancyQuery = {
  response: VacancyQueriesGetVacancyQuery$data;
  variables: VacancyQueriesGetVacancyQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'skip',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'status',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'description',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'avatarUrl',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'size',
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'websiteUrl',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'alts',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'perma',
      storageKey: null,
    },
    v11 = [v2 /*: any*/, v8 /*: any*/, v10 /*: any*/],
    v12 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'firstName',
      storageKey: null,
    },
    v13 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'lastName',
      storageKey: null,
    },
    v14 = [
      v2 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'Profile',
        kind: 'LinkedField',
        name: 'owner',
        plural: false,
        selections: [v2 /*: any*/],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'VacancyQueriesGetVacancyQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: null,
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'VacancyFragments',
                },
              ],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'VacancyQueriesGetVacancyQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: null,
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: '__typename',
                  storageKey: null,
                },
                v2 /*: any*/,
                {
                  kind: 'InlineFragment',
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'createdAt',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'title',
                      storageKey: null,
                    },
                    v3 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'industry',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'salaryPeriod',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Organization',
                      kind: 'LinkedField',
                      name: 'organization',
                      plural: false,
                      selections: [
                        v2 /*: any*/,
                        v4 /*: any*/,
                        v5 /*: any*/,
                        v6 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'headquarters',
                          storageKey: null,
                        },
                        v7 /*: any*/,
                        v8 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'salaryFrom',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'salaryTo',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'duration',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'responsibilities',
                      storageKey: null,
                    },
                    v4 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Certificate',
                      kind: 'LinkedField',
                      name: 'certificates',
                      plural: true,
                      selections: [
                        v2 /*: any*/,
                        v8 /*: any*/,
                        v9 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'priority',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'neverExpires',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: 'CertificateKind',
                          kind: 'LinkedField',
                          name: 'kind',
                          plural: false,
                          selections: v11 /*: any*/,
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'VesselKind',
                      kind: 'LinkedField',
                      name: 'vesselKind',
                      plural: false,
                      selections: v11 /*: any*/,
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Duty',
                      kind: 'LinkedField',
                      name: 'duties',
                      plural: true,
                      selections: [v2 /*: any*/, v8 /*: any*/, v9 /*: any*/, v10 /*: any*/],
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'HiringArea',
                      kind: 'LinkedField',
                      name: 'hiringArea',
                      plural: false,
                      selections: [v2 /*: any*/, v10 /*: any*/, v8 /*: any*/],
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'tornWeeksOff',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'tornWeeksOn',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Application',
                      kind: 'LinkedField',
                      name: 'applications',
                      plural: true,
                      selections: [
                        v2 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Profile',
                          kind: 'LinkedField',
                          name: 'owner',
                          plural: false,
                          selections: [
                            v2 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              concreteType: 'ProfileInfo',
                              kind: 'LinkedField',
                              name: 'info',
                              plural: false,
                              selections: [v12 /*: any*/, v13 /*: any*/, v5 /*: any*/, v2 /*: any*/],
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                        v3 /*: any*/,
                      ],
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Invite',
                      kind: 'LinkedField',
                      name: 'invites',
                      plural: true,
                      selections: v14 /*: any*/,
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Currency',
                      kind: 'LinkedField',
                      name: 'currency',
                      plural: false,
                      selections: v11 /*: any*/,
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'startingCondition',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'startingDate',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'Contact',
                      kind: 'LinkedField',
                      name: 'contacts',
                      plural: true,
                      selections: v14 /*: any*/,
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'OrganizationMember',
                      kind: 'LinkedField',
                      name: 'organizationMember',
                      plural: false,
                      selections: [
                        v2 /*: any*/,
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Profile',
                          kind: 'LinkedField',
                          name: 'profile',
                          plural: false,
                          selections: [
                            v2 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              concreteType: 'ProfileInfo',
                              kind: 'LinkedField',
                              name: 'info',
                              plural: false,
                              selections: [v12 /*: any*/, v13 /*: any*/, v2 /*: any*/],
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Organization',
                          kind: 'LinkedField',
                          name: 'organization',
                          plural: false,
                          selections: [v2 /*: any*/, v8 /*: any*/, v5 /*: any*/, v6 /*: any*/, v4 /*: any*/, v7 /*: any*/],
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                  ],
                  type: 'Vacancy',
                  abstractKey: null,
                },
              ],
              storageKey: null,
            },
          ],
        },
      ],
    },
    params: {
      cacheID: 'e5c8a4a1aa590ca901a091734a48f001',
      id: null,
      metadata: {},
      name: 'VacancyQueriesGetVacancyQuery',
      operationKind: 'query',
      text: 'query VacancyQueriesGetVacancyQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ...VacancyFragments\n    id\n  }\n}\n\nfragment VacancyFragments on Vacancy {\n  id\n  createdAt\n  title\n  status\n  industry\n  salaryPeriod\n  organization {\n    id\n    description\n    avatarUrl\n    size\n    headquarters\n    websiteUrl\n    name\n  }\n  salaryFrom\n  salaryTo\n  duration\n  responsibilities\n  description\n  certificates {\n    id\n    name\n    alts\n    priority\n    neverExpires\n    kind {\n      id\n      name\n      perma\n    }\n  }\n  vesselKind {\n    id\n    name\n    perma\n  }\n  duties {\n    id\n    name\n    alts\n    perma\n  }\n  hiringArea {\n    id\n    perma\n    name\n  }\n  tornWeeksOff\n  tornWeeksOn\n  applications {\n    id\n    owner {\n      id\n      info {\n        firstName\n        lastName\n        avatarUrl\n        id\n      }\n    }\n    status\n  }\n  invites {\n    id\n    owner {\n      id\n    }\n  }\n  currency {\n    id\n    name\n    perma\n  }\n  startingCondition\n  startingDate\n  contacts {\n    id\n    owner {\n      id\n    }\n  }\n  organizationMember {\n    id\n    profile {\n      id\n      info {\n        firstName\n        lastName\n        id\n      }\n    }\n    organization {\n      id\n      name\n      avatarUrl\n      size\n      description\n      websiteUrl\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '34a5bb6c307871437c1d91f6f4f70a12';

export default node;
