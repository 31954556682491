// utils
import { formatterFilterByLabel, formatterFilterByName } from 'utils/filterHelpers';
// components
import ToggleFilter from '../components/ToggleFilter';
import { FilteringByNumbers } from 'system/FilterSlideComponents/components/FilteringByNumbers';
import { FilteringByInputValue } from 'system/FilterSlideComponents/components/FilteringByInputValue';
import { SearchFilterWithDropDown } from 'system/FilterSlideComponents/components/SearchFilterWithDropDown';
// models
import { FILTER_BOOLEAN_ID, FILTER_TYPE } from 'models/enums';
// hooks
import useProfileFiltersQuery from 'hooks/searchFilter/useProfileFiltersQuery';
// utils
import { FilterByValues } from '../components/FilterByValues';

const FilterSlideComponent = () => {
  const { allDuties, listOfCountries, allCertificates, allActivities, vesselsType, educationKind } = useProfileFiltersQuery();

  return (
    <section>
      {allDuties && (
        <SearchFilterWithDropDown
          listOfFilters={allDuties}
          formatter={formatterFilterByName}
          filterType={FILTER_TYPE.DUTIES}
          title={{ id: 'search_filter_experience_with_duty_label' }}
          placeholder={{ id: 'label_form_duty' }}
        />
      )}
      {allDuties && (
        <SearchFilterWithDropDown
          listOfFilters={allDuties}
          formatter={formatterFilterByName}
          filterType={FILTER_TYPE.PRIMARY_DUTIES}
          title={{ id: 'search_filter_primary_duty_label' }}
        />
      )}
      {allDuties && (
        <SearchFilterWithDropDown
          listOfFilters={allDuties}
          formatter={formatterFilterByName}
          filterType={FILTER_TYPE.MOST_RECENT_SERVICE_DUTY}
          title={{ id: 'search_filter_most_recent_service_duty_label' }}
        />
      )}
      {allCertificates && (
        <SearchFilterWithDropDown listOfFilters={allCertificates} formatter={formatterFilterByName} filterType={FILTER_TYPE.CERTIFICATES} title={{ id: 'profile_certificates' }} />
      )}
      <FilteringByNumbers
        title={{ id: 'search_filter_popover_number_of_sea_services_title' }}
        filterNameFrom={FILTER_TYPE.NUMBER_OF_SEA_SERVICES_FROM}
        filterNameTo={FILTER_TYPE.NUMBER_OF_SEA_SERVICES_TO}
      />
      <FilteringByNumbers
        title={{ id: 'search_filter_popover_number_of_sea_services_years_experience' }}
        filterNameFrom={FILTER_TYPE.NUMBER_OF_EXPERIENCE_FROM}
        filterNameTo={FILTER_TYPE.NUMBER_OF_EXPERIENCE_TO}
      />
      <FilteringByNumbers
        title={{ id: 'search_filter_popover_number_of_appraisals' }}
        filterNameFrom={FILTER_TYPE.NUMBER_OF_APPRAISALS_FROM}
        filterNameTo={FILTER_TYPE.NUMBER_OF_APPRAISALS_TO}
      />
      {vesselsType && (
        <SearchFilterWithDropDown listOfFilters={vesselsType} formatter={formatterFilterByName} filterType={FILTER_TYPE.VESSEL_TYPES} title={{ id: 'hire_vessel_type_label' }} />
      )}
      {listOfCountries && (
        <SearchFilterWithDropDown
          listOfFilters={listOfCountries}
          formatter={formatterFilterByName}
          filterType={FILTER_TYPE.COUNTRIES}
          title={{ id: 'hire_location_placeholder' }}
        />
      )}
      {allActivities && (
        <SearchFilterWithDropDown
          listOfFilters={allActivities}
          formatter={formatterFilterByLabel}
          filterType={FILTER_TYPE.ACTIVITIES}
          title={{ id: 'search_filter_popover_activity_title' }}
        />
      )}
      {educationKind && (
        <SearchFilterWithDropDown
          listOfFilters={educationKind}
          formatter={formatterFilterByName}
          filterType={FILTER_TYPE.EDUCATION}
          title={{ id: 'search_filter_popover_education_kinds' }}
        />
      )}
      <ToggleFilter
        label="profile_available_for_work"
        description="search_filter_available_for_work_description"
        filterType={FILTER_TYPE.AVAILABLE_FOR_WORK}
        filterBooleanId={FILTER_BOOLEAN_ID.AVAILABLE_FOR_WORK}
      />

      <FilteringByInputValue filterType={FILTER_TYPE.WORKED_FOR} title={{ id: 'search_filter_popover_worked_for' }} />

      <FilterByValues filterType={FILTER_TYPE.CREW_VESSEL_IMO} title={{ id: 'search_filter_popover_crews_vessel_imo' }} />
    </section>
  );
};

export default FilterSlideComponent;
