import Skeleton from 'react-loading-skeleton';

const CrewSettingsSkeletonLeftSide = () => {
  return (
    <div className="flex w-full md:w-1/4 border-r border-solid border-specialGray-012 sm:pt-[53px] pt-8 xl:pl-[51px] lg:pl-6 px-3 lg:space-y-[44px] space-y-6">
      <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-2 lg:space-x-3 space-x-0 h-fit w-full lg:items-start items-center">
        <Skeleton width={40} height={40} />
        <div className="hidden md:flex flex-col w-full lg:items-start items-center">
          <Skeleton height={16} containerClassName="flex w-2/6 items-end" />
          <Skeleton height={14} containerClassName="w-5/6 items-end" />
        </div>
      </div>
    </div>
  );
};

export default CrewSettingsSkeletonLeftSide;
