// packages
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// routing
import { RoutePaths } from 'app/routing';
// components
import { InputGroup } from 'system/InputGroups/InputGroup';
import EmailBlueIcon from 'icons/EmailBlueIcon';
import CustomButton from 'system/Buttons/CustomButton';
import Header from 'auth/SignUpSteps/components/Header';
// types
import { RegisterWithEmailOrPhoneData } from '../types';
// formHelpers
import { RegisterWithEmailOrPhoneValues } from 'formHelpers/initialValuesOfForms';
import { RegisterStepWithEmailOrPhoneValidateSchema } from 'formHelpers/validationsOfForms';
// styles
import 'react-phone-number-input/style.css';
// models
import { PLATFORM_GROUP } from 'models/enums';

const StepWithEmailAndPhone = ({ registerType, defaultError }: { registerType: PLATFORM_GROUP; defaultError?: Error }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { state, search } = useLocation();

  const formik = useFormik<RegisterWithEmailOrPhoneData>({
    onSubmit,
    initialValues: RegisterWithEmailOrPhoneValues(state?.email),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: RegisterStepWithEmailOrPhoneValidateSchema(registerType),
  });

  // clean formik dynamic by business account error if user clear the input
  useEffect(() => {
    if (!formik.values.email && formik.errors.email === 'business_email_validation_with_email') {
      formik.setFieldError('email', '');
    }
  }, [formik]);

  // storybook show error for not business emails
  useEffect(() => {
    if (defaultError?.message && formik.values.email) {
      formik.setFieldError('email', defaultError.message);
      formik.setFieldTouched('email');
    }
    // eslint-disable-next-line
  }, [defaultError]);

  const isHireForm = registerType === 'hire';
  function onSubmit(d: RegisterWithEmailOrPhoneData) {
    navigate({ pathname: isHireForm ? RoutePaths.HireAuthRegisterStepTwo : RoutePaths.AuthRegisterStepTwo, search }, { state: { ...state, email: d.email } });
    window.analytics?.track(isHireForm ? 'wui-hire-signup-step-with-email' : 'wui-signup-step-with-email', {
      email: d.email,
      type_of_account: registerType,
    });
  }

  return (
    <section className="flex w-full flex-col h-full sm:h-auto pt-5 pb-7">
      <Header
        title={{ id: 'email_or_phone_title' }}
        description={isHireForm ? { id: 'email_or_phone_organization_description' } : { id: 'email_or_phone_description' }}
        icon={<EmailBlueIcon />}
      />
      <FormikProvider value={formik}>
        <Form className="flex flex-col mt-7 sm:mt-8 w-full sm:py-8 px-4 sm:border sm:border-specialGray-012 sm:px-10 rounded-[20px] space-y-[18px] h-full justify-between sm:justify-start">
          <fieldset className="space-y-[18px]">
            <InputGroup
              name="email"
              type="email"
              label={{ id: 'profile_email' }}
              placeholderText={{ id: 'default_auth_emailLabel' }}
              inputCustomStyle="h-12 sm:h-[34px]"
              valueForDynamicError={formik.values.email}
              autoFocus
            />

            {formik.errors.email === 'business_email_validation_with_email' && (
              <div className="relative inline-flex text-blue text-sm bottom-2">
                {intl.formatMessage({ id: 'register_are_you_a_crew' })}
                &nbsp;
                <Link state={{ choseTypeOfAccount: 'crew' }} className="underline" to={RoutePaths.AuthRegisterStepOne}>
                  {intl.formatMessage({ id: 'click_here' })}
                </Link>
              </div>
            )}
            {/*Waiting the backend*/}
            {/*<PhoneNumberField name="phoneNumber" />*/}
          </fieldset>
          <fieldset className="flex flex-col items-center space-y-[18px]">
            <CustomButton
              type="submit"
              label={{ id: 'next' }}
              className="flex bg-blue rounded-[10px] text-white flex-row-reverse w-full h-12 items-center justify-center space-x-3 space-x-reverse"
              disabled={formik.isSubmitting}
            >
              <ChevronRightIcon height={24} />
            </CustomButton>
            <div className="flex text-xs space-x-2 mx-auto">
              <span className="text-specialGray-075">{intl.formatMessage({ id: 'register_alreadyAccount' })}</span>
              <Link className="block text-blue" to={isHireForm ? RoutePaths.HireAuthLogin : RoutePaths.AuthLogin}>
                {intl.formatMessage({ id: 'register_sign_in' })}
              </Link>
            </div>
          </fieldset>
        </Form>
      </FormikProvider>
    </section>
  );
};

export default StepWithEmailAndPhone;
