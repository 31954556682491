import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { RoutePaths } from 'app/routing';
//hooks
import { useAuth } from 'authentication';
//translations
import translations from 'translations';
//components
import SpinnerIcon from 'icons/SpinnerIcon';
import CustomButton from 'system/Buttons/CustomButton';
// models
import { PLATFORM_GROUP } from 'models/enums';
import { useSetRecoilState } from 'recoil';
import { selfProfileIdAtom } from '../recoil/Auth/atoms/selfProfileIdAtom';

const ErrorBoundaryLogoutButton = () => {
  const { identity } = useAuth();
  const setSelfProfileIdAtom = useSetRecoilState(selfProfileIdAtom);

  const [loading, setLoading] = useState<boolean>(false);

  const handleLogout = async () => {
    setLoading(true);
    await Auth.signOut();
    window.analytics?.track('wui-error-logout-click', {
      profileId: identity?.profileId,
    });
    localStorage.clear();
    setLoading(false);
    setSelfProfileIdAtom(null);
    window.location.assign(RoutePaths.AuthLogin);
  };

  return identity?.platformGroup !== PLATFORM_GROUP.GUEST ? (
    <CustomButton
      classNameStyle="flex px-3 mr-6 rounded-[10px] h-10 text-blue font-medium bg-blue-100 w-full text-sm justify-center"
      label={{ id: translations.profile_logout }}
      onClick={handleLogout}
    >
      {loading && <SpinnerIcon spinnerColor="#2563EB" additionalClasses="w-5 h-5 mr-1" />}
    </CustomButton>
  ) : null;
};

export default ErrorBoundaryLogoutButton;
