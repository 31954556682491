// packages
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
// components
import CertificateList from 'app/Profile/Certificates/CertificateList/CertificateList';
// generated
import { ProfileQueriesRootQuery } from 'schemas/profile/__generated__/ProfileQueriesRootQuery.graphql';
import { ProfileFragments$data, ProfileFragments$key } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { ProfileQueriesRootRefreshQuery } from 'schemas/profile/__generated__/ProfileQueriesRootRefreshQuery.graphql';
// schemas
import { PROFILE_QUERY } from 'schemas/profile/ProfileQueries';
import { PROFILE_FRAGMENT } from 'schemas/profile/ProfileFragments';
// hooks
import { useAuth } from 'authentication';

const SelfCertificates = () => {
  const { identity } = useAuth();

  const profileFragment = useLazyLoadQuery<ProfileQueriesRootQuery>(PROFILE_QUERY, {
    id: `${identity?.profileId}`,
    skip: !identity?.profileId,
  });

  const [profile] = useRefetchableFragment<ProfileQueriesRootRefreshQuery, ProfileFragments$key>(PROFILE_FRAGMENT, profileFragment.node || null);

  return <CertificateList profile={profile as ProfileFragments$data} />;
};

export default SelfCertificates;
