export const PencilEditIcon = () => {
  return (
    <svg data-test-id="pencil-edit-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.4164 16.5807H16.7306" stroke="#8490A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7124 4.12902C11.3266 3.34652 12.3191 3.38735 13.1024 4.00152L14.2608 4.90985C15.0441 5.52402 15.3216 6.47652 14.7074 7.26069L7.79994 16.0732C7.5691 16.3682 7.2166 16.5424 6.8416 16.5465L4.17744 16.5807L3.5741 13.9849C3.4891 13.6207 3.5741 13.2374 3.80494 12.9415L10.7124 4.12902Z"
        stroke="#8490A8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.41879 5.7793L13.4138 8.91096" stroke="#8490A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
