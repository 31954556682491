// packages
import { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
// components
import { Modal } from 'system/Modal';
import BoatIcon from 'icons/BoatSmall';
import { SeeMoreItem } from 'system/SeeMoreItem';
import { HighlightedSeaServicesItem } from './HighlightedSeaServicesItem';
import HiddenCrewDataPopover from 'system/ProfileSlide/components/HiddenCrewDataPopover';
import SeaServiceJobExperience from 'system/SeaServiceJobExpirience/SeaServiceJobExperience';
// generated
import { ServiceFragments$data } from 'schemas/services/__generated__/ServiceFragments.graphql';
import { ServiceFragmentsListPlural_profile$key } from 'schemas/services/__generated__/ServiceFragmentsListPlural_profile.graphql';
// schemas
import { SERVICES_LIST_FRAGMENT } from 'schemas/services/ServiceFragments';
// hooks
import { useConvertToCorrectServiceDate } from 'hooks/useConvertToCorrectServiceDate';
import useRestrictedCrewProfileSeaServices from 'hooks/ProfileDataHooks/useRestrictedCrewProfileSeaServices';
//type
import { HighlightedOverviewSectionProps } from 'app/ProfileCrew/type/ProfileCrewTypes';
import { RESTRICTED_PROFILE_DATA_COUNTER } from 'models/enums';

export const LatestCrewSeaServices = ({ profileCrewData }: HighlightedOverviewSectionProps) => {
  const intl = useIntl();

  const { getLatestSeaServices } = useRestrictedCrewProfileSeaServices(profileCrewData?.id);

  const seaServices = useFragment<ServiceFragmentsListPlural_profile$key>(SERVICES_LIST_FRAGMENT, profileCrewData?.seaServices || null);
  const { convertDays, convertMonths, convertYears } = useConvertToCorrectServiceDate(seaServices);

  const allSeaServicesAmount = profileCrewData?.seaServices?.length || 0;

  const [showSeeMoreModal, setShowSeeMoreModal] = useState(false);
  const [currentService, setCurrentService] = useState<ServiceFragments$data>();

  const closeSeeMoreModal = useCallback(() => {
    setShowSeeMoreModal(false);
  }, []);

  const openSeeMoreModal = useCallback((service: ServiceFragments$data) => {
    setCurrentService(service);
    setShowSeeMoreModal(true);
  }, []);

  const seaServicesAmount = profileCrewData?.seaServices?.length || 0;
  const showMoreSeaServicesCounter = seaServicesAmount - RESTRICTED_PROFILE_DATA_COUNTER.SEA_SERVICES;

  return (
    <section className="flex flex-col w-full gap-y-4">
      {!isEmpty(getLatestSeaServices) ? (
        <Modal onClose={closeSeeMoreModal} show={showSeeMoreModal} tinyModal childrenClass="mt-0 mb-2.5">
          <SeeMoreItem currentService={currentService} />
        </Modal>
      ) : null}

      <div className="md:flex md:items-center md:justify-between pt-5 pb-1">
        <div className="flex flex-col w-full">
          <h2 id="sea-service-title" className="flex-1 min-w-0 text-lg leading-6 font-semibold text-specialGray-1">
            {intl.formatMessage({ id: 'profile_seaServiceTitle' })}
          </h2>
          <div className="flex justify-between w-full text-specialGray-075 text-sm mt-1">
            <div className="flex">
              <span className="mr-3">{intl.formatMessage({ id: 'profile_seaServiceAmount' }, { amount: allSeaServicesAmount })}</span>
              {!isEmpty(seaServices) && (
                <SeaServiceJobExperience days={convertDays} months={convertMonths} years={convertYears} classes="flex items-center text-specialGray-075 ml-3" />
              )}
            </div>
          </div>
        </div>
      </div>
      {!isEmpty(getLatestSeaServices) ? (
        <div className={`flex w-full flex-col md:flex-row${seaServicesAmount >= RESTRICTED_PROFILE_DATA_COUNTER.SEA_SERVICES ? ' justify-between' : ''}`}>
          {getLatestSeaServices?.map(service => {
            if (!service?.node) {
              return null;
            }
            return service && <HighlightedSeaServicesItem key={service.node?.id} servicesAmount={seaServicesAmount} service={service.node} onClickMore={openSeeMoreModal} />;
          })}
        </div>
      ) : null}

      {seaServicesAmount > RESTRICTED_PROFILE_DATA_COUNTER.SEA_SERVICES && (
        <HiddenCrewDataPopover label={intl.formatMessage({ id: 'profile_seaService_with_restriction_amount' }, { amount: showMoreSeaServicesCounter })} />
      )}

      {isEmpty(getLatestSeaServices) && (
        <div className="flex items-center border border-specialGray-012 rounded-xl px-4 py-2">
          <BoatIcon />
          <span className="text-darkBlue ml-2 mr-1 text-sm">{intl.formatMessage({ id: 'profile_no_sea_services' })}</span>
        </div>
      )}
    </section>
  );
};
