// packages
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useParams, Link } from 'react-router-dom';
// components
import { Modal } from 'system/Modal';
import DoneIcon from 'icons/DoneIcon';
import InviteIcon from 'icons/InviteIcon';
import FolderPlus from 'icons/FolderPlus';
import SpinnerIcon from 'icons/SpinnerIcon';
import { ProfileAvatar } from 'app/Avatars/ProfileAvatar/ProfileAvatar';
import AvailableForWorkLabel from 'app/ProfileCrew/components/AvailableForWorkLabel';
import ListOfVacancies from 'app/ProfileSearch/components/ProfileListItem/components/ListOfVacancies/ListOfVacancies';
import ContactButton from 'app/ProfileCrew/ProfileCrewViews/ProfileCrewEmployerView/MainCrewInformationEmployerView/components/ButtonToContactWithCrew/ContactButton';
import EmployerAddUserToCrewPoolCommon from 'app/ProfileEmployer/components/EmployerCrewPools/components/EmployerAddUserToCrewPool/components/EmployerAddUserToCrewPoolCommon';
// models
import { HeaderProps } from 'models/modelsOfComponents';
// routes
import { RoutePaths } from 'app/routing';
// hooks
import { useAuth } from 'authentication';
import useGetIsProSubscription from 'hooks/subscription/useGetIsProSubscription';
import useGetRestrictedProfileLastName from 'hooks/ProfileDataHooks/useGetRestrictedProfileLastName';
import { useIsUserAppliedForAnyVacancies } from 'hooks/hireProfileHooks/useIsUserAppliedForAnyVacancies';
import { useIsInvitedCrewEvenForOneOfVacancies } from 'hooks/hireProfileHooks/useIsInvitedCrewEvenForOneOfVacancies';
//utils
import { envVariableTransform } from 'utils';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';
import { contactInformationAtom } from 'recoil/ContactInformation/atoms/contactInformationAtom';
import { paymentSubscriptionModalAtom } from 'recoil/ContactInformation/atoms/paymentSubscriptionModalAtom';

const ProfileSlideHeader = ({ profileInfo, profileId, generalSettings }: HeaderProps) => {
  const intl = useIntl();
  const { identity } = useAuth();
  const params = useParams();
  const isInvitedUserEvenForOneOfVacancies: boolean = useIsInvitedCrewEvenForOneOfVacancies(profileId);
  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);
  const lastName = useGetRestrictedProfileLastName(profileInfo?.lastName);

  const isProSubscription = useGetIsProSubscription();
  const isUserAppliedForAnyVacancies = useIsUserAppliedForAnyVacancies(profileId);

  const [showListOfVacancies, setShowListOfVacancies] = useState<boolean>(false);
  const [showAddUserToPoolModal, setShowAddUserToPoolModal] = useState<boolean>(false);

  const setContactInfoButton = useSetRecoilState(contactInformationAtom);
  const setOpenPaymentSubscriptionModal = useSetRecoilState(paymentSubscriptionModalAtom);

  const handleSendAnalytics = (profileId: string) => () => {
    window.analytics?.track('wui-clicked-view-profile', {
      profileId,
    });
  };

  const handleOpenModal = useCallback(
    (setState: React.Dispatch<React.SetStateAction<boolean>>) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setState(true);
    },
    [],
  );

  const handleCloseModal = useCallback((setState: React.Dispatch<React.SetStateAction<boolean>>) => () => setState(false), []);

  const pathToProfileDetailPage = useMemo((): string => {
    const isSelfProfile = params.id === identity?.profileId;
    if (isSelfProfile) {
      return RoutePaths.ProfileBase;
    }
    if (getCurrentOrganisation.isSelected) {
      return `${RoutePaths.ProfileSingleCrewPageEmployerView}/${profileId}`;
    }
    return `${RoutePaths.ProfileSingleCrewPage}/${profileId}`;
  }, [getCurrentOrganisation.isSelected, identity?.profileId, params.id, profileId]);

  const handleOpenContactModal = useCallback(() => {
    window.analytics?.track('wui-clicked-contact-button', {
      hireOrganizationId: getCurrentOrganisation.organisationId,
      crewProfileId: profileId,
    });

    // for the applied page there are no restrictions on viewing the contact info
    if (isProSubscription || isUserAppliedForAnyVacancies) {
      setContactInfoButton({
        profileId: profileId,
        showContactInformation: true,
        isLoading: true,
      });
      return;
    }
    setOpenPaymentSubscriptionModal({ showPaymentSubscriptionModal: true });
  }, [getCurrentOrganisation.organisationId, isProSubscription, isUserAppliedForAnyVacancies, profileId, setContactInfoButton, setOpenPaymentSubscriptionModal]);

  return (
    <div className="flex w-full sm:flex-row flex-col">
      {getCurrentOrganisation.isSelected && (
        <>
          {(envVariableTransform(process.env.REACT_APP_FEATURE_INVITE_BUTTON) || envVariableTransform(process.env.STORYBOOK_FEATURE_INVITE_BUTTON)) && (
            <Modal
              description={{ id: 'search_modal_description' }}
              title={{ id: 'search_modal_title' }}
              onClose={handleCloseModal(setShowListOfVacancies)}
              show={showListOfVacancies}
            >
              <Suspense fallback={<SpinnerIcon />}>
                <ListOfVacancies crewProfileId={profileId} />
              </Suspense>
            </Modal>
          )}

          {(envVariableTransform(process.env.REACT_APP_FEATURE_HIRE_CREW_POOLS) || envVariableTransform(process.env.STORYBOOK_FEATURE_INVITE_BUTTON)) && (
            <Modal
              childrenClass="flex w-full text-darkBlue"
              description={{ id: 'hire_crew_pool_invite_user_description' }}
              title={{ id: 'hire_crew_pool_invite_user_title' }}
              onClose={handleCloseModal(setShowAddUserToPoolModal)}
              show={showAddUserToPoolModal}
            >
              <EmployerAddUserToCrewPoolCommon onHandleClose={handleCloseModal(setShowAddUserToPoolModal)} userId={profileId} />
            </Modal>
          )}
        </>
      )}
      <div className="flex relative justify-center">
        <Link onClick={handleSendAnalytics(profileId)} to={pathToProfileDetailPage}>
          <ProfileAvatar avatarUrl={profileInfo?.avatarUrl} size="w-48 h-48" avatarRadius="rounded-2xl" />
        </Link>
      </div>
      <div className="w-full sm:ml-2">
        <div className="relative top-2 sm:ml-4">
          <div className="font-semibold text-specialGray-1 mt-3 sm:mt-16 text-2xl">
            <Link onClick={handleSendAnalytics(profileId)} to={pathToProfileDetailPage}>
              {profileInfo ? `${profileInfo?.firstName} ${lastName}` : intl.formatMessage({ id: 'profile_is_empty' })}
            </Link>
          </div>
          <div className="flex items-center gap-x-4">
            <div className="flex w-fit text-specialGray-075 text-sm">{profileInfo ? `\u{0040}${profileInfo?.firstName}${profileInfo?.lastName}`.toLocaleLowerCase() : ''}</div>
            {getCurrentOrganisation.isSelected && <AvailableForWorkLabel isAvailable={generalSettings === null || generalSettings.availableForWork} />}
          </div>
        </div>
        <div className="flex w-full pt-4 sm:pt-6 sm:flex-row flex-col gap-4">
          <section>
            {getCurrentOrganisation.isSelected && (
              <div className="flex gap-x-3 items-center ml-4">
                <ContactButton profileId={profileId} onClick={handleOpenContactModal} />
                {(envVariableTransform(process.env.REACT_APP_FEATURE_INVITE_BUTTON) || envVariableTransform(process.env.STORYBOOK_FEATURE_INVITE_BUTTON)) && (
                  <button
                    className={`flex justify-center gap-x-2 border border-solid bg-white ${
                      isInvitedUserEvenForOneOfVacancies ? 'hover:bg-green-50 border-green-500 text-green-500' : 'hover:bg-gray-50 border-specialGray-05 text-specialGray-075'
                    } shadow-sm rounded-[10px] h-10 px-[19px] py-2 w-full sm:w-auto mx-auto`}
                    onClick={handleOpenModal(setShowListOfVacancies)}
                  >
                    {isInvitedUserEvenForOneOfVacancies ? <DoneIcon /> : <InviteIcon />}
                    {intl.formatMessage({ id: isInvitedUserEvenForOneOfVacancies ? 'hire_job_invited_label' : 'search_list_item_invite_button_label' })}
                  </button>
                )}
                {(envVariableTransform(process.env.REACT_APP_FEATURE_HIRE_CREW_POOLS) || envVariableTransform(process.env.STORYBOOK_FEATURE_INVITE_BUTTON)) && (
                  <>
                    <button
                      onClick={handleOpenModal(setShowAddUserToPoolModal)}
                      className="flex w-fit bg-white px-3 items-center border h-10 border-specialGray-05 rounded-[10px] hover:bg-gray-50 text-specialGray-05 mr-2"
                    >
                      <FolderPlus />
                    </button>
                  </>
                )}
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default ProfileSlideHeader;
