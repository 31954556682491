import { forwardRef } from 'react';
import { useIntl } from 'react-intl';
//models
import { CommonInputProps } from 'models/modelsOfComponents';

const CommonInput = forwardRef<HTMLInputElement, CommonInputProps>(({ children, label, inputValue, onChange, id, clickEvent, validationError, onKeyDown, placeholder }, ref) => {
  const intl = useIntl();
  const borderClasses: string = validationError ? 'focus:ring-red-500 focus:border-red-500 border-red-500' : 'focus:ring-blue-500 focus:border-blue-500 border-specialGray-012';

  return (
    <div onClick={clickEvent} className="flex-col w-full">
      <span className="text-sm font-medium text-darkBlue">{intl.formatMessage(label)}</span>
      <div className="my-1 mb-6 relative mt-4">
        {children}
        <input
          ref={ref}
          placeholder={intl.formatMessage(placeholder ?? label)}
          id={id}
          className={`shadow-sm ${borderClasses} block w-full sm:text-sm rounded-md placeholder:text-specialGray-05 pl-2 text-darkBlue`}
          value={inputValue}
          onChange={onChange}
          type="text"
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
});

export default CommonInput;
