// @ts-nocheck
import { Component, ErrorInfo } from 'react';
import * as Sentry from '@sentry/react';
//utils
import { getRequestErrorIdFromMessage } from 'utils';
//models
import { ErrorBoundaryProps, ErrorBoundaryState } from 'models/modelsOfComponents';
//components
import { NotFound } from 'system/NotFound';
import ErrorBoundaryMessage from './components/ErrorBoundaryMessage';

class ErrorBoundaryMainContent extends Component<
  ErrorBoundaryProps & {
    storybookError?: Error;
  },
  ErrorBoundaryState
> {
  public state: ErrorBoundaryState = {
    error: null,
    hasError: false,
    errorId: '',
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
    this.prevPath = null;
  }

  public static getDerivedStateFromError(err: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: err, errorId: getRequestErrorIdFromMessage(err) };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.warn('Uncaught error:', error, errorInfo);

    if (error && error?.message !== 'NOT_FOUND') {
      Sentry.captureException(error);
    }
  }

  componentDidMount() {
    this.prevPath = window.location.pathname;
    if (this.props.storybookError) {
      this.setState({ hasError: true, errorId: getRequestErrorIdFromMessage(this.props.storybookError), error: this.props.storybookError });
    }
  }

  // componentDidUpdate we use in case for navigate between pages if it has error
  componentDidUpdate() {
    if (window.location.pathname !== this.prevPath) {
      this.setState({ error: null, errorInfo: null, hasError: false });
    }
    this.prevPath = window.location.pathname;
  }
  public render() {
    if (this.state.hasError) {
      return this.state.error?.message === 'NOT_FOUND' ? <NotFound /> : <ErrorBoundaryMessage error={this.state.error} errorId={this.state.errorId} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundaryMainContent;
