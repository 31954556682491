// packages
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// components
import NoJobOpeningsForOperator from 'icons/NoJobOpeningsForOperator';
// routes
import { RoutePaths } from 'app/routing';
import { ArrowUpRightIcon } from '@heroicons/react/24/solid';

const NoJobs = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleClearSearchParams = () => {
    navigate({ pathname: RoutePaths.JobOpenings, search: '' });
  };
  return (
    <div className="flex flex-col items-center w-full sm:w-[400px] mx-auto mt-20">
      <NoJobOpeningsForOperator />
      <div className="flex flex-col items-center mt-2">
        <span className="text-xs text-specialGray-075 text-center w-[120px]">{intl.formatMessage({ id: 'job_opening_empty_page_description_for_operator' })}</span>
        <button onClick={handleClearSearchParams} className="sm:whitespace-nowrap whitespace-normal text-blue text-sm font-medium cursor-pointer sm:mt-5 mt-3">
          {intl.formatMessage({ id: 'job_opening_browser_other_employers' })}
          <ArrowUpRightIcon className="w-4 h-4 text-blue inline-block ml-1" />
        </button>
      </div>
    </div>
  );
};

export default NoJobs;
