// packages
import { Component, ErrorInfo, Suspense } from 'react';
// components
import AlertForError from 'system/Alert/AlertForError';
import SeaServiceForm from './components/SeaServiceForm/SeaServiceForm';
import StepWithSeaServiceFormSkeleton from 'system/skeletons/SignUpSteps/StepWithSeaServiceForm/StepWithSeaServiceFormSkeleton';

class StepWithSeaServiceErrorBoundaryForm extends Component<{}, { error: Error | null; hasError: boolean }> {
  public state: { error: Error | null; hasError: boolean } = {
    error: null,
    hasError: false,
  };
  constructor(props: {}) {
    super(props);
    this.handleError = this.handleError.bind(this);
    this.getTypeForAlert = this.getTypeForAlert.bind(this);
    this.getHeadForAlert = this.getHeadForAlert.bind(this);
  }
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.warn('Uncaught error:', error, errorInfo);
  }

  public static getDerivedStateFromError(err: Error) {
    return { hasError: true, error: err };
  }

  public handleError(error: Error | null) {
    this.setState({ hasError: !!error, error: error });
  }

  public getTypeForAlert() {
    return this.state.error?.message === 'service_not_exist_vessel_by_imo' ? 'warning' : 'error';
  }

  public getHeadForAlert() {
    return this.state.error?.message === 'service_not_exist_vessel_by_imo' ? 'service_form_vessel_did_not_find' : 'service_form_failedSubmit';
  }

  render() {
    return (
      <>
        {this.state.hasError ? <AlertForError heading={{ id: this.getHeadForAlert() }} error={this.state.error as Error} type={this.getTypeForAlert()} /> : null}
        <Suspense fallback={<StepWithSeaServiceFormSkeleton />}>
          <SeaServiceForm />
        </Suspense>
      </>
    );
  }
}

export default StepWithSeaServiceErrorBoundaryForm;
