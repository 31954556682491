// packages
import { useState, useEffect, useCallback, Suspense } from 'react';
import { useIntl } from 'react-intl';
import { FormikProvider, useFormik, Form } from 'formik';
import { useFragment, useLazyLoadQuery, useMutation } from 'react-relay';
// components
import { Button } from 'system/Button';
import { DateInputGroup } from 'system/DateInputGroup';
import { useCurrentWidth } from 'hooks/useBreakpoints';
import { InputGroup } from 'system/InputGroups/InputGroup';
import TagInputGroup from 'system/TagInputGroup/TagInputGroup';
import VesselInformation from './components/VesselInformation';
import { CheckboxInputGroup } from 'system/CheckboxInputGroup';
import SelectDutyComponent from 'system/SelectDutyComponent/SelectDutyComponent';
import VesselInformationSkeleton from 'system/skeletons/servies/VesselInformationSkeleton';
//helpers
import { getConnectionId } from 'formHelpers/utils';
import { ServiceFormValidateSchema } from 'formHelpers/validationsOfForms';
import { ServiceFormInitialValues } from 'formHelpers/initialValuesOfForms';
import { ServiceFormCommitCreate, ServiceFormCommitUpdater } from 'formHelpers/updaters/updatersOfService';
// generated
import { ServiceFragmentsActivities$key } from 'schemas/services/__generated__/ServiceFragmentsActivities.graphql';
import { ServiceFragmentsVesselKind$data } from 'schemas/services/__generated__/ServiceFragmentsVesselKind.graphql';
import { ServiceFragments$data, ServiceFragments$key } from 'schemas/services/__generated__/ServiceFragments.graphql';
import { ServiceQueriesVesselKindsQuery } from 'schemas/services/__generated__/ServiceQueriesVesselKindsQuery.graphql';
import { ServiceMutationsFormCreateMutation } from 'schemas/services/__generated__/ServiceMutationsFormCreateMutation.graphql';
import { ServiceMutationsFormUpdateMutation } from 'schemas/services/__generated__/ServiceMutationsFormUpdateMutation.graphql';
import { ServiceQueriesDetailsVesselQuery$data } from 'schemas/services/__generated__/ServiceQueriesDetailsVesselQuery.graphql';
import { ServiceQueriesFormGetAllDutiesQuery } from 'schemas/services/__generated__/ServiceQueriesFormGetAllDutiesQuery.graphql';
import { ServiceFragmentsVessel$data, ServiceFragmentsVessel$key } from 'schemas/services/__generated__/ServiceFragmentsVessel.graphql';
import { ServiceFragmentsOneDuty$data, ServiceFragmentsOneDuty$key } from 'schemas/services/__generated__/ServiceFragmentsOneDuty.graphql';
import { ServiceMutationsAddTagsToSeaServiceMutation } from 'schemas/services/__generated__/ServiceMutationsAddTagsToSeaServiceMutation.graphql';
// schemas
import { GET_ALL_DUTIES_QUERY, GET_VESSELS_KINDS } from 'schemas/services/ServiceQueries';
import { ADD_TAGS_TO_SEA_SERVICE, CREATE_SERVICE, UPDATE_SERVICE } from 'schemas/services/ServiceMutations';
import { GET_ONE_DUTY, SERVICE_ACTIVITIES, SERVICE_FRAGMENT, VESSEL_FRAGMENT } from 'schemas/services/ServiceFragments';
// hooks
import { useAuth } from 'authentication';
//models
import { IServiceFormData, IVesselData } from 'models/modelsOfForms';
import { BREAKPOINTS } from 'models/enums';
import { IVesselKind } from 'models/IVesselKind';
import { IActivities } from 'models/IActivity';
import { ServiceFormProps } from './types';

export const ServiceForm = ({
  service = null,
  defaultSubmitting = false,
  defaultError = null,
  onSubmitted = () => {},
  isOpenCreateModal,
  defaultShowModal = false,
  handleError,
  globalError,
}: ServiceFormProps) => {
  const { identity } = useAuth();
  const storyID = getConnectionId('ServiceListFromProfile_seaServices');
  const intl = useIntl();

  const [commitCreate] = useMutation<ServiceMutationsFormCreateMutation>(CREATE_SERVICE);
  const [commitUpdate] = useMutation<ServiceMutationsFormUpdateMutation>(UPDATE_SERVICE);
  const [commitAddTags] = useMutation<ServiceMutationsAddTagsToSeaServiceMutation>(ADD_TAGS_TO_SEA_SERVICE);

  const ServicesData = useFragment<ServiceFragments$key>(SERVICE_FRAGMENT, service);
  const dataOfDuty = useFragment<ServiceFragmentsOneDuty$key>(GET_ONE_DUTY, ServicesData?.duty || null);
  const [MAX_LENGTH_FOR_PIN_CODE_INPUT] = useState<number>(7);

  const allDuties = useLazyLoadQuery<ServiceQueriesFormGetAllDutiesQuery>(GET_ALL_DUTIES_QUERY, { skip: false });
  const vesselKinds = useLazyLoadQuery<ServiceQueriesVesselKindsQuery>(GET_VESSELS_KINDS, { skip: false });

  const activitiesFromService = useFragment<ServiceFragmentsActivities$key>(SERVICE_ACTIVITIES, ServicesData?.activities || null);
  const vesselData = useFragment<ServiceFragmentsVessel$key>(VESSEL_FRAGMENT, ServicesData?.vessel || null);
  const [isDisabledTo, setDisabledTo] = useState<boolean>(ServicesData?.end === null);

  const [isClosedModal, setIsClosedModal] = useState<boolean>(true);
  const [isResetForm, setIsResetForm] = useState<boolean>(false);
  const [getDetailsVessel, setGetDetailsVessel] = useState<ServiceQueriesDetailsVesselQuery$data | null>(null);

  const windowSize = useCurrentWidth();

  const formik = useFormik<IServiceFormData>({
    onSubmit,
    initialValues: ServiceFormInitialValues(ServicesData, vesselData as ServiceFragmentsVessel$data, dataOfDuty?.id, activitiesFromService as IActivities),
    validationSchema: ServiceFormValidateSchema(),
  });

  useEffect(() => {
    if (defaultSubmitting && !formik.isSubmitting) formik.setSubmitting(true);
  }, [formik, defaultSubmitting]);

  const handleAnalyticsWhenVesselInfoChangedManually = ({ name, imo, kind, operatorName }: IVesselData) => {
    if (getDetailsVessel && getDetailsVessel.vessel) {
      window.analytics?.track('wui-changed-imo', {
        label: 'User changed IMO manually',
        imo,
      });

      if (getDetailsVessel.vessel?.name !== name) {
        window.analytics?.track('wui-changed-vessel-name', {
          label: 'User changed vessel name manually',
          oldName: getDetailsVessel.vessel?.name,
          newName: name,
        });
      }

      if (getDetailsVessel.vessel?.kind?.id !== kind) {
        window.analytics?.track('wui-changed-vessel-kind', {
          label: 'User changed vessel kind manually',
          oldKindId: getDetailsVessel.vessel?.name,
          newKindId: kind,
        });
      }

      if (getDetailsVessel.vessel?.owner !== operatorName) {
        window.analytics?.track('wui-changed-operator-name', {
          label: 'User changed operator name manually',
          oldOperatorName: getDetailsVessel.vessel?.owner,
          newOperatorName: operatorName,
        });
      }
    }
  };

  const onCompletedCommits = ({ workingNow, tags, vessel }: IServiceFormData) => {
    onSubmitted(!isClosedModal);
    if (workingNow) {
      window.analytics?.track('wui-currently-worked-here-selected', {
        label: 'The user selected "I\'m currently working here"',
        value: workingNow,
      });
    }

    window.analytics?.track('wui-added-tags', {
      label: 'The user has added tags to a sea service',
      numberOfTags: tags.length ?? 0,
    });

    handleAnalyticsWhenVesselInfoChangedManually(vessel);
  };

  useEffect(() => {
    if (getDetailsVessel && getDetailsVessel.vessel?.imoNumber !== vesselData?.imo) {
      window.analytics?.track('wui-user-changed-imo', {
        value: getDetailsVessel.vessel?.imoNumber as number,
      });
    }
  }, [getDetailsVessel, vesselData?.imo]);

  function onSubmit(formData: IServiceFormData) {
    const onError = (error: Error) => {
      formik.setSubmitting(false);
      if (handleError) {
        handleError(error);
      }
    };

    const data = {
      ownerId: `${identity?.profileId}`,
      start: formData.start ? formData.start.toISOString() : '',
      end: formData.end ? formData.end.toISOString() : null,
      rotations: formData.rotations,
      dutyId: formData.dutyId !== 'null' ? formData.dutyId : '',
      vesselKindId: formData.vessel.kind,
      vesselImo: formData.vessel.imo ? +formData.vessel.imo : 0,
      vesselName: formData.vessel.name,
      vesselOperatorName: formData.vessel.operatorName || undefined,
      vesselGrossTonnage: formData.vessel.gross_tonnage ? +formData.vessel.gross_tonnage : 0,
      vesselImage: formData.vessel.image || '',
      vesselCnIso: formData.vessel.cn_iso || '',
      vesselLength: formData.vessel.length || 0,
      description: formData.note || null,
    };

    if (ServicesData) {
      commitUpdate({
        onError,
        onCompleted: () => {
          onCompletedCommits(formData);
          window.analytics?.track('wui-updated-sea-service', {
            updatedSeaServiceId: ServicesData.id,
          });
        },
        variables: {
          id: ServicesData.id,
          data,
        },
        updater: ServiceFormCommitUpdater(formData, onError, ServicesData, commitAddTags),
      });
    } else if (identity?.profileId) {
      commitCreate({
        onError,
        onCompleted: response => {
          onCompletedCommits(formData);
          window.analytics?.track('wui-created-sea-service', {
            updatedSeaServiceId: response.createSeaService?.node?.id,
          });
        },
        variables: {
          data,
          connections: [`${storyID}(ownerId:"${identity?.profileId}")`],
        },
        updater: ServiceFormCommitCreate(formData, onError, commitAddTags, `${identity?.profileId}`),
      });
    }
  }

  useEffect(() => {
    if (defaultError) {
      formik.setSubmitting(true);
    } else {
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line
  }, [defaultError]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (isResetForm) {
      timer = setTimeout(() => {
        setIsResetForm(false);
      }, 100);
    }
    return () => {
      clearTimeout(timer!);
    };
  }, [isResetForm]);

  const handleSetDetailsVessel = useCallback(
    (detailsVessel: ServiceQueriesDetailsVesselQuery$data | null) => {
      setGetDetailsVessel(detailsVessel);
      if (detailsVessel && detailsVessel.vessel) {
        formik.setFieldValue('vessel.name', detailsVessel.vessel.name);
        formik.setFieldValue('vessel.imo', detailsVessel.vessel.imoNumber);
        formik.setFieldValue('vessel.operatorName', detailsVessel.vessel.owner);
        formik.setFieldValue('vessel.image', detailsVessel.vessel.imageURL);
        formik.setFieldValue('vessel.gross_tonnage', detailsVessel.vessel.grossTonnage);
        formik.setFieldValue('vessel.length', detailsVessel.vessel.length);
        formik.setFieldValue('vessel.cn_iso', detailsVessel.vessel.cnISO);
        formik.setFieldValue('vessel.kind', detailsVessel.vessel.kind?.id);
      }
      if (formik.values.vessel.imo !== `${vesselData?.imo}` && !detailsVessel?.vessel) {
        formik.setFieldValue('vessel.name', '');
        formik.setFieldValue('vessel.imo', formik.values.vessel.imo || '');
        formik.setFieldValue('vessel.operatorName', '');
        formik.setFieldValue('vessel.image', '');
        formik.setFieldValue('vessel.gross_tonnage', '');
        formik.setFieldValue('vessel.length', '');
        formik.setFieldValue('vessel.cn_iso', '');
        formik.setFieldValue('vessel.kind', '');
      }
    },
    // eslint-disable-next-line
    [ServicesData, formik.values.vessel.imo],
  );

  useEffect(() => {
    if (+formik.values.vessel.imo !== getDetailsVessel?.vessel?.imoNumber) {
      formik.setFieldValue('vessel.imo', formik.values.vessel.imo);
    } else {
      formik.setFieldValue('vessel.imo', getDetailsVessel.vessel.imoNumber);
    }
    // eslint-disable-next-line
  }, [formik.values.vessel.imo, getDetailsVessel?.vessel?.imoNumber]);

  return (
    <FormikProvider value={formik}>
      <Form className="space-y-6">
        <section className="flex flex-col sm:flex-row">
          <div className={`${formik.errors.start && formik.touched.start ? 'mb-6' : 'mb-3.5'} sm:mb-0`}>
            <DateInputGroup autoFocus name="start" label={{ id: 'input_placeholder_from' }} isResetInput={isResetForm} />
          </div>
          <div className="flex-col justify-end mx-3.5 text-specialGray-03 hidden sm:flex">
            <span className="mb-[7px]">—</span>
          </div>
          <DateInputGroup name="end" label={{ id: 'input_placeholder_to' }} isResetInput={isResetForm} disabled={isDisabledTo} />
        </section>

        <section className="flex flex-row">
          <div className="w-full">
            <CheckboxInputGroup name="workingNow" value={isDisabledTo.toString()} label={{ id: 'service_form_working_now' }} onChange={setDisabledTo} />
          </div>
        </section>
        <section className="flex flex-col sm:flex-row">
          <SelectDutyComponent allDuties={allDuties} dataOfDuty={dataOfDuty as ServiceFragmentsOneDuty$data} isResetForm={isResetForm} nameField="dutyId" />

          <InputGroup typeInput="digital" name="rotations" label={{ id: 'service_form_rotations' }} helpText={{ id: 'service_form_rotations_help_message' }} />
        </section>

        <section className="flex flex-row">
          <TagInputGroup
            name="tags"
            label={{ id: 'service_form_tags_label' }}
            placeholder={{ id: 'service_form_tagging_placeholder' }}
            isResetInput={isResetForm}
            defaultArrayOfTags={activitiesFromService}
            helpText={{ id: 'sea_service_modal_help_text' }}
            autoComplete="off"
            maxCharCount={35}
          />
        </section>

        <section>
          <h2 className="mt-6 sm:mt-8 text-left text-lg font-medium text-specialGray-1">{intl.formatMessage({ id: 'service_form_vessel_info_title' })}</h2>
          <p className="mt-1 text-xs sm:text-sm text-specialGray-075">{intl.formatMessage({ id: 'service_form_vessel_info_text' })}</p>
        </section>

        <Suspense fallback={<VesselInformationSkeleton />}>
          <VesselInformation
            isResetForm={isResetForm}
            dataOfVesselKind={vesselData?.kind as ServiceFragmentsVesselKind$data}
            ServicesData={ServicesData as ServiceFragments$data}
            defaultShowModal={defaultShowModal}
            maxInputLength={MAX_LENGTH_FOR_PIN_CODE_INPUT}
            setGetDetailsVessel={handleSetDetailsVessel}
            vesselKinds={vesselKinds.vesselKinds?.edges as IVesselKind}
            handleError={handleError}
            globalError={globalError}
          />
        </Suspense>

        <input type="hidden" name="vessel.fleetmonId" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.vessel.fleetmonId} />
        <input type="hidden" name="vessel.cn_iso" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.vessel.cn_iso} />
        <input type="hidden" name="vessel.gross_tonnage" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.vessel.gross_tonnage} />
        <input type="hidden" name="vessel.length" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.vessel.length} />

        <div className="flex flex-col sm:flex-row sm:justify-end">
          <Button
            onClick={() => {
              setIsClosedModal(true);
            }}
            buttonType="secondary"
            type="submit"
            label={{ id: 'service_form_save' }}
            disabled={formik.isSubmitting}
            fullSize={windowSize.width < BREAKPOINTS.sm.max}
          />
          {isOpenCreateModal && (
            <div className="sm:ml-2 mt-5 sm:mt-0">
              <Button
                onClick={() => {
                  setIsClosedModal(false);
                }}
                type="submit"
                label={{ id: 'save_and_add_another' }}
                disabled={formik.isSubmitting}
                fullSize={windowSize.width < BREAKPOINTS.sm.max}
              />
            </div>
          )}
        </div>
      </Form>
    </FormikProvider>
  );
};
