// packages
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
// routing
import { RoutePaths, RoutesPathNames } from 'app/routing';
// utils
import translations from 'translations';

const Breadcrumbs: FC = () => {
  const { jobDescriptionEditId, orgId } = useParams();

  const pages = [
    {
      name: translations.hire_dashboard_header_button_job_opening,
      href: `${RoutePaths.ProfileOrganizationBase}/${orgId}/${RoutesPathNames.job}/${RoutesPathNames.edit}/${jobDescriptionEditId}`,
      current: false,
    },
    { name: translations.hire_job_description_invited_crew_title, href: RoutePaths.JobInvitedCrew, current: true },
  ];

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {pages.map((page, index) => (
          <li key={page.name} className="flex items-center">
            {index !== 0 && <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-darkBlue font-normal mr-4" aria-hidden="true" />}
            <Link
              to={page.current ? '#' : page.href}
              className={`text-sm font-medium  ${page.current ? 'text-darkBlue font-medium' : 'text-darkBlue opacity-80 hover:opacity-100'}`}
              aria-current={page.current ? 'page' : undefined}
            >
              {page.name}
            </Link>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
