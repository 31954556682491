import { useState } from 'react';

export function useToggleShowWindow() {
  const [showWindow, setShowWindow] = useState<boolean>(false);

  const toggleShowWindow = (value: boolean) => () => {
    setShowWindow(value);
  };

  return {
    showWindow,
    toggleShowWindow,
  };
}
