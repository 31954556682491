import React from 'react';
import { PopoverProps } from 'models/modelsOfComponents';

const Popover: React.FC<PopoverProps> = ({ children, position }) => {
  return (
    <section
      id="common-popover"
      className={`absolute ${position || '-top-10'} bg-white text-center border w-72 text-sm py-1 rounded-lg mb-1 z-10 -translate-x-1/2 transform left-1/2`}
    >
      <div id="common-popover-content" className="relative block px-2 text-darkBlue max-w-xs">
        {children}
        <div id="common-popover-arrow" className="absolute w-3 -bottom-3 overflow-hidden inline-block -translate-x-1/2 transform left-1/2">
          <div className="h-2 w-2 border border-specialGray-01 bg-white -rotate-45 transform origin-top-left" />
        </div>
      </div>
    </section>
  );
};

export default Popover;
