// packages
import { Dispatch, SetStateAction, Suspense, useCallback, useEffect, useState, MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSearchParams } from 'react-router-dom';
// utils
import { envVariableTransform } from 'utils';
// models
import { ProfileCommonListItemProps } from 'models/modelsOfComponents';
// components
import { Modal } from 'system/Modal';
import DoneIcon from 'icons/DoneIcon';
import InviteIcon from 'icons/InviteIcon';
import FolderPlus from 'icons/FolderPlus';
import SpinnerIcon from 'icons/SpinnerIcon';
import ProfileListItem from 'app/ProfileSearch/components/ProfileListItem/ProfileListItem';
import ListOfVacancies from 'app/ProfileSearch/components/ProfileListItem/components/ListOfVacancies/ListOfVacancies';
import AvailableForWorkIconWithPopover from 'app/ProfileSearch/components/ProfileListItem/components/AvailableForWorkIconWithPopover';
import ContactButton from 'app/ProfileCrew/ProfileCrewViews/ProfileCrewEmployerView/MainCrewInformationEmployerView/components/ButtonToContactWithCrew/ContactButton';
import EmployerAddUserToCrewPoolCommon from 'app/ProfileEmployer/components/EmployerCrewPools/components/EmployerAddUserToCrewPool/components/EmployerAddUserToCrewPoolCommon';
// recoil
import { contactInformationAtom } from 'recoil/ContactInformation/atoms/contactInformationAtom';
import { paymentSubscriptionModalAtom } from 'recoil/ContactInformation/atoms/paymentSubscriptionModalAtom';
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';
// hooks
import useGetIsProSubscription from 'hooks/subscription/useGetIsProSubscription';
import { useIsUserAppliedForAnyVacancies } from 'hooks/hireProfileHooks/useIsUserAppliedForAnyVacancies';

const ProfileSearchListItem = ({ profile, isInvitedProfileEvenForOneOfVacancies, ...props }: ProfileCommonListItemProps) => {
  const intl = useIntl();
  const [searchParams] = useSearchParams();

  const isProSubscription = useGetIsProSubscription();
  const isUserAppliedForAnyVacancies = useIsUserAppliedForAnyVacancies(profile.objectID);

  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);
  const setContactInfoButton = useSetRecoilState(contactInformationAtom);
  const setOpenPaymentSubscriptionModal = useSetRecoilState(paymentSubscriptionModalAtom);

  const [showListOfVacancies, setShowListOfVacancies] = useState<boolean>(false);
  const [showPoolsModal, setShowPoolsModal] = useState<boolean>(false);

  useEffect(() => {
    if (searchParams.get('defaultProfileIdStorybook')) {
      setShowListOfVacancies(searchParams.get('defaultProfileIdStorybook') === profile.objectID);
    }
  }, [searchParams, profile.objectID]);

  const handleOpenModal = useCallback(
    (setOpenModalState: Dispatch<SetStateAction<boolean>>) => (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setOpenModalState(true);
    },
    [],
  );

  const handleOpenContactModal = useCallback(() => {
    if (isProSubscription || isUserAppliedForAnyVacancies) {
      setContactInfoButton({
        profileId: profile.objectID,
        showContactInformation: true,
        isLoading: true,
      });
      return;
    }
    setOpenPaymentSubscriptionModal({ showPaymentSubscriptionModal: true });
  }, [isUserAppliedForAnyVacancies, isProSubscription, profile.objectID, setContactInfoButton, setOpenPaymentSubscriptionModal]);

  const renderInviteButton = useCallback(() => {
    return (
      getCurrentOrganisation.isSelected && (
        <>
          <AvailableForWorkIconWithPopover isAvailable={!!profile.availableForWork} />
          {(envVariableTransform(process.env.REACT_APP_FEATURE_HIRE_CREW_POOLS) || envVariableTransform(process.env.STORYBOOK_FEATURE_INVITE_BUTTON)) && (
            <button
              onClick={handleOpenModal(setShowPoolsModal)}
              className="flex bg-white px-3 items-center border h-10 border-specialGray-05 rounded-[10px] hover:bg-gray-50 text-specialGray-05 mx-4"
            >
              <FolderPlus />
            </button>
          )}
          {(envVariableTransform(process.env.REACT_APP_FEATURE_INVITE_BUTTON) || envVariableTransform(process.env.STORYBOOK_FEATURE_INVITE_BUTTON)) && (
            <button
              className={`flex justify-center items-center gap-x-2 border ${
                isInvitedProfileEvenForOneOfVacancies ? 'border-green-500 text-green-500 hover:bg-green-50' : 'hover:bg-gray-50 border-specialGray-05 text-specialGray-075'
              } border-solid bg-white shadow-sm h-10 rounded-[10px] px-[19.3px] w-fit sm:w-auto mr-4`}
              onClick={handleOpenModal(setShowListOfVacancies)}
            >
              {isInvitedProfileEvenForOneOfVacancies ? <DoneIcon /> : <InviteIcon />}

              {intl.formatMessage({
                id: isInvitedProfileEvenForOneOfVacancies ? 'hire_job_invited_label' : 'search_list_item_invite_button_label',
              })}
            </button>
          )}
          <ContactButton profileId={profile.objectID} onClick={handleOpenContactModal} />
        </>
      )
    );
  }, [getCurrentOrganisation.isSelected, handleOpenContactModal, handleOpenModal, intl, isInvitedProfileEvenForOneOfVacancies, profile.availableForWork, profile.objectID]);

  return (
    <>
      {getCurrentOrganisation.isSelected && (
        <>
          {(envVariableTransform(process.env.REACT_APP_FEATURE_INVITE_BUTTON) || envVariableTransform(process.env.STORYBOOK_FEATURE_INVITE_BUTTON)) && (
            <Modal
              description={{ id: 'search_modal_description' }}
              additionalClasses="md:w-8/12 lg:max-w-[720px] h-[500px]"
              title={{ id: 'search_modal_title' }}
              onClose={() => setShowListOfVacancies(false)}
              show={showListOfVacancies}
            >
              <Suspense fallback={<SpinnerIcon />}>
                <ListOfVacancies crewProfileId={profile.objectID} />
              </Suspense>
            </Modal>
          )}
          {(envVariableTransform(process.env.REACT_APP_FEATURE_HIRE_CREW_POOLS) || envVariableTransform(process.env.STORYBOOK_FEATURE_INVITE_BUTTON)) && (
            <Modal
              childrenClass="flex w-full text-darkBlue"
              description={{ id: 'hire_crew_pool_invite_user_description' }}
              title={{ id: 'hire_crew_pool_invite_user_title' }}
              onClose={() => setShowPoolsModal(false)}
              show={showPoolsModal}
            >
              <EmployerAddUserToCrewPoolCommon onHandleClose={() => setShowPoolsModal(false)} userId={profile.objectID} />
            </Modal>
          )}
        </>
      )}

      <ProfileListItem profile={profile} inviteButton={renderInviteButton} {...props} />
    </>
  );
};

export default ProfileSearchListItem;
