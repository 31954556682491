//packages
//helpers
import {
  getConnectionHandler,
  handleUpdateOrganizationStatsWhenSwitchStatus,
  removeVacancyFromConnectionHandlers,
  switchStatusInConnectionHandlers,
} from 'formHelpers/updaters/updatersHelpers';
// models
import { ORDER_DIRECTION, VACANCY_ORDER_FIELD, VACANCY_STATUS } from 'models/enums';
import { ConnectionHandler, RecordSourceSelectorProxy } from 'relay-runtime';
// generated
import { InviteMutationsRemoveInviteMutation$data } from 'schemas/Invite/__generated__/InviteMutationsRemoveInviteMutation.graphql';
import { VacancyMutationsDeleteVacancyMutation$data } from 'schemas/vacancy/__generated__/VacancyMutationsDeleteVacancyMutation.graphql';
import { VacancyMutationsSwitchVacancyStatusMutation$data } from 'schemas/vacancy/__generated__/VacancyMutationsSwitchVacancyStatusMutation.graphql';
import { VacancyMutationsUpdateVacancyMutation$data } from 'schemas/vacancy/__generated__/VacancyMutationsUpdateVacancyMutation.graphql';
import { VacancyMutationsCreateVacancyMutation$data } from 'schemas/vacancy/__generated__/VacancyMutationsCreateVacancyMutation.graphql';
import { VacancyMutationsUpdateVacancyManagerMutation$data } from 'schemas/vacancy/__generated__/VacancyMutationsUpdateVacancyManagerMutation.graphql';
// types
import { vacancyFiltersTypes } from 'recoil/Organisation/Dashboard/types';

export const deleteJobOpening =
  (openedVacancyFilters: vacancyFiltersTypes, allVacancyFilters: vacancyFiltersTypes) => (store: RecordSourceSelectorProxy<VacancyMutationsDeleteVacancyMutation$data>) => {
    const payload = store.getRootField('deleteVacancy');
    const storyRecord = store.getRoot();
    const vacancyStatus = payload.getValue('status');
    const organizationRecord = payload.getLinkedRecord('organization');
    const organizationVacancyStatsRecord = storyRecord.getLinkedRecord(`organizationVacancyStats(orgId:"${organizationRecord?.getDataID()}")`)?.getLinkedRecords('stats');

    const teamMembersVacanciesConnection = ConnectionHandler.getConnection(storyRecord, 'VacancyListForOrganizationMembership_vacancies', {
      orgId: organizationRecord?.getDataID(),
      orderBy: { field: VACANCY_ORDER_FIELD.STATUS, direction: ORDER_DIRECTION.ASC },
    });

    if (teamMembersVacanciesConnection && payload) {
      ConnectionHandler.deleteNode(teamMembersVacanciesConnection, `${payload?.getDataID()}`);
    }

    if (organizationVacancyStatsRecord) {
      organizationVacancyStatsRecord.forEach(statRecord => {
        const statusValue = statRecord.getValue('status');
        const totalCount = statRecord.getValue('totalCount');
        if (statusValue === vacancyStatus) {
          statRecord.setValue((totalCount as number) - 1, 'totalCount');
        }
      });
    }
    const isClosedVacancyStatus = payload.getValue('status') === VACANCY_STATUS.CLOSED;
    const arrayOfFiltersForConnectionHandler = isClosedVacancyStatus ? allVacancyFilters : [...openedVacancyFilters, ...allVacancyFilters];

    removeVacancyFromConnectionHandlers(storyRecord, payload, arrayOfFiltersForConnectionHandler);
  };

export const switchStatusWithNode =
  (openedVacancyFilters: vacancyFiltersTypes, showAllVacancyFilters: vacancyFiltersTypes) =>
  (store: RecordSourceSelectorProxy<VacancyMutationsSwitchVacancyStatusMutation$data>) => {
    const payload = store.getRootField('switchVacancyStatus');
    const storyRecord = store.getRoot();

    const organizationRecord = payload.getLinkedRecord('organization');
    const organizationVacancyStatsRecord = storyRecord.getLinkedRecord(`organizationVacancyStats(orgId:"${organizationRecord?.getDataID()}")`)?.getLinkedRecords('stats');

    const nodeRecord = payload?.setLinkedRecord(payload, 'node');

    const isOpenVacancyStatus = payload.getValue('status') === VACANCY_STATUS.OPEN;

    switchStatusInConnectionHandlers(storyRecord, payload, openedVacancyFilters, showAllVacancyFilters);

    // remove or add OPENED vacancy to handler for an invite list
    const openedVacanciesListConnectionHandler = ConnectionHandler.getConnection(storyRecord, 'VacancyList_opened_vacancies', {
      orgId: payload?.getLinkedRecord('organization')?.getValue('id'),
      filters: [{ field: VACANCY_ORDER_FIELD.STATUS, values: [VACANCY_STATUS.OPEN] }],
    });

    if (isOpenVacancyStatus && openedVacanciesListConnectionHandler) {
      ConnectionHandler.insertEdgeBefore(openedVacanciesListConnectionHandler, nodeRecord);
    } else if (openedVacanciesListConnectionHandler) {
      ConnectionHandler.deleteNode(openedVacanciesListConnectionHandler, `${nodeRecord?.getDataID()}`);
    }

    handleUpdateOrganizationStatsWhenSwitchStatus(payload.getValue('status') as VACANCY_STATUS, organizationVacancyStatsRecord);
  };

export const JobOpeningCommitUpdateVacancy = (store: RecordSourceSelectorProxy<VacancyMutationsUpdateVacancyMutation$data>) => {
  const payload = store.getRootField('updateVacancy');
  const storyRecord = store.getRoot();

  const { connectionRecordWithOpenedJobs, connectionRecordWithClosedJobs } = getConnectionHandler(storyRecord, payload);

  //from create or edit vacancy we have just two states OPEN/CLOSED event if we opened DRAFT/ARCHIVED it will be changed on CLOSED
  if (connectionRecordWithClosedJobs && payload && payload.getValue('status') === VACANCY_STATUS.CLOSED) {
    ConnectionHandler.deleteNode(connectionRecordWithClosedJobs, `${payload?.getDataID()}`);
    ConnectionHandler.insertEdgeBefore(connectionRecordWithClosedJobs, payload);
  }

  if (connectionRecordWithOpenedJobs && payload && payload.getValue('status') === VACANCY_STATUS.OPEN) {
    ConnectionHandler.deleteNode(connectionRecordWithOpenedJobs, `${payload?.getDataID()}`);
    ConnectionHandler.insertEdgeAfter(connectionRecordWithOpenedJobs, payload);
  }
};
export const JobOpeningCommitCreateVacancy = (store: RecordSourceSelectorProxy<VacancyMutationsCreateVacancyMutation$data>) => {
  const vacancyPayload = store.getRootField('createVacancy');
  const vacancyNodePayload = vacancyPayload.getLinkedRecord('node');
  const orgId = vacancyNodePayload.getLinkedRecord('organization')?.getDataID();
  const storyRecord = store.getRoot();

  const organizationVacancyStatsRecord = storyRecord.getLinkedRecord(`organizationVacancyStats(orgId:"${orgId}")`)?.getLinkedRecords('stats');
  if (organizationVacancyStatsRecord) {
    organizationVacancyStatsRecord.forEach(statRecord => {
      const statusValue = statRecord.getValue('status');
      const totalCount = statRecord.getValue('totalCount');
      if (statusValue === VACANCY_STATUS.CLOSED) {
        statRecord.setValue((totalCount as number) + 1, 'totalCount');
      }
    });
  }
};

export const JobOpeningUpdateVacancyManager = (
  store: RecordSourceSelectorProxy<VacancyMutationsUpdateVacancyManagerMutation$data>,
  data: VacancyMutationsUpdateVacancyManagerMutation$data,
) => {
  const storyRecord = store.getRoot();
  const vacancyWithUpdatedManager = store.get(data.updateVacancyManager.id);

  const teamMembersVacanciesConnection = ConnectionHandler.getConnection(storyRecord, 'VacancyListForOrganizationMembership_vacancies', {
    orgId: data.updateVacancyManager.organization.id,
    orderBy: { field: VACANCY_ORDER_FIELD.STATUS, direction: ORDER_DIRECTION.ASC },
  });

  if (!teamMembersVacanciesConnection || !vacancyWithUpdatedManager) {
    return;
  }

  const edge = ConnectionHandler.createEdge(store, teamMembersVacanciesConnection, vacancyWithUpdatedManager, 'Vacancy');
  ConnectionHandler.deleteNode(teamMembersVacanciesConnection, `${vacancyWithUpdatedManager?.getDataID()}`);
  if (data.updateVacancyManager.status === VACANCY_STATUS.OPEN) {
    ConnectionHandler.insertEdgeBefore(teamMembersVacanciesConnection, edge);
  } else {
    ConnectionHandler.insertEdgeAfter(teamMembersVacanciesConnection, edge);
  }
};

export const RemoveInviteCommit =
  (vacancyId: string) => (store: RecordSourceSelectorProxy<InviteMutationsRemoveInviteMutation$data>, data: InviteMutationsRemoveInviteMutation$data) => {
    const storyRecord = store.getRoot();
    const connectionRecord = ConnectionHandler.getConnection(storyRecord, 'ListWithInvites_invites', { vacancyId });
    if (connectionRecord) {
      ConnectionHandler.deleteNode(connectionRecord, data.deleteInvite.id);
    }
  };
