import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSkeletonContext } from 'Context/StorybookContext';
import { useBreakpoints } from 'hooks/useBreakpoints';

const ServiceListItemSkeleton: React.FC = () => {
  const { sm } = useBreakpoints();
  const { isSelfProfile } = useSkeletonContext();

  return (
    <div className="mt-5 rounded-2xl border bg-white md:flex block">
      <section className="w-auto md:w-40 text-sm md:border-r text-center px-5 flex flex-col justify-center align-middle text-specialGray-1">
        <Skeleton width={70} />
        <Skeleton width={70} />
        <Skeleton width={60} />
      </section>
      <div className="w-full">
        <div className="relative block md:flex flex-row px-3 py-3">
          <Skeleton width={sm ? '100%' : 112} height={sm ? 160 : 64} borderRadius="0.5rem" />
          <div className="flex flex-col md:ml-5 justify-center">
            <Skeleton width={150} />
            <div className="flex">
              <Skeleton width={30} height={20} className="mr-3" />
              <Skeleton width={150} height={20} />
            </div>
          </div>
          {isSelfProfile && (
            <div className="flex items-center ml-auto">
              <Skeleton width={sm ? 102 : 130} height={sm ? 48 : 34} borderRadius="0.75rem" className="mr-5" />
              <Skeleton width={50} height={34} borderRadius="0.75rem" className="mr-5" />
              <Skeleton width={42} height={34} borderRadius="0.75rem" />
            </div>
          )}
        </div>
        <div className="px-3 pb-3">
          <div className="flex flex-wrap flex-col sm:flex-row">
            <Skeleton width={90} className="mr-5" />
            <Skeleton width={150} className="mr-5" />
            <Skeleton width={80} className="mr-5" />
            <Skeleton width={50} className="mr-5" />
            <Skeleton width={90} />
          </div>
          <div className="flex flex-wrap flex-col sm:flex-row mt-3 items-center">
            <Skeleton width={80} height={28} borderRadius="0.5rem" className="mr-1" />
            <Skeleton width={80} height={28} borderRadius="0.5rem" className="mr-1" />
            <Skeleton width={80} height={28} borderRadius="0.5rem" className="mr-1" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceListItemSkeleton;
