import { PropsWithChildren } from 'react';
import { RoutePaths } from 'app/routing';
import { useAuth } from 'authentication';
import { Navigate, useLocation } from 'react-router-dom';
// models
import { PLATFORM_GROUP } from 'models/enums';

const PrivateRoutes = ({ redirectPath = RoutePaths.AuthLogin, children }: { redirectPath?: string } & PropsWithChildren) => {
  const { identity, loggedIn } = useAuth();
  const { pathname, search } = useLocation();

  if (typeof loggedIn === 'undefined') {
    return null;
  }

  if (identity?.platformGroup === PLATFORM_GROUP.GUEST) {
    return <Navigate to={{ pathname: redirectPath }} state={{ previousPathname: pathname, search }} replace />;
  }

  return <>{children}</>;
};

export default PrivateRoutes;
