import React from 'react';
import { PeriodDateProps } from 'models/modelsOfComponents';
import { format, formatDistanceStrict } from 'date-fns';
import ItemField from '../ListComponents/ItemField';
import { useIntl } from 'react-intl';

const PeriodDate: React.FC<PeriodDateProps> = ({ start, end, withDuration }) => {
  const intl = useIntl();

  return (
    <div className="flex sm:flex-col gap-y-2 w-full justify-between sm:justify-center">
      <div className="flex sm:flex-col gap-y-2 gap-x-1">
        <span className="font-medium whitespace-nowrap">{format(new Date(`${start}`), 'dd LLL yyyy')} </span>
        <span className="sm:hidden">&#8208;</span>
        {end && <span className="font-medium whitespace-nowrap">{format(new Date(`${end}`), 'dd LLL yyyy')} </span>}

        {/* to present */}
        {!end && <p className="font-medium">{intl.formatMessage({ id: 'service_present' })}</p>}
      </div>

      {/* duration */}
      {withDuration && end && (
        <ItemField
          contentField={end && `(${formatDistanceStrict(new Date(`${end}`), new Date(`${start}`))})`}
          contentFieldClasses="relative sm:left-2 mb-3 ml-full sm:text-start"
        />
      )}
    </div>
  );
};

export default PeriodDate;
