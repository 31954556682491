// packages
import useTextOverflow from 'hooks/useTextOverflow';
import { VACANCY_DESCRIPTION_TYPE_OF_LIST, VACANCY_STATUS } from 'models/enums';
import React, { PropsWithChildren, useMemo } from 'react';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// components
import LineSvg from 'icons/LineSvg';
import LocationIcon from 'icons/LocationIcon';
import CalendarMonth from 'icons/CalendarMonth';
import JobAvatar from 'app/Avatars/JobAvatar/JobAvatar';
import ComponentWithPopover from 'system/ComponentWithPopover';
import JobOpeningStatus from 'system/JobOpeningStatus/JobOpeningStatus';
// routing
import { RoutePaths } from 'app/routing';
// models
import { VacancyFragments$data } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';
// utils
import { getVacancyDescription } from 'utils';

const ResponseCommonItem = ({
  vacancyData,
  avatarSize,
  typeOfList,
  renderButton,
  children,
}: { vacancyData: VacancyFragments$data; avatarSize: string; typeOfList: VACANCY_DESCRIPTION_TYPE_OF_LIST; renderButton?: React.ReactElement } & PropsWithChildren) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { isOverflowActive, overflowingTextRef } = useTextOverflow();

  const isListOfInvitations = useMemo(() => typeOfList === VACANCY_DESCRIPTION_TYPE_OF_LIST.INVITED, [typeOfList]);

  const handleClickByVacancy = () => {
    if (vacancyData.status === 'OPEN') {
      window.analytics?.track('wui-opened-single-job-opening', {
        vacancyId: vacancyData.id,
      });
    }
    navigate(`${RoutePaths.ProfileBase}/job/${vacancyData.id}`);
  };

  return (
    <div className="flex flex-col sm:flex-row p-4 sm:px-6 sm:py-8 border border-specialGray-012 rounded-2xl gap-x-5 cursor-pointer w-full" onClick={handleClickByVacancy}>
      <div ref={overflowingTextRef} className={`flex w-full gap-x-2 sm:gap-x-4 ${isOverflowActive ? '' : 'overflow-hidden'}`}>
        <JobAvatar size={avatarSize} avatarUrl={vacancyData?.organization?.avatarUrl} alt={vacancyData?.organization?.id} />
        <div className={`flex flex-col w-full gap-y-1 lg:gap-y-4 ${isListOfInvitations ? '' : 'sm:justify-between'}`}>
          <div className="flex justify-between gap-x-2">
            <div className={`flex gap-3.5 lg:gap-x-4 sm:items-center ${isOverflowActive ? '' : 'overflow-hidden'}`}>
              {vacancyData?.status && isListOfInvitations ? <JobOpeningStatus status={vacancyData?.status as VACANCY_STATUS} /> : null}
              {isOverflowActive ? (
                <PopoverHelper
                  classes="inline-grid"
                  element={<div className="text-specialGray-1 text-lg font-semibold truncate">{vacancyData?.title}</div>}
                  position="bottom-8 left-1/2 -translate-x-1/2"
                  positionArrow="left-1/2 sm:-translate-x-1/2 rotate-45 -bottom-0.5"
                  widthContainer="w-64 xs:w-80 sm:w-96"
                  darkTheme
                >
                  {vacancyData?.title}
                </PopoverHelper>
              ) : (
                <div className="text-specialGray-1 text-lg font-semibold truncate">{vacancyData?.title}</div>
              )}
              <span className={`hidden sm:inline text-specialGray-075 text-sm whitespace-nowrap ${isListOfInvitations ? '' : 'sm:hidden'}`}>
                {format(new Date(`${vacancyData?.createdAt}`), 'dd MMM yyyy')}
              </span>
            </div>
            {children}
          </div>
          <div className="flex w-full">
            <div className={`flex flex-col ${isListOfInvitations ? 'sm:flex-row lg:flex-col lg:w-1/2 gap-x-3 lg:gap-y-3' : 'sm:flex-row md:w-2/3 gap-x-3 md:gap-x-8'}`}>
              {(vacancyData?.salaryFrom || vacancyData?.salaryTo) && (
                <div className="hidden sm:flex items-center text-sm gap-x-2">
                  <p className={`lg:uppercase text-specialGray-075 lg:font-semibold whitespace-nowrap ${isListOfInvitations ? 'w-1/3' : ''}`}>
                    <span className="hidden md:inline">{intl.formatMessage({ id: 'hire_job_description_salary_range' })}</span>
                    <span className="inline md:hidden">{intl.formatMessage({ id: 'hire_information_salary' })}</span>
                  </p>
                  <div className="flex text-darkBlue gap-x-3 md:gap-x-3.5">
                    <span>
                      {vacancyData.salaryFrom || ''}
                      {vacancyData.salaryTo ? `\u00A0\u2212\u00A0${vacancyData.salaryTo}` : ''}
                    </span>
                    <span className="text-specialGray-075">
                      {vacancyData.currency ? vacancyData.currency.name : intl.formatMessage({ id: 'usd' })}&nbsp;&#8725;&nbsp;{vacancyData?.salaryPeriod?.toLocaleLowerCase()}
                    </span>
                  </div>
                </div>
              )}
              <div className="flex sm:hidden text-specialGray-075 text-sm items-center gap-x-1">
                <CalendarMonth />
                <span>{format(new Date(`${vacancyData?.createdAt}`), 'dd MMM yyyy')}</span>
              </div>
              {vacancyData?.hiringArea && (
                <div className="flex items-center gap-x-1 sm:gap-x-2">
                  <p className={`hidden sm:inline text-sm text-specialGray-075 lg:uppercase lg:font-semibold ${isListOfInvitations ? 'lg:w-1/3' : ''}`}>
                    {intl.formatMessage({ id: 'hire_location_label' })}
                  </p>
                  <LocationIcon size="w-4 h-4 sm:hidden" />
                  <ComponentWithPopover
                    popoverPosition="bottom-[calc(100%_+_5px)]"
                    id={vacancyData.hiringArea.name}
                    name={vacancyData.hiringArea.name}
                    customStyle="text-sm text-specialGray-075 sm:text-darkBlue max-w-[150px] truncate"
                  />
                </div>
              )}
            </div>
            {vacancyData?.description && isListOfInvitations ? (
              <div className="hidden lg:flex w-1/2 mb-3 text-sm">
                <p className="uppercase text-specialGray-075 font-semibold w-1/3">{intl.formatMessage({ id: 'hire_description_job_description_section' })}</p>
                <span className="flex text-darkBlue w-2/3 whitespace-pre-wrap">{getVacancyDescription(vacancyData.description)}</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {!!(vacancyData?.salaryFrom || vacancyData?.salaryTo) ? (
        <>
          <LineSvg classes="sm:hidden mt-3 mb-2 w-[calc(100%_+_32px)] relative -left-4" />
          <div className="flex sm:hidden w-full text-sm text-darkBlue justify-between">
            <span className="text-specialGray-075">
              {vacancyData?.currency ? vacancyData?.currency.name : intl.formatMessage({ id: 'usd' })}&nbsp;&#8725;&nbsp;{vacancyData?.salaryPeriod?.toLocaleLowerCase()}
            </span>
            <span>
              {vacancyData?.salaryFrom || ''}
              {vacancyData?.salaryTo ? `\u00A0\u2212\u00A0${vacancyData?.salaryTo}` : null}
            </span>
          </div>
          {renderButton ? <div className="flex w-full mt-4 sm:hidden">{renderButton}</div> : null}
        </>
      ) : null}
    </div>
  );
};

export default ResponseCommonItem;
