// packages
import { ICountry } from 'models/ICountry';
import { useCallback, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useLazyLoadQuery, useMutation } from 'react-relay';
import { Form, FormikProvider, useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
// routes
import { RoutePaths } from 'app/routing';
// components
import { InputGroup } from 'system/InputGroups/InputGroup';
import ProfileGreenIcon from 'icons/ProfileGreenIcon';
import AlertForError from 'system/Alert/AlertForError';
import Footer from 'auth/SignUpSteps/components/Footer';
import Header from 'auth/SignUpSteps/components/Header';
// types
import { SignUpWithNameData } from '../types';
// formHelpers
import { SignUpWithNameInitialValues } from 'formHelpers/initialValuesOfForms';
import { SignUpWithNameValidateSchema } from 'formHelpers/validationsOfForms';
// models
import { PLATFORM_GROUP } from 'models/enums';
import { SignUpStepsState } from 'models/routeLocationState';
// generated
import { BasicInfoFormMutationsCreateMutation } from 'schemas/forms/__generated__/BasicInfoFormMutationsCreateMutation.graphql';
import { BasicInfoFormMutationsUpdateMutation } from 'schemas/forms/__generated__/BasicInfoFormMutationsUpdateMutation.graphql';
import { CountryQueriesCountriesOfResidenceQuery } from 'schemas/country/__generated__/CountryQueriesCountriesOfResidenceQuery.graphql';
// schemas
import { GET_PROFILE_COUNTRIES_OF_RESIDENCE } from 'schemas/country/CountryQueries';
import { CREATE_PROFILE_INFO_MUTATION, UPDATE_PROFILE_INFO_MUTATION } from 'schemas/forms/BasicInfoFormMutations';

const StepWithEnterName = ({ registerType }: { registerType: PLATFORM_GROUP }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [error, setError] = useState<Error | undefined>();

  const dataOfCountries = useLazyLoadQuery<CountryQueriesCountriesOfResidenceQuery>(GET_PROFILE_COUNTRIES_OF_RESIDENCE, { skip: false }).countries?.edges;

  const [getCountryByDefault, setCountryByDefault] = useState<ICountry | undefined>(undefined);

  useEffect(() => {
    setCountryByDefault(dataOfCountries?.find(country => country.isoCode === 'NL'));
  }, [dataOfCountries]);

  const [commitCreate, createLoading] = useMutation<BasicInfoFormMutationsCreateMutation>(CREATE_PROFILE_INFO_MUTATION);
  const [commitUpdate, updateLoading] = useMutation<BasicInfoFormMutationsUpdateMutation>(UPDATE_PROFILE_INFO_MUTATION);

  const formik = useFormik<SignUpWithNameData>({
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: SignUpWithNameInitialValues((state as SignUpStepsState)?.profileInfo?.firstName, (state as SignUpStepsState)?.profileInfo?.lastName),
    validationSchema: SignUpWithNameValidateSchema,
  });

  const handleCompleted = useCallback(
    <
      T extends {
        readonly firstName: string;
        readonly lastName: string;
      },
    >(
      profileInfo: T,
    ) => {
      window.analytics?.track('wui-signup-step-with-enter-names', {
        firstName: profileInfo.firstName,
        lastName: profileInfo.lastName,
        type_of_account: registerType,
      });

      if (registerType === 'hire') {
        navigate({ pathname: RoutePaths.HireAuthRegisterStepFive }, { state: { ...state, profileInfo } });
      } else {
        navigate({ pathname: RoutePaths.AuthRegisterStepFive }, { state: { ...state, profileInfo } });
      }
    },
    [navigate, registerType, state],
  );

  async function onSubmit(data: SignUpWithNameData) {
    const currentUser = await Auth.currentSession();

    if ((state as SignUpStepsState).profileInfo) {
      commitUpdate({
        variables: {
          id: (state as SignUpStepsState).profileInfo.id,
          data: {
            ownerId: currentUser.getIdToken().payload['custom:profile_id'],
            firstName: data.firstName,
            lastName: data.lastName,
            birthDate: new Date().toISOString(),
            countryId: getCountryByDefault?.id || '',
          },
        },
        onCompleted: response => handleCompleted(response.updateProfileInfo),
        onError: (error: Error) => {
          setError(error);
        },
      });
    } else {
      commitCreate({
        variables: {
          data: {
            ownerId: currentUser.getIdToken().payload['custom:profile_id'],
            firstName: data.firstName,
            lastName: data.lastName,
            birthDate: new Date('2000-01-01').toISOString(),
            countryId: getCountryByDefault?.id || '',
          },
        },
        onCompleted: response => handleCompleted(response.createProfileInfo),
        onError: (error: Error) => {
          setError(error);
        },
      });
    }
  }

  return (
    <section className="flex w-full flex-col h-full sm:h-auto pt-5 pb-7">
      <Header title={{ id: 'enter_name_title' }} description={{ id: 'enter_name_description' }} icon={<ProfileGreenIcon />} />
      <FormikProvider value={formik}>
        <Form className="flex flex-col mt-7 sm:mt-8 w-full sm:py-8 px-4 sm:border sm:border-specialGray-012 sm:px-10 rounded-[20px] space-y-[18px] h-full justify-between sm:justify-start">
          <fieldset className="space-y-[18px]">
            {error && <AlertForError heading={{ id: 'register_failedAlert' }} error={error} />}
            <InputGroup name="firstName" label={{ id: 'profile_first_name' }} placeholderText={{ id: 'field_is_name' }} inputCustomStyle="h-12 sm:h-[34px]" autoFocus />
            <InputGroup name="lastName" label={{ id: 'profile_last_name' }} placeholderText={{ id: 'profile_last_name' }} inputCustomStyle="h-12 sm:h-[34px]" />
          </fieldset>
          <Footer
            error={error}
            pathnameToPrevStep={RoutePaths.AuthRegisterStepTree}
            isDisabled={!(formik.values.firstName && formik.values.lastName) || createLoading || updateLoading}
          />
        </Form>
      </FormikProvider>
    </section>
  );
};

export default StepWithEnterName;
