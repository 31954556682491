import { PropsWithChildren } from 'react';

const ProfileBasicAdaptivePopover = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex absolute sm:w-max xs:w-80 w-64 px-2 py-2 border bg-white z-20 rounded-xl top-[25px] sm:-translate-x-1/2 transform sm:left-1/2 xs:-left-20 -left-8">
      <div className="w-2 h-2 bg-white border border-b-0 border-r-0 absolute -translate-x-1/2 transform sm:left-1/2 xs:left-[35%] left-[20%] rotate-45 top-[-5px]" />
      <div className="flex w-fit max-w-[400px] max-h-[400px] overflow-auto">{children}</div>
    </div>
  );
};

export default ProfileBasicAdaptivePopover;
