import { FC } from 'react';
//hooks
import { useBreakpoints } from 'hooks/useBreakpoints';
//components
import TextInputSkeleton from 'system/skeletons/components/TextInputSkeleton';
import RangeNumbersSkeleton from 'system/skeletons/components/RangeNumbersSkeleton';

const FilterComponentSkeleton: FC = () => {
  const { sm } = useBreakpoints();
  return (
    <div className="flex-col w-full">
      <TextInputSkeleton additionalStyle="flex flex-col space-y-4 py-8 border-b border-b-specialGray-01 px-8" widthTitle={40} borderRadius="0.375rem" heightInput={sm ? 34 : 38} />
      <TextInputSkeleton additionalStyle="flex flex-col space-y-4 py-8 border-b border-b-specialGray-01 px-8" widthTitle={85} borderRadius="0.375rem" heightInput={sm ? 34 : 38} />

      <RangeNumbersSkeleton additionalStyle="py-8 border-b border-b-specialGray-01 px-8" widthTitle={180} borderRadius="0.375rem" heightInput={sm ? 34 : 38} />
      <RangeNumbersSkeleton additionalStyle="py-8 border-b border-b-specialGray-01 px-8" widthTitle={160} borderRadius="0.375rem" heightInput={sm ? 34 : 38} />
      <RangeNumbersSkeleton additionalStyle="py-8 border-b border-b-specialGray-01 px-8" widthTitle={180} borderRadius="0.375rem" heightInput={sm ? 34 : 38} />

      <TextInputSkeleton additionalStyle="flex flex-col space-y-4 py-8 border-b border-b-specialGray-01 px-8" widthTitle={90} borderRadius="0.375rem" heightInput={sm ? 34 : 38} />
      <TextInputSkeleton additionalStyle="flex flex-col space-y-4 py-8 border-b border-b-specialGray-01 px-8" widthTitle={115} borderRadius="0.375rem" heightInput={sm ? 34 : 38} />
      <TextInputSkeleton additionalStyle="flex flex-col space-y-4 py-8 border-b border-b-specialGray-01 px-8" widthTitle={130} borderRadius="0.375rem" heightInput={sm ? 34 : 38} />
      <TextInputSkeleton additionalStyle="flex flex-col space-y-4 py-8 border-b border-b-specialGray-01 px-8" widthTitle={120} borderRadius="0.375rem" heightInput={sm ? 34 : 38} />
      <TextInputSkeleton additionalStyle="flex flex-col space-y-4 py-8 border-b border-b-specialGray-01 px-8" widthTitle={200} borderRadius="0.375rem" heightInput={sm ? 34 : 38} />
    </div>
  );
};

export default FilterComponentSkeleton;
