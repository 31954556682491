//packages
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useField } from 'formik';
// helpers
import { debounce, differenceBy, isEmpty } from 'lodash';
// components
import DropDownList from 'system/DropDownList';
import ChoseElement from 'system/ChoseElement';
import SelectInputCommon from 'system/SelectInputCommon';
import { FieldErrorMessage } from 'system/FieldErrorMessage';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
// types
import { chooseInputGroupItemProps, ChooseInputGroupProps } from 'models/modelsOfComponents';

const ChooseInputGroup = ({
  name,
  openByDefault,
  defaultSelectedElement,
  defaultFocusedElement,
  label,
  defaultArrayOfValues,
  autoComplete,
  maxCharCount,
  groupData,
  customFieldAdding,
  ...props
}: ChooseInputGroupProps) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isOpenList, setIsOpenList] = useState<boolean>(openByDefault || false);
  const containerRef = useRef<HTMLDivElement>(null);

  const [elementInFocus, setElementInFocus] = useState<string | undefined>('');
  const [indexChoseElement, setIndexChoseElement] = useState<number>(0);
  const [arrayOfValues, setArrayOfValues] = useState<chooseInputGroupItemProps[]>(defaultArrayOfValues || []);
  const [substr, setSubstr] = useState<string>('');
  const [arrayOfOptionsList, setArrayOfOptionsList] = useState<chooseInputGroupItemProps[] | null>([]);

  const [options, setOptions] = useState<chooseInputGroupItemProps[]>([]);

  const [{ onBlur, ...field }, { error, touched, initialError, initialTouched }, { setValue, setTouched }] = useField<readonly chooseInputGroupItemProps[]>({
    name,
  });
  const [count, setCount] = useState(0);
  const [valueForInput, setValueForInput] = useState<string>('');
  const [showError, setShowError] = useState<string | false | undefined>(touched && !!valueForInput && error);
  const [customError, setCustomError] = useState<boolean>(false);

  const isShowWarning = useMemo<boolean>(() => arrayOfValues.length > 2 && !(showError || customError), [arrayOfValues.length, customError, showError]);

  const classesDefault = useMemo<string>(
    () => 'border border-solid shadow-sm flex-col-reverse sm:flex-row sm:items-center w-full border-specialGray-012 rounded-md flex flex-wrap',
    [],
  );

  const classesDisabled = useMemo<string>(() => (props.disabled ? 'cursor-not-allowed' : ''), [props.disabled]);

  const classesWithError = useMemo<string>(
    () => (showError || customError ? 'block w-full pr-10 border-red-300 text-red-900 sm:text-sm rounded-md ' : ''),
    [customError, showError],
  );

  const classesWithWarning = useMemo<string>(() => (isShowWarning ? 'border-yellow-300' : ''), [isShowWarning]);

  const placeholderWithError = useMemo<string>(
    () =>
      showError || customError
        ? 'placeholder:text-red-300 focus:outline-none focus:border-red-500 focus:ring-red-500'
        : 'placeholder:text-specialGray-05 focus:border-blue-500 focus:ring-blue-500',
    [customError, showError],
  );

  const [allIncomingData, setAllIncomingData] = useState(groupData || []);
  const preventAddingId = 'no-value';

  useEffect(() => {
    if (error && arrayOfValues && arrayOfValues.length > 5) {
      setCustomError(true);
      return;
    }
    setCustomError(false);
    return;
  }, [error, arrayOfValues]);

  useEffect(() => {
    if (defaultArrayOfValues) {
      setArrayOfValues(defaultArrayOfValues);
    }
  }, [defaultArrayOfValues]);

  useEffect(() => {
    //filter input by included chars
    if (substr) {
      const filteredByChars = groupData.filter((field: any) => {
        const alternativeSearchWord = field.alts.find((altWord: string) => {
          return altWord.toLowerCase().includes(substr.toLowerCase().trim());
        });
        return field?.label.toLowerCase().includes(substr.toLowerCase().trim()) || alternativeSearchWord;
      });
      setAllIncomingData(filteredByChars);
      return;
    }
    setAllIncomingData(groupData);
  }, [substr, groupData]);

  useEffect(() => {
    if (initialTouched && initialError) {
      setShowError(initialTouched && initialError);
    } else if (!elementInFocus) {
      setShowError(touched && !!valueForInput && error);
    }
  }, [touched, error, valueForInput, initialTouched, initialError, elementInFocus]);

  useEffect(() => {
    return () => {
      setTouched(false);
    };
    // eslint-disable-next-line
  }, []);

  const debouncedSetSubtitle = useRef(
    debounce((newValue: string | undefined, isOpenList: boolean) => {
      if (isOpenList) {
        setSubstr(newValue as string);
      }
    }, 500),
  );

  useEffect(() => {
    debouncedSetSubtitle.current(valueForInput, isOpenList);
  }, [valueForInput, isOpenList]);

  useEffect(() => {
    setCount(valueForInput.length);
  }, [valueForInput, maxCharCount]);

  useEffect(() => {
    const differenceArray = differenceBy(options, Array.prototype.concat(arrayOfValues), 'id');
    if (!isEmpty(options) && !isEmpty(differenceArray)) {
      setArrayOfOptionsList(() => differenceArray);
    } else if (valueForInput) {
      if (customFieldAdding) {
        setArrayOfOptionsList(() => [{ id: valueForInput, label: valueForInput }]);
        return;
      }
      //in this case we want to prevent adding this field to data array and show "NO VALUE" info
      setArrayOfOptionsList(() => [{ id: preventAddingId, label: intl.formatMessage({ id: 'hire_information_position_no_value' }) }]);
    } else {
      setArrayOfOptionsList([]);
    }
  }, [options, arrayOfValues, valueForInput, customFieldAdding, intl]);

  useEffect(() => {
    if (defaultSelectedElement && defaultFocusedElement) {
      setElementInFocus(defaultFocusedElement?.id);
    } else if (defaultSelectedElement) {
      setElementInFocus(defaultSelectedElement?.id);
    }
  }, [defaultSelectedElement, defaultFocusedElement]);

  const handleBlur = useCallback(
    (event?: React.ChangeEvent) => {
      if (event) {
        onBlur(event);
      }
      setValue(Array.prototype.concat(arrayOfValues));
      setValueForInput('');
      setElementInFocus('');
    },
    [arrayOfValues, onBlur, setValue],
  );

  const handleSetValueForInput = useCallback(
    (value: string) => {
      arrayOfOptionsList?.forEach(({ id, label, ...rest }: { id: string; label: string }) => {
        if (value === id && id !== preventAddingId) {
          setArrayOfValues(prevState => {
            if (prevState) {
              return Array.prototype.concat(prevState, [{ id, label, ...rest }]);
            } else {
              return [];
            }
          });
        }
      });
      handleBlur();
    },
    [arrayOfOptionsList, handleBlur],
  );

  const handleChooseElement = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent> | MouseEvent | KeyboardEvent, value: string) => {
      event.stopPropagation();
      handleSetValueForInput(value);
      setIsOpenList(false);
    },
    [handleSetValueForInput],
  );

  const handleMouseUp = useCallback(() => {
    setIndexChoseElement(0);
    setElementInFocus(arrayOfOptionsList?.[0]?.id!);
  }, [arrayOfOptionsList]);

  const removeItemFromArray = useCallback(
    (itemId: string) => () => {
      setArrayOfValues(prevState => prevState?.filter(({ id }) => id !== itemId));
    },
    [],
  );

  useEffect(() => {
    if (!showError) {
      setValue(arrayOfValues);
    }
    // eslint-disable-next-line
  }, [arrayOfValues, showError]);

  useEffect(() => {
    setElementInFocus(arrayOfOptionsList?.[0]?.id);
    setIndexChoseElement(0);
  }, [arrayOfOptionsList]);

  useEffect(() => {
    if (props.isResetInput) {
      setArrayOfValues([]);
    }
  }, [props.isResetInput]);

  const handleInputInFocus = useCallback(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  const handleClickByWindow = useCallback(
    (event: MouseEvent) => {
      if (!containerRef.current?.contains(event.target as HTMLInputElement)) {
        setIsOpenList(false);
        setValueForInput('');
        inputRef.current?.blur();
      }
    },
    [containerRef, inputRef],
  );

  const handleInputBlur = useCallback(() => {
    setTouched(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window?.addEventListener('mouseup', handleClickByWindow);
    return () => {
      window.removeEventListener('mouseup', handleClickByWindow);
    };
  }, [isOpenList, handleClickByWindow, inputRef]);

  useEffect(() => {
    const input = inputRef.current;
    input?.addEventListener('blur', handleInputBlur);
    return () => {
      input?.removeEventListener('blur', handleInputBlur);
    };
  }, [handleInputBlur]);

  useEffect(() => {
    if (allIncomingData) {
      setOptions(allIncomingData);
    }
  }, [allIncomingData, setOptions]);

  return (
    <div className="relative flex flex-col w-full" ref={containerRef}>
      <div className="flex justify-between mb-2">
        <label className="inline-block text-sm font-medium text-specialGray-1">{intl.formatMessage(label)}&nbsp;</label>
        {(maxCharCount as number) > 0 && (
          <span className={`text-xs sm:text-sm ${showError ? 'text-red-600' : 'text-specialGray-05'} my-auto sm:my-0`}>
            ({count}/{maxCharCount})
          </span>
        )}
      </div>
      <div className="relative flex items-center flex-auto text-darkBlue">
        <MagnifyingGlassIcon className="absolute top-1/5 left-2.5 sm:left-0.5 sm:ml-2 w-6 h-6 text-specialGray-05" />
        <SelectInputCommon
          ref={inputRef}
          onBlur={handleBlur}
          onMouseUp={handleMouseUp}
          onChangeSelect={setSubstr}
          classes={`${classesDisabled} ${placeholderWithError} ${classesWithWarning} ${classesDefault} ${classesWithError} pl-9 w-full rounded-md text-xs sm:text-sm focus:border-solid`}
          setValueForInput={setValueForInput}
          valueForInput={valueForInput}
          setIndexChoseElement={setIndexChoseElement}
          indexChoseElement={indexChoseElement}
          setIsOpenList={setIsOpenList}
          elementInFocus={elementInFocus}
          isOpenList={isOpenList}
          onInputInFocus={handleInputInFocus}
          autoComplete={autoComplete}
          {...props}
          {...field}
        />
      </div>
      <div className="relative">
        {isOpenList && (
          <DropDownList options={arrayOfOptionsList} onChooseElement={handleChooseElement}>
            <div className="py-1.5 mx-auto">{intl.formatMessage({ id: 'search_input_no_results' })}</div>
          </DropDownList>
        )}
      </div>
      {isShowWarning ? <p className="mt-2 text-sm text-yellow-600">{intl.formatMessage({ id: 'warning_length_of_job_positions' })}</p> : null}
      <div>{error && touched && <FieldErrorMessage error={error} />}</div>
      {!isEmpty(arrayOfValues) ? (
        <div className="flex flex-row flex-wrap mt-2">
          {!isEmpty(arrayOfValues) && arrayOfValues?.map(item => <ChoseElement width="w-fit" key={item.id} label={item.label} onClick={removeItemFromArray(item.id)} />)}
        </div>
      ) : null}
    </div>
  );
};

export default ChooseInputGroup;
