import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// components
import JobOpeningStatus from 'system/JobOpeningStatus/JobOpeningStatus';
//types
import { VACANCY_STATUS } from 'models/enums';
import { MemberListOfManagedVacanciesProps } from '../types';
// routing
import { RoutePaths, RoutesPathNames } from 'app/routing';

const EmployerWarningDeleteMemberList = ({ memberListOfManagedVacancies }: { memberListOfManagedVacancies: MemberListOfManagedVacanciesProps[] }) => {
  const intl = useIntl();

  const navigate = useNavigate();
  const { orgId } = useParams();

  const handleVacancyNavigate = (vacancyId: string) => () => {
    navigate(`${RoutePaths.ProfileOrganizationBase}/${orgId}/${RoutesPathNames.job}/${RoutesPathNames.edit}/${vacancyId}`);
  };

  return (
    <div className="flex flex-col w-full border rounded-xl">
      <table className="table-auto w-full rounded-xl overflow-hidden text-sm">
        <thead className="font-normal">
          <tr className="text-specialGray-075 border-b">
            <th className="px-3 py-2">{intl.formatMessage({ id: 'vacancy' })}</th>
            <th className="px-3 py-2">{intl.formatMessage({ id: 'hire_add_new_organization_team_member' })}</th>
            <th className="px-3 py-2">{intl.formatMessage({ id: 'hire_dashboard_table_header_status' })}</th>
          </tr>
        </thead>
        <tbody className="text-specialGray-075">
          {memberListOfManagedVacancies.map(vacancy => {
            return (
              <tr key={vacancy?.vacancyId} onClick={handleVacancyNavigate(vacancy.vacancyId)} className="text-specialGray-075 cursor-pointer border-b last:border-b-0">
                <td className="flex px-3 py-2 max-w-[180px]">{vacancy.title}</td>
                <td className="px-3 py-2">{vacancy.member}</td>
                <td className="px-3 py-2">
                  <JobOpeningStatus status={(vacancy?.status as VACANCY_STATUS) || ''} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EmployerWarningDeleteMemberList;
