// packages
import { useIntl } from 'react-intl';
import { RadioGroup } from '@headlessui/react';
import { RadioButtonsProps } from 'system/RadioButtons/types';

const RadioButtons = <T extends Record<string, string>>({ value, setValue, listOfRadioButtons }: RadioButtonsProps<T>) => {
  const intl = useIntl();
  return (
    <RadioGroup value={value} onChange={setValue} className="space-y-3">
      <RadioGroup.Label className="text-darkBlue font-medium sm:text-base text=sm">{intl.formatMessage({ id: 'step_with_experience_label' })}</RadioGroup.Label>
      <div className="space-y-4">
        {listOfRadioButtons.map(radioButton => (
          <RadioGroup.Option key={radioButton.id} className="flex items-center" value={radioButton}>
            {({ checked }) => (
              <>
                <input
                  data-test-id={radioButton.name}
                  id={radioButton.id}
                  name="userExperience"
                  type="radio"
                  defaultChecked={checked}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label htmlFor={radioButton.id} className="ml-3 block text-sm font-medium text-gray-700">
                  {radioButton.name}
                </label>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default RadioButtons;
