//hooks
import { useHover } from 'hooks/useHover';
import useTextOverflow from 'hooks/useTextOverflow';
//components
import Popover from 'system/Popover';
//type
import { OverviewTextItemWithPopoverProps } from 'app/ProfileCrew/type/ProfileCrewTypes';

const OverviewTextItemWithPopover = ({ description, additionalClasses = 'max-w-[120px]' }: OverviewTextItemWithPopoverProps) => {
  const { overflowingTextRef, isOverflowActive } = useTextOverflow();
  const { hovered, unHovered, isHovered } = useHover();

  return (
    <div
      onMouseEnter={hovered}
      onMouseLeave={unHovered}
      className={`flex flex-col relative w-full [&>#common-popover>#common-popover-content]:w-max [&>#common-popover>#common-popover-content]:max-w-[400px] ${additionalClasses} [&>#common-popover]:w-fit`}
    >
      <div className="inline-grid">
        <span className="truncate" ref={overflowingTextRef}>
          {description}
        </span>
      </div>

      {isHovered && isOverflowActive && (
        <Popover position="bottom-5">
          <span className="flex w-full justify-center px-3">{description}</span>
        </Popover>
      )}
    </div>
  );
};

export default OverviewTextItemWithPopover;
