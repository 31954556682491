//hooks
import useTextOverflow from 'hooks/useTextOverflow';
//components
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';

const AllCertificatesListItemTextPopover = ({ description }: { description: string }) => {
  const { overflowingTextRef, isOverflowActive } = useTextOverflow();

  return (
    <section className="inline-grid ml-1 sm:ml-3 text-xs sm:text-sm text-specialGray-03 relative sm:pr-2">
      {isOverflowActive ? (
        <PopoverHelper
          classes="inline-grid"
          element={
            <span ref={overflowingTextRef} className="truncate">
              {description}
            </span>
          }
          position="bottom-8 left-1/2 -translate-x-1/2"
          positionArrow="left-1/2 sm:-translate-x-1/2 rotate-45 -bottom-0.5"
          widthContainer="w-[200px] sm:w-[300px]"
          darkTheme
        >
          {description}
        </PopoverHelper>
      ) : (
        <span ref={overflowingTextRef} className="truncate">
          {description}
        </span>
      )}
    </section>
  );
};

export default AllCertificatesListItemTextPopover;
