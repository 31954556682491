const DangerConfirmIcon = () => {
  return (
    <svg width="106" height="76" viewBox="0 0 106 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M67.5867 40.2386L37.2504 9.84964C34.057 6.59062 28.7513 6.49953 25.4533 9.68564C24.3037 10.7501 23.5156 12.1381 23.1446 13.602L11.9995 55.4054C11.4075 59.9178 14.6568 64.0694 19.2331 64.6282C20.1423 64.7174 21.079 64.6813 21.9763 64.4983L63.675 53.3252C65.781 52.6399 67.4967 51.1817 68.5265 49.2145C69.5548 47.2417 69.7439 44.9854 69.0627 42.8989C68.7629 42.008 68.3298 41.1892 67.5867 40.2386Z"
        fill="url(#paint0_linear_1737_19732)"
      />
      <g filter="url(#filter0_f_1737_19732)">
        <path
          d="M55.7822 42.8684L38.3896 25.4457C36.5588 23.5772 33.5169 23.525 31.626 25.3516C30.967 25.9619 30.5151 26.7577 30.3024 27.597L23.9127 51.564C23.5732 54.151 25.4362 56.5313 28.0599 56.8516C28.5811 56.9028 29.1181 56.882 29.6326 56.7771L53.5396 50.3713C54.747 49.9784 55.7306 49.1424 56.321 48.0146C56.9106 46.8835 57.019 45.5899 56.6285 44.3936C56.4566 43.8829 56.2083 43.4134 55.7822 42.8684Z"
          fill="#E74C3C"
        />
      </g>
      <g filter="url(#filter1_b_1737_19732)">
        <path
          d="M103.425 54.9371L75.6549 6.74184C72.7518 1.59334 66.1436 -0.299495 60.9487 2.58139C59.1534 3.52781 57.7018 5.00043 56.7469 6.70777L28.786 55.2778C26.5323 60.7254 29.2062 67.0096 34.7449 69.2431C35.8526 69.6595 37.0368 69.9283 38.2209 70.0002H94.1427C97.0075 69.8488 99.6432 68.5996 101.591 66.4834C103.539 64.3596 104.532 61.5999 104.38 58.7606C104.303 57.5454 104.036 56.3756 103.425 54.9371Z"
          fill="#FF958A"
          fillOpacity="0.35"
        />
        <path
          d="M103.885 54.7416C103.877 54.723 103.868 54.7049 103.858 54.6875L76.0904 6.49625C76.0901 6.49565 76.0897 6.49505 76.0894 6.49445C73.0523 1.11048 66.1457 -0.869099 60.7108 2.14158C58.8286 3.13505 57.3107 4.67661 56.3121 6.46094C56.3115 6.46186 56.311 6.46277 56.3105 6.46369L28.3527 55.0283C28.3419 55.0471 28.3323 55.0666 28.324 55.0867C25.9621 60.7957 28.7662 67.3713 34.5579 69.7068L34.5578 69.707L34.569 69.7111C35.7192 70.1435 36.9523 70.4241 38.1906 70.4993C38.2007 70.4999 38.2108 70.5002 38.2209 70.5002H94.1427C94.1515 70.5002 94.1603 70.5 94.169 70.4995C97.1656 70.3412 99.9234 69.0334 101.959 66.822L101.96 66.8214C103.998 64.5996 105.039 61.7087 104.879 58.7337L104.879 58.7292C104.798 57.4524 104.516 56.2273 103.885 54.7416Z"
          stroke="url(#paint1_linear_1737_19732)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#filter2_bd_1737_19732)">
        <mask id="path-5Inside-1_1737_19732" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.1832 41.8383C68.0167 41.8383 69.5064 40.3203 69.5064 38.5032V27.7935C69.5064 25.9726 68.0167 24.5 66.1832 24.5C64.3497 24.5 62.8218 25.9726 62.8218 27.7935V38.5032C62.8218 40.3203 64.3497 41.8383 66.1832 41.8383ZM62.8218 51.4517C62.8218 53.2651 64.3497 54.7831 66.1832 54.7831C68.0167 54.7831 69.5064 53.2651 69.5064 51.4101C69.5064 49.5968 68.0167 48.1166 66.1832 48.1166C64.3497 48.1166 62.8218 49.6308 62.8218 51.4517Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.1832 41.8383C68.0167 41.8383 69.5064 40.3203 69.5064 38.5032V27.7935C69.5064 25.9726 68.0167 24.5 66.1832 24.5C64.3497 24.5 62.8218 25.9726 62.8218 27.7935V38.5032C62.8218 40.3203 64.3497 41.8383 66.1832 41.8383ZM62.8218 51.4517C62.8218 53.2651 64.3497 54.7831 66.1832 54.7831C68.0167 54.7831 69.5064 53.2651 69.5064 51.4101C69.5064 49.5968 68.0167 48.1166 66.1832 48.1166C64.3497 48.1166 62.8218 49.6308 62.8218 51.4517Z"
          fill="url(#paint2_linear_1737_19732)"
        />
        <path
          d="M69.1064 38.5032C69.1064 40.1025 67.7926 41.4383 66.1832 41.4383V42.2383C68.2407 42.2383 69.9064 40.5381 69.9064 38.5032H69.1064ZM69.1064 27.7935V38.5032H69.9064V27.7935H69.1064ZM66.1832 24.9C67.7995 24.9 69.1064 26.1972 69.1064 27.7935H69.9064C69.9064 25.748 68.2339 24.1 66.1832 24.1V24.9ZM63.2218 27.7935C63.2218 26.2031 64.5609 24.9 66.1832 24.9V24.1C64.1385 24.1 62.4218 25.7421 62.4218 27.7935H63.2218ZM63.2218 38.5032V27.7935H62.4218V38.5032H63.2218ZM66.1832 41.4383C64.5678 41.4383 63.2218 40.0966 63.2218 38.5032H62.4218C62.4218 40.544 64.1316 42.2383 66.1832 42.2383V41.4383ZM66.1832 54.3831C64.5675 54.3831 63.2218 53.0411 63.2218 51.4517H62.4218C62.4218 53.4891 64.1319 55.1831 66.1832 55.1831V54.3831ZM69.1064 51.4101C69.1064 53.0503 67.7897 54.3831 66.1832 54.3831V55.1831C68.2436 55.1831 69.9064 53.4799 69.9064 51.4101H69.1064ZM66.1832 48.5166C67.7989 48.5166 69.1064 49.8208 69.1064 51.4101H69.9064C69.9064 49.3728 68.2345 47.7166 66.1832 47.7166V48.5166ZM63.2218 51.4517C63.2218 49.8548 64.5675 48.5166 66.1832 48.5166V47.7166C64.1319 47.7166 62.4218 49.4068 62.4218 51.4517H63.2218Z"
          fill="url(#paint3_linear_1737_19732)"
          mask="url(#path-5Inside-1_1737_19732)"
        />
      </g>
      <defs>
        <filter id="filter0_f_1737_19732" x="4.87256" y="5.01172" width="70.9863" height="70.8672" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9.5" result="effect1_foregroundBlur_1737_19732" />
        </filter>
        <filter id="filter1_b_1737_19732" x="2.98584" y="-23.7676" width="126.409" height="118.768" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1737_19732" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1737_19732" result="shape" />
        </filter>
        <filter id="filter2_bd_1737_19732" x="47.8218" y="9.5" width="36.6846" height="60.2832" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1737_19732" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.493908 0 0 0 0 0.441667 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_1737_19732" result="effect2_dropShadow_1737_19732" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1737_19732" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_1737_19732" x1="41.0769" y1="35.581" x2="0.0986089" y2="74.3053" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FE6D5E" />
          <stop offset="1" stopColor="#E74C3C" />
        </linearGradient>
        <linearGradient id="paint1_linear_1737_19732" x1="40.1493" y1="9.24396" x2="82.8295" y2="67.9777" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint2_linear_1737_19732" x1="65.4735" y1="30.4902" x2="58.4392" y2="29.5128" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.75" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint3_linear_1737_19732" x1="63.8859" y1="28.028" x2="73.9394" y2="30.7765" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DangerConfirmIcon;
