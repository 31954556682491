// packages
import { FC, useCallback, useEffect, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
// system
import SelectInputGroup from 'system/SelectInputGroup';
// models
import { ICountries, ICountry } from 'models/ICountry';
import { SelectCountryInputGroupProps } from 'models/modelsOfComponents';
// schemas
import { GET_PROFILE_COUNTRIES_OF_RESIDENCE } from 'schemas/country/CountryQueries';
// generated
import { CountryQueriesCountriesOfResidenceQuery } from 'schemas/country/__generated__/CountryQueriesCountriesOfResidenceQuery.graphql';

const SelectCountryInputGroup: FC<SelectCountryInputGroupProps> = ({ name, initialValue, label, placeholder, autoFocus }) => {
  const dataOfCountries = useLazyLoadQuery<CountryQueriesCountriesOfResidenceQuery>(GET_PROFILE_COUNTRIES_OF_RESIDENCE, { skip: false });
  const [countries, setCountries] = useState<ICountries>(dataOfCountries?.countries?.edges || []);
  const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);

  const handleChangeCountry = useCallback(
    (value: string) => {
      if (value) {
        setCountries(() => dataOfCountries?.countries?.edges.filter(({ name }) => name.toLowerCase().includes(value.toLowerCase())) as ICountries);
      } else {
        setCountries(() => dataOfCountries.countries?.edges as ICountries);
      }
    },
    [dataOfCountries.countries],
  );

  useEffect(() => {
    if (initialValue) {
      setSelectedCountry(initialValue);
    }
  }, [initialValue]);

  const handleSetSelectedCountry = useCallback((value: ICountry) => {
    setSelectedCountry(value);
  }, []);

  return (
    <SelectInputGroup
      name={name}
      options={countries}
      label={label}
      onChangeSelect={handleChangeCountry}
      placeholder={placeholder}
      defaultSelectedElement={selectedCountry}
      setSelectedItem={handleSetSelectedCountry}
      selectedItem={selectedCountry}
      sortOptionsByAlphabetically
      autoComplete="chrome-off"
      autoFocus={autoFocus}
    />
  );
};

export default SelectCountryInputGroup;
