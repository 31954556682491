/**
 * @generated SignedSource<<019afd6cc7c0ef06d05daf92257def59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ServiceQueriesFormGetOneDutyQuery$variables = {
  id: string;
  skip: boolean;
};
export type ServiceQueriesFormGetOneDutyQuery$data = {
  readonly node?: {
    readonly alts?: ReadonlyArray<string> | null;
    readonly id?: string;
    readonly name?: string;
    readonly perma?: string;
  } | null;
};
export type ServiceQueriesFormGetOneDutyQuery = {
  response: ServiceQueriesFormGetOneDutyQuery$data;
  variables: ServiceQueriesFormGetOneDutyQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'skip',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'alts',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'perma',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ServiceQueriesFormGetOneDutyQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: null,
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                {
                  kind: 'InlineFragment',
                  selections: [v2 /*: any*/, v3 /*: any*/, v4 /*: any*/, v5 /*: any*/],
                  type: 'Duty',
                  abstractKey: null,
                },
              ],
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ServiceQueriesFormGetOneDutyQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v1 /*: any*/,
              concreteType: null,
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: '__typename',
                  storageKey: null,
                },
                v2 /*: any*/,
                {
                  kind: 'InlineFragment',
                  selections: [v3 /*: any*/, v4 /*: any*/, v5 /*: any*/],
                  type: 'Duty',
                  abstractKey: null,
                },
              ],
              storageKey: null,
            },
          ],
        },
      ],
    },
    params: {
      cacheID: '6a879bbae8b62bdf6b4dac6aabb30646',
      id: null,
      metadata: {},
      name: 'ServiceQueriesFormGetOneDutyQuery',
      operationKind: 'query',
      text: 'query ServiceQueriesFormGetOneDutyQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on Duty {\n      id\n      name\n      alts\n      perma\n    }\n    id\n  }\n}\n',
    },
  };
})();

(node as any).hash = '8d013cf3e4a209ae103ea989a0310df7';

export default node;
