// packages
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useLazyLoadQuery } from 'react-relay';
import { useParams } from 'react-router-dom';
// hooks
import { useGetHireProfile } from './index';
// generated
import { ApplicationQueriesGetCrewApplicationsQuery } from 'schemas/Application/__generated__/ApplicationQueriesGetCrewApplicationsQuery.graphql';
// schemas
import { GET_CREW_APPLICATIONS } from 'schemas/Application/ApplicationQueries';
// routing
import { RoutesPathNames } from 'app/routing';
// recoil
import { currentOrganisationSelector } from 'recoil/Organisation/getCurrentOrganization/selectors/currentOrganisationSelector';
import { contactInformationAtom } from 'recoil/ContactInformation/atoms/contactInformationAtom';

const useGetAppliedHireProfileVacancies = () => {
  const params = useParams();
  const getShowContactInformation = useRecoilValue(contactInformationAtom);

  const dynamicProfileId = params[RoutesPathNames.id] ?? getShowContactInformation.profileId;

  const crewAppliedVacancies = useLazyLoadQuery<ApplicationQueriesGetCrewApplicationsQuery>(GET_CREW_APPLICATIONS, { ownerId: dynamicProfileId!, skip: !dynamicProfileId })
    .userApplications?.edges;

  const getCurrentOrganisation = useRecoilValue(currentOrganisationSelector);

  const { organizationId } = useGetHireProfile();

  return useMemo(() => {
    if (getCurrentOrganisation.organisationId) {
      return crewAppliedVacancies?.filter(appliedVacancy => appliedVacancy?.node?.vacancy.organization.id === getCurrentOrganisation.organisationId);
    }
    return crewAppliedVacancies?.filter(appliedVacancy => appliedVacancy?.node?.vacancy.organization.id === organizationId);
  }, [crewAppliedVacancies, getCurrentOrganisation.organisationId, organizationId]);
};

export default useGetAppliedHireProfileVacancies;
