// packages
import React from 'react';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { RoutePaths, RoutesPathNames } from 'app/routing';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// models
import { VACANCY_DESCRIPTION_TYPE_OF_LIST } from 'models/enums';
import { ListOfCrewItemCommonProps } from 'models/modelsOfComponents';
// generated
import { ProfileFragments_info$data } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
// components
import CountryFlag from 'system/CountryFlag';
import { ProfileAvatar } from 'app/Avatars/ProfileAvatar/ProfileAvatar';
import ProfileFullName from 'app/ProfileEmployer/components/ReviewApplicationsPage/components/ProfileFullName';
// utils
import { getUserYears } from 'utils';

const ListOfCrewItemCommon: FC<ListOfCrewItemCommonProps> = ({ typeOfList, profileInfoData, countryData, primaryDuty, profileData, children }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { search } = useLocation();

  const handleClickByElement = (profileId: string) => () => {
    navigate({ pathname: `${RoutesPathNames.show}/${profileId}`, search }, { state: { previousPathname: pathname } });
  };

  const handleCLickByLink = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (typeOfList !== VACANCY_DESCRIPTION_TYPE_OF_LIST.INVITED) {
      event.preventDefault();
    }
  };

  return (
    <Link to={`${RoutePaths.ProfileSingleCrewPageEmployerView}/${profileData.id}`} className="flex rounded-2xl border border-specialGray-012" onClick={handleCLickByLink}>
      <div className="flex w-full p-4 justify-between" onClick={handleClickByElement(profileData.id)}>
        <div className="space-x-4 flex">
          <ProfileAvatar avatarUrl={profileInfoData?.avatarUrl} size="w-16 h-16" />
          <div className="flex flex-col justify-between">
            <div className="flex items-center font-medium space-x-4">
              {typeOfList === VACANCY_DESCRIPTION_TYPE_OF_LIST.MATCHED && (
                <div className=" flex justify-center items-center w-14 h-4 rounded bg-green-500 text-white uppercase text-[10px]">
                  {intl.formatMessage({ id: 'hire_job_invited_label' })}
                </div>
              )}
              <ProfileFullName profileInfoData={profileInfoData as ProfileFragments_info$data} />
              <span className="text-blue uppercase text-md">{primaryDuty?.name}</span>
              {profileInfoData?.birthDate && (
                <span className="text-specialGray-075 text-xs">{intl.formatMessage({ id: 'short_years_old' }, { years: getUserYears(profileInfoData.birthDate as string) })}</span>
              )}
              <CountryFlag countryName={countryData?.name} countryId={countryData?.isoCode} />
            </div>
            <div className="text-sm space-x-4 text-specialGray-075">
              <span>{intl.formatMessage({ id: 'profile_seaServiceAmount' }, { amount: profileData.seaServices?.length })}</span>
              <span>{intl.formatMessage({ id: 'profile_certificatesAmount' }, { amount: profileData.certificateInfos?.length })}</span>
              <span>{intl.formatMessage({ id: 'profile_educationAmount' }, { amount: profileData.educations?.length })}</span>
            </div>
          </div>
        </div>
        {children}
      </div>
    </Link>
  );
};

export default ListOfCrewItemCommon;
