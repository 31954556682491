import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useMutation } from 'react-relay';
//generated
import { ProfileMutationsDeleteCrewFromPoolMutation } from 'schemas/profile/__generated__/ProfileMutationsDeleteCrewFromPoolMutation.graphql';
//schemas
import { DELETE_CREW_FROM_POOL } from 'schemas/profile/ProfileMutations';
//components
import { Modal } from 'system/Modal';
import { ConfirmModalBody } from 'system/ConfirmModalBody';
//routing
import { RoutesPathNames } from 'app/routing';
//updaters
import { deleteUserFromCrewPoolUpdater } from 'formHelpers/updaters/updatersOfHireCrewPools';

const EmployerCrewPoolDeleteUserFromPool = () => {
  const { poolId, userId } = useParams();
  const arrayOfPoolIDs: string[] = useOutletContext();
  const location = useLocation();
  const navigate = useNavigate();

  const [deleteUserFromPool] = useMutation<ProfileMutationsDeleteCrewFromPoolMutation>(DELETE_CREW_FROM_POOL);

  const handleGoBack = () => {
    navigate(location.pathname.replace(`${RoutesPathNames.deleteUser}/${userId}`, ''), { replace: true });
  };

  const handleDeleteUserFromPool = () => {
    if (!poolId || !userId) return;
    deleteUserFromPool({
      variables: {
        poolId: poolId,
        profileIDs: [userId],
      },
      updater: deleteUserFromCrewPoolUpdater(userId, arrayOfPoolIDs),
      onCompleted: handleGoBack,
    });
  };
  return (
    <Modal onClose={handleGoBack} show={!!poolId && !!userId} tinyModal>
      <ConfirmModalBody
        title={{ id: 'hire_crew_pool_delete_user_title' }}
        textOne={{ id: 'hire_crew_pool_delete_user_description' }}
        onClick={handleDeleteUserFromPool}
        onCancel={handleGoBack}
        buttonLabel={{ id: 'delete' }}
        typeButtonConfirm="red-primary"
      />
    </Modal>
  );
};

export default EmployerCrewPoolDeleteUserFromPool;
