import { useState, FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
//models
import { HighlightElementWrapperProps } from 'models/modelsOfComponents';

const HighlightElementWrapper: FC<HighlightElementWrapperProps> = ({
  children,
  enableFlickering,
  addPadding,
  defaultEnableFlickering,
  className = '',
  borderRadius = 'rounded-md',
}) => {
  const [isFlickering, setIsFlickering] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (enableFlickering) {
      setIsFlickering(enableFlickering);
      timer = setTimeout(() => {
        setIsFlickering(false);
        navigate(`${pathname}`, {
          replace: true,
          state: null,
        });

        clearTimeout(timer as NodeJS.Timeout);
      }, 3000);
    }
    return () => {
      clearTimeout(timer as NodeJS.Timeout);
    };
  }, [enableFlickering, isFlickering, navigate, pathname]);

  return (
    <div
      className={`${className} ${isFlickering ? `blink-border ${addPadding} ${borderRadius}` : ''} ${defaultEnableFlickering ? `blink-border ${addPadding} ${borderRadius}` : ''}`}
    >
      {children}
    </div>
  );
};

export default HighlightElementWrapper;
