// packages
import React from 'react';
import { useIntl } from 'react-intl';
import { formatDistanceStrict, format } from 'date-fns';
import { useFragment } from 'react-relay';
// components
import ShipPhoto from 'icons/ShipPhoto';
import CountryFlag from 'system/CountryFlag';
// generated
import { ServiceFragments$key } from 'schemas/services/__generated__/ServiceFragments.graphql';
import { ServiceFragmentsVessel$key } from 'schemas/services/__generated__/ServiceFragmentsVessel.graphql';
import { ServiceFragmentsOneDuty$key } from 'schemas/services/__generated__/ServiceFragmentsOneDuty.graphql';
// schemas
import { GET_ONE_DUTY, SERVICE_FRAGMENT, VESSEL_FRAGMENT } from 'schemas/services/ServiceFragments';
// models
import { HighlightedSeaServicesItemProps } from 'models/modelsOfComponents';

export const HighlightedSeaServicesItem: React.FC<HighlightedSeaServicesItemProps> = ({ service, servicesAmount, onClickMore }) => {
  const intl = useIntl();

  const dataOfSeaServices = useFragment<ServiceFragments$key>(SERVICE_FRAGMENT, service);
  const vesselData = useFragment<ServiceFragmentsVessel$key>(VESSEL_FRAGMENT, dataOfSeaServices?.vessel || null);
  const dataOfDuty = useFragment<ServiceFragmentsOneDuty$key>(GET_ONE_DUTY, dataOfSeaServices.duty);

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), 'dd.MM.yy');
  };

  return (
    <div className={`flex flex-col w-full md:w-30 mb-2 sm:mb-5 md:mb-0 rounded-lg pt-3 border ${servicesAmount >= 3 ? '' : 'sm:mr-3.33'}`}>
      <section className="flex flex-col w-full px-3 mb-5">
        <section className="flex flex-row items-center">
          {/* flag */}
          <CountryFlag countryId={vesselData?.cnISO} countryName={vesselData?.countryName} />
          <div className="flex sm:flex-row flex-col sm:items-center sm:justify-between sm:w-full">
            <span className="text-specialGray-1 text-lg truncate">{vesselData?.name}</span>
            <span className="sm:ml-auto text-specialGray-075 text-sm">{vesselData?.kind?.name}</span>
          </div>
        </section>
        {/* Ship photo */}
        {vesselData?.image ? (
          <div className="h-32 overflow-hidden rounded-lg flex items-center mt-3 mb-3">
            <img className="w-full h-auto" src={vesselData.image} alt={vesselData.name} />
          </div>
        ) : (
          <div className="bg-avatar-bg w-full mt-3 mb-3 py-14 md:py-10 px-8 flex justify-center rounded-lg">
            <span className="w-[37px] h-[33px]">
              <ShipPhoto />
            </span>
          </div>
        )}
        <section className="text-sm flex flex-wrap flex-col">
          {/* duty */}
          <p className="text-specialGray-075 border-b pb-1 flex justify-between">
            <span className="mr-1 uppercase">{intl.formatMessage({ id: 'label_form_duty' })}</span>
            <span className="text-specialGray-1 font-medium">{dataOfDuty.name}</span>
          </p>

          {/* duration */}
          <div className="flex text-specialGray-075 pt-1">
            <span className="mr-auto uppercase">{intl.formatMessage({ id: 'sea_service_duration' })}</span>
            <div className="flex sm:flex-row flex-col items-end sm:items-start">
              <span>
                {formatDate(`${dataOfSeaServices.start}`)} - {dataOfSeaServices.end && formatDate(`${dataOfSeaServices.end}`)}
              </span>
              {!dataOfSeaServices.end && <span className="sm:ml-1">{intl.formatMessage({ id: 'service_present' })}</span>}
              {dataOfSeaServices.end && (
                <span className="text-specialGray-1 sm:ml-2 font-medium">{formatDistanceStrict(new Date(`${dataOfSeaServices.end}`), new Date(`${dataOfSeaServices.start}`))}</span>
              )}
            </div>
          </div>
        </section>
      </section>

      <section className="w-full px-3 mt-auto bg-specialGray-002 text-sm text-specialGray-075 hover:text-darkBlue py-3">
        <button onClick={() => onClickMore && onClickMore(dataOfSeaServices)}>{intl.formatMessage({ id: 'sea_service_highlighted_see_info' })}</button>
      </section>
    </div>
  );
};
