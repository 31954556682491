// packages
import React, { FC, useCallback } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useFragment, useMutation, useRefetchableFragment } from 'react-relay';
// generated
import { InviteFragments$key } from 'schemas/Invite/__generated__/InviteFragments.graphql';
import { VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { ProfileFragmentsCrewProfile$key } from 'schemas/profile/__generated__/ProfileFragmentsCrewProfile.graphql';
import { InviteMutationsRemoveInviteMutation } from 'schemas/Invite/__generated__/InviteMutationsRemoveInviteMutation.graphql';
import { VacancyQueriesGetVacancyRefreshQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetVacancyRefreshQuery.graphql';
import { ProfileFragments_info$data, ProfileFragments_info$key } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
import { CountryFragments_info$data, CountryFragments_info$key } from 'schemas/country/__generated__/CountryFragments_info.graphql';
import { ServiceFragmentsOneDuty$data, ServiceFragmentsOneDuty$key } from 'schemas/services/__generated__/ServiceFragmentsOneDuty.graphql';
// schemas
import { INVITE_FRAGMENT } from 'schemas/Invite/InviteFragments';
import { VACANCY_FRAGMENT } from 'schemas/vacancy/VacancyFragments';
import { COUNTRY_FRAGMENT } from 'schemas/country/CountryFragments';
import { CREW_PROFILE_FRAGMENT, PROFILE_INFO_FRAGMENT } from 'schemas/profile/ProfileFragments';
// models
import { ItemListOfCrewProps } from 'models/modelsOfComponents';
import { GET_ONE_DUTY } from 'schemas/services/ServiceFragments';
import { REMOVE_VACANCY_INVITE } from 'schemas/Invite/InviteMutations';
// components
import { Modal } from 'system/Modal';
import { Button } from 'system/Button';
import { ConfirmModalBody } from 'system/ConfirmModalBody';
import UninvitedArrowIcon from 'icons/UninvitedArrowIcon';
import { RemoveInviteCommit } from 'formHelpers/updaters/updatersOfJobOpening';
import ListOfCrewItemCommon from 'system/JobOpeningComponents/ListOfCrewItemCommon';
// hooks
import { useToggleShowWindow } from 'hooks/useToggleShowModal';

const ItemListOfCrew: FC<ItemListOfCrewProps> = ({ inviteFragment, vacancyFragment }) => {
  const { jobDescriptionEditId } = useParams();
  const { toggleShowWindow, showWindow } = useToggleShowWindow();
  const invite = useFragment<InviteFragments$key>(INVITE_FRAGMENT, inviteFragment);
  const [, reFetch] = useRefetchableFragment<VacancyQueriesGetVacancyRefreshQuery, VacancyFragments$key>(VACANCY_FRAGMENT, vacancyFragment as VacancyFragments$key);

  const profileData = useFragment<ProfileFragmentsCrewProfile$key>(CREW_PROFILE_FRAGMENT, invite.owner);
  const profileInfoData = useFragment<ProfileFragments_info$key>(PROFILE_INFO_FRAGMENT, profileData.info);
  const primaryDuty = useFragment<ServiceFragmentsOneDuty$key>(GET_ONE_DUTY, profileInfoData?.primaryDuty || null);
  const countryData = useFragment<CountryFragments_info$key>(COUNTRY_FRAGMENT, profileInfoData?.country || null);

  const [commitRemoveInvite] = useMutation<InviteMutationsRemoveInviteMutation>(REMOVE_VACANCY_INVITE);

  const handleDiscard = useCallback(
    (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      commitRemoveInvite({
        variables: {
          id,
        },
        onCompleted: () => {
          reFetch({}, { fetchPolicy: 'network-only' });
          toggleShowWindow(false)();
        },
        updater: RemoveInviteCommit(jobDescriptionEditId!),
      });
    },
    [commitRemoveInvite, jobDescriptionEditId, reFetch, toggleShowWindow],
  );

  return (
    <>
      <Modal onClose={toggleShowWindow(false)} show={showWindow} tinyModal={true}>
        <ConfirmModalBody
          title={{ id: 'confirm_uninvite_this_crew_title' }}
          textOne={{ id: 'confirm_uninvite_this_crew_text_1' }}
          onCancel={toggleShowWindow(false)}
          onClick={handleDiscard(invite.id)}
          buttonLabel={{ id: 'hire_job_description_un_invite_title' }}
          typeButtonConfirm="red-primary"
          buttonWithIcon={false}
        />
      </Modal>
      <ListOfCrewItemCommon
        profileInfoData={profileInfoData as ProfileFragments_info$data}
        profileData={profileData}
        countryData={countryData as CountryFragments_info$data}
        primaryDuty={primaryDuty as ServiceFragmentsOneDuty$data}
      >
        <div className="flex items-center space-x-8">
          <div className="flex flex-col items-end text-xs text-specialGray-075">
            <span>{format(new Date(invite.createdAt?.toString()), 'hh:mm')}</span>
            <span>{format(new Date(invite.createdAt?.toString()), 'dd LLL yy')}</span>
          </div>
          <Button
            label={{ id: 'hire_job_description_un_invite_title' }}
            type="button"
            buttonType="white-primary"
            prefixIcon={UninvitedArrowIcon}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              toggleShowWindow(true)();
            }}
            additionalClasses="rounded-xl space-x-3"
          />
        </div>
      </ListOfCrewItemCommon>
    </>
  );
};

export default ItemListOfCrew;
