import { useEffect } from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
//generated
import { ProfileFragments$key } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { ProfileQueriesRootQuery } from 'schemas/profile/__generated__/ProfileQueriesRootQuery.graphql';
import { ProfileFragmentsOrganizationPlural_profile$key } from 'schemas/profile/__generated__/ProfileFragmentsOrganizationPlural_profile.graphql';
//schemas
import { PROFILE_QUERY } from 'schemas/profile/ProfileQueries';
import { PROFILE_FRAGMENT, PROFILE_ORGANIZATION_FRAGMENT } from 'schemas/profile/ProfileFragments';
// hooks
import { useAuth } from 'authentication';
import useGetCurrentOpenedProfileOrganization from 'hooks/hireProfileHooks/useGetCurrentOpenedProfileOrganization';
// context
import { useHireProfileContext } from 'Context/HireProfileContext';

const useProfileMembership = () => {
  const { identity } = useAuth();
  const profileFragment = useLazyLoadQuery<ProfileQueriesRootQuery>(PROFILE_QUERY, { id: `${identity?.profileId}`, skip: !identity?.profileId });
  const profileData = useFragment<ProfileFragments$key>(PROFILE_FRAGMENT, profileFragment?.node!);
  const membershipData = useFragment<ProfileFragmentsOrganizationPlural_profile$key>(PROFILE_ORGANIZATION_FRAGMENT, profileData?.membership);
  const { setHireOrganization } = useHireProfileContext();

  const { profileOrganization } = useGetCurrentOpenedProfileOrganization(membershipData);

  useEffect(() => {
    setHireOrganization({ id: profileOrganization?.organization?.id || '' });
  }, [membershipData, setHireOrganization, profileOrganization]);

  return {
    profileFragment,
    membership: profileOrganization,
  };
};

export default useProfileMembership;
