// packages
import { useCallback, useEffect, useState } from 'react';
// types
import { SelectDutyWithTrackingProps } from '../../../types';
// models
import { DutyService, IListOfDutyService } from 'models/IListOfDutyService';
// components
import SelectInputGroup from 'system/SelectInputGroup';
// utils
import { onChangeDuty } from 'utils';

const SelectDutyWithTacking = ({ allDuties, nameField, autoFocus }: SelectDutyWithTrackingProps) => {
  const [duties, setDuties] = useState<IListOfDutyService>(allDuties?.duties?.edges || []);

  const [selectedDuty, setSelectedDuty] = useState<DutyService | undefined>();

  const handleChangeDuty = useCallback((value: string) => onChangeDuty(allDuties?.duties?.edges as IListOfDutyService, setDuties)(value), [allDuties]);

  useEffect(() => {
    if (selectedDuty) {
      window.analytics?.track('wui-signup-step-sea-service-with-chose-duty', {
        dutyId: selectedDuty.id,
      });
    }
  }, [selectedDuty]);

  return (
    <div className="w-full mr-5">
      <SelectInputGroup
        name={nameField}
        options={duties}
        label={{ id: 'label_form_duty' }}
        onChangeSelect={handleChangeDuty}
        placeholder={{ id: 'service_form_duty_placeholder' }}
        defaultSelectedElement={selectedDuty}
        setSelectedItem={setSelectedDuty}
        selectedItem={selectedDuty}
        autoComplete="off"
        autoFocus={autoFocus}
      />
    </div>
  );
};

export default SelectDutyWithTacking;
