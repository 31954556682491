import React from 'react';

export const SearchInputIcon: React.FC<{ className: string }> = ({ className }) => {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.80541" cy="9.80492" r="7.49047" stroke="#8490A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.0151 15.4043L17.9518 18.3334" stroke="#8490A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
