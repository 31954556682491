/**
 * @generated SignedSource<<272530dc726760774a3041822df1c172>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ProfileFragments$data = {
  readonly 'appraisals': ReadonlyArray<{
    readonly ' $fragmentSpreads': FragmentRefs<'AppraisalFragmentsListPlural_profile'>;
  }> | null;
  readonly 'certificateInfos': ReadonlyArray<{
    readonly ' $fragmentSpreads': FragmentRefs<'CertificateFragmentsCertificateInfo'>;
  }> | null;
  readonly 'createdAt': String;
  readonly 'educations': ReadonlyArray<{
    readonly 'end': String | null;
    readonly 'id': string;
    readonly ' $fragmentSpreads': FragmentRefs<'EducationFragments'>;
  }> | null;
  readonly 'generalSetting': {
    readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsGeneralSettings'>;
  } | null;
  readonly 'id': string;
  readonly 'info': {
    readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragments_info'>;
  } | null;
  readonly 'membership': ReadonlyArray<{
    readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsOrganizationPlural_profile'>;
  }> | null;
  readonly 'notificationPreference': {
    readonly id: string;
    readonly jobOpening: boolean;
    readonly tipsToImprove: boolean;
  } | null;
  readonly 'seaServices': ReadonlyArray<{
    readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragmentsListPlural_profile'>;
  }> | null;
  readonly 'seaServicesAddedCount': number;
  readonly 'seaServicesHighlighted': ReadonlyArray<{
    readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragmentsListPlural_profile'>;
  }> | null;
  readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsAvatarInfo'>;
  readonly ' $fragmentType': 'ProfileFragments';
};
export type ProfileFragments$key = {
  readonly ' $data'?: ProfileFragments$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragments'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = [
      {
        args: null,
        kind: 'FragmentSpread',
        name: 'ServiceFragmentsListPlural_profile',
      },
    ];
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: {
      refetch: {
        connection: null,
        fragmentPathInResult: ['node'],
        operation: require('./ProfileQueriesRootRefreshQuery.graphql'),
        identifierField: 'id',
      },
    },
    name: 'ProfileFragments',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'createdAt',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'ProfileInfo',
        kind: 'LinkedField',
        name: 'info',
        plural: false,
        selections: [
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'ProfileFragments_info',
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'CertificateInfo',
        kind: 'LinkedField',
        name: 'certificateInfos',
        plural: true,
        selections: [
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'CertificateFragmentsCertificateInfo',
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'SeaService',
        kind: 'LinkedField',
        name: 'seaServices',
        plural: true,
        selections: v1 /*: any*/,
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Education',
        kind: 'LinkedField',
        name: 'educations',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'end',
            storageKey: null,
          },
          v0 /*: any*/,
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'EducationFragments',
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'SeaService',
        kind: 'LinkedField',
        name: 'seaServicesHighlighted',
        plural: true,
        selections: v1 /*: any*/,
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'Appraisal',
        kind: 'LinkedField',
        name: 'appraisals',
        plural: true,
        selections: [
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'AppraisalFragmentsListPlural_profile',
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'OrganizationMember',
        kind: 'LinkedField',
        name: 'membership',
        plural: true,
        selections: [
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'ProfileFragmentsOrganizationPlural_profile',
          },
        ],
        storageKey: null,
      },
      {
        args: null,
        kind: 'FragmentSpread',
        name: 'ProfileFragmentsAvatarInfo',
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'seaServicesAddedCount',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'NotificationPreference',
        kind: 'LinkedField',
        name: 'notificationPreference',
        plural: false,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'jobOpening',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'tipsToImprove',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'GeneralSetting',
        kind: 'LinkedField',
        name: 'generalSetting',
        plural: false,
        selections: [
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'ProfileFragmentsGeneralSettings',
          },
        ],
        storageKey: null,
      },
    ],
    type: 'Profile',
    abstractKey: null,
  };
})();

(node as any).hash = '7773802c90db258dfcaca36b67618cb7';

export default node;
