// packages
import { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Skeleton from 'react-loading-skeleton';
// components
import EducationItemSkeleton from 'system/skeletons/profileTabs/components/EducationItemSkeleton';

const ListOfEducationsSkeleton: FC = () => {
  const [emptyList] = useState<Array<string>>(new Array(2).fill('').map(() => uuidv4()));

  return (
    <div className="flex flex-col w-full my-4 space-y-4">
      <div className="flex w-full">
        <Skeleton width={90} height={12} containerClassName="w-1/2" />
        <Skeleton width={110} height={14} />
      </div>
      {emptyList.map(item => (
        <EducationItemSkeleton key={item} width="w-full" />
      ))}
      <Skeleton width={167} height={34} borderRadius={6} containerClassName="mx-auto" />
    </div>
  );
};

export default ListOfEducationsSkeleton;
