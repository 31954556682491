/**
 * @generated SignedSource<<6a65295357e5323606e1c88cf61d5f92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GeneralSettingData = {
  availableForWork?: boolean | null;
  hideContactInfo?: boolean | null;
  ownerId: string;
};
export type ProfileMutationsCreateGeneralSettingsMutation$variables = {
  data: GeneralSettingData;
};
export type ProfileMutationsCreateGeneralSettingsMutation$data = {
  readonly createGeneralSetting: {
    readonly availableForWork: boolean;
    readonly hideContactInfo: boolean;
    readonly id: string;
  };
};
export type ProfileMutationsCreateGeneralSettingsMutation = {
  response: ProfileMutationsCreateGeneralSettingsMutation$data;
  variables: ProfileMutationsCreateGeneralSettingsMutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'data',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'data',
            variableName: 'data',
          },
        ],
        concreteType: 'GeneralSetting',
        kind: 'LinkedField',
        name: 'createGeneralSetting',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'availableForWork',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'hideContactInfo',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileMutationsCreateGeneralSettingsMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ProfileMutationsCreateGeneralSettingsMutation',
      selections: v1 /*: any*/,
    },
    params: {
      cacheID: '9a638f839db9f1043628566f882e468b',
      id: null,
      metadata: {},
      name: 'ProfileMutationsCreateGeneralSettingsMutation',
      operationKind: 'mutation',
      text: 'mutation ProfileMutationsCreateGeneralSettingsMutation(\n  $data: GeneralSettingData!\n) {\n  createGeneralSetting(data: $data) {\n    id\n    availableForWork\n    hideContactInfo\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'fdfdb05a58a65db686954b49b0117acb';

export default node;
