// routes
import { RoutePaths } from 'app/routing';
import PrivateRoutes from 'app/PrivateRoutes/PrivateRoutes';
import AccountSettingsCommon from 'app/AccountSettings/AccountSettingsCommon';
import AvailableForWork from 'app/ManualSettingOfAvailableForWork/AvailableForWork';

export const SimpleRoute = [
  {
    path: RoutePaths.AccountSettings,
    element: (
      <PrivateRoutes>
        <AccountSettingsCommon />
      </PrivateRoutes>
    ),
  },
  {
    path: RoutePaths.AvailableForWork,
    element: <AvailableForWork isAvailable />,
  },
  {
    path: RoutePaths.UnavailableForWork,
    element: <AvailableForWork isAvailable={false} />,
  },
];
