import React from 'react';
import { EducationIconProps } from 'models/modelsOfComponents';

export const Primary: React.FC<EducationIconProps> = ({ big }) => {
  const size = big ? 45 : 30;
  return (
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.375 30H5.625C2.51813 30 0 27.4819 0 24.375V5.625C0 2.51813 2.51813 0 5.625 0H24.375C27.4819 0 30 2.51813 30 5.625V24.375C30 27.4819 27.4819 30 24.375 30Z"
        fill="#EBDC5A"
        fillOpacity="0.2"
      />
      <path
        d="M24.9606 12.3207C25.3302 12.3207 25.6303 12.0206 25.6303 11.651V10.3116C25.6303 10.0638 25.4936 9.83749 25.2753 9.72096L15.2301 4.36351C15.0332 4.25904 14.7975 4.25904 14.6006 4.36351L4.55537 9.72096C4.33705 9.83749 4.20044 10.0638 4.20044 10.3116V11.651C4.20044 12.0206 4.50046 12.3207 4.87012 12.3207H6.87917V23.0356H5.09379C4.59957 23.0356 4.20044 23.4347 4.20044 23.9289V24.8223C4.20044 25.3152 4.59957 25.7143 5.09379 25.7143H24.7382C25.2311 25.7143 25.6303 25.3152 25.6303 24.8209V23.9276C25.6303 23.4347 25.2311 23.0356 24.7369 23.0356H22.9515V12.3207H24.9606ZM21.1662 23.0356H8.66588V12.3207H21.1662V23.0356Z"
        fill="#EBDC5A"
      />
      <rect x="14.4004" y="14.4609" width="1.2" height="6.56558" rx="0.599999" fill="#EBDC5A" />
    </svg>
  );
};

export const Secondary: React.FC<EducationIconProps> = ({ big }) => {
  const size = big ? 45 : 30;
  return (
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.375 30H5.625C2.51813 30 0 27.4819 0 24.375V5.625C0 2.51813 2.51813 0 5.625 0H24.375C27.4819 0 30 2.51813 30 5.625V24.375C30 27.4819 27.4819 30 24.375 30Z"
        fill="#FFC266"
        fillOpacity="0.2"
      />
      <path
        d="M24.9606 12.3207C25.3302 12.3207 25.6303 12.0206 25.6303 11.651V10.3116C25.6303 10.0638 25.4936 9.83749 25.2753 9.72096L15.2301 4.36351C15.0332 4.25904 14.7975 4.25904 14.6006 4.36351L4.55537 9.72096C4.33705 9.83749 4.20044 10.0638 4.20044 10.3116V11.651C4.20044 12.0206 4.50046 12.3207 4.87012 12.3207H6.87917V23.0356H5.09379C4.59957 23.0356 4.20044 23.4347 4.20044 23.9289V24.8223C4.20044 25.3152 4.59957 25.7143 5.09379 25.7143H24.7382C25.2311 25.7143 25.6303 25.3152 25.6303 24.8209V23.9276C25.6303 23.4347 25.2311 23.0356 24.7369 23.0356H22.9515V12.3207H24.9606ZM21.1662 23.0356H8.66588V12.3207H21.1662V23.0356Z"
        fill="#FFC266"
      />
      <rect x="13.1997" y="14.3984" width="1.2" height="6.56558" rx="0.6" fill="#FFC266" />
      <rect x="15.6003" y="14.3984" width="1.2" height="6.56558" rx="0.6" fill="#FFC266" />
    </svg>
  );
};

export const Tertiary: React.FC<EducationIconProps> = ({ big }) => {
  const size = big ? 45 : 30;
  return (
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.0455 12.3207C25.4152 12.3207 25.7152 12.0207 25.7152 11.651V10.3116C25.7152 10.0638 25.5786 9.83749 25.3603 9.72096L15.3151 4.36351C15.1182 4.25904 14.8824 4.25904 14.6856 4.36351L4.64033 9.72096C4.42202 9.83749 4.2854 10.0638 4.2854 10.3116V11.651C4.2854 12.0207 4.58542 12.3207 4.95508 12.3207H6.96413V23.0356H5.17876C4.68453 23.0356 4.2854 23.4347 4.2854 23.9289V24.8223C4.2854 25.3152 4.68453 25.7143 5.17876 25.7143H24.8232C25.3161 25.7143 25.7152 25.3152 25.7152 24.821V23.9276C25.7152 23.4347 25.3161 23.0356 24.8219 23.0356H23.0365V12.3207H25.0455ZM21.2511 23.0356H8.75084V12.3207H21.2511V23.0356Z"
        fill="#FE8E6B"
      />
      <rect x="11.4353" y="14.4336" width="1.2" height="6.56559" rx="0.6" fill="#FE8E6B" />
      <rect x="14.0608" y="14.4336" width="1.2" height="6.56559" rx="0.599999" fill="#FE8E6B" />
      <rect x="16.6865" y="14.4336" width="1.2" height="6.56559" rx="0.599999" fill="#FE8E6B" />
      <path
        d="M24.375 30H5.625C2.51813 30 0 27.4819 0 24.375V5.625C0 2.51813 2.51813 0 5.625 0H24.375C27.4819 0 30 2.51813 30 5.625V24.375C30 27.4819 27.4819 30 24.375 30Z"
        fill="#FE8E6B"
        fillOpacity="0.2"
      />
    </svg>
  );
};

export const Doctoral: React.FC<EducationIconProps> = ({ big }) => {
  const size = big ? 45 : 30;
  return (
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.375 30H5.625C2.51813 30 0 27.4819 0 24.375V5.625C0 2.51813 2.51813 0 5.625 0H24.375C27.4819 0 30 2.51813 30 5.625V24.375C30 27.4819 27.4819 30 24.375 30Z"
        fill="#2A6FF5"
        fillOpacity="0.2"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M14.8172 20.7183C14.5926 20.7183 14.3714 20.6801 14.1597 20.6049L8.25 18.5039V20.6049C8.25 21.3093 8.98486 21.905 10.4342 22.3754C11.6975 22.7854 13.2541 23.0112 14.8172 23.0112C16.3804 23.0112 17.937 22.7854 19.2003 22.3754C20.6496 21.905 21.3526 21.3093 21.3526 20.6049V18.5039L15.4748 20.6049C15.263 20.6801 15.0418 20.7183 14.8172 20.7183Z"
          fill="#2A6FF5"
        />
        <path
          d="M14.815 19.3511C14.5904 19.3511 14.3692 19.3129 14.1576 19.2377L8.2478 17.1367V19.2377C8.2478 19.9421 8.98267 20.5378 10.432 21.0082C11.6953 21.4182 13.2519 21.644 14.815 21.644C16.3782 21.644 17.9348 21.4182 19.1981 21.0082C20.6474 20.5378 21.3823 19.9421 21.3823 19.2377V17.1367L15.4726 19.2377C15.2608 19.3129 15.0396 19.3511 14.815 19.3511Z"
          fill="#D4E2FD"
        />
        <path
          d="M25.5634 9.81274L15.051 6.1037C14.9102 6.05397 14.7565 6.05397 14.6156 6.1037L4.10326 9.81274C3.84189 9.90495 3.66698 10.1518 3.66663 10.429C3.66628 10.7062 3.84063 10.9534 4.10178 11.0463L14.6142 14.7835C14.685 14.8087 14.7592 14.8213 14.8333 14.8213C14.9075 14.8213 14.9816 14.8087 15.0525 14.7835L25.5648 11.0463C25.826 10.9534 26.0003 10.7062 26 10.429C25.9996 10.1518 25.8247 9.90495 25.5634 9.81274Z"
          fill="#2A6FF5"
        />
        <path
          d="M24.4286 17.0459V12.8398L23.12 13.305V17.0459C22.7258 17.2728 22.4602 17.6979 22.4602 18.1853C22.4602 18.6727 22.7258 19.0978 23.12 19.3247V20.7717C23.12 21.1331 23.4129 21.426 23.7743 21.426C24.1356 21.426 24.4286 21.1331 24.4286 20.7717V19.3247C24.8227 19.0979 25.0883 18.6728 25.0883 18.1853C25.0883 17.6979 24.8227 17.2728 24.4286 17.0459Z"
          fill="#2A6FF5"
        />
        <path
          d="M14.6863 18.1045C14.4617 18.1045 14.2405 18.0664 14.0288 17.9911L8.25781 15.8906V17.9916C8.25781 18.696 8.85394 19.2913 10.3033 19.7616C11.5665 20.1717 13.1231 20.3975 14.6863 20.3975C16.2495 20.3975 17.806 20.1717 19.0694 19.7616C20.5187 19.2913 21.3527 18.704 21.3527 17.9995V15.8986L15.3438 17.9911C15.1321 18.0664 14.9109 18.1045 14.6863 18.1045Z"
          fill="#2A6FF5"
        />
        <path
          d="M14.8154 16.2144C14.5908 16.2144 14.3696 16.1762 14.1579 16.1009L8.24817 14V16.5C8.24817 17.2044 8.98303 17.8001 10.4323 18.2705C11.6956 18.6806 13.2522 18.9064 14.8154 18.9064C16.3786 18.9064 17.9351 18.6806 19.1985 18.2705C20.6478 17.8001 21.3826 17.2044 21.3826 16.5V14L15.4729 16.1009C15.2612 16.1762 15.04 16.2144 14.8154 16.2144Z"
          fill="#D4E2FD"
        />
        <path
          d="M14.8335 16.1362C14.6089 16.1362 14.3877 16.0981 14.176 16.0228L8.26624 13.9219L8.31582 15.2295C8.31582 15.9339 9.05069 16.5296 10.5 17C11.7633 17.41 13.3199 17.6358 14.8831 17.6358C16.4462 17.6358 18.0028 17.41 19.2661 17C20.7154 16.5296 21.4503 15.9339 21.4503 15.2295L21.4007 13.9219L15.491 16.0228C15.2793 16.0981 15.0581 16.1362 14.8335 16.1362Z"
          fill="#2A6FF5"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="22.3333" height="22.3333" fill="white" transform="translate(3.66663 3.66797)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default function getEducationIcon(type: string, bigIcon?: boolean) {
  let element;
  switch (type) {
    case '1':
      element = <Secondary big={bigIcon} />;
      break;

    case '2':
      element = <Tertiary big={bigIcon} />;
      break;

    case '3':
      element = <Doctoral big={bigIcon} />;
      break;

    default:
      element = <Primary big={bigIcon} />;
  }

  return element;
}
