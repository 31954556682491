import { ConnectionHandler, RecordSourceSelectorProxy } from 'relay-runtime';
import { AppraisalMutationsListRemoveMutation$data } from 'schemas/appraisals/__generated__/AppraisalMutationsListRemoveMutation.graphql';

export const UpdatersOfAppraisalCommitRemove = (storyID: string) => (store: RecordSourceSelectorProxy<AppraisalMutationsListRemoveMutation$data>) => {
  const payload = store.getRootField('deleteAppraisal');
  const record = store.get(`${storyID}`);
  if (record) {
    ConnectionHandler.deleteNode(record, `${payload?.getDataID()}`);
  }
};
