// components
import MainOrgInfoSkeleton from './components/MainOrgInfoSkeleton';
import ListOfMembersSkeleton from './components/ListOfMembersSkeleton/ListOfMembersSkeleton';
import ListOfVacationsSkeleton from './components/ListOfVacationsSkeleton/ListOfVacationsSkeleton';

const EmployerInfoSkeleton = () => {
  return (
    <section className="flex flex-col w-full gap-y-8">
      <MainOrgInfoSkeleton />
      <ListOfVacationsSkeleton />
      <ListOfMembersSkeleton />
    </section>
  );
};

export default EmployerInfoSkeleton;
