import { Suspense } from 'react';
//skeletons
import AddCrewToPoolInfoSkeleton from 'system/skeletons/hireProfile/components/AddCrewToPoolSkeleton/AddCrewToPoolInfoSkeleton';
import EmployerAddUserToCrewPoolMainSkeleton from 'system/skeletons/hireProfile/components/AddCrewToPoolSkeleton/EmployerAddUserToCrewPoolMainSkeleton';
//components
import EmployerAddUserToCrewPoolMain from './EmployerAddUserToPoolMain';
import EmployerAddUserToCrewPoolInfo from './EmployerAddUserToCrewPoolInfo';
//types
import { EmployerAddUserToCrewPoolMainProps } from '../../../types';

const EmployerAddUserToCrewPoolCommon = ({ userId, onHandleClose }: EmployerAddUserToCrewPoolMainProps) => {
  return (
    <section className="relative flex w-full text-darkBlue pt-6 pb-14">
      <Suspense fallback={<AddCrewToPoolInfoSkeleton />}>
        <EmployerAddUserToCrewPoolInfo userId={userId} />
      </Suspense>
      <Suspense fallback={<EmployerAddUserToCrewPoolMainSkeleton />}>
        <EmployerAddUserToCrewPoolMain userId={userId} onHandleClose={onHandleClose} />
      </Suspense>
    </section>
  );
};

export default EmployerAddUserToCrewPoolCommon;
