import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// routing
import { RoutePaths } from 'app/routing';

export const useHandleGoBackFormVacancy = (prevPathname: string) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const goBack = useCallback(() => {
    navigate(`${RoutePaths.ProfileOrganizationBase}/${prevPathname}`, {
      state,
      replace: true,
    });
  }, [navigate, state, prevPathname]);

  return {
    goBack,
  };
};
