/**
 * @generated SignedSource<<7ae91a75c59c9b11d46a9dc7e791eba5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ServiceFragmentsVessel$data = {
  readonly 'cnISO': string | null;
  readonly 'countryName': string | null;
  readonly 'grossTonnage': number | null;
  readonly 'id': string;
  readonly 'image': string | null;
  readonly 'imo': number;
  readonly 'kind': {
    readonly id: string;
    readonly name: string;
    readonly perma: string;
  } | null;
  readonly 'length': number | null;
  readonly 'name': string;
  readonly 'operatorName': string | null;
  readonly ' $fragmentType': 'ServiceFragmentsVessel';
};
export type ServiceFragmentsVessel$key = {
  readonly ' $data'?: ServiceFragmentsVessel$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragmentsVessel'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    };
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'ServiceFragmentsVessel',
    selections: [
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'imo',
        storageKey: null,
      },
      v1 /*: any*/,
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'grossTonnage',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'operatorName',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'VesselKind',
        kind: 'LinkedField',
        name: 'kind',
        plural: false,
        selections: [
          v0 /*: any*/,
          v1 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'perma',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'length',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'image',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'cnISO',
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'countryName',
        storageKey: null,
      },
    ],
    type: 'Vessel',
    abstractKey: null,
  };
})();

(node as any).hash = '2fe16e1a5aa5f40e8cd278d9585790a3';

export default node;
