// packages
import { useIntl } from 'react-intl';
// components
import { HandShakeIcon } from 'icons/HandShakeIcon';
import AppliedAlertMessageItem from './AppliedAlertMessageItem';
// hooks
import { useReceiveCrewProfile } from 'hooks/useReceiveCrewProfile';
import useGetAppliedHireProfileVacancies from 'hooks/hireProfileHooks/useGetAppliedHireProfileVacancies';
import useGetRestrictedProfileLastName from 'hooks/ProfileDataHooks/useGetRestrictedProfileLastName';

const AppliedAlertMessageWrapper = () => {
  const intl = useIntl();
  const uniSpace = '\u00a0';
  const appliedVacancies = useGetAppliedHireProfileVacancies();
  const { profileInfo } = useReceiveCrewProfile();
  const lastName = useGetRestrictedProfileLastName(profileInfo?.lastName);

  if (!appliedVacancies?.length) {
    return null;
  }

  return (
    <div className="flex w-full items-center rounded-[15px] bg-green-100 border border-green-200 min-h-[70px] py-[13px] px-[24px] text-xs leading-5 mb-8">
      <div className="pr-[18px] self-center">
        <HandShakeIcon />
      </div>
      <div className="flex flex-col w-full">
        <span className="text-darkBlue font-semibold">
          {profileInfo?.firstName}
          {uniSpace}
          {lastName}
          {uniSpace}
          {intl.formatMessage({ id: 'profile_employer_applied_with_crew' })}
        </span>

        <div className="flex w-full flex-wrap">
          <span className="text-specialGray-075">
            {appliedVacancies.map((appliedVacancyItem, i) => {
              return <AppliedAlertMessageItem index={i} key={appliedVacancyItem?.node?.id} vacancy={appliedVacancyItem?.node} />;
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AppliedAlertMessageWrapper;
