// packages
import React from 'react';
import Skeleton from 'react-loading-skeleton';
// components
import TextInputSkeleton from '../components/TextInputSkeleton';
import PhoneNumberSkeleton from '../components/PhoneNumberSkeleton';
// hooks
import { useBreakpoints } from 'hooks/useBreakpoints';

const BasicInfoFormSkeleton: React.FC = () => {
  const { sm } = useBreakpoints();

  return (
    <div className="w-full space-y-6">
      <div className="flex sm:items-end flex-col sm:flex-row items-center">
        <Skeleton width={160} height={160} borderRadius="0.375rem" />
        <Skeleton width={160} height={38} borderRadius="0.375rem" className="sm:ml-5 mt-5 sm:mt-0" />
      </div>
      <TextInputSkeleton widthTitle={80} borderRadius="0.375rem" heightInput={sm ? 34 : 38} />
      <TextInputSkeleton widthTitle={80} borderRadius="0.375rem" heightInput={sm ? 34 : 38} />
      <div className="flex w-full flex-col">
        <Skeleton width={90} />
        <div className="flex justify-between w-full">
          <div className="w-1/3 mr-4">
            <Skeleton width="100%" height={sm ? 34 : 38} />
          </div>
          <div className="w-1/3 mr-4">
            <Skeleton width="100%" height={sm ? 34 : 38} />
          </div>
          <div className="w-1/3">
            <Skeleton width="100%" height={sm ? 34 : 38} />
          </div>
        </div>
      </div>
      <TextInputSkeleton widthTitle={100} borderRadius="0.375rem" heightInput={sm ? 34 : 38} />
      <TextInputSkeleton widthTitle={150} borderRadius="0.375rem" heightInput={sm ? 34 : 38} />
      <PhoneNumberSkeleton />
      <TextInputSkeleton widthTitle={110} borderRadius="0.375rem" heightInput={sm ? 50 : 58} />
      <Skeleton width={`${sm ? '100%' : '75%'}`} className="mt-2" />
      <div className="flex space-x-2">
        <Skeleton width={16} height={16} />
        <Skeleton width={425} height={16} />
      </div>
      {sm && <Skeleton width="75%" />}
      <div className="flex justify-end w-full">
        <Skeleton width={100} height={38} borderRadius="0.5rem" className="mr-5" />
        <Skeleton width={130} height={38} borderRadius="0.5rem" />
      </div>
    </div>
  );
};

export default BasicInfoFormSkeleton;
