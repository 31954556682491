// packages
import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

const TabsSkeleton: FC = () => {
  return (
    <div className="w-full bg-specialGray-004 flex items-center">
      <div className="flex w-full max-w-7xl h-full justify-between mx-auto">
        <div className="flex w-80 h-16 items-center justify-center">
          <div className="rounded-full 'bg-specialGray-05' w-6 h-6 flex justify-center items-center">
            <Skeleton width={24} height={24} circle />
          </div>
          <div className="ml-3 text-darkBlue relative flex items-center">
            <Skeleton width={175} height={16} />
          </div>
        </div>
        <div className="flex w-80 h-16 items-center justify-center">
          <div className="rounded-full 'bg-specialGray-05' w-6 h-6 flex justify-center items-center">
            <Skeleton width={24} height={24} circle />
          </div>
          <div className="ml-3 text-darkBlue relative flex items-center">
            <Skeleton width={240} height={16} />
          </div>
        </div>
        <div className="flex w-80 h-16 items-center justify-center">
          <div className="rounded-full 'bg-specialGray-05' w-6 h-6 flex justify-center items-center">
            <Skeleton width={24} height={24} circle />
          </div>
          <div className="ml-3 text-darkBlue relative flex items-center">
            <Skeleton width={160} height={16} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabsSkeleton;
