// packages
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// components
import { Modal } from 'system/Modal';
import Overview from 'app/Profile/Overview/Overview';
import AssumeProfileForm from 'system/Admin/AssumeProfile/AssumeProfileForm';

const AssumeProfileModal = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleCloseAssumeModal = useCallback(() => {
    navigate(state?.prevPath, { replace: true });
  }, [navigate, state?.prevPath]);

  return (
    <>
      <Modal
        show={state?.isShowAssumeModal || false}
        onClose={handleCloseAssumeModal}
        title={{ id: 'assume_profile_modal_title' }}
        description={{ id: 'assume_profile_modal_description' }}
      >
        <AssumeProfileForm />
      </Modal>
      <Overview />
    </>
  );
};

export default AssumeProfileModal;
