import Skeleton from 'react-loading-skeleton';

const SettingsOptionToggleSkeleton = ({ titleClassName, subtitleClassName }: { titleClassName: string; subtitleClassName: string }) => {
  return (
    <div className="flex items-center">
      <Skeleton width={51} height={31} borderRadius="1rem" />
      <div className="flex w-full flex-col ml-3">
        <Skeleton height={14} containerClassName={`flex w-full h-5 ${titleClassName}`} />
        <Skeleton height={14} containerClassName={`flex w-full h-5 ${subtitleClassName}`} />
      </div>
    </div>
  );
};

export default SettingsOptionToggleSkeleton;
