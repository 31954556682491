import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
//components
import NavbarSearchDropdownMembersSkeletonItem from './NavbarSearchDropdownMembersSkeletonItem';

const NavbarSearchDropdownMembersSkeleton = () => {
  const [items] = useState<string[]>(['001', '002', '003']);

  return (
    <>
      <div className="flex justify-between items-center border-b h-[52px] px-3 w-full text-xs sm:text-sm">
        <div className="flex space-x-2">
          <Skeleton width={55} height={14} />
          <Skeleton width={20} height={14} />
        </div>
        <div className="flex space-x-1">
          <Skeleton width={25} height={14} />
          <Skeleton width={25} height={14} />
        </div>
      </div>
      {items.map(mockItem => (
        <NavbarSearchDropdownMembersSkeletonItem key={mockItem} />
      ))}
    </>
  );
};

export default NavbarSearchDropdownMembersSkeleton;
