// packages
import { useMemo } from 'react';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useFragment } from 'react-relay';
// components
import LineSvg from 'icons/LineSvg';
import MobileListItemField from './MobileListItemField';
import ControlButtons from '../components/ControlButtons';
import AppliedCrewPortraits from '../components/AppliedCrewPortraits';
import JobOpeningStatus from 'system/JobOpeningStatus/JobOpeningStatus';
// models
import { VACANCY_STATUS } from 'models/enums';
// mocks
import { MOCK_LIST_OF_INDUSTRIES } from 'mocks/mockData';
// generated
import { VacancyFragmentsForDashboardFragment$key } from 'schemas/vacancy/__generated__/VacancyFragmentsForDashboardFragment.graphql';
// schemas
import { VACANCY_FRAGMENT_FOR_DASHBOARD } from 'schemas/vacancy/VacancyFragments';

const DashboardMobileListItem = ({ vacancyFragment, orderNumber }: { vacancyFragment: VacancyFragmentsForDashboardFragment$key; orderNumber: number }) => {
  const intl = useIntl();
  const vacancy = useFragment<VacancyFragmentsForDashboardFragment$key>(VACANCY_FRAGMENT_FOR_DASHBOARD, vacancyFragment || null);

  const mappingVacancyDuties = useMemo<string>(() => vacancy?.duties?.map(item => item.name)?.join(', ') || '', [vacancy?.duties]);
  const getVacancyIndustry = useMemo<string>(() => MOCK_LIST_OF_INDUSTRIES.find(industry => industry.id === vacancy?.industry)?.name || '', [vacancy?.industry]);

  return (
    <Link to={`job/edit/${vacancy?.id}`} className="flex flex-col border border-solid border-[#DADEE5] rounded-lg px-4 pt-2 space-y-2">
      <div className="flex text-darkBlue text-sm">
        <span className="text-specialGray-075">{intl.formatMessage({ id: 'hire_dashboard_table__header_order_number' })}</span>
        &nbsp;
        <span>{orderNumber}</span>
        <div className="ml-auto">
          <JobOpeningStatus status={(vacancy?.status as VACANCY_STATUS) || ''} />
        </div>
      </div>
      <MobileListItemField text={vacancy?.title as string} title={{ id: 'hire_information_position_title' }} />
      <MobileListItemField text={mappingVacancyDuties} title={{ id: 'hire_information_position' }} />
      {!!vacancy?.vesselKind?.name ? <MobileListItemField text={vacancy?.vesselKind?.name} title={{ id: 'hire_vessel_type_label' }} /> : null}
      {!!vacancy?.salaryFrom ? <MobileListItemField text={`${vacancy?.salaryFrom} - ${vacancy?.salaryTo}`} title={{ id: 'hire_information_salary_range' }} /> : null}
      {!!vacancy?.industry ? <MobileListItemField text={getVacancyIndustry} title={{ id: 'hire_information_industry' }} /> : null}
      <LineSvg classes="w-[calc(100%_+_32px)] relative -left-4" />
      <div className="flex flex-col w-full space-y-1">
        <span className="text-xs text-specialGray-075 uppercase">{intl.formatMessage({ id: 'hire_label_applied' })}</span>
        <div className="self-start">
          <AppliedCrewPortraits listOfAppliedCrew={vacancy?.applications} />
        </div>
      </div>
      {vacancy?.createdAt ? <MobileListItemField text={format(new Date(`${vacancy?.createdAt}`), 'dd LLL yyyy')} title={{ id: 'hire_job_description_posting_date' }} /> : null}
      <div className="flex flex-col">
        <LineSvg classes="w-[calc(100%_+_32px)] relative -left-4" />
        <div className="flex justify-between">
          <ControlButtons vacancy={vacancy} />
        </div>
      </div>
    </Link>
  );
};

export default DashboardMobileListItem;
