// packages
import { FC } from 'react';
import { useIntl } from 'react-intl';
//components
import EmptyFieldItem from '../components/EmptyFieldItem';

const VacancyDescription: FC<{ vacancyDescription: string | null }> = ({ vacancyDescription }) => {
  const intl = useIntl();

  return (
    <div className="flex flex-col">
      <h2 id="sea-service-title" className="flex-1 min-w-0 text-lg sm:text-2xl leading-6 font-semibold text-specialGray-1 mb-2">
        {intl.formatMessage({ id: 'hire_description_job_description_section' })}
      </h2>
      <p data-test-id="vacancy-description" className="text-specialGray-075 whitespace-pre-wrap">
        {vacancyDescription || <EmptyFieldItem />}
      </p>
    </div>
  );
};

export default VacancyDescription;
