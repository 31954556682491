import { useIntl } from 'react-intl';
import { useFragment, useMutation } from 'react-relay';
import { useNavigate } from 'react-router-dom';
// components
import SpinnerIcon from 'icons/SpinnerIcon';
import CustomButton from 'system/Buttons/CustomButton';
import CommercialAvatar from 'app/Avatars/CommercialAvatar/CommercialAvatar';
// schemas
import { ACCEPT_ORGANIZATION_MEMBER_INVITATION } from 'schemas/profile/ProfileMutations';
import { PROFILE_ORGANIZATION_MEMBER_INVITATIONS_FRAGMENT } from 'schemas/profile/ProfileFragments';
// generated
import { ProfileFragmentsOrganizationMemberInvitation$key } from 'schemas/profile/__generated__/ProfileFragmentsOrganizationMemberInvitation.graphql';
import { ProfileMutationsAcceptOrganizationMemberInviteMutation } from 'schemas/profile/__generated__/ProfileMutationsAcceptOrganizationMemberInviteMutation.graphql';
// routing
import { RoutePaths } from 'app/routing';
import { organizationAcceptInviteUpdater } from 'app/Profile/ProfileOrganizationInvites/updaters';

const OrganizationInviteItem = ({ data }: { data: ProfileFragmentsOrganizationMemberInvitation$key }) => {
  const intl = useIntl();
  const navigation = useNavigate();
  const invitationData = useFragment<ProfileFragmentsOrganizationMemberInvitation$key>(PROFILE_ORGANIZATION_MEMBER_INVITATIONS_FRAGMENT, data);
  const [acceptMutation, acceptLoading] = useMutation<ProfileMutationsAcceptOrganizationMemberInviteMutation>(ACCEPT_ORGANIZATION_MEMBER_INVITATION);

  const onHandleAcceptInvite = () => {
    acceptMutation({
      variables: {
        orgMemberId: invitationData.id,
      },
      updater: organizationAcceptInviteUpdater,
      onCompleted: resp => {
        navigation(`${RoutePaths.ProfileOrganizationBase}/${resp.acceptOrganizationMemberInvitation.organization.id}`);
      },
    });
  };

  return (
    <div className="flex w-full border border-specialGray-012 rounded-xl py-2 px-3 text-darkBlue">
      <CommercialAvatar size="w-14 h-14" avatarUrl={invitationData.organization?.avatarUrl} />
      <div className="flex flex-col">
        <span className="font-medium ml-2 text-sm text-specialGray-075">
          {intl.formatMessage({ id: 'hire_organization_member_name' }, { value: invitationData.organization.name })}
        </span>
      </div>

      <CustomButton
        onClick={onHandleAcceptInvite}
        className="flex border border-gray-300 text-specialGray-075 rounded-xl px-3 text-sm h-fit py-2 self-end ml-auto self-center items-center"
      >
        {intl.formatMessage({ id: 'hire_button_label_accept_invite' })} {acceptLoading && <SpinnerIcon additionalClasses="ml-2 w-4 h-4" />}
      </CustomButton>
    </div>
  );
};

export default OrganizationInviteItem;
