import { Environment, Network, RecordSource, Store, RequestParameters, Variables, Observable } from 'relay-runtime';
import fetch from 'isomorphic-fetch';
import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import { createClient } from 'graphql-ws';
import { GraphQLError } from 'graphql/error';

const wsClient = createClient({
  url: process.env.REACT_APP_RELAY_WS_ENDPOINT || '',
  connectionParams: async () => {
    const sess = await Auth.currentSession();
    const auth_token = 'Bearer ' + sess.getIdToken().getJwtToken();

    return {
      type: 'connection_init',
      Authorization: auth_token,
    };
  },
});

// fetchQuery implements how all Relay's graphql requests will be delivered to the server. Unlike other
// libraries, Relay requires you to bring this yourself.
async function fetchQuery(operation: RequestParameters, variables: Variables) {
  const profileToken = new URLSearchParams(window.location.search).get('t');
  const mainEndpoint: string = process.env.REACT_APP_RELAY_ENDPOINT || 'http://localhost:8080/query/';

  // http headers are different if the user is logged in
  const headers: Record<string, string> = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'x-request-id': uuidv4(),
  };

  // we get the current user session. This is async because the token might need to be
  // refreshed.
  try {
    const sess = await Auth.currentSession();
    headers['Authorization'] = 'Bearer ' + sess.getIdToken().getJwtToken();
  } catch (e) {
    // If the user is not authorized, we throw another token in headers['Authorization']
    if (profileToken) {
      headers['Authorization'] = 'Basic ' + profileToken;
    }
    // Any errors thrown here by amplify can get really cryptic so we log it in the console instead
    console.warn('failed to get Auth session:', e);
  }

  const resp = await (
    await fetch(mainEndpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
    })
  ).json();

  if (resp.errors) {
    console.warn('Backend error', resp.errors);
  }
  if (resp.errors && resp.errors[0]?.extensions?.code === 'NOT_FOUND') {
    throw new Error('NOT_FOUND');
  }

  return !isEmpty(resp.errors) && resp.errors[0]?.extensions?.code !== 'NOT_FOUND'
    ? {
        errors: resp.errors.map((error: { message: string }) => {
          return new Error(`${error.message} \nx-request-id:${headers['x-request-id']}`);
        }),
        data: null,
      }
    : resp;
}

function stringifyIds<T extends Record<string, any>>(data: T): T {
  for (const node in data) {
    if (node === 'id') {
      data[node] = data[node].toString();
      continue;
    }
    if (typeof data[node] === 'object') {
      stringifyIds(data[node]);
    }
  }
  return data;
}

function subscribe(operation: RequestParameters, variables: Variables): Observable<any> {
  return Observable.create(sink => {
    if (!operation.text) {
      return sink.error(new Error('Operation text cannot be empty'));
    }
    return wsClient.subscribe(
      {
        operationName: operation.name,
        query: operation.text,
        variables,
      },
      {
        ...sink,
        next: data => sink.next(stringifyIds(data)),
        error: err => {
          if (err instanceof Error) {
            return sink.error(err);
          }

          if (err instanceof CloseEvent) {
            return sink.error(
              // reason will be available on clean closes
              new Error(`Socket closed with event ${err.code} ${err.reason || ''}`),
            );
          }

          return sink.error(new Error((err as GraphQLError[]).map(({ message }) => message).join(', ')));
        },
      },
    );
  });
}

// export the Relay environment for the provider to use
export default new Environment({
  network: Network.create(fetchQuery, subscribe),
  store: new Store(new RecordSource(), {
    gcReleaseBufferSize: 10,
  }),
});
