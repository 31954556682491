const ProfileProgressBarIcon5 = () => {
  return (
    <svg width="205" height="102" viewBox="0 0 205 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M198.959 95.9797C198.959 46.3807 155.877 6.17285 102.732 6.17285C49.5883 6.17285 6.50647 46.3807 6.50647 95.9797"
        stroke="#F1F2F4"
        strokeWidth="11.664"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.50647 95.9794C6.50647 76.1995 13.3582 57.9131 24.9673 43.0748C36.9038 27.8181 53.8698 16.2067 73.5889 10.3652C92.4757 4.7702 112.989 4.7702 131.876 10.3652C150.688 15.9379 166.994 26.7617 178.818 40.992"
        stroke="#F1F2F4"
        strokeWidth="11.664"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.50647 95.9794C6.50647 76.1995 13.3582 57.9131 24.9673 43.0748C36.9038 27.8181 53.8698 16.2067 73.5889 10.3652C92.4757 4.7702 112.989 4.7702 131.876 10.3652C150.688 15.9379 166.994 26.7617 178.818 40.992"
        stroke="url(#paint0_linear_6750_59408)"
        strokeWidth="11.664"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient id="paint0_linear_6750_59408" x1="189.812" y1="43.0745" x2="42.9511" y2="147.7" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7EE065" />
          <stop offset="1" stopColor="#BFE42C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProfileProgressBarIcon5;
