// components
import CypressWrapper from './components/CypressWrapper';
// routes
import { ChildrenRoutes } from '../auth/childrenRoutes/ChildrenRoutes';

export const CypressRoute = {
  path: '/cypress-crew/*',
  Component: CypressWrapper,
  children: ChildrenRoutes,
};
