import React, { useEffect, useState } from 'react';
//models
import { EmptyCompanyIcon } from 'icons/EmptyCompanyIcon';
import { AvatarHireAccountProps } from 'models/modelsOfComponents';

export const CommercialAvatar: React.FC<AvatarHireAccountProps> = ({ size, avatarUrl, imgMessage, updateAvatar }) => {
  const [avatarURL, setAvatarURL] = useState<string | null | undefined>(avatarUrl);

  useEffect(() => {
    setAvatarURL(avatarUrl);
  }, [avatarUrl]);

  useEffect(() => {
    if (updateAvatar) {
      setAvatarURL(updateAvatar);
    }
  }, [updateAvatar]);

  return (
    <div className="flex">
      <div className={`flex ${size} justify-center items-center bg-avatar-bg rounded-md overflow-hidden`}>
        {avatarURL ? <img data-test-id="commercial-avatar" className="block w-auto mx-auto rounded-md" alt={imgMessage} src={avatarURL} key={avatarURL} /> : <EmptyCompanyIcon />}
      </div>
    </div>
  );
};
export default CommercialAvatar;
