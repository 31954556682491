// packages
import { useIntl } from 'react-intl';
// components
import FieldWrapper from './components/FieldWrapper';
import ChangePasswordForm from './components/ChangePasswordForm';
import CheckboxesAccountSettings from './components/CheckboxesAccountSettings/CheckboxesAccountSettings';

const SettingRightSide = () => {
  const intl = useIntl();

  return (
    <div className="flex flex-col pl-4 md:pl-8 sm:pt-[49px] pt-7 pr-4 md:pr-8 lg:pr-16">
      <section className="sm:mb-9 mb-5">
        <h1 className="text-darkBlue font-semibold text-2xl leading-9">{intl.formatMessage({ id: 'label_account_settings' })}</h1>
        <span className="text-specialGray-075 text-sm">{intl.formatMessage({ id: 'profile_settings_description' })}</span>
      </section>
      <div className="flex flex-col sm:space-y-[58px] space-y-8">
        <FieldWrapper title={{ id: 'label_change_password' }} description={{ id: 'change_password_description' }}>
          <ChangePasswordForm />
        </FieldWrapper>
        <CheckboxesAccountSettings />
      </div>
    </div>
  );
};

export default SettingRightSide;
