// packages
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useFragment } from 'react-relay';
import { formatDistanceStrict } from 'date-fns';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
//schemas
import { ServiceFragments$key } from 'schemas/services/__generated__/ServiceFragments.graphql';
import { GET_ONE_DUTY, SERVICE_FRAGMENT, VESSEL_FRAGMENT } from 'schemas/services/ServiceFragments';
import { ServiceFragmentsVessel$key } from 'schemas/services/__generated__/ServiceFragmentsVessel.graphql';
import { ServiceFragmentsOneDuty$key } from 'schemas/services/__generated__/ServiceFragmentsOneDuty.graphql';
//components
import PopoverHelper from 'system/PopoverHelper/PopoverHelper';
import CrewsSeaServiceInfoEvItem from './CrewsSeaServiceInfoEVItem';
import CrewsSeaServiceEVWrapperView from '../../components/CrewsSeaServiceEVWrapperView';
// routes
import { RoutePaths } from 'app/routing';
//models
import { FILTER_TYPE } from 'models/enums';

export const CrewsSeaServiceEVItem = ({ service }: { service: ServiceFragments$key }) => {
  const intl = useIntl();
  const { seaServiceId } = useParams();
  const navigate = useNavigate();

  const dataOfSeaService = useFragment<ServiceFragments$key>(SERVICE_FRAGMENT, service);
  const dataOfDuty = useFragment<ServiceFragmentsOneDuty$key>(GET_ONE_DUTY, dataOfSeaService.duty);
  const vessel = useFragment<ServiceFragmentsVessel$key>(VESSEL_FRAGMENT, dataOfSeaService.vessel);
  const avatarUrl = vessel.image;
  const timeFrom = dataOfSeaService.start ? dataOfSeaService.start.toString() : null;
  const timeTo = dataOfSeaService.end ? dataOfSeaService.end.toString() : null;
  const vesselName = vessel.name;
  const dutyName = dataOfDuty.name;
  const endDateOfSeaServiceDuration = dataOfSeaService.end ? new Date(dataOfSeaService.end.toString()) : new Date();
  const rotations = dataOfSeaService.rotations.toString();
  const operatorName = vessel.operatorName;
  const vesselLength = !!vessel.length && vessel.length.toString();
  const grossTonnage = !!vessel.grossTonnage && vessel.grossTonnage.toString();
  const vesselKind = vessel.kind && vessel.kind.name;
  const description = dataOfSeaService.description;

  const handleOpenSeaServiceModal = useCallback(() => {
    navigate(`${RoutePaths.ShowSeaServiceDetails}/${dataOfSeaService.id}`);
  }, [dataOfSeaService.id, navigate]);

  return (
    <div onClick={handleOpenSeaServiceModal} className="flex cursor-pointer w-full">
      {/*Sea service modal component*/}
      {!!seaServiceId && <Outlet context={dataOfSeaService} />}

      <CrewsSeaServiceEVWrapperView
        additionalClassName="hover:bg-specialGray-005"
        timeTo={timeTo}
        timeFrom={timeFrom}
        vesselName={vesselName}
        avatarUrl={avatarUrl}
        dutyName={dutyName}
      >
        <div className="flex w-full flex-col">
          <div className="flex w-full mt-4 flex-wrap">
            <CrewsSeaServiceInfoEvItem description={vessel.countryName || ''} iso={vessel.cnISO} title={{ id: 'hire_location_label' }} />
            <CrewsSeaServiceInfoEvItem description={rotations} title={{ id: 'sea_service_modal_more_rotations' }} />
            <CrewsSeaServiceInfoEvItem
              description={formatDistanceStrict(endDateOfSeaServiceDuration, new Date(`${dataOfSeaService.start}`))}
              title={{ id: 'sea_service_duration' }}
            />
            {operatorName && <CrewsSeaServiceInfoEvItem description={operatorName} title={{ id: 'sea_service_operator' }} />}
            {vesselLength && <CrewsSeaServiceInfoEvItem description={vesselLength} title={{ id: 'sea_service_length_item' }} />}
            {grossTonnage && <CrewsSeaServiceInfoEvItem description={grossTonnage} title={{ id: 'sea_service_gross_tonnage' }} />}
            {vesselKind && <CrewsSeaServiceInfoEvItem description={vesselKind} title={{ id: 'hire_vessel_type_label' }} />}
            {vessel?.imo && (
              <Link
                className="text-center w-full mt-2 md:mt-0 md:w-fit md:absolute md:-right-10 py-1 px-2 text-xs text-specialGray-075 border border-specialGray-012 rounded-[10px] hover:bg-gray-50 md:top-[35%] xl:top-[40%] md:transform md:-translate-x-1/2 md:-translate-y-1/2"
                onClick={e => {
                  e.stopPropagation();
                }}
                to={`${RoutePaths.SearchProfileBase}?${FILTER_TYPE.CREW_VESSEL_IMO}=${vessel.imo}`}
                target="_blank"
              >
                {intl.formatMessage({ id: 'sea_service_find_shipmates' })}
              </Link>
            )}
          </div>
          {description ? (
            <div className="flex text-xs mr-[18px] whitespace-nowrap items-center leading-5">
              <span className="text-specialGray-075 mr-1.5">{intl.formatMessage({ id: 'sea_service_description' })}</span>
              <PopoverHelper
                classes="whitespace-pre-wrap w-[200px]"
                position="bottom-6 left-1/2 -translate-x-1/2"
                positionArrow="left-1/2 sm:-translate-x-1/2 rotate-45 -bottom-0.5"
                element={<span className="truncate">{description}</span>}
                darkTheme
                widthContainer="w-fit w-[200px] sm:w-[300px]"
              >
                <span className="flex flex-wrap">{description}</span>
              </PopoverHelper>
            </div>
          ) : null}
        </div>
      </CrewsSeaServiceEVWrapperView>
    </div>
  );
};

export default CrewsSeaServiceEVItem;
