// packages
import { useParams } from 'react-router-dom';
import { useFragment, useLazyLoadQuery } from 'react-relay';
//components
import ProfileCrewInfo from 'app/ProfileCrew/components/ProfileCrewInfo';
import OverviewComponent from 'system/OverviewComponent/OverviewComponent';
//generated
import { ProfileQueriesCrewProfileQuery } from 'schemas/profile/__generated__/ProfileQueriesCrewProfileQuery.graphql';
import { ProfileFragmentsCrewProfile$data, ProfileFragmentsCrewProfile$key } from 'schemas/profile/__generated__/ProfileFragmentsCrewProfile.graphql';
//models
import { CREW_PROFILE_QUERY } from 'schemas/profile/ProfileQueries';
import { CREW_PROFILE_FRAGMENT } from 'schemas/profile/ProfileFragments';

const ProfileCrew = () => {
  const { id } = useParams();
  const profileQueryRef = useLazyLoadQuery<ProfileQueriesCrewProfileQuery>(CREW_PROFILE_QUERY, { id: id! });
  const profileData = useFragment<ProfileFragmentsCrewProfile$key>(CREW_PROFILE_FRAGMENT, profileQueryRef.node);

  return (
    <main className="w-full">
      <div className="w-full max-w-7xl">
        {profileData && <ProfileCrewInfo profile={profileData} fullShareProfile={false} />}
        <OverviewComponent profileData={profileData as ProfileFragmentsCrewProfile$data} />
      </div>
    </main>
  );
};

export default ProfileCrew;
