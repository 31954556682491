// packages
import { useRecoilValue } from 'recoil';
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFragment, useRefetchableFragment } from 'react-relay';
// context
import { useSkeletonContext } from 'Context/StorybookContext';
// schemas
import { PROFILE_FRAGMENT, PROFILE_INFO_FRAGMENT } from 'schemas/profile/ProfileFragments';
// components
import { ProfileTabs } from './ProfileTabs';
import { LazyLoader } from 'system/skeletons/wrapper/LazyLoader';
import MainTabSkeleton from 'system/skeletons/profileTabs/MainTabSkeleton';
import { ProfileBasicInfo } from 'app/Profile/ProfileBasicInfo/ProfileBasicInfo';
import ProfileBasicInfoSkeleton from 'system/skeletons/profile/ProfileSkeleton/components/ProfileBasicInfoSkeleton';
// generated
import { ProfileFragments$key } from 'schemas/profile/__generated__/ProfileFragments.graphql';
import { ProfileQueriesRootRefreshQuery } from 'schemas/profile/__generated__/ProfileQueriesRootRefreshQuery.graphql';
import { ProfileFragments_info$data, ProfileFragments_info$key } from 'schemas/profile/__generated__/ProfileFragments_info.graphql';
// models
import { PLATFORM_GROUP } from 'models/enums';
// routing
import { RoutePaths } from 'app/routing';
// hooks
import { useAuth } from 'authentication';
import { useGetHireProfile } from 'hooks/hireProfileHooks';
import { assumeProfileAtom } from '../../recoil/Admin/atoms/assumeProfileAtom';

// Profile component can render any profile include the user's own. It requires a pre-loaded profile query
// so the component can start rendering while it fetches the actual profile.
// props "firstTabInFocus" is for focusing on the first tab called "overview", it only needs the storybook.
export const Profile = ({ setProfile }: { setProfile?: Dispatch<SetStateAction<ProfileFragments$key | null>> }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { identity } = useAuth();
  const { setIsSelfProfile } = useSkeletonContext();
  const { currentOrganizationData } = useGetHireProfile();
  const getIsAssumeProfile = useRecoilValue(assumeProfileAtom);

  const [selfDataFragment, setSelfDataFragment] = useState<ProfileFragments$key | null>(null);

  const [selfData] = useRefetchableFragment<ProfileQueriesRootRefreshQuery, ProfileFragments$key>(PROFILE_FRAGMENT, selfDataFragment || null);
  const selfInfoData = useFragment<ProfileFragments_info$key>(PROFILE_INFO_FRAGMENT, selfData?.info || null);

  useEffect(() => {
    if (identity?.platformGroup === PLATFORM_GROUP.HIRE && !currentOrganizationData && !state?.isNewHire && !getIsAssumeProfile) {
      navigate(RoutePaths.ProfileOrganizationBase, { replace: true });
    }
  }, [currentOrganizationData, identity?.platformGroup, navigate, state?.isNewHire, getIsAssumeProfile]);

  useEffect(() => {
    if (setProfile && selfDataFragment) {
      setProfile(selfDataFragment);
    }
  }, [setProfile, selfDataFragment]);

  useEffect(() => {
    setIsSelfProfile(true);
  }, [setIsSelfProfile]);

  return (
    <main className="w-full max-w-7xl">
      <Suspense
        fallback={
          <LazyLoader mainComponent={<ProfileBasicInfo setSelfData={setSelfDataFragment} />}>
            <ProfileBasicInfoSkeleton />
          </LazyLoader>
        }
      >
        <ProfileBasicInfo setSelfData={setSelfDataFragment} />
      </Suspense>
      <Suspense
        fallback={
          <LazyLoader mainComponent={<ProfileTabs profileInfoData={selfInfoData as ProfileFragments_info$data} />}>
            <MainTabSkeleton />
          </LazyLoader>
        }
      >
        <ProfileTabs profileInfoData={selfInfoData as ProfileFragments_info$data} />
      </Suspense>
    </main>
  );
};
