import { useIntl } from 'react-intl';
// types
import { OverviewTitleSectionProps } from 'models/modelsOfComponents';

const OverviewTitleSection = ({ title, count, countLabel }: OverviewTitleSectionProps) => {
  const intl = useIntl();
  return (
    <div className="md:flex md:items-center md:justify-between pt-5 pb-1">
      <div className="flex flex-col w-full">
        <h2 id="education-title" className="flex-1 min-w-0 text-lg leading-6 font-semibold text-specialGray-1">
          {intl.formatMessage(title)}
        </h2>

        <p className="flex justify-between w-full text-specialGray-075 text-sm mt-1">
          <span className="mr-3">{intl.formatMessage(countLabel, { amount: count })}</span>
        </p>
      </div>
    </div>
  );
};

export default OverviewTitleSection;
