import Skeleton from 'react-loading-skeleton';

const ProfileRatingSkeleton = () => {
  return (
    <div className="flex w-full xs:w-2/5 sm:w-[326px] md:w-[300px] lg:w-[326px] h-10 sm:h-[67px] justify-center md:justify-start lg:justify-center items-center border border-gray-200 rounded-[10px] sm:rounded-2xl sm:py-4 px-2 sm:flex-row flex-col">
      <Skeleton width={125} className="mr-3.5" />
      <div className="flex space-x-3 items-center">
        <Skeleton width={28} height={28} borderRadius="50%" />
        <Skeleton width={65} height={28} />
        <Skeleton width={18} height={18} borderRadius="50%" />
      </div>
    </div>
  );
};

export default ProfileRatingSkeleton;
