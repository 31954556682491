// packages
import { useMemo } from 'react';
// hooks
import useProfileFiltersQuery from 'hooks/searchFilter/useProfileFiltersQuery';
// components
import SelectedCommonFilterElements from './SelectedCommonFilterElements';
// models
import { FILTER_TYPE } from 'models/enums';
import { FilterDynamicProps } from 'models/commonTypes';
// mocks
import { MOCK_AVAILABLE_FOR_WORK } from 'mocks/mockData';

const SelectedProfileFilterElements = () => {
  const { allDuties, listOfCountries, allCertificates, allActivities, vesselsType, educationKind } = useProfileFiltersQuery(false);

  const allFiltersBySelectValue = useMemo<FilterDynamicProps>(
    () => ({
      [FILTER_TYPE.DUTIES]: allDuties,
      [FILTER_TYPE.PRIMARY_DUTIES]: allDuties,
      [FILTER_TYPE.MOST_RECENT_SERVICE_DUTY]: allDuties,
      [FILTER_TYPE.CERTIFICATES]: allCertificates,
      [FILTER_TYPE.VESSEL_TYPES]: vesselsType,
      [FILTER_TYPE.COUNTRIES]: listOfCountries,
      [FILTER_TYPE.EDUCATION]: educationKind,
      [FILTER_TYPE.ACTIVITIES]: allActivities,
      [FILTER_TYPE.AVAILABLE_FOR_WORK]: MOCK_AVAILABLE_FOR_WORK,
    }),
    [allDuties, listOfCountries, allCertificates, allActivities, vesselsType, educationKind],
  );

  return <SelectedCommonFilterElements allFiltersBySelectValue={allFiltersBySelectValue} margins="mb-4" />;
};

export default SelectedProfileFilterElements;
