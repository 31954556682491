// packages
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Skeleton from 'react-loading-skeleton';
// components
import ListItemSkeleton from './components/ListItemSkeleton';

const ListOfVacationsSkeleton = () => {
  const [emptyList] = useState<Array<string>>(new Array(4).fill(uuidv4()).map(() => uuidv4()));

  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex flex-col w-full">
        <div className="flex justify-between items-center w-full mb-2">
          <Skeleton width={150} height={18} />
          <Skeleton width={112} height={48} borderRadius={12} />
        </div>
        <div className="flex flex-col gap-y-6 text-center">
          {emptyList.map(item => (
            <ListItemSkeleton key={item} />
          ))}
        </div>
      </div>
      <Skeleton width={220} height={34} borderRadius={12} containerClassName="text-center" />
    </div>
  );
};

export default ListOfVacationsSkeleton;
