// packages
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { isEmpty, sortBy } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
// components
import getRatingIcon from 'icons/RatingIcons';
import ProfileRatingLayoutButton from './ProfileRatingLayoutButton';
import { PopoverRatingContentProps } from 'system/ProfileReting/types';
// mocks
import { GetPopoverProfileRatingButtons } from 'mocks/mockData';
// models
import { ProfileScoreDetailsType } from 'models/commonTypes';
//hooks
import useScoreFilteredNotFilledItems from 'hooks/useScoreFilteredNotFilledItems';
//routs
import { RoutesPathNames } from 'app/routing';

const PopoverRatingContent = ({ profileScoreDetails, getSummary, maximumSummary }: PopoverRatingContentProps) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { filteredNotFilledItems } = useScoreFilteredNotFilledItems();

  const scoreDetails = useMemo(() => {
    const sortedItemsWithComponent = sortBy(profileScoreDetails, 'order').map((element: ProfileScoreDetailsType) => {
      return { ...element, Component: ProfileRatingLayoutButton };
    });
    return filteredNotFilledItems(sortedItemsWithComponent);
  }, [filteredNotFilledItems, profileScoreDetails]);

  return (
    <>
      <div className="flex">
        <p className="text-sm mb-2">{intl.formatMessage({ id: 'rating_profile_score' })}</p>
      </div>
      <div className="flex sm:flex-row sm:items-top items-start flex-col mb-1">
        {getRatingIcon(getSummary)}
        <span className="ml-2 mt-0.5">
          <span className="font-semibold text-2xl">{getSummary}</span>
          <span className="text-specialGray-05">/{maximumSummary}</span>
          <span className="text-specialGray-05 ml-2 text-sm">
            {getSummary === maximumSummary ? intl.formatMessage({ id: 'rating_very_well' }) : intl.formatMessage({ id: isEmpty(scoreDetails) ? 'rating_bed' : 'rating_well' })}
          </span>
        </span>
      </div>

      <div className="my-2 text-sm flex">
        {getSummary === maximumSummary
          ? intl.formatMessage({ id: 'rating_profile_complete' })
          : intl.formatMessage({ id: isEmpty(scoreDetails) ? 'rating_profile_not_completion' : 'rating_profile_completion' })}
      </div>

      {!isEmpty(scoreDetails) ? (
        <div className="flex flex-col">
          {scoreDetails.map(({ name, filled, Component }: ProfileScoreDetailsType) => {
            return !filled && GetPopoverProfileRatingButtons(navigate, pathname)[name] && Component ? (
              <Component key={name} {...GetPopoverProfileRatingButtons(navigate, pathname)[name]} />
            ) : null;
          })}
        </div>
      ) : (
        <>
          {getSummary !== 100 && (
            <ProfileRatingLayoutButton
              label={{ id: 'rating_fill_form' }}
              onClick={() => {
                navigate(RoutesPathNames.editProfileInfo);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default PopoverRatingContent;
