import { useRecoilValue } from 'recoil';
import { MouseEvent, Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
// routing
import { RoutePaths } from 'app/routing';
// components
import ProfileProgressBarCommon from 'app/Profile/ProfileScore/components/ProfileCompletionScoreDetails/components/ProfileProgressBarCommon';
// events
import analyticEventsNames from 'eventNames';
// hooks
import { useGetProfileScore } from 'hooks/useGetProfileScore';
// recoil
import { selfProfileIdAtom } from 'recoil/Auth/atoms/selfProfileIdAtom';

const ProfileLowScoreBody = ({
  handleCreateApplication,
  setIsLowProfileScoreModal,
  vacancyId,
}: {
  handleCreateApplication: (event?: MouseEvent<HTMLButtonElement>) => void;
  setIsLowProfileScoreModal: Dispatch<SetStateAction<boolean>>;
  vacancyId: string;
}) => {
  const intl = useIntl();
  const { profileScoreSummary } = useGetProfileScore();
  const getProfileId = useRecoilValue(selfProfileIdAtom);

  const handleJobApplication = () => {
    handleCreateApplication();
    window.analytics?.track(analyticEventsNames.increaseProfileScoreModal.applyWithALowerScore, {
      vacancyId,
      profileId: getProfileId,
    });
    setIsLowProfileScoreModal(false);
  };

  const handleSendHowCanIDoBetterAnalytics = () => {
    window.analytics?.track(analyticEventsNames.increaseProfileScoreModal.iWantToIncreaseProfileScore, {
      vacancyId,
      profileId: getProfileId,
    });
  };

  return (
    <div className="flex flex-col lg:flex-row w-full h-full gap-y-3 pb-32 sm:pb-20 text-darkBlue">
      <div className="flex relative self-center h-[190px] min-w-[calc(100%_-_20px)] lg:w-1/2 lg:min-w-[50%] rounded-[30px] shadow-[0px_11px_29px_rgba(140,145,180,0.15)]">
        <ProfileProgressBarCommon score={profileScoreSummary} additionalClasses="mx-auto mt-8" />
      </div>
      <div className="flex flex-col sm:w-full lg:w-1/2 items-start justify-center pl-3">
        <h2 className="text-[26px] font-semibold">{intl.formatMessage({ id: 'rating_profile_ready_to_apply' })}</h2>
        <p className="text-sm text-specialGray-075 mt-3">{intl.formatMessage({ id: 'rating_profile_low_score_hire' })}</p>
      </div>
      <div className="w-full flex-col sm:flex-row flex gap-x-5 justify-start sm:items-center gap-y-2 sm:gap-y-0 bg-specialGray-002 absolute bottom-0 left-0 py-4 px-8">
        <button
          onClick={handleJobApplication}
          className="disabled:opacity-50 items-center px-3 py-2 border border-blue-100 shadow-sm text-sm leading-4 font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 hover:border-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {intl.formatMessage({ id: 'rating_profile_apply_with_a_lower_score' })}
        </button>

        <Link
          state={{ profileHowCanIDoBetterFlickering: true }}
          to={RoutePaths.ProfileBase}
          onClick={handleSendHowCanIDoBetterAnalytics}
          className="disabled:opacity-50 items-center px-3 py-2 border border-blue-600 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 hover:border-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {intl.formatMessage({ id: 'rating_profile_want_increase' })}
        </Link>
      </div>
    </div>
  );
};

export default ProfileLowScoreBody;
