// packages
import { useIntl } from 'react-intl';
// types
import { ItemFieldProps } from 'system/ListComponents/types';

const ItemField = ({ labelField, labelFieldAfter, contentField, contentFieldClasses, children }: ItemFieldProps) => {
  const intl = useIntl();
  return (
    <span className="text-sm text-specialGray-075 lg:mr-5 my-auto sm:my-0">
      {labelField && <span className="mr-1">{intl.formatMessage(labelField)}</span>}
      {contentField && <span className={`${contentFieldClasses}`}>{contentField}</span>}
      {labelFieldAfter && <span className="ml-1">{intl.formatMessage(labelFieldAfter)}</span>}
      {children}
    </span>
  );
};

export default ItemField;
