// packages
import { useFragment } from 'react-relay';
// components
import AppraisalsListItem from './components/AppraisalsListItem';
// types
import { AppraisalItemWithoutDocumentProps } from '../types';
// schemas
import { GET_APPRAISAL_WITHOUT_DOCUMENT_FRAGMENT } from 'schemas/appraisals/AppraisalFragments';
// generated
import { AppraisalFragmentsOneAppraisalWithoutDocument$key } from 'schemas/appraisals/__generated__/AppraisalFragmentsOneAppraisalWithoutDocument.graphql';

const AppraisalItemWithoutDocument = ({ appraisalFragment, ...props }: AppraisalItemWithoutDocumentProps) => {
  const appraisalData = useFragment<AppraisalFragmentsOneAppraisalWithoutDocument$key>(GET_APPRAISAL_WITHOUT_DOCUMENT_FRAGMENT, appraisalFragment);

  return <AppraisalsListItem appraisalData={appraisalData} {...props} />;
};

export default AppraisalItemWithoutDocument;
