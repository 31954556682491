/**
 * @generated SignedSource<<2c35cefdc480eed81f60b4adf957867e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ServiceQueriesOneQuery$variables = {
  id: string;
};
export type ServiceQueriesOneQuery$data = {
  readonly node: {
    readonly ' $fragmentSpreads': FragmentRefs<'ServiceFragments'>;
  } | null;
};
export type ServiceQueriesOneQuery = {
  response: ServiceQueriesOneQuery$data;
  variables: ServiceQueriesOneQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'perma',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ServiceQueriesOneQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'ServiceFragments',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ServiceQueriesOneQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: '__typename',
              storageKey: null,
            },
            v2 /*: any*/,
            {
              kind: 'InlineFragment',
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'start',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'end',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'rotations',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'description',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Vessel',
                  kind: 'LinkedField',
                  name: 'vessel',
                  plural: false,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'imo',
                      storageKey: null,
                    },
                    v3 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'grossTonnage',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'operatorName',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'VesselKind',
                      kind: 'LinkedField',
                      name: 'kind',
                      plural: false,
                      selections: [v2 /*: any*/, v3 /*: any*/, v4 /*: any*/],
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'length',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'image',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'cnISO',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'countryName',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Duty',
                  kind: 'LinkedField',
                  name: 'duty',
                  plural: false,
                  selections: [
                    v2 /*: any*/,
                    v3 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'alts',
                      storageKey: null,
                    },
                    v4 /*: any*/,
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'Activity',
                  kind: 'LinkedField',
                  name: 'activities',
                  plural: true,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'label',
                      storageKey: null,
                    },
                    v4 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'timesUsed',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              type: 'SeaService',
              abstractKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: 'ac07c8a5701f56b655d373726fae8d2b',
      id: null,
      metadata: {},
      name: 'ServiceQueriesOneQuery',
      operationKind: 'query',
      text: 'query ServiceQueriesOneQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ServiceFragments\n    id\n  }\n}\n\nfragment ServiceFragments on SeaService {\n  id\n  start\n  end\n  rotations\n  description\n  vessel {\n    ...ServiceFragmentsVessel\n    id\n  }\n  duty {\n    ...ServiceFragmentsOneDuty\n    id\n  }\n  activities {\n    ...ServiceFragmentsActivities\n    id\n  }\n}\n\nfragment ServiceFragmentsActivities on Activity {\n  id\n  label\n  perma\n  timesUsed\n}\n\nfragment ServiceFragmentsOneDuty on Duty {\n  id\n  name\n  alts\n  perma\n}\n\nfragment ServiceFragmentsVessel on Vessel {\n  id\n  imo\n  name\n  grossTonnage\n  operatorName\n  kind {\n    id\n    name\n    perma\n  }\n  length\n  image\n  cnISO\n  countryName\n}\n',
    },
  };
})();

(node as any).hash = 'eb6aede785b2bde3a3688d6bc20eb243';

export default node;
