import Skeleton from 'react-loading-skeleton';
import { PropsWithChildren } from 'react';

const SeaServiceCrewEvItemSkeleton = ({ children, titleWidth, descriptionWidth }: { titleWidth: number; descriptionWidth: number } & PropsWithChildren) => {
  return (
    <div className="flex text-xs mr-[18px] whitespace-nowrap items-center leading-5 items-center">
      <Skeleton className="mr-1.5" width={titleWidth} height={12} />
      {children ? children : <Skeleton className="mr-1.5" width={descriptionWidth} height={12} />}
    </div>
  );
};

export default SeaServiceCrewEvItemSkeleton;
