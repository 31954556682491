export const validationSearchCrewByVesselImo = (value: string) => {
  // safari browser does not support correctly "number" type of the input
  const digitsOnly = value.replace(/[^0-9]/g, '');
  if (digitsOnly.length > 7) {
    return;
  }
  const error = digitsOnly.length < 7 ? 'error_vessel_imo' : '';
  return {
    validatedValue: digitsOnly,
    error,
  };
};
