// routes
import { RoutePaths } from 'app/routing';
// components
import { CrewRegisterRoute } from './crew/crewRegisterRoute';
import { HireRegisterRoute } from './hire/hireRegisterRoute';
import ChoiceTypeOfAccount from 'auth/SignUpSteps/ChoiceTypeOfAccount';

export const RegisterRoute = [
  {
    path: RoutePaths.AuthRegister,
    Component: ChoiceTypeOfAccount,
  },
  CrewRegisterRoute,
  HireRegisterRoute,
];
