// packages
import { Suspense, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLazyLoadQuery } from 'react-relay';
// components
import LineSvg from 'icons/LineSvg';
import { CheckboxInputGroup } from 'system/CheckboxInputGroup';
import VesselInformation from '../VesselInformation';
import { DateInputGroup } from 'system/DateInputGroup';
import SelectDutyWithTacking from './components/SelectDutyWithTracking';
import VesselInformationSkeleton from 'system/skeletons/SignUpSteps/StepWithSeaServiceForm/components/VesselInformationSkeleton';
//  generated
import { ServiceQueriesFormGetAllDutiesQuery } from 'schemas/services/__generated__/ServiceQueriesFormGetAllDutiesQuery.graphql';
// schemas
import { GET_ALL_DUTIES_QUERY } from 'schemas/services/ServiceQueries';

const SeaServiceForm = () => {
  const intl = useIntl();
  const [isDisabledTo, setDisabledTo] = useState<boolean>(false);

  const allDuties = useLazyLoadQuery<ServiceQueriesFormGetAllDutiesQuery>(GET_ALL_DUTIES_QUERY, { skip: false });

  const handleTrackingWhenChooseDate = useCallback(
    (nameField: string) => (date: string) => {
      window.analytics?.track(`wui-signup-step-sea-service-with-chose-${nameField}-date`, {
        date,
      });
    },
    [],
  );

  const handleSetCheckbox = useCallback((value: boolean) => {
    setDisabledTo(value);
    window.analytics?.track('wui-signup-step-sea-service-with-chose-i-am-working-here', {
      IWorkInThisPosition: value,
    });
  }, []);

  return (
    <>
      <SelectDutyWithTacking allDuties={allDuties} nameField="dutyId" autoFocus />
      <div className="mt-3.5 space-y-[18px]">
        <DateInputGroup name="start" label={{ id: 'input_placeholder_from' }} trackingCallback={handleTrackingWhenChooseDate('from')} />
        <DateInputGroup name="end" label={{ id: 'input_placeholder_to' }} disabled={isDisabledTo} trackingCallback={handleTrackingWhenChooseDate('to')} />
        <div className="flex flex-row w-full [&_input]:h-4">
          <CheckboxInputGroup name="workingNow" value={isDisabledTo.toString()} label={{ id: 'service_form_working_now' }} onChange={handleSetCheckbox} />
        </div>
      </div>
      <LineSvg classes="mt-6 mb-7" />
      <h4 className="font-semibold text-darkBlue mb-6">{intl.formatMessage({ id: 'most_recent_sea_service_add_vesel_title' })}</h4>
      <div className="relative">
        <Suspense fallback={<VesselInformationSkeleton />}>
          <VesselInformation />
        </Suspense>
      </div>
    </>
  );
};

export default SeaServiceForm;
