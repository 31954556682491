// packages
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
// components
import ListOfJobOpeningsItemSkeleton from './components/ListOfJobOpeningsItemSkeleton';

const ListOfJobOpeningsSkeleton = () => {
  const [emptyList] = useState<Array<string>>(new Array(6).fill('').map(() => uuidv4()));
  return (
    <div className="flex flex-col space-y-6">
      {emptyList.map(item => (
        <ListOfJobOpeningsItemSkeleton key={item} />
      ))}
    </div>
  );
};

export default ListOfJobOpeningsSkeleton;
