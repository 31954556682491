/**
 * @generated SignedSource<<32c416176f8877e5fdddb5c14c2fd925>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CrewPoolOrderField = 'CREATED_AT' | 'NAME' | '%future added value';
export type OrderDirection = 'ASC' | 'DESC' | '%future added value';
export type CrewPoolOrder = {
  direction: OrderDirection;
  field?: CrewPoolOrderField | null;
};
export type ProfileQueriesGetSelectedPoolsForProfileQuery$variables = {
  after?: any | null;
  before?: any | null;
  first?: number | null;
  last?: number | null;
  orderBy?: CrewPoolOrder | null;
  orgId: string;
  profileId: string;
  skip: boolean;
};
export type ProfileQueriesGetSelectedPoolsForProfileQuery$data = {
  readonly crewPoolsByProfile?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null> | null;
  } | null;
};
export type ProfileQueriesGetSelectedPoolsForProfileQuery = {
  response: ProfileQueriesGetSelectedPoolsForProfileQuery$data;
  variables: ProfileQueriesGetSelectedPoolsForProfileQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'after',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'before',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'first',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'last',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'orderBy',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'orgId',
    },
    v6 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'profileId',
    },
    v7 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'skip',
    },
    v8 = {
      kind: 'Variable',
      name: 'orderBy',
      variableName: 'orderBy',
    },
    v9 = {
      kind: 'Variable',
      name: 'orgId',
      variableName: 'orgId',
    },
    v10 = {
      kind: 'Variable',
      name: 'profileId',
      variableName: 'profileId',
    },
    v11 = [
      {
        alias: null,
        args: null,
        concreteType: 'CrewPoolEdge',
        kind: 'LinkedField',
        name: 'edges',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'CrewPool',
            kind: 'LinkedField',
            name: 'node',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: '__typename',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'cursor',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: null,
        concreteType: 'PageInfo',
        kind: 'LinkedField',
        name: 'pageInfo',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'endCursor',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'hasNextPage',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'hasPreviousPage',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'startCursor',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
    v12 = [
      {
        kind: 'Variable',
        name: 'after',
        variableName: 'after',
      },
      {
        kind: 'Variable',
        name: 'before',
        variableName: 'before',
      },
      {
        kind: 'Variable',
        name: 'first',
        variableName: 'first',
      },
      {
        kind: 'Variable',
        name: 'last',
        variableName: 'last',
      },
      v8 /*: any*/,
      v9 /*: any*/,
      v10 /*: any*/,
    ];
  return {
    fragment: {
      argumentDefinitions: [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/, v5 /*: any*/, v6 /*: any*/, v7 /*: any*/],
      kind: 'Fragment',
      metadata: null,
      name: 'ProfileQueriesGetSelectedPoolsForProfileQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: 'crewPoolsByProfile',
              args: [v8 /*: any*/, v9 /*: any*/, v10 /*: any*/],
              concreteType: 'CrewPoolConnection',
              kind: 'LinkedField',
              name: '__crewPoolsByProfileList_crewPoolsByProfile_connection',
              plural: false,
              selections: v11 /*: any*/,
              storageKey: null,
            },
          ],
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [v6 /*: any*/, v5 /*: any*/, v0 /*: any*/, v2 /*: any*/, v1 /*: any*/, v3 /*: any*/, v4 /*: any*/, v7 /*: any*/],
      kind: 'Operation',
      name: 'ProfileQueriesGetSelectedPoolsForProfileQuery',
      selections: [
        {
          condition: 'skip',
          kind: 'Condition',
          passingValue: false,
          selections: [
            {
              alias: null,
              args: v12 /*: any*/,
              concreteType: 'CrewPoolConnection',
              kind: 'LinkedField',
              name: 'crewPoolsByProfile',
              plural: false,
              selections: v11 /*: any*/,
              storageKey: null,
            },
            {
              alias: null,
              args: v12 /*: any*/,
              filters: ['profileId', 'orgId', 'orderBy'],
              handle: 'connection',
              key: 'crewPoolsByProfileList_crewPoolsByProfile',
              kind: 'LinkedHandle',
              name: 'crewPoolsByProfile',
            },
          ],
        },
      ],
    },
    params: {
      cacheID: '726dc76254fd459156b7b8084fef226c',
      id: null,
      metadata: {
        connection: [
          {
            count: null,
            cursor: null,
            direction: 'bidirectional',
            path: ['crewPoolsByProfile'],
          },
        ],
      },
      name: 'ProfileQueriesGetSelectedPoolsForProfileQuery',
      operationKind: 'query',
      text: 'query ProfileQueriesGetSelectedPoolsForProfileQuery(\n  $profileId: ID!\n  $orgId: ID!\n  $after: Cursor\n  $first: Int\n  $before: Cursor\n  $last: Int\n  $orderBy: CrewPoolOrder\n  $skip: Boolean!\n) {\n  crewPoolsByProfile(profileId: $profileId, orgId: $orgId, after: $after, before: $before, orderBy: $orderBy, first: $first, last: $last) @skip(if: $skip) {\n    edges {\n      node {\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n      hasPreviousPage\n      startCursor\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '9a41eadb40181dd208dc191376731703';

export default node;
