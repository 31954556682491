// packages
import React, { useCallback, useEffect, useState } from 'react';
import { RefetchFnDynamic, useMutation } from 'react-relay';
// schemas
import { CREATE_APPLICATION } from 'schemas/Application/ApplicationMutations';
// generated
import { VacancyFragments$data, VacancyFragments$key } from 'schemas/vacancy/__generated__/VacancyFragments.graphql';
import { VacancyQueriesGetVacancyRefreshQuery } from 'schemas/vacancy/__generated__/VacancyQueriesGetVacancyRefreshQuery.graphql';
import { ApplicationMutationsCreateApplicationMutation } from 'schemas/Application/__generated__/ApplicationMutationsCreateApplicationMutation.graphql';
//mocks
import { VACANCY_ERRORS } from 'mocks/mockData';

export function useCreateVacancyApplication(vacancy: VacancyFragments$data, reFetch: RefetchFnDynamic<VacancyQueriesGetVacancyRefreshQuery, VacancyFragments$key>) {
  const [commitCreateApplication] = useMutation<ApplicationMutationsCreateApplicationMutation>(CREATE_APPLICATION);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (error && VACANCY_ERRORS[error]) {
      throw new Error(error);
    }
  }, [error]);

  return useCallback(
    (event?: React.MouseEvent<HTMLButtonElement>) => {
      event?.stopPropagation();

      commitCreateApplication({
        variables: {
          data: {
            vacancyId: vacancy?.id,
          },
        },
        onError: err => {
          const errorMessage = err.message.split('*')[1].split('\n')[0].trim();
          reFetch({}, { fetchPolicy: 'network-only' });
          setError(errorMessage);
        },
        onCompleted: () => {
          reFetch({}, { fetchPolicy: 'network-only' });
        },
      });
    },
    [vacancy?.id, commitCreateApplication, reFetch],
  );
}
