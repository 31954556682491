import { FILTER_TYPE } from 'models/enums';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useSearchArrayFromFiltersType() {
  const [searchUseSearch] = useSearchParams();

  return useCallback(
    (
      mainArrayOfFilters: Array<Array<string>>,
      currentFilterType: FILTER_TYPE,
      filterName: string,
      isArray?: boolean,
      replaceId?: string,
      replaceValueToBoolean?: { booleanFilterId: string; boolValue: boolean }[],
    ) => {
      const comparedArrayWithSameFilters: Array<string> = [];
      const currentFilterValue = searchUseSearch.get(currentFilterType);

      if (!currentFilterValue) {
        return;
      }

      const arrayOfFiltersId = currentFilterValue.split(',').filter(item => !!item);
      if (!arrayOfFiltersId) {
        return;
      }

      if (!isArray) {
        //when user write custom word
        const searchUrlByWord = [`${filterName}:'${currentFilterValue}'`];
        mainArrayOfFilters.push(searchUrlByWord);
        return;
      }

      arrayOfFiltersId.forEach(filterId => {
        if (replaceId) {
          comparedArrayWithSameFilters.push(filterName.replaceAll(replaceId, filterId));
          return;
        }
        if (replaceValueToBoolean && replaceValueToBoolean) {
          const booleanFilter = replaceValueToBoolean.find(({ booleanFilterId }) => booleanFilterId === filterId);
          comparedArrayWithSameFilters.push(`${filterName}:${booleanFilter?.boolValue}`);
        } else {
          comparedArrayWithSameFilters.push(`${filterName}:${filterId}`);
        }
      });
      mainArrayOfFilters.push(comparedArrayWithSameFilters);
    },
    [searchUseSearch],
  );
}
