import Skeleton from 'react-loading-skeleton';

const DateInputSkeleton = ({ labelWidth, wrapperClasses, inputHeight = 38 }: { labelWidth: number; wrapperClasses: string; inputHeight?: number }) => {
  return (
    <div className={wrapperClasses}>
      <Skeleton width={labelWidth} />
      <div className="flex justify-between w-full">
        <div className="w-1/3 mr-4">
          <Skeleton width="100%" height={inputHeight} />
        </div>
        <div className="w-1/3 mr-4">
          <Skeleton width="100%" height={inputHeight} />
        </div>
        <div className="w-1/3">
          <Skeleton width="100%" height={inputHeight} />
        </div>
      </div>
    </div>
  );
};

export default DateInputSkeleton;
