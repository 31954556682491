import SettingsCheckBox from 'system/SettingsCheckBox/SettingsCheckBox';
//generated
import { ProfileFragments$data } from 'schemas/profile/__generated__/ProfileFragments.graphql';
//hooks
import useGeneralSettings from 'hooks/accountSettings/useGeneralSettings';
//enums
import { PERSONAL_GENERAL_SETTINGS } from 'models/enums';

const AvailabilitySettings = ({ profileData }: { profileData: ProfileFragments$data | null }) => {
  const { loading, onChangeFieldGeneralSettings, generalSettings } = useGeneralSettings(profileData);

  return (
    <SettingsCheckBox
      label="general_settings_available_for_work"
      checked={generalSettings?.availableForWork ?? true}
      eventFunction={onChangeFieldGeneralSettings(PERSONAL_GENERAL_SETTINGS.availableForWork)}
      isLoading={loading}
      description="tips_general_settings_available_for_work"
    />
  );
};

export default AvailabilitySettings;
