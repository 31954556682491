// packages
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
// generated
import { ProfileFragmentsOrganizationMemberInfo$key } from 'schemas/profile/__generated__/ProfileFragmentsOrganizationMemberInfo.graphql';
import { ProfileQueriesGetOrganizationMembersConnectionQuery } from 'schemas/profile/__generated__/ProfileQueriesGetOrganizationMembersConnectionQuery.graphql';
// schemas
import { GET_ALL_HIRE_ORGANIZATION_MEMBERS } from 'schemas/profile/ProfileQueries';
// components
import { Button } from 'system/Button';
import SpinnerIcon from 'icons/SpinnerIcon';
import NoTeamMembers from './components/NoTeamMembers';
import MemberComponent from './components/MemberComponent';

const ListOfMembers = () => {
  const intl = useIntl();
  const { orgId } = useParams();
  const [numberOfMembers, setNumberOfMembers] = useState<number>(4);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [oldEndCourse, setOldEndCourse] = useState<string>('');

  const allTeamMembers = useLazyLoadQuery<ProfileQueriesGetOrganizationMembersConnectionQuery>(GET_ALL_HIRE_ORGANIZATION_MEMBERS, {
    orgId: orgId!,
    first: numberOfMembers,
  }).organizationMembers;

  const handleLoadMore = useCallback(() => {
    setNumberOfMembers(prev => prev + 4);
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (allTeamMembers?.pageInfo.endCursor !== oldEndCourse) {
      setIsLoading(false);
    }
  }, [allTeamMembers?.pageInfo.endCursor, oldEndCourse]);

  useEffect(() => {
    setOldEndCourse(allTeamMembers?.pageInfo.endCursor as string);
  }, [allTeamMembers?.pageInfo.endCursor]);

  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex text-lg gap-x-3 font-medium">
        <h2 className="text-darkBlue">{intl.formatMessage({ id: 'hire_organization_members_page_title' })}</h2>
        <span className="text-specialGray-075">{allTeamMembers?.totalCount}</span>
      </div>
      {allTeamMembers?.edges?.length ? (
        <div className="flex gap-y-3 justify-between flex-wrap">
          {allTeamMembers?.edges?.map(member => (
            <MemberComponent key={member?.node?.id} member={member?.node as ProfileFragmentsOrganizationMemberInfo$key} />
          ))}
        </div>
      ) : (
        <NoTeamMembers />
      )}
      {allTeamMembers?.pageInfo.hasNextPage && (
        <>
          {!isLoading ? (
            <Button
              type="button"
              buttonType="white-primary"
              label={{ id: 'hire_organization_load_more_members' }}
              additionalClasses="mx-auto px-6 py-4 rounded-xl"
              onClick={handleLoadMore}
            />
          ) : (
            <SpinnerIcon />
          )}
        </>
      )}
    </div>
  );
};

export default ListOfMembers;
