import { MessageDescriptor } from '@formatjs/intl';
import LineSvg from 'icons/LineSvg';
import { useIntl } from 'react-intl';

const MobileListItemField = ({ title, text }: { title: MessageDescriptor; text: string }) => {
  const intl = useIntl();
  return (
    <>
      <LineSvg classes="w-[calc(100%_+_32px)] relative -left-4" />
      <div className="flex flex-col w-full space-y-1">
        <span className="text-xs text-specialGray-075 uppercase">{intl.formatMessage(title)}</span>
        <span className="text-sm text-darkBlue">{text}</span>
      </div>
    </>
  );
};

export default MobileListItemField;
