/**
 * @generated SignedSource<<7f3b220baef31cbd35b6076cdcab5d5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ProfileFragmentsSearchDropDown$data = {
  readonly 'id': string;
  readonly 'info': {
    readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragments_SearchDropDown_info'>;
  } | null;
  readonly 'membership': ReadonlyArray<{
    readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsOrganizationPlural_profile'>;
  }> | null;
  readonly ' $fragmentType': 'ProfileFragmentsSearchDropDown';
};
export type ProfileFragmentsSearchDropDown$key = {
  readonly ' $data'?: ProfileFragmentsSearchDropDown$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ProfileFragmentsSearchDropDown'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ProfileFragmentsSearchDropDown',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      concreteType: 'ProfileInfo',
      kind: 'LinkedField',
      name: 'info',
      plural: false,
      selections: [
        {
          args: null,
          kind: 'FragmentSpread',
          name: 'ProfileFragments_SearchDropDown_info',
        },
      ],
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      concreteType: 'OrganizationMember',
      kind: 'LinkedField',
      name: 'membership',
      plural: true,
      selections: [
        {
          args: null,
          kind: 'FragmentSpread',
          name: 'ProfileFragmentsOrganizationPlural_profile',
        },
      ],
      storageKey: null,
    },
  ],
  type: 'Profile',
  abstractKey: null,
};

(node as any).hash = '6b354801a162b65c3f6a0ebfd11e2997';

export default node;
