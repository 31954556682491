// packages
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyLoadQuery, useMutation } from 'react-relay';
// generated
import { LIST_OF_EDUCATIONS_FROM_PROFILE } from 'schemas/educations/EducationQueries';
import { REMOVE_EDUCATION_LIST_ITEM_MUTATION } from 'schemas/educations/EducationMutations';
import { EducationFragments$data } from 'schemas/educations/__generated__/EducationFragments.graphql';
import { EducationMutationsListRemoveMutation } from 'schemas/educations/__generated__/EducationMutationsListRemoveMutation.graphql';
import { ProfileMutationsProfileProgressMutation } from 'schemas/profile/__generated__/ProfileMutationsProfileProgressMutation.graphql';
import { EducationQueriesListOfEducationsFromProfileQuery } from 'schemas/educations/__generated__/EducationQueriesListOfEducationsFromProfileQuery.graphql';
// schemas
import { UPDATE_PROFILE_DOCUMENT } from 'schemas/profile/ProfileMutations';
// components
import { Modal } from 'system/Modal';
import EducationForm from './EducationForm';
import EducationIcon from 'icons/EducationIcon';
import TableEducationItem from './TableEducationItem';
import EducationItemTitle from './EducationItemTitle';
import EmptyList from 'system/ListComponents/EmptyList';
import HeaderList from 'system/ListComponents/HeaderList';
import { ConfirmModalBody } from 'system/ConfirmModalBody';
import ListEducationItem from 'app/Profile/Education/ListEducationItem';
import EducationFormSkeleton from 'system/skeletons/educations/EducationFormSkeleton';
//helpers
import { EducationDeleteUpdater } from 'formHelpers/updaters/updatersOfEducation';
// hooks
import useSortedByEndDate from 'hooks/useSortedByEndDate';
import { useGetProfileIdFromAuthOrParams } from 'hooks/useGetProfileIdFromAuthOrParams';
// types
import { EducationListProps } from 'app/Profile/Education/types';

const EducationList = ({ defaultShowModal = false }: EducationListProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { getProfileIdFromAuthOrParams } = useGetProfileIdFromAuthOrParams();

  useEffect(() => {
    window.analytics?.track('wui-open-educationList-tab');
  }, []);

  const { educations } = useLazyLoadQuery<EducationQueriesListOfEducationsFromProfileQuery>(LIST_OF_EDUCATIONS_FROM_PROFILE, {
    ownerId: getProfileIdFromAuthOrParams,
    skip: !getProfileIdFromAuthOrParams,
  });

  const getSortedField = useCallback((item: { node: { end: String | null } | null } | null) => item?.node?.end ?? null, []);
  const sortedEducationByEndDate = useSortedByEndDate([...(educations?.edges || [])], getSortedField);

  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<EducationFragments$data>();
  const [showModal, setShowModal] = useState<boolean>(defaultShowModal);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [removingItemId, setRemovingItemId] = useState<string>('');
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);

  const [commitRemoveListItem] = useMutation<EducationMutationsListRemoveMutation>(REMOVE_EDUCATION_LIST_ITEM_MUTATION);

  // Update progress profile if last item zero, backend will fix it in a while
  const [commitCreate] = useMutation<ProfileMutationsProfileProgressMutation>(UPDATE_PROFILE_DOCUMENT);

  const openCreateModal = () => {
    setSelected(undefined);
    setShowModal(true);
    setIsOpenCreateModal(true);
  };

  const openUpdateModal = useCallback((education: EducationFragments$data) => {
    setSelected(education);
    setShowModal(true);
  }, []);

  const closeModal = () => {
    setShowModal(false);
    setIsOpenCreateModal(false);
    navigate(pathname, {
      replace: true,
      state: null,
    });
  };

  function onFormSubmitted(reopenFormAgain: boolean) {
    closeModal();

    if (reopenFormAgain) {
      const HALF_SECOND = 500;
      setTimeout(() => openCreateModal(), HALF_SECOND);
    }
  }

  const closeConfirmModal = useCallback(() => {
    setShowConfirmModal(false);
  }, []);

  const onItemRemoveClick = useCallback((id: string) => {
    setShowConfirmModal(true);
    setRemovingItemId(id);
  }, []);

  const removeListItem = useCallback(
    (id: string) => {
      setLoading(true);
      const onError = (error: Error) => {
        console.warn(error);
      };
      commitRemoveListItem({
        variables: { sid: id },
        onCompleted: () => {
          setLoading(false);
          window.analytics?.track('wui-removed-education', {
            removedEducationId: id,
          });

          closeConfirmModal();
          if (educations?.edges?.length === 1) {
            commitCreate({
              variables: {
                pid: getProfileIdFromAuthOrParams || '',
                data: { createdFirstEducationItem: false },
              },
              onError,
            });
          }
        },
        updater: EducationDeleteUpdater(getProfileIdFromAuthOrParams),
      });
    },
    [closeConfirmModal, commitRemoveListItem, getProfileIdFromAuthOrParams, educations?.edges?.length, commitCreate],
  );

  return (
    <section aria-labelledby="education-title">
      <Modal onClose={closeModal} show={showModal} title={{ id: selected ? 'self_edus_modalTitle' : 'profile_addEduCta' }}>
        <Suspense fallback={<EducationFormSkeleton />}>
          <EducationForm education={selected} isOpenCreateModal={isOpenCreateModal} onSubmitted={onFormSubmitted} />
        </Suspense>
      </Modal>

      <Modal onClose={closeConfirmModal} show={showConfirmModal} tinyModal>
        <ConfirmModalBody
          title={{ id: 'confirm_remove_item_title' }}
          textOne={{ id: 'confirm_remove_item_text_1' }}
          textTwo={{ id: 'confirm_remove_item_text_2' }}
          onClick={() => removeListItem(removingItemId)}
          onCancel={closeConfirmModal}
          buttonLabel={{ id: 'delete' }}
          typeButtonConfirm="red-primary"
        />
      </Modal>
      <div className="rounded-lg">
        <HeaderList
          title={{ id: 'profile_educationTitle' }}
          onClick={openCreateModal}
          buttonLabel={{ id: 'profile_addEduCta' }}
          amountLabel={{ id: 'profile_educationAmount' }}
          amount={educations?.edges?.length}
        />

        {sortedEducationByEndDate ? (
          <>
            <section className="hidden sm:flex px-5">
              <table className="table-auto w-full">
                <EducationItemTitle />
                <tbody>
                  {sortedEducationByEndDate.map(edge => {
                    return (
                      edge?.node && (
                        <TableEducationItem key={edge?.node?.id} education={edge?.node} disabled={loading} openUpdateModal={openUpdateModal} removeFunction={onItemRemoveClick} />
                      )
                    );
                  })}
                </tbody>
              </table>
            </section>
            <ul className="sm:hidden">
              {sortedEducationByEndDate.map(edge => {
                return (
                  edge?.node && (
                    <ListEducationItem key={edge?.node?.id} education={edge?.node} disabled={loading} openUpdateModal={openUpdateModal} removeFunction={onItemRemoveClick} />
                  )
                );
              })}
            </ul>
          </>
        ) : (
          <EmptyList navigationTitle={{ id: 'profile_addEduCta' }} onClick={openCreateModal} title={{ id: 'profile_noEducation' }} icon={<EducationIcon />} />
        )}
      </div>
    </section>
  );
};

export default EducationList;
